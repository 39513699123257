import { paramsConfig, paramsMaxSizeSearch } from "../../utils/paramsUtils";
import axios from "axios";
import { ISymbolTimekeeping } from "../models/categoryModels";

const API_URL_CHAM_CONG = process.env.REACT_APP_API_URL_CHAM_CONG;

export const searchSymbol = (searchData: any = {}) => {
  let url = `${API_URL_CHAM_CONG}/ma-cong-viec/page`;
  return axios.get(url, paramsConfig(searchData));
};

export const addSymbol = (symbolInfo: ISymbolTimekeeping) => {
  let url = `${API_URL_CHAM_CONG}/ma-cong-viec`;
  return axios.post(url, symbolInfo);
};

export const updateSymbol = (id: string, symbolInfo: ISymbolTimekeeping) => {
  let url = `${API_URL_CHAM_CONG}/ma-cong-viec/${id}`;
  return axios.put(url, symbolInfo);
};

export const deleteSymbol = (ids: string[]) => {
  let url = `${API_URL_CHAM_CONG}/ma-cong-viec/ids?ids=${ids.toString()}`;
  return axios.delete(url);
};

export const getListWorkDay = (searchData: any = {}) => {
  let url = `${API_URL_CHAM_CONG}/workday-type/page`;
  return axios.get(url, paramsMaxSizeSearch(searchData));
}
import { MISSION_STATUS, TYPE, TYPE_CATEGORY } from "../../constants/moduleConsts";
import { ISecondment } from "../models/secondmentModels";
import { searchAllSimpleValue, searchListWorkUnit, searchPhongBan } from "../../services/services";

export const INIT_SECONDMENT: ISecondment = {
  chucVuId: "",
  chucVuMoi: null,
  chucVuMoiId: "",
  chucVuMoiText: "",
  chucVuText: "",
  coHieuLucDenNgay: null,
  coHieuLucTuNgay: null,
  createDate: null,
  createdBy: "",
  donViCongTacId: "",
  donViCongTacText: "",
  donViCongTacMoi: null,
  donViCongTacMoiId: "",
  donViCongTacMoiText: "",
  employee: null,
  employeeId: "",
  fileId: "",
  fileName: "",
  ghiChu: "",
  id: "",
  maNhanVien: "",
  modifiedBy: "",
  modifyDate: null,
  name: "",
  ngayQuyetDinh: null,
  phongBanId: "",
  phongBanMoi: null,
  phongBanMoiId: "",
  phongBanMoiText: "",
  phongBanText: "",
  soQuyetDinh: "",
  trangThai: null,
  xacNhanBietPhai: false
} 

export const ListSearch = (lang: any) => {
  return [
    {
      name: lang("PROFILE.CODE"),
      field: "maNhanVien",
      type: TYPE.TEXT,
    },
    {
      name: lang("PROFILE.NAME"),
      field: "name",
      type: TYPE.TEXT,
    },
    {
      name: lang("INPUT.WORKPROCESS.UNIT.OLD"),
      field: "donViCongTacId",
      searchFunction: searchListWorkUnit,
      type: TYPE.SELECT,
    },
    {
      name: lang("INPUT.WORKPROCESS.DEPARTMENT.OLD"),
      field: "phongBanIds",
      searchFunction: searchPhongBan,
      type: TYPE.SELECT,
      isMulti: true,
    },    
    {
      name: lang("RESIGN.OLDPOSITION"),
      field: "chucVuId",
      searchFunction: searchAllSimpleValue,
      searchObject: { type: TYPE_CATEGORY.chucDanh },
      type: TYPE.SELECT,
      optionLabel: "value"
    },
    {
      name: lang("INPUT.WORKPROCESS.UNIT.NEW"),
      field: "donViCongTacMoiId",
      searchFunction: searchListWorkUnit,
      type: TYPE.SELECT,
    },
    {
      name: lang("INPUT.WORKPROCESS.DEPARTMENT.NEW"),
      field: "phongBanMoiIds",
      searchFunction: searchPhongBan,
      type: TYPE.SELECT,
      isMulti: true,
    },
    {
      name: lang("RESIGN.NEWPOSITION"),
      field: "chucVuMoiId",
      searchFunction: searchAllSimpleValue,
      searchObject: { type: TYPE_CATEGORY.chucDanh },
      type: TYPE.SELECT,
      optionLabel: "value"
    },
    {
      name: lang("INPUT.WORKPROCESS.DECISION.NUMBER"),
      field: "soQuyetDinh",
      type: TYPE.TEXT,
    },
    {
      name: lang("GENERAL.STATUS"),
      field: "trangThai",
      type: TYPE.SELECT,
      listOption: MISSION_STATUS,
      isMulti: true,
    },
  ]
}
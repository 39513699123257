import { Modal } from "react-bootstrap";
import { IDeclareAssets } from "../../../declare-assets/models/declareAssetsModels";
import DeclareAssetsForm from "../../../declare-assets/components/DeclareAssetsForm";
import { useRef } from "react";
import { FormikProps } from "formik";
import { GroupButton } from "../../../components/GroupButton";

interface IProps {
  open: boolean;
  handleClose: () => void;
  handleCloseAndSearch: () => void;
  dataEdit: IDeclareAssets;
  employeeProfiles: any;
}

function DeclareAssetsDialog(props: IProps) {
  const { open, handleClose, handleCloseAndSearch, dataEdit, employeeProfiles } = props;
  const formikRef = useRef<FormikProps<any> | null>(null);

  return (
    <Modal show={open} onHide={handleClose} backdrop="static" centered size="xl" className="modal-size-custom custom-modal declare-assets declare-profile">
      <Modal.Header closeButton className="spaces py-12">
        <Modal.Title className="spaces fs-20 fw-600">
          {dataEdit?.id ? "Chỉnh sửa thông tin kê khai tài sản" : "Thêm mới thông tin kê khai tài sản"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DeclareAssetsForm 
          formikRef={formikRef} 
          editId={dataEdit?.id} 
          handleClose={handleClose} 
          handleCloseAndSearch={handleCloseAndSearch}
          employeeInfo={employeeProfiles} 
        />
      </Modal.Body>
      <Modal.Footer className="flex-center">
        <GroupButton type="btn-cancel" handleCloseUpdateDialog={handleClose} />
        <GroupButton type="btn-save" handleSubmit={() => formikRef.current?.handleSubmit()} />
      </Modal.Footer>
    </Modal>
  );
}

export default DeclareAssetsDialog;

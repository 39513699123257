import { ITemplateExportWord } from "../../components/exportWord";
import { findList } from "../../utils/functionUtils";
import CompensatoryLeaveWord from "../components/attendance-watch/CompensatoryLeaveWord";
import OnLeaveWord from "../components/attendance-watch/OnLeaveWord";
import { IAttendanceWatch } from "../models/attendanceWatchModes";
import { CODE_STATUS, LIST_LOAI_DON, LIST_STATUS, TYPE_DON } from "./managerRequestConsts";

export const TYPE_LOAI_NGHI = {
    NGHI_PHEP: 1,
    NGHI_NGHI_BU: 2,
    NGHI_KHONG_LUONG: 3,
}

export const LIST_LOAI_NGHI = [
    { type: TYPE_LOAI_NGHI.NGHI_PHEP, name: "Đơn xin nghỉ phép", code: "DXNP" },
    { type: TYPE_LOAI_NGHI.NGHI_NGHI_BU, name: "Đơn xin nghỉ bù", code: "DXNB" },
    { type: TYPE_LOAI_NGHI.NGHI_KHONG_LUONG, name: "Nghỉ không lương", code: "NKL" }
]

export const KEY_TAB = {
    CHUA_GUI: "1",
    CHO_DUYET: "2",
    DA_DUYET: "3",
    TU_CHOI: "4"
}

export const DS_TAB_DON_XIN_NGHI = [
    {
        eventKey: KEY_TAB.CHUA_GUI,
        title: "Chưa gửi",
    },
    {
        eventKey: KEY_TAB.CHO_DUYET,
        title: "Chờ duyệt",
    },
    {
        eventKey: KEY_TAB.DA_DUYET,
        title: "Đã duyệt",
    },
    {
        eventKey: KEY_TAB.TU_CHOI,
        title: "Từ chối",
    }
]

export const INIT_ATTENDANCE_WATCH: IAttendanceWatch = {
    employee: null,
    donViCongTacId: "",
    donViCongTacText: "",
    maNhanVien: "",
    phongBanId: "",
    phongBanText: "",
    chucDanhId: "",
    chucDanhText: "",
    dienThoai: "",
    ngayNop: "",
    tuNgay: "",
    denNgay: "",
    loaiDon: findList(LIST_LOAI_DON, TYPE_DON.DON_XIN_NGHI, "type"),
    loaiNghi: null,
    tyLeHuongLuong: "",
    noiXinNghi: "",
    lyDoXinNghi: "",
    lyDoNghiBu: "",
    truongKhoa: null,
    trangThai: findList(LIST_STATUS, CODE_STATUS.TAO_MOI),
    ngayDuyet: null,
    ghiChu: "",
}

export const attendanceWatchWordExportList: ITemplateExportWord[] = [
    {
        type: TYPE_LOAI_NGHI.NGHI_PHEP,
        name: "Đơn nghỉ phép",
        componentPreview: (dataPreview) => OnLeaveWord({ data: dataPreview })
    },
    {
        type: TYPE_LOAI_NGHI.NGHI_NGHI_BU,
        name: "Đơn nghỉ bù",
        componentPreview: (dataPreview) => CompensatoryLeaveWord({ data: dataPreview })
    }
]
import React, { useContext, useEffect, useState } from 'react'
import useMultiLanguage from '../../hook/useMultiLanguage';
import AppContext from '../../AppContext';
import { INIT_SEARCH_OBJECT, RESPONSE_STATUS_CODE, TYPE } from '../constants/moduleConsts';
import { toast } from 'react-toastify';
import TableCustom from '../components/table-custom/TableCustom';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import InputSearch from '../components/input/InputSearch';
import { GroupButton } from '../components/GroupButton';
import { hasAuthority } from '../utils/functionUtils';
import { PERMISSION_ABILITY, PERMISSIONS } from '../../constants';
import RegisterOvertimeForm from './components/register-overtime/RegisterOvertimeForm';
import { DS_TAB_DANG_KI_LAM_THEM, INIT_REGISTER_OVERTIME, KEY_TAB } from './constants/registerOvertimeConsts';
import { IRegisterOvertime } from './models/registerOvertimeModes';
import { CODE_STATUS, TYPE_DON } from './constants/managerRequestConsts';
import { columns } from './components/register-overtime/Columns';
import { deleteManagerRequest, getByIdManagerRequest, searchManagerRequest } from './services/managerRequestServices';

function RegisterOvertime() {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [listResignationLetter, setListResignationLetter] = useState<any[]>([]);
  const [openResignationLetterForm, setOpenResignationLetterForm] = useState<boolean>();
  const [isApproveOrReject, setIsApproveOrReject] = useState<boolean>(false);

  const [resignationLetter, setResignationLetter] = useState<IRegisterOvertime>(INIT_REGISTER_OVERTIME);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [isView, setIsView] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>(KEY_TAB.CHUA_GUI);
  const [searchObject, setSearchObject] = useState<any>({
    ...INIT_SEARCH_OBJECT,
    loaiDon: TYPE_DON.DANG_KY_LAM_THEM,
    trangThai: CODE_STATUS.TAO_MOI,
  });

  const getById = async (id: string | undefined) => {
    try {
      setPageLoading(true)
      const searchObject = {
        loaiDon: TYPE_DON.DANG_KY_LAM_THEM
      }
      const { data } = await getByIdManagerRequest(id, searchObject)
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setResignationLetter(data.data);
        return;
      }
      toast.warning(data?.message);
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false)
    }
  }

  const handleOpenResignationLetterForm = (data: any) => {
    getById(data?.id)
    setIsView(true);
    setOpenResignationLetterForm(true);
  };

  const handleApproveOrReject = (data: any) => {
    getById(data?.id)
    setIsView(true);
    setIsApproveOrReject(true)
    setOpenResignationLetterForm(true);
  };

  const handleCloseAndSearch = () => {
    handleSearch();
    handleClose();
  }

  const handleClose = () => {
    setOpenResignationLetterForm(false);
    setIsView(false);
    setIsApproveOrReject(false);
    setResignationLetter(INIT_REGISTER_OVERTIME);
  };

  const getListData = async (searchData?: any) => {
    try {
      setPageLoading(true);
      setSearchObject(searchData)
      const { data } = await searchManagerRequest(searchData);

      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListResignationLetter(data?.data?.content || []);
        setTotalPage(data?.data?.pageable?.totalPages);
        setTotalElements(data?.data?.pageable?.totalElements);
        setNumberOfElements(data?.data?.pageable?.numberOfElements);
        return;
      }
      toast.warning(data?.message);
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleOpenUpdateDialog = () => {
    setOpenResignationLetterForm(true)
  }

  const handleCloseUpdateDialog = () => {
    setIsView(true);
  }

  const handleSearch = (data: any = {}) => {
    const dataSearch: any = {
      ...searchObject,
      ...data
    };
    dataSearch.keyword = dataSearch?.keyword?.trim() || "";
    getListData(dataSearch);
  };

  useEffect(() => {
    if (searchObject?.pageIndex && searchObject?.pageSize) {
      handleSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchObject?.pageIndex, searchObject?.pageSize])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchObject({
      ...searchObject,
      keyword: e.target?.value
    });
  };

  const handleDeleteShift = async (ids: string[]) => {
    try {
      let searchObject = {
        ids: ids?.toString(),
        loaiDon: TYPE_DON.DANG_KY_LAM_THEM
      }
      const { data } = await deleteManagerRequest(searchObject)
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("TIMEKEEPING.SHIFT.DELETE"));
        handleSearch();
        return true;
      }
      toast.warning(data?.message)
    }
    catch (err: any) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      handleClose();
    }
  }

  const handleTabSelect: (eventKey: string | null) => void = (eventKey) => {
    if (eventKey) {
      setActiveTab(eventKey);
    }
    handleSearch({ trangThai: eventKey });
  };

  return (
    <div className="manager-request spaces px-8 py-12 flex-1">
      {!openResignationLetterForm ? (
        <>
          <Row className="spaces mb-12 flex-middle">
            <Col xs={5} xl={6} xxl={7} className="p-0">
              <h2 className="breadcrumb-title">{lang("MANAGENT_REQUEST.REGISTER_OVERTIME.LIST")}</h2>
            </Col>
            <Col xs={7} xl={6} xxl={5} className="flex">
              <div className="spaces mr-10 flex-1">
                <InputSearch
                  className="border-3px"
                  value={searchObject?.keyword}
                  placeholder="Tìm kiếm theo tên người nộp"
                  handleSearch={() => handleSearch({ pageIndex: 1 })}
                  handleChange={handleChange}
                  isEnter
                />
              </div>
              <GroupButton handleEvent={() => handleSearch({ pageIndex: 1 })}>
                {lang("BTN.SEARCH")}
              </GroupButton>
            </Col>
          </Row>
          <Row className="bg-white">
            <Col className="table-custom">
              <Tabs
                className="tabs nav nav-tabs customs-tabs"
                activeKey={activeTab}
                onSelect={handleTabSelect}
              >
                {
                  DS_TAB_DANG_KI_LAM_THEM.map((item: any, index: number) => {
                    return <Tab
                      eventKey={item.eventKey}
                      key={item.eventKey}
                      title={
                        <div className="label">
                          <span>{item?.title}</span>
                        </div>
                      }
                    >
                      <TableCustom
                        id="shift-list"
                        data={listResignationLetter}
                        columns={columns({
                          handleOpenResignationLetterForm,
                          handleApproveOrReject,
                          lang
                        })}
                        searchObject={searchObject}
                        setSearchObject={setSearchObject}
                        buttonAdd={item.eventKey === KEY_TAB.CHUA_GUI && hasAuthority(PERMISSIONS.DON_DANG_KI_LAM_THEM, PERMISSION_ABILITY.CREATE)}
                        notDelete={!hasAuthority(PERMISSIONS.DON_DANG_KI_LAM_THEM, PERMISSION_ABILITY.DELETE) || item.eventKey !== KEY_TAB.CHUA_GUI}
                        type={TYPE.MULTILINE}
                        fixedColumnsCount={4}
                        totalPages={totalPage}
                        totalElements={totalElements}
                        numberOfElements={numberOfElements}
                        handleOpenDialog={handleOpenUpdateDialog}
                        handleDoubleClick={(row: any) => handleOpenResignationLetterForm(row)}
                        handleDelete={(ids: string[]) => handleDeleteShift(ids)}
                      />
                    </Tab>
                  })
                }
              </Tabs>
            </Col>
          </Row>
        </>
      ) : (
        <RegisterOvertimeForm
          handleClose={handleClose}
          handleCloseAndSearch={handleCloseAndSearch}
          item={resignationLetter}
          handleCloseUpdateDialog={handleCloseUpdateDialog}
          isView={isView}
          setIsView={setIsView}
          isApproveOrReject={isApproveOrReject}
        />
      )}
    </div>
  )
}

export { RegisterOvertime };
import { IPropsExportWord } from "../../components/exportWord";
import { ValueOrDots } from "../../components/exportWord/partials";

export function ReceivingCitizenWord({ data }: IPropsExportWord) {
  const tuThang = data?.tuThang ? (new Date(data?.tuThang)).getMonth() + 1 : "";
  const denThang = data?.denThang ? (new Date(data?.denThang)).getMonth() + 1 : "";
  return (
    <div className="word spaces px-70 colors-word">
      <div className="spaces text-center flex flex-between align-start">
        <div className="fs-lg">
          <p>{data?.soYTeHeader}</p>
          <p className="fw-600 text-uppercase">
            <span className="header-underline fs-lg">{data?.tenBenhVien}</span>
          </p>
          <p className="spaces mt-16 fs-md">
            Số: <ValueOrDots value={data.soBaoCao} />/BC-BV
          </p>
        </div>
        <div className="fw-600">
          <p className="fs-lg">CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM</p>
          <p>
            <span className="header-underline fs-lg">Độc lập - Tự do - Hạnh phúc</span>
          </p>
          <p className="text-italic fw-500 spaces mt-16">
            <ValueOrDots value="" />,
            ngày {(new Date()).getDate()} tháng {(new Date()).getMonth() + 1} năm {(new Date()).getFullYear()}
          </p>
        </div>
      </div>
      <div className="spaces py-16 text-center fw-600">
        <p className="fs-lg">BÁO CÁO</p>
        <p className="fs-lg">
          Công tác tiếp công dân, giải quyết khiếu nại, tố cáo {data?.soThang} tháng từ tháng {tuThang} đến tháng {denThang} năm {data?.nam}
        </p>
      </div>
      <div className="text-indent-40">
        <p>Thực hiện Công văn số 821/SYT-TTrS ngày 08/3/2024 của {data?.soYTe} về việc báo cáo công tác tiếp công dân, giải quyết khiếu nại, 
          tố cáo {data?.soThang} tháng từ tháng {tuThang} đến tháng {denThang} năm {data?.nam}.
        </p>
        <p>{data?.tenBenhVien} báo cáo kết quả tiếp công dân, giải quyết khiếu nại, tố cáo {data?.soThang} tháng từ 
          tháng {tuThang} đến tháng {denThang} năm {data?.nam} như sau: 
        </p>
        <p className="fw-bold fs-lg">I. TÌNH HÌNH KHIẾU NẠI, TỐ CÁO</p>
        <p className="fw-bold text-italic">1. Tình hình khiếu nại, tố cáo</p>
        <p>Trong {data?.soThang} tháng từ tháng {tuThang} đến tháng {denThang} năm {data?.nam}, Bệnh viện luôn nhận được sự quan tâm, chỉ đạo 
          sát sao của Sở Y tế về công tác tiếp công dân và giải quyết khiếu nại, tố cáo của công dân; Bệnh viện thường xuyên chỉ đạo, sắp xếp thời 
          gian để tiếp công dân, chỉ đạo giải quyết khiếu nại, tố cáo; xem đây là nhiệm vụ chính trị quan trọng và thường xuyên của đơn vị, thể hiện 
          trách nhiệm của người đứng đầu trong công tác tiếp dân, giải quyết khiếu nại, tố cáo; Trong {data?.soThang} tháng từ tháng {tuThang} đến 
          tháng {denThang} năm {data?.nam}, bệnh viện đã tiếp nhận tổng số {data?.totalDon} phản ánh của người bệnh, người nhà người bệnh.
        </p>
        <p className="fw-bold text-italic">2. Nguyên nhân của tình hình khiếu nại, tố cáo</p>
        <p className="text-italic">a) Nguyên nhân khách quan:</p>
        <p>Cơ chế, chính sách, pháp luật để giải quyết khiếu nại thiếu thống nhất, chưa phù hợp với thực tế về thẩm quyền và trình tự thủ tục giải quyết, 
          cơ chế giải quyết khiếu nại chưa phân định rõ việc tổ chức, thực hiện chức năng quản lý hành chính và giải quyết khiếu nại hành chính.
        </p>
        <p className="text-italic">b) Nguyên nhân chủ quan:</p>
        <p>Công tác tuyên truyền các chủ trương, chính sách pháp luật của Đảng và Nhà nước nói chung, về tiếp công dân, giải quyết đơn thư khiếu nại, 
          tố cáo của công dân nói riêng chưa được thực hiện một cách tích cực, thường xuyên liên tục, chưa đạt được kết quả cao.
        </p>
        <p className="fw-bold fs-lg">II. KẾT QUẢ CÔNG TÁC TIẾP CÔNG DÂN, XỬ LÝ ĐƠN, GIẢI QUYẾT KHIẾU NẠI, TỐ CÁO</p>
        <p className="fw-bold">1. Công tác tiếp công dân (Biểu số 01/TCD, 02/TCD)</p>
        <p className="text-italic">a) Kết quả tiếp công dân</p>
        <p>Trong {data?.soThang} tháng từ tháng {tuThang} đến tháng {denThang} năm {data?.nam}, bệnh viện tiếp nhận {data?.totalDon} lượt tiếp công dân 
          (tiếp thường xuyên) xem xét giải quyết ý kiến của người bệnh về việc chuyển tuyến.
        </p>
        <p className="text-italic">b) Phân loại đơn, vụ việc qua tiếp công dân</p>
        <p>- Phân loại theo nội dung (tổng số đơn, số vụ việc):</p>
        <p>+ Khiếu nại: {data?.totalKhieuNai} đơn</p>
        <p>+ Tố cáo: {data?.totalToCao} đơn</p>
        <p>+ Phản ánh, kiến nghị: {data?.totalKienNghi} đơn</p>
        <p>- Phân loại theo thẩm quyền giải quyết:</p>
        <p>+ Thuộc thẩm quyền (số đơn, số vụ việc): {data?.totalThuocThamQuyen} đơn</p>
        <p>+ Không thuộc thẩm quyền (số đơn, số vụ việc): {data?.totalKhongThuocThamQuyen} đơn</p>
        <p className="fw-bold">2. Tiếp nhận, phân loại và xử lý đơn (Biểu số 01/XLD, 02/XLD, 03/XLD, 04/XLD)</p>
        <p>Gồm đơn nhận được qua tiếp công dân và đơn nhận từ các nguồn khác (người viết đơn gửi qua dịch vụ bưu 
          chính, cơ quan, tổ chức, cá nhân chuyển đến theo quy định, …)
        </p>
        <p>a) Tổng số đơn: Kỳ trước chuyển sang, tiếp nhận trong kỳ: Không có</p>
        <p>- Số đơn đã xử lý/ Tổng số đơn tiếp nhận: {data?.totalDaGiaiQuyet}/{data?.totalDon} đơn</p>
        <p>- Số đơn, số vụ việc, đủ điều kiện xử lý: {data?.totalDon}/{data?.totalDon} đơn</p>
        <p>b) Phân loại, xử lý đơn</p>
        <p>- Phân loại theo nội dung:</p>
        <p>+ Số đơn, số vụ việc khiếu nại: {data?.totalKhieuNai} đơn</p>
        <p>+ Số đơn, số vụ việc tố cáo: {data?.totalToCao} đơn</p>
        <p>+ Số đơn, số vụ việc kiến nghị, phản ánh: {data?.totalKienNghi} đơn</p>
        <p>- Phân loại theo tình trạng giải quyết</p>
        <p>+ Số đơn, số vụ việc đã giải quyết: {data?.totalDaGiaiQuyet} đơn</p>
        <p>+ Số đơn, số vụ việc đang giải quyết: {data?.totalDangGiaiQuyet} đơn</p>
        <p>+ Số đơn, số vụ việc chưa giải quyết: {data?.totalChuaGiaiQuyet} đơn</p>
        <p>c) Kết quả xử lý đơn</p>
        <p> -Số đơn, số vụ việc thuộc thẩm quyền: {data?.totalThuocThamQuyen} đơn</p>
        <p>- Số đơn, số vụ việc không thuộc thẩm quyền: {data?.totalKhongThuocThamQuyen} đơn</p>
        <p className="fw-bold">3. Kết quả giải quyết khiếu nại, tố cáo, kiến nghị, phản ánh thuộc thẩm quyền (Biểu số 01/KQGQ, 02/KQGQ, 03/KQGQ, 04/KQGQ)</p>
        <p>Tổng số đơn, số vụ việc (khiếu nại, tố cáo, kiến nghị, phản ánh) đã giải quyết là {data?.totalSoDonGiaiQuyet} đơn/{data?.totalDon} tổng số đơn, số vụ việc 
          (khiếu nại, tố cáo, kiến nghị, phản ánh) phải giải quyết; tỷ lệ giải quyết {data?.tiLeGiaiQuyet}
        </p>
        <p className="text-italic">a) Kết quả giải quyết khiếu nại (Biểu số 01/KQGQ, 02/KQGQ)</p>
        <p>- Tổng số đơn, số vụ việc đã giải quyết {data?.khieuNaiDaGiaiQuyet} đơn/{data?.totalDon} tổng số đơn, số vụ việc phải giải quyết; tỷ lệ giải quyết;</p>
        <p>- Tổng hợp quyết định giải quyết khiếu nại đã thu hồi cho Nhà nước, trả lại cho tập thể cá nhân, 
          kiến nghị xử lý trách nhiệm, chuyển cơ quan điều tra…: Không có
        </p>
        <p>- Phân tích kết quả giải quyết đối với các vụ việc khiếu nại lần đầu, lần 2, việc chấp hành thời hạn giải quyết khiếu nại: Không có</p>
        <p> - Kết quả thực hiện quyết định giải quyết khiếu nại: số quyết định phải thực hiện, số quyết định đã thực hiện xong; qua thực hiện đã thu 
          hồi cho Nhà nước, trả lại cho tập thể cá nhân; số người đã bị xử lý trách nhiệm, đã khởi tố…: Không có
        </p>
        <p className="text-italic">b) Kết quả giải quyết tố cáo (03/KQGQ, 04/KQGQ)</p>
        <p>- Tổng số đơn, số vụ việc đã giải quyết {data?.toCaoDaGiaiQuyet} đơn/{data?.totalDon} tổng số đơn, số vụ việc phải giải quyết; tỷ lệ giải quyết;</p>
        <p>- Tổng hợp kết luận nội dung tố cáo đã thu hồi cho Nhà nước, trả lại cho tập thể cá nhân, kiến nghị xử lý trách nhiệm, …: Không có</p>
        <p>- Phân tích kết quả giải quyết đối với các vụ việc tố cáo lần đầu, tố cáo tiếp, việc chấp hành thời hạn giải quyết tố cáo: Không có</p>
        <p>- Kết quả thực hiện kết luận nội dung tố cáo: số kết luận phải thực hiện, số kết luận đã thực hiện xong; số tiền, đất đã thu hồi cho 
          Nhà nước, trả lại cho tập thể cá nhân; số người đã bị xử lý trách nhiệm, …: Không có
        </p>
        <p className="text-italic">c) Kết quả giải quyết kiến nghị, phản ánh (nếu có)</p>
        <p>Tổng số vụ việc {data?.kienNghiDaGiaiQuyet}/{data?.totalDon} nội dung kiến nghị, phản ánh số vụ việc/nội dung đã giải quyết; …</p>
        <p><span className="fw-bold">4. Bảo vệ người tố cáo:</span> Không có</p>
        <p className="fw-bold fs-lg">III. NHẬN XÉT, ĐÁNH GIÁ</p>
        <p className="fw-bold">1.	Ưu điểm</p>
        <p>{data?.soYTe} đã chỉ đạo các cơ quan, đơn vị triển khai thực hiện nghiêm túc công tác tiếp công dân và giải quyết 
          khiếu nại, tố cáo; xác định là nhiệm vụ chính trị quan trọng, thường xuyên; Lãnh đạo bệnh viện đã chỉ đạo tăng cường 
          tiếp và đối thoại với công dân, nâng cao chất lượng giải quyết khiếu nại, tố cáo; nghiêm túc thực hiện các quyết định 
          giải quyết khiếu nại có hiệu lực pháp luật, quyết định xử lý tố cáo. Công tác tiếp công dân, giải quyết khiếu nại, tố 
          cáo đã có chuyển biến tích cực, chất lượng giải quyết các vụ việc ở đơn vị được nâng lên. Tăng cường kiểm tra, đôn đốc 
          cán bộ, viên chức, đảng viên thực hiện nhiệm vụ tiếp dân và xử lý đơn thư chấp hành tốt nội quy, quy chế, công 
          tác thanh tra, tiếp công dân, giải quyết khiếu nại tố cáo.
        </p>
        <p className="fw-bold">2. Tồn tại, hạn chế</p>
        <p>Bên cạnh những kết quả đạt được, công tác tiếp công dân, giải quyết khiếu nại, tố cáo vẫn còn một số tồn tại, hạn chế 
          và khó khăn vướng mắc đó là: Bệnh viện chưa thực hiện tốt việc tiếp công dân định kỳ của người đứng đầu theo quy định của Luật Tiếp công dân.
        </p>
        <p className="fw-bold">3. Nguyên nhân</p>
        <p>Công tác lãnh, chỉ đạo về tiếp dân, giải quyết đơn thư chưa được quan tâm đúng mức; cán bộ, viên chức mới được kiện toàn, 
          chưa được tập huấn về chuyên môn, nghiệp vụ do vậy thực hiện công tác tham mưu giải quyết khiếu nại, tố cáo, phản ánh còn 
          chưa hiệu quả. Ý thức chấp hành của một bộ phận nhân dân còn hạn chế nên có những yêu cầu, đòi hỏi không đúng quy định của pháp luật.
        </p>
        <p className="fw-bold fs-lg">IV. DỰ BÁO TÌNH HÌNH</p>
        <p>Trong thời gian tới, tình hình đơn thư khiếu nại, tố cáo, kiến nghị, phản ánh có khả năng tiếp tục phát sinh. 
          Do vậy, bệnh viện thực hiện nghiêm túc các quy định của pháp luật về Luật Tiếp công dân.
        </p>
        <p className="fw-bold fs-lg">V. PHƯƠNG HƯỚNG, NHIỆM VỤ, GIẢI PHÁP CHỦ YẾU TRONG KỲ TIẾP THEO</p>
        <p>Thường xuyên tuyên truyền sâu rộng chủ trương, đường lối, chính sách, pháp luật của Đảng, Nhà nước nói chung, 
          Luật Khiếu nại, Luật Tố cáo, Luật Tiếp công dân để mọi người hiểu thực hiện, đồng thời phát huy sức mạnh của các 
          tổ chức chính trị, chính trị xã hội trong công tác tuyên truyền vận động nhân dân thực hiện các chủ trương 
          đường lối của Đảng, pháp luật của Nhà nước.
        </p>
        <p>Duy trì, tổ chức thực hiện việc tiếp công dân, giải quyết đơn thư đúng quy định của Luật Khiếu nại, Luật Tố cáo, 
          Luật Tiếp công dân và các văn bản hướng dẫn của cấp trên. Chấn chỉnh những tồn tại, yếu kém, nâng cao hiệu lực, 
          hiệu quả quản lý nhà nước, chất lượng đội ngũ cán bộ phụ trách công tác tiếp công dân, xử lý đơn thư, giải quyết 
          khiếu nại, tố cáo, đề nghị của công dân.
        </p>
        <p>Thủ trưởng đơn vị thực hiện chế độ thông tin báo cáo về tiếp công dân, giải quyết đơn thư, khiếu nại, 
          tố cáo kịp thời và đầy đủ nội dung theo quy định của pháp luật.
        </p>
        <p className="fw-bold fs-lg">VI. KIẾN NGHỊ, ĐỀ XUẤT: Không có</p>
        <p>Trên đây là báo cáo Công tác tiếp công dân, giải quyết khiếu nại, tố cáo {data?.soThang} tháng từ 
          tháng {tuThang} đến tháng {denThang} năm {data?.nam} của {data?.tenBenhVien}.
        </p>
      </div>
      <div className="align-start flex flex-between spaces mt-16 pb-70">
        <div>
          <p className="fw-600 text-italic">Nơi nhận:</p>
          <p>- Sở Y tế (b/c);</p>
          <p>- Lưu: VT, TCCB(Van03b)</p>
        </div>
        <div className="fw-600 text-center spaces pr-70">
          <p>GIÁM ĐỐC</p>
        </div>
      </div>
    </div>
  );
}

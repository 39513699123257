import { FC, useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import AppContext from "../../../../AppContext";
import { IHoliday } from "../../models/categoryModels";
import { INIT_SEARCH_OBJECT, RESPONSE_STATUS_CODE, TYPE } from "../../../constants/moduleConsts";
import InputSearch from "../../../components/input/InputSearch";
import { GroupButton } from "../../../components/GroupButton";
import TableCustom, { columnNamesType } from "../../../components/table-custom/TableCustom";
import { formatDateTable, hasAuthority } from "../../../utils/functionUtils";
import { PERMISSION_ABILITY, PERMISSIONS } from "../../../../constants";
import { deleteLeaveRegulations, searchLeaveRegulations } from "../../services/leaveRegulationsServices";
import { HolidayForm } from "./dialogs";
import { REGULATION_TYPE } from "../../constants/leaveRegulationsConsts";

const Holiday: FC = () => {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [listHoliday, setListHoliday] = useState<IHoliday[]>([]);
  const [openHolidayForm, setOpenHolidayForm] = useState<boolean>(false);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [searchObject, setSearchObject] = useState<any>({
    ...INIT_SEARCH_OBJECT,
    regulationType: REGULATION_TYPE.HOLIDAY
  });
  const [holidayInfo, setHolidayInfo] = useState<IHoliday>({} as IHoliday);
  const [isView, setIsView] = useState(false);

  const getListHoliday = async (searchData?: any) => {
    try {
      setPageLoading(true);
      setSearchObject({ ...searchData })
      const { data } = await searchLeaveRegulations(searchData);
      if(data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListHoliday(data?.data?.content || []);
        setTotalPage(data?.data?.pageable?.totalPages);
        setTotalElements(data?.data?.pageable?.totalElements);
        setNumberOfElements(data?.data?.pageable?.numberOfElements);
      } else {
        toast.warning(data?.message);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleOpenUpdateDialog = () => {
    setIsView(false);
    setOpenHolidayForm(true);
  }

  const handleSearch = (data: any = {}) => {
    const dataSearch: any = {
      ...searchObject,
      ...data
    };
    dataSearch.keyword = dataSearch?.keyword?.trim() || "";
    getListHoliday(dataSearch);
  };

  useEffect(() => {
    if(searchObject?.pageIndex && searchObject?.pageSize) {
      handleSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchObject?.pageIndex, searchObject?.pageSize])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchObject({
      ...searchObject,
      keyword: e.target?.value
    });
  };

  const handleDeleteHoliday = async (ids: string[]) => {
    try {
      const { data } = await deleteLeaveRegulations(ids)
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("HOLIDAY.DELETE"))
        await getListHoliday(searchObject);
        return true;
      } else {
        toast.warning(data?.message)
      }
    }
    catch (err: any) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }

  const handleOpenHolidayForm = (info: any) => {
    setIsView(true);
    setOpenHolidayForm(true);
    setHolidayInfo(info);
  };

  const handleCloseAndSearch = () => {
    handleSearch();
    handleClose();
  }

  const handleClose = () => {
    setOpenHolidayForm(false);
    setHolidayInfo({} as IHoliday);
  };
  
  const columns: columnNamesType[] = [
    {
      name: "STT",
      field: "",
      render: (_row: any, _index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("HOLIDAY.NAME"),
      field: "name",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("HOLIDAY.TIME"),
      field: "thoiGianNghi",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row: any) => <span>{formatDateTable(row?.startDate)} - {formatDateTable(row?.endDate)}</span>
    },
    {
      name: lang("DESCRIPTION"),
      field: "description",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      }
    }
  ];
  
  return (
    <div className="spaces px-8 py-12 flex-1">
      <Row className="spaces mb-12 flex-middle">
        <Col xs={5} xl={8} xxl={9} className="p-0">
          <h2 className="breadcrumb-title">{lang("HOLIDAY.LIST")}</h2>
        </Col>
        <Col xs={7} xl={4} xxl={3} className="flex">
          <div className="spaces mr-10 flex-1">
            <InputSearch
              className="border-3px"
              value={searchObject?.keyword}
              placeholder="Tìm kiếm theo tháng, năm"
              handleSearch={() => handleSearch({ pageIndex: 1 })}
              handleChange={handleChange}
              isEnter
            />
          </div>
          <GroupButton handleEvent={() => handleSearch({ pageIndex: 1 })}>
            {lang("BTN.SEARCH")}
          </GroupButton>
        </Col>
      </Row>
      <Row>
        <Col className="table-custom">
          <TableCustom
            id="holiday-list"
            data={listHoliday}
            columns={columns}
            searchObject={searchObject}
            setSearchObject={setSearchObject}
            buttonAdd={hasAuthority(PERMISSIONS["DANH_MUC.CHAM_CONG.NGHI_LE"], PERMISSION_ABILITY.CREATE)}
            notDelete={!hasAuthority(PERMISSIONS["DANH_MUC.CHAM_CONG.NGHI_LE"], PERMISSION_ABILITY.DELETE)}
            type={TYPE.MULTILINE}
            totalPages={totalPage}
            totalElements={totalElements}
            numberOfElements={numberOfElements}
            handleOpenDialog={handleOpenUpdateDialog}
            handleDoubleClick={(row: any) => handleOpenHolidayForm(row)}
            handleDelete={(ids: string[]) => handleDeleteHoliday(ids)}
          />
        </Col>
      </Row>
      {openHolidayForm &&
        <HolidayForm
          open={openHolidayForm}
          handleClose={handleClose}
          handleCloseAndSearch={handleCloseAndSearch}
          holidayInfo={holidayInfo}
          isView={isView}
        />
      }
    </div>
  );
}

export { Holiday };
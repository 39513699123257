import { useContext, useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import Autocomplete from "../input/autocomplete";
import { GroupButton } from "../../components/GroupButton";
import { checkObject, exportToFile } from "../../utils/functionUtils";
import AppContext from "../../../AppContext";
import { RESPONSE_STATUS_CODE, TYPE } from "../../constants/moduleConsts";
import { toast } from "react-toastify";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { IDataPreview, IPropsSearchExportWord, ITemplateExportWord } from "./models/exportWordModels";
import "./export-word.scss";
import { exportWord, getDataPreviewWord } from "../../services/services";
import { IObject } from "../../models/models";
import { AxiosResponse } from "axios";

interface IProps {
  open: boolean;
  dataWord?: IDataPreview;
  handleClose: () => void;
  templateList: ITemplateExportWord[];
  getObj: IObject;
  customFileName?: (dataPreview: IDataPreview, fileNameBase: string) => string;
  ComponentSearch?: (props: IPropsSearchExportWord) => JSX.Element;
  searchFunction?: (searchObject: IObject) => void;
  getData?: (SearchObject: IObject) => Promise<AxiosResponse<any, any>>;
  downloadWord?: (SearchObject: IObject) => Promise<AxiosResponse<any, any>>;
}

export function ExportWord(props: IProps) {
  const { open,
    dataWord = {} as IDataPreview,
    handleClose,
    templateList,
    getObj,
    customFileName,
    ComponentSearch,
    searchFunction,
    getData = getDataPreviewWord,
    downloadWord = exportWord
  } = props;
  const { lang } = useMultiLanguage();

  const [dataPreview, setDataPreview] = useState<IDataPreview>(dataWord);
  const [template, setTemplate] = useState<ITemplateExportWord>(templateList[0]);
  const [searchDataObj, setSearchDataObj] = useState<IObject>(getObj || {} as IObject);
  const { setPageLoading } = useContext(AppContext);

  const getDataPreview = async (searchObject: IObject) => {
    try {
      setPageLoading(true);
      const { data } = await getData(searchObject);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setDataPreview(data?.data || {})
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    if(searchFunction) {
      searchFunction(searchDataObj);
    } else {
      getDataPreview(searchDataObj);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDataObj]);

  useEffect(() => {
    !checkObject(dataWord) && setDataPreview(dataWord);
  }, [dataWord]);

  const handleExportWord = () => {
    exportToFile({
      exportAPI: () => downloadWord(searchDataObj),
      fileName: customFileName ? customFileName(dataPreview, template.name) : template.name,
      type: TYPE.WORD,
      setPageLoading
    });
  };

  return (
    <Modal
      show={open}
      size="xl"
      centered
      className="custom-modal export-word"
      aria-labelledby="example-custom-modal-styling-title"
      onHide={handleClose}
      backdrop="static"
    >
      <Modal.Header className="spaces px-16 py-10">
        <div className="w-100">
          <div className="spaces flex flex-middle flex-space-between py-12 color-primary text-uppercase">
            <GroupButton type="btn-back" handleClose={handleClose} />
            <GroupButton handleEvent={handleExportWord}>{lang("GENERAL.EXPORT_TYPE_WORD")}</GroupButton>
          </div>
          {templateList.length > 1 && (
            <Row>
              <Col xs={8} className="spaces z-index-10">
                <Autocomplete
                  horizontal={true}
                  lable={lang("GENERAL.SELECT_TEMPLATE_EXPORT_WORD")}
                  options={templateList}
                  value={template}
                  onChange={(value) => setTemplate(value)}
                />
              </Col>
            </Row>
          )}
          {ComponentSearch && <ComponentSearch values={searchDataObj} setValues={setSearchDataObj} />}
        </div>
      </Modal.Header>
      <Modal.Body className="content">{template?.componentPreview && template.componentPreview(dataPreview)}</Modal.Body>
    </Modal>
  );
}

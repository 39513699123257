/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import * as Yup from "yup";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import Autocomplete from "../../../components/input/autocomplete";
import { RangeDatePicker } from "../../../components/input/RangeDatePicker";
import TextValidator from "../../../components/input/text-validator";
import { TYPE_OF_OFFICER } from "../../../profile/constants/dialogChildConsts";
import { salaryAdjustmentTypes, salaryTypes } from "../../../profile/constants/profileConsts";
import { getBacLuongByChucDanh, getNgayHuongLuongDenNgay } from "../../../profile/services/dialogServices";
import { getEmployeeProfileById } from "../../../profile/services/profileServices";
import "../../../styles/index.scss";
import { RESPONSE_STATUS_CODE, VARIABLE_STRING, TYPE_CATEGORY } from "../../../constants/moduleConsts";
import { convertNumberPrice, convertTextPrice, covertDateToString, handleBlurDate } from "../../../utils/functionUtils";
import { IAdjustSalary, ISalaryLevel } from "../../models/warningModels";
import { checkInvalidDate } from "../../../utils/validationSchema";
import AppContext from "../../../../AppContext";
import { addAdjustSalary } from "../../services/warningServices";
import { GroupButton } from "../../../components/GroupButton";
import { searchAllSimpleValue, searchNguoiDaiDienKy, searchPhongBanByDonVi } from "../../../services/services";
import { AddNewRepresentative } from "../../../components/dialogs";
import { IPeopleDecision } from "../../../models/models";

interface IProps {
  handleClose: () => void;
  salaryInfo: IAdjustSalary;
  handleUpdateParent: () => void;
}
export const AdjustSalaryDialog: React.FC<IProps> = ({handleClose, salaryInfo, handleUpdateParent}) => {
  const { lang, intl } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [salaryLevel, setSalaryLevel] = useState<ISalaryLevel[]>([])
  const [isShowDialogAddRepresentative, setIsShowDialogAddRepresentative] = useState<boolean>(false);
  const [peopleDecisions, setPeopleDecisions] = useState<IPeopleDecision[]>([]);

  const getPeopleDecision = async () => {
    try {
      const { data } = await searchNguoiDaiDienKy();
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setPeopleDecisions(data?.data?.content || [])
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }

  useEffect(() => {
    getPeopleDecision();
  }, []);

  const handleCloseAndSearchRepresentative = () => {
    getPeopleDecision();
    setIsShowDialogAddRepresentative(false);
  }
  
  const validationSchema = Yup.object().shape({
    employee: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    bacLuongOption: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    heSoBacLuong: Yup.number().required(lang("VALIDATION.REQUIRE")).nullable(),
    ngayHuongLuongTuNgay: Yup.date()
      .concat(checkInvalidDate(intl))
      .when("ngayHuongLuongDenNgay", {
        is: (ngayHuongLuongDenNgay: Date | null) => ngayHuongLuongDenNgay,
        then: Yup.date()
          .max(
            Yup.ref("ngayHuongLuongDenNgay"),
            lang("VALIDATION.MAXDATE") +
              lang("INPUT.QUALIFICATION.TRAINING_TIME_END")
          )
          .nullable()
      }).required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    ngayHuongLuongDenNgay: Yup.date().concat(checkInvalidDate(intl)).nullable(),
    loaiDieuChinhLuong: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
  });

  const handleFormSubmit = async (values: IAdjustSalary) => {
    try {
      setPageLoading(true);
      const dataAdjustSalary = {
        ...values,
        nguoiQuyetDinhId: values?.nguoiQuyetDinh?.id,
        nguoiQuyetDinhText: values?.nguoiQuyetDinh?.name
      }
      const { data } = await addAdjustSalary(dataAdjustSalary);
      if(data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("TOAST.SALARY_AUJUSTED.SUCCESS"));
        handleUpdateParent();
        handleClose();
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    if(!salaryInfo || !salaryInfo.employeeId) return;
    getEmployeeInfo(salaryInfo.employeeId);
  },[salaryInfo])

  const getEmployeeInfo = async (id: string) => {
    try {
      const { data } = await getEmployeeProfileById(id);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        formik.setFieldValue(VARIABLE_STRING.EMPLOYEE, data?.data);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  useEffect(() => {
    if(!salaryInfo || !salaryInfo.chucDanhId) return;
    getBacLuong(salaryInfo.chucDanhId);
  },[salaryInfo]);

  const getBacLuong = async (id: string) => {
    try {
      const { data } = await getBacLuongByChucDanh(id);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        let sortedSalaryLevel = data?.data?.sort((a: any, b: any) => a.bacLuong - b.bacLuong);
        let nextSalaryLevel: ISalaryLevel = sortedSalaryLevel?.find((item: ISalaryLevel) => item?.bacLuong === salaryInfo?.bacLuongDeXuat);
        setSalaryLevel(sortedSalaryLevel);
        formik.setFieldValue(VARIABLE_STRING.BAC_LUONG_OPTION, nextSalaryLevel);
        formik.setFieldValue(VARIABLE_STRING.HE_SO_BAC_LUONG, nextSalaryLevel?.heSoLuong);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const convertDataSalaryInfo = (salaryInfo: IAdjustSalary) => {
    return {
      ...salaryInfo,
      ngayHuongLuongTuNgay: salaryInfo?.ngayDeXuat || "",
      thoiGianGiuBacDuKienBatDau: salaryInfo?.ngayDeXuat || "",
      phongBan: {
        id: salaryInfo?.phongBanId,
        name: salaryInfo?.phongBanText
      },
      chucDanh: {
        id: salaryInfo?.chucDanhId,
        value: salaryInfo?.chucDanhText
      }
    }
  }

  const formik = useFormik({
    initialValues: convertDataSalaryInfo(salaryInfo),
    validationSchema,
    onSubmit: handleFormSubmit
  })

  const handleChangeSelect = (name: string, value: any) => {
    if (name === VARIABLE_STRING.BAC_LUONG_OPTION) {
      formik.setValues({
        ...formik.values,
        [name]: value,
        heSoBacLuong: value?.heSoLuong
      });
    }
    formik.setFieldValue(name, value);
  }

  const handleChangeRangeDatePicker = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    switch (name) {
      case VARIABLE_STRING.NGAY_HUONG_LUONG_TU:
        formik.setValues({
          ...formik.values,
          [name]: value,
          thoiGianGiuBacDuKienBatDau: value
        });
        break;
      case VARIABLE_STRING.NGAY_HUONG_LUONG_DEN:
        formik.setValues({
          ...formik.values,
          [name]: value,
          thoiGianGiuBacDuKienKetThuc: value
        });
        break;
      default:
        formik.setFieldValue(name, value);
        break;
    }
  };

  const handleChangeMoney = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, convertTextPrice(value));
  }

  const getNgayHuongLuongToiNgay = async (params: any) => {
    try {
      let { data } = await getNgayHuongLuongDenNgay(params);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        formik.setFieldValue("ngayHuongLuongDenNgay", data?.data);
        formik.setFieldValue("thoiGianGiuBacDuKienKetThuc", data?.data);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  useEffect(() => {
    let ngayHuongLuongTuNgay = formik.values?.ngayHuongLuongTuNgay;
    getNgayHuongLuongToiNgay({
      employeeId: salaryInfo?.employeeId,
      ngayHuongLuongTuNgay,
      loaiCanBo: formik.values?.loaiCanBo?.code,
      loaiDieuChinhLuong: formik.values?.loaiDieuChinhLuong?.code,
      chucDanhId: formik.values?.chucDanh?.id
    });
  }, [formik.values?.ngayHuongLuongTuNgay]);

  return (
    <Modal
      show={true}
      size="xl"
      centered
      aria-labelledby="example-custom-modal-styling-title"
      onHide={handleClose}
      backdrop="static"
    >
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title
            id="example-custom-modal-styling-title"
            className="heading-5"
          >
            {lang("INPUT.SALARY_INFO.ADJUST_SALARY")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12}>
              <span className="fs-3 fw-bold">
                {lang("INPUT.SALARY_INFO.EMPLOYEE_INFO")}
              </span>
            </Col>
            <Col xs={12}>
              <Row className="ms-2">
                <Col xs={3} className="pt-3">
                  <Autocomplete
                    isRequired
                    isReadOnly={true}
                    lable={lang("PROFILE.NAME")}
                    options={[]}
                    onChange={() => {}}
                    name="employee"
                    value={formik.values?.employee || null}
                    errors={formik.errors.employee}
                    touched={formik.touched.employee}
                  />
                </Col>
                <Col xs={3} className="pt-3">
                  <TextValidator
                    lable={lang("INPUT.CODE_NEW")}
                    name="maNhanVien"
                    isReadOnly={true}
                    value={formik.values?.maNhanVien || ""}
                    type="text"
                    readOnly={true}
                    onChange={formik.handleChange}
                  />
                </Col>
                <Col xs={3} className="pt-3">
                  <Autocomplete
                    isReadOnly={true}
                    lable={lang("INPUT.DEPARTMENTS")}
                    options={[]}
                    value={formik.values?.phongBan || null}
                    name="phongBan"
                    onChange={(selectedOption) =>
                      handleChangeSelect("phongBan", selectedOption)
                    }
                    urlData="data.data"
                    searchFunction={searchPhongBanByDonVi}
                    searchObject={{ id: formik.values?.employee?.donViCongTacId }}
                    touched={formik.touched?.phongBan}
                    errors={formik.errors?.phongBan}
                    dependencies={[formik.values?.employee?.donViCongTacId]}
                  />
                </Col>
                <Col xs={3} className="pt-3">
                  <Autocomplete
                    lable={lang("GENERAL.EMPLOYEE.TITLE")}
                    name="chucDanh"
                    isReadOnly={true}
                    value={formik.values?.chucDanh || ""}
                    searchFunction={searchAllSimpleValue}
                    searchObject={{ type: TYPE_CATEGORY.viTriCongViec }}
                    onChange={(selectedOption) =>
                      handleChangeSelect("chucDanh", selectedOption)
                    }
                    options={[]}
                    getOptionLabel={(option) => option?.value}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={12} className="pt-4">
              <span className="fs-3 fw-bold">
                {lang("INPUT.SALARY_INFO.ADJUST_SALARY_INFO")}
              </span>
            </Col>
            <Col xs={12}>
              <Row className="ms-2">
                <Col xs={3} className="pt-3">
                  <Autocomplete
                    lable={lang("GENERAL.EMPLOYEE.MANAGER.TYPE")}
                    isReadOnly={true}
                    name="loaiCanBo"
                    value={formik.values?.loaiCanBo || null}
                    onChange={(selectedOption) =>
                      handleChangeSelect("loaiCanBo", selectedOption)
                    }
                    options={TYPE_OF_OFFICER}
                  />
                </Col>
                <Col xs={3} className="pt-3">
                  <Autocomplete
                    lable={lang("INPUT.SALARY.KIND")}
                    isReadOnly={true}
                    options={salaryTypes}
                    value={formik.values?.loaiLuong}
                    name="loaiLuong"
                    onChange={(value) =>
                      handleChangeSelect(VARIABLE_STRING.LOAI_LUONG, value)
                    }
                    errors={formik.errors?.loaiLuong}
                    touched={formik.touched?.loaiLuong}
                  />
                </Col>
                <Col xs={3} className="pt-3">
                  <Autocomplete
                    isReadOnly={true}
                    lable={lang("INPUT.SALARY.ADJUSTMENT.TYPE")}
                    isRequired
                    options={salaryAdjustmentTypes}
                    value={formik.values?.loaiDieuChinhLuong || null}
                    name="loaiDieuChinhLuong"
                    onChange={(selectedOption) =>
                      handleChangeSelect("loaiDieuChinhLuong", selectedOption)
                    }
                    errors={formik.errors?.loaiDieuChinhLuong}
                    touched={formik.touched?.loaiDieuChinhLuong}
                  />
                </Col>
                <Col xs={3}>
                  <Row>
                    <Col xs={6} className="pt-3">
                      <Autocomplete
                        lable={lang("INPUT.SALARY.RANK")}
                        isRequired
                        isReadOnly={true}
                        name="bacLuongOption"
                        value={formik.values?.bacLuongOption || ""}
                        onChange={(value) =>
                          handleChangeSelect(VARIABLE_STRING.BAC_LUONG_OPTION, value)
                        }
                        options={salaryLevel}
                        getOptionLabel={(option) => option?.bacLuong}
                      />
                    </Col>
                    <Col xs={6} className="pt-3">
                      <TextValidator
                        lable={lang("INPUT.SALARY.COEFFICIENT")}
                        name="heSoBacLuong"
                        type="number"
                        isRequired
                        value={formik.values?.heSoBacLuong || null}
                        readOnly={true}
                        errors={formik.errors?.heSoBacLuong}
                        touched={formik.touched?.heSoBacLuong}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs={7}>
                  <Row>
                    <Col xs={6} className="pt-3">
                      <RangeDatePicker
                        label={lang("INPUT.SALARY.BENEFIT_TIME")}
                        isRequired
                        startDateName="ngayHuongLuongTuNgay"
                        endDateName="ngayHuongLuongDenNgay"
                        handleChange={handleChangeRangeDatePicker}
                        value={formik.values}
                        setFieldValue={formik.setFieldValue}
                        touch={formik.touched}
                        errors={formik.errors}
                      />
                    </Col>
                    <Col xs={6} className="pt-3">
                      <RangeDatePicker
                        label={lang("INPUT.SALARY.KEEP_LEVEL_TIME")}
                        startDateName="thoiGianGiuBacDuKienBatDau"
                        endDateName="thoiGianGiuBacDuKienKetThuc"
                        isView={true}
                        handleChange={formik.handleChange}
                        value={formik.values}
                        setFieldValue={formik.setFieldValue}
                        touch={formik.touched}
                        errors={formik.errors}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs={5}>
                  <Row>
                    <Col xs={6} className="pt-3">
                      <TextValidator
                        lable={lang("SALARY.SENIORITY_ALLOWANCES")}
                        name="phuCapThamNienVuotKhung"
                        value={convertNumberPrice(
                          formik.values?.phuCapThamNienVuotKhung || null
                        )}
                        type="text"
                        onChange={handleChangeMoney}
                      />
                    </Col>
                    <Col xs={6} className="pt-3">
                      <TextValidator
                        lable={lang("SALARY.DIFFERENT_ALLOWANCES")}
                        name="phuCapChenhLechBaoLuu"
                        value={convertNumberPrice(
                          formik.values?.phuCapChenhLechBaoLuu || null
                        )}
                        type="text"
                        onChange={handleChangeMoney}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={12} className="pt-4">
              <span className="fs-3 fw-bold">
                {lang("INPUT.SALARY_INFO.DECISION")}
              </span>
            </Col>
            <Col xs={12}>
              <Row className="ms-2">
                <Col xs={3} className="pt-3">
                  <TextValidator
                    lable={lang("INPUT.WORKPROCESS.DECISION.SIGNED.DATE")}
                    name="ngayKyToTrinh"
                    value={
                      covertDateToString(formik.values?.ngayKyToTrinh) || ""
                    }
                    type="date"
                    onBlur={() =>
                      handleBlurDate(
                        formik.setFieldValue,
                        formik.values?.ngayKyToTrinh,
                        "ngayKyToTrinh"
                      )
                    }
                    onChange={formik.handleChange}
                  />
                </Col>
                <Col xs={3} className="pt-3">
                  <TextValidator
                    lable={lang("INPUT.WORKPROCESS.DECISION.SIGNED.NUMBER")}
                    name="soToTrinh"
                    value={formik.values?.soToTrinh || ""}
                    type="text"
                    onChange={formik.handleChange}
                  />
                </Col>
                <Col xs={3} className="pt-3">
                  <TextValidator
                    lable={lang("SALARY.PLACE_OF_DECISION")}
                    name="noiQuyetDinh"
                    value={formik.values?.noiQuyetDinh || ""}
                    type="text"
                    onChange={formik.handleChange}
                  />
                </Col>
                <Col xs={3} className="pt-3">
                  <TextValidator
                    lable={lang("INPUT.WORKPROCESS.DECISION.DATE")}
                    name="ngayQuyetDinh"
                    value={
                      covertDateToString(formik.values?.ngayQuyetDinh) || ""
                    }
                    type="date"
                    onBlur={() =>
                      handleBlurDate(
                        formik.setFieldValue,
                        formik.values?.ngayQuyetDinh,
                        "ngayQuyetDinh"
                      )
                    }
                    onChange={formik.handleChange}
                  />
                </Col>
                <Col xs={3} className="pt-3">
                  <TextValidator
                    lable={lang("INPUT.WORKPROCESS.DECISION.NUMBER")}
                    name="soQuyetDinh"
                    value={formik.values?.soQuyetDinh || ""}
                    type="text"
                    onChange={formik.handleChange}
                  />
                </Col>
                <Col xs={3} className="pt-3">
                  <Autocomplete
                    className="z-index-2"
                    lable={lang("INPUT.WORKPROCESS.DECISION.PERSON")}
                    options={peopleDecisions}
                    onChange={(selectedOption) =>
                      handleChangeSelect("nguoiQuyetDinh", selectedOption)
                    }
                    name="nguoiQuyetDinh"
                    value={formik.values?.nguoiQuyetDinh || null}
                    isAddNew
                    onAddNew={() => setIsShowDialogAddRepresentative(true)}
                    errors={formik.errors.nguoiQuyetDinh}
                    touched={formik.touched.nguoiQuyetDinh}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="flex-center">
          <GroupButton type="btn-cancel" handleCloseUpdateDialog={handleClose} />
          <GroupButton typeButton="submit">
            {lang("BTN.SALARY_ADJUSTMENT")}
          </GroupButton>
        </Modal.Footer>
      </Form>
      {isShowDialogAddRepresentative && (
        <AddNewRepresentative
          handleClose={() => setIsShowDialogAddRepresentative(false)}
          handleCloseAndSearch={handleCloseAndSearchRepresentative}
        />
      )}
    </Modal>
  );
};

import { useContext, useEffect, useState } from 'react'
import TableCustom, { columnNamesType } from '../components/table-custom/TableCustom'
import { LIST_MONTH, RESPONSE_STATUS_CODE, VARIABLE_STRING } from '../constants/moduleConsts';
import { formatDateTable, getFullYear, hasAuthority } from '../utils/functionUtils';
import useMultiLanguage from '../../hook/useMultiLanguage';
import AppContext from '../../AppContext';
import CustomTooltip from '../components/custom-tooltip';
import { KTSVG } from '../../../_metronic/helpers';
import { Col, Form, Row } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { toast } from 'react-toastify';
import Autocomplete from '../components/input/autocomplete';
import { searchListWorkUnit, searchPhongBanByDonVi } from '../services/services';
import { GroupButton } from '../components/GroupButton';
import { INIT_UPDATE_SHIFT_SEARCH } from './constants/updateWorkShiftsConsts';
import UpdateWorkShiftsForm from './components/update-shifts/UpdateWorkShiftsForm';
import "./shift.scss"
import { searchUpdateWorkShifts } from './services/updateWorkShiftsServices';
import { IEmployeeSchedule } from './models/shiftDivisionModels';
import { PERMISSION_ABILITY, PERMISSIONS } from '../../constants';
import { localStorageItem } from '../utils/localstorage';
import { KEY_LOCALSTORAGE } from '../auth/core/_consts';

function UpdateWorkShifts() {
    const [openForm, setOpenForm] = useState<boolean>(false);
    const [listShift, setListShift] = useState<any[]>([]);
    const [itemShift, seItemShift] = useState<object>({});
    const [isView, setIsView] = useState<boolean>(false);

    const { lang } = useMultiLanguage();
    const { setPageLoading } = useContext(AppContext);

    const getDonViCongTac = async () => {
        try {
            const { data } = await searchListWorkUnit();
            if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
                formik.setFieldValue("donViCongTac", data?.data?.content?.[0] || null)
                return;
            }
            toast.warning(data?.message);
        } catch (error) {
            toast.error(lang("GENERAL.ERROR"));
        }
    }

    useEffect(() => {
        getDonViCongTac()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleOpenEdit = (data: any) => {
        seItemShift(data);
        setOpenForm(true);
        setIsView(true)
    };

    const handleClose = () => {
        setOpenForm(false);
        setIsView(false);
        seItemShift({});
    };

    const handleCloseAndSearch = () => {
        handleSearch();
        handleClose();
    }

    const validationSchema = Yup.object().shape({
        donViCongTac: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
        phongBan: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
        nam: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
        thang: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    })

    const handleSearch = async () => {
        try {
            setPageLoading(true);
            let { donViCongTac, phongBan, nam, thang, ...searchOption } = formik.values;
            let searchObject = {
                ...searchOption,
                nam,
                thang,
                phongBanId: phongBan?.id || null,
            };

            const { data } = await searchUpdateWorkShifts(searchObject);
            if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
                setListShift(data?.data?.content || [])
                setIsView(true);
                return;
            }
            toast.warning(data?.message);
        } catch (error) {
            toast.error(lang("GENERAL.ERROR"));
        } finally {
            setPageLoading(false);
        }
    };

    const formik = useFormik({
        initialValues: { 
            ...INIT_UPDATE_SHIFT_SEARCH,
            phongBan: (hasAuthority(PERMISSIONS.DEPARTMENT, PERMISSION_ABILITY.VIEW) 
                || hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW))
                ? {
                    id: localStorageItem.get(KEY_LOCALSTORAGE.EMPLOYEE)?.phongBanId,
                    name: localStorageItem.get(KEY_LOCALSTORAGE.EMPLOYEE)?.phongBanText
                } : null,
        },
        validationSchema: validationSchema,
        onSubmit: handleSearch
    });

    const handleChangeSelect = (name: string, value: any) => {
        formik.setFieldValue(name, value);
    };

    const columns: columnNamesType[] = [
        {
            name: "STT",
            field: "",
            render: (row: any, index: number, stt: number) => <span>{stt}</span>
        },
        {
            name: lang("GENERAL.ACTION"),
            field: "thaoTac",
            headerStyle: {
                width: "150px"
            },
            render: (row: any) => {
                return (
                    <div className="flex flex-center column-gap-4 cursor-pointer">
                        <CustomTooltip title={lang("GENERAL.EDIT")} placement="top">
                            <div className='color-primary' onClick={() => handleOpenEdit(row)}>
                                <KTSVG path='/media/icons/pencil-fill.svg' />
                            </div>
                        </CustomTooltip>

                    </div>
                )
            }
        },
        {
            name: lang("TIME_KEEPING.DATE_WORKING"),
            field: "ngayLamViec",
            headerStyle: {
                minWidth: "120px"
            },
            render: row => <span>{formatDateTable(row?.ngayLamViec)}</span>
        },
        {
            name: lang("TIMEKEEPING.NUMBER.PATIENT"),
            field: "soLuongBenhNhan",
            headerStyle: {
                minWidth: "80px"
            }
        },
        {
            name: lang("TIMEKEEPING.NURSING.ON_CALL"),
            field: "dieuDuongTruc",
            headerStyle: {
                minWidth: "300px"
            },
            cellStyle: {
                textAlign: "left"
            },
            render: (row) => <span>{row?.employeeInfoList?.filter((employee: IEmployeeSchedule) => employee?.duocTinhCong)?.map((item: IEmployeeSchedule) => item?.employeeText)?.join(", ")}</span>
        },
    ];

    return (
        <div className='update-shift spaces px-8 py-12'>
            <Form onSubmit={formik.handleSubmit}>
                <Row className="spaces relative z-index-4">
                    <Col xs={3}>
                        <Autocomplete
                            isRequired
                            lable={lang("GENERAL.CURRENT_ORGANIZATION")}
                            options={[]}
                            value={formik.values?.donViCongTac || null}
                            name={VARIABLE_STRING.DON_VI_CONG_TAC}
                            onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.DON_VI_CONG_TAC, selectedOption)}
                            isReadOnly={true}
                            touched={formik.touched?.donViCongTac}
                            errors={formik.errors?.donViCongTac}
                        />
                    </Col>
                    <Col xs={3}>
                        <Autocomplete
                            isRequired
                            isReadOnly={hasAuthority(PERMISSIONS.DEPARTMENT, PERMISSION_ABILITY.VIEW)
                                || hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW)
                            }
                            lable={lang("INPUT.DEPARTMENTS")}
                            options={[]}
                            value={formik.values?.phongBan || null}
                            name={VARIABLE_STRING.PHONG_BAN}
                            onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.PHONG_BAN, selectedOption)}
                            urlData="data.data"
                            searchFunction={searchPhongBanByDonVi}
                            searchObject={{ id: (formik.values?.donViCongTac as any)?.id }}
                            touched={formik.touched?.phongBan}
                            errors={formik.errors?.phongBan}
                            dependencies={[formik.values?.donViCongTac]}
                        />
                    </Col>
                    <Col xs={3}>
                        <Row>
                            <Col xs={6}>
                                <Autocomplete
                                    isRequired
                                    lable={lang("GENERAL.YEAR")}
                                    options={getFullYear(0)}
                                    value={formik.values?.nam || null}
                                    name={VARIABLE_STRING.NAM}
                                    valueSearch="name"
                                    onChange={(value) => handleChangeSelect(VARIABLE_STRING.NAM, value?.name)}
                                    touched={formik.touched?.nam}
                                    errors={formik.errors?.nam}
                                />
                            </Col>
                            <Col xs={6}>
                                <Autocomplete
                                    isRequired
                                    lable={lang("GENERAL.MONTH")}
                                    options={LIST_MONTH}
                                    value={formik.values?.thang || null}
                                    name="thang"
                                    valueSearch="code"
                                    onChange={(value) => handleChangeSelect("thang", value?.code)}
                                    touched={formik.touched?.thang}
                                    errors={formik.errors?.thang}
                                />
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={3} className="spaces flex flex-start mt-21">
                        <GroupButton typeButton="submit" className='spaces pr-10'>
                            <i className="bi bi-funnel spaces flex fs-18 text-white mr-6"></i>
                            {lang("GENERAL.FILTER")}
                        </GroupButton>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="table-custom">
                        <div className='relative pb-1 pt-5 mb-3'>
                            <p className='fw-bold text-uppercase fs-1 text-center m-0'>Bảng ca làm việc</p>
                            <p className='fw-bold fs-4 text-center m-0'>{`Tháng ${formik.values.thang ?? "....."} năm ${formik.values.nam ?? "....."}`}</p>
                        </div>
                        <TableCustom
                            id="update-shift"
                            data={listShift}
                            columns={columns}
                            searchObject={formik.values}
                            setSearchObject={formik.handleChange}
                            buttonAdd={false}
                            notDelete={false}
                            fixedColumnsCount={4}
                            noPagination={true}
                            justFilter={true}
                            clearToolbar={true}
                        />
                    </Col>
                </Row>
            </Form>
            {
                openForm &&
                <UpdateWorkShiftsForm
                    open={openForm}
                    handleCloseAndSearch={handleCloseAndSearch}
                    handleClose={handleClose}
                    isView={isView}
                    item={itemShift}

                />
            }
        </div>
    )
}

export { UpdateWorkShifts };
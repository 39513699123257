import { Col, Row } from "react-bootstrap";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import Autocomplete from "../../components/input/autocomplete";
import { getFullYear } from "../../utils/functionUtils";
import { VARIABLE_STRING } from "../../constants/moduleConsts";
import { QUARTER_LIST } from "../../report/constants/reportConsts";
import { IPropsSearchExportWord } from "../../components/exportWord";

export function QuarterSearch({ values, setValues }: IPropsSearchExportWord) {
  const { lang } = useMultiLanguage();

  const handleChangeSelect = (name: string, value: any) => {
    let tuThang = null, denThang = null, nam = values?.nam;
    switch (name) {
      case VARIABLE_STRING.QUY:
        tuThang = values?.year?.name && value ? values?.year?.name + value?.startDate : null;
        denThang = values?.year?.name && value ? values?.year?.name + value?.endDate : null;
        break;
      case VARIABLE_STRING.YEAR:
        tuThang = value?.name && values?.quy ? value?.name + values?.quy?.startDate : null;
        denThang = value?.name && values?.quy ? value?.name + values?.quy?.endDate : null;
        nam = value?.name;
        break;
    };
    setValues({ ...values, tuThang, denThang, nam, [name]: value });
  };

  return (
    <Row>
      <Col xs={2}>
        <Autocomplete
          lable={lang("GENERAL.YEAR")}
          options={getFullYear(5, 0)}
          value={values?.year || null}
          name="year"
          onChange={(value) => handleChangeSelect(VARIABLE_STRING.YEAR, value)}
        />
      </Col>
      <Col xs={2}>
        <Autocomplete
          lable={lang("GENERAL.QUARTER")}
          options={QUARTER_LIST}
          value={values?.quy || null}
          name="quy"
          onChange={(value) => handleChangeSelect(VARIABLE_STRING.QUY, value)}
        />
      </Col>
    </Row>
  )
}
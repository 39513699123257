import { useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Col, Form, Row } from "react-bootstrap";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import Autocomplete from "../../components/input/autocomplete";
import TextValidator from "../../components/input/text-validator";
import {
  AFFECT_SALARY,
  DISCIPLINE_AFFECT_SALARY_STATUS_CODE,
  INIT_DISCIPLINE_INFO,
  LIST_CHANGE_POSITION,
  TYPE_FORM_DISCIPLINE,
  decisionLevels,
  disciplineFormOfs,
  disciplineStatus,
  disciplineType
} from "../constants/disciplineConsts";
import { GroupButton } from "../../components/GroupButton";
import { RangeDatePicker } from "../../components/input/RangeDatePicker";
import { addNewDiscipline, getKyLuatEmployeeList, updateDiscipline } from "../services/disciplineServices";
import { MISSION_STATUS_CODE, RESPONSE_STATUS_CODE, VARIABLE_STRING, TYPE_CATEGORY } from "../../constants/moduleConsts";
import { convertDataSubmit, convertDataUI } from "../utils/disciplineUtils";
import { getEmployeeProfileById } from "../../profile/services/profileServices";
import { IFile } from "../../profile/models/dialogModels";
import UploadFile from "../../components/file-upload/UploadFile";
import AppContext from "../../../AppContext";
import { AddNewRepresentative, SelectEmployeeDialog } from "../../components/dialogs";
import TableCustom, { columnNamesType } from "../../components/table-custom/TableCustom";
import { addMoreMonth, checkObject, handleBlurDate, hasAuthority, justAllowNumber } from "../../utils/functionUtils";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../../constants";
import { localStorageItem } from "../../utils/localstorage";
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";
import DisciplineResignDialog from "./DisciplineResignDialog";
import { ResignInfo } from "../../resign/models/resignModels";
import { RESIGN_TYPE, listResignType } from "../../resign/constants/resignConsts";
import { IPeopleDecision, OptionReactSelect } from "../../models/models";
import { searchAllSimpleValue, searchListWorkUnit, searchNguoiDaiDienKy } from "../../services/services";
import { DisciplineInfo } from "../models/disciplineModels";

interface IProps {
  handleClose: () => void;
  handleCloseUpdateDialog: () => void;
  handleCloseAndSearch: () => void;
  isView?: boolean;
  disciplineEdit: any;
  setIsView: (data: any) => void;
}

function DisciplineDialog(props: IProps) {
  const { lang } = useMultiLanguage();
  const {
    handleClose,
    isView = false,
    disciplineEdit,
    setIsView,
    handleCloseAndSearch,
    handleCloseUpdateDialog
  } = props;
  const { setPageLoading } = useContext(AppContext);

  const [shouldOpenAddEmployeeDialog, setShouldOpenAddEmployeeDialog] = useState<boolean>(false);
  const [employeeSelected, setEmployeeSelected] = useState<any[]>([]);
  const [isViewOnly, setIsViewOnly] = useState<boolean>(false);
  const [showDialogResign, setShowDialogResign] = useState<boolean>(false);
  const [isShowBtnSave, setIsShowBtnSave] = useState<boolean>(true);
  const [isShowAcceptDisciplineBtn, setIsShowAcceptDisciplineBtn] = useState<boolean>(false);
  const [isAcceptDiscipline, setIsAcceptDiscipline] = useState<boolean>(disciplineEdit.xacNhanKyLuat || false);
  const [resignInfo, setResignInfo] = useState(disciplineEdit?.thongTinNghiViec ?? {} as ResignInfo);
  const [changePosition, setChangePosition] = useState<boolean>(LIST_CHANGE_POSITION.includes(disciplineEdit?.hinhThucKyLuat?.code) || false);
  const [isShowDialogAddRepresentative, setIsShowDialogAddRepresentative] = useState<boolean>(false);
  const [peopleDecisions, setPeopleDecisions] = useState<IPeopleDecision[]>([]);

  useEffect(() => {
    if (disciplineEdit?.id) {
      formik.setValues(convertDataUI(disciplineEdit));
      setIsViewOnly(disciplineEdit?.trangThai?.code === MISSION_STATUS_CODE.COMPLETED);
      getEmployeeSelected();
    } else {
      formik.setFieldValue(VARIABLE_STRING.TRANG_THAI, disciplineStatus[0]);
    }
    setIsShowAcceptDisciplineBtn(!disciplineEdit?.xacNhanKyLuat)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disciplineEdit]);

  const getEmployeeSelected = async () => {
    try {
      setPageLoading(true);
      const { data } = await getKyLuatEmployeeList(String(disciplineEdit?.id));
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setEmployeeSelected(data?.data[0]?.listEmployee || []);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleOpenSelectEmployeeDialog = () => {
    setShouldOpenAddEmployeeDialog(true);
  };

  const validationSchema = Yup.object().shape({
    loaiKyLuat: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    capQuyetDinh: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    trangThai: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    hanhVi: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    hinhThucKyLuat: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    coQuanQuyetDinh: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    soQuyetDinh: Yup.string().nullable(),
    tuNgay: Yup.date().required(lang("VALIDATION.REQUIRE")).nullable(),
    denNgay: Yup.date()
      .min(Yup.ref("tuNgay"), lang("VALIDATION.NOT_SMALLER_FROM_DAY"))
      .nullable(),
    thoiGianKeoDaiDieuChinhLuong: Yup.number()
      .when("ahtgDieuChinhLuong", {
        is: (ahtgDieuChinhLuong: boolean) => ahtgDieuChinhLuong,
        then: Yup.number().required(lang("VALIDATION.REQUIRE")).nullable(),
      })
      .nullable(),
    chucVuMoi: changePosition ? Yup.object().required(lang("VALIDATION.REQUIRE")).nullable() : Yup.string().nullable(),
    nguoiKyQuyetDinh: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable()
  });

  const handleSubmit = async (values: DisciplineInfo) => {
    if (!(employeeSelected?.length > 0)) return toast.warning(lang("TOAST.SELECT_LIST_DISCIPLINE"));
    if(values.hinhThucKyLuat?.code === TYPE_FORM_DISCIPLINE.BUOC_THOI_VIEC && !values.thongTinNghiViec) {
      return toast.warning(lang("TOAST.FILL_RESIGN_INFO"));
    }
    try {
      setPageLoading(true);
      const _values = {
        ...values,
        xacNhanKyLuat: isAcceptDiscipline
      };
      const dataSubmit = convertDataSubmit(_values, employeeSelected);
      const { data } = disciplineEdit?.id
        ? await updateDiscipline(disciplineEdit.id, dataSubmit)
        : await addNewDiscipline(dataSubmit);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        let message = disciplineEdit?.id ? "TOAST.EDIT.SUCCESS" : "TOAST.CREATE.SUCCESS";
        toast.success(lang(message));
        handleCloseAndSearch();
      } else {
        toast.warning(data?.message);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: INIT_DISCIPLINE_INFO,
    onSubmit: handleSubmit,
    validationSchema
  });

  useEffect(() => {
    setChangePosition(LIST_CHANGE_POSITION.includes(Number(formik.values?.hinhThucKyLuat?.code)));
  }, [formik.values?.hinhThucKyLuat?.code]);

  const handleChangeSelect = (name: string, value: any) => {
    switch (name) {
      case VARIABLE_STRING.HINH_THUC_KY_LUAT:
        const affectSalary = DISCIPLINE_AFFECT_SALARY_STATUS_CODE.find(status => status.code === value?.code);
        formik.setValues({
          ...formik.values,
          [name]: value,
          chucVuMoi: null,
          ahtgDieuChinhLuong: Boolean(affectSalary),
          thoiGianKeoDaiDieuChinhLuong: affectSalary ? affectSalary.value : null
        });
        break;
      default:
        formik.setFieldValue(name, value);
    }
  };

  const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    if (name === VARIABLE_STRING.ANH_HUONG_THOI_GIAN_DIEU_CHINH_LUONG) {
      formik.setValues({
        ...formik.values,
        [name]: checked,
        thoiGianKeoDaiDieuChinhLuong: null
      });
    } else {
      formik.setFieldValue(name, checked);
    }
  };

  const columns: columnNamesType[] = [
    {
      name: lang("TABLE.INDEX"),
      field: "index",
      headerStyle: {
        minWidth: 50
      },
      cellStyle: {
        textAlign: "center"
      },
      render: (row: any, index: number) => <span>{index + 1}</span>
    },
    {
      name: lang("INPUT.CODE"),
      field: "maNhanVien",
      headerStyle: {
        minWidth: 80
      },
      cellStyle: {
        textAlign: "center"
      },
      render: (row: any) => <span className="color-steel-blue fw-600">{row?.maNhanVien}</span>
    },
    {
      name: lang("INPUT.FULLNAME"),
      field: "name",
      headerStyle: {
        minWidth: 200
      }
    },
    {
      name: lang("PROFILE.ROLE"),
      field: "chucVuText",
      headerStyle: {
        minWidth: 250
      }
    },
    {
      name: lang("GENERAL.EMPLOYEE.TITLE"),
      field: "viTriCongViecText",
      headerStyle: {
        minWidth: 150
      }
    }
  ];

  const handleSetFile = (data: IFile) => {
    formik.setFieldValue("fileId", data.id);
    formik.setFieldValue("fileName", data.name);
  };

  const handleSaveEmployeeSingle = (employee: any) => {
    setEmployeeSelected([{ ...employee[0], isChecked: false }]);
  };

  const handleChangePositiveNumber = (event: React.ChangeEvent<any>) => {
    if (!event.target?.value?.startsWith("0")) {
      formik.handleChange(event);
    }
  };

  useEffect(() => {
    const tuNgay = formik.values.tuNgay;
    const thoiGianKeoDai = formik.values.thoiGianKeoDaiDieuChinhLuong;
    if (tuNgay && thoiGianKeoDai) {
      formik.setFieldValue("denNgay", addMoreMonth(tuNgay, Number(thoiGianKeoDai)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.tuNgay,
    formik.values.hinhThucKyLuat,
    formik.values.thoiGianKeoDaiDieuChinhLuong
  ]);

  const getEmployeeInfoByRoleUser = async () => {
    try {
      const accountEmployeeId = localStorageItem.get(KEY_LOCALSTORAGE.ACCOUNT_EMPLOYEE_ID);
      const { data } = await getEmployeeProfileById(accountEmployeeId);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        formik.setFieldValue(VARIABLE_STRING.CO_QUAN_QUYET_DINH, {
          id: data?.data?.donViCongTacId || "",
          name: data?.data?.donViCongTacText || "",
        });
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }

  const handleChangeDate = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value);
    if (name === VARIABLE_STRING.TU_NGAY) {
      const effectiveDate = value ? new Date(value) : null;
      const currentDate = new Date();
      if (effectiveDate && effectiveDate <= currentDate) {
        setIsShowBtnSave(false);
      } else setIsShowBtnSave(true);
    }
  }

  useEffect(() => {
    if (hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW) && !disciplineEdit?.id) {
      getEmployeeInfoByRoleUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleShowResignDialog = async () => {
    if (employeeSelected?.length === 0) return toast.warning(lang("TOAST.SELECT_LIST_DISCIPLINE"));
    if(!checkObject(await formik.validateForm())) return toast.warning(lang("TOAST.PLEASE_FILL_DISCIPLINE_FORM"));
    setShowDialogResign(true);
    const _resign: ResignInfo = {
      ...resignInfo,
      employee: employeeSelected?.[0],
      employeeId: employeeSelected?.[0]?.employeeId,
      nguoiKy: formik.values?.nguoiKyQuyetDinh,
      nguoiKyId: formik.values?.nguoiKyQuyetDinh?.id,
      nguoiKyText: formik.values?.nguoiKyQuyetDinh?.name,
      ngayHieuLuc: formik.values?.tuNgay,
      ngayQuyetDinh: formik.values?.ngayQuyetDinh,
      soQuyetDinh: formik.values?.soQuyetDinh,
      loaiThoiViec: listResignType.find((item: OptionReactSelect) => item.code === RESIGN_TYPE.KY_LUAT_BUOC_THOI_VIEC),
      raQuyetDinh: formik.values?.xacNhanKyLuat
    }
    setResignInfo(formik.values?.thongTinNghiViec ?? _resign);
  }

  const handleSaveResign = (values: ResignInfo) => {
    setResignInfo(values);
    formik.setFieldValue("thongTinNghiViec", values);
    handleCloseResignDialog();
  }

  const handleCloseResignDialog = () => {
    setShowDialogResign(false);
  }

  const handleAcceptDiscipline = async () => {
    if (checkObject(await formik.validateForm())) {
      setIsAcceptDiscipline(true);
    }
  }

  const getPeopleDecision = useCallback(async () => {
    try {
      const { data } = await searchNguoiDaiDienKy();
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setPeopleDecisions(data?.data?.content || [])
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }, [lang]);

  useEffect(() => {
    getPeopleDecision();
  }, [getPeopleDecision]);

  useEffect(() => {
    if(!disciplineEdit?.id && peopleDecisions.length > 0) {
      formik.setFieldValue(VARIABLE_STRING.NGUOI_KY_QUYET_DINH, peopleDecisions.find(people => people.isDefault) || null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [peopleDecisions]);

  const handleCloseAndSearchRepresentative = () => {
    getPeopleDecision();
    setIsShowDialogAddRepresentative(false);
  }

  return (
    <div className="discipline-dialog spaces overflow-y-auto overflow-x-hidden bg-transparent">
      <Form
        onSubmit={formik.handleSubmit}
        className="flex-column min-h-full"
      >
        <div className="header-form-action">
          <GroupButton type="btn-back" handleClose={handleClose}/>
          <div className="flex gap-4">
            {(!isView && disciplineEdit?.trangThai?.code === MISSION_STATUS_CODE.UNFULFILLED && disciplineEdit?.id) && (
              <GroupButton type="btn-cancel" handleCloseUpdateDialog={handleCloseUpdateDialog} />
            )}
            {isView && disciplineEdit?.id && disciplineEdit?.trangThai?.code !== MISSION_STATUS_CODE.COMPLETED 
              && !disciplineEdit.xacNhanBoNhiem && hasAuthority(PERMISSIONS.KY_LUAT_MENU, PERMISSION_ABILITY.UPDATE) && (
              <GroupButton type="btn-edit" handleSaveEdit={() => setIsView(false)} />
            )}
            {(!isView && isShowBtnSave && (disciplineEdit?.trangThai?.code !== MISSION_STATUS_CODE.COMPLETED || !disciplineEdit?.id)) && (
              <GroupButton type="btn-save" handleSubmit={() => setIsAcceptDiscipline(false)} />
            )}
            {isShowAcceptDisciplineBtn && (
              <GroupButton typeButton="submit" handleEvent={handleAcceptDiscipline}>{lang("DISCIPLINE.ACCEPT")}</GroupButton>
            )}
          </div>
        </div>
        <div className="form-content-scroll">
          <Row className="gap-col-modal z-index-2 relative">
            <Col xs={12} className="sub-title-form">{disciplineEdit?.id ? lang("DISCIPLINE.TITLE.EDIT") : lang("DISCIPLINE.TITLE.ADD")}</Col>
            <Col xs={6} md={4} lg={3} xxl={2}>
              <Autocomplete
                isRequired
                name="loaiKyLuat"
                lable={lang("DISCIPLINE.TYPE")}
                isReadOnly={isView}
                value={formik.values?.loaiKyLuat || null}
                onChange={(value) => handleChangeSelect(VARIABLE_STRING.LOAI_KY_LUAT, value)}
                options={disciplineType}
                errors={formik.errors?.loaiKyLuat}
                touched={formik.touched?.loaiKyLuat}
              />
            </Col>
            <Col xs={6} md={4} lg={3} xxl={2}>
              <Autocomplete
                isRequired
                name="capQuyetDinh"
                lable={lang("DISCIPLINE.DECISION_LEVEL")}
                isReadOnly={isView}
                value={formik.values?.capQuyetDinh || null}
                onChange={(value) => handleChangeSelect(VARIABLE_STRING.CAP_QUYET_DINH, value)}
                options={decisionLevels}
                errors={formik.errors?.capQuyetDinh}
                touched={formik.touched?.capQuyetDinh}
              />
            </Col>
            <Col xs={6} md={4} lg={3} xxl={2}>
              <TextValidator
                isRequired
                name="hanhVi"
                lable={lang("DISCIPLINE.VIOLATION_BEHAVIOR")}
                readOnly={isView}
                type="text"
                value={formik.values?.hanhVi || ""}
                onChange={formik.handleChange}
                errors={formik.errors?.hanhVi}
                touched={formik.touched?.hanhVi}
              />
            </Col>
            <Col xs={6} md={4} lg={3} xxl={2}>
              <Autocomplete
                isRequired
                name="hinhThucKyLuat"
                lable={lang("DISCIPLINE.FORM_OF")}
                isReadOnly={isView}
                value={formik.values?.hinhThucKyLuat || null}
                onChange={(value) => handleChangeSelect(VARIABLE_STRING.HINH_THUC_KY_LUAT, value)}
                options={disciplineFormOfs}
                errors={formik.errors?.hinhThucKyLuat}
                touched={formik.touched?.hinhThucKyLuat}
              />
            </Col>
            {changePosition &&
              <Col xs={6} md={4} lg={3} xxl={2}>
                <Autocomplete
                  lable={lang("INPUT_APPOINTMENT_NEW_JOB_POSITION_NEW")}
                  isReadOnly={isView}
                  searchFunction={searchAllSimpleValue}
                  searchObject={{ type: TYPE_CATEGORY.chucDanh }}
                  options={[]}
                  isRequired
                  value={formik.values?.chucVuMoi}
                  name={VARIABLE_STRING.CHUC_VU_MOI}
                  onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.CHUC_VU_MOI, selectedOption)}
                  errors={formik.errors?.chucVuMoi}
                  touched={formik.touched?.chucVuMoi}
                  getOptionLabel={(option) => option?.value}
                  />
              </Col>
            }
            <Col xs={6} md={4} lg={3} xxl={2}>
              <TextValidator
                name="soQuyetDinh"
                lable={lang("INPUT.WORKPROCESS.DECISION.NUMBER")}
                readOnly={isView}
                type="text"
                value={formik.values?.soQuyetDinh || ""}
                onChange={formik.handleChange}
                errors={formik.errors?.soQuyetDinh}
                touched={formik.touched?.soQuyetDinh}
              />
            </Col>
            <Col xs={6} md={4} lg={3} xxl={2}>
              <TextValidator
                name="ngayQuyetDinh"
                lable={lang("INPUT_APPOINTMENT_CONFIRM_DATE")}
                type="date"
                value={formik.values?.ngayQuyetDinh}
                readOnly={isView}
                onChange={formik.handleChange}
                onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.ngayQuyetDinh, "ngayQuyetDinh")}
                errors={formik?.errors?.ngayQuyetDinh}
                touched={formik?.touched?.ngayQuyetDinh}
              />
            </Col>
            <Col xs={6} md={4} lg={3} xxl={2}>
              <Autocomplete
                isRequired
                name="coQuanQuyetDinh"
                lable={lang("DISCIPLINE.ISSUING_AGENCY_UNIT")}
                isReadOnly={isView || hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW)}
                value={formik.values?.coQuanQuyetDinh || null}
                onChange={(value) => handleChangeSelect(VARIABLE_STRING.CO_QUAN_QUYET_DINH, value)}
                options={[]}
                searchFunction={searchListWorkUnit}
                errors={formik.errors?.coQuanQuyetDinh}
                touched={formik.touched?.coQuanQuyetDinh}
              />
            </Col>
            <Col xs={6} md={4} lg={3} xxl={2}>
              <Autocomplete
                isRequired
                name="trangThai"
                lable={lang("GENERAL.STATUS")}
                isReadOnly={isViewOnly ? isViewOnly : isView}
                value={formik.values?.trangThai || null}
                onChange={(value) => handleChangeSelect(VARIABLE_STRING.TRANG_THAI, value)}
                options={disciplineStatus}
                errors={formik.errors?.trangThai}
                touched={formik.touched?.trangThai}
              />
            </Col>
            <Col xs={6} md={4} lg={3} xxl={2}>
              <Autocomplete
                name="nguoiKyQuyetDinh"
                lable={lang("APPRECIATIVE.PERSON_SINGING")}
                isReadOnly={isView}
                isRequired
                value={formik.values?.nguoiKyQuyetDinh || null}
                onChange={(value) => handleChangeSelect(VARIABLE_STRING.NGUOI_KY_QUYET_DINH, value)}
                options={peopleDecisions}
                isAddNew
                onAddNew={() => setIsShowDialogAddRepresentative(true)}
                errors={formik.errors?.nguoiKyQuyetDinh}
                touched={formik.touched?.nguoiKyQuyetDinh}
              />
            </Col>
            <Col xs={6} md={4} lg={3} xxl={4} className="range-date-gap-sm">
              <RangeDatePicker
                isRequired
                label={lang("GENERAL.TIME")}
                startDateName="tuNgay"
                endDateName="denNgay"
                isView={isView}
                isReadOnlyEndDate={formik.values?.ahtgDieuChinhLuong}
                handleChange={handleChangeDate}
                value={formik.values}
                setFieldValue={formik.setFieldValue}
                touch={formik.touched}
                errors={formik.errors}
              />
            </Col>
            <Col xs={6} md={4} lg={3} xxl={2}>
              <UploadFile
                label="CONTRACT.ATTACHMENTS"
                setValue={handleSetFile}
                fileValue={{
                  id: formik.values.fileId || "",
                  name: formik.values.fileName || ""
                }}
                isReadOnly={isView}
              />
            </Col>
            <Col xs={6} md={4} lg={2} xxl={2}>
              <Form.Check
                label={lang("DISCIPLINE.MOST")}
                type="checkbox"
                className="checkBox custom-form-check"
                name="kyLuatCaoNhat"
                checked={formik.values?.kyLuatCaoNhat}
                onChange={handleChangeCheckBox}
                disabled={isView}
              />
            </Col>
            <Col xs={6} md={4} lg={4} xxl={3}>
              <Form.Check
                label={lang("APPRECIATIVE.SALARY_AUJUSTMENT_TIME")}
                type="checkbox"
                className="checkBox custom-form-check"
                name="ahtgDieuChinhLuong"
                checked={formik.values?.ahtgDieuChinhLuong}
                onChange={handleChangeCheckBox}
                disabled={isView || formik.values?.hinhThucKyLuat?.code !== AFFECT_SALARY.KHAC}
              />
            </Col>
            <Col xs={6} md={4} lg={4} xxl={3}>
              <TextValidator
                isRequired={formik.values?.ahtgDieuChinhLuong}
                name="thoiGianKeoDaiDieuChinhLuong"
                lable={lang("APPRECIATIVE.SALARY_AUJUSTMENT_EXTENDED_TIME")}
                readOnly={isView || !formik.values?.ahtgDieuChinhLuong}
                type="number"
                value={formik.values?.thoiGianKeoDaiDieuChinhLuong || ""}
                onChange={handleChangePositiveNumber}
                onKeyDown={justAllowNumber}
                errors={formik.errors?.thoiGianKeoDaiDieuChinhLuong}
                touched={formik.touched?.thoiGianKeoDaiDieuChinhLuong}
              />
            </Col>
            {formik.values?.hinhThucKyLuat?.code === TYPE_FORM_DISCIPLINE.BUOC_THOI_VIEC && 
              <Col xs={4} md={3} xxl={2} className="spaces mt-21">
                <GroupButton handleEvent={handleShowResignDialog}>{lang("DISCIPLINE.RESIGN_INFO")}</GroupButton>
              </Col>
            }
            <Col xs={12}>
              <TextValidator
                name="ghiChu"
                lable={lang("INPUT.WORKPROCESS.NOTE")}
                readOnly={isView}
                value={formik.values?.ghiChu || ""}
                onChange={formik.handleChange}
                as="textarea"
              />
            </Col>
          </Row>
          <Row className="flex-1 flex-column flex-between">
            <Col
              xs={12}
              className="spaces mt-24"
            >
              <TableCustom
                title={lang("DISCIPLINE.EMPLOYEE")}
                columns={columns}
                data={employeeSelected}
                handleOpenDialog={handleOpenSelectEmployeeDialog}
                buttonAdd={!isView}
                notDelete
                noPagination
                noToolbar
                isActionTableTab
                uniquePrefix="employeeId"
              />
            </Col>

            {shouldOpenAddEmployeeDialog && (
              <SelectEmployeeDialog
                open={shouldOpenAddEmployeeDialog}
                handleClose={() => setShouldOpenAddEmployeeDialog(false)}
                handleSaveData={handleSaveEmployeeSingle}
                dataParent={employeeSelected}
              />
            )}
          </Row>
        </div>
      </Form>
      {showDialogResign && 
        <DisciplineResignDialog 
          open={showDialogResign}
          handleSubmit={handleSaveResign}
          handleClose={handleCloseResignDialog}
          resignInfo={resignInfo}
          isView={isView}
        />
      }
      {isShowDialogAddRepresentative && (
        <AddNewRepresentative
          handleClose={() => setIsShowDialogAddRepresentative(false)}
          handleCloseAndSearch={handleCloseAndSearchRepresentative}
        />
      )}
    </div>
  );
}

export default DisciplineDialog;

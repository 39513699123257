import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";
import { OptionReactSelect } from "../../models/models";
import { EmployeeProfile } from "../../profile/models/profileModels";
import { generateRandomId } from "../../utils/functionUtils";
import { localStorageItem } from "../../utils/localstorage";
import { TYPE_OBJECT_SCHEDULE } from "../constants/shiftComprehensionConsts";
import { IEmployeeSchedule, IRowDataSchedule, IShiftDivision, IWorkDay } from "../models/shiftDivisionModels";

export const convertDataShiftSchedule = (dataSchedule: IWorkDay[]) => {
	const dataConverted: IRowDataSchedule[] = [];

	dataSchedule.forEach(day => {
		day.employeeInfoList?.forEach((employee, index) => {
			if (!dataConverted[employee.thuTuTruc ?? index]) {
				dataConverted[employee.thuTuTruc ?? index] = {
					id: generateRandomId(),
					phongBan: employee.phongBanId ? {
						id: employee.phongBanId,
						name: employee.phongBanText
					} : null,
					loaiTruc: employee.loaiTrucId ? {
						id: employee.loaiTrucId,
						value: employee.loaiTrucText
					} : null,
					caLamViec: employee.caLamViecId ? {
						id: employee.caLamViecId,
						tenCa: employee.caLamViecText
					} : null,
					addNewRow: Boolean(employee.loaiTrucId && employee.caLamViecId)
				};
			}
			dataConverted[employee.thuTuTruc ?? index][day.ngayLamViec] = employee.employeeId ? {
				id: employee.employeeId,
				name: employee.employeeText
			} : null;
		});
	});

	return dataConverted.filter(item => item);
}

const uniqueDepartmentCount = (listSchedule: IRowDataSchedule[], phongBanId: string): number => {
  const uniqueDepartments: { [key: string]: boolean } = {};

  listSchedule.forEach(item => {
    if (item.phongBan && item.phongBan.id && item.phongBan.id !== phongBanId) {
      uniqueDepartments[item.phongBan.id] = true;
    }
  });

  return Object.keys(uniqueDepartments).length;
};

export const convertDataShiftComprehension = (listData: IShiftDivision[]) => {
	const dataConverted: IRowDataSchedule[] = [];

	listData.forEach((data, index) => {
		const listSchedule: IRowDataSchedule[] = [];
		data.bangPhanCaDetailDtoList?.forEach(day => {
			day.employeeInfoList?.forEach((employee, indexEmployee) => {
				if (!listSchedule[employee.thuTuTruc ?? indexEmployee]) {
					listSchedule[employee.thuTuTruc ?? indexEmployee] = {
						id: generateRandomId(),
						stt: data?.doiTuong?.code === TYPE_OBJECT_SCHEDULE.DIEU_DUONG 
							? (index + 1) : uniqueDepartmentCount(listSchedule, employee?.phongBanId ?? "") + 1,
						phongBanText: employee?.phongBanText ?? "",
						phongBan: employee?.phongBanId ? {
							id: employee?.phongBanId,
							name: employee?.phongBanText
						} : null,
						loaiTruc: employee.loaiTrucId ? {
							id: employee.loaiTrucId,
							value: employee.loaiTrucText
						} : null,
						caLamViec: employee.caLamViecId ? {
							id: employee.caLamViecId,
							tenCa: employee.caLamViecText
						} : null
					};
				}
				listSchedule[employee.thuTuTruc ?? indexEmployee][day.ngayLamViec] = employee.employeeId ? {
					id: employee.employeeId,
					name: employee.employeeText
				} : null;
			});
		});
		dataConverted.push(...listSchedule);
	});

	return dataConverted.filter(item => item);
}

const convertToShortName = (fullName: string) => {
		const nameParts = fullName.trim().split(' ');
		const shortNameParts = nameParts.slice(0, -1).map(name => name.charAt(0) + '.');
		const lastName = nameParts[nameParts.length - 1];
		return shortNameParts.join('') + lastName;
	}

	export const convertNameEmployee = (listEmployee: EmployeeProfile[]): OptionReactSelect[] => {
		const newList: OptionReactSelect[] = [];
		listEmployee.forEach(employee => {
			return employee && employee.name && newList.push({
				id: employee.id,
				name: convertToShortName(employee.name)
			})
		});
		return newList;
	}

	export const convertDataShiftDivision = (shiftDivision: IShiftDivision) => {
		const { listSchedule, listCondition, nguoiPheDuyet, ...outputData } = {
			...shiftDivision,
			nguoiPheDuyetId: shiftDivision?.nguoiPheDuyet?.id ?? "",
			nguoiPheDuyetText: shiftDivision?.nguoiPheDuyet?.name ?? "",
			bangPhanCaDetailDtoList: [] as IWorkDay[],
			configPhanCaDtoList: shiftDivision?.listCondition?.filter(condition => condition.dieuKienDuoi || condition.dieuKienTren)
		};

		shiftDivision?.listSchedule?.forEach((item, index) => {
			const dateKeys = Object.keys(item).filter(key => key.match(/^\d{4}-\d{2}-\d{2}$/));
			dateKeys.forEach(date => {
				if(!item?.[date]) return;
				const employeeInfo: IEmployeeSchedule = {
					thuTuTruc: index,
					caLamViecId: item?.caLamViec?.id ?? "",
					caLamViecText: item?.caLamViec?.tenCa ?? "",
					employeeId: (item?.[date] as OptionReactSelect)?.id ?? "",
					employeeText: (item?.[date] as OptionReactSelect)?.name ?? "",
					loaiTrucId: item?.loaiTruc?.id ?? "",
					loaiTrucText: item?.loaiTruc?.value ? String(item?.loaiTruc?.value) : "",
					phongBanId: outputData?.doiTuong?.code === TYPE_OBJECT_SCHEDULE.DIEU_DUONG
						? localStorageItem.get(KEY_LOCALSTORAGE.EMPLOYEE)?.phongBanId : item?.phongBan?.id,
					phongBanText: outputData?.doiTuong?.code === TYPE_OBJECT_SCHEDULE.DIEU_DUONG
						? localStorageItem.get(KEY_LOCALSTORAGE.EMPLOYEE)?.phongBanText : item?.phongBan?.name
				};

				let workDay = outputData.bangPhanCaDetailDtoList.find((wd: IWorkDay) => wd.ngayLamViec === date);
				if (!workDay) {
					workDay = {
						bangPhanCaId: shiftDivision.id,
						ngayLamViec: date,
						employeeInfoList: []
					};
					outputData.bangPhanCaDetailDtoList.push(workDay);
				}

				workDay.employeeInfoList.push(employeeInfo);
			});
		});

		return outputData;
	}
import { FC } from "react";
import { Button, Modal } from "react-bootstrap";
import "./dialog-style.scss";
import { GroupButton } from "../GroupButton";

interface Props {
  show: boolean;
  onCloseClick?: () => void;
  onYesClick?: () => void;
  onCancelClick?: () => void;
  title?: string;
  message?: string;
  yes?: string;
  cancel?: string;
  close?: string;
  isView?: boolean;
  isWarning?: boolean;
}
  
const ConfirmDialog: FC<Props> = (props) => {
  const { show, onCloseClick, onYesClick, onCancelClick, title, message, yes, cancel, close, isView, isWarning } = props;

  return (
    <Modal
      show={show}
      onHide={onCloseClick}
      backdrop="static"
      centered
      animation
      aria-labelledby="example-custom-modal-styling-title"
      className="custom-modal confirm-dialog"
    >
      <Modal.Header className={`${isWarning ? "bg-warning" : "bg-primary"} p-4`}>
        <Modal.Title className="text-white text-uppercase">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex text-center px-4 py-3">
        <h4 className="spaces m-0">{message}</h4>
      </Modal.Body>

      <Modal.Footer className="flex-center py-4">
        {!isView && yes && <GroupButton typeButton="submit" handleEvent={onYesClick}>{yes}</GroupButton>}
        {cancel && (
          <Button
            variant="outline-secondary"
            className="button-secondary"
            onClick={onCancelClick}
          >
            {cancel}
          </Button>
        )}
        {close && <GroupButton handleEvent={onCloseClick}>{close}</GroupButton>}
      </Modal.Footer>
    </Modal>
  );
};

export { ConfirmDialog };

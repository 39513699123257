import TextValidator from "../../../components/input/text-validator";
import { Col, Form, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { INIT_MINOR_CHILDREN } from "../../constants/initValues";
import { IFormProps, IMinorChildren } from "../../models/declareAssetsModels";
import { useEffect } from "react";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import { formatDateTime } from "../../../utils/functionUtils";

export function MinorChildrenForm(props: IFormProps<IMinorChildren>) {
  const { handleSubmit, isView, btnSubmitRef, dataEdit } = props;
  const { lang } = useMultiLanguage();

  useEffect(() => {
    if (dataEdit?.id) {
      dataEdit.birthdate = formatDateTime(dataEdit.birthdate);
      dataEdit.soCmndCccdNgayCap = formatDateTime(dataEdit.soCmndCccdNgayCap);
      formik.setValues(dataEdit);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataEdit]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    birthdate: Yup.date()
      .required(lang("VALIDATION.REQUIRE"))
      .max(new Date(), lang("VALIDATON.DATE_CURRENT"))
      .nullable(),
    soCmndCccdNgayCap: Yup.date().max(new Date(), lang("VALIDATON.DATE_CURRENT")).nullable()
  });

  const formik = useFormik({
    initialValues: INIT_MINOR_CHILDREN,
    onSubmit: handleSubmit,
    validationSchema
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row>
        <Col xs={6} className="spaces mt-10">
          <TextValidator
            isRequired
            lable={lang("PROFILE.NAME")}
            name="name"
            readOnly={isView}
            type="text"
            value={formik.values?.name}
            onChange={formik.handleChange}
            errors={formik.errors?.name}
            touched={formik.touched?.name}
          />
        </Col>
        <Col xs={6} className="spaces mt-10">
          <TextValidator
            isRequired
            lable={lang("PROFILE.BIRTHDAY")}
            name="birthdate"
            readOnly={isView}
            type="date"
            value={formik.values?.birthdate}
            onChange={formik.handleChange}
            errors={formik.errors?.birthdate}
            touched={formik.touched?.birthdate}
          />
        </Col>
        <Col xs={4} className="spaces mt-10">
          <TextValidator
            lable={lang("INPUT.IDENTIFICATION")}
            name="soCmndCccd"
            readOnly={isView}
            type="text"
            value={formik.values?.soCmndCccd}
            onChange={formik.handleChange}
            errors={formik.errors?.soCmndCccd}
            touched={formik.touched?.soCmndCccd}
          />
        </Col>
        <Col xs={4} className="spaces mt-10">
          <TextValidator
            lable={lang("INPUT.DATERANGE")}
            name="soCmndCccdNgayCap"
            readOnly={isView}
            type="date"
            value={formik.values?.soCmndCccdNgayCap}
            onChange={formik.handleChange}
            errors={formik.errors?.soCmndCccdNgayCap}
            touched={formik.touched?.soCmndCccdNgayCap}
          />
        </Col>
        <Col xs={4} className="spaces mt-10">
          <TextValidator
            lable={lang("INPUT.PLACERANGE")}
            name="soCmndCccdNoiCap"
            readOnly={isView}
            type="text"
            value={formik.values?.soCmndCccdNoiCap}
            onChange={formik.handleChange}
            errors={formik.errors?.soCmndCccdNoiCap}
            touched={formik.touched?.soCmndCccdNoiCap}
          />
        </Col>
        <Col xs={12} className="spaces mt-10">
          <TextValidator
            lable={lang("DECLARE_ASSETS.PLACE_RESIDENCE")}
            name="noiThuongTru"
            readOnly={isView}
            type="text"
            value={formik.values?.noiThuongTru}
            onChange={formik.handleChange}
            errors={formik.errors?.noiThuongTru}
            touched={formik.touched?.noiThuongTru}
          />
        </Col>
      </Row>
      <button ref={btnSubmitRef} type="submit" className="hidden"></button>
    </Form>
  );
}

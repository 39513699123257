import { FC, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import useMultiLanguage from "../../hook/useMultiLanguage";
import { deleteBonus, getBonus } from "./services/bonusServices";
import { checkStatus, exportToFile, hasAuthority } from "../utils/functionUtils";
import { exportKhenThuong } from "../services/exportFileServices";
import TableCustom, { columnNamesType } from "../components/table-custom/TableCustom";
import {
  MISSION_STATUS,
  MISSION_STATUS_CODE,
  RESPONSE_STATUS_CODE,
  TYPE,
  INIT_SEARCH_OBJECT,
  EXPORT_FILE_NAME,
  INIT_INPUT_SEARCH
} from "../constants/moduleConsts";
import BonusDialog from "./components/BonusDialog";
import InputSearch from "../components/input/InputSearch";
import { Col, Row } from "react-bootstrap";
import { ListSearch } from "./constants/bonusConsts";
import AppContext from "../../AppContext";
import AdvancedSearch from "../profile/components/dialogs/AdvancedSearch";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../constants";
import { convertSearch } from "../utils/functionUtils";
import { GroupButton } from "../components/GroupButton";
import { BonusInfo } from "./models/bonusModels";

const Bonus: FC = () => {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [bonusEdit, setBonusEdit] = useState<BonusInfo>({} as BonusInfo);
  const [dataChecked, setDataChecked] = useState<BonusInfo[]>([]);
  const [listBonus, setListBonus] = useState<BonusInfo[]>([]);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [shouldOpenBonusDialog, setShouldOpenBonusDialog] = useState<boolean>(false);
  const [isView, setIsView] = useState<boolean>(false);
  const [isAdvancedSearch, setIsAdvancedSearch] = useState<boolean>(false);
  const [listInputSearch, setListInputSearch] = useState<any[]>([INIT_INPUT_SEARCH]);
  const [searchObject, setSearchObject] = useState<any>(INIT_SEARCH_OBJECT)

  useEffect(() => {
    if (searchObject?.pageIndex && searchObject?.pageSize) {
      handleSearch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchObject?.pageIndex, searchObject?.pageSize])

  const handleCloseAndSearch = () => {
    handleSearch();
    handleCloseDialog();
  }

  const handleCloseDialog = () => {
    setShouldOpenBonusDialog(false);
    getAllBonus();
    setBonusEdit({} as BonusInfo);
  };

  const handleOpenEditDialog = (row: any) => {
    setShouldOpenBonusDialog(true);
    setBonusEdit(row);
    setIsView(true);
  };

  const handleOpenDialog = () => {
    setShouldOpenBonusDialog(true);
    setIsView(false);
  };

  const getAllBonus = async (searchData?: any) => {
    try {
      setSearchObject({ ...searchData });
      setPageLoading(true);
      const { data } = await getBonus(searchData);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListBonus(data?.data?.content || []);
        setTotalPages(data?.data?.pageable?.totalPages || 0);
        setTotalElements(data?.data?.pageable?.totalElements || 0);
        setNumberOfElements(data?.data?.pageable?.numberOfElements || 0);
        setIsAdvancedSearch(false);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleSearch = (data: any = {}) => {
    const dataSearch: any = {
      ...searchObject,
      ...data
    }

    dataSearch.keyword = dataSearch?.keyword?.trim() || ""
    return getAllBonus(dataSearch);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchObject({
      ...searchObject,
      keyword: e.target?.value
    })
  }

  const handleDelete = async (ids: any) => {
    try {
      const { data } = await deleteBonus(ids);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("TOAST.DELETE.BONUS.SUCCESS"));
        getAllBonus(searchObject);
        return true;
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const handleAdvancedSearch = (data: any[]) => {
    setListInputSearch(data);

    handleSearch({ ...convertSearch(data), pageIndex: 1 });
  };

  const columns: columnNamesType[] = [
    {
      name: lang("TABLE.INDEX"),
      field: "stt",
      headerStyle: {
        minWidth: "30px"
      },
      render: (row: any, index: number, STT: number) => <span>{STT}</span>
    },
    {
      name: lang("GENERAL.STATUS"),
      field: "trangThai",
      headerStyle: {
        minWidth: "150px"
      },
      render: (row: any) => (
        <span className={`${checkStatus(MISSION_STATUS, row?.trangThai?.code)} status`}>
          {row?.trangThai?.name}
        </span>
      )
    },
    {
      name: lang("APPRECIATIVE.NAME"),
      field: "dotKhenThuong",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("APPRECIATIVE.YEAR"),
      field: "namKhenThuong",
      headerStyle: {
        minWidth: "150px"
      }
    },
    {
      name: lang("APPRECIATIVE.LOAIKHENTHUONGTEXT"),
      field: "loaiKhenThuong",
      headerStyle: {
        minWidth: "150px"
      },
      cellStyle: {
        textAlign: "left"
      },
      render: (row) => <span>{row?.loaiKhenThuong?.name}</span>
    },
    {
      name: lang("APPRECIATIVE.DOITUONGKHENTHUONGTEXT"),
      field: "doiTuongKhenThuong",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row) => <span>{row?.doiTuongKhenThuong?.name}</span>
    },
    {
      name: lang("APPRECIATIVE.HINHTHUCKHENTHUONGTEXT"),
      field: "hinhThucKhenThuongText",
      headerStyle: {
        minWidth: "400px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("APPRECIATIVE.PERSON_SINGING"),
      field: "nguoiKyQuyetDinhText",
      headerStyle: {
        minWidth: "200px"
      }
    },
    {
      name: lang("APPRECIATIVE.SOQUYETDINH"),
      field: "soQuyetDinh",
      headerStyle: {
        minWidth: "200px"
      }
    },
    {
      name: lang("APPRECIATIVE.AGENCIES_UNIT_DECITION"),
      field: "donViBanHanhText",
      headerStyle: {
        minWidth: "280px"
      },
      cellStyle: {
        textAlign: "left"
      }
    }
  ];

  return (
    <div className="spaces px-8 py-12 flex-1">
      {!shouldOpenBonusDialog ? (
        <>
          <Row className="spaces mb-12 flex-middle">
            <Col xs={5} xxl={7} className="spaces p-0">
              <h2 className="breadcrumb-title">{lang("GENERAL.APPRECIATIVE.LIST")}</h2>
            </Col>
            <Col xs={7} xxl={5} className="flex">
              <div className="spaces mr-10 flex-1">
                <InputSearch
                  className="border-3px"
                  value={searchObject?.keyword || ""}
                  placeholder="Tìm kiếm theo đợt khen thưởng, năm, hình thức khen thưởng"
                  handleSearch={() => handleSearch({ pageIndex: 1 })}
                  handleChange={handleChange}
                  isEnter
                />
              </div>
              <GroupButton className="spaces mr-10" handleEvent={() => handleSearch({ pageIndex: 1 })}>
                {lang("BTN.SEARCH")}
              </GroupButton>
              <GroupButton className="spaces mr-10" outline handleEvent={() => setIsAdvancedSearch(true)}>
                {lang("BTN.ADVANCE_SEARCH")}
              </GroupButton>
              {isAdvancedSearch && (
                <AdvancedSearch
                  open={isAdvancedSearch}
                  listInputSearch={listInputSearch}
                  handleSearch={handleAdvancedSearch}
                  listOption={ListSearch(lang)}
                  handleClose={() => setIsAdvancedSearch(false)}
                />
              )}
            </Col>
          </Row>
          <Row className="bg-white">
            <Col xs={12} className="table-scroll">
              <TableCustom
                id="bonus-list"
                data={listBonus}
                columns={columns}
                searchObject={searchObject}
                setSearchObject={setSearchObject}
                type={TYPE.MULTILINE}
                handleOpenDialog={handleOpenDialog}
                buttonAdd={hasAuthority(PERMISSIONS.KHEN_THUONG_MENU, PERMISSION_ABILITY.CREATE)}
                notDelete={!hasAuthority(PERMISSIONS.KHEN_THUONG_MENU, PERMISSION_ABILITY.DELETE)}
                buttonExportExcel={!hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW)}
                handleExportExcel={(ids: string[]) =>
                  exportToFile({
                    exportAPI: () => exportKhenThuong({ ids, ...searchObject }),
                    fileName: EXPORT_FILE_NAME.KHEN_THUONG,
                    setPageLoading
                  })
                }
                fixedColumnsCount={3}
                handleDoubleClick={handleOpenEditDialog}
                totalPages={totalPages}
                totalElements={totalElements}
                numberOfElements={numberOfElements}
                handleDelete={handleDelete}
                setDataChecked={setDataChecked}
                dataChecked={dataChecked}
                deleteConditional={[
                  {
                    keyPath: "trangThai.code",
                    value: MISSION_STATUS_CODE.COMPLETED
                  }
                ]}
              />
            </Col>
          </Row>
        </>
      ) : (
        <BonusDialog
          listBonus={listBonus}
          bonusInfo={bonusEdit}
          handleClose={handleCloseDialog}
          handleCloseAndSearch={handleCloseAndSearch}
          isView={isView}
          setIsView={setIsView}
        />
      )}
    </div>
  );
};

export default Bonus;

import { paramsConfig } from "../../utils/paramsUtils";
import axios from "axios";

const API_URL_CA_LAM_VIEC = process.env.REACT_APP_API_URL_CHAM_CONG;

export const searchUpdateWorkShifts = (searchData: any) => {
  let url = `${API_URL_CA_LAM_VIEC}/bang-phan-ca-detail/page`;
  return axios.get(url, paramsConfig(searchData))
};

export const updateWorkShifts = (id: string, shiftInfo: any) => {
  let url = `${API_URL_CA_LAM_VIEC}/bang-phan-ca-detail/${id}`;
  return axios.put(url, shiftInfo);
};

export const deleteUpdateWorkShifts = (ids: string[]) => {
  let url = `${API_URL_CA_LAM_VIEC}/bang-phan-ca-detail/ids?ids=${ids.toString()}`;
  return axios.delete(url);
};

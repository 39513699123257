/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Form, Modal, Row } from "react-bootstrap";
import { addTaiLieuDinhKem, updateTaiLieuDinhKem } from "../../services/dialogServices";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import FileUpload from "../../../components/file-upload/FileUpload";
import { TYPE_OF } from "../../constants/profileDialogConsts";
import TextValidator from "../../../components/input/text-validator";
import { INIT_TAI_LIEU_DINH_KEM } from "../../constants/profileConsts";
import { ITaiLieuDinhKemInfo } from "../../models/profileModels";
import { RESPONSE_STATUS_CODE } from "../../../constants/moduleConsts";
import { useContext, useEffect, useState } from "react";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import { useProfileContext } from "../../ProfileContext";
import AppContext from "../../../../AppContext";
import { GroupButton } from "../../../components/GroupButton";
import { hasAuthority } from "../../../utils/functionUtils";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../../../constants";

type Props = {
  handleCloseAttachmentsDialog: () => void;
  identify: string;
  isView?: boolean;
  attachmentEdit: ITaiLieuDinhKemInfo;
};

const AttachmentsDialog = (props: Props) => {
  const { lang } = useMultiLanguage();
  const { isResign } = useProfileContext();
  const { setPageLoading } = useContext(AppContext);

  const { handleCloseAttachmentsDialog, identify, attachmentEdit, isView } = props;
  const [isEdit, setIsEdit] = useState<boolean>(!isView);

  useEffect(() => {
    if(!attachmentEdit?.id) return;
    formik.setValues(attachmentEdit)
  }, [attachmentEdit])

  const handleSubmit = async (values: ITaiLieuDinhKemInfo) => {
    const dataValues = { ...values, employeeId: identify, id: attachmentEdit?.id };
    if (typeof identify === TYPE_OF.STRING && identify) {
      try {
        setPageLoading(true);
        const { data } = attachmentEdit?.id
          ? await updateTaiLieuDinhKem(attachmentEdit?.id, dataValues)
          : await addTaiLieuDinhKem(dataValues);
        if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
          const message = attachmentEdit?.id
            ? "TOAST.EDIT.SUCCESS"
            : "TOAST.ADD.SUCCESS";
          toast.success(lang(message));
          handleCloseAttachmentsDialog();
        } else {
          toast.warning(`${data?.message}`);
        }
      } catch (error) {
        toast.error(lang("GENERAL.ERROR"));
      } finally {
        setPageLoading(false);
      }
    } else {
      toast.warning(lang("MESSAGE.BASIC.FIRST"));
    }
  };

  const validationSchema = Yup.object().shape({
    fileName: Yup.string()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    fileId: Yup.string()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
  });

  const formik = useFormik({
    initialValues: INIT_TAI_LIEU_DINH_KEM,
    validationSchema,
    onSubmit: handleSubmit
  })

  const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target;
    formik.setFieldValue(name, checked);
  };

  return (
    <Modal
      show={true}
      size="lg"
      onHide={handleCloseAttachmentsDialog}
      backdrop="static"
      centered
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title
            id="example-custom-modal-styling-title"
            className="heading-5"
          >
            {lang("GENERAL.INFO.ATTACHMENTS")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="spaces px-8">
            <Col xs={6}>
              <TextValidator
                lable={lang("GENERAL.INFO.ATTACHMENTS.NAME")}
                name="fileName"
                value={formik.values?.fileName || ""}
                type="text"
                readOnly={!isEdit}
                isRequired
                onChange={formik.handleChange}
                errors={formik.errors?.fileName}
                touched={formik.touched?.fileName}
              />
            </Col>
            <Col xs={6}>
              <FileUpload
                required
                label="CONTRACT.ATTACHMENTS"
                setFieldValue={(data: string) => {
                  formik.setFieldValue("fileId", data);
                }}
                isReadOnly={!isEdit}
                fileName={formik.values?.fileId}
                errors={formik.errors?.fileId}
              />
            </Col>
          </Row>
          <Row className="spaces px-8 pt-8">
            <Col xs={6}>
              <Form.Check
                className="custom-form-check"
                label={lang("GENERAL.INFO.ATTACHMENTS.ALLOW")}
                name="choPhepTaiVe"
                disabled={!isEdit}
                checked={formik.values?.choPhepTaiVe}
                onChange={handleChangeCheckBox}
              />
            </Col>
            <Col xs={6}>
              <TextValidator
                lable={lang("ALLOWANCE.NOTE")}
                name="ghiChu"
                value={formik.values?.ghiChu || ""}
                type="text"
                readOnly={!isEdit}
                onChange={formik.handleChange}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="flex-center">
          <GroupButton type="btn-cancel" outline handleCloseUpdateDialog={handleCloseAttachmentsDialog} />
          {!isResign &&
            (isEdit ? (
              <GroupButton type="btn-save" />
            ) : (hasAuthority(PERMISSIONS.EMPLOYEE, PERMISSION_ABILITY.UPDATE) &&
              <GroupButton type="btn-edit" handleSaveEdit={() => setIsEdit(true)} />
            ))}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AttachmentsDialog;

import { findList } from "../../utils/functionUtils";
import { IRegisterOvertime } from "../models/registerOvertimeModes";
import { CODE_STATUS, LIST_LOAI_DON, LIST_STATUS, TYPE_DON } from "./managerRequestConsts";

export const INIT_REGISTER_OVERTIME: IRegisterOvertime = {
    employee: null,
    donViCongTacId: "",
    donViCongTacText: "",
    donViCongTac: null,
    phongBan: null,
    phongBanId: "",
    phongBanText: "",
    ngayNop: "",
    loaiDon: findList(LIST_LOAI_DON, TYPE_DON.DANG_KY_LAM_THEM, "type"),
    truongKhoa: null,
    trangThai: findList(LIST_STATUS, CODE_STATUS.TAO_MOI),
    ngayDuyet: null,
    ghiChu: "",
    employeeInfos: []
}

export const CODE_THOI_DIEM_LAM_THEM = {
    TRUOC_CA: 1,
    SAU_CA: 2,
    NGHI_GIUA_GIO: 3,
    NGAY_NGHI: 4,
}

export const LIST_THOI_DIEM_LAM_THEM = [
    { code: CODE_THOI_DIEM_LAM_THEM.TRUOC_CA, name: "Trước ca" },
    { code: CODE_THOI_DIEM_LAM_THEM.SAU_CA, name: "Sau ca" },
    { code: CODE_THOI_DIEM_LAM_THEM.NGHI_GIUA_GIO, name: "Nghỉ giữa giờ" },
    { code: CODE_THOI_DIEM_LAM_THEM.NGAY_NGHI, name: "Ngày nghỉ" }
]

export const KEY_TAB = {
    CHUA_GUI: "1",
    CHO_DUYET: "2",
    DA_DUYET: "3",
    TU_CHOI: "4"
};

export const DS_TAB_DANG_KI_LAM_THEM = [
    {
        eventKey: KEY_TAB.CHUA_GUI,
        title: "Chưa gửi",
    },
    {
        eventKey: KEY_TAB.CHO_DUYET,
        title: "Chờ duyệt",
    },
    {
        eventKey: KEY_TAB.DA_DUYET,
        title: "Đã duyệt",
    },
    {
        eventKey: KEY_TAB.TU_CHOI,
        title: "Từ chối",
    }
];
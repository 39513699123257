import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import useMultiLanguage from "../../hook/useMultiLanguage";
import Autocomplete from "../components/input/autocomplete";
import { RESPONSE_STATUS_CODE } from "../constants/moduleConsts";
import { RemindBirthdayTable, RemindEmployeeNoContractTable } from "./components/table";
import "./remind.scss";
import { IOrganization } from "../models/models";
import { GroupButton } from "../components/GroupButton";
import { searchListWorkUnit } from "../services/services";

function Remind() {
  const { lang } = useMultiLanguage();

  const [unitList, setUnitList] = useState<IOrganization[]>([]);
  const [unit, setUnit] = useState<IOrganization | null>(null);
  const [resfeshPage, setResfeshPage] = useState<boolean>(false);

  useEffect(() => {
    fetchDataUnit();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDataUnit = async () => {
    try {
      let { data } = await searchListWorkUnit();
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setUnitList(data?.data?.content || []);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const handleSearch = () => {
    setResfeshPage(!resfeshPage);
  };

  return (
    <div className="wrapper">
      <div className="spaces flex justify-content-between align-items-center mt-16">
        <h2 className="breadcrumb-title">{lang("REMIND.INFOMATION")}</h2>
        <div className="flex gap-4 align-self-end">
          <div className="mr-2 warning-input-search">
            <Autocomplete
              placeholder={lang("WARNING.UNIT")}
              options={unitList || []}
              value={unit || null}
              name="unit"
              onChange={(value) => setUnit(value)}
            />
          </div>
          <GroupButton handleEvent={handleSearch}>
            {lang("BTN.SEARCH")}
          </GroupButton>
        </div>
      </div>
      <div className="content-wrapper spaces pb-16">
        <Row className="spaces">
          <Col xs={12}>
            <RemindBirthdayTable unitId={unit?.id || ""} resfeshPage={resfeshPage} />
          </Col>
          <Col xs={12}>
            <RemindEmployeeNoContractTable unitId={unit?.id || ""} resfeshPage={resfeshPage} />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Remind;

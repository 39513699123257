import { toAbsoluteUrl } from "../../../_metronic/helpers";
import "./homepage.scss";
import { Col, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAuth } from "../../modules/auth";
import { checkMenuByPermissions, findMenuAccessAllowed, hasAuthority } from "../../modules/utils/functionUtils";
import useMultiLanguage from "../../hook/useMultiLanguage";
import { useEffect, useState } from "react";
import { localStorageItem } from "../../modules/utils/localstorage";
import { KEY_LOCALSTORAGE } from "../../modules/auth/core/_consts";
import { TMenu, allMenu } from "./listMenu";

interface LinkBtnProps {
  itemLink: TMenu;
}

const LinkButton = (props: LinkBtnProps) => {
  const { itemLink } = props;
  const { lang } = useMultiLanguage();
  const checkedMenu = checkMenuByPermissions(allMenu);
  const [path, setPath] = useState<string>(itemLink?.defaultTo ?? "");

  const handleButtonClick = (module: string) => {
    const selectedMenu = checkedMenu.find((menu) => menu.name === module);
    if (selectedMenu) {
      localStorage.setItem(KEY_LOCALSTORAGE.LIST_SUB_MENU, JSON.stringify(selectedMenu.subMenu));
    }
  };

  useEffect(() => {
    if(!itemLink?.defaultTo) {
      setPath(findMenuAccessAllowed(itemLink.subMenu)?.to ?? itemLink.to);
    }
  }, [itemLink?.defaultTo, itemLink.subMenu, itemLink.to]);

  return (
    <Col xs={12} sm={6} lg={4} xl={3} className="flex flex-center">
      <Link to={path} onClick={() => handleButtonClick(itemLink.name)}>
        <div className="link-button-container">
          <button
            type="button"
            className="button-link"
          >
            <div className="cirle-animation cirle-animation-1"></div>
            <div className="cirle-animation cirle-animation-2"></div>
            <Image src={toAbsoluteUrl(itemLink.icon)} alt="image" />
          </button>
          <span className="button-text">{lang(itemLink.title)}</span>
        </div>
      </Link>
    </Col>
  )
}

export function HomePage() {
  const { lang } = useMultiLanguage();
  const { currentUser, logout } = useAuth();
  document.title = `${lang('SOFTWARE')}`;

  return (
    <div className="main">
      <div className="header">
        <div className="logo">
          <Image src={toAbsoluteUrl("/media/logos/xHRM.svg")} />
        </div>
        <div className="user">
          <div className="user-info">
            <span>{lang("GENERAL.HELLO")}</span>
            <h5>{currentUser?.name ?? localStorageItem.get(KEY_LOCALSTORAGE.CURRENT)?.username}</h5>
          </div>
          <div className="user-avatar">
            <img
              src={localStorageItem.get(KEY_LOCALSTORAGE.URL_IMAGE_AVATAR) || toAbsoluteUrl("/media/avatars/blank.png")}
              alt="avatar"
            />
          </div>
          <div className="user-logout">
            <button type="button" onClick={logout}>{lang("LOGOUT")}</button>
          </div>
        </div>
      </div>
      <div className="spaces mt-32">
        <h2 className="title-software">{lang("GENERAL.NAME_SOFTWARE")}</h2>
        <Row className="mx-10 flex-center gap-0">
          <Col xs={12} xxl={10}>
            <Row className="flex-center flex-top gap-0">
              {allMenu.map((item, index) => hasAuthority(item.permission, item.ability) ? (
                  <LinkButton itemLink={item} key={index} />
                ) : <></>
              )}
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
}

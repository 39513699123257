import { memo } from "react";

interface IProps {
  size?: number;
  className?: string;
  value?: string | number;
}

function ValueOrDots(props: IProps) {
  const { size = 30, value, className = "" } = props;
  return <span className={`break-word ${className}`}>{value || new Array(size).fill(".").join("")}</span>;
}

export default memo(ValueOrDots);

import { FC, useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import TableCustom, { columnNamesType } from "../table-custom/TableCustom";
import { toast } from "react-toastify";
import { RESPONSE_STATUS_CODE, TYPE, INIT_SEARCH_OBJECT } from "../../constants/moduleConsts";
import InputSearch from "../input/InputSearch";
import AppContext from "../../../AppContext";
import { convertEmployeeId } from "./utils/common";
import { STATUS_NV } from "../../profile/constants/dialogChildConsts";
import { GroupButton } from "../GroupButton";
import { searchAllEmployee } from "../../services/services";

interface IProps {
  open: boolean;
  handleClose: () => void;
  handleSaveData: (newData: any) => void;
  dataParent: any[];
}

const SelectEmployeeDialog: FC<IProps> = (props) => {
  const { open, handleClose, handleSaveData, dataParent } = props;
  const { lang } = useMultiLanguage();

  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [data, setData] = useState<any[]>([]);
  const [dataSelect, setDataSelect] = useState<any[]>([]);
  const [keyword, setKeyWord] = useState<string>("");
  const { setPageLoading } = useContext(AppContext);
  const [searchObject, setSearchObject] = useState<any>(INIT_SEARCH_OBJECT);

  useEffect(() => {
    handleSearch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchObject?.pageIndex, searchObject?.pageSize])

  useEffect(() => {
    if (dataParent?.length > 0) {
      setDataSelect(dataParent);
    }
  }, [dataParent]);

  const updatePageData = async (searchObject?: any) => {
    try {
      setPageLoading(true);
      let { data } = await searchAllEmployee({ 
        ...searchObject, 
        trangThaiLamViec: Object.values(STATUS_NV).filter(status => status !== STATUS_NV.THOI_VIEC).join(',') 
      });
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        let employeeConvert = convertEmployeeId(data?.data?.content || []);
        Array.isArray(data?.data?.content) &&
          employeeConvert.forEach((item: any) => {
            let idCompare = dataParent[0]?.employeeId;
            if(dataSelect?.length > 0) {
              idCompare = dataSelect[0]?.employeeId;
            }
            if (item?.employeeId === idCompare) {
              item.isChecked = true;
            }
          });
        setData(employeeConvert || []);
        setTotalPage(data?.data?.pageable?.totalPages);
        setTotalElements(data?.data?.pageable?.totalElements);
        setNumberOfElements(data?.data?.pageable?.numberOfElements);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleSaveDataParent = () => {
    if (!(dataSelect?.length > 0)) return toast.warning(lang("TOAST.SELECT_EMPLOYEE"));
    handleSaveData(dataSelect);
    handleClose();
  };

  const handleSearch = () => {
    const searchData = {
      keyword: keyword.trim(),
      ...searchObject
    };
    updatePageData(searchData);
  };

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    if(value === "") updatePageData();
    setKeyWord(value);
  };

  const columns: columnNamesType[] = [
    {
      name: lang("TABLE.INDEX"),
      field: "index",
      headerStyle: {
        minWidth: 50
      },
      cellStyle: {
        textAlign: "center"
      },
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("INPUT.CODE"),
      field: "maNhanVien",
      headerStyle: {
        minWidth: 80
      },
      cellStyle: {
        textAlign: "center"
      },
      render: (row: any) => <span className="color-steel-blue fw-600">{row?.maNhanVien}</span>
    },
    {
      name: lang("INPUT.FULLNAME"),
      field: "name",
      headerStyle: {
        minWidth: 200
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("PROFILE.ROLE"),
      field: "chucVuText",
      headerStyle: {
        minWidth: 250
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("GENERAL.EMPLOYEE.TITLE"),
      field: "viTriCongViecText",
      headerStyle: {
        minWidth: 150
      },
      cellStyle: {
        textAlign: "left"
      }
    }
  ];

  return (
    <Modal
      show={open}
      onHide={handleClose}
      backdrop="static"
      centered
      size="xl"
    >
      <Modal.Header
        closeButton
        className="spaces py-12"
      >
        <Modal.Title className="spaces fs-20 fw-600">{lang("SELECT.EMPLOYEE")}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="spaces pt-0">
        <div className="flex flex-end">
          <div className="flex spaces pt-16 w-1/2">
            <div className="spaces mr-10 flex-1">
              <InputSearch
                className="border-3px"
                value={keyword}
                placeholder={lang("GENERAL.ENTER_HERE")}
                handleSearch={handleSearch}
                handleChange={handleChangeSearch}
                isEnter
              />
            </div>
            <GroupButton className="spaces mr-10" handleEvent={handleSearch}>{lang("BTN.SEARCH")}</GroupButton>
          </div>
        </div>
        <TableCustom
          data={data}
          columns={columns}
          searchObject={searchObject}
          setSearchObject={setSearchObject}
          totalPages={totalPage}
          totalElements={totalElements}
          numberOfElements={numberOfElements}
          noToolbar
          type={TYPE.SINGLE}
          notDelete
          setDataChecked={setDataSelect}
          uniquePrefix="employeeId"
        />
      </Modal.Body>
      <Modal.Footer className="flex-center">
        <GroupButton type="btn-cancel" handleCloseUpdateDialog={handleClose} />
        <GroupButton type="btn-save" typeButton="button" handleSubmit={handleSaveDataParent} />
      </Modal.Footer>
    </Modal>
  );
}

export { SelectEmployeeDialog };

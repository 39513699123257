import { useFormik } from "formik";
import { FC, useContext } from "react";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import AppContext from "../../../AppContext";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { IPeopleDecision } from "../../models/models";
import { addNguoiDaiDienKy, searchAllSimpleValue } from "../../services/services";
import { RESPONSE_STATUS_CODE, TYPE_CATEGORY, VARIABLE_STRING } from "../../constants/moduleConsts";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { GroupButton } from "../GroupButton";
import { INIT_REPRESENTATIVE_INFO } from "./constants/dialogConsts";
import TextValidator from "../input/text-validator";
import Autocomplete from "../input/autocomplete";

interface IProps {
  handleClose: () => void;
  handleCloseAndSearch?: () => void;
};

const AddNewRepresentative: FC<IProps> = (props) => {
  const { handleClose, handleCloseAndSearch } = props;
  const { setPageLoading } = useContext(AppContext);
  const { lang } = useMultiLanguage();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    chucVu: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
  });

  const handleSubmit = async (values: IPeopleDecision) => {
    try {
      setPageLoading(true);
      const _values = {
        ...values,
        chucVuId: values.chucVu?.id || "",
        chucVuText: String(values.chucVu?.value) || ""
      }
      const { data } = await addNguoiDaiDienKy(_values);
      if(data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("TOAST.ADD.REPRESENTATIVE.SUCCESS"));
        handleCloseAndSearch ? handleCloseAndSearch() : handleClose();
      } else {
        toast.warning(lang("TOAST.IMPORT.ERROR"));
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  }
  
  const formik = useFormik({
    initialValues: INIT_REPRESENTATIVE_INFO,
    onSubmit: handleSubmit,
    validationSchema
  });

  const handleChangeSelect = (name: string, value: any) => {
    formik.setFieldValue(name, value);
  };

  return (
    <Modal
      show={true}
      onHide={handleClose}
      backdrop="static"
      centered
      size="lg"
    >
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton className="spaces py-12">
          <Modal.Title className="spaces fs-20 fw-600">{lang("REPRESENTATIVE.ADD")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-10">
          <Row>
            <Col xs={12} lg={6}>
              <TextValidator
                lable={lang("INPUT.FULLNAME")}
                name="name"
                value={formik.values?.name || ""}
                type="text"
                isRequired
                onChange={formik.handleChange}
                errors={formik.errors?.name}
                touched={formik.touched?.name}
              />
            </Col>
            <Col xs={12} lg={6}>
              <Autocomplete
                isRequired
                lable={lang("GENERAL.EMPLOYEE.POSITION")}
                name="chucVu"
                menuPlacement="bottom"
                value={formik.values?.chucVu || null}
                searchFunction={searchAllSimpleValue}
                searchObject={{ type: TYPE_CATEGORY.chucDanh }}
                onChange={(value) => handleChangeSelect(VARIABLE_STRING.CHUC_VU, value)}
                errors={formik.errors?.chucVu}
                touched={formik.touched?.chucVu}
                options={[]}
                getOptionLabel={(option) => option?.value}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="flex-center">
          <GroupButton type="btn-cancel" handleCloseUpdateDialog={handleClose} />
          <GroupButton typeButton="submit">{lang("BTN.ADD")}</GroupButton>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export { AddNewRepresentative };

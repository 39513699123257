import React, { ForwardedRef, forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react'
import TableGrouping, { IColumnsGrouping } from '../../../components/table-grouping/TableGrouping'
import Autocomplete from '../../../components/input/autocomplete';
import { variantStandardStyle } from '../../../components/input/autocomplete/styleJS';
import TextArea from '../../../components/input/TextArea';
import { GroupButton } from '../../../components/GroupButton';
import SelectMultilineEmployeeDialog from '../../../components/dialogs/SelectMultilineEmployeeDialog';
import TextValidator from '../../../components/input/text-validator';
import { IShift } from '../../../shift/models/shiftModels';
import AppContext from '../../../../AppContext';
import { searchShift } from '../../../shift/services/shiftServices';
import { RESPONSE_STATUS_CODE, SEARCH_OBJECT_MAX_SIZE } from '../../../constants/moduleConsts';
import { toast } from 'react-toastify';
import useMultiLanguage from '../../../../hook/useMultiLanguage';
import { numberExceptThisSymbols } from '../../../utils/functionUtils';

const DanhSachNhanVien = React.memo(forwardRef((props: any, ref: ForwardedRef<any>) => {
    let { values, employeeInfos } = props;
    const menuPortalTarget = document.body;
    const { setPageLoading } = useContext(AppContext);
    const { lang } = useMultiLanguage();

    const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
    const [dataNhanVien, setDataNhanVien] = useState<any[]>(employeeInfos)
    const [listCaTruc, setListCaTruc] = useState<IShift[]>([]);

    useImperativeHandle(ref, () => ({
        getDataNhanVien: () => dataNhanVien
    }));

    const getListDataCaTruc = async (searchData?: any) => {
        try {
            setPageLoading(true);
            const { data } = await searchShift(SEARCH_OBJECT_MAX_SIZE);
            if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
                setListCaTruc(data?.data?.content || []);
                return;
            }
            toast.warning(data?.message);
        } catch (err) {
            toast.error(lang("GENERAL.ERROR"));
        } finally {
            setPageLoading(false);
        }
    };

    useEffect(() => {
        getListDataCaTruc()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setDataNhanVien(employeeInfos)
    }, [employeeInfos])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, index: number, data: any) => {
        const { name, value } = event.target;
        let _data = [...dataNhanVien];
        _data[index][name] = value;
        setDataNhanVien(_data);
    }

    const handleChangeTextArea = (value: any, name: string, index: number, data: any) => {
        let _data: any = [...dataNhanVien];
        _data[index][name] = value;
        setDataNhanVien(_data);
    }

    const handleChangeSelect = (name: string, value: any, index: number, data: any) => {
        let _data = [...dataNhanVien];
        switch (name) {
            case "caNghiBu": {
                _data[index] = {
                    ..._data[index],
                    caNghiBuId: value?.id,
                    caNghiBuText: value?.tenCa
                }
                setDataNhanVien(_data)
                break
            }
            default: {
                _data[index] = {
                    ..._data[index],
                    [name]: value,
                }
                setDataNhanVien(_data);
                break
            }
        }
    };

    const columns: IColumnsGrouping[] = [
        {
            name: "STT",
            field: "stt",
            render: (row: any, index: number, stt: number) => <span >{stt}</span>,
            isSticky: true,
        },
        {
            name: "Họ và tên",
            field: "employeeText",
            headerStyle: {
                minWidth: "170px"
            },
            cellStyle: {
                minWidth: "170px",
                textAlign: "left"
            },
            isSticky: true,
        },
        {
            name: "Công việc được giao",
            field: "congViec",
            isSticky: true,
            headerStyle: {
                minWidth: "200px"
            },
            cellStyle: {
                textAlign: "left"
            },
            render: (row: any, index: number, stt: number, data: any) => (
                <TextArea
                    name="congViec"
                    type="text"
                    value={row?.congViec}
                    rows="2"
                    onChange={(value: any, name: string) => handleChangeTextArea(value, name, index, data)}
                />
            )
        },
        {
            name: "Thời gian làm thêm",
            field: "",
            child: [
                {
                    name: "Thời gian bắt đầu",
                    field: "thoiGianBatDau",
                    headerStyle: {
                        minWidth: "80px"
                    },
                    render: (row: any, index: number, stt: number, data: any) => {
                        return (
                            <TextValidator
                                name="thoiGianBatDau"
                                type="time"
                                value={row?.thoiGianBatDau}
                                onChange={(value: React.ChangeEvent<HTMLInputElement>) => handleChange(value, index, data)}
                            />
                        )
                    }
                },
                {
                    name: "Thời gian kết thúc",
                    field: "thoiGianKetThuc",
                    headerStyle: {
                        minWidth: "80px"
                    },
                    render: (row: any, index: number, stt: number, data: any) => {
                        return (
                            <TextValidator
                                name="thoiGianKetThuc"
                                type="time"
                                value={row?.thoiGianKetThuc}
                                onChange={(value: React.ChangeEvent<HTMLInputElement>) => handleChange(value, index, data)}
                            />
                        )
                    }
                },
                {
                    name: "Tổng số giờ",
                    field: "tongSoGio",
                    headerStyle: {
                        minWidth: "80px"
                    },
                    render: (row: any, index: number, stt: number, data: any) => {
                        return (
                            <TextValidator
                                name="tongSoGio"
                                type="number"
                                onKeyDown={numberExceptThisSymbols}
                                value={row?.tongSoGio || ""}
                                onChange={(value: React.ChangeEvent<HTMLInputElement>) => handleChange(value, index, data)}
                            />
                        )
                    }
                },
            ]
        },
        {
            name: "Duyệt nghỉ bù",
            field: "",
            child: [
                {
                    name: "Ngày nghỉ bù",
                    field: "ngayNghiBu",
                    headerStyle: {
                        minWidth: "80px"
                    },
                    render: (row: any, index: number, stt: number, data: any) => {
                        return (
                            <TextValidator
                                name="ngayNghiBu"
                                type="date"
                                value={row?.ngayNghiBu}
                                onChange={(value: React.ChangeEvent<HTMLInputElement>) => handleChange(value, index, data)}
                            />
                        )
                    }
                },
                {
                    name: "Ca nghỉ bù",
                    field: "",
                    headerStyle: {
                        minWidth: "150px"
                    },
                    render: (row: any, index: number, stt: number, data: any) => {
                        let caNghiBu = row?.caNghiBuId ? {
                            id: row?.caNghiBuId,
                            tenCa: row?.caNghiBuText,
                        } : null;
                        return (
                            <Autocomplete
                                options={listCaTruc}
                                placeholder='Nhập'
                                value={caNghiBu}
                                getOptionLabel={(option: any) => option?.tenCa}
                                styles={variantStandardStyle("26px")}
                                menuPortalTarget={menuPortalTarget}
                                onChange={(value) => handleChangeSelect("caNghiBu", value, index, data)}
                            />
                        )
                    }
                },
            ]
        },
    ];

    const handleSelect = (data: any[]) => {
        if(!data.length) {
            toast.warning("Bạn chưa chọn nhân viên nào!");
            return;
        }
        const listNhanVien: any = []
        data.forEach((item: any) => {
            let itemNhanVien = dataNhanVien.find(itemNhanVien => itemNhanVien?.employeeId === item?.employeeId)
            if (!itemNhanVien)
                itemNhanVien = {
                    employeeId: item?.id || null,
                    employeeText: item?.name || null,
                    congViec: item?.congViec || null,
                    thoiGianBatDau: item?.thoiGianBatDau || null,
                    thoiGianNghiBatDau: item?.thoiGianNghiBatDau || null,
                    thoiGianNghiKetThuc: item?.thoiGianNghiKetThuc || null,
                    thoiGianKetThuc: item?.thoiGianKetThuc || null,
                    tongSoGio: item?.tongSoGio || null,
                    ngayNghiBu: item?.ngayNghiBu || null,
                    caNghiBuId: item?.caNghiBuId || null,
                    caNghiBuText: item?.caNghiBuText || null,
                }
            listNhanVien.push(itemNhanVien)
        })
        setDataNhanVien(listNhanVien)
        handleClose();
    }

    const handleClose = () => {
        setIsOpenDialog(false);
    }

    const handleOpenDialog = () => {
        if (!values.phongBan?.id) {
            toast.warn("Vui lòng chọn phòng ban trước!");
            return;
        }
        setIsOpenDialog(true)
    }

    return (
        <div className='pt-2'>
            <div className='d-flex justify-content-between py-2'>
                <p className='fw-bold text-uppercase fs-3 m-0'>Danh sách nhân viên</p>
                <GroupButton handleEvent={handleOpenDialog}>Thêm mới</GroupButton>

                {
                    isOpenDialog &&
                    <SelectMultilineEmployeeDialog
                        open={isOpenDialog}
                        searchObject={{
                            phongBanIds: values.phongBan?.id
                        }}
                        selectedData={dataNhanVien}
                        uniquePrefixSelect="employeeId"
                        handleClose={handleClose}
                        handleSelect={handleSelect}
                    />
                }
            </div>
            <TableGrouping
                className="table-custom"
                id="timeSheets"
                columns={columns}
                data={dataNhanVien}
                prefixDataField="data"
                prefixTitleField="phongBanText"
            />

        </div>
    )
}))

export default DanhSachNhanVien
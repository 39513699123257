import { FC, forwardRef, useEffect } from "react";
import { IColumnsSpan, IPositionHandleRow, IRowSpanInfo } from "./TableCustomSpan";
import { TableSpanCol } from "./TableSpanCol";

interface IProps {
  id: string;
  data: any[];
  rowData: any;
  columns: IColumnsSpan[];
  rowIndex: number;
  notEdit?: boolean;
  handleDoubleClick?: (row: any, index: number) => void;
  searchObject?: any;
  dependencies?: any[];
  stickyColumnCount: number;
  rowSpanInfo: IRowSpanInfo;
  isRowSpan: boolean;
  handleRenderStickyRowData: (rowIndex: number) => void;
  isHandleNewRow?: boolean;
  listPositionHandleRow?: IPositionHandleRow[];
}

export const TableSpanRow: FC<IProps> = forwardRef((props, ref) => {
  const {
    id,
    data,
    rowData,
    columns,
    rowIndex,
    notEdit,
    handleDoubleClick,
    searchObject,
    dependencies,
    stickyColumnCount,
    rowSpanInfo,
    isRowSpan,
    handleRenderStickyRowData,
    isHandleNewRow,
    listPositionHandleRow
  } = props;
  
  useEffect(() => {
    handleRenderStickyRowData(rowIndex);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowIndex, dependencies, rowSpanInfo, columns]);

  return (
    <>
      <tr
        key={rowIndex}
        className="border-bottom border"
        onDoubleClick={() => {
          !notEdit && handleDoubleClick && handleDoubleClick(rowData, rowIndex);
        }}
      >
        {columns.map((column, colIndex, listColumns) => {
          const cellKey = column.field + '-' + rowIndex;
          const rowSpan = rowSpanInfo[cellKey]?.rowSpan ?? 1;
          
          return (
            isRowSpan ? (
              rowSpan ?
                <TableSpanCol
                  idTable={id}
                  colIndex={colIndex}
                  rowData={rowData}
                  rowIndex={rowIndex}
                  rowSpanPrevIndex={(isRowSpan && colIndex) 
                    ? (rowSpanInfo[listColumns[colIndex - 1]?.field + '-' + rowIndex]?.indexOfRowSpan ?? rowIndex) 
                    : rowIndex
                  }
                  itemList={data}
                  searchObject={searchObject}
                  column={column}
                  stickyColumnCount={stickyColumnCount}
                  rowSpan={isRowSpan ? rowSpan : 1}
                  isHandleNewRow={isHandleNewRow}
                  listPositionHandleRow={listPositionHandleRow}
                /> : null)
                : <TableSpanCol
                  idTable={id}
                  colIndex={colIndex}
                  rowData={rowData}
                  rowIndex={rowIndex}
                  itemList={data}
                  searchObject={searchObject}
                  column={column}
                  stickyColumnCount={stickyColumnCount}
                  rowSpan={isRowSpan ? rowSpan : 1}
                  isHandleNewRow={isHandleNewRow}
                  listPositionHandleRow={listPositionHandleRow}
                />
          )
        })}
      </tr>
    </>
  );
});


import axios from "axios";
import { paramsConfig } from "../../utils/paramsUtils";
import { ISendPeopleTraining } from "../models/sendPeopleTrainingModels";
const API_URL = process.env.REACT_APP_API_URL + "/cu-dao-tao";

export const getListSendPeopleTraining = (searchData: any) => {
  let url = `${API_URL}/page`;
  return axios.get(url, paramsConfig(searchData));
};

export const addSendPeopleTraining = (data: ISendPeopleTraining) => {
  let url = `${API_URL}`;
  return axios.post(url, data);
};

export const getSendPeopleTrainingById = (id: string) => {
  let url = `${API_URL}/${id}`;
  return axios.get(url);
};

export const updateSendPeopleTraining = (id: string, data: ISendPeopleTraining) => {
  let url = `${API_URL}/${id}`;
  return axios.put(url, data);
};

export const deleteSendPeopleTraining = (ids: string[]) => {
  let url = `${API_URL}/ids?ids=${ids.toString()}`;
  return axios.delete(url);
};

import axios, { AxiosResponse } from "axios";
import { IAdjustSalary, IParams, IRetireInfo } from "../models/warningModels";
import { IContractInfo } from "../../contract/models/contractModels";
import { ResignInfo } from "../../resign/models/resignModels";
import { CertificateInfo } from "../../profile/models/dialogModels";

const API_URL = process.env.REACT_APP_API_URL;
const API_URL_RETIRE = process.env.REACT_APP_API_URL + "/employee-nghi-viec";
const API_URL_ADJUST_SALARY = process.env.REACT_APP_API_URL + "/lich-su-luong/dieu-chinh-luong";

export const getDataWarning = (data: IParams): Promise<AxiosResponse<any>> => {
  let url = `${API_URL}/canh-bao`;
  return axios.post(url, data);
};

export const getDataCanhBaoHetHanHopDong = (data: IParams): Promise<AxiosResponse<any>> => {
  let url = `${API_URL}/canh-bao/data-het-han-hop-dong`;
  return axios.post(url, data);
};

export const getDataCanhBaoHetHanNangLuong = (data: IParams): Promise<AxiosResponse<any>> => {
  let url = `${API_URL}/canh-bao/data-nang-luong`;
  return axios.post(url, data);
};

export const getDataCanhBaoNangLuongTruocHan = (data: IParams): Promise<AxiosResponse<any>> => {
  let url = `${API_URL}/canh-bao/data-nang-luong-truoc-thoi-han`;
  return axios.post(url, data);
};

export const getDataCanhBaoNhanSuDenTuoiNghiHuu = (data: IParams): Promise<AxiosResponse<any>> => {
  let url = `${API_URL}/canh-bao/data-nghi-huu`;
  return axios.post(url, data);
};

export const getDataCanhBaoDenHanVanBangChungChi = (data: IParams): Promise<AxiosResponse<any>> => {
  let url = `${API_URL}/canh-bao/data-het-han-chung-chi`;
  return axios.post(url, data);
};

export const getDataCanhBaoHetHanLuanChuyen = (data: IParams): Promise<AxiosResponse<any>> => {
  let url = `${API_URL}/canh-bao/data-het-han-luan-chuyen`;
  return axios.post(url, data);
};

export const getDataCanhBaoHetHanGianDoan = (data: IParams): Promise<AxiosResponse<any>> => {
  let url = `${API_URL}/canh-bao/data-gian-doan`;
  return axios.post(url, data);
};

export const getDataCanhBaoHetHanDaoTao = (data: IParams): Promise<AxiosResponse<any>> => {
  let url = `${API_URL}/canh-bao/data-cu-dao-tao`;
  return axios.post(url, data);
};

export const getRetirementByEmployeeId = (id: string): Promise<AxiosResponse<any>> => {
  const url = `${API_URL_RETIRE}/employee-nghi-huu/${id}`;
  return axios.get(url);
}

export const addRetire = (data: IRetireInfo): any => {
  const url = `${API_URL_RETIRE}/nghi-huu`;
  return axios.post(url, data);
}

export const addAdjustSalary = (data: IAdjustSalary): any => {
  return axios.post(API_URL_ADJUST_SALARY, data);
}
export const createHopDongTaiKy = (data: IContractInfo): Promise<AxiosResponse<any>> => {
  let url = `${API_URL}/hop-dong-lao-dong/tai-ky`;
  return axios.post(url, data);
};

export const createChamDutHopDong = (data: ResignInfo): Promise<AxiosResponse<any>> => {
  let url = `${API_URL}/employee-nghi-viec/ket-thuc-hop-dong`;
  return axios.post(url, data);
};

export const getNghiViecChamDutHopDong = (employeeId: string): Promise<AxiosResponse<any>> => {
  let url = `${API_URL}/employee-nghi-viec/employee-kt-hop-dong/${employeeId}`;
  return axios.get(url);
};

export const addCertificateTraining = (sendPeopleTrainingId: string, certificate: CertificateInfo): Promise<AxiosResponse<any>> => {
  const url = `${API_URL}/cu-dao-tao/by-certificate/${sendPeopleTrainingId}`;
  return axios.put(url, certificate);
}
import { useFormik } from "formik";
import React, { ChangeEvent, useContext, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import * as Yup from "yup";
import AppContext from "../../../../AppContext";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import UploadFile from "../../../components/file-upload/UploadFile";
import Autocomplete from "../../../components/input/autocomplete";
import { RangeDatePicker } from "../../../components/input/RangeDatePicker";
import TextValidator from "../../../components/input/text-validator";
import { searchAllSimpleValue } from "../../../services/services";
import "../../../styles/index.scss";
import { RESPONSE_STATUS_CODE, VARIABLE_STRING, TYPE_CATEGORY } from "../../../constants/moduleConsts";
import { hasAuthority, useCustomIntl } from "../../../utils/functionUtils";
import { checkInvalidDate } from "../../../utils/validationSchema";
import { TRAINING_TYPE } from "../../constants/dialogChildConsts";
import { INIT_NATIONAL_DEFENSE_INFO } from "../../constants/profileDialogConsts";
import { IFile, INationalDefenseInfo } from "../../models/dialogModels";
import { addNationalDefense, updateNationalDefense } from "../../services/dialogServices";
import { useProfileContext } from "../../ProfileContext";
import { GroupButton } from "../../../components/GroupButton";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../../../constants";

interface IProps {
  handleCloseNationalDefenseDialog: () => void;
  handleCloseAndSearch: () => void;
  identify: string;
  isView: boolean;
  nationalDefenseEdit: INationalDefenseInfo;
}
export const NationalDefenseDialog: React.FC<IProps> = ({ 
  handleCloseNationalDefenseDialog,
  handleCloseAndSearch,
  identify,
  nationalDefenseEdit,
  isView,
}) => {
  const { lang, intl } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);
  const { isResign } = useProfileContext();

  const [isCheckCoSoDaoTao, setIsCheckCoSoDaoTao] = useState<boolean>(nationalDefenseEdit?.coSoDaoTaoKhac ? true : false);
  const [isEdit, setIsEdit] = useState<boolean>(!isView);

  const validationSchema = Yup.object().shape({
    coSoDaoTao: !isCheckCoSoDaoTao
      ? Yup.object().required(lang("VALIDATION.REQUIRE")).nullable()
      : Yup.object().nullable(),
    coSoDaoTaoKhac: isCheckCoSoDaoTao
      ? Yup.string().required(lang("VALIDATION.REQUIRE")).nullable()
      : Yup.string().nullable(),
    daoTaoTuNgay: Yup.date()
      .concat(checkInvalidDate(intl))
      .when("daoTaoDenNgay", {
        is: (daoTaoDenNgay: Date | null) => daoTaoDenNgay && daoTaoDenNgay < (new Date()),
        then: Yup.date()
          .max(
            Yup.ref("daoTaoDenNgay"),
            lang("VALIDATION.MAXDATE") +
            lang("INPUT.QUALIFICATION.TRAINING_TIME_END")
          )
          .nullable()
      })
      .max(new Date(), lang("VALIDATION.INVALID_START_TRAINING_DATE"))
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    daoTaoDenNgay: Yup.date()
      .concat(checkInvalidDate(intl))
      .required(lang("VALIDATION.REQUIRE"))
      .max(new Date(), lang("VALIDATION.INVALID_END_TRAINING_DATE"))
      .nullable(),
    vanBang: Yup.string()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable()
  });

  const convertDataNationalDefense = (data: INationalDefenseInfo) => {
    return {
      ...data,
      coSoDaoTao: data?.coSoDaoTaoId ? {
        id: data?.coSoDaoTaoId,
        value: data?.coSoDaoTaoText
      } : null
    }
  }

  const handleFormSubmit = async (values: INationalDefenseInfo) => {
    if (identify) {
      let _values = {
        ...values,
        employeeId: identify,
        coSoDaoTaoId: values.coSoDaoTao?.id,
        coSoDaoTaoText: values.coSoDaoTao?.value,
        type: TRAINING_TYPE.QUOC_PHONG
      }
      try {
        setPageLoading(true);
        const { data } = nationalDefenseEdit?.id
          ? await updateNationalDefense(nationalDefenseEdit?.id, { ..._values })
          : await addNationalDefense({ ..._values });

        if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
          const message = nationalDefenseEdit?.id
            ? "TOAST.EDIT.NATIONAL_DEFENSE.SUCCESS"
            : "TOAST.ADD.NATIONAL_DEFENSE.SUCCESS";
          toast.success(intl.formatMessage({ id: message }));
          handleCloseAndSearch();
        } else {
          toast.warning(data?.message);
        }
      } catch (error) {
        toast.error(intl.formatMessage({ id: "GENERAL.ERROR" }));
      } finally {
        setPageLoading(false);
      }
    } else {
      toast.warning(intl.formatMessage({ id: "MESSAGE.BASIC.FIRST" }));
    }
  };


  const formik = useFormik({
    initialValues: nationalDefenseEdit?.id ? convertDataNationalDefense(nationalDefenseEdit) : INIT_NATIONAL_DEFENSE_INFO,
    validationSchema,
    onSubmit: handleFormSubmit
  })

  const handleChangeSelect = (name: string, value: any) => {
    if (name === VARIABLE_STRING.CO_SO_DAO_TAO) {
      formik.setFieldValue(VARIABLE_STRING.CO_SO_DAO_TAO_KHAC, "");
    }
    formik.setFieldValue(name, value);
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>, nameObj: string) => {
    const { name, value } = event.target
    formik.setFieldValue(name, value)
    formik.setFieldValue(nameObj, null)
  }

  const handleFile = (data: IFile) => {
    formik.setFieldValue("fileId", data.id);
    formik.setFieldValue("fileName", data.name);
  }

  return (
    <Modal
      show={true}
      size="xl"
      centered
      aria-labelledby="example-custom-modal-styling-title"
      onHide={handleCloseNationalDefenseDialog}
      backdrop="static"
    >
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title
            id="example-custom-modal-styling-title"
            className="heading-5"
          >
            {useCustomIntl(
              `${nationalDefenseEdit
                ? "INFO.NATIONAL_DEFENSE.ADD"
                : "INFO.NATIONAL_DEFENSE.UPDATE"
              }`
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={4}>
              <RangeDatePicker
                label={useCustomIntl("INPUT.NATIONAL_DEFENSE.TRAINING_TIME")}
                isRequired
                startDateName="daoTaoTuNgay"
                endDateName="daoTaoDenNgay"
                isView={!isEdit}
                handleChange={formik.handleChange}
                value={formik.values}
                setFieldValue={formik.setFieldValue}
                touch={formik.touched}
                errors={formik.errors}
              />
            </Col>
            <Col xs={4}>
              <Autocomplete
                lable={useCustomIntl(
                  "INPUT.NATIONAL_DEFENSE.TRAINING_FACILITIES"
                )}
                isReadOnly={!isEdit}
                isRequired
                formCheckBox={true}
                value={
                  isCheckCoSoDaoTao
                    ? formik.values?.coSoDaoTaoKhac
                    : formik.values?.coSoDaoTao
                }
                name={
                  isCheckCoSoDaoTao
                    ? VARIABLE_STRING.CO_SO_DAO_TAO_KHAC
                    : VARIABLE_STRING.CO_SO_DAO_TAO
                }
                setIsCheckBox={setIsCheckCoSoDaoTao}
                isCheckBox={isCheckCoSoDaoTao}
                onChange={(selectedOption) =>
                  isCheckCoSoDaoTao
                    ? handleChange(
                      selectedOption,
                      VARIABLE_STRING.CO_SO_DAO_TAO
                    )
                    : handleChangeSelect(
                      VARIABLE_STRING.CO_SO_DAO_TAO,
                      selectedOption
                    )
                }
                options={[]}
                searchFunction={searchAllSimpleValue}
                searchObject={{ type: TYPE_CATEGORY.noiDaoTao }}
                getOptionLabel={(option) => option?.value}
                errors={
                  isCheckCoSoDaoTao
                    ? formik.errors?.coSoDaoTaoKhac
                    : formik.errors?.coSoDaoTao
                }
                touched={
                  isCheckCoSoDaoTao
                    ? formik.touched?.coSoDaoTaoKhac
                    : formik.touched?.coSoDaoTao
                }
              />
            </Col>
            <Col xs={4}>
              <TextValidator
                lable={useCustomIntl("INPUT.NATIONAL_DEFENSE.CERTIFICATE")}
                isRequired
                name="vanBang"
                value={formik.values?.vanBang || ""}
                type="text"
                readOnly={!isEdit}
                onChange={formik.handleChange}
                touched={formik.touched?.vanBang}
                errors={formik.errors?.vanBang}
              />
            </Col>
            <Col xs={4} className="pt-3">
              <UploadFile
                label="INPUT.NATIONAL_DEFENSE.ATTACHMENTS"
                setValue={handleFile}
                isReadOnly={!isEdit}
                fileValue={{
                  id: formik.values.fileId || "",
                  name: formik.values.fileName || ""
                }}
              />
            </Col>
            <Col xs={8} className="pt-3">
              <TextValidator
                lable={useCustomIntl("INPUT.NATIONAL_DEFENSE.NOTE")}
                name="note"
                type="text"
                readOnly={!isEdit}
                as="textarea"
                rows="1"
                value={formik.values?.note}
                onChange={formik.handleChange}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="flex-center">
          <GroupButton type="btn-cancel" handleCloseUpdateDialog={handleCloseNationalDefenseDialog} />
          {!isResign &&
            (isEdit ? (
              <GroupButton type="btn-save" />
            ) : (hasAuthority(PERMISSIONS.EMPLOYEE, PERMISSION_ABILITY.UPDATE) &&
              <GroupButton type="btn-edit" handleSaveEdit={() => setIsEdit(true)} />
            ))}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

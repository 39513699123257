import { useCallback, useEffect, useState } from "react";
import { countArrayDeep, extractElementsByDepth } from "../../../utils/functionUtils";
import { IColumnsGrouping } from "../TableGrouping";
import { bringChildToParrent, filterChild } from "../utils/tableGroupingUtils";

interface Iprops {
  columns: IColumnsGrouping[];
  tableId: string;
  data: any[];
}

function TableHeader(props: Iprops) {
  const { columns, tableId, data } = props;

  const [rowHeader, setRowHeader] = useState<IColumnsGrouping[][]>([]);

  useEffect(() => {
    setRowHeader(extractElementsByDepth(bringChildToParrent(columns)));
  }, [columns]);

  const calculateColSpan = (column: IColumnsGrouping) => {
    return column?.child ? filterChild(column.child).flat(Infinity).length : 1;
  };

  const calculateRowSpan = (column: IColumnsGrouping, level: number) => {
    let maxRowSpan = countArrayDeep(filterChild(columns));
    return column?.child ? 1 : maxRowSpan - level;
  };

  const handleRenderStickyColumns = useCallback(() => {
    setTimeout(() => {
      let stickyColumns = document.querySelectorAll(`.column-header-group-sticky-${tableId}`);
      Array.from(stickyColumns).reduce((acc: number, col: Element) => {
        let colElement = col as HTMLElement;
        colElement.style.left = acc + "px";
        return acc + colElement.offsetWidth;
      }, 0);
    }, 80)
  }, [tableId]);

  useEffect(() => {
    handleRenderStickyColumns();
  }, [columns, tableId, data, handleRenderStickyColumns]);

  return (
    <thead className="position-sticky top-0 z-index-3">
      {rowHeader?.map((row, index) => {
        return (
          <tr className="text-header-table fw-600 fw-bolder text-capitalize-first gs-0 border" key={index}>
            {row?.map((col, idx) => (
              <th
                key={col.field + idx}
                className={`
                    p-table text-center bg-header-table 
                    ${col.isSticky ? `column-header-group-sticky-${tableId} sticky-column` : ""}
                    ${col.className ? col.className : ""}
                    `}
                style={col.headerStyle}
                rowSpan={calculateRowSpan(col, index)}
                colSpan={calculateColSpan(col)}
              >
                {col.name}
              </th>
            ))}
          </tr>
        );
      })}
    </thead>
  );
}

export default TableHeader;

import { useFormik } from "formik";
import { FC, useContext, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import AppContext from "../../../../AppContext";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import { GroupButton } from "../../../components/GroupButton";
import TextValidator from "../../../components/input/text-validator";
import { FORMAT_DATE, RESPONSE_STATUS_CODE } from "../../../constants/moduleConsts";
import { IPropsShiftForm, IShift } from "../../models/shiftModels";
import { addShift, searchShift, updateShift } from "../../services/shiftServices";
import { INIT_SHIFT, TYPE_SHIFT } from "../../constants/shiftConsts";
import { hasAuthority, justAllowNumber } from "../../../utils/functionUtils";
import Autocomplete from "../../../components/input/autocomplete";
import { PERMISSION_ABILITY, PERMISSIONS } from "../../../../constants";
import moment from "moment";

const ShiftForm: FC<IPropsShiftForm> = (props) => {
  const {
    handleClose,
    shiftInfo,
    handleCloseUpdateDialog,
    isView,
    setIsView,
    handleCloseAndSearch
  } = props;
  const { lang } = useMultiLanguage()
  const { setPageLoading } = useContext(AppContext);

  const validationSchema = Yup.object().shape({
    maCa: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    tenCa: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    loaiCa: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    thoiGianBatDau: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    thoiGianKetThuc: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    soGioTinhCong: Yup.number().required(lang("VALIDATION.REQUIRE")).nullable(),
  });

  useEffect(() => {
      shiftInfo?.id && formik.setValues({ 
        ...shiftInfo, 
        caNghiBu: shiftInfo.caNghiBuId ? 
          { id: shiftInfo.caNghiBuId, tenCa: shiftInfo.caNghiBuText ?? "" } 
          : null 
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shiftInfo]);

  const handleSubmit = async (values: IShift) => {
    try {
      setPageLoading(true);
      const _values = {
        ...values,
        caNghiBuId: values.caNghiBu?.id ?? "",
        caNghiBuText: values.caNghiBu?.tenCa ?? ""
      }
      const { data } = shiftInfo?.id
        ? await updateShift(shiftInfo.id, _values)
        : await addShift(_values);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(
          shiftInfo.id
            ? lang("TOAST.EDIT.SUCCESS")
            : lang("TOAST.CREATE.SUCCESS")
        );
        handleCloseAndSearch();
      } else {
        toast.warning(data?.message);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false)
    }
  };

  const formik = useFormik({
    initialValues: INIT_SHIFT,
    validationSchema,
    onSubmit: handleSubmit
  });

  useEffect(() => {
    if (formik.values?.thoiGianBatDau && formik.values?.thoiGianKetThuc) {
      const startTime = moment(formik.values.thoiGianBatDau, FORMAT_DATE.HH_MM);
      const endTime = moment(formik.values.thoiGianKetThuc, FORMAT_DATE.HH_MM);
      const diffHrs = endTime.diff(startTime, 'hours', true);
      formik.setFieldValue("soGioLam", diffHrs.toFixed(2));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values?.thoiGianBatDau, formik.values?.thoiGianKetThuc]);
  
  const handleChangeSelect = (name: string, value: any) => {
    formik.setFieldValue(name, value);
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="header-form-action">
        <GroupButton type="btn-back" handleClose={handleClose} />
        <div className="flex gap-4">
          {(!isView && shiftInfo?.id) && (
            <GroupButton
              type="btn-cancel"
              handleCloseUpdateDialog={handleCloseUpdateDialog}
            />
          )}
          {isView ? (hasAuthority(PERMISSIONS.CA_LAM_VIEC, PERMISSION_ABILITY.UPDATE) &&
            <GroupButton type="btn-edit" handleSaveEdit={() => setIsView(false)} />
          ) : (
            <GroupButton type="btn-save" />
          )}
        </div>
      </div>
      <div className="form-content-scroll">
        <div className="sub-title-form">{shiftInfo?.id ? lang("TIMEKEEPING.SHIFT.EDIT") : lang("TIMEKEEPING.SHIFT.ADD")}</div>
        <div className="sub-title pb-2 pt-0">{lang("GENERAL.INFO")}</div>
        <Row className="g-4">
          <Col sm={4} md={3} lg={3} xl={2}>
            <TextValidator
              isRequired
              name="maCa"
              lable={lang("TIMEKEEPING.SHIFT.CODE")}
              type="text"
              value={formik.values?.maCa}
              onChange={formik.handleChange}
              readOnly={isView}
              errors={formik.errors?.maCa}
              touched={formik.touched?.maCa}
            />
          </Col>
          <Col sm={8} md={5} lg={5} xl={2}>
            <TextValidator
              isRequired
              name="tenCa"
              lable={lang("TIMEKEEPING.SHIFT.NAME")}
              type="text"
              value={formik.values?.tenCa}
              onChange={formik.handleChange}
              readOnly={isView}
              errors={formik.errors?.tenCa}
              touched={formik.touched?.tenCa}
            />
          </Col>
          <Col xs={6} sm={3} md={3} lg={3} xl={2}>
            <Autocomplete
              isRequired
              name="loaiCa"
              lable={lang("TIMEKEEPING.SHIFT.TYPE_SHIFT")}
              options={TYPE_SHIFT}
              isReadOnly={isView}
              value={formik.values?.loaiCa || null}
              onChange={(value=> handleChangeSelect("loaiCa", value))}
              errors={formik.errors?.loaiCa}
              touched={formik.touched?.loaiCa}
            />
          </Col>
          <Col xs={6} sm={3} md={3} lg={2} xl={2}>
            <TextValidator
              isRequired
              name="thoiGianBatDau"
              lable={lang("TIMEKEEPING.SHIFT.TIME_START")}
              type="time"
              value={formik.values?.thoiGianBatDau}
              onChange={formik.handleChange}
              readOnly={isView}
              errors={formik.errors?.thoiGianBatDau}
              touched={formik.touched?.thoiGianBatDau}
            />
          </Col>
          <Col xs={6} sm={3} md={3} lg={2} xl={2}>
            <TextValidator
              isRequired
              name="thoiGianKetThuc"
              lable={lang("TIMEKEEPING.SHIFT.TIME_END")}
              type="time"
              value={formik.values?.thoiGianKetThuc}
              onChange={formik.handleChange}
              readOnly={isView}
              errors={formik.errors?.thoiGianKetThuc}
              touched={formik.touched?.thoiGianKetThuc}
            />
          </Col>
          <Col xs={6} sm={3} md={2} lg={2} xl={2}>
            <TextValidator
              name="soGioLam"
              lable={lang("TIMEKEEPING.SHIFT.NUMBER_OF_WORKING")}
              readOnly={true}
              type="number"
              value={formik.values?.soGioLam || ""}
              onChange={formik.handleChange}
              onKeyDown={justAllowNumber}
            />
          </Col>
          <Col xs={6} sm={3} md={2} lg={2} xl={2}>
            <TextValidator
              isRequired
              name="soGioTinhCong"
              lable={lang("TIMEKEEPING.SHIFT.NUMBER_OF_WORK_HOURS")}
              readOnly={isView}
              type="number"
              value={formik.values?.soGioTinhCong || ""}
              onChange={formik.handleChange}
              onKeyDown={justAllowNumber}
              errors={formik.errors?.soGioTinhCong}
              touched={formik.touched?.soGioTinhCong}
            />
          </Col>
          <Col xs={6} sm={3} md={3} lg={3} xl={2}>
            <Autocomplete
              name="caNghiBu"
              getOptionLabel={(option: any) => option?.tenCa}
              lable={lang("TIMEKEEPING.SHIFT.COMPENSATION")}
              options={[]}
              searchFunction={searchShift}
              isReadOnly={isView}
              value={formik.values?.caNghiBu || null}
              onChange={(value=> handleChangeSelect("caNghiBu", value))}
            />
          </Col>
        </Row>
      </div>
    </Form>
  );
};
export default ShiftForm;

import { FC, useContext, useEffect, useState } from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { toast } from "react-toastify";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../constants";
import useMultiLanguage from "../../hook/useMultiLanguage";
import InputSearch from "../components/input/InputSearch";
import TableCustom, { columnNamesType } from "../components/table-custom/TableCustom";
import { checkStatus, formatDateTable, hasAuthority } from "../utils/functionUtils";
import AppContext from "../../AppContext";
import { GroupButton } from "../components/GroupButton";
import { INIT_SEARCH_OBJECT, RESPONSE_STATUS_CODE, TYPE } from "../constants/moduleConsts";
import ShiftDivisionForm from "./components/shift-division/ShiftDivisionForm";
import { IPropsShiftDivision, IShiftDivision } from "./models/shiftDivisionModels";
import "./shift.scss";
import { deleteShiftDivision, searchShiftDivision } from "./services/shiftDivisionServices";
import {CODE_STATUS, LIST_STATUS} from "../manager-request/constants/managerRequestConsts";
import {KEY_TAB, LIST_TAB_STATUS} from "./constants/shiftDivisionConsts";
import CustomTooltip from "../components/custom-tooltip";
import { KTSVG } from "../../../_metronic/helpers";
import { TYPE_OBJECT_SCHEDULE } from "./constants/shiftComprehensionConsts";

const ShiftDivision: FC<IPropsShiftDivision> = ({ typeObject }) => {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [listShift, setListShift] = useState<IShiftDivision[]>([]);
  const [openShiftForm, setOpenShiftForm] = useState<boolean>();
  const [shiftDivision, setShiftDivision] = useState<IShiftDivision>({} as IShiftDivision);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [isView, setIsView] = useState<boolean>(false);
  const [searchObject, setSearchObject] = useState<any>({
    ...INIT_SEARCH_OBJECT,
    trangThai: CODE_STATUS.TAO_MOI
  });
  const [activeTab, setActiveTab] = useState<string>(KEY_TAB.CHUA_GUI);
  const [isApproveOrReject, setIsApproveOrReject] = useState<boolean>(false);

  const handleOpenShiftForm = (info: any) => {
    setIsView(true);
    setOpenShiftForm(true);
    setShiftDivision(info);
  };

  const handleCloseAndSearch = () => {
    handleSearch();
    handleClose();
  }

  const handleClose = () => {
    setOpenShiftForm(false);
    setIsView(false);
    setIsApproveOrReject(false);
    setShiftDivision({} as IShiftDivision);
  };

  const getListShift = async (searchData?: any) => {
    try {
      setPageLoading(true);
      setSearchObject({ ...searchData })
      const { data } = await searchShiftDivision(searchData);
      if(data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListShift(data?.data?.content || []);
        setTotalPage(data?.data?.pageable?.totalPages);
        setTotalElements(data?.data?.pageable?.totalElements);
        setNumberOfElements(data?.data?.pageable?.numberOfElements);
      } else {
        toast.warning(data?.message);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleOpenUpdateDialog = () => {
    setOpenShiftForm(true)
  }

  const handleCloseUpdateDialog = () => {
    setIsView(true);
  }

  const handleSearch = (data: any = {}) => {
    const dataSearch: any = {
      ...searchObject,
      ...data
    };
    dataSearch.keyword = dataSearch?.keyword?.trim() || "";
    getListShift(dataSearch);
  };

  useEffect(() => {
    if(searchObject?.pageIndex && searchObject?.pageSize && typeObject) {
      handleSearch({ doiTuong: typeObject?.code });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchObject?.pageIndex, searchObject?.pageSize])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchObject({
      ...searchObject,
      keyword: e.target?.value
    });
  };

  const handleDeleteShift = async (ids: string[]) => {
    try {
      const { data } = await deleteShiftDivision(ids)
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("TIMEKEEPING.SHIFT_DIVISION.DELETE"))
        await getListShift(searchObject);
        return true;
      } else {
        toast.warning(data?.message)
      }
    }
    catch (err: any) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }

  const handleTabSelect: (eventKey: string | null) => void = (eventKey) => {
    if (eventKey) {
      setActiveTab(eventKey);
    }
    handleSearch({ trangThai: eventKey });
  };

  const handleApproveOrReject = (data: any) => {
    setShiftDivision(data);
    setIsView(true);
    setIsApproveOrReject(true);
    setOpenShiftForm(true);
  };

  const columns: columnNamesType[] = [
    {
      name: "STT",
      field: "",
      render: (_row: any, _index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("GENERAL.ACTION"),
      field: "action",
      headerStyle: {
        minWidth: "100px"
      },
      render: (row: any) => {
        return (
          <span className="cursor-pointer">
            {
              row?.trangThai?.code === CODE_STATUS.TAO_MOI &&
              <CustomTooltip title={lang("GENERAL.EDIT")} placement="top">
                <span className='color-primary' onClick={() => handleOpenShiftForm(row)}>
                  <KTSVG path='/media/icons/pencil-fill.svg' />
                </span>
              </CustomTooltip>
            }
            {
              row?.trangThai?.code === CODE_STATUS.CHO_DUYET && 
              hasAuthority(PERMISSIONS.PHAN_CA, PERMISSION_ABILITY.APPROVE) &&
              <CustomTooltip title={lang('GENERAL.APPROVE_OR_REJECT')} placement="top">
                <span className='color-green' onClick={() => handleApproveOrReject(row)}>
                  <KTSVG path={'/media/icons/circle-check.svg'} />
                </span>
              </CustomTooltip>
            }
          </span>
        )
      }
    },
    {
      name: lang("STATUS"),
      field: "trangThai",
      headerStyle: {
        minWidth: "100px"
      },
      render: (row: any) => (
        <span className={`${checkStatus(LIST_STATUS, Number(row?.trangThai?.code))} status`}>
          {row?.trangThai?.name}
        </span>
      )
    },
    ...typeObject?.code === TYPE_OBJECT_SCHEDULE.DIEU_DUONG ? [
      {
        name: lang("INPUT.DEPARTMENTS"),
        field: "phongBanText",
        headerStyle: {
          minWidth: "200px",
        },
        cellStyle: {
          textAlign: "left"
        }
      }
    ] : [],
    {
      name: lang("TIMEKEEPING.SHIFT_DIVISION.NAME"),
      field: "ten",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
    },
    {
      name: lang("START.DATE"),
      field: "ngayBatDau",
      headerStyle: {
        minWidth: "120px"
      },
      render: (row: any) => <span>{formatDateTable(row?.ngayBatDau)}</span>
    },
    {
      name: lang("END.DATE"),
      field: "ngayKetThuc",
      headerStyle: {
        minWidth: "120px"
      },
      render: (row: any) => <span>{formatDateTable(row?.ngayKetThuc)}</span>
    },
    {
      name: lang("DATE.APPROVE"),
      field: "ngayDuyet",
      headerStyle: {
        minWidth: "120px"
      },
      render: (row: any) => <span>{formatDateTable(row?.ngayDuyet)}</span>
    },
    {
      name: lang("WELFARE.ACTUAL_COST.APPROVED_PEOPLE"),
      field: "nguoiPheDuyetText",
      headerStyle: {
        minWidth: "120px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
  ];

  return (
    <div className="spaces px-8 py-12 flex-1 shift-division">
      {!openShiftForm ? (
        <>
          <Row className="spaces mb-12 flex-middle">
            <Col xs={5} xl={6} xxl={7} className="p-0">
              <h2 className="breadcrumb-title">{lang("TIMEKEEPING.SHIFT_DIVISION.LIST")}</h2>
            </Col>
            <Col xs={7} xl={6} xxl={5} className="flex">
              <div className="spaces mr-10 flex-1">
                <InputSearch
                  className="border-3px"
                  value={searchObject?.keyword}
                  placeholder="Tìm kiếm theo tên bảng phân ca"
                  handleSearch={() => handleSearch({ pageIndex: 1 })}
                  handleChange={handleChange}
                  isEnter
                />
              </div>
              <GroupButton handleEvent={() => handleSearch({ pageIndex: 1 })}>
                {lang("BTN.SEARCH")}
              </GroupButton>
            </Col>
          </Row>
          <Row className="bg-white">
            <Col className="table-custom">
              <Tabs
                className="tabs nav nav-tabs customs-tabs"
                activeKey={activeTab}
                onSelect={handleTabSelect}
              >
                {LIST_TAB_STATUS.map(item => (
                  <Tab
                    eventKey={item.eventKey}
                    key={item.eventKey}
                    title={
                      <div className="label">
                        <span>{item?.title}</span>
                      </div>
                    }
                  >
                    <TableCustom
                      id="shift-list"
                      data={listShift}
                      columns={columns}
                      searchObject={searchObject}
                      setSearchObject={setSearchObject}
                      buttonAdd={hasAuthority(typeObject?.code === TYPE_OBJECT_SCHEDULE.DIEU_DUONG 
                          ? PERMISSIONS.PHAN_CA_DIEU_DUONG : PERMISSIONS.PHAN_CA_BAC_SI, PERMISSION_ABILITY.CREATE) 
                        && item.eventKey === KEY_TAB.CHUA_GUI
                      }
                      notDelete={!hasAuthority(PERMISSIONS.PHAN_CA, PERMISSION_ABILITY.DELETE) || item.eventKey !== KEY_TAB.CHUA_GUI}
                      type={TYPE.MULTILINE}
                      totalPages={totalPage}
                      totalElements={totalElements}
                      numberOfElements={numberOfElements}
                      handleOpenDialog={handleOpenUpdateDialog}
                      handleDoubleClick={(row: any) => handleOpenShiftForm(row)}
                      handleDelete={(ids: string[]) => handleDeleteShift(ids)}
                    />
                  </Tab>
                ))}
              </Tabs>
            </Col>
          </Row>
        </>
      ) : (
        <ShiftDivisionForm
          handleClose={handleClose}
          handleCloseAndSearch={handleCloseAndSearch}
          shiftDivision={shiftDivision}
          handleCloseUpdateDialog={handleCloseUpdateDialog}
          isView={isView}
          setIsView={setIsView}
          doiTuong={typeObject}
          isApproveOrReject={isApproveOrReject}
        />
      )}
    </div>
  )
}

export { ShiftDivision };

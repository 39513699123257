import { Col, Row } from "react-bootstrap";
import TextUnderline from "../../../components/TextUnderline";
import { ValueOrDots } from "../../../components/exportWord/partials";
import { TYPE } from "../../../constants/moduleConsts";
import { IPropsExportWord } from "../../../components/exportWord";

export function ContractAnnexWord({ data }: IPropsExportWord) {
  return (
    <div className="word spaces px-70 colors-word">
      <div className="spaces text-center flex flex-between align-start">
        <div className="fs-lg">
          <p>{data.soYTeHeader}</p>
          <p className="fw-600 text-uppercase">
            <span className="header-underline fs-lg">
              <ValueOrDots className="fs-lg" value={data.donViKyHopDongText} size={70} />
            </span>
          </p>
          <p className="spaces mt-16 fs-md">
            Số: <ValueOrDots value={data.soPhuLuc} />
          </p>
        </div>
        <div className="fw-600">
          <p className="fs-lg">CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM</p>
          <p>
            <span className="header-underline fs-lg">Độc lập - Tự do - Hạnh phúc</span>
          </p>
          <p className="text-italic fw-500 spaces mt-16">
            <ValueOrDots value="" />, {data.ngayKyPhuLuc}</p>
        </div>
      </div>
      <div className="spaces py-16 text-center fw-600">
        <p className="fs-lg">PHỤ LỤC HỢP ĐỒNG LÀM VIỆC</p>
        <p>
          (Đính kèm <ValueOrDots value={data.tenHopDong} size={50} /> số <ValueOrDots value={data.soHopDong} /> ký{" "}
          <ValueOrDots value={data.ngayKyHopDong} />)
        </p>
      </div>
      <div className="text-italic text-indent-40 text-justify">
        <p>Căn cứ Nghị định 115/2020/NĐ-CP của Chính phủ ngày 25/9/2020 quy định về tuyển dụng, sử dụng, quản lý viên chức.</p>
        <p>Căn cứ Bộ luật lao động số 45/2019/QH14 ngày 20/11/2019; Nghị định 145/2020/NĐ-CP ngày 14/12/2020 của Chính phủ.</p>
        <p>{data?.quyetDinhHopDong ?? ""}</p>
        <p>{data?.qdhdPhuLuc ?? "" }</p>
      </div>
      <div className="text-indent-40">
        <div className="spaces pl-40">
          <div className="flex">
            <TextUnderline readOnly label="Chúng tôi, một bên là Ông/bà" value={data.nguoiDaiDienCtyKyText} />
            <TextUnderline readOnly label="Quốc tịch" value={data.quocTichText} />
          </div>
          <TextUnderline readOnly label="Chức vụ" value={data.nguoiDaiDienCtyChucDanhText} />
          <TextUnderline readOnly label="Đại diện cho" value={data.donViKyHopDongText} />
        </div>
        <p>Địa chỉ: {data?.unitAddress ?? ""}</p>
        <p>Điện thoại: {data?.unitPhone ?? ""}</p>
        <div className="spaces pl-40">
          <p className="fw-600">
            <TextUnderline readOnly label="Và một bên là ông/bà" value={data.fullName} />
          </p>
          <Row className="flex">
            <Col xs={4}>
              <TextUnderline readOnly label="Sinh ngày" value={data.birthdate} type={TYPE.DATE} />
            </Col>
            <Col xs={8}>
              <TextUnderline readOnly label="Tại" value={data.noiSinh} />
            </Col>
          </Row>
          <TextUnderline readOnly label="Giới tính" value={data.gender?.name} />
          <TextUnderline readOnly label="Hộ khẩu thường trú" value={data.hoKhauThuongTru} />
          <TextUnderline readOnly label="Điện thoại" value={data.phone} />
          <TextUnderline readOnly label="Tài khoản ngân hàng" value={data.tkNganHang} />
          <TextUnderline readOnly label="Nơi mở tài khoản" value={data.chiNhanh} />
          <TextUnderline readOnly label="Email (nếu có)" value={data.email} />
          <p className="flex">
            <TextUnderline readOnly label="Số CCCD/Hộ chiếu" value={data.soCMNDOrCCCD} />
            <TextUnderline readOnly label="Cấp ngày" value={data.ngayCapCMNDOrCCCD} type={TYPE.DATE} />
          </p>
          <TextUnderline readOnly label="Tại" value={data.noiCapCMNDOrCCCD} />
          <TextUnderline readOnly label="Trình độ đào tạo" value={data.trinhDoChuyenMon} />
          <TextUnderline readOnly label="Chuyên ngành đào tạo" value={data.chuyenNganhDaoTao} />
        </div>
        <p>Hai bên cùng nhau thỏa thuận thay đổi một số nội dung của Hợp đồng làm việc mà hai bên đã ký kết như sau:</p>
        <p>
          <span className="fw-600">Điều 1.</span> Nội dung thay đổi:{" "}
        </p>
        <p>
          - Xếp lương viên chức đối với Ông/bà <ValueOrDots value={data.fullName} />, chức danh nghề nghiệp{" "}
          <ValueOrDots value={data.chucDanhText} size={80} />, mã số:
          <ValueOrDots value={data.maNgach} />, kể từ ngày <ValueOrDots value={data.ngayCoHieuLucPhuLuc} />.
        </p>
        <p>
          - Ông/bà <ValueOrDots value={data.fullName} /> được hưởng lương bậc {data.currentBacLuong}, hệ số{" "}
          {data.heSoLuong}.
        </p>
        <p>- Thời gian nâng lương lần sau tính từ ngày ....../....../..........</p>
        <p>
          <span className="fw-600">Điều 2. </span> Thời gian thực hiện: những điều khoản thay đổi được ghi tại Điều 1
          của Phụ lục hợp đồng này có hiệu lực kể từ ngày <ValueOrDots value={data.ngayCoHieuLucPhuLuc} />.
        </p>
        <p>
          Phụ lục này có hiệu lực kể từ ngày ký kết, là bộ phận của Hợp đồng làm việc số{" "}
          <ValueOrDots value={data.soHopDong} /> ký ngày
          <ValueOrDots value={data.ngayCoHieuLucPhuLuc} /> giữa {data.donViKyHopDongText} và ông/bà{" "}
          <ValueOrDots value={data.fullName} />. Phụ lục này được diễn giải và áp dụng cùng với Hợp đồng làm việc, tạo
          thành một hợp đồng hoàn chỉnh, không thể tách rời và có giá trị ràng buộc các Bên. Nếu có bất kỳ nội dung nào
          mâu thuẫn giữa quy định tại Phụ lục và Hợp đồng làm việc, thứ tự ưu tiên áp dụng như sau: (i) Phụ lục này;
          (ii) Hợp đồng làm việc.{" "}
        </p>
        <p>
          Các Bên đã đọc, hiểu rõ, nhất trí với toàn bộ nội dung tại Phụ lục này. Phụ lục này được làm thành hai bản có
          giá trị như nhau, mỗi bên giữ một bản và là cơ sở để thực hiện.
        </p>
      </div>
      <div className="text-center align-start flex flex-between spaces mt-16 pb-70 px-70">
        <div>
          <p className="fw-600">Viên chức</p>
        </div>
        <div className="fw-600">
          <p>GIÁM ĐỐC</p>
          <p className="spaces mt-70">
            <ValueOrDots value={data.nguoiDaiDienCtyKyText} size={60} />
          </p>
        </div>
      </div>
    </div>
  );
}

import { IKeyValueString, IQuarter } from "../models/reportModels";
import {
  columnSalaryFund,
  columnStatistical,
  columnSalaryFundTotal,
  columnLaborContract
} from "./columns";

export const itemListAdditional = {
  name: "Tất cả báo cáo",
  id: null
};

export const REPORT_TYPE = {
  TANG_GIAM_CAN_BO: "1",
  LAO_DONG_THEO_CHUC_DANH: "2",
  BAO_HIEM: "3",
  DANH_GIA_KET_QUA_PHAN_LOAI_CAN_BO: "4",
  CHAT_LUONG_CAN_BO: "5",
  CAN_BO_NGHI_HUU_DUNG_QUY_DINH: "6",
  DANH_SACH_LAO_DONG: "7",
  THONG_KE: "8",
  DANH_SACH_DANG_VIEN: "9",
  NHAN_VIEN_THEO_PHONG_BAN: "10",
  DANH_SACH_VIEN_CHUC: "11",
  THONG_KE_HOP_DONG: "12",
  LUAN_CHUYEN_DIEU_DONG_HANG: "13",
  QUY_LUONG: "14",
  THEO_LOAI_HOP_DONG_LAO_DONG: "15",
};

export const REPORT_MAPING_COLUMNS = [
  {
    type: REPORT_TYPE.TANG_GIAM_CAN_BO,
    columns: []
  },
  {
    type: REPORT_TYPE.LAO_DONG_THEO_CHUC_DANH,
    // Tạm ẩn do BE chưa làm
    // columns: columnWorkingByPosition
    columns: []
  },
  {
    type: REPORT_TYPE.BAO_HIEM,
    columns: []
  },
  {
    type: REPORT_TYPE.DANH_GIA_KET_QUA_PHAN_LOAI_CAN_BO,
    columns: []
  },
  {
    type: REPORT_TYPE.CHAT_LUONG_CAN_BO,
    // Tạm ẩn do BE chưa làm
    // columns: columnStaffQuality
    columns: []
  },
  {
    type: REPORT_TYPE.CAN_BO_NGHI_HUU_DUNG_QUY_DINH,
    columns: []
  },
  {
    type: REPORT_TYPE.DANH_SACH_LAO_DONG,
    columns: []
  },
  {
    type: REPORT_TYPE.THONG_KE,
    columns: columnStatistical
  },
  {
    type: REPORT_TYPE.DANH_SACH_DANG_VIEN,
    columns: []
  },
  {
    type: REPORT_TYPE.NHAN_VIEN_THEO_PHONG_BAN,
    columns: []
  },
  {
    type: REPORT_TYPE.DANH_SACH_VIEN_CHUC,
    columns: []
  },
  {
    type: REPORT_TYPE.THONG_KE_HOP_DONG,
    columns: columnLaborContract
  },
  {
    type: REPORT_TYPE.LUAN_CHUYEN_DIEU_DONG_HANG,
    columns: []
  },
  {
    type: REPORT_TYPE.QUY_LUONG,
    columns: columnSalaryFund,
    columnsTotal: columnSalaryFundTotal
  }
];

export const REPORT_TYPE_SINGLE = [
  REPORT_TYPE.THONG_KE,
  REPORT_TYPE.LAO_DONG_THEO_CHUC_DANH,
  REPORT_TYPE.THONG_KE_HOP_DONG
];

export const REPORT_TYPE_COLLAPSE = [REPORT_TYPE.QUY_LUONG];

export const REPORT_KEY: IKeyValueString = {
  congChucVienChuc: "Cán bộ, viên chức",
  hopDong111: "Hợp đồng theo Nghị định 111/2022"
};

export const QUARTER_LIST: IQuarter[] = [
  {
    code: 1,
    name: "Quý 1",
    startDate: "-01-01",
    endDate: "-03-31"
  },
  {
    code: 2,
    name: "Quý 2",
    startDate: "-04-01",
    endDate: "-06-30"
  },
  {
    code: 3,
    name: "Quý 3",
    startDate: "-07-01",
    endDate: "-09-30"
  },
  {
    code: 4,
    name: "Quý 4",
    startDate: "-10-01",
    endDate: "-12-31"
  }
];

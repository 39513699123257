import { Modal } from "react-bootstrap";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { ASSETS_TYPE_ABROAD } from "../constants/declareAssetsConsts";
import BodyInfomation from "./BodyInfomation";
import { IDeclareAssets } from "../models/declareAssetsModels";
import { GroupButton } from "../../components/GroupButton";

interface IProps {
  open: boolean;
  handleClose: () => void;
  handleAddAndEditData: (newChildData: any[], field: string) => void;
  data: IDeclareAssets;
}

function DeclareAbroadDialog(props: IProps) {
  const { open, handleClose, handleAddAndEditData, data } = props;
  const { lang } = useMultiLanguage();

  return (
    <Modal show={open} onHide={handleClose} backdrop="static" centered size="xl" className="modal-size-custom custom-modal">
      <Modal.Header closeButton className="spaces py-12">
        <Modal.Title className="spaces fs-20 fw-600">Kê khai thông tin tài sản ở nước ngoài</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="spaces py-20 px-30">
          <BodyInfomation handleAddAndEditData={handleAddAndEditData} data={data} assetsType={ASSETS_TYPE_ABROAD} />
        </div>
      </Modal.Body>
      <Modal.Footer className="flex-center">
        <GroupButton outline handleEvent={handleClose}>{lang("BTN.CLOSE")}</GroupButton>
      </Modal.Footer>
    </Modal>
  );
}

export default DeclareAbroadDialog;
import React, { useEffect, useState } from "react";
import { KTSVG } from "../../../../_metronic/helpers";
import { ITreeViewProps, TSelectTree } from "./treeViewModels";

function TreeView({
  listOption,
  className = "",
  idSelected,
  handleChangeSelectId,
  handleSelect,
  fieldCollapse = "code",
  showQuantity = false,
  depth = 1
}: ITreeViewProps) {
  const [listData, setListData] = useState<TSelectTree[]>(listOption);

  useEffect(() => {
    listOption && setListData(listOption);
  }, [listOption]);

  const handleChangeCollapse = (item: any) => {
    if(!item.hasChildren) return;
    listOption.map((node: any) => updateExpandedNode(node, item));
    setListData([...listOption]);
  };

  const updateExpandedNode = (node: TSelectTree, item: TSelectTree) => {
    if (node.code === item.code) {
      node.expanded = !node.expanded;
    } else if (node.listData) {
      node.listData.map((childNode: any) => updateExpandedNode(childNode, item));
    }
    return node;
  };
  
  return ( 
    <div key={depth} className={`treeSelect ${className}`}>
      {(listData && listData.length > 0) && (
        listData?.map((item: TSelectTree) => (
          <React.Fragment key={item[fieldCollapse]}>
            <div
              className={`spaces rowTreeSelect fs-14 h-24 color-gunmetal flex flex-middle gap-2 rounded-4
                position-relative ${item?.id === idSelected ? "active" : ""} ${item?.expanded ? "expanded" : ""}`
              }
              style={{
                paddingLeft: `${depth*10}px`
              }}
              onClick={async () => {
                await handleSelect?.(item)
                handleChangeCollapse(item);
                handleChangeSelectId(item?.id ?? "");
              }}
            >
              <div className="flex gap-1">
                {item.hasChildren ? (
                  item?.expanded ? (
                    <>
                      <KTSVG path={"/media/icons/caret-down-fill.svg"} className='text-black svg-icon-down' />
                      <KTSVG path={item?.iconOpen || "/media/icons/folder-open.svg"} className='svg-icon-folder-open' />
                    </>
                  ) : (
                    <>
                      <KTSVG path={"/media/icons/caret-right-fill.svg"} className='text-black svg-icon-right' />
                      <KTSVG path={item?.iconClose || "/media/icons/folder-icon.svg"} className='svg-icon-folder' />
                    </>
                  )) : (
                    <div className="spaces ml-16 flex-middle">
                      {showQuantity
                        ? <span className="total-number">{item?.quantity}</span>
                        : <KTSVG path={"/media/icons/file-icon.svg"} className='svg-icon-file' />
                      }
                    </div>
                  )
                }
              </div>
              <span className={`truncate-text ${item.hasChildren && item?.expanded ? "spaces fw-600" : ""}`}>
                  {item.name}
              </span>
            </div>

            {item?.expanded && item?.listData && item.listData.length > 0 &&
              <div>
                <TreeView 
                  depth={depth + 1}
                  listOption={item.listData}
                  handleSelect={handleSelect}
                  idSelected={idSelected}
                  handleChangeSelectId={handleChangeSelectId}
                  showQuantity={showQuantity}
                />
              </div>
            }
          </React.Fragment>
        ))
      )}
    </div>
  );
}

export default TreeView;
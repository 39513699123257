/* eslint-disable react-hooks/exhaustive-deps */
import clsx from "clsx";
import React, { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import Select, { GetOptionLabel, GroupBase, mergeStyles, StylesConfig } from "react-select";
import { toast } from "react-toastify";
import { IAutoCompleteProps } from "./models";
import { KEY, TYPE } from "../../../constants/moduleConsts";
import { autocompleteStyle, multiValueRemove } from "./styleJS";
import TextValidator from "../text-validator";
import { flatArrayByChildrenName, removeDiacritics } from "../../../utils/functionUtils";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import CustomTooltip from "../../custom-tooltip";
import debounce from 'lodash/debounce';
import { GroupButton } from "../../GroupButton";

const Autocomplete: FC<IAutoCompleteProps> = React.forwardRef((props: IAutoCompleteProps, ref: React.Ref<any>) => {
  const {
    options,
    onChange,
    searchFunction,
    searchObject = {},
    labelSearch = "keyword",
    renderChilden,
    removeOption,
    keyRemoveOption = "id",
    isRefValue = false
  } = props;
  const [optionList, setOptionList] = useState<any[]>([]);
  const [originalOptionList, setOriginalOptionList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<any>(null);
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [isCheckDataScroll, setIsCheckDataScroll] = useState<boolean>(false);
  const [keyword, setKeyword] = useState('');
  const { lang } = useMultiLanguage();

  const labelRef = useRef<any>(null);
  const selectRef = useRef<any>(null);

  let styles = { ...props?.styles, ...props?.isReadOnly ? multiValueRemove : {} }
  const combinedStyles: StylesConfig<GetOptionLabel<any>, boolean, GroupBase<GetOptionLabel<any>>> = mergeStyles(autocompleteStyle, styles);

  const convertNameUrl = (value: string, item: any) => {
    const array = value.split(".")
    for (let i = 0; i < array.length; i++) {
      item = item?.[array[i]];
    }
    return item
  }

  const fetchData = async () => {
    if ((options?.length > 0 && !searchFunction) || props.isReadOnly) {
      let newData = sortData(options)
      setOriginalOptionList(options)
      setOptionList(newData);
    } else if (!isLoading) {
      setIsLoading(true);
      try {
        if (searchFunction) {
          let data = await getData(searchObject)
          setPageIndex(searchObject?.pageIndex || 1);
          setOptionList(sortData(data))
          setOriginalOptionList(data)
          setIsCheckDataScroll(data?.length > 0);
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (isFocus && props?.isScroll) {
      searchOption()
    }
  }, [isFocus, props?.isScroll, keyword])

  useEffect(() => {
    if (options?.length > 0) fetchData()
  }, [options])

  useEffect(() => {
    if ((isFocus && optionList?.length === 0 && !props?.isScroll)) fetchData()
  }, [isFocus])

  const getData = async (searchData: any) => {
    const res = await searchFunction?.(searchData);
    let data = props?.urlData ? convertNameUrl(props?.urlData, res) : res?.data?.data?.content;
    data = props?.sort ? props?.sort(data) : data || [];
    return data;
  }

  useEffect(() => {
    if (originalOptionList?.length > 0) {
      setOptionList(sortData(originalOptionList))
    }
  }, [removeOption]);

  const sortData = (data: any) => {
    let newData = data.filter((item: any) => !(removeOption || []).some((itemRemove: any) => item?.[keyRemoveOption] === itemRemove?.[keyRemoveOption]));
    newData = props?.sort ? props?.sort(newData) : newData;
    return newData;
  }

  useEffect(() => {
    if (props?.dependencies && props?.dependencies?.length > 0) {
      setOptionList([]);
      setSelectedValue(null)
    }
  }, props?.dependencies || []);

  useEffect(() => {
    getValue();
  }, [props.value, optionList]);

  const handleChange = (selectedOption: any) => {
    setKeyword("");
    setSelectedValue(selectedOption);
    onChange?.(selectedOption);
  };

  const combinedClassName = clsx(
    props?.className ? props.className : "w-100",
    clsx(props.className, props.errors && props.touched && "ac-is-invalid", "autocomplete-custom radius")
  );

  const getValue = () => {
    if (typeof props?.value === TYPE.OBJECT) {
      setSelectedValue(props?.value)
      return;
    }

    if ((typeof props?.value === TYPE.STRING) || (typeof props?.value === TYPE.NUMBER)) {
      const value = optionList.find((option: any) => option[props?.valueSearch ? props?.valueSearch : "name"] === (props?.value ?? false))
      setSelectedValue(value)
      return;
    }

    setSelectedValue(null);
  };

  const handleScrollToBottom = async () => {
    try {
      if (isCheckDataScroll) {
        const searchData = { ...searchObject }
        searchData.pageIndex = pageIndex + 1
        if (keyword) searchData[labelSearch] = keyword

        setIsLoading(true);
        let data = await getData(searchData);
        setPageIndex(pageIndex + 1);
        setIsCheckDataScroll(data?.length > 0);
        setOptionList([...optionList, ...data]);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false)
      toast.error("Xảy ra lỗi, vui lòng thử lại!");
    }
  }

  const handleKeyDown = async (event: React.KeyboardEvent<HTMLElement>) => {
    if (KEY.SPACE === event.code && !(event.target as HTMLInputElement).value) {
      event.preventDefault();
      return;
    }
  };

  const handleInputChange = debounce((newValue: string) => {
    setKeyword(newValue?.trim());
  }, 300);

  const handleBlur = () => {
    setIsFocus(false);
    keyword && setKeyword("");
  }

  const handleCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    props?.setIsCheckBox && props?.setIsCheckBox(event?.target?.checked)
  };

  const searchOption = async () => {
    try {
      let searchData = { ...searchObject };
      if (keyword) searchData[labelSearch] = keyword;

      setIsLoading(true);
      let data = await getData(searchData);
      setPageIndex(searchObject?.pageIndex || 1);
      setOptionList(data);
      setIsCheckDataScroll(data?.length > 0);
      setIsLoading(false);
      return;
    } catch (error) {
      setIsLoading(false);
    }
  };

  const customFilterOption = (option: any, inputValue: any) => {
    const label = String(option.label).toLowerCase();
    const input = String(inputValue).toLowerCase();
    return removeDiacritics(label).includes(removeDiacritics(input));
  };

  useEffect(() => {
    if (props.horizontal && labelRef.current && selectRef.current) {
      labelRef.current.style.marginTop =
        `calc((${selectRef.current?.controlRef?.offsetHeight}px - ${getComputedStyle(labelRef.current)?.lineHeight}) / 2)`;
    }
  }, [labelRef.current, selectRef.current]);

  const getListOption = useMemo(() => {
    if (renderChilden) {
      return flatArrayByChildrenName(optionList, renderChilden);
    } else {
      return optionList || [];
    }
  }, [optionList]);

  return (
    <div className={`${props.horizontal ? "flex horizontal gap-3 flex-middle" : ""}`}>
      <span className={`
        text-lable-input lable flex-shrink-0 flex-top
        ${props?.formCheckBox ? "flex flex-middle justify-content-between" : ""}
      `}>
        <label ref={labelRef} className="lable-autocomplete">
          {props?.lable}
          {props?.isRequired && <span className="color-red"> *</span>}
        </label>
        {
          props?.formCheckBox && (
            <div className="flex">
              <span>{lang("GENERAL.OTHER")}</span>
              <Form.Check
                disabled={props?.isReadOnly}
                className="checkBox check-box-autoComplete"
                name="isCheckBox"
                checked={props?.isCheckBox}
                onChange={handleCheckbox}
              />
            </div>
          )
        }
      </span>

      <CustomTooltip
        title={
          props?.titleTooltip
            ? props?.titleTooltip
            : props?.touched && props.errors
              ? props.errors?.toString()
              : ""
        }
        placement={props?.placementTooltip}
        delay={props.delayTooltip}
        className={`
          ${(!props?.isTooltip && !props?.titleTooltip) || !(props?.touched && props.errors) ? "hidden" : ""} 
          ${props?.titleTooltip ? "" : "tooltip-error"}
        `}
      >
        <div className="w-100">
          {
            props?.formCheckBox && props?.isCheckBox
              ? <TextValidator
                name={props?.name || ""}
                value={props?.value || ""}
                onChange={props?.onChange}
                touched={props.touched}
                errors={props.errors}
                readOnly={props?.isReadOnly}
              /> : <>
                <Select
                  ref={ref}
                  getOptionLabel={(option: GetOptionLabel<any>) =>
                    props.getOptionLabel
                      ? props.getOptionLabel(option)
                      : option.name
                  }
                  getOptionValue={(option: any) =>
                    props.getOptionValue
                      ? props.getOptionValue(option)
                      : option?.name ?? option?.value
                  }
                  backspaceRemovesValue={props?.backspaceRemovesValue}
                  options={getListOption}
                  noOptionsMessage={({ inputValue }) => props?.isAddNew
                    ? <GroupButton className="w-100" handleEvent={props?.onAddNew}>{lang("BTN.ADD")}</GroupButton>
                    : <span>Không tìm thấy lựa chọn cho {inputValue}</span>
                  }
                  className={combinedClassName}
                  name={props?.name}
                  value={selectedValue ?? (isRefValue ? undefined : null)}
                  defaultValue={props?.defaultValue ?? undefined}
                  id={props?.id}
                  key={props?.key}
                  onFocus={() => setIsFocus(true)}
                  onBlur={handleBlur}
                  isDisabled={props?.isDisabled}
                  isLoading={isLoading}
                  styles={combinedStyles}
                  minMenuHeight={props?.minMenuHeight}
                  maxMenuHeight={props?.maxMenuHeight}
                  placeholder={
                    <p className="color-placeholder spaces fs-13 m-0">
                      {props?.placeholder || `Chọn ...`}
                    </p>
                  }
                  onChange={onChange ? handleChange : undefined}
                  menuPortalTarget={props?.menuPortalTarget}
                  isMulti={props?.isMulti}
                  closeMenuOnSelect={props?.closeMenuOnSelect}
                  menuPlacement={props?.menuPlacement ? props?.menuPlacement : "auto"}
                  onMenuScrollToBottom={props?.isScroll ? handleScrollToBottom : undefined}
                  onKeyDown={handleKeyDown}
                  onInputChange={handleInputChange}
                  hideSelectedOptions={props?.isReadOnly ? false : undefined}
                  menuIsOpen={props?.isReadOnly ? false : undefined}
                  isSearchable={props?.isReadOnly ? false : props?.isSearchable !== undefined ? props?.isSearchable : true}
                  isClearable={props?.isReadOnly ? false : (props?.isClearable !== undefined ? props?.isClearable : true)}
                  filterOption={customFilterOption}
                />
                {(!props?.isTooltip && !props?.titleTooltip) && props.touched && props.errors && <div className="invalid-feedback">{props.errors}</div>}
              </>
          }
        </div>
      </CustomTooltip>
    </div>
  );
});
export default Autocomplete;
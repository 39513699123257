import { paramsConfig } from "../../utils/paramsUtils";
import axios from "axios";
import { ITableTimesheet } from "../models/timesheetModels";

const API_URL_CHAM_CONG = process.env.REACT_APP_API_URL_CHAM_CONG;

export const searchListTimesheet = (searchData: any) => {
    let url = `${API_URL_CHAM_CONG}/general-timesheet/page`;
    return axios.get(url, paramsConfig(searchData))
};

export const searchTimeSheet = (searchData: any) => {
    let url = `${API_URL_CHAM_CONG}/bang-cham-cong/page`;
    return axios.get(url, paramsConfig(searchData))
};

export const updateTimeSheet = (data: ITableTimesheet[]) => {
    let url = `${API_URL_CHAM_CONG}/bang-cham-cong`;
    return axios.put(url, data);
};

export const autoUpdateTimeSheet = (data: any) => {
    let url = `${API_URL_CHAM_CONG}/bang-cham-cong/update`;
    return axios.put(url, data);
}

export const approveTimeSheet = (id: string) => {
    let url = `${API_URL_CHAM_CONG}/general-timesheet/approve/${id}`;
    return axios.put(url);
}
export const INIT_APPROVAL_CONFIG = {
  letterType: null,
  approvingInfo: [],
  note: ""
}

export const LIST_APPROVING = [
  {
    code: "HRM.APPROVAL.MANAGER",
    name: "Trưởng khoa/phòng"
  },
  {
    code: "HRM.APPROVAL.TCHC",
    name: "Tổ chức hành chính"
  },
  {
    code: "HRM.APPROVAL.DIRECTOR",
    name: "Giám đốc"
  }
]
import "./_loading-indicator.scss";

function LoadingSpiner({ show }: { show: boolean }) {
  return (
    <>
      {show && (
        <div className="loading-spiner-wrapper">
          <div className="spinnerContainer">
            <div className="spinner"></div>
          </div>
        </div>
      )}
    </>
  );
}

export default LoadingSpiner;

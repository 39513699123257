import axios, { AxiosResponse } from "axios";
import { APIResponse } from "../../models/models";
import { paramsMaxSizeSearch } from "../../utils/paramsUtils";
import { EmployeeProfile } from "../models/profileModels";
import { IWorkingHistory } from "../models/dialogModels";

const API_PATH = process.env.REACT_APP_API_URL;
const API_EMPLOYEE_PROFILE = API_PATH + "/employee";

export const getEmployeeProfileById = (id: string) => {
  return axios.get(`${API_EMPLOYEE_PROFILE}/${id}`);
};

export const getEmployeeCurriculumVitae= (id: string) => {
  return axios.get(`${API_EMPLOYEE_PROFILE}/so-yeu-ly-lich/${id}`);
};

export const addNewPoliticInfo = (data: any) => {
  let url = API_PATH + `/thong-tin-chinh-tri`;
  return axios.post(url, data);
};

export const updatePoliticInfo = (id: any, data: any) => {
  let url = API_PATH + `/thong-tin-chinh-tri/${id}`;
  return axios.put(url, data);
};

export const getPoliticInfo = (idEmployee: string) => {
  let url = API_PATH + `/thong-tin-chinh-tri/get-by-employee/${idEmployee}`;
  return axios.get(url);
};

export const addMedicalInfo = (idEmployee: string, obj: EmployeeProfile) => {
  let url = API_EMPLOYEE_PROFILE + `/thong-tin-y-te/${idEmployee}`;
  return axios.put(url, obj);
};

export const deleteProfile = (listID: string[]): Promise<AxiosResponse<any, any>> => {
  let url = API_PATH + `/employee/list-employee-id/?id=${listID}`;
  return axios.delete(url);
};

export const getAllDepartmentByUserId = (userId: string) => {
  let url = API_PATH + `/c-department/get-by-user-id/${userId}`;
  return axios.get(url);
};

export const addBasicInfo = (basicInfo: any): any => {
  let url = API_PATH + "/employee/thong-tin-co-ban";
  return axios.post(url, basicInfo);
};

export const addContactInfo = (
  contactInfo: any,
  id: string
): Promise<AxiosResponse<APIResponse>> => {
  let url = API_PATH + `/employee/thong-tin-lien-he/${id}`;
  return axios.put(url, contactInfo);
};

export const addWorkInfo = (
  workInfo: any,
  id: string
): Promise<AxiosResponse<APIResponse>> => {
  let url = API_PATH + `/employee/thong-tin-cong-viec/${id}`;
  return axios.put(url, workInfo);
};

export const getTrainingInfoByEmployee = (employeeId: string): any => {
  let url = API_PATH + `/trinh-do-dao-tao/get-by-employee/${employeeId}`;
  return axios.get(url);
};

export const getContractByEmployee = (
  employeeId: string
): Promise<AxiosResponse<any>> => {
  let url = `${API_PATH}/hop-dong-lao-dong/employee/${employeeId}`;
  return axios.get(url);
};

export const getContractsByEmployee = (
  employeeId: string
): Promise<AxiosResponse<any>> => {
  let url = `${API_PATH}/hop-dong-lao-dong/get-by-employee/${employeeId}`;
  return axios.get(url);
};

export const deleteMultipleContract = (
  ids: string
): Promise<AxiosResponse<any>> => {
  let url = `${API_PATH}/hop-dong-lao-dong/laborContractIds?ids=${ids}`;
  return axios.delete(url);
};

export const deleteMultipleAllowance = (
  ids: string
): Promise<AxiosResponse<any>> => {
  let url = `${API_PATH}/hop-dong-lao-dong-phu-cap/allowanceIds=${ids}`;
  return axios.delete(url);
};

export const updateTrainingInfo = (id: string, data: any) => {
  let url = API_PATH + `/trinh-do-dao-tao/${id}`;
  return axios.put(url, data);
};

export const addQuanDoi = (employeeId: string, data: any) => {
  let url = API_PATH + `/employee-quan-doi/${employeeId}`;
  return axios.post(url, data);
};

export const getQuanDoi = (employeeId: string) => {
  let url = API_PATH + `/employee-quan-doi/get-by-employee/${employeeId}`;
  return axios.get(url);
};

export const getNguoiQuanLy = (searchObject: any) => {
  let url = API_PATH + `/employee-nguoi-quan-ly/page`;
  return axios.get(url, paramsMaxSizeSearch(searchObject));
}

export const updateThongTinGiaDinhChinhSach = (employeeId: string, data: any) => {
  let url = API_PATH + `/employee/thong-tin-gia-dinh-chinh-sach/${employeeId}`;
  return axios.put(url, data);
}

export const updateDanhHieuPhongTang = (employeeId: string, data: any) => {
  let url = API_PATH + `/employee/danh-hieu-phong-tang/${employeeId}`;
  return axios.put(url, data);
}

export const getAvailableFieldsExport = () => {
  let url = API_PATH + "/employee/field-export";
  return axios.get(url);
}

export const exportExcelProfile = (data: any) => {
  let url = API_PATH + "/download-excel/export-ho-so";
  return axios({
    url: url,
    method: "POST",
    responseType: "blob",
    data: data,
  });
}

export const updateThongTinDacDiemBanThan = (employeeId: string, data: IWorkingHistory) => {
  let url = API_PATH + `/employee/thong-tin-dac-diem-ban-than/${employeeId}`;
  return axios.put(url, data);
}
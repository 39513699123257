
import { IPropsExportWord } from "../../components/exportWord";
import { ValueOrDots } from "../../components/exportWord/partials";
import { capitalizeWords } from "../../utils/functionUtils";

export default function SendPeopleTrainingWord({ data }: IPropsExportWord) {
  return (
    <div className="word spaces px-70 colors-word">
      <div className="spaces text-center flex flex-between align-start">
        <div className="fs-lg">
          <p>{data?.soYTeHeader}</p>
          <p className="fw-600 text-uppercase">
            <span className="header-underline fs-lg">{data?.donViCongTacText}</span>
          </p>
          <p className="spaces mt-16 fs-md">
            Số: <ValueOrDots value={data.soQuyetDinh} />
          </p>
        </div>
        <div className="fw-600">
          <p className="fs-lg">CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM</p>
          <p>
            <span className="header-underline fs-lg">Độc lập - Tự do - Hạnh phúc</span>
          </p>
          <p className="text-italic fw-500 spaces mt-16">
            <ValueOrDots value="" size={20} />, ngày <ValueOrDots value={data?.ngayQuyetDinhNgay} size={10} /> tháng 
            <ValueOrDots value={data?.ngayQuyetDinhThang} size={10} /> năm <ValueOrDots value={data?.ngayQuyetDinhNam} size={10} />
          </p>
        </div>
      </div>
      <div className="spaces py-12 text-center fw-600">
        <p className="fs-lg">QUYẾT ĐỊNH</p>
        <p className="header-underline fs-lg mx-auto">V/v cử cán bộ đi học</p>
        <p className="fs-lg mt-4">GIÁM ĐỐC {data?.donViCongTacHeader}</p>
      </div>
      <div className="text-indent-40">
        <p>{data?.quyetDinhHopDong}</p>
        <p>{data?.qdhdCuDaoTao}</p>
        <p>Căn cứ Thông báo số 992/TB-BM ngày 08/3/2024 của Bệnh viện Bạch Mai về nhập học khóa đào tạo “Kỹ thuật lọc 
          máu liên tục và thay huyết tương”;
        </p>
        <p>Căn cứ nhu cầu chuyên môn thực tế và năng lực công tác cán bộ;</p>
        <p>Theo đề nghị của Trưởng phòng Tổ chức hành chính Bệnh viện.</p>
        <p className="fs-lg fw-600 text-center">QUYẾT ĐỊNH</p>
        <p><b>Điều 1.</b> Cử ông/bà <ValueOrDots value={capitalizeWords(data?.fullName)} /> - <ValueOrDots value={data?.chucDanhText} />
          , hiện đang công tác tại <ValueOrDots value={data?.phongBanText} />, <ValueOrDots value={data?.donViCongTacText} /> tham 
          gia khóa đào tạo về “<ValueOrDots value={data?.tenKhoaDaoTao} />” tại <ValueOrDots value={data?.noiDaoTao} />
        </p>
        <p>Thời gian đào tạo: <ValueOrDots value={data?.hieuLucTuNgay} size={20} /> - <ValueOrDots value={data?.hieuLucDenNgay} size={20} /></p>
        <p><b>Điều 2.</b> Ông/Bà <ValueOrDots value={capitalizeWords(data?.fullName)} size={50} /> có trách nhiệm thực hiện đúng nội quy, quy chế của cơ sở đào tạo và của đơn vị 
          sự nghiệp trong thời gian tham gia học tập.
        </p>
        <p>
          <b>Điều 3.</b> Các ông, bà: <ValueOrDots value={data?.khoaPhongNhanQuyetDinh} size={160} />
          ; các khoa, phòng có liên quan và ông/bà <ValueOrDots value={capitalizeWords(data?.fullName)} size={50} /> chịu trách nhiệm thi hành Quyết định này.
        </p>
      </div>
      <div className="align-start flex flex-between spaces mt-16 pb-70">
        <div>
          <p className="fw-600 text-italic">Nơi nhận:</p>
          <p>- Như Điều 3 (để t.hiện);</p>
          <p>- Lưu: VT, TCHC</p>
        </div>
        <div className="fw-600 text-center spaces pr-70">
          <p>GIÁM ĐỐC</p>
          <p className="spaces mt-70">{data.nguoiQuyetDinhText}</p>
        </div>
      </div>
    </div>
  );
}

import { findList } from "../../utils/functionUtils";
import { IChangeCompensated } from "../models/changeCompensatedModes";
import { CODE_STATUS, LIST_LOAI_DON, LIST_STATUS, TYPE_DON } from "./managerRequestConsts";

export const KEY_TAB = {
	CHUA_GUI: "1",
	CHO_DUYET: "2",
	DA_DUYET: "3",
	TU_CHOI: "4"
}

export const DS_TAB_DON_DOI_CA_NGHI_BU = [
	{
		eventKey: KEY_TAB.CHUA_GUI,
		title: "Chưa gửi",
	},
	{
		eventKey: KEY_TAB.CHO_DUYET,
		title: "Chờ duyệt",
	},
	{
		eventKey: KEY_TAB.DA_DUYET,
		title: "Đã duyệt",
	},
	{
		eventKey: KEY_TAB.TU_CHOI,
		title: "Từ chối",
	}
]

export const INIT_CHANGE_COMPENSATED: IChangeCompensated = {
	employee: null,
	maNhanVienDangKy: "",
	employeeDangKy: null,
	employeeDangKyId: "",
	employeeDangKyText: "",
	donViCongTacId: "",
	donViCongTacText: "",
	maNhanVien: "",
	phongBanId: "",
	phongBanText: "",
	chucDanhId: "",
	chucDanhText: "",
	ngayNop: "",
	loaiDon: findList(LIST_LOAI_DON, TYPE_DON.DON_DOI_CA_NGHI_BU, "type"),
	loaiNghi: null,
	truongKhoa: null,
	trangThai: findList(LIST_STATUS, CODE_STATUS.TAO_MOI),
	ngayDuyet: null,
	ghiChu: "",
	lyDo: "",
	ngayHienTai: "",
	caHienTai: null,
	caHienTaiId: "",
	caHienTaiText: "",
	ngayDangKy: "",
	caDangKy: null,
	caDangKyId: "",
	caDangKyText: "",
	loaiDoi: null
}

export const TYPE_CHANGE_SHIFT = {
	CA_NGHI_BU: 1,
	CA_LAM_VIEC: 2
}

export const LIST_TYPE_CHANGE_SHIFT = [
	{
		code: TYPE_CHANGE_SHIFT.CA_NGHI_BU,
		name: "Đổi ca nghỉ bù"
	},
	{
		code: TYPE_CHANGE_SHIFT.CA_LAM_VIEC,
		name: "Đổi ca làm việc"
	},
]
import { useFormik } from "formik";
import { ChangeEvent, useContext, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Autocomplete from "../../../components/input/autocomplete";
import { RangeDatePicker } from "../../../components/input/RangeDatePicker";
import TextValidator from "../../../components/input/text-validator";
import { searchAllSimpleValue } from "../../../services/services";
import "../../../styles/index.scss";
import { RESPONSE_STATUS_CODE, VARIABLE_STRING, TYPE_CATEGORY } from "../../../constants/moduleConsts";
import { handleBlurDate, useCustomIntl } from "../../../utils/functionUtils";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import UploadFile from "../../../components/file-upload/UploadFile";
import AppContext from "../../../../AppContext";
import { GroupButton } from "../../../components/GroupButton";
import { TRAINING_TYPE } from "../../../profile/constants/dialogChildConsts";
import { ISendPeopleTraining } from "../../../send-people-training/models/sendPeopleTrainingModels";
import { CertificateInfo, IFile } from "../../../profile/models/dialogModels";
import { addCertificateTraining } from "../../services/warningServices";
import { INIT_CRETIFICAFE_INFO } from "../../../profile/constants/profileConsts";

interface Props {
  handleCloseDialog: () => void;
  handleCloseAndSearch: () => void;
  sendPeopleInfo: ISendPeopleTraining;
}

export const CertificateTrainingDialog = (props: Props) => {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const { handleCloseDialog, handleCloseAndSearch, sendPeopleInfo } = props;
  const [isCheckCoSoDaoTao, setIsCheckCoSoDaoTao] = useState<boolean>(false);

  const validationSchema = Yup.object().shape({
    tenChungChi: Yup.string()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    thoiGianHieuLucTuNgay: Yup.date()
      .when("thoiGianHieuLucDenNgay", {
        is: (thoiGianHieuLucDenNgay: Date | null) => thoiGianHieuLucDenNgay && thoiGianHieuLucDenNgay < (new Date()),
        then: Yup.date()
          .max(
            Yup.ref("thoiGianHieuLucDenNgay"),
            lang("VALIDATION.MAXDATE") +
            lang("INPUT.CERTIFICATE.EFFECTIVE_TIME_END")
          )
          .nullable()
      })
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    thoiGianHieuLucDenNgay: Yup.date().nullable(),
    soCCHN: Yup.string()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    noiCap: Yup.string()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    chuyenNganh: Yup.string()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    ngayCap: Yup.date()
      .when("thoiGianHieuLucTuNgay", {
        is: (thoiGianHieuLucTuNgay: Date | null) => thoiGianHieuLucTuNgay && thoiGianHieuLucTuNgay < (new Date()),
        then: Yup.date()
          .max(
            Yup.ref("thoiGianHieuLucTuNgay"),
            lang("VALIDATION.MAXDATE") +
            lang("INPUT.CERTIFICATE.EFFECTIVE_TIME_START")
          )
          .nullable()
      })
      .max(new Date(), lang("VALIDATION.INVALID_ISSUEDATE"))
      .nullable(),
  });

  const handleFormSubmit = async (values: CertificateInfo) => {
    try {
      let _values = {
        ...values,
        employeeId: sendPeopleInfo?.employeeId,
        coSoDaoTaoId: values?.coSoDaoTao?.id,
        coSoDaoTaoText: values?.coSoDaoTao?.value,
        type: TRAINING_TYPE.CHUNG_CHI_HANH_NGHE
      }
      setPageLoading(true);
      const { data } = await addCertificateTraining(sendPeopleInfo?.id ?? "", _values)
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("TOAST.ADD.CERTIFICATE.SUCCESS"));
        handleCloseAndSearch();
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: INIT_CRETIFICAFE_INFO,
    validationSchema,
    onSubmit: handleFormSubmit
  });

  const handleChangeSelect = (name: string, value: any) => {
    if (name === VARIABLE_STRING.CO_SO_DAO_TAO) {
      formik.setFieldValue(VARIABLE_STRING.CO_SO_DAO_TAO_KHAC, "");
    }
    formik.setFieldValue(name, value);
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>, nameObj: string) => {
    const { name, value } = event.target
    formik.setFieldValue(name, value)
    formik.setFieldValue(nameObj, null)
  }

  const handleFile = (data: IFile) => {
    formik.setFieldValue("fileId", data.id);
    formik.setFieldValue("fileName", data.name);
  }

  return (
    <Modal
      show={true}
      size="xl"
      centered
      aria-labelledby="example-custom-modal-styling-title"
      onHide={handleCloseDialog}
      backdrop="static"
      className="modal-scroll"
    >
      <Modal.Header closeButton className="py-4">
        <Modal.Title
          id="example-custom-modal-styling-title"
          className="heading-5"
        >
          {lang("INFO.CERTIFICATE.ADD")}
        </Modal.Title>
      </Modal.Header>
      <Form
        className="form-info pb-0"
        onSubmit={formik.handleSubmit}
      >
        <Modal.Body>
          <div className="spaces mb-4">
            <span className="fs-3 fw-bold">{lang("PROFILE.TITLE")}</span>
          </div>
          <Row className="spaces mb-14 g-4">
            <Col sm={4} md={4} lg={4} xl={3} xxl={3}>
              <TextValidator
                lable={lang("PROFILE.NAME")}
                type="text"
                name="tenNhanVien"
                value={sendPeopleInfo?.tenNhanVien}
                readOnly={true}
              />
            </Col>
            <Col sm={3} md={3} lg={3} xl={2} xxl={2}>
              <TextValidator
                name="maNhanVien"
                lable={lang("PROFILE.CODE")}
                type="text"
                value={sendPeopleInfo?.maNhanVien}
                readOnly={true}
              />
            </Col>
            <Col sm={5} md={5} lg={5} xl={4}>
              <TextValidator
                name="donViCongTacText"
                lable={lang("GENERAL.CURRENT_ORGANIZATION")}
                type="text"
                value={sendPeopleInfo?.donViCongTacText}
                readOnly={true}
              />
            </Col>
            <Col sm={6} md={6} lg={6} xl={3} xxl={3}>
              <TextValidator
                name="phongBanText"
                lable={lang("INPUT.DEPARTMENTS")}
                type="text"
                value={sendPeopleInfo?.phongBanText}
                readOnly={true}
              />
            </Col>
            <Col sm={6} md={6} lg={6} xl={4}>
              <TextValidator
                name="chucDanhText"
                lable={lang("GENERAL.EMPLOYEE.TITLE")}
                type="text"
                value={sendPeopleInfo?.chucDanhText}
                readOnly={true}
              />
            </Col>
          </Row>
          <div className="spaces mb-4">
            <span className="fs-3 fw-bold">{lang("INPUT.CERTIFICATE.TITLE")}</span>
          </div>
          <Row className="g-4">
            <Col xs={4}>
              <TextValidator
                lable={useCustomIntl("INPUT.CERTIFICATE")}
                name="tenChungChi"
                value={formik.values?.tenChungChi || ""}
                type="text"
                isRequired
                onChange={formik.handleChange}
                touched={formik.touched?.tenChungChi}
                errors={formik.errors?.tenChungChi}
              />
            </Col>
            <Col xs={4}>
              <TextValidator
                lable={useCustomIntl("INFO.CERTIFICATE.NUMBER")}
                name="soCCHN"
                value={formik.values?.soCCHN || ""}
                type="text"
                isRequired
                onChange={formik.handleChange}
                touched={formik.touched?.soCCHN}
                errors={formik.errors?.soCCHN}
              />
            </Col>
            <Col xs={4}>
              <RangeDatePicker
                label={useCustomIntl("INPUT.CERTIFICATE.EFFECTIVE_TIME")}
                isRequired
                startDateName="thoiGianHieuLucTuNgay"
                endDateName="thoiGianHieuLucDenNgay"
                handleChange={formik.handleChange}
                value={formik.values}
                setFieldValue={formik.setFieldValue}
                touch={formik.touched}
                errors={formik.errors}
              />
            </Col>
            <Col xs={4}>
              <TextValidator
                lable={useCustomIntl("INFO.CERTIFICATE.SPECIALIZED_TRAINING")}
                name="chuyenNganh"
                value={formik.values?.chuyenNganh || ""}
                type="text"
                isRequired
                onChange={formik.handleChange}
                touched={formik.touched?.chuyenNganh}
                errors={formik.errors?.chuyenNganh}
              />
            </Col>
            <Col xs={4}>
              <Autocomplete
                lable={useCustomIntl("INPUT.CERTIFICATE.TRAINING_FACILITIES")}
                formCheckBox={true}
                menuPlacement="bottom"
                maxMenuHeight={180}
                value={
                  isCheckCoSoDaoTao
                    ? formik.values?.coSoDaoTaoKhac
                    : formik.values?.coSoDaoTao
                }
                name={
                  isCheckCoSoDaoTao
                    ? VARIABLE_STRING.CO_SO_DAO_TAO_KHAC
                    : VARIABLE_STRING.CO_SO_DAO_TAO
                }
                setIsCheckBox={setIsCheckCoSoDaoTao}
                isCheckBox={isCheckCoSoDaoTao}
                onChange={(selectedOption) =>
                  isCheckCoSoDaoTao
                    ? handleChange(
                      selectedOption,
                      VARIABLE_STRING.CO_SO_DAO_TAO
                    )
                    : handleChangeSelect(
                      VARIABLE_STRING.CO_SO_DAO_TAO,
                      selectedOption
                    )
                }
                options={[]}
                searchFunction={searchAllSimpleValue}
                searchObject={{ type: TYPE_CATEGORY.noiDaoTao }}
                getOptionLabel={(option) => option?.value}
                errors={formik.errors?.coSoDaoTao}
                touched={formik.touched?.coSoDaoTao}
              />
            </Col>
            <Col xs={4}>
              <TextValidator
                lable={useCustomIntl("INFO.CERTIFICATE.PROFESSIONAL_DIPLOMA")}
                name="vanBang"
                value={formik.values?.vanBang || ""}
                type="text"
                onChange={formik.handleChange}
                touched={formik.touched?.vanBang}
                errors={formik.errors?.vanBang}
              />
            </Col>
            <Col xs={4}>
              <TextValidator
                lable={useCustomIntl("INPUT.CERTIFICATE.DATE.PROVIDE")}
                name="ngayCap"
                value={formik.values?.ngayCap || ""}
                type="date"
                onChange={formik.handleChange}
                onBlur={() =>
                  handleBlurDate(
                    formik.setFieldValue,
                    formik.values?.ngayCap,
                    "ngayCap"
                  )
                }
                touched={formik.touched?.ngayCap}
                errors={formik.errors?.ngayCap}
              />
            </Col>
            <Col xs={4}>
              <TextValidator
                lable={useCustomIntl("INPUT.CERTIFICATE.PLACE")}
                name="noiCap"
                isRequired
                value={formik.values?.noiCap || ""}
                type="text"
                onChange={formik.handleChange}
                touched={formik.touched?.noiCap}
                errors={formik.errors?.noiCap}
              />
            </Col>
            <Col xs={4}>
              <UploadFile
                label="CONTRACT.ATTACHMENTS"
                setValue={handleFile}
                fileValue={{
                  id: formik.values.fileId || "",
                  name: formik.values.fileName || ""
                }}
              />
            </Col>
            <Col xs={6}>
              <TextValidator
                lable={useCustomIntl("INPUT.CERTIFICATE.RANGE_DESCRIPTION")}
                name="moTa"
                value={formik.values?.moTa || ""}
                type="text"
                as="textarea"
                rows="3"
                onChange={formik.handleChange}
                touched={formik.touched?.moTa}
                errors={formik.errors?.moTa}
              />
            </Col>
            <Col xs={6}>
              <TextValidator
                lable={useCustomIntl("INPUT.CERTIFICATE.NOTE")}
                name="note"
                value={formik.values?.note || ""}
                type="text"
                as="textarea"
                rows="3"
                onChange={formik.handleChange}
                touched={formik.touched?.note}
                errors={formik.errors?.note}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="flex-center py-3">
          <GroupButton type="btn-cancel" handleCloseUpdateDialog={handleCloseDialog} />
          <GroupButton type="btn-save" /> 
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

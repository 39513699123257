import { FC, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { RoleList } from "./components/RoleList";
import { UnitList } from "./components/UnitList";
import { UserList } from "./components/UserList";
import useMultiLanguage from "../../hook/useMultiLanguage";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../constants";
import { findMenuAccessAllowed, hasAuthority } from "../utils/functionUtils";
import { KEY_TAB, LIST_TAB } from "./constants/managerConsts";
import "./manager.scss"
import { AuthorityList } from "./components/AuthorityList";
import { SoftwareUsage } from "./components/SoftwareUsage";
import { KTSVG } from "../../../_metronic/helpers";

const Manager: FC = () => {
  const { lang } = useMultiLanguage();
  const defaultKey = useMemo(() => findMenuAccessAllowed(LIST_TAB)?.key, []);
  return (
    <Row className="manager spaces mt-16">
      <Tabs defaultActiveKey={defaultKey} id="uncontrolled-tab-example">
        {hasAuthority(PERMISSIONS.ROLE, PERMISSION_ABILITY.VIEW) &&
          <Tab
            eventKey={KEY_TAB.ROLE}
            title={
              <>
                <i className="bi bi-box-fill pe-2 text-primary"></i>
                <span>{lang("ROLE")}</span>
              </>
            }
          >
            <RoleList />
          </Tab>
        }
        {hasAuthority(PERMISSIONS.AUTHORITY, PERMISSION_ABILITY.VIEW) &&
          <Tab
            eventKey={KEY_TAB.AUTHORITY}
            title={
              <>
                <i className="bi bi-key-fill pe-2 text-primary"></i>
                <span>{lang("AUTH")}</span>
              </>
            }
          >
            <AuthorityList />
          </Tab>
        }
        {hasAuthority(PERMISSIONS.ORGANIZATION_MENU, PERMISSION_ABILITY.VIEW) &&
          <Tab
            eventKey={KEY_TAB.UNIT}
            title={
              <>
                <i className="bi bi-building pe-2 text-primary"></i>
                <span>{lang("UNIT")}</span>
              </>
            }
          >
            <Col xs={12}>
              <UnitList />
            </Col>
          </Tab>
        }
        {hasAuthority(PERMISSIONS.MANAGEMENT_UNIT, PERMISSION_ABILITY.VIEW) &&
          <Tab
            eventKey={KEY_TAB.USER}
            title={
              <>
                <i className="bi bi-person-fill pe-2 text-primary"></i>
                <span>{lang("USER")}</span>
              </>
            }
          >
            <Col xs={12}>
              <UserList />
            </Col>
          </Tab>
        }
        {hasAuthority(PERMISSIONS.SOFTWARE_USAGE, PERMISSION_ABILITY.VIEW) &&
          <Tab
            eventKey={KEY_TAB.SOFTWARE_USAGE}
            title={
              <>
                <KTSVG path="/media/icons/chart-line-up.svg" className="pe-2 text-primary" />
                <span>{lang("SOFTWARE_USAGE")}</span>
              </>
            }
          >
            <Col xs={12}>
              <SoftwareUsage />
            </Col>
          </Tab>
        }
      </Tabs>
    </Row>
  );
};
export { Manager };

import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { TransitionInFo } from "./models/transitionModels";
import { deleteTransfer, getListTransfer } from "./services/transitionServices";
import { checkStatus, exportToFile, formatDateTable, hasAuthority, searchDataRoleUser } from "../utils/functionUtils";
import { exportThuyenChuyen } from "../services/exportFileServices";
import TableCustom from "../components/table-custom/TableCustom";
import { RESPONSE_STATUS_CODE, TYPE, INIT_SEARCH_OBJECT, EXPORT_FILE_NAME, INIT_INPUT_SEARCH } from "../constants/moduleConsts";
import { TransitionForm } from "./components/TransitionForm";
import useMultiLanguage from "../../hook/useMultiLanguage";
import { Col, Row } from "react-bootstrap";
import InputSearch from "../components/input/InputSearch";
import AdvancedSearch from "../profile/components/dialogs/AdvancedSearch";
import { converDataUI } from "./utils/transitionUtils";
import { LIST_TRANSFER_STATUS, LIST_TRANSFER_STATUS_CODE, ListSearch } from "./constants/transitionConsts";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../constants";
import { convertSearch } from "../utils/functionUtils";
import AppContext from "../../AppContext";
import { GroupButton } from "../components/GroupButton";
import { useAuth } from "../auth";
import { IItemSearch } from "../models/models";

const Transition = () => {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);
  const { currentUser } = useAuth();
  document.title = `${lang("GENERAL.APP.NAME")} | ${lang("GENERAL.TRANSFER")}`;

  const [transition, setTransition] = useState<TransitionInFo>({} as TransitionInFo);
  const [listTransition, setListTransition] = useState<TransitionInFo[]>([]);
  const [shouldOpenTransitionDialog, setShouldOpenTransitionDialog] = useState<boolean>(false);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [searchObject, setSearchObject] = useState<any>(INIT_SEARCH_OBJECT);
  const [isView, setIsView] = useState<boolean>(false)
  const [isAdvancedSearch, setIsAdvancedSearch] = useState<boolean>(false);
  const [listInputSearch, setListInputSearch] = useState<any[]>([INIT_INPUT_SEARCH]);
  const [dataChecked, setDataChecked] = useState<TransitionInFo[]>([]);

  const handleOpenUpdateDialog = () => {
    setIsView(false);
  };

  const handleOpenInfoDialog = (row: any) => {
    setIsView(true);
    setShouldOpenTransitionDialog(true);
    setTransition(converDataUI(row));
  };

  const handleCloseAndSearch = () => {
    handleSearch();
    handleCloseDialog();
  }

  const handleCloseDialog = () => {
    setShouldOpenTransitionDialog(false);
    setTransition({} as TransitionInFo);
    setIsView(false);
  };

  const handleOpenDialog = () => {
    if (hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW)) {
      setTransition({ employee: currentUser } as TransitionInFo);
    }
    setShouldOpenTransitionDialog(true);
  };

  const updatePageData = async (searchData?: any) => {
    try {
      setSearchObject({ ...searchData });
      setPageLoading(true);
      const { data } = await getListTransfer(hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW) ? searchDataRoleUser(searchData) : { ...searchData });
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListTransition(data?.data?.content || []);
        setTotalPage(data?.data?.pageable?.totalPages);
        setTotalElements(data?.data?.pageable?.totalElements);
        setNumberOfElements(data?.data?.pageable?.numberOfElements);
        setIsAdvancedSearch(false);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleSearch = (data: any = {}) => {
    const dataSearch: any = {
      ...searchObject,
      ...data
    };
    dataSearch.keyword = dataSearch?.keyword?.trim() || ""
    updatePageData(dataSearch);
  };

  useEffect(() => {
    if (searchObject?.pageIndex && searchObject?.pageSize) {
      handleSearch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchObject?.pageIndex, searchObject?.pageSize])

  const handleOpenAdvancedSearch = () => {
    setIsAdvancedSearch(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchObject({
      ...searchObject,
      keyword: e.target?.value
    });
  };

  const handleAdvancedSearch = (data: IItemSearch[]) => {
    setListInputSearch(data);
    handleSearch({ ...convertSearch(data), pageIndex: 1 });
  };

  const handleDeleteTransfer = async (ids: string[]) => {
    try {
      const { data } = await deleteTransfer(ids)
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("GENERAL.DELETE_SUCCESS"))
        updatePageData(searchObject);
        return true;
      } else {
        toast.warning(data?.message);
      }
    }
    catch (err: any) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }

  const columns = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("GENERAL.STATUS"),
      field: "",
      headerStyle: {
        minWidth: "150px"
      },
      render: (row: any) => <span className={`${checkStatus(LIST_TRANSFER_STATUS, row?.trangThai?.code)} status`}>{row?.trangThai?.name}</span>
    },
    {
      name: lang("PROFILE.CODE"),
      field: "maNhanVien",
      headerStyle: {
        minWidth: "110px"
      },
      render: (row: any) => <span className="color-steel-blue fw-600">{row?.code}</span>
    },
    {
      name: lang("PROFILE.NAME"),
      field: "fullName",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        minWidth: "200px",
        textAlign: "left",
      },
    },
    {
      name: lang("INPUT.WORKEXPERIENCE.DATE.START"),
      field: "coHieuLucTuNgay",
      headerStyle: {
        minWidth: "120px"
      },
      render: (row: any) => <span>{formatDateTable(row?.coHieuLucTuNgay)}</span>
    },
    {
      name: lang("INPUT.WORKEXPERIENCE.DATE.END"),
      field: "coHieuLucDenNgay",
      headerStyle: {
        minWidth: "120px"
      },
      render: (row: any) => <span>{formatDateTable(row?.coHieuLucDenNgay)}</span>
    },
    {
      name: lang("INPUT.WORKPROCESS.UNIT.OLD"),
      field: "donViCongTacText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("INPUT.WORKPROCESS.DEPARTMENT.OLD"),
      field: "khoaPhongText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("RESIGN.OLDPOSITION"),
      field: "chucVuCuText",
      headerStyle: {
        minWidth: "200px",
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("INPUT.WORKPROCESS.UNIT.NEW"),
      field: "donViCongTacMoiText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("INPUT.WORKPROCESS.DEPARTMENT.NEW"),
      field: "khoaPhongMoiText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("RESIGN.NEWPOSITION"),
      field: "chucVuMoiText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("INPUT.WORKPROCESS.DECISION.NUMBER"),
      field: "soQuyetDinh",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        textAlign: "left"
      }
    },
  ]

  return (
    <div className="spaces px-8 py-12">
      {!shouldOpenTransitionDialog &&
        <>
          <Row className="spaces mb-12 flex-middle">
            <Col xs={5} xl={6} xxl={7} className="spaces p-0">
              <h2 className="breadcrumb-title">{lang("GENERAL.TRANSFER.LIST")}</h2>
            </Col>
            <Col xs={7} xl={6} xxl={5} className="flex">
              <div className="spaces mr-10 flex-1">
                <InputSearch
                  className="border-3px"
                  value={searchObject?.keyword}
                  placeholder="Tìm kiếm theo mã NV, họ và tên"
                  handleSearch={() => handleSearch({ pageIndex: 1 })}
                  handleChange={handleChange}
                  isEnter
                />
              </div>
              <GroupButton className="spaces mr-10" handleEvent={() => handleSearch({ pageIndex: 1 })}>
                {lang("BTN.SEARCH")}
              </GroupButton>
              <GroupButton className="spaces mr-10" outline handleEvent={handleOpenAdvancedSearch}>
                {lang("BTN.ADVANCE_SEARCH")}
              </GroupButton>
              {isAdvancedSearch && (
                <AdvancedSearch
                  open={isAdvancedSearch}
                  listInputSearch={listInputSearch}
                  handleSearch={handleAdvancedSearch}
                  listOption={ListSearch(lang)}
                  handleClose={() => setIsAdvancedSearch(false)}
                />
              )}
            </Col>
          </Row>
          <Row className="bg-white">
            <Col xs={12} className="table-scroll">
              <TableCustom
                id="transition-table"
                data={listTransition}
                columns={columns}
                searchObject={searchObject}
                setSearchObject={setSearchObject}
                buttonAdd={hasAuthority(PERMISSIONS.THUYEN_CHUYEN, PERMISSION_ABILITY.CREATE)}
                buttonExportExcel={!hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW)}
                notDelete={!hasAuthority(PERMISSIONS.THUYEN_CHUYEN, PERMISSION_ABILITY.DELETE)}
                handleExportExcel={(ids: string[]) =>
                  exportToFile({
                    exportAPI: () => exportThuyenChuyen({ ids, ...searchObject }),
                    fileName: EXPORT_FILE_NAME.LUAN_CHUYEN,
                    setPageLoading
                  })
                }
                type={TYPE.MULTILINE}
                fixedColumnsCount={4}
                totalPages={totalPage}
                totalElements={totalElements}
                numberOfElements={numberOfElements}
                handleOpenDialog={handleOpenDialog}
                handleDoubleClick={handleOpenInfoDialog}
                handleDelete={(ids: string[]) => handleDeleteTransfer(ids)}
                setDataChecked={setDataChecked}
                dataChecked={dataChecked}
                deleteConditional={[
                  {
                    keyPath: "trangThai.code",
                    value: LIST_TRANSFER_STATUS_CODE.PROCESSING,
                  },
                  {
                    keyPath: "trangThai.code",
                    value: LIST_TRANSFER_STATUS_CODE.COMPLETED,
                  }
                ]}
              />
            </Col>
          </Row>
        </>
      }
      {shouldOpenTransitionDialog && (
        <TransitionForm
          isView={isView}
          transferInfo={transition}
          handleCloseDialog={handleCloseDialog}
          handleCloseAndSearch={handleCloseAndSearch}
          handleOpenUpdateDialog={handleOpenUpdateDialog}
        />
      )}
    </div>
  )

};

export default Transition;

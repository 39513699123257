import { IColumnsGrouping } from "../../../components/table-grouping/TableGrouping";
import { TYPE } from "../../../constants/moduleConsts";

export const columnSalaryFund: IColumnsGrouping[] = [
  {
    name: "STT",
    field: "stt",
    isSticky: true,
    render: (row, index, stt) => <span>{stt}</span>
  },
  {
    name: "Tên đơn vị",
    field: "name",
    headerStyle: {
      minWidth: "250px"
    },
    cellStyle: {
      textAlign: "left"
    },
    isSticky: true
  },
  {
    name: "Năm sinh",
    field: "",
    child: [
      {
        name: "Nam",
        field: "maleBirthdate",
        headerStyle: {
          minWidth: "100px"
        },
        type: TYPE.DATE
      },
      {
        name: "Nữ",
        field: "femaleBirthdate",
        headerStyle: {
          minWidth: "100px"
        },
        type: TYPE.DATE
      }
    ]
  },
  {
    name: "Ngày tháng năm tuyển dụng",
    field: "ngayTuyenDung",
    headerStyle: {
      minWidth: "150px"
    },
    type: TYPE.DATE
  },
  {
    name: "Tên chức danh nghề nghiệp",
    field: "tenChucDanh",
    headerStyle: {
      minWidth: "250px"
    },
    cellStyle: {
      textAlign: "left"
    }
  },
  {
    name: "Mã số chức danh",
    field: "maSoChucDanh",
    headerStyle: {
      minWidth: "150px"
    },
    cellStyle: {
      textAlign: "left"
    }
  },
  {
    name: "Hệ số lương",
    field: "",
    child: [
      {
        name: "Hệ số lương theo ngạch bậc",
        field: "heSoBacLuong",
        headerStyle: {
          minWidth: "150px"
        },
        type: TYPE.NUMBER_FLOAT
      },
      {
        name: "PCTNVK/bảo lưu",
        field: "phuCapTnvk",
        headerStyle: {
          minWidth: "150px"
        },
        type: TYPE.NUMBER_FLOAT
      },
      {
        name: "Hệ số phụ cấp",
        field: "",
        child: [
          {
            name: "Chức vụ",
            field: "phuCapChucVu",
            headerStyle: {
              minWidth: "120px"
            },
            type: TYPE.NUMBER_FLOAT
          },
          {
            name: "Trách nhiệm",
            field: "phuCapTrachNhiem",
            headerStyle: {
              minWidth: "120px"
            },
            type: TYPE.NUMBER_FLOAT
          },
          {
            name: "Độc hại",
            field: "phuCapDocHai",
            headerStyle: {
              minWidth: "120px"
            },
            type: TYPE.NUMBER_FLOAT
          },
          {
            name: "PC ưu đãi",
            field: "phuCapUuDai",
            headerStyle: {
              minWidth: "120px"
            },
            type: TYPE.NUMBER_FLOAT
          },
          {
            name: "PC công vụ, PC & và CLBL",
            field: "phuCapKhac",
            headerStyle: {
              minWidth: "200px"
            },
            type: TYPE.NUMBER_FLOAT
          }
        ]
      }
    ]
  },
  {
    name: "Quỹ lương 1 tháng",
    field: "",
    child: [
      {
        name: "Tổng số",
        field: "tongSo",
        headerStyle: {
          minWidth: "100px"
        },
        type: TYPE.MONEY
      },
      {
        name: "Tiền lương theo ngạch bậc",
        field: "luongTheoNgachBac",
        headerStyle: {
          minWidth: "150px"
        },
        type: TYPE.MONEY
      },
      {
        name: "PCTNVK",
        field: "tienPhuCapTnvk",
        headerStyle: {
          minWidth: "100px"
        },
        type: TYPE.MONEY
      },
      {
        name: "Phụ cấp",
        field: "",
        child: [
          {
            name: "Tổng số phụ cấp",
            field: "tongSoPhuCap",
            headerStyle: {
              minWidth: "160px"
            },
            type: TYPE.MONEY
          },
          {
            name: "Chia ra",
            field: "",
            child: [
              {
                name: "Chức vụ",
                field: "tienPhuCapChucVu",
                headerStyle: {
                  minWidth: "120px"
                },
                type: TYPE.MONEY
              },
              {
                name: "Trách nhiệm",
                field: "tienPhuCapTrachNhiem",
                headerStyle: {
                  minWidth: "120px"
                },
                type: TYPE.MONEY
              },
              {
                name: "Độc hại",
                field: "tienPhuCapDocHai",
                headerStyle: {
                  minWidth: "120px"
                },
                type: TYPE.MONEY
              },
              {
                name: "PC ưu đãi",
                field: "tienPhuCapUuDai",
                headerStyle: {
                  minWidth: "120px"
                },
                type: TYPE.MONEY
              },
              {
                name: "PC công vụ, PC &  và CLBL",
                field: "tienPhuCapKhac",
                headerStyle: {
                  minWidth: "200px"
                },
                type: TYPE.MONEY
              }
            ]
          }
        ]
      }
    ]
  },
  {
    name: "Ghi chú",
    field: "ghiChu",
    headerStyle: {
      minWidth: "100px"
    }
  }
];

import { COMPLETED_STATUS } from '../../constants/moduleConsts';
import { TYPE } from "../../constants/moduleConsts";
import { TYPE_CATEGORY } from '../../constants/moduleConsts';
import { searchAllSimpleValue, searchListWorkUnit, searchPhongBan } from '../../services/services';
import { IDismissed } from '../models/dismissedModels';
import { ILang } from '../../../hook/useMultiLanguage';

export const INIT_DISMISSED: IDismissed = {
  id: "",
  fullName: "",
  maNhanVien: "",
  employeeId: "",
  employee: null,
  donViCongTacId: "",
  donViCongTacText: "",
  chucVuId: "",
  chucVuText: "",
  phongBanId: "",
  phongBanText: "",
  thoiGianHieuLucTuNgay: "",
  thoiGianHieuLucDenNgay: "",
  fileId: "",
  fileName: "",
  ghiChu: "",
  donViCongTacMoiId: "",
  donViCongTacMoiText: "",
  donViCongTacMoi: null,
  phongBanMoiId: "",
  phongBanMoiText: "",
  phongBanMoi: null,
  chucVuMoiId: "",
  chucVuMoiText: "",
  chucVuMoi: null,
  ngayQuyetDinh: "",
  soQuyetDinh: "",
  isConfirmed : false,
  trangThai: null,
  xacNhanMienNhiem: false,
  nguoiKy: null,
  nguoiKyId: "",
  nguoiKyText: "",
  thoiGiaoNhiemVuType: null
}

export const ListSearch = (lang: ILang) => {
  return [
    {
      name: lang("GENERAL.STATUS"),
      field: "trangThai",
      type: TYPE.SELECT,
      listOption: COMPLETED_STATUS,
      isMulti: true,
    },
    {
      name: lang("PROFILE.CODE"),
      field: "maNhanVien",
      type: TYPE.TEXT,
    },
    {
      name: lang("PROFILE.NAME"),
      field: "ten",
      type: TYPE.TEXT,
    },
    {
      name: lang("GENERAL.CURRENT_ORGANIZATION"),
      field: "donViCongTacId",
      searchFunction: searchListWorkUnit,
      type: TYPE.SELECT,
    },
    {
      name: lang("INPUT_DISMISSED_UNIT_DEPARTMENT"),
      field: "phongBanIds",
      searchFunction: searchPhongBan,
      type: TYPE.SELECT,
      isMulti: true,
    },
    {
      name: lang("INPUT_DISMISSED_JOB_POSITION"),
      field: "chucVuId",
      searchFunction: searchAllSimpleValue,
      searchObject: { type: TYPE_CATEGORY.chucDanh },
      type: TYPE.SELECT,
      optionLabel: "value"
    },
    {
      name: lang("INPUT_DISMISSED_WORK_UNIT_NEW"),
      field: "donViCongTacMoiId",
      searchFunction: searchListWorkUnit,
      type: TYPE.SELECT,
    },
    {
      name: lang("INPUT_DISMISSED_UNIT_DEPARTMENT_NEW"),
      field: "phongBanMoiIds",
      searchFunction: searchPhongBan,
      type: TYPE.SELECT,
      isMulti: true,
    },
    {
      name: lang("INPUT_DISMISSED_JOB_POSITION_NEW"),
      field: "chucVuMoiId",
      searchFunction: searchAllSimpleValue,
      searchObject: { type: TYPE_CATEGORY.chucDanh },
      type: TYPE.SELECT,
      optionLabel: "value"
    },
    {
      name: lang("INPUT.WORKPROCESS.DECISION.NUMBER"),
      field: "soQuyetDinh",
      type: TYPE.TEXT,
    },
  ]
}

export const DISMISS = {
  STATUS: {
    PROCESSING: 2,
    FINISHED: 3,
    UNFINISH: 1
  }
}

export const LIST_DISMISSED_TYPE = [
  {
    code: 1,
    name: "Thôi giao phụ trách"
  },
  {
    code: 2,
    name: "Thôi giao công việc"
  }
]
import React, { useContext, useEffect, useState } from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import {
  DECLARE_ASSETS_STATUS,
  DECLARE_ASSETS_STATUS_CODES,
  ListSearch,
  TAB_KEY
} from "./constants/declareAssetsConsts";
import { IItemSearch, IObject } from "../models/models";
import "./declare-assets.scss";
import { convertSearch, formatDateTable, hasAuthority } from "../utils/functionUtils";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../constants";
import TableCustom, { columnNamesType } from "../components/table-custom/TableCustom";
import InputSearch from "../components/input/InputSearch";
import { RESPONSE_STATUS_CODE, TYPE, INIT_SEARCH_OBJECT, INIT_INPUT_SEARCH } from "../constants/moduleConsts";
import AdvancedSearch from "../profile/components/dialogs/AdvancedSearch";
import DeclareAssetsForm from "./components/DeclareAssetsForm";
import { IDeclareAssets } from "./models/declareAssetsModels";
import useMultiLanguage from "../../hook/useMultiLanguage";
import AppContext from "../../AppContext";
import { toast } from "react-toastify";
import { deleteDeclareAssets, searchDeclareAssets } from "./services/declareAssetsServices";
import { GroupButton } from "../components/GroupButton";

function DeclareAssets() {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [activeTab, setActiveTab] = useState<string>(TAB_KEY.CHUA_GUI);
  const [searchObject, setSearchObject] = useState<IObject>(INIT_SEARCH_OBJECT);
  const [isAdvancedSearch, setIsAdvancedSearch] = useState<boolean>(false);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [listData, setListData] = useState<IDeclareAssets[]>([]);
  const [dataEdit, setDataEdit] = useState<IDeclareAssets>({} as IDeclareAssets);
  const [listInputSearch, setListInputSearch] = useState<IItemSearch[]>([INIT_INPUT_SEARCH]);
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [dataChecked, setDataChecked] = useState<IDeclareAssets[]>([]);

  useEffect(() => {
    let status = DECLARE_ASSETS_STATUS.find((item) => item.key === activeTab);
    updatePageData({ trangThai: status?.code });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchObject?.pageIndex, searchObject?.pageSize, activeTab]);

  const updatePageData = async (searchData?: IObject) => {
    try {
      setPageLoading(true);
      let dataSearch = { ...searchObject, ...searchData };
      setSearchObject(dataSearch);
      const { data } = await searchDeclareAssets(dataSearch);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListData(data?.data?.content || []);
        setTotalPage(data?.data?.pageable?.totalPages);
        setTotalElements(data?.data?.pageable?.totalElements);
        setNumberOfElements(data?.data?.pageable?.numberOfElements);
        setIsAdvancedSearch(false);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleTabChange = (key: string | null) => {
    setSearchObject({});
    key && setActiveTab(key);
  };

  const handleChangeKeyWord = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchObject({ ...searchObject, keyword: event.target.value });
  };

  const handleAdvancedSearch = (data: IItemSearch[]) => {
    setListInputSearch(data);
    handleSearch({ ...convertSearch(data), pageIndex: 1 });
  };

  const handleSearch = (data: any = {}) => {
    const dataSearch: any = {
      ...searchObject,
      ...data
    };
    dataSearch.keyword = dataSearch?.keyword?.trim() || "";
    updatePageData(dataSearch);
  };

  const handleOpenDialog = (row: IDeclareAssets) => {
    setIsOpenDialog(true);
    setDataEdit(row);
  };

  const handleCloseDialog = () => {
    setIsOpenDialog(false);
  };

  const handleDelete = async (ids: string) => {
    try {
      setPageLoading(true);
      const { data } = await deleteDeclareAssets(ids);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("TOAST.DELETE.SUCCESS"));
        updatePageData();
        return true;
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const matchTitle = () => {
    switch (activeTab) {
      case TAB_KEY.CHUA_GUI:
        return lang("DECLARE_ASSETS.STATUS.NOT_SEND");
      case TAB_KEY.CHO_PHE_DUYET:
        return lang("DECLARE_ASSETS.STATUS.PENDING");
      case TAB_KEY.DA_PHE_DUYET:
        return lang("DECLARE_ASSETS.STATUS.APPROVED");
      case TAB_KEY.TU_CHOI:
        return lang("DECLARE_ASSETS.STATUS.REJECT");
      default:
        return lang("DECLARE_ASSETS.STATUS.NOT_SEND");
    }
  };

  const handleCloseAndSearch = () => {
    handleSearch();
    handleCloseDialog();
  }

  const columns: columnNamesType[] = [
    {
      name: "STT",
      field: "stt",
      headerStyle: {
        width: "50px",
        minWidth: "50px"
      },
      render: (_, index, stt) => <span>{stt}</span>
    },
    {
      name: lang("STATUS"),
      field: "trangThai",
      headerStyle: {
        width: "160px",
        minWidth: "160px"
      },
      render: (row) => <span className="status bg-gray-700">{row?.trangThai?.name}</span>
    },
    {
      name: lang("PROFILE.CODE"),
      field: "maNhanVien",
      headerStyle: {
        width: "110px",
        minWidth: "110px"
      },
      render: (row: any) => <span className="color-steel-blue fw-600">{row?.maNhanVien}</span>
    },
    {
      name: lang("INPUT.EMPLOYEE"),
      field: "tenNhanVien",
      headerStyle: {
        width: "200px",
        minWidth: "200px",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang("GENERAL.CURRENT_ORGANIZATION"),
      field: "donViCongTacText",
      headerStyle: {
        minWidth: "250px",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang("INPUT.DEPARTMENTS"),
      field: "phongBanText",
      headerStyle: {
        minWidth: "250px",
      },
      cellStyle: {
        textAlign: "left",
      }
    },
    {
      name: lang("DECLARE_ASSETS.TYPE"),
      field: "loaiKeKhai",
      headerStyle: {
        minWidth: "200px",
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row) => <span>{row?.loaiKeKhai?.name}</span>
    },
    {
      name: lang("DECLARE_ASSETS.DECLARE_DATE"),
      field: "ngayKeKhai",
      headerStyle: {
        minWidth: "120px"
      },
      render: (row) => <span>{formatDateTable(row?.ngayKeKhai)}</span>
    },
  ];

  return (
    <div className="declare-assets spaces mt-16">
      {!isOpenDialog ? (
        <>
          <Tabs
            activeKey={activeTab}
            defaultActiveKey={TAB_KEY.CHUA_GUI}
            className="tabs-user-info"
            onSelect={handleTabChange}
          >
            <Tab
              eventKey={TAB_KEY.CHUA_GUI}
              title={lang("DECLARE_ASSETS.STATUS.NOT_SEND")}
              className="tab-content"
              id={TAB_KEY.CHUA_GUI}
              mountOnEnter
            ></Tab>
            <Tab
              eventKey={TAB_KEY.CHO_PHE_DUYET}
              title={lang("DECLARE_ASSETS.STATUS.PENDING")}
              className="tab-content"
              id={TAB_KEY.CHO_PHE_DUYET}
              mountOnEnter
              disabled
            ></Tab>
            <Tab
              eventKey={TAB_KEY.DA_PHE_DUYET}
              title={lang("DECLARE_ASSETS.STATUS.APPROVED")}
              className="tab-content"
              id={TAB_KEY.DA_PHE_DUYET}
              mountOnEnter
              disabled
            ></Tab>
            <Tab
              eventKey={TAB_KEY.TU_CHOI}
              title={lang("DECLARE_ASSETS.STATUS.REJECT")}
              className="tab-content"
              id={TAB_KEY.TU_CHOI}
              mountOnEnter
              disabled
            ></Tab>
          </Tabs>
          <div className="spaces px-10 py-16 bg-white declare-assets__content">
            <Row className="spaces mb-8 flex-middle ml-10">
              <Col xs={4} xxl={6} className="spaces p-0">
                <h2 className="breadcrumb-title">Danh sách đơn kê khai tài sản {matchTitle()}</h2>
              </Col>
              <Col xs={8} xxl={6} className="flex">
                <div className="spaces mr-10 flex-1">
                  <InputSearch
                    className="border-3px"
                    value={searchObject?.keyword}
                    placeholder="Tìm kiếm theo mã NV, họ và tên, phòng ban, chức vụ và chức danh"
                    isEnter={true}
                    handleSearch={() => handleSearch({ pageIndex: 1 })}
                    handleChange={handleChangeKeyWord}
                  />
                </div>
                <GroupButton className="spaces mr-10" handleEvent={() => handleSearch({ pageIndex: 1 })}>
                  {lang("BTN.SEARCH")}
                </GroupButton>
                <GroupButton className="spaces mr-10" outline handleEvent={() => setIsAdvancedSearch(true)}>
                  {lang("BTN.ADVANCE_SEARCH")}
                </GroupButton>
                {isAdvancedSearch && (
                  <AdvancedSearch
                    open={isAdvancedSearch}
                    listInputSearch={listInputSearch}
                    handleSearch={handleAdvancedSearch}
                    listOption={ListSearch(lang)}
                    handleClose={() => setIsAdvancedSearch(false)}
                  />
                )}
              </Col>
            </Row>
            <div className="spaces flex-1 pb-18 table-custom">
              <TableCustom
                id="declareAssets"
                data={listData || []}
                columns={columns}
                searchObject={searchObject}
                setSearchObject={setSearchObject}
                buttonAdd={hasAuthority(PERMISSIONS.KE_KHAI_TAI_SAN_MENU, PERMISSION_ABILITY.CREATE)}
                notDelete={!hasAuthority(PERMISSIONS.KE_KHAI_TAI_SAN_MENU, PERMISSION_ABILITY.DELETE)}
                type={TYPE.MULTILINE}
                fixedColumnsCount={4}
                totalPages={totalPage}
                totalElements={totalElements}
                numberOfElements={numberOfElements}
                handleOpenDialog={handleOpenDialog}
                handleDoubleClick={handleOpenDialog}
                handleDelete={handleDelete}
                setDataChecked={setDataChecked}
                dataChecked={dataChecked}
                deleteConditional={[
                  {
                    keyPath: "trangThai.code",
                    value: DECLARE_ASSETS_STATUS_CODES.CHO_PHE_DUYET
                  },
                  {
                    keyPath: "trangThai.code",
                    value: DECLARE_ASSETS_STATUS_CODES.DA_PHE_DUYET
                  },
                  {
                    keyPath: "trangThai.code",
                    value: DECLARE_ASSETS_STATUS_CODES.TU_CHOI
                  }
                ]}
              />
            </div>
          </div>
        </>
      ) : (
        <DeclareAssetsForm handleClose={handleCloseDialog} handleCloseAndSearch={handleCloseAndSearch} editId={dataEdit?.id} />
      )}
    </div>
  );
}

export default DeclareAssets;

import { PERMISSIONS, PERMISSION_ABILITY } from "../../../constants"
import { IAuth, IRole, IUser, IuserByUnit } from "../models/managerModels";

export const KEY_TAB = {
  ROLE: "role",
  AUTHORITY: "authority",
  UNIT: "unit",
  USER: "user",
  SOFTWARE_USAGE: "softwareUsage"
}

export const LIST_TAB = [
  {
    key: KEY_TAB.ROLE,
    permission: PERMISSIONS.ROLE,
    ability: PERMISSION_ABILITY.VIEW
  },
  {
    key: KEY_TAB.AUTHORITY,
    permission: PERMISSIONS.AUTHORITY,
    ability: PERMISSION_ABILITY.VIEW
  },
  {
    key: KEY_TAB.UNIT,
    permission: PERMISSIONS.ORGANIZATION_MENU,
    ability: PERMISSION_ABILITY.VIEW
  },
  {
    key: KEY_TAB.USER,
    permission: PERMISSIONS.MANAGEMENT_UNIT,
    ability: PERMISSION_ABILITY.VIEW
  },
  {
    key: KEY_TAB.SOFTWARE_USAGE,
    permission: PERMISSIONS.SOFTWARE_USAGE,
    ability: PERMISSION_ABILITY.VIEW
  }
]

export const INIT_USER: IuserByUnit = {
  id: "",
  user: {
    username: "",
    password: "",
    confirmPassword: "",
    email: "",
    phone: "",
    roles: [],
    organization: null
  } as IUser,
  employee: null,
  organization: null,
  orgId: "",
  createDate: "",
  createdBy: "",
  modifiedBy: "",
  modifyDate: ""
};

export const INIT_AUTH: IAuth = {
  id: "",
  name: "",
  code: ""
};

export const INIT_ROLE: IRole = {
  id: "",
  orgId: "",
  name: "",
  description: "",
  authorities: []
};

export const TYPE_SOFTWARE_USAGE = 18;

export const LIST_STATUS_SOFTWARE_USAGE = [
  {
    maxValue: 0.3,
    className: "bg-coral"
  },
  {
    maxValue: 0.5,
    className: "bg-orange-dark"
  },
  {
    maxValue: 0.7,
    className: "bg-amber"
  },
  {
    maxValue: 1,
    className: "bg-green"
  },
];
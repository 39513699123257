import {
  Children,
  ReactElement,
  cloneElement,
  forwardRef,
  isValidElement,
  useCallback,
  useEffect,
  useMemo,
  useRef
} from 'react';
import { WithChildren } from '../../../../_metronic/helpers';
import { DIRECTION } from './resizableConsts';
import "./resizable.scss";

interface IProps extends WithChildren {
  direction?: "horizontal" | "vertical";
  className?: string;
  resize?: boolean;
}

function ResizableGroup({ children, direction = "horizontal", resize = true, className }: IProps, parentRef: React.ForwardedRef<HTMLDivElement>) {
  const panelsRefs = useRef<HTMLDivElement[]>([]);
  const isHorizontal = useMemo(() => direction === DIRECTION.HORIZONTAL, [direction]);

  const handler = useCallback((mouseDownEvent: React.MouseEvent<HTMLDivElement, MouseEvent>, panelKey: number) => {
    const child = Children.toArray(children)[panelKey];
    const currentSize = (panelsRefs.current[panelKey]).style.flexBasis ?? 0;
    const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };
    const lastElement = panelsRefs.current[panelsRefs.current.length - 1];
    const lastElementWidth = lastElement.style.width;

    function onMouseMove(mouseMoveEvent: MouseEvent) {
      if (isValidElement(child)) {
        const delta = isHorizontal ? (startPosition.x - mouseMoveEvent.pageX) : (startPosition.y - mouseMoveEvent.pageY);

        if (child.props.initialStyle.flexBasis.endsWith("px")) {
          const newSize = Number(currentSize.replace(/px/g, "").replace(/calc/g, "").replace(/\(/g, "").replace(/\)/g, "")) - delta;
          
          if (newSize < child.props.initialStyle.flexBasis.replace(/px/g, "")
            || newSize > parseInt(child.props.initialStyle[isHorizontal ? "maxWidth" : "maxHeight"])) return;

          panelsRefs.current[panelKey].style.flexBasis = `calc(${currentSize} - ${delta}px)`;
        } else {
          const newSize = `calc(${currentSize} - ${delta}px)`
                            .replace(/px/g, "").replace(/calc/g, "")
                            .replace(/\(/g, "").replace(/\)/g, "")
                            .replace(child.props.initialStyle.flexBasis, "");
          const numbers = newSize.match(/[+-] ?\d+(\.\d+)?/g);
          const result = numbers?.reduce((accumulator: number, currentValue: string) => {
            return delta > 0 ? (accumulator + Number(currentValue.replace(" ", "")))
              : (accumulator - Number(currentValue));
          }, 0);

          if (Number(result) < 0 || Number(result) > parseInt(child.props.initialStyle[isHorizontal ? "maxWidth" : "maxHeight"])) return;
          panelsRefs.current[panelKey].style.flexBasis = `calc(${currentSize} - ${delta}px)`;
        }

        if (isHorizontal) {
          lastElement.style.width = `calc(${lastElementWidth} + ${delta}px)`;
        }
      }
    }

    function onMouseUp() {
      document.body.removeEventListener("mousemove", onMouseMove);
    }

    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("mouseup", onMouseUp);
  }, [children, isHorizontal]);

  useEffect(() => {
    if (isHorizontal) {
      const totalWidth = panelsRefs.current.slice(0, -1).reduce((acc, child: HTMLElement) =>
        acc + child.offsetWidth + (child.nextSibling as HTMLElement)?.offsetWidth
        , 0);
      panelsRefs.current[panelsRefs.current.length - 1].style.width = `calc(100% - ${totalWidth}px)`;
    }
  }, [isHorizontal]);

  return (
    <div ref={parentRef} className={`resizable-group ${isHorizontal ? "flex" : "flex-column"} ${className}`}>
      {Children.map(children, (child, index) => {
        if (isValidElement(child)) {
          return (
            <>
              {cloneElement(child as ReactElement, {
                ref: (ref: HTMLDivElement) => (panelsRefs.current[index] = ref),
                isHorizontal
              })}
              {index !== Children.count(children) - 1 && (
                <div className={`resizer ${direction} ${resize && "active"}`} onMouseDown={(event) => resize ? handler(event, index) : () => { }}>
                  <div className="resizer-bar"></div>
                </div>
              )}
            </>
          );
        }
        return child;
      })}
    </div>
  );
}

export default forwardRef<HTMLDivElement, IProps>(ResizableGroup);
import { FC, useContext, useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import * as Yup from "yup";
import TextValidator from "../../components/input/text-validator";
import { IApprovalConfig } from "../models/categoryModels";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import AppContext from "../../../AppContext";
import { addApprovalConfig, updateApprovalConfig } from "../services/approvalConfigServices";
import { RESPONSE_STATUS_CODE } from "../../constants/moduleConsts";
import { INIT_APPROVAL_CONFIG, LIST_APPROVING } from "../constants/approvalConfigConsts";
import { GroupButton } from "../../components/GroupButton";
import { hasAuthority } from "../../utils/functionUtils";
import { PERMISSION_ABILITY, PERMISSIONS } from "../../../constants";
import Autocomplete from "../../components/input/autocomplete";
import {LIST_LOAI_DON} from "../../manager-request/constants/managerRequestConsts";
import { heightSelectMutil } from "../../components/input/autocomplete/styleJS";

interface IProps {
  open: boolean;
  handleClose: () => void;
  handleCloseAndSearch: () => void;
  approvalConfigInfo: IApprovalConfig;
  isView: boolean;
}

const ApprovalConfigForm: FC<IProps> = (props) => {
  const {
    open,
    handleClose,
    handleCloseAndSearch,
    approvalConfigInfo,
    isView
  } = props;

  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);
  const [isEdit, setIsEdit] = useState<boolean>(!isView);

  useEffect(() => {
    approvalConfigInfo?.id && formik.setValues({ ...formik.values, ...approvalConfigInfo });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approvalConfigInfo]);

  const validationSchema = Yup.object().shape({
    letterType: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    approvingInfo: Yup.array().min(1, lang("VALIDATION.REQUIRE")),
  });

  const handleSubmit = async (values: IApprovalConfig) => {
    try {
      setPageLoading(true);
      const { data } = approvalConfigInfo?.id
        ? await updateApprovalConfig(approvalConfigInfo.id, values)
        : await addApprovalConfig(values);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(
          approvalConfigInfo.id
            ? lang("TOAST.EDIT.SUCCESS")
            : lang("TOAST.CREATE.SUCCESS")
        );
        handleCloseAndSearch();
      } else {
        toast.warning(data?.message);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false)
    }
  };

  const formik = useFormik({
    initialValues: INIT_APPROVAL_CONFIG,
    validationSchema,
    onSubmit: handleSubmit
  });

  const handleChangeSelect = (name: string, value: any) => {
    formik.setFieldValue(name, value);
  };

  return (
    <Modal
      show={open}
      onHide={handleClose}
      backdrop="static"
      size="lg"
      centered
    >
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>
            {approvalConfigInfo?.id ? lang("APPROVAL_CONFIG.EDIT") : lang("APPROVAL_CONFIG.ADD")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="g-4 relative spaces z-index-4">
            <Col sm={4} md={4} lg={6}>
              <Autocomplete
                isRequired
                name="letterType"
                lable={lang("APPROVAL_CONFIG.LETTER_TYPE")}
                options={LIST_LOAI_DON}
                onChange={(selectedOption: any) => handleChangeSelect("letterType", selectedOption)}
                value={formik.values?.letterType}
                isReadOnly={!isEdit}
                errors={formik.errors?.letterType}
                touched={formik.touched?.letterType}
              />
            </Col>
            <Col sm={4} md={4} lg={6}>
              <Autocomplete
                isRequired
                lable={lang("APPROVAL_CONFIG.APPROVING_INFO")}
                closeMenuOnSelect={false}
                name="approvingInfo"
                value={formik.values?.approvingInfo || []}
                options={LIST_APPROVING}
                isMulti={true}
                onChange={(selectedOption: any) => handleChangeSelect("approvingInfo", selectedOption)}
                isReadOnly={!isEdit}
                styles={heightSelectMutil("auto")}
                errors={formik.errors?.approvingInfo}
                touched={formik.touched?.approvingInfo}
              />
            </Col>
            <Col>
              <TextValidator
                name="note"
                lable={lang("GENERAL.NOTE")}
                type="text"
                onChange={formik.handleChange}
                value={formik.values?.note}
                readOnly={!isEdit}
                errors={formik.errors?.note}
                touched={formik.touched?.note}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          {(isEdit ? (
              <GroupButton type="btn-save" />
            ) : (hasAuthority(PERMISSIONS["DANH_MUC.CHAM_CONG.CAU_HINH_DUYET"], PERMISSION_ABILITY.UPDATE) &&
              <GroupButton type="btn-edit" handleSaveEdit={() => setIsEdit(true)} />
          ))}
          <GroupButton
            type="btn-cancel"
            handleCloseUpdateDialog={handleClose}
          />
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default ApprovalConfigForm;

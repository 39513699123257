import { useFormik } from "formik";
import { FC, useEffect, useMemo, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { IRole, IuserByUnit } from "../../models/managerModels";
import { addNewUser, searchAllRole, updateUser } from "../../services/managerServices";
import Autocomplete from "../../../components/input/autocomplete";
import TextValidator from "../../../components/input/text-validator";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import { PERMISSIONS, PERMISSION_ABILITY, ROLE } from "../../../../constants";
import { INIT_SEARCH_OBJECT, REGEX, RESPONSE_STATUS_CODE, VARIABLE_STRING } from "../../../constants/moduleConsts";
import { GroupButton } from "../../../components/GroupButton";
import { checkObject, hasAuthority } from "../../../utils/functionUtils";
import { INIT_USER } from "../../constants/managerConsts";
import { searchAllEmployee, searchListWorkUnit } from "../../../services/services";

interface IProps {
  handleCloseDialog: () => void;
  userInfo: IuserByUnit;
  handleCloseAndSearch: () => void;
}

const UserDialog: FC<IProps> = (props) => {
  const { handleCloseDialog, userInfo, handleCloseAndSearch } = props;
  const { lang } = useMultiLanguage();
  const [isSelectRoleAdmin, setIsSelectRoleAdmin] = useState<boolean>(false);
  const [listRole, steListRole] = useState([]);

  const isUpdate = useMemo(() => hasAuthority(PERMISSIONS.MANAGEMENT_UNIT, PERMISSION_ABILITY.UPDATE), []);

  const validationSchema = Yup.object().shape({
    employee: isSelectRoleAdmin ? Yup.object().nullable() : Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    user: Yup.object().shape({
      organization: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
      email: Yup.string().required(lang("VALIDATION.REQUIRE")).email(lang("VALIDATION.EMAILINVALID")).nullable(),
      phone: Yup.string().required(lang("VALIDATION.REQUIRE")).matches(REGEX.CHECK_PHONE, lang("VALIDATION.ISPHONE")).nullable(),
      password: !userInfo?.user?.id
        ? Yup.string().required(lang("VALIDATION.REQUIRE")).min(6, lang("VALIDATION.PASSWORD.MIN_LENGTH")).nullable()
        : Yup.string().min(6, lang("VALIDATION.PASSWORD.MIN_LENGTH")).nullable(),
      confirmPassword: !userInfo?.user?.id
        ? Yup.string()
            .required(lang("VALIDATION.REQUIRE"))
            .oneOf([Yup.ref("password"), null], lang("PASSWORD_INVALID"))
            .nullable()
        : Yup.string().oneOf([Yup.ref("password"), null], lang("PASSWORD_INVALID")).nullable(),
      username: Yup.string().required(lang("VALIDATION.REQUIRE")).matches(REGEX.AZ_09, lang("VALIDATE.USER.USERNAME.ERR")).nullable(),
      roles: Yup.array().of(Yup.object().required(lang("VALIDATION.REQUIRE")).nullable()).min(1, lang("VALIDATION.REQUIRE")).nullable()
    })
  });

  const handleSubmit = async (values: IuserByUnit) => {
    const data: any = {
      ...values,
      user: {
        ...values.user,
        accountNonExpired: true,
        accountNonLocked: true,
        active: true,
        changePassword: userInfo?.user?.id ? false : true,
        credentialsNonExpired: true,
        justCreated: true,
        roles: [values.user?.roles[0]?.name]
      },
      orgId: values?.user?.organization?.id,
      employeeId: formik.values?.employee?.id
    };
    try {
      const res = userInfo?.user?.id ? await updateUser(userInfo?.user?.id, data) : await addNewUser(data);
      if (res?.data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(userInfo?.user?.id ? lang("TOAST.EDIT.SUCCESS") : lang("TOAST.CREATE.SUCCESS"));
        handleCloseAndSearch();
      } else {
        toast.warning(`${res?.data?.message}`);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const formik = useFormik({
    initialValues: INIT_USER,
    validationSchema,
    onSubmit: handleSubmit
  });

  const searchRoles = async () => {
    try {
      const { data } = await searchAllRole();
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        steListRole(data?.data?.content ?? []);
        !hasAuthority(PERMISSIONS.ROLE, PERMISSION_ABILITY.ALL) && checkObject(userInfo) 
          && formik.setFieldValue("user.roles", [data?.data?.content?.find((role: IRole) => role?.name === ROLE.USER)]);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }

  useEffect(() => {
    searchRoles();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userInfo?.user?.id) {
      formik?.setValues(userInfo);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  useEffect(() => {
    setIsSelectRoleAdmin(formik?.values?.user?.roles?.[0]?.name === ROLE.ADMIN);
  }, [formik?.values?.user?.roles]);

  const handleChangeSelect = (name: string, data: any) => {
    formik.setFieldValue(name, data);
  };

  return (
    <>
      <Modal
        show={true}
        onHide={handleCloseDialog}
        backdrop="static"
        size="xl"
        centered
      >
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>
              {lang(!userInfo?.user?.id ? "CATEGORY.ADD" : "CATEGORY.EDIT")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="px-6">
              <Col xs={4}>
                <TextValidator
                  isRequired
                  name="user.email"
                  lable={lang("USER.EMAIL")}
                  type={"text"}
                  isReadOnly={!isUpdate}
                  onChange={formik.handleChange}
                  value={formik?.values?.user?.email}
                  errors={formik?.errors?.user?.email}
                  touched={formik?.touched?.user?.email}
                />
              </Col>
              <Col xs={4}>
                <TextValidator
                  isRequired
                  name="user.phone"
                  lable={lang("USER.PHONE")}
                  type={"text"}
                  isReadOnly={!isUpdate}
                  onChange={formik.handleChange}
                  value={formik?.values?.user?.phone}
                  errors={formik?.errors?.user?.phone}
                  touched={formik?.touched?.user?.phone}
                />
              </Col>
              <Col xs={4}>
                <Autocomplete
                  isRequired
                  isReadOnly={!isUpdate}
                  lable={lang("UNIT")}
                  options={[]}
                  value={formik?.values?.user?.organization || null}
                  name="user.organization"
                  onChange={(selectedOption) => handleChangeSelect("user.organization", selectedOption)}
                  searchFunction={searchListWorkUnit}
                  errors={formik.errors?.user?.organization}
                  touched={formik.touched?.user?.organization}
                />
              </Col>
            </Row>
            <Row className="px-6">
              <Col
                xs={4}
                className="pt-4"
              >
                <TextValidator
                  name="user.username"
                  isReadOnly={!isUpdate}
                  lable={lang("USER.ACCOUNT")}
                  type={"text"}
                  onChange={formik.handleChange}
                  value={formik?.values?.user?.username}
                  errors={formik?.errors?.user?.username}
                  touched={formik?.touched?.user?.username}
                  isRequired
                />
              </Col>
              {(!userInfo?.user?.id || hasAuthority(PERMISSIONS.CHANGE_PASSWORD, PERMISSION_ABILITY.VIEW)) && (
                <>
                  <Col
                    xs={4}
                    className="pt-4"
                  >
                    <TextValidator
                      name="user.password"
                      isReadOnly={!isUpdate}
                      lable={lang("USER.PASSWORD")}
                      type={"password"}
                      onChange={formik.handleChange}
                      value={formik?.values?.user?.password}
                      touched={formik?.touched?.user?.password}
                      errors={formik?.errors?.user?.password}
                      isRequired={!userInfo?.user?.id}
                    />
                  </Col>
                  <Col
                    xs={4}
                    className="pt-4"
                  >
                    <TextValidator
                      name="user.confirmPassword"
                      isReadOnly={!isUpdate}
                      lable={lang("USER.RE_PASSWORD")}
                      type={"password"}
                      onChange={formik.handleChange}
                      value={formik?.values?.user?.confirmPassword}
                      touched={formik?.touched?.user?.confirmPassword}
                      errors={formik?.errors?.user?.confirmPassword}
                      isRequired={!userInfo?.user?.id}
                    />
                  </Col>
                </>
              )}

              <Col
                xs={4}
                className="pt-4"
              >
                <Autocomplete
                  isRequired
                  isReadOnly={!isUpdate || !hasAuthority(PERMISSIONS.ROLE, PERMISSION_ABILITY.ALL)}
                  lable={lang("AUTH")}
                  options={listRole}
                  value={formik?.values?.user?.roles?.[0] || null}
                  name="user.roles"
                  onChange={(selectedOption) => handleChangeSelect("user.roles", [selectedOption])}
                  errors={formik?.errors?.user?.roles}
                  touched={formik?.touched?.user?.roles}
                />
              </Col>

              {!isSelectRoleAdmin && (
                <Col
                  xs={6}
                  className="pt-4"
                >
                  <Autocomplete
                    isRequired
                    isReadOnly={!isUpdate}
                    lable={lang("SELECT.EMPLOYEE")}
                    getOptionLabel={(option) => `${option?.maNhanVien} - ${option?.name} - ${option?.phongBanText}`}
                    options={[]}
                    value={formik?.values?.employee || null}
                    name={VARIABLE_STRING.EMPLOYEE}
                    onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.EMPLOYEE, selectedOption)}
                    searchFunction={searchAllEmployee}
                    searchObject={INIT_SEARCH_OBJECT}
                    isScroll
                    errors={formik?.errors?.employee}
                    touched={formik?.touched?.employee}
                  />
                </Col>
              )}
            </Row>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <GroupButton
              type="btn-cancel"
              handleCloseUpdateDialog={handleCloseDialog}
            />
            {isUpdate && <GroupButton type="btn-save" />}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
export { UserDialog };

/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Form, Row } from "react-bootstrap";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import TextValidator from "../../components/input/text-validator";
import Autocomplete from "../../components/input/autocomplete";
import { INIT_TITLE_CONFERRED, TITLE_CONFERRED } from "../constants/profileConsts";
import * as Yup from "yup";
import { RESPONSE_STATUS_CODE, VARIABLE_STRING } from "../../constants/moduleConsts";
import { updateDanhHieuPhongTang } from "../services/profileServices";
import { useEffect } from "react";
import { GroupButton } from "../../components/GroupButton";

interface IProps {
  identify: string;
  isView: boolean;
  employeeProfiles: any;
  activeTab: string;
  handleGetEmployee: (id: string) => void;
}

function TitleConferredInformation(props: IProps) {
  const { identify, isView, employeeProfiles, handleGetEmployee } = props;
  const { lang } = useMultiLanguage();

  useEffect(() => {
    if (employeeProfiles?.id) {
      formik.setValues({
        danhHieu: employeeProfiles?.danhHieu || null,
        namPhongTang: employeeProfiles?.namPhongTang || null
      });
    }
  }, [employeeProfiles]);

  const handleFormSubmit = async (values: any) => {
    try {
      const { data } = await updateDanhHieuPhongTang(identify, values);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("TOAST.EDIT.SUCCESS"));
        handleGetEmployee(employeeProfiles?.id || "");
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const validationSchema = Yup.object().shape({
    danhHieu: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    namPhongTang: Yup.number()
      .min(1900, lang("VALIDATION.MIN_YEAR"))
      .required(lang("VALIDATION.REQUIRE"))
      .max(new Date().getFullYear(), lang("VALIDATON.YEAR_CURRENT"))
      .nullable()
  });

  const formik = useFormik({
    initialValues: INIT_TITLE_CONFERRED,
    onSubmit: handleFormSubmit,
    validationSchema
  });

  const handleChangeSelect = (name: string, value: any) => {
    formik.setFieldValue(name, value);
  };

  return (
    <Form
      className="form-info"
      onSubmit={formik.handleSubmit}
    >
      <div className="block-content">
        <div className="spaces mb-8 flex flex-between">
          <span className="spaces fs-18 fw-600">{lang("INPUT.TITLE_CONFERRED")}</span>
          {!isView && <GroupButton type="btn-save" />}
        </div>
        <Row className="spaces pl-20">
          <Col xs={6} className="spaces pt-0">
            <Autocomplete
              className="spaces z-index-6"
              lable={lang("INPUT.TITLE")}
              name="danhHieu"
              isRequired
              value={formik?.values?.danhHieu || null}
              isReadOnly={isView}
              onChange={(value) => handleChangeSelect(VARIABLE_STRING.DANH_HIEU, value)}
              options={TITLE_CONFERRED}
              touched={formik.touched?.danhHieu}
              errors={formik.errors?.danhHieu}
            />
          </Col>
          <Col xs={6} className="spaces pt-0">
            <TextValidator
              lable={lang("INPUT.YEAR_OF_CONFERMENT")}
              name="namPhongTang"
              type="number"
              isRequired
              value={formik.values?.namPhongTang}
              readOnly={isView}
              onChange={formik.handleChange}
              touched={formik.touched?.namPhongTang}
              errors={formik.errors?.namPhongTang}
            />
          </Col>
        </Row>
      </div>
    </Form>
  );
}

export default TitleConferredInformation;

import { Col, Form, Row } from "react-bootstrap";
import useMultiLanguage from "../../hook/useMultiLanguage";
import { ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { KTSVG } from "../../../_metronic/helpers/components/KTSVG";
import { EXPORT_FILE_NAME, RESPONSE_STATUS_CODE, VARIABLE_STRING } from "../constants/moduleConsts";
import { toast } from "react-toastify";
import AppContext from "../../AppContext";
import { getReportDetail } from "./services/reportServices";
import { exportToFile, getCurrentQuarter, getFullYear } from "../utils/functionUtils";
import { exportBaoCao } from "../services/exportFileServices";
import { GroupButton } from "../components/GroupButton";
import Autocomplete from "../components/input/autocomplete";
import { useFormik } from "formik";
import { IColumns, IReportInfo, ISearchDetail } from "./models/reportModels";
import TableGrouping, { IColumnsTotal } from "../components/table-grouping/TableGrouping";
import { QUARTER_LIST, REPORT_MAPING_COLUMNS, REPORT_TYPE, REPORT_TYPE_COLLAPSE } from "./constants/reportConsts";
import { convertDataReport, convertSearchData, templateTitleReport } from "./utils/reportUtils";
import { RangeDatePicker } from "../components/input/RangeDatePicker";
import moment from "moment";
import { searchListWorkUnit } from "../services/services";

interface IProps {
  reportInfo: IReportInfo;
  handleBack: () => void;
}

function ReportDetail(props: IProps) {
  const { lang } = useMultiLanguage();
  const { reportInfo, handleBack } = props;

  const [data, setData] = useState<any[]>([]);
  const [workUnitList, setWorkUnitList] = useState<any[]>([]);
  const [columns, setColumns] = useState<IColumns[]>([]);
  const [columnsTotal, setColumnsTotal] = useState<IColumnsTotal[]>([]);
  const [titleReport, setTitleReport] = useState<string | ReactNode>("");
  const [isSalaryFund, setIsSalaryFund] = useState<boolean>(false);
  const [searchDataObj, setSearchDataObj] = useState<ISearchDetail>({} as ISearchDetail);
  const { setPageLoading } = useContext(AppContext);
  
  const getWorkUnit = useCallback(async () => {
    try {
      const { data } = await searchListWorkUnit();
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setWorkUnitList(data?.data?.content || []);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }, [lang]);
  
  useEffect(() => {
    if(reportInfo && reportInfo?.mappingLevel) {
      getWorkUnit();
    }
  }, [getWorkUnit, reportInfo]);

  useEffect(() => {
    let columnsByType = REPORT_MAPING_COLUMNS.find((column) => column.type === reportInfo?.mappingName);
    setColumns(columnsByType?.columns || []);
    setColumnsTotal(columnsByType?.columnsTotal || []);
    setIsSalaryFund(REPORT_TYPE.QUY_LUONG === reportInfo?.mappingName);
    if(reportInfo && reportInfo?.mappingLevel && workUnitList.length > 0) {
      initComponent();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportInfo, workUnitList]);

  const initComponent = () => {
    const yearDefault = getFullYear().find((year) => Number(year?.name) === new Date().getFullYear());
    const quarterDefault = QUARTER_LIST.find((quarter) => quarter.code === getCurrentQuarter());
    const endDateDefault = moment(new Date()).format("YYYY-MM-DD");
    const workUnitDefault = workUnitList.length === 1 ? workUnitList[0] : null;

    let stateDefault = {
      ...formik.values,
      nam: yearDefault?.name || null,
      quy: Number(quarterDefault?.code) || null,
      denNgay: endDateDefault || null,
      donVi: workUnitDefault
    };
    formik.setValues(stateDefault);

    const searchObj: ISearchDetail = {
      ...stateDefault,
      type: reportInfo?.mappingName,
      donViCongTacId: workUnitDefault?.id || null
    };
    updatePageData(searchObj);
  };

  const updatePageData = async (searchData: ISearchDetail) => {
    try {
      setPageLoading(true);
      setSearchDataObj(searchData);
      const { donVi, ...filterSearchData } = searchData;
      const { data } = await getReportDetail(convertSearchData(filterSearchData));
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        REPORT_TYPE_COLLAPSE.includes(reportInfo?.mappingName)
          ? setData(data?.data ? convertDataReport(data?.data) : [])
          : setData(data?.data ? [data?.data] : []);

        let otherOptionTitleData = {
          quy: Number(searchData?.quy),
          nam: searchData?.nam || "",
          endDate: searchData?.denNgay || "",
          donVi: searchData?.donViCongTacId ? donVi?.name || "" : ""
        };
        setTitleReport(templateTitleReport(reportInfo, otherOptionTitleData));
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleExportExcel = () => {
    exportToFile({
      exportAPI: () => exportBaoCao({ ...searchDataObj, type: reportInfo?.mappingName }),
      fileName: EXPORT_FILE_NAME.BAO_CAO,
      setPageLoading
    });
  };

  const handleSubmit = (values: ISearchDetail) => {
    const searchData: ISearchDetail = {
      ...values,
      type: reportInfo?.mappingName,
      donViCongTacId: values?.donVi?.id || null
    };
    updatePageData(searchData);
  };

  const formik = useFormik({
    initialValues: {} as ISearchDetail,
    onSubmit: handleSubmit
  });

  const handleChangeSelect = (name: string, value: any) => {
    formik.setFieldValue(name, value);
  };

  return (
    <div className="flex-1">
      <div className="spaces flex flex-middle flex-space-between py-12 color-primary text-uppercase">
        <GroupButton
          type="btn-back"
          handleClose={handleBack}
        />
      </div>

      <div className="report-detail spaces p-12 flex flex-column bg-white">
        {(reportInfo && reportInfo.mappingLevel) ? (
          <>
            <Form
              onSubmit={formik.handleSubmit}
              className="z-4 relative"
            >
              <Row className="align-items-end pb-4 relative z-index-2">
                <Col xs={3}>
                  <Autocomplete
                    lable={lang("WARNING.UNIT")}
                    options={workUnitList}
                    value={formik.values?.donVi || null}
                    name="donVi"
                    onChange={(value) => handleChangeSelect(VARIABLE_STRING.DON_VI, value)}
                  />
                </Col>
                <Col xs={3}>
                  <Row>
                    {isSalaryFund ? (
                      <>
                        <Col xs={6}>
                          <Autocomplete
                            lable={lang("GENERAL.YEAR")}
                            options={getFullYear(0)}
                            value={formik.values?.nam || null}
                            name="nam"
                            onChange={(value) => handleChangeSelect(VARIABLE_STRING.NAM, value?.name)}
                          />
                        </Col>
                        <Col xs={6}>
                          <Autocomplete
                            lable={lang("GENERAL.QUARTER")}
                            options={QUARTER_LIST}
                            value={formik.values?.quy || null}
                            name="quy"
                            valueSearch="code"
                            onChange={(value) => handleChangeSelect(VARIABLE_STRING.QUY, value?.code)}
                          />
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col xs={12}>
                          <RangeDatePicker
                            label={lang("INPUT_APPOINTMENT_TIME_EFFECT")}
                            startDateName="tuNgay"
                            endDateName="denNgay"
                            handleChange={formik.handleChange}
                            value={formik.values}
                            setFieldValue={formik.setFieldValue}
                            touch={formik.touched}
                            errors={formik.errors}
                          />
                        </Col>
                      </>
                    )}
                  </Row>
                </Col>
                <Col
                  xs={6}
                  className="flex flex-middle"
                >
                  <GroupButton typeButton="submit">
                    <i className="bi bi-funnel spaces flex fs-18 text-white mr-6"></i>
                    {lang("GENERAL.FILTER")}
                  </GroupButton>
                  <div className="flex-1 flex flex-end">
                    <GroupButton className="spaces mr-16" outline handleEvent={handleExportExcel}>
                      <KTSVG path="/media/icons/export-excel.svg" />
                      {lang("BTN.EXPORT")}
                    </GroupButton>
                  </div>
                </Col>
              </Row>
              <div className="text-center">
                <span className="text-header spaces fs-20">{titleReport}</span>
              </div>
            </Form>

            <div className="bg-white spaces mt-10 flex-1">
              <TableGrouping
                id="reportDetail"
                columns={columns || []}
                data={data || []}
                className="table-custom"
                showTotalRow={Array.isArray(columnsTotal) && columnsTotal.length > 0}
                columnsTotal={columnsTotal}
                prefixDataField="data"
                prefixTitleField="phongBanText"
              />
            </div>
          </>
        ) : (
          <div className="spaces gap-0 height-100 flex-column flex-middle flex-center">
            <div className="animation-character fw-bold spaces fs-60">
              <span>C</span>
              <span>O</span>
              <span>M</span>
              <span>I</span>
              <span>N</span>
              <span>G</span>
              <span className="spaces ml-20">S</span>
              <span>O</span>
              <span>O</span>
              <span>N</span>
            </div>
            <p className="spaces fs-28">
              <KTSVG path='/media/icons/stars.svg' svgClassName='spaces w-32 h-32 text-yellow' />
              Tính năng đang phát triển
              <KTSVG path='/media/icons/stars.svg' svgClassName='spaces w-32 h-32 text-yellow' />
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default ReportDetail;

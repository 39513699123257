import { DisciplineInfo } from "../models/disciplineModels";

export const convertDataSubmit = (data: DisciplineInfo, employeeList: any) => {
  let thongTinNghiViec = data.thongTinNghiViec;
  if(thongTinNghiViec) {
    thongTinNghiViec.listKhoanThu.forEach(item => delete item.id);
    thongTinNghiViec.raQuyetDinh = data.xacNhanKyLuat;
  }
  
  const dataConvert: DisciplineInfo = {
    ...data,
    thongTinNghiViec,
    nguoiKyQuyetDinhId: data?.nguoiKyQuyetDinh?.id || "",
    nguoiKyQuyetDinhText: data?.nguoiKyQuyetDinh?.name || "",
    coQuanQuyetDinhId: data?.coQuanQuyetDinh?.id || "",
    coQuanQuyetDinhText: data?.coQuanQuyetDinh?.name || "",
    chucVuMoiId: data?.chucVuMoi?.id || "",
    chucVuMoiText: String(data?.chucVuMoi?.value || ""),
    employeeKyLuats: [{...employeeList[0], employeeId: employeeList[0]?.employeeId || employeeList[0]?.id}]
  };

  return dataConvert;
};

export const convertDataUI = (data: DisciplineInfo) => {
  const dataConvert = {
    ...data,
    chucVuMoi: data?.chucVuMoiId ? {
      id: data?.chucVuMoiId || "",
      value: data?.chucVuMoiText || ""
    } : null,
    nguoiKyQuyetDinh: data?.nguoiKyQuyetDinhId
      ? {
          id: data?.nguoiKyQuyetDinhId || "",
          name: data?.nguoiKyQuyetDinhText || ""
        }
      : null,
    coQuanQuyetDinh: data?.coQuanQuyetDinhId
      ? {
          id: data?.coQuanQuyetDinhId || "",
          name: data?.coQuanQuyetDinhText || ""
        }
      : null
  };

  return dataConvert;
};

import TablePartInfomation from "./TablePartInfomation";
import {
  GemStonesColumns,
  HousingColumns,
  LandUseColumns,
  MoneyColumns,
  OtherAssetsColumns,
  OtherAssetsLandColumns,
  StockColumns
} from "../constants/columns";
import {
  LandUseForm,
  HousingForm,
  OtherAssetsLandForm,
  GemStonesForm,
  MoneyForm,
  StockForm,
  OtherAssetsForm
} from "./part-form";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { IDeclareAssets } from "../models/declareAssetsModels";
import { OptionReactSelect } from "../../models/models";

interface Props {
  handleAddAndEditData: (newChildData: any[], field: string) => void;
  data: IDeclareAssets;
  assetsType: OptionReactSelect;
}

function BodyInfomation(props: Props) {
  const { handleAddAndEditData, data, assetsType } = props;
  const { lang } = useMultiLanguage();

  return (
    <>
      <div className="spaces mt-16">
        <TablePartInfomation
          id={"landUse" + assetsType?.code}
          columns={LandUseColumns(lang)}
          data={data?.quyenSuDungDat || []}
          setData={(newData) => handleAddAndEditData(newData, "quyenSuDungDat")}
          partForm={(props) => <LandUseForm {...props} />}
          assetsType={assetsType}
          tableTitle="1. Quyền sử dụng thực tế đối với đất:"
        />
      </div>
      <div className="spaces mt-20">
        <TablePartInfomation
          id={"housing" + assetsType?.code}
          columns={HousingColumns(lang)}
          data={data?.congTrinhXayDung || []}
          setData={(newData) => handleAddAndEditData(newData, "congTrinhXayDung")}
          partForm={(props) => <HousingForm {...props} />}
          assetsType={assetsType}
          tableTitle="2. Nhà ở, công trình xây dựng:"
        />
      </div>
      <div className="spaces mt-20">
        <TablePartInfomation
          id={"otherAssetsLand" + assetsType?.code}
          columns={OtherAssetsLandColumns(lang)}
          data={data?.taiSanTrenDat || []}
          setData={(newData) => handleAddAndEditData(newData, "taiSanTrenDat")}
          partForm={(props) => <OtherAssetsLandForm {...props} />}
          assetsType={assetsType}
          dialogSize="lg"
          tableTitle="3. Tài sản khác gắn liền với đất:"
        />
      </div>
      <div className="spaces mt-20">
        <TablePartInfomation
          id={"gemStones" + assetsType?.code}
          columns={GemStonesColumns(lang)}
          data={data?.daQuy || []}
          setData={(newData) => handleAddAndEditData(newData, "daQuy")}
          partForm={(props) => <GemStonesForm {...props} />}
          dialogSize="lg"
          assetsType={assetsType}
          tableTitle="4. Vàng, kim cương, bạch kim và các kim loại quý, đá quý khác:"
        />
      </div>
      <div className="spaces mt-20">
        <TablePartInfomation
          id={"money" + assetsType?.code}
          columns={MoneyColumns(lang)}
          data={data?.tien || []}
          setData={(newData) => handleAddAndEditData(newData, "tien")}
          partForm={(props) => <MoneyForm {...props} />}
          dialogSize="lg"
          assetsType={assetsType}
          tableTitle="5. Tiền (tiền Việt Nam, ngoại tệ):"
        />
      </div>
      <div className="spaces mt-20">
        <TablePartInfomation
          id={"stock" + assetsType?.code}
          columns={StockColumns(lang)}
          data={data?.coPhieu || []}
          setData={(newData) => handleAddAndEditData(newData, "coPhieu")}
          partForm={(props) => <StockForm {...props} />}
          assetsType={assetsType}
          dialogSize="lg"
          tableTitle="6. Cổ phiếu, trái phiếu, vốn góp, các loại giấy tờ có giá khác:"
        />
      </div>
      <div className="spaces mt-20">
        <TablePartInfomation
          id={"otherAssets" + assetsType?.code}
          columns={OtherAssetsColumns(lang)}
          data={data?.khac || []}
          setData={(newData) => handleAddAndEditData(newData, "khac")}
          partForm={(props) => <OtherAssetsForm {...props} />}
          assetsType={assetsType}
          dialogSize="lg"
          tableTitle="7. Tài sản khác:"
        />
      </div>
    </>
  );
}

export default BodyInfomation;

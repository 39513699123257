import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { IPropsShiftDivisionForm, IRefBangPhanCongDetailList, IShiftDivision } from '../../models/shiftDivisionModels';
import * as Yup from "yup";
import useMultiLanguage from '../../../../hook/useMultiLanguage';
import AppContext from '../../../../AppContext';
import { FORMAT_DATE, RESPONSE_STATUS_CODE } from '../../../constants/moduleConsts';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { INIT_SHIFT_DIVISION } from '../../constants/shiftDivisionConsts';
import TextValidator from '../../../components/input/text-validator';
import { Col, Form, Row } from 'react-bootstrap';
import { GroupButton } from '../../../components/GroupButton';
import moment from 'moment';
import { searchAllEmployee, searchNguoiDaiDienKy } from '../../../services/services';
import ShiftSchedule from './tables/ShiftSchedule';
import { addShiftDivision, approveShiftDivision, getShiftDivisionById, updateShiftDivision } from '../../services/shiftDivisionServices';
import { convertDataShiftDivision, convertNameEmployee } from '../../utils/shiftUtils';
import { IPeopleDecision, OptionReactSelect } from '../../../models/models';
import Autocomplete from '../../../components/input/autocomplete';
import { AddNewRepresentative, ConfirmDialog } from '../../../components/dialogs';
import { handleBlurDate, hasAuthority } from '../../../utils/functionUtils';
import { RangeDatePicker } from '../../../components/input/RangeDatePicker';
import { PERMISSION_ABILITY, PERMISSIONS } from '../../../../constants';
import {CODE_STATUS, LIST_STATUS} from '../../../manager-request/constants/managerRequestConsts';
import { RefuseDialog } from '../../../components/dialogs/RefuseDialog';
import { TYPE_OBJECT_SCHEDULE } from '../../constants/shiftComprehensionConsts';

const ShiftDivisionForm: FC<IPropsShiftDivisionForm> = (props) => {
  const {
    handleClose,
    shiftDivision,
    handleCloseUpdateDialog,
    handleCloseAndSearch,
    isView,
    setIsView,
    doiTuong,
    isApproveOrReject
  } = props;

  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);
  const [listEmployee, setListEmployee] = useState<OptionReactSelect[]>([]);
  const [peopleDecisions, setPeopleDecisions] = useState<IPeopleDecision[]>([]);
  const [isShowDialogAddRepresentative, setIsShowDialogAddRepresentative] = useState<boolean>(false);
  const refBangPhanCongDetailList = useRef<IRefBangPhanCongDetailList | null>(null);
  const [shouldOpenSendDialog, setShouldOpenSendDialog] = useState<boolean>(false);
  const [shouldOpenRejectDialog, setShouldOpenRejectDialog] = useState<boolean>(false);
  const [shouldOpenApproveDialog, setShouldOpenApproveDialog] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const getPeopleDecision = useCallback(async () => {
    try {
      const { data } = await searchNguoiDaiDienKy();
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setPeopleDecisions(data?.data?.content || [])
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }, [lang]);
  
  const getListEmployee = useCallback(async () => {
    try {
      setPageLoading(true);
      const { data } = await searchAllEmployee();
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListEmployee(convertNameEmployee(data?.data?.content || []));
      } else {
        toast.warning(data?.message);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  }, [lang, setPageLoading]);

  useEffect(() => {
    getListEmployee();
    getPeopleDecision();
  }, [getListEmployee, getPeopleDecision]);
  
  const getShiftDivisionDetail = async (id: string) => {
    try {
      setPageLoading(true);
      const { data } = await getShiftDivisionById(id);
      if(data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        formik.setValues({
          ...formik.values,
          ...data?.data,
          nguoiPheDuyet: data?.data?.nguoiPheDuyetId ? {
            id: data?.data?.nguoiPheDuyetId,
            name: data?.data?.nguoiPheDuyetText
          } : null
        });
      } else {
        toast.warning(data?.message);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  }

  useEffect(() => {
    isView && shiftDivision?.id && getShiftDivisionDetail(shiftDivision.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shiftDivision, isView]);
  
  useEffect(() => {
    if (!shiftDivision?.id) {
      const startOfNextWeek = moment().startOf('isoWeek').add(1, 'weeks').startOf('isoWeek');
      const endOfNextWeek = moment(startOfNextWeek).endOf('isoWeek');

      formik.setValues({
        ...formik.values,
        ngayBatDau: startOfNextWeek.format(FORMAT_DATE.YYYY_MM_DD_INPUT),
        ngayKetThuc: endOfNextWeek.format(FORMAT_DATE.YYYY_MM_DD_INPUT)
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shiftDivision]);

  const validationSchema = Yup.object().shape({
    ten: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    ngayBatDau: Yup.date().required(lang("VALIDATION.REQUIRE")).nullable(),
    ngayKetThuc: Yup.date().required(lang("VALIDATION.REQUIRE")).nullable(),
  });

  const handleSubmit = async (values: IShiftDivision) => {
    try {
      setPageLoading(true);
      const _values = convertDataShiftDivision({ ...values, doiTuong, ...refBangPhanCongDetailList.current?.getDataDetail() });
      const { data } = shiftDivision?.id
      ? await updateShiftDivision(shiftDivision.id, _values)
      : await addShiftDivision(_values);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(
        shiftDivision.id
          ? lang("TOAST.EDIT.SUCCESS")
          : lang("TOAST.CREATE.SUCCESS")
      );
        handleCloseAndSearch();
      } else {
        toast.warning(data?.message);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false)
    }
  };

  const formik = useFormik({
    initialValues: INIT_SHIFT_DIVISION,
    validationSchema,
    onSubmit: handleSubmit
  });

  const handleChangeSelect = (name: string, value: any) => {
    formik.setFieldValue(name, value);
  };

  const handleCloseAndSearchRepresentative = () => {
    getPeopleDecision();
    setIsShowDialogAddRepresentative(false);
  }

  const handleConfirm = () => {
    shouldOpenApproveDialog && handleUpdateStatus(CODE_STATUS.DA_DUYET, "Duyệt lịch phân ca thành công");
    shouldOpenSendDialog && handleUpdateStatus(CODE_STATUS.CHO_DUYET, "Gửi lịch phân ca Thành công");
    shouldOpenRejectDialog && handleUpdateStatus(CODE_STATUS.TU_CHOI, "Đã từ chối lịch phân ca");
  }

  const handleCloseForm = () => {
    setShouldOpenSendDialog(false);
    setShouldOpenApproveDialog(false);
    setShouldOpenRejectDialog(false);
  }

  const handleOpenSendDialog = () => {
    setMessage("Bạn có chắc chắn muốn gửi không?")
    setShouldOpenSendDialog(true);
  }

  const handleOpenApproveDialog = () => {
    setMessage("Bạn có chắc chắn muốn duyệt lịch phân ca này?");
    setShouldOpenApproveDialog(true);
  }

  const handleOpenRejectDialog = () => {
    setShouldOpenRejectDialog(true);
  }

  const handleUpdateStatus = async (status: number, messageSuccess: string = "") => {
    try {
      setPageLoading(true);

      const { data } = await approveShiftDivision(shiftDivision?.id ?? "", status);

      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(messageSuccess);
        handleCloseAndSearch();
        handleCloseForm();
      } else toast.warn(data?.message);
    } catch {
      toast.error(lang("TOAST.GET.ERROR"));
    } finally {
      setPageLoading(false);
    }
  }

  return (
    <div className="h-full">
      <Form onSubmit={formik.handleSubmit}>
        <div className="header-form-action">
          <GroupButton type="btn-back" handleClose={handleClose} />
          <div className="flex gap-4">
            {formik.values?.trangThai?.code === CODE_STATUS.TAO_MOI && !isApproveOrReject && shiftDivision?.id &&
              (isView ?
                <>
                  {hasAuthority(PERMISSIONS.PHAN_CA, PERMISSION_ABILITY.UPDATE) &&
                    <GroupButton type="btn-edit" handleSaveEdit={() => setIsView(false)} />
                  }
                  <GroupButton handleEvent={handleOpenSendDialog}>{lang("AUTH.GENERAL.SUBMIT_BUTTON")}</GroupButton>
                </>
                : <GroupButton
                  type="btn-cancel"
                  handleCloseUpdateDialog={handleCloseUpdateDialog}
                />
              )
            }
            {!isView && <GroupButton type="btn-save" />}
            {shiftDivision?.id && isView && isApproveOrReject && hasAuthority(PERMISSIONS.PHAN_CA, PERMISSION_ABILITY.APPROVE) && (
              <>
                <GroupButton handleEvent={handleOpenApproveDialog}>{lang("BTN.APPROVE")}</GroupButton>
                <GroupButton handleEvent={handleOpenRejectDialog}>{lang("DECLARE_ASSETS.STATUS.REJECT")}</GroupButton>
              </>
            )}
          </div>
        </div>
        <div className="form-content-scroll">
          <div className="sub-title-form">{shiftDivision?.id ? lang("TIMEKEEPING.SHIFT_DIVISION.EDIT") : lang("TIMEKEEPING.SHIFT_DIVISION.ADD")}</div>
          <div className="sub-title pb-2 pt-0">{lang("GENERAL.INFO")}</div>
          <Row className="g-4 relative spaces z-index-4">
            {!hasAuthority(PERMISSIONS.DEPARTMENT, PERMISSION_ABILITY.VIEW) && doiTuong?.code === TYPE_OBJECT_SCHEDULE.DIEU_DUONG && (
              <Col sm={7} md={5} lg={3} xl={3}>
                <TextValidator
                  readOnly={true}
                  lable={lang("INPUT.DEPARTMENTS")}
                  options={[]}
                  value={formik.values?.phongBanText || null}
                />
              </Col>
            )}
            <Col sm={7} md={5} lg={3} xl={3}>
              <TextValidator
                isRequired
                name="ten"
                lable={lang("TIMEKEEPING.SHIFT_DIVISION.NAME")}
                type="text"
                value={formik.values?.ten}
                onChange={formik.handleChange}
                readOnly={isView}
                errors={formik.errors?.ten}
                touched={formik.touched?.ten}
              />
            </Col>
            <Col xs={6} sm={5} md={5} lg={4} xl={4}>
              <RangeDatePicker
                label={lang("GENERAL.TIME")}
                startDateName="ngayBatDau"
                endDateName="ngayKetThuc"
                isView={isView}
                isRequired
                handleChange={formik.handleChange}
                value={formik.values}
                setFieldValue={formik.setFieldValue}
                touch={formik.touched}
                errors={formik.errors}
              />
            </Col>
            <Col sm={4} md={2} lg={2} xl={2} xxl={2}>
              <TextValidator
                name="ngayDuyet"
                lable={lang("DATE.APPROVE")}
                type="date"
                value={formik.values?.ngayDuyet}
                readOnly={isView}
                onChange={formik.handleChange}
                onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.ngayDuyet, "ngayDuyet")}
                errors={formik?.errors?.ngayDuyet}
                touched={formik?.touched?.ngayDuyet}
              />
            </Col>
            <Col sm={6} md={4} lg={3} xl={3} xxl={3}>
              <Autocomplete
                lable={lang("WELFARE.ACTUAL_COST.APPROVED_PEOPLE")}
                isReadOnly={isView}
                options={peopleDecisions}
                value={formik.values?.nguoiPheDuyet || null}
                isAddNew
                onAddNew={() => setIsShowDialogAddRepresentative(true)}
                name="nguoiPheDuyet"
                onChange={(selectedOption) => handleChangeSelect("nguoiPheDuyet", selectedOption)}
                errors={formik.errors?.nguoiPheDuyet}
                touched={formik.touched?.nguoiPheDuyet}
              />
            </Col>
            <Col sm={6} md={4} lg={3} xl={3} xxl={3}>
              <Autocomplete
                isRequired
                lable={lang("GENERAL.STATUS")}
                isReadOnly={true}
                options={LIST_STATUS}
                value={formik.values?.trangThai || null}
                name="trangThai"
                onChange={(value) => handleChangeSelect("trangThai", value)}
                errors={formik.errors?.trangThai}
                touched={formik.touched?.trangThai}
              />
            </Col>
          </Row>
          {/* Tạm ẩn ca hành chính */}
          {/* <ResizableGroup direction="horizontal" className="mt-6 gap-2">
            <ResizablePanel initialStyle={{ flexBasis: "600px", maxWidth: "1000px" }}>
              <OfficeHour
                isView={isView}
                listEmployee={listEmployee}
                startDate={formik.values?.ngayBatDau}
                endDate={formik.values?.ngayKetThuc}
                shiftDivisionId={shiftDivision?.id}
              />
            </ResizablePanel>
            <ResizablePanel> */}
          <ShiftSchedule
            isView={isView}
            ref={refBangPhanCongDetailList}
            listEmployee={listEmployee}
            startDate={formik.values?.ngayBatDau}
            endDate={formik.values?.ngayKetThuc}
            danhSachDieuKien={formik.values?.configPhanCaDtoList || []}
            danhSachPhanCa={formik.values?.bangPhanCaDetailDtoList || []}
            doiTuong={doiTuong?.code ? Number(doiTuong?.code) : null}
          />
          {/* </ResizablePanel>
          </ResizableGroup> */}
        </div>
      </Form>
      {isShowDialogAddRepresentative && (
        <AddNewRepresentative
          handleClose={() => setIsShowDialogAddRepresentative(false)}
          handleCloseAndSearch={handleCloseAndSearchRepresentative}
        />
      )}
      {(shouldOpenSendDialog || shouldOpenApproveDialog) &&
        <ConfirmDialog
          show={true}
          title={lang("DIALOG.CONFIRM")}
          message={message}
          cancel={lang("BTN.CLOSE")}
          onCancelClick={handleCloseForm}
          yes={lang("BTN.CONFIRM")}
          onYesClick={handleConfirm}
        />
      }
      {shouldOpenRejectDialog &&
        <RefuseDialog
            show={shouldOpenRejectDialog}
            field='lyDo'
            onSubmit={handleConfirm}
            onCloseClick={handleCloseForm}
        />
      }
    </div>
  )
}

export default ShiftDivisionForm

/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import AppContext from "../../../../AppContext";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import Autocomplete from "../../../components/input/autocomplete";
import { RangeDatePicker } from "../../../components/input/RangeDatePicker";
import TextValidator from "../../../components/input/text-validator";
import { searchAllSimpleValue, searchListWorkUnit, searchNguoiDaiDienKy, searchPhongBanByDonVi } from "../../../services/services";
import { 
  checkInvalidDate,
  checkObject,
  convertNumberPrice,
  convertTextPrice,
  covertDateToString,
  handleBlurDate,
  hasAuthority,
  matchTemplateWord, 
  numberExceptThisSymbols 
} from "../../../utils/functionUtils";
import { RESPONSE_STATUS_CODE, VARIABLE_STRING, DATA_WORD_TYPES, TYPE_CATEGORY } from "../../../constants/moduleConsts";
import { TYPE_OF_OFFICER } from "../../constants/dialogChildConsts";
import { INIT_SALARY_DEVELOPMENT, SALARY_ADJUSTMENT_CODES, SALARY_TYPE_STATUS, TYPE_ADJUST_SALARY, TYPE_LEVEL_SALARY, TYPE_POSITION, TYPE_SALARY_RATE, salaryAdjustmentTypes, salaryRate, salaryTypes } from "../../constants/profileConsts";
import {
  addLichSuLuong,
  getBacLuongByChucDanh,
  getNgayHuongLuongDenNgay,
  updateLichSuLuong
} from "../../services/dialogServices";
import { convertDataSubmit, convertDataUI } from "../../utils/salaryDevelopmentUtils";
import { useProfileContext } from "../../ProfileContext";
import { ITemplateExportWord, ExportWord } from "../../../components/exportWord";
import RegularSalaryIncreases from "../word-templates/RegularSalaryIncreases";
import TextArea from "../../../components/input/TextArea";
import { GroupButton } from "../../../components/GroupButton";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../../../constants";
import { AddNewRepresentative } from "../../../components/dialogs";
import { IPeopleDecision } from "../../../models/models";


const decistionRaseSalaryWords: ITemplateExportWord[] = [
  {
    type: SALARY_ADJUSTMENT_CODES.USULLY,
    name: "Nâng lương thường xuyên",
    componentPreview: (dataPreview) => (
      <RegularSalaryIncreases data={dataPreview}/>
    )
  },
  //Tạm ẩn phần này cho đến khi có cảnh báo "Nâng lương trước thời hạn"
  // {
  //   type: SALARY_ADJUSTMENT_CODES.BEFORE_DEADLINE,
  //   name: "Nâng lương trước thời hạn",
  //   componentPreview: (dataPreview) => (
  //     <SalaryInceaseBeforeDealineWord data={dataPreview}/>
  //   )
  // }
];

interface Props {
  handleClose: () => void;
  isView: boolean;
  salaryInfo: any;
  show: boolean;
  employeeProfiles: any;
  handleCloseAndSearch: () => void;
}

function SalaryDevelopmentDialog(props: Props) {
  const { handleClose, isView, salaryInfo, show, employeeProfiles, handleCloseAndSearch } = props;
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);
  const { isResign } = useProfileContext();

  const { SUCCESS } = RESPONSE_STATUS_CODE;
  const [isEdit, setIsEdit] = useState<boolean>(!isView);
  const [isCollaborators, setIsCollaborators] = useState<boolean>(false);
  const [isSalaryAccordingPosition, setIsSalaryAccordingPosition] = useState<boolean>(salaryInfo?.loaiLuong?.code === SALARY_TYPE_STATUS.LUONG_THEO_VI_TRI_VIEC_LAM);
  const [probationary, setProbationary] = useState<boolean>(false);
  const [listSalaryLevel, setListSalaryLevel] = useState<any>([]);
  const [isShowDialogExportWord, setIsShowDialogExportWord] = useState<boolean>(false);
  const [templateWord, setTemplateWord] = useState<ITemplateExportWord[] | null>(null);
  const [isShowDialogAddRepresentative, setIsShowDialogAddRepresentative] = useState<boolean>(false);
  const [peopleDecisions, setPeopleDecisions] = useState<IPeopleDecision[]>([]);
  const [isCheckDonViCongTac, setIsCheckDonViCongTac] = useState<boolean>(!checkObject(salaryInfo) && !salaryInfo?.donViCongTacId);
  const [isCheckPhongBan, setIsCheckPhongBan] = useState<boolean>(!checkObject(salaryInfo) && !salaryInfo?.phongBanId);

  useEffect(() => {
    let data = null;
    if (salaryInfo?.id) {
      data = convertDataUI({ ...salaryInfo, isUpdateEndDate: false });
    } else {
      data = {
        ...INIT_SALARY_DEVELOPMENT,
        loaiCanBo: employeeProfiles?.loaiCanBo,
        phongBan: employeeProfiles?.phongBan,
        chucVu: employeeProfiles?.chucVu,
        chucDanh: employeeProfiles?.viTriCongViec,
        donViCongTac: employeeProfiles?.donViCongTac,
        bac: employeeProfiles?.bac
      };
    }
    bindingDataToWorkInfo(data);
    setTemplateWord(matchTemplateWord(Number(salaryInfo?.loaiDieuChinhLuong?.code), decistionRaseSalaryWords));
  }, [salaryInfo]);

  const getPeopleDecision = async () => {
    try {
      const { data } = await searchNguoiDaiDienKy();
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setPeopleDecisions(data?.data?.content || []);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }

  useEffect(() => {
    getPeopleDecision();
  }, []);

  const handleCloseAndSearchRepresentative = () => {
    getPeopleDecision();
    setIsShowDialogAddRepresentative(false);
  }

  const handleFormSubmit = async (values: any) => {
    try {
      setPageLoading(true);
      const dataSubmit = convertDataSubmit(values, employeeProfiles?.id);
      const { data } = await (!salaryInfo?.id
        ? addLichSuLuong(dataSubmit)
        : updateLichSuLuong(salaryInfo?.id, dataSubmit));
      if (data?.code === SUCCESS) {
        toast.success(lang(`${salaryInfo?.id ? "TOAST.EDIT.SUCCESS" : "TOAST.ADD.SUCCESS"}`));
        handleCloseAndSearch();
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const validationSchema = Yup.object().shape({
    loaiCanBo: Yup.object().shape({}).required(lang("VALIDATION.REQUIRE")).nullable(),
    chucVu: Yup.object().shape({}).required(lang("VALIDATION.REQUIRE")).nullable(),
    chucDanh:
      (isCollaborators || isSalaryAccordingPosition)
        ? Yup.object().nullable()
        : Yup.object().shape({})
          .required(lang("VALIDATION.REQUIRE"))
          .nullable(),
    loaiLuong: Yup.object().shape({}).required(lang("VALIDATION.REQUIRE")).nullable(),
    bac:
      (isCollaborators || isSalaryAccordingPosition)
        ? Yup.object().nullable()
        : Yup.object().shape({})
          .required(lang("VALIDATION.REQUIRE"))
          .nullable(),
    heSoBacLuong:
      (isCollaborators || isSalaryAccordingPosition)
        ? Yup.string().nullable()
        : Yup.string()
          .required(lang("VALIDATION.REQUIRE"))
          .nullable(),
    ngayHuongLuongTuNgay: Yup.date().required(lang("VALIDATION.REQUIRE")).nullable(),
    ngayHuongLuongDenNgay: Yup.date()
      .when("ngayHuongLuongTuNgay", {
        is: (ngayHuongLuongTuNgay: Date | null) => ngayHuongLuongTuNgay,
        then: Yup.date()
          .min(
            Yup.ref("ngayHuongLuongTuNgay"),
            lang("VALIDATION.MINDATE") +
            lang("INPUT.SALARY.BENEFIT_TIME")
          )
          .nullable()
      })
      .nullable(),
    luongThoaThuan: (isCollaborators || isSalaryAccordingPosition)  ? Yup.string().required(lang("VALIDATION.REQUIRE")).nullable() : Yup.string().nullable(),
    ngayQuyetDinh: Yup.date()
      .max(new Date(), lang("VALIDATION.INVALID_DECISION_DATE"))
      .nullable(),
    loaiDieuChinhLuong: Yup.object().shape({}).required(lang("VALIDATION.REQUIRE")).nullable(),
    donViCongTac: !isCheckDonViCongTac
      ? Yup.object().required(lang("VALIDATION.REQUIRE")).nullable()
      : Yup.object().nullable(),
    donViCongTacText: isCheckDonViCongTac 
      ? Yup.string().required(lang("VALIDATION.REQUIRE")).nullable()
      : Yup.string(),
    phongBan: !isCheckPhongBan
      ? Yup.object().required(lang("VALIDATION.REQUIRE")).nullable()
      : Yup.object().nullable(),
    phongBanText: isCheckPhongBan
      ? Yup.string().required(lang("VALIDATION.REQUIRE")).nullable()
      : Yup.string(),
  });

  const formik = useFormik({
    initialValues: INIT_SALARY_DEVELOPMENT,
    onSubmit: handleFormSubmit,
    validationSchema
  });

  const handleChangeSelect = (name: string, value: any) => {
    switch (name) {
      case VARIABLE_STRING.DON_VI_CONG_TAC: 
        formik.setValues({
          ...formik.values,
          [name]: value,
          donViCongTacText: ""
        });
        break;
      case VARIABLE_STRING.PHONG_BAN: 
        formik.setValues({
          ...formik.values,
          [name]: value,
          phongBanText: ""
        });
        break;
      case VARIABLE_STRING.BAC:
        formik.setValues({
          ...formik.values,
          [name]: value,
          heSoBacLuong: value?.heSoLuong || ""
        });
        break;
      case VARIABLE_STRING.LOAI_CAN_BO:
      case VARIABLE_STRING.CHUC_DANH:
      case VARIABLE_STRING.LOAI_DIEU_CHINH_LUONG:
        formik.setValues({
          ...formik.values,
          [name]: value,
          ngayHuongLuongTuNgay: "",
          isUpdateEndDate: true
        });
        break;
      default:
        formik.setFieldValue(name, value);
        break;
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>, nameObj: string) => {
    const { name, value } = event.target
    formik.setFieldValue(name, value)
    formik.setFieldValue(nameObj, null)
  }

  const getNgayHuongLuongToiNgay = async (params: any) => {
    try {
      let { data } = await getNgayHuongLuongDenNgay(params);
      if (data?.code === SUCCESS) {
        formik.setFieldValue("ngayHuongLuongDenNgay", data?.data);
        formik.setFieldValue("thoiGianGiuBacDuKienKetThuc", data?.data);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  useEffect(() => {
    if(formik.values.isUpdateEndDate && !isCollaborators) {
      let ngayHuongLuongTuNgay = formik.values?.ngayHuongLuongTuNgay;
      if (checkInvalidDate(ngayHuongLuongTuNgay)) return;
      getNgayHuongLuongToiNgay({
        employeeId: employeeProfiles?.id,
        ngayHuongLuongTuNgay,
        loaiCanBo: formik.values?.loaiCanBo?.code,
        loaiDieuChinhLuong: formik.values?.loaiDieuChinhLuong?.code,
        chucDanhId: formik.values?.chucDanh?.id
      });
    }
  }, [formik.values?.ngayHuongLuongTuNgay]);

  const bindingDataToWorkInfo = async (values: any) => {
    let dataBinding = values;

    if (employeeProfiles?.bacId) {
      try {
        const { data } = await getBacLuongByChucDanh(values?.chucDanh?.id || employeeProfiles?.viTriCongViecId);
        if(data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
          setListSalaryLevel(data?.data);
          const bac =
            data?.code === RESPONSE_STATUS_CODE.SUCCESS
              ? data?.data?.find((item: any) => (item?.bacLuong === values?.bacLuong) || (item?.id === values?.bac?.id)) || null
              : {};
          dataBinding = {
            ...dataBinding,
            heSoBacLuong: bac?.heSoLuong
          }
        } else {
          toast.warning(data?.message);
        }
      } catch (error) {
        toast.error(lang("GENERAL.ERROR"));
      }
    }
    formik.setValues(dataBinding);
  };

  const handleChangeRangeDatePicker = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    switch (name) {
      case VARIABLE_STRING.NGAY_HUONG_LUONG_TU:
        formik.setValues({
          ...formik.values,
          [name]: value,
          thoiGianGiuBacDuKienBatDau: value,
          isUpdateEndDate: true
        });
        break;
      case VARIABLE_STRING.NGAY_HUONG_LUONG_DEN:
        formik.setValues({
          ...formik.values,
          [name]: value,
          thoiGianGiuBacDuKienKetThuc: value
        });
        break;
      default:
        formik.setFieldValue(name, value);
        break;
    }
  };

  const handleChangeMoney = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, convertTextPrice(value));
  }

  const getPositionAccordingCollaborators = async (values: any) => {
    try {
      const { data } = await searchAllSimpleValue({ type: TYPE_CATEGORY.chucDanh });
      if(data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        formik.setValues({
          ...formik.values,
          ...values,
          chucVu: data.data?.content?.find((position: any) => position?.mappingName === TYPE_POSITION.CONG_TAC_VIEN) || null
        })
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }

  useEffect(() => {
    if (formik?.values?.loaiCanBo?.name === VARIABLE_STRING.CONG_TAC_VIEN_NAME) {
      setIsCollaborators(true);
      getPositionAccordingCollaborators({
        chucDanh: null,
        bac: null,
        heSoBacLuong: null,
        loaiLuong: salaryTypes[1],
        loaiDieuChinhLuong: salaryAdjustmentTypes.find(item => item?.code === TYPE_ADJUST_SALARY.KHAC) || null
      });
    } else {
      setIsCollaborators(false)
    }
  }, [formik?.values?.loaiCanBo])

  useEffect(() => {
    if(formik.values.loaiDieuChinhLuong?.name === VARIABLE_STRING.HUONG_LUONG_TAP_SU) {
      setProbationary(true);
      const defaultSalaryRate = salaryRate.find(item => item.code === TYPE_SALARY_RATE["85%"]) || null;
      const defaultSalaryLevel = listSalaryLevel.find((item: any) => item?.bacLuong === TYPE_LEVEL_SALARY["1"]) || null;
      const defaultSalaryCoefficient = defaultSalaryLevel ? defaultSalaryLevel.heSoLuong : "";
      formik.setValues({
        ...formik.values,
        phanTramHuong: defaultSalaryRate,
        bac: defaultSalaryLevel,
        heSoBacLuong: defaultSalaryCoefficient
      });
    } else {
      setProbationary(false);
    }
  }, [formik.values?.loaiDieuChinhLuong]);

  useEffect(() => {
    const salaryAccordingPosition = formik.values?.loaiLuong?.code === SALARY_TYPE_STATUS.LUONG_THEO_VI_TRI_VIEC_LAM;
    setIsSalaryAccordingPosition(salaryAccordingPosition);
    salaryAccordingPosition && formik.setValues({
      ...formik.values,
      chucDanh: null,
      bac: null,
      heSoBacLuong: null,
    });
  }, [formik?.values?.loaiLuong]);

  return (
    <Modal
      show={show}
      size="xl"
      centered
      aria-labelledby="example-custom-modal-styling-title"
      onHide={() => handleClose()}
      backdrop="static"
      className="custom-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="example-custom-modal-styling-title"
          className="heading-5"
        >
          {lang("SALARY.DEVELOPMENT")}
        </Modal.Title>
      </Modal.Header>
      <Form className="form-info" onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <span className="fs-3 fw-bold">
            {lang("INPUT.SALARY_INFO.EMPLOYEE_INFO")}
          </span>
          <Row className="ms-2 gap-col-modal">
            <Col xs={4} className="pt-4">
              <Autocomplete
                isRequired
                lable={lang("GENERAL.CURRENT_ORGANIZATION")}
                name={isCheckDonViCongTac 
                  ? VARIABLE_STRING.DON_VI_CONG_TAC_TEXT
                  : VARIABLE_STRING.DON_VI_CONG_TAC
                }
                value={isCheckDonViCongTac
                  ? formik.values?.donViCongTacText
                  : formik.values?.donViCongTac || null
                }
                isReadOnly={!isEdit}
                searchFunction={searchListWorkUnit}
                onChange={(value) => 
                  isCheckDonViCongTac
                  ? handleChange(value, VARIABLE_STRING.DON_VI_CONG_TAC) 
                  : handleChangeSelect(VARIABLE_STRING.DON_VI_CONG_TAC, value)
                }
                options={[]}
                formCheckBox
                setIsCheckBox={setIsCheckDonViCongTac}
                isCheckBox={isCheckDonViCongTac}
                errors={isCheckDonViCongTac ? formik.errors?.donViCongTacText : formik.errors?.donViCongTac}
                touched={isCheckDonViCongTac ? formik.touched?.donViCongTacText : formik.touched?.donViCongTac}
              />
            </Col>
            <Col xs={4} className="pt-4">
              <Autocomplete
                isRequired
                lable={lang("INPUT.SALARY.DEPARTMENT")}
                name={isCheckPhongBan
                  ? VARIABLE_STRING.PHONG_BAN_TEXT
                  : VARIABLE_STRING.PHONG_BAN
                }
                value={isCheckPhongBan 
                  ? formik.values?.phongBanText
                  : formik.values?.phongBan || null}
                isReadOnly={!isEdit}
                searchFunction={searchPhongBanByDonVi}
                searchObject={{ id: formik.values?.donViCongTac?.id ||employeeProfiles?.donViCongTacId }}
                onChange={(value) => 
                  isCheckPhongBan
                  ? handleChange(value, VARIABLE_STRING.PHONG_BAN)
                  : handleChangeSelect(VARIABLE_STRING.PHONG_BAN, value)
                }
                formCheckBox
                setIsCheckBox={setIsCheckPhongBan}
                isCheckBox={isCheckPhongBan}
                urlData="data.data"
                options={[]}
                errors={isCheckPhongBan ? formik.errors?.phongBanText : formik.errors?.phongBan}
                touched={isCheckPhongBan ? formik.errors?.phongBanText : formik.touched?.phongBan}
              />
            </Col>
            <Col xs={4} className="pt-4">
              <Autocomplete
                isRequired={!isCollaborators || !isSalaryAccordingPosition}
                lable={lang("PROFILE.ROLE")}
                name="chucVu"
                value={formik.values?.chucVu || null}
                searchFunction={searchAllSimpleValue}
                searchObject={{ type: TYPE_CATEGORY.chucDanh }}
                isReadOnly={!isEdit || isCollaborators}
                onChange={(value) => handleChangeSelect("chucVu", value)}
                options={[]}
                getOptionLabel={(options) => options.value}
                errors={formik.errors?.chucVu}
                touched={formik.touched?.chucVu}
              />
            </Col>
            <Col xs={4} className="pt-4">
              <Autocomplete
                isRequired={!isCollaborators && !isSalaryAccordingPosition}
                lable={lang("GENERAL.EMPLOYEE.TITLE")}
                name="chucDanh"
                value={formik.values?.chucDanh || null}
                searchFunction={searchAllSimpleValue}
                searchObject={{ type: TYPE_CATEGORY.viTriCongViec }}
                isReadOnly={!isEdit || isCollaborators || isSalaryAccordingPosition}
                onChange={(value) => handleChangeSelect("chucDanh", value)}
                options={[]}
                getOptionLabel={(options) => options.value}
                errors={formik.errors?.chucDanh}
                touched={formik.touched?.chucDanh}
              />
            </Col>
          </Row>
          <div className="mt-4">
            <span className="fs-3 fw-bold">
              {lang("INPUT.SALARY_INFO.ADJUST_SALARY_INFO")}
            </span>
          </div>
          <Row className="ms-2">
            <Col xs={4} className="pt-4">
              <Autocomplete
                isRequired
                lable={lang("CONTRACT.NEW.TYPE_CCVC")}
                name="loaiCanBo"
                value={formik.values?.loaiCanBo || ""}
                isReadOnly={!isEdit}
                options={TYPE_OF_OFFICER}
                onChange={(value) => handleChangeSelect("loaiCanBo", value)}
                menuPortalTarget={document.body}
                errors={formik.errors?.loaiCanBo}
                touched={formik.touched?.loaiCanBo}
              />
            </Col>
            <Col xs={4} className="pt-4">
              <Autocomplete
                lable={lang("INPUT.SALARY.KIND")}
                isRequired
                isReadOnly={!isEdit || isCollaborators}
                options={salaryTypes}
                value={formik.values?.loaiLuong}
                name="loaiLuong"
                onChange={(value) =>
                  handleChangeSelect(VARIABLE_STRING.LOAI_LUONG, value)
                }
                errors={formik.errors?.loaiLuong}
                touched={formik.touched?.loaiLuong}
              />
            </Col>
            <Col xs={4} className="pt-4">
              <Autocomplete
                lable={lang("INPUT.SALARY.ADJUSTMENT.TYPE")}
                isRequired
                isReadOnly={!isEdit || isCollaborators}
                options={salaryAdjustmentTypes}
                value={formik.values?.loaiDieuChinhLuong || null}
                name="loaiDieuChinhLuong"
                onChange={(selectedOption) =>
                  handleChangeSelect("loaiDieuChinhLuong", selectedOption)
                }
                menuPortalTarget={document.body}
                errors={formik.errors?.loaiDieuChinhLuong}
                touched={formik.touched?.loaiDieuChinhLuong}
              />
            </Col>
            <Col xs={4} className="pt-4">
              <Autocomplete
                lable={lang("INPUT.SALARY.RANK")}
                name="bac"
                isRequired={!isCollaborators && !isSalaryAccordingPosition}
                value={formik.values?.bac || null}
                searchFunction={getBacLuongByChucDanh}
                searchObject={
                  formik.values?.chucDanh?.id ||
                  employeeProfiles?.viTriCongViecId
                }
                sort={(data: any) =>
                  data.sort((a: any, b: any) => a.bacLuong - b.bacLuong)
                }
                urlData="data.data"
                isReadOnly={!isEdit || isCollaborators || probationary || isSalaryAccordingPosition}
                onChange={(value) =>
                  handleChangeSelect(VARIABLE_STRING.BAC, value)
                }
                options={[]}
                getOptionLabel={(option) => option?.bacLuong}
                dependencies={[
                  formik.values?.chucDanh?.id,
                  employeeProfiles?.viTriCongViecId
                ]}
                errors={formik.errors?.bac}
                touched={formik.touched?.bac}
              />
            </Col>
            <Col xs={4} className="pt-4">
              <TextValidator
                isRequired={!isCollaborators && !isSalaryAccordingPosition}
                lable={lang("INPUT.SALARY.COEFFICIENT")}
                name="heSoBacLuong"
                type="number"
                value={formik.values?.heSoBacLuong || ""}
                readOnly={true}
                errors={formik.errors?.heSoBacLuong}
                touched={formik.touched?.heSoBacLuong}
              />
            </Col>
            <Col xs={4} className="pt-4">
              <Autocomplete
                lable={lang("INPUT.SALARY.RATE")}
                isReadOnly={!isEdit}
                options={salaryRate}
                value={formik.values?.phanTramHuong}
                name="phanTramHuong"
                onChange={(selectedOption) =>
                  handleChangeSelect("phanTramHuong", selectedOption)
                }
              />
            </Col>
            <Col xs={4} className="pt-4">
              <RangeDatePicker
                label={lang("INPUT.SALARY.BENEFIT_TIME")}
                isRequired
                startDateName="ngayHuongLuongTuNgay"
                endDateName="ngayHuongLuongDenNgay"
                isView={!isEdit}
                isReadOnlyEndDate={false}
                handleChange={handleChangeRangeDatePicker}
                value={formik.values}
                setFieldValue={formik.setFieldValue}
                touch={formik.touched}
                errors={formik.errors}
              />
            </Col>
            <Col xs={4} className="pt-4">
              <RangeDatePicker
                label={lang("INPUT.SALARY.KEEP_LEVEL_TIME")}
                startDateName="thoiGianGiuBacDuKienBatDau"
                endDateName="thoiGianGiuBacDuKienKetThuc"
                isView={true}
                handleChange={formik.handleChange}
                value={formik.values}
                setFieldValue={formik.setFieldValue}
                touch={formik.touched}
                errors={formik.errors}
              />
            </Col>
            <Col xs={4} className="pt-4">
              <TextValidator
                lable={lang("SALARY.SENIORITY_ALLOWANCES")}
                name="phuCapThamNienVuotKhung"
                value={convertNumberPrice(formik.values?.phuCapThamNienVuotKhung)}
                type="text"
                readOnly={!isEdit}
                onChange={handleChangeMoney}
              />
            </Col>
            <Col xs={12}>
              <Row>
                <Col xs={7}>
                  <Row>
                    <Col xs={6} className="pt-4">
                      <TextValidator
                        lable={lang("SALARY.DIFFERENT_ALLOWANCES")}
                        name="phuCapChenhLechBaoLuu"
                        value={convertNumberPrice( formik.values?.phuCapChenhLechBaoLuu)}
                        type="text"
                        readOnly={!isEdit}
                        onChange={handleChangeMoney}
                      />
                    </Col>
                    <Col xs={6} className="pt-4">
                      <TextValidator
                        lable={lang("SALARY.COEFFICIENT_DIFFERENT_ALLOWANCES")}
                        name="heSoPhuCapChenhLechBaoLuu"
                        value={formik.values?.heSoPhuCapChenhLechBaoLuu}
                        type="number"
                        onKeyDown={numberExceptThisSymbols}
                        readOnly={!isEdit}
                        onChange={formik.handleChange}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs={5}>
                  <Row>
                    <Col xs={6} className="pt-4">
                      <TextValidator
                        lable={lang("INPUT.SALARY.AGREEMENTS")}
                        name="luongThoaThuan"
                        isRequired={isCollaborators || isSalaryAccordingPosition}
                        value={convertNumberPrice(formik.values?.luongThoaThuan)}
                        type="text"
                        readOnly={!isEdit}
                        onChange={handleChangeMoney}
                        errors={formik.errors?.luongThoaThuan}
                        touched={formik.touched?.luongThoaThuan}
                      />
                    </Col>
                    <Col xs={6} className="pt-4">
                      <TextValidator
                        lable={lang("GENERAL.INFO.BACK_PAY_SECTION")}
                        name="mucTruyLinh"
                        isRequired={isCollaborators || isSalaryAccordingPosition}
                        value={convertNumberPrice(formik.values?.mucTruyLinh)}
                        type="text"
                        readOnly={!isEdit}
                        onChange={handleChangeMoney}
                        errors={formik.errors?.mucTruyLinh}
                        touched={formik.touched?.mucTruyLinh}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={12} className="pt-4">
              <TextArea
                className="w-100"
                lable={lang("GENERAL.NOTE")}
                name="moTaCongViec"
                value={formik.values?.ghiChu || ""}
                type="text"
                as="textarea"
                rows="2"
                readOnly={!isEdit}
                bulletPoints="+"
                onChange={(value: any) => formik.setFieldValue("ghiChu", value)}
                touched={formik.touched?.ghiChu}
                errors={formik.errors?.ghiChu}
              />
            </Col>
          </Row>
          <div className="mt-4">
            <span className="fs-3 fw-bold">
              {lang("INPUT.SALARY_INFO.DECISION")}
            </span>
          </div>
          <Row className="ms-2">
            <Col xs={4} className="pt-4">
              <TextValidator
                lable={lang("INPUT.WORKPROCESS.DECISION.SIGNED.DATE")}
                name="ngayKyToTrinh"
                isReadOnly={!isEdit}
                value={
                  covertDateToString(formik.values?.ngayKyToTrinh) || ""
                }
                type="date"
                onBlur={() =>
                  handleBlurDate(
                    formik.setFieldValue,
                    formik.values?.ngayKyToTrinh,
                    "ngayKyToTrinh"
                  )
                }
                onChange={formik.handleChange}
              />
            </Col>
            <Col xs={4} className="pt-3">
              <TextValidator
                lable={lang("INPUT.WORKPROCESS.DECISION.SIGNED.NUMBER")}
                name="soToTrinh"
                isReadOnly={!isEdit}
                value={formik.values?.soToTrinh || ""}
                type="text"
                onChange={formik.handleChange}
              />
            </Col>
            <Col xs={4} className="pt-4">
              <TextValidator
                lable={lang("SALARY.PLACE_OF_DECISION")}
                name="noiQuyetDinh"
                value={formik.values?.noiQuyetDinh || ""}
                type="text"
                readOnly={!isEdit}
                onChange={formik.handleChange}
              />
            </Col>
            <Col xs={4} className="pt-4">
              <TextValidator
                lable={lang("INPUT.WORKPROCESS.DECISION.DATE")}
                name="ngayQuyetDinh"
                value={covertDateToString(formik.values?.ngayQuyetDinh) || ""}
                type="date"
                onBlur={() =>
                  handleBlurDate(
                    formik.setFieldValue,
                    formik.values?.ngayQuyetDinh,
                    "ngayQuyetDinh"
                  )
                }
                readOnly={!isEdit}
                onChange={formik.handleChange}
                errors={formik.errors?.ngayQuyetDinh}
                touched={formik.touched?.ngayQuyetDinh}
              />
            </Col>
            <Col xs={4} className="pt-4">
              <TextValidator
                lable={lang("INPUT.WORKPROCESS.DECISION.NUMBER")}
                name="soQuyetDinh"
                value={formik.values?.soQuyetDinh || ""}
                type="text"
                readOnly={!isEdit}
                onChange={formik.handleChange}
              />
            </Col>
            <Col xs={4} className="pt-4">
              <Autocomplete
                className="z-index-2"
                lable={lang("INPUT.WORKPROCESS.DECISION.PERSON")}
                options={peopleDecisions}
                onChange={(selectedOption) =>
                  handleChangeSelect("nguoiQuyetDinh", selectedOption)
                }
                isReadOnly={!isEdit}
                name="nguoiQuyetDinh"
                value={formik.values?.nguoiQuyetDinh || null}
                isAddNew
                onAddNew={() => setIsShowDialogAddRepresentative(true)}
                errors={formik.errors.nguoiQuyetDinh}
                touched={formik.touched.nguoiQuyetDinh}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="flex-center">
          <GroupButton type="btn-cancel" handleCloseUpdateDialog={handleClose} />
          {!isResign &&
            (isEdit ? (
              <GroupButton type="btn-save" />
            ) : (hasAuthority(PERMISSIONS.EMPLOYEE, PERMISSION_ABILITY.UPDATE) &&
              <GroupButton type="btn-edit" handleSaveEdit={() => setIsEdit(true)} />
            ))}
          {(salaryInfo?.id && templateWord) && (
            <GroupButton handleEvent={() => setIsShowDialogExportWord(true)}>
              {lang("GENERAL.EXPORT_WORD")}
            </GroupButton>
          )}
        </Modal.Footer>
      </Form>

      {isShowDialogExportWord && (
        <ExportWord
          open={isShowDialogExportWord}
          handleClose={() => setIsShowDialogExportWord(false)}
          templateList={templateWord || []}
          getObj={{
            employeeId: employeeProfiles.id,
            lichSuLuongId: salaryInfo.id,
            type: DATA_WORD_TYPES.SALARY_USULLY
          }}
        />
      )}
      {isShowDialogAddRepresentative && (
        <AddNewRepresentative
          handleClose={() => setIsShowDialogAddRepresentative(false)}
          handleCloseAndSearch={handleCloseAndSearchRepresentative}
        />
      )}
    </Modal>
  );
}

export default SalaryDevelopmentDialog;

import { Modal } from "react-bootstrap";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import { IRemindHeader } from "../../models/remindModels";
import { GroupButton } from "../../../components/GroupButton";

interface IProps {
  open: boolean;
  handleClose: () => void;
  remindInfo: IRemindHeader;
}

function RemindViewDialog(props: IProps) {
  const { open, handleClose, remindInfo } = props;
  const { lang } = useMultiLanguage();

  return (
    <Modal show={open} onHide={handleClose} backdrop="static" centered size="xl">
      <Modal.Header closeButton className="spaces py-12">
        <Modal.Title className="spaces fs-20 fw-600">{remindInfo.tilte}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{remindInfo.remindTable()}</Modal.Body>
      <Modal.Footer className="flex-center">
        <GroupButton outline handleEvent={handleClose}>{lang("BTN.CLOSE")}</GroupButton>
      </Modal.Footer>
    </Modal>
  );
}

export default RemindViewDialog;

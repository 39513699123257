import {CODE_STATUS, LIST_STATUS} from "../../manager-request/constants/managerRequestConsts";
import { IShiftDivision } from "../models/shiftDivisionModels";

export const INIT_SHIFT_DIVISION: IShiftDivision = {
  ten: "",
  ngayBatDau: null,
  ngayKetThuc: null,
  ngayDuyet: null,
  nguoiPheDuyet: null,
  nguoiPheDuyetId: "",
  nguoiPheDuyetText: "",
  configPhanCaDtoList: [],
  bangPhanCaDetailDtoList: [],
  trangThai: LIST_STATUS.find((status) => status.code === CODE_STATUS.TAO_MOI) || LIST_STATUS[0]
}

export const MAPPING_NAME_TYPE_DUTY = {
  TRUC_CHINH: "TC",
  THUONG_TRU: "TT"
}

export const MIN_EMPLOYEE_DUTY = 0;
export const MAX_EMPLOYEE_DUTY = 10;

export const KEY_TAB = {
	CHUA_GUI: "1",
	CHO_DUYET: "2",
	DA_DUYET: "3",
	TU_CHOI: "4"
};

export const LIST_TAB_STATUS = [
	{
		eventKey: KEY_TAB.CHUA_GUI,
		title: "Chưa gửi",
	},
	{
		eventKey: KEY_TAB.CHO_DUYET,
		title: "Chờ duyệt",
	},
	{
		eventKey: KEY_TAB.DA_DUYET,
		title: "Đã duyệt",
	},
	{
		eventKey: KEY_TAB.TU_CHOI,
		title: "Từ chối",
	}
];
import { useFormik } from "formik";
import { FC, useContext, useState } from "react";
import { Col, Modal, Row, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Autocomplete from "../../../components/input/autocomplete";
import TextValidator from "../../../components/input/text-validator";
import { INIT_DEDUCT } from "../../constants/profileDialogConsts";
import { EmployeeProfile, IDeduct } from "../../models/profileModels";
import { addKhauTru, updateKhauTru } from "../../services/dialogServices";
import { deductOpt } from "../../../contract/constants/allowanceConsts";
import AppContext from "../../../../AppContext";
import { useProfileContext } from "../../ProfileContext";
import { convertNumberPrice, convertTextPrice, hasAuthority } from "../../../utils/functionUtils";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import { RESPONSE_STATUS_CODE } from "../../../constants/moduleConsts";
import { GroupButton } from "../../../components/GroupButton";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../../../constants";
interface IProps {
  handleCloseDeductDialog: () => void;
  handleCloseAndSearch: () => void;
  deductInfo: IDeduct;
  isView: boolean;
  profileInfo: EmployeeProfile;
}
const DeductDialog: FC<IProps> = (props) => {
  const { handleCloseDeductDialog, handleCloseAndSearch, deductInfo, profileInfo, isView } = props;
  const { lang } = useMultiLanguage();

  const { setPageLoading } = useContext(AppContext);
  const { isResign } = useProfileContext();

  const [isEdit, setIsEdit] = useState<boolean>(!isView);

  const validationSchema = Yup.object().shape({
    tenKhoanKhauTru: Yup.object()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    giaTri: Yup.string()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
  });

  const handleSubmit = async (values: IDeduct) => {
    try {
      setPageLoading(true);
      values.employeeId = String(profileInfo?.id);
      const res = deductInfo?.id
        ? await updateKhauTru(deductInfo?.id, values)
        : await addKhauTru(values);
      if (res?.data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(
          deductInfo?.id
            ? lang("TOAST.EDIT.SUCCESS")
            : lang("TOAST.CREATE.SUCCESS")
        );
        handleCloseAndSearch();
      } else {
        toast.warning(`${res?.data?.message}`);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: deductInfo?.id ? deductInfo : INIT_DEDUCT,
    onSubmit: handleSubmit,
    validationSchema
  });

  const handleChangeMoney = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, Number(convertTextPrice(value)));
  };

  return (
    <Modal
      show={true}
      onHide={handleCloseDeductDialog}
      backdrop="static"
      size="lg"
      centered
    >
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>
            {lang(`${
              deductInfo
                ? "GENERAL.DEDUCT.UPDATE"
                : "GENERAL.DEDUCT.ADD"
            }`)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="ps-4">
            <Col xs={4}>
              <Autocomplete
                lable={lang("ALLOWANCE.NAME")}
                name="tenKhoanKhauTru"
                isRequired
                options={deductOpt}
                value={formik.values?.tenKhoanKhauTru}
                isReadOnly={!isEdit}
                onChange={(selectedOption) =>
                  formik.setFieldValue("tenKhoanKhauTru", selectedOption)
                }
                errors={formik.errors?.tenKhoanKhauTru}
                touched={formik.touched?.tenKhoanKhauTru}
              />
            </Col>
            <Col xs={4}>
              <TextValidator
                lable={lang("ALLOWANCE.VALUE")}
                isRequired
                name="giaTri"
                type="text"
                readOnly={!isEdit}
                onChange={handleChangeMoney}
                value={convertNumberPrice(formik.values?.giaTri || null)}
                errors={formik.errors?.giaTri}
                touched={formik.touched?.giaTri}
              />
            </Col>
            <Col xs={4}>
              <Form.Check
                className="custom-form-check"
                name="tinhCongHuongLuong"
                label={lang("ALLOWANCE.PAYROLL")}
                type="checkbox"
                checked={Boolean(formik.values?.tinhCongHuongLuong)}
                onChange={formik.handleChange}
                readOnly={!isEdit}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <GroupButton type="btn-cancel" handleCloseUpdateDialog={handleCloseDeductDialog} />
          {!isResign &&
            (isEdit ? (
              <GroupButton type="btn-save" />
            ) : (hasAuthority(PERMISSIONS.EMPLOYEE, PERMISSION_ABILITY.UPDATE) &&
              <GroupButton type="btn-edit" handleSaveEdit={() => setIsEdit(true)} />
            ))}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
export { DeductDialog };


import {  useContext, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import useMultiLanguage from '../../hook/useMultiLanguage';
import { checkStatus, formatDateTable } from '../utils/functionUtils';
import { INIT_SEARCH_OBJECT, RESPONSE_STATUS_CODE, TYPE } from '../constants/moduleConsts';
import { GroupButton } from '../components/GroupButton';
import "./time-sheet.scss";
import { toast } from 'react-toastify';
import AppContext from '../../AppContext';
import { ITimesheet } from './models/timesheetModels';
import { searchListTimesheet } from './services/timesheetServices';
import TableCustom, { columnNamesType } from '../components/table-custom/TableCustom';
import { LIST_STATUS } from '../receiving-citizens/constants/receivingCitizensConsts';
import InputSearch from '../components/input/InputSearch';
import TimesheetForm from './components/TimesheetForm';

function Timesheet() {
	const { lang } = useMultiLanguage();
	const { setPageLoading } = useContext(AppContext);

	const [listTimesheet, setListTimesheet] = useState<ITimesheet[]>([]);
	const [openTimesheetForm, setOpenOfficeWorkForm] = useState<boolean>();
	const [timesheetInfo, setTimesheetInfo] = useState<ITimesheet>({} as ITimesheet);
	const [totalPage, setTotalPage] = useState<number>(0);
	const [totalElements, setTotalElements] = useState<number>(0);
	const [numberOfElements, setNumberOfElements] = useState<number>(0);
	const [searchObject, setSearchObject] = useState<any>(INIT_SEARCH_OBJECT);

	const handleOpenForm = (info: any) => {
		setOpenOfficeWorkForm(true);
		setTimesheetInfo(info);
	};

	const handleClose = () => {
		setOpenOfficeWorkForm(false);
		setTimesheetInfo({} as ITimesheet);
	};

	const getListOfficeWorkSchedule = async (searchData?: any) => {
		try {
			setPageLoading(true);
			setSearchObject({ ...searchData })
			const { data } = await searchListTimesheet(searchData);
			if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
				setListTimesheet(data?.data?.content || []);
				setTotalPage(data?.data?.pageable?.totalPages);
				setTotalElements(data?.data?.pageable?.totalElements);
				setNumberOfElements(data?.data?.pageable?.numberOfElements);
			} else {
				toast.warning(data?.message);
			}
		} catch (err) {
			toast.error(lang("GENERAL.ERROR"));
		} finally {
			setPageLoading(false);
		}
	};

	const handleSearch = (data: any = {}) => {
		const dataSearch: any = {
			...searchObject,
			...data
		};
		dataSearch.keyword = dataSearch?.keyword?.trim() || "";
		getListOfficeWorkSchedule(dataSearch);
	};

	useEffect(() => {
		if (searchObject?.pageIndex && searchObject?.pageSize) {
			handleSearch();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchObject?.pageIndex, searchObject?.pageSize])

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchObject({
			...searchObject,
			keyword: e.target?.value
		});
	};

	const handleCloseAndSearch = () => {
    handleSearch();
    handleClose();
  }


	const columns: columnNamesType[] = [
		{
			name: "STT",
			field: "",
			render: (_row, _index, stt) => <span>{stt}</span>
		},
		{
			name: lang("STATUS"),
			field: "trangThai",
			headerStyle: {
				minWidth: "100px"
			},
			render: (row: any) => (
				<span className={`${checkStatus(LIST_STATUS, Number(row?.trangThai?.code))} status`}>
					{row?.trangThai?.name}
				</span>
			)
		},
		{
			name: lang("INPUT.DEPARTMENTS"),
			field: "phongBanText",
			headerStyle: {
				minWidth: "200px",
			},
			cellStyle: {
				textAlign: "left"
			}
		},
		{
			name: lang("GENERAL.TIME"),
			field: "thoiGian",
			headerStyle: {
				minWidth: "120px"
			},
			render: (row: any) => <span>{row?.thang + "/" + row?.nam}</span>
		},
		{
			name: lang("DATE.APPROVE"),
			field: "ngayDuyet",
			headerStyle: {
				minWidth: "120px"
			},
			render: (row: any) => <span>{formatDateTable(row?.ngayDuyet)}</span>
		},
		{
			name: lang("WELFARE.ACTUAL_COST.APPROVED_PEOPLE"),
			field: "nguoiDuyetText",
			headerStyle: {
				minWidth: "120px"
			},
			cellStyle: {
				textAlign: "left"
			}
		},
	];

	return (
		<div className="time-sheets spaces px-8 py-12 flex-1">
			{!openTimesheetForm ? (
				<>
					<Row className="spaces mb-12 flex-middle flex-space-between">
						<Col xs={5} xl={6} xxl={7} className="p-0">
							<h2 className="breadcrumb-title">{lang("TIMESHEET.LIST")}</h2>
						</Col>
						<Col xs={5} xl={3} xxl={3} className="flex">
							<div className="spaces mr-10 flex-1">
								<InputSearch
									className="border-3px"
									value={searchObject?.keyword}
									placeholder="Tìm kiếm"
									handleSearch={() => handleSearch({ pageIndex: 1 })}
									handleChange={handleChange}
									isEnter
								/>
							</div>
							<GroupButton handleEvent={() => handleSearch({ pageIndex: 1 })}>
								{lang("BTN.SEARCH")}
							</GroupButton>
						</Col>
					</Row>
					<Row className="bg-white">
						<Col className="table-scroll">
							<TableCustom
								id="office-work-schedule-list"
								data={listTimesheet}
								columns={columns}
								searchObject={searchObject}
								setSearchObject={setSearchObject}
								notDelete={true}
								type={TYPE.MULTILINE}
								totalPages={totalPage}
								totalElements={totalElements}
								numberOfElements={numberOfElements}
								handleDoubleClick={(row: any) => handleOpenForm(row)}
							/>
						</Col>
					</Row>
				</>
			) : (
				<TimesheetForm
					handleClose={handleClose}
					timesheetInfo={timesheetInfo}
					handleCloseAndSearch={handleCloseAndSearch}
				/>
			)}
		</div>
	)
}

export default Timesheet

import { FC, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import * as Yup from "yup";
import { useFormik } from 'formik';
import TextArea from '../../components/input/TextArea';
import useMultiLanguage from '../../../hook/useMultiLanguage';

interface Props {
  show: boolean;
  isView?: boolean;
  onCloseClick?: () => void
  onSubmit: (data: string) => void;
  field: string
  title?: string;
}

const RefuseDialog: FC<Props> = (props) => {
  const {
    show,
    onCloseClick,
    onSubmit,
    title,
    field = "reason"
  } = props
  const { lang } = useMultiLanguage();

  const validationSchema = Yup.object().shape({
    [field]: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
  });

  const handleSubmit = () => {
    onSubmit(formik.values?.[field])
  }

  const formik = useFormik({
    initialValues: { [field]: "" },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    formik?.setValues({ [field]: "" })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  return (
    <Modal
      show={show}
      centered
      animation
      className='custom-modal'
    >
      <Modal.Header className="bg-primary p-3">
        <Modal.Title className="text-white text-uppercase">{title || lang("DECLARE_ASSETS.STATUS.REJECT")}</Modal.Title>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body className="d-flex justify-content-center p-4">
          <TextArea
            className="w-100"
            lable="Lí do từ chối"
            name={field}
            value={formik.values?.[field] || ""}
            type="text"
            as="textarea"
            rows="3"
            bulletPoints="+"
            onChange={(value: any) => formik.setFieldValue(field, value)}
            touched={formik.touched?.[field]}
            errors={formik.errors?.[field]}
          />
        </Modal.Body>
        <Modal.Footer className="p-3 center ">
          <Button className="btn btn-primary btn-sm" type="submit">
            {lang("BTN.CONFIRM")}
          </Button>
          <Button className="btn btn-secondary btn-sm" onClick={onCloseClick}>
            {lang("BTN.CANCEL")}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export { RefuseDialog }

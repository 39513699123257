import axios, { AxiosResponse } from "axios";
import { APIResponse } from "../../models/models";
import { DisciplineInfo } from "../models/disciplineModels";
import { paramsConfig } from "../../utils/paramsUtils";

const API_URL = process.env.REACT_APP_API_URL;

export const getDiscipline = (searchData?: any): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/ky-luat/page`;
  return axios.get(url, paramsConfig(searchData));
};

export const updateDiscipline = (id: string, data: DisciplineInfo): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/ky-luat/${id}`;
  return axios.put(url, data);
};

export const addNewDiscipline = (data: DisciplineInfo): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/ky-luat`;
  return axios.post(url, data);
};

export const deleteDiscipline = (ids: string[]): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/ky-luat/ids?ids=${ids.toString()}`;
  return axios.delete(url);
};

export const getKyLuatEmployeeList = (idKhenThuong: string) => {
  let url = `${API_URL}/employee-ky-luat/employee-list/${idKhenThuong}`;
  return axios.get(url);
};

import { Col, Row } from "react-bootstrap";
import TextUnderline from "../../../components/TextUnderline";
import { ValueOrDots } from "../../../components/exportWord/partials";
import { TYPE } from "../../../constants/moduleConsts";
import { IPropsExportWord } from "../../../components/exportWord";

export function ContractProbationWord({ data }: IPropsExportWord) {
  return (
    <div className="word spaces px-70 colors-word">
      <div className="spaces text-center flex flex-between align-start">
        <div className="fs-lg">
          <p>{data.soYTeHeader}</p>
          <p className="fw-600 text-uppercase">
            <span className="header-underline fs-lg">
              <ValueOrDots className="fs-lg" value={data.donViKyHopDongText} size={70} />
            </span>
          </p>
        </div>
        <div className="fw-600 fs-lg">
          <p>CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM</p>
          <p>
            <span className="header-underline fs-lg">Độc lập - Tự do - Hạnh phúc</span>
          </p>
        </div>
      </div>
      <div className="spaces py-16 text-center">
        <p className="fw-600 fs-lg">HỢP ĐỒNG LAO ĐỘNG</p>
      </div>
      <div className="text-italic text-indent-40 text-justify">
        <p>Căn cứ Bộ luật lao động số 45/2019/QH14 ngày 20/11/2019;Nghị định 145/2020/NĐ-CP ngày 14/12/2020 của Chính phủ;</p>
        <p>{data?.quyetDinhHopDong ?? ""}</p>
      </div>
      <div className="text-indent-40">
        <div className="spaces pl-40">
          <div className="flex">
            <TextUnderline readOnly label="Chúng tôi, một bên là Ông/bà" value={data.nguoiDaiDienCtyKyText} />
            <TextUnderline readOnly label="Quốc tịch" value={data.quocTichText} />
          </div>
          <TextUnderline readOnly label="Chức vụ" value={data.nguoiDaiDienCtyChucDanhText} />
          <p className="fw-600">
            <TextUnderline readOnly label="Đại diện cho" value={data.donViKyHopDongText} />
          </p>
          <p>Địa chỉ: {data?.unitAddress ?? ""}</p>
          <p>Điện thoại: {data?.unitPhone ?? ""}; Email: {data?.unitEmail ?? ""}</p>
          <div className="flex">
            <TextUnderline readOnly label="Và một bên là ông/bà" value={data.fullName} />
            <TextUnderline readOnly label="Quốc tịch" value={data.daiDienQuocTich} />
          </div>
          <Row className="flex">
            <Col xs={4}>
              <TextUnderline readOnly label="Sinh ngày" value={data.birthdate} type={TYPE.DATE} />
            </Col>
            <Col xs={8}>
              <TextUnderline readOnly label="Tại" value={data.noiSinh} />
            </Col>
          </Row>
          <TextUnderline readOnly label="Trình độ chuyên môn" value={data.trinhDoChuyenMon} />
          <TextUnderline readOnly label="Hộ khẩu thường trú" value={data.hoKhauThuongTru} />
          <p className="flex">
            <TextUnderline readOnly label="Số CCCD/Hộ chiếu" value={data.soCMNDOrCCCD} />
            <TextUnderline readOnly label="Cấp ngày" value={data.ngayCapCMNDOrCCCD} type={TYPE.DATE} />
          </p>
          <TextUnderline readOnly label="Nơi cấp" value={data.noiCapCMNDOrCCCD} />
        </div>
        <p>
          Ngày{" "}
          <span className="fw-600">
            <ValueOrDots value={data.ngayKy} />
          </span>{" "}
          tại <ValueOrDots value={data.donViKyHopDongText} size={70} />, thoả thuận ký kết hợp đồng lao động với các
          điều khoản sau đây
        </p>
        <p className="fw-600">Điều 1. Thời hạn và nhiệm vụ hợp đồng:</p>
        <p>1. Loại hợp đồng lao động: {data.loaiHopDong}</p>
        <p>
          2. Thời gian thực hiện hợp đồng: Từ ngày <ValueOrDots value={data.ngayCoHieuLuc} /> đến ngày{" "}
          <ValueOrDots value={data.ngayHetHan} />.
        </p>
        <div className="spaces pl-40">
          <TextUnderline
            readOnly
            label="3. Địa điểm làm việc"
            value={`${data.phongBanText || ""} , ${data.donViKyHopDongText || ""}`}
          />
          <TextUnderline readOnly label="4. Chức danh chuyên môn tương đương" value={data.chucDanhText} />
        </div>
        <p>5. Công việc phải làm: </p>
        <p>
          a) Thực hiện tốt chức trách, nhiệm vụ của chức danh <ValueOrDots value={data.chucDanhText} size={80} /> và các
          nhiệm vụ khác do Trưởng khoa trực tiếp quản lý và phân công.
        </p>
        <p>
          b) Thực hiện tốt nhiệm vụ làm Bác sĩ được quy định trong quy chế Bệnh viện do Bộ Y tế ban hành; Quy chế cơ
          quan.
        </p>
        <p>
          c) Chịu sự quản lý trực tiếp của cán bộ phụ trách khoa, phòng. Nếu không hoàn thành nhiệm vụ tuỳ theo mức độ
          sai phạm khoa, phòng lập biên bản đề nghị Giám đốc xử lý theo Bộ luật Lao động.
        </p>
        <p>6.Chế độ làm việc:</p>
        <p>6.1. Thời gian làm việc theo giờ hành chính 08 giờ/ngày, không quá 48 giờ/tuần.</p>
        <p>6.2. Giờ làm việc:</p>
        <p>- Buổi sáng: Từ 7 giờ 30 phút đến 12 giờ 00 phút.</p>
        <p>- Buổi chiều: Từ 13 giờ 30 phút đến 17 giờ 00 phút.</p>
        <p className="fw-600">Điều 2. Mức lương theo hợp đồng và phương thức thanh toán</p>
        <p>
          - Mức lương: Được hưởng 85% mức lương cơ sở của bậc <ValueOrDots value={data.currentBacLuong} />, chức danh{" "}
          <ValueOrDots value={data.chucDanhText} size={80} />, Mã số:
          <ValueOrDots value={data.maNgach} />, hệ số lương <ValueOrDots value={data.heSoLuong} />, theo thang bảng
          lương của viên chức loại A1.
        </p>
        <p>
          - Các phụ cấp khác: được hưởng trợ cấp về nhà ở, công tác phí... với số tiền{" "}
          <span className="fw-600">.....................đ/tháng</span>(Bằng chữ:
          .................................................................................)
        </p>
        <p>- Phương thức thanh toán: Chuyển khoản.</p>
        <p className="fw-600">Điều 3. Nghĩa vụ và quyền lợi của người lao động:</p>
        <p className="fw-600 text-italic">1. Quyền lợi:</p>
        <p>- Được bố trí công việc, việc làm theo chuyên ngành được đào tạo.</p>
        <p>- Được tiền lương, tiền công theo quy định tại Điều 2 của hợp đồng này.</p>
        <p>- Tiền thưởng: Căn cứ vào Quy chế chi tiêu nội bộ của đơn vị.</p>
        <p>- Tham gia BHXH, BHYT, BHTN: Không.</p>
        <p>- Phương tiện đi lại làm việc: Cá nhân tự túc.</p>
        <p>- Được trang bị bảo hộ khi làm việc (nếu có): Theo quy định.</p>
        <p>
          - Chế độ nghỉ ngơi (nghỉ hàng tuần, phép năm, lễ tết...): Theo quy định của Bộ luật Lao động và Quy chế của
          đơn vị.
        </p>
        <p>
          - Những thoả thuận khác: Nếu không hoàn thành nhiệm vụ được giao; vi phạm nội quy quy chế bệnh viện, quy chế
          chuyên môn, ảnh hưởng xấu đến bệnh viện thì Bệnh viện sẽ đơn phương thanh lý hợp đồng.
        </p>
        <p className="fw-600">2. Nghĩa vụ:</p>
        <p>- Hoàn thành những nhiệm vụ đã cam kết trong hợp đồng;</p>
        <p>- Tham gia trực chuyên môn tại các khoa (nếu có yêu cầu);</p>
        <p>- Chấp hành nội quy, quy chế của đơn vị, kỷ luật làm việc và các quy định của Bộ luật Lao động;</p>
        <p>- Chấp hành việc xử lý kỷ luật và bồi thường thiệt hại theo quy định của Pháp luật;</p>
        <p>- Chấp hành sự phân công điều động của tổ chức, thủ trưởng đơn vị, trưởng (phụ trách) khoa, phòng.</p>
        <p>
          - Bồi thường vi phạm về vật chất: Nếu được trang bị cá nhân để mất hỏng do thiếu trách nhiệm gây nên thì phải
          bồi thường trả cơ quan theo giá quy định hiện hành.
        </p>
        <p className="fw-600">Điều 4. Nghĩa vụ và quyền hạn của người sử dụng lao động: </p>
        <p className="fw-600 text-italic">1. Nghĩa vụ: </p>
        <p>- Bảo đảm việc làm và thực hiện đầy đủ những điều đã cam kết trong Hợp đồng.</p>
        <p>- Thanh toán đầy đủ, đúng thời hạn các chế độ và quyền lợi của người được Hợp đồng làm việc .</p>
        <p className="fw-600 text-italic">2. Quyền hạn:</p>
        <p>
          - Điều hành người được hợp đồng hoàn thành công việc theo Hợp đồng (Bố trí, điều động, tạm ngừng việc … ).
        </p>
        <p>
          - Tạm hoãn, chấm dứt Hợp đồng thuê lao động, kỷ luật người lao động theo quy định của pháp luật và nội quy lao
          động của Bệnh viện.
        </p>
        <p className="fw-600">Điều 5. Điều khoản thi hành:</p>
        <p>
          Hợp đồng này được lập thành 03 bản có giá trị pháp lý như nhau (Người lao động 01 bản; Phòng Tổ chức hành
          chính 01 bản; Phòng Tài chính kế toán 01 bản).
        </p>
      </div>
      <div className="text-center align-start flex flex-between spaces mt-16 pb-70 px-70">
        <div>
          <p className="fw-600">NGƯỜI LAO ĐỘNG</p>
          <p className="spaces mt-70 fw-600">
            <ValueOrDots value={data.fullName} size={60} />
          </p>
        </div>
        <div className="fw-600">
          <p>GIÁM ĐỐC</p>
          <p className="spaces mt-70">
            <ValueOrDots value={data.nguoiDaiDienCtyKyText} size={60} />
          </p>
        </div>
      </div>
    </div>
  );
}

import { FC, useMemo } from "react";
import { IColumnsSpan, IPositionHandleRow } from "./TableCustomSpan";
import { KTSVG } from "../../../../_metronic/helpers";
import { LIST_ICON_HANDLE_ROW } from "./constants/tableCustomSpanConsts";

interface IProps {
  idTable: string;
  rowData: any;
  rowIndex: number;
  rowSpanPrevIndex?: number;
  searchObject: any;
  column: IColumnsSpan;
  colIndex: number;
  stickyColumnCount: number;
  itemList: any[];
  rowSpan: number;
  isHandleNewRow?: boolean;
  listPositionHandleRow?: IPositionHandleRow[];
}

export const TableSpanCol: FC<IProps> = (props) => {
  const {
    idTable,
    rowData,
    column,
    colIndex,
    rowIndex,
    rowSpanPrevIndex,
    searchObject,
    stickyColumnCount,
    itemList,
    rowSpan,
    isHandleNewRow,
    listPositionHandleRow
  } = props;
  const numericalOrder = (((Number(searchObject?.pageIndex || 1) - 1) * Number(searchObject?.pageSize || 10) + rowIndex) + 1);
  const positionHandleRow = useMemo(() => listPositionHandleRow?.find((item) => item.position === colIndex + 1), [colIndex, listPositionHandleRow]);
  const firstColumnHandleRow = useMemo(() => colIndex === 0 && listPositionHandleRow?.find((item) => item.position === 0), [colIndex, listPositionHandleRow]);

  return (
    <td
      data-index-span-prev={rowSpanPrevIndex}
      className={`bg-white ${colIndex < stickyColumnCount ? `sticky-column sticky-column-row-${idTable}-${rowIndex}` : 'relative'}`}
      style={column?.cellStyle}
      key={colIndex}
      rowSpan={rowSpan}
    >
      {column?.render ? column.render(rowData, rowIndex, numericalOrder, itemList) : rowData?.[column?.field]}
      {isHandleNewRow && rowData?.addNewRow && (
        <>
          {firstColumnHandleRow && (
            <span className="icon-handle-table-col position-before" onClick={() => firstColumnHandleRow.handleRow(rowData, rowSpan + rowIndex, itemList)}>
              <KTSVG
                path={LIST_ICON_HANDLE_ROW.find((item) => item.type === firstColumnHandleRow.type)?.icon ?? ""}
                svgClassName='spaces w-20 h-20'
              />
            </span>
          )}
          {positionHandleRow && (
            <span className="icon-handle-table-col" onClick={() => positionHandleRow.handleRow(rowData, rowSpan + rowIndex, itemList)}>
              <KTSVG
                path={LIST_ICON_HANDLE_ROW.find((item) => item.type === positionHandleRow.type)?.icon ?? ""}
                svgClassName='spaces w-20 h-20'
              />
            </span>
          )}
        </>
      )}
    </td>
  );
};

import { useContext, useEffect, useState } from "react";
import { KTSVG } from "../../../../../../_metronic/helpers";
import { convertNumberPrice, exportToFile } from "../../../../utils/functionUtils";
import { exportKhauTru } from "../../../../services/exportFileServices";
import { EmployeeProfile, IDeduct } from "../../../models/profileModels";
import TableCustom from "../../../../components/table-custom/TableCustom";
import { deleteKhauTru, getDeductByEmployee } from "../../../services/dialogServices";
import { EXPORT_FILE_NAME, RESPONSE_STATUS_CODE, TYPE } from "../../../../constants/moduleConsts";
import { toast } from "react-toastify";
import { DeductDialog } from "../../dialogs/DeductDialog";
import useMultiLanguage from "../../../../../hook/useMultiLanguage";
import AppContext from "../../../../../AppContext";

interface Props {
  isView: boolean;
  employeeProfiles: EmployeeProfile;
  identify: string;
  isCallApi: boolean;
}

function Deduct(props: Props) {
  const { lang } = useMultiLanguage();
  const { isView, employeeProfiles, identify, isCallApi } = props;

  const [deductInfo, setDeductInfo] = useState<IDeduct>({});
  const [openDeductDialog, setOpenDeductDialog] = useState<boolean>(false);
  const [listDeduct, setListDeduct] = useState<IDeduct[]>([]);
  const { setPageLoading } = useContext(AppContext);

  useEffect(() => {
    if (!(identify && isCallApi)) return;
    updateDataDeduct();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identify, isCallApi]);

  const updateDataDeduct = async () => {
    if (identify) {
      try {
        let { data } = await getDeductByEmployee(employeeProfiles.id || "");
        if(data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
          setListDeduct(data?.data || []);
        } else {
          toast.warning(data?.message);
        }
      } catch (error) {
        toast.error(lang("GENERAL.ERROR"));
      }
    }
  };

  const handleCloseDeductDialog = () => {
    setOpenDeductDialog(false);
    setDeductInfo({} as IDeduct);
  };

  const handleCloseAndSearch = () => {
    updateDataDeduct();
    handleCloseDeductDialog();
  }

  const handleOpenDeductDialog = (data: any) => {
    setOpenDeductDialog(true);
    setDeductInfo(data);
  };

  const handleDeleteItems = async (ids: string) => {
    try {
      const res = await deleteKhauTru(ids);
      if (res?.data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("GENERAL.DELETE_SUCCESS"));
        updateDataDeduct();
        return true;
      } else toast.warning(`${res?.data?.message}`);
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    }
    await updateDataDeduct();
  };

  const deductColumns = [
    {
      name: lang("TABLE.INDEX"),
      field: "stt",
      headerStyle: {
        minWidth: "100px"
      },
      render: (row: any, index: number, STT: number) => <span>{STT}</span>
    },
    {
      name: lang("ALLOWANCE.NAME"),
      field: "tenKhoanKhauTru",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row: any) => <div>{row?.tenKhoanKhauTru?.name}</div>
    },
    {
      name: lang("ALLOWANCE.PAYROLL"),
      field: "tinhCongHuongLuong",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        minWidth: "200px"
      },
      render: (row: any) => {
        if (row?.tinhCongHuongLuong) {
          return (
            <KTSVG
              path="/media/icons/check.svg"
              className=" svg-icon-1"
            />
          );
        } else {
          return (
            <KTSVG
              path="/media/icons/uncheck.svg"
              className=" svg-icon-1"
            />
          );
        }
      }
    },
    {
      name: lang("ALLOWANCE.VALUE"),
      field: "giaTri",
      headerStyle: {
        minWidth: "250px"
      },
      render: (row: any) => <span>{convertNumberPrice(row?.giaTri || null)}</span>
    }
  ];

  return (
    <div className="block-content">
      <TableCustom
        title={lang("GENERAL.DEDUCTIONS")}
        isActionTableTab
        data={listDeduct}
        columns={deductColumns}
        handleDelete={handleDeleteItems}
        justFilter={true}
        notDelete={isView}
        buttonAdd={!isView}
        buttonExportExcel={true}
        type={TYPE.MULTILINE}
        noPagination={true}
        handleExportExcel={() => 
          exportToFile({
            exportAPI: () => exportKhauTru(String(employeeProfiles?.id)), 
            fileName: EXPORT_FILE_NAME.KHAU_TRU,
            setPageLoading
          })
        }
        handleOpenDialog={() => handleOpenDeductDialog({})}
        handleDoubleClick={handleOpenDeductDialog}
      />
      {openDeductDialog && (
        <DeductDialog
          handleCloseDeductDialog={handleCloseDeductDialog}
          handleCloseAndSearch={handleCloseAndSearch}
          deductInfo={deductInfo}
          isView={isView}
          profileInfo={employeeProfiles}
        />
      )}
    </div>
  );
}

export default Deduct;

import { useFormik } from "formik";
import { FC, useEffect, useMemo, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import * as Yup from "yup";
import { IAuth, INodeTree, IRole } from "../../models/managerModels";
import { addNewRole, searchAllAuthority, updateRole } from "../../services/managerServices";
import { toast } from "react-toastify";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import TextValidator from "../../../components/input/text-validator";
import { RESPONSE_STATUS_CODE } from "../../../constants/moduleConsts";
import { hasAuthority } from "../../../utils/functionUtils";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../../../constants";
import { GroupButton } from "../../../components/GroupButton";
import { INIT_ROLE } from "../../constants/managerConsts";
import { checkAndModifyArray, convertDataEdit, findChildrenNamesByValue, handleConvertData } from "../../utils/managerUtils";
import SelectedPermissions from "./SelectPermission";

interface IProps {
  handleCloseDialog: () => void;
  roleInfo: IRole;
  handleCloseAndSearchDialogRole: () => void;
}

const RoleDialog: FC<IProps> = (props) => {
  const { handleCloseDialog, roleInfo, handleCloseAndSearchDialogRole } = props;
  const { lang } = useMultiLanguage();

  const [permissionsList, setPermissionsList] = useState<INodeTree[]>([]);
  const [listAuth, setListAuth] = useState<string[]>([]);

  const isUpdate = useMemo(() => hasAuthority(PERMISSIONS.ROLE, PERMISSION_ABILITY.UPDATE), []);

  useEffect(() => {
    formik.setValues({ ...formik.values, ...roleInfo });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleInfo]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable()
  });

  const handleSubmit = async (values: IRole) => {
    try {
      const { data } = roleInfo.id ? await updateRole(roleInfo.id, values) : await addNewRole(values);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        let message = roleInfo?.id ? "TOAST.EDIT.SUCCESS" : "TOAST.CREATE.SUCCESS";
        toast.success(lang(message));
        handleCloseAndSearchDialogRole();
      } else {
        toast.warning(data?.message);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const formik = useFormik({
    initialValues: INIT_ROLE,
    onSubmit: handleSubmit,
    validationSchema
  });

  useEffect(() => {
    let _permissionsList = convertDataEdit(permissionsList, formik.values?.authorities);
    setPermissionsList(_permissionsList);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values?.authorities]);
  
  const getListAuth = async () => {
    try {
      const { data } = await searchAllAuthority();
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        const authorities: IAuth[] = data?.data?.content || [];
        setListAuth(authorities.map(item => item.name) || []);
        const listAuthoritiesConverted = handleConvertData(authorities);
        if (roleInfo?.id) {
          let permissionsList = convertDataEdit(listAuthoritiesConverted, roleInfo?.authorities || []);
          setPermissionsList(permissionsList);
        } else {
          setPermissionsList(listAuthoritiesConverted);
        }
      } else toast.warning(data?.message);
    } catch (error) {
      console.error(error);
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  useEffect(() => {
    getListAuth();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  
  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    let checked = e.target.checked;
    if (listAuth.includes(value)) {
      // Xử lý khi check children nhỏ nhất
      let _authorities = checkAndModifyArray(formik.values?.authorities, value);
      formik.setFieldValue("authorities", [..._authorities]);
    } else {
      // Xử lý khi check parent bất kỳ
      let children = findChildrenNamesByValue(permissionsList, value);
      let _authorities: string[] = [];
      if (checked) {
        _authorities = formik.values?.authorities;
        for (let index = 0; index < children.length; index++) {
          if (!formik.values?.authorities.includes(children[index])) {
            _authorities.push(children[index]);
          }
        }
      } else {
        _authorities = formik.values?.authorities;
        _authorities = _authorities.filter((item) => !children.includes(item));
      }
      formik.setFieldValue("authorities", [..._authorities]);
    }
  };

  const handleShowListPermission = (keyPermission: string) => {
    let newPermissionsList = [...permissionsList];
    newPermissionsList = newPermissionsList.map((item) => {
      if (item.value === keyPermission) {
        return {
          ...item,
          notShow: !item.notShow,
        };
      } else {
        return item;
      }
    });

    setPermissionsList(newPermissionsList);
  };
  
  return (
    <Modal
      show={true}
      onHide={handleCloseDialog}
      backdrop="static"
      size="xl"
      centered
      fullscreen
      className="roles-dialog"
    >
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>{lang(!roleInfo?.id ? "ROLE.ADD" : "ROLE.EDIT")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="px-8">
            <Col xs={6} xxl={4}>
              <TextValidator
                isRequired
                name="name"
                lable={lang("ROLE.CODE")}
                type="text"
                isReadOnly={!isUpdate}
                value={formik.values?.name || ""}
                onChange={formik.handleChange}
                errors={formik.errors?.name}
                touched={formik.touched?.name}
              />
            </Col>
            <Col xs={6} xxl={8}>
              <TextValidator
                name="description"
                lable={lang("ROLE.NAME")}
                type="text"
                isReadOnly={!isUpdate}
                value={formik.values?.description || ""}
                onChange={formik.handleChange}
                errors={formik.errors?.description}
                touched={formik.touched?.description}
              />
            </Col>
          </Row>
          <Row className="px-8 pt-6 tree-permission">
            <SelectedPermissions
              data={permissionsList}
              isView={!isUpdate}
              handleShowListPermission={handleShowListPermission}
              handleCheck={handleCheck}
            />
          </Row>
        </Modal.Body>
        <Modal.Footer className="flex justify-content-center">
          <GroupButton
            type="btn-cancel"
            handleCloseUpdateDialog={handleCloseDialog}
          />
          {isUpdate && <GroupButton type="btn-save" />}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
export { RoleDialog };

import React, { useEffect } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { useFormik } from "formik";
import { INIT_ATTACHED_PEOPLE } from "../constants/welfareConsts";
import { IAttachedPeople } from "../models/welfareModels";
import Autocomplete from "../../components/input/autocomplete";
import TextValidator from "../../components/input/text-validator";
import { REGEX, VARIABLE_STRING } from "../../constants/moduleConsts";
import { listGender } from "../../profile/constants/profileDialogConsts";
import { TYPE_CATEGORY } from "../../constants/moduleConsts";
import { convertNumberPrice, convertTextPrice, generateRandomId } from "../../utils/functionUtils";
import * as Yup from "yup";
import { GroupButton } from "../../components/GroupButton";
import { searchAllSimpleValue } from "../../services/services";

interface IProps {
  handleClose: () => void;
  open: boolean;
  isView: boolean;
  attachInfo: IAttachedPeople;
  handleAdd: (data: IAttachedPeople) => void;
  handleEdit: (data: IAttachedPeople) => void;
}

function AttachDialog(props: IProps) {
  const { handleClose, open, isView, attachInfo, handleAdd, handleEdit } = props;
  const { lang } = useMultiLanguage();

  useEffect(() => {
    if (attachInfo?.id) {
      formik.setValues({
        ...attachInfo,
        quanHe: attachInfo?.quanHeId
          ? {
              id: attachInfo?.quanHeId || "",
              value: attachInfo?.quanHeText || ""
            }
          : null
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachInfo]);

  const handleSubmit = (values: IAttachedPeople) => {
    let _values = {
      ...values,
      id: values?.id || generateRandomId()
    };
    attachInfo?.id ? handleEdit(_values) : handleAdd(_values);
    handleClose();
  };

  const validationSchema = Yup.object().shape({
    ten: Yup.string()
      .required(lang("VALIDATION.REQUIRE"))
      .matches(REGEX.CHARACTER255, lang("VALIDATION.MAX255"))
      .matches(REGEX.TEN, lang("VALIDATION.INVALID_NAME"))
      .nullable(),
    quanHe: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    ngaySinh: Yup.date().max(new Date(), lang("VALIDATION.INVALID_BIRTHDATE")).nullable()
  });

  const formik = useFormik({
    initialValues: INIT_ATTACHED_PEOPLE,
    onSubmit: handleSubmit,
    validationSchema
  });

  const handleChangeSelect = (name: string, value: any) => {
    switch (name) {
      case VARIABLE_STRING.QUAN_HE:
        formik.setValues({
          ...formik.values,
          [name]: value,
          quanHeId: value?.id || "",
          quanHeText: value?.value || ""
        });
        break;
      default:
        formik.setFieldValue(name, value);
        break;
    }
  };

  const handleChangeMoney = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, Number(convertTextPrice(value)));
  };

  return (
    <Modal
      show={open}
      size="lg"
      centered
      aria-labelledby="example-custom-modal-styling-title"
      onHide={handleClose}
      backdrop="static"
      className="custom-modal"
    >
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title
            id="example-custom-modal-styling-title"
            className="heading-5"
          >
            {lang("WELFARE.ATTACHED.PEOPLE")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="g-4">
            <Col xs={4}>
              <TextValidator
                name="ten"
                isRequired
                lable={lang("FULL.NAME")}
                type="text"
                value={formik.values?.ten || ""}
                onChange={formik.handleChange}
                readOnly={isView}
                touched={formik.touched?.ten}
                errors={formik.errors?.ten}
              />
            </Col>

            <Col xs={4}>
              <Autocomplete
                lable={lang("INPUT.FAMILY.RELATION")}
                isReadOnly={isView}
                isRequired
                options={[]}
                value={formik.values?.quanHe || null}
                name="quanHe"
                menuPlacement="bottom"
                onChange={(value) => handleChangeSelect(VARIABLE_STRING.QUAN_HE, value)}
                searchFunction={searchAllSimpleValue}
                searchObject={{ type: TYPE_CATEGORY.quanHeGiaDinh }}
                getOptionLabel={(options) => options?.value}
                errors={formik.errors?.quanHe}
                touched={formik.touched?.quanHe}
              />
            </Col>
            <Col xs={4}>
              <TextValidator
                name="ngaySinh"
                lable={lang("INPUT.BIRTHDAY")}
                type="date"
                value={formik.values?.ngaySinh || ""}
                onChange={formik.handleChange}
                readOnly={isView}
                touched={formik.touched?.ngaySinh}
                errors={formik.errors?.ngaySinh}
              />
            </Col>
            <Col xs={4}>
              <Autocomplete
                lable={lang("INPUT.GENDER")}
                isReadOnly={isView}
                options={listGender}
                value={formik.values?.gender || null}
                name="gender"
                menuPlacement="bottom"
                onChange={(value) => handleChangeSelect(VARIABLE_STRING.GENDER, value)}
                errors={formik.errors?.gender}
                touched={formik.touched?.gender}
              />
            </Col>
            <Col xs={4}>
              <TextValidator
                name="soTienDong"
                lable={lang("WELFARE.MONEY.MUST_TURN_IN")}
                type="text"
                onChange={handleChangeMoney}
                value={convertNumberPrice(formik.values?.soTienDong || null)}
                readOnly={isView}
                touched={formik.touched?.soTienDong}
                errors={formik.errors?.soTienDong}
              />
            </Col>
            <Col xs={4}>
              <TextValidator
                name="soTienDonViHoTro"
                lable={lang("WELFARE.MONEY.UNIT_SUPORT")}
                type="text"
                onChange={handleChangeMoney}
                value={convertNumberPrice(formik.values?.soTienDonViHoTro || null)}
                readOnly={isView}
                touched={formik.touched?.soTienDonViHoTro}
                errors={formik.errors?.soTienDonViHoTro}
              />
            </Col>
            <Col xs={12}>
              <TextValidator
                name="note"
                lable={lang("GENERAL.NOTE")}
                type="text"
                as="textarea"
                rows={3}
                value={formik.values?.note || ""}
                onChange={formik.handleChange}
                readOnly={isView}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="flex-center">
          <GroupButton type="btn-cancel" handleCloseUpdateDialog={handleClose} />
          {!isView && <GroupButton type="btn-save" />}
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default AttachDialog;

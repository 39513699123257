import { Col, Row } from "react-bootstrap";
import { KTSVG } from "../../../../_metronic/helpers";
import { IDataInfoTextItem, IWarning } from "../models/warningModels";
import { useEffect, useRef, useState } from "react";
import * as echarts from "echarts";
import { resizeObserver } from "../../utils/functionUtils";

type IProps = {
  data: IWarning;
}

export const WarningChart: React.FC<IProps> = ({ data }) => {

  const chartRef: any = useRef();
  const [chart, setChart] = useState<echarts.EChartsType>();

  useEffect(() => {
    const chart = echarts.init(chartRef.current);
    chart.setOption({ ...data.options, resizeObserver });
    setChart(chart);
    if (resizeObserver) resizeObserver.observe(chartRef.current);
  }, [data.options]);

  useEffect(() => {
    if (!chart) {
      return;
    }
    if (data?.loading) {
      chart.showLoading();
      return;
    }

    chart.hideLoading();
  }, [chart, data?.loading]);
  
  const checkColor = () => {
    if (Number(data?.dataInfoText?.[0]?.value) > 0) {
      return "item-dark-red";
    }
    if (Number(data?.dataInfoText?.[1]?.value) > 0) {
      return "item-dark-orange"
    }
    if (Number(data?.dataInfoText?.[2]?.value) > 0) {
      return "item-green"
    }

    return "item-green"
  }

  return (
    <>
      <div className={`item-warning ${checkColor()}`}>
      <div className='item-header svg-icon-green'>
        <KTSVG path={"/media/svg/icons/warning-icon.svg"} />
        <p>{data.title}</p>
      </div>
      <Row className='item-container'>
        <Col xs={4} className="flex align-items-start px-6">
          <ul className="pt-4">
            {data?.dataInfoText?.map((item: IDataInfoTextItem, index:  number) => (
              <li key={index} className={`${item?.value ? item?.colorLabel : ""} pb-4 warning-label`}>
                <span>{item?.name}</span>
                <span>{item?.value || 0}</span>
              </li>
            ))}
          </ul>
        </Col>
        <Col xs={8}>
          <div className="echarts-parent position-relative h-100">
            <div
              ref={chartRef}
              style={{
                height: data?.height
              }}
            />
          </div>
        </Col>
      </Row>
    </div>
    </>
  );
};


import { ILang } from "../../../hook/useMultiLanguage";
import { OptionReactSelect } from "../../models/models";
import { DisciplineInfo } from "../models/disciplineModels";
import { MISSION_STATUS_CODE, TYPE } from "../../constants/moduleConsts";
import { searchAllEmployee, searchListWorkUnit, searchNguoiDaiDienKy } from "../../services/services";

export const INIT_DISCIPLINE_INFO: DisciplineInfo = {
  id: "",
  loaiKyLuat: null,
  capQuyetDinh: null,
  hanhVi: "",
  hinhThucKyLuat: null,
  ahtgDieuChinhLuong: false,
  thoiGianKeoDaiDieuChinhLuong: null,
  kyLuatCaoNhat: false,
  tuNgay: "",
  denNgay: "",
  soQuyetDinh: "",
  ngayQuyetDinh: "",
  ghiChu: "",
  file: "",
  trangThai: null,
  employeeKyLuats: null,
  coQuanQuyetDinhId: "",
  coQuanQuyetDinhText: "",
  nguoiKyQuyetDinhId: "",
  nguoiKyQuyetDinhText: "",
  nguoiKyQuyetDinh: null,
  coQuanQuyetDinh: null,
  fileId: "",
  fileName: "",
  xacNhanKyLuat: false,
  thongTinNghiViec: null
};

export const decisionLevels: OptionReactSelect[] = [
  {
    code: 1,
    name: "Cấp nhà nước"
  },
  {
    code: 2,
    name: "Cấp Bộ"
  },
  {
    code: 3,
    name: "Cấp Tỉnh"
  },
  {
    code: 4,
    name: "Cấp Huyện"
  },
  {
    code: 5,
    name: "Cấp cơ sở"
  },
  {
    code: 6,
    name: "Khác"
  }
];

export const TYPE_FORM_DISCIPLINE = {
  KHIEN_TRACH: 1,
  CANH_CAO: 2,
  CACH_CHUC: 3,
  BAI_NHIEM: 4,
  HA_BAC_LUONG: 5,
  GIANG_CHUC: 6,
  BUOC_THOI_VIEC: 7,
  KHAC: 8,
  CHU_XAC_DINH: 9
}

export const disciplineFormOfs: OptionReactSelect[] = [
  {
    code: TYPE_FORM_DISCIPLINE.KHIEN_TRACH,
    name: "Khiển trách"
  },
  {
    code: TYPE_FORM_DISCIPLINE.CANH_CAO,
    name: "Cảnh cáo"
  },
  {
    code: TYPE_FORM_DISCIPLINE.CACH_CHUC,
    name: "Cách chức"
  },
  {
    code: TYPE_FORM_DISCIPLINE.BAI_NHIEM,
    name: "Bãi nhiệm"
  },
  {
    code: TYPE_FORM_DISCIPLINE.HA_BAC_LUONG,
    name: "Hạ bậc lương"
  },
  {
    code: TYPE_FORM_DISCIPLINE.GIANG_CHUC,
    name: "Giáng chức"
  },
  {
    code: TYPE_FORM_DISCIPLINE.BUOC_THOI_VIEC,
    name: "Buộc thôi việc"
  },
  {
    code: TYPE_FORM_DISCIPLINE.KHAC,
    name: "Khác"
  },
  {
    code: TYPE_FORM_DISCIPLINE.CHU_XAC_DINH,
    name: "Chưa xác định"
  }
];

export const disciplineStatus = [
  {
    name: "Chưa xử lý",
    code: MISSION_STATUS_CODE.UNFULFILLED,
    styleClass: "bg-gray-700"
  },
  {
    name: "Đang xử lý",
    code: MISSION_STATUS_CODE.PROCESSING,
    styleClass: "bg-orange-dark"
  },
  {
    name: "Đã xử lý",
    code: MISSION_STATUS_CODE.COMPLETED,
    styleClass: "bg-green"
  }
];

export const disciplineType: OptionReactSelect[] = [
  {
    code: 1,
    name: "Đảng"
  },
  {
    code: 2,
    name: "Chính quyền"
  },
  {
    code: 3,
    name: "Đoàn thể"
  },
  {
    code: 4,
    name: "Khác"
  }
];

export const DISCIPLINE_AFFECT_SALARY_STATUS_CODE = [
  {
    code: 1,
    value: 3
  },
  { 
    code: 2,
    value: 6
  },
  { 
    code: 3,
    value: 12
  }
];

export enum AFFECT_SALARY {
  KHAC = 8
}

export const ListSearch = (lang: ILang) => {
  return [
    {
      name: lang("DISCIPLINE.TYPE"),
      field: "loaiKyLuat",
      type: TYPE.SELECT,
      listOption: disciplineType
    },
    {
      name: lang("DISCIPLINE.DECISION_LEVEL"),
      field: "capQuyetDinh",
      type: TYPE.SELECT,
      listOption: decisionLevels
    },
    {
      name: lang("DISCIPLINE.VIOLATION_BEHAVIOR"),
      field: "hanhVi",
      type: TYPE.TEXT
    },
    {
      name: lang("DISCIPLINE.FORM_OF"),
      field: "hinhThucKyLuat",
      type: TYPE.SELECT,
      listOption: disciplineFormOfs
    },
    {
      name: lang("APPRECIATIVE.PERSON_SINGING"),
      field: "nguoiKyQuyetDinhId",
      type: TYPE.SELECT,
      searchFunction: searchNguoiDaiDienKy
    },
    {
      name: lang("APPRECIATIVE.AGENCIES_UNIT_DECITION"),
      field: "coQuanQuyetDinhId",
      type: TYPE.SELECT,
      searchFunction: searchListWorkUnit,
    },
    {
      name: lang("INPUT.EMPLOYEE"),
      field: "employeeId",
      type: TYPE.SELECT,
      searchFunction: searchAllEmployee
    },
    {
      name: lang("APPRECIATIVE.TRANGTHAITEXT"),
      field: "trangThai",
      type: TYPE.SELECT,
      listOption: disciplineStatus
    },
  ];
}

export const LIST_CHANGE_POSITION = [
  TYPE_FORM_DISCIPLINE.GIANG_CHUC,
  TYPE_FORM_DISCIPLINE.BAI_NHIEM,
  TYPE_FORM_DISCIPLINE.CACH_CHUC
];
import { Col, Row } from "react-bootstrap";
import useMultiLanguage from "../../hook/useMultiLanguage";
import { useContext, useEffect, useState } from "react";
import ReportDetail from "./ReportDetail";
import { RESPONSE_STATUS_CODE, INIT_SEARCH_OBJECT, TYPE_CATEGORY } from "../constants/moduleConsts";
import AppContext from "../../AppContext";
import { toast } from "react-toastify";
import InputSearch from "../components/input/InputSearch";
import TableCustom, { columnNamesType } from "../components/table-custom/TableCustom";
import ListMenuVertical from "../components/list-menu-vertical/ListMenuVertical";
import { itemListAdditional } from "./constants/reportConsts";
import { IReportCategory, IReportInfo } from "./models/reportModels";
import "./report.scss";
import { GroupButton } from "../components/GroupButton";
import { searchAllSimpleValue, searchAllSimpleCategory } from "../services/services";

function Report() {
  const { lang } = useMultiLanguage();

  const [keyword, setKeyWord] = useState<string>("");
  const [listData, setListData] = useState<IReportInfo[]>([]);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [shouldOpenDetail, setShoulOpenDetail] = useState<boolean>(false);
  const [reportInfo, setReportInfo] = useState<IReportInfo>({} as IReportInfo);
  const [reportCategory, setReportCategory] = useState<IReportCategory[]>([]);
  const [listItemIndex, setListItemIndex] = useState<number>(0);
  const [searchData, setSearchData] = useState(INIT_SEARCH_OBJECT);
  const { setPageLoading } = useContext(AppContext);

  useEffect(() => {
    getAllReportCategory();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllReportCategory = async () => {
    try {
      setPageLoading(true);
      let searchData = {
        type: TYPE_CATEGORY.phanLoaiBaoCao,
      };
      const { data } = await searchAllSimpleCategory(searchData);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        let dataSorted = data?.data?.content
          ? [itemListAdditional, ...data?.data?.content.sort((a: any, b: any) => a.code - b.code)]
          : [];
        setReportCategory(dataSorted);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    handleSearch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchData?.pageIndex, searchData?.pageSize])

  const updatePageData = async (search?: any) => {
    try {
      setPageLoading(true);
      const searchObj = {
        ...search,
        type: TYPE_CATEGORY.phanLoaiBaoCao,
        keyword: keyword.trim()
      };
      setSearchData(searchObj);
      const { data } = await searchAllSimpleValue(searchObj);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListData(data?.data?.content || []);
        setTotalPages(data?.data?.pageable?.totalPages || 0);
        setTotalElements(data?.data?.pageable?.totalElements || 0);
        setNumberOfElements(data?.data?.pageable?.numberOfElements || 0);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleSearch = () => {
    updatePageData(searchData);
  };

  const handleChangeList = (index: number, listItemInfo: any) => {
    setListItemIndex(index);
    updatePageData({ ...searchData, attributeId: listItemInfo.id });
  };

  const handleOpenViewDialog = (row: any) => {
    setShoulOpenDetail(true);
    setReportInfo(row);
  };

  const columns: columnNamesType[] = [
    {
      name: lang("REPORT.NAME"),
      field: "value",
      cellStyle: {
        textAlign: "left"
      },
      render: (row) => (
        <p className={`spaces p-0 m-0 flex flex-middle ${!row?.mappingLevel ? "disabled" : "fw-700"}`}>
          <span>
            <i className="bi bi-heart spaces fs-20 flex px-10"></i>
          </span>
          <span>{row.value}</span>
        </p>
      )
    },
    {
      name: lang("REPORT.TYPE"),
      field: "attributeName",
      render: (row) => (
        <span className={`${!row?.mappingLevel ? "disabled" : "spaces fw-700"}`}>{row?.attributeName}</span>
      )
    }
  ];

  return (
    <div className="report spaces px-8 py-12">
      {!shouldOpenDetail ? (
        <>
          <Row className="spaces mb-12 flex-middle h-35">
            <Col xs={5} xl={6} xxl={7} className="spaces p-0">
              <h2 className="breadcrumb-title">{lang("REPORT.TITLE")}</h2>
            </Col>
          </Row>
          <Row className="bg-white">
            <Col xs={2}>
              <ListMenuVertical
                activeIndex={listItemIndex}
                handleChangeActiveItem={handleChangeList}
                listData={reportCategory}
                fieldTitle="name"
              />
            </Col>
            <Col xs={10}>
              <div className="flex flex-end flex-middle spaces pt-12">
                <div className="w-1/2">
                  <span className="text-header">{reportCategory[listItemIndex]?.name}</span>
                </div>
                <div className="flex w-1/2">
                  <div className="spaces mr-10 flex-1">
                    <InputSearch
                      className="border-3px"
                      value={keyword || ""}
                      placeholder={lang("GENERAL.ENTER_HERE")}
                      handleSearch={handleSearch}
                      handleChange={(event) => setKeyWord(event.target.value)}
                      isEnter
                    />
                  </div>
                  <GroupButton className="spaces mr-10" handleEvent={handleSearch}>
                    {lang("BTN.SEARCH")}
                  </GroupButton>
                </div>
              </div>
              <div className="spaces flex-1 mb-6 table-custom">
                <TableCustom
                  data={listData}
                  columns={columns}
                  searchObject={searchData}
                  setSearchObject={setSearchData}
                  noToolbar
                  handleDoubleClick={handleOpenViewDialog}
                  totalPages={totalPages}
                  totalElements={totalElements}
                  numberOfElements={numberOfElements}
                />
              </div>
            </Col>
          </Row>
        </>
      ) : (
        <ReportDetail
          reportInfo={reportInfo}
          handleBack={() => setShoulOpenDetail(false)}
        />
      )}
    </div>
  );
}

export default Report;

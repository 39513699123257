import { filterObject } from "./profileUtils";

export const convertDataSubmit = (data: any, employeeId: any) => {
  const dataConvert = {
    ...data,
    donViCongTacId: data?.donViCongTac?.id || "",
    donViCongTacText: data?.donViCongTac?.name || data?.donViCongTacText || "",
    chucVuId: data?.chucVu?.id || "",
    chucVuText: data?.chucVu?.value || "",
    chucDanhId: data?.chucDanh?.id || "",
    chucDanhText: data?.chucDanh?.value || "",
    phongBanId: data?.phongBan?.id || "",
    phongBanText: data?.phongBan?.name || data?.phongBanText || "",
    bacId: data?.bac?.id || "",
    bacLuong: data?.bac?.bacLuong || "",
    nguoiQuyetDinhId: data?.nguoiQuyetDinh?.id || "",
    nguoiQuyetDinhText: data?.nguoiQuyetDinh?.name || "",
    employeeId: employeeId || ""
  };
  return filterObject(dataConvert);
};

export const convertDataUI = (data: any) => {
  const dataConvert = {
    ...data,
    donViCongTac: data?.donViCongTacId ? {
      id: data?.donViCongTacId || "",
      name: data?.donViCongTacText || ""
    } : null,
    chucVu: data?.chucVuId
      ? {
          id: data?.chucVuId || "",
          value: data?.chucVuText || ""
        }
      : null,
    chucDanh: data?.chucDanhId
      ? {
          id: data?.chucDanhId || "",
          value: data?.chucDanhText || ""
        }
      : null,
    phongBan: data?.phongBanId
      ? {
          id: data?.phongBanId || "",
          name: data?.phongBanText || ""
        }
      : null,
    bac: data?.bacLuong
      ? {
          id: data?.bacId,
          bacLuong: data?.bacLuong
        }
      : null,
    nguoiQuyetDinh: data?.nguoiQuyetDinhId 
      ? {
        id: data?.nguoiQuyetDinhId,
        name: data?.nguoiQuyetDinhText
      } : null
  };
  return dataConvert;
};
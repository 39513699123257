import * as echarts from "echarts";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { KTSVG } from "../../../../../_metronic/helpers";
import Autocomplete from "../../../components/input/autocomplete";
import { IStatisticalChart } from "../../models/statisticalChartModels";
import { useStatisticalContext } from "../../Statistical";
import { OptionReactSelect } from "../../../models/models";
import { getFullYear, resizeObserver } from "../../../utils/functionUtils";
import { LAYOUT_CHART } from "../../constants/statisticalConsts";

const DEFAULT_CHART_HEIGHT = "100%";

export const StatisticalChart: React.FC<IStatisticalChart> = ({
  options,
  title,
  height = DEFAULT_CHART_HEIGHT,
  typeId,
  year,
  typeLayout
}) => {
  const { setType, setYear, loading } = useStatisticalContext()
  const [chart, setChart] = useState<echarts.ECharts>();

  const chartRef: any = useRef();
  const item: any = useRef();
  const { isFullscreen, setIsFullscreen, listChart, setListChart } = useStatisticalContext();

  const handleExport = async (format: 'svg' | 'png' | 'jpg' | 'jpeg') => {
    if (chart) {
      try {
        let dataURL;
        let fileType;
        chartRef.current.style.backgroundColor = '#fff'

        switch (format) {
          case 'svg':
            dataURL = chart.getDataURL({
              pixelRatio: 2,
              type: 'svg'
            });
            fileType = 'svg';
            break;
          case 'jpg':
            dataURL = chart.getDataURL({
              pixelRatio: 2,
              type: 'jpeg'
            });
            fileType = 'jpg';
            break;
          case 'jpeg':
            dataURL = chart.getDataURL({
              pixelRatio: 2,
              type: 'jpeg'
            });
            fileType = 'jpeg';
            break;
          case 'png':
            dataURL = chart.getDataURL({
              pixelRatio: 2,
              type: 'png'
            });
            fileType = 'png';
            break;
          default:
            return;
        }

        const blob = dataURLtoBlob(dataURL);
        const url = URL.createObjectURL(blob);
        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.download = `chart.${fileType}`;

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } catch (error) {
        console.error(`Error exporting ${format.toUpperCase()}:`, error);
      }
    }
  };

  const dataURLtoBlob = (dataURL: string): Blob => {
    const parts = dataURL.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const byteString = atob(parts[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    return new Blob([arrayBuffer], { type: contentType });
  };

  useEffect(() => {
    if(typeLayout === LAYOUT_CHART.SPLIT) {
      options.legend.textStyle.fontSize = 18;
    } else {
      options.legend.textStyle?.fontSize && delete options.legend.textStyle.fontSize;
    }
  }, [options, typeLayout]);

  useEffect(() => {
    updateDataCharts(options)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const updateDataCharts = (data: any = {}) => {
    if (chart) {
      chart.dispose();
    }

    const newChart = echarts.init(chartRef.current);
    newChart.setOption({ ...data, resizeObserver });
    setChart(newChart);
    if (resizeObserver) resizeObserver.observe(chartRef.current);
  }

  useEffect(() => {
    if (!chart) {
      return;
    }
    if (loading) {
      chart.showLoading();
      return;
    }

    chart.hideLoading();
  }, [chart, loading]);

  const handleChangYear = (yearOption: OptionReactSelect) => {
    let index: number = listChart.findIndex((item: IStatisticalChart) => item?.id === typeId)
    listChart[index].year = yearOption.name;
    setListChart([...listChart])
    setYear(yearOption?.name);
    setType(typeId);
  }

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      item.current.requestFullscreen();
      item.current.style.backgroundColor = '#fff';
      let data = {
        ...options,
        legend: {
          ...options.legend,
          right: 120,
          textStyle: {
            ...options.legend.textStyle,
            fontSize: "25px",
          }
        },

        ...(options.xAxis?.data?.length > 0 ?
          {
            legend: {
              ...options.legend,
              right: "auto",
              textStyle: {
                ...options.legend.textStyle,
                fontSize: "25px",
              }
            },
            xAxis: {
              ...options.xAxis,
              axisLabel: {
                ...options.xAxis.axisLabel,
                fontSize: "25px",
              }
            }
          } : {})
      }
      updateDataCharts(data)
    } else {
      document.exitFullscreen();
      updateDataCharts(options)
    }
    setIsFullscreen?.(!isFullscreen);
  };

  const handleFullscreen = useCallback(() => {
    if(!document.fullscreenElement && isFullscreen) {
      updateDataCharts(options)
      setIsFullscreen?.(!isFullscreen);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFullscreen]);

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullscreen);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreen);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFullscreen]);

  return (
    <div ref={item} className="statistical-chart flex-column justify-content-between h-100 chart-item">
      <div className="chart-header flex justify-content-between align-items-center">
        <p className="chart-title m-0">{title}</p>
        <div className="actions flex align-items-center gap-2">
          <Autocomplete
            options={getFullYear(0, 5)}
            value={String(year || (new Date().getFullYear()))}
            name="year"
            onChange={(yearOption) => handleChangYear(yearOption)}
          />
          <div className="dropdown">
            <button
              className="btn btn-secondary dropdown-toggle action-btn"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <KTSVG
                path={"/media/icons/collapse-menu.svg"}
                className="text-primary m-0"
              />
            </button>
            <ul className="dropdown-menu">
              <li>
                <div className="dropdown-item" onClick={toggleFullscreen}> {isFullscreen ? 'Thu nhỏ' : 'Phóng to'}</div>
              </li>
              <li>
                <div className="dropdown-item" onClick={() => handleExport('jpg')}>Xuất file ảnh JPG</div>
              </li>
              <li>
                <div className="dropdown-item" onClick={() => handleExport('jpeg')}>Xuất file ảnh JPEG</div>
              </li>
              <li>
                <div className="dropdown-item" onClick={() => handleExport('png')}>Xuất file PNG</div>
              </li>
              {/* <li>
                <div className="dropdown-item" onClick={() => handleExport('svg')}>Xuất file SVG</div>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
      <div className="echarts-parent position-relative h-100">
        <div
          ref={chartRef}
          style={{
            height: height
          }}
        />
      </div>
    </div>
  );
};


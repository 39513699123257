import axios from "axios";
import { paramsConfig, paramsMaxSizeSearch } from "../utils/paramsUtils";
import { hasAuthority } from "../utils/functionUtils";
import { localStorageItem } from "../utils/localstorage";
import { KEY_LOCALSTORAGE } from "../auth/core/_consts";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../constants";
import { IPeopleDecision } from "../models/models";

const API_URL = process.env.REACT_APP_API_URL;
const API_URL_CHAM_CONG = process.env.REACT_APP_API_URL_CHAM_CONG;

export const searchAllSimpleValue = (searchObject: any) => {
  let url = API_URL + `/c-simple-category-attribute-value/page`;
  return axios.get(url, paramsMaxSizeSearch(searchObject));
};

export const addSimpleAttributeValue = (data: any) => {
  return axios.post(`${API_URL}/c-simple-category-attribute-value`, data);
};

export const searchAllSimpleCategory = (searchObject: any) => {
  const url = API_URL + `/c-simple-category-attribute/page`;
  return axios.get(url, paramsMaxSizeSearch(searchObject));
};

export const searchNationality = (searchObject?: any) => {
  let url = API_URL + `/c-nationality/page`;
  return axios.get(url, paramsMaxSizeSearch(searchObject));
};

export const searchAllCountry = () => {
  let url = API_URL + `/c-country`;
  return axios.get(url);
};

export const searchAllProvince = (searchObject: any) => {
  let url = API_URL + "/province/page";
  return axios.get(url, paramsMaxSizeSearch(searchObject));
};

export const searchDistrictByProvince = (searchObject: any) => {
  let url = API_URL + "/district/get-by-province/page";
  return axios.get(url, paramsMaxSizeSearch(searchObject));
};
export const searchCommuneByDistrict = (searchObject: any) => {
  let url = API_URL + "/subdistrict/get-by-district/page";
  return axios.get(url, paramsMaxSizeSearch(searchObject));
};

//word
export const getDataPreviewTimekeepingWord = (getObj: any) => {
  const url = API_URL_CHAM_CONG + "/word";
  return axios.get(url, paramsConfig(getObj));
};

export const exportTimekeepingWord = (exportObj: any) => {
  let url = `${API_URL_CHAM_CONG}/word`;
  return axios({
    url: url,
    method: "POST",
    responseType: "blob",
    data: exportObj || {},
  });
};

//word chấm công
export const getDataPreviewWord = (getObj: any) => {
  const url = API_URL + "/download-word";
  return axios.get(url, paramsConfig(getObj));
};

export const exportWord = (exportObj: any) => {
  let url = `${API_URL}/download-word`;
  return axios({
    url: url,
    method: "POST",
    responseType: "blob",
    data: exportObj || {},
  });
};

export const searchAllEmployee = (searchObject: any = {}) => {
  if(!searchObject?.phongBanIds && hasAuthority(PERMISSIONS.DEPARTMENT, PERMISSION_ABILITY.VIEW)) {
    searchObject.phongBanIds = localStorageItem.get(KEY_LOCALSTORAGE.EMPLOYEE)?.phongBanId;
  }
  let url = API_URL + "/employee/page";
  return axios.get(url, paramsMaxSizeSearch(searchObject));
};

export const searchNguoiDaiDienKy = (searchObject?: any) => {
  const url = API_URL + "/nguoi-dai-dien-ky/page";
  return axios.get(url, paramsMaxSizeSearch(searchObject));
};

export const addNguoiDaiDienKy = (representativeInfo?: IPeopleDecision) => {
  const url = API_URL + "/nguoi-dai-dien-ky";
  return axios.post(url, representativeInfo);
};

export const searchListWorkUnit = (searchObject?: any) => {
  let url = `${API_URL}/organizations/page`;
  return axios.get(url, paramsMaxSizeSearch(searchObject));
};

export const searchListWorkUnitTree = (searchObject?: any) => {
  let url = `${API_URL}/organizations/page/tree`;
  return axios.get(url, paramsMaxSizeSearch(searchObject));
};

export const searchPhongBanByDepartment = ({ departmentId, ...searchObject }: any) => {
  return axios.get(`${API_URL}/c-department/get-peers/${departmentId}`, paramsMaxSizeSearch(searchObject));
};

export const searchPhongBan = (searchObj?: any) => {
  return axios.get(`${API_URL}/c-department/page`, paramsMaxSizeSearch(searchObj));
};

export const searchPhongBanByDonVi = (searchObj: any) => {
  return axios.get(`${API_URL}/c-department/get-by-organization`, paramsMaxSizeSearch(searchObj));
};
import { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import useMultiLanguage from "../../hook/useMultiLanguage";
import AppContext from "../../AppContext";
import { ISymbolTimekeeping } from "./models/categoryModels";
import { INIT_SEARCH_OBJECT, RESPONSE_STATUS_CODE, TYPE } from "../constants/moduleConsts";
import InputSearch from "../components/input/InputSearch";
import { GroupButton } from "../components/GroupButton";
import TableCustom, { columnNamesType } from "../components/table-custom/TableCustom";
import { hasAuthority } from "../utils/functionUtils";
import { PERMISSION_ABILITY, PERMISSIONS } from "../../constants";
import { deleteSymbol, searchSymbol } from "./services/symbolServices";
import SymbolTimekeepingForm from "./components/SymbolTimekeepingForm";

const SymbolTimekeeping = () => {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [listSymbol, setListSymbol] = useState<ISymbolTimekeeping[]>([]);
  const [symbolInfo, setSymbolInfo] = useState<ISymbolTimekeeping>({} as ISymbolTimekeeping);
  const [openSymbolForm, setOpenSymbolForm] = useState<boolean>(false);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [searchObject, setSearchObject] = useState<any>(INIT_SEARCH_OBJECT);
  const [isView, setIsView] = useState(false);

  const getListSymbol = async (searchData?: any) => {
    try {
      setPageLoading(true);
      setSearchObject({ ...searchData })
      const { data } = await searchSymbol(searchData);
      if(data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListSymbol(data?.data?.content || []);
        setTotalPage(data?.data?.pageable?.totalPages);
        setTotalElements(data?.data?.pageable?.totalElements);
        setNumberOfElements(data?.data?.pageable?.numberOfElements);
      } else {
        toast.warning(data?.message);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleOpenCreateDialog = () => {
    setIsView(false);
    setOpenSymbolForm(true);
  }

  const handleSearch = (data: any = {}) => {
    const dataSearch: any = {
      ...searchObject,
      ...data
    };
    dataSearch.keyword = dataSearch?.keyword?.trim() || "";
    getListSymbol(dataSearch);
  };

  useEffect(() => {
    if(searchObject?.pageIndex && searchObject?.pageSize) {
      handleSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchObject?.pageIndex, searchObject?.pageSize])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchObject({
      ...searchObject,
      keyword: e.target?.value
    });
  };

  const handleDeleteSymbol = async (ids: string[]) => {
    try {
      const { data } = await deleteSymbol(ids)
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("SYMBOL.DELETE"))
        await getListSymbol(searchObject);
        return true;
      } else {
        toast.warning(data?.message)
      }
    }
    catch (err: any) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }

  const handleOpenSymbolForm = (info: any) => {
    setIsView(true);
    setOpenSymbolForm(true);
    setSymbolInfo(info);
  };

  const handleClose = () => {
    setOpenSymbolForm(false);
    setSymbolInfo({} as ISymbolTimekeeping);
  };

  const handleCloseAndSearch = () => {
    handleSearch();
    handleClose();
  };
  
  const columns: columnNamesType[] = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("SYMBOL"),
      field: "code",
      headerStyle: {
        minWidth: "80px"
      },
      render: (row: any) => <span className="color-steel-blue fw-600">{row?.code}</span>
    },
    {
      name: lang("CATEGORY.NAME"),
      field: "name",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("ATTRIBUTION"),
      field: "loaiTinhCongs",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
      render: row => <span>{row?.loaiTinhCongs?.map((item: any) => item?.name).join(", ")}</span>
    }
  ];
  
  return (
    <div className="spaces px-8 py-12 flex-1">
        <Row className="spaces mb-12 flex-middle">
          <Col xs={5} xl={6} xxl={7} className="p-0">
            <h2 className="breadcrumb-title">{lang("SYMBOL.LIST")}</h2>
          </Col>
          <Col xs={7} xl={6} xxl={5} className="flex">
            <div className="spaces mr-10 flex-1">
              <InputSearch
                className="border-3px"
                value={searchObject?.keyword}
                placeholder="Tìm kiếm theo mã ký hiệu, tên ký hiệu"
                handleSearch={() => handleSearch({ pageIndex: 1 })}
                handleChange={handleChange}
                isEnter
              />
            </div>
            <GroupButton handleEvent={() => handleSearch({ pageIndex: 1 })}>
              {lang("BTN.SEARCH")}
            </GroupButton>
          </Col>
        </Row>
        <Row className="bg-white">
          <Col className="table-scroll">
            <TableCustom
              id="symbol-list"
              data={listSymbol}
              columns={columns}
              searchObject={searchObject}
              setSearchObject={setSearchObject}
              buttonAdd={hasAuthority(PERMISSIONS["DANH_MUC.CHAM_CONG.KY_HIEU"], PERMISSION_ABILITY.CREATE)}
              notDelete={!hasAuthority(PERMISSIONS["DANH_MUC.CHAM_CONG.KY_HIEU"], PERMISSION_ABILITY.DELETE)}
              type={TYPE.MULTILINE}
              totalPages={totalPage}
              totalElements={totalElements}
              numberOfElements={numberOfElements}
              handleOpenDialog={handleOpenCreateDialog}
              handleDoubleClick={(row: any) => handleOpenSymbolForm(row)}
              handleDelete={(ids: string[]) => handleDeleteSymbol(ids)}
            />
          </Col>
        </Row>
        {openSymbolForm && (
          <SymbolTimekeepingForm
            open={openSymbolForm}
            handleClose={handleClose}
            handleCloseAndSearch={handleCloseAndSearch}
            symbolInfo={symbolInfo}
            isView={isView}
          />
        )}
    </div>
  );
}

export { SymbolTimekeeping };
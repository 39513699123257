import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../../_metronic/helpers";
import TextValue from "../../../components/TextValue";
import { checkObject, formatDateTable } from "../../../utils/functionUtils";
import TableCustom from "../../../components/table-custom/TableCustom";
import TableGrouping, { IColumnsGrouping } from "../../../components/table-grouping/TableGrouping";
import TextUnderline from "../../../components/TextUnderline";

type Props = {
  dataEmployee?: any
}

export const CircularsTT062023TTBNV: React.FC<Props> = ({ dataEmployee: data }) => {
  const [stateManagement, setStateManagement] = useState<any>([]);
  const [foreignLanguageLevel, setForeignLanguageLevel] = useState<any>([]);

  useEffect(() => {
    if (!checkObject(data)) {
      let cc = (data?.quanLyNhaNuoc || []).map((item: any) => {
        return {
          tuNgay: item?.ccDaoTaoTuNgay,
          denNgay: item?.ccDaoTaoDenNgay,
          coSoDaoTao: item?.ccCoSoDaoTao,
          vanBang: item?.ccVanBang,
        }
      });

      let qtbd = (data?.chucDanhNgheNghiep || []).map((item: any) => {
        return {
          tuNgay: item?.qtbdDaoTaoTuNgay,
          denNgay: item?.qtbdDaoTaoDenNgay,
          coSoDaoTao: item?.qtbdCoSoDaoTao,
          vanBang: item?.qtbdVanBang,
        }
      });
      setStateManagement([...cc, ...qtbd]);

      cc = ([...data?.ngoaiNgu]).map((item: any) => {
        return {
          tuNgay: item?.ccTuNgay,
          denNgay: item?.ccDenNgay,
          coSoDaoTao: item?.ccCoSoDaoTao,
          name: item?.ccNgoaiNgu?.name,
          vanBang: item?.ccVanBang,
          diem: item?.ccDiem
        }
      });

      let tdt = (data?.tiengDanToc || []).map((item: any) => {
        return {
          tuNgay: item?.tdtTuNgay,
          denNgay: item?.tdtDenNgay,
          coSoDaoTao: item?.tdtCoSoDaoTao,
          name: item?.tdtTiengDanToc,
          vanBang: item?.tdtVanBang,
          diem: null
        }
      });
      setForeignLanguageLevel([...cc, ...tdt]);
    }
  }, [data]);

  const columnsTrainingProcess = [
    {
      name: "STT",
      field: "stt",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: "Tháng/năm",
      field: "thangNam",
      child: [
        {
          name: "Từ",
          field: "bcTuNgay",
          render: (row: any) => row?.bcTuNgay ? <span>{formatDateTable(row?.bcTuNgay)}</span> : <TextValue className="spaces h-25" />
        },
        {
          name: "Đến",
          field: "bcDenNgay",
          render: (row: any) => row?.bcDenNgay ? <span>{formatDateTable(row?.bcDenNgay)}</span> : <TextValue className="spaces h-25" />
        },
      ]
    },
    {
      name: "Tên cơ sở đào tạo",
      field: "bcCoSoDaoTao",
      render: (row: any) => row?.bcCoSoDaoTao ? <span>{row?.bcCoSoDaoTao}</span> : <TextValue className="spaces h-25" />
    },
    {
      name: "Chuyên ngành đào tạo",
      field: "bcChuyenNganh",
      render: (row: any) => row?.bcChuyenNganh ? <span>{row?.bcChuyenNganh}</span> : <TextValue className="spaces h-25" />
    },
    {
      name: "Hình thức đào tạo",
      field: "bcHinhThucDaoTao",
      render: (row: any) => row?.bcHinhThucDaoTao?.name ? <span>{row?.bcHinhThucDaoTao?.name}</span> : <TextValue className="spaces h-25" />
    },
    {
      name: "Văn bằng trình độ",
      field: "bcVanBang",
      render: (row: any) => row?.bcVanBang ? <span>{row?.bcVanBang}</span> : <TextValue className="spaces h-25" />
    },
  ];

  const columnsPoliticalTheory = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: "Tháng/năm",
      field: "thangNam",
      child: [
        {
          name: "Từ",
          field: "llctTuNgay",
          render: (row: any) => row?.llctTuNgay ? <span>{formatDateTable(row?.llctTuNgay)}</span> : <TextValue className="spaces h-25" />
        },
        {
          name: "Đến",
          field: "llctDenNgay",
          render: (row: any) => row?.llctDenNgay ? <span>{formatDateTable(row?.llctDenNgay)}</span> : <TextValue className="spaces h-25" />
        },
      ]
    },
    {
      name: "Tên cơ sở đào tạo",
      field: "llctCoSoDaoTao",
      render: (row: any) => row?.llctCoSoDaoTao ? <span>{row?.llctCoSoDaoTao}</span> : <TextValue className="spaces h-25" />
    },
    {
      name: "Hình thức đào tạo",
      field: "llctHinhThucDaoTao",
      render: (row: any) => row?.llctHinhThucDaoTao?.name ? <span>{row?.llctHinhThucDaoTao?.name}</span> : <TextValue className="spaces h-25" />
    },
    {
      name: "Văn bằng được cấp",
      field: "llctVanBang",
      render: (row: any) => row?.llctVanBang ? <span>{row?.llctVanBang}</span> : <TextValue className="spaces h-25" />
    },
  ];

  const columnsStateManagement = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: "Tháng/năm",
      field: "thangNam",
      child: [
        {
          name: "Từ",
          field: "tuNgay",
          render: (row: any) => row?.tuNgay ? <span>{formatDateTable(row?.tuNgay)}</span> : <TextValue className="spaces h-25" />
        },
        {
          name: "Đến",
          field: "denNgay",
          render: (row: any) => row?.denNgay ? <span>{formatDateTable(row?.denNgay)}</span> : <TextValue className="spaces h-25" />
        },
      ]
    },
    {
      name: "Tên cơ sở đào tạo",
      field: "coSoDaoTao",
      render: (row: any) => row?.coSoDaoTao ? <span>{row?.coSoDaoTao}</span> : <TextValue className="spaces h-25" />
    },
    {
      name: "Chứng chỉ được cấp",
      field: "vanBang",
      render: (row: any) => row?.vanBang ? <span>{row?.vanBang}</span> : <TextValue className="spaces h-25" />
    },
  ];

  const columnsNationalDefense = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: "Tháng/năm",
      field: "thangNam",
      child: [
        {
          name: "Từ",
          field: "qtbdDaoTaoTuNgay",
          render: (row: any) => row?.qtbdDaoTaoTuNgay ? <span>{formatDateTable(row?.qtbdDaoTaoTuNgay)}</span> : <TextValue className="spaces h-25" />
        },
        {
          name: "Đến",
          field: "qtbdDaoTaoDenNgay",
          render: (row: any) => row?.qtbdDaoTaoDenNgay ? <span>{formatDateTable(row?.qtbdDaoTaoDenNgay)}</span> : <TextValue className="spaces h-25" />
        },
      ]
    },
    {
      name: "Tên cơ sở đào tạo",
      field: "qtbdCoSoDaoTao",
      render: (row: any) => row?.qtbdCoSoDaoTao ? <span>{row?.qtbdCoSoDaoTao}</span> : <TextValue className="spaces h-25" />
    },
    {
      name: "Chứng chỉ được cấp",
      field: "qtbdVanBang",
      render: (row: any) => row?.qtbdVanBang ? <span>{row?.qtbdVanBang}</span> : <TextValue className="spaces h-25" />
    },
  ];

  const columnsITQualification = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: "Tháng/năm",
      field: "thangNam",
      child: [
        {
          name: "Từ",
          field: "ccTuNgay",
          render: (row: any) => row?.ccTuNgay ? <span>{formatDateTable(row?.ccTuNgay)}</span> : <TextValue className="spaces h-25" />
        },
        {
          name: "Đến",
          field: "ccDenNgay",
          render: (row: any) => row?.ccDenNgay ? <span>{formatDateTable(row?.ccDenNgay)}</span> : <TextValue className="spaces h-25" />
        },
      ]
    },
    {
      name: "Tên cơ sở đào tạo",
      field: "ccCoSoDaoTao",
      render: (row: any) => row?.ccCoSoDaoTao ? <span>{row?.ccCoSoDaoTao}</span> : <TextValue className="spaces h-25" />
    },
    {
      name: "Chứng chỉ được cấp",
      field: "ccVanBang",
      render: (row: any) => row?.ccVanBang ? <span>{row?.ccVanBang}</span> : <TextValue className="spaces h-25" />
    },
  ];

  const columnsForeignLanguageLevel = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: "Tháng/năm",
      field: "thangNam",
      child: [
        {
          name: "Từ",
          field: "tuNgay",
          render: (row: any) => row?.tuNgay ? <span>{formatDateTable(row?.tuNgay)}</span> : <TextValue className="spaces h-25" />
        },
        {
          name: "Đến",
          field: "denNgay",
          render: (row: any) => row?.denNgay ? <span>{formatDateTable(row?.denNgay)}</span> : <TextValue className="spaces h-25" />
        },
      ]
    },
    {
      name: "Tên cơ sở đào tạo",
      field: "coSoDaoTao",
      render: (row: any) => row?.coSoDaoTao ? <span>{row?.coSoDaoTao}</span> : <TextValue className="spaces h-25" />
    },
    {
      name: "Tên ngoại ngữ/ tiếng dân tộc",
      field: "name",
      render: (row: any) => row?.name ? <span>{row?.name}</span> : <TextValue className="spaces h-25" />
    },
    {
      name: "Chứng chỉ được cấp",
      field: "vanBang",
      render: (row: any) => row?.vanBang ? <span>{row?.vanBang}</span> : <TextValue className="spaces h-25" />
    },
    {
      name: "Điểm số",
      field: "diem",
      render: (row: any) => row?.diem ? <span>{row?.diem}</span> : <TextValue className="spaces h-25" />
    },
  ];

  const columnsWorkingProcess = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: "Tháng/năm",
      field: "thangNam",
      child: [
        {
          name: "Từ",
          field: "qtctTuNgay",
          render: (row: any) => row?.qtctTuNgay ? <span>{formatDateTable(row?.qtctTuNgay)}</span> : <TextValue className="spaces h-25" />
        },
        {
          name: "Đến",
          field: "qtctDenNgay",
          render: (row: any) => row?.qtctDenNgay ? <span>{formatDateTable(row?.qtctDenNgay)}</span> : <TextValue className="spaces h-25" />
        },
      ]
    },
    {
      name: "Đơn vị công tác (đảng, chính quyền, đoàn thể, tổ chức xã hội)",
      field: "qtctDonViCongTac",
      render: (row: any) => row?.qtctDonViCongTac ? <span>{row?.qtctDonViCongTac}</span> : <TextValue className="spaces h-25" />
    },
    {
      name: "Chức danh/ chức vụ",
      field: "qtctChucDanh",
      render: (row: any) => row?.qtctChucDanh ? <span>{row?.qtctChucDanh}</span> : <TextValue className="spaces h-25" />
    },
  ];

  const columnsOldRegime: IColumnsGrouping[] = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: "Tháng/năm",
      field: "thangNam",
      child: [
        {
          name: "Từ",
          field: "lslvTuNgay",
          render: (row: any) => row?.lslvTuNgay ? <span>{formatDateTable(row?.lslvTuNgay)}</span> : <TextValue className="spaces h-25" />
        },
        {
          name: "Đến",
          field: "lslvDenNgay",
          render: (row: any) => row?.lslvDenNgay ? <span>{formatDateTable(row?.lslvDenNgay)}</span> : <TextValue className="spaces h-25" />
        },
      ]
    },
    {
      name: "Chức danh, chức vụ, đơn vị, địa điểm đã làm việc",
      field: "lslvThongTin",
      cellStyle: {
        textAlign: "left"
      },
      render: (row: any) => row?.lslvThongTin ? <span>{row?.lslvThongTin}</span> : <TextValue className="spaces h-25" />
    },
  ];

  const columnsRelationshipsForeignOrganizations: IColumnsGrouping[] = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: "Tháng/năm",
      field: "thangNam",
      child: [
        {
          name: "Từ",
          field: "lslvTuNgay",
          render: (row: any) => row?.lslvTuNgay ? <span>{formatDateTable(row?.lslvTuNgay)}</span> : <TextValue className="spaces h-25" />
        },
        {
          name: "Đến",
          field: "lslvDenNgay",
          render: (row: any) => row?.lslvDenNgay ? <span>{formatDateTable(row?.lslvDenNgay)}</span> : <TextValue className="spaces h-25" />
        },
      ]
    },
    {
      name: "Tên tổ chức, địa chỉ trụ sở, công việc đã làm",
      field: "lslvThongTin",
      cellStyle: {
        textAlign: "left"
      },
      render: (row: any) => row?.lslvThongTin ? <span>{row?.lslvThongTin}</span> : <TextValue className="spaces h-25" />
    },
  ];

  const columnsBonus = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: "Năm",
      field: "namKhenThuong",
      headerStyle: {
        minWidth: "110px",
        maxWidth: "110px"
      },
      render: (row: any) => row?.namKhenThuong ? <span>{row?.namKhenThuong}</span> : <TextValue className="spaces h-25" />
    },
    {
      name: "Xếp loại chuyên môn",
      field: "xepLoaiChuyenMon",
      render: (row: any) => row?.xepLoaiChuyenMon ? <span>{row?.xepLoaiChuyenMon}</span> : <TextValue className="spaces h-25" />
    },
    {
      name: "Xếp loại thi đua",
      field: "xepLoaiThiDua",
      render: (row: any) => row?.xepLoaiThiDua ? <span>{row?.xepLoaiThiDua}</span> : <TextValue className="spaces h-25" />
    },
    {
      name: "Hình thức khen thưởng",
      field: "khenThuongHinhThuc",
      render: (row: any) => row?.khenThuongHinhThuc ? <span>{row?.khenThuongHinhThuc}</span> : <TextValue className="spaces h-25" />
    },
  ];

  const columnsDiscipline = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: "Tháng/năm",
      field: "thangNam",
      child: [
        {
          name: "Từ",
          field: "klTuNgay",
          render: (row: any) => row?.klTuNgay ? <span>{formatDateTable(row?.klTuNgay)}</span> : <TextValue className="spaces h-25" />
        },
        {
          name: "Đến",
          field: "klDenNgay",
          render: (row: any) => row?.klDenNgay ? <span>{formatDateTable(row?.klDenNgay)}</span> : <TextValue className="spaces h-25" />
        },
      ]
    },
    {
      name: "Hình thức",
      field: "klHinhThuc",
      render: (row: any) => row?.klHinhThuc?.name ? <span>{row?.klHinhThuc?.name}</span> : <TextValue className="spaces h-25" />
    },
    {
      name: "Hành vi vi phạm chính",
      field: "klHanhVi",
      render: (row: any) => row?.klHanhVi ? <span>{row?.klHanhVi}</span> : <TextValue className="spaces h-25" />
    },
    {
      name: "Cơ quan quyết định",
      field: "klCoQuanQuyetDinh",
      render: (row: any) => row?.klCoQuanQuyetDinh ? <span>{row?.klCoQuanQuyetDinh}</span> : <TextValue className="spaces h-25" />
    },
  ];

  const columnsRelationshipsEmployee = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: "Mối quan hệ",
      field: "qhgdQuanHe",
      headerStyle: {
        minWidth: "140px"
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any) => row?.qhgdQuanHe ? <span>{row?.qhgdQuanHe}</span> : <TextValue className="spaces h-25" />
    },
    {
      name: "Họ và tên",
      field: "qhgdTen",
      headerStyle: {
        minWidth: "250px"
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any) => row?.qhgdTen ? <span>{row?.qhgdTen}</span> : <TextValue className="spaces h-25" />
    },
    {
      name: "Quê quán, nghề nghiệp, chức danh, chức vụ, đơn vị công tác, học tập, nơi ở (trong, ngoài nước); " +
        "thành viên các tổ chức chính trị - xã hội (trong, ngoài nước); làm việc cho chế độ cũ, tiền án, tiền sự (nếu có)",
      field: "qhgdDetails",
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any) => row?.qhgdDetails ? <span>{row?.qhgdDetails}</span> : <TextValue className="spaces h-25" />
    },
  ];

  const columnsSalaryEmployee = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: "Tháng/năm",
      field: "thangNam",
      child: [
        {
          name: "Từ",
          field: "lslTuNgay",
          render: (row: any) => row?.lslTuNgay ? <span>{formatDateTable(row?.lslTuNgay)}</span> : <TextValue className="spaces h-25" />
        },
        {
          name: "Đến",
          field: "lslDenNgay",
          render: (row: any) => row?.lslDenNgay ? <span>{formatDateTable(row?.lslDenNgay)}</span> : <TextValue className="spaces h-25" />
        },
      ]
    },
    {
      name: "Mã số",
      field: "lslChucDanh",
      render: (row: any) => row?.lslChucDanh ? <span>{row?.lslChucDanh}</span> : <TextValue className="spaces h-25" />
    },
    {
      name: "Bậc lương",
      field: "lslBacLuong",
      render: (row: any) => row?.lslBacLuong ? <span>{row?.lslBacLuong}</span> : <TextValue className="spaces h-25" />
    },
    {
      name: "Hệ số lương",
      field: "lslHeSo",
      render: (row: any) => row?.lslHeSo ? <span>{row?.lslHeSo}</span> : <TextValue className="spaces h-25" />
    },
    {
      name: "Tiền lương theo vị trí việc làm",
      field: "lslLuongThoaThuan",
      render: (row: any) => row?.lslLuongThoaThuan ? <span>{row?.lslLuongThoaThuan}</span> : <TextValue className="spaces h-25" />
    },
  ];

  const columnsOtherAllowances = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: "Tháng/năm",
      field: "thangNam",
      child: [
        {
          name: "Từ",
          field: "pcTuNgay",
          render: (row: any) => row?.pcTuNgay ? <span>{formatDateTable(row?.pcTuNgay)}</span> : <TextValue className="spaces h-25" />
        },
        {
          name: "Đến",
          field: "pcDenNgay",
          render: (row: any) => row?.pcDenNgay ? <span>{formatDateTable(row?.pcDenNgay)}</span> : <TextValue className="spaces h-25" />
        },
      ]
    },
    {
      name: "Loại phụ cấp",
      field: "pcLoaiPhuCap",
      render: (row: any) => row?.pcLoaiPhuCap?.name ? <span>{row?.pcLoaiPhuCap?.name}</span> : <TextValue className="spaces h-25" />
    },
    {
      name: "Phần trăm hưởng",
      field: "pcPhanTram",
      render: (row: any) => row?.pcPhanTram ? <span>{row?.pcPhanTram}</span> : <TextValue className="spaces h-25" />
    },
    {
      name: "Hệ số",
      field: "pcHeSo",
      render: (row: any) => row?.pcHeSo ? <span>{row?.pcHeSo}</span> : <TextValue className="spaces h-25" />
    },
    {
      name: "Hình thức hưởng",
      field: "pcHinhThucHuong",
      render: (row: any) => row?.pcHinhThucHuong ? <span>{row?.pcHinhThucHuong}</span> : <TextValue className="spaces h-25" />
    },
    {
      name: "Giá trị (đồng)",
      field: "pcPhanTram",
      render: (row: any) => row?.pcPhanTram ? <span>{row?.pcPhanTram}</span> : <TextValue className="spaces h-25" />
    },
  ];

  return (
    <div className="word">
      <TextUnderline readOnly label="Cơ quan quản lý cán bộ, công chức, viên chức" value={data?.coQuanQuanLy} />
      <TextUnderline readOnly label="Cơ quan, đơn vị sử dụng cán bộ, công chức, viên chức" value={data?.khoaPhong} />
      <TextUnderline readOnly label="Số hiệu" value={data?.soHieu} />
      <TextUnderline readOnly className="spaces mb-16" label="Mã số định danh" value={data?.soCMNDOrCCCD} />
      <div className="flex spaces mb-12 flex-space-between flex-top">
        <div className="image-profile">
          <Image src={data?.photo || toAbsoluteUrl("/media/avatars/blank.png")} alt="Ảnh màu (4 x 6cm)" className="object-fit-cover w-100 h-100" />
        </div>
        <div className="flex-grow-1">
          <p className="text-center spaces fs-lg fw-bold mb-0">SƠ YẾU LÝ LỊCH</p>
          <p className="spaces text-center fw-light"><i>(Ban hành kèm theo Thông tư số 06/2023/TT-BNV <br />ngày 04/5/2023 của Bộ trưởng Bộ Nội vụ)</i></p>
          <div className="flex">
            <TextUnderline readOnly label="1) Họ và tên khai sinh (viết chữ in hoa)" value={data?.name} />
            <TextUnderline readOnly className="spaces w-200" label="Giới tính" value={data?.gender?.name} />
          </div>
          <TextUnderline readOnly label="2) Các tên gọi khác" value={data?.otherName} />
          <TextUnderline readOnly label="3) Sinh ngày" value={`${data?.ngaySinh} tháng ${data?.thangSinh} năm ${data?.namSinh}`} />
          <TextUnderline readOnly label="4) Nơi sinh" value={data?.noiSinh} />
          <div className='flex spaces'>
            <TextUnderline readOnly label="5) Quê quán: Xã/Phường" value={data?.queQuanXaText} />
            <TextUnderline readOnly label="Huyện/Quận" value={data?.queQuanHuyenText} />
            <TextUnderline readOnly label="Tỉnh/Thành phố" value={data?.queQuanTinhText} />
          </div>
        </div>
      </div>
      <div className="flex">
        <TextUnderline readOnly label="6) Dân tộc" value={data?.danTocText} />
        <TextUnderline readOnly label="7) Tôn giáo" value={data?.tonGiaoText} />
      </div>
      <div className="flex">
        <TextUnderline readOnly label="8) Số CCCD" value={data?.soCMNDOrCCCD} />
        <TextUnderline readOnly label="Ngày cấp" value={data?.ngayCapCMNDOrCCCD} type="date" />
        <TextUnderline readOnly label="SĐT liên hệ" value={data?.phone} />
      </div>
      <div className="flex">
        <TextUnderline readOnly label="9) Số BHXH" value={data?.soBhxh} />
        <TextUnderline readOnly label="Số thẻ BHYT" value={data?.soTheBhyt} />
      </div>
      <TextUnderline readOnly label="10) Nơi ở hiện nay" value={data?.hnDiaChi} />
      <TextUnderline readOnly label="11) Thành phần gia đình xuất thân" value={data?.tpGiaDinh} />
      <TextUnderline readOnly label="12) Nghề nghiệp trước khi được tuyển dụng" value="" />
      <div className="flex">
        <TextUnderline readOnly label="13) Ngày được tuyển dụng lần đầu" value="" type="date" />
        <TextUnderline readOnly label="Cơ quan, tổ chức, đơn vị tuyển dụng" value={data?.donViCongTacText} />
      </div>
      <TextUnderline readOnly label="14) Ngày vào cơ quan hiện đang công tác" value={data?.ngayVaoCoQuan} type="date" />
      <div className="flex">
        <TextUnderline readOnly label="15) Ngày vào Đảng Cộng sản Việt Nam" value={data?.ttctNgayVaoDang} type="date" />
        <TextUnderline readOnly label="Ngày chính thức" value={data?.ttctNgayChinhThuc} type="date" />
      </div>
      <TextUnderline readOnly
        label="16) Ngày tham gia tổ chức chính trị-xã hội đầu tiên (ngày vào Đoàn TNCSHCM, Công đoàn, Hội)"
        value={data?.ngayVaoDoan}
        type="date"
      />
      <div className="flex">
        <TextUnderline readOnly label="17) Ngày nhập ngũ" value={data?.ngayNhapNgu} type="date" />
        <TextUnderline readOnly label="Ngày xuất ngũ" value={data?.ngayXuatNgu} type="date" />
        <TextUnderline readOnly label="Quân hàm cao nhất" value={data?.capBacQuanSuText} />
      </div>
      <TextUnderline readOnly label="18) Đối tượng chính sách" value={data?.chinhSach} />
      <TextUnderline readOnly label="19) Trình độ giáo dục phổ thông (đã tốt nghiệp lớp mấy/thuộc hệ nào)" value={data?.trinhDoGiaoDucPhoThongText} />
      <TextUnderline readOnly label="20) Trình độ chuyên môn cao nhất" value={data?.trinhDoChuyenMonCaoNhat} />
      <TextUnderline readOnly label="21) Học hàm" value={data?.hocHam} />
      <div className="flex">
        <TextUnderline readOnly label="22) Danh hiệu nhà nước phong tặng" value={data?.danhHieu} />
        <TextUnderline readOnly label="Năm" value={data?.namPhongTang} />
      </div>
      <TextUnderline readOnly label="23) Chức vụ hiện tại" value={data?.chucVuText} />
      <div className="flex spaces pl-30">
        <TextUnderline readOnly label="Ngày bổ nhiệm/ngày phê chuẩn" value={data?.ngayBoNhiem} type="date" />
        <TextUnderline readOnly label="Ngày bổ nhiệm lại/phê chuẩn nhiệm kỳ tiếp theo" value="" type="date" />
      </div>
      <TextUnderline readOnly label="24) Được quy hoạch chức danh" value="" />
      <TextUnderline readOnly label="25) Chức vụ kiêm nhiệm" value="" />
      <TextUnderline readOnly label="26) Chức vụ Đảng hiện tại" value={data?.hienNayChucVuDang} />
      <TextUnderline readOnly label="27) Chức vụ Đảng kiêm nhiệm" value={data?.chucVuDangKiemNhiem} />
      <TextUnderline readOnly label="28) Công việc chính được giao" value="" />
      <div className="flex">
        <TextUnderline readOnly label="29) Sở trường công tác" value="" />
        <TextUnderline readOnly label="Công việc làm lâu nhất" value="" />
      </div>
      <p className="spaces mb-0 ml-5">30&#10089; Tiền lương</p>
      <div className="flex">
        <TextUnderline readOnly label="30.1) Ngạch/chức danh nghề nghiệp" value={data?.chucDanh} />
        <TextUnderline readOnly label="Mã số" value={data?.maSoChucDanh} />
      </div>
      <TextUnderline readOnly className="spaces ml-44" label="Ngày bổ nhiệm ngạch/chức danh nghề nghiệp" value="" type="date" />
      <div className="flex">
        <TextUnderline readOnly className="spaces ml-44" label="Bậc lương" value={data?.bacLuong} />
        <TextUnderline readOnly label="Hệ số" value={data?.heSoBacLuong} />
        <TextUnderline readOnly label="Ngày hưởng" value={data?.ngayHuongLuongTuNgay} type="date" />
      </div>
      <div className="flex spaces ml-44">
        <div className="spaces flex-middle w-200">
          <TextUnderline readOnly label="Phần trăm hưởng" value={data?.phanTramHuong} />
        </div>
        <div className="spaces flex-middle w-300">
          <TextUnderline readOnly className="spaces ml-12" label="Phụ cấp thâm niên vượt khung" value={data?.phuCapThamNienVuotKhung} />
          <p className='unti-icon'>%</p>
        </div>
        <TextUnderline readOnly className="spaces ml-12 flex-1" label="Ngày hưởng PCTNVK" value="" type="date" />
      </div>
      <div className="flex">
        <TextUnderline readOnly label="30.2) Phụ cấp chức vụ" value={data?.pcChucVu} />
        <TextUnderline readOnly label="Phụ cấp kiêm nhiệm" value={data?.pcKiemNhiem} />
        <TextUnderline readOnly label="Phụ cấp khác" value={data?.pcKhac} />
      </div>
      <div className="flex">
        <TextUnderline readOnly label="30.3) Vị trí việc làm" value={data?.chucDanh} />
        <TextUnderline readOnly label="Mã số" value={data?.maSoChucDanh} />
      </div>
      <div className="flex spaces pl-44">
        <TextUnderline readOnly label="Bậc lương" value={data?.bacLuong} />
        <TextUnderline readOnly label="Lương theo mức tiền" value={data?.luongCoBan} />
        <p className='unti-icon'>vnđ</p>
        <TextUnderline readOnly className="spaces ml-12" label="Ngày hưởng" value={data?.ngayHuongLuongTuNgay} type="date" />
      </div>
      <div className="flex spaces pl-44">
        <div className="spaces flex-middle w-200">
          <TextUnderline readOnly label="Phần trăm hưởng" value={data?.phanTramHuong} />
        </div>
        <div className="spaces flex-middle w-300">
          <TextUnderline readOnly className="spaces ml-12" label="Phụ cấp thâm niên vượt khung" value={data?.phuCapThamNienVuotKhung} />
          <p className='unti-icon'>%</p>
        </div>
        <TextUnderline readOnly className="spaces ml-12 flex-1" label="Ngày hưởng PCTNVK" value="" type="date" />
      </div>
      <TextUnderline readOnly label="31) Tình trạng sức khoẻ" value={data?.tinhTrangSucKhoe} />
      <div className="flex spaces pl-30">
        <TextUnderline readOnly label="Chiều cao" value={data?.chieuCao} />
        <TextUnderline readOnly label="Cân nặng" value={data?.canNang} />
        <TextUnderline readOnly label="Nhóm máu" value={data?.nhomMau} />
      </div>

      <p className="text-center spaces mt-36 space-bottom"><b>32&#10089; QUÁ TRÌNH ĐÀO TẠO, BỒI DƯỠNG</b></p>
      <p className="spaces mt-12 mb-0">32.1- Chuyên môn (từ trung cấp trở lên cả trong nước và nước ngoài)</p>
      <TableGrouping
        id="trainingProcess"
        columns={columnsTrainingProcess}
        data={data?.chuyenMon || []}
        className="table-custom"
      />
      <p className="spaces mt-12 mb-0">32.2- Lý luận chính trị</p>
      <TableGrouping
        id="politicalTheory"
        columns={columnsPoliticalTheory}
        data={data?.lyLuanChinhTri || []}
        className="table-custom"
      />
      <p className="spaces mt-12 mb-0">32.3- Bồi dưỡng quản lý nhà nước/ chức danh nghề nghiệp/ nghiệp vụ chuyên ngành</p>
      <TableGrouping
        id="stateManagement"
        columns={columnsStateManagement}
        data={stateManagement || []}
        className="table-custom"
      />
      <p className="spaces mt-12 mb-0">32.4- Bồi dưỡng kiến thức an ninh, quốc phòng</p>
      <TableGrouping
        id="nationalDefense"
        columns={columnsNationalDefense}
        data={data?.quocPhong || []}
        className="table-custom"
      />
      <p className="spaces mt-12 mb-0">32.5- Tin học</p>
      <TableGrouping
        id="ITQualification"
        columns={columnsITQualification}
        data={data?.tinHoc || []}
        className="table-custom"
      />
      <p className="spaces mt-12 mb-0">32.6- Ngoại ngữ/ tiếng dân tộc</p>
      <TableGrouping
        id="foreignLanguageLevel"
        columns={columnsForeignLanguageLevel}
        data={foreignLanguageLevel || []}
        className="table-custom"
      />
      <p className="text-center spaces mt-36 space-bottom"><b>33&#10089; TÓM TẮT QUÁ TRÌNH CÔNG TÁC</b></p>
      <TableGrouping
        id="workingProcess"
        columns={columnsWorkingProcess}
        data={data?.quaTrinhCongTac || []}
        className="table-custom"
      />
      <p className="text-center spaces mt-36 space-bottom"><b>34&#10089; ĐẶC ĐIỂM LỊCH SỬ BẢN THÂN</b></p>
      <p>34.1- Khai rõ: bị bắt, bị tù (từ ngày tháng năm nào đến ngày tháng năm nào, ở đâu?), đã khai báo cho ai, những vấn đề gì?</p>
      <TextUnderline
        rows={2}
        readOnly
        value={data?.lichSuBanThan?.join("\n")}
      />
      <p className="spaces mt-12 mb-0">34.2- Bản thân có làm việc cho chế độ cũ</p>
      <TableGrouping
        id="oldRegime"
        columns={columnsOldRegime}
        data={data?.lichSuLamViecCheDoCuList || []}
        className="table-custom"
      />
      <p className="spaces mt-12 mb-0">34.3-Tham gia hoặc có quan hệ với các tổ chức chính trị, kinh tế, xã hội ... ở nước ngoài</p>
      <TableGrouping
        id="relationshipsForeignOrganizations"
        columns={columnsRelationshipsForeignOrganizations}
        data={data?.lichSuLamViecNuocNgoaiList || []}
        className="table-custom"
      />
      <p className="text-center spaces mt-36 space-bottom"><b>35&#10089; KHEN THƯỞNG, KỶ LUẬT</b></p>
      <p className="spaces mt-12 mb-0">35.1- Thành tích thi đua, khen thưởng</p>
      <TableCustom
        columns={columnsBonus}
        data={data?.khenThuong || []}
        buttonAdd={false}
        buttonExportExcel={false}
        justFilter
        noPagination
      />
      <p className="spaces mt-12 mb-0">35.2-Kỷ luật Đảng/hành chính</p>
      <TableGrouping
        id="discipline"
        columns={columnsDiscipline}
        data={data?.kyLuat || []}
        className="table-custom"
      />
      <p className="text-center spaces mt-36 space-bottom"><b>36&#10089; QUAN HỆ GIA ĐÌNH</b></p>
      <p className="spaces mt-12 mb-0">36.1- Về bản thân: Cha, Mẹ, Vợ (hoặc chồng), các con, anh chị em ruột</p>
      <TableCustom
        columns={columnsRelationshipsEmployee}
        data={data?.quanHeGiaDinhBt || []}
        buttonAdd={false}
        buttonExportExcel={false}
        justFilter
        noPagination
      />
      <p className="spaces mt-12 mb-0">36.2- Cha, Mẹ, anh chị em ruột (bên vợ hoặc chồng)</p>
      <TableCustom
        columns={columnsRelationshipsEmployee}
        data={data?.quanHeGiaDinhVc || []}
        buttonAdd={false}
        buttonExportExcel={false}
        justFilter
        noPagination
      />
      <p className="text-center spaces mt-36 space-bottom"><b>37&#10089; HOÀN CẢNH KINH TẾ GIA ĐÌNH</b></p>
      <p className="spaces mt-12 mb-0">37.1- Quá trình lương của bản thân</p>
      <TableGrouping
        id="salaryEmployee"
        columns={columnsSalaryEmployee}
        data={data?.lichSuLuong || []}
        className="table-custom"
      />
      <p className="spaces mt-12 mb-0">37.2- Các loại phụ cấp khác</p>
      <TableGrouping
        id="otherAllowances"
        columns={columnsOtherAllowances}
        data={data?.phuCap || []}
        className="table-custom"
      />

      <p className="spaces mt-12 mb-0">37.3- Nguồn thu nhập chính của gia đình hàng năm</p>
      <TextUnderline readOnly label="- Tiền lương" />
      <TextUnderline readOnly label="- Các nguồn khác" />
      <p className="spaces mb-0">- Nhà ở:</p>
      <div className="flex">
        <TextUnderline readOnly className="spaces ml-24 flex-2" label="+ Được cấp, được thuê (loại nhà)" />
        <div className="flex-middle flex-1">
          <TextUnderline readOnly label=", tổng diện tích sử dụng" />
          <p className='unti-icon'>m²</p>
        </div>
      </div>
      <TextUnderline readOnly className="spaces ml-24" label="Giấy chứng nhận quyền sở hữu" />
      <div className="flex">
        <TextUnderline readOnly className="spaces ml-24 flex-2" label="+ Nhà tự mua, tự xây (loại nhà)" />
        <div className="flex-middle flex-1">
          <TextUnderline readOnly label=", tổng diện tích sử dụng" />
          <p className='unti-icon'>m²</p>
        </div>
      </div>
      <TextUnderline readOnly className="spaces ml-24" label="Giấy chứng nhận quyền sở hữu" />
      <p className="spaces mb-0">- Đất ở:</p>
      <div className="flex-middle">
        <TextUnderline readOnly className="spaces ml-24" label="+ Đất được cấp" />
        <p className='unti-icon'>m²</p>
      </div>
      <TextUnderline readOnly className="spaces ml-24" label="Giấy chứng nhận quyền sử dụng" />
      <div className="flex-middle">
        <TextUnderline readOnly className="spaces ml-24" label="+ Đất tự mua" />
        <p className='unti-icon'>m²</p>
      </div>
      <TextUnderline readOnly className="spaces ml-24" label="Giấy chứng nhận quyền sử dụng" />
      <TextUnderline readOnly rows={3} label="- Đất sản xuất kinh doanh" />

      <p className="text-center spaces mt-36 space-bottom"><b>38&#10089; NHẬN XÉT, ĐÁNH GIÁ CỦA CƠ QUAN, TỔ CHỨC, ĐƠN VỊ SỬ DỤNG</b></p>
      <TextUnderline readOnly rows={4} />

      <div className="flex text-center gap-10 fs-sm spaces mt-40">
        <div className="flex-1">
          <p className="spaces mb-0"><b>Người Khai</b></p>
          <p className="spaces mb-0">Tôi xin cam đoan những lời khai trên đây <br /> là đúng sự thật</p>
        </div>
        <div className="flex-1">
          <div>
            <p className="mb-0 flex flex-center flex-align-items-end gap-0">
              <TextValue label="" className="spaces w-80" />, ngày
              <TextValue label="" className="spaces w-48" /> tháng
              <TextValue label="" className="spaces w-48" /> năm
              <TextValue label="" className="spaces w-48" />
            </p>
            <p className="mb-0">
              <b>Xác nhận của cơ quan, đơn vị quản lý <br />cán bộ, công chức, viên chức</b>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

import { FC, SetStateAction, createContext, useContext, useState } from "react";
import { WithChildren } from "../../../_metronic/helpers";

type ProfileContextProps = {
  isResign: boolean | undefined;
  setIsResign: React.Dispatch<SetStateAction<boolean | undefined>>;
}

const initState = {
  isResign: undefined,
  setIsResign: () => {}
}

export const ProfileContext = createContext<ProfileContextProps>(initState);
export const useProfileContext = () => useContext(ProfileContext);

const ProfileProvider: FC<WithChildren> = ({ children }) => {
  const [isResign, setIsResign] = useState<boolean>();

  const value = { isResign, setIsResign };

  return (
    <ProfileContext.Provider value={value}>
      {children}
    </ProfileContext.Provider>
  )
}

export default ProfileProvider;
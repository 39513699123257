/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import Autocomplete from "../../components/input/autocomplete";
import TextValidator from "../../components/input/text-validator";
import { RESPONSE_STATUS_CODE, VARIABLE_STRING, TYPE_CATEGORY } from "../../constants/moduleConsts";
import { INIT_INSURANCE, LIST_INSURANCE_STATUS_CODE, REF_TAB, listInsuranceStatus } from "../constants/profileConsts";
import { EmployeeProfile, IInsuranceProcess } from "../models/profileModels";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { convertNumberPrice, convertTextPrice, formatDateTable } from "../../utils/functionUtils";
import { addInsurance, getAllInsuranceProcess, getInsuranceByEmployeeId, updateBank, updateInsurance } from "../services/dialogServices";
import InsuranceProcessDialog from "./dialogs/InsuranceProcessDialog";
import * as Yup from "yup";
import TableWithColumnGroup from "../../report/components/TableWithColumnGroup";
import { IColumns } from "../../report/models/reportModels";
import AppContext from "../../../AppContext";
import { IBank } from "../models/dialogModels";
import { ConfirmDialog } from "../../components/dialogs";
import { searchAllProvince, searchAllSimpleValue, searchListWorkUnit } from "../../services/services";

type IProps = {
  identify: string;
  isView: boolean;
  employeeProfiles: EmployeeProfile;
  handleGetEmployee: (id: string) => Promise<void>;
  formikRef: any;
  activeTab: string;
};

export const InsuranceInformation: React.FC<IProps> = ({
  identify,
  isView,
  employeeProfiles,
  handleGetEmployee,
  formikRef,
  activeTab
}) => {
  const { setPageLoading } = useContext(AppContext);

  const { lang } = useMultiLanguage();
  const [listData, setListData] = useState<IInsuranceProcess[]>([]);
  const [shouldOpenCreateDialog, setShouldOpenCreateDialog] = useState<boolean>(false);
  const [insuranceProcessInfo, setInsuranceProcessInfo] = useState({});
  const [shouldOpenConfirmDialog, setShouldOpenConfirmDialog] = useState<boolean>(false)
  const [soSoBhxh, setSoSoBhxh] = useState("");

  const validationSchema = Yup.object().shape({
    trangThaiThamGiaBaoHiem: Yup.object().shape({}).required(lang("VALIDATION.REQUIRE")).nullable(),
    tkNganHang: Yup.string().nullable(),
    nganHang: Yup.object().nullable(),
    soSoBhxh: Yup.string()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    mucLuong: Yup.number()
      .when("luongTheoHeSo", {
        is: (luongTheoHeSo : boolean) => !luongTheoHeSo,
        then: Yup.number().required(lang("VALIDATION.REQUIRE")).nullable()
      })
      .nullable(),
    heSoLuong: Yup.number()
      .when("luongTheoHeSo", {
        is: (luongTheoHeSo : boolean) => luongTheoHeSo,
        then: Yup.number().required(lang("VALIDATION.REQUIRE")).nullable()
      })
      .nullable(),
  });

  const handleFormSubmit = async (values: any) => {
    try {
      setPageLoading(true);
      let dataInsuranceSubmit = {
        ...values,
        employeeId: identify,
        donViThamGiaBhxhId: values.donViThamGiaBhxh?.id,
        donViThamGiaBhxhText: values.donViThamGiaBhxh?.name,
        tinhDangKyKhamChuaBenhId: values.tinhDangKyKhamChuaBenh?.id,
        tinhDangKyKhamChuaBenhText: values.tinhDangKyKhamChuaBenh?.name,
        noiDangKyKcbId: values.noiDangKyKcb?.id,
        noiDangKyKcbText: values.noiDangKyKcb?.name,
      };
      const dataBankSubmit: IBank = {
        ...values,
        nganHangId: values.nganHang?.id,
        nganHangText: values.nganHang?.value
      };
      if (!employeeProfiles?.id) return;
      const { data: dataBank } = await updateBank(dataBankSubmit, employeeProfiles.id);
      const { data: dataInsurance } =
        dataInsuranceSubmit?.id
        ? await updateInsurance(dataInsuranceSubmit.id, dataInsuranceSubmit)
        : await addInsurance(dataInsuranceSubmit);

      if (dataBank?.code === RESPONSE_STATUS_CODE.SUCCESS && dataInsurance?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        const message = dataInsuranceSubmit?.id
          ? "TOAST.EDIT.SUCCESS"
          : "TOAST.ADD.SUCCESS";
        toast.success(lang(message));
        setSoSoBhxh(dataInsurance.data?.soSoBhxh);
        formik.setFieldValue("id", dataInsurance.data?.id);
        handleGetEmployee(employeeProfiles.id);
      } else toast.warning(`${dataInsurance?.message}`);
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: INIT_INSURANCE,
    validationSchema,
    onSubmit: handleFormSubmit
  });

  useEffect(() => {
    if(formik.values?.employeeId) {
      updateListDataInsuranceProcess();
    }
  }, [formik.values?.employeeId]);

  useEffect(() => {
    if (formikRef) {
      formikRef.current = formik;
    }
  }, [formik, formikRef]);

  useEffect(() => {
    if (identify && activeTab === REF_TAB.BAO_HIEM) {
      let stateDefault = {
        ...formik.values,
        chiNhanh: employeeProfiles?.chiNhanh || "",
        nganHang: employeeProfiles?.nganHang || null,
        tkNganHang: employeeProfiles?.tkNganHang || ""
      };
      if(!formik.values?.id) {
        const insuranceStatusDefault = listInsuranceStatus.find(option => option.code === LIST_INSURANCE_STATUS_CODE.THAM_GIA_BHXH);
        stateDefault = {
          ...stateDefault,
          trangThaiThamGiaBaoHiem: insuranceStatusDefault,
          coSoBhxhHayChua: true
        };
      }
      updateDataInsurance(stateDefault);
    }
  }, [employeeProfiles, activeTab]);

  const handleChangeSelect = (name: string, value: any) => {
    if(name === VARIABLE_STRING.NOI_Dang_KY_KCB) {
      formik.setFieldValue(VARIABLE_STRING.MA_NOI_Dang_KY_KCB, value?.code);
    }
    formik.setFieldValue(name, value);
  };

  const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target;
    if(name === VARIABLE_STRING.LUONG_THEO_HE_SO) {
      formik.setValues({
        ...formik.values,
        heSoLuong: "",
        phuCapThamNienNghe: "",
        phuCapThamNienVuotKhung: "",
        mucLuong: null,
        phuCapLuong: null,
        cacKhoanBoSung: null
      });
    }
    formik.setFieldValue(name, checked);
  };

  const handleChangeMoney = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, convertTextPrice(value));
  };

  const handleOpenDialog = (data: any) => {
    if(!data?.id && formik.values?.soSoBhxh !== soSoBhxh) {
      setShouldOpenConfirmDialog(true);
      return;
    }
    setShouldOpenCreateDialog(true);
    setInsuranceProcessInfo({
      ...data,
      soSoBhxh: data.soSoBhxh ? data.soSoBhxh : soSoBhxh,
    });
  };

  const handleCloseDialog = () => {
    setShouldOpenCreateDialog(false);
    setInsuranceProcessInfo({});
  };

  const updateDataInsurance = async (defaultValue: any) => {
    try {
      setPageLoading(true);
      const { data } = await getInsuranceByEmployeeId(String(employeeProfiles.id));
      let values = defaultValue;
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        const { data: dataFromApi } = data;
        values = {
          ...values,
          ...dataFromApi,
          donViThamGiaBhxh: dataFromApi?.donViThamGiaBhxhId ? {
            id: dataFromApi?.donViThamGiaBhxhId,
            name: dataFromApi?.donViThamGiaBhxhText,
          } : null,
          tinhDangKyKhamChuaBenh: dataFromApi?.tinhDangKyKhamChuaBenhId ? {
            id: dataFromApi?.tinhDangKyKhamChuaBenhId,
            name: dataFromApi?.tinhDangKyKhamChuaBenhText,
          } : null,
          noiDangKyKcb: dataFromApi?.noiDangKyKcbId ? {
            id: dataFromApi?.noiDangKyKcbId,
            name: dataFromApi?.noiDangKyKcbText,
          } : null
        }
        setSoSoBhxh(dataFromApi?.soSoBhxh);
      } else {
        toast.warning(data?.message);
      }
      formik.setValues(values);
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const updateListDataInsuranceProcess = async () => {
    try {
      const { data } = await getAllInsuranceProcess(String(employeeProfiles.id));
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListData(data?.data?.content || []);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const handleCloseConfirmDialog = () => {
    setShouldOpenConfirmDialog(false)
  }

  const handleConfirmContinue = async () => {
    setShouldOpenConfirmDialog(false);
    setShouldOpenCreateDialog(true);
    setInsuranceProcessInfo({ soSoBhxh: soSoBhxh });
  }

  const columns: IColumns[] = [
    {
      name: "STT",
      field: "stt",
      rowSpan: 2,
      headerStyle: {
        minWidth: "60px"
      },
      render: (_row: any, _index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("GENERAL.ENSURANCE.NUMBER"),
      rowSpan: 2,
      field: "soSoBhxh",
      headerStyle: {
        minWidth: "120px"
      }
    },
    {
      name: lang("INPUT.FULLNAME"),
      rowSpan: 2,
      field: "fullName",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
      render: () => (<span>{employeeProfiles?.name}</span>)
    },
    {
      name: lang("INPUT.INSURANCE.MONTH.START"),
      rowSpan: 2,
      field: "tuThang",
      headerStyle: {
        minWidth: "110px"
      },
      render: (row: any) => <span>{formatDateTable(row?.tuThang)}</span>
    },
    {
      name: lang("INPUT.INSURANCE.MONTH.END"),
      rowSpan: 2,
      field: "denThang",
      headerStyle: {
        minWidth: "110px"
      },
      render: (row: any) => <span>{formatDateTable(row?.denThang)}</span>
    },
    {
      name: lang("INPUT.INSURANCE.MORE_INFO"),
      field: "tenCoQuanDonVi",
      rowSpan: 2,
      headerStyle: {
        minWidth: "350px"
      },
      cellStyle: {
        textAlign: "left"
      },
      render: (row: any) => {
        const lines = row?.tenCoQuanDonVi ? row?.tenCoQuanDonVi?.split('\n') : [];
        return (
          lines.map((line: string, index: number) => (
            <p className="spaces m-0" key={index}>{line}</p>
          ))
        )
      }
    },
    {
      name: lang("INPUT.INSURANCE.SALARY"),
      field: "mucDong",
      rowSpan: 2,
      headerStyle: {
        minWidth: "120px"
      },
      render: (row: any) => <span>{convertNumberPrice(row?.mucDong)}</span>
    },
    {
      name: lang("INPUT.INSURANCE.ALLOWANCE"),
      field: "",
      colSpan: 6,
      child: [
        {
          name: lang("TIME_KEEPING.POSITION"),
          field: "phuCapKhuVuc",
          headerStyle: {
            minWidth: "140px"
          },
          render: (row: any) => <span>{convertNumberPrice(row?.phuCapKhuVuc)}</span>
        },
        {
          name: lang("SALARY.PROFESSIONAL.SENIORITY_ALLOWANCES_SHORT"),
          field: "phuCapTnvk",
          headerStyle: {
            minWidth: "140px"
          },
          render: (row: any) => <span>{convertNumberPrice(row?.phuCapTnvk)}</span>
        },
        {
          name: lang("SALARY.SENIORITY_ALLOWANCES_SHORT"),
          field: "phuCapTnnn",
          headerStyle: {
            minWidth: "140px"
          },
          render: (row: any) => <span>{convertNumberPrice(row?.phuCapTnnn)}</span>
        },
        {
          name: lang("INPUT.INSURANCE.AREA"),
          field: "phuCapKhuVuc",
          headerStyle: {
            minWidth: "140px"
          },
          render: (row: any) => <span>{convertNumberPrice(row?.phuCapKhuVuc)}</span>
        },
        {
          name: lang("INPUT.INSURANCE.OTHER"),
          field: "phuCapKhac",
          headerStyle: {
            minWidth: "140px"
          },
          render: (row: any) => <span>{convertNumberPrice(row?.phuCapKhac)}</span>
        },
        {
          name: lang("INPUT.INSURANCE.RE_ELECTED"),
          field: "phuCapTaiCu",
          headerStyle: {
            minWidth: "140px"
          },
          render: (row: any) => <span>{convertNumberPrice(row?.phuCapTaiCu)}</span>
        }
      ]
    },
    {
      name: lang("GENERAL.ENSURANCE.PERCENT_SHORT"),
      field: "tyLeDong",
      rowSpan: 2,
      headerStyle: {
        minWidth: "120px"
      },
      render: (row: any) => <span>{row?.tyLeDong} %</span>
    },
    {
      name: lang("INPUT.INSURANCE.SALARY.MAIN"),
      field: "luongChinh",
      rowSpan: 2,
      headerStyle: {
        minWidth: "140px"
      },
      render: (row: any) => <span>{convertNumberPrice(row?.luongChinh)}</span>
    },
    {
      name: lang("INPUT.SALARY.ALLOWANCE"),
      field: "phuCapLuong",
      rowSpan: 2,
      headerStyle: {
        minWidth: "160px"
      },
      render: (row: any) => <span>{convertNumberPrice(row?.phuCapLuong)}</span>
    },
    {
      name: lang("INPUT.INSURANCE.ADDED_OTHER"),
      field: "boSungKhac",
      rowSpan: 2,
      headerStyle: {
        minWidth: "120px"
      },
      render: (row: any) => <span>{convertNumberPrice(row?.boSungKhac)}</span>
    },
    {
      name: lang("INPUT.INSURANCE.BUDGET.SUPPORT_SHORT"),
      field: "nsnnHoTro",
      rowSpan: 2,
      headerStyle: {
        minWidth: "140px"
      },
      render: (row: any) => <span>{convertNumberPrice(row?.nsnnHoTro)}</span>
    },
  ];

  return (
    <div className="insurance-dialog spaces px-32 pb-16">
      <Form onSubmit={formik.handleSubmit}>
        <span className="text-header">
          {lang("GENERAL.INSURANCE")}
        </span>
        <Row>
          <Col md={6} lg={6} xl={4} xxl={3}>
            <Form.Check
              label={lang("GENERAL.ENSURANCE.HAS_NUMBER")}
              checked={formik?.values?.coSoBhxhHayChua}
              onChange={handleChangeCheckBox}
              name="coSoBhxhHayChua"
              className="spaces pt-33"
              disabled={isView}
            />
          </Col>
          <Col md={6} lg={6} xl={4} xxl={3} className="pt-3">
            <TextValidator
              isRequired
              lable={lang("GENERAL.ENSURANCE.NUMBER")}
              name="soSoBhxh"
              value={formik.values?.soSoBhxh || ""}
              type="text"
              readOnly={isView}
              onChange={formik.handleChange}
              errors={formik.errors?.soSoBhxh}
              touched={formik.touched?.soSoBhxh}
            />
          </Col>
          <Col md={6} lg={6} xl={4} xxl={3} className="pt-3">
            <Autocomplete
              isRequired
              lable={lang("INPUT.INSURANCE.STATUS")}
              options={listInsuranceStatus}
              value={formik.values?.trangThaiThamGiaBaoHiem}
              isReadOnly={isView}
              name="trangThaiThamGiaBaoHiem"
              onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.TRANG_THAI_THAM_GIA_BAO_HIEM, selectedOption)}
              errors={formik.errors?.trangThaiThamGiaBaoHiem}
              touched={formik.touched?.trangThaiThamGiaBaoHiem}
            />
          </Col>
          <Col md={6} lg={6} xl={4} xxl={3} className="pt-3">
            <TextValidator
              lable={lang("GENERAL.ENSURANCE.START_DATE")}
              name="ngayThamGiaBaoHiemXh"
              type="date"
              value={formik.values?.ngayThamGiaBaoHiemXh || ""}
              readOnly={isView}
              onChange={formik.handleChange}
            />
          </Col>
          <Col md={6} lg={6} xl={4} xxl={3} className="pt-3">
            <TextValidator
            lable={lang("GENERAL.ENSURANCE.PERCENT")}
              name="tiLeDongBaoHiem"
              value={formik.values?.tiLeDongBaoHiem || ""}
              type="number"
              readOnly={isView}
              onChange={formik.handleChange}
            />
          </Col>
          <Col md={6} lg={6} xl={4} xxl={3} className="pt-3">
            <Autocomplete
              lable={lang("GENERAL.INSURANCE_ORGANIZATION")}
              name="donViThamGiaBhxh"
              value={formik.values?.donViThamGiaBhxh}
              onChange={(selectedOption) =>
                handleChangeSelect(
                  VARIABLE_STRING.DON_VI_THAM_GIA_BHXH,
                  selectedOption
                )
              }
              options={[]}
              searchFunction={searchListWorkUnit}
            />
          </Col>
          <Col md={6} lg={6} xl={4} xxl={3}>
            <Form.Check
              label={lang("SALARY.BASED_COEFFICIENT")}
              checked={formik?.values?.luongTheoHeSo}
              onChange={handleChangeCheckBox}
              name="luongTheoHeSo"
              className="spaces pt-33"
              disabled={isView}
            />
          </Col>
          {formik?.values?.luongTheoHeSo ? (
            <>
              <Col md={6} lg={6} xl={4} xxl={3} className="pt-3">
                <TextValidator
                  isRequired
                  lable={lang("SALARY.COEFFICIENT")}
                  name="heSoLuong"
                  value={formik.values?.heSoLuong || ""}
                  type="number"
                  readOnly={isView}
                  onChange={formik.handleChange}
                  errors={formik.errors?.heSoLuong}
                  touched={formik.touched?.heSoLuong}
                />
              </Col>
              <Col md={6} lg={6} xl={4} xxl={3} className="pt-3">
                <TextValidator
                  lable={lang("SALARY.PROFESSIONAL.SENIORITY_ALLOWANCES")}
                  name="phuCapThamNienNghe"
                  value={formik.values?.phuCapThamNienNghe || ""}
                  type="number"
                  readOnly={isView}
                  onChange={formik.handleChange}
                />
              </Col>
              <Col md={6} lg={6} xl={4} xxl={3} className="pt-3">
                <TextValidator
                  lable={lang("SALARY.SENIORITY_ALLOWANCES")}
                  name="phuCapThamNienVuotKhung"
                  value={convertNumberPrice(formik.values?.phuCapThamNienVuotKhung)}
                  type="text"
                  readOnly={isView}
                  onChange={handleChangeMoney}
                />
              </Col>
            </>
          ) : (
            <>
              <Col md={6} lg={6} xl={4} xxl={3} className="pt-3">
                <TextValidator
                  isRequired
                  lable={lang("INPUT.WORKEXPERIENCE.SALARY")}
                  name="mucLuong"
                  value={convertNumberPrice(formik.values?.mucLuong)}
                  type="text"
                  readOnly={isView}
                  onChange={handleChangeMoney}
                  errors={formik.errors?.mucLuong}
                  touched={formik.touched?.mucLuong}
                />
              </Col>
              <Col md={6} lg={6} xl={4} xxl={3} className="pt-3">
                <TextValidator
                  lable={lang("INPUT.SALARY.ALLOWANCE")}
                  name="phuCapLuong"
                  value={convertNumberPrice(formik.values?.phuCapLuong)}
                  type="text"
                  readOnly={isView}
                  onChange={handleChangeMoney}
                />
              </Col>
              <Col md={6} lg={6} xl={4} xxl={3} className="pt-3">
                <TextValidator
                  lable={lang("GENERAL.ADDITIONAL")}
                  name="cacKhoanBoSung"
                  value={convertNumberPrice(formik.values?.cacKhoanBoSung)}
                  type="text"
                  readOnly={isView}
                  onChange={handleChangeMoney}
                />
              </Col>
            </>
          )}
          <Col md={6} lg={6} xl={4} xxl={3} className="pt-3">
            <TextValidator
              lable={lang("GENERAL.ENSURANCE.HEALTH_NUMBER")}
              name="soTheBhyt"
              value={formik.values?.soTheBhyt || ""}
              type="text"
              readOnly={isView}
              onChange={formik.handleChange}
            />
          </Col>
          <Col md={6} lg={6} xl={4} xxl={3} className="pt-3">
            <Autocomplete
              lable={lang("GENERAL.PROVINCE.REGISTRATION_KCB")}
              name="tinhDangKyKcb"
              value={formik.values?.tinhDangKyKhamChuaBenh || ""}
              searchFunction={searchAllProvince}
              isReadOnly={isView}
              onChange={(selectedOption) =>
                handleChangeSelect(
                  VARIABLE_STRING.TINH_DANG_KY_KCB,
                  selectedOption
                )
              }
              options={[]}
            />
          </Col>
          <Col md={6} lg={6} xl={4} xxl={3} className="pt-3">
            <Autocomplete
              lable={lang("GENERAL.EMPLOYEE.KCB_REGISTER_PLACE")}
              name="noiDangKyKcb"
              value={formik.values?.noiDangKyKcb || ""}
              menuPlacement="top"
              searchFunction={searchAllProvince}
              isReadOnly={isView}
              onChange={(selectedOption) =>
                handleChangeSelect(
                  VARIABLE_STRING.NOI_Dang_KY_KCB,
                  selectedOption
                )
              }
              options={[]}
            />
          </Col>
          <Col md={6} lg={6} xl={4} xxl={3} className="pt-3">
            <TextValidator
              lable={lang("GENERAL.EMPLOYEE.KCB_REGISTER_PLACE_CODE")}
              name="maSoNoiDangKyKcb"
              value={formik.values?.maSoNoiDangKyKcb || ""}
              type="text"
              readOnly={true}
              onChange={formik.handleChange}
            />
          </Col>
        </Row>
        <span className="text-header">
          {lang("SALARY.BANK.INFOMATION")}
        </span>
        <Row className="spaces mb-10">
          <Col md={6} lg={6} xl={4} xxl={3} className="pt-3">
            <TextValidator
              lable={lang("GENERAL.EMPLOYEE.BANK_NUMBER")}
              name="tkNganHang"
              value={formik.values?.tkNganHang || ""}
              type="text"
              readOnly={isView}
              onChange={formik.handleChange}
              errors={formik.errors?.tkNganHang}
              touched={formik.touched?.tkNganHang}/>
          </Col>
          <Col md={6} lg={6} xl={4} xxl={3} className="pt-3">
            <Autocomplete
              lable={lang("INPUT.SALARY.BANK")}
              name="nganHang"
              menuPlacement="top"
              value={formik.values?.nganHang || null}
              searchFunction={searchAllSimpleValue}
              searchObject={{ type: TYPE_CATEGORY.nganHang }}
              isReadOnly={isView}
              onChange={(value) => handleChangeSelect(VARIABLE_STRING.NGAN_HANG, value)}
              options={[]}
              getOptionLabel={(option) => option?.value}
              errors={formik.errors?.nganHang}
              touched={formik.touched?.nganHang}/>
          </Col>
          <Col md={6} lg={6} xl={4} xxl={3} className="pt-3">
            <TextValidator
              lable={lang("GENERAL.EMPLOYEE.RANK_BRANCH")}
              name="chiNhanh"
              value={formik.values?.chiNhanh || ""}
              type="text"
              readOnly={isView}
              onChange={formik.handleChange}
            />
          </Col>
        </Row>
      </Form>
      {formik.values?.id && (
        <Row>
          <Col xs={12} className="pt-6">
            <TableWithColumnGroup
              title={lang("GENERAL.INFO.INSURANCE.PROCESS")}
              columns={columns}
              data={listData}
              noPagination={true}
              buttonAdd={!isView}
              handleOpenDialog={(_) => handleOpenDialog({})}
              handleDoubleClick={!isView ? handleOpenDialog : () => {}}
            />
            {shouldOpenCreateDialog && (
              <InsuranceProcessDialog
                isView={isView}
                insuranceProcessInfo={insuranceProcessInfo}
                handleClose={handleCloseDialog}
                show={shouldOpenCreateDialog}
                employeeProfiles={employeeProfiles}
                updateParentTable={updateListDataInsuranceProcess}
              />
            )}
            {shouldOpenConfirmDialog && (
              <ConfirmDialog
                show={shouldOpenConfirmDialog}
                title={lang('DIALOG.CONFIRM.INSURACE.CHANGE.TITLE')}
                message={lang('DIALOG.CONFIRM.INSURACE.CHANGE.MESSAGE')}
                yes={lang('BTN.CONTINUE')}
                onYesClick={handleConfirmContinue}
                cancel={lang('BTN.CANCEL')}
                onCancelClick={handleCloseConfirmDialog}
              />
            )}
          </Col>
        </Row>
      )}
    </div>
  );
};

import { useFormik } from "formik";
import * as Yup from "yup";
import { Col, Row, Form } from "react-bootstrap";
import { GroupButton } from "../../components/GroupButton";
import Autocomplete from "../../components/input/autocomplete";
import TextValidator from "../../components/input/text-validator";
import { RangeDatePicker } from "../../components/input/RangeDatePicker";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { 
  MISSION_STATUS,
  MISSION_STATUS_CODE,
  RESPONSE_STATUS_CODE,
  TYPE,
  VARIABLE_STRING,
  TYPE_CATEGORY
} from "../../constants/moduleConsts";
import { checkObject, handleBlurDate, hasAuthority } from "../../utils/functionUtils";
import UploadFile from "../../components/file-upload/UploadFile";
import { IFile } from "../../profile/models/dialogModels";
import { ISecondment } from "../models/secondmentModels";
import { INIT_SECONDMENT } from "../constants/secondmentConsts";
import { useContext, useEffect, useMemo, useState } from "react";
import { STATUS_NV } from "../../profile/constants/dialogChildConsts";
import { checkInvalidDate } from "../../utils/validationSchema";
import { getEmployeeProfileById } from "../../profile/services/profileServices";
import { toast } from "react-toastify";
import AppContext from "../../../AppContext";
import { addSecondment, updateSecondment } from "../services/secondmentServices";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../../constants";
import { searchAllEmployee, searchAllSimpleValue, searchListWorkUnit, searchPhongBanByDonVi } from "../../services/services";

interface IProps {
  secondmentInfo: ISecondment;
  handleClose: () => void;
  handleCloseAndSearch: () => void;
  handleCloseUpdateDialog: () => void;
  isView: boolean;
  setIsView: (value: boolean) => void;
}

function SecondmentForm(props: IProps) {
  const {
    secondmentInfo,
    handleClose,
    handleCloseUpdateDialog,
    isView,
    setIsView,
    handleCloseAndSearch
  } = props;
  const { lang, intl } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [isShowBtnSave, setIsShowBtnSave] = useState<boolean>(true);
  const [isAcceptSecondment, setIsAcceptSecondment] = useState<boolean>(Boolean(secondmentInfo?.xacNhanBietPhai));
  const isShowAcceptSecondmentBtn = useMemo(() => !secondmentInfo?.id || !secondmentInfo?.xacNhanBietPhai, [secondmentInfo?.id, secondmentInfo?.xacNhanBietPhai]);

  const validationSchema = Yup.object().shape({
    employee: Yup.object()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    maNhanVien: Yup.string()
      .required(lang("VALIDATION.REQUIRE")),
    donViCongTacText: Yup.string().required(lang("VALIDATION.REQUIRE")),
    phongBanText: Yup.string().required(lang("VALIDATION.REQUIRE")),
    chucVuText: Yup.string().required(lang("VALIDATION.REQUIRE")),
    donViCongTacMoi: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    phongBanMoi: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    chucVuMoi: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    soQuyetDinh: Yup.string(),
    coHieuLucTuNgay: Yup.date()
      .concat(checkInvalidDate(intl))
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    coHieuLucDenNgay: Yup.date()
      .concat(checkInvalidDate(intl))
      .required(lang("VALIDATION.REQUIRE"))
      .when("coHieuLucTuNgay", {
        is: (coHieuLucTuNgay: Date | null) => coHieuLucTuNgay,
        then: Yup.date()
          .min(
            Yup.ref("coHieuLucTuNgay"),
            lang("VALIDATION.MINDATE") +
            lang("GENERAL_EFFECTIVE_DATE_START")
          )
          .nullable()
      })
      .nullable(),
    trangThai: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
  });

  const convertDataSubmit = (data: ISecondment) => {
    return {
      ...data,
      chucVuMoiId: data?.chucVuMoi?.id || "",
      chucVuMoiText: String(data.chucVuMoi?.value),
      phongBanMoiId: data?.phongBanMoi?.id || "",
      phongBanMoiText: data.phongBanMoi?.name || "",
      donViCongTacMoiId: data?.donViCongTacMoi?.id || "",
      donViCongTacMoiText: data.donViCongTacMoi?.name || "",
      xacNhanBietPhai: isAcceptSecondment
    }
  }

  const handleSubmit = async (values: any) => {
    try {
      setPageLoading(true);
      const { data } = secondmentInfo.id
        ? await updateSecondment(secondmentInfo.id, convertDataSubmit(values))
        : await addSecondment(convertDataSubmit(values));
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(
          secondmentInfo.id
            ? lang("TOAST.EDIT.SUCCESS")
            : lang("TOAST.CREATE.SUCCESS")
        );
        handleCloseAndSearch();
      } else {
        toast.warning(data?.message);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false)
    }
  };

  const formik = useFormik({
    initialValues: INIT_SECONDMENT,
    onSubmit: handleSubmit,
    validationSchema
  });

  const getEmployeeInfo = async (id: string) => {
    try {
      const { data } = await getEmployeeProfileById(id);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        formik.setFieldValue(VARIABLE_STRING.EMPLOYEE, data?.data);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      console.error(error);
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  useEffect(() => {
    if (!secondmentInfo?.id) {
      formik.setValues(INIT_SECONDMENT);
    } else {
      let values = {
        ...secondmentInfo,
        donViCongTacMoi: secondmentInfo?.donViCongTacMoiId ? {
          id: secondmentInfo?.phongBanMoiId,
          name: secondmentInfo?.phongBanMoiText
        } : null,
        phongBanMoi: secondmentInfo?.phongBanMoiId ? {
          id: secondmentInfo?.phongBanMoiId,
          name: secondmentInfo?.phongBanMoiText
        } : null,
        chucVuMoi: secondmentInfo?.chucVuMoiId ? {
          id: secondmentInfo?.chucVuMoiId,
          value: secondmentInfo?.chucVuMoiText
        } : null
      }
      formik.setValues(values);
    }
    if (secondmentInfo?.employeeId) {
      getEmployeeInfo(secondmentInfo.employeeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondmentInfo]);

  const handleChangeSelect = (name: string, value: any) => {
    formik.setFieldValue(name, value);
  };

  const handleEmployeeChange = (employee: any) => {
    let values: ISecondment = {
      ...formik.values,
      employee,
      name: employee?.name || "",
      employeeId: employee?.id || "",
      maNhanVien: employee?.maNhanVien || "",
      donViCongTacId: employee?.donViCongTacId || "",
      donViCongTacText: employee?.donViCongTacText || "",
      phongBanId: employee?.phongBanId || "",
      phongBanText: employee?.phongBanText || "",
      chucVuId: employee?.chucVuId || "",
      chucVuText: employee?.chucVuText || ""
    };
    formik.setValues(values);
  };

  const handleFile = (data: IFile) => {
    formik.setFieldValue("fileId", data.id);
    formik.setFieldValue("fileName", data.name);
  };

  const handleAcceptSecondment = async () => {
    if (checkObject(await formik.validateForm())) {
      setIsAcceptSecondment(true);
    }
  }

  const handleChangeDate = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value);
    if (name === VARIABLE_STRING.TU_NGAY) {
      const effectiveDate = value ? new Date(value) : null;
      const currentDate = new Date();
      if (effectiveDate && effectiveDate <= currentDate) {
        setIsShowBtnSave(false);
      } else setIsShowBtnSave(true);
    }
  }

  return (
    <Form onSubmit={formik.handleSubmit} className="h-full">
      <div className="header-form-action">
        <GroupButton
          type="btn-back"
          handleClose={handleClose}
        />
        <div className="flex gap-4">
          {(!isView && secondmentInfo?.trangThai?.code === MISSION_STATUS_CODE.UNFULFILLED && secondmentInfo?.id) && (
            <GroupButton type="btn-cancel" handleCloseUpdateDialog={handleCloseUpdateDialog} />
          )}
          {isView && secondmentInfo?.id && secondmentInfo?.trangThai?.code !== MISSION_STATUS_CODE.COMPLETED 
            && !secondmentInfo.xacNhanBietPhai && hasAuthority(PERMISSIONS.BIET_PHAI, PERMISSION_ABILITY.UPDATE) && (
            <GroupButton type="btn-edit" handleSaveEdit={() => setIsView(false)} />
          )}
          {(!isView && isShowBtnSave && (secondmentInfo?.trangThai?.code === MISSION_STATUS_CODE.UNFULFILLED || !secondmentInfo?.id)) && (
            <GroupButton type="btn-save" handleSubmit={() => setIsAcceptSecondment(false)} />
          )}
          {isShowAcceptSecondmentBtn && (
            <GroupButton typeButton="submit" handleEvent={handleAcceptSecondment}>{lang("SECONDMENT.ACCEPT")}</GroupButton>
          )}
        </div>
      </div>
      <div className="form-content-scroll">
        <div className="sub-title-form">{secondmentInfo?.id ? lang("SECONDMENT.EDIT") : lang("SECONDMENT.ADD")}</div>
        <div className="sub-title pb-2 pt-0">{lang("APPOINTMENT_INFO_EMPLOYEE")}</div>
        <Row className="g-4">
          <Col sm={4} md={4} lg={4} xl={3} xxl={3}>
            <Autocomplete
              isRequired
              lable={lang("GENERAL.FULLNAME")}
              options={[]}
              onChange={(value) => handleEmployeeChange(value)}
              name="employee"
              value={formik.values?.employee || null}
              searchFunction={searchAllEmployee}
              searchObject={{
                trangThaiLamViec: Object.values(STATUS_NV).filter(status => status !== STATUS_NV.THOI_VIEC).join(',')
              }}
              isReadOnly={isView}
              errors={formik.errors.employee}
              touched={formik.touched.employee}
              getOptionLabel={(option) => `${option?.maNhanVien} - ${option?.name}`}
            />
          </Col>
          <Col sm={3} md={3} lg={3} xl={2} xxl={3}>
            <TextValidator
              name="maNhanVien"
              lable={lang("INPUT_APPOINTMENT_CODE")}
              type="text"
              value={formik.values?.maNhanVien}
              readOnly={true}
              errors={formik?.errors?.maNhanVien}
              touched={formik?.touched?.maNhanVien}
              isRequired
            />
          </Col>
          <Col sm={5} md={5} lg={5} xl={3} xxl={3}>
            <TextValidator
              name="donViCongTacText"
              lable={lang("SECONDMENT.WORK_UNIT_OLD")}
              type="text"
              value={formik.values?.donViCongTacText}
              readOnly={true}
              isRequired
              errors={formik?.errors?.donViCongTacText}
              touched={formik?.touched?.donViCongTacText}
            />
          </Col>
          <Col sm={6} md={5} lg={5} xl={4} xxl={3}>
            <TextValidator
              name="phongBanText"
              lable={lang("INPUT_APPOINTMENT_UNIT_DEPARTMENT")}
              type="text"
              value={formik.values?.phongBanText}
              readOnly={true}
              isRequired
              errors={formik?.errors?.phongBanText}
              touched={formik?.touched?.phongBanText}
            />
          </Col>
          <Col sm={6} md={5} lg={5} xl={4} xxl={4}>
            <TextValidator
              name="chucVuText"
              lable={lang("INPUT_APPOINTMENT_JOB_POSITION")}
              type="text"
              value={formik.values?.chucVuText}
              readOnly={true}
              isRequired
              errors={formik?.errors?.chucVuText}
              touched={formik?.touched?.chucVuText}
            />
          </Col>
        </Row>
        <div className="sub-title pb-2">{lang("SECONDMENT.INFOMATION")}</div>
        <Row className="g-4">
          <Col sm={6} md={4} lg={4} xl={4} xxl={3}>
            <Autocomplete
              name="donViCongTacMoi"
              lable={lang("SECONDMENT.WORK_UNIT_NEW")}
              searchFunction={searchListWorkUnit}
              onChange={(value) => handleChangeSelect(VARIABLE_STRING.DON_VI_CONG_TAC_MOI, value)}
              value={formik.values.donViCongTacMoi || null}
              isRequired
              options={[]}
              errors={formik?.errors?.donViCongTacMoi}
              touched={formik?.touched?.donViCongTacMoi}
              isReadOnly={isView}
            />
          </Col>
          <Col sm={6} md={5} lg={5} xl={4} xxl={4}>
            <Autocomplete
              lable={lang("SECONDMENT.DEPARTMENTS_SECONDMENT")}
              isReadOnly={isView}
              searchFunction={searchPhongBanByDonVi}
              searchObject={{ id: formik.values?.donViCongTacMoi?.id }}
              options={[]}
              isRequired
              value={formik.values?.phongBanMoi}
              name={VARIABLE_STRING.PHONG_BAN_MOI}
              onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.PHONG_BAN_MOI, selectedOption)}
              urlData="data.data"
              errors={formik.errors?.phongBanMoi}
              touched={formik.touched?.phongBanMoi}
              dependencies={[formik.values?.donViCongTacMoi]}
            />
          </Col>
          <Col sm={6} md={4} lg={4} xl={4} xxl={3}>
            <Autocomplete
              lable={lang("INPUT_APPOINTMENT_NEW_JOB_POSITION_NEW")}
              isReadOnly={isView}
              searchFunction={searchAllSimpleValue}
              searchObject={{ type: TYPE_CATEGORY.chucDanh }}
              options={[]}
              isRequired
              value={formik.values?.chucVuMoi}
              name={VARIABLE_STRING.CHUC_VU_MOI}
              onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.CHUC_VU_MOI, selectedOption)}
              errors={formik.errors?.chucVuMoi}
              touched={formik.touched?.chucVuMoi}
              getOptionLabel={(option) => option?.value}
            />
          </Col>
          <Col sm={6} md={3} lg={3} xl={3} xxl={2}>
            <Autocomplete
              lable={lang("GENERAL.STATUS")}
              isRequired
              isReadOnly={isView}
              options={MISSION_STATUS}
              value={formik.values?.trangThai || null}
              name="trangThai"
              onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.TRANG_THAI, selectedOption)}
              errors={formik.errors?.trangThai}
              touched={formik.touched?.trangThai}
            />
          </Col>
          <Col sm={4} md={3} lg={3} xl={3} xxl={3}>
            <TextValidator
              name="soQuyetDinh"
              lable={lang("INPUT_APPOINTMENT_NUMBER")}
              type="text"
              value={formik.values?.soQuyetDinh}
              readOnly={isView}
              onChange={formik.handleChange}
              errors={formik?.errors?.soQuyetDinh}
              touched={formik?.touched?.soQuyetDinh}
            />
          </Col>
          <Col sm={6} md={3} lg={2} xl={2} xxl={2}>
            <TextValidator
              name="ngayQuyetDinh"
              lable={lang("INPUT_APPOINTMENT_CONFIRM_DATE")}
              type="date"
              value={formik.values?.ngayQuyetDinh}
              readOnly={isView}
              onChange={formik.handleChange}
              onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.ngayQuyetDinh, "ngayQuyetDinh")}
              errors={formik?.errors?.ngayQuyetDinh}
              touched={formik?.touched?.ngayQuyetDinh}
            />
          </Col>
          <Col sm={6} md={3} lg={4} xl={4} xxl={4}>
            <RangeDatePicker
              label={lang("CONCURRENTLY.EFFECTIVE.TIME")}
              startDateName="coHieuLucTuNgay"
              endDateName="coHieuLucDenNgay"
              isView={isView}
              handleChange={handleChangeDate}
              value={formik.values}
              setFieldValue={formik.setFieldValue}
              touch={formik.touched}
              errors={formik.errors}
              isRequired
            />
          </Col>
          <Col sm={6} md={3} lg={3} xl={3} xxl={3}>
            <UploadFile
              label="INPUT_APPOINTMENT_ATTACHMENTS"
              setValue={handleFile}
              fileValue={{
                id: formik.values.fileId || "",
                name: formik.values.fileName || ""
              }}
              isReadOnly={isView}
            />
          </Col>
          <Col xs={12}>
            <TextValidator
              className="h-auto-input spaces"
              lable={lang("ALLOWANCE.NOTE")}
              name="ghiChu"
              value={formik.values.ghiChu}
              type="text"
              readOnly={isView}
              rows={5}
              onChange={formik.handleChange}
              as={TYPE.TEXTAREA}
            />
          </Col>
        </Row>
      </div>
    </Form>
  );
}

export default SecondmentForm;
import TextValidator from "../../../components/input/text-validator";
import { Col, Form, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { INIT_ORTHER_ASSETS_LAND } from "../../constants/initValues";
import { IFormProps, IOtherAssetsLand } from "../../models/declareAssetsModels";
import { useEffect } from "react";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import Autocomplete from "../../../components/input/autocomplete";
import { convertNumberPrice, convertTextPrice } from "../../../utils/functionUtils";
import { searchAllSimpleValue } from "../../../services/services";
import { TYPE_CATEGORY } from "../../../constants/moduleConsts";

export function OtherAssetsLandForm(props: IFormProps<IOtherAssetsLand>) {
  const { handleSubmit, isView, btnSubmitRef, dataEdit } = props;
  const { lang } = useMultiLanguage();

  useEffect(() => {
    if (dataEdit?.taiSanId) {
      formik.setValues({
        ...dataEdit,
        loaiObj: { value: dataEdit?.loai }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataEdit]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    loaiObj: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable()
  });

  const formik = useFormik({
    initialValues: INIT_ORTHER_ASSETS_LAND,
    onSubmit: handleSubmit,
    validationSchema
  });

  const handleChangeSelect = (name: string, value: any) => {
    switch (name) {
      case "loaiObj":
        formik.setValues({ ...formik.values, [name]: value, loai: value?.value || "" });
        break;
      default:
        formik.setFieldValue(name, value);
        break;
    }
  };

  const handleChangeMoney = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, convertTextPrice(value));
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row>
        <Col xs={12} className="spaces mt-10">
          <Autocomplete
            isRequired
            lable={lang("VACCINATION.TYPE")}
            name="loaiObj"
            value={formik.values?.loaiObj}
            options={[]}
            searchObject={{ type: TYPE_CATEGORY.taiSanGanLienVoiDat }}
            searchFunction={searchAllSimpleValue}
            menuPlacement="bottom"
            getOptionLabel={(option) => option?.value}
            isReadOnly={isView}
            onChange={(value) => handleChangeSelect("loaiObj", value)}
            errors={formik.errors?.loaiObj}
            touched={formik.touched?.loaiObj}
          />
        </Col>
        <Col xs={12} className="spaces mt-10">
          <TextValidator
            isRequired
            lable={lang("GENERAL.NAME")}
            name="name"
            readOnly={isView}
            type="text"
            value={formik.values?.name}
            onChange={formik.handleChange}
            errors={formik.errors?.name}
            touched={formik.touched?.name}
          />
        </Col>
        <Col xs={6} className="spaces mt-10">
          <TextValidator
            lable={lang("DECLARE_ASSETS.AMOUNT_ACREAGE")}
            name="dienTich"
            readOnly={isView}
            type="text"
            value={convertNumberPrice(formik.values?.dienTich)}
            onChange={handleChangeMoney}
            errors={formik.errors?.dienTich}
            touched={formik.touched?.dienTich}
          />
        </Col>
        <Col xs={6} className="spaces mt-10">
          <TextValidator
            lable={lang("ALLOWANCE.VALUE")}
            name="giaTri"
            readOnly={isView}
            type="text"
            value={convertNumberPrice(formik.values?.giaTri)}
            onChange={handleChangeMoney}
            errors={formik.errors?.giaTri}
            touched={formik.touched?.giaTri}
          />
        </Col>
      </Row>
      <button ref={btnSubmitRef} type="submit" className="hidden"></button>
    </Form>
  );
}

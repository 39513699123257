import { ILang } from "../../../hook/useMultiLanguage";
import { MISSION_STATUS, TYPE } from "../../constants/moduleConsts";
import { searchListWorkUnit, searchPhongBan } from "../../services/services";

export const INIT_SEND_PEOPLE_TRAINING = {
  hieuLucTuNgay: "",
  hieuLucDenNgay: "",
  tenKhoaDaoTao: "",
  noiDaoTao: "",
  trangThai: null,
  note: "",
  fileId: "",
  fileName: "",
  ngayQuyetDinh: "",
  soQuyetDinh: "",
  khoaPhongNhanQuyetDinh: "",
  nguoiKy: null,
  nguoiKyId: "",
  nguoiKyText: "",
  employeeId: "",
  maNhanVien: "",
  tenNhanVien: "",
  donViCongTacId: "",
  donViCongTacText: "",
  phongBanId: "",
  phongBanText: "",
  chucDanhId: "",
  chucDanhText: "",
  soTiet: null
};

export const ListSearch = (lang: ILang) => {
  return [
    {
      name: lang("PROFILE.CODE"),
      field: "maNhanVien",
      type: TYPE.TEXT,
    },
    {
      name: lang("PROFILE.NAME"),
      field: "tenNhanVien",
      type: TYPE.TEXT,
    },
    {
      name: lang("GENERAL.CURRENT_ORGANIZATION"),
      field: "donViCongTacId",
      searchFunction: searchListWorkUnit,
      type: TYPE.SELECT,
    },
    {
      name: lang("INPUT.DEPARTMENTS"),
      field: "phongBanIds",
      searchFunction: searchPhongBan,
      type: TYPE.SELECT,
      isMulti: true,
    },
    {
      name: lang("SEND_PEOPLE_TRAINING.NAME"),
      field: "tenKhoaDaoTao",
      type: TYPE.TEXT,
    },
    {
      name: lang("SEND_PEOPLE_TRAINING.LOCATION"),
      field: "noiDaoTao",
      type: TYPE.TEXT,
    },
    {
      name: lang("GENERAL.STATUS"),
      field: "trangThai",
      type: TYPE.SELECT,
      listOption: MISSION_STATUS,
      isMulti: true,
    },
  ]
}
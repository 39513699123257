import { paramsConfig } from "../../utils/paramsUtils";
import axios from "axios";
import { ILeaveRegulations } from "../models/categoryModels";

const API_URL_CHAM_CONG = process.env.REACT_APP_API_URL_CHAM_CONG;

export const searchLeaveRegulations = (searchData: any) => {
  let url = `${API_URL_CHAM_CONG}/leave-regulations/page`;
  return axios.get(url, paramsConfig(searchData))
};

export const addLeaveRegulations = (holidayInfo: ILeaveRegulations) => {
  let url = `${API_URL_CHAM_CONG}/leave-regulations`;
  return axios.post(url, holidayInfo);
};

export const updateLeaveRegulations = (id: string, holidayInfo: ILeaveRegulations) => { 
  let url = `${API_URL_CHAM_CONG}/leave-regulations/${id}`;
  return axios.put(url, holidayInfo);
};

export const deleteLeaveRegulations = (ids: string[]) => {
  let url = `${API_URL_CHAM_CONG}/leave-regulations/ids?ids=${ids.toString()}`;
  return axios.delete(url);
};

export const CacKhoanPhaiThuColumn = () => {
  return (
    [
      {
        name: "Khoản thu",
        field: "khoanThu",
        headerCellProps: {
          minWidth: 200
        }
      },
      {
        name: "Số tiền",
        field: "giaTri",
        headerCellProps: {
          minWidth: 200
        }
      },
      {
        name: "Ghi chú",
        field: "ghiChu",
        headerCellProps: {
          minWidth: 300
        }
      }
    ]
  )
}
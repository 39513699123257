import { Col, Form, Row } from "react-bootstrap";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import TextValidator from "../../components/input/text-validator";
import Autocomplete from "../../components/input/autocomplete";
import { INIT_FAMILY_POLICY, POLICY, POLICY_CODE } from "../constants/profileConsts";
import * as Yup from "yup";
import { RESPONSE_STATUS_CODE, VARIABLE_STRING, TYPE_CATEGORY } from "../../constants/moduleConsts";
import { useEffect, useState } from "react";
import { updateThongTinGiaDinhChinhSach } from "../services/profileServices";
import { GroupButton } from "../../components/GroupButton";
import { searchAllSimpleValue } from "../../services/services";

interface IProps {
  identify: string;
  isView: boolean;
  employeeProfiles: any;
  activeTab: string;
  handleGetEmployee: (id: string) => void;
}

function FamilyPolicyInformation(props: IProps) {
  const { identify, isView, employeeProfiles, handleGetEmployee } = props;
  const { lang } = useMultiLanguage();

  const [isThuongBinh, setIsThuongBinh] = useState(false);

  useEffect(() => {
    if (employeeProfiles?.id) {
      formik.setValues({
        chinhSach: employeeProfiles?.chinhSach || null,
        hangThuongBinh: employeeProfiles?.hangThuongBinhId
          ? {
            id: employeeProfiles?.hangThuongBinhId || "",
            value: employeeProfiles?.hangThuongBinhText || ""
          }
          : null,
        hinhThucThuongTat: employeeProfiles?.hinhThucThuongTat || "",
        namThuongTat: employeeProfiles?.namThuongTat || null,
        laThuongBinh: employeeProfiles?.laThuongBinh,
        laGiaDinhChinhSach: employeeProfiles?.laGiaDinhChinhSach,
        laGiaDinhCoCongCachMang: employeeProfiles?.laGiaDinhCoCongCachMang
      });
      setIsThuongBinh(employeeProfiles?.chinhSach?.code === POLICY_CODE.THUONG_BINH);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeProfiles]);

  const handleFormSubmit = async (values: any) => {
    try {
      const dataSubmit = {
        ...values,
        hangThuongBinhId: values?.hangThuongBinh?.id || "",
        hangThuongBinhText: values?.hangThuongBinh?.value || ""
      };
      const { data } = await updateThongTinGiaDinhChinhSach(identify, dataSubmit);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("TOAST.EDIT.SUCCESS"));
        handleGetEmployee(employeeProfiles?.id || "");
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const validationSchema = Yup.object().shape({
    chinhSach: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    hangThuongBinh: isThuongBinh
      ? Yup.object().required(lang("VALIDATION.REQUIRE")).nullable()
      : Yup.object().notRequired().nullable(),
    namThuongTat: Yup.number()
      .min(1900, lang("VALIDATION.MIN_YEAR"))
      .max(new Date().getFullYear(), lang("VALIDATON.YEAR_CURRENT"))
      .nullable()
  });

  const formik = useFormik({
    initialValues: INIT_FAMILY_POLICY,
    onSubmit: handleFormSubmit,
    validationSchema
  });

  const handleChangeSelect = (name: string, value: any) => {
    const {
      THUONG_BINH,
      NHU_THUONG_BINH,
      THUONG_BINH_DAC_BIET,
      ANH_HUNG_VU_TRANG,
      ANH_HUNG_LAO_DONG,
      ME_VNAH,
      DICH_BAT_TU_DAY,
      LAO_THANH_CACH_MANG,
      CHAT_DOC_GIA_CAM
    } = POLICY_CODE;

    if (name === VARIABLE_STRING.CHINH_SACH) {
      setIsThuongBinh(value?.code === THUONG_BINH);
      formik.setValues({
        ...formik.values,
        [name]: value,
        laThuongBinh: [THUONG_BINH, NHU_THUONG_BINH, THUONG_BINH_DAC_BIET].includes(value?.code),
        laGiaDinhCoCongCachMang: [
          ANH_HUNG_VU_TRANG,
          ANH_HUNG_LAO_DONG,
          ME_VNAH,
          DICH_BAT_TU_DAY,
          LAO_THANH_CACH_MANG,
          CHAT_DOC_GIA_CAM
        ].includes(value?.code)
      });
    } else {
      formik.setFieldValue(name, value);
    }
  };

  return (
    <Form
      className="family-policy form-info relative z-index-2"
      onSubmit={formik.handleSubmit}
    >
      <div className="block-content">
        <div className="spaces mb-8 flex flex-between">
          <span className="spaces fs-18 text-header-table fw-600">{lang("INPUT.FAMILY_POCIFY_TILTE")}</span>
          {!isView && <GroupButton type="btn-save" />}
        </div>
        <Row className="spaces pl-20 pt-0">
          <Col xs={12} xl={12}>
            <Autocomplete
              className="spaces z-index-5"
              lable={lang("INPUT.POCIFY")}
              name="chinhSach"
              isRequired
              value={formik?.values?.chinhSach || null}
              isReadOnly={isView}
              onChange={(value) => handleChangeSelect(VARIABLE_STRING.CHINH_SACH, value)}
              options={POLICY}
              errors={formik.errors?.chinhSach}
              touched={formik.touched?.chinhSach}
            />
          </Col>
        </Row>
        <Row className="spaces pl-20">
          <Col xs={2}xl={3} className="spaces pt-4">
            <Form.Check
              label={lang("INPUT.WOUNDED_SOLIDER")}
              name="laThuongBinh"
              checked={formik.values?.laThuongBinh || false}
              readOnly={isView}
              // onChange={handleCheckBoxChange}
              className="checkBox custom-form-check"
              disabled={isView}
            />
          </Col>
          <Col xs={2} xl={3} className="spaces pt-4">
            <Form.Check
              label={lang("INPUT.IS_FAMILY_POCIFY")}
              name="laGiaDinhChinhSach"
              checked={formik.values?.laGiaDinhChinhSach || false}
              readOnly={isView}
              // onChange={handleCheckBoxChange}
              className="checkBox custom-form-check"
              disabled={isView}
            />
          </Col>
          <Col xs={3} xl={4} className="spaces pt-4">
            <Form.Check
              label={lang("INPUT.FAMILY_WITH_REVOLUTONERY_MERIT")}
              name="laGiaDinhCoCongCachMang"
              checked={formik.values?.laGiaDinhCoCongCachMang || false}
              readOnly={isView}
              // onChange={handleCheckBoxChange}
              className="checkBox custom-form-check"
              disabled={isView}
            />
          </Col>
          {isThuongBinh && (
            <>
              <Col xs={3} xl={4} className="spaces pt-4">
                <Autocomplete
                  className="spaces z-index-4"
                  lable={lang("INPUT.WOUNDED_RANK")}
                  name="hangThuongBinh"
                  isRequired
                  value={formik?.values?.hangThuongBinh || null}
                  isReadOnly={isView}
                  onChange={(value) => handleChangeSelect(VARIABLE_STRING.HANG_THUONG_BINH2, value)}
                  options={[]}
                  searchFunction={searchAllSimpleValue}
                  searchObject={{ type: TYPE_CATEGORY.hangThuongBenhBinh }}
                  getOptionLabel={(options) => options?.value}
                  touched={formik.touched?.hangThuongBinh}
                  errors={formik.errors?.hangThuongBinh}
                />
              </Col>
              <Col xs={3} xl={4} className="spaces pt-4">
                <TextValidator
                  lable={lang("INPUT.FORM_OF_INJURY")}
                  name="hinhThucThuongTat"
                  type="text"
                  value={formik.values?.hinhThucThuongTat}
                  readOnly={isView}
                  onChange={formik.handleChange}
                />
              </Col>
              <Col xs={3} xl={4} className="spaces pt-4">
                <TextValidator
                  lable={lang("INPUT.YEAR_OF_INJURY")}
                  name="namThuongTat"
                  type="number"
                  value={formik.values?.namThuongTat}
                  readOnly={isView}
                  onChange={formik.handleChange}
                  touched={formik.touched?.namThuongTat}
                  errors={formik.errors?.namThuongTat}
                />
              </Col>
            </>
          )}
        </Row>
      </div>
    </Form>
  );
}

export default FamilyPolicyInformation;

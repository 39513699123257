/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import {Col, Modal, Row, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Autocomplete from "../../../components/input/autocomplete";
import TextValidator from "../../../components/input/text-validator";
import { IAllowance, ILoaiPhuCap } from "../../../contract/models/allowanceModels";
import { addPhuCap, updatePhuCap } from "../../services/dialogServices";
import { INIT_ALLOWANCE, coGiaHan, heSoPhanTramGiaTri, loaiPhuCapList } from "../../../contract/constants/allowanceConsts";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import FileUpload from "../../../components/file-upload/FileUpload";
import { RangeDatePicker } from "../../../components/input/RangeDatePicker";
import AppContext from "../../../../AppContext";
import { useProfileContext } from "../../ProfileContext";
import { hasAuthority, numberExceptThisSymbols } from "../../../utils/functionUtils";
import { RESPONSE_STATUS_CODE } from "../../../constants/moduleConsts";
import { GroupButton } from "../../../components/GroupButton";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../../../constants";
interface IProps {
  handleCloseAllowanceDialog: (isAddAndUpdate?: boolean) => void;
  allowanceInfo: IAllowance;
  isView: boolean;
  getListAllowance: () => Promise<void>;
  employeeId: string | undefined;
}

const EmployeeAllowanceDialog: FC<IProps> = (props) => {
  const { handleCloseAllowanceDialog, getListAllowance, allowanceInfo, employeeId, isView } = props;
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);
  const { isResign } = useProfileContext();

  const [isEdit, setIsEdit] = useState<boolean>(!isView);

  const validationSchema = Yup.object().shape({
    tenKhoanPhuCap: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    heSoPhanTramHuong: Yup.string()
      .test("positiveNumber", "", function (value) {
        if(value && Number(value) <= 0) {
          return this.createError({
            path: 'heSoPhanTramHuong',
            message: lang('ALLOWANCE.COEFFICIENT_PERCENT_VALUE') + " " +
              lang('VALIDATION.POSITIVE_NUMBER')
          });
        }
        return true;
      })
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    thoiGianHieuLucBatDau: Yup.date()
      .required(lang("VALIDATION.REQUIRE"))
      .when('thoiGianHieuLucKetThuc', {
        is: (thoiGianHieuLucKetThuc: Date | null) => thoiGianHieuLucKetThuc && thoiGianHieuLucKetThuc < (new Date()),
        then: Yup.date().max(Yup.ref('thoiGianHieuLucKetThuc'), lang("VALIDATION.MAXDATE") + lang("ALLOWANCE.TIME_EFFECT_END")).nullable(),
      })
      .max(new Date(), lang("VALIDATION.INVALID_START_EFFECTIVE_DATE"))
      .nullable(),
    thoiGianHieuLucKetThuc: Yup.date()
      .min(Yup.ref("thoiGianHieuLucBatDau"), lang("VALIDATION.MINDATE") + lang("ALLOWANCE.TIME_EFFECT_START"))
      .nullable(),
    hinhThucHuong: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),

  });

  const handleSubmit = async (values: IAllowance) => {
    try {
      setPageLoading(true);
      values.employeeId = String(employeeId);
      const res = allowanceInfo?.id ? await updatePhuCap(allowanceInfo?.id, values) : await addPhuCap(values);
      if (res?.data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(allowanceInfo?.id ? lang("TOAST.EDIT.SUCCESS") : lang("TOAST.CREATE.SUCCESS"));
        await getListAllowance();
        handleCloseAllowanceDialog();
      } else {
        toast.warning(`${res?.data?.message}`);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: INIT_ALLOWANCE,
    onSubmit: handleSubmit,
    validationSchema
  });

  useEffect(() => {
    if (allowanceInfo?.id) {
      formik.setValues(allowanceInfo);
    } else {
      formik.setValues(INIT_ALLOWANCE);
    }
  }, [allowanceInfo]);

  const hanleChangeLoaiPhuCap = (value: ILoaiPhuCap) => {
    let hinhThucHuong = "";

    switch (value.heSoPhanTramGiaTri) {
      case heSoPhanTramGiaTri.COEFFICIENT:
        hinhThucHuong = lang("ALLOWANCE.COEFFICIENT");
        break;
      case heSoPhanTramGiaTri.PERCENT:
        hinhThucHuong = lang("ALLOWANCE.PERCENT");
        break;
      case heSoPhanTramGiaTri.MONEY:
        hinhThucHuong = lang("ALLOWANCE.MONEY");
        break;
      default:
        hinhThucHuong = lang("ALLOWANCE.COEFFICIENT");
        break;
    }

    let coGiaHanValue: boolean = false;
    let phuCapBHXH: boolean = false;

    switch (value.coGiaHan) {
      case coGiaHan.KHONG_TICH:
        coGiaHanValue = false;
        phuCapBHXH = false;
        break;
      case coGiaHan.BHXH:
        coGiaHanValue = false;
        phuCapBHXH = true;
        break;
      case coGiaHan.CO_GIA_HAN:
        coGiaHanValue = true;
        phuCapBHXH = false;
        break;
      case coGiaHan.BOTH:
        coGiaHanValue = true;
        phuCapBHXH = true;
        break;
      default:
        coGiaHanValue = false;
        phuCapBHXH = false;
        break;
    }

    formik.setValues({
      ...formik.values,
      tenKhoanPhuCap: value,
      coGiaHan: coGiaHanValue,
      phuCapBHXH,
      hinhThucHuong
    });
  };

  return (
    <Modal
      show={true}
      onHide={() => handleCloseAllowanceDialog()}
      backdrop="static"
      size="xl"
      className="custom-modal"
      centered
    >
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>
            {lang(
              `${
                allowanceInfo
                  ? "GENERAL.ALLOWANCE.UPDATE"
                  : "GENERAL.ALLOWANCE.ADD"
              }`
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="gap-col-modal">
            <Col xs={4}>
              <Autocomplete
                lable={lang("ALLOWANCE.TYPE")}
                name="tenKhoanPhuCap"
                options={loaiPhuCapList}
                value={formik.values?.tenKhoanPhuCap || null}
                isReadOnly={!isEdit}
                onChange={(value) => hanleChangeLoaiPhuCap(value)}
                isRequired
                menuPlacement="bottom"
                maxMenuHeight={180}
                errors={formik.errors?.tenKhoanPhuCap}
                touched={formik.touched?.tenKhoanPhuCap}
              />
            </Col>
            <Col xs={4}>
              <TextValidator
                lable={lang("ALOWNCE.BONUS_FORM")}
                name="hinhThucHuong"
                isRequired
                value={formik.values?.hinhThucHuong || ""}
                type="text"
                readOnly={true}
                onChange={formik.handleChange}
                errors={formik.errors?.hinhThucHuong}
                touched={formik.touched?.hinhThucHuong}
              />
            </Col>
            <Col xs={4}>
              <TextValidator
                lable={lang("ALLOWANCE.COEFFICIENT_PERCENT_VALUE")}
                name="heSoPhanTramHuong"
                value={formik.values?.heSoPhanTramHuong}
                type="number"
                readOnly={!isEdit}
                onChange={formik.handleChange}
                onKeyDown={numberExceptThisSymbols}
                isRequired
                errors={formik.errors?.heSoPhanTramHuong}
                touched={formik.touched?.heSoPhanTramHuong}
              />
            </Col>
            <Col xs={4}>
              <RangeDatePicker
                label={lang("ALLOWANCE.TIME_EFFECT")}
                isRequired
                startDateName="thoiGianHieuLucBatDau"
                endDateName="thoiGianHieuLucKetThuc"
                isView={!isEdit}
                handleChange={formik.handleChange}
                value={formik.values}
                setFieldValue={formik.setFieldValue}
                touch={formik.touched}
                errors={formik.errors}
              />
            </Col>
            <Col xs={4} className="flex">
              <Form.Check
                label={lang("ALLOWANCE.BHXH")}
                className="custom-form-check spaces mr-16"
                name="phuCapBHXH"
                checked={formik.values?.phuCapBHXH || false}
                // readOnly={true}
                onChange={formik.handleChange}
              />
              {/* </Col>
            <Col xs={2}> */}
              <Form.Check
                label={lang("ALLOWANCE.IS_EXTENSION")}
                className="custom-form-check"
                name="coGiaHan"
                checked={formik.values?.coGiaHan || false}
                // readOnly={true}
                onChange={formik.handleChange}
              />
            </Col>
            <Col xs={4}>
              <FileUpload
                isReadOnly={!isEdit}
                label="ALLOWANCE.DOCUMENT_DECISION"
                setFieldValue={(data: string) => {
                  formik.setFieldValue("taiLieuDinhKem", data);
                }}
                fileName={formik.values?.taiLieuDinhKem || ""}
              />
            </Col>
            <Col xs={12}>
              <TextValidator
                lable={lang("ALLOWANCE.NOTE")}
                name="ghiChu"
                value={formik.values?.ghiChu || ""}
                as="textarea"
                rows={3}
                readOnly={!isEdit}
                onChange={formik.handleChange}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="flex-center">
          <GroupButton type="btn-cancel" handleCloseUpdateDialog={handleCloseAllowanceDialog} />
          {!isResign &&
            (isEdit ? (
              <GroupButton type="btn-save" />
            ) : (hasAuthority(PERMISSIONS.EMPLOYEE, PERMISSION_ABILITY.UPDATE) &&
              <GroupButton type="btn-edit" handleSaveEdit={() => setIsEdit(true)} />
            ))}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
export { EmployeeAllowanceDialog };

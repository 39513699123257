import { RESPONSE_STATUS_CODE, TYPE } from "../../constants/moduleConsts";
import { DECLARE_ASSETS_STATUS_CODES } from "../../declare-assets/constants/declareAssetsConsts";
import { formatDateTable, hasAuthority } from "../../utils/functionUtils";
import TableCustom, { columnNamesType } from "../../components/table-custom/TableCustom";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../../constants";
import { useContext, useEffect, useState } from "react";
import { IDeclareAssets } from "../../declare-assets/models/declareAssetsModels";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import AppContext from "../../../AppContext";
import {
  deleteDeclareAssets,
  getDeclareAssetsByEmployeeId
} from "../../declare-assets/services/declareAssetsServices";
import { toast } from "react-toastify";
import { REF_TAB } from "../constants/profileConsts";
import DeclareAssetsDialog from "./dialogs/DeclareAssetsDialog";

interface Props {
  employeeProfiles: any;
  activeTab: string;
}

function DeclareAssetsInformation(props: Props) {
  const { employeeProfiles, activeTab } = props;
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [listData, setListData] = useState<IDeclareAssets[]>([]);
  const [dataEdit, setDataEdit] = useState<IDeclareAssets>({} as IDeclareAssets);
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);

  useEffect(() => {
    if (employeeProfiles?.id && activeTab === REF_TAB.KE_KHAI_TAI_SAN) {
      updatePageData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeProfiles?.id, activeTab]);

  const updatePageData = async () => {
    try {
      setPageLoading(true);
      const { data } = await getDeclareAssetsByEmployeeId(employeeProfiles?.id);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListData(data?.data || []);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleOpenDialog = (row: IDeclareAssets) => {
    setIsOpenDialog(true);
    setDataEdit(row);
  };

  const handleCloseDialog = () => {
    setIsOpenDialog(false);
  };
  
  const handleCloseAndSearch = () => {
    updatePageData();
    handleCloseDialog();
  }

  const handleDelete = async (ids: string) => {
    try {
      setPageLoading(true);
      const { data } = await deleteDeclareAssets(ids?.toString());
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("TOAST.DELETE.SUCCESS"));
        updatePageData();
        return true;
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const columns: columnNamesType[] = [
    {
      name: "STT",
      field: "stt",
      headerStyle: {
        minWidth: "50px"
      },
      render: (_, index, stt) => <span>{stt}</span>
    },
    {
      name: lang("INPUT.EMPLOYEE"),
      field: "tenNhanVien"
    },
    {
      name: lang("GENERAL.UNIT_DEPARTMENT"),
      field: "donViCongTacText"
    },
    {
      name: lang("UNIT.TYPE"),
      field: "loaiKeKhai",
      render: (row) => <span>{row?.loaiKeKhai?.name}</span>
    },
    {
      name: lang("DECLARE_ASSETS.DECLARE_DATE"),
      field: "ngayKeKhai",
      render: (row) => <span>{formatDateTable(row?.ngayKeKhai)}</span>
    },
    {
      name: lang("STATUS"),
      field: "trangThai",
      render: (row) => <span>{row?.trangThai?.name}</span>
    }
  ];

  return (
    <div className="form-info">
      <div className="block-content">
        <TableCustom
          id="declareAssetsProfile"
          title="Thông tin kê khai tài sản"
          data={listData || []}
          columns={columns}
          isActionTableTab
          buttonAdd={hasAuthority(PERMISSIONS.HOP_DONG, PERMISSION_ABILITY.CREATE)}
          buttonExportExcel={false}
          notDelete={!hasAuthority(PERMISSIONS.HOP_DONG, PERMISSION_ABILITY.DELETE)}
          type={TYPE.MULTILINE}
          noPagination
          handleOpenDialog={handleOpenDialog}
          handleDoubleClick={handleOpenDialog}
          handleDelete={handleDelete}
          deleteConditional={[
            {
              keyPath: "trangThai.code",
              value: DECLARE_ASSETS_STATUS_CODES.CHO_PHE_DUYET
            },
            {
              keyPath: "trangThai.code",
              value: DECLARE_ASSETS_STATUS_CODES.DA_PHE_DUYET
            },
            {
              keyPath: "trangThai.code",
              value: DECLARE_ASSETS_STATUS_CODES.TU_CHOI
            }
          ]}
        />
      </div>

      {isOpenDialog && (
        <DeclareAssetsDialog
          dataEdit={dataEdit}
          employeeProfiles={employeeProfiles}
          handleClose={handleCloseDialog}
          handleCloseAndSearch={handleCloseAndSearch}
          open={isOpenDialog}
        />
      )}
    </div>
  );
}

export default DeclareAssetsInformation;

/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { GroupButton } from "../../components/GroupButton";
import Autocomplete from "../../components/input/autocomplete";
import TextValidator from "../../components/input/text-validator";
import EmployeeInfo from "../../profile/components/EmployeeInfo";
import { getBacLuongByChucDanh } from "../../profile/services/dialogServices";
import { getEmployeeProfileById } from "../../profile/services/profileServices";
import { RESPONSE_STATUS_CODE, TYPE, VARIABLE_STRING, DATA_WORD_TYPES, TYPE_CATEGORY, EXPORT_FILE_NAME } from "../../constants/moduleConsts";
import { addMoreYear, convertNumberPrice, convertTextPrice, exportToFile, handleBlurDate, hasAuthority, matchTemplateWord } from "../../utils/functionUtils";
import { checkInvalidDate } from "../../utils/validationSchema";
import {
  CODE_CONTRACT_LONG_TERM,
  CODE_HOP_DONG,
  INIT_CONTRACT,
  INIT_CONTRACT_ANNEX_INFO,
  codeStatusContract,
  contractStatus,
  contractTypeBienChe,
  contractTypeHopDong,
  contractWordExportList,
  signinStatus,
    workShifts
} from "../constants/contractConsts";
import { addNewContract, updateContract } from "../services/contractServices";
import { IContractAnnexInfo, IContractInfo } from "../models/contractModels";
import { convertDataContactDto, filterObject, switchContractStatus } from "../utils/contractUtils";
import TableCustom from "../../components/table-custom/TableCustom";
import { ContractAnnexColumn } from "../../profile/components/columns/ContractAnnexColumn";
import { deleteMultipleContractAnnex, getListAnnexByContract } from "../services/annexServices";
import { AddNewContractAnnex } from "./AddNewContractAnnex";
import { AxiosResponse } from "axios";
import { APIResponse, IPeopleDecision } from "../../models/models";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import UploadFile from "../../components/file-upload/UploadFile";
import { IFile } from "../../profile/models/dialogModels";
import { OFFICER_CODE, STATUS_NV } from "../../profile/constants/dialogChildConsts";
import { TYPE_POSITION } from "../../profile/constants/profileConsts";
import { exportHopDongPhuLuc } from "../../services/exportFileServices";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../../constants";
import AppContext from "../../../AppContext";
import { ExportWord, ITemplateExportWord } from "../../components/exportWord";
import { searchAllEmployee, searchAllSimpleValue, searchListWorkUnit, searchNguoiDaiDienKy, searchPhongBanByDonVi } from "../../services/services";
import { AddNewRepresentative } from "../../components/dialogs";

interface IProps {
  view: boolean;
  handleOpenUpdateDialog: () => void;
  handleClose: () => void;
  handleCloseUpdateDialog: () => void;
  handleCloseAndSearch: () => void;
  contractInfo: IContractInfo;
}

const AddNewContract: FC<IProps> = (props) => {
  const { handleClose, view, handleOpenUpdateDialog, contractInfo, handleCloseUpdateDialog, handleCloseAndSearch } = props;
  const { lang, intl } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [isHopDongDaiHan, setIsHopDongDaiHan] = useState(false);
  const [shouldOpenAddContractAnnex, setShouldOpenAddContractAnnex] = useState<boolean>(false);
  const [annexInfo, setAnnexInfo] = useState<IContractAnnexInfo>(INIT_CONTRACT_ANNEX_INFO);
  const [contractAnnexList, setContractAnnexList] = useState<IContractAnnexInfo[]>([]);
  const [listWorkUnit, setListWorkUnit] = useState<any[]>([]);
  const [isCollaborators, setIsCollaborators] = useState<boolean>(false);
  const [isLumpSum, setIsLumpSum] = useState<boolean>(false);
  const [isShowDialogWord, setIsShowDialogWord] = useState<boolean>(false);
  const [templateWord, setTemplateWord] = useState<ITemplateExportWord[] | null>(null);
  const [isShowDialogAddRepresentative, setIsShowDialogAddRepresentative] = useState<boolean>(false);
  const [peopleDecisions, setPeopleDecisions] = useState<IPeopleDecision[]>([]);

  const validationSchema: any = Yup.object().shape({
    employee: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    trangThaiHopDong: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    donViKyHopDong: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    soHopDong: Yup.string().when(VARIABLE_STRING.LOAI_HOP_DONG, {
      is: (loaiHopDong: any) => loaiHopDong?.code === CODE_HOP_DONG.THU_VIEC,
      then: Yup.string().nullable(),
      otherwise: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable()
    }),
    loaiHopDong: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    nguoiDaiDienCtyKy: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    chucDanh: isCollaborators ? Yup.object().nullable() : Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    luongCoBan: (!isCollaborators && !isLumpSum) ? Yup.string().nullable() : Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    bacLuongOption: (isCollaborators || isLumpSum) ? Yup.object().nullable() : Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    heSoLuong: (isCollaborators || isLumpSum) ? Yup.string().nullable() : Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    ngayCoHieuLuc: Yup.date()
      .concat(checkInvalidDate(intl))
      .required(lang("VALIDATION.REQUIRE"))
      .when("ngayHetHan", {
        is: (ngayHetHan: Date | null) => ngayHetHan,
        then: Yup.date()
          .max(
            Yup.ref("ngayHetHan"),
            lang("VALIDATION.MAXDATE") +
            lang("CONTRACT.EXPIRATIONDATE")
          )
          .nullable()
      })
      .nullable(),
    ngayHetHan: Yup.date().when(VARIABLE_STRING.LOAI_HOP_DONG, {
      is: (loaiHopDong: any) =>
        loaiHopDong?.code === CODE_HOP_DONG.VIEN_CHUC_DAI_HAN || loaiHopDong?.code === CODE_HOP_DONG.HOP_DONG_DAI_HAN,
      then: Yup.date().nullable(),
      otherwise: Yup.date()
        .required(intl.formatMessage({ id: "VALIDATION.REQUIRE" }))
        .concat(checkInvalidDate(intl))
        .min(Yup.ref(VARIABLE_STRING.NGAY_CO_HIEU_LUC), lang("VALIDATE.EXPIRATION_DATE_AFTER_EFFECTIVE_DATE"))
        .nullable()
    }),
    ngayKyHopDong: Yup.date()
      .concat(checkInvalidDate(intl))
      .when("ngayHetHan", {
        is: (ngayHetHan: Date | null) => ngayHetHan && ngayHetHan < (new Date()),
        then: Yup.date()
          .max(
            Yup.ref("ngayHetHan"),
            lang("VALIDATION.SIGN_DAY.EQUAL_OF_LESS")
          )
          .nullable()
      })
      .max(new Date(), lang("VALIDATION.INVALID_SIGN_DATE"))
      .nullable(),
    phongBan: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    chucVu: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable()
  });

  const handleFormSubmit = async (values: IContractInfo) => {
    try {
      setPageLoading(true);
      const dataContract = filterObject(convertDataContactDto(values));
      const res = contractInfo.id
        ? await updateContract(contractInfo.id, dataContract)
        : await addNewContract(dataContract);

      if (res?.data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(contractInfo.id
          ? lang("TOAST.EDIT.CONTRACT.SUCCESS")
          : lang("TOAST.ADD.CONTRACT.SUCCESS"));
        handleCloseAndSearch();
      } else {
        toast.warning(`${res?.data?.message}`);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: INIT_CONTRACT,
    validationSchema,
    onSubmit: handleFormSubmit
  });

  const handleChangeSelect = (name: string, value: any) => {
    switch (name) {
      case VARIABLE_STRING.LOAI_HOP_DONG:
        const { VIEN_CHUC_DAI_HAN, HOP_DONG_DAI_HAN, ONE_YEAR } = CODE_HOP_DONG;
        switch (value?.code) {
          case VIEN_CHUC_DAI_HAN:
          case HOP_DONG_DAI_HAN:
            formik.setValues({
              ...formik.values,
              [name]: value,
              ngayHetHan: ""
            });
            setIsHopDongDaiHan(true);
            break;
          case ONE_YEAR:
            setIsHopDongDaiHan(true);
            formik.setValues({
              ...formik.values,
              [name]: value,
              ngayHetHan: addMoreYear(formik.values?.ngayCoHieuLuc, 1)
            });
            break;
          default:
            formik.setFieldValue(VARIABLE_STRING.LOAI_HOP_DONG, value);
            setIsHopDongDaiHan(false);
            break;
        }
        break;
      case VARIABLE_STRING.NGUOI_DAI_DIEN_KY:
        formik.setValues({
          ...formik.values,
          nguoiDaiDienCtyKy: value,
          nguoiDaiDienCtyChucDanhId: value?.chucVuId,
          nguoiDaiDienCtyChucDanhText: value?.chucVuText,
          nguoiDaiDienCtyKyId: value?.id,
          nguoiDaiDienCtyKyText: value?.name
        });
        break;
      case VARIABLE_STRING.CHUC_DANH:
        formik.setFieldValue(name, value);
        formik.setFieldValue(VARIABLE_STRING.BAC_LUONG_OPTION, null);
        formik.setFieldValue(VARIABLE_STRING.HE_SO_LUONG, undefined);
        break;
      case VARIABLE_STRING.BAC_LUONG_OPTION:
        formik.setFieldValue(name, value);
        formik.setFieldValue(VARIABLE_STRING.HE_SO_LUONG, value?.heSoLuong || "");
        break;
      default:
        formik.setFieldValue(name, value);
        break;
    }
  };

  const handleChangeNgayCoHieuLuc = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    formik.setValues({
      ...formik.values,
      [name]: value,
      ngayHetHan:
        formik.values?.loaiHopDong?.code === CODE_HOP_DONG.ONE_YEAR ? addMoreYear(value, 1) : formik.values?.ngayHetHan
    });
  };

  useEffect(() => {
    const lumpSumContract = formik.values?.loaiHopDong?.code === CODE_HOP_DONG.HOP_DONG_KHOAN;
    setIsLumpSum(lumpSumContract);
    lumpSumContract && formik.setValues({ ...formik.values, bacLuongOption: null, heSoLuong: "" });
  }, [formik.values?.loaiHopDong?.code]);

  useEffect(() => {
    formik.setValues(contractInfo);
    if(hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW)) {
      handleChangeEmployee(contractInfo?.employee);
    } else if(contractInfo?.employeeId) {
      getEmployeeInfo(contractInfo.employeeId);
    }
    
    const checkHopDongDaiHan = CODE_CONTRACT_LONG_TERM.some(value => value === Number(contractInfo?.loaiHopDong?.code))
    setIsHopDongDaiHan(checkHopDongDaiHan);

    setTemplateWord(matchTemplateWord(Number(contractInfo?.loaiHopDong?.code), contractWordExportList));
  }, [contractInfo]);

  useEffect(() => {
    let officerType = formik.values?.employee?.loaiCanBo;
    const conditionalCodes = [OFFICER_CODE.CONG_TAC_VIEN];
    if (officerType?.code) {
      let isCooperator = conditionalCodes.includes(Number(officerType?.code));
      setIsCollaborators(isCooperator);
      if (isCooperator) {
        if (!formik.values?.employee?.chucVu) {
          const getPosition = async () => {
            try {
              const { data } = await searchAllSimpleValue({ type: TYPE_CATEGORY.chucDanh });
              if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
                const positionCollaborator = data.data?.content?.find((position: any) => position?.mappingName === TYPE_POSITION.CONG_TAC_VIEN);
                formik.setFieldValue("chucVu", positionCollaborator);
              } else {
                toast.warning(data?.message);
              }
            } catch (error) {
              toast.error(lang("GENERAL.ERROR"));
            }
          }
          getPosition();
        }
      }
    }
  }, [formik.values?.employee])

  const getEmployeeInfo = async (id: string) => {
    try {
      const { data } = await getEmployeeProfileById(id);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        formik.setFieldValue(VARIABLE_STRING.EMPLOYEE, data?.data);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      console.error(error);
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const updateDataContractAnnex = async () => {
    let contractId = contractInfo?.id;
    if (!contractId) return;
    try {
      let { data } = await getListAnnexByContract(contractId);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setContractAnnexList(data?.data);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      console.error(error);
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const handleOpenDialog = (
    row: any,
    setShouldOpenDialog: (value: React.SetStateAction<boolean>) => void,
    setData: (value: any) => void
  ): void => {
    setShouldOpenDialog(true);
    setData(row);
  };

  const handleCloseDialog = (
    initData: any,
    setShouldOpenDialog: (value: React.SetStateAction<boolean>) => void,
    setData: (value: any) => void
  ): void => {
    setShouldOpenDialog(false);
    setData(initData);
  };

  const handleChangeEmployee = async (employee: any) => {
    let bacLuong = null;
    if (employee?.bacId && !isLumpSum) {
      const { data } = employee?.bacId && (await getBacLuongByChucDanh(employee?.viTriCongViecId));
      bacLuong =
        data?.code === RESPONSE_STATUS_CODE.SUCCESS
          ? data?.data?.find((item: any) => item?.id === employee?.bacId)
          : null;
    }

    const donViKyHopDong = () => {
      if (hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW)) {
        return employee?.donViCongTacId
          ? {
            id: employee?.donViCongTacId,
            name: employee?.donViCongTacText
          }
          : null;
      } else {
        return employee?.donViKyHopDongId
          ? {
            id: employee?.donViKyHopDongId,
            value: employee?.donViKyHopDongText
          }
          : null;
      }
    };

    formik.setFieldValue(VARIABLE_STRING.EMPLOYEE, employee);
    formik.setFieldValue(VARIABLE_STRING.HE_SO_LUONG, bacLuong?.heSoLuong);
    formik.setFieldValue(VARIABLE_STRING.DON_VI_KY_HOP_DONG, donViKyHopDong());
    formik.setFieldValue(VARIABLE_STRING.CHUC_DANH, employee?.viTriCongViecText
      ? {
        id: employee?.viTriCongViecId,
        value: employee?.viTriCongViecText
      }
      : null
    );
    formik.setFieldValue(VARIABLE_STRING.PHONG_BAN, employee?.phongBanText
      ? {
        id: employee?.phongBanId,
        name: employee?.phongBanText
      }
      : null
    );
    formik.setFieldValue(VARIABLE_STRING.CHUC_VU, employee?.chucVuText
      ? {
        id: employee?.chucVuId,
        value: employee?.chucVuText
      }
      : null
    );
    formik.setFieldValue(VARIABLE_STRING.BAC_LUONG_OPTION, employee?.bacLuong && !isLumpSum
      ? {
        bacLuong: employee?.bacLuong,
        heSoLuong: employee?.heSoLuong
      }
      : null
    );

    switchContractStatus(employee?.loaiCanBo, formik);
  };

  const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    switch (name) {
      case VARIABLE_STRING.VIEN_CHUC:
        formik.setFieldValue(name, checked);
        formik.setFieldValue(VARIABLE_STRING.HOP_DONG_LAO_DONG, false);
        break;
      case VARIABLE_STRING.HOP_DONG_LAO_DONG:
        formik.setFieldValue(name, checked);
        formik.setFieldValue(VARIABLE_STRING.VIEN_CHUC, false);
        break;
      default:
        formik.setFieldValue(name, checked);
        break;
    }
  };

  const handleDeleteItems = async (
    handleDelete: (...params: any) => Promise<AxiosResponse<APIResponse>>,
    handleUpdate: () => Promise<void>,
    ids: string
  ) => {
    try {
      const res = await handleDelete?.(ids);
      if (res?.data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("GENERAL.DELETE_SUCCESS"));
        updateDataContractAnnex();
        return true;
      } else toast.warning(`${res?.data?.message}`);
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    }
    await handleUpdate?.();
  };

  const handleChangeMoney = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, convertTextPrice(value));
  };

  const handleFile = (data: IFile) => {
    formik.setFieldValue("fileId", data.id);
    formik.setFieldValue("fileName", data.name);
  }

  const getListWorkUnit = async () => {
    try {
      let { data } = await searchListWorkUnit();
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListWorkUnit(data?.data?.content)
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }

  useEffect(() => {
    getListWorkUnit();
    updateDataContractAnnex();
    getPeopleDecision();
  }, [])

  useEffect(() => {
    if (listWorkUnit?.length > 0 && !formik?.values?.id) {
      formik.setFieldValue("donViKyHopDong", listWorkUnit[0])
    }
  }, [listWorkUnit, formik.values?.employee?.id])

  const getPeopleDecision = async () => {
    try {
      const { data } = await searchNguoiDaiDienKy();
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setPeopleDecisions(data?.data?.content || [])
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }

  useEffect(() => {
    if(!contractInfo?.id && peopleDecisions.length > 0) {
      const representativeDefault = peopleDecisions.find(people => people.isDefault) || null;
      formik.setValues({
        ...formik.values,
        nguoiDaiDienCtyKy: representativeDefault,
        nguoiDaiDienCtyKyId: representativeDefault?.id || "",
        nguoiDaiDienCtyKyText: representativeDefault?.name || "",
        nguoiDaiDienCtyChucDanhId: representativeDefault?.chucVuId || "",
        nguoiDaiDienCtyChucDanhText: representativeDefault?.chucVuText || ""
      });
    }
  }, [peopleDecisions]);
  
  const handleCloseAndSearchRepresentative = () => {
    getPeopleDecision();
    setIsShowDialogAddRepresentative(false);
  }

  return (
    <div className="h-full">
      <div className="header-form-action">
        <GroupButton
          type="btn-back"
          handleClose={handleClose}
        />
        <div className="flex flex-center">
          {view && (contractInfo?.id && contractInfo?.trangThaiHopDong?.code !== codeStatusContract.EXPIRE) 
            && hasAuthority(PERMISSIONS.EMPLOYEE, PERMISSION_ABILITY.UPDATE) && (
            <GroupButton
              type="btn-edit"
              handleSaveEdit={handleOpenUpdateDialog}
            />
          )}
          {!view && (
            <GroupButton
              type="btn-save"
              handleClose={handleCloseUpdateDialog}
              handleSubmit={formik.handleSubmit}
              value={formik.values}
            />
          )}
          {(contractInfo?.id && templateWord) && (
            <GroupButton handleEvent={() => setIsShowDialogWord(true)}>
              {lang("GENERAL.EXPORT_WORD")}
            </GroupButton>
          )}
        </div>
      </div>
      <div className="layout-v2 flex align-items-start">
        <div className="general-user-info flex-column align-items-start align-self-stretch">
          <EmployeeInfo
            isView={true}
            employeeProfiles={formik.values?.employee || null}
          />
        </div>
        <div className="form-content-scroll box-shadow-none pb-4">
          <div className="sub-title-form pt-3">
            {contractInfo?.id ? lang("CONTRACT.UPDATE") : lang("CONTRACT.ADD_NEW")}
          </div>
          <Form onSubmit={formik.handleSubmit} className="relative spaces z-index-2">
            <div className="sub-title p-0">{lang("CONTRACT.INFO")}</div>
            <Row>
              <Col xs={6} md={5} xl={5} xxl={3} className="spaces pt-6">
                <Autocomplete
                  isRequired
                  lable={lang("PROFILE.NAME")}
                  options={[]}
                  onChange={(value) => handleChangeEmployee(value)}
                  name="employee"
                  value={formik.values?.employee || null}
                  searchFunction={searchAllEmployee}
                  searchObject={{
                    trangThaiLamViec: Object.values(STATUS_NV).filter(status => status !== STATUS_NV.THOI_VIEC).join(',')
                  }}
                  isReadOnly={view || Boolean(formik?.values?.id) || hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW)}
                  errors={formik.errors.employee}
                  touched={formik.touched.employee}
                  getOptionLabel={(option) => `${option?.maNhanVien} - ${option?.name}`}
                />
              </Col>
              <Col xs={6} md={2} xl={2} xxl={2} className="spaces pt-6">
                <TextValidator
                  lable={lang("INPUT.CODE_NEW")}
                  name="maNhanVien"
                  value={formik.values?.employee?.maNhanVien || ""}
                  type="text"
                  readOnly={true}
                  onChange={formik.handleChange}
                />
              </Col>
              <Col xs={6} md={5} xl={5} xxl={4} className="spaces pt-6">
                <Autocomplete
                  isRequired
                  lable={lang("INPUT.DEPARTMENTS")}
                  isReadOnly={view}
                  options={[]}
                  value={formik.values?.phongBan || null}
                  name="phongBan"
                  onChange={(selectedOption) => handleChangeSelect("phongBan", selectedOption)}
                  urlData="data.data"
                  searchFunction={searchPhongBanByDonVi}
                  searchObject={{ id: formik.values?.employee?.donViCongTacId }}
                  touched={formik.touched?.phongBan}
                  errors={formik.errors?.phongBan}
                  dependencies={[formik.values?.employee?.donViCongTacId]}
                />
              </Col>
              <Col xs={6} xl={6} xxl={3} className="spaces pt-6">
                <Autocomplete
                  isRequired
                  lable={lang("GENERAL.EMPLOYEE.POSITION")}
                  name="chucVu"
                  value={formik.values?.chucVu || null}
                  searchFunction={searchAllSimpleValue}
                  searchObject={{ type: TYPE_CATEGORY.chucDanh }}
                  isReadOnly={view || isCollaborators}
                  onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.CHUC_VU, selectedOption)}
                  errors={formik.errors?.chucVu}
                  touched={formik.touched?.chucVu}
                  options={[]}
                  getOptionLabel={(option) => option?.value}
                />
              </Col>
              <Col xs={6} xl={6} xxl={5} className="spaces pt-6">
                <Autocomplete
                  lable={lang("GENERAL.EMPLOYEE.TITLE")}
                  name="chucDanh"
                  isRequired={!isCollaborators}
                  value={formik.values?.chucDanh || null}
                  searchFunction={searchAllSimpleValue}
                  searchObject={{ type: TYPE_CATEGORY.viTriCongViec }}
                  isReadOnly={view || isCollaborators}
                  onChange={(selectedOption) => handleChangeSelect("chucDanh", selectedOption)}
                  options={[]}
                  getOptionLabel={(option) => option?.value}
                  errors={formik.errors?.chucDanh}
                  touched={formik.touched?.chucDanh}
                />
              </Col>
              <Col xs={6} md={4} xl={3} xxl={2} className="spaces pt-6">
                <Form.Check
                  className="custom-form-check"
                  label={lang("CONTRACT.IS_JOBHOLDER")}
                  checked={formik?.values?.vienChuc}
                  onChange={handleChangeCheckBox}
                  name="vienChuc"
                  readOnly={true}
                  disabled={true}
                  type="radio"
                />
              </Col>
              <Col xs={6} md={4} xl={3} xxl={2} className="spaces pt-6">
                <Form.Check
                  className="custom-form-check"
                  label={lang("CONTARCT.IS_LABOR_CONTRACT")}
                  checked={formik?.values?.hopDongLaoDong}
                  onChange={handleChangeCheckBox}
                  name="hopDongLaoDong"
                  readOnly={true}
                  disabled={true}
                  type="radio"
                />
              </Col>
            </Row>
            <div className="sub-title">{lang("CONTRACT.INFO_DETAIL")}</div>
            <Row>
              <Col xs={6} md={4} xl={4} xxl={3} className="spaces pt-6">
                <Autocomplete
                  isRequired
                  lable={lang("CONTRACT.SIGNINGUNIT")}
                  options={listWorkUnit || []}
                  value={formik.values?.donViKyHopDong || null}
                  name="donViKyHopDong"
                  onChange={(value) => handleChangeSelect(VARIABLE_STRING.DON_VI_KY_HOP_DONG, value)}
                  touched={formik.touched?.donViKyHopDong}
                  errors={formik.errors?.donViKyHopDong}
                  isReadOnly={view || hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW)}
                />
              </Col>
              <Col xs={6} md={4} xl={3} xxl={3} className="spaces pt-6">
                <Autocomplete
                  isRequired
                  lable={lang("GENERAL.EMPLOYEE.CONTRACT_TYPE")}
                  options={formik.values?.vienChuc ? contractTypeBienChe : contractTypeHopDong}
                  onChange={(value) => handleChangeSelect(VARIABLE_STRING.LOAI_HOP_DONG, value)}
                  name="loaiHopDong"
                  value={formik.values?.loaiHopDong || null}
                  isReadOnly={view}
                  touched={formik.touched?.loaiHopDong}
                  errors={formik.errors?.loaiHopDong}
                  dependencies={[formik.values?.vienChuc, formik.values?.hopDongLaoDong]}
                />
              </Col>
              <Col xs={6} md={4} xl={2} xxl={2} className="spaces pt-6">
                <TextValidator
                  isRequired={formik.values.loaiHopDong?.code !== CODE_HOP_DONG.THU_VIEC}
                  lable={lang("CONTRACT.NUMBER")}
                  name="soHopDong"
                  value={formik.values?.soHopDong || ""}
                  type="text"
                  readOnly={view}
                  onChange={formik.handleChange}
                  touched={formik.touched?.soHopDong}
                  errors={formik.errors?.soHopDong}
                />
              </Col>
              <Col xs={6} md={4} xl={3} xxl={2} className="spaces pt-6">
                <TextValidator
                  lable={lang("CONTRACT.NAME")}
                  name="tenHopDong"
                  value={formik.values?.tenHopDong || ""}
                  type="text"
                  readOnly={view}
                  onChange={formik.handleChange}
                />
              </Col>
              <Col xs={6} md={4} xl={3} xxl={2} className="spaces pt-6">
                <Autocomplete
                  lable={lang("CONTRACT.WORK_SHIFT")}
                  options={workShifts}
                  onChange={(value) => handleChangeSelect(VARIABLE_STRING.CA_LAM_VIEC, value)}
                  name="caLamViec"
                  value={formik.values?.caLamViec || null}
                  isReadOnly={view}
                />
              </Col>
              <Col xs={6} md={4} xl={3} xxl={2} className="spaces pt-6">
                <TextValidator
                  lable={lang("CONTRACT.EFFECTIVEDATE")}
                  name="ngayCoHieuLuc"
                  value={formik.values?.ngayCoHieuLuc || ""}
                  type="date"
                  isRequired
                  readOnly={view}
                  onChange={handleChangeNgayCoHieuLuc}
                  onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.ngayCoHieuLuc, "ngayCoHieuLuc")}
                  errors={formik.errors?.ngayCoHieuLuc}
                  touched={formik.touched?.ngayCoHieuLuc}
                />
              </Col>
              <Col xs={6} md={4} xl={3} xxl={2} className="spaces pt-6">
                <TextValidator
                  lable={lang("CONTRACT.EXPIRATIONDATE")}
                  name="ngayHetHan"
                  value={formik.values?.ngayHetHan || ""}
                  type="date"
                  readOnly={view || isHopDongDaiHan}
                  isRequired={!isHopDongDaiHan}
                  onChange={formik.handleChange}
                  onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.ngayHetHan, "ngayHetHan")}
                  errors={formik.errors?.ngayHetHan}
                  touched={formik.touched?.ngayHetHan}
                />
              </Col>
              <Col xs={6} md={4} xl={3} xxl={2} className="spaces pt-6">
                <Autocomplete
                  lable={lang("CONTRACT.SALARY.LEVEL")}
                  name="bacLuongOption"
                  value={formik.values?.bacLuongOption || null}
                  searchFunction={getBacLuongByChucDanh}
                  searchObject={formik.values?.chucDanh?.id}
                  isRequired={!isCollaborators && !isLumpSum}
                  isReadOnly={view || isCollaborators || isLumpSum}
                  sort={(data: any) => data.sort((a: any, b: any) => a.bacLuong - b.bacLuong)}
                  urlData="data.data"
                  onChange={(selectedOption) => handleChangeSelect("bacLuongOption", selectedOption)}
                  options={[]}
                  getOptionLabel={(option) => option?.bacLuong}
                  dependencies={[formik.values?.chucDanh?.id]}
                  errors={formik.errors?.bacLuongOption}
                  touched={formik.touched?.bacLuongOption}
                />
              </Col>
              <Col xs={6} md={4} xl={3} xxl={2} className="spaces pt-6">
                <TextValidator
                  isRequired={!isCollaborators && !isLumpSum}
                  lable={lang("CONTRACT.SALARY.COEFFICIENT")}
                  name="heSoLuong"
                  value={formik.values?.heSoLuong || ""}
                  type="number"
                  readOnly={true}
                  onChange={formik.handleChange}
                  errors={formik.errors?.heSoLuong}
                  touched={formik.touched?.heSoLuong}
                />
              </Col>
              <Col xs={6} md={4} xl={3} xxl={2} className="spaces pt-6">
                <TextValidator
                  lable={lang("CONTRACT.BASESALARY")}
                  name="luongCoBan"
                  isRequired={isCollaborators || isLumpSum}
                  value={convertNumberPrice(formik.values?.luongCoBan)}
                  type="text"
                  readOnly={view}
                  onChange={handleChangeMoney}
                  errors={formik.errors?.luongCoBan}
                  touched={formik.touched?.luongCoBan}
                />
              </Col>
              <Col xs={6} md={4} xl={3} xxl={2} className="spaces pt-6">
                <TextValidator
                  lable={lang("CONTRACT.SALARY_PERCENT")}
                  name="tyLeHuongLuong"
                  value={formik.values?.tyLeHuongLuong || ""}
                  type="number"
                  readOnly={view}
                  onChange={formik.handleChange}
                />
              </Col>
              <Col xs={6} md={4} xl={3} xxl={2} className="spaces pt-6">
                <TextValidator
                  lable={lang("CONTRACT.INSURANCECONTRIBUTION")}
                  name="luongDongBaoHiem"
                  value={convertNumberPrice(formik.values?.luongDongBaoHiem)}
                  type="text"
                  readOnly={view}
                  onChange={handleChangeMoney}
                />
              </Col>
              <Col xs={6} md={4} xl={3} xxl={2} className="spaces pt-6">
                <Autocomplete
                  isRequired
                  lable={lang("CONTRACT.STATUS")}
                  options={contractStatus}
                  onChange={(value) => handleChangeSelect(VARIABLE_STRING.TRANG_THAI_HOP_DONG, value)}
                  name="trangThaiHopDong"
                  value={formik.values?.trangThaiHopDong || null}
                  isReadOnly={view}
                  errors={formik.errors?.trangThaiHopDong}
                  touched={formik.touched?.trangThaiHopDong}
                />
              </Col>
              <Col xs={6} md={4} xl={6} xxl={6} className="spaces pt-6">
                <TextValidator
                  lable={lang("CONTRACT.NOTE")}
                  name="ghiChu"
                  value={formik.values?.ghiChu}
                  type="text"
                  readOnly={view}
                  onChange={formik.handleChange}
                />
              </Col>
              <Col xs={6} md={4} xl={3} xxl={2} className="spaces pt-6">
                <UploadFile
                  label="CONTRACT.ATTACHMENTS"
                  setValue={handleFile}
                  isReadOnly={view}
                  fileValue={{
                    id: formik.values.fileId || "",
                    name: formik.values.fileName || ""
                  }}
                />
              </Col>
            </Row>
            <div className="sub-title">{lang("CONTRACT.SALARY_SIGN")}</div>
            <Row>
              <Col xs={6} md={4} xl={3} xxl={2} className="spaces pt-6">
                <Form.Check
                  label={lang("CONTRACT.PAPER_AUTHORITY")}
                  checked={formik.values?.giayUyQuyen}
                  onChange={handleChangeCheckBox}
                  className="custom-form-check"
                  readOnly={view}
                  disabled={view}
                  name="giayUyQuyen"
                />
              </Col>
              <Col xs={6} md={4} xl={3} xxl={3} className="spaces pt-6">
                <Autocomplete
                  isRequired
                  lable={lang("CONTRACT.SIGNEDCOMPANYREPRESENTATIVE")}
                  options={peopleDecisions}
                  value={formik.values?.nguoiDaiDienCtyKy || null}
                  name="nguoiDaiDienCtyKy"
                  onChange={(value) => handleChangeSelect(VARIABLE_STRING.NGUOI_DAI_DIEN_KY, value)}
                  isAddNew
                  onAddNew={() => setIsShowDialogAddRepresentative(true)}
                  isReadOnly={view}
                  errors={formik.errors?.nguoiDaiDienCtyKy}
                  touched={formik.touched?.nguoiDaiDienCtyKy}
                />
              </Col>
              <Col xs={6} md={4} xl={3} xxl={3} className="spaces pt-6">
                <TextValidator
                  lable={lang("CONTRACT.REPRESENTATIVETITLE")}
                  name="nguoiDaiDienCtyChucDanhText"
                  value={formik.values?.nguoiDaiDienCtyChucDanhText || ""}
                  type="text"
                  readOnly={true}
                />
              </Col>
              <Col xs={6} md={4} xl={3} xxl={2} className="spaces pt-6">
                <TextValidator
                  lable={lang("ANNEX.SIGNINGDATE")}
                  name="ngayKyHopDong"
                  value={formik.values?.ngayKyHopDong || ""}
                  type="date"
                  readOnly={view}
                  onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.ngayKyHopDong, "ngayKyHopDong")}
                  onChange={formik.handleChange}
                  errors={formik.errors?.ngayKyHopDong}
                  touched={formik.touched?.ngayKyHopDong}
                />
              </Col>
              <Col xs={6} md={4} xl={3} xxl={2} className="spaces pt-6">
                <Autocomplete
                  lable={lang("CONTRACT.SIGNINGSTATUS")}
                  options={signinStatus}
                  value={formik.values?.trangThaiKy || null}
                  name="trangThaiKy"
                  onChange={(value) => handleChangeSelect(VARIABLE_STRING.TRANG_THAI_KY, value)}
                  isReadOnly={view}
                />
              </Col>
            </Row>
          </Form>
          {contractInfo?.id && (
            <>
              <TableCustom
                id="contractAnnex"
                title={lang("CONTRACT.ANNEX")}
                className="pt-4"
                data={contractAnnexList}
                columns={ContractAnnexColumn()}
                handleDelete={(ids: string) =>
                  handleDeleteItems(deleteMultipleContractAnnex, updateDataContractAnnex, ids)
                }
                isActionTableTab
                buttonAdd={!view && formik.values?.trangThaiHopDong?.code !== codeStatusContract.EXPIRE}
                buttonExportExcel={true}
                type={TYPE.MULTILINE}
                fixedColumnsCount={3}
                noToolbar={true}
                noPagination={true}
                handleExportExcel={() => exportToFile({
                  exportAPI: () => exportHopDongPhuLuc(contractInfo.id),
                  fileName: EXPORT_FILE_NAME.PHU_LUC_HD,
                  setPageLoading
                })}
                handleOpenDialog={(row) => handleOpenDialog(annexInfo, setShouldOpenAddContractAnnex, setAnnexInfo)}
                handleDoubleClick={(row: any) => handleOpenDialog(row, setShouldOpenAddContractAnnex, setAnnexInfo)}
              />
              {/* Tạm ẩn phụ cấp trong hợp đồng */}
              {/* <div className="spaces mt-16">
                <Allowance
                  isView={view}
                  identify={contractInfo?.employeeId}
                  isCallApi={true}
                  isDisplayBtnAdd={formik.values?.trangThaiHopDong?.code !== codeStatusContract.EXPIRE}
                />
              </div> */}
            </>
          )}
          {shouldOpenAddContractAnnex && (
            <AddNewContractAnnex
              annexInfo={annexInfo}
              contractInfo={contractInfo}
              getListAnnex={updateDataContractAnnex}
              handleCloseAddContractAnnex={() =>
                handleCloseDialog(INIT_CONTRACT_ANNEX_INFO, setShouldOpenAddContractAnnex, setAnnexInfo)
              }
              isView={view}
              isCollaborators={isCollaborators}
            />
          )}

          {isShowDialogWord && (
            <ExportWord
              open={isShowDialogWord}
              handleClose={() => setIsShowDialogWord(false)}
              templateList={templateWord || []}
              getObj={{
                employeeId: contractInfo?.employeeId,
                hopDongId: contractInfo?.id,
                hopDongType: contractInfo?.loaiHopDong?.code,
                type: DATA_WORD_TYPES.CONSTRACT
              }}
              customFileName={(data, nameBase) => `${data.fullName} (${nameBase})`}
            />
          )}
          {isShowDialogAddRepresentative && (
            <AddNewRepresentative
              handleClose={() => setIsShowDialogAddRepresentative(false)}
              handleCloseAndSearch={handleCloseAndSearchRepresentative}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export { AddNewContract };

import { KTSVG } from '../../../../_metronic/helpers';
import { Col, Row } from 'react-bootstrap';
import { GroupButton } from '../GroupButton';
import useMultiLanguage from '../../../hook/useMultiLanguage';


function ActionTableTab(props: any) {
    const { lang } = useMultiLanguage();

    const {
        title,
        buttonAdd,
        buttonExportExcel,
        handleOpenDialog,
        handleExport,
        selectedRows,
        handleCheckBoxAll,
        notDelete,
        handleShowConfirmDialog
    } = props;
    const hasSelectedRow = selectedRows?.length > 0;
    return (
        <Row className="flex-middle">
            <Col sm={4} xl={6} xxl={hasSelectedRow ? 7 : 9} className="spaces fs-18 text-header-table fw-600 title-action-tab">{title}</Col>
            <Col sm={8} xl={6} xxl={hasSelectedRow ? 5 : 3} className="d-flex align-items-center flex-end">
                {hasSelectedRow && (
                    <>
                        <span className="spaces mr-8">
                            {lang("SELECTED")}:
                            <strong className="ps-2">
                                {selectedRows ? selectedRows?.length : 0}
                            </strong>
                        </span>
                        <span
                            className="fw-bold text-warning cursor-pointer "
                            onClick={() => handleCheckBoxAll(false)}
                        >
                            {lang("UNSELECTED")}
                        </span>
                        {!notDelete &&
                            <div
                                className="delete-box cursor-pointer spaces mr-8"
                                onClick={handleShowConfirmDialog}
                            >
                                <i className="bi bi-trash fs-4 text-danger px-4"></i>
                                <span className="fw-bold text-danger">
                                    {lang("DELETE")}
                                </span>
                            </div>
                        }
                    </>
                )}
                {buttonAdd &&
                    <GroupButton className="spaces ml-8" handleEvent={handleOpenDialog}>
                        <i className="spaces bi bi-plus fs-20 white pr-0"></i>
                        <p className="spaces fs-14 m-0 ">{lang("BTN.ADD")}</p>
                    </GroupButton>
                }

                {buttonExportExcel &&
                    <GroupButton className="spaces ml-8" outline handleEvent={handleExport}>
                        <KTSVG path="/media/icons/export-excel.svg" />{" "}
                        {lang("BTN.EXPORT")}
                    </GroupButton>
                }
            </Col>

        </Row>
    )
}

export default ActionTableTab
import { ISimpleValue } from "../../models/models";
import {generateRandomId} from "../../utils/functionUtils";
import { DECLARE_ASSETS_TYPES_CODES } from "../constants/declareAssetsConsts";
import { IDeclareAssets, IFluctuation } from "../models/declareAssetsModels";

export const convertDataFluctuation = (data: ISimpleValue[]): IFluctuation[] => {
  let dataConverted: IFluctuation[] = [];
  data.forEach((item) => {
    dataConverted.push({
      order: item?.mappingName ? Number(item.mappingName) : 0,
      taiSanId: generateRandomId(),
      nhomTaiSan: item.attributeName,
      name: item.value,
      tangGiam: null,
      soLuong: 0,
      noiDung: "",
      giaTri: 0
    });
  });

  return dataConverted.sort((a, b) => Number(a.order) - Number(b.order));
};

const clearId = (data: any[] = [], fieldName: string = "taiSanId") => {
  if (data?.length > 0) {
    return data.map((item) => {
      let { [fieldName]: removedField , ...itemFilter } = item;
      return itemFilter;
    });
  }

  return [];
};

export const convertDataSubmit = (data: IDeclareAssets, fluctuation: IFluctuation[]) => {
  let firstCheck = data?.loaiKeKhai?.code === DECLARE_ASSETS_TYPES_CODES.LAN_DAU ? [] : clearId(fluctuation);
  let addinalCheck =
    data.loaiKeKhai?.code === DECLARE_ASSETS_TYPES_CODES.BO_SUNG
      ? {
        quyenSuDungDat: [],
        coPhieu: [],
        congTrinhXayDung: [],
        daQuy: [],
        khac: [],
        taiSanTrenDat: [],
        tien: [],
        taiKhoanNganHang: []
      }
      : {
        quyenSuDungDat: clearId(data.quyenSuDungDat),
        coPhieu: clearId(data.coPhieu),
        congTrinhXayDung: clearId(data.congTrinhXayDung),
        daQuy: clearId(data.daQuy),
        khac: clearId(data.khac),
        taiSanTrenDat: clearId(data.taiSanTrenDat),
        tien: clearId(data.tien),
        taiKhoanNganHang: clearId(data.taiKhoanNganHang)
      };

  return {
    ...data,
    ...addinalCheck,
    conCai: clearId(data.conCai),
    bienDong: firstCheck
  };
};

export const convertDataUI = (data: IDeclareAssets) => {
  return {
    ...data,
    employee: {
      name: data.tenNhanVien || "",
      donViCongTacText: data.donViCongTacText || "",
      donViCongTacId: data.donViCongTacId || "",
      viTriCongViecId: data.viTriCongViecId || "",
      viTriCongViecText: data.viTriCongViecText || "",
      chucVuId: data.chucVuId || "",
      chucVuText: data.chucVuText || "",
      birthDate: data.birthdate || "",
      soCMNDOrCCCD: data.soCmndCccd || "",
      hkDiachi: data.diaChiThuongChu || "",
      ngayCapCMNDOrCCCD: data.soCmndCccdNgayCap || "",
      noiCapCMNDOrCCCD: data.soCmndCccdNoiCap || "",
      maNhanVien: data?.maNhanVien || ""
    }
  };
};

import { useEffect, useState } from "react";
import { REF_TAB } from "../constants/profileConsts";
import BonusTable from "./partials/bonus/BonusTable";
import DisciplineTable from "./partials/discipline/DisciplineTable";
import { hasAuthority } from "../../utils/functionUtils";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../../constants";

interface Iprops {
  employeeProfiles: any;
  activeTab: string;
  isView: boolean;
}

function BonusAndDisciplineInformation(props: Iprops) {
  const { employeeProfiles, activeTab, isView } = props;
  const [isCallApi, setIsCallApi] = useState(false);

  useEffect(() => {
    setIsCallApi(employeeProfiles?.id && activeTab === REF_TAB.KHEN_THUONG_KY_LUAT);
  }, [employeeProfiles, activeTab]);

  return (
    <div className="form-info">
      <div className="block-content">
        {hasAuthority(PERMISSIONS.KHEN_THUONG, PERMISSION_ABILITY.VIEW) &&
          <BonusTable
            isView={isView}
            isCallApi={isCallApi}
            employeeProfiles={employeeProfiles}
          />
        }
        {hasAuthority(PERMISSIONS.KY_LUAT, PERMISSION_ABILITY.VIEW) && 
          <DisciplineTable
            isCallApi={isCallApi}
            employeeProfiles={employeeProfiles}
          />
        }
      </div>
    </div>
  );
}

export default BonusAndDisciplineInformation;

import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import * as Yup from "yup";
import useMultiLanguage from '../../../../hook/useMultiLanguage';
import AppContext from '../../../../AppContext';
import { FORMAT_DATE, LIST_MONTH, RESPONSE_STATUS_CODE, VARIABLE_STRING, WEEKDAY } from '../../../constants/moduleConsts';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import TextValidator from '../../../components/input/text-validator';
import { Col, Form, Row } from 'react-bootstrap';
import { GroupButton } from '../../../components/GroupButton';
import Autocomplete from '../../../components/input/autocomplete';
import { findList, getFullYear, handleBlurDate, hasAuthority } from '../../../utils/functionUtils';
import { PERMISSION_ABILITY, PERMISSIONS } from '../../../../constants';
import { IOfficeWorkDay, IOfficeWorkSchedule, IPropsOfficeWorkScheduleForm } from '../../models/officeWorkScheduleModels';
import { addOfficeWorkSchedule, updateOfficeWorkSchedule } from '../../services/officeWorkScheduleServices';
import { INIT_OFFICE_WORK_SCHEDULE } from '../../constants/officeWorkScheduleConsts';
import TableCustom, { columnNamesType } from '../../../components/table-custom/TableCustom';
import { convertDataSubmit, convertDataUI, convertDetailSchedule, initListWorkday } from '../../utils/officeWorkScheduleUtils';
import moment from 'moment';
import { searchListWorkUnit, searchPhongBanByDonVi } from '../../../services/services';
import { OptionReactSelect } from '../../../models/models';
import { CODE_STATUS, LIST_STATUS } from '../../../manager-request/constants/managerRequestConsts';
import { ConfirmDialog } from '../../../components/dialogs';

const OfficeWorkScheduleForm: FC<IPropsOfficeWorkScheduleForm> = (props) => {
  const {
    handleClose,
    officeWorkInfo,
    handleCloseUpdateDialog,
    handleCloseAndSearch,
    isView,
    setIsView
  } = props;

  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);
  const [listWorkday, setListWorkday] = useState<IOfficeWorkDay[]>(initListWorkday(new Date().getFullYear(), new Date().getMonth() + 2));
  const [listOrganization, setListOrganization] = useState<OptionReactSelect[]>([]);
  const [shouldOpenApproveDialog, setShouldOpenApproveDialog] = useState<boolean>(false);

  useEffect(() => {
    if(isView && officeWorkInfo?.id) {
      formik.setValues(convertDataUI(officeWorkInfo));
      setListWorkday(convertDetailSchedule(officeWorkInfo?.detailSchedule || []));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [officeWorkInfo, isView]);

  const getDonViCongTac = async () => {
    try {
      const { data } = await searchListWorkUnit();
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListOrganization(data?.data?.content || []);
        formik.setFieldValue("donViCongTac", data?.data?.content?.[0] || null)
        return;
      }
      toast.warning(data?.message);
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }

  useEffect(() => {
    !officeWorkInfo?.id && getDonViCongTac();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const validationSchema = Yup.object().shape({
    donViCongTac: !hasAuthority(PERMISSIONS.DEPARTMENT, PERMISSION_ABILITY.VIEW) 
      ? Yup.object().required(lang("VALIDATION.REQUIRE")).nullable()
      : Yup.object().nullable(),
    phongBan: !hasAuthority(PERMISSIONS.DEPARTMENT, PERMISSION_ABILITY.VIEW) 
      ? Yup.object().required(lang("VALIDATION.REQUIRE")).nullable()
      : Yup.object().nullable(),
    year: Yup.number().required(lang("VALIDATION.REQUIRE")).nullable(),
    month: Yup.number().required(lang("VALIDATION.REQUIRE")).nullable(),
  });

  const handleSubmit = async (values: IOfficeWorkSchedule) => {
    try {
      setPageLoading(true);
      const _values = convertDataSubmit(values, listWorkday);
      const { data } = officeWorkInfo?.id
      ? await updateOfficeWorkSchedule(officeWorkInfo.id, _values)
      : await addOfficeWorkSchedule(_values);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(
        officeWorkInfo.id
          ? lang("TOAST.EDIT.SUCCESS")
          : lang("TOAST.CREATE.SUCCESS")
      );
        handleCloseAndSearch();
      } else {
        toast.warning(data?.message);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false)
    }
  };

  const formik = useFormik({
    initialValues: INIT_OFFICE_WORK_SCHEDULE,
    validationSchema,
    onSubmit: handleSubmit
  });

  const handleChangeSelect = (name: string, value: any) => {
    switch (name) {
      case VARIABLE_STRING.YEAR:
        formik.setFieldValue(name, value);
        if(value && formik.values.month) {
          setListWorkday(initListWorkday(value, formik.values.month));
        }
        break;
      case VARIABLE_STRING.MONTH:
        formik.setFieldValue(name, value);
        if(value && formik.values.year) {
          setListWorkday(initListWorkday(formik.values.year, value));
        }
        break;
      default:
        formik.setFieldValue(name, value);
    }
  };

  const handleCheckBoxChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, index: number, key: number) => {
    const _listWorkday = [...listWorkday];
    _listWorkday[index][key] = {
      ..._listWorkday[index][key],
      isWorked: e.target.checked
    }
    setListWorkday(_listWorkday);
  }, [listWorkday]);

  const handleApprove = async () => {
    setShouldOpenApproveDialog(true)
  }

  const handleCloseForm = () => {
    setShouldOpenApproveDialog(false);
  }

  const handleConfirm = async () => {
    try {
      setPageLoading(true);
      let _values = {
        ...formik.values,
        status: findList(LIST_STATUS, CODE_STATUS.DA_DUYET)
      }

      const { data } = await updateOfficeWorkSchedule(officeWorkInfo?.id ?? "", convertDataSubmit(_values, []));

      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success("Duyệt lịch thành công");
        handleCloseAndSearch();
        handleCloseForm();
        return;
      }
      toast.warn(data?.message);
    } catch {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  }

  const columns: columnNamesType[] = useMemo(() => [
    {
      name: lang("WEEKDAY.MONDAY"),
      field: String(WEEKDAY.MONDAY),
      render: (row, index) => (
        row?.[String(WEEKDAY.MONDAY)] ? (
          <div className="flex gap-3 justify-content-center flex-middle">
            <span className="spaces fs-14 w-20 text-center">
              {row?.[String(WEEKDAY.MONDAY)]?.date ? moment(row?.[String(WEEKDAY.MONDAY)]?.date).format(FORMAT_DATE.DD) : ""} 
            </span>
            <Form.Check
              disabled={isView || row?.[String(WEEKDAY.MONDAY)]?.isRequire}
              className="checkBox"
              checked={row?.[String(WEEKDAY.MONDAY)]?.isWorked ?? false}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheckBoxChange(e, index, WEEKDAY.MONDAY)}
            />
          </div>
        ) : <></>
      )
    },
    {
      name: lang("WEEKDAY.TUESDAY"),
      field: String(WEEKDAY.TUESDAY),
      render: (row, index) => (
        row?.[String(WEEKDAY.TUESDAY)] ? (
          <div className="flex gap-3 justify-content-center flex-middle">
            <span className="spaces fs-14 w-20 text-center">
              {row?.[String(WEEKDAY.TUESDAY)]?.date ? moment(row?.[String(WEEKDAY.TUESDAY)]?.date).format(FORMAT_DATE.DD) : ""}
            </span>
            <Form.Check
              disabled={isView || row?.[String(WEEKDAY.TUESDAY)]?.isRequire}
              className="checkBox"
              checked={row?.[String(WEEKDAY.TUESDAY)]?.isWorked ?? false}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheckBoxChange(e, index, WEEKDAY.TUESDAY)}
            />
          </div>
        ) : <></>
      )
    },
    {
      name: lang("WEEKDAY.WEDNESDAY"),
      field: String(WEEKDAY.WEDNESDAY),
      render: (row, index) => (
        row?.[String(WEEKDAY.WEDNESDAY)] ? (
          <div className="flex gap-3 justify-content-center flex-middle">
            <span className="spaces fs-14 w-20 text-center">
              {row?.[String(WEEKDAY.WEDNESDAY)]?.date ? moment(row?.[String(WEEKDAY.WEDNESDAY)]?.date).format(FORMAT_DATE.DD) : ""}
            </span>
            <Form.Check 
              disabled={isView || row?.[String(WEEKDAY.WEDNESDAY)]?.isRequire}
              className="checkBox"
              checked={row?.[String(WEEKDAY.WEDNESDAY)]?.isWorked ?? false}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheckBoxChange(e, index, WEEKDAY.WEDNESDAY)}
            />
          </div>
        ) : <></>
      )
    },
    {
      name: lang("WEEKDAY.THURSDAY"),
      field: String(WEEKDAY.THURSDAY),
      render: (row, index) => (
        row?.[String(WEEKDAY.THURSDAY)] ? (
          <div className="flex gap-3 justify-content-center flex-middle">
            <span className="spaces fs-14 w-20 text-center">
              {row?.[String(WEEKDAY.THURSDAY)]?.date ? moment(row?.[String(WEEKDAY.THURSDAY)]?.date).format(FORMAT_DATE.DD) : ""}
            </span>
            <Form.Check
              disabled={isView || row?.[String(WEEKDAY.THURSDAY)]?.isRequire}
              className="checkBox"
              checked={row?.[String(WEEKDAY.THURSDAY)]?.isWorked ?? false}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheckBoxChange(e, index, WEEKDAY.THURSDAY)}
            />
          </div>
        ) : <></>
      )
    },
    {
      name: lang("WEEKDAY.FRIDAY"),
      field: String(WEEKDAY.FRIDAY),
      render: (row, index) => (
        row?.[String(WEEKDAY.FRIDAY)] ? (
          <div className="flex gap-3 justify-content-center flex-middle">
            <span className="spaces fs-14 w-20 text-center">
              {row?.[String(WEEKDAY.FRIDAY)]?.date ? moment(row?.[String(WEEKDAY.FRIDAY)]?.date).format(FORMAT_DATE.DD) : ""}
            </span>
            <Form.Check
              disabled={isView || row?.[String(WEEKDAY.FRIDAY)]?.isRequire}
              className="checkBox"
              checked={row?.[String(WEEKDAY.FRIDAY)]?.isWorked ?? false}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheckBoxChange(e, index, WEEKDAY.FRIDAY)}
            />
          </div>
        ) : <></>
      )
    },
    {
      name: lang("WEEKDAY.SATURDAY"),
      field: String(WEEKDAY.SATURDAY),
      render: (row, index) => (
        row?.[String(WEEKDAY.SATURDAY)] ? (
          <div className="flex gap-3 justify-content-center flex-middle">
            <span className="spaces fs-14 w-20 text-center">
              {row?.[String(WEEKDAY.SATURDAY)]?.date ? moment(row?.[String(WEEKDAY.SATURDAY)]?.date).format(FORMAT_DATE.DD) : ""}
            </span>
            <Form.Check
              disabled={isView || row?.[String(WEEKDAY.SATURDAY)]?.isRequire}
              className="checkBox"
              checked={row?.[String(WEEKDAY.SATURDAY)]?.isWorked ?? false}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheckBoxChange(e, index, WEEKDAY.SATURDAY)}
            />
          </div>
        ) : <></>
      )
    },
    {
      name: lang("WEEKDAY.SUNDAY"),
      field: String(WEEKDAY.SUNDAY),
      render: (row, index) => (
        row?.[String(WEEKDAY.SUNDAY)] ? (
          <div className="flex gap-3 justify-content-center flex-middle">
            <span className="spaces fs-14 w-20 text-center">
              {row?.[String(WEEKDAY.SUNDAY)]?.date ? moment(row?.[String(WEEKDAY.SUNDAY)]?.date).format(FORMAT_DATE.DD) : ""}
            </span>
            <Form.Check
              disabled={isView || row?.[String(WEEKDAY.SUNDAY)]?.isRequire}
              className="checkBox"
              checked={row?.[String(WEEKDAY.SUNDAY)]?.isWorked ?? false}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheckBoxChange(e, index, WEEKDAY.SUNDAY)}
            />
          </div>
        ) : <></>
      )
    },
  ], [handleCheckBoxChange, isView, lang]);

  return (
    <div className="h-full">
      <Form onSubmit={formik.handleSubmit}>
        <div className="header-form-action">
          <GroupButton type="btn-back" handleClose={handleClose} />
          <div className="flex gap-4">
            {(!isView && officeWorkInfo?.id) && (
              <GroupButton
                type="btn-cancel"
                handleCloseUpdateDialog={handleCloseUpdateDialog}
              />
            )}
            {isView ? (hasAuthority(PERMISSIONS.LICH_HANH_CHINH, PERMISSION_ABILITY.UPDATE) 
              && officeWorkInfo?.status?.code !== CODE_STATUS.DA_DUYET &&
              <GroupButton type="btn-edit" handleSaveEdit={() => setIsView(false)} />
            ) : (
              <GroupButton type="btn-save" />
            )}
            {officeWorkInfo?.id && isView && officeWorkInfo?.status?.code !== CODE_STATUS.DA_DUYET 
              && hasAuthority(PERMISSIONS.LICH_HANH_CHINH, PERMISSION_ABILITY.APPROVE) && (
              <GroupButton handleEvent={handleApprove}>{lang("BTN.APPROVE")}</GroupButton>
            )}
          </div>
        </div>
        <div className="form-content-scroll">
          <div className="sub-title-form">{officeWorkInfo?.id ? lang("TIMEKEEPING.SCHEDULE.OFFICE_WORK.EDIT") : lang("TIMEKEEPING.SCHEDULE.OFFICE_WORK.ADD")}</div>
          <div className="sub-title pb-2 pt-0">{lang("GENERAL.INFO")}</div>
          <Row className="g-4 spaces relative z-index-2">
            {!hasAuthority(PERMISSIONS.DEPARTMENT, PERMISSION_ABILITY.VIEW) && (
              <>
                <Col xs={12} sm={6} md={5} lg={4} xl={3} xxl={3}>
                  <Autocomplete
                    isRequired
                    lable={lang("GENERAL.CURRENT_ORGANIZATION")}
                    options={listOrganization}
                    value={formik.values?.donViCongTac || null}
                    name={VARIABLE_STRING.DON_VI_CONG_TAC}
                    onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.DON_VI_CONG_TAC, selectedOption)}
                    isReadOnly={isView}
                    touched={formik.touched?.donViCongTac}
                    errors={formik.errors?.donViCongTac}
                  />
                </Col>
                <Col xs={12} sm={6} md={5} lg={4} xl={3} xxl={3}>
                  <Autocomplete
                    isRequired
                    isReadOnly={isView}
                    lable={lang("INPUT.DEPARTMENTS")}
                    options={[]}
                    value={formik.values?.phongBan || null}
                    name={VARIABLE_STRING.PHONG_BAN}
                    onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.PHONG_BAN, selectedOption)}
                    urlData="data.data"
                    searchFunction={searchPhongBanByDonVi}
                    searchObject={{ id: formik.values?.donViCongTac?.id }}
                    touched={formik.touched?.phongBan}
                    errors={formik.errors?.phongBan}
                    dependencies={[formik.values?.donViCongTac]}
                  />
                </Col>
              </>
            )}
            <Col xs={6} sm={4} md={2} lg={2} xl={2}>
              <Autocomplete
                isRequired
                isReadOnly={isView}
                lable={lang("GENERAL.YEAR")}
                options={getFullYear(0)}
                value={String(formik.values?.year) || null}
                name={VARIABLE_STRING.YEAR}
                valueSearch="name"
                onChange={(value) => handleChangeSelect(VARIABLE_STRING.YEAR, value?.name)}
                touched={formik.touched?.year}
                errors={formik.errors?.year}
              />
            </Col>
            <Col xs={6} sm={4} md={hasAuthority(PERMISSIONS.DEPARTMENT, PERMISSION_ABILITY.VIEW) ? 3 : 2} lg={2} xl={2}>
              <Autocomplete
                isRequired
                isReadOnly={isView}
                lable={lang("GENERAL.MONTH")}
                options={LIST_MONTH}
                value={formik.values?.month || null}
                name={VARIABLE_STRING.MONTH}
                valueSearch="code"
                onChange={(value) => handleChangeSelect(VARIABLE_STRING.MONTH, value?.code)}
                touched={formik.touched?.month}
                errors={formik.errors?.month}
              />
            </Col>
            <Col sm={4} md={3} lg={2} xl={2}>
              <TextValidator
                name="approvalDate"
                lable={lang("DATE.APPROVE")}
                type="date"
                value={formik.values?.approvalDate}
                readOnly={true}
                onChange={formik.handleChange}
                onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.approvalDate, "approvalDate")}
                errors={formik?.errors?.approvalDate}
                touched={formik?.touched?.approvalDate}
              />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3} xxl={3}>
              <Autocomplete
                lable={lang("WELFARE.ACTUAL_COST.APPROVED_PEOPLE")}
                isReadOnly={true}
                options={[]}
                value={formik.values?.approver || null}
                name="approver"
                onChange={(selectedOption) => handleChangeSelect("approver", selectedOption)}
                errors={formik.errors?.approver}
                touched={formik.touched?.approver}
              />
            </Col>
            <Col sm={6} md={3} lg={2} xl={2} xxl={2}>
              <Autocomplete
                isRequired
                lable={lang("GENERAL.STATUS")}
                isReadOnly={true}
                options={LIST_STATUS}
                value={formik.values?.status || null}
                name="status"
                onChange={(value) => handleChangeSelect("status", value)}
                errors={formik.errors?.status}
                touched={formik.touched?.status}
              />
            </Col>
          </Row>

          <div className="sub-title pt-0 mt-4">{lang("TIMEKEEPING.SCHEDULE.OFFICE_WORK")}</div>
          <div className="mt-2">
            <TableCustom
              columns={columns}
              data={listWorkday || []}
              noPagination
              justFilter
              clearToolbar
              />
          </div>
        </div>
      </Form>
      {shouldOpenApproveDialog &&
        <ConfirmDialog
          show={true}
          title={lang("DIALOG.CONFIRM")}
          message="Bạn có chắc chắn muốn duyệt lịch này?"
          cancel={lang("BTN.CLOSE")}
          onCancelClick={handleCloseForm}
          yes={lang("BTN.CONFIRM")}
          onYesClick={handleConfirm}
        />
      }
    </div>
  )
}

export default OfficeWorkScheduleForm;

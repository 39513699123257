import { paramsConfig } from "../../utils/paramsUtils";
import axios from "axios";
import { IAttendanceWatch } from "../models/attendanceWatchModes";
import { IRegisterOvertime } from "../models/registerOvertimeModes";
import { IChangeCompensated } from "../models/changeCompensatedModes";

const API_URL = process.env.REACT_APP_API_URL_CHAM_CONG;

export const searchManagerRequest = (searchData: any) => {
  let url = `${API_URL}/don-tu/page`;
  return axios.get(url, paramsConfig(searchData))
};

export const getByIdManagerRequest = (id: string | undefined, searchData: any) => {
  let url = `${API_URL}/don-tu/${id}`;
  return axios.get(url, paramsConfig(searchData))
};

export const addManagerRequest = (data: IAttendanceWatch | IRegisterOvertime | IChangeCompensated) => {
  let url = `${API_URL}/don-tu?loaiDon=${data?.loaiDon?.type}`;
  return axios.post(url, data);
};

export const updateManagerRequest = (id: string | undefined, data: IAttendanceWatch | IRegisterOvertime | IChangeCompensated) => {
  let url = `${API_URL}/don-tu/${id}?loaiDon=${data?.loaiDon?.type}`;
  return axios.put(url, data);
};

export const deleteManagerRequest = (searchData: any) => {
	let url = `${API_URL}/don-tu/ids`;
	return axios.delete(url, paramsConfig(searchData));
}

export const approveManagerRequest = (id: string, data: any, searchObject: any) => {
  let url = `${API_URL}/don-tu/approve/${id}`;
  return axios.put(url, data, paramsConfig(searchObject));
}

// Đơn nghỉ phép
export const getLeaveDayByEmployee = (searchData: any) => {
  let url = `${API_URL}/attendance-infos/leave-day`;
  return axios.get(url, paramsConfig(searchData));
}
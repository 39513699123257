import { FC, useCallback, useContext, useEffect, useState } from 'react'
import useMultiLanguage from '../../../../hook/useMultiLanguage';
import * as Yup from "yup";
import AppContext from '../../../../AppContext';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';
import { GroupButton } from '../../../components/GroupButton';
import TextValidator from '../../../components/input/text-validator';
import Autocomplete from '../../../components/input/autocomplete';
import { searchAllEmployee, searchNguoiDaiDienKy } from '../../../services/services';
import { RESPONSE_STATUS_CODE, SEARCH_OBJECT_MAX_SIZE, VARIABLE_STRING } from '../../../constants/moduleConsts';
import { ConfirmDialog } from '../../../components/dialogs';
import { findList, hasAuthority } from '../../../utils/functionUtils';
import { RefuseDialog } from '../../../components/dialogs/RefuseDialog';
import { convertDataChangeCompensatedDto, convertDataChangeCompensatedUI } from '../../utils/functionUtils';
import { CODE_STATUS, LIST_STATUS } from '../../constants/managerRequestConsts';
import { INIT_CHANGE_COMPENSATED, LIST_TYPE_CHANGE_SHIFT, TYPE_CHANGE_SHIFT } from '../../constants/changeCompensatedConsts';
import { IChangeCompensated } from '../../models/changeCompensatedModes';
import { IShift } from '../../../shift/models/shiftModels';
import { searchShift } from '../../../shift/services/shiftServices';
import { PERMISSION_ABILITY, PERMISSIONS } from '../../../../constants';
import { addManagerRequest, approveManagerRequest, updateManagerRequest } from '../../services/managerRequestServices';


const ChangeCompensatedForm: FC<any> = (props) => {
	const {
		handleClose,
		item,
		handleCloseUpdateDialog,
		isView,
		setIsView,
		handleCloseAndSearch,
		isApproveOrReject
	} = props;
	const { lang } = useMultiLanguage()
	const { setPageLoading } = useContext(AppContext);
	const [shouldOpenSendDialog, setShouldOpenSendDialog] = useState<boolean>(false);
	const [shouldOpenRejectDialog, setShouldOpenRejectDialog] = useState<boolean>(false);
	const [shouldOpenApproveDialog, setShouldOpenApproveDialog] = useState<boolean>(false);
	const [message, setMessage] = useState<string>("");
	const [listShift, setListShift] = useState<IShift[]>([]);

	const validationSchema = Yup.object().shape({
		employee: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
		loaiDoi: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
		ngayNop: Yup.date().required(lang("VALIDATION.REQUIRE")).nullable(),
		ngayDuyet: Yup.date().nullable(),
		ngayHienTai: Yup.date()
			.required(lang("VALIDATION.REQUIRE"))
			.nullable(),
		ngayDangKy: Yup.date()
			.required(lang("VALIDATION.REQUIRE"))
			.when("ngayHienTai", {
				is: (ngayHienTai: Date | null) => ngayHienTai,
				then: Yup.date()
					.min(
						Yup.ref("ngayHienTai"),
						lang("VALIDATION.MINDATE") +
						lang("MANAGENT_REQUEST.CHANGE_COMPENSATED.DATE_CURRENT")
					)
					.nullable()
			})
			.nullable(),
		caHienTai: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
		caDangKy: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
		trangThai: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
		employeeDangKy: Yup.object()
			.when('loaiDoi.code', {
				is: TYPE_CHANGE_SHIFT.CA_LAM_VIEC,
				then: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable()
			}).nullable()
	});

	useEffect(() => {
		if (item?.id) {
			formik.setValues(convertDataChangeCompensatedUI(item))
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [item]);

	const getListShift = useCallback(async () => {
		try {
			const { data } = await searchShift({ ...SEARCH_OBJECT_MAX_SIZE });
			if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
				setListShift(data?.data?.content || []);
			} else {
				toast.warning(data?.message);
			}
		} catch (err) {
			toast.error(lang("GENERAL.ERROR"));
		}
	}, [lang]);

	useEffect(() => {
		getListShift();
	}, [getListShift]);

	const handleCloseForm = () => {
		setShouldOpenSendDialog(false)
		setShouldOpenApproveDialog(false)
		setShouldOpenRejectDialog(false)
	}

	const handleSubmit = async (values: IChangeCompensated) => {
		try {
			setPageLoading(true);
			const _data = convertDataChangeCompensatedDto(values)
			const { data } = values?.id
				? await updateManagerRequest(values?.id, _data)
				: await addManagerRequest(_data);
			if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
				toast.success(
					values?.id
						? lang("TOAST.EDIT.SUCCESS")
						: lang("TOAST.CREATE.SUCCESS")
				);
				handleCloseAndSearch();
				return;
			}
			toast.warning(data?.message);
		} catch (err) {
			toast.error(lang("GENERAL.ERROR"));
		} finally {
			setPageLoading(false)
		}
	};

	const formik = useFormik({
		initialValues: INIT_CHANGE_COMPENSATED,
		validationSchema,
		onSubmit: handleSubmit
	});

	const handleChangeSelect = (name: string, value: any) => {
		switch (name) {
			case VARIABLE_STRING.EMPLOYEE:
				formik.setValues({
					...formik.values,
					[name]: value,
					donViCongTacId: value?.donViCongTacId,
					donViCongTacText: value?.donViCongTacText,
					maNhanVien: value?.maNhanVien,
					phongBanId: value?.phongBanId,
					phongBanText: value?.phongBanText,
					chucDanhId: value?.viTriCongViecId,
					chucDanhText: value?.viTriCongViecText
				});
				break;
			default:
				formik.setFieldValue(name, value);
		}
	};

	const handleConfirm = (lyDo: string = "") => {
		shouldOpenApproveDialog && handleUpdateStatus(CODE_STATUS.DA_DUYET, "Duyệt đơn thành công");
		shouldOpenSendDialog && handleUpdateStatus(CODE_STATUS.CHO_DUYET, "Gửi đơn Thành công");
		shouldOpenRejectDialog && handleUpdateStatus(CODE_STATUS.TU_CHOI, "Đã từ chối đơn", lyDo)
	}

	const handleOpenSendDialog = () => {
		setMessage("Bạn có chắc chắn muốn gửi không?")
		setShouldOpenSendDialog(true)
	}

	const handleOpenApprovelDialog = () => {
		setMessage("Bạn có chắc chắn muốn duyệt đơn này?")
		setShouldOpenApproveDialog(true)
	}

	const handleOpenRejectDialog = () => {
		setShouldOpenRejectDialog(true)
	}

	const handleUpdateStatus = async (status: string | number, messageSuccess: string = "", lyDo: string = "") => {
		try {
			setPageLoading(true);
			let _values = {
				trangThai: findList(LIST_STATUS, status),
				...status === CODE_STATUS.TU_CHOI && { lyDo }
			}

			const { data } = await approveManagerRequest(formik.values?.id ?? "", _values, {
				loaiDon: formik.values?.loaiDon?.type
			});

			if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
				toast.success(messageSuccess);
				handleCloseAndSearch();
				handleCloseForm();
				return;
			}
			toast.warn(data?.message);
		} catch {
			toast.error(lang("TOAST.GET.ERROR"));
		} finally {
			setPageLoading(false);
		}
	}

	return (
		<div className="managerRequest h-full">
			<Form onSubmit={formik.handleSubmit}>
				<div className="header-form-action">
					<GroupButton type="btn-back" handleClose={handleClose} />
					<div className="flex gap-4">
						{formik.values?.trangThai?.code === CODE_STATUS.TAO_MOI && !isApproveOrReject && item?.id &&
							(isView
								? <>
									{hasAuthority(PERMISSIONS.DON_DOI_CA_NGHI_BU, PERMISSION_ABILITY.UPDATE)
										&& <GroupButton type="btn-edit" handleSaveEdit={() => setIsView(false)} />
									}
									<GroupButton handleEvent={handleOpenSendDialog}>{lang("AUTH.GENERAL.SUBMIT_BUTTON")}</GroupButton>
								</>
								: <GroupButton
									type="btn-cancel"
									handleCloseUpdateDialog={handleCloseUpdateDialog}
								/>
							)
						}

						{(!item?.id || (item?.id && !isView)) && < GroupButton type="btn-save" />}

						{item?.id && isView && isApproveOrReject && hasAuthority(PERMISSIONS.DON_DOI_CA_NGHI_BU, PERMISSION_ABILITY.APPROVE) && (
							<>
								<GroupButton handleEvent={handleOpenApprovelDialog}>{lang("BTN.APPROVE")}</GroupButton>
								<GroupButton handleEvent={handleOpenRejectDialog}>{lang("DECLARE_ASSETS.STATUS.REJECT")}</GroupButton>
							</>
						)}
					</div>
				</div>
				<div className="form-content-scroll">
					<div className="sub-title-form">
						{item?.id
							? lang("MANAGENT_REQUEST.CHANGE_COMPENSATED.EDIT")
							: lang("MANAGENT_REQUEST.CHANGE_COMPENSATED.ADD")
						}
					</div>
					<Row>
						<Col xs={6} md={5} xl={5} xxl={3} className="spaces pt-6">
							<Autocomplete
								isRequired
								isReadOnly={isView || isApproveOrReject}
								lable={lang("PROFILE.NAME")}
								options={[]}
								onChange={(value) => handleChangeSelect(VARIABLE_STRING.EMPLOYEE, value)}
								name="employee"
								value={formik.values?.employee || null}
								searchFunction={searchAllEmployee}
								// Tạm lấy ra tất cả nhân viên theo phòng ban để nhập đơn trong quá khứ
								// searchObject={{ trangThaiLamViec: Object.values(STATUS_NV).find(status => status === STATUS_NV.DANG_LAM_VIEC) }}
								errors={formik.errors.employee}
								touched={formik.touched.employee}
								getOptionLabel={(option) => `${option?.maNhanVien} - ${option?.name}`}
							/>
						</Col>
						<Col xs={6} md={2} xl={2} xxl={2} className="spaces pt-6">
							<TextValidator
								lable={lang("INPUT.CODE_NEW")}
								name="maNhanVien"
								value={formik.values?.maNhanVien || ""}
								type="text"
								readOnly={true}
							/>
						</Col>
						<Col xs={6} md={5} xl={5} xxl={4} className="spaces pt-6">
							<TextValidator
								name="donViCongTac"
								lable={lang("GENERAL.CURRENT_ORGANIZATION")}
								type="text"
								value={formik.values?.donViCongTacText || ""}
								readOnly={true}
								onChange={formik.handleChange}
							/>
						</Col>
						<Col xs={6} xl={6} xxl={3} className="spaces pt-6">
							<TextValidator
								name="phongBan"
								lable={lang("INPUT.DEPARTMENTS")}
								type="text"
								value={formik.values?.phongBanText || ""}
								readOnly={true}
								onChange={formik.handleChange}
							/>
						</Col>
						<Col xs={6} xl={6} xxl={5} className="spaces pt-6">
							<TextValidator
								name="chucDanh"
								lable={lang("GENERAL.EMPLOYEE.TITLE")}
								type="text"
								value={formik.values?.chucDanhText || ""}
								readOnly={true}
								onChange={formik.handleChange}
							/>
						</Col>
					</Row>
					<Row>
						<Col sm={3} md={3} lg={3} xl={3} className="spaces pt-6">
							<Autocomplete
								lable={lang("MANAGENT_REQUEST.CHANGE_COMPENSATED.TYPE")}
								isReadOnly={isView || isApproveOrReject}
								isRequired
								options={LIST_TYPE_CHANGE_SHIFT}
								value={formik.values?.loaiDoi}
								name={VARIABLE_STRING.LOAI_DOI}
								onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.LOAI_DOI, selectedOption)}
								errors={formik.errors?.loaiDoi}
								touched={formik.touched?.loaiDoi}
							/>
						</Col>
						<Col sm={3} md={3} lg={3} xl={3} className="spaces pt-6">
							<TextValidator
								isRequired
								name="ngayNop"
								lable={lang("MANAGENT_REQUEST.ATTENDANCE_WATCH.DATE_APPLICATION")}
								type="date"
								value={formik.values?.ngayNop}
								readOnly={isView || isApproveOrReject}
								onChange={formik.handleChange}
								errors={formik.errors?.ngayNop}
								touched={formik.touched?.ngayNop}
							/>
						</Col>
						<Col sm={3} md={3} lg={3} xl={3} className="spaces pt-6">
							<TextValidator
								isRequired
								name="ngayHienTai"
								lable={lang("MANAGENT_REQUEST.CHANGE_COMPENSATED.DATE_CURRENT")}
								type="date"
								value={formik.values?.ngayHienTai}
								readOnly={isView || isApproveOrReject}
								onChange={formik.handleChange}
								errors={formik.errors?.ngayHienTai}
								touched={formik.touched?.ngayHienTai}
							/>
						</Col>
						<Col sm={3} md={3} lg={3} xl={3} className="spaces pt-6">
							<Autocomplete
								lable={lang("MANAGENT_REQUEST.CHANGE_COMPENSATED.SHIFT_CURRENT")}
								isReadOnly={isView || isApproveOrReject}
								isRequired
								options={listShift}
								getOptionLabel={(option) => option?.tenCa}
								value={formik.values?.caHienTai}
								name="caHienTai"
								onChange={(selectedOption) => handleChangeSelect("caHienTai", selectedOption)}
								errors={formik.errors?.caHienTai}
								touched={formik.touched?.caHienTai}
							/>
						</Col>
						{formik.values?.loaiDoi?.code === TYPE_CHANGE_SHIFT.CA_LAM_VIEC && (
							<>
								<Col sm={3} md={3} lg={3} xl={3} className="spaces pt-6">
									<Autocomplete
										lable={lang("MANAGENT_REQUEST.CHANGE_COMPENSATED.EMPLOYEE_REGISTRY")}
										isReadOnly={isView || isApproveOrReject}
										isRequired
										options={[]}
										onChange={(value) => handleChangeSelect(VARIABLE_STRING.EMPLOYEE_DANG_KY, value)}
										name={VARIABLE_STRING.EMPLOYEE_DANG_KY}
										value={formik.values?.employeeDangKy || null}
										searchFunction={searchAllEmployee}
										// Tạm lấy ra tất cả nhân viên theo phòng ban để nhập đơn trong quá khứ
										// searchObject={{ trangThaiLamViec: Object.values(STATUS_NV).find(status => status === STATUS_NV.DANG_LAM_VIEC) }}
										errors={formik.errors.employeeDangKy}
										touched={formik.touched.employeeDangKy}
										getOptionLabel={(option) => option?.name}
									/>
								</Col>
								<Col sm={3} md={3} lg={3} xl={3} className="spaces pt-6">
									<TextValidator
										lable={lang("INPUT.CODE_NEW")}
										name="maNhanVien"
										value={formik.values?.employeeDangKy?.maNhanVien || ""}
										type="text"
										readOnly={true}
									/>
								</Col>
							</>
						)}
						<Col sm={3} md={3} lg={3} xl={3} className="spaces pt-6">
							<TextValidator
								isRequired
								name="ngayDangKy"
								lable={lang("MANAGENT_REQUEST.CHANGE_COMPENSATED.DATE_REGISTRY")}
								type="date"
								value={formik.values?.ngayDangKy}
								readOnly={isView || isApproveOrReject}
								onChange={formik.handleChange}
								errors={formik.errors?.ngayDangKy}
								touched={formik.touched?.ngayDangKy}
							/>
						</Col>
						<Col sm={3} md={3} lg={3} xl={3} className="spaces pt-6">
							<Autocomplete
								lable={lang("MANAGENT_REQUEST.CHANGE_COMPENSATED.SHIFT_REGISTRY")}
								isReadOnly={isView || isApproveOrReject}
								isRequired
								options={listShift}
								getOptionLabel={(option) => option?.tenCa}
								value={formik.values?.caDangKy}
								name="caDangKy"
								onChange={(selectedOption) => handleChangeSelect("caDangKy", selectedOption)}
								errors={formik.errors?.caDangKy}
								touched={formik.touched?.caDangKy}
							/>
						</Col>
						<Col sm={3} md={3} lg={3} xl={3} className="spaces pt-6">
							<TextValidator
								name="ngayDuyet"
								lable={lang("DATE.APPROVE")}
								type="date"
								value={formik.values?.ngayDuyet}
								readOnly={true}
								onChange={formik.handleChange}
								errors={formik.errors?.ngayDuyet}
								touched={formik.touched?.ngayDuyet}
							/>
						</Col>
						<Col sm={3} md={3} lg={3} xl={3} className="spaces pt-6">
							<Autocomplete
								isReadOnly={true}
								lable={lang("MANAGENT_REQUEST.ATTENDANCE_WATCH.DEAN")}
								options={[]}
								value={formik.values?.truongKhoa || null}
								name="truongKhoa"
								searchFunction={searchNguoiDaiDienKy}
								onChange={(value) => handleChangeSelect("truongKhoa", value)}
								errors={formik.errors?.truongKhoa}
								touched={formik.touched?.truongKhoa}
							/>
						</Col>
						<Col sm={3} md={3} lg={3} xl={3} className="spaces pt-6">
							<Autocomplete
								isReadOnly={true}
								lable={lang("TCCB")}
								options={[]}
								value={formik.values?.phongTccb || null}
								name="phongTccb"
								searchFunction={searchNguoiDaiDienKy}
								onChange={(value) => handleChangeSelect("phongTccb", value)}
								errors={formik.errors?.phongTccb}
								touched={formik.touched?.phongTccb}
							/>
						</Col>
						<Col sm={3} md={3} lg={3} xl={3} className="spaces pt-6">
							<Autocomplete
								isReadOnly={true}
								lable={lang("CHIEF")}
								options={[]}
								value={formik.values?.thuTruong || null}
								name="thuTruong"
								searchFunction={searchNguoiDaiDienKy}
								onChange={(value) => handleChangeSelect("thuTruong", value)}
								errors={formik.errors?.thuTruong}
								touched={formik.touched?.thuTruong}
							/>
						</Col>
						<Col sm={3} md={3} lg={3} xl={3} className="spaces pt-6">
							<Autocomplete
								isRequired
								lable={lang("GENERAL.STATUS")}
								isReadOnly={formik.values?.id || isView || isApproveOrReject}
								options={LIST_STATUS}
								value={formik.values?.trangThai || null}
								name="trangThai"
								onChange={(value) => handleChangeSelect("trangThai", value)}
								errors={formik.errors?.trangThai}
								touched={formik.touched?.trangThai}
							/>
						</Col>
					</Row>
					<Row>
						<Col sm={6} md={6} lg={6} xl={6} className="spaces pt-6">
							<TextValidator
								as="textarea"
								name="lyDoDoiCa"
								lable={lang("MANAGENT_REQUEST.CHANGE_COMPENSATED.REASON")}
								type="text"
								value={formik.values?.lyDoDoiCa}
								readOnly={isView || isApproveOrReject}
								errors={formik.errors?.lyDoDoiCa}
								touched={formik.touched?.lyDoDoiCa}
								rows="2"
								onChange={formik.handleChange}
							/>
						</Col>

						<Col sm={6} md={6} lg={6} xl={6} className="spaces pt-6">
							<TextValidator
								as="textarea"
								name="ghiChu"
								lable={"Ghi chú"}
								type="text"
								value={formik.values?.ghiChu}
								readOnly={isView || isApproveOrReject}
								errors={formik.errors?.ghiChu}
								touched={formik.touched?.ghiChu}
								rows="2"
								onChange={formik.handleChange}
							/>
						</Col>
						{formik.values?.lyDo &&
							<Col sm={12} md={12} lg={12} xl={12} className="spaces pt-6">
								<TextValidator
									as="textarea"
									name="lyDo"
									lable={"Lý do"}
									type="text"
									value={formik.values?.lyDo}
									readOnly={true}
									errors={formik.errors?.lyDo}
									touched={formik.touched?.lyDo}
									rows="2"
									onChange={formik.handleChange}
								/>
							</Col>
						}
					</Row>
				</div>
			</Form >

			{
				(shouldOpenSendDialog || shouldOpenApproveDialog) &&
				<ConfirmDialog
					show={true}
					title={lang("DIALOG.CONFIRM")}
					message={message}
					cancel={lang("BTN.CLOSE")}
					onCancelClick={handleCloseForm}
					yes={lang("BTN.CONFIRM")}
					onYesClick={handleConfirm}
				/>
			}

			{
				shouldOpenRejectDialog &&
				<RefuseDialog
					show={shouldOpenRejectDialog}
					field='lyDo'
					onSubmit={handleConfirm}
					onCloseClick={handleCloseForm}
				/>
			}
		</div >
	);
};

export default ChangeCompensatedForm;

import { KTSVG } from "../../../../_metronic/helpers/components/KTSVG";
import { ILang } from "../../../hook/useMultiLanguage";
import { columnNamesType } from "../../components/table-custom/TableCustom";
import { convertNumberPrice, formatDateTable } from "../../utils/functionUtils";

export const MinorChildrenColumns = (lang: ILang): columnNamesType[] => {
  return [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("PROFILE.NAME"),
      field: "name",
      headerStyle: {
        minWidth: "130px",
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang("PROFILE.BIRTHDAY"),
      field: "birthdate",
      render: (row) => <span>{formatDateTable(row?.birthdate)}</span>
    },
    {
      name: lang("DECLARE_ASSETS.PLACE_RESIDENCE"),
      field: "noiThuongTru",
      headerStyle: {
        minWidth: "250px",
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang("INPUT.IDENTIFICATION"),
      field: "soCmndCccd",
      headerStyle: {
        minWidth: "100px",
        textAlign: "center",
      },
    },
    {
      name: lang("INPUT.DATERANGE"),
      field: "soCmndCccdNgayCap",
      headerStyle: {
        minWidth: "80px",
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row) => <span>{formatDateTable(row?.soCmndCccdNgayCap)}</span>
    },
    {
      name: lang("INPUT.PLACERANGE"),
      field: "soCmndCccdNoiCap",
      headerStyle: {
        minWidth: "150px",
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "left",
      },
    }
  ];
};

export const LandUseColumns = (lang: ILang): columnNamesType[] => {
  return [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("DECLARE_ASSETS.MODEL_TYPE"),
      field: "kieu",
      headerStyle: {
        minWidth: "100px",
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang("GENERAL.NAME"),
      field: "name",
      headerStyle: {
        minWidth: "150px",
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang("DECLARE_ASSETS.ACREAGE"),
      field: "dienTich",
      headerStyle: {
        minWidth: "120px",
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row) => <span>{convertNumberPrice(row?.dienTich)}</span>
    },
    {
      name: lang("ALLOWANCE.VALUE"),
      field: "giaTri",
      headerStyle: {
        minWidth: "150px",
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row) => <span>{convertNumberPrice(row?.giaTri)}</span>
    },
    {
      name: lang("DECLARE_ASSETS.CANNOT_ESTIMATED"),
      field: "khongTheUocLuong",
      headerStyle: {
        minWidth: "150px",
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any) => {
        if (row?.khongTheUocLuong) {
          return <KTSVG path="/media/icons/check.svg" className="svg-icon-1" />;
        } else {
          return <span></span>;
        }
      }
    },
    {
      name: lang("GENERAL.ADDRESS"),
      field: "diaChi",
      headerStyle: {
        minWidth: "200px",
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang("DECLARE_ASSETS.CERTIFATE_LAND_USE"),
      field: "giayChungNhan",
      headerStyle: {
        minWidth: "250px",
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang("INFORMATION.OTHER"),
      field: "thongTinKhac",
      headerStyle: {
        minWidth: "200px",
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "left",
      },
    }
  ];
};

export const HousingColumns = (lang: ILang): columnNamesType[] => {
  return [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("VACCINATION.TYPE"),
      field: "loai",
      headerStyle: {
        minWidth: "200px",
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang("GENERAL.NAME"),
      field: "name",
      headerStyle: {
        minWidth: "150px",
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang("DECLARE_ASSETS.HOUSE_TYPE"),
      field: "loaiNha",
      headerStyle: {
        minWidth: "120px",
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang("DECLARE_ASSETS.ACREAGE"),
      field: "dienTich",
      headerStyle: {
        minWidth: "120px",
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row) => <span>{convertNumberPrice(row?.dienTich)}</span>
    },
    {
      name: lang("ALLOWANCE.VALUE"),
      field: "giaTri",
      headerStyle: {
        minWidth: "150px",
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row) => <span>{convertNumberPrice(row?.giaTri)}</span>
    },
    {
      name: lang("GENERAL.ADDRESS"),
      field: "diaChi",
      headerStyle: {
        minWidth: "200px",
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang("DECLARE_ASSETS.CERTIFATE_LAND_USE"),
      field: "giayChungNhan",
      headerStyle: {
        minWidth: "250px",
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang("INFORMATION.OTHER"),
      field: "thongTinKhac",
      headerStyle: {
        minWidth: "150px",
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "left",
      },
    }
  ];
};

export const OtherAssetsLandColumns = (lang: ILang): columnNamesType[] => {
  return [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("VACCINATION.TYPE"),
      field: "loai",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang("GENERAL.NAME"),
      field: "name",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang("DECLARE_ASSETS.ACREAGE"),
      field: "dienTich",
      headerStyle: {
        minWidth: "120px",
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row) => <span>{convertNumberPrice(row?.dienTich)}</span>
    },
    {
      name: lang("ALLOWANCE.VALUE"),
      field: "giaTri",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row) => <span>{convertNumberPrice(row?.giaTri)}</span>
    }
  ];
};

export const GemStonesColumns = (lang: ILang): columnNamesType[] => {
  return [
    {
      name: "STT",
      field: "",
      headerStyle: {
        width: "50px"
      },
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("GENERAL.NAME"),
      field: "name",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang("ALLOWANCE.VALUE"),
      field: "giaTri",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row) => <span>{convertNumberPrice(row?.giaTri)}</span>
    }
  ];
};

export const MoneyColumns = (lang: ILang): columnNamesType[] => {
  return [
    {
      name: "STT",
      field: "",
      headerStyle: {
        width: "50px"
      },
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("GENERAL.NAME"),
      field: "name",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang("ALLOWANCE.VALUE"),
      field: "giaTri",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row) => <span>{convertNumberPrice(row?.giaTri)}</span>
    }
  ];
};

export const StockColumns = (lang: ILang): columnNamesType[] => {
  return [
    {
      name: "STT",
      field: "",
      headerStyle: {
        width: "50px"
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("VACCINATION.TYPE"),
      field: "loai",
      headerStyle: {
        minWidth: "100px",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang("GENERAL.NAME"),
      field: "name",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang("DECLARE_ASSETS.AMOUNT"),
      field: "soLuong",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row) => <span>{convertNumberPrice(row?.soLuong)}</span>
    },
    {
      name: lang("ALLOWANCE.VALUE"),
      field: "giaTri",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row) => <span>{convertNumberPrice(row?.giaTri)}</span>
    }
  ];
};

export const OtherAssetsColumns = (lang: ILang): columnNamesType[] => {
  return [
    {
      name: "STT",
      field: "",
      headerStyle: {
        width: "50px"
      },
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("VACCINATION.TYPE"),
      field: "loai",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang("GENERAL.NAME"),
      field: "name",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang("DECLARE_ASSETS.NUMBER_REGISTER"),
      field: "soDangKy",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang("ALLOWANCE.VALUE"),
      field: "giaTri",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row) => <span>{convertNumberPrice(row?.giaTri)}</span>
    }
  ];
};

export const AbroadAccountColumns = (lang: ILang): columnNamesType[] => {
  return [
    {
      name: "STT",
      field: "",
      headerStyle: {
        width: "50px"
      },
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("DECLARE_ASSETS.BANK_USER"),
      field: "name",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang("DECLARE_ASSETS.BANK_NUMBER"),
      field: "soTaiKhoan",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang("DECLARE_ASSETS.BANK_NAME"),
      field: "tenNganHang",
      headerStyle: {
        minWidth: "300px",
      },
      cellStyle: {
        textAlign: "left",
      },
    }
  ];
};

/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { searchAllSimpleValue, searchNationality } from "../../../services/services";
import "../../../styles/index.scss";
import { IRelativesInfo, IRelativesInfoDto } from "../../models/dialogModels";
import { addQuanHeGiaDinh, updateQuanHeGiaDinh } from "../../services/dialogServices";
import { INIT_RELATIVES_INFO } from "../../constants/profileConsts";
import TextValidator from "../../../components/input/text-validator";
import { hasAuthority, numberExceptThisSymbols } from "../../../utils/functionUtils";
import Autocomplete from "../../../components/input/autocomplete";
import { REGEX, RESPONSE_STATUS_CODE, VARIABLE_STRING, TYPE_CATEGORY } from "../../../constants/moduleConsts";
import { convertDataFamilyDto } from "../../utils/profileUtils";
import TextArea from "../../../components/input/TextArea";
import AddAttributeValueDialog from "./AddAttributeValueDialog";
import AppContext from "../../../../AppContext";
import { useProfileContext } from "../../ProfileContext";
import { GroupButton } from "../../../components/GroupButton";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../../../constants";
import useMultiLanguage from "../../../../hook/useMultiLanguage";

interface Props {
  handleCloseRelativesDialog: () => void;
  handleCloseAndSearch: () => void;
  identify: string;
  dataRelativeEdit: IRelativesInfo;
  isView: boolean;
}

export const RelativesDialog = (props: Props) => {
  const { handleCloseRelativesDialog, identify, isView, dataRelativeEdit, handleCloseAndSearch } = props;

  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);
  const { isResign } = useProfileContext();
  const [showAddRelativeDialog, setShowAddRelativeDialog] = useState(false);
  const [loadPersonalRelation, setLoadPersonalRelation] = useState<boolean>(true);
  const [isEdit, setIsEdit] = useState<boolean>(!isView);

  useEffect(() => {
    formik.setValues(dataRelativeEdit)
  }, [dataRelativeEdit])

  const validationSchema = Yup.object().shape({
    ten: Yup.string()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    quanHeNV: Yup.object()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    namSinh: Yup.number()
      .min(1000, lang("VALIDATION.FOUR_DIGITS"))
      .max(9999, lang("VALIDATION.FOUR_DIGITS"))
      .nullable(),
    email: Yup.string().email(lang("VALIDATION.EMAILINVALID")).nullable(),
    dienThoai: Yup.string()
      .matches(REGEX.CHECK_PHONE, lang("VALIDATION.ISPHONE"))
      .nullable(),
  });

  const handleSubmit = async (values: IRelativesInfo) => {
    let dataSubmit = { ...values, employeeId: identify };
    if (identify) {
      try {
        setPageLoading(true);
        const dataFamily: IRelativesInfoDto = convertDataFamilyDto(dataSubmit);
        const { data } = dataRelativeEdit?.id
          ? await updateQuanHeGiaDinh(dataRelativeEdit?.id, dataFamily)
          : await addQuanHeGiaDinh(dataFamily);
        if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
          const message = dataRelativeEdit?.id
            ? "TOAST.EDIT.SUCCESS"
            : "TOAST.ADD.SUCCESS";
          toast.success(lang(message));
          handleCloseAndSearch();
        } else {
          toast.warning(data?.message);
        }
      } catch (error) {
        toast.error(lang("GENERAL.ERROR"));
      } finally {
        setPageLoading(false);
      }
    } else {
      toast.warning(lang("MESSAGE.BASIC.FIRST"));
    }
  };


  const formik = useFormik({
    initialValues: dataRelativeEdit?.id ? dataRelativeEdit : INIT_RELATIVES_INFO,
    validationSchema,
    onSubmit: handleSubmit
  })

  const handleChangeSelect = (name: string, value: any) => {
    formik.setFieldValue(name, value)
  }

  const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target;
    formik.setFieldValue(name, checked);
  };

  useEffect(() => {
    if (dataRelativeEdit?.id) return;
    getNationality()
  }, [dataRelativeEdit])

  const getNationality = async () => {
    try {
      const { data } = await searchNationality();
      if(data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        const nationality = data?.data?.content?.length > 0 ? data?.data?.content?.find((item: any) => item?.name === VARIABLE_STRING.VIET_NAM) : null
        formik.setFieldValue("nationality", nationality)
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }

  const handleOpenAddAttributeValueDialog = () => {
    setShowAddRelativeDialog(true);
  };

  const handleCloseAddAttributeValueDialog = () => {
    setShowAddRelativeDialog(false);
  };

  const handleUpdateAddAttributeValueDialog = () => {
    setLoadPersonalRelation(!loadPersonalRelation);
    setShowAddRelativeDialog(false);
  }

  const handleSaveItem = (newItem :any) => {
    handleChangeSelect("quanHeNV", newItem);
    handleCloseAddAttributeValueDialog();
  };

  return (
    <>
      <Modal
        show={true}
        size="xl"
        centered
        aria-labelledby="example-custom-modal-styling-title"
        onHide={handleCloseRelativesDialog}
        backdrop="static"
      >
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title
              id="example-custom-modal-styling-title"
              className="heading-5"
            >
              {lang("GENERAL.INFO.RELATIVES")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="spaces px-8 pt-6">
              <Col xs={4}>
                <Autocomplete
                  isRequired
                  lable={lang("INPUT.FAMILY.RELATION")}
                  name="quanHeNV"
                  value={formik.values?.quanHeNV || ""}
                  searchFunction={searchAllSimpleValue}
                  searchObject={{ type: TYPE_CATEGORY.quanHeGiaDinh }}
                  isReadOnly={!isEdit}
                  onChange={(selectedOption) =>
                    handleChangeSelect(
                      VARIABLE_STRING.QUAN_HE_NV,
                      selectedOption
                    )
                  }
                  errors={formik.errors?.quanHeNV}
                  touched={formik.touched?.quanHeNV}
                  options={[]}
                  getOptionLabel={(option) => option?.value}
                  isAddNew={true}
                  onAddNew={handleOpenAddAttributeValueDialog}
                  dependencies={[loadPersonalRelation]}
                />
              </Col>
              <Col xs={4}>
                <TextValidator
                  lable={lang("INPUT.RELATIVE.FULLNAME")}
                  name="ten"
                  value={formik.values?.ten || ""}
                  type="text"
                  isRequired
                  readOnly={!isEdit}
                  onChange={formik.handleChange}
                  errors={formik.errors?.ten}
                  touched={formik.touched?.ten}
                />
              </Col>
              <Col xs={4}>
                <TextValidator
                  lable={lang("INPUT.FAMILY.BIRTH")}
                  name="namSinh"
                  value={formik.values?.namSinh || ""}
                  type="number"
                  readOnly={!isEdit}
                  onChange={formik.handleChange}
                  onKeyDown={numberExceptThisSymbols}
                  errors={formik.errors?.namSinh}
                  touched={formik.touched?.namSinh}
                />
              </Col>
            </Row>
            <Row className="spaces px-8 pt-6">
              <Col xs={4}>
                <Autocomplete
                  lable={lang("INPUT.RELATIVE.NATION")}
                  isReadOnly={!isEdit}
                  options={[]}
                  value={formik.values?.nationality || null}
                  name="nationality"
                  onChange={(selectedOption) =>
                    handleChangeSelect("nationality", selectedOption)
                  }
                  searchFunction={searchNationality}
                />
              </Col>
              <Col xs={4}>
                <TextValidator
                  lable={lang("INPUT.RELATIVE.PHONE")}
                  name="dienThoai"
                  value={formik.values?.dienThoai || ""}
                  type="text"
                  readOnly={!isEdit}
                  onChange={formik.handleChange}
                  errors={formik.errors?.dienThoai}
                  touched={formik.touched?.dienThoai}
                />
              </Col>
            </Row>
            <Row className="spaces px-8 pt-6">
              <Col xs={4}>
                <Form.Check
                  className="py-4"
                  label={lang("INPUT.FAMILY.IS_SAME_ORGANIZATION")}
                  name="isSameOrganization"
                  checked={formik.values?.isSameOrganization}
                  disabled={!isEdit}
                  onChange={handleChangeCheckBox}
                />
              </Col>
              <Col xs={4}>
                <Form.Check
                  className="py-4"
                  label={lang("INPUT.FAMILY.IS_DEATH")}
                  name="isDeadth"
                  checked={formik.values?.isDeadth}
                  disabled={!isEdit}
                  onChange={handleChangeCheckBox}
                />
              </Col>
              <Col xs={4}>
                <Form.Check
                  className="py-4"
                  label={lang("INPUT.FAMILY.IS_DEPENDENT_PERSON")}
                  name="isDependentPerson"
                  checked={formik.values?.isDependentPerson}
                  disabled={!isEdit}
                  onChange={handleChangeCheckBox}
                />
              </Col>
            </Row>
            <Row className="spaces px-8 pt-6">
              <Col xs={12} className="pt-6">
                <TextArea
                  lable={lang("INPUT.FAMILY.MORE_INFO")}
                  name="moreDetails"
                  value={formik.values?.moreDetails || ""}
                  type="text"
                  as="textarea"
                  rows="3"
                  readOnly={!isEdit}
                  onChange={(value: any) =>
                    formik.setFieldValue("moreDetails", value)
                  }
                  touched={formik.touched?.moreDetails}
                  errors={formik.errors?.moreDetails}
                />
              </Col>
              <Col xs={12} className="pt-6">
                <TextArea
                  className="w-100"
                  lable={lang("INPUT.FAMILY.NOTE")}
                  name="note"
                  value={formik.values?.note || ""}
                  type="text"
                  as="textarea"
                  rows="3"
                  readOnly={!isEdit}
                  onChange={(value: any) => formik.setFieldValue("note", value)}
                  touched={formik.touched?.note}
                  errors={formik.errors?.note}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="flex-center">
            <GroupButton type="btn-cancel" handleCloseUpdateDialog={handleCloseRelativesDialog} />
            {!isResign &&
              (isEdit ? (
                <GroupButton type="btn-save" />
              ) : (hasAuthority(PERMISSIONS.EMPLOYEE, PERMISSION_ABILITY.UPDATE) &&
                <GroupButton type="btn-edit" handleSaveEdit={() => setIsEdit(true)} />
              ))}
          </Modal.Footer>
        </Form>
      </Modal>
      {showAddRelativeDialog && (
        <AddAttributeValueDialog
          onClose={handleCloseAddAttributeValueDialog}
          onUpdate={handleUpdateAddAttributeValueDialog}
          handleSaveItem={handleSaveItem}
          title={"GENERAL.INFO.RELATIVES_FAMILY"}
          typeContent={TYPE_CATEGORY.quanHeGiaDinh}
        />
      )}
    </>
  );
};

import { LIST_STATUS_SOFTWARE_USAGE } from "../constants/managerConsts";
import { IAuth, INodeTree } from "../models/managerModels";

export const findChildrenNamesByValue = (array: INodeTree[], value: string) => {
  let result: any[] = [];

  array.forEach((item) => {
    if (item.value.startsWith(value.concat('.'))) {
      if (item.children.length === 0) {
        result.push(item.value);
      } else {
        result = result.concat(findChildrenNamesByValue(item.children, value));
      }
    } else {
      if (item.children.length > 0) {
        result = result.concat(findChildrenNamesByValue(item.children, value));
      }
    }
  });
  return result;
};

export const checkAndModifyArray = (arr: string[] = [], str: string) => {
  // Kiểm tra xem str có trong mảng hay không
  const index = arr.indexOf(str);

  // Nếu str đã có trong mảng, xóa str khỏi mảng
  if (index !== -1 && index !== undefined) {
    arr.splice(index, 1);
  }
  // Nếu str không có trong mảng, thêm str vào cuối mảng
  else {
    arr.push(str);
  }

  // Trả về mảng sau khi đã được sửa đổi
  return arr;
};

// Function to recursively build the tree structure
export function addToTree(node: INodeTree, pathArr: string[], nameArr: string[]) {
  if (pathArr.length === 0) return; // Exit condition

  const path = pathArr.shift();
  let childNode = node.children.find((child: any) => child.name === path);
  if (!childNode && path) {
    childNode = {
      name: path,
      nameNode: nameArr[0],
      value: node.value ? `${node.value}.${path}` : path,
      children: [],
    };
    node.children.push(childNode);
  }
  childNode && addToTree(childNode, pathArr, nameArr.slice(1));
}

export const handleConvertData = (data: IAuth[]) => {
  const output: INodeTree[] = [];

  // Loop through each input object
  data.forEach((obj) => {
    const pathArr = obj.code.split("."); // Split the code by '.'
    const nameArr = obj.name.split("."); // Split the code by '.'
    let rootNode = output.find((node) => node.name === pathArr[0]); // Find root node
    if (!rootNode) {
      rootNode = {
        name: pathArr[0],
        nameNode: nameArr[0],
        value: pathArr[0],
        children: [],
      };
      output.push(rootNode);
    }
    addToTree(rootNode, pathArr.slice(1), nameArr.slice(1)); // Add the rest of the path to the tree
  });

  return output;
};

export const convertDataEdit = (input: INodeTree[], authorities: string[]) => {
  // Nếu không có input hoặc input không phải mảng, trả về input
  if (!input || !Array.isArray(input)) {
    return input;
  }

  // Lặp qua từng phần tử trong mảng input
  return input.map((item) => {
    // Tạo một bản sao của đối tượng hiện tại
    const newItem = { ...item };

    // Nếu đối tượng hiện tại có 'children' là một mảng có ít nhất 1 phần tử
    if (newItem.children?.length > 0) {
      // Gọi đệ quy để chuyển đổi các con của đối tượng hiện tại
      newItem.children = convertDataEdit(newItem.children, authorities);
      newItem.checked = newItem.children.some(child => child.checked);
    } else {
      // Thiết lập thuộc tính 'checked' cho đối tượng cấp nhỏ nhất
      newItem.checked = authorities.includes(newItem.value);
    }
    
    // Trả về đối tượng mới đã chuyển đổi
    return newItem;
  });
};

export const checkWarningSoftwareUsage = (progress?: number) => {
  return progress !== undefined ? LIST_STATUS_SOFTWARE_USAGE.find(status => progress <= status.maxValue)?.className : "";
}
import { IPropsExportWord } from "../../../components/exportWord";
import { ValueOrDots } from "../../../components/exportWord/partials";

export function TransferStaffWord(props: IPropsExportWord) {
  const { data } = props;
  return (
    <div className="word spaces px-70 colors-word">
      <div className="spaces text-center flex flex-between align-start">
        <div className="fs-lg">
          <p>{data?.soYTeHeader ?? ""}</p>
          <p className="fw-600 text-uppercase">
            <span className="header-underline fs-lg">
              <ValueOrDots className="fs-lg" value={data.donViCongTacText} size={70} />
            </span>
          </p>
          <p className="spaces mt-16 fs-md">
            Số: <ValueOrDots value={data.soQuyetDinh} />
          </p>
        </div>
        <div className="fw-600">
          <p className="fs-lg">CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM</p>
          <p>
            <span className="header-underline fs-lg">Độc lập - Tự do - Hạnh phúc</span>
          </p>
          <p className="text-italic fw-500 spaces mt-16">
            <ValueOrDots value="" />, ngày <ValueOrDots value={data.ngayQuyetDinhNgay} size={15}/> tháng{" "}
            <ValueOrDots value={data.ngayQuyetDinhThang} size={15}/> năm <ValueOrDots value={data.ngayQuyetDinhNam} size={15} />
          </p>
        </div>
      </div>
      <div className="text-center fw-600">
        <div className="fs-lg">
          <p>QUYẾT ĐỊNH</p>
          <p>
            <span className="header-underline fs-lg">Về việc Điều động cán bộ</span>
          </p>
        </div>
        <div className="spaces py-16">
          <p className="fs-lg text-uppercase">
            GIÁM ĐỐC <ValueOrDots className="fs-lg" value={data.donViCongTacText} size={70} />
          </p>
        </div>
      </div>
      <div className="text-indent-40 text-italic text-justify">
        <p>{data?.quyetDinhHopDong ?? ""}</p>
        <p>{data?.qdhdLuanChuyen ?? ""}</p>
        <p>Căn cứ yêu cầu nhiệm vụ và năng lực công tác cán bộ;</p>
        <p>Theo đề nghị của Trưởng phòng Tổ chức hành chính tại Tờ trình 
          ngày <ValueOrDots value={data.ngayQuyetDinh} /> về việc xem xét, quyết định điều động cán bộ.
        </p>
      </div>
      <p className="spaces my-10 fw-600 fs-lg text-center">QUYẾT ĐỊNH:</p>
      <div className="text-indent-40">
        <p>
          <span className="fw-600">Điều 1.</span> Điều động{" "}
          <span className="fw-600">
            ông/bà <ValueOrDots value={data.fullName} />
          </span>
          , sinh ngày <ValueOrDots value={data.birthdate} />, <ValueOrDots value={data.chucDanhText} size={70} />
          , hiện đang công tác tại <ValueOrDots value={data.phongBanText} size={70} /> đến nhận nhiệm vụ tại{" "}
          <ValueOrDots value={data.phongBanMoiText} size={70} />,{" "}
          <ValueOrDots value={data.donViCongTacText} size={70} /> kể từ ngày{" "}
          <ValueOrDots value={data.hieuLucTuNgay} />
        </p>
        <p>
          Ông/bà <ValueOrDots value={data.fullName} /> thực hiện nhiệm vụ theo sự phân công của Trưởng khoa{" "}
          <ValueOrDots value={data.phongBanMoiText} />,
          <ValueOrDots value={data.donViCongTacText} size={70} />.
        </p>
        <p>
          <span className="fw-600">Điều 2.</span> Lương và các khoản phụ cấp theo lương của ông/bà{" "}
          <ValueOrDots value={data.fullName} /> hưởng theo quy định hiện hành của Nhà nước và theo Quy chế chi tiêu nội
          bộ của <ValueOrDots value={data.donViCongTacText} size={70} />.
        </p>
        <p>
          <span className="fw-600">Điều 3. </span> Các ông, bà:{" "}
          <ValueOrDots value={data.khoaPhongNhanQuyetDinh} size={160} />; các khoa, phòng có liên quan và ông/bà{" "}
          <ValueOrDots value={data.fullName} /> chịu trách nhiệm thi hành Quyết định này.
        </p>
      </div>
      <div className="flex flex-between align-start spaces pb-70 mt-16">
        <div>
          <p className="fw-600 text-italic">Nơi nhận: </p>
          <p>- Như Điều 3 (để t/h);</p>
          <p>- Lưu: TCHC, VT.</p>
        </div>
        <div className="text-center fw-600 fs-md spaces pr-20">
          <p>GIÁM ĐỐC</p>
          <p className="spaces mt-70">
            <ValueOrDots value={data.nguoiQuyetDinhText} size={60} />
          </p>
        </div>
      </div>
    </div>
  );
}

import { ReactNode } from "react";
import { formatDateTable, romanize } from "../../utils/functionUtils";
import { REPORT_KEY, REPORT_TYPE } from "../constants/reportConsts";
import { IReportInfo, TOptionTemplateTitleParam } from "../models/reportModels";

export const convertDataReport = (dataObj: Object) => {
  let dataConverted: any[] = [];
  Object.entries(dataObj).forEach(([key, value]) => {
    dataConverted.push({
      phongBanText: REPORT_KEY[key] || "",
      data: value
    });
  });

  return dataConverted;
};

export const templateTitleReport = (reportInfo: IReportInfo, otherOtions: TOptionTemplateTitleParam): string | ReactNode => {
  const { quy, nam, endDate, donVi } = otherOtions;
  switch (reportInfo?.mappingName) {
    case REPORT_TYPE.QUY_LUONG:
      return quy ? `Dự toán biên chế và quỹ tiền lương quý ${quy ? romanize(quy) : ""} năm ${nam}`
        : `Dự toán biên chế và quỹ tiền lương năm ${nam}`;
    case REPORT_TYPE.LAO_DONG_THEO_CHUC_DANH:
      return (
        <div className="leading-1.5">
          <p className="spaces m-0">Thống kê lao động theo chức danh</p>
          <p className="spaces m-0 fs-15">Đơn vị: {donVi || ""}</p>
          <p className="spaces m-0 fs-15">Đến ngày: {formatDateTable(endDate)}</p>
        </div>
      );
    default:
      return reportInfo?.value || "";
  }
};

export const convertSearchData = (searchData: any) => {
  const dataConverted: any = {};
  Object.entries(searchData).forEach(([key, value]) => {
    if(!searchData[key]) {
      dataConverted[key] = null;
    }else {
      dataConverted[key] = value;
    }
  })

  return dataConverted;
}
import { GroupButton } from "../../components/GroupButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Col, Form, Row } from "react-bootstrap";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { handleBlurDate, hasAuthority, justAllowNumber } from "../../utils/functionUtils";
import { useCallback, useContext, useEffect, useState } from "react";
import AppContext from "../../../AppContext";
import TextValidator from "../../components/input/text-validator";
import { MISSION_STATUS, MISSION_STATUS_CODE, RESPONSE_STATUS_CODE, TYPE, VARIABLE_STRING } from "../../constants/moduleConsts";
import Autocomplete from "../../components/input/autocomplete";
import { IFile } from "../../profile/models/dialogModels";
import UploadFile from "../../components/file-upload/UploadFile";
import { AddNewRepresentative, SelectEmployeeByDepartment } from "../../components/dialogs";
import { STATUS_NV } from "../../profile/constants/dialogChildConsts";
import { toast } from "react-toastify";
import { RangeDatePicker } from "../../components/input/RangeDatePicker";
import { IPeopleDecision, OptionReactSelect } from "../../models/models";
import { INIT_SEND_PEOPLE_TRAINING } from "../constants/sendPeopleTrainingConsts";
import { IEmployeeSelect, IEmployeeSelectList, ISendPeopleTraining, ISendPeopleTrainingDialog } from "../models/sendPeopleTrainingModels";
import { addSendPeopleTraining, updateSendPeopleTraining } from "../services/sendPeopleTrainingServices";
import TableCollapseCustom, { columnNamesCollapseType } from "../../components/table-collapse-custom/TableCollapseCustom";
import { EmployeeProfile } from "../../profile/models/profileModels";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../../constants";
import { searchNguoiDaiDienKy } from "../../services/services";

function SendPeopleTrainingDialog(props: ISendPeopleTrainingDialog) {
  const {
    dataEdit,
    isView,
    handleCloseDialog,
    handleCloseUpdateDialog,
    handleOpenUpdateDialog,
    handleCloseAndSearch
  } = props;
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [employeeChecked, setEmployeeChecked] = useState<IEmployeeSelect[]>([]);
  const [employeeList, setEmployeeList] = useState<IEmployeeSelectList[]>([]);
  const [shouldOpenSelectEmployeeDialog, setShouldOpenSelectEmployeeDialog] = useState<boolean>(false);
  const [isShowDialogAddRepresentative, setIsShowDialogAddRepresentative] = useState<boolean>(false);
  const [peopleDecisions, setPeopleDecisions] = useState<IPeopleDecision[]>([]);

  const getPeopleDecision = useCallback(async () => {
    try {
      const { data } = await searchNguoiDaiDienKy();
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setPeopleDecisions(data?.data?.content || [])
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }, [lang]);

  useEffect(() => {
    getPeopleDecision();
  }, [getPeopleDecision]);

  const handleCloseAndSearchRepresentative = () => {
    getPeopleDecision();
    setIsShowDialogAddRepresentative(false);
  };

  useEffect(() => {
    if (dataEdit?.id) {
      formik.setValues({
        ...dataEdit,
        nguoiKy: dataEdit?.nguoiKyId ? {
          id: dataEdit?.nguoiKyId,
          name: dataEdit?.nguoiKyText
        } : null
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataEdit]);

  const handleSubmit = async (values: ISendPeopleTraining) => {
    if (!dataEdit?.id && employeeList?.length === 0) return toast.warning(lang("TOAST.PLEASE_ADD_EMPLOYEE_LIST"));
    try {
      setPageLoading(true);
      const dataSubmit: ISendPeopleTraining = {
        ...values,
        nguoiKyId: values?.nguoiKy?.id ?? "",
        nguoiKyText: values?.nguoiKy?.name ?? "",
        thamGiaList: !dataEdit?.id ? employeeList?.map((item: IEmployeeSelectList) => item?.data)?.flat()
          : [{
            chucDanhId: dataEdit?.chucDanhId,
            chucDanhText: dataEdit?.chucDanhText,
            employeeId: dataEdit?.employeeId,
            maNhanVien: dataEdit?.maNhanVien,
            phongBanId: dataEdit?.phongBanId,
            phongBanText: dataEdit?.phongBanText,
            tenNhanVien: dataEdit?.tenNhanVien,
            donViCongTacId: dataEdit?.donViCongTacId,
            donViCongTacText: dataEdit?.donViCongTacText
          }]
      };
      const { data } = await (dataEdit?.id
        ? updateSendPeopleTraining(dataEdit.id, dataSubmit)
        : addSendPeopleTraining(dataSubmit));

      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        let message = dataEdit?.id ? "TOAST.EDIT.SUCCESS" : "TOAST.CREATE.SUCCESS";
        toast.success(lang(message));
        handleCloseAndSearch();
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const validationSchema = Yup.object().shape({
    hieuLucTuNgay: Yup.date().required(lang("VALIDATION.REQUIRE")).nullable(),
    hieuLucDenNgay: Yup.date()
      .when("hieuLucTuNgay", {
        is: (hieuLucTuNgay: Date | null) => hieuLucTuNgay,
        then: Yup.date().min(Yup.ref("hieuLucTuNgay"), lang("VALIDATE.TRAINING_DATE_END_AFTER_START")),
      })
      .required(lang("VALIDATION.REQUIRE")).nullable(),
    noiDaoTao: Yup.string().required(lang("VALIDATION.REQUIRE")),
    trangThai: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable()
  });

  const formik = useFormik({
    initialValues: INIT_SEND_PEOPLE_TRAINING,
    onSubmit: handleSubmit,
    validationSchema
  });

  const handleChangeSelect = (name: string, value: OptionReactSelect) => {
    formik.setFieldValue(name, value);
  };

  const handleFile = (data: IFile) => {
    formik.setFieldValue("fileId", data.id);
    formik.setFieldValue("fileName", data.name);
  };

  const handleDeleteEmployeeSelect = () => {
    const newSelectedData: IEmployeeSelectList[] = [];
    employeeList?.forEach((row) => {
      if (!row?.isParentChecked) {
        newSelectedData.push({
          ...row,
          data: (row?.data || []).filter(
            (oldItem) => !employeeChecked?.some((removeItem) => oldItem?.employeeId === removeItem?.employeeId)
          )
        });
      }
    });

    setEmployeeList(newSelectedData);
    setEmployeeChecked([]);
  };

  const convertDataEmployeeSelect = (data: EmployeeProfile[]): IEmployeeSelect[] => {
    return data?.map((item) => {
      return {
        tenNhanVien: item?.name || "",
        employeeId: item?.id || "",
        maNhanVien: item?.maNhanVien || "",
        phongBanId: item?.phongBanId || "",
        phongBanText: item?.phongBanText || "",
        chucDanhId: item?.viTriCongViecId || "",
        chucDanhText: item?.viTriCongViecText || "",
        viTriCongViecId: item?.viTriCongViecId || "",
        viTriCongViecText: item?.viTriCongViecText || "",
        chucVuId: item?.chucVuId || "",
        chucVuText: item?.chucVuText || "",
        donViCongTacId: item?.donViCongTacId || "",
        donViCongTacText: item?.donViCongTacText || ""
      };
    });
  };

  const columns: columnNamesCollapseType[] = [
    {
      name: lang("TABLE.INDEX"),
      field: "stt",
      headerCellProps: {
        minWidth: 50
      },
      bodyCellProps: {
        textAlign: "center"
      },
      render: (row: any, index: number) => <span>{index + 1}</span>
    },
    {
      name: lang("INPUT.CODE"),
      field: "maNhanVien",
      headerCellProps: {
        minWidth: 120
      },
      bodyCellProps: {
        textAlign: "center"
      },
      render: (row: any) => <span className="color-steel-blue fw-600">{row?.maNhanVien}</span>
    },
    {
      name: lang("INPUT.FULLNAME"),
      field: "name",
      headerCellProps: {
        minWidth: 200
      }
    },
    {
      name: lang("PROFILE.ROLE"),
      field: "chucVuText",
      headerCellProps: {
        minWidth: 250
      }
    },
    {
      name: lang("GENERAL.EMPLOYEE.TITLE"),
      field: "viTriCongViecText",
      headerCellProps: {
        minWidth: 150
      }
    }
  ];

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <div className="header-form-action">
          <GroupButton type="btn-back" handleClose={handleCloseDialog} />
          <div className="flex">
            {dataEdit?.trangThai?.code !== MISSION_STATUS_CODE.COMPLETED &&
              (isView ? (hasAuthority(PERMISSIONS.CU_DAO_TAO, PERMISSION_ABILITY.UPDATE) &&
                <GroupButton type="btn-edit" handleSaveEdit={handleOpenUpdateDialog} />
              ) : (
                <>
                  {dataEdit.id && (
                    <GroupButton type="btn-cancel" className="me-2" handleCloseUpdateDialog={handleCloseUpdateDialog} />
                  )}
                  <GroupButton type="btn-save" handleClose={handleCloseUpdateDialog} />
                </>
            ))}
          </div>
        </div>
        <div className="form-content-scroll">
          <Row>
            <Col xs={12} className="relative z-index-2">
              <div className="sub-title-form">
                {dataEdit?.id ? lang("SEND_PEOPLE_TRAINING.UPDATE") : lang("SEND_PEOPLE_TRAINING.ADD")}
              </div>
              {dataEdit?.id && (
                <>
                  <div className="spaces">
                    <span className="fs-3 fw-bold">{lang("PROFILE.TITLE")}</span>
                  </div>
                  <Row className="spaces mb-14">
                    <Col sm={4} md={4} lg={4} xl={3} xxl={2} className="spaces pt-6">
                      <TextValidator
                        lable={lang("PROFILE.NAME")}
                        type="text"
                        name="tenNhanVien"
                        value={formik.values?.tenNhanVien}
                        readOnly={true}
                      />
                    </Col>
                    <Col sm={3} md={3} lg={3} xl={2} xxl={2} className="spaces pt-6">
                      <TextValidator
                        name="maNhanVien"
                        lable={lang("PROFILE.CODE")}
                        type="text"
                        value={formik.values?.maNhanVien}
                        readOnly={true}
                      />
                    </Col>
                    <Col sm={5} md={5} lg={5} xl={3} xxl={3} className="spaces pt-6">
                      <TextValidator
                        name="donViCongTacText"
                        lable={lang("GENERAL.CURRENT_ORGANIZATION")}
                        type="text"
                        value={formik.values?.donViCongTacText}
                        readOnly={true}
                      />
                    </Col>
                    <Col sm={6} md={6} lg={6} xl={4} xxl={3} className="spaces pt-6">
                      <TextValidator
                        name="phongBanText"
                        lable={lang("INPUT.DEPARTMENTS")}
                        type="text"
                        value={formik.values?.phongBanText}
                        readOnly={true}
                      />
                    </Col>
                    <Col sm={6} md={6} lg={6} xl={3} xxl={2} className="spaces pt-6">
                      <TextValidator
                        name="chucDanhText"
                        lable={lang("GENERAL.EMPLOYEE.TITLE")}
                        type="text"
                        value={formik.values?.chucDanhText}
                        readOnly={true}
                        errors={formik?.errors?.chucDanhText}
                        touched={formik?.touched?.chucDanhText}
                      />
                    </Col>
                  </Row>
                </>
              )}
              <div className="spaces">
                <span className="fs-3 fw-bold">{lang("SEND_PEOPLE_TRAINING.INFO")}</span>
              </div>
              <Row>
                <Col xs={12} sm={6} md={4} lg={3} xl={3} xxl={3} className="spaces pt-6">
                  <TextValidator
                    lable={lang("SEND_PEOPLE_TRAINING.NAME")}
                    type="text"
                    name="tenKhoaDaoTao"
                    value={formik.values?.tenKhoaDaoTao}
                    onChange={formik.handleChange}
                    readOnly={isView}
                    touched={formik.touched?.tenKhoaDaoTao}
                    errors={formik.errors?.tenKhoaDaoTao}
                  />
                </Col>
                <Col xs={12} sm={6} md={4} lg={3} xl={3} xxl={3} className="spaces pt-6">
                  <TextValidator
                    isRequired
                    lable={lang("SEND_PEOPLE_TRAINING.LOCATION")}
                    type="text"
                    name="noiDaoTao"
                    value={formik.values?.noiDaoTao}
                    onChange={formik.handleChange}
                    readOnly={isView}
                    touched={formik.touched?.noiDaoTao}
                    errors={formik.errors?.noiDaoTao}
                  />
                </Col>
                <Col xs={12} sm={6} md={4} lg={4} xl={4} xxl={3} className="spaces pt-6">
                  <RangeDatePicker
                    isRequired
                    label={lang("INPUT.ETHNIC_LANGUAGE.TRAINING_TIME")}
                    startDateName="hieuLucTuNgay"
                    endDateName="hieuLucDenNgay"
                    isView={isView}
                    handleChange={formik.handleChange}
                    value={formik.values}
                    setFieldValue={formik.setFieldValue}
                    touch={formik.touched}
                    errors={formik.errors}
                  />
                </Col>
                <Col xs={6} sm={6} md={4} lg={2} xl={2} xxl={1} className="spaces pt-6">
                  <TextValidator
                    lable={lang("SEND_PEOPLE_TRAINING.NUMBER_OF_TRAINING")}
                    type="number"
                    onKeyDown={justAllowNumber}
                    name="soTiet"
                    value={formik.values?.soTiet || ""}
                    onChange={formik.handleChange}
                    readOnly={isView}
                    touched={formik.touched?.soTiet}
                    errors={formik.errors?.soTiet}
                  />
                </Col>
                <Col xs={6} md={4} lg={2} xl={2} xxl={2} className="spaces pt-6">
                  <TextValidator
                    name="ngayQuyetDinh"
                    lable={lang("INPUT_APPOINTMENT_CONFIRM_DATE")}
                    type="date"
                    value={formik.values?.ngayQuyetDinh}
                    readOnly={isView}
                    onChange={formik.handleChange}
                    onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.ngayQuyetDinh, "ngayQuyetDinh")}
                    errors={formik?.errors?.ngayQuyetDinh}
                    touched={formik?.touched?.ngayQuyetDinh}
                  />
                </Col>
                <Col xs={6} md={4} lg={2} xl={2} xxl={3} className="spaces pt-6">
                  <TextValidator
                    lable={lang("INPUT.WORKPROCESS.DECISION.NUMBER")}
                    type="text"
                    name="soQuyetDinh"
                    value={formik.values?.soQuyetDinh}
                    onChange={formik.handleChange}
                    readOnly={isView}
                    touched={formik.touched?.soQuyetDinh}
                    errors={formik.errors?.soQuyetDinh}
                  />
                </Col>
                <Col xs={6} sm={6} md={4} lg={2} xl={2} xxl={3} className="spaces pt-6">
                  <Autocomplete
                    lable={lang("GENERAL.STATUS")}
                    options={MISSION_STATUS}
                    isReadOnly={isView}
                    value={formik.values?.trangThai || null}
                    name="trangThai"
                    isRequired
                    onChange={(value) => handleChangeSelect(VARIABLE_STRING.TRANG_THAI, value)}
                    isClearable={false}
                    touched={formik.touched?.trangThai}
                    errors={formik.errors?.trangThai}
                  />
                </Col>
                <Col xs={12} sm={6} md={4} lg={3} xl={3} xxl={3} className="spaces pt-6">
                  <UploadFile
                    label="INPUT_APPOINTMENT_ATTACHMENTS"
                    setValue={handleFile}
                    fileValue={{
                      id: formik.values?.fileId || "",
                      name: formik.values?.fileName || ""
                    }}
                    isReadOnly={isView}
                  />
                </Col>
                <Col xs={12} sm={6} md={4} lg={3} xl={3} xxl={3} className="spaces pt-6">
                  <Autocomplete
                    lable={lang("APPRECIATIVE.NGUOIQUYETDINHTEXT")}
                    isReadOnly={isView}
                    options={peopleDecisions}
                    isAddNew
                    onAddNew={() => setIsShowDialogAddRepresentative(true)}
                    value={formik.values?.nguoiKy || null}
                    name="nguoiKy"
                    onChange={(selectedOption) => handleChangeSelect("nguoiKy", selectedOption)}
                    errors={formik.errors?.nguoiKy}
                    touched={formik.touched?.nguoiKy}
                  />
                </Col>
                <Col xs={12} lg={6} className="spaces pt-6">
                  <TextValidator
                    lable={lang("GENERAL.DEPARTMENT_RECEIVE")}
                    name="khoaPhongNhanQuyetDinh"
                    value={formik.values.khoaPhongNhanQuyetDinh || ""}
                    type="text"
                    readOnly={isView}
                    rows={3}
                    onChange={formik.handleChange}
                    as={TYPE.TEXTAREA}
                  />
                </Col>
                <Col xs={12} lg={6} className="spaces pt-6">
                  <TextValidator
                    lable={lang("GENERAL.NOTE")}
                    type="text"
                    name="note"
                    value={formik.values?.note || ""}
                    onChange={formik.handleChange}
                    readOnly={isView}
                    as={TYPE.TEXTAREA}
                    rows={3}
                    touched={formik.touched?.note}
                    errors={formik.errors?.note}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          {!dataEdit?.id && (
            <Row className="mt-4">
              <TableCollapseCustom
                title={lang("ISSUE.LIST_EMPLOYEES")}
                columnNameList={columns}
                data={employeeList || []}
                nameParent="phongBanText"
                nameChildren="data"
                selectData={setEmployeeChecked}
                setData={setEmployeeList}
                selectionMode="multiple"
                isSelect={true}
                buttonAdd={!isView}
                handleOpenDialog={() => setShouldOpenSelectEmployeeDialog(true)}
                handleDelete={handleDeleteEmployeeSelect}
                notDelete={isView}
                uniquePrefix="employeeId"
                disabledSelect={isView}
              />
            </Row>
          )}
        </div>
      </Form>
      {shouldOpenSelectEmployeeDialog && (
        <SelectEmployeeByDepartment
          title={lang("GENERAL.LIST_EMPLOYEE_BY_DEPARTMENT")}
          open={shouldOpenSelectEmployeeDialog}
          handleClose={() => setShouldOpenSelectEmployeeDialog(false)}
          parentSelectedData={employeeList || []}
          setParentSelectedData={setEmployeeList}
          funcConvert={convertDataEmployeeSelect}
          prefixNameChildren="data"
          searchEmployeeObject={{
            trangThaiLamViec: Object.values(STATUS_NV)
              .filter((status) => status !== STATUS_NV.THOI_VIEC)
              .join(",")
          }}
        />
      )}
      {isShowDialogAddRepresentative && (
        <AddNewRepresentative
          handleClose={() => setIsShowDialogAddRepresentative(false)}
          handleCloseAndSearch={handleCloseAndSearchRepresentative}
        />
      )}
    </>
  );
}

export default SendPeopleTrainingDialog;

import { FC, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { IRole } from "../models/managerModels";
import { deleteRole, searchAllRole } from "../services/managerServices";
import { toast } from "react-toastify";
import { RoleDialog } from "./dialogs/RoleDialog";
import { hasAuthority } from "../../utils/functionUtils";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../../constants";
import TableCustom from "../../components/table-custom/TableCustom";
import { RESPONSE_STATUS_CODE, TYPE } from "../../constants/moduleConsts";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { INIT_SEARCH_OBJECT } from "../../constants/moduleConsts";
import { GroupButton } from "../../components/GroupButton";
import InputSearch from "../../components/input/InputSearch";

const RoleList: FC = () => {
  const { lang } = useMultiLanguage();

  const [roleInfo, setRoleInfo] = useState<IRole>({} as IRole);
  const [roleList, setRoleList] = useState<IRole[]>([]);
  const [openRoleDialog, setOpenRoleDialog] = useState<boolean>(false);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [searchObject, setSearchObject] = useState<any>(INIT_SEARCH_OBJECT);

  const handleOpenRoleDialog = (data: IRole) => {
    setOpenRoleDialog(true);
    setRoleInfo(data);
  };

  const handleCloseRoleDialog = () => {
    setOpenRoleDialog(false);
    setRoleInfo({} as IRole);
  };

  const handleCloseAndSearchDialogRole = () => {
    handleCloseRoleDialog();
    handleSearch(searchObject);
  }

  useEffect(() => {
    if(searchObject?.pageIndex && searchObject?.pageSize){
      handleSearch(searchObject);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchObject?.pageIndex, searchObject?.pageSize]);

  const loadListRole = async (searchObject?: any) => {
    try {
      setSearchObject({ ...searchObject });
      const { data } = await searchAllRole(searchObject);
      if(data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setRoleList(data?.data?.content);
        setTotalPage(data?.data?.pageable?.totalPages);
        setTotalElements(data?.data?.pageable?.totalElements);
        setNumberOfElements(data?.data?.pageable?.numberOfElements);
      } else {
        toast.warning(data?.message);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const handleDeleteRole = async (ids: string[]) => {
    try {
      const { data } = await deleteRole(ids);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("GENERAL.DELETE_SUCCESS"));
        await loadListRole(searchObject)
        return true;
      } else toast.warning(data?.message);
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }

  const handleSearch = (data: any = {}) => {
    data.keyword = data?.keyword?.trim() || "";
    loadListRole(data);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchObject((prev: any) => {
      return {
        ...prev,
        keyword: e.target?.value
      }
    });
  };

  const roleColumn = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("ROLE.CODE"),
      field: "name",
      cellStyle: {
        textAlign: "left",
        minWidth: "170px"
      }
    },
    {
      name: lang("ROLE.NAME"),
      field: "description",
      cellStyle: {
        textAlign: "left",
        minWidth: "170px"
      }
    },
    {
      name: lang("AUTH"),
      field: "authorities",
      cellStyle: {
        textAlign: "left",
        minWidth: "400px"
      },
      render: (row: any, index: number, stt: number) =>
        row.authorities?.length > 0 &&
        row.authorities.map((item: any, index: number) => (
          <span key={index}>
            <span>{item}</span>
            <span className="color-red spaces fw-600">; </span>
          </span>
        ))
    }
  ];

  return (
    <>
      <div className="spaces pt-10 role-auth">
        <span className="spaces fs-18 fw-600">{lang("ROLE.LIST")}</span>
        <Row className="flex-space-between">
          <Col xs={4} md={3} lg={2} className="mt-3">
            {hasAuthority(PERMISSIONS.ROLE, PERMISSION_ABILITY.CREATE) &&
              <GroupButton handleEvent={() => setOpenRoleDialog(true)}>
                <i className="spaces bi bi-plus fs-20 white pr-0"></i>
                <p className="spaces fs-14 m-0 ">{lang("BTN.ADD")}</p>
              </GroupButton>
            }
          </Col>
          <Col xs={8} md={5} xl={4} xxl={3} className="flex mt-3">
            <div className="spaces mr-10 flex-1">
              <InputSearch
                className="border-3px"
                value={searchObject?.keyword}
                placeholder="Tìm kiếm theo tên vai trò"
                isEnter={true}
                handleSearch={() => handleSearch({ ...searchObject, pageIndex: 1 })}
                handleChange={handleChange}
              />
            </div>
            <GroupButton handleEvent={() => handleSearch({ ...searchObject, pageIndex: 1 })}>
              {lang("BTN.SEARCH")}
            </GroupButton>
          </Col>
        </Row>
        <div className="table-custom">
          <TableCustom
            id="roleList"
            data={roleList}
            columns={roleColumn}
            searchObject={searchObject}
            setSearchObject={setSearchObject}
            buttonAdd={false}
            notDelete={!hasAuthority(PERMISSIONS.ROLE, PERMISSION_ABILITY.DELETE)}
            type={TYPE.MULTILINE}
            totalPages={totalPage}
            totalElements={totalElements}
            numberOfElements={numberOfElements}
            handleDoubleClick={handleOpenRoleDialog}
            handleDelete={(ids: any) => handleDeleteRole(ids)}
            justFilter={false}
            noToolbar
          />
        </div>
      </div>
      {openRoleDialog && (
        <RoleDialog
          handleCloseDialog={handleCloseRoleDialog}
          roleInfo={roleInfo}
          handleCloseAndSearchDialogRole={handleCloseAndSearchDialogRole}
        />
      )}
    </>
  );
};
export { RoleList };

import { OptionReactSelect } from "../../models/models";
import { TYPE, TYPE_CATEGORY } from "../../constants/moduleConsts";
import { ReceivableInfo, ResignInfo, ResignTab } from "../models/resignModels";
import { searchAllSimpleValue, searchPhongBan } from "../../services/services";

export const listStatusResign = [
  {
    code: 1,
    styleClass: "bg-gray-700",
    name: "Chưa thực hiện"
  },
  {
    code: 3,
    styleClass: "bg-green",
    name: "Đã thực hiện"
  }
]

export const INIT_RESIGN_INFO: ResignInfo = {
  employee: null,
  maNhanVien: "",
  employeeId: "",
  phongBan: null,
  phongBanId: "",
  phongBanText: "",
  chucVuId: "",
  chucVuText: "",
  chucDanhId: "",
  chucDanhText: "",
  doiTuongKhenThuong: null,
  doiTuongKhenThuongId: "",
  doiTuongKhenThuongText: "",
  createDateTime: null,
  createdBy: "",
  loaiThoiViec: null,
  loaiThoiViecId: "",
  loaiThoiViecText: "",
  trangThaiBaoHiem: null,
  trangThaiBaoHiemId: "",
  trangThaiBaoHiemText: "",
  troCapThoiViec: null,
  troCapMatViec: null,
  cacKhoanTraKhac: null,
  cacKhoanThuKhac: null,
  thangKhauTruOption: null,
  namKhauTruOption: null,
  coPhieuXacNhan: false,
  ngayQuyetDinh: "",
  ngayHieuLuc: "",
  soQuyetDinh: "",
  nguoiKy: null,
  nguoiKyId: "",
  nguoiKyText: "",
  lyDoThoiViec: "",
  daBoSungHoSo: false,
  daHoanTatThuTuc: false,
  fileId: "",
  fileName: "",
  trangThai: null,
  id: "",
  modifiedBy: "",
  modifyDate: null,
  ghiChu: "",
  thoiGianDungDongBH: "",
  hinhThucDieuChinh: null,
  thoiGianThucHien: "",
  raQuyetDinh: false,
  khoaPhongNhanQuyetDinh: "",
  listKhoanThu: []
}

export enum REF_TAB_RESIGN {
  THOI_VIEC = "#kt_tab_pane_1",
  GIAN_DOAN = "#kt_tab_pane_2"
}

export const resignTabs: ResignTab[] = [
  {
    id: 1,
    name: "Thôi việc",
    ref: REF_TAB_RESIGN.THOI_VIEC
  },
  {
    id: 2,
    name: "Gián đoạn",
    ref: REF_TAB_RESIGN.GIAN_DOAN
  }
];

export const INIT_RECEIVABLE_INFO: ReceivableInfo = {
  khoanThu: "",
  giaTri: null,
  ghiChu: ""
}

export const RESIGN_TYPE = {
  TINH_GIAN_BIEN_CHE: 1,
  KET_THUC_HOP_DONG: 2,
  NGHI_HUU: 3,
  KY_LUAT_BUOC_THOI_VIEC: 4,
  CHET: 5,
  NGHI_THEO_NGUYEN_VONG: 6,
  LUAN_CHUYEN_DIEU_DONG: 7,
  LY_DO_KHAC: 8
}

export const listResignType: OptionReactSelect[] = [
  {
    code: RESIGN_TYPE.TINH_GIAN_BIEN_CHE,
    name: "Tinh giản biên chế"
  },
  {
    code: RESIGN_TYPE.KET_THUC_HOP_DONG,
    name: "Nghỉ kết thúc hợp đồng"
  },
  {
    code: RESIGN_TYPE.NGHI_HUU,
    name: "Nghỉ hưu"
  },
  {
    code: RESIGN_TYPE.KY_LUAT_BUOC_THOI_VIEC,
    name: "Kỷ luật buộc thôi việc"
  },
  {
    code: RESIGN_TYPE.CHET,
    name: "Chết"
  },
  {
    code: RESIGN_TYPE.NGHI_THEO_NGUYEN_VONG,
    name: "Nghỉ theo nguyện vọng"
  },
  {
    code: RESIGN_TYPE.LUAN_CHUYEN_DIEU_DONG,
    name: "Luân chuyển/Điều động"
  },
  {
    code: RESIGN_TYPE.LY_DO_KHAC,
    name: "Lý do khác"
  },
]

export const listResignTypeInsurance: OptionReactSelect[] = [
  {
    code: 1,
    name: "Không thay đổi bảo hiểm"
  },
  {
    code: 2,
    name: "Dừng đóng bảo hiểm"
  },
  {
    code: 3,
    name: "Báo giảm bảo hiểm"
  },
]

export const listFormOfAdjustment: OptionReactSelect[] = [
  {
    code: 1,
    name: "Giảm"
  }
]

export const RESIGN = {
  STATUS: {
    UNFINISH: 1,
    FINISH: 3
  }
}

export enum INSURANCE_TYPE_CODE {
  KHONG_THAY_DOI = 1,
  DUNG_DONG = 2,
  BAO_GIAM = 3
}


export const FIELD_SEARCH_RESIGN = [
  {
    name: "Mã nhân viên",
    field: "maNhanVien",
    type: TYPE.TEXT
  },
  {
    name: "Họ và tên",
    field: "fullName",
    type: TYPE.TEXT
  },
  {
    name: "Khoa/Phòng",
    field: "phongBanIds",
    searchFunction: searchPhongBan,
    type: TYPE.SELECT,
    isMulti: true,
  },
  {
    name: "Chức vụ",
    field: "chucVuId",
    searchFunction: searchAllSimpleValue,
    searchObject: { type: TYPE_CATEGORY.chucDanh },
    type: TYPE.SELECT,
    optionLabel: "value"
  },
  {
    name: "Mã Ngạch/Chức Danh",
    field: "viTriCongViecIds",
    searchFunction: searchAllSimpleValue,
    searchObject: { type: TYPE_CATEGORY.viTriCongViec },
    type: TYPE.SELECT,
    optionLabel: "value",
    isMulti: true,
  },
  {
    name: "Loại thôi việc",
    field: "loaiThoiViec",
    type: TYPE.SELECT,
    listOption: listResignType
  },
  {
    name: "Ngày thôi việc",
    field: "ngayHieuLuc",
    type: TYPE.DATE
  },
  {
    name: "Số quyết định",
    field: "soQuyetDinh",
    type: TYPE.TEXT
  },
  {
    name: "Trạng thái",
    field: "trangThai",
    type: TYPE.SELECT,
    listOption: listStatusResign,
    isMulti: true,
  }
];
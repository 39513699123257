import {
  IDeclareAssets,
  ILandUse,
  IMinorChildren,
  IHousing,
  IOtherAssetsLand,
  IGemStones,
  IMoney,
  IStock,
  IAbroadAccount,
  IOtherAssets,
  IFluctuation
} from "../models/declareAssetsModels";
import { DECLARE_ASSETS_STATUS, DECLARE_ASSETS_TYPES } from "./declareAssetsConsts";

export const INIT_DECLARE_ASSETS: IDeclareAssets = {
  id: "",
  donViCongTacId: "",
  donViCongTacText: "",
  employeeId: "",
  birthdate: "",
  chucVuId: "",
  chucVuText: "",
  loaiKeKhai: DECLARE_ASSETS_TYPES[0],
  ngayKeKhai: "",
  tongThuNhap: 0,
  trangThai: DECLARE_ASSETS_STATUS[0],
  vcThuNhap: 0,
  viTriCongViecId: "",
  viTriCongViecText: "",
  tenNhanVien: "",
  thuNhap: 0,
  conCaiChuaThanhNien: 0,
  employee: null,
  diaChiThuongChu: "",
  soCmndCccd: "",
  soCmndCccdNoiCap: "",
  soCmndCccdNgayCap: "",
  vcBirthdate: "",
  vcChucVuId: "",
  vcChucVuText: "",
  vcDiaChiThuongChu: "",
  vcDonViCongTacId: "",
  vcDonViCongTacText: "",
  vcHoTen: "",
  vcSoCmndCccd: "",
  vcSoCmndCccdNoiCap: "",
  vcSoCmndCccdNgayCap: "",
  vcViTriCongViecId: "",
  vcViTriCongViecText: "",
  maNhanVien: "",
  conCai: [],
  quyenSuDungDat: [],
  coPhieu: [],
  congTrinhXayDung: [],
  daQuy: [],
  khac: [],
  taiKhoanNganHang: [],
  taiSanTrenDat: [],
  tien: [],
  bienDong: []
};

export const INIT_MINOR_CHILDREN: IMinorChildren = {
  id: "",
  birthdate: "",
  name: "",
  noiThuongTru: "",
  soCmndCccd: "",
  soCmndCccdNoiCap: "",
  soCmndCccdNgayCap: "",
  loaiTaiSan: null
};

export const INIT_LAND_USE: ILandUse = {
  id: "",
  kieu: "",
  name: "",
  dienTich: 0,
  giaTri: 0,
  khongTheUocLuong: false,
  diaChi: "",
  fileId: "",
  fileName: "",
  thongTinKhac: "",
  loaiTaiSan: null,
  kieuObj: null
};

export const INIT_HOUSING: IHousing = {
  id: "",
  loai: "",
  name: "",
  loaiNha: "",
  dienTich: 0,
  giaTri: 0,
  diaChi: "",
  fileId: "",
  fileName: "",
  thongTinKhac: "",
  loaiTaiSan: null,
  loaiObj: null,
  loaiNhaObj: null
};

export const INIT_ORTHER_ASSETS_LAND: IOtherAssetsLand = {
  id: "",
  loai: "",
  name: "",
  dienTich: 0,
  giaTri: 0,
  loaiTaiSan: null,
  loaiObj: null
};

export const INIT_GEM_STONES: IGemStones = {
  id: "",
  name: "",
  giaTri: 0,
  loaiTaiSan: null
};

export const INIT_MONEY: IMoney = {
  id: "",
  name: "",
  giaTri: 0,
  loaiTaiSan: null
};

export const INIT_STOCK: IStock = {
  id: "",
  soLuong: 0,
  loai: "",
  name: "",
  giaTri: 0,
  loaiTaiSan: null,
  loaiObj: null
};

export const INIT_OTHER_ASSETS: IOtherAssets = {
  id: "",
  loai: "",
  name: "",
  soDangKy: "",
  loaiTaiSan: null,
  loaiObj: null,
  giaTri: 0
};

export const INIT_ABROAD_ACCOUNT: IAbroadAccount = {
  id: "",
  soTaiKhoan: "",
  tenNganHang: "",
  name: "",
  loaiTaiSan: null
};

export const INIT_FLUCTUATION: IFluctuation = {
  taiSanId: "",
  tangGiam: null,
  nhomTaiSan: "",
  soLuong: null,
  noiDung: "",
  name: "",
  giaTri: null
};

import React, { ForwardedRef, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";
import TableCustomSpan, { IColumnsSpan } from "../../../../components/table-custom/TableCustomSpan";
import useMultiLanguage from "../../../../../hook/useMultiLanguage";
import { IConditionShift, IPropsShiftSchedule, IRefBangPhanCongDetailList, IRowDataSchedule } from "../../../models/shiftDivisionModels";
import { buildWeekDaysList } from "../../../../utils/dateUtils";
import Autocomplete from "../../../../components/input/autocomplete";
import { variantStandardStyle } from "../../../../components/input/autocomplete/styleJS";
import { generateRandomId, hasAuthority, justAllowNumber } from "../../../../utils/functionUtils";
import { searchShift } from "../../../services/shiftServices";
import { FORMAT_DATE, RESPONSE_STATUS_CODE, SEARCH_OBJECT_MAX_SIZE, TYPE_CATEGORY, VARIABLE_STRING } from "../../../../constants/moduleConsts";
import { toast } from "react-toastify";
import { convertDataShiftSchedule } from "../../../utils/shiftUtils";
import moment from "moment";
import { OptionReactSelect } from "../../../../models/models";
import { TYPE_HANDLE_ROW } from "../../../../components/table-custom/constants/tableCustomSpanConsts";
import { KTSVG } from "../../../../../../_metronic/helpers";
import CustomTooltip from "../../../../components/custom-tooltip";
import { searchAllSimpleValue, searchPhongBan } from "../../../../services/services";
import { MAPPING_NAME_TYPE_DUTY, MAX_EMPLOYEE_DUTY, MIN_EMPLOYEE_DUTY } from "../../../constants/shiftDivisionConsts";
import TextValidator from "../../../../components/input/text-validator";
import { IShift } from "../../../models/shiftModels";
import { LIST_OBJECT_SCHEDULE, TYPE_OBJECT_SCHEDULE } from "../../../constants/shiftComprehensionConsts";
import { LIST_COMPARISON_OPERATOR } from "../../../constants/shiftConsts";
import { PERMISSION_ABILITY, PERMISSIONS } from "../../../../../constants";
import { localStorageItem } from "../../../../utils/localstorage";
import { KEY_LOCALSTORAGE } from "../../../../auth/core/_consts";

const ShiftSchedule = React.memo(forwardRef((props: IPropsShiftSchedule, ref: ForwardedRef<IRefBangPhanCongDetailList>) => {
  const { isView, startDate, endDate, listEmployee, danhSachPhanCa, danhSachDieuKien, doiTuong } = props;
  const { lang } = useMultiLanguage();

  const [columnsFull, setColumnsFull] = useState<IColumnsSpan[]>([]);
  const [maxPrimaryDuty, setMaxPrimaryDuty] = useState<number>(0);
  const [maxPermanentDuty, setMaxPermanentDuty] = useState<number>(0);
  const [listShift, setListShift] = useState<IShift[]>([]);
  const [listDepartment, setListDepartment] = useState<OptionReactSelect[]>([]);
  const [listTypeDuty, setListTypeDuty] = useState<OptionReactSelect[]>([]);
  const [listData, setListData] = useState<IRowDataSchedule[]>([]);
  const [listCondition, setListCondition] = useState<IConditionShift[]>([]);
  const [listDutyRemove, setListDutyRemove] = useState<OptionReactSelect[]>([]);
  const [listDepartmentRemove, setListDepartmentRemove] = useState<OptionReactSelect[]>([]);

  useImperativeHandle(ref, () => ({
    getDataDetail: () => ({ listSchedule: listData.slice(0, listData.length - 1), listCondition })
  }));

  useEffect(() => {
    if(danhSachPhanCa?.length) {
      const listDataConverted = convertDataShiftSchedule(danhSachPhanCa)

      const listOptionRemoveDuty: OptionReactSelect[] = [];
      listDataConverted.forEach(data => {
        data.loaiTruc && listOptionRemoveDuty.push(data.loaiTruc);
      });
      setListDutyRemove(listOptionRemoveDuty);
      
      const listOptionRemoveDepartment: OptionReactSelect[] = [];
      listDataConverted.forEach(data => {
        data.phongBan && listOptionRemoveDepartment.push(data.phongBan);
      });
      setListDepartmentRemove(listOptionRemoveDepartment);

      setListData(listDataConverted);
    }
  }, [danhSachPhanCa, doiTuong]);

  useEffect(() => {
    if((!maxPrimaryDuty && !maxPermanentDuty) || !listTypeDuty.length) return;
    setListData(preListData => {
      const newList = [...preListData];
      const primaryDuty = listTypeDuty.find(item => item?.mappingName === MAPPING_NAME_TYPE_DUTY.TRUC_CHINH);
      const permanentDuty = listTypeDuty.find(item => item?.mappingName === MAPPING_NAME_TYPE_DUTY.THUONG_TRU);
      
      newList.forEach(item => {
        if(
          ((maxPrimaryDuty && item.loaiTruc?.id === primaryDuty?.id) 
          || (maxPermanentDuty && item.loaiTruc?.id === permanentDuty?.id)) 
          && item.addNewRow
        ) {
          item.addNewRow = false;
        }
      });
      return newList;
    });
  }, [listTypeDuty, maxPermanentDuty, maxPrimaryDuty]);

  useEffect(() => {
    if (danhSachDieuKien?.length) {
      setListCondition(danhSachDieuKien);
      const newMaxPrimaryDuty = danhSachDieuKien
        .flatMap(condition => condition.soLuongTrucDtos)
        ?.filter(item => item?.mappingName === MAPPING_NAME_TYPE_DUTY.TRUC_CHINH)
        ?.reduce((max, item) => item.soLuong ? Math.max(max, item.soLuong) : max, 0);

      setMaxPrimaryDuty(newMaxPrimaryDuty);

      const newMaxPermanentDuty = danhSachDieuKien
        .flatMap(condition => condition.soLuongTrucDtos)
        ?.filter(item => item?.mappingName === MAPPING_NAME_TYPE_DUTY.THUONG_TRU)
        ?.reduce((max, item) => item.soLuong ? Math.max(max, item.soLuong) : max, 0);

      setMaxPermanentDuty(newMaxPermanentDuty);
    }
  }, [danhSachDieuKien]);

  useEffect(() => {
    if (isView) {
      setListData(preListData => preListData.slice(0, -1));

      if(listCondition.length === 1) {
        setListCondition(preListCondition => preListCondition.slice(0, -1));
      }
    } else {
      setListData((prevListData => [
        ...prevListData,
        {
          id: generateRandomId(),
          phongBan: null,
          loaiTruc: null,
          caLamViec: null
        }
      ]));

      if(listCondition.length === 0) {
        setListCondition((prevListCondition => [
          ...prevListCondition,
          {
            id: generateRandomId(),
            soLuongTrucDtos: []
          }
        ]));
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isView]);

  useEffect(() => {
    maxPrimaryDuty && setListData(prevListData => {
      const newListData = [...prevListData];
      const primaryDuty = listTypeDuty.find(item => item?.mappingName === MAPPING_NAME_TYPE_DUTY.TRUC_CHINH);
      const primaryDutyRows = newListData.filter(row => row.loaiTruc?.id === primaryDuty?.id);
      const defaultShift = listShift.find(item => item?.caMacDinh);
      if (primaryDutyRows.length > 0) {
        const startIndex = newListData.findIndex(row => row.loaiTruc?.id === primaryDuty?.id);
        if (primaryDutyRows.length < maxPrimaryDuty) {
          newListData.splice(primaryDutyRows.length + startIndex, 0,
            ...Array.from({ length: maxPrimaryDuty - primaryDutyRows.length }, () => ({
              id: generateRandomId(),
              loaiTruc: primaryDuty ?? null,
              caLamViec: newListData[startIndex]?.caLamViec ?? defaultShift ?? null,
              addNewRow: false
            }))
          );
        } else if (primaryDutyRows.length > maxPrimaryDuty) {
          newListData.splice(startIndex + maxPrimaryDuty, primaryDutyRows.length - maxPrimaryDuty);
        }
        return newListData;
      } else {
        newListData.splice(newListData.length - 1, 0,
          ...Array.from({ length: maxPrimaryDuty }, () => ({
            id: generateRandomId(),
            loaiTruc: primaryDuty ?? null,
            caLamViec: defaultShift ?? null,
            addNewRow: false
          }))
        );
        primaryDuty && setListDutyRemove(preList => [...preList, primaryDuty]);
        return newListData;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxPrimaryDuty]);

  useEffect(() => {
    maxPermanentDuty && setListData(prevListData => {
      const newListData = [...prevListData];
      const permanentDuty = listTypeDuty.find(item => item?.mappingName === MAPPING_NAME_TYPE_DUTY.THUONG_TRU);
      const permanentDutyRows = newListData.filter(row => row.loaiTruc?.id === permanentDuty?.id);
      const defaultShift = listShift.find(item => item?.caMacDinh);
      if (permanentDutyRows.length > 0) {
        const startIndex = newListData.findIndex(row => row.loaiTruc?.id === permanentDuty?.id);
        if (permanentDutyRows.length < maxPermanentDuty) {
          newListData.splice(permanentDutyRows.length + startIndex, 0,
            ...Array.from({ length: maxPermanentDuty - permanentDutyRows.length }, () => ({
              id: generateRandomId(),
              loaiTruc: permanentDuty ?? null,
              caLamViec: defaultShift ?? null,
              addNewRow: false
            }))
          );
        } else if (permanentDutyRows.length > maxPermanentDuty) {
          newListData.splice(startIndex + maxPermanentDuty, permanentDutyRows.length - maxPermanentDuty);
        }
        return newListData;
      } else {
        newListData.splice(newListData.length - 1, 0,
          ...Array.from({ length: maxPermanentDuty }, () => ({
            id: generateRandomId(),
            loaiTruc: permanentDuty ?? null,
            caLamViec: defaultShift ?? null,
            addNewRow: false
          }))
        );
        permanentDuty && setListDutyRemove(preList => [...preList, permanentDuty]);
        return newListData;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxPermanentDuty]);

  const getListShift = useCallback(async () => {
    try {
      const { data } = await searchShift({ ...SEARCH_OBJECT_MAX_SIZE });
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListShift(data?.data?.content || []);
      } else {
        toast.warning(data?.message);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }, [lang]);

  useEffect(() => {
    getListShift();
  }, [getListShift]);

  const getListDepartment = useCallback(async () => {
    try {
      const { data } = await searchPhongBan({ ...SEARCH_OBJECT_MAX_SIZE });
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListDepartment(data?.data?.content || []);
      } else {
        toast.warning(data?.message);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }, [lang]);

  useEffect(() => {
    if(doiTuong === TYPE_OBJECT_SCHEDULE.BAC_SI) {
      if(hasAuthority(PERMISSIONS.DEPARTMENT, PERMISSION_ABILITY.VIEW)) {
        const employee = localStorageItem.get(KEY_LOCALSTORAGE.EMPLOYEE);
        setListDepartment([{
          id: employee?.phongBanId,
          name: employee?.phongBanText
        }]);
      } else getListDepartment();
    }
  }, [doiTuong, getListDepartment]);

  const getListTypeDuty = useCallback(async () => {
    try {
      const { data } = await searchAllSimpleValue({ type: TYPE_CATEGORY.loaiTruc });
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListTypeDuty(data?.data?.content || []);
      } else {
        toast.warning(data?.message);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }, [lang]);

  useEffect(() => {
    getListTypeDuty();
  }, [getListTypeDuty]);

  const handleDeleteRowSchedule = useCallback((row: any, itemList: any[]) => {
    if(doiTuong === TYPE_OBJECT_SCHEDULE.DIEU_DUONG) {
      setListDutyRemove(preList => preList.filter(item => item.id !== row?.loaiTruc?.id));
    } else {
      setListDepartmentRemove(preList => preList.filter(item => item.id !== row?.phongBan?.id));
    }
    setListData(itemList.filter(item => item.id !== row?.id));
  }, [doiTuong]);

  const handleDeleteCondition = (id: string) => {
    setListCondition(listCondition.filter(item => item.id !== id));
  }
  
  const handleChangeSelectSchedule = useCallback((name: string, value: any, itemList: any, row?: any) => {
    let newData = [...itemList];
    let indexRow = newData.findIndex((data: any) => {
      return data?.id === row.id;
    });

    switch (name) {
      case VARIABLE_STRING.PHONG_BAN:
        if (!value) {
          newData = newData.filter((data: any) => (data.id !== row?.id && data.phongBan?.id !== row.phongBan?.id));
          setListDepartmentRemove(preList => preList.filter(item => item.id !== row.phongBan?.id));
        } else {
          setListDepartmentRemove(preList => [...preList.filter(item => item.id !== newData[indexRow][name]?.id), value]);
          newData = newData.map(data => data?.[name]?.id === newData[indexRow][name]?.id ? { ...data, [name]: value } : data);
          !itemList[indexRow][name] && newData.push({ id: generateRandomId(), phongBan: null });
          newData[indexRow].addNewRow = true;
        }
        break;
      case VARIABLE_STRING.LOAI_TRUC:
        if(doiTuong === TYPE_OBJECT_SCHEDULE.DIEU_DUONG) {
          if (!value) {
            newData = newData.filter((data: any) => (data.id !== row?.id && data.loaiTruc?.id !== row.loaiTruc?.id));
            setListDutyRemove(preList => preList.filter(item => item.id !== row.loaiTruc?.id));
          } else {
            setListDutyRemove(preList => [...preList.filter(item => item.id !== newData[indexRow][name]?.id), value]);
            newData = newData.map(data => data?.[name]?.id === newData[indexRow][name]?.id ? { ...data, [name]: value } : data);
            !itemList[indexRow][name] && newData.push({ id: generateRandomId(), loaiTruc: null });
          }
        } else {
          newData[indexRow][name] = value;
        }
        break;
      case VARIABLE_STRING.CA_LAM_VIEC:
        newData[indexRow] = {
          ...newData[indexRow],
          [name]: value,
          ...doiTuong === TYPE_OBJECT_SCHEDULE.DIEU_DUONG && { addNewRow: Boolean(value) }
        };
        break;
      default:
        newData[indexRow][name] = value;
    }

    setListData(newData);
  }, [doiTuong]);

  const handleFillColumns = useCallback((columns: IColumnsSpan[], employees: OptionReactSelect[]) => {
    const listWeekDay = buildWeekDaysList(startDate, endDate);
    const newColumns = [...columns];
    listWeekDay.forEach(item => {
      newColumns.push({
        name: `${lang(item.weekday)}<br />${moment(item.day).format(FORMAT_DATE.DD)}`,
        field: item.day,
        headerStyle: {
          minWidth: "140px"
        },
        cellStyle: {
          textAlign: "left"
        },
        render: (row, _index, _stt, itemList) => (
          isView ?
            <div className="text-center spaces h-20">{row[item.day]?.name}</div> :
            <Autocomplete
              className="variant-standard"
              options={employees}
              isDisabled={!row?.loaiTruc}
              value={row[item.day] || null}
              onChange={(value) => handleChangeSelectSchedule(item.day, value, itemList, row)}
              styles={variantStandardStyle("30px")}
              menuPortalTarget={document.body}
            />
        )
      });
    });

    !isView && newColumns.push({
      name: lang("TABLE.ACTION"),
      field: "action",
      headerStyle: {
        minWidth: "80px"
      },
      cellStyle: {
        textAlign: "center",
        verticalAlign: "middle"
      },
      render: (row, _index, _stt, itemList) => (
        (doiTuong === TYPE_OBJECT_SCHEDULE.DIEU_DUONG ? row?.loaiTruc : row?.phongBan) ?
          <CustomTooltip title={lang("DELETE.ROW")}>
            <span className="cursor-pointer" onClick={() => handleDeleteRowSchedule(row, itemList)}>
              <KTSVG
                className="color-red"
                path="/media/icons/trash.svg"
                svgClassName='spaces w-20 h-20'
              />
            </span>
          </CustomTooltip> : null
      )
    });

    setColumnsFull(newColumns);
  }, [doiTuong, endDate, handleChangeSelectSchedule, handleDeleteRowSchedule, isView, lang, startDate]);
  
  const getBaseColumns = useCallback((
      listDuty: OptionReactSelect[], 
      listShift: IShift[], 
      listOptionRemoveTypeSchedule: OptionReactSelect[],
      listDepartment: OptionReactSelect[] = [],
      listOptionRemoveDepartment: OptionReactSelect[] = []
    ): IColumnsSpan[] => ([
      ...doiTuong === TYPE_OBJECT_SCHEDULE.BAC_SI ? [
        {
          name: lang("INPUT.DEPARTMENTS"),
          field: VARIABLE_STRING.PHONG_BAN,
          headerStyle: {
            minWidth: "250px"
          },
          cellStyle: {
            textAlign: "left",
            verticalAlign: "top"
          },
          render: (row, _index, _stt, itemList) => (
            isView ?
              <span>{row?.phongBan?.name}</span> :
              <Autocomplete
                name={VARIABLE_STRING.PHONG_BAN}
                options={listDepartment}
                removeOption={listOptionRemoveDepartment}
                value={row?.phongBan || null}
                onChange={(value => handleChangeSelectSchedule(VARIABLE_STRING.PHONG_BAN, value, itemList, row))}
                styles={variantStandardStyle("30px")}
                menuPortalTarget={document.body}
              />
          )
        },
      ] as IColumnsSpan[] : [] as IColumnsSpan[],
      {
        name: lang("TIMEKEEPING.SHIFT.SCHEDULE.TYPE"),
        field: VARIABLE_STRING.LOAI_TRUC,
        headerStyle: {
          minWidth: "150px"
        },
        cellStyle: {
          textAlign: "left",
          verticalAlign: "top"
        },
        render: (row, _index, _stt, itemList) => (
          isView ?
            <span>{row?.loaiTruc?.value}</span> :
            <Autocomplete
              name={VARIABLE_STRING.LOAI_TRUC}
              getOptionLabel={(option: any) => option?.value}
              options={listDuty}
              isDisabled={doiTuong === TYPE_OBJECT_SCHEDULE.BAC_SI && !row?.phongBan}
              removeOption={listOptionRemoveTypeSchedule}
              value={row?.loaiTruc || null}
              onChange={(value => handleChangeSelectSchedule(VARIABLE_STRING.LOAI_TRUC, value, itemList, row))}
              styles={variantStandardStyle("30px")}
              menuPortalTarget={document.body}
            />
        )
      },
      {
        name: lang("TIMEKEEPING.SHIFT.SCHEDULE.SHIFT"),
        field: VARIABLE_STRING.CA_LAM_VIEC,
        headerStyle: {
          minWidth: "150px"
        },
        cellStyle: {
          textAlign: "left",
          verticalAlign: "top"
        },
        render: (row, _index, _stt, itemList) => (
          isView ?
            <span>{row?.caLamViec?.tenCa}</span> :
            <Autocomplete
              name={VARIABLE_STRING.CA_LAM_VIEC}
              options={listShift}
              getOptionLabel={(option: any) => option?.tenCa}
              isDisabled={!row?.loaiTruc}
              value={row?.caLamViec || null}
              onChange={(value => handleChangeSelectSchedule(VARIABLE_STRING.CA_LAM_VIEC, value, itemList, row))}
              styles={variantStandardStyle("30px")}
              menuPortalTarget={document.body}
              isClearable={false}
            />
        )
      }
    ]
  ), [doiTuong, handleChangeSelectSchedule, isView, lang]);

  useEffect(() => {
    handleFillColumns(getBaseColumns(listTypeDuty, listShift, listDutyRemove, listDepartment, listDepartmentRemove), listEmployee);
  }, [getBaseColumns, handleFillColumns, listDepartment, listDepartmentRemove, listDutyRemove, listEmployee, listShift, listTypeDuty]);

  const handleChangeSelectCondition = (name: string, value: any, condition: IConditionShift) => {
    setListCondition(preListCondition => preListCondition.map(data => data.id === condition?.id ? { ...data, [name]: value } : data));
  }

  const handleChangeCondition = (e: React.ChangeEvent<HTMLInputElement>, condition: IConditionShift) => {
    const { name, value } = e.target;

    switch (name) {
      case VARIABLE_STRING.TRUC_CHINH:
        if(Number(value) > MAX_EMPLOYEE_DUTY) return;
        setListCondition(preListCondition => {
          const newListCondition = [...preListCondition];
          const indexOfCondition = preListCondition.findIndex(item => item.id === condition?.id);
          const indexPrimaryDuty = newListCondition?.[indexOfCondition]?.soLuongTrucDtos
            ?.findIndex(item => item?.mappingName === MAPPING_NAME_TYPE_DUTY.TRUC_CHINH);
          if (indexOfCondition !== -1 && indexPrimaryDuty !== undefined && indexPrimaryDuty !== -1) {
            newListCondition[indexOfCondition].soLuongTrucDtos[indexPrimaryDuty].soLuong = value ? Number(value) : null;
          } else {
            const primaryDuty = listTypeDuty.find(item => item?.mappingName === MAPPING_NAME_TYPE_DUTY.TRUC_CHINH);
            newListCondition[indexOfCondition].soLuongTrucDtos?.push({
              loaiTrucId: primaryDuty?.id ?? "",
              loaiTrucText: primaryDuty?.value ? String(primaryDuty?.value) : "",
              mappingName: primaryDuty?.mappingName ?? "",
              soLuong: Number(value)
            });
          }

          const newMaxPrimaryDuty = newListCondition
            .flatMap(condition => condition.soLuongTrucDtos)
            ?.filter(item => item?.mappingName === MAPPING_NAME_TYPE_DUTY.TRUC_CHINH)
            ?.reduce((max, item) => item.soLuong ? Math.max(max, item.soLuong) : max, 0);

          setMaxPrimaryDuty(newMaxPrimaryDuty);

          return newListCondition;
        });
        break;
      case VARIABLE_STRING.THUONG_TRU:
        if(Number(value) > MAX_EMPLOYEE_DUTY) return;
        setListCondition(preListCondition => {
          const newListCondition = [...preListCondition];
          const indexOfCondition = preListCondition.findIndex(item => item.id === condition?.id);
          const indexPrimaryDuty = newListCondition?.[indexOfCondition]?.soLuongTrucDtos
            ?.findIndex(item => item?.mappingName === MAPPING_NAME_TYPE_DUTY.THUONG_TRU);
          if (indexOfCondition !== -1 && indexPrimaryDuty !== undefined && indexPrimaryDuty !== -1) {
            newListCondition[indexOfCondition].soLuongTrucDtos[indexPrimaryDuty].soLuong = value ? Number(value) : null;
          } else {
            const permanentDuty = listTypeDuty.find(item => item?.mappingName === MAPPING_NAME_TYPE_DUTY.THUONG_TRU);
            newListCondition[indexOfCondition].soLuongTrucDtos?.push({
              loaiTrucId: permanentDuty?.id ?? "",
              loaiTrucText: permanentDuty?.value ? String(permanentDuty?.value) : "",
              mappingName: permanentDuty?.mappingName ?? "",
              soLuong: Number(value)
            });
          }

          const newMaxPermanentDuty = newListCondition
            .flatMap(condition => condition.soLuongTrucDtos)
            ?.filter(item => item?.mappingName === MAPPING_NAME_TYPE_DUTY.THUONG_TRU)
            ?.reduce((max, item) => item.soLuong ? Math.max(max, item.soLuong) : max, 0);

          setMaxPermanentDuty(newMaxPermanentDuty);

          return newListCondition;
        });
        break;
      default:
        setListCondition(preListCondition => preListCondition.map(data => data.id === condition?.id ? { ...data, [name]: value } : data));
    }
  }

  const handleAddEmployee = (rowData: any, rowIndex: number, itemList: any[]) => {
    const newListData = [...itemList];
    newListData.splice(rowIndex, 0, { id: generateRandomId(), loaiTruc: rowData.loaiTruc, caLamViec: rowData.caLamViec, addNewRow: true });
    setListData(newListData);
  }
  
  const handleAddTypeSchedule = (rowData: any, rowIndex: number, itemList: any[]) => {
    const newListData = [...itemList];
    newListData.splice(rowIndex, 0, { id: generateRandomId(), phongBan: rowData.phongBan, addNewRow: true });
    setListData(newListData);
  }

  const handleAddCondition = () => {
    setListCondition(preListCondition => [...preListCondition, { id: generateRandomId(), soLuongTrucDtos: [] }]);
  }

  return (
    <div>
      {doiTuong === TYPE_OBJECT_SCHEDULE.DIEU_DUONG && (
        <>
          <div className="d-flex flex-middle gap-2 mt-4">
            <span className="sub-title pt-0">{lang("CONDITION")}</span>
            {!isView && (
              <div className="condition__add">
                <CustomTooltip title={lang("TYPE.CONDITION.ADD")}>
                  <span className={`${!isView ? "icon inline-block cursor-pointer" : ""}`} onClick={handleAddCondition}>
                    <KTSVG
                      className={`${!isView ? "color-primary" : "color-black"} inline-block`}
                      path="/media/icons/add.svg"
                      svgClassName='spaces w-26 h-26'
                    />
                  </span>
                </CustomTooltip>
              </div>
            )}
          </div>
          <div className="condition relative spaces z-index-3">
            <div>
              {listCondition.map(condition => (
                <div className="mb-3 d-flex gap-2 flex-bottom">
                  <TextValidator
                    className="text-center spaces w-60"
                    name="soLuongBenhNhanDuoi"
                    readOnly={isView}
                    type="number"
                    value={condition?.soLuongBenhNhanDuoi ?? ""}
                    onChange={(e) => handleChangeCondition(e, condition)}
                    onKeyDown={justAllowNumber}
                  />
                  <Autocomplete
                    className="text-center spaces w-60 fs-18"
                    name="dieuKienDuoi"
                    menuPlacement="bottom"
                    isDisabled={isView}
                    options={LIST_COMPARISON_OPERATOR}
                    value={condition?.dieuKienDuoi || null}
                    onChange={(value => handleChangeSelectCondition("dieuKienDuoi", value, condition))}
                    styles={variantStandardStyle("26px")}
                  />
                  <span className="spaces fs-14">BN</span>
                  <Autocomplete
                    className="text-center spaces w-60 fs-18"
                    name="dieuKienTren"
                    menuPlacement="bottom"
                    isDisabled={isView}
                    options={LIST_COMPARISON_OPERATOR}
                    value={condition?.dieuKienTren || null}
                    onChange={(value => handleChangeSelectCondition("dieuKienTren", value, condition))}
                    styles={variantStandardStyle("26px")}
                  />
                  <TextValidator
                    className="spaces w-60"
                    name="soLuongBenhNhanTren"
                    readOnly={isView}
                    type="number"
                    value={condition?.soLuongBenhNhanTren ?? ""}
                    onChange={(e) => handleChangeCondition(e, condition)}
                    onKeyDown={justAllowNumber}
                  />
                  <span className="spaces fs-14">Chấm</span>
                  <TextValidator
                    className="spaces w-60"
                    name={VARIABLE_STRING.TRUC_CHINH}
                    readOnly={isView}
                    type="number"
                    min={MIN_EMPLOYEE_DUTY}
                    max={MAX_EMPLOYEE_DUTY}
                    value={condition?.soLuongTrucDtos?.find(item => item?.mappingName === MAPPING_NAME_TYPE_DUTY.TRUC_CHINH)?.soLuong ?? ""}
                    onChange={(e) => handleChangeCondition(e, condition)}
                    onKeyDown={justAllowNumber}
                  />
                  <span className="spaces fs-14">trực chính, </span>
                  <TextValidator
                    className="spaces w-60"
                    name={VARIABLE_STRING.THUONG_TRU}
                    readOnly={isView}
                    type="number"
                    min={MIN_EMPLOYEE_DUTY}
                    max={MAX_EMPLOYEE_DUTY}
                    value={condition?.soLuongTrucDtos?.find(item => item?.mappingName === MAPPING_NAME_TYPE_DUTY.THUONG_TRU)?.soLuong ?? ""}
                    onChange={(e) => handleChangeCondition(e, condition)}
                    onKeyDown={justAllowNumber}
                  />
                  <span className="spaces fs-14">thường trú</span>
                  {!isView && (
                    <CustomTooltip title={lang("TYPE.CONDITION.DELETE")} placement="right">
                      <span className={`spaces ml-6 ${!isView ? "cursor-pointer color-red" : "color-black"}`} onClick={() => handleDeleteCondition(condition?.id ?? "")}>
                        <KTSVG
                          className="color-inherit inline-block"
                          path="/media/icons/trash.svg"
                          svgClassName='spaces w-16 h-16'
                        />
                      </span>
                    </CustomTooltip>
                  )}
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      <div className="schedule table-custom mt-4">
        <TableCustomSpan
          id="shift-schedule"
          title={lang(LIST_OBJECT_SCHEDULE.find(item => item?.code === doiTuong)?.title ?? "")}
          data={listData}
          columns={columnsFull}
          isHandleNewRow={!isView}
          isRowSpan
          numberSpan={1}
          listPositionHandleRow={useMemo(() => [
            {
              position: doiTuong === TYPE_OBJECT_SCHEDULE.BAC_SI ? 1 : 2,
              type: TYPE_HANDLE_ROW.ADD,
              handleRow: doiTuong === TYPE_OBJECT_SCHEDULE.BAC_SI ? handleAddTypeSchedule : handleAddEmployee
            }
          ], [doiTuong])}
          noPagination
        />
      </div>
    </div>
  );
}));

export default ShiftSchedule;
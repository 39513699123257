import { ILang } from "../../../hook/useMultiLanguage";
import { ITemplateExportWord } from "../../components/exportWord";
import { OptionReactSelect } from "../../models/models";
import { IAppointment } from "../models/appointmentModels";
import { FirstAppointmentDecisionWord, ReappointmentDecisionWord } from "../components/word-templates";
import { COMPLETED_STATUS, TYPE, TYPE_CATEGORY } from "../../constants/moduleConsts";
import { searchAllSimpleValue, searchListWorkUnit, searchPhongBan } from "../../services/services";

export const LOAI = {
  BO_NHIEM_LAN_DAU: 1,
  BO_NHIEM_LAI: 2
}

export const LOAI_BO_NHIEM: OptionReactSelect[] = [
  { name: "Bổ nhiệm lần đầu", code: LOAI.BO_NHIEM_LAN_DAU },
  { name: "Bổ nhiệm lại", code: LOAI.BO_NHIEM_LAI },
]

export const INIT_BO_NHIEM: IAppointment = {
  maNhanVien: "",
  employeeId: "",
  employee: null,
  fullName:"",
  donViCongTacText: "",
  donViCongTacId:"",
  phongBanText: "",
  phongBanId: "",
  chucVuText: "",
  chucVuId: "",
  loaiBoNhiem: null,
  donViCongTacMoiText: "",
  donViCongTacMoiId: "",
  donViCongTacMoi: null,
  phongBanMoiText: "",
  phongBanMoiId: "",
  phongBanMoi: null,
  chucVuMoiId: "",
  chucVuMoiText: "",
  chucVuMoi: null,
  thoiGianHieuLucTuNgay: "",
  thoiGianHieuLucDenNgay: "",
  nhiemKyTuNgay: "",
  nhiemKyDenNgay: "",
  ngayQuyetDinh: "",
  nhiemKy: "",
  soQuyetDinh: "",
  fileId: "",
  fileName: "",
  trangThai: null,
  ghiChu: "",
  nguoiKy: null,
  nguoiKyId: "",
  nguoiKyText: "",
  khoaPhongNhanQuyetDinh: ""
}

export const ListSearch = (lang: ILang) => {
  return [
    {
      name: lang("PROFILE.CODE"),
      field: "maNhanVien",
      type: TYPE.TEXT,
    },
    {
      name: lang("PROFILE.NAME"),
      field: "ten",
      type: TYPE.TEXT,
    },
    {
      name: lang("GENERAL.CURRENT_ORGANIZATION"),
      field: "donViCongTacId",
      searchFunction: searchListWorkUnit,
      type: TYPE.SELECT,
    },
    {
      name: lang("INPUT_DISMISSED_UNIT_DEPARTMENT"),
      field: "phongBanIds",
      searchFunction: searchPhongBan,
      type: TYPE.SELECT,
      isMulti: true,
    },
    {
      name: lang("INPUT_DISMISSED_JOB_POSITION"),
      field: "chucVuId",
      searchFunction: searchAllSimpleValue,
      searchObject: { type: TYPE_CATEGORY.chucDanh },
      type: TYPE.SELECT,
      optionLabel: "value"
    },
    {
      name: lang("INPUT_DISMISSED_WORK_UNIT_NEW"),
      field: "donViCongTacMoiId",
      searchFunction: searchListWorkUnit,
      type: TYPE.SELECT,
    },
    {
      name: lang("INPUT_DISMISSED_UNIT_DEPARTMENT_NEW"),
      field: "phongBanMoiIds",
      searchFunction: searchPhongBan,
      type: TYPE.SELECT,
      isMulti: true,
    },
    {
      name: lang("INPUT_DISMISSED_JOB_POSITION_NEW"),
      field: "chucVuMoiId",
      searchFunction: searchAllSimpleValue,
      searchObject: { type: TYPE_CATEGORY.chucDanh },
      type: TYPE.SELECT,
      optionLabel: "value"
    },
    {
      name: lang("INPUT.WORKPROCESS.DECISION.NUMBER"),
      field: "soQuyetDinh",
      type: TYPE.TEXT,
    },
    {
      name: lang("GENERAL.STATUS"),
      field: "trangThai",
      type: TYPE.SELECT,
      listOption: COMPLETED_STATUS,
      isMulti: true,
    },
  ]
}

export const appointmentWordExportList: ITemplateExportWord[] = [
  {
    type: LOAI.BO_NHIEM_LAN_DAU,
    name: "QĐ bổ nhiệm lần đầu",
    componentPreview: (dataPreview) => FirstAppointmentDecisionWord({ data: dataPreview })
  },
  {
    type: LOAI.BO_NHIEM_LAI,
    name: "QĐ bổ nhiệm lại",
    componentPreview: (dataPreview) => ReappointmentDecisionWord({ data: dataPreview })
  }
]
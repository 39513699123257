import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { convertSearch, formatDateTable, searchDataRoleUser, exportToFile, checkStatus, hasAuthority } from "../utils/functionUtils";
import TableCustom, { columnNamesType } from "../components/table-custom/TableCustom";
import { MISSION_STATUS_CODE, RESPONSE_STATUS_CODE, TYPE, INIT_SEARCH_OBJECT, EXPORT_FILE_NAME, INIT_INPUT_SEARCH } from "../constants/moduleConsts";
import DisciplineDialog from "./components/DisciplineDialog";
import useMultiLanguage from "../../hook/useMultiLanguage";
import { Col, Row } from "react-bootstrap";
import InputSearch from "../components/input/InputSearch";
import { deleteDiscipline, getDiscipline } from "./services/disciplineServices";
import { DisciplineInfo } from "./models/disciplineModels";
import { ListSearch, disciplineStatus } from "./constants/disciplineConsts";
import AppContext from "../../AppContext";
import AdvancedSearch from "../profile/components/dialogs/AdvancedSearch";
import "./discipline.scss";
import { exportKyLuat } from "../services/exportFileServices";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../constants";
import { GroupButton } from "../components/GroupButton";

const Discipline = () => {
  const { lang } = useMultiLanguage();
  const [disciplineInfo, setDisciplineInfo] = useState({});
  const [shouldOpenDisciplineDialog, setShouldOpenDisciplineDialog] = useState(false);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [disciplineList, setDisciplineList] = useState<DisciplineInfo[]>([]);
  const [dataChecked, setDataChecked] = useState<DisciplineInfo[]>([]);
  const [isView, setIsView] = useState<boolean>(false);
  const [isAdvancedSearch, setIsAdvancedSearch] = useState<boolean>(false);
  const [listInputSearch, setListInputSearch] = useState<any[]>([INIT_INPUT_SEARCH]);
  const { setPageLoading } = useContext(AppContext);
  const [searchObject, setSearchObject] = useState<any>(INIT_SEARCH_OBJECT);

  useEffect(() => {
    if (searchObject?.pageIndex && searchObject?.pageSize) {
      handleSearch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchObject?.pageIndex, searchObject?.pageSize])

  const handleOpenInfoDialog = () => {
    setShouldOpenDisciplineDialog(true);
    setIsView(false);
  };

  const handleOpenEditDialog = (row: any) => {
    setShouldOpenDisciplineDialog(true);
    setDisciplineInfo(row);
    setIsView(true);
  };

  const handleCloseAndSearch = () => {
    handleSearch();
    handleCloseDialog();
  }

  const handleCloseDialog = () => {
    setShouldOpenDisciplineDialog(false);
    setDisciplineInfo({});
  };

  const updateDataDiscipline = async (searchData?: any) => {
    try {
      setPageLoading(true);
      setSearchObject({ ...searchObject, ...searchData });
      const { data } = await getDiscipline(hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW) ? searchDataRoleUser(searchData) : { ...searchData });
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setDisciplineList(data?.data?.content || []);
        setTotalPages(data?.data?.pageable?.totalPages || 0);
        setTotalElements(data?.data?.pageable?.totalElements || 0);
        setNumberOfElements(data?.data?.pageable?.numberOfElements || 0);
        setIsAdvancedSearch(false);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleSearch = (data: any = {}) => {
    const dataSearch: any = {
      ...searchObject,
      ...data
    };
    dataSearch.keyword = dataSearch?.keyword?.trim() || "";
    return updateDataDiscipline(dataSearch);
  }

  const handleDelete = async (ids: any) => {
    try {
      const { data } = await deleteDiscipline(ids);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("GENERAL.DELETE_SUCCESS"));
        updateDataDiscipline(searchObject);
        return true;
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const handleAdvancedSearch = (data: any[]) => {
    setListInputSearch(data);
    handleSearch({ ...convertSearch(data), pageIndex: 1 });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchObject({
      ...searchObject,
      keyword: e.target?.value
    });
  };

  const columns: columnNamesType[] = [
    {
      name: lang("TABLE.INDEX"),
      field: "stt",
      headerStyle: {
        width: "50px",
        minWidth: "50px"
      },
      render: (row: any, index: number, STT: number) => <span>{STT}</span>
    },
    {
      name: lang("GENERAL.STATUS"),
      field: "trangThai",
      headerStyle: {
        width: "150px",
        minWidth: "150px"
      },
      render: (row: any) => (
        <span className={`${checkStatus(disciplineStatus, row?.trangThai?.code)} status`}>
          {row?.trangThai?.name}
        </span>
      )
    },
    {
      name: lang("FULL.NAME"),
      field: "name",
      headerStyle: {
        width: "200px",
        minWidth: "200px"
      },
      render: (row: any) => <span>{row?.employeeKyLuats?.[0]?.name}</span>
    },
    {
      name: lang("GENERAL.TIME"),
      field: "tuNgay-denNgay",
      headerStyle: {
        minWidth: "180px"
      },
      render: (row: any) => (
        <span>{row?.tuNgay ? formatDateTable(row?.tuNgay) + (row?.denNgay ? " - " + formatDateTable(row?.denNgay) : "") : ""}</span>
      )
    },
    {
      name: lang("DISCIPLINE.TYPE"),
      field: "loaiKyLuat",
      headerStyle: {
        minWidth: "120px"
      },
      cellStyle: {
        textAlign: "left"
      },
      render: (row: any) => <span>{row?.loaiKyLuat?.name}</span>
    },
    {
      name: lang("APPRECIATIVE.DECISION_LEVEL"),
      field: "capQuyetDinh",
      headerStyle: {
        minWidth: "150px"
      },
      cellStyle: {
        textAlign: "left"
      },
      render: (row: any) => <span>{row?.capQuyetDinh?.name}</span>
    },
    {
      name: lang("DISCIPLINE.VIOLATION_BEHAVIOR"),
      field: "hanhVi",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("DISCIPLINE.FORM_OF"),
      field: "hinhThucKyLuat",
      headerStyle: {
        minWidth: "150px"
      },
      cellStyle: {
        textAlign: "left"
      },
      render: (row: any) => <span>{row?.hinhThucKyLuat?.name}</span>
    },
    {
      name: lang("APPRECIATIVE.PERSON_SINGING"),
      field: "nguoiKyQuyetDinhText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("DISCIPLINE.ISSUING_AGENCY_UNIT"),
      field: "coQuanQuyetDinhText",
      headerStyle: {
        minWidth: "280px"
      },
      cellStyle: {
        textAlign: "left"
      }
    }
  ];

  return (
    <div className="spaces px-8 py-12 flex-1">
      {!shouldOpenDisciplineDialog ? (
        <>
          <Row className="spaces mb-12 flex-middle">
            <Col xs={5} xl={6} xxl={7} className="spaces p-0">
              <h2 className="breadcrumb-title">{lang("DISCIPLINE.TITLE.LIST")}</h2>
            </Col>
            <Col xs={7} xl={6} xxl={5} className="flex">
              <div className="spaces mr-10 flex-1">
                <InputSearch
                  className="border-3px"
                  value={searchObject?.keyword}
                  placeholder="Tìm kiếm theo họ và tên, hành vi vi phạm"
                  handleSearch={() => handleSearch({ pageIndex: 1 })}
                  handleChange={handleChange}
                  isEnter
                />
              </div>
              <GroupButton className="spaces mr-10" handleEvent={() => handleSearch({ pageIndex: 1 })}>
                {lang("BTN.SEARCH")}
              </GroupButton>
              <GroupButton className="spaces mr-10" outline handleEvent={() => setIsAdvancedSearch(true)}>
                {lang("BTN.ADVANCE_SEARCH")}
              </GroupButton>
              {isAdvancedSearch && (
                <AdvancedSearch
                  open={isAdvancedSearch}
                  listInputSearch={listInputSearch}
                  handleSearch={handleAdvancedSearch}
                  listOption={ListSearch(lang)}
                  handleClose={() => setIsAdvancedSearch(false)}
                />
              )}
            </Col>
          </Row>
          <Row className="bg-white">
            <Col xs={12} className="table-scroll">
              <TableCustom
                id="discipline-list"
                data={disciplineList}
                columns={columns}
                type={TYPE.MULTILINE}
                searchObject={searchObject}
                setSearchObject={setSearchObject}
                handleOpenDialog={handleOpenInfoDialog}
                handleDoubleClick={handleOpenEditDialog}
                buttonAdd={hasAuthority(PERMISSIONS.KY_LUAT_MENU, PERMISSION_ABILITY.CREATE)}
                notDelete={!hasAuthority(PERMISSIONS.KY_LUAT_MENU, PERMISSION_ABILITY.DELETE)}
                buttonExportExcel={!hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW)}
                handleExportExcel={(ids: string[]) =>
                  exportToFile({
                    exportAPI: () => exportKyLuat({ ids, ...searchObject }),
                    fileName: EXPORT_FILE_NAME.KY_LUAT,
                    setPageLoading
                  })
                }
                fixedColumnsCount={3}
                totalPages={totalPages}
                totalElements={totalElements}
                numberOfElements={numberOfElements}
                handleDelete={handleDelete}
                setDataChecked={setDataChecked}
                dataChecked={dataChecked}
                deleteConditional={[
                  {
                    keyPath: "trangThai.code",
                    value: MISSION_STATUS_CODE.PROCESSING
                  }, {
                    keyPath: "trangThai.code",
                    value: MISSION_STATUS_CODE.COMPLETED
                  }, {
                    keyPath: "xacNhanKyLuat",
                    value: true
                  }
                ]}
              />
            </Col>
          </Row>
        </>
      ) : (
        <DisciplineDialog
          handleClose={handleCloseDialog}
          handleCloseUpdateDialog={() => setIsView(true)}
          handleCloseAndSearch={handleCloseAndSearch}
          isView={isView}
          disciplineEdit={disciplineInfo}
          setIsView={setIsView}
        />
      )}
    </div>
  );
};

export default Discipline;

import { PERMISSION_ABILITY, PERMISSIONS } from "../../../constants"
import { OptionReactSelect } from "../../models/models";
import { Holiday, LeaveType } from "../components/leave-regulations"
import { IHoliday, ILeaveType } from "../models/categoryModels";

export const KEY_TAB_LEAVE_REGULATIONS = {
  LOAI_NGHI: "loaiNghi",
  NGHI_LE: "nghiLe"
};

export const LIST_TAB_LEAVE_REGULATIONS = [
  {
    key: KEY_TAB_LEAVE_REGULATIONS.LOAI_NGHI,
    permission: PERMISSIONS["DANH_MUC.CHAM_CONG.LOAI_NGHI"],
    ability: PERMISSION_ABILITY.VIEW,
    title: "RESIGN.TYPE_BREAK",
    component: LeaveType
  },
  {
    key: KEY_TAB_LEAVE_REGULATIONS.NGHI_LE,
    permission: PERMISSIONS["DANH_MUC.CHAM_CONG.NGHI_LE"],
    ability: PERMISSION_ABILITY.VIEW,
    title: "HOLIDAY",
    component: Holiday
  }
];


export const REGULATION_TYPE = {
  HOLIDAY: 1,
  LEAVE_TYPE: 2
}

export const LIST_REGULATION_TYPE = [
  {
    code: REGULATION_TYPE.HOLIDAY,
    name: "Ngày lễ tết"
  },
  {
    code: REGULATION_TYPE.LEAVE_TYPE,
    name: "Loại nghỉ"
  }
];

export const INIT_HOLIDAY: IHoliday = {
  name: "",
  startDate: null,
  endDate: null,
  description: "",
  maxDay: 0,
  regulationType: LIST_REGULATION_TYPE.find(type => type.code === REGULATION_TYPE.HOLIDAY) as OptionReactSelect
};

export const INIT_LEAVE_TYPE: ILeaveType = {
  name: "",
  description: "",
  maxDay: null,
  leaveType: null,
  regulationType: LIST_REGULATION_TYPE.find(type => type.code === REGULATION_TYPE.LEAVE_TYPE) as OptionReactSelect
};
/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import AppContext from "../../../AppContext";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import UploadFile from "../../components/file-upload/UploadFile";
import { GroupButton } from "../../components/GroupButton";
import Autocomplete from "../../components/input/autocomplete";
import { RangeDatePicker } from "../../components/input/RangeDatePicker";
import TextValidator from "../../components/input/text-validator";
import { IFile } from "../../profile/models/dialogModels";
import { getEmployeeProfileById } from "../../profile/services/profileServices";
import { checkObject, handleBlurDate, hasAuthority, matchTemplateWord } from "../../utils/functionUtils";
import { INIT_BO_NHIEM, LOAI, LOAI_BO_NHIEM, appointmentWordExportList } from "../constants/appointmentConsts";
import { IAppointment } from "../models/appointmentModels";
import { addNewAppointment, editAppointment } from "../services/appointmentServices";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../../constants";
import { ExportWord } from "../../components/exportWord";
import { IPeopleDecision } from "../../models/models";
import { 
  COMPLETED_STATUS,
  COMPLETED_STATUS_CODE,
  DATA_WORD_TYPES,
  RESPONSE_STATUS_CODE,
  TYPE,
  TYPE_CATEGORY, 
  VARIABLE_STRING
} from "../../constants/moduleConsts";
import { searchAllEmployee, searchAllSimpleValue, searchListWorkUnit, searchNguoiDaiDienKy, searchPhongBanByDonVi } from "../../services/services";
import { AddNewRepresentative } from "../../components/dialogs";

interface IProps {
  appointmentInfo: IAppointment;
  handleClose: () => void;
  handleCloseUpdateDialog: () => void;
  handleCloseAndSearch: () => void;
  isView: boolean;
  setIsView: (value: boolean) => void;
}
const AppointmentForm: FC<IProps> = (props) => {
  const { lang } = useMultiLanguage()
  const { setPageLoading } = useContext(AppContext);

  const {
    handleClose,
    appointmentInfo,
    handleCloseUpdateDialog,
    isView,
    setIsView,
    handleCloseAndSearch
  } = props;

  const [isShowAcceptAppointmentBtn, setIsShowAcceptAppointmentBtn] = useState<boolean>(false);
  const [isAcceptAppointment, setIsAcceptAppointment] = useState<boolean>(appointmentInfo.xacNhanBoNhiem || false);
  const [isShowBtnSave, setIsShowBtnSave] = useState<boolean>(true);
  const [isShowDialogWord, setIsShowDialogWord] = useState<boolean>(false);
  const [peopleDecisions, setPeopleDecisions] = useState<IPeopleDecision[]>([]);
  const [isShowDialogAddRepresentative, setIsShowDialogAddRepresentative] = useState<boolean>(false);

  const getPeopleDecision = async () => {
    try {
      const { data } = await searchNguoiDaiDienKy();
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setPeopleDecisions(data?.data?.content || [])
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }

  useEffect(() => {
    getPeopleDecision();
  }, [])

  const validationSchema = Yup.object().shape({
    employee: Yup.object()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    maNhanVien: Yup.string().nullable(),
    loaiBoNhiem: Yup.object()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    chucVuText: Yup.string().nullable(),
    donViCongTacText: Yup.string().nullable(),
    phongBanText: Yup.string().nullable(),
    thoiGianHieuLucTuNgay: Yup.date()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    thoiGianHieuLucDenNgay: Yup.date()
      .min(Yup.ref("thoiGianHieuLucTuNgay"), lang("VALIDATION.MINDATE") + lang("GENERAL_EFFECTIVE_DATE_START"))
      .nullable(),
    nhiemKyTuNgay: Yup.date()
      .nullable(),
    nhiemKyDenNgay: Yup.date()
      .min(Yup.ref("nhiemKyTuNgay"), lang("VALIDATION.MINDATE") + lang("GENERAL_EFFECTIVE_DATE_START"))
      .nullable(),
    soQuyetDinh: Yup.string().nullable().max(255, lang("VALIDATION.MAX255")),
    donViCongTacMoi: Yup.object()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    phongBanMoi: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    chucVuMoi: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    nguoiKy: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable()
  });

  const templateWord = useMemo(() => 
    matchTemplateWord(Number(appointmentInfo?.loaiBoNhiem?.code), appointmentWordExportList), [appointmentInfo?.loaiBoNhiem]);

  useEffect(() => {
    if (!appointmentInfo?.id) {
      formik.setFieldValue("loaiBoNhiem", LOAI_BO_NHIEM.find(option => option.code === LOAI.BO_NHIEM_LAN_DAU) || null);
    } else {
      let values = {
        ...appointmentInfo,
        donViCongTacMoi: {
          id: appointmentInfo?.donViCongTacMoiId,
          name: appointmentInfo?.donViCongTacMoiText
        },
        phongBanMoi: {
          id: appointmentInfo?.phongBanMoiId,
          name: appointmentInfo?.phongBanMoiText
        },
        chucVuMoi: {
          id: appointmentInfo?.chucVuMoiId,
          value: appointmentInfo?.chucVuMoiText
        },
        nguoiKy: appointmentInfo?.nguoiKyId ? {
          id: appointmentInfo?.nguoiKyId,
          name: appointmentInfo?.nguoiKyText
        } : null
      }
      formik.setValues(values);
    }
    if(hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW)) {
      handleEmployeeChange(appointmentInfo?.employee);
    } else if(appointmentInfo?.employeeId) {
      getEmployeeInfo(appointmentInfo.employeeId);
    }
    setIsShowAcceptAppointmentBtn(!appointmentInfo?.xacNhanBoNhiem);
  }, [appointmentInfo]);

  useEffect(() => {
    if (!appointmentInfo?.id && peopleDecisions.length > 0) {
      formik.setFieldValue("nguoiKy", peopleDecisions.find(people => people.isDefault) || null);
    }
  }, [peopleDecisions]);

  const getEmployeeInfo = async (id: string) => {
    try {
      setPageLoading(true);
      const { data } = await getEmployeeProfileById(id);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        formik.setFieldValue(VARIABLE_STRING.EMPLOYEE, data?.data);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleEmployeeChange = (employeeInfo: any) => {
    if(hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW)) {
      formik.setFieldValue("donViCongTacMoi", {
        id: employeeInfo?.donViCongTacId || "",
        name: employeeInfo?.donViCongTacText || ""
      });
    }
    formik.setFieldValue("employee", employeeInfo);
    formik.setFieldValue("employeeId", employeeInfo?.id);
  }

  const convertDataSubmit = (data: IAppointment) => {
    return {
      ...data,
      chucVuMoiId: data?.chucVuMoi?.id,
      chucVuMoiText: data?.chucVuMoi?.value,
      chucVuId: data?.employee?.chucVuId,
      chucVuText: data?.employee?.chucVuText,
      donViCongTacText: data?.employee?.donViCongTacText,
      donViCongTacId: data?.employee?.donViCongTacId,
      donViCongTacMoiId: data?.donViCongTacMoi?.id,
      donViCongTacMoiText: data?.donViCongTacMoi?.name,
      maNhanVien: data?.employee?.maNhanVien,
      phongBanMoiId: data?.phongBanMoi?.id,
      phongBanMoiText: data?.phongBanMoi?.name,
      phongBanId: data?.employee?.phongBanId,
      phongBanText: data?.employee?.phongBanText,
      ten: data?.employee?.name,
      xacNhanBoNhiem: isAcceptAppointment,
      nguoiKyId: data?.nguoiKy?.id || "",
      nguoiKyText: data?.nguoiKy?.name || ""
    }
  }

  const handleFile = (data: IFile) => {
    formik.setFieldValue("fileId", data.id);
    formik.setFieldValue("fileName", data.name);
  }

  const handleChangeSelect = (name: string, value: any) => {
    switch (name) {
      case VARIABLE_STRING.LOAI_BO_NHIEM:
        formik.setFieldValue(VARIABLE_STRING.LOAI_BO_NHIEM, value);
        break;
      case VARIABLE_STRING.DON_VI_CONG_TAC_MOI:
        formik.setValues({
          ...formik.values,
          [name]: value,
          [VARIABLE_STRING.PHONG_BAN_MOI]: null,
          [VARIABLE_STRING.CHUC_VU_MOI]: null
        });
        break;
      case VARIABLE_STRING.PHONG_BAN_MOI:
        formik.setValues({
          ...formik.values,
          [name]: value,
          [VARIABLE_STRING.CHUC_VU_MOI]: null,
          [VARIABLE_STRING.PHONG_BAN_MOI_KHAC]: null
        });
        break;
      case VARIABLE_STRING.CHUC_VU_MOI:
        formik.setValues({
          ...formik.values,
          [name]: value,
          [VARIABLE_STRING.CHUC_VU_MOI_KHAC]: null
        });
        break;
      default:
        formik.setFieldValue(name, value);
        break;
    }
  };

  const handleSubmit = async (values: IAppointment) => {
    try {
      setPageLoading(true);
      const res = appointmentInfo.id
        ? await editAppointment(appointmentInfo.id, convertDataSubmit(values))
        : await addNewAppointment(convertDataSubmit(values));
      if (res?.data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(
          appointmentInfo.id
            ? lang("TOAST.EDIT.SUCCESS")
            : lang("TOAST.CREATE.SUCCESS")
        );
        handleCloseAndSearch();
      } else {
        toast.warning(`${res?.data?.message}`);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false)
    }
  };

  const formik = useFormik({
    initialValues: INIT_BO_NHIEM,
    validationSchema,
    onSubmit: handleSubmit
  })

  const handleAcceptAppointment = async () => {
    if (checkObject(await formik.validateForm())) {
      setIsAcceptAppointment(true);
    }
  }

  const handleChangeDate = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value);
    if (name === VARIABLE_STRING.THOI_GIAN_HIEU_LUC_TU_NGAY) {
      const effectiveDate = value ? new Date(value) : null;
      const currentDate = new Date();
      if (effectiveDate && effectiveDate <= currentDate) {
        setIsShowBtnSave(false);
      } else setIsShowBtnSave(true);
    }
  }

  const handleCloseAndSearchRepresentative = () => {
    getPeopleDecision();
    setIsShowDialogAddRepresentative(false);
  }

  return (
    <div className="h-full">
      <Form onSubmit={formik.handleSubmit}>
        <div className="header-form-action">
          <GroupButton type="btn-back" handleClose={handleClose} />
          <div className="flex gap-4">
            {(!isView && appointmentInfo?.trangThai?.code === COMPLETED_STATUS_CODE.UNFULFILLED && appointmentInfo?.id) && (
              <GroupButton
                type="btn-cancel"
                handleCloseUpdateDialog={handleCloseUpdateDialog}
              />
            )}
            {isView && appointmentInfo?.id && appointmentInfo?.trangThai?.code !== COMPLETED_STATUS_CODE.COMPLETED 
              && !appointmentInfo.xacNhanBoNhiem && hasAuthority(PERMISSIONS.BO_NHIEM, PERMISSION_ABILITY.UPDATE) && (
              <GroupButton type="btn-edit" handleSaveEdit={() => setIsView(false)} />
            )}
            {(!isView && isShowBtnSave && (appointmentInfo?.trangThai?.code === COMPLETED_STATUS_CODE.UNFULFILLED || !appointmentInfo?.id)) && (
              <GroupButton type="btn-save" handleSubmit={() => setIsAcceptAppointment(false)} />
            )}
            {isShowAcceptAppointmentBtn && (
              <GroupButton typeButton="submit" handleEvent={handleAcceptAppointment}>{lang("APPOINTMENT.ACCEPT")}</GroupButton>
            )}

            {appointmentInfo?.id && (
              <GroupButton handleEvent={() => setIsShowDialogWord(true)}>
                {lang("GENERAL.EXPORT_WORD")}
              </GroupButton>
            )}
          </div>
        </div>
        <div className="form-content-scroll">
          <div className="sub-title-form">{appointmentInfo?.id ? lang("APPOINTMENT.EDIT") : lang("APPOINTMENT.ADD")}</div>
          <div className="sub-title pb-2 pt-0">{lang("APPOINTMENT_INFO_EMPLOYEE")}</div>
          <Row className="g-4">
            <Col sm={4} md={4} lg={4} xl={3} xxl={3}>
              <Autocomplete
                isRequired
                lable={lang("GENERAL.FULLNAME")}
                options={[]}
                className="spaces"
                onChange={(value) => handleEmployeeChange(value)}
                name="employee"
                value={formik.values?.employee || null}
                searchFunction={searchAllEmployee}
                searchObject={{ isDangLamViec: true }}
                isReadOnly={isView || hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW)}
                errors={formik.errors.employee}
                touched={formik.touched.employee}
                getOptionLabel={(option) => `${option?.maNhanVien} - ${option?.name}`}
              />
            </Col>
            <Col sm={3} md={3} lg={3} xl={2} xxl={3}>
              <TextValidator
                name="maNhanVien"
                lable={lang("INPUT_APPOINTMENT_CODE")}
                type="text"
                value={formik.values?.employee?.maNhanVien}
                readOnly={true}
              />
            </Col>
            <Col sm={5} md={5} lg={5} xl={3} xxl={3}>
              <TextValidator
                name="donViCongTacText"
                lable={lang("INPUT_APPOINTMENT_WORK_UNINT")}
                type="text"
                value={formik.values?.employee?.donViCongTacText}
                readOnly={true}
              />
            </Col>
            <Col sm={6} md={5} lg={5} xl={4} xxl={3}>
              <TextValidator
                name="phongBanText"
                lable={lang("INPUT_APPOINTMENT_UNIT_DEPARTMENT")}
                type="text"
                value={formik.values?.employee?.phongBanText}
                readOnly={true}
              />
            </Col>
            <Col sm={6} md={5} lg={5} xl={4} xxl={4}>
              <TextValidator
                name="chucVuText"
                lable={lang("INPUT_APPOINTMENT_JOB_POSITION")}
                type="text"
                value={formik.values?.employee?.chucVuText}
                readOnly={true}
              />
            </Col>
          </Row>
          <div className="sub-title pb-2">{lang("APPOINTMENT_INFO")}</div>
          <Row className="g-4">
            <Col sm={6} md={3} lg={3} xl={3} xxl={2}>
              <Autocomplete
                isRequired
                lable={lang("INPUT_APPOINTMENT_TYPE")}
                options={LOAI_BO_NHIEM}
                isReadOnly={isView}
                value={formik.values?.loaiBoNhiem || null}
                name="loaiBoNhiem"
                onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.LOAI_BO_NHIEM, selectedOption)}
                touched={formik.touched?.loaiBoNhiem}
                errors={formik.errors?.loaiBoNhiem}
              />
            </Col>
            <Col sm={6} md={4} lg={4} xl={4} xxl={3}>
              <Autocomplete
                name="donViCongTacMoi"
                lable={lang("INPUT_APPOINTMENT_WORK_UNINT_NEW")}
                searchFunction={searchListWorkUnit}
                onChange={(value) => handleChangeSelect(VARIABLE_STRING.DON_VI_CONG_TAC_MOI, value)}
                value={formik.values.donViCongTacMoi || null}
                isRequired
                options={[]}
                errors={formik?.errors?.donViCongTacMoi}
                touched={formik?.touched?.donViCongTacMoi}
                isReadOnly={isView || hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW)}
              />
            </Col>
            <Col sm={6} md={5} lg={5} xl={5} xxl={4}>
              <Autocomplete
                lable={lang("INPUT_APPOINTMENT_UNIT_DEPARTMENT_NEW")}
                isReadOnly={isView}
                searchFunction={searchPhongBanByDonVi}
                searchObject={{ id: formik.values?.donViCongTacMoi?.id }}
                options={[]}
                isRequired
                value={formik.values?.phongBanMoi}
                name={VARIABLE_STRING.PHONG_BAN_MOI}
                onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.PHONG_BAN_MOI, selectedOption)}
                urlData="data.data"
                errors={formik.errors?.phongBanMoi}
                touched={formik.touched?.phongBanMoi}
                dependencies={[formik.values?.donViCongTacMoi]}
              />
            </Col>
            <Col sm={6} md={4} lg={4} xl={4} xxl={3}>
              <Autocomplete
                lable={lang("INPUT_APPOINTMENT_NEW_JOB_POSITION_NEW")}
                isReadOnly={isView}
                searchFunction={searchAllSimpleValue}
                searchObject={{ type: TYPE_CATEGORY.chucDanh }}
                options={[]}
                isRequired
                value={formik.values?.chucVuMoi}
                name={VARIABLE_STRING.CHUC_VU_MOI}
                onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.CHUC_VU_MOI, selectedOption)}
                errors={formik.errors?.chucVuMoi}
                touched={formik.touched?.chucVuMoi}
                getOptionLabel={(option) => option?.value}
              />
            </Col>
            <Col sm={8} md={5} lg={5} xl={5} xxl={3}>
              <RangeDatePicker
                label={lang("INPUT_APPOINTMENT_TIME_EFFECT")}
                startDateName="thoiGianHieuLucTuNgay"
                endDateName="thoiGianHieuLucDenNgay"
                isView={isView}
                isRequired
                handleChange={handleChangeDate}
                value={formik.values}
                setFieldValue={formik.setFieldValue}
                touch={formik.touched}
                errors={formik.errors}
              />
            </Col>
            <Col sm={4} md={3} lg={3} xl={3} xxl={3}>
              <TextValidator
                name="soQuyetDinh"
                lable={lang("INPUT_APPOINTMENT_NUMBER")}
                type="text"
                value={formik.values?.soQuyetDinh}
                readOnly={isView}
                onChange={formik.handleChange}
                errors={formik?.errors?.soQuyetDinh}
                touched={formik?.touched?.soQuyetDinh}
              />
            </Col>
            <Col sm={6} md={3} lg={2} xl={2} xxl={3}>
              <TextValidator
                name="ngayQuyetDinh"
                lable={lang("INPUT_APPOINTMENT_CONFIRM_DATE")}
                type="date"
                value={formik.values?.ngayQuyetDinh}
                readOnly={isView}
                onChange={formik.handleChange}
                onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.ngayQuyetDinh, "ngayQuyetDinh")}
                errors={formik?.errors?.ngayQuyetDinh}
                touched={formik?.touched?.ngayQuyetDinh}
              />
            </Col>
            <Col sm={6} md={5} lg={4} xl={4} xxl={3}>
              <RangeDatePicker
                label={lang("INPUT_APPOINTMENT_TERM")}
                startDateName="nhiemKyTuNgay"
                endDateName="nhiemKyDenNgay"
                isView={isView}
                handleChange={formik.handleChange}
                value={formik.values}
                setFieldValue={formik.setFieldValue}
                touch={formik.touched}
                errors={formik.errors}
              />
            </Col>
            <Col sm={6} md={4} lg={3} xl={3} xxl={3}>
              <UploadFile
                label="INPUT_APPOINTMENT_ATTACHMENTS"
                setValue={handleFile}
                isReadOnly={isView}
                fileValue={{
                  id: formik.values.fileId || "",
                  name: formik.values.fileName || ""
                }}
              />
            </Col>
            <Col sm={6} md={3} lg={3} xl={3} xxl={3}>
              <Autocomplete
                lable={lang("GENERAL.STATUS")}
                isReadOnly={isView}
                options={COMPLETED_STATUS}
                value={formik.values?.trangThai || null}
                name="trangThai"
                onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.TRANG_THAI, selectedOption)}
                errors={formik.errors?.trangThai}
                touched={formik.touched?.trangThai}
              />
            </Col>
            <Col sm={6} md={4} lg={4} xl={3} xxl={3}>
              <Autocomplete
                lable={lang("APPRECIATIVE.NGUOIQUYETDINHTEXT")}
                isReadOnly={isView}
                options={peopleDecisions}
                isRequired
                value={formik.values?.nguoiKy || null}
                isAddNew
                onAddNew={() => setIsShowDialogAddRepresentative(true)}
                name="nguoiKy"
                onChange={(selectedOption) => handleChangeSelect("nguoiKy", selectedOption)}
                errors={formik.errors?.nguoiKy}
                touched={formik.touched?.nguoiKy}
              />
            </Col>
          </Row>
          <Row className="g-4 mt-0">
            <Col xs={12} md={6}>
              <TextValidator
                lable={lang("GENERAL.DEPARTMENT_RECEIVE")}
                name="khoaPhongNhanQuyetDinh"
                type="text"
                as={TYPE.TEXTAREA}
                rows={3}
                value={formik.values?.khoaPhongNhanQuyetDinh}
                readOnly={isView}
                onChange={formik.handleChange}
              />
            </Col>
            <Col xs={12} md={6}>
              <TextValidator
                className="h-auto-input spaces"
                lable={lang("ALLOWANCE.NOTE")}
                name="ghiChu"
                value={formik.values.ghiChu}
                type="text"
                readOnly={isView}
                rows={3}
                onChange={formik.handleChange}
                as={TYPE.TEXTAREA}
              />
            </Col>
          </Row>
        </div>
      </Form>
      {isShowDialogWord && (
        <ExportWord
          open={isShowDialogWord}
          handleClose={() => setIsShowDialogWord(false)}
          templateList={templateWord || []}
          getObj={{
            id: appointmentInfo?.id,
            type: DATA_WORD_TYPES.APPOINTMENT,
            boNhiemType: appointmentInfo?.loaiBoNhiem?.code
          }}
        />
      )}
      {isShowDialogAddRepresentative && (
        <AddNewRepresentative
          handleClose={() => setIsShowDialogAddRepresentative(false)}
          handleCloseAndSearch={handleCloseAndSearchRepresentative}
        />
      )}
    </div>
  );
};
export default AppointmentForm;

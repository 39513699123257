import { FC, useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import AppContext from "../../../../AppContext";
import { ILeaveType } from "../../models/categoryModels";
import { INIT_SEARCH_OBJECT, RESPONSE_STATUS_CODE, TYPE } from "../../../constants/moduleConsts";
import InputSearch from "../../../components/input/InputSearch";
import { GroupButton } from "../../../components/GroupButton";
import TableCustom, { columnNamesType } from "../../../components/table-custom/TableCustom";
import { hasAuthority } from "../../../utils/functionUtils";
import { PERMISSION_ABILITY, PERMISSIONS } from "../../../../constants";
import { deleteLeaveRegulations, searchLeaveRegulations } from "../../services/leaveRegulationsServices";
import { REGULATION_TYPE } from "../../constants/leaveRegulationsConsts";
import { LeaveTypeForm } from "./dialogs";

const LeaveType: FC = () => {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [listLeaveType, setListLeaveType] = useState<ILeaveType[]>([]);
  const [openLeaveTypeForm, setOpenLeaveTypeForm] = useState<boolean>();
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [searchObject, setSearchObject] = useState<any>({
    ...INIT_SEARCH_OBJECT,
    regulationType: REGULATION_TYPE.LEAVE_TYPE
  });
  const [leaveTypeInfo, setLeaveTypeInfo] = useState<ILeaveType>({} as ILeaveType);
  const [isView, setIsView] = useState<boolean>(false);

  const getListLeaveType = async (searchData?: any) => {
    try {
      setPageLoading(true);
      setSearchObject({ ...searchData })
      const { data } = await searchLeaveRegulations(searchData);
      if(data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListLeaveType(data?.data?.content || []);
        setTotalPage(data?.data?.pageable?.totalPages);
        setTotalElements(data?.data?.pageable?.totalElements);
        setNumberOfElements(data?.data?.pageable?.numberOfElements);
      } else {
        toast.warning(data?.message);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleOpenUpdateDialog = () => {
    setOpenLeaveTypeForm(true);
  }

  const handleSearch = (data: any = {}) => {
    const dataSearch: any = {
      ...searchObject,
      ...data
    };
    dataSearch.keyword = dataSearch?.keyword?.trim() || "";
    getListLeaveType(dataSearch);
  };

  useEffect(() => {
    if(searchObject?.pageIndex && searchObject?.pageSize) {
      handleSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchObject?.pageIndex, searchObject?.pageSize])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchObject({
      ...searchObject,
      keyword: e.target?.value
    });
  };

  const handleDeleteLeaveType = async (ids: string[]) => {
    try {
      const { data } = await deleteLeaveRegulations(ids)
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("LEAVE_TYPE.DELETE"))
        await getListLeaveType(searchObject);
        return true;
      } else {
        toast.warning(data?.message)
      }
    }
    catch (err: any) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }

  const handleOpenLeaveTypeForm = (info: any) => {
    setIsView(true);
    setOpenLeaveTypeForm(true);
    setLeaveTypeInfo(info);
  };

  const handleCloseAndSearch = () => {
    handleSearch();
    handleClose();
  }

  const handleClose = () => {
    setOpenLeaveTypeForm(false);
    setIsView(false);
    setLeaveTypeInfo({} as ILeaveType);
  };
  
  const columns: columnNamesType[] = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("LEAVE_TYPE.NAME"),
      field: "name",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("RESIGN.TYPE_BREAK"),
      field: "leaveType",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
      render: row => <span>{row?.leaveType?.name}</span>
    },
    {
      name: lang("LEAVE_TYPE.NUMBER_DAY"),
      field: "maxDay",
      headerStyle: {
        minWidth: "150px"
      }
    },
    {
      name: lang("DESCRIPTION"),
      field: "description",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      }
    }
  ];
  
  return (
    <div className="spaces px-8 py-12 flex-1">
      <Row className="spaces mb-12 flex-middle">
        <Col xs={5} xl={6} xxl={7} className="p-0">
          <h2 className="breadcrumb-title">{lang("LEAVE_TYPE.LIST")}</h2>
        </Col>
        <Col xs={7} xl={6} xxl={5} className="flex">
          <div className="spaces mr-10 flex-1">
            <InputSearch
              className="border-3px"
              value={searchObject?.keyword}
              placeholder="Tìm kiếm theo tên loại nghỉ"
              handleSearch={() => handleSearch({ pageIndex: 1 })}
              handleChange={handleChange}
              isEnter
            />
          </div>
          <GroupButton handleEvent={() => handleSearch({ pageIndex: 1 })}>
            {lang("BTN.SEARCH")}
          </GroupButton>
        </Col>
      </Row>
      <Row className="bg-white">
        <Col className="table-custom">
          <TableCustom
            id="leave-type-list"
            data={listLeaveType}
            columns={columns}
            searchObject={searchObject}
            setSearchObject={setSearchObject}
            buttonAdd={hasAuthority(PERMISSIONS["DANH_MUC.CHAM_CONG.NGHI_LE"], PERMISSION_ABILITY.CREATE)}
            notDelete={!hasAuthority(PERMISSIONS["DANH_MUC.CHAM_CONG.NGHI_LE"], PERMISSION_ABILITY.DELETE)}
            type={TYPE.MULTILINE}
            fixedColumnsCount={2}
            totalPages={totalPage}
            totalElements={totalElements}
            numberOfElements={numberOfElements}
            handleOpenDialog={handleOpenUpdateDialog}
            handleDoubleClick={(row: any) => handleOpenLeaveTypeForm(row)}
            handleDelete={(ids: string[]) => handleDeleteLeaveType(ids)}
          />
        </Col>
      </Row>
      {openLeaveTypeForm &&
        <LeaveTypeForm
          open={openLeaveTypeForm}
          handleClose={handleClose}
          handleCloseAndSearch={handleCloseAndSearch}
          leaveTypeInfo={leaveTypeInfo}
          isView={isView}
        />
      }
    </div>
  );
}

export { LeaveType };
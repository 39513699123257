import { Modal, Row, Col, Form } from "react-bootstrap";
import useMultiLanguage from "../../../../../hook/useMultiLanguage";
import TextValidator from "../../../../components/input/text-validator";
import { useContext, useEffect, useState } from "react";
import { INIT_BONUS_INFO, OBJECT_BONUS_CODE } from "../../../../bonus/constants/bonusConsts";
import { convertDataSubmit, convertDataUI } from "../../../../bonus/utils/bonusUtils";
import BonusForm from "../../../../bonus/components/BonusForm";
import AppContext from "../../../../../AppContext";
import { MISSION_STATUS, MISSION_STATUS_CODE, RESPONSE_STATUS_CODE, VARIABLE_STRING } from "../../../../constants/moduleConsts";
import { addNewBonus, updateBonus } from "../../../../bonus/services/bonusServices";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { checkInvalidDate } from "../../../../utils/validationSchema";
import { useFormik } from "formik";
import { IFile } from "../../../models/dialogModels";
import { BonusInfo } from "../../../../bonus/models/bonusModels";
import "../../../profile.scss"
import { GroupButton } from "../../../../components/GroupButton";

interface IProps {
  open: boolean;
  handleClose: () => void;
  handleCloseAndSearch: () => void;
  bonusInfo: BonusInfo;
  isView: boolean;
}

function BonusViewDialog(props: IProps) {
  const { open, handleClose, bonusInfo, handleCloseAndSearch, isView } = props;
  const { lang, intl } = useMultiLanguage();

  const [employeeSelected, setEmployeeSelected] = useState<any[]>([]);
  const [isEdit, setIsEdit] = useState<boolean>(isView);
  const { setPageLoading } = useContext(AppContext);

  useEffect(() => {
    if (bonusInfo?.id) {
      setIsEdit(bonusInfo?.doiTuongKhenThuong?.code === OBJECT_BONUS_CODE.GROUP || bonusInfo?.trangThai?.code === MISSION_STATUS_CODE.COMPLETED);
      formik.setValues(convertDataUI(bonusInfo));
    } else {
      formik.setFieldValue(VARIABLE_STRING.TRANG_THAI, MISSION_STATUS[0]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bonusInfo]);

  const validationSchema = Yup.object().shape({
    doiTuongKhenThuong: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    dotKhenThuong: Yup.string().required(lang("VALIDATION.REQUIRE")).max(255, lang("VALIDATION.MAX255")).nullable(),
    loaiKhenThuong: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    hinhThucKhenThuong: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    capQuyetDinh: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    namKhenThuong: Yup.number()
      .required(lang("VALIDATION.REQUIRE"))
      .min(1900, lang("VALIDATION.MIN_YEAR"))
      .max(9999, lang("VALIDATION.MAX_YEAR"))
      .nullable(),
    ngayKhenThuong: Yup.date().concat(checkInvalidDate(intl)).required(lang("VALIDATION.REQUIRE")),
    thoiGianHuongLuongKeoDai: Yup.number()
      .max(12, lang("APPRECIATIVE.MAX_12_MONTHS"))
      .when("ahtgDieuChinhLuong", {
        is: (ahtgDieuChinhLuong: boolean) => ahtgDieuChinhLuong,
        then: Yup.number().required(lang("VALIDATION.REQUIRE")).nullable(),
      })
      .nullable(),
  });

  const handleSubmit = async (values: any) => {
    try {
      setPageLoading(true);
      const dataSubmit = convertDataSubmit(values, employeeSelected);
      const { data } = bonusInfo?.id ? await updateBonus(bonusInfo.id, dataSubmit) : await addNewBonus(dataSubmit);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        let message = bonusInfo?.id ? "TOAST.EDIT.SUCCESS" : "TOAST.CREATE.SUCCESS";
        toast.success(lang(message));
        handleCloseAndSearch();
      } else {
        toast.warning(data?.message);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: INIT_BONUS_INFO,
    onSubmit: handleSubmit,
    validationSchema
  });

  const handleChangeSelect = (name: string, value: any) => {
    switch (name) {
      case VARIABLE_STRING.DOI_TUONG_KHEN_THUONG:
        setEmployeeSelected([]);
        formik.setFieldValue(name, value);
        break;
      case VARIABLE_STRING.DANH_HIEU_THI_DUA:
        formik.setValues({
          ...formik.values,
          [name]: value,
          ahtgDieuChinhLuong: Boolean(value.mappingName),
          thoiGianHuongLuongKeoDai: null
        })
        break;
      default:
        formik.setFieldValue(name, value);
    }
  };

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    if (name === VARIABLE_STRING.ANH_HUONG_THOI_GIAN_DIEU_CHINH_LUONG) {
      formik.setValues({
        ...formik.values,
        [name]: checked,
        thoiGianHuongLuongKeoDai: null
      });
    } else {
      formik.setFieldValue(name, checked);
    }
  };

  const handleSetFile = (data: IFile) => {
    formik.setFieldValue("fileId", data.id);
    formik.setFieldValue("fileName", data.name);
  };

  const handleChangePositiveNumber = (event: React.ChangeEvent<any>) => {
    if (!event.target?.value?.startsWith("0")) {
      formik.handleChange(event);
    }
  };

  return (
    <Modal
      show={open}
      onHide={handleClose}
      backdrop="static"
      centered
      size="xl"
      className="modal-size-custom custom-moda profile"
    >
      <Modal.Header
        closeButton
        className="spaces py-12"
      >
        <Modal.Title className="spaces fs-20 fw-600">{lang("BONUS.INFO")}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <div><span className="fs-3 fw-bold">{lang("GENERAL.APPRECIATIVE")}</span></div>
          <Row className="pt-2 gap-col-modal">
            <Col xs={2} md={4} lg={4} xxl={4}>
              <TextValidator
                isRequired
                name="name"
                lable={lang("APPRECIATIVE.STAF_NAME")}
                readOnly={true}
                type="text"
                value={formik?.values?.khenThuongList?.[0]?.name || ""}
              />
            </Col>
            <Col xs={2} md={4} lg={3} xxl={2}>
              <TextValidator
                isRequired
                name="phongBanText"
                lable={lang("PROFILE.DEPARTMENT")}
                readOnly={true}
                type="text"
                value={formik?.values?.khenThuongList?.[0]?.phongBanText || ""}
              />
            </Col>
            <Col xs={2} md={4} lg={3} xxl={3}>
              <TextValidator
                isRequired
                name="chucVuText"
                lable={lang("PROFILE.ROLE")}
                readOnly={true}
                type="text"
                value={formik?.values?.khenThuongList?.[0]?.chucVuText || ""}
              />
            </Col>
            <Col xs={2} md={4} lg={3} xxl={3}>
              <TextValidator
                isRequired
                name="viTriCongViecText"
                lable={lang("GENERAL.EMPLOYEE.TITLE")}
                readOnly={true}
                type="text"
                value={formik?.values?.khenThuongList?.[0]?.viTriCongViecText || ""}
              />
            </Col>
          </Row>
          <BonusForm
            handleClose={handleClose}
            bonusInfo={bonusInfo}
            isView={isEdit}
            formik={formik}
            handleChangeSelect={handleChangeSelect}
            handleCheckBoxChange={handleCheckBoxChange}
            handleChangePositiveNumber={handleChangePositiveNumber}
            handleSetFile={handleSetFile}
            isPersonal={true}
          />
        </Modal.Body>
        <Modal.Footer className="flex-center">
          {!isEdit &&
            <GroupButton type="btn-save" />
          }
          <GroupButton type="btn-cancel" handleCloseUpdateDialog={handleClose} />
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default BonusViewDialog;

import TextValue from "../../../components/TextValue"

export const columnsWorkingProcess = () => {
  return (
    [
      {
        name: "STT",
        field: "",
        render: (row: any, index: number, stt: number) => <span>{stt}</span>
      },
      {
        name: "Từ tháng, năm đến tháng, năm",
        field: "qtctThoiGianDaoTao",
        headerStyle: {
          minWidth: "130px",
          maxWidth: "130px",
          textAlign: "center",
        },
        cellStyle: {
          minWidth: "130px",
          maxWidth: "130px",
          textAlign: "center",
        },
        render: (row: any) => row?.qtctThoiGianDaoTao ? <span>{row?.qtctThoiGianDaoTao}</span> : <TextValue className="spaces h-25" value="" />
      },
      {
        name: "Chức danh, chức vụ, đơn vị công tác (Đảng, Chính quyền, Đoàn thể)",
        field: "qtctDetails",
        headerStyle: {
          minWidth: "500px",
          textAlign: "center",
        },
        cellStyle: {
          textAlign: "left",
        },
        render: (row: any) => row?.qtctDetails ? <span>{row?.qtctDetails}</span> : <TextValue className="spaces h-25" value="" />
      },
    ]
  )
}
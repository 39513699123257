import { Modal } from "react-bootstrap";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { GroupButton } from "../../components/GroupButton";
import ResignForm from "../../resign/components/resign/ResignForm";
import { ResignInfo } from "../../resign/models/resignModels";
import { useRef } from "react";
import { FormikProps } from "formik";

interface IProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: (values: any) => void;
  resignInfo: ResignInfo;
  isView: boolean;
}

function DisciplineResignDialog(props: IProps) {
  const { open, handleClose, resignInfo, isView, handleSubmit } = props;
  const { lang } = useMultiLanguage();
  const formikRef = useRef<FormikProps<any> | null>(null);
  
  return (
    <Modal
      show={open}
      onHide={handleClose}
      backdrop="static"
      centered
      size="xl"
      className="modal-size-custom custom-modal profile"
    >
      <Modal.Header closeButton className="spaces py-12">
        <Modal.Title className="spaces fs-20 fw-600">{lang("RESIGN.TITLE")}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-10">
        <ResignForm
          handleSubmitForm={handleSubmit}
          handleClose={handleClose}
          resignInfo={resignInfo}
          isView={isView}
          formikRef={formikRef}
          isDialog={true}
        />
      </Modal.Body>
      <Modal.Footer className="flex-center">
        <GroupButton type="btn-cancel" handleCloseUpdateDialog={handleClose} />
        {!isView && <GroupButton type="btn-save" handleSubmit={() => formikRef.current?.handleSubmit()} />}
      </Modal.Footer>
    </Modal>
  );
}

export default DisciplineResignDialog;

import { FC, useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../constants";
import useMultiLanguage from "../../hook/useMultiLanguage";
import InputSearch from "../components/input/InputSearch";
import TableCustom, { columnNamesType } from "../components/table-custom/TableCustom";
import AdvancedSearch from "../profile/components/dialogs/AdvancedSearch";
import { MISSION_STATUS, RESPONSE_STATUS_CODE, TYPE, INIT_SEARCH_OBJECT, EXPORT_FILE_NAME, INIT_INPUT_SEARCH } from "../constants/moduleConsts";
import { convertSearch, hasAuthority, exportToFile, searchDataRoleUser, checkStatus } from "../utils/functionUtils";
import AppContext from "../../AppContext";
import { IConcurrently } from "./models/concurrentlyModels";
import { ListSearch } from "./constants/concurrentlyConsts";
import ConcurrentlyForm from "./components/ConcurrentlyForm";
import { deleteConcurrently, getListConcurrently } from "./services/concurrentlyServices";
import { exportKiemNhiem } from "../services/exportFileServices";
import { GroupButton } from "../components/GroupButton";
import { IItemSearch } from "../models/models";

const Concurrently: FC = () => {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [listConcurrently, setListConcurrently] = useState<IConcurrently[]>([]);
  const [openConcurrentlyForm, setOpenConcurrentlyForm] = useState<boolean>();
  const [conConcurrentlyInfo, setConcurrentlyInfo] = useState<IConcurrently>({} as IConcurrently);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [isView, setIsView] = useState<boolean>(false);
  const [searchObject, setSearchObject] = useState<any>(INIT_SEARCH_OBJECT);
  const [isAdvancedSearch, setIsAdvancedSearch] = useState<boolean>(false);
  const [listInputSearch, setListInputSearch] = useState<any[]>([INIT_INPUT_SEARCH]);
  const [dataChecked, setDataChecked] = useState<IConcurrently[]>([]);

  const handleOpenConcurrentlyForm = (info: any) => {
    setIsView(true);
    setOpenConcurrentlyForm(true);
    setConcurrentlyInfo(info);
  };

  const handleClose = () => {
    setOpenConcurrentlyForm(false);
    setIsView(false);
    setConcurrentlyInfo({} as IConcurrently);
  };

  const handleCloseAndSearch = () => {
    handleSearch();
    handleClose();
  }

  const updatePageData = async (searchData?: any) => {
    try {
      setPageLoading(true);
      setSearchObject({ ...searchData })
      const { data } = await getListConcurrently(
        hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW)
          ? searchDataRoleUser(searchData) : { ...searchData });
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListConcurrently(data?.data?.content || []);
        setTotalPage(data?.data?.pageable?.totalPages);
        setTotalElements(data?.data?.pageable?.totalElements);
        setNumberOfElements(data?.data?.pageable?.numberOfElements);
        setIsAdvancedSearch(false);
      } else {
        toast.warning(data?.message);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleOpenUpdateDialog = () => {
    setOpenConcurrentlyForm(true);
  };

  const handleCloseUpdateDialog = () => {
    setIsView(true);
  };

  const handleSearch = (data: any = {}) => {
    const dataSearch: any = {
      ...searchObject,
      ...data
    };
    dataSearch.keyword = dataSearch?.keyword?.trim() || "";
    updatePageData(dataSearch);
  };

  useEffect(() => {
    if (searchObject?.pageIndex && searchObject?.pageSize) {
      handleSearch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchObject?.pageIndex, searchObject?.pageSize])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchObject({
      ...searchObject,
      keyword: e.target?.value
    });
  };

  const handleOpenAdvancedSearch = () => {
    setIsAdvancedSearch(true);
  };

  const handleAdvancedSearch = (data: IItemSearch[]) => {
    setListInputSearch(data);
    handleSearch({ ...convertSearch(data), pageIndex: 1 });
  };

  const handleDeleteConcurrently = async (ids: string[]) => {
    try {
      const { data } = await deleteConcurrently(ids);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("TOAST.DELETE.CONCURRENTLY.SUCCESS"))
        updatePageData(searchObject);
        return true;
      } else {
        toast.warning(data?.message)
      }
    }
    catch (err: any) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const columns: columnNamesType[] = [
    {
      name: "STT",
      field: "",
      headerStyle: {
        width: "50px",
        minWidth: "50px"
      },
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("GENERAL.STATUS"),
      field: "trangThai",
      headerStyle: {
        width: "150px",
        minWidth: "150px"
      },
      render: (row: any) => (
        <span className={`${checkStatus(MISSION_STATUS, row?.trangThai?.code)} status`}>
          {row?.trangThai?.name}
        </span>
      )
    },
    {
      name: lang("INPUT_APPOINTMENT_CODE"),
      field: "maNhanVien",
      headerStyle: {
        width: "150px",
        minWidth: "150px"
      },
      render: (row: any) => <span className="color-steel-blue fw-600">{row?.maNhanVien}</span>
    },
    {
      name: lang("INPUT_APPOINTMENT_NAME"),
      field: "name",
      headerStyle: {
        width: "200px",
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("CONCURRENTLY.TYPE_ASSIGNMENT"),
      field: "phanCongType",
      headerStyle: {
        minWidth: "140px"
      },
      render: (row) => <span>{row?.phanCongType?.name}</span>
    },
    {
      name: lang("INPUT_APPOINTMENT_UNIT_DEPARTMENT"),
      field: "phongBanText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("INPUT_APPOINTMENT_JOB_POSITION"),
      field: "chucVuText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("INPUT_APPOINTMENT_UNIT_DEPARTMENT_NEW"),
      field: "phongBanMoiText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("INPUT_APPOINTMENT_NEW_JOB_POSITION_NEW"),
      field: "chucVuMoiText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
      render: (row) => <span>{row?.chucVuMoiText || row?.chucVuMoiKhac}</span>
    },
    {
      name: lang("INPUT_APPOINTMENT_NUMBER"),
      field: "soQuyetDinh",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      }
    }
  ];

  return (
    <div className="spaces px-8 py-12 flex-1">
      {!openConcurrentlyForm ? (
        <>
          <Row className="spaces mb-12 flex-middle">
            <Col
              xs={5}
              xl={6}
              xxl={7}
              className="p-0"
            >
              <h2 className="breadcrumb-title">{lang("CONCURRENTLY.LIST")}</h2>
            </Col>
            <Col
              xs={7}
              xl={6}
              xxl={5}
              className="flex"
            >
              <div className="spaces mr-10 flex-1">
                <InputSearch
                  className="border-3px"
                  value={searchObject?.keyword}
                  placeholder="Tìm kiếm theo mã NV, họ và tên"
                  handleSearch={() => handleSearch({ pageIndex: 1 })}
                  handleChange={handleChange}
                  isEnter
                />
              </div>
              <GroupButton className="spaces mr-10" handleEvent={() => handleSearch({ pageIndex: 1 })}>
                {lang("BTN.SEARCH")}
              </GroupButton>
              <GroupButton className="spaces mr-10" outline handleEvent={handleOpenAdvancedSearch}>
                {lang("BTN.ADVANCE_SEARCH")}
              </GroupButton>
              {isAdvancedSearch && (
                <AdvancedSearch
                  open={isAdvancedSearch}
                  listInputSearch={listInputSearch}
                  handleSearch={handleAdvancedSearch}
                  listOption={ListSearch(lang)}
                  handleClose={() => setIsAdvancedSearch(false)}
                />
              )}
            </Col>
          </Row>
          <Row className="bg-white">
            <Col className="table-scroll">
              <TableCustom
                id="appointment-list"
                data={listConcurrently || []}
                columns={columns}
                searchObject={searchObject}
                setSearchObject={setSearchObject}
                buttonAdd={hasAuthority(PERMISSIONS.KIEM_NHIEM, PERMISSION_ABILITY.CREATE)}
                buttonExportExcel={!hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW)}
                notDelete={!hasAuthority(PERMISSIONS.KIEM_NHIEM, PERMISSION_ABILITY.DELETE)}
                handleExportExcel={(ids: string[]) =>
                  exportToFile({
                    exportAPI: () => exportKiemNhiem({ ids, ...searchObject }),
                    fileName: EXPORT_FILE_NAME.KIEM_NHIEM,
                    setPageLoading
                  })
                }
                type={TYPE.MULTILINE}
                fixedColumnsCount={4}
                totalPages={totalPage}
                totalElements={totalElements}
                numberOfElements={numberOfElements}
                handleOpenDialog={handleOpenUpdateDialog}
                handleDoubleClick={(row: any) => handleOpenConcurrentlyForm(row)}
                handleDelete={(ids: string[]) => handleDeleteConcurrently(ids)}
                setDataChecked={setDataChecked}
                dataChecked={dataChecked}
                deleteConditional={[
                  {
                    keyPath: 'xacNhanKiemNhiem',
                    value: true
                  }
                ]}
              />
            </Col>
          </Row>
        </>
      ) : (
        <ConcurrentlyForm
          handleClose={handleClose}
          handleCloseAndSearch={handleCloseAndSearch}
          concurrentlyInfo={conConcurrentlyInfo}
          handleCloseUpdateDialog={handleCloseUpdateDialog}
          isView={isView}
          setIsView={setIsView}
        />
      )}
    </div>
  );
};

export { Concurrently };

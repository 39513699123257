/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import Autocomplete from "../../components/input/autocomplete";
import TextValidator from "../../components/input/text-validator";
import { RESPONSE_STATUS_CODE, VARIABLE_STRING, TYPE_CATEGORY } from "../../constants/moduleConsts";
import { formatDateTable, handleBlurDate } from "../../utils/functionUtils";
import { LIST_STATUS_NV, OFFICER_CODE } from "../constants/dialogChildConsts";
import { HAS_RETIRED_CODE, TYPE_NATURE_OF_WORK, listContractType, natureOfWork } from "../constants/profileDialogConsts";
import { INIT_WORK, REF_TAB, TYPE_POSITION } from "../constants/profileConsts";
import "../profile.scss";
import { getContractByEmployee, getNguoiQuanLy, addWorkInfo } from "../services/profileServices";
import { convertDataInfoDto } from "../utils/profileUtils";
import { getBacLuongByChucDanh } from "../services/dialogServices";
import AppContext from "../../../AppContext";
import TableCustom from "../../components/table-custom/TableCustom";
import { searchAllSimpleValue } from "../../services/services";

export const WorkInformation = (props: any) => {
  const { employeeProfiles, activeTab, isView, handleGetEmployee, identify, formikRef, handleToggleIsView } = props;
  const { lang } = useMultiLanguage();
  const [isWorking, setIsWorking] = useState(true);
  const [requireObject, setRequireObject] = useState<any>({});
  const [isCollaborator, setIsCollaborator] = useState<boolean>(false);
  const { setPageLoading } = useContext(AppContext);

  const updateContractType = async () => {
    if (identify) {
      try {
        const { data } = await getContractByEmployee(identify);
        if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
          formik.setFieldValue(VARIABLE_STRING.LOAI_HOP_DONG, data?.data?.loaiHopDong || null);
        } else {
          toast.warning(data?.message);
        }
      } catch (error) {
        toast.error(lang("GENERAL.ERROR"));
      }
    }
  };

  const validationSchema = Yup.object().shape({
    donViCongTac: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    phongBan: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    viTriCongViec: Yup.object()
      .concat(
        requireObject?.chucDanh ? 
          Yup.object().required(lang("VALIDATION.REQUIRE")).nullable()
          : Yup.object().nullable()
      )
      .nullable(),
    chucVu: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    bac: Yup.object()
      .concat(
        requireObject?.bac ? 
          Yup.object().required(lang("VALIDATION.REQUIRE")).nullable()
          : Yup.object().nullable()
      ).nullable(),
    trangThaiLaoDong: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    tinhChatLaoDong: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    ngayThuViec: Yup.date()
      .concat(
        requireObject?.ngayThuViec ? 
          Yup.date().required(lang("VALIDATION.REQUIRE")).nullable()
          : Yup.date().nullable()
      )
      .when("ngayChinhThuc", {
        is: (ngayChinhThuc: Date | null) => ngayChinhThuc && ngayChinhThuc < (new Date()),
        then: Yup.date()
          .max(
            Yup.ref("ngayChinhThuc"), 
            lang("VALIDATE.TRIAL_DATE_BEFORE_OFFICIAL_DATE")
          )
          .nullable()
      })
      .max(new Date(), lang("VALIDATION.INVALID_TRIAL_DATE"))
      .nullable(),
    ngayVaoCoQuan: Yup.date()
      .when("ngayThuViec", {
        is: (ngayThuViec: Date | null) => ngayThuViec && ngayThuViec < (new Date()),
        then: Yup.date()
          .max(
            Yup.ref("ngayThuViec"),
            lang("VALIDATE.APPRENTICE_DATE_BEFORE_TRIAL_DATE")
          )
          .nullable()
      })
      .max(new Date(), lang("VALIDATION.INVALID_APPRENTICE_DATE"))
      .nullable(),
    ngayTapSu: Yup.date()
      .when("ngayChinhThuc", {
        is: (ngayChinhThuc: Date | null) => ngayChinhThuc && ngayChinhThuc < (new Date()),
        then: Yup.date()
          .max(
            Yup.ref("ngayChinhThuc"),
            lang("VALIDATE.PROBATIONARY_DATE_BEFORE_OFFICIAL_DATE")
          )
          .nullable()
      })
      .max(new Date(), lang("VALIDATION.INVALID_PROBATIONARY_DATE"))
      .nullable(),
    ngayChinhThuc: Yup.date()
      .concat(
        requireObject?.ngayChinhThuc ? 
          Yup.date().required(lang("VALIDATION.REQUIRE")).nullable()
          : Yup.date().nullable()
      )
      .max(new Date(), lang("VALIDATION.INVALID_OFFICIAL_DATE"))
      .nullable(),
    ngayNghiViec: isWorking ? Yup.date().nullable() : Yup.date().required(lang("VALIDATION.REQUIRE")).nullable(),
  });

  useEffect(() => {
    if (activeTab !== REF_TAB.TT_CONG_VIEC) return;
    if (identify) {
      formik.setValues(employeeProfiles);
      updateContractType();
    } else {
      formik.setValues(INIT_WORK);
    }
  }, [employeeProfiles, activeTab]);

  const handleFormSubmit = async (values: any) => {
    try {
      setPageLoading(true);
      const dataInfo = convertDataInfoDto(values);
      const data = await addWorkInfo(dataInfo, employeeProfiles?.id);
      if (data?.data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("TOAST.EDIT.SUCCESS"));
        handleToggleIsView();
        handleGetEmployee(employeeProfiles?.id);
      } else {
        toast.warning(data?.data?.message);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: INIT_WORK,
    validationSchema,
    onSubmit: handleFormSubmit
  });

  useEffect(() => {
    let officerType = employeeProfiles?.loaiCanBo;
    const conditionalCodes = [
      OFFICER_CODE.CHUA_XAC_DINH, 
      OFFICER_CODE.HOP_DONG_LAO_DONG, 
      OFFICER_CODE.CONG_TAC_VIEN,
      OFFICER_CODE.HOP_DONG_LAO_DONG_68,
      OFFICER_CODE.HOP_DONG_LAO_DONG_111
    ];
    const officialDateConditionalCodes = [OFFICER_CODE.CONG_TAC_VIEN];
    if(officerType?.code) {
      let isRequiredNgayThuViec = conditionalCodes.includes(Number(officerType?.code));
      let isCooperator = officialDateConditionalCodes.includes(Number(officerType?.code));
      setRequireObject({
        ngayThuViec: isRequiredNgayThuViec && !isCooperator,
        ngayChinhThuc: !isCooperator,
        chucDanh: !isCooperator,
        bac: !isCooperator
      });
      setIsCollaborator(isCooperator);
      const getPosition = async () => {
        try {
          const { data } = await searchAllSimpleValue({ type: TYPE_CATEGORY.chucDanh });
          if(data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
            const positionCollaborator = data.data?.content?.find((position: any) => position?.mappingName === TYPE_POSITION.CONG_TAC_VIEN);
            formik.setFieldValue("chucVu", positionCollaborator);
          } else {
            toast.warning(data?.message);
          }
          const natureOfWorkCollaborator = natureOfWork.find(item => item?.code === TYPE_NATURE_OF_WORK.CONG_TAC_VIEN);
          formik.setFieldValue("tinhChatLaoDong", natureOfWorkCollaborator);
        } catch (error) {
          toast.error(lang("GENERAL.ERROR"));
        }
      }
      if(isCooperator) {
        getPosition();
      }
    }
  }, [employeeProfiles?.loaiCanBo?.code, activeTab])

  const handleChangeSelect = (name: string, value: any) => {
    formik.setFieldValue(name, value);
  };

  const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target;
    formik.setFieldValue(name, checked);
  };

  useEffect(() => {
    if (formikRef) {
      formikRef.current = formik;
    }
  }, [formik, formikRef]);

  const handleChangeTrangThaiLaoDong = (value: any) => {
    switch (value?.code) {
      case HAS_RETIRED_CODE:
        formik.setFieldValue(VARIABLE_STRING.TRANG_THAI_LAO_DONG, value);
        setIsWorking(false);
        break;
      default:
        formik.setValues({
          ...formik.values,
          [VARIABLE_STRING.TRANG_THAI_LAO_DONG]: value,
          [VARIABLE_STRING.NGAY_NGHI_VIEC]: "",
          [VARIABLE_STRING.LY_DO_NGHI]: ""
        });
        setIsWorking(true);
        break;
    }
  };

  const columnsConcurrently = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("CONCURRENTLY.TYPE_ASSIGNMENT"),
      field: "phanCongType",
      headerStyle: {
        minWidth: "140px"
      },
      render: (row: any) => <span>{row?.phanCongType?.name}</span>
    },
    {
      name: lang("CONCURRENTLY.DEPARTMENT"),
      field: "phongBanMoiText",
      headerStyle: {
        minWidth: "150px",
        textAlign: "left"
      }
    },
    {
      name: lang("CONCURRENTLY.POSITION"),
      field: "chucVuMoiText",
      headerStyle: {
        minWidth: "150px",
        textAlign: "left"
      },
      render: (row: any) => <span>{row?.chucVuMoiText || row?.chucVuMoiKhac}</span>
    },
    {
      name: lang("CONCURRENTLY.TIME"),
      field: "time",
      headerStyle: {
        minWidth: "150px"
      },
      cellStyle: {
        minWidth: "150px",
        maxWidth: "150px",
      },
      render: (row: any) => <span>{formatDateTable(row?.tuNgay)} - {formatDateTable(row?.thoiGianMienNhiemTuNgay) || formatDateTable(row?.denNgay) || "hiện tại"}</span>
    }
  ]

  return (
    <div className="">
      <Form
        className="form-info flex-column"
        onSubmit={formik.handleSubmit}
      >
        <div className="block-content">
          <span className="text-header">{lang("PROFILE.TITLE")}</span>
          <Row className="g-4 z-index-2 relative">
            <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={3}>
              <TextValidator
                isRequired
                lable={lang("GENERAL.CURRENT_ORGANIZATION")}
                name="donViCongTac"
                type="text"
                value={formik.values?.donViCongTac?.name || ""}
                readOnly={true}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={2}>
              <TextValidator
                isRequired
                lable={lang("INPUT.DEPARTMENTS")}
                name="phongBan"
                type="text"
                value={formik.values?.phongBan?.name || ""}
                readOnly={true}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={3}>
              <Autocomplete
                isRequired
                lable={lang("GENERAL.EMPLOYEE.POSITION")}
                name="chucVu"
                value={formik.values?.chucVu || null}
                searchFunction={searchAllSimpleValue}
                searchObject={{ type: TYPE_CATEGORY.chucDanh }}
                isReadOnly={isView || isCollaborator}
                onChange={(value) => handleChangeSelect("chucVu", value)}
                errors={formik.errors?.chucVu}
                touched={formik.touched?.chucVu}
                options={[]}
                getOptionLabel={(option) => option?.value}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={3}>
              <Autocomplete
                lable={lang("GENERAL.EMPLOYEE.TITLE")}
                name="viTriCongViec"
                isRequired={requireObject?.chucDanh}
                value={formik.values?.viTriCongViec || null}
                searchFunction={searchAllSimpleValue}
                searchObject={{ type: TYPE_CATEGORY.viTriCongViec }}
                isReadOnly={isView || isCollaborator}
                onChange={(selectedOption) => handleChangeSelect("viTriCongViec", selectedOption)}
                options={[]}
                getOptionLabel={(option) => option?.value}
                errors={formik.errors?.viTriCongViec}
                touched={formik.touched?.viTriCongViec}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={1}>
              <Autocomplete
                lable={lang("GENERAL.EMPLOYEE.RANK")}
                name="bac"
                value={formik.values?.bac || null}
                searchFunction={getBacLuongByChucDanh}
                searchObject={formik.values?.viTriCongViec?.id}
                sort={(data: any) => data.sort((a: any, b: any) => a.bacLuong - b.bacLuong)}
                urlData="data.data"
                isRequired={requireObject?.bac}
                isReadOnly={isView || isCollaborator}
                onChange={(value) => handleChangeSelect(VARIABLE_STRING.BAC, value)}
                options={[]}
                getOptionLabel={(option) => option?.bacLuong}
                dependencies={[formik.values?.viTriCongViec?.id]}
                errors={formik.errors?.bac}
                touched={formik.touched?.bac}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={3}>
              <Autocomplete
                lable={lang("GENERAL.EMPLOYEE.CONTRACT_TYPE")}
                name="loaiHopDong"
                value={formik.values?.loaiHopDong || null}
                options={listContractType}
                isReadOnly={true}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={3}>
              <Autocomplete
                lable={lang("GENERAL.EMPLOYEE.MANAGER")}
                name="nguoiQuanLy"
                value={formik.values?.nguoiQuanLy || ""}
                searchFunction={getNguoiQuanLy}
                isReadOnly={isView}
                onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.NGUOI_QUAN_LY, selectedOption)}
                options={[]}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={2}>
              <TextValidator
                lable={lang("GENERAL.EMPLOYEE.PROBATIONARY_START_DATE")}
                name="ngayVaoCoQuan"
                type="date"
                value={formik.values?.ngayVaoCoQuan || ""}
                readOnly={isView}
                onChange={formik.handleChange}
                onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.ngayVaoCoQuan, "ngayVaoCoQuan")}
                errors={formik.errors?.ngayVaoCoQuan}
                touched={formik.touched?.ngayVaoCoQuan}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={2}>
              <TextValidator
                lable={lang("DATE.TRIAL")}
                name="ngayThuViec"
                type="date"
                isRequired={requireObject?.ngayThuViec}
                value={formik.values?.ngayThuViec || ""}
                readOnly={isView}
                onChange={formik.handleChange}
                onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.ngayThuViec, "ngayThuViec")}
                errors={formik.errors?.ngayThuViec}
                touched={formik.touched?.ngayThuViec}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={2}>
              <TextValidator
                lable={lang("DATE.PROBATIONARY")}
                name="ngayTapSu"
                type="date"
                value={formik.values?.ngayTapSu || ""}
                readOnly={isView}
                onChange={formik.handleChange}
                onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.ngayTapSu, "ngayTapSu")}
                errors={formik.errors?.ngayTapSu}
                touched={formik.touched?.ngayTapSu}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={2}>
              <TextValidator
                lable={lang("GENERAL.EMPLOYEE.OFFICIAL_DATE")}
                name="ngayChinhThuc"
                type="date"
                isRequired={requireObject?.ngayChinhThuc}
                value={formik.values?.ngayChinhThuc || ""}
                readOnly={isView}
                onChange={formik.handleChange}
                onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.ngayChinhThuc, "ngayChinhThuc")}
                errors={formik.errors?.ngayChinhThuc}
                touched={formik.touched?.ngayChinhThuc}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={2}>
              <TextValidator
                isRequired={!isWorking}
                lable={lang("GENERAL.EMPLOYEE.RESIGN_DATE")}
                name="ngayNghiViec"
                type="date"
                value={formik.values?.ngayNghiViec || ""}
                readOnly={isView || isWorking}
                // disabled={isWorking}
                onChange={formik.handleChange}
                errors={formik.errors?.ngayNghiViec}
                touched={formik.touched?.ngayNghiViec}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={6}>
              <TextValidator
                lable={lang("GENERAL.EMPLOYEE.RESIGN_REASON")}
                name="lyDoNghi"
                value={formik.values?.lyDoNghi || ""}
                type="text"
                readOnly={isView || isWorking}
                // disabled={isWorking}
                onChange={formik.handleChange}
                errors={formik.errors?.lyDoNghi}
                touched={formik.touched?.lyDoNghi}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={2}>
              <TextValidator
                lable={lang("INPUT.RETIRE_INFO.EXPECTED_RETIREMENT_DATE")}
                name="ngayNghiHuuDuKien"
                value={formik.values?.ngayNghiHuuDuKien || ""}
                type="date"
                readOnly={isView || isWorking}
                // disabled={isWorking}
                onChange={formik.handleChange}
                errors={formik.errors?.ngayNghiHuuDuKien}
                touched={formik.touched?.ngayNghiHuuDuKien}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={2}>
              <TextValidator
                lable={lang("INPUT.RETIRE_INFO.OFFICIAL_RETIREMENT_DATE")}
                name="ngayNghiHuuChinhThuc"
                value={formik.values?.ngayNghiHuuChinhThuc || ""}
                type="date"
                readOnly={true}
                // disabled={isWorking}
                onChange={formik.handleChange}
                errors={formik.errors?.ngayNghiHuuChinhThuc}
                touched={formik.touched?.ngayNghiHuuChinhThuc}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={2}>
              <Autocomplete
                lable={lang("GENERAL.EMPLOYEE.NATURE_OF_WORK")}
                name="tinhChatLaoDong"
                isRequired
                value={formik.values?.tinhChatLaoDong || null}
                options={natureOfWork}
                isReadOnly={isView || isCollaborator}
                onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.TINH_CHAT_LAO_DONG, selectedOption)}
                touched={formik.touched?.tinhChatLaoDong}
                errors={formik.errors?.tinhChatLaoDong}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={2}>
              <Autocomplete
                isRequired
                lable={lang("GENERAL.EMPLOYEE.ACTIVE_STATUS")}
                name="trangThaiLaoDong"
                value={formik.values?.trangThaiLaoDong || null}
                options={LIST_STATUS_NV}
                isReadOnly={isView}
                onChange={(selectedOption) => handleChangeTrangThaiLaoDong(selectedOption)}
                errors={formik.errors?.trangThaiLaoDong}
                touched={formik.touched?.trangThaiLaoDong}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={2}>
              <Form.Check
                label={lang("GENERAL.EMPLOYEE.HAS_UNION")}
                name="thamGiaCongDoan"
                className="checkBox custom-form-check"
                checked={formik.values?.thamGiaCongDoan}
                onChange={handleChangeCheckBox}
                disabled={isView}
              />
            </Col>
          </Row>
          <Row className="spaces mt-12">
            {
              employeeProfiles?.dsChucVuKiemNhiem?.length > 0 &&
              <TableCustom
                id="dsChucVuKiemNhiem"
                title={lang("CONCURRENTLY.LIST")}
                data={employeeProfiles?.dsChucVuKiemNhiem || []}
                isActionTableTab
                columns={columnsConcurrently}
                buttonAdd={false}
                buttonExportExcel={false}
                noToolbar={true}
                noPagination={true}
                dependencies={activeTab}
              />
            }
          </Row>
        </div>
      </Form>
    </div>
  );
};

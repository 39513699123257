import { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import useMultiLanguage from "../../hook/useMultiLanguage";
import AppContext from "../../AppContext";
import { IApprovalConfig } from "./models/categoryModels";
import { INIT_SEARCH_OBJECT, RESPONSE_STATUS_CODE, TYPE } from "../constants/moduleConsts";
import InputSearch from "../components/input/InputSearch";
import { GroupButton } from "../components/GroupButton";
import TableCustom, { columnNamesType } from "../components/table-custom/TableCustom";
import { hasAuthority } from "../utils/functionUtils";
import { PERMISSION_ABILITY, PERMISSIONS } from "../../constants";
import { deleteApprovalConfig, searchApprovalConfig } from "./services/approvalConfigServices";
import ApprovalConfigForm from "./components/ApprovalConfigForm";

const ApprovalConfig = () => {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [listApprovalConfig, setListApprovalConfig] = useState<IApprovalConfig[]>([]);
  const [approvalConfigInfo, setApprovalConfigInfo] = useState<IApprovalConfig>({} as IApprovalConfig);
  const [openApprovalConfigForm, setOpenApprovalConfigForm] = useState<boolean>(false);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [searchObject, setSearchObject] = useState<any>(INIT_SEARCH_OBJECT);
  const [isView, setIsView] = useState(false);

  const getListApprovalConfig = async (searchData?: any) => {
    try {
      setPageLoading(true);
      setSearchObject({ ...searchData })
      const { data } = await searchApprovalConfig(searchData);
      if(data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListApprovalConfig(data?.data?.content || []);
        setTotalPage(data?.data?.pageable?.totalPages);
        setTotalElements(data?.data?.pageable?.totalElements);
        setNumberOfElements(data?.data?.pageable?.numberOfElements);
      } else {
        toast.warning(data?.message);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleOpenCreateDialog = () => {
    setIsView(false);
    setOpenApprovalConfigForm(true);
  }

  const handleSearch = (data: any = {}) => {
    const dataSearch: any = {
      ...searchObject,
      ...data
    };
    dataSearch.keyword = dataSearch?.keyword?.trim() || "";
    getListApprovalConfig(dataSearch);
  };

  useEffect(() => {
    if(searchObject?.pageIndex && searchObject?.pageSize) {
      handleSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchObject?.pageIndex, searchObject?.pageSize])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchObject({
      ...searchObject,
      keyword: e.target?.value
    });
  };

  const handleDeleteItem = async (ids: string[]) => {
    try {
      const { data } = await deleteApprovalConfig(ids)
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("APPROVAL_CONFIG.DELETE"))
        await getListApprovalConfig(searchObject);
        return true;
      } else {
        toast.warning(data?.message)
      }
    }
    catch (err: any) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }

  const handleOpenForm = (info: any) => {
    setIsView(true);
    setOpenApprovalConfigForm(true);
    setApprovalConfigInfo(info);
  };

  const handleClose = () => {
    setOpenApprovalConfigForm(false);
    setApprovalConfigInfo({} as IApprovalConfig);
  };

  const handleCloseAndSearch = () => {
    handleSearch();
    handleClose();
  };
  
  const columns: columnNamesType[] = [
    {
      name: "STT",
      field: "",
      render: (_row: any, _index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("APPROVAL_CONFIG.LETTER_TYPE"),
      field: "letterType",
      headerStyle: {
        minWidth: "100px"
      },
      cellStyle: {
        textAlign: "left"
      },
      render: row => <span>{row?.letterType?.name}</span>
    },
    {
      name: lang("APPROVAL_CONFIG.APPROVING_INFO"),
      field: "approvingInfo",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
      render: row => <span>{row?.approvingInfo?.map((item: any) => item?.name).join(", ")}</span>
    },
    {
      name: lang("GENERAL.NOTE"),
      field: "note",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
    }
  ];
  
  return (
    <div className="spaces px-8 py-12 flex-1">
        <Row className="spaces mb-12 flex-middle flex-space-between">
          <Col xs={5} xl={6} xxl={7} className="p-0">
            <h2 className="breadcrumb-title">{lang("APPROVAL_CONFIG.LIST")}</h2>
          </Col>
          <Col xs={5} xl={3} xxl={3} className="flex">
            <div className="spaces mr-10 flex-1">
              <InputSearch
                className="border-3px"
                value={searchObject?.keyword}
                placeholder="Tìm kiếm"
                handleSearch={() => handleSearch({ pageIndex: 1 })}
                handleChange={handleChange}
                isEnter
              />
            </div>
            <GroupButton handleEvent={() => handleSearch({ pageIndex: 1 })}>
              {lang("BTN.SEARCH")}
            </GroupButton>
          </Col>
        </Row>
        <Row className="bg-white">
          <Col className="table-scroll">
            <TableCustom
              id="approval-config-list"
              data={listApprovalConfig}
              columns={columns}
              searchObject={searchObject}
              setSearchObject={setSearchObject}
              buttonAdd={hasAuthority(PERMISSIONS["DANH_MUC.CHAM_CONG.CAU_HINH_DUYET"], PERMISSION_ABILITY.CREATE)}
              notDelete={!hasAuthority(PERMISSIONS["DANH_MUC.CHAM_CONG.CAU_HINH_DUYET"], PERMISSION_ABILITY.DELETE)}
              type={TYPE.MULTILINE}
              totalPages={totalPage}
              totalElements={totalElements}
              numberOfElements={numberOfElements}
              handleOpenDialog={handleOpenCreateDialog}
              handleDoubleClick={(row: any) => handleOpenForm(row)}
              handleDelete={(ids: string[]) => handleDeleteItem(ids)}
            />
          </Col>
        </Row>
        {openApprovalConfigForm && (
          <ApprovalConfigForm
            open={openApprovalConfigForm}
            handleClose={handleClose}
            handleCloseAndSearch={handleCloseAndSearch}
            approvalConfigInfo={approvalConfigInfo}
            isView={isView}
          />
        )}
    </div>
  );
}

export { ApprovalConfig };

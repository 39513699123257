import { FC } from "react";
import { Button } from "react-bootstrap";
import useMultiLanguage from "../../hook/useMultiLanguage";
import "../styles/_button.scss";
import { WithChildren } from "../../../_metronic/helpers";

interface IProps extends WithChildren {
  className?: string;
  disabled?: boolean;
  handleClose?: () => void;
  handleCloseUpdateDialog?: () => void;
  handleEvent?: any;
  handleSaveEdit?: () => void;
  handleSubmit?:  (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  outline?: boolean;
  type?: "btn-back" | "btn-edit" | "btn-save" | "btn-cancel";
  typeButton?: "button" | "submit";
  value?: any;
  variant?: string;
}

const GroupButton: FC<IProps> = (props) => {
  const { 
    children,
    className,
    disabled = false,
    handleClose,
    handleCloseUpdateDialog,
    handleEvent,
    handleSubmit,
    handleSaveEdit,
    outline = false,
    type,
    typeButton,
    value,
    variant
  } = props;
  const { lang } = useMultiLanguage();

  return (
    <>
      {type === "btn-back" ? (
        outline ? (
          <div className={`${className ?? ""}`}>
            <Button type="button" variant="outline-primary" className="button-primary" onClick={handleClose}>
              {lang("AUTH.GENERAL.BACK_BUTTON")}
            </Button>
          </div>
        ) : (
          <div className={`d-flex align-items-center cursor-pointer ${className ?? ""}`} onClick={handleClose}>
            <i className="bi bi-arrow-bar-left fs-2 me-2 text-primary "></i>
            <span className="fs-3 fw-bold">
              {lang("AUTH.GENERAL.BACK_BUTTON")}
            </span>
          </div>
        )
      ) : type === "btn-edit" ? (
        <div className={`${className ?? ""}`}>
          <Button 
            type="button"
            className="button-primary" 
            variant={outline ? "outline-primary" : "primary"} 
            onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              event.preventDefault();
              handleSaveEdit && handleSaveEdit(); 
            }}
            disabled={disabled}
          >
            <i className="bi-pencil fs-4 me-2"></i>
            {lang("BTN.EDIT")}
          </Button>
        </div>
      ) : type === "btn-cancel" ? (
        <div className={`${className ?? ""}`}>
          <Button type="button" variant={outline ? "outline-secondary" : "secondary" } className="button-secondary" onClick={handleCloseUpdateDialog}>
            {lang("BTN.CANCEL")}
          </Button>
        </div>
      ) : type === "btn-save" ? (
        <div className={`${className ?? ""}`}>
          <Button 
            type={typeButton ?? "submit"} 
            className="button-primary" 
            variant={outline ? "outline-primary" : "primary"} 
            onClick={(e: any) => handleSubmit && handleSubmit(value)}
            disabled={disabled}
          >
            {lang("BTN.SAVE")}
          </Button>
        </div>
      ) : 
        <div className={`inline-block ${className ?? ""}`}>
          <Button 
            type={typeButton ?? "button"} 
            className="button-primary w-100"
            variant={variant ?? (outline ? "outline-primary" : "primary")}
            onClick={handleEvent}
            disabled={disabled}
          >
            {children}
          </Button>
        </div>
      }
    </>
  );
};
export { GroupButton };

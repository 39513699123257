import { paramsConfig } from "../../utils/paramsUtils";
import { IAppointment } from "../models/appointmentModels";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
export const getListAppointment = (searchData:any) => {
  let url = `${API_URL}/bo-nhiem/page`;
  return axios.get(url,paramsConfig(searchData))
}
export const addNewAppointment = (
  appointment: IAppointment) => {
  let url = `${API_URL}/bo-nhiem`;
  return axios.post(url, appointment);
};
export const editAppointment = (id:string,
  appointment: IAppointment) => {
  let url = `${API_URL}/bo-nhiem/${id}`;
  return axios.put(url, appointment);
};
export const deleteAppointment = (ids:string[]) => {
  let url = `${API_URL}/bo-nhiem/boNhiemIds?ids=${ids.toString()}`;
  return axios.delete(url);
};

import { formatDateTable } from "../../../utils/functionUtils";

export const canhBaoNangLuongTruocHanColumn = (handleActionClick: any) => {
  return (
    [
      {
        name: "Trạng thái",
        field: "",
        headerCellProps: {
          minWidth: 120
        },
        render: (row: any) => <span className={`${row?.trangThaiHetHanNangLuong?.bgColor} warning-status`}>{row?.trangThaiHetHanNangLuong?.name}</span>
      },
      {
        name: "Điều chỉnh lương",
        field: "",
        headerCellProps: {
          minWidth: 120
        },
        bodyCellProps: {
          textAlign: "center"
        },
        render: (row: any) => {
          return (
            <span
              onClick={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                e.stopPropagation();
                handleActionClick && handleActionClick(row);
              }}
            >
              <i className="bi bi-pencil-square text-primary fs-3"></i>
            </span>
          );
        }
      },
      {
        name: "Họ và tên",
        field: "employeeCode",
        headerCellProps: {
          minWidth: 280
        },
        render: (row: any) => <span>{`${row?.maNhanVien || ""} ${row?.maNhanVien && row?.name ? "-" : ""} ${row?.name || ""}`}</span>
      },
      {
        name: "Khoa/Phòng",
        field: "phongBan",
        headerCellProps: {
          minWidth: 300
        },
        render: (row: any) => <span>{`${row?.donViKyHopDongText || ""} ${row?.phongBanText && row?.donViKyHopDongText ? "/" : ""} ${row?.phongBanText || ""}`}</span>
      },
      {
        name: "Ngạch/chức danh",
        field: "chucDanhText",
        headerCellProps: {
          minWidth: 200
        }
      },
      {
        name: "Ngày hưởng lương",
        field: "ngayHuongLuongTuNgay",
        headerCellProps: {
          minWidth: 120
        },
        bodyCellProps: {
          textAlign: "center"
        },
        render: (row: any) => <span>{formatDateTable(row?.ngayHuongLuongTuNgay)}</span>
      },
      {
        name: "Bậc lương",
        field: "bacLuong",
        headerCellProps: {
          minWidth: 80
        },
        bodyCellProps: {
          textAlign: "center"
        }
      },
      {
        name: "Hệ số lương",
        field: "heSoBacLuong",
        headerCellProps: {
          minWidth: 100
        },
        bodyCellProps: {
          textAlign: "center"
        }
      },
      {
        name: "PC thâm niên vượt khung",
        field: "phuCapThamNienVuotKhung",
        headerCellProps: {
          minWidth: 180
        }
      },
      {
        name: "Ngày đề xuất",
        field: "ngayDeXuat",
        headerCellProps: {
          minWidth: 110
        },
        bodyCellProps: {
          textAlign: "center"
        },
        render: (row: any) => <span>{formatDateTable(row?.ngayDeXuat)}</span>
      },
      {
        name: "Bậc lương đề xuất",
        field: "bacLuongDeXuat",
        headerCellProps: {
          minWidth: 130
        },
        bodyCellProps: {
          textAlign: "center"
        },
      },
      {
        name: "Hệ số đề xuất",
        field: "heSoDeXuat",
        headerCellProps: {
          minWidth: 110
        },
        bodyCellProps: {
          textAlign: "center"
        },
      },
      {
        name: "PC thâm niên vượt khung đề xuất",
        field: "phuCapThamNienVuotKhungDeXuat",
        headerCellProps: {
          minWidth: 220
        }
      }
    ]
  )
}
import { useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import TextValidator from "../../components/input/text-validator";
import { IExpenses } from "../models/welfareModels";
import Autocomplete from "../../components/input/autocomplete";
import { INIT_EXPENSES } from "../constants/welfareConsts";
import { RESPONSE_STATUS_CODE, VARIABLE_STRING } from "../../constants/moduleConsts";
import * as Yup from "yup";
import { convertNumberPrice, convertTextPrice, generateRandomId } from "../../utils/functionUtils";
import { GroupButton } from "../../components/GroupButton";
import { searchNguoiDaiDienKy } from "../../services/services";
import { IPeopleDecision } from "../../models/models";
import { toast } from "react-toastify";
import { AddNewRepresentative } from "../../components/dialogs";

interface Props {
  handleCloseDialog: () => void;
  isView: boolean;
  expenses: IExpenses;
  handleAdd: (data: IExpenses) => void;
  handleEdit: (data: IExpenses) => void;
}
export const ActualCostDialog = (props: Props) => {
  const { lang } = useMultiLanguage();

  const { handleCloseDialog, expenses, isView, handleAdd, handleEdit } = props;
  const [isShowDialogAddRepresentative, setIsShowDialogAddRepresentative] = useState<boolean>(false);
  const [peopleDecisions, setPeopleDecisions] = useState<IPeopleDecision[]>([]);

  const getPeopleDecision = useCallback(async () => {
    try {
      const { data } = await searchNguoiDaiDienKy();
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setPeopleDecisions(data?.data?.content || []);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }, [lang]);

  useEffect(() => {
    getPeopleDecision();
  }, [getPeopleDecision]);

  useEffect(() => {
    if(!expenses?.id && peopleDecisions.length > 0) {
      const representativeDefault = peopleDecisions.find(people => people.isDefault) || null;
      formik.setValues({
        ...formik.values,
        nguoiDuyet: representativeDefault,
        nguoiDuyetId: representativeDefault?.id || "",
        tenNguoiDuyet: representativeDefault?.name || ""
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [peopleDecisions]);

  const handleCloseAndSearchRepresentative = () => {
    getPeopleDecision();
    setIsShowDialogAddRepresentative(false);
  }

  useEffect(() => {
    if (expenses?.id) {
      let dataConverted = {
        ...expenses,
        nguoiDuyet: expenses?.nguoiDuyetId
          ? {
              id: expenses?.nguoiDuyetId || "",
              name: expenses?.tenNguoiDuyet || ""
            }
          : null
      };
      formik.setValues(dataConverted);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expenses]);

  const handleFormSubmit = (values: IExpenses) => {
    let _values = {
      ...values,
      id: values?.id || generateRandomId()
    };
    expenses?.id ? handleEdit(_values) : handleAdd(_values);
    handleCloseDialog();
  };

  const validationSchema = Yup.object().shape({
    tenKhoanChi: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    soTien: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    nguoiDuyet: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable()
  });

  const formik = useFormik({
    initialValues: INIT_EXPENSES,
    onSubmit: handleFormSubmit,
    validationSchema
  });

  const handleChangeSelect = (name: string, value: any) => {
    switch (name) {
      case VARIABLE_STRING.NGUOI_DUYET:
        formik.setValues({
          ...formik.values,
          [name]: value,
          tenNguoiDuyet: value?.name || "",
          nguoiDuyetId: value?.id || ""
        });
        break;
      default:
        formik.setFieldValue(name, value);
        break;
    }
  };

  const handleChangeMoney = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, Number(convertTextPrice(value)));
  };

  return (
    <Modal
      show={true}
      size="lg"
      centered
      aria-labelledby="example-custom-modal-styling-title"
      onHide={handleCloseDialog}
      backdrop="static"
    >
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title
            id="example-custom-modal-styling-title"
            className="heading-5"
          >
            {lang("WELFARE.ACTUAL_COST")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="g-4">
            <Col xs={4}>
              <TextValidator
                name="tenKhoanChi"
                isRequired
                lable={lang("WELFARE.ACTUAL_COST.NAME")}
                type="text"
                value={formik.values?.tenKhoanChi}
                onChange={formik.handleChange}
                readOnly={isView}
                touched={formik.touched?.tenKhoanChi}
                errors={formik.errors?.tenKhoanChi}
              />
            </Col>
            <Col xs={4}>
              <TextValidator
                name="soTien"
                isRequired
                lable={lang("WELFARE.ACTUAL_COST.AMOUNT")}
                type="text"
                onChange={handleChangeMoney}
                value={convertNumberPrice(formik.values?.soTien || null)}
                readOnly={isView}
                touched={formik.touched?.soTien}
                errors={formik.errors?.soTien}
              />
            </Col>
            <Col xs={4}>
              <Autocomplete
                isRequired
                lable={lang("WELFARE.ACTUAL_COST.APPROVED_PEOPLE")}
                options={peopleDecisions}
                isReadOnly={isView}
                value={formik.values?.nguoiDuyet || null}
                name="nguoiDuyet"
                onChange={(value) => handleChangeSelect(VARIABLE_STRING.NGUOI_DUYET, value)}
                isClearable={false}
                isAddNew
                onAddNew={() => setIsShowDialogAddRepresentative(true)}
                touched={formik.touched?.nguoiDuyet}
                errors={formik.errors?.nguoiDuyet}
              />
            </Col>
            <Col xs={12}>
              <TextValidator
                name="note"
                lable={lang("WELFARE.ACTUAL_COST.NOTE")}
                type="text"
                as="textarea"
                rows={3}
                value={formik.values?.note || ""}
                onChange={formik.handleChange}
                readOnly={isView}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="flex-center">
          <GroupButton type="btn-cancel" handleCloseUpdateDialog={handleCloseDialog} />
          {!isView && <GroupButton type="btn-save" />}
        </Modal.Footer>
      </Form>
      {isShowDialogAddRepresentative && (
        <AddNewRepresentative
          handleClose={() => setIsShowDialogAddRepresentative(false)}
          handleCloseAndSearch={handleCloseAndSearchRepresentative}
        />
      )}
    </Modal>
  );
};

import { FC, useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../constants";
import useMultiLanguage from "../../hook/useMultiLanguage";
import InputSearch from "../components/input/InputSearch";
import TableCustom, { columnNamesType } from "../components/table-custom/TableCustom";
import AdvancedSearch from "../profile/components/dialogs/AdvancedSearch";
import { exportBoNhiem } from "../services/exportFileServices";
import { convertSearch, formatDateTable, hasAuthority, searchDataRoleUser, exportToFile, checkStatus } from "../utils/functionUtils";
import AppointmentForm from "./components/AppointmentForm";
import { ListSearch } from "./constants/appointmentConsts";
import { IAppointment } from "./models/appointmentModels";
import AppContext from "../../AppContext";
import { GroupButton } from "../components/GroupButton";
import { useAuth } from "../auth";
import { deleteAppointment, getListAppointment } from "./services/appointmentServices";
import {
  COMPLETED_STATUS,
  COMPLETED_STATUS_CODE,
  EXPORT_FILE_NAME,
  INIT_INPUT_SEARCH,
  INIT_SEARCH_OBJECT,
  RESPONSE_STATUS_CODE,
  TYPE
} from "../constants/moduleConsts";
import { IItemSearch } from "../models/models";

const AppointmentList: FC = () => {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);
  const { currentUser } = useAuth();

  const [listAppointment, setListAppointment] = useState<IAppointment[]>([]);
  const [dataChecked, setDataChecked] = useState<IAppointment[]>([]);
  const [openAppointmentForm, setOpenAppointmentForm] = useState<boolean>();
  const [appointmentInfo, setAppointmentInfo] = useState<IAppointment>({} as IAppointment);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [isView, setIsView] = useState<boolean>(false);
  const [searchObject, setSearchObject] = useState<any>(INIT_SEARCH_OBJECT);
  const [isAdvancedSearch, setIsAdvancedSearch] = useState<boolean>(false);
  const [listInputSearch, setListInputSearch] = useState<any[]>([INIT_INPUT_SEARCH]);

  const handleOpenAppointmentForm = (info: any) => {
    setIsView(true);
    setOpenAppointmentForm(true);
    setAppointmentInfo({
      ...info,
      employee: hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW) ? currentUser : info?.employee
    });
  };

  const handleCloseAndSearch = () => {
    handleSearch();
    handleClose();
  }

  const handleClose = () => {
    setOpenAppointmentForm(false);
    setIsView(false);
    setAppointmentInfo({} as IAppointment);
  };

  const getAllAppointment = async (searchData?: any) => {
    try {
      setPageLoading(true);
      setSearchObject({ ...searchData })
      const { data } = await getListAppointment(hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW) ? searchDataRoleUser(searchData) : { ...searchData });
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListAppointment(data?.data?.content || []);
        setTotalPage(data?.data?.pageable?.totalPages);
        setTotalElements(data?.data?.pageable?.totalElements);
        setNumberOfElements(data?.data?.pageable?.numberOfElements);
        setIsAdvancedSearch(false);
      } else {
        toast.warning(data?.message);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleOpenUpdateDialog = () => {
    if (hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW)) {
      setAppointmentInfo({ employee: currentUser } as IAppointment);
    }
    setOpenAppointmentForm(true)
  }

  const handleCloseUpdateDialog = () => {
    setIsView(true);
  }

  const columns: columnNamesType[] = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("GENERAL.STATUS"),
      field: "trangThai",
      headerStyle: {
        minWidth: "150px"
      },
      render: (row: any) => <span className={`${checkStatus(COMPLETED_STATUS, row?.trangThai?.code)} status`}>
        {row?.trangThai?.name}
      </span>
    },
    {
      name: lang("INPUT_APPOINTMENT_CODE"),
      field: "maNhanVien",
      headerStyle: {
        minWidth: "150px"
      },
      render: (row: any) => <span className="color-steel-blue fw-600">{row?.maNhanVien}</span>
    },
    {
      name: lang("INPUT_APPOINTMENT_NAME"),
      field: "ten",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
    },
    {
      name: lang("INPUT_APPOINTMENT_WORK_UNINT"),
      field: "donViCongTacText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
    },
    {
      name: lang("INPUT_APPOINTMENT_UNIT_DEPARTMENT"),
      field: "phongBanText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
    },
    {
      name: lang("INPUT_APPOINTMENT_JOB_POSITION"),
      field: "chucVuText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
    },
    {
      name: lang("INPUT_APPOINTMENT_DATE_START"),
      field: "thoiGianHieuLucTuNgay",
      headerStyle: {
        minWidth: "100px"
      },
      render: (row: any) => <span>{formatDateTable(row?.thoiGianHieuLucTuNgay)}</span>
    },
    {
      name: lang("INPUT_APPOINTMENT_DATE_END"),
      field: "thoiGianHieuLucDenNgay",
      headerStyle: {
        minWidth: "100px"
      },
      render: (row: any) => <span>{formatDateTable(row?.thoiGianHieuLucDenNgay)}</span>
    },
    {
      name: lang("INPUT_APPOINTMENT_WORK_UNINT_NEW"),
      field: "donViCongTacMoiText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
    },
    {
      name: lang("INPUT_APPOINTMENT_UNIT_DEPARTMENT_NEW"),
      field: "phongBanMoiText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
    },
    {
      name: lang("INPUT_APPOINTMENT_NEW_JOB_POSITION_NEW"),
      field: "chucVuMoiText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
    },
    {
      name: lang("INPUT_APPOINTMENT_NUMBER"),
      field: "soQuyetDinh",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
    }
  ]

  const handleSearch = (data: any = {}) => {
    const dataSearch: any = {
      ...searchObject,
      ...data
    };
    dataSearch.keyword = dataSearch?.keyword?.trim() || ""
    getAllAppointment(dataSearch);
  };

  useEffect(() => {
    handleSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchObject?.pageIndex, searchObject?.pageSize])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchObject({
      ...searchObject,
      keyword: e.target?.value
    });
  };

  const handleOpenAdvancedSearch = () => {
    setIsAdvancedSearch(true);
  };

  const handleAdvancedSearch = (data: IItemSearch[]) => {
    setListInputSearch(data);
    handleSearch({ ...convertSearch(data), pageIndex: 1 });
  };

  const handleDeleteAppointment = async (ids: string[]) => {
    try {
      const { data } = await deleteAppointment(ids)
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("TOAST.DELETE.APPOINTMENT.SUCCESS"))
        getAllAppointment(searchObject);
        return true;
      } else {
        toast.warning(data?.message)
      }
    }
    catch (err: any) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }

  return (
    <div className="spaces px-8 py-12 flex-1">
      {!openAppointmentForm ? (
        <>
          <Row className="spaces mb-12 flex-middle">
            <Col xs={5} xl={6} xxl={7} className="p-0">
              <h2 className="breadcrumb-title">{lang("GENERAL_APPOINTMENT_LIST")}</h2>
            </Col>
            <Col xs={7} xl={6} xxl={5} className="flex">
              <div className="spaces mr-10 flex-1">
                <InputSearch
                  className="border-3px"
                  value={searchObject?.keyword}
                  placeholder="Tìm kiếm theo mã NV, họ và tên"
                  handleSearch={() => handleSearch({ pageIndex: 1 })}
                  handleChange={handleChange}
                  isEnter
                />
              </div>
              <GroupButton className="spaces mr-10" handleEvent={() => handleSearch({ pageIndex: 1 })}>
                {lang("BTN.SEARCH")}
              </GroupButton>
              <GroupButton className="spaces mr-10" outline handleEvent={handleOpenAdvancedSearch}>
                {lang("BTN.ADVANCE_SEARCH")}
              </GroupButton>
              {isAdvancedSearch && (
                <AdvancedSearch
                  open={isAdvancedSearch}
                  listInputSearch={listInputSearch}
                  handleSearch={handleAdvancedSearch}
                  listOption={ListSearch(lang)}
                  handleClose={() => setIsAdvancedSearch(false)}
                />
              )}
            </Col>
          </Row>
          <Row className="bg-white">
            <Col className="table-scroll">
              <TableCustom
                id="appointment-list"
                data={listAppointment}
                columns={columns}
                searchObject={searchObject}
                setSearchObject={setSearchObject}
                buttonAdd={hasAuthority(PERMISSIONS.BO_NHIEM, PERMISSION_ABILITY.CREATE)}
                buttonExportExcel={!hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW)}
                notDelete={!hasAuthority(PERMISSIONS.BO_NHIEM, PERMISSION_ABILITY.DELETE)}
                handleExportExcel={(ids: string[]) =>
                  exportToFile({
                    exportAPI: () => exportBoNhiem({ ids, ...searchObject }),
                    fileName: EXPORT_FILE_NAME.BO_NHIEM,
                    setPageLoading
                  })
                }
                type={TYPE.MULTILINE}
                fixedColumnsCount={4}
                totalPages={totalPage}
                totalElements={totalElements}
                numberOfElements={numberOfElements}
                handleOpenDialog={handleOpenUpdateDialog}
                handleDoubleClick={(row: any) => handleOpenAppointmentForm(row)}
                handleDelete={(ids: string[]) => handleDeleteAppointment(ids)}
                setDataChecked={setDataChecked}
                dataChecked={dataChecked}
                deleteConditional={[
                  {
                    keyPath: "trangThai.code",
                    value: COMPLETED_STATUS_CODE.COMPLETED,
                  },
                  {
                    keyPath: 'xacNhanBoNhiem',
                    value: true
                  }
                ]}
              />
            </Col>
          </Row>
        </>
      ) : (
        <AppointmentForm
          handleClose={handleClose}
          handleCloseAndSearch={handleCloseAndSearch}
          appointmentInfo={appointmentInfo}
          handleCloseUpdateDialog={handleCloseUpdateDialog}
          isView={isView}
          setIsView={setIsView}
        />
      )}
    </div>
  )
};

export { AppointmentList };


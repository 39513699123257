import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { INIT_SEARCH_OBJECT, RESPONSE_STATUS_CODE, TYPE } from '../../constants/moduleConsts'
import TableCustom, { columnNamesType } from '../table-custom/TableCustom'
import useMultiLanguage from '../../../hook/useMultiLanguage'
import { GroupButton } from '../GroupButton'
import AppContext from '../../../AppContext'
import { searchAllEmployee } from '../../services/services'
import { toast } from 'react-toastify'
import { IObject } from '../../models/models'

interface IProps {
    open: boolean;
    searchObject: IObject;
    selectedData: any[];
    columnsCustom?: columnNamesType[];
    handleClose: () => void;
    handleSelect: (data: any[]) => void;
    uniquePrefixSelect?: string;
    uniquePrefixList?: string;
    title?: string;
}

function SelectMultilineEmployeeDialog(props: IProps) {
    const {
        open,
        handleClose,
        handleSelect,
        columnsCustom,
        selectedData,
        uniquePrefixSelect = "id",
        uniquePrefixList = "id",
        title = "Chọn danh sách nhân viên"
    } = props;
    const { lang } = useMultiLanguage();
    const { setPageLoading } = useContext(AppContext);
    const [data, setData] = useState<any[]>([]);
    const [dataChecked, setDataChecked] = useState<any[]>(selectedData);
    const [searchObject, setSearchObject] = useState<any>({
        ...INIT_SEARCH_OBJECT,
        ...props?.searchObject
    });

    const [totalPage, setTotalPage] = useState<number>(0);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [numberOfElements, setNumberOfElements] = useState<number>(0);

    useEffect(() => {
        if (searchObject?.pageIndex && searchObject?.pageSize) {
            updatePageData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchObject?.pageIndex, searchObject?.pageSize])

    const updatePageData = async () => {
        try {
            setPageLoading(true);
            let { data } = await searchAllEmployee(searchObject);

            if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
                let listNhanVien = data?.data?.content || [];
                listNhanVien.forEach((item: any) => {
                    item[uniquePrefixSelect] = item?.[uniquePrefixList]
                });
                setData(listNhanVien);
                setTotalPage(data?.data?.pageable?.totalPages);
                setTotalElements(data?.data?.pageable?.totalElements);
                setNumberOfElements(data?.data?.pageable?.numberOfElements);
            } else {
                toast.warning(data?.message);
            }
        } catch (error) {
            toast.error(lang("GENERAL.ERROR"));
        } finally {
            setPageLoading(false);
        }
    };

    const columns: columnNamesType[] = columnsCustom
        ? [...columnsCustom]
        : [
            {
                name: lang("INPUT.FULLNAME"),
                field: "custom",
                headerStyle: {
                    minWidth: "200px"
                },
                cellStyle: {
                    textAlign: "left"
                },
                render: (row: any) => (
                    <span>
                        <p className="color-steel-blue fw-600 spaces p-0 m-0">{row?.maNhanVien}</p>
                        <p className="spaces p-0 m-0">{row?.name || row?.tenNhanVien}</p>
                    </span>
                )
            },
            {
                name: lang("INPUT.SALARY.POSITION"),
                field: "chucVuText",
                headerStyle: {
                    minWidth: "200px"
                },
                cellStyle: {
                    textAlign: "left"
                }
            },
            {
                name: lang("GENERAL.EMPLOYEE.TITLE"),
                field: "viTriCongViecText",
                headerStyle: {
                    minWidth: "200px"
                },
                cellStyle: {
                    textAlign: "left"
                }
            }
        ];
    return (
        <Modal
            show={open}
            onHide={handleClose}
            size="xl"
            centered
            backdrop="static"
            className="custom-modal confirm-dialog"
        >
            <Modal.Header className="bg-primary p-4" closeButton>
                <Modal.Title className="text-white text-uppercase">{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TableCustom
                    data={data}
                    columns={columns}
                    type={TYPE.MULTILINE}
                    noToolbar
                    notDelete
                    setDataChecked={setDataChecked}
                    uniquePrefix={uniquePrefixSelect}
                    dataChecked={dataChecked}
                    searchObject={searchObject}
                    setSearchObject={setSearchObject}
                    totalPages={totalPage}
                    totalElements={totalElements}
                    numberOfElements={numberOfElements}
                />
            </Modal.Body>
            <Modal.Footer>
                <GroupButton handleEvent={() => handleSelect(dataChecked)}>{lang("BTN.SELECT")}</GroupButton>
                <GroupButton type="btn-cancel" handleCloseUpdateDialog={handleClose} />
            </Modal.Footer>
        </Modal>
    )
}

export default SelectMultilineEmployeeDialog
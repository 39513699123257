/* eslint-disable react-hooks/exhaustive-deps */
import { FormikProps } from "formik";
import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { KTSVG } from "../../../_metronic/helpers";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../constants";
import { useAuth } from "../auth";
import { GroupButton } from "../components/GroupButton";
import { RESPONSE_STATUS_CODE } from "../constants/moduleConsts";
import { hasAuthority } from "../utils/functionUtils";
import { useProfileContext } from "./ProfileContext";
import { AttachmentsInformation } from "./components/AttachmentsInformation";
import { BasicInformation } from "./components/BasicInformation";
import { ContactInformation } from "./components/ContactInformation";
import { ContractInformation } from "./components/ContractInformation";
import EmployeeInfo from "./components/EmployeeInfo";
import FamilyInformation from "./components/FamilyInformation";
import MedicalInformation from "./components/MedicalInformation";
import MilitaryInformation from "./components/MilitaryInformation";
import PoliticsInformation from "./components/PoliticsInformation";
import { SalaryInformation } from "./components/SalaryInformation";
import { TempPapersInformation } from "./components/TempPapersInformation";
import { TrainingInformation } from "./components/TrainingInformation";
import VaccinationInformation from "./components/VaccinationInformation";
import { WorkExperienceInformation } from "./components/WorkExperienceInformation";
import { WorkInformation } from "./components/WorkInformation";
import { WorkingProcessInformation } from "./components/WorkingProcessInformation";
import { STATUS_NV } from "./constants/dialogChildConsts";
import { REF_TAB, defaultEmployeeProfile, profileTabs } from "./constants/profileConsts";
import { EmployeeProfile } from "./models/profileModels";
import { getEmployeeProfileById, addBasicInfo } from "./services/profileServices";
import { convertDataUI } from "./utils/profileUtils";
import CustomTooltip from "../components/custom-tooltip";
import AppContext from "../../AppContext";
import debounce from 'lodash/debounce';
import ExportWord from "./components/ExportWord";
import PersonalHistoricalInformation from "./components/PersonalHistoricalInformation";
import useMultiLanguage from "../../hook/useMultiLanguage";
import { searchNationality } from "../services/services";
import { InsuranceInformation } from "./components/InsuranceInformation";
import WelfareInformation from "./components/WelfareInformation";
import BonusAndDisciplineInformation from "./components/BonusAndDisciplineInformation";
import DeclareAssetsInformation from "./components/DeclareAssetsInformation";
import TitleConferredInformation from "./components/TitleConferredInformation";
import FamilyPolicyInformation from "./components/FamilyPolicyInformation";

interface IProps {
  handleCloseDialog: () => void;
  idEmployee?: string;
  setIdEmployee: (data: any) => void;
  isView: boolean;
  handleToggleIsView: () => void;
}

export const ProfileAddAndUpdateV2: React.FC<IProps> = ({
  handleCloseDialog,
  idEmployee,
  setIdEmployee,
  isView,
  handleToggleIsView
}) => {
  const { lang } = useMultiLanguage();
  const { setIsResign } = useProfileContext();
  const { setPageLoading } = useContext(AppContext);
  const { currentUser, setCurrentUser } = useAuth();

  const [employeeProfiles, setEmployeeProfiles] = useState<EmployeeProfile>(defaultEmployeeProfile);
  const [title, setTitle] = useState("PROFILE.ADD");
  const [identify, setIdentify] = useState("");
  const [activeTab, setActiveTab] = useState<string>(REF_TAB.TT_CO_BAN);
  const [dataFromChild, setDataFromChild] = useState({});
  const formikRefBasicInformation = useRef<FormikProps<any>>(null);
  const formikRefContactInformation = useRef<FormikProps<any>>(null);
  const formikRefWorkInformation = useRef<FormikProps<any>>(null);
  const formikRefInsuranceInformation = useRef<FormikProps<any>>(null);
  const formikRefPoliticsInformation = useRef<FormikProps<any>>(null);
  const formikRefLaborContract = useRef<FormikProps<any>>(null);
  const formikRefSalaryInformation = useRef<FormikProps<any>>(null);
  const formikRefTrainingInformation = useRef<FormikProps<any>>(null);
  const [activeFormikRef, setActiveFormikRef] = useState<FormikProps<any> | null>();
  const [nationality, setNationality] = useState([]);
  const [nationalityDefault, setNationalityDefault] = useState(null);
  const [isDialogExportWord, setIsDialogExportWord] = useState<boolean>(false);

  useEffect(() => {
    if (hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW)) {
      setEmployeeProfiles(convertDataUI(currentUser));
      setIsResign(currentUser?.trangThaiLaoDong?.code === STATUS_NV.THOI_VIEC);
    }
  }, [currentUser]);

  useEffect(() => {
    updateDataNationality();

    if(hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW)) {
      setIdentify(currentUser?.id ?? "");
      setIdEmployee(currentUser?.id);
      setTitle("PROFILE.EDIT");
      handleToggleIsView();
    }
  }, []);

  useLayoutEffect(() => {
    const tabList: any = document.querySelector(".tabs-user-info ul");

    if (tabList) {
      const measure = debounce(() => {
        const isScroll = tabList.clientWidth < tabList?.scrollWidth;
        const rightArrow = document.querySelector(".tabs-user-info .arrow-right");
        const leftArrow = document.querySelector(".tabs-user-info .arrow-left");
        if (isScroll) {
          rightArrow?.classList?.remove("hidden");
          const handleShowIcon = () => {
            if (tabList) {
              if (tabList.scrollLeft >= 20) {
                leftArrow?.classList?.remove("hidden");
                tabList.style.marginLeft = "0px";
                tabList.style.marginRight = "24px";
              } else {
                leftArrow?.classList?.add("hidden");
              }

              const maxScrollValue = tabList.scrollWidth - tabList.clientWidth - 20;
              if (tabList.scrollLeft >= maxScrollValue) {
                tabList.style.marginLeft = "24px";
                tabList.style.marginRight = "0px";
                rightArrow?.classList?.add("hidden");
              } else {
                rightArrow?.classList?.remove("hidden");
              }
            }
          };
          rightArrow?.addEventListener("click", () => {
            if (tabList) {
              tabList.scrollLeft += 100;
              handleShowIcon();
            }
          });
          leftArrow?.addEventListener("click", () => {
            if (tabList) {
              tabList.scrollLeft -= 100;
              handleShowIcon();
            }
          });
          tabList?.addEventListener("scroll", handleShowIcon);
        }
      }, 250);

      requestAnimationFrame(measure);

      return () => {
        window.removeEventListener('resize', measure);
      };
    }
  }, []);

  const updateDataNationality = async () => {
    try {
      const { data } = await searchNationality();
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setNationality(data?.data?.content);
        let defaultItem = data?.data?.content?.find((item: any) => item?.name === "Việt Nam");
        setNationalityDefault(defaultItem);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  useEffect(() => {
    if (idEmployee && !hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW)) {
      handleGetEmployee(idEmployee);
    }
  }, [idEmployee]);

  const handleGetEmployee = async (id: string) => {
    try {
      setPageLoading(true);
      let { data } = await getEmployeeProfileById(id);
      if(data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        if(id === currentUser?.id) setCurrentUser(data?.data);
        setEmployeeProfiles(convertDataUI(data?.data));
        setIsResign(data?.data?.trangThaiLaoDong?.code === STATUS_NV.THOI_VIEC);
        setIdentify(id);
      } else {
        toast.warning(data?.message);
      }
    } catch (err: any) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleClose = () => {
    handleCloseDialog();
    setEmployeeProfiles(defaultEmployeeProfile);
  };

  const handleUploadAvatar = (url: string) => {
    const newEmployeeInfo = { ...employeeProfiles, photo: url };
    setEmployeeProfiles(newEmployeeInfo);
    addBasicInfo(newEmployeeInfo);
  };

  const handleOpenField = (statusField: boolean, condition: string | number, handleSet: any) => {
    if (statusField === false && !condition) {
      toast.warning(lang("MESSAGE.BASIC.FIRST"));
    } else {
      handleSet(!statusField);
    }
  };

  const handleChangeSelect = (name: string, value: string) => {
    setEmployeeProfiles({ ...employeeProfiles, [name]: value });
  };

  const handleTabChange = (tabId: string) => {
    if (tabId !== REF_TAB.TT_CO_BAN && !employeeProfiles?.id) {
      toast.warning(lang("MESSAGE.BASIC.FIRST"));
      return;
    }
    setActiveTab(tabId);
    switch (tabId) {
      case REF_TAB.TT_CO_BAN:
        setActiveFormikRef(formikRefBasicInformation.current);
        break;
      case REF_TAB.TT_LIEN_HE:
        setActiveFormikRef(formikRefContactInformation.current);
        break;
      case REF_TAB.TT_CONG_VIEC:
        setActiveFormikRef(formikRefWorkInformation.current);
        break;
      case REF_TAB.TT_CHINH_TRI:
        setActiveFormikRef(formikRefPoliticsInformation.current);
        break;
      case REF_TAB.BAO_HIEM:
        setActiveFormikRef(formikRefInsuranceInformation.current);
        break;
      case REF_TAB.TT_LUONG:
        setActiveFormikRef(formikRefSalaryInformation.current);
        break;
      case REF_TAB.HOP_DONG_LAO_DONG:
        setActiveFormikRef(formikRefLaborContract.current);
        break;
      case REF_TAB.DAO_TAO_BOI_DUONG:
        setActiveFormikRef(formikRefTrainingInformation.current);
        break;
      default:
        setActiveFormikRef(null);
        break;
    }
  };

  useEffect(() => {
    setActiveFormikRef(formikRefBasicInformation.current);
  }, []);

  const handleSubmit = () => {
    if (activeFormikRef) {
      activeFormikRef.submitForm();
    }
  };

  const handleGetDataFromChildren = (data: {}) => {
    setDataFromChild({ ...dataFromChild, ...data });
  };

  const handleExportWord = () => {
    setIsDialogExportWord(true)
  }

  return (
    <>
      <div className="header-container color-primary">
        {!isView ? (
          <>
            {" "}
            <span className="fs-1 fw-bold">{lang(title)}</span>
            <div className="actions">
              {!hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW) && (
                <GroupButton type="btn-back" outline handleClose={handleCloseDialog} />
              )}
              {activeFormikRef !== null && 
                <GroupButton type="btn-save" className="spaces ml-6" handleSubmit={handleSubmit} />
              }
              {employeeProfiles?.id && 
                <GroupButton className="spaces ml-6" handleEvent={handleExportWord}>
                  {lang("GENERAL.EXPORT_WORD")}
                </GroupButton>
              }
            </div>
          </>
        ) : (
          <>
            {!hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW) && (
              <GroupButton
                className="text-uppercase"
                type="btn-back"
                handleClose={handleClose}
              ></GroupButton>
            )}
            {hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW) && <span className="fs-1 fw-bold">{lang(title)}</span>}
            <div className="flex">
              {employeeProfiles.trangThaiLaoDong?.code !== STATUS_NV.THOI_VIEC 
                && hasAuthority(PERMISSIONS.EMPLOYEE, PERMISSION_ABILITY.UPDATE) 
                && (
                <GroupButton
                  type="btn-edit"
                  handleSaveEdit={() => {
                    handleToggleIsView();
                    setTitle("PROFILE.EDIT");
                  }}
                  handleClose={handleClose}
                />
              )}
              <GroupButton className="spaces ml-6" handleEvent={handleExportWord}>{lang("GENERAL.EXPORT_WORD")}</GroupButton>
            </div>
          </>
        )}
      </div>
      <div className="layout-v2 flex align-items-start">
        <div className="general-user-info flex-column align-items-start align-self-stretch">
          <EmployeeInfo
            isView={isView}
            handleUploadAvatar={handleUploadAvatar}
            employeeProfiles={employeeProfiles}
          />
        </div>
        <div className="tabs-user-info flex-1">
          <div className="d-grid relative">
            <KTSVG
              path="/media/icons/arrow-left.svg"
              className="text-white arrow-left hidden"
              svgClassName={`position-absolute`}
            />
            <ul
              className="nav nav-tabs nav-line-tabs flex-nowrap text-nowrap overflow-hidden overflow-x-auto"
              id="profileTabsUl"
            >
              <li className="nav-item">
                <CustomTooltip
                  title={"Thông tin cơ bản"}
                  placement="bottom"
                >
                  <a
                    className="nav-link active"
                    data-bs-toggle="tab"
                    href={REF_TAB.TT_CO_BAN}
                    onClick={(e: any) => handleTabChange(REF_TAB.TT_CO_BAN)}
                  >
                    <KTSVG path="/media/icons/user.svg" />
                  </a>
                </CustomTooltip>
              </li>
              {profileTabs.map((tab, index) => (
                (!tab?.permission || tab.permission.some((permission) => hasAuthority(permission, PERMISSION_ABILITY.VIEW))) &&
                  <li
                    className="nav-item"
                    key={tab.id + index}
                  >
                    <CustomTooltip
                      title={tab.name}
                      placement="bottom"
                    >
                      <a
                        className={`nav-link`}
                        data-bs-toggle={identify ? "tab" : ""}
                        href={tab?.ref}
                        onClick={() => handleTabChange(tab.ref)}
                      >
                        <KTSVG path={tab.svgPath} />
                      </a>
                    </CustomTooltip>
                  </li>
              ))}
            </ul>
            <KTSVG
              path="/media/icons/arrow-left.svg"
              className="text-white arrow-right hidden"
              svgClassName={`position-absolute`}
            />
          </div>
          <div
            className="tab-content"
            id="myTabContent"
          >
            <div
              className="tab-pane fade show active"
              id="kt_tab_pane_1"
              role="tabpanel"
            >
              <BasicInformation
                identify={identify}
                activeTab={activeTab}
                formikRef={formikRefBasicInformation}
                employeeProfiles={employeeProfiles}
                isView={isView}
                handleOpenField={handleOpenField}
                handleToggleIsView={handleToggleIsView}
                handleGetEmployee={handleGetEmployee}
                handleChangeSelect={handleChangeSelect}
                nationality={nationality}
                nationalityDefault={nationalityDefault}
              />
            </div>
            <div
              className="tab-pane fade"
              id="kt_tab_pane_2"
              role="tabpanel"
            >
              <ContactInformation
                identify={identify}
                activeTab={activeTab}
                formikRef={formikRefContactInformation}
                employeeProfiles={employeeProfiles}
                isView={isView}
                handleOpenField={handleOpenField}
                handleToggleIsView={handleToggleIsView}
                handleGetEmployee={handleGetEmployee}
                handleChangeSelect={handleChangeSelect}
                nationality={nationality}
                nationalityDefault={nationalityDefault}
              />
            </div>
            <div
              className="tab-pane fade"
              id="kt_tab_pane_3"
              role="tabpanel"
            >
              <WorkInformation
                activeTab={activeTab}
                handleGetDataFromChildren={handleGetDataFromChildren}
                identify={identify}
                formikRef={formikRefWorkInformation}
                employeeProfiles={employeeProfiles}
                isView={isView}
                handleGetEmployee={handleGetEmployee}
                handleToggleIsView={handleToggleIsView}
                handleChangeSelect={handleChangeSelect}
              />
            </div>
            <div
              className="tab-pane fade"
              id="kt_tab_pane_4"
              role="tabpanel"
            >
              <TrainingInformation
                isView={isView}
                activeTab={activeTab}
                identify={identify}
                employeeProfiles={employeeProfiles}
                formikRef={formikRefTrainingInformation}
              />
            </div>
            <div
              className="tab-pane fade"
              id="kt_tab_pane_5"
              role="tabpanel"
            >
              <SalaryInformation
                activeTab={activeTab}
                // isView={blockEditSalary ? blockEditSalary : isView}
                isView={isView}
                identify={identify}
                employeeProfiles={employeeProfiles}
                handleGetEmployee={handleGetEmployee}
                formikRef={formikRefSalaryInformation}
              />
            </div>
            <div
              className="tab-pane fade"
              id="kt_tab_pane_6"
              role="tabpanel"
            >
              <ContractInformation
                // isView={blockEditContract ? blockEditContract : isView}
                isView={isView}
                formikRef={formikRefLaborContract}
                activeTab={activeTab}
                employeeProfiles={employeeProfiles}
                handleToggleIsView={handleToggleIsView}
              />
            </div>
            <div
              className="tab-pane fade"
              id="kt_tab_pane_7"
              role="tabpanel"
            >
              <WorkingProcessInformation
                handleGetDataFromChildren={handleGetDataFromChildren}
                identify={identify}
                isView={isView}
                employeeProfiles={employeeProfiles}
                activeTab={activeTab}
                handleGetEmployee={handleGetEmployee}
              />
            </div>
            <div
              className="tab-pane fade"
              id="kt_tab_pane_8"
              role="tabpanel"
            >
              <PoliticsInformation
                activeTab={activeTab}
                identify={identify}
                employeeProfiles={employeeProfiles}
                formikRef={formikRefPoliticsInformation}
                isView={isView}
                handleToggleIsView={handleToggleIsView}
              />
            </div>
            <div
              className="tab-pane fade"
              id="kt_tab_pane_9"
              role="tabpanel"
            >
              <FamilyInformation
                handleGetDataFromChildren={handleGetDataFromChildren}
                employeeProfiles={employeeProfiles}
                identify={identify}
                isView={isView}
                activeTab={activeTab}
                handleGetEmployee={handleGetEmployee}
              />
            </div>
            <div
              className="tab-pane fade"
              id="kt_tab_pane_10"
              role="tabpanel"
            >
              <InsuranceInformation
                activeTab={activeTab}
                isView={isView}
                formikRef={formikRefInsuranceInformation}
                identify={identify}
                employeeProfiles={employeeProfiles}
                handleGetEmployee={handleGetEmployee}
              />
            </div>
            <div
              className="tab-pane fade"
              id="kt_tab_pane_11"
              role="tabpanel"
            >
              <WorkExperienceInformation
                isView={isView}
                handleGetDataFromChildren={handleGetDataFromChildren}
                identify={identify}
                employeeProfiles={employeeProfiles}
                handleGetEmployee={handleGetEmployee}
                activeTab={activeTab}
              />
            </div>
            {hasAuthority(PERMISSIONS.PHUC_LOI, PERMISSION_ABILITY.VIEW) &&
              <div
                className="tab-pane fade"
                id="kt_tab_pane_14"
                role="tabpanel"
              >
                <WelfareInformation
                  isView={isView}
                  activeTab={activeTab}
                  employeeProfiles={employeeProfiles}
                />
              </div>
            }
            {(hasAuthority(PERMISSIONS.KHEN_THUONG, PERMISSION_ABILITY.VIEW) ||
              hasAuthority(PERMISSIONS.KY_LUAT, PERMISSION_ABILITY.VIEW)) &&
              <div
                className="tab-pane fade"
                id="kt_tab_pane_15"
                role="tabpanel"
              >
                <BonusAndDisciplineInformation
                  activeTab={activeTab}
                  isView={isView}
                  employeeProfiles={employeeProfiles}
                />
              </div>
            }
            <div
              className="tab-pane fade"
              id="kt_tab_pane_16"
              role="tabpanel"
            >
              <VaccinationInformation
                isView={isView}
                activeTab={activeTab}
                identify={identify}
                employeeProfiles={employeeProfiles}
              />
            </div>
            {hasAuthority(PERMISSIONS.KE_KHAI_TAI_SAN, PERMISSION_ABILITY.VIEW) &&
              <div
                className="tab-pane fade"
                id="kt_tab_pane_19"
                role="tabpanel"
              >
                <DeclareAssetsInformation
                  employeeProfiles={employeeProfiles}
                  activeTab={activeTab}
                />
              </div>
            }
            <div
              className="tab-pane fade"
              id="kt_tab_pane_17"
              role="tabpanel"
            >
              {/* Y tế */}
              <MedicalInformation
                activeTab={activeTab}
                identify={identify}
                employeeProfiles={employeeProfiles}
                isView={isView}
                handleGetEmployee={handleGetEmployee}
                handleChangeSelect={handleChangeSelect}
              />
              {/* Quân sự */}
              <MilitaryInformation
                activeTab={activeTab}
                identify={identify}
                employeeProfiles={employeeProfiles}
                isView={isView}
                handleChangeSelect={handleChangeSelect}
              />
              {/* Danh Hiệu phong tặng */}
              <TitleConferredInformation
                activeTab={activeTab}
                identify={identify}
                employeeProfiles={employeeProfiles}
                isView={isView}
                handleGetEmployee={handleGetEmployee}
                />
              {/* Thông tin gia đình chính sách */}
              <FamilyPolicyInformation
                activeTab={activeTab}
                identify={identify}
                employeeProfiles={employeeProfiles}
                isView={isView}
                handleGetEmployee={handleGetEmployee}
                />
              {/* Đặc điểm lịch sử phát triển bản thân */}
              <PersonalHistoricalInformation
                identify={identify}
                isView={isView}
                employeeProfiles={employeeProfiles}
                activeTab={activeTab}
                handleGetEmployee={handleGetEmployee}
              />
              {/* Giấy tờ */}
              <TempPapersInformation
                handleGetDataFromChildren={handleGetDataFromChildren}
                identify={identify}
                isView={isView}
                employeeProfiles={employeeProfiles}
                activeTab={activeTab}
                handleGetEmployee={handleGetEmployee}
              />
              {/* Tài liệu đính kèm */}
              <AttachmentsInformation
                handleGetDataFromChildren={handleGetDataFromChildren}
                identify={identify}
                isView={isView}
                employeeProfiles={employeeProfiles}
                activeTab={activeTab}
                handleGetEmployee={handleGetEmployee}
              />
            </div>
            {/* <div
              className="tab-pane fade"
              id="kt_tab_pane_18"
              role="tabpanel"
            >
              <ConcurrentlyInformation
                handleGetDataFromChildren={handleGetDataFromChildren}
                identify={identify}
                isView={isView}
                employeeProfiles={employeeProfiles}
                handleGetEmployee={handleGetEmployee}
                activeTab={activeTab}
                handleToggleIsView={handleToggleIsView}
              />
            </div> */}
          </div>
        </div>
      </div>
      {isDialogExportWord &&
        <ExportWord
          handleClose={() => setIsDialogExportWord(false)}
          idEmployee={employeeProfiles.id}
        />
      }
    </>
  );
};

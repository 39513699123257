import { TYPE_CATEGORY } from '../../constants/moduleConsts';
import { ILang } from "../../../hook/useMultiLanguage";
import { OptionReactSelect } from "../../models/models";
import { TYPE } from "../../constants/moduleConsts";
import { TransitionInFo } from "../models/transitionModels";
import { searchAllSimpleValue, searchListWorkUnit, searchPhongBan } from '../../services/services';

export const templateSelect = [
  { code: "1", name: "Xuất sắc" },
  { code: "2", name: "Giỏi" },
  { code: "3", name: "Khá" },
  { code: "4", name: "Trung bình khá" },
  { code: "5", name: "Trung bình" },
]

export const TRANSITION_STATUS_CODE = {
  UNFULFILLED: 1,
  PROCESSING: 2,
  COMPLETED: 3
};

export const INIT_TRANSITION: TransitionInFo = {
  doiTuong: null,
  id: "",
  fullName: "",
  code: "",
  employeeId: "",
  employee: null,
  donViCongTacId: "",
  donViCongTacText: "",
  chucVuCuId: "",
  chucVuCuText: "",
  khoaPhongId: "",
  khoaPhongText: "",
  viTriCongViecId: "",
  viTriCongViecText: "",
  loaiDieuDong: null,
  loaiDieuDongId: "",
  loaiDieuDongText: "",
  coHieuLucTuNgay: "",
  coHieuLucDenNgay: "",
  fileId: "",
  fileName: "",
  ghiChu: "",
  donViCongTacMoiId: "",
  donViCongTacMoiText: "",
  donViCongTacMoi: null,
  khoaPhongMoiId: "",
  khoaPhongMoiText: "",
  khoaPhongMoi: null,
  viTriCongTacMoiId: "",
  viTriCongTacMoiText: "",
  viTriCongTacMoi: null,
  chucVuMoiId: "",
  chucVuMoiText: "",
  chucVuMoi: null,
  donViQuyetDinhId: "",
  donViQuyetDinhText: "",
  donViQuyetDinh: null,
  nguoiKyId: "",
  nguoiKyText: "",
  nguoiKy: null,
  ngayQuyetDinh: "",
  soQuyetDinh: "",
  trangThai: null,
  uyQuyen: false,
  khoaPhongNhanQuyetDinh: ""
}

export enum TRANSFER_PEOPLE {
  CA_NHAN = 1,
  TAP_THE = 2,
}

export const LIST_TRANSFER_OBJECT: OptionReactSelect[] = [
  {
    name: "Cá nhân",
    code: TRANSFER_PEOPLE.CA_NHAN,
  },
  // Tập thể làm sau
  // {
  //   name: "Tập thể",
  //   code: TRANSFER_PEOPLE.TAP_THE,
  // },
];

export enum LIST_TRANSFER_STATUS_CODE {
  UNFULFILLED = 3,
  PROCESSING = 2,
  COMPLETED = 1,
}

export const LIST_TRANSFER_STATUS = [
  {
    name: "Chưa thực hiện",
    code: LIST_TRANSFER_STATUS_CODE.UNFULFILLED,
    styleClass: "bg-gray-700"
  },
  {
    name: "Đang thực hiện",
    code: LIST_TRANSFER_STATUS_CODE.COMPLETED,
    styleClass: "bg-orange-dark"
  },
  {
    name: "Đã hoàn thành",
    code: LIST_TRANSFER_STATUS_CODE.PROCESSING,
    styleClass: "bg-green"
  },
];


export enum TRANSFER_TYPE {
  DIEU_DONG = 1,
  LUAN_CHUYEN = 2,
  TIEP_NHAN = 3,
}

export const LIST_TRANSFER_TYPE: OptionReactSelect[] = [
  {
    name: "Điều động",
    code: TRANSFER_TYPE.DIEU_DONG,
  },
  {
    name: "Luân chuyển",
    code: TRANSFER_TYPE.LUAN_CHUYEN,
  },
  {
    name: "Tiếp nhận",
    code: TRANSFER_TYPE.TIEP_NHAN,
  },
];

export const ListSearch = (lang: ILang) => {
  return [
    {
      name: lang("PROFILE.CODE"),
      field: "code",
      type: TYPE.TEXT,
    },
    {
      name: lang("PROFILE.NAME"),
      field: "fullName",
      type: TYPE.TEXT,
    },
    {
      name: lang("INPUT.WORKPROCESS.UNIT.OLD"),
      field: "donViCongTacId",
      searchFunction: searchListWorkUnit,
      type: TYPE.SELECT,
    },
    {
      name: lang("INPUT.WORKPROCESS.DEPARTMENT.OLD"),
      field: "khoaPhongIds",
      searchFunction: searchPhongBan,
      type: TYPE.SELECT,
      isMulti: true,
    },
    {
      name: lang("RESIGN.OLDPOSITION"),
      field: "chucVuCuId",
      searchFunction: searchAllSimpleValue,
      searchObject: { type: TYPE_CATEGORY.chucDanh },
      type: TYPE.SELECT,
      optionLabel: "value"
    },
    {
      name: lang("INPUT.WORKPROCESS.UNIT.NEW"),
      field: "donViCongTacMoiId",
      searchFunction: searchListWorkUnit,
      type: TYPE.SELECT,
    },
    {
      name: lang("INPUT.WORKPROCESS.DEPARTMENT.NEW"),
      field: "khoaPhongMoiIds",
      searchFunction: searchPhongBan,
      type: TYPE.SELECT,
      isMulti: true,
    },
    {
      name: lang("RESIGN.NEWPOSITION"),
      field: "chucVuMoiId",
      searchFunction: searchAllSimpleValue,
      searchObject: { type: TYPE_CATEGORY.chucDanh },
      type: TYPE.SELECT,
      optionLabel: "value"
    },
    {
      name: lang("INPUT.WORKPROCESS.DECISION.NUMBER"),
      field: "soQuyetDinh",
      type: TYPE.TEXT,
    },
    {
      name: lang("GENERAL.STATUS"),
      field: "trangThai",
      type: TYPE.SELECT,
      listOption: LIST_TRANSFER_STATUS,
      isMulti: true,
    },
  ]
} 
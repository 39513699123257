import { useContext, useEffect, useMemo, useState } from "react";
import "../../remind.scss";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import { IRemindAmount, IRemindHeader } from "../../models/remindModels";
import RemindViewDialog from "../dialog/RemindViewDialog";
import { REMIND_HEDER_LIST } from "../../constants/remindMenuConsts";
import { KTSVG } from "../../../../../_metronic/helpers";
import { toast } from "react-toastify";
import { NAME_EVENT_LISTENER } from "../../../constants/moduleConsts";
import { localStorageItem } from "../../../utils/localstorage";
import AppContext from "../../../../AppContext";
import { KEY_LOCALSTORAGE } from "../../../auth/core/_consts";

function HeaderRemindMenu() {
  const { lang } = useMultiLanguage();
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [isShowDialogDetail, setIsShowDialogDetail] = useState<boolean>(false);
  const [remindList, setRemindList] = useState<IRemindHeader[]>(REMIND_HEDER_LIST);
  const [remindInfo, setRemindInfo] = useState<IRemindHeader>(remindList[0]);
  const { setEventSource, eventSource } = useContext(AppContext);

  useEffect(() => {
    getRemindAmount();

    return () => {
      eventSource?.eventSourceRemind?.close();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRemindAmount = async () => {
    let current = localStorageItem.get(KEY_LOCALSTORAGE.CURRENT) || {}
    try {
      let newEventSource = new EventSource(`${process.env.REACT_APP_API_URL}/public/sse/nhac-nho?userId=${Number(current?.id)}`)

      newEventSource.addEventListener(NAME_EVENT_LISTENER.REMINDER_NOTIFY, (message) => {
        let data = JSON.parse(message.data) || [];
        let dataConverted: IRemindHeader[] = [...remindList];

        data.forEach((item: IRemindAmount) => {
          let remindItemMatchType = dataConverted.find((itemConst) => item.type?.code === itemConst.codeType);
          if (remindItemMatchType) {
            remindItemMatchType.amount = item.soLuong;
          }
        });

        setRemindList(dataConverted);
      })

      setEventSource({
        ...eventSource,
        eventSourceRemind: newEventSource
      })

    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const handleSwitchShowModal = () => {
    setIsShowModal(!isShowModal);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const remindMenu = document.querySelector("#remind-menu");
      if (!remindMenu?.contains(event.target as Node)) {
        setIsShowModal(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => document.removeEventListener("click", handleClickOutside);
  });

  const handleRemindItemClick = (remindItem: IRemindHeader) => {
    setRemindInfo(remindItem);
    setIsShowModal(false);
    setIsShowDialogDetail(true);
  };

  const totalRemind = useMemo(() => {
    return remindList.reduce((acc, item) => {
      return acc + item.amount;
    }, 0);
  }, [remindList]);

  return (
    <div id="remind-menu">
      <div className="cursor-pointer p-2" onClick={handleSwitchShowModal}>
        <button type="button" className="btn p-0 position-relative">
          <KTSVG path="/media/icons/remind-nav.svg" className="flex mx-0 p-1 color-white" />
          <span className="position-absolute translate-middle badge rounded-pill bg-danger ">
            {totalRemind >= 100 ? "99+" : totalRemind}
          </span>
        </button>
      </div>

      <div className={`remind-menu__modal ${!isShowModal && "hidden"}`}>
        <h3 className="remind-menu__modal__titlte">{lang("REMIND")}</h3>
        <div className="remind-menu__modal__content">
          {remindList.map((item, index) => {
            return (
              <div className="remind-menu__modal__item" key={index} onClick={() => handleRemindItemClick(item)}>
                <div className="modal__item__icon">
                  <KTSVG path={item.pathIcon} />
                  <p>{item.tilte}</p>
                </div>
                <div className="modal__item__amount">
                  <span className="modal__item__amount--sl">{item.amount >= 100 ? "99+" : item.amount}</span>
                  <KTSVG path="/media/icons/arrow-right.svg" />
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {isShowDialogDetail && (
        <RemindViewDialog
          open={isShowDialogDetail}
          handleClose={() => setIsShowDialogDetail(false)}
          remindInfo={remindInfo}
        />
      )}
    </div>
  );
}

export { HeaderRemindMenu };

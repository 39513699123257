import { EmployeeProfile } from "../../profile/models/profileModels";
import { IRetireInfo } from "../models/warningModels";

export const INIT_RETIRE: IRetireInfo = {
  ...{} as EmployeeProfile,
  loaiThoiViec: null,
  troCapThoiViec: "",
  troCapMatViec: "",
  cacKhoanTraKhac: "",
  cacKhoanThuKhac: "",
  thangKhauTru: null,
  thangKhauTruOption: null,
  namKhauTru: null,
  namKhauTruOption: null,
  ngayQuyetDinh: "",
  ngayHieuLuc: "",
  ngayThongBao: "",
  soQuyetDinh: "",
  nguoiKy: null,
  daBoSungHoSo: false,
  daHoanTatThuTuc: false,
  lyDoThoiViec: "",
  trangThaiBaoHiem: null,
  thoiGianDungDongBH: "",
  hinhThucDieuChinh: null,
  thoiGianThucHien: "",
  chucDanh: null,
  ngayGiaHanNghiHuu: "",
  listKhoanThu: [],
  raQuyetDinh: false
}
import { KTSVG } from "../../../../../_metronic/helpers";
import { PERMISSION_ABILITY, PERMISSIONS } from "../../../../constants";
import { ILang } from "../../../../hook/useMultiLanguage";
import CustomTooltip from "../../../components/custom-tooltip";
import { columnNamesType } from "../../../components/table-custom/TableCustom";
import { checkStatus, formatDateTable, hasAuthority } from "../../../utils/functionUtils";
import { CODE_STATUS, LIST_STATUS } from "../../constants/managerRequestConsts";
import { IChangeCompensated } from "../../models/changeCompensatedModes";

type IProps = {
	handleOpenChangeCompensatedForm: (data: IChangeCompensated) => void,
	handleApproveOrReject: (data: IChangeCompensated) => void,
	lang: ILang
}

export const columns = (props: IProps): columnNamesType[] => {
	let {
		handleOpenChangeCompensatedForm,
		handleApproveOrReject,
		lang
	} = props;

	return [
		{
			name: "STT",
			field: "",
			render: (row: any, index: number, stt: number) => <span>{stt}</span>
		},
		{
			name: lang("GENERAL.ACTION"),
			field: "thaoTac",
			headerStyle: {
				minWidth: "100px"
			},
			render: (row: any) => {
				return (
					<div className="flex flex-center column-gap-4">
						{
							row?.trangThai?.code === CODE_STATUS.TAO_MOI &&
							<CustomTooltip title={lang("GENERAL.EDIT")} placement="top">
								<div className='color-primary' onClick={() => handleOpenChangeCompensatedForm(row)}>
									<KTSVG path='/media/icons/pencil-fill.svg' />
								</div>
							</CustomTooltip>
						}
						{
							row?.trangThai?.code === CODE_STATUS.CHO_DUYET &&
							hasAuthority(PERMISSIONS.DON_XIN_NGHI, PERMISSION_ABILITY.APPROVE) &&
							<CustomTooltip title={lang('GENERAL.APPROVE_OR_REJECT')} placement="top">
								<div className='color-green' onClick={() => handleApproveOrReject(row)}>
									<KTSVG path={'/media/icons/circle-check.svg'} />
								</div>
							</CustomTooltip>
						}
					</div>
				)
			}
		},
		{
			name: lang("STATUS"),
			field: "trangThai",
			headerStyle: {
				minWidth: "100px"
			},
			render: (row: any) => (
				<span className={`${checkStatus(LIST_STATUS, Number(row?.trangThai?.code))} status`} >
					{row?.trangThai?.name}
				</span>
			)
		},
		{
			name: lang("MANAGENT_REQUEST.CHANGE_COMPENSATED.TYPE"),
			field: "loaiDoi",
			headerStyle: {
				minWidth: "100px"
			},
			render: (row: any) => <span>{row?.loaiDoi?.name}</span>
		},
		{
			name: lang("MANAGENT_REQUEST.ATTENDANCE_WATCH.PERSON_APPLY_LEAVE"),
			field: "employeeText",
			headerStyle: {
				minWidth: "200px"
			},
			cellStyle: {
				textAlign: "left"
			},
		},
		{
			name: lang("GENERAL.EMPLOYEE.TITLE"),
			field: "chucDanhText",
			headerStyle: {
				minWidth: "200px"
			},
			cellStyle: {
				textAlign: "left"
			},
		},
		// Tạm ẩn thông tin ca do BE chưa trả ra được
		// {
		//   name: lang("MANAGENT_REQUEST.CHANGE_COMPENSATED.DATE_CURRENT"),
		//   field: "ngayHienTai",
		//   headerStyle: {
		//     minWidth: "120px"
		//   },
		//   render: row => <span>{formatDateTable(row?.ngayHienTai)}</span>
		// },
		// {
		//   name: lang("MANAGENT_REQUEST.CHANGE_COMPENSATED.SHIFT_CURRENT"),
		//   field: "caHienTaiText",
		//   headerStyle: {
		//     minWidth: "120px"
		//   }
		// },
		// {
		//   name: lang("MANAGENT_REQUEST.CHANGE_COMPENSATED.DATE_REGISTRY"),
		//   field: "ngayDangKy",
		//   headerStyle: {
		//     minWidth: "120px"
		//   },
		//   render: row => <span>{formatDateTable(row?.ngayDangKy)}</span>
		// },
		// {
		//   name: lang("MANAGENT_REQUEST.CHANGE_COMPENSATED.SHIFT_REGISTRY"),
		//   field: "caDangKyText",
		//   headerStyle: {
		//     minWidth: "120px"
		//   }
		// },
		{
			name: lang("MANAGENT_REQUEST.ATTENDANCE_WATCH.DATE_APPLICATION"),
			field: "ngayNop",
			headerStyle: {
				minWidth: "120px"
			},
			render: row => <span>{formatDateTable(row?.ngayNop)}</span>
		},
		{
			name: lang("MANAGENT_REQUEST.ATTENDANCE_WATCH.DATE_APPROVE"),
			field: "ngayDuyet",
			headerStyle: {
				minWidth: "120px"
			},
			render: row => <span>{formatDateTable(row?.ngayDuyet)}</span>
		}
	];
}
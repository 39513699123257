/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Autocomplete from "../../components/input/autocomplete";
import TextValidator from "../../components/input/text-validator";
import { REGEX, RESPONSE_STATUS_CODE, VARIABLE_STRING, DATA_WORD_TYPES, TYPE_CATEGORY } from "../../constants/moduleConsts";
import { convertNumberPrice, convertTextPrice, handleBlurDate } from "../../utils/functionUtils";
import { checkInvalidDate } from "../../utils/validationSchema";
import { CODE_HOP_DONG, INIT_CONTRACT_ANNEX_INFO, contractTypes, signinStatus } from "../constants/contractConsts";
import { addNewAnnex, updateAnnex } from "../services/annexServices";
import { IContractAnnexInfo, IContractInfo } from "../models/contractModels";
import { convertDataContactDto, filterObject } from "../utils/contractUtils";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import AppContext from "../../../AppContext";
import UploadFile from "../../components/file-upload/UploadFile";
import { IFile } from "../../profile/models/dialogModels";
import { getBacLuongByChucDanh } from "../../profile/services/dialogServices";
import { TYPE_POSITION } from "../../profile/constants/profileConsts";
import { ExportWord } from "../../components/exportWord";
import { ContractAnnexWord } from "./word-templates";
import { GroupButton } from "../../components/GroupButton";
import { searchAllSimpleValue, searchListWorkUnit, searchNguoiDaiDienKy, searchPhongBanByDonVi } from "../../services/services";
import { AddNewRepresentative } from "../../components/dialogs";
import { IPeopleDecision } from "../../models/models";

interface IProps {
  handleCloseAddContractAnnex: () => void;
  getListAnnex: () => Promise<void>;
  contractInfo: IContractInfo;
  annexInfo: IContractAnnexInfo;
  isView?: boolean;
  isCollaborators?: boolean;
}
const AddNewContractAnnex: FC<IProps> = (props) => {
  const { handleCloseAddContractAnnex, getListAnnex, contractInfo, annexInfo, isView = false, isCollaborators = false } = props;
  const { lang, intl } = useMultiLanguage();
  const [isEdit, setIsEdit] = useState<boolean>(!isView);
  const [isLongTermContract, setIsLongTermContract] = useState<boolean>(false);
  const [isShowDialogWord, setIsShowDialogWord] = useState<boolean>(false);
  const { setPageLoading } = useContext(AppContext);
  const [isShowDialogAddRepresentative, setIsShowDialogAddRepresentative] = useState<boolean>(false);
  const [peopleDecisions, setPeopleDecisions] = useState<IPeopleDecision[]>([]);

  const validationSchema = Yup.object().shape({
    soPhuLuc: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable().max(255, lang("VALIDATION.255MAX")),
    tenPhuLuc: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable().max(255, lang("VALIDATION.255MAX")),
    ngayCoHieuLuc: Yup.date().concat(checkInvalidDate(intl)).nullable(),
    ngayHetHan: Yup.date().concat(checkInvalidDate(intl)).nullable(),
    ngayKy: Yup.date()
      .concat(checkInvalidDate(intl))
      .required(lang("VALIDATION.REQUIRE"))
      .min(Yup.ref('ngayCoHieuLuc'), lang("VALIDATE.SIGNING_DATE_AFTER_EFFECTIVE_DATE"))
      .when('ngayHetHan', {
        is: (ngayHetHan: Date | null) => ngayHetHan,
        then: Yup.date()
          .max(Yup.ref('ngayHetHan'), lang('VALIDATE.SIGNING_DATE_AFTER_EXPIRATION_DATE')),
      })
      .nullable(),
    ngayCoHieuLucPhuLuc: Yup.date().required(lang("VALIDATION.REQUIRE"))
      .concat(checkInvalidDate(intl))  
      .when("ngayHetHan", {
        is: (ngayHetHan: Date | null) => ngayHetHan,
        then: Yup.date()
          .max(Yup.ref('ngayHetHan'), lang("VALIDATE.EFFECTIVE_DATE_BEFORE_EXPIRATION_DATE")).nullable(),
      })
      .min(Yup.ref('ngayCoHieuLuc'), lang("VALIDATE.EFFECTIVE_ADDENDUM_DATE_AFTER_EFFECTIVE_DATE"))
      .nullable(),
    donViKyHopDong: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    phongBan: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    viTriCongViec: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    chucDanh: isCollaborators ? Yup.object().nullable() : Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    luongCoBan: !isCollaborators ? Yup.string().nullable() : Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    loaiHopDong: Yup.object()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    tyLeHuongLuong: Yup.string().nullable().matches(REGEX.PERCENTAGE, lang("VALIDATE.SALARY.RATE.INVALID")),
    bacLuongOption: isCollaborators ? Yup.object().nullable() : Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    heSoLuong: isCollaborators ? Yup.string().nullable() : Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    nguoiDaiDienCtyKy: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable()
  });

  useEffect(() => {
    const checkHopDongDaiHan = contractInfo?.loaiHopDong?.code === CODE_HOP_DONG.ONE_YEAR || contractInfo?.loaiHopDong?.code === CODE_HOP_DONG.HOP_DONG_DAI_HAN || contractInfo?.loaiHopDong?.code === CODE_HOP_DONG.VIEN_CHUC_DAI_HAN
    setIsLongTermContract(checkHopDongDaiHan);
    if (contractInfo?.id) {
      formik.setValues({
        ...formik.values,
        loaiHopDong: contractInfo?.loaiHopDong,
        hopDongGoc: contractInfo?.soHopDong,
        donViKyHopDong: contractInfo?.donViKyHopDong,
        ngayCoHieuLuc: contractInfo?.ngayCoHieuLuc,
        ngayHetHan: contractInfo?.ngayHetHan
      });
    }
  }, [contractInfo])

  useEffect(() => {
    if(!annexInfo.viTriCongViecId && isCollaborators) {
      const getPosition = async () => {
        try {
          const { data } = await searchAllSimpleValue({ type: TYPE_CATEGORY.chucDanh });
          if(data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
            const positionCollaborator = data.data?.content?.find((position: any) => position?.mappingName === TYPE_POSITION.CONG_TAC_VIEN);
            formik.setFieldValue(VARIABLE_STRING.VI_TRI_CONG_VIEC, positionCollaborator);
          } else {
            toast.warning(data?.message);
          }
        } catch (error) {
          toast.error(lang("GENERAL.ERROR"));
        }
      }
      getPosition();
    }
  }, [isCollaborators]);

  const convertData = (data: IContractAnnexInfo) => {
    return {
      ...data,
      bacLuongOption: data?.bacLuong ? {
        bacLuong: data.bacLuong,
        heSoLuong: data?.heSoLuong
      } : null,
      donViKyHopDong: data?.donViKyHopDongId ? {
        id: data?.donViKyHopDongId,
        name: data?.donViKyHopDongText
      } : null,
      nguoiDaiDienCtyKy: data?.nguoiDaiDienCtyKyId ? {
        id: data?.nguoiDaiDienCtyKyId,
        name: data?.nguoiDaiDienCtyKyText
      } : null,
      nguoiDaiDienCtyChucDanh: data?.nguoiDaiDienCtyChucDanhId ? {
        id: data?.nguoiDaiDienCtyChucDanhId,
        value: data?.nguoiDaiDienCtyChucDanhText
      } : null,
      phongBan: data?.phongBanId ? {
        id: data?.phongBanId,
        name: data?.phongBanText
      } : null,
      viTriCongViec: data?.viTriCongViecId ? {
        id: data?.viTriCongViecId,
        value: data?.viTriCongViecText
      } : null,
      chucDanh: data?.chucDanhId ? {
        id: data?.chucDanhId,
        value: data?.chucDanhText
      } : null
    }
  };

  const handleSubmit = async (values: IContractAnnexInfo) => {
    try {
      setPageLoading(true);
      values.employeeId = contractInfo.employeeId || "";
      values.hopDongLaoDongId = contractInfo.id;
      const dataAnnex = filterObject(convertDataContactDto(values));
      const res = annexInfo.id ? await updateAnnex(annexInfo.id, dataAnnex) : await addNewAnnex(dataAnnex);
      if (res?.data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(annexInfo.id ? lang("TOAST.EDIT.SUCCESS") : lang("TOAST.CREATE.SUCCESS"));
        await getListAnnex();
        handleCloseAddContractAnnex();
      } else {
        toast.warning(`${res?.data?.message}`);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false)
    }
  };

  const formik = useFormik({
    initialValues: annexInfo?.id ? convertData(annexInfo) : INIT_CONTRACT_ANNEX_INFO,
    validationSchema,
    onSubmit: handleSubmit
  });

  const handleChangeSelect = (name: string, value: any) => {
    formik.setFieldValue(name, value);
    switch (name) {
      case VARIABLE_STRING.BAC_LUONG_OPTION:
        formik.setFieldValue(name, value);
        formik.setFieldTouched(name, false, false);
        formik.setFieldValue(VARIABLE_STRING.HE_SO_LUONG, value?.heSoLuong || "");
        break;
      case VARIABLE_STRING.NGUOI_DAI_DIEN_KY:
        formik.setValues({
          ...formik.values,
          nguoiDaiDienCtyChucDanhId: value?.chucVuId,
          nguoiDaiDienCtyChucDanhText: value?.chucVuText,
          nguoiDaiDienCtyKyId: value?.id,
          nguoiDaiDienCtyKyText: value?.name
        });
        formik.setFieldValue(name, value);
        break;
      default:
        formik.setFieldValue(name, value);
        break;
    }
  };

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    formik.setFieldValue(name, checked);
  };

  const handleFile = (data: IFile) => {
    formik.setFieldValue("fileId", data.id);
    formik.setFieldValue("fileName", data.name);
  }

  const getPeopleDecision = async () => {
    try {
      const { data } = await searchNguoiDaiDienKy();
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setPeopleDecisions(data?.data?.content || [])
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }

  useEffect(() => {
    getPeopleDecision();
  }, []);

  useEffect(() => {
    if(!annexInfo?.id && peopleDecisions.length > 0) {
      const representativeDefault = peopleDecisions.find(people => people.isDefault) || null;
      formik.setValues({
        ...formik.values,
        nguoiDaiDienCtyKy: representativeDefault,
        nguoiDaiDienCtyKyId: representativeDefault?.id || "",
        nguoiDaiDienCtyKyText: representativeDefault?.name || "",
        nguoiDaiDienCtyChucDanhId: representativeDefault?.chucVuId || "",
        nguoiDaiDienCtyChucDanhText: representativeDefault?.chucVuText || ""
      });
    }
  }, [peopleDecisions]);

  const handleCloseAndSearchRepresentative = () => {
    getPeopleDecision();
    setIsShowDialogAddRepresentative(false);
  }

  const handleChangeMoney = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, convertTextPrice(value));
  };

  return (
    <Modal
      show={true}
      size="xl"
      onHide={handleCloseAddContractAnnex}
      backdrop="static"
      className="custom-modal"
      centered
    >
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title className="heading-5">{lang("CONTRACT.ANNEX")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="annex-dialog contract-dialog-content">
          <Row>
            <Row>
              <Col
                xs={12}
                className="mx-4"
              >
                <span className="fs-3 fw-bold">{lang("GENERAL.INFO")}</span>
              </Col>
              <Col xs={12}>
                <Row className="ms-8">
                  <Col xs={4} className="mt-3">
                    <TextValidator
                      lable={lang("ANNEX.NUMBER")}
                      name="soPhuLuc"
                      value={formik.values?.soPhuLuc || ""}
                      type="text"
                      isRequired
                      readOnly={!isEdit}
                      onChange={formik.handleChange}
                      errors={formik.errors?.soPhuLuc}
                      touched={formik.touched?.soPhuLuc}
                    />
                  </Col>
                  <Col xs={4} className="mt-3">
                    <TextValidator
                      lable={lang("ANNEX.NAME")}
                      name="tenPhuLuc"
                      value={formik.values?.tenPhuLuc || ""}
                      type="text"
                      isRequired
                      readOnly={!isEdit}
                      onChange={formik.handleChange}
                      errors={formik.errors?.tenPhuLuc}
                      touched={formik.touched?.tenPhuLuc}
                    />
                  </Col>
                  <Col xs={4} className="mt-3">
                    <TextValidator
                      lable={lang("ANNEX.SIGNINGDATE")}
                      isRequired
                      name="ngayKy"
                      value={formik.values?.ngayKy || ""}
                      type="date"
                      readOnly={!isEdit}
                      onChange={formik.handleChange}
                      onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.ngayKy, "ngayKy")}
                      errors={formik.errors?.ngayKy}
                      touched={formik.touched?.ngayKy}
                    />
                  </Col>
                  <Col xs={4} className="mt-3">
                    <TextValidator
                      lable={lang("ANNEX.EFFECTIVEDATE")}
                      name="ngayCoHieuLucPhuLuc"
                      value={formik.values?.ngayCoHieuLucPhuLuc || ""}
                      type="date"
                      isRequired
                      readOnly={!isEdit}
                      onChange={formik.handleChange}
                      onBlur={() =>
                        handleBlurDate(formik.setFieldValue, formik.values?.ngayCoHieuLucPhuLuc, "ngayCoHieuLucPhuLuc")
                      }
                      errors={formik.errors?.ngayCoHieuLucPhuLuc}
                      touched={formik.touched?.ngayCoHieuLucPhuLuc}
                    />
                  </Col>
                  <Col xs={4} className="mt-3">
                    <TextValidator
                      lable={lang("CONTRACT.ORIGINAL")}
                      name="hopDongGoc"
                      value={formik.values?.hopDongGoc || ""}
                      type="text"
                      readOnly={!isEdit}
                      onChange={formik.handleChange}
                    />
                  </Col>
                  <Col xs={4} className="mt-3">
                    <Autocomplete
                      lable={lang("CONTRACT.SIGNINGSTATUS")}
                      options={signinStatus}
                      value={formik.values?.trangThaiKy || null}
                      name="trangThaiKy"
                      isReadOnly={!isEdit}
                      onChange={(selectedOption) => handleChangeSelect("trangThaiKy", selectedOption)}
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                xs={12}
                className="mt-12 mx-4"
              >
                <span className="fs-3 fw-bold ">{lang("INFO.CHANGE")}</span>
              </Col>
              <Col xs={12}>
                <Row className="ms-8">
                  <Col xs={4} className="mt-3">
                    <Autocomplete
                      isRequired
                      lable={lang("CONTRACT.SIGNINGUNIT")}
                      options={[]}
                      value={formik.values?.donViKyHopDong || null}
                      name="donViKyHopDong"
                      isReadOnly={!isEdit}
                      onChange={(selectedOption) => handleChangeSelect("donViKyHopDong", selectedOption)}
                      searchFunction={searchListWorkUnit}
                      touched={formik.touched?.donViKyHopDong}
                      errors={formik.errors?.donViKyHopDong}
                    />
                  </Col>
                  <Col xs={4} className="mt-3">
                    <Autocomplete
                      isRequired
                      lable={lang("INPUT.DEPARTMENTS")}
                      isReadOnly={!isEdit}
                      options={[]}
                      value={formik.values?.phongBan || null}
                      name="phongBan"
                      onChange={(selectedOption) => handleChangeSelect("phongBan", selectedOption)}
                      urlData="data.data"
                      searchFunction={searchPhongBanByDonVi}
                      searchObject={{ id: formik.values?.donViKyHopDong?.id }}
                      touched={formik.touched?.phongBan}
                      errors={formik.errors?.phongBan}
                      dependencies={[formik.values?.donViKyHopDong]}
                    />
                  </Col>
                  <Col xs={4} className="mt-3">
                    <Autocomplete
                      isRequired
                      lable={lang("CONTRACT.JOBPOSITION")}
                      options={[]}
                      value={formik.values?.viTriCongViec || null}
                      name="viTriCongViec"
                      isReadOnly={!isEdit || isCollaborators}
                      onChange={(selectedOption) => handleChangeSelect("viTriCongViec", selectedOption)}
                      getOptionLabel={(option) => option?.value}
                      searchFunction={searchAllSimpleValue}
                      searchObject={{ type: TYPE_CATEGORY.chucDanh }}
                      touched={formik.touched?.viTriCongViec}
                      errors={formik.errors?.viTriCongViec}
                    />
                  </Col>
                  <Col xs={4} className="mt-3">
                    <Autocomplete
                      lable={lang("GENERAL.EMPLOYEE.TITLE")}
                      name="chucDanh"
                      isRequired={!isCollaborators}
                      value={formik.values?.chucDanh || null}
                      searchFunction={searchAllSimpleValue}
                      searchObject={{ type: TYPE_CATEGORY.viTriCongViec }}
                      isReadOnly={!isEdit || isCollaborators}
                      onChange={(selectedOption) => handleChangeSelect("chucDanh", selectedOption)}
                      options={[]}
                      getOptionLabel={(option) => option?.value}
                      errors={formik.errors?.chucDanh}
                      touched={formik.touched?.chucDanh}
                    />
                  </Col>
                  <Col xs={4} className="mt-3">
                    <Autocomplete
                      isRequired
                      lable={lang("CONTRACT.TYPE")}
                      options={contractTypes}
                      isReadOnly
                      value={formik.values?.loaiHopDong || null}
                      name="loaiHopDong"
                      onChange={(selectedOption) => handleChangeSelect("loaiHopDong", selectedOption)}
                      touched={formik.touched?.loaiHopDong}
                      errors={formik.errors?.loaiHopDong}
                    />
                  </Col>
                  <Col xs={4} className="mt-3">
                    <TextValidator
                      lable={lang("CONTRACT.EFFECTIVEDATE")}
                      name="ngayCoHieuLuc"
                      isRequired
                      value={formik.values?.ngayCoHieuLuc || ""}
                      type="date"
                      readOnly={true}
                      errors={formik.errors?.ngayCoHieuLuc}
                      touched={formik.touched?.ngayCoHieuLuc}
                    />
                  </Col>
                  <Col xs={4} className="mt-3">
                    <TextValidator
                      lable={lang("CONTRACT.EXPIRATIONDATE")}
                      name="ngayHetHan"
                      readOnly={true}
                      isRequired={!isLongTermContract}
                      value={formik.values?.ngayHetHan || ""}
                      type="date"
                      errors={formik.errors?.ngayHetHan}
                      touched={formik.touched?.ngayHetHan}
                    />
                  </Col>
                  <Col xs={4} className="mt-3">
                    <Autocomplete
                      lable={lang("CONTRACT.SALARY.LEVEL")}
                      name="bacLuongOption"
                      isRequired={!isCollaborators}
                      isReadOnly={!isEdit || isCollaborators}
                      value={formik.values?.bacLuongOption || null}
                      searchFunction={getBacLuongByChucDanh}
                      searchObject={formik.values?.chucDanh?.id}
                      sort={(data: any) => data.sort((a: any, b: any) => a.bacLuong - b.bacLuong)}
                      urlData="data.data"
                      onChange={(selectedOption) => handleChangeSelect("bacLuongOption", selectedOption)}
                      options={[]}
                      getOptionLabel={(option) => option?.bacLuong}
                      errors={formik.errors?.bacLuongOption}
                      touched={formik.touched?.bacLuongOption}
                      dependencies={[formik.values?.chucDanh?.id]}
                    />
                  </Col>
                  <Col xs={4} className="mt-3">
                    <TextValidator
                      lable={lang("CONTRACT.SALARY.COEFFICIENT")}
                      name="heSoLuong"
                      isRequired={!isCollaborators}
                      value={formik.values?.heSoLuong || ""}
                      type="number"
                      readOnly={true}
                      onChange={formik.handleChange}
                      errors={formik.errors?.heSoLuong}
                      touched={formik.touched?.heSoLuong}
                    />
                  </Col>
                  <Col xs={4} className="mt-3">
                    <TextValidator
                      lable={lang("CONTRACT.BASESALARY")}
                      name="luongCoBan"
                      isRequired={isCollaborators}
                      type="text"
                      readOnly={!isEdit}
                      value={convertNumberPrice(formik.values?.luongCoBan)}
                      onChange={handleChangeMoney}
                      errors={formik.errors?.luongCoBan}
                      touched={formik.touched?.luongCoBan}
                    />
                  </Col>
                  <Col xs={4} className="mt-3">
                    <TextValidator
                      lable={lang("CONTRACT.INSURANCECONTRIBUTION")}
                      name="luongDongBaoHiem"
                      value={convertNumberPrice(formik.values?.luongDongBaoHiem)}
                      type="text"
                      onChange={handleChangeMoney}
                      readOnly={!isEdit}
                    />
                  </Col>
                  <Col xs={4} className="mt-3">
                    <TextValidator
                      lable={lang("CONTRACT.SALARYRATE")}
                      name="tyLeHuongLuong"
                      value={formik.values?.tyLeHuongLuong || ""}
                      type="text"
                      readOnly={!isEdit}
                      onChange={formik.handleChange}
                      touched={formik.touched?.tyLeHuongLuong}
                      errors={formik.errors?.tyLeHuongLuong}
                    />
                  </Col>
                  <Col xs={4} className="mt-3">
                    <Autocomplete
                      lable={lang("CONTRACT.SIGNEDCOMPANYREPRESENTATIVE")}
                      options={peopleDecisions}
                      isRequired
                      isReadOnly={!isEdit}
                      value={formik.values?.nguoiDaiDienCtyKy || null}
                      name="nguoiDaiDienCtyKy"
                      onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.NGUOI_DAI_DIEN_KY, selectedOption)}
                      isAddNew
                      onAddNew={() => setIsShowDialogAddRepresentative(true)}
                      errors={formik.errors?.nguoiDaiDienCtyKy}
                      touched={formik.touched?.nguoiDaiDienCtyKy}
                    />
                  </Col>
                  <Col xs={4} className="mt-3">
                    <TextValidator
                      lable={lang("CONTRACT.REPRESENTATIVETITLE")}
                      name="nguoiDaiDienCtyChucDanhText"
                      value={formik.values?.nguoiDaiDienCtyChucDanhText || ""}
                      type="text"
                      readOnly={true}
                    />
                  </Col>
                  <Col xs={4} className="mt-3">
                    <Form.Check
                      label={lang("CONTRACT.HAS_AUTHORITY_PAPER")}
                      name="coGiayUyQuyen"
                      checked={formik.values?.coGiayUyQuyen}
                      readOnly={!isEdit}
                      onChange={handleCheckBoxChange}
                      className="checkBox custom-form-check"
                    />
                  </Col>
                  <Col xs={4} className="mt-3">
                    <TextValidator
                      lable={lang("CONTRACT.NOTE")}
                      name="ghiChu"
                      value={formik.values?.ghiChu || ""}
                      type="text"
                      readOnly={!isEdit}
                      onChange={formik.handleChange}
                    />
                  </Col>
                  <Col xs={4} className="mt-3">
                    <UploadFile
                      label="CONTRACT.ATTACHMENTS"
                      setValue={handleFile}
                      isReadOnly={isView}
                      fileValue={{
                        id: formik.values.fileId || "",
                        name: formik.values.fileName || ""
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Row>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <GroupButton type="btn-cancel" handleCloseUpdateDialog={handleCloseAddContractAnnex} />
          {isEdit ? (
            <GroupButton type="btn-save" />
          ) : (
            <GroupButton type="btn-edit" handleSaveEdit={() => setIsEdit(true)} />
          )}
          {annexInfo?.id && (
            <GroupButton handleEvent={() => setIsShowDialogWord(true)}>
              {lang("GENERAL.EXPORT_WORD")}
            </GroupButton>
          )}
        </Modal.Footer>
      </Form>
      {isShowDialogWord && (
        <ExportWord
          open={isShowDialogWord}
          handleClose={() => setIsShowDialogWord(false)}
          templateList={[{
            name: "Phụ lục hợp đồng",
            componentPreview: (dataPreview) => <ContractAnnexWord data={dataPreview}/>
          }]}
          getObj={{
            employeeId: annexInfo?.employeeId,
            hopDongPhuLucId: annexInfo?.id,
            type: DATA_WORD_TYPES.CONSTRACT_ANNEX
          }}
        />
      )}
      {isShowDialogAddRepresentative && (
        <AddNewRepresentative
          handleClose={() => setIsShowDialogAddRepresentative(false)}
          handleCloseAndSearch={handleCloseAndSearchRepresentative}
        />
      )}
    </Modal>
  );
};
export { AddNewContractAnnex };

import React, { useEffect, useState } from 'react'
import { Col, Image, Row } from 'react-bootstrap'
import TableCustom from '../../../components/table-custom/TableCustom';
import "./style-word.scss"
import TextValue from '../../../components/TextValue';
import { columnsLevel } from '../columns/ColumnsLevel';
import { columnsWorkingProcess } from '../columns/ColumnsWorkingProcess';
import { columnFamilyRelationships } from '../columns/ColumnFamilyRelationships';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';
import TextUnderline from '../../../components/TextUnderline';
import { checkObject } from '../../../utils/functionUtils';

type Props = {
  dataEmployee?: any
}

function TCTW98(props: Props) {
  const { dataEmployee } = props;
  const [data26, setData26] = useState<any>([])

  useEffect(() => {
    if (!checkObject(dataEmployee)) {
      let bc = (dataEmployee?.chuyenMon || []).map((item: any) => {
        return {
          name: item?.bcCoSoDaoTao,
          chuyenNganh: item?.bcChuyenNganh,
          thoiGian: item?.bcThoiGianDaoTao,
          vanBang: item?.bcVanBang,
          hinhThuc: item?.bcHinhThucDaoTao,
        }
      })

      let llct = (dataEmployee?.lyLuanChinhTri || []).map((item: any) => {
        return {
          name: item?.llctCoSoDaoTao,
          thoiGian: item?.llctThoiGianDaoTao,
          vanBang: item?.llctVanBang,
          hinhThuc: item?.llctHinhThucDaoTao,
        }
      })

      let cc = ([...dataEmployee?.ngoaiNgu]).map((item: any) => {
        return {
          name: item?.ccCoSoDaoTao,
          thoiGian: item?.ccThoiGianDaoTao,
          vanBang: item?.ccVanBang,
          hinhThuc: item?.ccHinhThucDaoTao,
        }
      })

      let qtbd = (dataEmployee?.chucDanhNgheNghiep || []).map((item: any) => {
        return {
          name: item?.qtbdCoSoDaoTao,
          thoiGian: item?.qtbdThoiGianDaoTao,
          vanBang: item?.qtbdVanBang,
          hinhThuc: item?.qtbdHinhThucDaoTao,
        }
      })
      setData26([...bc, ...llct, ...cc, ...qtbd])
    }
  }, [dataEmployee])

  const columnsSalaryHistory = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: "Tháng/năm",
      field: "lslThoiGianHuongLuong",
      headerStyle: {
        minWidth: "100px",
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any) => row?.lslThoiGianHuongLuong ? <span>{row?.lslThoiGianHuongLuong}</span> : <TextValue className="spaces h-25" value="" />
    },
    {
      name: "Ngạch/bậc",
      field: "lslChucDanh",
      headerStyle: {
        minWidth: "130px",
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any) => row?.lslChucDanh ? <span>{row?.lslChucDanh}</span> : <TextValue className="spaces h-25" value="" />
    },
    {
      name: "Hệ số lương",
      field: "lslHeSo",
      headerStyle: {
        minWidth: "50px",
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any) => row?.lslHeSo ? <span>{row?.lslHeSo}</span> : <TextValue className="spaces h-25" value="" />
    },
  ]

  return (
    <div className='word'>
      <Row >
        <Col xs={5}>
          <TextUnderline readOnly label="Bộ, tỉnh" value={dataEmployee?.coQuanQuanLy} />
          <TextUnderline readOnly label="Đơn vị trực thuộc" value={dataEmployee?.donViCongTacText} />
          <TextUnderline readOnly label="Đơn vị cơ sở" value={dataEmployee?.khoaPhong} />
        </Col>
        <Col xs={3} className='spaces fw-700 fs-24 flex flex-center'>SƠ YẾU LÝ LỊCH</Col>
        <Col xs={4} className='flex flex-top flex-end'>Mẫu 2C/TCTW-98</Col>
      </Row>
      <p className='flex flex-end'>Số hiệu cán bộ, công chức: {dataEmployee?.soHieu}</p>
      <div className='flex spaces mb-12'>
        <div className="image-profile">
          <Image src={dataEmployee?.photo || toAbsoluteUrl("/media/avatars/blank.png")} alt="Ảnh màu (4 x 6cm)" className="object-fit-cover w-100 h-100" />
        </div>
        <div className='flex-1'>
          <Row>
            <Col xs={8}>
              <TextUnderline readOnly label="1) Họ và tên khai sinh" value={dataEmployee?.name} />
            </Col>
            <Col xs={4}>
              <TextUnderline readOnly label="Nam, nữ" value={dataEmployee?.gender?.name} />
            </Col>
          </Row>
          <TextUnderline readOnly label="2) Các tên gọi khác" value={dataEmployee?.otherName} />
          <div>
            <div className='flex'>
              <TextUnderline readOnly label="3) Cấp ủy hiện tại" value={""} />
              <TextUnderline readOnly label="Cấp ủy kiêm" value={""} />
            </div>
            <TextUnderline readOnly label="Chức vụ (Đảng, đoàn thể, Chính quyền, kể cả chức vụ kiêm nhiệm)" value={""} />
            <TextUnderline readOnly label="Phụ cấp chức vụ" value={""} />
          </div>
          <div className="flex">
            <TextUnderline label="4) Sinh ngày" className="spaces w-200" value={dataEmployee?.ngaySinh} />
            <TextUnderline label="tháng" className="spaces w-120" value={dataEmployee?.thangSinh} />
            <TextUnderline label="năm" className="spaces w-120" value={dataEmployee?.namSinh} />
          </div>
          <TextUnderline label="5) Nơi sinh" value={dataEmployee?.noiSinh} />
        </div>
      </div>
      <div className='flex'>
        <TextUnderline readOnly label="6) Quê quán (xã, phường)" value={dataEmployee?.queQuanXaText} />
        <TextUnderline readOnly label="(huyện, quận)" value={dataEmployee?.queQuanHuyenText} />
        <TextUnderline readOnly label="(tỉnh, TP)" value={dataEmployee?.queQuanTinhText} />
      </div>
      <div>
        <TextUnderline label="7) Nơi ở hiện nay (Xã, huyện, tỉnh hoặc số nhà, đường phố, TP)" value={dataEmployee?.hnDiaChi} readOnly />
        <TextUnderline label="Điện thoại" value={dataEmployee?.phone} readOnly />
        <div>
          <TextUnderline label="Nơi đăng ký hộ khẩu thường trú (Số nhà, đường phố, thành phố; xóm, thôn, xã, huyện, tỉnh)" value={dataEmployee?.hkDiachi} readOnly />
        </div>
      </div>
      <TextUnderline readOnly label="8) Dân tộc: (Kinh, Tày, Mông, Ê đê...)" value={dataEmployee?.danTocText} />
      <TextUnderline readOnly label="9) Tôn giáo" value={dataEmployee?.tonGiaoText} />
      <div>
        <TextUnderline readOnly label="10) Thành phần gia đình xuất thân" value={dataEmployee?.tpGiaDinh?.name} />
        <i>(Ghi là công nhân, nông dân, cán bộ, công chức, trí thức, quân nhân, dân nghèo thành thị, tiểu thương, tiểu chủ, tư sản ...)</i>
      </div>
      <div>
        <TextUnderline readOnly label="11) Nghề nghiệp bản thân trước khi được tuyển dụng" value={""} />
        <i>(Ghi nghề được đào tạo hoặc công nhân (thợ gì), làm ruộng, buôn bàn, học sinh, ...)</i>
      </div>
      <Row>
        <Col xs={5}>
          <TextUnderline readOnly label="12) Ngày được tuyển dụng" type="date" value={dataEmployee?.ngayChinhThuc} />
        </Col>
        <Col xs={7}>
          <TextUnderline readOnly label="Vào cơ quan nào, ở dâu" value={dataEmployee?.donViCongTacText} />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <TextUnderline readOnly label="13) Ngày vào cơ quan hiện đang công tác" type="date" value={dataEmployee?.ngayVaoCoQuan} />
        </Col>
        <Col xs={6}>
          <TextUnderline readOnly label="Ngày tham gia cách mạng" type="date" value={""} />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <TextUnderline readOnly label="14) Ngày vào Đảng Cộng sản Việt Nam" type="date" value={dataEmployee?.ttctNgayVaoDang} />
        </Col>
        <Col xs={6}>
          <TextUnderline readOnly readOnlyalue label="Ngày chính thức" type="date" value={dataEmployee?.ttctNgayChinhThuc} />
        </Col>
      </Row>
      <div>
        <TextUnderline readOnly label="15) Ngày tham gia các tổ chức chính trị, xã hội" type="date" value={dataEmployee?.ngayVaoDoan} />
        <i>(Ngày vào Đoàn TNCSHCM, Công đoàn, Hội .........  ) </i>
      </div>
      <Row>
        <Col xs={3}>
          <TextUnderline readOnly label="16) Ngày nhập ngũ" type="date" value={dataEmployee?.ngayNhapNgu} />
        </Col>
        <Col xs={3}>
          <TextUnderline readOnly label="Ngày xuất ngũ" type="date" value={dataEmployee?.ngayXuatNgu} />
        </Col>
        <Col xs={6}>
          <TextUnderline readOnly label="Quân hàm, chức vụ cao nhất (năm)" value={dataEmployee?.capBacQuanSuText} />
        </Col>
      </Row>
      <div>
        <p>17)Trình độ học vấn:</p>
        <div className='spaces pl-24'>
          <TextUnderline readOnly label="- Giáo dục phổ thông" value={dataEmployee?.trinhDoGiaoDucPhoThongText} />
          <i className="spaces ml-160">(Lớp mấy)</i>
          <div className="flex">
            <TextUnderline readOnly label="- Học hàm, học vị cao nhất" value={dataEmployee?.hocHam } />
            <TextUnderline readOnly label="Năm" value={dataEmployee?.tddtNamPhongTang} />
          </div>
          <i className="spaces ml-200">(GS, PGS, TS, PTS, Thạc sĩ, Cử nhân, Kỹ sư ... năm nào, chuyên ngành gì)</i>
          <TextUnderline readOnly label="- Lý luận chính trị" value={dataEmployee?.trinhDoLyLuanChinhTri} />
          <i className="spaces ml-140">(Cử nhân, Cao cấp, Trung cấp, Sơ cấp)</i>
          <TextUnderline readOnly label="- Ngoại ngữ" value={dataEmployee?.trinhDoNgoaiNgu} />
          <i className="spaces ml-95">(Anh (A/B/C/D)      Nga (A/B/C/D)      Pháp (A/B/C/D) ...)</i>
        </div>
      </div>
      <TextUnderline readOnly label="18) Công tác chính đang làm" value={dataEmployee?.trinhDoNgoaiNgu} />
      <Row className='flex'>
        <Col xs={6} className='flex'>
          <TextUnderline readOnly label="19) Ngạch công chức" className="flex-1" value={dataEmployee?.chucDanh} />
          <p className='unti-icon'>(</p>
          <TextUnderline readOnly label="mã số" className="spaces w-150" value={dataEmployee?.maSoChucDanh} />
          <p className='unti-icon'>)</p>
        </Col>
        <Col xs={3}>
          <Row>
            <Col xs={6}>
              <TextUnderline readOnly label="Bậc lương" value={dataEmployee?.bacLuong} />
            </Col>
            <Col xs={6}>
              <TextUnderline readOnly label="hệ số" value={dataEmployee?.heSoBacLuong} />
            </Col>
          </Row>
        </Col>
        <Col xs={3}>
          <TextUnderline readOnly label="từ tháng" type="date" value={dataEmployee?.ngayHuongLuongTuNgay} />
        </Col>
      </Row>

      <div className="flex">
        <TextUnderline readOnly label="20) Danh hiệu được phong (năm nào)" value={dataEmployee?.danhHieu?.name} />
        <TextUnderline readOnly label="Năm" value={dataEmployee?.namPhongTang} />
      </div>
      <i>(Anh hùng lao động, anh hùng lực lượng vũ trang, nhà giáo, thấy thuốc, nghệ sĩ nhân dân, ưu tú)</i>
      <div className='flex'>
        <TextUnderline readOnly label="21) Sở trường công tác" value={""} />
        <TextUnderline readOnly label="Công việc đã làm lâu nhất" value={""} />
      </div>
      <div>
        <TextUnderline readOnly label="22) Khen thưởng" value={dataEmployee?.khenThuongCaoNhat} />
        <i>(Huân, huy chương, năm nào)</i>
      </div>
      <TextUnderline readOnly label="23) Kỷ luật (Đảng, Chính quyền, Đoàn thể, Cấp quyết định, năm nào, lý do, hình thức, ...)" value={dataEmployee?.kyLuatCaoNhat} />
      <div>
        <div className='flex'>
          <TextUnderline readOnly label="24) Tình trạng sức khỏe" className="flex-1" value={dataEmployee?.tinhTrangSucKhoe} />
          <div className='flex-middle'>
            <TextUnderline readOnly label="Cao: 1m" className="spaces w-150" value={dataEmployee?.chieuCao} />
            <p className='unti-icon'>cm,</p>
          </div>
          <div className='flex-middle'>
            <TextUnderline readOnly label="Cân nặng" className="spaces w-150 ml-5" value={dataEmployee?.canNang} />
            <p className='unti-icon'>(kg)</p>
          </div>
          <TextUnderline readOnly label="Nhóm máu" className="spaces w-170 ml-5" value={dataEmployee?.nhomMau?.name} />
        </div>
        <i>(tốt, bình thường, yếu hoặc có bệnh mãn tính gì)</i>
      </div>
      <div className='flex'>
        <TextUnderline readOnly label="25) Số chứng minh nhân dân" value={dataEmployee?.soCMNDOrCCCD} />
        <TextUnderline readOnly label="Thương binh loại" value={dataEmployee?.hangThuongBinh} />
        <TextUnderline readOnly label="Gia đình liệt sĩ" value={""} />
      </div>
      <div>
        <p className='text-uppercase spaces mt-6'>26) ĐÀO TẠO, BỒI DƯỠNG VỀ CHUYÊN MÔN, NGHIỆP VỤ, LÝ LUẬN CHÍNH TRỊ, NGOẠI NGỮ</p>
        <TableCustom
          id=""
          data={data26}
          columns={columnsLevel()}
          buttonAdd={false}
          buttonExportExcel={false}
          notDelete={false}
          justFilter={true}
          noPagination={true}
        />
        <p><i>Ghi chú: Hình thức học: Chính quy, tại chức, chuyên tu, bồi dưỡng .../  Văn bằng: Tiến sĩ, Phó tiến sĩ, Thạc sĩ, Cử nhân, Kỹ sư ...</i></p>
      </div>

      <div>
        <p className='spaces mt-6'>27) TÓM TẮT QUÁ TRÌNH CÔNG TÁC</p>
        <TableCustom
          id=""
          data={dataEmployee?.quaTrinhCongTac || []}
          columns={columnsWorkingProcess()}
          buttonAdd={false}
          buttonExportExcel={false}
          notDelete={false}
          justFilter={true}
          noPagination={true}
        />
      </div>

      <div>
        <p className='spaces mt-6'>28) ĐẶC ĐIỂM LỊCH SỬ BẢN THÂN</p>
        <p>a) Khai rõ: bị bắt, bị tù (từ ngày tháng năm nào đến ngày tháng năm nào, ở đâu), đã khai báo cho ai, những vấn đề gì</p>
        <TextUnderline
          rows={2}
          readOnly
          value={dataEmployee?.lichSuBanThan?.join("\n")}
        />
        <p>b) Bản thân có làm việc trong chế độ cũ (Cơ quan, đơn vị nào, địa điểm, chức danh, chức vụ, thời gian làm việc</p>
        <TextUnderline readOnly rows={2} value={dataEmployee?.lichSuLamViecCheDoCu?.join("\n")} />
      </div>

      <div>
        <p className='spaces mt-6'>29) QUAN HỆ VỚI NƯỚC NGOÀI</p>
        <p>- Tham gia hoặc có quan hệ với các tổ chức chính trị, kinh tế, xã hội nào ở nước ngoài (làm gì, tổ chức nào, đặt trụ sở ở đâu...?):</p>
        <TextUnderline readOnly rows={2} value={dataEmployee?.lichSuLamViecNuocNgoai?.join("\n")} />
        <p>- Có thân nhân (Bố, mẹ, vợ, chồng, con, anh chị em ruột) ở nước ngoài (làm gì, địa chỉ ...?):</p>
        <TextUnderline readOnly rows={2} value={dataEmployee?.lichSuLamViecNuocNgoaiThanNhan?.join("\n")} />
      </div>

      <div>
        <p className='spaces mt-6'>30) QUAN HỆ GIA ĐÌNH</p>
        <div>
          <p className='spaces pl-16'>a) Về bản thân:  Bố, Mẹ, Vợ (chồng), các con, anh chị em ruột</p>
          <TableCustom
            id=""
            data={dataEmployee?.quanHeGiaDinhBt || []}
            columns={columnFamilyRelationships()}
            buttonAdd={false}
            buttonExportExcel={false}
            notDelete={false}
            justFilter={true}
            noPagination={true}
          />
        </div>
        <div>
          <p className='spaces pl-16'>b) Bố, Mẹ, anh chị em ruột (bên vợ hoặc chồng):</p>
          <TableCustom
            id=""
            data={dataEmployee?.quanHeGiaDinhVc || []}
            columns={columnFamilyRelationships()}
            buttonAdd={false}
            buttonExportExcel={false}
            notDelete={false}
            justFilter={true}
            noPagination={true}
          />
        </div>
      </div>

      <div>
        <p className='spaces mt-6'>31) HOÀN CẢNH KINH TẾ GIA ĐÌNH</p>
        <p className='spaces pl-16'>-Quá trình lương của bản thân: </p>
        <TableCustom
          id=""
          data={dataEmployee?.lichSuLuong || []}
          columns={columnsSalaryHistory}
          buttonAdd={false}
          buttonExportExcel={false}
          notDelete={false}
          justFilter={true}
          noPagination={true}
        />
      </div>
      <div>
        <p className='spaces mt-6'>- Nguồn thu nhập chính của gia đình (hàng năm): </p>
        <div className='spaces pl-20'>
          <TextUnderline readOnly label="+ Lương" value={""} />
          <TextUnderline readOnly label="+ Các nguồn khác" value={""} />
        </div>
        <p>- Nhà ở: </p>
        <div className='spaces pl-20'>
          <div className='flex'>
            <TextUnderline readOnly label="+ Được cấp, được thuê, loại nhà" value={""} />,
            <TextUnderline readOnly className="spaces ml-6" label="tổng diện tích sử dụng" value={""} />
          </div>
          <div className='flex'>
            <TextUnderline readOnly label="+ Nhà tự mua, tự xây, loại nhà" value={""} />,
            <TextUnderline readOnly className="spaces ml-6" label="tổng diện tích sử dụng" value={""} />
          </div>
        </div>
        <p>- Đất ở:</p>
        <div className='spaces pl-20'>
          <div className='flex-middle'>
            <TextUnderline readOnly label="+ Đất được cấp" className="spaces w-220" value={""} />
            <p className='unti-icon'>m²</p>
          </div>
          <div className='flex-middle'>
            <TextUnderline readOnly label="+ Đất tự mua" value={""} />
            <p className='unti-icon'>m²</p>
          </div>
        </div>
        <TextUnderline readOnly rows={3} label="- Đất sản xuất, kinh doanh: (Tổng diện tích đất được cấp, tự mua, tự khai phá ...)" value={""} />
      </div>
      <div className="flex text-center gap-10 fs-sm spaces mt-40">
        <div className="flex-1">
          <p className="spaces mb-0"><b>Người Khai</b></p>
          <p className="spaces mb-0">Tôi xin cam đoan những <br />lời khai trên đây là đúng sự thật</p>
        </div>
        <div className="flex-1">
          <div>
            <p className="mb-0 flex flex-center flex-align-items-end gap-0">
              <TextValue label="" className="spaces w-80" />, ngày
              <TextValue label="" className="spaces w-48" /> tháng
              <TextValue label="" className="spaces w-48" /> năm
              <TextValue label="" className="spaces w-48" />
            </p>
            <p className="mb-0">
              <b>Xác nhận của cơ quan quản lý</b>
            </p>
            <p className="text-center spaces mb-100"><i>(hoặc của Công an xã, phường, thị trấn nơi cư ngụ)</i></p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TCTW98
import { Modal, Row, Col } from "react-bootstrap";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import { useEffect, useState } from "react";
import { IWelfare } from "../../../welfare-list/models/welfareModels";
import TextValidator from "../../../components/input/text-validator";
import Autocomplete from "../../../components/input/autocomplete";
import { formEvent, groupWelfare } from "../../../welfare-list/constants/welfareConsts";
import { MISSION_STATUS } from "../../../constants/moduleConsts";
import { GroupButton } from "../../../components/GroupButton";

interface IProps {
  handleClose: () => void;
  open: boolean;
  dataWelfare: IWelfare;
}

function WelfareDialog(props: IProps) {
  const { handleClose, open, dataWelfare } = props;
  const { lang } = useMultiLanguage();

  const [data, setData] = useState<IWelfare>({} as IWelfare);

  useEffect(() => {
    setData(dataWelfare);
  }, [dataWelfare]);

  return (
    <Modal
      show={open}
      size="xl"
      centered
      aria-labelledby="example-custom-modal-styling-title"
      onHide={handleClose}
      backdrop="static"
    >
      <Modal.Header
        closeButton
        className="spaces py-12"
      >
        <Modal.Title className="spaces fs-20 ">{lang("WELFARE.DETAIL")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="g-4">
          <Col xs={3}>
            <TextValidator
              name="tenPhucLoi"
              lable={lang("WELFARE.NAME")}
              type="text"
              value={data?.tenPhucLoi}
              readOnly={true}
            />
          </Col>
          <Col xs={3}>
            <Autocomplete
              lable={lang("WELFARE.GROUP")}
              options={groupWelfare}
              isReadOnly={true}
              value={data?.nhomPhucLoi || null}
              name="nhomPhucLoi"
              isClearable={false}
            />
          </Col>
          <Col xs={3}>
            <TextValidator
              name="tenPdonViApDunghucLoi"
              lable={lang("WELFARE.APPLY.UNIT")}
              type="text"
              value={data?.donViApDungText}
              readOnly={true}
            />
          </Col>
          <Col xs={3}>
            <TextValidator
              name="ngayBatDau"
              lable={lang("WELFARE.DATE.START")}
              type="date"
              value={data?.ngayBatDau}
              readOnly={true}
            />
          </Col>
          <Col xs={3}>
            <TextValidator
              name="ngayKetThuc"
              lable={lang("WELFARE.DATE.END")}
              type="date"
              value={data?.ngayKetThuc}
              readOnly={true}
            />
          </Col>
          <Col xs={3}>
            <TextValidator
              name="mucDichToChuc"
              lable={lang("WELFARE.ORGANIZATION.PURPOSE")}
              type="text"
              value={data?.mucDichToChuc}
              readOnly={true}
            />
          </Col>
          <Col xs={3}>
            <Autocomplete
              lable={lang("WELFARE.IMPLEMENT.FORM")}
              options={formEvent}
              isReadOnly={true}
              value={data?.hinhThucThucHien || null}
              name="hinhThucThucHien"
            />
          </Col>
          <Col xs={3}>
            <TextValidator
              name="soNguoiDuKien"
              lable={lang("WELFARE.EXPECTED.PEOPLE")}
              type="number"
              value={data?.soNguoiDuKien || null}
              readOnly={true}
            />
          </Col>
          <Col xs={3}>
            <Autocomplete
              lable={lang("WELFARE.STATUS")}
              options={MISSION_STATUS}
              isReadOnly={true}
              value={data?.trangThai || null}
              name="trangThai"
            />
          </Col>
          <Col xs={6}>
            <TextValidator
              name="diaDiemToChuc"
              lable={lang("WELFARE.ORGANIZATION.PLACE")}
              type="text"
              value={data?.diaDiemToChuc}
              readOnly={true}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="flex-center spaces py-6">
        <GroupButton outline handleEvent={handleClose}>{lang("BTN.CLOSE")}</GroupButton>
      </Modal.Footer>
    </Modal>
  );
}

export default WelfareDialog;

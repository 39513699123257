import { IDismissed } from "../models/dismissedModels";
import axios from "axios";
import { paramsConfig } from "../../utils/paramsUtils";
const API_URL = process.env.REACT_APP_API_URL;

export const getListDismissed = (searchData:any) => {
  let url = `${API_URL}/mien-nhiem/page`;
  return axios.get(url, paramsConfig(searchData));
};

export const addNewDismissed = (dismissed: IDismissed) => {
  let url = `${API_URL}/mien-nhiem`;
  return axios.post(url, dismissed);
};

export const editDismissed = (id: string, dismissed: IDismissed) => {
  let url = `${API_URL}/mien-nhiem/${id}`;
  return axios.put(url, dismissed);
};

export const deleteDismissed = (ids: string[]) => {
  let url = `${API_URL}/mien-nhiem/mienNhiemIds?ids=${ids.toString()}`;
  return axios.delete(url);
};

export const getTypeAssignmentConcurrently = (employeeId: string) => {
  let url = `${API_URL}/kiem-nhiem/check-assignment-existence`;
  return axios.get(url, paramsConfig({ employeeId }));
}

export const getPositionConcurrently = (searchObject: any) => {
  let url = `${API_URL}/kiem-nhiem/get-assignments`;
  return axios.get(url, paramsConfig(searchObject));
}
import { useCallback, useContext, useEffect, useState } from "react";
import TableCustom, { columnNamesType } from "../../table-custom/TableCustom";
import { toast } from "react-toastify";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import { RESPONSE_STATUS_CODE } from "../../../constants/moduleConsts";
import AppContext from "../../../../AppContext";
import { searchPhongBan } from "../../../services/services";
import { GroupButton } from "../../GroupButton";

interface IProps {
  handleSelect: (row: any) => void;
}

function DepartmentTable(props: IProps) {
  const { lang } = useMultiLanguage();
  const { handleSelect } = props;
  const [departmentList, setDepartmentList] = useState([]);
  const { setPageLoading } = useContext(AppContext);

  const getDepartmentData = useCallback(async () => {
    try {
      setPageLoading(true);
      const { data } = await searchPhongBan();
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setDepartmentList(data?.data?.content || []);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  }, [lang, setPageLoading]);

  useEffect(() => {
    getDepartmentData();
  }, [getDepartmentData])


  const columns: columnNamesType[] = [
    {
      name: lang("TABLE.INDEX"),
      field: "stt",
      headerStyle: {
        minWidth: "50px"
      },
      render: (row: any, index: number, STT: number) => <span>{STT}</span>
    },
    {
      name: lang("GENERAL.OFFICE"),
      field: "name",
      headerStyle: {
        minWidth: "300px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("GENERAL.ACTION"),
      field: "action",
      render: (row) => (
        <GroupButton
          className="flex flex-center" 
          outline handleEvent={() => handleSelect(row)}
        >{lang("BTN.SELECT")}</GroupButton>
      )
    }
  ];

  return (
    <div className="table-modal-h">
      <TableCustom
        data={departmentList}
        columns={columns}
        noPagination
        noToolbar
        uniquePrefix="employeeId"
      />
    </div>
  );
}

export default DepartmentTable;

import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { REF_TAB } from "../constants/profileConsts";
import TableCustom, { columnNamesType } from "../../components/table-custom/TableCustom";
import { RESPONSE_STATUS_CODE, TYPE } from "../../constants/moduleConsts";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import AppContext from "../../../AppContext";
import { IWorkingHistory } from "../models/dialogModels";
import { INIT_WORKING_HISTORY, WORKING_HISTORY_OLD_MODE_TYPE } from "../constants/profileDialogConsts";
import { deleteLichSuLamViec, getAllLichSuLamViecById } from "../services/dialogServices";
import { formatDateTable } from "../../utils/functionUtils";
import { WorkInOldModeDialog } from "./dialogs/WorkInOldModeDialog";

interface IProps {
  identify: string;
  isView: boolean;
  activeTab: string;
}

export const WorkInOldModeInformation = (props: IProps) => {
  const { identify, isView, activeTab } = props;
  const [shouldOpenDialog, setShouldOpenDialog] = useState<boolean>(false);
  const [rowInfo, setRowInfo] = useState<IWorkingHistory>(INIT_WORKING_HISTORY);
  const [listData, setListData] = useState<IWorkingHistory[]>([]);
  const { setPageLoading } = useContext(AppContext);

  const { lang } = useMultiLanguage();

  useEffect(() => {
    if (!(identify && activeTab === REF_TAB.TT_KHAC)) return;
    updatePageData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identify, activeTab]);

  const handleOpenDialog = (data: IWorkingHistory): void => {
    setRowInfo(data);
    setShouldOpenDialog(true);
  };

  const handleCloseDialog = (): void => {
    setShouldOpenDialog(false);
    setRowInfo(INIT_WORKING_HISTORY);
  };
  
  const handleCloseAndSearch = () => {
    updatePageData();
    handleCloseDialog();
  }

  const updatePageData = async () => {
    if (identify) {
      try {
        setPageLoading(true);
        const { data } = await getAllLichSuLamViecById({
          employeeId: identify,
          type: WORKING_HISTORY_OLD_MODE_TYPE.code
        });
        if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
          setListData(data?.data?.content || []);
        } else {
          toast.warning(data?.message);
        }
      } catch (error) {
        toast.error(lang("GENERAL.ERROR"));
      } finally {
        setPageLoading(false);
      }
    }
  };

  const handleDelete = async (ids: string[]) => {
    try {
      const { data } = await deleteLichSuLamViec(ids);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("GENERAL.DELETE_SUCCESS"));
        updatePageData();
        return true;
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const columns: columnNamesType[] = [
    {
      name: "STT",
      field: "",
      render: (_: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("INPUT_APPOINTMENT_DATE_START"),
      field: "tuNgay",
      render: (row: any) => <span>{formatDateTable(row?.tuNgay)}</span>
    },
    {
      name: lang("INPUT_APPOINTMENT_DATE_END"),
      field: "denNgay",
      render: (row: any) => <span>{formatDateTable(row?.denNgay)}</span>
    },
    {
      name: lang("PROFILE.OTHER.MORE_INFOMATION"),
      field: "thongTin",
      cellStyle: {
        textAlign: "left"
      },
      render: (row: any) => {
        const lines = row?.thongTin ? row?.thongTin?.split("\n") : [];
        return lines.map((line: string, index: number) => (
          <p className="spaces m-0" key={index}>
            {line}
          </p>
        ));
      }
    }
  ];

  return (
    <>
      <TableCustom
        id="listWorkOldMode"
        title={"* " + lang("PROFILE.OTHER.WORKING_MODE_OLD")}
        isActionTableTab
        data={listData}
        columns={columns}
        type={TYPE.MULTILINE}
        noToolbar={true}
        noPagination={true}
        handleDoubleClick={handleOpenDialog}
        handleDelete={handleDelete}
        buttonAdd={!isView}
        buttonExportExcel={false}
        handleOpenDialog={handleOpenDialog}
        dependencies={activeTab}
      />
      {shouldOpenDialog && (
        <WorkInOldModeDialog
          open={shouldOpenDialog}
          handleClose={handleCloseDialog}
          handleCloseAndSearch={handleCloseAndSearch}
          isView={isView}
          dataEdit={rowInfo}
          employeeId={identify}
        />
      )}
    </>
  );
};

import { formatDateTable } from "../../../utils/functionUtils";

export const canhBaoHetHanGianDoanColumn = () => {
  return [
    {
      name: "Trạng thái",
      field: "trangThai",
      headerCellProps: {
        minWidth: 120
      },
      render: (row: any) => (
        <span className={`${row?.trangThaiHetHanGianDoan?.bgColor} warning-status`}>{row?.trangThaiHetHanGianDoan?.name}</span>
      )
    },
    {
      name: "Họ và tên",
      field: "name",
      headerCellProps: {
        minWidth: 200
      },
      render: (row: any) => (
        <span>{`${row?.maNhanVien || ""} ${row?.maNhanVien && row?.name ? "-" : ""} ${
          row?.name || ""
        }`}</span>
      )
    },
    {
      name: "Khoa/Phòng",
      field: "phongBanText",
      headerCellProps: {
        minWidth: 300
      }
    },
    {
      name: "Chức vụ",
      field: "chucVuText",
      headerCellProps: {
        minWidth: 200
      }
    },
    {
      name: "Mã ngạch/Chức danh",
      field: "viTriCongViecText",
      headerCellProps: {
        minWidth: 250
      }
    },
    {
      name: "Từ ngày",
      field: "ngayBatDau",
      headerCellProps: {
        minWidth: 100
      },
      bodyCellProps: {
        textAlign: "center"
      },
      render: (row: any) => <span>{formatDateTable(row?.ngayBatDau)}</span>
    },
    {
      name: "Đến ngày",
      field: "ngayQuayLaiLV",
      headerCellProps: {
        minWidth: 100
      },
      bodyCellProps: {
        textAlign: "center"
      },
      render: (row: any) => <span>{formatDateTable(row?.ngayQuayLaiLV)}</span>
    },
    {
      name: "Số ngày còn lại",
      field: "soNgayConLai",
      headerCellProps: {
        minWidth: 120
      },
      bodyCellProps: {
        textAlign: "center"
      }
    }
  ];
};

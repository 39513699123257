/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Autocomplete from "../../components/input/autocomplete";
import TextValidator from "../../components/input/text-validator";
import TableCustom from "../../components/table-custom/TableCustom";
import {
  CODE_CONTRACT_LONG_TERM,
  CODE_HOP_DONG,
  contractStatus,
  contractTypeBienChe,
  contractTypeHopDong,
  contractWordExportList,
  signinStatus,
  workShifts
} from "../../contract/constants/contractConsts";
import { addNewContract, deleteContract, updateContract } from "../../contract/services/contractServices";
import { IContractInfo } from "../../contract/models/contractModels";
import { convertDataContactDto, convertDataUI, switchContractStatus } from "../../contract/utils/contractUtils";
import { RESPONSE_STATUS_CODE, TYPE, VARIABLE_STRING, DATA_WORD_TYPES, TYPE_CATEGORY } from "../../constants/moduleConsts";
import { addMoreYear, convertNumberPrice, convertTextPrice, handleBlurDate, hasAuthority, matchTemplateWord } from "../../utils/functionUtils";
import { REF_TAB, TYPE_POSITION } from "../constants/profileConsts";
import { EmployeeProfile } from "../models/profileModels";
import "../profile.scss";
import { getBacLuongByChucDanh } from "../services/dialogServices";
import { getContractByEmployee, getContractsByEmployee } from "../services/profileServices";
import { convertDataContract } from "../utils/profileUtils";
import { ContractColumn } from "./columns/ContractColumn";
import { ContractDialog } from "./dialogs/ContractDialog";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { checkInvalidDate } from "../../utils/validationSchema";
import { OFFICER_CODE } from "../constants/dialogChildConsts";
import UploadFile from "../../components/file-upload/UploadFile";
import { IFile } from "../models/dialogModels";
import AppContext from "../../../AppContext";
import { ExportWord, ITemplateExportWord } from "../../components/exportWord";
import { GroupButton } from "../../components/GroupButton";
import { searchAllSimpleValue, searchListWorkUnit, searchNguoiDaiDienKy, searchPhongBanByDonVi } from "../../services/services";
import { AddNewRepresentative } from "../../components/dialogs";
import { IPeopleDecision } from "../../models/models";
import { PERMISSION_ABILITY, PERMISSIONS } from "../../../constants";

type IProps = {
  isView: boolean;
  formikRef: any;
  activeTab: string;
  employeeProfiles?: EmployeeProfile;
  handleToggleIsView: () => void;
};

export const ContractInformation: React.FC<IProps> = ({ isView, formikRef, activeTab, employeeProfiles }) => {
  const { lang, intl } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [isHopDongDaiHan, setIsHopDongDaiHan] = useState<boolean>(false);
  const [isCollaborators, setIsCollaborators] = useState<boolean>(false);
  const [isLumpSum, setIsLumpSum] = useState<boolean>(false);
  const [shouldOpenContractDialog, setShouldOpenContractDialog] = useState<boolean>(false);
  const [contract, setContract] = useState<IContractInfo>({} as IContractInfo);
  const [initContract, setInitContract] = useState<IContractInfo>({} as IContractInfo);
  const [contractList, setContractList] = useState<IContractInfo[]>([]);
  const [isShowDialogWord, setIsShowDialogWord] = useState<boolean>(false);
  const [templateWord, setTemplateWord] = useState<ITemplateExportWord[] | null>(null);
  const [isShowDialogAddRepresentative, setIsShowDialogAddRepresentative] = useState<boolean>(false);
  const [peopleDecisions, setPeopleDecisions] = useState<IPeopleDecision[]>([]);

  const getContractInfoByEmployee = async () => {
    if (employeeProfiles?.id) {
      try {
        const { data } = await getContractByEmployee(employeeProfiles.id);
        if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
          const checkHopDongDaiHan = CODE_CONTRACT_LONG_TERM.some(value => value === Number(data?.data?.loaiHopDong?.code))
          setIsHopDongDaiHan(checkHopDongDaiHan)
        } else {
          toast.warning(data?.message);
        }
        return data?.data;
      } catch (error) {
        console.error(error);
        toast.error(lang("GENERAL.ERROR"));
      }
    }
  };

  const getPeopleDecision = useCallback(async () => {
    try {
      const { data } = await searchNguoiDaiDienKy();
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setPeopleDecisions(data?.data?.content || []);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }, [lang]);

  useEffect(() => {
    getPeopleDecision();
  }, [getPeopleDecision]);

  useEffect(() => {
    if (!employeeProfiles?.id) return;
    const initContract = {
      employee: employeeProfiles,
      employeeCode: employeeProfiles?.code,
      phongBan: {
        id: employeeProfiles?.phongBanId,
        name: employeeProfiles?.phongBanText
      },
      chucVu: employeeProfiles?.chucVu,
      chucDanh: employeeProfiles?.viTriCongViec,
      loaiCanBo: employeeProfiles?.loaiCanBo,
      tenNguoiLaoDong: employeeProfiles?.name,
      employeeId: employeeProfiles?.id,
    };
    setContract(initContract as IContractInfo);
    setInitContract(initContract as IContractInfo);
  }, [employeeProfiles]);

  const validationSchema = Yup.object().shape({
    donViKyHopDong: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    soHopDong: Yup.string().when(VARIABLE_STRING.LOAI_HOP_DONG, {
      is: (loaiHopDong: any) => loaiHopDong?.code === CODE_HOP_DONG.THU_VIEC,
      then: Yup.string().nullable(),
      otherwise: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable()
    }),
    phongBan: Yup.object().shape({}).required(lang("VALIDATION.REQUIRE")).nullable(),
    chucVu: Yup.object().shape({}).required(lang("VALIDATION.REQUIRE")).nullable(),
    chucDanh: isCollaborators ? Yup.object().nullable() : Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    luongCoBan: (!isCollaborators && !isLumpSum) ? Yup.string().nullable() : Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    bacLuongOption: (isCollaborators || isLumpSum) ? Yup.object().nullable() : Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    heSoLuong: (isCollaborators || isLumpSum) ? Yup.string().nullable() : Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    ngayCoHieuLuc: Yup.date()
      .concat(checkInvalidDate(intl))
      .required(lang("VALIDATION.REQUIRE"))
      .when("ngayHetHan", {
        is: (ngayHetHan: Date | null) => ngayHetHan,
        then: Yup.date()
          .max(
            Yup.ref("ngayHetHan"),
            lang("VALIDATION.MAXDATE") +
            lang("CONTRACT.EXPIRATIONDATE")
          )
          .nullable()
      })
      .nullable(),
    ngayHetHan: Yup.date()
      .concat(checkInvalidDate(intl))
      .when(VARIABLE_STRING.LOAI_HOP_DONG, {
        is: (loaiHopDong: any) =>
          loaiHopDong?.code === CODE_HOP_DONG.VIEN_CHUC_DAI_HAN || loaiHopDong?.code === CODE_HOP_DONG.HOP_DONG_DAI_HAN,
        then: Yup.date().nullable(),
        otherwise: Yup.date()
          .required(lang("VALIDATION.REQUIRE"))
          .concat(checkInvalidDate(intl))
          .min(Yup.ref(VARIABLE_STRING.NGAY_CO_HIEU_LUC), lang("VALIDATE.EXPIRATION_DATE_AFTER_EFFECTIVE_DATE"))
          .nullable()
      }),
    ngayKyHopDong: Yup.date()
      .concat(checkInvalidDate(intl))
      .when("ngayHetHan", {
        is: (ngayHetHan: Date | null) => ngayHetHan && ngayHetHan < (new Date()),
        then: Yup.date()
          .max(
            Yup.ref("ngayHetHan"),
            lang("VALIDATION.SIGN_DAY.EQUAL_OF_LESS")
          )
          .nullable()
      })
      .max(new Date(), lang("VALIDATION.INVALID_SIGN_DATE"))
      .nullable(),
    loaiHopDong: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    trangThaiHopDong: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    nguoiDaiDienCtyKy: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable()
  });

  const handleSearch = async () => {
    await Promise.all([fetchContract(), updateDataContract()]);
  }

  const handleFormSubmit = async (values: IContractInfo) => {
    try {
      setPageLoading(true);
      const dataContract = convertDataContactDto(values);
      const { data } = formik.values?.id
        ? await updateContract(formik.values?.id, dataContract)
        : await addNewContract(dataContract);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(formik.values?.id ? lang("TOAST.EDIT.CONTRACT.SUCCESS") : lang("TOAST.ADD.CONTRACT.SUCCESS"));
        await handleSearch();
      } else toast.warning(`${data?.message}`);
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {} as IContractInfo,
    validationSchema,
    onSubmit: handleFormSubmit
  });

  const handleChangeSelect = (name: string, value: any) => {
    switch (name) {
      case VARIABLE_STRING.LOAI_HOP_DONG:
        const { VIEN_CHUC_DAI_HAN, HOP_DONG_DAI_HAN, ONE_YEAR } = CODE_HOP_DONG;
        switch (value?.code) {
          case VIEN_CHUC_DAI_HAN:
          case HOP_DONG_DAI_HAN:
            formik.setValues({
              ...formik.values,
              [name]: value,
              ngayHetHan: null
            });
            setIsHopDongDaiHan(true);
            break;
          case ONE_YEAR:
            setIsHopDongDaiHan(true);
            formik.setValues({
              ...formik.values,
              [name]: value,
              ngayHetHan: addMoreYear(formik.values?.ngayCoHieuLuc, 1)
            });
            break;
          default:
            formik.setFieldValue(VARIABLE_STRING.LOAI_HOP_DONG, value);
            setIsHopDongDaiHan(false);
            break;
        }
        break;
      case VARIABLE_STRING.NGUOI_DAI_DIEN_KY:
        formik.setValues({
          ...formik.values,
          nguoiDaiDienCtyChucDanhId: value?.chucVuId,
          nguoiDaiDienCtyChucDanhText: value?.chucVuText,
          nguoiDaiDienCtyKyId: value?.id,
          nguoiDaiDienCtyKyText: value?.name
        });
        formik.setFieldValue(name, value);
        break;
      case VARIABLE_STRING.CHUC_DANH:
        formik.setFieldValue(name, value);
        formik.setFieldValue(VARIABLE_STRING.BAC_LUONG_OPTION, null);
        formik.setFieldValue(VARIABLE_STRING.HE_SO_LUONG, undefined);
        break;
      case VARIABLE_STRING.BAC_LUONG_OPTION:
        formik.setFieldValue(name, value);
        formik.setFieldTouched(name, false, false);
        formik.setFieldValue(VARIABLE_STRING.HE_SO_LUONG, value?.heSoLuong || "");
        break;
      default:
        formik.setFieldValue(name, value);
        break;
    }
  };

  const handleChangeNgayCoHieuLuc = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    formik.setValues({
      ...formik.values,
      [name]: value,
      ngayHetHan:
        formik.values?.loaiHopDong?.code === CODE_HOP_DONG.ONE_YEAR ? addMoreYear(value, 1) : formik.values?.ngayHetHan
    });
  };

  const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    switch (name) {
      case VARIABLE_STRING.VIEN_CHUC:
        formik.setFieldValue(name, checked);
        formik.setFieldValue(VARIABLE_STRING.HOP_DONG_LAO_DONG, false);
        formik.setFieldValue(VARIABLE_STRING.LOAI_HOP_DONG, null);
        break;
      case VARIABLE_STRING.HOP_DONG_LAO_DONG:
        formik.setFieldValue(name, checked);
        formik.setFieldValue(VARIABLE_STRING.VIEN_CHUC, false);
        formik.setFieldValue(VARIABLE_STRING.LOAI_HOP_DONG, null);
        break;
      default:
        formik.setFieldValue(name, checked);
        break;
    }
  };

  useEffect(() => {
    const lumpSumContract = formik.values?.loaiHopDong?.code === CODE_HOP_DONG.HOP_DONG_KHOAN;
    setIsLumpSum(lumpSumContract);
    lumpSumContract && formik.setValues({ ...formik.values, bacLuongOption: null, heSoLuong: "" });
  }, [formik.values?.loaiHopDong?.code]);

  useEffect(() => {
    setTemplateWord(matchTemplateWord(Number(formik.values?.loaiHopDong?.code), contractWordExportList));
  }, [formik.values])

  useEffect(() => {
    if (formikRef) {
      formikRef.current = formik;
    }
  }, [formik, formikRef]);

  useEffect(() => {
    if (!employeeProfiles?.id || activeTab !== REF_TAB.HOP_DONG_LAO_DONG) return;
    handleSearch();
  }, [employeeProfiles, activeTab]);
  
  useEffect(() => {
    formik.values?.loaiCanBo && switchContractStatus(employeeProfiles?.loaiCanBo, formik);
  }, [formik.values?.loaiCanBo]);

  useEffect(() => {
    if (!employeeProfiles?.id || activeTab !== REF_TAB.HOP_DONG_LAO_DONG) return;
    let officerType = employeeProfiles?.loaiCanBo;
    const conditionalCodes = [OFFICER_CODE.CONG_TAC_VIEN];
    if (officerType?.code) {
      let isCooperator = conditionalCodes.includes(Number(officerType?.code));
      setIsCollaborators(isCooperator);
      if(isCooperator) {
        if(!employeeProfiles?.chucVu) {
          const getPosition = async () => {
            try {
              const { data } = await searchAllSimpleValue({ type: TYPE_CATEGORY.chucDanh });
              if(data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
                const positionCollaborator = data.data?.content?.find((position: any) => position?.mappingName === TYPE_POSITION.CONG_TAC_VIEN);
                formik.setFieldValue("chucVu", positionCollaborator);
              } else {
                toast.warning(data?.message || lang("GENERAL.ERROR"));
              }
            } catch (error) {
              toast.error(lang("GENERAL.ERROR"));
            }
          }
          getPosition();
        }
      }
    }
  }, [employeeProfiles?.loaiCanBo?.code, activeTab])

  const fetchContract = async () => {
    let values = {
      employee: employeeProfiles,
      employeeCode: employeeProfiles?.code,
      viTriCongViecText: employeeProfiles?.chucVuText,
      loaiCanBo: employeeProfiles?.loaiCanBo,
      tenNguoiLaoDong: employeeProfiles?.name,
      employeeId: employeeProfiles?.id,
      ...formik.values,
      phongBan: employeeProfiles?.phongBan,
      chucVu: employeeProfiles?.chucVu,
      chucDanh: employeeProfiles?.viTriCongViec,
      donViKyHopDong: null,
      soHopDong: "",
      trangThaiHopDong: null,
      ngayCoHieuLuc: null,
      ngayHetHan: null,
      ngayKyHopDong: "",
      loaiHopDong: null,
      bacLuongOption: null,
      heSoLuong: "",
      nguoiDaiDienCtyKy: null,
      luongCoBan: ""
    };
    let response = await getContractInfoByEmployee();
    if (response?.id) {
      let contractInfoConverted = { ...values, ...convertDataContract(response) };
      formik.setValues(contractInfoConverted);
    } else {
      formik.setValues(values);
    }
  };

  const handleOpenDialog = (
    row: any,
    setShouldOpenDialog: (value: React.SetStateAction<boolean>) => void,
    setData: (value: any) => void
  ): void => {
    let id = row?.id;
    if (employeeProfiles?.code) {
      setShouldOpenDialog(true);
      setData(id ? convertDataUI(row) : row);
    } else {
      toast.warning(lang("MESSAGE.BASIC.FIRST"));
    }
  };

  const updateDataContract = async () => {
    try {
      setPageLoading(true);
      let { data } = await getContractsByEmployee(employeeProfiles?.id ?? "");
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setContractList(data?.data);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      console.error(error);
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleCloseContractDialog = (): void => {
    setShouldOpenContractDialog(false);
    setContract(initContract as IContractInfo);
  };

  const handleCloseAndSearch = () => {
    handleSearch();
    handleCloseContractDialog();
  }

  const handleChangeMoney = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, convertTextPrice(value));
  };

  const handleFile = (data: IFile) => {
    formik.setFieldValue("fileId", data.id);
    formik.setFieldValue("fileName", data.name);
  }

  const handleCloseAndSearchRepresentative = () => {
    getPeopleDecision();
    setIsShowDialogAddRepresentative(false);
  }

  const handleDeleteContract = async (ids: string[]) => {
    try {
      const { data } = await deleteContract(ids);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("GENERAL.DELETE_SUCCESS"));
        await handleSearch();
        return true;
      } else toast.warning(data?.message);
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }

  return (
    <>
      <div className="basic-info-wrapper h-full">
        <Form
          onSubmit={formik.handleSubmit}
          className="form-info contract flex-column z-index-2 relative"
        >
          <>
            <div className="block-content">
              <div className="flex flex-middle flex-between spaces pt-16">
                <span className="text-header">{lang("CONTRACT.INFO")}</span>
                {(formik.values?.id && templateWord) && (
                  <GroupButton handleEvent={() => setIsShowDialogWord(true)}>
                    {lang("GENERAL.EXPORT_WORD")}
                  </GroupButton>
                )}
              </div>
              <Row className="g-4">
                <Col md={6} lg={6} xl={4} xxl={2}>
                  <Autocomplete
                    isRequired
                    lable={lang("PROFILE.NAME")}
                    options={[]}
                    name="employee"
                    value={formik.values?.employee || null}
                    isReadOnly={true}
                    errors={formik.errors.employee}
                    touched={formik.touched.employee}
                  />
                </Col>
                <Col md={6} lg={6} xl={3} xxl={2}>
                  <TextValidator
                    lable={lang("INPUT.CODE_NEW")}
                    name="maNhanVien"
                    isRequired
                    value={employeeProfiles?.maNhanVien || ""}
                    type="text"
                    onChange={formik.handleChange}
                    readOnly={true}
                  />
                </Col>
                <Col xs={12} md={6} lg={6} xl={5} xxl={3}>
                  <Autocomplete
                    isRequired
                    lable={lang("INPUT.DEPARTMENTS")}
                    isReadOnly={isView}
                    options={[]}
                    value={formik.values?.phongBan || null}
                    name="phongBan"
                    onChange={(selectedOption) => handleChangeSelect("phongBan", selectedOption)}
                    urlData="data.data"
                    searchFunction={searchPhongBanByDonVi}
                    searchObject={{ id: employeeProfiles?.donViCongTacId }}
                    touched={formik.touched?.phongBan}
                    errors={formik.errors?.phongBan}
                    dependencies={[employeeProfiles?.donViCongTacId]}
                  />
                </Col>
                <Col xs={12} md={6} lg={6} xl={4} xxl={2}>
                  <Autocomplete
                    isRequired
                    lable={lang("GENERAL.EMPLOYEE.POSITION")}
                    name="chucVu"
                    value={formik.values?.chucVu || null}
                    searchFunction={searchAllSimpleValue}
                    searchObject={{ type: TYPE_CATEGORY.chucDanh }}
                    isReadOnly={isView || isCollaborators}
                    onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.CHUC_VU, selectedOption)}
                    errors={formik.errors?.chucVu}
                    touched={formik.touched?.chucVu}
                    options={[]}
                    getOptionLabel={(option) => option?.value}
                  />
                </Col>
                <Col xs={12} lg={7} xl={4} xxl={3}>
                  <Autocomplete
                    lable={lang("GENERAL.EMPLOYEE.TITLE")}
                    name="chucDanh"
                    isRequired={!isCollaborators}
                    value={formik.values?.chucDanh || null}
                    searchFunction={searchAllSimpleValue}
                    searchObject={{ type: TYPE_CATEGORY.viTriCongViec }}
                    isReadOnly={isView || isCollaborators}
                    onChange={(selectedOption) => handleChangeSelect("chucDanh", selectedOption)}
                    options={[]}
                    getOptionLabel={(option) => option?.value}
                    errors={formik.errors?.chucDanh}
                    touched={formik.touched?.chucDanh}
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md={5} lg={4} xl={3} xxl={2}>
                  <Form.Check
                    label={lang("CONTRACT.IS_JOBHOLDER")}
                    checked={formik?.values?.vienChuc}
                    onChange={handleChangeCheckBox}
                    name="vienChuc"
                    className="py-2"
                    readOnly={true}
                    type="radio"
                    disabled={true}
                  />
                </Col>
                <Col md={5} lg={4} xl={3} xxl={2}>
                  <Form.Check
                    label={lang("CONTARCT.IS_LABOR_CONTRACT")}
                    checked={formik?.values?.hopDongLaoDong}
                    onChange={handleChangeCheckBox}
                    name="hopDongLaoDong"
                    className="py-2"
                    readOnly={true}
                    type="radio"
                    disabled={true}
                  />
                </Col>
              </Row>
            </div>
            <div className="block-content">
              <span className="text-header">{lang("CONTRACT.INFO_DETAIL")}</span>
              <Row className="g-4">
                <Col md={6} lg={6} xl={4} xxl={3}>
                  <Autocomplete
                    isRequired
                    lable={lang("CONTRACT.SIGNINGUNIT")}
                    options={[]}
                    value={formik.values?.donViKyHopDong || null}
                    name="donViKyHopDong"
                    onChange={(value) => handleChangeSelect(VARIABLE_STRING.DON_VI_KY_HOP_DONG, value)}
                    searchFunction={searchListWorkUnit}
                    touched={formik.touched?.donViKyHopDong}
                    errors={formik.errors?.donViKyHopDong}
                    isReadOnly={isView}
                  />
                </Col>
                <Col md={6} lg={6} xl={4} xxl={3}>
                  <Autocomplete
                    isRequired
                    lable={lang("GENERAL.EMPLOYEE.CONTRACT_TYPE")}
                    options={formik.values?.vienChuc ? contractTypeBienChe : contractTypeHopDong}
                    onChange={(value) => handleChangeSelect(VARIABLE_STRING.LOAI_HOP_DONG, value)}
                    name="loaiHopDong"
                    value={formik.values?.loaiHopDong || null}
                    isReadOnly={isView}
                    touched={formik.touched?.loaiHopDong}
                    errors={formik.errors?.loaiHopDong}
                    dependencies={[formik.values?.vienChuc, formik.values?.hopDongLaoDong]}
                  />
                </Col>
                <Col md={6} lg={6} xl={4} xxl={2}>
                  <TextValidator
                    isRequired={formik.values.loaiHopDong?.code !== CODE_HOP_DONG.THU_VIEC}
                    lable={lang("CONTRACT.NUMBER")}
                    name="soHopDong"
                    value={formik.values?.soHopDong || ""}
                    type="text"
                    readOnly={isView}
                    onChange={formik.handleChange}
                    touched={formik.touched?.soHopDong}
                    errors={formik.errors?.soHopDong}
                  />
                </Col>
                <Col md={6} lg={6} xl={4} xxl={2}>
                  <TextValidator
                    lable={lang("CONTRACT.NAME")}
                    name="tenHopDong"
                    value={formik.values?.tenHopDong || ""}
                    type="text"
                    readOnly={isView}
                    onChange={formik.handleChange}
                  />
                </Col>
                <Col md={6} lg={6} xl={4} xxl={2}>
                  <Autocomplete
                    lable={lang("CONTRACT.WORK_SHIFT")}
                    options={workShifts}
                    onChange={(value) => handleChangeSelect(VARIABLE_STRING.CA_LAM_VIEC, value)}
                    name="caLamViec"
                    value={formik.values?.caLamViec || null}
                    isReadOnly={isView}
                  />
                </Col>
                <Col md={6} lg={6} xl={4} xxl={2}>
                  <TextValidator
                    lable={lang("CONTRACT.EFFECTIVEDATE")}
                    name="ngayCoHieuLuc"
                    value={formik.values?.ngayCoHieuLuc || ""}
                    type="date"
                    isRequired
                    readOnly={isView}
                    onChange={handleChangeNgayCoHieuLuc}
                    onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.ngayCoHieuLuc, "ngayCoHieuLuc")}
                    errors={formik.errors?.ngayCoHieuLuc}
                    touched={formik.touched?.ngayCoHieuLuc}
                  />
                </Col>
                <Col md={6} lg={6} xl={4} xxl={2}>
                  <TextValidator
                    lable={lang("CONTRACT.EXPIRATIONDATE")}
                    name="ngayHetHan"
                    value={formik.values?.ngayHetHan || ""}
                    type="date"
                    readOnly={isView || isHopDongDaiHan}
                    isRequired={!isHopDongDaiHan}
                    onChange={formik.handleChange}
                    onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.ngayHetHan, "ngayHetHan")}
                    errors={formik.errors?.ngayHetHan}
                    touched={formik.touched?.ngayHetHan}
                  />
                </Col>
                <Col md={6} lg={6} xl={4} xxl={2}>
                  <Autocomplete
                    lable={lang("CONTRACT.SALARY.LEVEL")}
                    name="bacLuongOption"
                    isRequired={!isCollaborators && !isLumpSum}
                    isReadOnly={isView || isCollaborators || isLumpSum}
                    value={formik.values?.bacLuongOption || null}
                    searchFunction={getBacLuongByChucDanh}
                    searchObject={formik.values?.chucDanh?.id}
                    sort={(data: any) => data.sort((a: any, b: any) => a.bacLuong - b.bacLuong)}
                    urlData="data.data"
                    onChange={(selectedOption) => handleChangeSelect("bacLuongOption", selectedOption)}
                    options={[]}
                    getOptionLabel={(option) => option?.bacLuong}
                    dependencies={[formik.values?.chucDanh?.id]}
                    errors={formik.errors?.bacLuongOption}
                    touched={formik.touched?.bacLuongOption}
                  />
                </Col>
                <Col md={6} lg={6} xl={4} xxl={2}>
                  <TextValidator
                    lable={lang("CONTRACT.SALARY.COEFFICIENT")}
                    name="heSoLuong"
                    isRequired={!isCollaborators && !isLumpSum}
                    value={formik.values?.heSoLuong || ""}
                    type="number"
                    readOnly={true}
                    onChange={formik.handleChange}
                    errors={formik.errors?.heSoLuong}
                    touched={formik.touched?.heSoLuong}
                  />
                </Col>
                <Col md={6} lg={6} xl={4} xxl={2}>
                  <TextValidator
                    lable={lang("CONTRACT.BASESALARY")}
                    name="luongCoBan"
                    value={convertNumberPrice(formik.values?.luongCoBan)}
                    type="text"
                    isRequired={isCollaborators || isLumpSum}
                    readOnly={isView}
                    onChange={handleChangeMoney}
                    errors={formik.errors?.luongCoBan}
                    touched={formik.touched?.luongCoBan}
                  />
                </Col>
                <Col md={6} lg={6} xl={4} xxl={2}>
                  <TextValidator
                    lable={lang("CONTRACT.SALARY_PERCENT")}
                    name="tyLeHuongLuong"
                    value={formik.values?.tyLeHuongLuong || ""}
                    type="number"
                    readOnly={isView}
                    onChange={formik.handleChange}
                  />
                </Col>
                <Col md={6} lg={6} xl={4} xxl={2}>
                  <TextValidator
                    lable={lang("CONTRACT.INSURANCECONTRIBUTION")}
                    name="luongDongBaoHiem"
                    value={convertNumberPrice(formik.values?.luongDongBaoHiem)}
                    type="text"
                    readOnly={isView}
                    onChange={handleChangeMoney}
                  />
                </Col>
                <Col md={6} lg={6} xl={4} xxl={2}>
                  <Autocomplete
                    isRequired
                    lable={lang("CONTRACT.STATUS")}
                    options={contractStatus}
                    onChange={(value) => handleChangeSelect(VARIABLE_STRING.TRANG_THAI_HOP_DONG, value)}
                    name="trangThaiHopDong"
                    value={formik.values?.trangThaiHopDong || null}
                    isReadOnly={isView}
                    errors={formik.errors?.trangThaiHopDong}
                    touched={formik.touched?.trangThaiHopDong}
                  />
                </Col>
                <Col xl={8} xxl={6}>
                  <TextValidator
                    lable={lang("CONTRACT.NOTE")}
                    name="ghiChu"
                    value={formik.values?.ghiChu}
                    type="text"
                    readOnly={isView}
                    onChange={formik.handleChange}
                  />
                </Col>
                <Col md={6} lg={6} xl={4} xxl={2}>
                  <UploadFile 
                    label="CONTRACT.ATTACHMENTS"
                    setValue={handleFile}
                    isReadOnly={isView}
                    fileValue={{
                      id: formik.values.fileId || "",
                      name: formik.values.fileName || ""
                    }}
                  />
                </Col>
              </Row>
            </div>
            <div className="block-content">
              <span className="text-header">{lang("CONTRACT.SALARY_SIGN")}</span>
              <Row className="g-4">
                <Col md={6} lg={6} xl={4} xxl={2}>
                  <Form.Check
                    label={lang("CONTRACT.PAPER_AUTHORITY")}
                    checked={formik.values?.giayUyQuyen}
                    onChange={handleChangeCheckBox}
                    className="custom-form-check"
                    readOnly={isView}
                    name="giayUyQuyen"
                    disabled={isView}
                  />
                </Col>
                <Col md={6} lg={6} xl={4} xxl={3}>
                  <Autocomplete
                    lable={lang("CONTRACT.SIGNEDCOMPANYREPRESENTATIVE")}
                    options={peopleDecisions}
                    value={formik.values?.nguoiDaiDienCtyKy || null}
                    isAddNew
                    onAddNew={() => setIsShowDialogAddRepresentative(true)}
                    isRequired
                    name="nguoiDaiDienCtyKy"
                    onChange={(value) => handleChangeSelect(VARIABLE_STRING.NGUOI_DAI_DIEN_KY, value)}
                    isReadOnly={isView}
                    errors={formik.errors?.nguoiDaiDienCtyKy}
                    touched={formik.touched?.nguoiDaiDienCtyKy}
                  />
                </Col>
                <Col md={6} lg={6} xl={4} xxl={3}>
                  <TextValidator
                    lable={lang("CONTRACT.REPRESENTATIVETITLE")}
                    name="nguoiDaiDienCtyChucDanh"
                    value={formik.values?.nguoiDaiDienCtyChucDanhText || ""}
                    type="text"
                    readOnly={true}
                  />
                </Col>
                <Col md={6} lg={6} xl={4} xxl={2}>
                  <TextValidator
                    lable={lang("ANNEX.SIGNINGDATE")}
                    name="ngayKyHopDong"
                    value={formik.values?.ngayKyHopDong || ""}
                    type="date"
                    readOnly={isView}
                    onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.ngayKyHopDong, "ngayKyHopDong")}
                    onChange={formik.handleChange}
                    errors={formik.errors?.ngayKyHopDong}
                    touched={formik.touched?.ngayKyHopDong}
                  />
                </Col>
                <Col md={6} lg={6} xl={4} xxl={2}>
                  <Autocomplete
                    menuPlacement="top"
                    lable={lang("CONTRACT.SIGNINGSTATUS")}
                    options={signinStatus}
                    value={formik.values?.trangThaiKy || null}
                    name="trangThaiKy"
                    onChange={(value) => handleChangeSelect(VARIABLE_STRING.TRANG_THAI_KY, value)}
                    isReadOnly={isView}
                  />
                </Col>
              </Row>
            </div>
          </>
        </Form>
        <div className="form-info">
          <div className="block-content">
            <TableCustom
              id="contract"
              title={lang("INFO.CONTRACT.LIST")}
              data={contractList}
              columns={ContractColumn()}
              isActionTableTab
              dependencies={activeTab}
              buttonAdd={!isView}
              notDelete={!hasAuthority(PERMISSIONS.HOP_DONG, PERMISSION_ABILITY.DELETE)}
              handleDelete={handleDeleteContract}
              noToolbar={true}
              justFilter={true}
              type={TYPE.MULTILINE}
              fixedColumnsCount={4}
              handleOpenDialog={(row) => handleOpenDialog(contract, setShouldOpenContractDialog, setContract)}
              handleDoubleClick={(row) => handleOpenDialog(row, setShouldOpenContractDialog, setContract)}
              noPagination
            />
          </div>
        </div>
        {shouldOpenContractDialog && (
          <ContractDialog
            contractEdit={contract}
            handleCloseContractDialog={handleCloseContractDialog}
            isView={isView}
            shouldOpenContractDialog={shouldOpenContractDialog}
            handleCloseAndSearch={handleCloseAndSearch}
            employeeInfo={employeeProfiles}
          />
        )}
        
        {isShowDialogWord && (
          <ExportWord
            open={isShowDialogWord}
            handleClose={() => setIsShowDialogWord(false)}
            templateList={templateWord || []}
            getObj={{
              employeeId: formik.values?.employeeId,
              hopDongId: formik.values?.id,
              hopDongType: formik.values?.loaiHopDong?.code,
              type: DATA_WORD_TYPES.CONSTRACT
            }}
            customFileName={(data, nameBase) => `${data.fullName} (${nameBase})`}
          />
        )}
        {isShowDialogAddRepresentative && (
          <AddNewRepresentative
            handleClose={() => setIsShowDialogAddRepresentative(false)}
            handleCloseAndSearch={handleCloseAndSearchRepresentative}
          />
        )}
      </div>
    </>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import { FC, useContext, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import AppContext from "../../../AppContext";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { GroupButton } from "../../components/GroupButton";
import Autocomplete from "../../components/input/autocomplete";
import TextValidator from "../../components/input/text-validator";
import { handleBlurDate, hasAuthority } from "../../utils/functionUtils";
import { INIT_RECEIVING_CITIZENS, LIST_STATUS, LIST_STATUS_CODE, PHAN_LOAI_DON, PHUONG_PHAP_GIAI_QUYET } from "../constants/receivingCitizensConsts";
import { IReceivingCitizens } from "../models/receivingCitizensModels";
import TextArea from "../../components/input/TextArea";
import { checkInvalidDate } from "../../utils/validationSchema";
import { addNewReceivingCitizens, updateReceivingCitizens } from "../services/receivingCitizensServices";
import { RESPONSE_STATUS_CODE } from "../../constants/moduleConsts";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../../constants";

interface IProps {
  receivingCitizens: IReceivingCitizens;
  isView: boolean;
  setIsView: (value: boolean) => void;
  handleCloseDialog: () => void;
  handleCloseAndSearch: () => void;
  handleOpenUpdateDialog: () => void;
  handleCloseUpdateDialog: () => void;
}
const ReceivingCitizensForm: FC<IProps> = (props) => {
  const { lang, intl } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const {
    receivingCitizens,
    handleCloseDialog,
    isView,
    handleCloseUpdateDialog,
    setIsView,
    handleCloseAndSearch
  } = props;

  const validationSchema = Yup.object().shape({
    ngayTiep: Yup.date()
      .concat(checkInvalidDate(intl))
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
  });

  const handleSubmmit = async (values: IReceivingCitizens) => {
    try {
      setPageLoading(true)
      const { data } = receivingCitizens?.id
        ? await updateReceivingCitizens(receivingCitizens.id, values)
        : await addNewReceivingCitizens(values);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(
          receivingCitizens.id
            ? lang("TOAST.EDIT.SUCCESS")
            : lang("TOAST.CREATE.SUCCESS")
        );
        handleCloseAndSearch();
      } else {
        toast.warning(data?.message);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false)
    }
  };

  const formik = useFormik({
    initialValues: INIT_RECEIVING_CITIZENS,
    validationSchema,
    onSubmit: handleSubmmit
  })

  useEffect(() => {
    formik.setValues(receivingCitizens?.id ? receivingCitizens : INIT_RECEIVING_CITIZENS)
  }, [receivingCitizens])

  const handleChangeSelect = (name: string, value: any) => {
    formik.setFieldValue(name, value);
  };

  return (
    <>
      <div className="h-full">
        <div className="header-form-action">
          <GroupButton type="btn-back" handleClose={handleCloseDialog} />
          <div className="flex gap-4">
            {(!isView && receivingCitizens?.status?.code === LIST_STATUS_CODE.UNFINISH && receivingCitizens?.id) && (
              <GroupButton
                type="btn-cancel"
                handleCloseUpdateDialog={handleCloseUpdateDialog}
              />
            )}
            {isView && receivingCitizens?.id && receivingCitizens?.status?.code !== LIST_STATUS_CODE.FINISHED 
              && hasAuthority(PERMISSIONS.TIEP_CONG_DAN, PERMISSION_ABILITY.UPDATE) && (
              <GroupButton
                type="btn-edit"
                handleSaveEdit={() => setIsView(false)}
              />
            )}
            {(!isView && (!receivingCitizens?.status?.code || receivingCitizens?.status?.code === LIST_STATUS_CODE.UNFINISH || !receivingCitizens?.id || receivingCitizens?.status?.code === LIST_STATUS_CODE.PROCESSING)) && (
              <GroupButton
                type="btn-save"
                handleSubmit={formik.handleSubmit}
                value={formik.values}
              />
            )}
          </div>
        </div>
        <Form onSubmit={formik.handleSubmit} className="form-content-scroll">
          <div className="sub-title-form">{receivingCitizens?.id ? lang("RECEIVING.UPDATE") : lang("RECEIVING.ADD")}</div>
          <Row>
            <Col md={2} lg={2} xl={2} xxl={2} className="spaces pb-6">
              <TextValidator
                lable={lang("RECEIVING.DAY_RECEPTION")}
                name="ngayTiep"
                isRequired
                value={formik.values?.ngayTiep || ""}
                type="date"
                readOnly={isView}
                onChange={formik.handleChange}
                onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.ngayTiep, "ngayTiep")}
                errors={formik.errors?.ngayTiep}
                touched={formik.touched?.ngayTiep}
              />
            </Col>
            <Col md={3} lg={3} xl={3} xxl={3} className="spaces pb-6">
              <TextValidator
                lable={lang("PROFILE.NAME")}
                name="name"
                value={formik.values?.name}
                type="text"
                readOnly={isView}
                onChange={formik.handleChange}
                errors={formik.errors?.name}
                touched={formik.touched?.name}
              />
            </Col>
            <Col md={2} lg={2} xl={2} xxl={2} className="spaces pb-6">
              <TextValidator
                lable={lang("INPUT.IDENTIFICATION")}
                name="cccd"
                value={formik.values?.cccd}
                type="text"
                readOnly={isView}
                onChange={formik.handleChange}
                errors={formik.errors?.cccd}
                touched={formik.touched?.cccd}
              />
            </Col>
            <Col md={5} lg={5} xl={5} xxl={5} className="spaces pb-6">
              <TextValidator
                lable={lang("GENERAL.ADDRESS")}
                name="address"
                value={formik.values?.address}
                type="text"
                readOnly={isView}
                onChange={formik.handleChange}
                errors={formik.errors?.address}
                touched={formik.touched?.address}
              />
            </Col>
            <Col md={3} lg={3} xl={3} xxl={3} className="spaces pb-6">
              <TextValidator
                lable={lang("REPORT.TITLE")}
                name="baoCao"
                value={formik.values?.baoCao}
                type="text"
                readOnly={isView}
                onChange={formik.handleChange}
                errors={formik.errors?.baoCao}
                touched={formik.touched?.baoCao}
              />
            </Col>
            <Col md={3} lg={3} xl={3} xxl={3} className="spaces pb-6">
              <Autocomplete
                lable={lang("RECEIVING.SOLUTION")}
                isReadOnly={isView}
                options={PHUONG_PHAP_GIAI_QUYET}
                value={formik.values?.phuongPhapGiaiQuyet}
                name="phuongPhapGiaiQuyet"
                onChange={(selectedOption) => handleChangeSelect("phuongPhapGiaiQuyet", selectedOption)}
                errors={formik.errors?.phuongPhapGiaiQuyet}
                touched={formik.touched?.phuongPhapGiaiQuyet}
              />
            </Col>
            <Col md={3} lg={3} xl={3} xxl={3} className="spaces pb-6">
              <Autocomplete
                lable={lang("RECEIVING.SINGLE_CLASSIFICATION")}
                isReadOnly={isView}
                options={PHAN_LOAI_DON}
                value={formik.values?.phanLoaiDon}
                name="phanLoaiDon"
                onChange={(selectedOption) => handleChangeSelect("phanLoaiDon", selectedOption)}
                errors={formik.errors?.phanLoaiDon}
                touched={formik.touched?.phanLoaiDon}
              />
            </Col>
            <Col md={3} lg={3} xl={3} xxl={3} className="spaces pb-6">
              <Autocomplete
                lable={lang("GENERAL.STATUS")}
                isReadOnly={isView}
                options={LIST_STATUS}
                value={formik.values?.status}
                name="status"
                onChange={(selectedOption) => handleChangeSelect("status", selectedOption)}
                errors={formik.errors?.status}
                touched={formik.touched?.status}
              />
            </Col>
            <Col md={12} lg={12} xl={6} xxl={4} className="spaces pb-6">
              <TextArea
                className="w-100"
                lable={lang("RECEIVING.WORK_CONTENT")}
                name="noiDungCongViec"
                value={formik.values?.noiDungCongViec || ""}
                type="text"
                as="textarea"
                rows="2"
                bulletPoints="+"
                readOnly={isView}
                onChange={(value: any) => formik.setFieldValue("noiDungCongViec", value)}
                touched={formik.touched?.noiDungCongViec}
                errors={formik.errors?.noiDungCongViec}
              />
            </Col>
            <Col md={12} lg={12} xl={6} xxl={4} className="spaces pb-6">
              <TextArea
                className="w-100"
                lable={lang("RECEIVING.SOLUTION_CONTENT")}
                name="noiDungHuongGiaiQuyet"
                value={formik.values?.noiDungHuongGiaiQuyet || ""}
                type="text"
                as="textarea"
                rows="2"
                bulletPoints="+"
                readOnly={isView}
                onChange={(value: any) => formik.setFieldValue("noiDungHuongGiaiQuyet", value)}
                touched={formik.touched?.noiDungHuongGiaiQuyet}
                errors={formik.errors?.noiDungHuongGiaiQuyet}
              />
            </Col>
            <Col md={12} lg={12} xl={6} xxl={4} className="spaces pb-6">
              <TextArea
                className="w-100"
                lable={lang("GENERAL.NOTE")}
                name="note"
                value={formik.values?.note || ""}
                type="text"
                as="textarea"
                rows="2"
                bulletPoints="+"
                readOnly={isView}
                onChange={(value: any) => formik.setFieldValue("note", value)}
                touched={formik.touched?.note}
                errors={formik.errors?.note}
              />
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};
export { ReceivingCitizensForm };


import { IPropsExportWord } from "../../../components/exportWord";
import { ValueOrDots } from "../../../components/exportWord/partials";

export function ReappointmentDecisionWord({ data }: IPropsExportWord) {
  return (
    <div className="word spaces px-70">
      <div className="spaces text-center flex flex-between align-start">
        <div className="fs-lg">
          <p>{data?.soYTeHeader ?? ""}</p>
          <p className="fw-600 text-uppercase">
            <span className="header-underline fs-lg">{data?.donViCongTacText ?? ""}</span>
          </p>
          <p className="spaces mt-16 fs-md">Số: {data?.soQuyetDinh}</p>
        </div>
        <div className="fw-600">
          <p className="fs-lg">CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM</p>
          <p>
            <span className="header-underline fs-lg">Độc lập - Tự do - Hạnh phúc</span>
          </p>
          <p className="text-italic fw-500 spaces mt-16">
            <ValueOrDots value="" size={20} />, ngày <ValueOrDots value={data.ngayQuyetDinhNgay} size={15}/> tháng{" "}
            <ValueOrDots value={data.ngayQuyetDinhThang} size={15}/> năm <ValueOrDots value={data.ngayQuyetDinhNam} size={15} />
          </p>
        </div>
      </div>
      <div className="text-center fw-600">
        <div className="fs-lg">
          <p>QUYẾT ĐỊNH</p>
          <p>Về bổ nhiệm lại {data?.chucVuText} {data?.phongBanText}</p>
          <p>
            <span className="header-underline fs-lg">{data?.donViCongTacText}</span>
          </p>
        </div>
        <div className="spaces py-16">
          <p className="fs-lg text-uppercase">GIÁM ĐỐC {data?.donViCongTacText ?? ""}</p>
        </div>
      </div>
      <div className="text-italic text-indent-40 text-justify">
        <p>{data?.qdUBNDBoNhiem ?? ""}</p>
        <p>{data?.quyetDinhHopDong ?? ""}</p>
        <p>{data?.qdhdBoNhiem}</p>
        <p>Căn cứ Quyết định số ......../QĐ-BV ngày <ValueOrDots value="" /> của {data?.donViCongTacText ?? ""} về <ValueOrDots value="" />;</p>
        <p>Căn cứ Biên bản họp Ban Giám đốc {data?.donViCongTacText ?? ""} ngày <ValueOrDots value="" /></p>
        <p>Xét đề nghị của Trưởng phòng Tổ chức Hành chính Bệnh viện tại Tờ trình ngày <ValueOrDots value="" size={20} />về việc <ValueOrDots value="" size={38} /></p>
      </div>
      <p className="fw-600 text-center fs-lg spaces my-16">QUYẾT ĐỊNH:</p>
      <div className="text-indent-40">
        <p>
          <span className="fw-600">Điều 1.</span> 
          <span> Bổ nhiệm lại (Ông/Bà) </span> 
          <ValueOrDots value={data?.fullName} /> 
          <span> sinh ngày {data?.birthdate ? data?.birthdate : "....../..../........."}, </span>
          <ValueOrDots value={data?.chucVuText} size={40}/> 
          <ValueOrDots value={data?.phongBanText} size={40}/> 
          <span> tiếp tục giữ chức vụ </span>
          <ValueOrDots value={data?.chucVuMoiText} size={40}/>
          , <ValueOrDots value={data?.phongBanMoiText} size={40}/>
          <span> trực thuộc {data?.donViCongTacText}.</span>
        </p>
        <p>Thời hạn bổ nhiệm lại 05 năm.</p>
        <div>
          <span className="fw-600">Điều 2. </span>
          <span className="fw-600">(Ông/Bà) </span>
          <ValueOrDots value={data?.fullName} size={40}/>
          <span> được hưởng lương và các khoản phụ theo quy định hiện hành của Nhà nước và Thành phố.</span>
        </div>
        <p>
          <span className="fw-600">Điều 3.</span> Quyết định này có hiệu lực kể từ ngày ký.
        </p>
        <div>
          <ValueOrDots value={data?.khoaPhongNhanQuyetDinh} size={160}/>;
          <span> các khoa, phòng có liên quan và (Ông/Bà) </span>
          <ValueOrDots value={data?.fullName} size={40}/>;
          <span> chịu trách nhiệm thi hành quyết định này.</span>
        </div>
      </div>
      <div className="flex flex-between align-start spaces pb-70 mt-16">
        <div>
          <p className="fw-600 text-italic">Nơi nhận: </p>
          <p>- Như Điều 3 (để t/h);</p>
          <p>- Lưu: TCHC, VT.</p>
        </div>
        <div className="text-center fs-md">
          <p className="fw-600">GIÁM ĐỐC</p>
          <p className="spaces mt-70 fw-600 text-capitalize-first">{data?.nguoiQuyetDinhText}</p>
        </div>
      </div>
    </div>
  );
}

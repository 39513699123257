import { FC, useContext, useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { GroupButton } from "../../../../components/GroupButton";
import TextValidator from "../../../../components/input/text-validator";
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import { ILeaveType } from "../../../models/categoryModels";
import useMultiLanguage from "../../../../../hook/useMultiLanguage";
import AppContext from "../../../../../AppContext";
import * as Yup from "yup";
import { addLeaveRegulations, updateLeaveRegulations } from "../../../services/leaveRegulationsServices";
import { RESPONSE_STATUS_CODE } from "../../../../constants/moduleConsts";
import { hasAuthority } from "../../../../utils/functionUtils";
import { PERMISSION_ABILITY, PERMISSIONS } from "../../../../../constants";
import { INIT_LEAVE_TYPE } from "../../../constants/leaveRegulationsConsts";
import Autocomplete from "../../../../components/input/autocomplete";
import { LIST_LOAI_NGHI } from "../../../../manager-request/constants/attendanceWatchConsts";

interface IProps {
  open: boolean;
  handleClose: () => void;
  handleCloseAndSearch: () => void;
  leaveTypeInfo: ILeaveType;
  isView: boolean;
}

const LeaveTypeForm: FC<IProps> = (props) => {
  const {
    open,
    handleClose,
    handleCloseAndSearch,
    leaveTypeInfo,
    isView
  } = props;

  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);
  const [isEdit, setIsEdit] = useState<boolean>(!isView);

  useEffect(() => {
    leaveTypeInfo?.id && formik.setValues({ ...formik.values, ...leaveTypeInfo });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaveTypeInfo]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(lang("VALIDATION.REQUIRE")),
    maxDay: Yup.number().required(lang("VALIDATION.REQUIRE")).nullable(),
    leaveType: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
  });

  const handleSubmit = async (values: ILeaveType) => {
    try {
      setPageLoading(true);
      const { data } = leaveTypeInfo?.id
        ? await updateLeaveRegulations(leaveTypeInfo.id, values)
        : await addLeaveRegulations(values);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(
          leaveTypeInfo.id
            ? lang("TOAST.EDIT.SUCCESS")
            : lang("TOAST.CREATE.SUCCESS")
        );
        handleCloseAndSearch();
      } else {
        toast.warning(data?.message);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false)
    }
  };

  const formik = useFormik({
    initialValues: INIT_LEAVE_TYPE,
    validationSchema,
    onSubmit: handleSubmit
  });

  return (
    <Modal
      show={open}
      onHide={handleClose}
      backdrop="static"
      size="lg"
      centered
      className="custom-modal"
    >
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>
            {leaveTypeInfo?.id ? lang("LEAVE_TYPE.EDIT") : lang("LEAVE_TYPE.ADD")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="g-4 relative spaces z-index-4">
            <Col sm={12} md={12} lg={6} xl={6}>
              <TextValidator
                isRequired
                name="name"
                lable={lang("LEAVE_TYPE.NAME")}
                type="text"
                onChange={formik.handleChange}
                value={formik.values?.name}
                readOnly={!isEdit}
                errors={formik.errors?.name}
                touched={formik.touched?.name}
              />
            </Col>
            <Col sm={8} md={8} lg={3} xl={3}>
              <Autocomplete
                isRequired
                lable={lang("RESIGN.TYPE_BREAK")}
                name="leaveType"
                value={formik.values?.leaveType || null}
                onChange={(value) => formik.setFieldValue("leaveType", value)}
                options={LIST_LOAI_NGHI}
                isReadOnly={!isEdit}
                menuPlacement="bottom"
                errors={formik?.errors?.leaveType}
                touched={formik?.touched?.leaveType}
              />
            </Col>
            <Col sm={4} md={4} lg={3} xl={3}>
              <TextValidator
                isRequired
                name="maxDay"
                lable={lang("LEAVE_TYPE.NUMBER_DAY")}
                type="number"
                value={formik.values?.maxDay || ""}
                onChange={formik.handleChange}
                readOnly={!isEdit}
                errors={formik?.errors?.maxDay}
                touched={formik?.touched?.maxDay}
              />
            </Col>
            <Col sm={12}>
              <TextValidator
                name="description"
                lable={lang("DESCRIPTION")}
                type="text"
                as="textarea"
                rows={2}
                value={formik.values?.description}
                onChange={formik.handleChange}
                readOnly={!isEdit}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          {(isEdit ? (
            <GroupButton type="btn-save" />
          ) : (hasAuthority(PERMISSIONS["DANH_MUC.CHAM_CONG.NGHI_LE"], PERMISSION_ABILITY.UPDATE) &&
            <GroupButton type="btn-edit" handleSaveEdit={() => setIsEdit(true)} />
          ))
          }
          <GroupButton
            type="btn-cancel"
            handleCloseUpdateDialog={handleClose}
          />
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export { LeaveTypeForm };
export const ConstantList = {
  ENTER_KEY: "Enter",
};
export enum SELECT_OPTION {
  SELECT_ACTION = "select-option",
  CLEAR_ACTION = "clear",
  REMOVE_ACTION = "remove-value",
}

export const PERMISSIONS = {
  ADMIN: "HRM.ADMIN.ORGANIZATION",
  AUTHORITY: "HRM.AUTHORITY",
  BANG_CHAM_CONG: "HRM.BANG_CHAM_CONG",
  BAO_CAO: "HRM.BAO_CAO",
  BAO_HIEM_Y_TE: "HRM.BAO_HIEM_Y_TE",
  BIET_PHAI: "HRM.BIET_PHAI",
  BO_NHIEM: "HRM.BO_NHIEM",
  CA_LAM_VIEC: "HRM.CA_LAM_VIEC",
  CANH_BAO: "HRM.CANH_BAO",
  CHAM_CONG_TONG_QUAN: "HRM.CHAM_CONG_TONG_QUAN",
  CHANGE_PASSWORD: "HRM.CHANGE_PASSWORD",
  CU_DAO_TAO: "HRM.CU_DAO_TAO",
  "DANH_MUC.CHAM_CONG": "HRM.DANH_MUC.CHAM_CONG",
  "DANH_MUC.CHAM_CONG.CAU_HINH_DUYET": "HRM.DANH_MUC.CHAM_CONG.CAU_HINH_DUYET",
  "DANH_MUC.CHAM_CONG.KY_HIEU": "HRM.DANH_MUC.CHAM_CONG.KY_HIEU",
  "DANH_MUC.CHAM_CONG.LOAI_NGHI": "HRM.DANH_MUC.CHAM_CONG.LOAI_NGHI",
  "DANH_MUC.CHAM_CONG.NGHI_LE": "HRM.DANH_MUC.CHAM_CONG.NGHI_LE",
  "DANH_MUC.CHAM_CONG.QUY_DINH_NGHI": "HRM.DANH_MUC.CHAM_CONG.QUY_DINH_NGHI",
  DEPARTMENT: "HRM.DEPARTMENT",
  DE_XUAT: "HRM.DE_XUAT",
  DON_TU: "HRM.DON_TU",
  DON_DANG_KI_LAM_THEM: "HRM.DON_DANG_KI_LAM_THEM",
  DON_DOI_CA_NGHI_BU: "HRM.DON_DOI_CA_NGHI_BU",
  DON_XIN_NGHI: "HRM.DON_XIN_NGHI",
  EMPLOYEE: "HRM.EMPLOYEE",
  GIAO_CA: "HRM.GIAO_CA",
  HOP_DONG: "HRM.HOP_DONG",
  KE_KHAI_TAI_SAN: "HRM.KE_KHAI_TAI_SAN",
  KE_KHAI_TAI_SAN_MENU: "HRM.KE_KHAI_TAI_SAN_MENU",
  KHEN_THUONG: "HRM.KHEN_THUONG",
  KHEN_THUONG_MENU: "HRM.KHEN_THUONG_MENU",
  KHAU_TRU: "HRM.KHAU_TRU",
  KIEM_NHIEM: "HRM.KIEM_NHIEM",
  KY_LUAT: "HRM.KY_LUAT",
  KY_LUAT_MENU: "HRM.KY_LUAT_MENU",
  LICH_HANH_CHINH: "HRM.LICH_HANH_CHINH",
  LICH_SU_LUONG: "HRM.LICH_SU_LUONG",
  MANAGEMENT: "HRM.MANAGEMENT",
  MANAGEMENT_UNIT: "HRM.MANAGEMENT_UNIT",
  MIEN_NHIEM: "HRM.MIEN_NHIEM",
  MODULE: "HRM.MODULE",
  NGHI_VIEC: "HRM.NGHI_VIEC",
  ORGANIZATION: "HRM.ORGANIZATION",
  ORGANIZATION_MENU: "HRM.ORGANIZATION_MENU",
  PHAN_CA: "HRM.PHAN_CA",
  PHAN_CA_BAC_SI: "HRM.PHAN_CA_BAC_SI",
  PHAN_CA_DIEU_DUONG: "HRM.PHAN_CA_DIEU_DUONG",
  PHAN_CA_TONG_HOP: "HRM.PHAN_CA_TONG_HOP",
  PHU_CAP: "HRM.PHU_CAP",
  PHUC_LOI: "HRM.PHUC_LOI",
  PHUC_LOI_MENU: "HRM.PHUC_LOI_MENU",
  REMIND: "HRM.REMIND",
  ROLE: "HRM.ROLE",
  SOFTWARE_USAGE: "HRM.SOFTWARE_USAGE",
  TAM_UNG: "HRM.TAM_UNG",
  THANH_TOAN: "HRM.THANH_TOAN",
  THONG_KE: "HRM.THONG_KE",
  THUYEN_CHUYEN: "HRM.THUYEN_CHUYEN",
  TIEP_CONG_DAN: "HRM.TIEP_CONG_DAN",
  USER: "HRM.USER"
}

export const PERMISSION_ABILITY = {
  VIEW: "VIEW",
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
  UPLOAD: "UPLOAD",
  EXPORT: "EXPORT",
  APPROVE: "APPROVE",
  ALL: "ALL"
}

export const MODULE = {
  HO_SO: "HO_SO",
  TUYEN_DUNG: "TUYEN_DUNG",
  CHAM_CONG: "CHAM_CONG",
  TAI_LIEU: "TAI_LIEU",
  DAO_TAO: "DAO_TAO",
  PHONG_HOP: "PHONG_HOP",
  CONG_VIEC: "CONG_VIEC",
  HE_THONG: "HE_THONG"
}

export const ROLE = {
  ADMIN: "HRM_ROLE_ADMIN",
  USER: "HRM_ROLE_USER",
}

export const MODULE_NAME = {
  HO_SO: "profile",
  TUYEN_DUNG: "recruitment",
  CHAM_CONG: "timekeeping",
  TAI_LIEU: "document",
  DAO_TAO: "training",
  PHONG_HOP: "meeting-room",
  CONG_VIEC: "job",
  HE_THONG: "system"
}

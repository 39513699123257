import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import AppContext from "../../AppContext";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../constants";
import useMultiLanguage from "../../hook/useMultiLanguage";
import InputSearch from "../components/input/InputSearch";
import TableCustom from "../components/table-custom/TableCustom";
import "../styles/index.scss";
import { RESPONSE_STATUS_CODE, TYPE, INIT_SEARCH_OBJECT, EXPORT_FILE_NAME, INIT_INPUT_SEARCH } from "../constants/moduleConsts";
import { checkStatus, convertSearch, formatDateTable, hasAuthority } from "../utils/functionUtils";
import { ProfileAddAndUpdateV2 } from "./ProfileAddAndUpdateV2";
import AdvancedSearch from "./components/dialogs/AdvancedSearch";
import { LIST_STATUS_NV } from "./constants/dialogChildConsts";
import { FIELD_SEARCH } from "./constants/profileConsts";
import { EmployeeProfile, ProfileInfo } from "./models/profileModels";
import "./profile.scss";
import { exportExcelProfile, getAllDepartmentByUserId, getAvailableFieldsExport } from "./services/profileServices";

import ProfileProvider from "./ProfileContext";
import { ExportExcelDialog } from "../components/export-excel/ExportExcelDialog";
import { MAX_EXPORT_FIELD_LENGTH, defaultCodeExportField } from "./constants/profileDialogConsts";
import { useLocation, useNavigate } from "react-router-dom";
import { convertOrganizationToTreeView } from "./utils/profileUtils";
import ResizableGroup from "../components/resize-component/ResizableGroup";
import ResizablePanel from "../components/resize-component/ResizablePanel";
import TreeView from "../components/tree-view/TreeView";
import { TSelectTree } from "../components/tree-view/treeViewModels";
import { toast } from "react-toastify";
import { GroupButton } from "../components/GroupButton";
import { localStorageItem } from "../utils/localstorage";
import { KEY_LOCALSTORAGE } from "../auth/core/_consts";
import { searchAllEmployee, searchListWorkUnitTree } from "../services/services";
import { IItemSearch } from "../models/models";

export const ProfileContext = React.createContext(() => { });
const Profile: FC = () => {
  const [shouldOpenDialog, setShouldOpenDialog] = useState<boolean>(false);
  const [shouldOpenExportDialog, setShouldOpenExportDialog] = useState<boolean>(false);
  const [isAdvancedSearch, setIsAdvancedSearch] = useState<boolean>(false);
  const [profiles, setProfiles] = useState<ProfileInfo[]>([]);
  const [isViewProfiles, setIsViewProfiles] = useState<boolean>(false);
  const [idEmployee, setIdEmployee] = useState<string>("");
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [listInputSearch, setListInputSearch] = useState<any[]>([INIT_INPUT_SEARCH])
  const [searchObject, setSearchObject] = useState<any>(INIT_SEARCH_OBJECT);
  const [listTreeSelect, setListTreeSelect] = useState<TSelectTree[]>([])
  const [dataChecked, setDataChecked] = useState<EmployeeProfile[]>([]);
  const [idSelected, setIdSelected] = useState<string>("");
  const [idsSelected, setIdsSelected] = useState<string[]>([]);
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const dataLocation: any = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (dataLocation?.state?.reset) {
      handleCloseDialog()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLocation?.state?.reset])

  const showNameTitle = useCallback((row: any = null) => {
    if (row?.id) {
      document.title = `${row.maNhanVien ? row.maNhanVien + "-  " : ""} ${row.name}`;
    } else {
      document.title = `${lang('GENERAL.APP.NAME')} | ${lang('GENERAL.PROFILE')}`;
    }
  }, [lang]);

  useEffect(() => {
    let ids = dataChecked.map((item: EmployeeProfile) => item?.id || "");
    setIdsSelected(ids);
  }, [dataChecked])

  useEffect(() => {
    showNameTitle()
  }, [showNameTitle])

  const handleOpenDialog = (row?: any): void => {
    showNameTitle(row)
    setIsViewProfiles(row?.id ? true : false);
    setIdEmployee(row?.id ? row?.id : "");
    setShouldOpenDialog(true);
    navigate(dataLocation?.pathname, { state: { reset: false } });
  };

  const handleOpenAddNewDialog = () => {
    setIdEmployee("");
    setIsViewProfiles(false);
    setShouldOpenDialog(true);
    navigate(dataLocation?.pathname, { state: { reset: false } });
  };

  const handleCloseDialog = (): void => {
    setShouldOpenDialog(false);
    showNameTitle();
    navigate(dataLocation?.pathname, { state: { reset: false } });
  };

  const handleToggleIsView = () => {
    setIsViewProfiles(!isViewProfiles);
  };

  const updatePageData = async (searchObject: any) => {
    try {
      setSearchObject({ ...searchObject })
      setPageLoading(true);
      let { data } = await searchAllEmployee(searchObject);
      
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setIsAdvancedSearch(false)
        setProfiles(data?.data?.content || []);
        setTotalPage(data?.data?.pageable?.totalPages);
        setTotalElements(data?.data?.pageable?.totalElements);
        setNumberOfElements(data?.data?.pageable?.numberOfElements);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const getDonVi = async () => {
    let treeSelect: TSelectTree[] = [];
    try {
      if(hasAuthority(PERMISSIONS.DEPARTMENT, PERMISSION_ABILITY.VIEW)) {
        const { data } = await getAllDepartmentByUserId(localStorageItem.get(KEY_LOCALSTORAGE.CURRENT)?.id ?? "");
        if(data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
          treeSelect = [data?.data]?.map(convertOrganizationToTreeView);
        } else {
          toast.warning(data?.message);
        }
      } else {
        const { data } = await searchListWorkUnitTree();
        if(data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
          treeSelect = data?.data?.content?.map(convertOrganizationToTreeView);
        } else {
          toast.warning(data?.message);
        }
      }
      setListTreeSelect(treeSelect);
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
    return treeSelect;
  };

  useEffect(() => {
    const handleWindowScroll = () => {
      let mucLucWidth = document.getElementById("profile__table-of-content")?.clientWidth;
      let mucLuc = document.getElementById('table-of-profiles');
      if (window.scrollY >= 80 && mucLuc) {
        mucLuc?.classList?.add('fixed');
        mucLuc.style.width = `${mucLucWidth}px`;
      } else if (window.scrollY < 80) {
        mucLuc?.classList?.remove('fixed');
      }
    };
    window.addEventListener('scroll', handleWindowScroll);
    return () => window.removeEventListener("scroll", handleWindowScroll);
  }, []);

  useEffect(() => {
    if(searchObject?.pageIndex && searchObject?.pageSize && !shouldOpenDialog && !hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW)) {
      handleSearch();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchObject?.pageIndex, searchObject?.pageSize, shouldOpenDialog])

  const handleSearch = async (data: any = {}) => {
    let donViCongTacId = searchObject?.donViCongTacId;
    let phongBanIds = searchObject?.phongBanIds;
    if(listTreeSelect.length === 0) {
      setPageLoading(true);
      const treeSelect = await getDonVi();
      if(treeSelect && treeSelect.length > 0) {
        if(treeSelect[0]?.orgId) {
          phongBanIds = treeSelect[0]?.id;
        } else donViCongTacId = treeSelect[0]?.id;
        setIdSelected(treeSelect[0]?.id ?? "");
      }
    }

    const dataSearch: any = {
      ...searchObject,
      donViCongTacId,
      phongBanIds,
      ...data
    }

    dataSearch.keyword = dataSearch?.keyword?.trim() || ""
    updatePageData(dataSearch)
  }

  const handleAdvancedSearch = (data: IItemSearch[]) => {
    setListInputSearch(data)
    handleSearch({ ...convertSearch(data), pageIndex: 1 });
  }

  const handleOpenAdvancedSearch = () => {
    setIsAdvancedSearch(true)
  }

  const handleTreeSelect = async (item: any) => {
    if (!item?.expanded) {
      handleSearch({
        pageIndex: 1,
        phongBanIds: item?.orgId ? item?.id : null,
        donViCongTacId: !item?.orgId ? item?.id : null
      });
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchObject({
      ...searchObject,
      keyword: e.target?.value
    })
  }

  const handleOpenExportProfile = () => {
    setShouldOpenExportDialog(true);
  }

  const columns = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: "Trạng thái",
      field: "trangThaiLaoDong",
      headerStyle: {
        minWidth: "160px",
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any, index: number) => (
        <span className={`${checkStatus(LIST_STATUS_NV, Number(row?.trangThaiLaoDong?.code))} status`} >
          {row?.trangThaiLaoDong?.name}
        </span>
      )
    },
    {
      name: "Mã nhân viên",
      field: "maNhanVien",
      headerStyle: {
        minWidth: "120px"
      },
      render: (row: any) => <span className="color-steel-blue fw-600">{row?.maNhanVien}</span>
    },
    {
      name: "Họ và tên",
      field: "name",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        minWidth: "200px",
        textAlign: "left",
      },
    },
    {
      name: "Giới tính",
      field: "gender",
      headerStyle: {
        minWidth: "80px"
      },
      render: (row: any) => <span>{row?.gender?.name}</span>
    },
    {
      name: "Ngày sinh",
      field: "birthDate",
      headerStyle: {
        minWidth: "100px"
      },
      render: (row: any) => <span>{formatDateTable(row?.birthDate)}</span>
    },
    {
      name: "Số ĐTDĐ",
      field: "phone",
      headerStyle: {
        minWidth: "100px"
      },
      cellStyle: {
        minWidth: "100px",
        textAlign: "left",
      },
    },
    {
      name: "CCCD",
      field: "soCMNDOrCCCD",
      headerStyle: {
        minWidth: "100px"
      },
      cellStyle: {
        minWidth: "100px",
        textAlign: "left",
      },
    },
    {
      name: "Khoa/Phòng",
      field: "phongBanText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        minWidth: "200px",
        textAlign: "left",
      },
    },
    {
      name: "Chức vụ",
      field: "chucVuText",
      headerStyle: {
        minWidth: "150px"
      },
      cellStyle: {
        minWidth: "150px",
        textAlign: "left",
      },
    },
    {
      name: "Chức danh",
      field: "viTriCongViecText",
      headerStyle: {
        minWidth: "250px"
      },
      cellStyle: {
        minWidth: "250px",
        textAlign: "left",
      },
    },
    {
      name: "Loại cán bộ",
      field: "loaiCanBo",
      headerStyle: {
        minWidth: "265px"
      },
      cellStyle: {
        textAlign: "left"
      },
      render: (row: any) => <span>{row?.loaiCanBo?.name}</span>
    }
  ]

  return (
    <ProfileProvider>
      <div className="profile">
        {!shouldOpenDialog && !hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW) && (
          <>
            <Row className="spaces mb-12 flex-middle">
              <Col xs={5} xl={6} xxl={7} className="spaces p-0">
                <h2 className="breadcrumb-title">
                  danh sách hồ sơ nhân viên
                </h2>
              </Col>
              <Col xs={7} xl={6} xxl={5} className="flex">
                <div className="spaces mr-10 flex-1">
                  <InputSearch
                    className="border-3px"
                    value={searchObject?.keyword}
                    placeholder="Tìm kiếm theo mã NV, họ và tên, SĐT và CCCD"
                    isEnter={true}
                    handleSearch={() => handleSearch({ pageIndex: 1 })}
                    handleChange={handleChange}
                  />
                </div>
                <GroupButton className="spaces mr-10" handleEvent={() => handleSearch({ pageIndex: 1 })}>
                  {lang("BTN.SEARCH")}
                </GroupButton>
                <GroupButton className="spaces mr-10" outline handleEvent={handleOpenAdvancedSearch}>
                  {lang("BTN.ADVANCE_SEARCH")}
                </GroupButton>

                {isAdvancedSearch && (
                  <AdvancedSearch
                    open={isAdvancedSearch}
                    listInputSearch={listInputSearch}
                    handleSearch={handleAdvancedSearch}
                    listOption={FIELD_SEARCH}
                    handleClose={() => setIsAdvancedSearch(false)}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <ResizableGroup direction="horizontal" className="bg-white container-profile">
                <ResizablePanel
                  className="spaces p-0"
                  initialStyle={{ flexBasis: "200px", maxWidth: "600px" }}
                >
                  <h2 className="spaces fw-600 py-12 m-0 mb-6 box-shadow-bottom">{lang("GENERAL.UNIT_DEPARTMENT")}</h2>
                  <div className="spaces pl-4 select-tree">
                    <TreeView 
                      listOption={listTreeSelect}
                      handleSelect={handleTreeSelect}
                      idSelected={idSelected}
                      handleChangeSelectId={setIdSelected}
                      showQuantity
                    />
                  </div>
                </ResizablePanel>
                <div className="spaces pl-0 table-scroll">
                  <TableCustom
                    id="profile"
                    data={profiles}
                    columns={columns}
                    searchObject={searchObject}
                    setSearchObject={setSearchObject}
                    buttonAdd={hasAuthority(PERMISSIONS.EMPLOYEE, PERMISSION_ABILITY.VIEW)}
                    buttonExportExcel={true}
                    notDelete={true}
                    handleExportExcel={handleOpenExportProfile}
                    type={TYPE.MULTILINE}
                    fixedColumnsCount={4}
                    totalPages={totalPage}
                    totalElements={totalElements}
                    numberOfElements={numberOfElements}
                    handleOpenDialog={handleOpenAddNewDialog}
                    handleDoubleClick={handleOpenDialog}
                    setDataChecked={setDataChecked}
                    dataChecked={dataChecked}
                  />
                </div>
              </ResizableGroup>
            </Row>
          </>
        )}
        {(shouldOpenDialog || hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW)) && (
          <ProfileAddAndUpdateV2
            handleCloseDialog={handleCloseDialog}
            idEmployee={idEmployee}
            setIdEmployee={setIdEmployee}
            isView={isViewProfiles}
            handleToggleIsView={handleToggleIsView}
          />
        )}
        {shouldOpenExportDialog && (
          <ExportExcelDialog
            handleClose={() => setShouldOpenExportDialog(false)}
            ids={idsSelected}
            exportAPI={exportExcelProfile}
            getFieldsAPI={getAvailableFieldsExport}
            defaultCodeExportField={defaultCodeExportField}
            maxExportFields={MAX_EXPORT_FIELD_LENGTH}
            fileName={EXPORT_FILE_NAME.HO_SO}
            searchObj={searchObject}
          />
        )}
      </div>
    </ProfileProvider>
  );
};
export { Profile };
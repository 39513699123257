import { FC, useContext, useEffect, useMemo, useState } from 'react'
import useMultiLanguage from '../../../../hook/useMultiLanguage';
import * as Yup from "yup";
import AppContext from '../../../../AppContext';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';
import { GroupButton } from '../../../components/GroupButton';
import TextValidator from '../../../components/input/text-validator';
import { IAttendanceWatch } from '../../models/attendanceWatchModes';
import { TYPE_LOAI_NGHI, INIT_ATTENDANCE_WATCH, LIST_LOAI_NGHI, attendanceWatchWordExportList } from '../../constants/attendanceWatchConsts';
import Autocomplete from '../../../components/input/autocomplete';
import { exportTimekeepingWord, getDataPreviewTimekeepingWord, searchAllEmployee, searchNguoiDaiDienKy } from '../../../services/services';
import { DATA_WORD_TYPES, FORMAT_DATE, RESPONSE_STATUS_CODE, VARIABLE_STRING } from '../../../constants/moduleConsts';
import moment from 'moment';
import { ColCustom, RowCustom } from '../../../components/grid-component';
import { ConfirmDialog } from '../../../components/dialogs';
import { findList, hasAuthority, matchTemplateWord } from '../../../utils/functionUtils';
import { RefuseDialog } from '../../../components/dialogs/RefuseDialog';
import { convertDataAttendanceWatchDto, convertDataAttendanceWatchUI } from '../../utils/functionUtils';
import { CODE_STATUS, LIST_STATUS, TYPE_DON } from '../../constants/managerRequestConsts';
import { PERMISSION_ABILITY, PERMISSIONS } from '../../../../constants';
import { ExportWord } from '../../../components/exportWord';
import { addManagerRequest, approveManagerRequest, getLeaveDayByEmployee, updateManagerRequest } from '../../services/managerRequestServices';

const AttendanceWatchForm: FC<any> = (props) => {
    const {
        handleClose,
        item,
        handleCloseUpdateDialog,
        isView,
        setIsView,
        handleCloseAndSearch,
        isApproveOrReject
    } = props;
    const { lang } = useMultiLanguage()
    const { setPageLoading } = useContext(AppContext);
    const [shouldOpenSendlDialog, setShouldOpenSendlDialog] = useState<boolean>(false);
    const [shouldOpenRejectDialog, setShouldOpenRejectDialog] = useState<boolean>(false);
    const [shouldOpenApproveDialog, setShouldOpenApproveDialog] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [isShowDialogWord, setIsShowDialogWord] = useState<boolean>(false);

    const templateWord = useMemo(() => 
        matchTemplateWord(Number(item?.loaiNghi?.type), attendanceWatchWordExportList), [item?.loaiNghi]);
    
    const validationSchema = Yup.object().shape({
        employee: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
        dienThoai: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
        loaiNghi: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
        ngayNop: Yup.date()
            .required(lang("VALIDATION.REQUIRE"))
            .max(new Date(), lang("VALIDATON.DATE_CURRENT"))
            .nullable(),
        tuNgay: Yup.date()
            .required(lang("VALIDATION.REQUIRE"))
            .nullable(),
        denNgay: Yup.date()
            .required(lang("VALIDATION.REQUIRE"))
            .when("tuNgay", {
                is: (tuNgay: Date | null) => tuNgay,
                then: Yup.date()
                    .min(
                        Yup.ref("tuNgay"),
                        lang("VALIDATION.MINDATE") +
                        lang("INPUT.DATE_END")
                    )
                    .nullable()
            })
            .nullable(),
        trangThai: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    });

    useEffect(() => {
        if (item?.id) {
            formik.setValues(convertDataAttendanceWatchUI(item))
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item]);

    const handleCloseForm = () => {
        setShouldOpenSendlDialog(false)
        setShouldOpenApproveDialog(false)
        setShouldOpenRejectDialog(false)
    }

    const handleSubmit = async (values: IAttendanceWatch) => {
        try {
            if(values?.loaiNghi?.type === TYPE_LOAI_NGHI.NGHI_PHEP && (!values?.soNPConLai || !values?.soNgayNghi || values?.soNPConLai < values?.soNgayNghi)) {
                toast.warning("Số ngày nghỉ phải nhỏ hơn hoặc bằng số ngày nghỉ phép còn lại");
                return;
            }
            setPageLoading(true);
            const _data = convertDataAttendanceWatchDto(formik.values)
            const { data } = formik.values?.id
                ? await updateManagerRequest(formik.values?.id, _data)
                : await addManagerRequest(_data);
            if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
                toast.success(
                    formik.values?.id
                        ? lang("TOAST.EDIT.SUCCESS")
                        : lang("TOAST.CREATE.SUCCESS")
                );
                handleCloseAndSearch();
                return;
            }
            toast.warning(data?.message);
        } catch (err) {
            toast.error(lang("GENERAL.ERROR"));
        } finally {
            setPageLoading(false)
        }
    };

    const formik = useFormik({
        initialValues: INIT_ATTENDANCE_WATCH as IAttendanceWatch,
        validationSchema,
        onSubmit: handleSubmit
    });

    useEffect(() => {
        if (formik.values?.tuNgay && formik.values?.denNgay) {
            const tuNgay = moment(formik.values?.tuNgay);
            const denNgay = moment(formik.values?.denNgay);
            formik.setFieldValue("soNgayNghi", denNgay.diff(tuNgay, 'days'))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values?.tuNgay, formik.values?.denNgay])

    const getInfoLeaveDay = async (searchObject: any) => {
        try {
            const { data } = await getLeaveDayByEmployee(searchObject);
            if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
                formik.setFieldValue("soNPDuocSuDung", data?.data?.numberOfUsableDays);
                formik.setFieldValue("soNPDaNghi", data?.data?.numberOfUsedDays);
                formik.setFieldValue("soNPConLai", data?.data?.numberOfUsableDays - data?.data?.numberOfUsedDays);
            } else toast.warning(data?.message);
        } catch (error) {
            toast.error(lang("GENERAL.ERROR"));
        }
    };

    useEffect(() => {
        formik.values?.employee?.id && getInfoLeaveDay({
            employeeId: formik.values?.employee?.id,
            thang: moment().format(FORMAT_DATE.MM),
            nam: moment().format(FORMAT_DATE.YYYY)
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values?.employee?.id]);

    const handleChangeSelect = (name: string, value: any) => {
        if (name === VARIABLE_STRING.EMPLOYEE) {
            let _values = {
                [VARIABLE_STRING.EMPLOYEE]: value,
                donViCongTacId: value?.donViCongTacId,
                donViCongTacText: value?.donViCongTacText,
                maNhanVien: value?.maNhanVien,
                phongBanId: value?.phongBanId,
                phongBanText: value?.phongBanText,
                chucDanhId: value?.viTriCongViecId,
                chucDanhText: value?.viTriCongViecText,
                dienThoai: value?.phone
            };

            formik.setValues({
                ...formik.values,
                ..._values
            });
            return;
        }

        formik.setFieldValue(name, value)
    };

    const handleConfirm = (lyDo: string = "") => {
        shouldOpenApproveDialog && handleUpdateStatus(CODE_STATUS.DA_DUYET, "Duyệt đơn thành công")
        shouldOpenSendlDialog && handleUpdateStatus(CODE_STATUS.CHO_DUYET, "Gửi đơn Thành công")
        shouldOpenRejectDialog && handleUpdateStatus(CODE_STATUS.TU_CHOI,"Đã từ chối đơn", lyDo)
    }

    const handleOpenSendlDialog = () => {
        setMessage("Bạn có chắc chắn muốn gửi không?")
        setShouldOpenSendlDialog(true)
    }

    const handleOpenApprovelDialog = () => {
        setMessage("Bạn có chắc chắn muốn duyệt đơn này?")
        setShouldOpenApproveDialog(true)
    }

    const handleOpenRejectDialog = () => {
        setShouldOpenRejectDialog(true)
    }

    const handleUpdateStatus = async (status: string | number, messageSuccess: string = "", lyDo: string = "") => {
        try {
            setPageLoading(true);
            let _values = {
                trangThai: findList(LIST_STATUS, status),
                ...status === CODE_STATUS.TU_CHOI && { lyDo }
            }

            const { data } = await approveManagerRequest(formik.values?.id ?? "", _values, {
                loaiDon: formik.values?.loaiDon?.type
            });

            if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
                toast.success(messageSuccess);
                handleCloseAndSearch();
                handleCloseForm();
                return;
            }
            toast.warn(data?.message);
        } catch {
            toast.error(lang("TOAST.GET.ERROR"));
        } finally {
            setPageLoading(false);
        }
    }

    return (
        <div className="managerRequest h-full">
            <Form onSubmit={formik.handleSubmit}>
                <div className="header-form-action">
                    <GroupButton type="btn-back" handleClose={handleClose} />
                    <div className="flex gap-4">
                        {
                            formik.values?.trangThai?.code === CODE_STATUS.TAO_MOI && !isApproveOrReject && item?.id &&
                            (isView
                                ? 
                                    <>
                                        {hasAuthority(PERMISSIONS.DON_XIN_NGHI, PERMISSION_ABILITY.UPDATE) 
                                            && <GroupButton type="btn-edit" handleSaveEdit={() => setIsView(false)} />
                                        }
                                        <GroupButton handleEvent={handleOpenSendlDialog}>{lang("AUTH.GENERAL.SUBMIT_BUTTON")}</GroupButton>
                                    </>
                                : <GroupButton
                                    type="btn-cancel"
                                    handleCloseUpdateDialog={handleCloseUpdateDialog}
                                />
                            )
                        }

                        {
                            (!item?.id || (item?.id && !isView)) &&
                            < GroupButton type="btn-save" />
                        }

                        {item?.id && isView && isApproveOrReject && hasAuthority(PERMISSIONS.DON_XIN_NGHI, PERMISSION_ABILITY.APPROVE) && (
                            <>
                                <GroupButton handleEvent={handleOpenApprovelDialog}>{lang("BTN.APPROVE")}</GroupButton>
                                <GroupButton handleEvent={handleOpenRejectDialog}>{lang("DECLARE_ASSETS.STATUS.REJECT")}</GroupButton>
                            </>
                        )}
                        {isView && item?.id && (
                            <GroupButton handleEvent={() => setIsShowDialogWord(true)}>
                              {lang("GENERAL.EXPORT_WORD")}
                            </GroupButton>
                        )}
                    </div>
                </div>
                <div className="form-content-scroll">
                    <div className="sub-title-form">{item?.id ? lang("MANAGENT_REQUEST.ATTENDANCE_WATCH.EDIT") : lang("MANAGENT_REQUEST.ATTENDANCE_WATCH.ADD")}</div>
                    <Row>
                        <Col xs={6} md={5} xl={5} xxl={3} className="spaces pt-6">
                            <Autocomplete
                                isRequired
                                isReadOnly={isView || isApproveOrReject}
                                lable={lang("PROFILE.NAME")}
                                options={[]}
                                onChange={(value) => handleChangeSelect(VARIABLE_STRING.EMPLOYEE, value)}
                                name="employee"
                                value={formik.values?.employee || null}
                                searchFunction={searchAllEmployee}
                                errors={formik.errors.employee}
                                touched={formik.touched.employee}
                                getOptionLabel={(option) => `${option?.maNhanVien} - ${option?.name}`}
                            />
                        </Col>
                        <Col xs={6} md={2} xl={2} xxl={2} className="spaces pt-6">
                            <TextValidator
                                lable={lang("INPUT.CODE_NEW")}
                                name="maNhanVien"
                                value={formik.values?.maNhanVien || ""}
                                type="text"
                                readOnly={true}
                            />
                        </Col>
                        <Col xs={6} md={5} xl={5} xxl={4} className="spaces pt-6">
                            <TextValidator
                                name="donViCongTac"
                                lable={lang("INPUT.WORKPROCESS.UNIT")}
                                type="text"
                                value={formik.values?.donViCongTacText || ""}
                                readOnly={true}
                                onChange={formik.handleChange}
                            />
                        </Col>
                        <Col xs={6} xl={6} xxl={3} className="spaces pt-6">
                            <TextValidator
                                name="phongBan"
                                lable={lang("INPUT.DEPARTMENTS")}
                                type="text"
                                value={formik.values?.phongBanText || ""}
                                readOnly={true}
                                onChange={formik.handleChange}
                            />
                        </Col>
                        <Col xs={6} xl={6} xxl={5} className="spaces pt-6">
                            <TextValidator
                                name="chucDanh"
                                lable={lang("GENERAL.EMPLOYEE.TITLE")}
                                type="text"
                                value={formik.values?.chucDanhText || ""}
                                readOnly={true}
                                onChange={formik.handleChange}
                            />
                        </Col>
                        <Col xs={6} xl={6} xxl={2} className="spaces pt-6">
                            <TextValidator
                                isRequired
                                name="dienThoai"
                                lable={lang("USER.PHONE")}
                                type="text"
                                value={formik.values?.dienThoai || ""}
                                readOnly={isView || isApproveOrReject}
                                onChange={formik.handleChange}
                                errors={formik.errors?.dienThoai}
                                touched={formik.touched?.dienThoai}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3} md={3} lg={3} xl={3} className="spaces pt-6">
                            <TextValidator
                                isRequired
                                name="ngayNop"
                                lable={lang("MANAGENT_REQUEST.ATTENDANCE_WATCH.DATE_APPLICATION")}
                                type="date"
                                value={formik.values?.ngayNop}
                                readOnly={isView || isApproveOrReject}
                                onChange={formik.handleChange}
                                errors={formik.errors?.ngayNop}
                                touched={formik.touched?.ngayNop}
                            />
                        </Col>
                        <Col sm={3} md={3} lg={3} xl={3} className="spaces pt-6">
                            <TextValidator
                                isRequired
                                name="tuNgay"
                                lable={lang("INPUT.DATE_START")}
                                type="date"
                                value={formik.values?.tuNgay}
                                readOnly={isView || isApproveOrReject}
                                onChange={formik.handleChange}
                                errors={formik.errors?.tuNgay}
                                touched={formik.touched?.tuNgay}
                            />
                        </Col>
                        <Col sm={3} md={3} lg={3} xl={3} className="spaces pt-6">
                            <TextValidator
                                isRequired
                                name="denNgay"
                                lable={lang("INPUT.DATE_END")}
                                type="date"
                                value={formik.values?.denNgay}
                                readOnly={isView || isApproveOrReject}
                                onChange={formik.handleChange}
                                errors={formik.errors?.denNgay}
                                touched={formik.touched?.denNgay}
                            />
                        </Col>
                        <Col sm={3} md={3} lg={3} xl={3} className="spaces pt-6">
                            <TextValidator
                                name="soNgayNghi"
                                lable={lang("HOLIDAY.NUMBER_DATE")}
                                type="text"
                                value={formik.values?.soNgayNghi}
                                readOnly={true}
                                errors={formik.errors?.soNgayNghi}
                                touched={formik.touched?.soNgayNghi}
                            />
                        </Col>
                        <Col sm={3} md={3} lg={3} xl={3} className="spaces pt-6">
                            <Autocomplete
                                lable={lang("RESIGN.TYPE_BREAK")}
                                isReadOnly={isView || isApproveOrReject}
                                isRequired
                                options={LIST_LOAI_NGHI}
                                value={formik.values?.loaiNghi}
                                name="loaiNghi"
                                onChange={(selectedOption) => handleChangeSelect("loaiNghi", selectedOption)}
                                errors={formik.errors?.loaiNghi}
                                touched={formik.touched?.loaiNghi}
                            />
                        </Col>
                        <Col sm={3} md={3} lg={3} xl={3} className="spaces pt-6">
                            <TextValidator
                                name="tyLeHuongLuong"
                                lable={lang("CONTRACT.SALARY_PERCENT")}
                                type="text"
                                value={formik.values?.tyLeHuongLuong}
                                readOnly={true}
                                onChange={formik.handleChange}
                                errors={formik.errors?.tyLeHuongLuong}
                                touched={formik.touched?.tyLeHuongLuong}
                            />
                        </Col>
                        {formik.values?.loaiNghi?.type === TYPE_LOAI_NGHI.NGHI_PHEP && (
                            <>
                                <Col sm={2} md={2} lg={2} xl={2} className="spaces pt-6">
                                    <TextValidator
                                        name="soNPDuocSuDung"
                                        lable={"Số NP được sử dụng"}
                                        type="text"
                                        value={formik.values?.soNPDuocSuDung}
                                        readOnly={true}
                                        onChange={formik.handleChange}
                                        errors={formik.errors?.soNPDuocSuDung}
                                        touched={formik.touched?.soNPDuocSuDung}
                                    />
                                </Col>
                                <Col sm={2} md={2} lg={2} xl={2} className="spaces pt-6">
                                    <TextValidator
                                        name="maCa"
                                        lable={"Số NP đã nghỉ"}
                                        type="text"
                                        value={formik.values?.soNPDaNghi}
                                        readOnly={true}
                                        onChange={formik.handleChange}
                                        errors={formik.errors?.soNPDaNghi}
                                        touched={formik.touched?.soNPDaNghi}
                                    />
                                </Col>
                                <Col sm={2} md={2} lg={2} xl={2} className="spaces pt-6">
                                    <TextValidator
                                        name="soNPconLai"
                                        lable={"Số NP còn lại"}
                                        type="text"
                                        value={formik.values?.soNPConLai}
                                        readOnly={true}
                                        onChange={formik.handleChange}
                                        errors={formik.errors?.soNPConLai}
                                        touched={formik.touched?.soNPConLai}
                                    />
                                </Col>
                            </>
                        )}
                        <Col sm={12} md={12} lg={12} xl={12} className="spaces pt-6">
                            <TextValidator
                                name="noiXinNghi"
                                lable={"Nơi xin nghỉ"}
                                type="text"
                                value={formik.values?.noiXinNghi}
                                readOnly={isView || isApproveOrReject}
                                onChange={formik.handleChange}
                                errors={formik.errors?.noiXinNghi}
                                touched={formik.touched?.noiXinNghi}
                            />
                        </Col>

                        <RowCustom xs={5}>
                            <ColCustom xs={1} className="spaces pt-6">
                                <TextValidator
                                    name="ngayDuyet"
                                    lable={lang("DATE.APPROVE")}
                                    type="date"
                                    value={formik.values?.ngayDuyet}
                                    readOnly={true}
                                    onChange={formik.handleChange}
                                    errors={formik.errors?.ngayDuyet}
                                    touched={formik.touched?.ngayDuyet}
                                />
                            </ColCustom>
                            <ColCustom xs={1} className="spaces pt-6">
                                <Autocomplete
                                    isReadOnly={true}
                                    lable={lang("MANAGENT_REQUEST.ATTENDANCE_WATCH.DEAN")}
                                    options={[]}
                                    value={formik.values?.truongKhoa || null}
                                    name="truongKhoa"
                                    searchFunction={searchNguoiDaiDienKy}
                                    onChange={(value) => handleChangeSelect("truongKhoa", value)}
                                    errors={formik.errors?.truongKhoa}
                                    touched={formik.touched?.truongKhoa}
                                />
                            </ColCustom>
                            <ColCustom xs={1} className="spaces pt-6">
                                <Autocomplete
                                    isReadOnly={true}
                                    lable={lang("TCCB")}
                                    options={[]}
                                    value={formik.values?.phongTccb || null}
                                    name="phongTccb"
                                    searchFunction={searchNguoiDaiDienKy}
                                    onChange={(value) => handleChangeSelect("phongTccb", value)}
                                    errors={formik.errors?.phongTccb}
                                    touched={formik.touched?.phongTccb}
                                />
                            </ColCustom>
                            <ColCustom xs={1} className="spaces pt-6">
                                <Autocomplete
                                    isReadOnly={true}
                                    lable={lang("CHIEF")}
                                    options={[]}
                                    value={formik.values?.thuTruong || null}
                                    name="thuTruong"
                                    searchFunction={searchNguoiDaiDienKy}
                                    onChange={(value) => handleChangeSelect("thuTruong", value)}
                                    errors={formik.errors?.thuTruong}
                                    touched={formik.touched?.thuTruong}
                                />
                            </ColCustom>
                            <ColCustom xs={1} className="spaces pt-6">
                                <Autocomplete
                                    isRequired
                                    lable={lang("GENERAL.STATUS")}
                                    isReadOnly={formik.values?.id || isView || isApproveOrReject}
                                    options={LIST_STATUS}
                                    value={formik.values?.trangThai || null}
                                    name="trangThai"
                                    onChange={(value) => handleChangeSelect("trangThai", value)}
                                    errors={formik.errors?.trangThai}
                                    touched={formik.touched?.trangThai}
                                />
                            </ColCustom>
                        </RowCustom>

                        <Col sm={6} md={6} lg={6} xl={6} className="spaces pt-6">
                            {
                                <TextValidator
                                    name="lyDoXinNghi"
                                    lable={formik.values.loaiNghi?.type === TYPE_LOAI_NGHI.NGHI_NGHI_BU ? "Lý do nghị bù" : "Lý do xin nghỉ"}
                                    type="text"
                                    value={formik.values?.lyDoXinNghi}
                                    readOnly={isView || isApproveOrReject}
                                    errors={formik.errors?.lyDoXinNghi}
                                    touched={formik.touched?.lyDoXinNghi}
                                    as="textarea"
                                    rows="2"
                                    onChange={formik.handleChange}
                                />
                            }
                        </Col>

                        <Col sm={6} md={6} lg={6} xl={6} className="spaces pt-6">
                            <TextValidator
                                name="ghiChu"
                                lable={"Ghi chú"}
                                type="text"
                                value={formik.values?.ghiChu}
                                readOnly={isView || isApproveOrReject}
                                errors={formik.errors?.ghiChu}
                                touched={formik.touched?.ghiChu}
                                as="textarea"
                                rows="2"
                                onChange={formik.handleChange}
                            />
                        </Col>
                        {formik.values?.lyDo &&
                            <Col sm={12} md={12} lg={12} xl={12} className="spaces pt-6">
                                <TextValidator
                                    name="lyDo"
                                    lable={"Lý do"}
                                    type="text"
                                    value={formik.values?.lyDo}
                                    readOnly={true}
                                    errors={formik.errors?.lyDo}
                                    touched={formik.touched?.lyDo}
                                    as="textarea"
                                    rows="2"
                                    onChange={formik.handleChange}
                                />
                            </Col>
                        }
                    </Row>
                </div>
            </Form >

            {
                (shouldOpenSendlDialog || shouldOpenApproveDialog) &&
                <ConfirmDialog
                    show={true}
                    title={lang("DIALOG.CONFIRM")}
                    message={message}
                    cancel={lang("BTN.CLOSE")}
                    onCancelClick={handleCloseForm}
                    yes={lang("BTN.CONFIRM")}
                    onYesClick={handleConfirm}
                />
            }

            {
                shouldOpenRejectDialog &&
                <RefuseDialog
                    show={shouldOpenRejectDialog}
                    field='lyDo'
                    onSubmit={handleConfirm}
                    onCloseClick={handleCloseForm}
                />
            }
            {isShowDialogWord && (
                <ExportWord
                    open={isShowDialogWord}
                    handleClose={() => setIsShowDialogWord(false)}
                    templateList={templateWord || []}
                    getData={getDataPreviewTimekeepingWord}
                    downloadWord={exportTimekeepingWord}
                    getObj={{
                        id: item?.id,
                        wordType: DATA_WORD_TYPES.ATTENDANCE_WATCH,
                        donTuType: TYPE_DON.DON_XIN_NGHI
                    }}
                />
            )}
        </div >
    );
};

export default AttendanceWatchForm

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Modal, Row, Col, Form } from "react-bootstrap";
import { useIntl } from "react-intl";
import { tempPapers as loaiGiayTo } from "../../constants/dialogChildConsts";
import { handleBlurDate, hasAuthority, useCustomIntl } from "../../../utils/functionUtils";
import { addGiayToDinhKem, updateGiayToDinhKem } from "../../services/dialogServices";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from 'yup';
import FileUpload from "../../../components/file-upload/FileUpload";
import { TYPE_OF } from "../../constants/profileDialogConsts";
import { IGiayToDinhKemInfo } from "../../models/profileModels";
import { INIT_GIAY_TO_DINH_KEM } from "../../constants/profileConsts";
import Autocomplete from "../../../components/input/autocomplete";
import TextValidator from "../../../components/input/text-validator";
import { checkInvalidDate } from "../../../utils/validationSchema";
import { RESPONSE_STATUS_CODE } from "../../../constants/moduleConsts";
import { useProfileContext } from "../../ProfileContext";
import AppContext from "../../../../AppContext";
import { GroupButton } from "../../../components/GroupButton";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../../../constants";

type Props = {
  handleCloseTempPapersDialog: () => void;
  handleCloseAndSearch: () => void;
  identify: string;
  isView: boolean;
  tempPapersEdit: IGiayToDinhKemInfo;
};
const TempPapersDialog = (props: Props) => {
  const { handleCloseTempPapersDialog, handleCloseAndSearch, identify, tempPapersEdit, isView } = props;

  const intl = useIntl();
  const { isResign } = useProfileContext();
  const { setPageLoading } = useContext(AppContext);
  const [isEdit, setIsEdit] = useState<boolean>(!isView);

  useEffect(() => {
    formik.setValues({ ...tempPapersEdit, employeeId: identify });
  }, [tempPapersEdit]);

  const validationSchema = Yup.object().shape({
    tenGiayTo: Yup.object().shape({})
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRE" }))
      .nullable(),
    ngayCap: Yup.date()
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRE" }))
      .concat(checkInvalidDate(intl))
      .max(Yup.ref('ngayHetHan'), intl.formatMessage({ id: "VALIDATION.MAXDATE" }) + intl.formatMessage({ id: "INPUT.EXPIRATIONDATE" }))
      .nullable(),
    ngayHetHan: Yup.date()
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRE" }))
      .concat(checkInvalidDate(intl))
      .min(Yup.ref('ngayCap'), intl.formatMessage({ id: "VALIDATION.MINDATE" }) + intl.formatMessage({ id: "INPUT.DATERANGE" }))
      .nullable(),
    noiCap: Yup.string().required(intl.formatMessage({ id: "VALIDATION.REQUIRE" })).nullable()
  });

  const handleSubmit = async (values: IGiayToDinhKemInfo) => {
    const dataSubmit = { ...values, employeeId: identify, id: tempPapersEdit?.id };
    if (typeof identify === TYPE_OF.STRING && identify !== "") {
      try {
        setPageLoading(true);
        const { data } = tempPapersEdit?.id
          ? await updateGiayToDinhKem(tempPapersEdit?.id, dataSubmit)
          : await addGiayToDinhKem(dataSubmit);
        if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
          const message = tempPapersEdit?.id ? "TOAST.EDIT.SUCCESS" : "TOAST.ADD.SUCCESS";
          toast.success(intl.formatMessage({ id: message }));
          handleCloseAndSearch();
        } else {
          toast.warning(data?.message);
        }
      } catch (error) {
        toast.error(intl.formatMessage({ id: "GENERAL.ERROR" }));
      } finally {
        setPageLoading(false);
      }
    } else {
      toast.warning(intl.formatMessage({ id: "MESSAGE.BASIC.FIRST" }));
    }
  };

  const formik = useFormik({
    initialValues: INIT_GIAY_TO_DINH_KEM,
    validationSchema,
    onSubmit: handleSubmit
  })

  const handleChangeSelect = (name: string, value: any) => {
    formik.setFieldValue(name, value)
  }

  return (
    <Modal
      show={true}
      size="xl"
      centered
      aria-labelledby="example-custom-modal-styling-title"
      onHide={handleCloseTempPapersDialog}
      backdrop="static"
    >
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title
            id="example-custom-modal-styling-title"
            className="heading-5"
          >
            {intl.formatMessage({ id: "GENERAL.INFO.TEMP.PAPERS" })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="px-8 pt-2">
            <Col xs={4}>
              <Autocomplete
                lable={useCustomIntl("GENERAL.INFO.TEMP.PAPERS.NAME")}
                options={loaiGiayTo}
                value={formik.values?.tenGiayTo || null}
                name="tenGiayTo"
                isRequired
                isReadOnly={!isEdit}
                onChange={(selectedOption) =>
                  handleChangeSelect("tenGiayTo", selectedOption)
                }
                errors={formik.errors?.tenGiayTo}
                touched={formik.touched?.tenGiayTo}
              />
            </Col>
            <Col xs={4}>
              <TextValidator
                lable={useCustomIntl("INPUT.DATERANGE")}
                name="ngayCap"
                value={formik.values?.ngayCap || ""}
                type="date"
                isRequired
                readOnly={!isEdit}
                onChange={formik.handleChange}
                onBlur={() =>
                  handleBlurDate(
                    formik.setFieldValue,
                    formik.values?.ngayCap,
                    "ngayCap"
                  )
                }
                errors={formik.errors?.ngayCap}
                touched={formik.touched?.ngayCap}
              />
            </Col>
            <Col xs={4}>
              <TextValidator
                lable={useCustomIntl("INPUT.EXPIRATIONDATE")}
                name="ngayHetHan"
                value={formik.values?.ngayHetHan || ""}
                type="date"
                isRequired
                readOnly={!isEdit}
                onChange={formik.handleChange}
                onBlur={() =>
                  handleBlurDate(
                    formik.setFieldValue,
                    formik.values?.ngayHetHan,
                    "ngayHetHan"
                  )
                }
                errors={formik.errors?.ngayHetHan}
                touched={formik.touched?.ngayHetHan}
              />
            </Col>
          </Row>
          <Row className="px-8 pt-6">
            <Col xs={4}>
              <TextValidator
                lable={useCustomIntl("INPUT.PLACERANGE")}
                name="noiCap"
                value={formik.values?.noiCap || ""}
                type="text"
                isRequired
                readOnly={!isEdit}
                onChange={formik.handleChange}
                errors={formik.errors?.noiCap}
                touched={formik.touched?.noiCap}
              />
            </Col>
            <Col xs={4}>
              <TextValidator
                lable={useCustomIntl("INPUT.WORKEXPERIENCE.NOTE")}
                name="ghiChu"
                value={formik.values?.ghiChu || ""}
                type="text"
                readOnly={!isEdit}
                onChange={formik.handleChange}
              />
            </Col>
            <Col xs={4}>
              <FileUpload
                label="CONTRACT.ATTACHMENTS"
                setFieldValue={(data: string) => {
                  formik.setFieldValue("attachment", data);
                }}
                isReadOnly={!isEdit}
                fileName={formik.values.attachment}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="flex-center">
          <GroupButton type="btn-cancel" handleCloseUpdateDialog={handleCloseTempPapersDialog} />
          {!isResign &&
            (isEdit ? (
              <GroupButton type="btn-save" />
            ) : (hasAuthority(PERMISSIONS.EMPLOYEE, PERMISSION_ABILITY.UPDATE) &&
              <GroupButton type="btn-edit" handleSaveEdit={() => setIsEdit(true)} />
            ))}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default TempPapersDialog;

import { Col, Form, Row } from "react-bootstrap";
import { IEmployeeJoin, IExpenses, IWelfare } from "../models/welfareModels";
import { useFormik } from "formik";
import { INIT_WELFARE, formEvent, groupWelfare } from "../constants/welfareConsts";
import { GroupButton } from "../../components/GroupButton";
import TextValidator from "../../components/input/text-validator";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import Autocomplete from "../../components/input/autocomplete";
import { convertNumberPrice, convertTextPrice, exportToFile, handleBlurDate, hasAuthority } from "../../utils/functionUtils";
import { EXPORT_FILE_NAME, MISSION_STATUS, MISSION_STATUS_CODE, RESPONSE_STATUS_CODE, TYPE, VARIABLE_STRING } from "../../constants/moduleConsts";
import { EmployeeJoinDialog } from "./EmployeeJoinDialog";
import { useContext, useEffect, useState } from "react";
import { ActualCostDialog } from "./ActualCostDialog";
import { ColCustom, RowCustom } from "../../components/grid-component";
import AppContext from "../../../AppContext";
import {
  createWelfare,
  getEmployeeJoinByWelfareId,
  getExpensesByWelfareId,
  updateWelfare
} from "../services/welfareServices";
import { toast } from "react-toastify";
import * as Yup from "yup";
import TableCustom from "../../components/table-custom/TableCustom";
import { convertDataEmployeeSelect, convertDataSubmit, convertEmployeeJoin } from "../utils/welfareUtils";
import { ConfirmDialog } from "../../components/dialogs";
import { STATUS_NV } from "../../profile/constants/dialogChildConsts";
import { exportPhucLoiChiTiet } from "../../services/exportFileServices";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../../constants";
import { KTSVG } from "../../../../_metronic/helpers";
import TableCollapseCustom, { columnNamesCollapseType } from "../../components/table-collapse-custom/TableCollapseCustom";
import { SelectEmployeeByDepartment } from "../../components/dialogs";
import { searchListWorkUnit } from "../../services/services";

type IProps = {
  welfare: IWelfare;
  isView: boolean;
  setIsView: (value: boolean) => void;
  handleCloseDialog: () => void;
  handleOpenUpdateDialog: () => void;
  handleCloseUpdateDialog: () => void;
  handleCloseAndSearch: () => void;
};

type ITotalCost = {
  totalCostEmployee: number;
  totalMoneySupport: number;
};

export const WelfareForm: React.FC<IProps> = (props) => {
  const {
    welfare,
    isView,
    handleCloseDialog,
    handleCloseUpdateDialog,
    handleOpenUpdateDialog,
    handleCloseAndSearch
  } = props;
  const { lang } = useMultiLanguage();

  const [isOpenEmployeeJoinDialog, setIsOpenEmployeeJoinDialog] = useState<boolean>(false);
  const [employeeJoinInfo, setEmployeeJoinInfo] = useState<IEmployeeJoin>({} as IEmployeeJoin);
  const [isOpenActualCostDialog, setIsOpenActualCostDialog] = useState<boolean>(false);
  const [shouldOpenSelectEmployeeDialog, setShouldOpenSelectEmployeeDialog] = useState<boolean>(false);
  const [expensesInfo, setExpensesInfo] = useState<IExpenses>({} as IExpenses);
  const [expensesList, setExpensesList] = useState<IExpenses[]>([]);
  const [totalCost, setTotalCost] = useState<ITotalCost>({} as ITotalCost);
  const [employeeChecked, setEmployeeChecked] = useState<any[]>([]);
  const [employeeJoinData, setEmployeeJoinData] = useState<any[]>([]);
  const [isOpenDialogApply, setIsOpenDialogApply] = useState<boolean>(false);
  const [isApplyMoney, setIsApplyMoney] = useState<boolean>(false);
  const { setPageLoading } = useContext(AppContext);

  useEffect(() => {
    if (welfare?.id) {
      convertData(welfare);
      updateEmployeeJoin();
      updateExpense();
    } else {
      const statusDefault = MISSION_STATUS.find((item: any) => item.code === MISSION_STATUS_CODE.UNFULFILLED);
      formik.setFieldValue(VARIABLE_STRING.TRANG_THAI, statusDefault);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [welfare]);

  const convertData = (data: IWelfare) => {
    const welfareConverted = {
      ...data,
      donViApDung: data?.donViApDungId
        ? {
          id: data?.donViApDungId || "",
          name: data?.donViApDungText || ""
        }
        : null
    };
    formik.setValues(welfareConverted);
  };

  const validationSchema = Yup.object().shape({
    nhomPhucLoi: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    tenPhucLoi: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    trangThai: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    donViApDung: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    ngayBatDau: Yup.date()
      .required(lang("VALIDATION.REQUIRE"))
      .max(Yup.ref("ngayKetThuc"), lang("VALIDATION.START_DATE_NOT_BIGEST_END_DATE"))
      .nullable(),
    ngayKetThuc: Yup.date()
      .required(lang("VALIDATION.REQUIRE"))
      .min(Yup.ref("ngayBatDau"), lang("VALIDATION.END_DATE_NOT_SMALL_START_DATE"))
      .nullable(),
    hinhThucThucHien: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    soNguoiDuKien: Yup.number().min(0, lang("VALIDATON.CAN_NOT_MINUS")).nullable()
  });

  const handleSubmit = async (values: IWelfare) => {
    if (employeeJoinData?.length === 0) return toast.warning(lang("TOAST.PLEASE_ADD_LIST_JOIN"));
    try {
      setPageLoading(true);
      const dataSubmit = convertDataSubmit(values, employeeJoinData, expensesList);
      const { data } = await (!values?.id ? createWelfare(dataSubmit) : updateWelfare(dataSubmit, values?.id || ""));
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        let message = !values?.id ? "TOAST.ADD.SUCCESS" : "TOAST.EDIT.SUCCESS";
        toast.success(lang(message));
        handleCloseAndSearch();
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: INIT_WELFARE,
    onSubmit: handleSubmit,
    validationSchema
  });

  const handleOpenEmployeeDialog = (row: any) => {
    setIsOpenEmployeeJoinDialog(true);
    setEmployeeJoinInfo(row);
  };

  const handleDialogClose = (
    setShouldOpenDialog: (value: React.SetStateAction<boolean>) => void,
    setData: (value: any) => void
  ): void => {
    setShouldOpenDialog(false);
    setData({});
  };

  const handleChangeSelect = (name: string, value: any) => {
    switch (name) {
      case VARIABLE_STRING.DON_VI_AP_DUNG:
        formik.setValues({
          ...formik.values,
          [name]: value,
          donViApDungId: value?.id || "",
          donViApDungText: value?.name || ""
        });
        break;
      default:
        formik.setFieldValue(name, value);
        break;
    }
  };

  const handleChangeMoney = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, Number(convertTextPrice(value)));
  };

  useEffect(() => {
    let checkApplyEmployee = employeeJoinData
      .map((item: any) => item?.data).flat()
      .every(
        (item: any) =>
          (item?.soTienNhanVienDong === formik.values?.soTienNvDong || 0) &&
          (item?.soTienDonViHoTro === formik.values?.soTienDvHoTro || 0)
      );
    setIsApplyMoney(
      Boolean(
        employeeJoinData?.length !== 0 &&
        (formik.values?.soTienNvDong || formik.values?.soTienDvHoTro) &&
        !checkApplyEmployee
      )
    );
  }, [formik.values?.soTienNvDong, formik.values?.soTienDvHoTro, employeeJoinData]);

  // Nhân viên tham gia
  const updateEmployeeJoin = async () => {
    if (!welfare?.id) return;
    try {
      setPageLoading(true);
      const { data } = await getEmployeeJoinByWelfareId(welfare?.id || "");
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setEmployeeJoinData(data?.data || []);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const calcMoneyTotal = (data: any[]) => {
    let dataCalc = data?.map((item) => item?.data).flat();
    let totalMoney: ITotalCost = dataCalc?.reduce((acc: any, cur) => {
      acc.totalCostEmployee = (acc.totalCostEmployee || 0) + (cur?.soTienNhanVienDong || 0);
      acc.totalMoneySupport = (acc.totalMoneySupport || 0) + (cur?.soTienDonViHoTro || 0);
      return acc;
    }, {});
    setTotalCost({ ...totalMoney });
  };

  useEffect(() => {
    calcMoneyTotal(employeeJoinData || []);
    formik.setFieldValue("soNguoiDuKien", employeeJoinData.map((item) => item?.data).flat().length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeJoinData]);

  const handleSetEmployeeJoin = (data: any[]) => {
    let dataConverted = convertEmployeeJoin(
      data,
      formik.values?.soTienNvDong || 0,
      formik.values?.soTienDvHoTro || 0,
      welfare?.id || ""
    );
    setEmployeeJoinData(dataConverted);
  };

  const handleDeleteEmployeeSelect = () => {
    const newSelectedData = employeeJoinData?.map((row: any) => {
      if (row?.isParentChecked) return null;
      return {
        ...row,
        data: (row?.data || []).filter(
          (oldItem: any) => !employeeChecked?.some((removeItem: any) => oldItem?.employeeId === removeItem?.employeeId)
        )
      };
    });

    let newDataFilter = newSelectedData?.filter((item: any) => item !== null);

    setEmployeeJoinData(newDataFilter || []);
    setEmployeeChecked([]);
  };

  const handleConfirmedApply = () => {
    if (employeeJoinData?.length === 0) {
      toast.warning(lang("TOAST.NO_EMPLOYEES_APPLY"));
    } else {
      handleSetEmployeeJoin(employeeJoinData || []);
    }

    setIsOpenDialogApply(false);
  };

  // Khoản chi
  const updateExpense = async () => {
    if (!welfare?.id) return;
    try {
      setPageLoading(true);
      const { data } = await getExpensesByWelfareId(welfare?.id || "");
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setExpensesList(data?.data || []);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleOpenEditExpenses = (row: any, index: number) => {
    setIsOpenActualCostDialog(true);
    setExpensesInfo(row);
  };

  const handleAddExpense = (data: IExpenses) => {
    setExpensesList([...expensesList, data]);
  };

  const handleEditExpense = (dataEdit: IExpenses) => {
    let expenseAfterEdit = expensesList.map(item => {
      return item.id === dataEdit.id ? dataEdit : item;
    });

    setExpensesList(expenseAfterEdit);
  };

  const handleDeleteExpense = (ids: string) => {
    const listIdRemove = ids.split(',');
    let expenseAfterRemove = expensesList?.filter(item => !listIdRemove.includes(item?.id ?? ""));
    setExpensesList(expenseAfterRemove);
    return true;
  };

  const handleExport = () => {
    exportToFile({
      exportAPI: () => exportPhucLoiChiTiet(welfare?.id || ""),
      fileName: EXPORT_FILE_NAME.CHI_TIET_PHUC_LOI,
      setPageLoading
    });
  };

  const employeeJoinColumns: columnNamesCollapseType[] = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number) => <span>{index + 1}</span>
    },
    {
      name: lang("EMPLOYEE.ID"),
      field: "maNhanVien",
      headerCellProps: {
        minWidth: "200px"
      }
    },
    {
      name: lang("GENERAL.FULLNAME"),
      field: "ten",
      headerCellProps: {
        minWidth: "200px"
      },
      bodyCellProps: {
        minWidth: "200px",
        textAlign: "left"
      }
    },
    {
      name: lang("WELFARE.WORK_UNIT"),
      field: "donViApDungText",
      headerCellProps: {
        minWidth: "300px"
      },
      bodyCellProps: {
        textAlign: "left"
      }
    },
    {
      name: lang("PROFILE.DEPARTMENT"),
      field: "phongBanText",
      headerCellProps: {
        minWidth: "300px"
      },
      bodyCellProps: {
        textAlign: "left"
      }
    },
    {
      name: lang("WELFARE.POSITION"),
      field: "chucVuText",
      headerCellProps: {
        minWidth: "200px"
      },
      bodyCellProps: {
        textAlign: "left"
      }
    },
    {
      name: lang("WELFARE.AMOUNT"),
      field: "soTienNhanVienDong",
      headerCellProps: {
        minWidth: "200px"
      },
      bodyCellProps: {
        textAlign: "left"
      },
      render: (row) => <span>{convertNumberPrice(row?.soTienNhanVienDong)}</span>
    },
    {
      name: lang("WELFARE.AMOUNT.SUPPORT"),
      field: "soTienDonViHoTro",
      headerCellProps: {
        minWidth: "200px"
      },
      bodyCellProps: {
        textAlign: "left"
      },
      render: (row) => <span>{convertNumberPrice(row?.soTienDonViHoTro)}</span>
    }
  ];

  const actualCostColumns = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("WELFARE.ACTUAL_COST.NAME"),
      field: "tenKhoanChi",
      headerStyle: {
        minWidth: "300px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("WELFARE.ACTUAL_COST.AMOUNT"),
      field: "soTien",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row: any) => <span>{convertNumberPrice(row?.soTien)}</span>
    },
    {
      name: lang("WELFARE.ACTUAL_COST.APPROVED_PEOPLE"),
      field: "tenNguoiDuyet",
      headerStyle: {
        minWidth: "200px"
      }
    },
    {
      name: lang("WELFARE.ACTUAL_COST.NOTE"),
      field: "note",
      headerStyle: {
        minWidth: "300px"
      },
      cellStyle: {
        textAlign: "left"
      }
    }
  ];

  const columnsEmployeeDepartment = [
    {
      name: lang("INPUT.CODE"),
      field: "custom",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
      render: (row: any) => (
        <span>
          <p className="color-steel-blue fw-600 spaces p-0 m-0">{row?.maNhanVien}</p>
          <p className="spaces p-0 m-0">{row?.ten}</p>
        </span>
      )
    },
    {
      name: lang("INPUT.SALARY.POSITION"),
      field: "chucVuText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("WELFARE.WORK_UNIT"),
      field: "donViApDungText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      }
    }
  ];

  const columnsSelected = [
    {
      name: lang("INPUT.CODE"),
      field: "custom",
      headerCellProps: {
        minWidth: "200px"
      },
      bodyCellProps: {
        textAlign: "left"
      },
      render: (row: any) => (
        <span>
          <p className="color-steel-blue fw-600 spaces p-0 m-0">{row?.maNhanVien}</p>
          <p className="spaces p-0 m-0">{row?.ten}</p>
        </span>
      )
    },
    {
      name: lang("INPUT.SALARY.POSITION"),
      field: "chucVuText",
      headerCellProps: {
        minWidth: "200px"
      },
      bodyCellProps: {
        textAlign: "left"
      }
    },
    {
      name: lang("WELFARE.WORK_UNIT"),
      field: "donViApDungText",
      headerCellProps: {
        minWidth: "200px"
      },
      bodyCellProps: {
        textAlign: "left"
      }
    }
  ];

  return (
    <div className="welfare-form">
      <Form onSubmit={formik.handleSubmit}>
        <div className="header-form-action">
          <GroupButton
            type="btn-back"
            handleClose={handleCloseDialog}
          />
          <div className="flex">
            {welfare?.id && (
              <GroupButton className="spaces mr-16" outline handleEvent={handleExport}>
                <KTSVG path="/media/icons/export-excel.svg" /> {lang("BTN.EXPORT")}
              </GroupButton>
            )}
            {isView ? (hasAuthority(PERMISSIONS.PHUC_LOI_MENU, PERMISSION_ABILITY.UPDATE) &&
              <GroupButton type="btn-edit" handleSaveEdit={handleOpenUpdateDialog} /> 
            )
            : (
              <>
                {welfare.id && (
                  <GroupButton type="btn-cancel" className="me-2" handleCloseUpdateDialog={handleCloseUpdateDialog} />
                )}
                <GroupButton
                  type="btn-save"
                  handleClose={handleCloseUpdateDialog}
                />
              </>
            )}
          </div>
        </div>
        <div className="form-content-scroll">
          <Row>
            <Col
              xs={12}
              className="relative z-index-2"
            >
              <div className="sub-title-form">{welfare?.id ? lang("WELFARE.EDIT") : lang("WELFARE.ADD")}</div>
              <div className="spaces mb-6">
                <span className="fs-3 fw-bold">{lang("GENERAL.INFO")}</span>
              </div>
              <RowCustom
                xs={5}
                md={3}
                lg={3}
                xl={4}
              >
                <ColCustom xs={1}>
                  <TextValidator
                    name="tenPhucLoi"
                    isRequired
                    lable={lang("WELFARE.NAME")}
                    type="text"
                    value={formik.values?.tenPhucLoi}
                    onChange={formik.handleChange}
                    readOnly={isView}
                    touched={formik.touched?.tenPhucLoi}
                    errors={formik.errors?.tenPhucLoi}
                  />
                </ColCustom>
                <ColCustom xs={1}>
                  <Autocomplete
                    isRequired
                    lable={lang("WELFARE.GROUP")}
                    options={groupWelfare}
                    isReadOnly={isView}
                    value={formik.values?.nhomPhucLoi || null}
                    name="nhomPhucLoi"
                    onChange={(value) => handleChangeSelect(VARIABLE_STRING.NHOM_PHUC_LOI, value)}
                    isClearable={false}
                    touched={formik.touched?.nhomPhucLoi}
                    errors={formik.errors?.nhomPhucLoi}
                  />
                </ColCustom>
                <ColCustom xs={1}>
                  <Autocomplete
                    isRequired
                    lable={lang("WELFARE.APPLY.UNIT")}
                    options={[]}
                    isReadOnly={isView}
                    value={formik.values?.donViApDung || null}
                    name="donViApDung"
                    onChange={(value) => handleChangeSelect(VARIABLE_STRING.DON_VI_AP_DUNG, value)}
                    searchFunction={searchListWorkUnit}
                    isClearable={false}
                    touched={formik.touched?.donViApDung}
                    errors={formik.errors?.donViApDung}
                  />
                </ColCustom>
                <ColCustom xs={1}>
                  <TextValidator
                    name="ngayBatDau"
                    isRequired
                    lable={lang("WELFARE.DATE.START")}
                    type="date"
                    value={formik.values?.ngayBatDau}
                    readOnly={isView}
                    onChange={formik.handleChange}
                    onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.ngayBatDau, "ngayBatDau")}
                    errors={formik?.errors?.ngayBatDau}
                    touched={formik?.touched?.ngayBatDau}
                  />
                </ColCustom>
                <ColCustom xs={1}>
                  <TextValidator
                    name="ngayKetThuc"
                    isRequired
                    lable={lang("WELFARE.DATE.END")}
                    type="date"
                    value={formik.values?.ngayKetThuc}
                    readOnly={isView}
                    onChange={formik.handleChange}
                    onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.ngayKetThuc, "ngayKetThuc")}
                    errors={formik?.errors?.ngayKetThuc}
                    touched={formik?.touched?.ngayKetThuc}
                  />
                </ColCustom>
                <ColCustom xs={1}>
                  <TextValidator
                    name="mucDichToChuc"
                    lable={lang("WELFARE.ORGANIZATION.PURPOSE")}
                    type="text"
                    value={formik.values?.mucDichToChuc}
                    onChange={formik.handleChange}
                    readOnly={isView}
                  />
                </ColCustom>
                <ColCustom xs={1}>
                  <Autocomplete
                    isRequired
                    lable={lang("WELFARE.IMPLEMENT.FORM")}
                    options={formEvent}
                    isReadOnly={isView}
                    value={formik.values?.hinhThucThucHien || null}
                    name="hinhThucThucHien"
                    onChange={(value) => handleChangeSelect(VARIABLE_STRING.HINH_THUC_THUC_HIEN, value)}
                    errors={formik?.errors?.hinhThucThucHien}
                    touched={formik?.touched?.hinhThucThucHien}
                  />
                </ColCustom>
                <ColCustom xs={1}>
                  <TextValidator
                    name="soNguoiDuKien"
                    lable={lang("WELFARE.EXPECTED.PEOPLE")}
                    type="number"
                    value={formik.values?.soNguoiDuKien || null}
                    onChange={formik.handleChange}
                    readOnly={true}
                    errors={formik?.errors?.soNguoiDuKien}
                    touched={formik?.touched?.soNguoiDuKien}
                  />
                </ColCustom>
                <ColCustom xs={1}>
                  <Autocomplete
                    isRequired
                    lable={lang("WELFARE.STATUS")}
                    options={MISSION_STATUS}
                    isReadOnly={isView}
                    value={formik.values?.trangThai || null}
                    name="trangThai"
                    onChange={(value) => handleChangeSelect(VARIABLE_STRING.TRANG_THAI, value)}
                    errors={formik?.errors?.trangThai}
                    touched={formik?.touched?.trangThai}
                  />
                </ColCustom>
                <ColCustom
                  xs={2}
                  md={3}
                  xl={3}
                >
                  <TextValidator
                    name="diaDiemToChuc"
                    lable={lang("WELFARE.ORGANIZATION.PLACE")}
                    type="text"
                    value={formik.values?.diaDiemToChuc}
                    onChange={formik.handleChange}
                    readOnly={isView}
                  />
                </ColCustom>
              </RowCustom>
            </Col>
            <Col xs={12}>
              <RowCustom
                xs={12}
                className="spaces mb-10 mt-30"
                gap="30px 60px"
              >
                <ColCustom
                  xs={6}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={6}
                >
                  <Row className="align-items-center">
                    <Col
                      xs={12}
                      className="flex flex-space-between flex-middle spaces mb-6"
                    >
                      <h5 className="fw-bold fs-18 spaces mb-8">{lang("WELFARE.AMOUNT.EMPLOYEE.EXPECTED.TOTAL")}</h5>
                    </Col>
                    <Col xs={4}>
                      <TextValidator
                        name="soTienNvDong"
                        lable={lang("WELFARE.AMOUNT")}
                        type="text"
                        onChange={handleChangeMoney}
                        value={convertNumberPrice(formik.values?.soTienNvDong || null)}
                        readOnly={isView}
                      />
                    </Col>
                    <Col xs={4}>
                      <TextValidator
                        name="soTienDvHoTro"
                        lable={lang("WELFARE.AMOUNT.SUPPORT")}
                        type="text"
                        onChange={handleChangeMoney}
                        value={convertNumberPrice(formik.values?.soTienDvHoTro || null)}
                        readOnly={isView}
                      />
                    </Col>
                    <Col xs={4}>
                      <TextValidator
                        name="tongChiPhi"
                        lable={lang("WELFARE.AMOUNT.TOTAL")}
                        type="text"
                        value={convertNumberPrice(
                          (formik.values?.soTienNvDong || 0) + (formik.values?.soTienDvHoTro || 0)
                        )}
                        readOnly={true}
                      />
                    </Col>
                    {!isView && isApplyMoney ? (
                      <Col
                        xs={12}
                        className="spaces mt-12 flex-center"
                      >
                        <GroupButton handleEvent={() => setIsOpenActualCostDialog(true)}>
                          {lang("BTN.APPLY_ALL_EMPLOYEES")}
                        </GroupButton>
                      </Col>
                    ) : (
                      <></>
                    )}
                  </Row>
                </ColCustom>
                <ColCustom
                  xs={6}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={6}
                >
                  <Row className="align-items-center">
                    <Col
                      xs={12}
                      className="spaces mb-6"
                    >
                      <h5 className="fw-bold fs-18 spaces mb-8">{lang("WELFARE.AMOUNT.EXPECTED.TOTAL")}</h5>
                    </Col>
                    <Col xs={4}>
                      <TextValidator
                        name="totalCostEmployee"
                        lable={lang("WELFARE.AMOUNT.EMPLOYEE.TOTAL")}
                        type="text"
                        value={convertNumberPrice(totalCost?.totalCostEmployee || 0)}
                        readOnly={true}
                      />
                    </Col>
                    <Col xs={4}>
                      <TextValidator
                        name="totalMoneySupport"
                        lable={lang("WELFARE.AMOUNT.SUPPORT")}
                        type="text"
                        value={convertNumberPrice(totalCost?.totalMoneySupport || 0)}
                        readOnly={true}
                      />
                    </Col>
                    <Col xs={4}>
                      <TextValidator
                        name=""
                        lable={lang("WELFARE.AMOUNT.TOTAL")}
                        type="text"
                        value={convertNumberPrice(
                          (totalCost?.totalCostEmployee || 0) + (totalCost?.totalMoneySupport || 0)
                        )}
                        readOnly={true}
                      />
                    </Col>
                  </Row>
                </ColCustom>
              </RowCustom>
            </Col>
            <Col
              xs={12}
              className="pt-2"
            >
              <TableCollapseCustom
                title={lang("WELFARE.EMPLOYEE.LIST")}
                columnNameList={employeeJoinColumns}
                data={employeeJoinData || []}
                nameParent="phongBanText"
                nameChildren="data"
                selectData={setEmployeeChecked}
                setData={setEmployeeJoinData}
                selectionMode="multiple"
                isSelect={true}
                buttonAdd={!isView}
                handleOpenDialog={() => setShouldOpenSelectEmployeeDialog(true)}
                handleDoubleClick={handleOpenEmployeeDialog}
                handleDelete={handleDeleteEmployeeSelect}
                notDelete={isView}
                uniquePrefix="employeeId"
                disabledSelect={isView}
              />
            </Col>
            <Col
              xs={12}
              className="spaces pt-2 pb-16"
            >
              <TableCustom
                id="actualCost"
                title={lang("WELFARE.ACTUAL_COST.LIST")}
                isActionTableTab
                buttonAdd={!isView}
                buttonExportExcel={false}
                notDelete={isView}
                noToolbar={true}
                data={expensesList || []}
                columns={actualCostColumns}
                justFilter={true}
                noPagination={true}
                handleOpenDialog={() => setIsOpenActualCostDialog(true)}
                handleDoubleClick={handleOpenEditExpenses}
                unSelectedAll={true}
                type={TYPE.MULTILINE}
                handleDelete={handleDeleteExpense}
                checkedInit={false}
              />
            </Col>
          </Row>
        </div>
      </Form>
      {shouldOpenSelectEmployeeDialog && (
        <SelectEmployeeByDepartment
          title={lang("GENERAL.LIST_EMPLOYEE_BY_DEPARTMENT")}
          open={shouldOpenSelectEmployeeDialog}
          handleClose={() => setShouldOpenSelectEmployeeDialog(false)}
          parentSelectedData={employeeJoinData || []}
          setParentSelectedData={handleSetEmployeeJoin}
          funcConvert={convertDataEmployeeSelect}
          prefixNameChildren="data"
          columnsEmployeeDepartment={columnsEmployeeDepartment}
          columnsSelected={columnsSelected}
          searchEmployeeObject={{
            trangThaiLamViec: Object.values(STATUS_NV)
              .filter((status) => status !== STATUS_NV.THOI_VIEC)
              .join(",")
          }}
        />
      )}
      {isOpenEmployeeJoinDialog && (
        <EmployeeJoinDialog
          employeeJoin={employeeJoinInfo}
          handleCloseDialog={() => handleDialogClose(setIsOpenEmployeeJoinDialog, setEmployeeJoinInfo)}
          isView={isView}
          setEmployeeJoinData={setEmployeeJoinData}
          employeeJoinData={employeeJoinData}
        />
      )}
      {isOpenActualCostDialog && (
        <ActualCostDialog
          expenses={expensesInfo}
          handleCloseDialog={() => handleDialogClose(setIsOpenActualCostDialog, setExpensesInfo)}
          isView={isView}
          handleAdd={handleAddExpense}
          handleEdit={handleEditExpense}
        />
      )}
      {isOpenDialogApply && (
        <ConfirmDialog
          show={isOpenDialogApply}
          title={lang("DIALOG.CONFIRM.APPLY.TILTE")}
          message={lang("DIALOG.CONFIRM.APPLY.DESCRIPTION")}
          yes={lang("BTN.CONFIRM")}
          onYesClick={handleConfirmedApply}
          cancel={lang("BTN.CANCEL")}
          onCancelClick={() => setIsOpenDialogApply(false)}
        />
      )}
    </div>
  );
};

import { useEffect, useState } from "react";
import useMultiLanguage from "../../../../../hook/useMultiLanguage";
import TableCustom, { columnNamesType } from "../../../../components/table-custom/TableCustom";
import { MISSION_STATUS, MISSION_STATUS_CODE, RESPONSE_STATUS_CODE, TYPE } from "../../../../constants/moduleConsts";
import { toast } from "react-toastify";
import BonusViewDialog from "./BonusViewDialog";
import { deleteBonus, getBonus } from "../../../../bonus/services/bonusServices";
import { checkStatus, hasAuthority } from "../../../../utils/functionUtils";
import { BonusInfo } from "../../../../bonus/models/bonusModels";
import { INIT_BONUS_INFO } from "../../../../bonus/constants/bonusConsts";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../../../../constants";
interface Iprops {
  isCallApi: boolean;
  employeeProfiles: any;
  isView: boolean;
}

function BonusTable(props: Iprops) {
  const { lang } = useMultiLanguage();
  const { isCallApi, employeeProfiles, isView } = props;

  const [bonusList, setBonusList] = useState<any[]>([]);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [bonusItem, setBonusItem] = useState<BonusInfo>(INIT_BONUS_INFO);
  const [shouldOpenViewDialog, setShouldBonusViewDialog] = useState<boolean>(false);

  useEffect(() => {
    updatePageData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCallApi])

  const updatePageData = async (searchObj?: any) => {
    if (!isCallApi) return;
    try {
      const searchData = {
        ...searchObj,
        employeeId: employeeProfiles?.id
      }
      const { data } = await getBonus(searchData);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setBonusList(data?.data?.content || []);
        setTotalPages(data?.data?.pageable?.totalPages || 0);
        setTotalElements(data?.data?.pageable?.totalElements || 0);
        setNumberOfElements(data?.data?.pageable?.numberOfElements || 0);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const handleOpenDialog = (row: any) => {
    let khenThuongList = [
      {
        chucVuId: employeeProfiles?.chucVuId,
        chucVuText: employeeProfiles?.chucVuText,
        employeeId: employeeProfiles?.id,
        khenThuongId: row?.id,
        maNhanVien: employeeProfiles?.maNhanVien,
        name: employeeProfiles?.name,
        phongBanId: employeeProfiles?.phongBanId,
        phongBanText: employeeProfiles?.phongBanText,
        viTriCongViecId: employeeProfiles?.viTriCongViecId,
        viTriCongViecText: employeeProfiles?.viTriCongViecText,
      }
    ]

    row.khenThuongList = khenThuongList;
    row.isProfile = true;
    setBonusItem(row);
    setShouldBonusViewDialog(true);
  };

  const handleCloseDialog = () => {
    setShouldBonusViewDialog(false);
  };

  const handleCloseAndSearch = () => {
    updatePageData();
    handleCloseDialog();
  }

  const columns: columnNamesType[] = [
    {
      name: lang("TABLE.INDEX"),
      field: "stt",
      headerStyle: {
        minWidth: "100px"
      },
      render: (row: any, index: number, STT: number) => <span>{STT}</span>
    },
    {
      name: lang("GENERAL.STATUS"),
      field: "trangThai",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row: any) => (
        <span className={`${checkStatus(MISSION_STATUS, row?.trangThai?.code)} status`}>
          {row?.trangThai?.name}
        </span>
      )
    },
    {
      name: lang("APPRECIATIVE.NAME"),
      field: "dotKhenThuong",
      headerStyle: {
        minWidth: "200px"
      }
    },
    {
      name: lang("APPRECIATIVE.YEAR"),
      field: "namKhenThuong",
      headerStyle: {
        minWidth: "200px"
      }
    },
    {
      name: lang("APPRECIATIVE.LOAIKHENTHUONGTEXT"),
      field: "loaiKhenThuong",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row) => <span>{row?.loaiKhenThuong?.name}</span>
    },
    {
      name: lang("APPRECIATIVE.DOITUONGKHENTHUONGTEXT"),
      field: "doiTuongKhenThuong",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row) => <span>{row?.doiTuongKhenThuong?.name}</span>
    },
    {
      name: lang("APPRECIATIVE.HINHTHUCKHENTHUONGTEXT"),
      field: "hinhThucKhenThuongText",
      headerStyle: {
        minWidth: "400px"
      }
    },
    {
      name: lang("APPRECIATIVE.SOQUYETDINH"),
      field: "soQuyetDinh",
      headerStyle: {
        minWidth: "200px"
      }
    },
    {
      name: lang("APPRECIATIVE.AGENCIES_UNIT_DECITION"),
      field: "donViBanHanhText",
      headerStyle: {
        minWidth: "280px"
      }
    }
  ];

  const handleDelete = async (ids: any) => {
    try {
      const { data } = await deleteBonus(ids);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("TOAST.DELETE.BONUS.SUCCESS"));
        updatePageData();
        return true;
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  return (
    <div>
      <TableCustom
        title={lang("GENERAL.APPRECIATIVE.LIST")}
        isActionTableTab
        data={bonusList}
        columns={columns}
        handleOpenDialog={() => handleOpenDialog(INIT_BONUS_INFO)}
        fixedColumnsCount={3}
        type={TYPE.MULTILINE}
        handleDoubleClick={handleOpenDialog}
        totalPages={totalPages}
        totalElements={totalElements}
        numberOfElements={numberOfElements}
        noPagination
        buttonAdd={!isView && hasAuthority(PERMISSIONS.KHEN_THUONG, PERMISSION_ABILITY.CREATE)}
        notDelete={isView || !hasAuthority(PERMISSIONS.KHEN_THUONG, PERMISSION_ABILITY.DELETE)}
        handleDelete={handleDelete}
        deleteConditional={[
          {
            keyPath: "trangThai.code",
            value: MISSION_STATUS_CODE.PROCESSING
          }, {
            keyPath: "trangThai.code",
            value: MISSION_STATUS_CODE.COMPLETED
          }
        ]}
      />

      {shouldOpenViewDialog && (
        <BonusViewDialog
          open={shouldOpenViewDialog}
          handleClose={handleCloseDialog}
          handleCloseAndSearch={handleCloseAndSearch}
          isView={isView}
          bonusInfo={bonusItem}
        />
      )}
    </div>
  );
}

export default BonusTable;

/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import { Col, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import * as Yup from "yup";
import "../../../styles/index.scss";
import { IRelativesFamily } from "../../models/dialogModels";
import { INIT_RELATIVES_FAMILY_INFO } from "../../constants/profileConsts";
import TextValidator from "../../../components/input/text-validator";
import { useCustomIntl } from "../../../utils/functionUtils";
import Autocomplete from "../../../components/input/autocomplete";
import { addSimpleAttributeValue, searchAllSimpleCategory } from "../../../services/services";
import { RESPONSE_STATUS_CODE} from "../../../constants/moduleConsts";
import { useEffect, useState } from "react";
import { GroupButton } from "../../../components/GroupButton";
import useMultiLanguage from "../../../../hook/useMultiLanguage";

interface Props {
  onClose: () => void;
  onUpdate?: () => void;
  handleSaveItem: (newItem: any) => void;
  title: string;
  typeContent: number
}

const AddAttributeValueDialog = (props: Props) => {
  const { lang } = useMultiLanguage();
  const { onClose, onUpdate, handleSaveItem, title, typeContent } = props;
  const [listType, setListType] = useState([])
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
  });

  const handleChangeSelect = (name: string, value: any) => {
    formik.setFieldValue(name, value)
  }

  const handleSubmit = async (values: IRelativesFamily) => {
    let data = {
      ...values,
      value: values?.name,
      scategoryId: values?.type?.scategoryId,
      attributeId: values?.type?.id,
    };
    try {
      const res = await addSimpleAttributeValue(data);
      if (res?.data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        const message = "TOAST.ADD.SUCCESS";
        toast.success(lang(message));
        handleSaveItem(res?.data?.data);
        onUpdate ? onUpdate() : onClose();
      } else {
        toast.warning(`${res?.data?.message}`);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const formik = useFormik({
    initialValues: INIT_RELATIVES_FAMILY_INFO,
    validationSchema,
    onSubmit: handleSubmit
  })

  const getData = async () => {
    try {
      const { data } = await searchAllSimpleCategory({ type: typeContent });
      if(data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListType(data?.data?.content)
        setIsDataLoaded(false)
      } else {
        toast.warning(data?.message);
      }
    }
    catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }

  useEffect(() => {
    getData()
  }, [typeContent])

  useEffect(() => {
    if (listType?.length > 0 && !isDataLoaded) {
      formik.setFieldValue("type", listType[0]);
      setIsDataLoaded(true);
    }
  }, [listType, isDataLoaded, formik])

  return (
    <>
      <Modal
        show={true}
        size="lg"
        centered
        className="custom-modal"
        aria-labelledby="example-custom-modal-styling-title"
        onHide={onClose}
        backdrop="static"
      >
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title
              id="example-custom-modal-styling-title"
              className="heading-5"
            >
              {useCustomIntl(title)}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="spaces px-8 pt-6">
              <Col xs={6}>
                <Autocomplete
                  lable="Loại"
                  name="type"
                  value={formik.values?.type || ""}
                  options={listType}
                  isReadOnly={true}
                  onChange={(selectedOption) =>
                    handleChangeSelect("type", selectedOption)
                  }
                  errors={formik.errors?.type}
                  touched={formik.touched?.type}
                />
              </Col>
              <Col xs={6}>
                <TextValidator
                  lable={useCustomIntl("CATEGORY.NAME")}
                  name="name"
                  value={formik.values?.name || ""}
                  type="text"
                  isRequired
                  onChange={formik.handleChange}
                  errors={formik.errors?.name}
                  touched={formik.touched?.name}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="flex-center">
            <GroupButton type="btn-cancel" handleCloseUpdateDialog={onClose} />
            <GroupButton type="btn-save" />
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
export default AddAttributeValueDialog
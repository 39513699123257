/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useEffect } from "react";
import { ReceivingCitizensForm } from "./components/ReceivingCitizensForm";
import { toast } from "react-toastify";
import { checkStatus, convertSearch, getFullYear, hasAuthority, searchDataRoleUser } from "../utils/functionUtils";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../constants";
import { Col, Row } from "react-bootstrap";
import InputSearch from "../components/input/InputSearch";
import AdvancedSearch from "../profile/components/dialogs/AdvancedSearch";
import { INIT_RECEIVING_CITIZENS, LIST_STATUS, LIST_STATUS_CODE, ListSearch } from "./constants/receivingCitizensConsts";
import TableCustom, { columnNamesType } from "../components/table-custom/TableCustom";
import useMultiLanguage from "../../hook/useMultiLanguage";
import { RESPONSE_STATUS_CODE, TYPE, DATA_WORD_TYPES, INIT_SEARCH_OBJECT, INIT_INPUT_SEARCH } from "../constants/moduleConsts";
import AppContext from "../../AppContext";
import { IReceivingCitizens } from "./models/receivingCitizensModels";
import { renderTextArea } from "../components/FunctionComponent";
import { ExportWord, IPropsSearchExportWord } from "../components/exportWord";
import { ReceivingCitizenWord } from "./components/ReceivingCitizenWord";
import { QuarterSearch } from "./components/QuarterSearch";
import { GroupButton } from "../components/GroupButton";
import { deleteReceivingCitizens, getListReceivingCitizens } from "./services/receivingCitizensServices";
import { QUARTER_LIST } from "../report/constants/reportConsts";
import { IItemSearch } from "../models/models";

function ReceivingCitizens() {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  document.title = `${lang("GENERAL.APP.NAME")} | ${lang("GENERAL.DISMISSED")}`;
  const [receivingCitizens, setReceivingCitizens] = useState<IReceivingCitizens>({} as IReceivingCitizens);
  const [receivingCitizensList, setReceivingCitizensList] = useState<IReceivingCitizens[]>([]);
  const [dataChecked, setDataChecked] = useState<IReceivingCitizens[]>([]);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [searchObject, setSearchObject] = useState<any>(INIT_SEARCH_OBJECT);
  const [isView, setIsView] = useState<boolean>(false)
  const [isAdvancedSearch, setIsAdvancedSearch] = useState<boolean>(false);
  const [listInputSearch, setListInputSearch] = useState<any[]>([INIT_INPUT_SEARCH]);
  const [openReceivingCitizens, setOpenReceivingCitizens] = useState<boolean>(false);
  const [shouldOpenExportWord, setShouldOpenExportWord] = useState<boolean>(false);
  const YEAR_LIST = getFullYear(5, 0);

  const handleAdvancedSearch = (data: IItemSearch[]) => {
    setListInputSearch(data);
    handleSearch({ ...convertSearch(data), pageIndex: 1 });
  };

  const handleOpenUpdateDialog = () => {
    setIsView(false);
  };

  const handleCloseUpdateDialog = () => {
    setIsView(true);
  };

  const handleOpenInfoDialog = (row: any) => {
    setIsView(true);
    setReceivingCitizens(row)
    setOpenReceivingCitizens(true);
  };

  const handleCloseAndSearch = () => {
    handleSearch();
    handleCloseDialog();
  }

  const handleCloseDialog = () => {
    setOpenReceivingCitizens(false);
    setReceivingCitizens({} as IReceivingCitizens);
    setIsView(false);
  };

  const handleOpenDialog = () => {
    setOpenReceivingCitizens(true);
    setReceivingCitizens(INIT_RECEIVING_CITIZENS)
  };

  const updatePageData = async (searchData?: any) => {
    try {
      setPageLoading(true);
      setSearchObject({ ...searchData });
      const { data } = await getListReceivingCitizens(hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW) ? searchDataRoleUser(searchData) : { ...searchData });
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setReceivingCitizensList(data?.data?.content || []);
        setTotalPage(data?.data?.pageable?.totalPages);
        setTotalElements(data?.data?.pageable?.totalElements);
        setNumberOfElements(data?.data?.pageable?.numberOfElements);
        setIsAdvancedSearch(false);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleSearch = (data: any = {}) => {
    const dataSearch: any = {
      ...searchObject,
      ...data
    };
    dataSearch.keyword = dataSearch?.keyword?.trim() || "";
    updatePageData(dataSearch);
  };

  useEffect(() => {
    if (searchObject?.pageIndex && searchObject?.pageSize) {
      handleSearch()
    }
  }, [searchObject?.pageIndex, searchObject?.pageSize])

  const handleOpenAdvancedSearch = () => {
    setIsAdvancedSearch(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchObject({
      ...searchObject,
      keyword: e.target?.value
    });
  };

  const handleDelete = async (ids: string[]) => {
    try {
      const { data } = await deleteReceivingCitizens(ids)
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("GENERAL.DELETE_SUCCESS"))
        updatePageData(searchObject);
        return true;
      } else {
        toast.warning(data?.message)
      }
    }
    catch (err: any) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }

  const columns: columnNamesType[] = [
    {
      name: "STT",
      field: "stt",
      headerStyle: {
        width: "50px",
        minWidth: "50px"
      },
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("GENERAL.STATUS"),
      field: "trangThai",
      headerStyle: {
        width: "160px",
        minWidth: "160px"
      },
      render: (row: any) =>
        <span className={`${checkStatus(LIST_STATUS, row?.status?.code)} status`}>
          {row?.status?.name}
        </span>
    },
    {
      name: lang("PROFILE.NAME"),
      field: "name",
      headerStyle: {
        width: "200px",
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
    },
    {
      name: lang("RECEIVING.DAY_RECEPTION"),
      field: "ngayTiep",
      headerStyle: {
        width: "120px",
        minWidth: "120px"
      },
    },
    {
      name: lang("INPUT.IDENTIFICATION"),
      field: "cccd",
      headerStyle: {
        minWidth: "120px"
      },
      cellStyle: {
        textAlign: "left"
      },
    },
    {
      name: lang("RECEIVING.SINGLE_CLASSIFICATION"),
      field: "phanLoaiDon",
      headerStyle: {
        minWidth: "180px"
      },
      cellStyle: {
        textAlign: "left"
      },
      render: (row: any) => row?.phanLoaiDon?.name
    },
    {
      name: lang("RECEIVING.SOLUTION"),
      field: "phuongPhapGiaiQuyet",
      headerStyle: {
        minWidth: "180px"
      },
      cellStyle: {
        textAlign: "left"
      },
      render: (row: any) => row?.phuongPhapGiaiQuyet?.name
    },
    {
      name: lang("REPORT.TITLE"),
      field: "baoCao",
      headerStyle: {
        minWidth: "100px"
      },
      cellStyle: {
        textAlign: "left"
      },
    },
    {
      name: lang("RECEIVING.WORK_CONTENT"),
      field: "noiDungCongViec",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
      render: (row: any) => renderTextArea(row?.noiDungCongViec)
    },
    {
      name: lang("RECEIVING.SOLUTION_CONTENT"),
      field: "noiDungHuongGiaiQuyet",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
      render: (row: any) => renderTextArea(row?.noiDungHuongGiaiQuyet)
    },
  ]

  return (
    <div className="spaces px-8 py-12 flex-1">
      {!openReceivingCitizens &&
        <>
          <Row className="spaces mb-12 flex-middle">
            <Col xs={5} xl={6} xxl={7} className="spaces p-0">
              <h2 className="breadcrumb-title">{lang("GENERAL.RECEIVING.LIST")}</h2>
            </Col>
            <Col xs={7} xl={6} xxl={5} className="flex">
              <div className="spaces mr-10 flex-1">
                <InputSearch
                  className="border-3px"
                  value={searchObject?.keyword}
                  placeholder="Tìm kiếm"
                  handleSearch={() => handleSearch({ pageIndex: 1 })}
                  handleChange={handleChange}
                  isEnter
                />
              </div>
              <GroupButton className="spaces mr-10" handleEvent={() => handleSearch({ pageIndex: 1 })}>
                {lang("BTN.SEARCH")}
              </GroupButton>
              <GroupButton className="spaces mr-10" outline handleEvent={handleOpenAdvancedSearch}>
                {lang("BTN.ADVANCE_SEARCH")}
              </GroupButton>
              {isAdvancedSearch && (
                <AdvancedSearch
                  open={isAdvancedSearch}
                  listInputSearch={listInputSearch}
                  handleSearch={handleAdvancedSearch}
                  listOption={ListSearch(lang)}
                  handleClose={() => setIsAdvancedSearch(false)}
                />
              )}
            </Col>
          </Row>
          <Row className="bg-white">
            <Col xs={12} className="table-scroll">
              <TableCustom
                id="receiving-citizen-list"
                data={receivingCitizensList}
                columns={columns}
                searchObject={searchObject}
                setSearchObject={setSearchObject}
                buttonAdd={hasAuthority(PERMISSIONS.TIEP_CONG_DAN, PERMISSION_ABILITY.CREATE)}
                buttonExportWord={true}
                handleExportWord={() => setShouldOpenExportWord(true)}
                buttonExportExcel={false}
                notDelete={!hasAuthority(PERMISSIONS.TIEP_CONG_DAN, PERMISSION_ABILITY.DELETE)}
                type={TYPE.MULTILINE}
                fixedColumnsCount={4}
                totalPages={totalPage}
                totalElements={totalElements}
                numberOfElements={numberOfElements}
                handleOpenDialog={handleOpenDialog}
                handleDoubleClick={handleOpenInfoDialog}
                handleDelete={(ids: string[]) => handleDelete(ids)}
                setDataChecked={setDataChecked}
                dataChecked={dataChecked}
                deleteConditional={[
                  {
                    keyPath: "status.code",
                    value: LIST_STATUS_CODE.PROCESSING,
                  }, {
                    keyPath: "status.code",
                    value: LIST_STATUS_CODE.FINISHED,
                  },
                ]}
              />
            </Col>
          </Row>
        </>
      }
      {openReceivingCitizens && (
        <ReceivingCitizensForm
          isView={isView}
          setIsView={setIsView}
          receivingCitizens={receivingCitizens}
          handleCloseDialog={handleCloseDialog}
          handleCloseAndSearch={handleCloseAndSearch}
          handleCloseUpdateDialog={handleCloseUpdateDialog}
          handleOpenUpdateDialog={handleOpenUpdateDialog}
        />
      )}
      {shouldOpenExportWord && (
        <ExportWord
          open={shouldOpenExportWord}
          handleClose={() => setShouldOpenExportWord(false)}
          templateList={[{
            name: "Báo cáo tiếp công dân",
            componentPreview: (dataPreview) => <ReceivingCitizenWord data={dataPreview} />
          }]}
          getObj={{
            tuThang: (YEAR_LIST[YEAR_LIST.length - 1]?.name || "") + (QUARTER_LIST[0]?.startDate || ""),
            denThang: (YEAR_LIST[YEAR_LIST.length - 1]?.name || "") + (QUARTER_LIST[0]?.endDate || ""),
            year: YEAR_LIST[YEAR_LIST.length - 1],
            nam: YEAR_LIST[YEAR_LIST.length - 1]?.name || "",
            quy: QUARTER_LIST[0],
            type: DATA_WORD_TYPES.RECEIVING_CITIZEN
          }}
          ComponentSearch={(props: IPropsSearchExportWord) => <QuarterSearch {...props} />}
          customFileName={(data, nameBase) => `${nameBase} ${data?.soThang ? data?.soThang + " tháng từ tháng "
            + (new Date(data?.tuThang ?? "").getMonth() + 1) + " đến tháng "
            + (new Date(data?.denThang ?? "").getMonth() + 1) + " năm " + data?.nam : ""}`
          }
        />
      )}
    </div>
  );
}

export default ReceivingCitizens
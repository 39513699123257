import { ILang } from "../../../hook/useMultiLanguage"
import { TYPE, TYPE_CATEGORY } from "../../constants/moduleConsts"
import { searchAllSimpleValue, searchPhongBan } from "../../services/services"

export const TAB_KEY = {
  CHUA_GUI: "CHUA_GUI",
  CHO_PHE_DUYET: "CHO_PHE_DUYET",
  DA_PHE_DUYET: "DA_PHE_DUYET",
  TU_CHOI: "TU_CHOI"
}

export const DECLARE_ASSETS_TYPES_CODES = {
  LAN_DAU: 1,
  HANG_NAM: 2,
  PHUC_VU_CAN_BO: 3,
  BO_SUNG: 4
}

export const DECLARE_ASSETS_TYPES = [
  {
    code: 1,
    name: "Kê khai lần đầu"
  },
  {
    code: 2,
    name: "Kê khai hằng năm"
  },
  {
    code: 3,
    name: "Kê khai phục vụ công tác cán bộ"
  },
  {
    code: 4,
    name: "Tài sản, thu nhập bổ sung"
  }
] 

export const DECLARE_ASSETS_STATUS_CODES = {
  CHUA_GUI: 1,
  CHO_PHE_DUYET: 2,
  DA_PHE_DUYET: 3,
  TU_CHOI: 4
}

export const DECLARE_ASSETS_STATUS = [
  {
    key: TAB_KEY.CHUA_GUI,
    code: DECLARE_ASSETS_STATUS_CODES.CHUA_GUI,
    name: "Chưa gửi"
  },
  {
    key: TAB_KEY.CHO_PHE_DUYET,
    code: DECLARE_ASSETS_STATUS_CODES.CHO_PHE_DUYET,
    name: "Chờ phê duyệt"
  },
  {
    key: TAB_KEY.DA_PHE_DUYET,
    code: DECLARE_ASSETS_STATUS_CODES.DA_PHE_DUYET,
    name: "Đã phê duyệt"
  },
  {
    key: TAB_KEY.TU_CHOI,
    code: DECLARE_ASSETS_STATUS_CODES.TU_CHOI,
    name: "Từ chối"
  }
]

export const ASSETS_TYPE_DOMESTIC = {
  code: 1,
  name: "TRONG_NUOC"
}

export const ASSETS_TYPE_ABROAD = {
  code: 2,
  name: "NUOC_NGOAI"
}

export const NAME_TITLE = {
  CON_CAI: "CON_CAI",
  TK_NGAN_HANG: "TK_NGAN_HANG"
}

export const UP_DOWNS = [
  {
    code: 1,
    name: "Tăng"
  },
  {
    code: 2,
    name: "Giảm"
  }
]

export const ListSearch = (lang: ILang) => {
  return [
    {
      name: lang("PROFILE.CODE"),
      field: "maNhanVien",
      type: TYPE.TEXT,
    },
    {
      name: lang("PROFILE.NAME"),
      field: "tenNhanVien",
      type: TYPE.TEXT,
    },
    {
      name: lang("INPUT.DEPARTMENTS"),
      field: "phongBanIds",
      searchFunction: searchPhongBan,
      type: TYPE.SELECT,
      isMulti: true,
    },
    {
      name: lang("GENERAL.EMPLOYEE.TITLE"),
      field: "viTriCongViecIds",
      searchFunction: searchAllSimpleValue,
      searchObject: { type: TYPE_CATEGORY.viTriCongViec },
      type: TYPE.SELECT,
      optionLabel: "value",
      isMulti: true,
    },
    {
      name: lang("PROFILE.ROLE"),
      field: "chucVuIds",
      searchFunction: searchAllSimpleValue,
      searchObject: { type: TYPE_CATEGORY.chucDanh },
      type: TYPE.SELECT,
      optionLabel: "value",
      isMulti: true,
    },
    {
      name: lang("DECLARE_ASSETS.TYPE"),
      field: "loaiKeKhai",
      listOption: DECLARE_ASSETS_TYPES,
      type: TYPE.SELECT,
      isMulti: true,
    },
    {
      name: lang("DECLARE_ASSETS.DECLARE_DATE"),
      field: "ngayKeKhai",
      type: TYPE.DATE,
    }
  ]
}
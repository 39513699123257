import { useFormik } from "formik";
import { ChangeEvent, useContext, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Autocomplete from "../../../components/input/autocomplete";
import { RangeDatePicker } from "../../../components/input/RangeDatePicker";
import TextValidator from "../../../components/input/text-validator";
import { searchAllSimpleValue } from "../../../services/services";
import "../../../styles/index.scss";
import { RESPONSE_STATUS_CODE, VARIABLE_STRING, TYPE_CATEGORY } from "../../../constants/moduleConsts";
import { handleBlurDate, hasAuthority, useCustomIntl } from "../../../utils/functionUtils";
import { checkInvalidDate } from "../../../utils/validationSchema";
import { INIT_CRETIFICAFE_INFO } from "../../constants/profileConsts";
import { CertificateInfo, IFile } from "../../models/dialogModels";
import { addChungChi, updateChungChi } from "../../services/dialogServices";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import { TRAINING_TYPE } from "../../constants/dialogChildConsts";
import UploadFile from "../../../components/file-upload/UploadFile";
import AppContext from "../../../../AppContext";
import { useProfileContext } from "../../ProfileContext";
import { GroupButton } from "../../../components/GroupButton";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../../../constants";

interface Props {
  handleCloseCertificateDialog: () => void;
  handleCloseAndSearch: () => void;
  identify: string;
  isView: boolean;
  certificateEdit: CertificateInfo;
}
export const CertificateDialog = (props: Props) => {
  const { lang, intl } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);
  const { isResign } = useProfileContext();

  const { handleCloseCertificateDialog, handleCloseAndSearch, identify, certificateEdit, isView } = props;
  const [isCheckCoSoDaoTao, setIsCheckCoSoDaoTao] = useState<boolean>(certificateEdit?.coSoDaoTaoKhac ? true : false);
  const [isEdit, setIsEdit] = useState<boolean>(!isView);

  const validationSchema = Yup.object().shape({
    tenChungChi: Yup.string()
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRE" }))
      .nullable(),
    thoiGianHieuLucTuNgay: Yup.date()
      .concat(checkInvalidDate(intl))
      .when("thoiGianHieuLucDenNgay", {
        is: (thoiGianHieuLucDenNgay: Date | null) => thoiGianHieuLucDenNgay && thoiGianHieuLucDenNgay < (new Date()),
        then: Yup.date()
          .max(
            Yup.ref("thoiGianHieuLucDenNgay"),
            lang("VALIDATION.MAXDATE") +
            lang("INPUT.CERTIFICATE.EFFECTIVE_TIME_END")
          )
          .nullable()
      })
      .max(new Date(), lang("VALIDATION.INVALID_START_EFFECTIVE_DATE"))
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRE" }))
      .nullable(),
    thoiGianHieuLucDenNgay: Yup.date()
      .concat(checkInvalidDate(intl))
      .nullable(),
    soCCHN: Yup.string()
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRE" }))
      .nullable(),
    noiCap: Yup.string()
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRE" }))
      .nullable(),
    chuyenNganh: Yup.string()
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRE" }))
      .nullable(),
    ngayCap: Yup.date()
      .when("thoiGianHieuLucTuNgay", {
        is: (thoiGianHieuLucTuNgay: Date | null) => thoiGianHieuLucTuNgay && thoiGianHieuLucTuNgay < (new Date()),
        then: Yup.date()
          .max(
            Yup.ref("thoiGianHieuLucTuNgay"),
            lang("VALIDATION.MAXDATE") +
            lang("INPUT.CERTIFICATE.EFFECTIVE_TIME_START")
          )
          .nullable()
      })
      .max(new Date(), lang("VALIDATION.INVALID_ISSUEDATE"))
      .nullable(),
  });

  const convertDataCertificate = (data: CertificateInfo) => {
    return {
      ...data,
      coSoDaoTao: data?.coSoDaoTaoId ? {
        id: data?.coSoDaoTaoId,
        value: data?.coSoDaoTaoText
      } : null
    }
  }

  const handleFormSubmit = async (values: CertificateInfo) => {
    if (identify) {
      let _values = {
        ...values,
        employeeId: identify,
        coSoDaoTaoId: values?.coSoDaoTao?.id,
        coSoDaoTaoText: values?.coSoDaoTao?.value,
        type: TRAINING_TYPE.CHUNG_CHI_HANH_NGHE
      }
      try {
        setPageLoading(true);
        const { data } = certificateEdit?.id
          ? await updateChungChi(certificateEdit?.id, _values)
          : await addChungChi(_values);
        if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
          const message = certificateEdit?.id
            ? "TOAST.EDIT.CERTIFICATE.SUCCESS"
            : "TOAST.ADD.CERTIFICATE.SUCCESS";
          toast.success(intl.formatMessage({ id: message }));
          handleCloseAndSearch();
        } else {
          toast.warning(`${data?.message}`);
        }
      } catch (error) {
        toast.error(intl.formatMessage({ id: "GENERAL.ERROR" }));
      } finally {
        setPageLoading(false);
      }
    } else {
      toast.warning(intl.formatMessage({ id: "MESSAGE.BASIC.FIRST" }));
    }
  };


  const formik = useFormik({
    initialValues: certificateEdit?.id ? convertDataCertificate(certificateEdit) : INIT_CRETIFICAFE_INFO,
    validationSchema,
    onSubmit: handleFormSubmit
  })

  const handleChangeSelect = (name: string, value: any) => {
    if (name === VARIABLE_STRING.CO_SO_DAO_TAO) {
      formik.setFieldValue(VARIABLE_STRING.CO_SO_DAO_TAO_KHAC, "");
    }
    formik.setFieldValue(name, value);
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>, nameObj: string) => {
    const { name, value } = event.target
    formik.setFieldValue(name, value)
    formik.setFieldValue(nameObj, null)
  }

  const handleFile = (data: IFile) => {
    formik.setFieldValue("fileId", data.id);
    formik.setFieldValue("fileName", data.name);
  }

  return (
    <Modal
      show={true}
      size="xl"
      centered
      aria-labelledby="example-custom-modal-styling-title"
      onHide={handleCloseCertificateDialog}
      backdrop="static"
      className="modal-scroll"
    >
      <Modal.Header closeButton className="py-4">
        <Modal.Title
          id="example-custom-modal-styling-title"
          className="heading-5"
        >
          {useCustomIntl(
            `${certificateEdit
              ? "INFO.CERTIFICATE.ADD"
              : "INFO.CERTIFICATE.UPDATE"
            }`
          )}
        </Modal.Title>
      </Modal.Header>
      <Form
        className="form-info pb-0"
        onSubmit={formik.handleSubmit}
      >
        <Modal.Body>
          <Row>
            <Col xs={4} className="pt-3">
              <TextValidator
                lable={useCustomIntl("INPUT.CERTIFICATE")}
                name="tenChungChi"
                value={formik.values?.tenChungChi || ""}
                type="text"
                isRequired
                readOnly={!isEdit}
                onChange={formik.handleChange}
                touched={formik.touched?.tenChungChi}
                errors={formik.errors?.tenChungChi}
              />
            </Col>
            <Col xs={4} className="pt-3">
              <TextValidator
                lable={useCustomIntl("INFO.CERTIFICATE.NUMBER")}
                name="soCCHN"
                value={formik.values?.soCCHN || ""}
                type="text"
                isRequired
                readOnly={!isEdit}
                onChange={formik.handleChange}
                touched={formik.touched?.soCCHN}
                errors={formik.errors?.soCCHN}
              />
            </Col>
            <Col xs={4} className="pt-3">
              <RangeDatePicker
                label={useCustomIntl("INPUT.CERTIFICATE.EFFECTIVE_TIME")}
                isRequired
                startDateName="thoiGianHieuLucTuNgay"
                endDateName="thoiGianHieuLucDenNgay"
                isView={!isEdit}
                handleChange={formik.handleChange}
                value={formik.values}
                setFieldValue={formik.setFieldValue}
                touch={formik.touched}
                errors={formik.errors}
              />
            </Col>
            <Col xs={4} className="pt-3">
              <TextValidator
                lable={useCustomIntl("INFO.CERTIFICATE.SPECIALIZED_TRAINING")}
                name="chuyenNganh"
                value={formik.values?.chuyenNganh || ""}
                type="text"
                isRequired
                readOnly={!isEdit}
                onChange={formik.handleChange}
                touched={formik.touched?.chuyenNganh}
                errors={formik.errors?.chuyenNganh}
              />
            </Col>
            <Col xs={4} className="pt-3">
              <Autocomplete
                lable={useCustomIntl("INPUT.CERTIFICATE.TRAINING_FACILITIES")}
                isReadOnly={!isEdit}
                formCheckBox={true}
                menuPlacement="bottom"
                maxMenuHeight={180}
                value={
                  isCheckCoSoDaoTao
                    ? formik.values?.coSoDaoTaoKhac
                    : formik.values?.coSoDaoTao
                }
                name={
                  isCheckCoSoDaoTao
                    ? VARIABLE_STRING.CO_SO_DAO_TAO_KHAC
                    : VARIABLE_STRING.CO_SO_DAO_TAO
                }
                setIsCheckBox={setIsCheckCoSoDaoTao}
                isCheckBox={isCheckCoSoDaoTao}
                onChange={(selectedOption) =>
                  isCheckCoSoDaoTao
                    ? handleChange(
                      selectedOption,
                      VARIABLE_STRING.CO_SO_DAO_TAO
                    )
                    : handleChangeSelect(
                      VARIABLE_STRING.CO_SO_DAO_TAO,
                      selectedOption
                    )
                }
                options={[]}
                searchFunction={searchAllSimpleValue}
                searchObject={{ type: TYPE_CATEGORY.noiDaoTao }}
                getOptionLabel={(option) => option?.value}
                errors={formik.errors?.coSoDaoTao}
                touched={formik.touched?.coSoDaoTao}
              />
            </Col>
            <Col xs={4} className="pt-3">
              <TextValidator
                lable={useCustomIntl("INFO.CERTIFICATE.PROFESSIONAL_DIPLOMA")}
                name="vanBang"
                value={formik.values?.vanBang || ""}
                type="text"
                readOnly={!isEdit}
                onChange={formik.handleChange}
                touched={formik.touched?.vanBang}
                errors={formik.errors?.vanBang}
              />
            </Col>
            <Col xs={4} className="pt-3">
              <TextValidator
                lable={useCustomIntl("INPUT.CERTIFICATE.DATE.PROVIDE")}
                name="ngayCap"
                value={formik.values?.ngayCap || ""}
                type="date"
                readOnly={!isEdit}
                onChange={formik.handleChange}
                onBlur={() =>
                  handleBlurDate(
                    formik.setFieldValue,
                    formik.values?.ngayCap,
                    "ngayCap"
                  )
                }
                touched={formik.touched?.ngayCap}
                errors={formik.errors?.ngayCap}
              />
            </Col>
            <Col xs={4} className="pt-3">
              <TextValidator
                lable={useCustomIntl("INPUT.CERTIFICATE.PLACE")}
                name="noiCap"
                isRequired
                value={formik.values?.noiCap || ""}
                type="text"
                readOnly={!isEdit}
                onChange={formik.handleChange}
                touched={formik.touched?.noiCap}
                errors={formik.errors?.noiCap}
              />
            </Col>
            <Col xs={4} className="pt-3">
              <UploadFile
                label="CONTRACT.ATTACHMENTS"
                setValue={handleFile}
                isReadOnly={!isEdit}
                fileValue={{
                  id: formik.values.fileId || "",
                  name: formik.values.fileName || ""
                }}
              />
            </Col>
            <Col xs={6} className="pt-3">
              <TextValidator
                lable={useCustomIntl("INPUT.CERTIFICATE.RANGE_DESCRIPTION")}
                name="moTa"
                value={formik.values?.moTa || ""}
                type="text"
                as="textarea"
                rows="3"
                readOnly={!isEdit}
                onChange={formik.handleChange}
                touched={formik.touched?.moTa}
                errors={formik.errors?.moTa}
              />
            </Col>
            <Col xs={6} className="pt-3">
              <TextValidator
                lable={useCustomIntl("INPUT.CERTIFICATE.NOTE")}
                name="note"
                value={formik.values?.note || ""}
                type="text"
                as="textarea"
                rows="3"
                readOnly={!isEdit}
                onChange={formik.handleChange}
                touched={formik.touched?.note}
                errors={formik.errors?.note}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="flex-center py-3">
          <GroupButton type="btn-cancel" handleCloseUpdateDialog={handleCloseCertificateDialog} />
          {!isResign &&
            (isEdit ? (
              <GroupButton type="btn-save" />
            ) : (hasAuthority(PERMISSIONS.EMPLOYEE, PERMISSION_ABILITY.UPDATE) &&
              <GroupButton type="btn-edit" handleSaveEdit={() => setIsEdit(true)} />
            ))}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

import React, { useContext, useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import TCTW98 from './word-templates/TCTW98'
import VCBNV from './word-templates/VCBNV'
import { getEmployeeCurriculumVitae } from '../services/profileServices'
import Autocomplete from '../../components/input/autocomplete'
import { GroupButton } from '../../components/GroupButton'
import "./word-templates/style-word.scss";
import "../profile.scss";
import { Circulars322010TTBCA } from './word-templates/Circulars322010TTBCA'
import { CircularsTT062023TTBNV } from './word-templates/CircularsTT062023TTBNV'
import { exportToFile } from '../../utils/functionUtils'
import { exportSoYeuLyLich } from '../../services/exportFileServices'
import AppContext from '../../../AppContext'
import { RESPONSE_STATUS_CODE, TYPE } from '../../constants/moduleConsts'
import { toast } from 'react-toastify'
import useMultiLanguage from '../../../hook/useMultiLanguage'

function ExportWord(props: any) {
    const { handleClose, idEmployee } = props;
    const { lang } = useMultiLanguage();

    const [dataEmployee, setDataEmployee] = useState({});
    const [template, setTemplate] = useState<any>();
    const { setPageLoading } = useContext(AppContext);

    const listTemplate = [
        { type: 1, name: "Sơ yếu lý lịch (Mẫu HS02-VCBNV)", component: (dataEmployee: any) => <VCBNV dataEmployee={dataEmployee} /> },
        { type: 2, name: "Sơ yếu lý lịch (Mẫu 2C-TCTW98)", component: (dataEmployee: any) => <TCTW98 dataEmployee={dataEmployee} /> },
        { type: 3, name: "Sơ yếu lý lịch (theo TT062023TT-BNV)", component: (dataEmployee: any) => <CircularsTT062023TTBNV dataEmployee={dataEmployee} /> },
        { type: 4, name: "Sơ yếu lý lịch (Thông tư số 322010TT-BCA)", component: (dataEmployee: any) => <Circulars322010TTBCA dataEmployee={dataEmployee} /> },
    ]

    const getCurriculumVitae = async () => {
        try {
            setPageLoading(true)
            const { data } = await getEmployeeCurriculumVitae(idEmployee);
            if(data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
                setDataEmployee(data?.data || {})
            } else {
                toast.warning(data?.message);
            }
        } catch (error) {
            toast.error(lang("GENERAL.ERROR"));
        } finally {
            setPageLoading(false);
        }
    }

    useEffect(() => {
        getCurriculumVitae()
        setTemplate(listTemplate[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idEmployee])

    const handleExportWord = () => {
        exportToFile({
            exportAPI: () => exportSoYeuLyLich({ id: idEmployee, type: template.type }), 
            fileName: template.name,
            type: TYPE.WORD,
            setPageLoading
        })
    }

    return (
        <Modal
            show={true}
            size="xl"
            centered
            className="custom-modal profile export-word"
            aria-labelledby="example-custom-modal-styling-title"
            onHide={handleClose}
            backdrop="static"
        >
            <Modal.Header className="spaces px-16 py-10">
                <div className='w-100'>
                    <div className='spaces flex flex-middle flex-space-between py-12 color-primary text-uppercase'>
                        <GroupButton
                            type="btn-back"
                            handleClose={handleClose}
                        />
                        <GroupButton handleEvent={handleExportWord}>Xuất dạng word</GroupButton>
                    </div>
                    <Row>
                        <Col xs={8} className='spaces z-index-10'>
                            <Autocomplete
                                horizontal={true}
                                lable='Chọn mẫu xuất word'
                                options={listTemplate}
                                value={template}
                                onChange={(value) => setTemplate(value)}
                            />
                        </Col>
                    </Row>
                </div>
            </Modal.Header>
            <Modal.Body className='content'>
                {template && template.component(dataEmployee)}
            </Modal.Body>
        </Modal>
    )
}

export default ExportWord
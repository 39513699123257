/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { exportTepDinhKem } from '../../services/exportFileServices';
import { exportToFile, hasAuthority } from '../../utils/functionUtils';
import { ITaiLieuDinhKemInfo } from '../models/profileModels';
import { deleteTaiLieuDinhKem, getAllTaiLieuDinhKemById } from '../services/dialogServices';
import AttachmentsDialog from './dialogs/AttachmentsDialog';
import TableCustom from '../../components/table-custom/TableCustom';
import { EXPORT_FILE_NAME, RESPONSE_STATUS_CODE, TYPE } from '../../constants/moduleConsts';
import { toast } from 'react-toastify';
import { REF_TAB } from '../constants/profileConsts';
import { PERMISSIONS, PERMISSION_ABILITY } from '../../../constants';
import useMultiLanguage from '../../../hook/useMultiLanguage';
import AppContext from '../../../AppContext';

export const AttachmentsInformation = (props: any) => {
  const [shouldOpenAttachments, setShouldOpenAttachments] = useState<boolean>(false);
  const { identify, isView, employeeProfiles, activeTab } = props;

  const [attachment, setAttachment] = useState<ITaiLieuDinhKemInfo>({} as ITaiLieuDinhKemInfo);
  const [listAttachment, setListAttachment] = useState<ITaiLieuDinhKemInfo[]>([]);
  const { setPageLoading } = useContext(AppContext);

  const { lang } = useMultiLanguage();

  useEffect(() => {
    if (!(identify && activeTab === REF_TAB.TT_KHAC)) return
    updateDataAttachment();
  }, [employeeProfiles, activeTab])

  const handleOpenAttachments = (data: ITaiLieuDinhKemInfo): void => {
    setAttachment(data)
    setShouldOpenAttachments(true);
  };

  const handleCloseAttachments = (): void => {
    setShouldOpenAttachments(false);
    updateDataAttachment();
    setAttachment({} as ITaiLieuDinhKemInfo);
  };

  const updateDataAttachment = async () => {
    if (identify) {
      try {
        const { data } = await getAllTaiLieuDinhKemById(identify);
        if(data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
          setListAttachment(data?.data);
        } else {
          toast.warning(data?.message);
        }
      } catch (error) {
        console.error(error);
        toast.error(lang("GENERAL.ERROR"));
      }
    }
  };

  const handleDelete = async (ids: string[]) => {
    try {
      const { data } = await deleteTaiLieuDinhKem(ids)
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("GENERAL.DELETE_SUCCESS"))
        updateDataAttachment();
        return true;
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }

  const columns = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("GENERAL.INFO.ATTACHMENTS.NAME"),
      field: "fileName",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        minWidth: "200px",
        maxWidth: "200px",
        textAlign: "left",
      },
    },
    {
      name: lang("GENERAL.INFO.ATTACHMENTS.ALLOW"),
      field: "ngayCap",
      headerStyle: {
        minWidth: "100px"
      },
      cellStyle: {
        minWidth: "100px",
        maxWidth: "100px",
        textAlign: "left",
      },
      render: (row: any) => <span>{row?.choPhepTaiVe ? "Có" : "Không"}</span>
    },
    {
      name: lang("INPUT.CERTIFICATE.NOTE"),
      field: "ghiChu",
      headerStyle: {
        minWidth: "130px"
      },
      cellStyle: {
        minWidth: "130px",
        maxWidth: "130px",
        textAlign: "left",
      },
    },
  ]

  return (
    <>
      <div className="form-info">
        <div className="block-content">
          <TableCustom
            id="listAttachment"
            title={lang("INPUT.VACCINATION.ATTACHMENTS")}
            isActionTableTab
            data={listAttachment}
            columns={columns}
            type={TYPE.MULTILINE}
            fixedColumnsCount={2}
            noToolbar={true}
            noPagination={true}
            handleDoubleClick={handleOpenAttachments}
            handleDelete={handleDelete}
            dependencies={activeTab}
            buttonAdd={!isView && hasAuthority(PERMISSIONS.EMPLOYEE, PERMISSION_ABILITY.UPDATE)}
            buttonExportExcel={hasAuthority(PERMISSIONS.EMPLOYEE, PERMISSION_ABILITY.VIEW)}
            handleOpenDialog={() => setShouldOpenAttachments(true)}
            handleExportExcel={() => exportToFile({
              exportAPI: () => exportTepDinhKem(employeeProfiles.id), 
              fileName: EXPORT_FILE_NAME.TEP_DINH_KEM,
              setPageLoading
            })}
          />
        </div>
        {shouldOpenAttachments && (
          <AttachmentsDialog
            handleCloseAttachmentsDialog={handleCloseAttachments}
            identify={props?.identify}
            isView={isView}
            attachmentEdit={attachment}
          />
        )}
      </div>
    </>
  );
};
import TextUnderline from "../../../components/TextUnderline";
import { IPropsExportWord } from "../../../components/exportWord";
import { ValueOrDots } from "../../../components/exportWord/partials";


function RegularSalaryIncreases(props: IPropsExportWord) {
  const { data } = props;

  return (
    <div className="word spaces px-70 colors-word">
      <div className="spaces text-center flex flex-between">
        <div className="fs-lg">
          <p>{data?.soYTeHeader ?? ""}</p>
          <p className="fw-600 text-uppercase">
            <span className="header-underline fs-lg">
              <ValueOrDots className="fs-lg" value={data.donViCongTacText} size={70} />
            </span>
          </p>
          <p className="spaces mt-16 fs-md">
            Số: <ValueOrDots value={data.soQuyetDinh} />
          </p>
        </div>
        <div className="fw-600 fs-lg">
          <p>CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM</p>
          <p>
            <span className="header-underline fs-lg">Độc lập - Tự do - Hạnh phúc</span>
          </p>
          <p className="spaces mt-16 fw-500 fs-md">
            <ValueOrDots value="" size={20} />, ngày <ValueOrDots value={data.ngayKyToTrinh ? new Date(data.ngayKyToTrinh).getDate() : ""} size={10} /> tháng{" "}
            <ValueOrDots value={data.ngayKyToTrinh ? new Date(data.ngayKyToTrinh).getMonth() + 1 : ""} size={10} /> năm{" "}
            <ValueOrDots value={data.ngayKyToTrinh ? new Date(data.ngayKyToTrinh).getFullYear() : ""} size={15} />
          </p>
        </div>
      </div>
      <div className="text-center fs-lg fw-600">
        <p>QUYẾT ĐỊNH</p>
        <p>
          <span className="header-underline fs-lg">Về việc nâng bậc lương thường xuyên</span>
        </p>
        <p className="spaces mt-16 text-uppercase">
          GIÁM ĐỐC <ValueOrDots className="fs-lg" value={data.donViCongTacText} size={70} />
        </p>
      </div>
      <div className="text-indent-40 text-italic text-justify">
        <p>{data?.quyetDinhHopDong ?? ""}</p>
        <p>{data?.qdhdNangLuong ?? ""}</p>
        <p>
          Căn cứ Thông tư số 08/2013/TT-BNV ngày 31/7/2013 của Bộ Nội vụ hướng dẫn thực hiện chế độ nâng bậc lương
          thường xuyên và nâng bậc lương trước thời hạn đối với cán bộ, công chức, viên chức và người lao động; Thông tư
          số 03/2021/TT-BNV ngày 29/6/2021 của Bộ Nội vụ sửa đổi, bổ sung chế độ nâng bậc lương thường xuyên, nâng bậc
          lương trước thời hạn và chế độ phụ cấp thâm niên vượt khung đối với cán bộ, công chức và người lao động;
        </p>
        <p>
          Căn cứ Kết quả họp Hội đồng xét duyệt nâng lương <ValueOrDots value={data.donViCongTacText} size={70} /> ngày{" "}
          <ValueOrDots value={data.ngayQuyetDinh} />;
        </p>
        <p>Xét đề nghị của Trưởng phòng Tổ chức hành chính Bệnh viện.</p>
      </div>

      <div className="spaces mt-16">
        <p className="fs-lg fw-600 text-center">QUYẾT ĐỊNH:</p>
        <div className="text-indent-40">
          <p>
            <span className="fw-600">Điều 1.</span> Nâng bậc lương thường xuyên đối với ông/bà{" "}
            <span className="fw-600 text-capitalize">
              <ValueOrDots value={data.fullName} />
            </span>
            , sinh ngày <ValueOrDots value={data.birthdate} />
            , <ValueOrDots value={data?.chucDanhText} /> <ValueOrDots value={data?.phongBanText}/> như sau:
          </p>
          <div className="spaces pl-40">
            <TextUnderline readOnly label="Chức danh nghề nghiệp" value={data.chucDanhText} />
            <TextUnderline readOnly label="Mã số chức danh" value={data.chucDanhCode} />
          </div>
          <p>
            Hiện hưởng bậc lương: <ValueOrDots value={data.beforeBacLuong} size={10} />/
            <ValueOrDots value={data.beforeTotalBacLuong} size={10} />, Hệ số:{" "}
            <ValueOrDots value={data.beforeHeSoBacLuong} size={10} />, Xếp ngày:{" "}
            <ValueOrDots value={data.beforeNgayHuongLuongTuNgay} />
          </p>
          <p>
            Lên bậc: <ValueOrDots value={data.currentBacLuong} size={10} />/
            <ValueOrDots value={data.currentTotalBacLuong} size={10} />, Hệ số:{" "}
            <ValueOrDots value={data.currentHeSoBacLuong} size={10} />, Kể từ ngày:{" "}
            <ValueOrDots value={data.currentNgayHuongLuongDenNgay} />
          </p>
          <p>Thời gian tính nâng bậc lương lần sau kể từ ngày: </p>
          <p>
            <span className="fw-600">Điều 2.</span> Các ông, bà: Trưởng phòng Tổ chức Hành chính; Trưởng phòng Tài chính
            kế toán; Trưởng, phó khoa, phòng có liên quan và ông/bà{" "}
            <span className="text-capitalize">
              <ValueOrDots value={data.fullName} />
            </span>{" "}
            căn cứ thi hành Quyết định này kể từ ngày ký.
          </p>
        </div>
      </div>
      <div className="flex flex-between spaces mt-16 align-start">
        <div>
          <p className="fw-600 text-italic">Nơi nhận: </p>
          <p>- Như Điều 2 (để t/h);</p>
          <p>- Lưu: TCHC, VT.</p>
        </div>
        <div className="fw-600 fs-lg text-center spaces pr-20">
          <p>GIÁM ĐỐC</p>
          <p className="spaces mt-70">
            <ValueOrDots value={data.nguoiQuyetDinhText} size={60} />
          </p>
        </div>
      </div>
    </div>
  );
}

export default RegularSalaryIncreases;

import useMultiLanguage from '../../../hook/useMultiLanguage';
import { Col, Form, Row } from 'react-bootstrap';
import TextValidator from '../../components/input/text-validator';
import Autocomplete from '../../components/input/autocomplete';
import { bonusDecisionLevel, bonusObject, bonusType } from '../constants/bonusConsts';
import { MISSION_STATUS, VARIABLE_STRING, TYPE_CATEGORY, RESPONSE_STATUS_CODE } from '../../constants/moduleConsts';
import { covertDateToString, handleBlurDate, justAllowNumber } from '../../utils/functionUtils';
import UploadFile from '../../components/file-upload/UploadFile';
import { searchAllSimpleValue, searchListWorkUnit, searchNguoiDaiDienKy } from '../../services/services';
import { useCallback, useEffect, useState } from 'react';
import { IPeopleDecision } from '../../models/models';
import { toast } from "react-toastify";
import { AddNewRepresentative } from '../../components/dialogs';

const BonusForm = (props: any) => {
    let {
        isView,
        formik,
        handleChangeSelect,
        setShouldOpenAddFormOfDialog,
        handleCheckBoxChange,
        isViewOnly,
        handleChangePositiveNumber,
        handleSetFile,
        isAddNew = false,
        isPersonal = false
    } = props;
    const { lang } = useMultiLanguage();
    const [peopleDecisions, setPeopleDecisions] = useState<IPeopleDecision[]>([]);
    const [isShowDialogAddRepresentative, setIsShowDialogAddRepresentative] = useState<boolean>(false);

    const getPeopleDecision = useCallback(async () => {
        try {
            const { data } = await searchNguoiDaiDienKy();
            if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
                setPeopleDecisions(data?.data?.content || [])
            } else {
                toast.warning(data?.message);
            }
        } catch (error) {
            toast.error(lang("GENERAL.ERROR"));
        }
    }, [lang]);

    useEffect(() => {
        getPeopleDecision();
    }, [getPeopleDecision]);

    const handleCloseAndSearchRepresentative = () => {
        getPeopleDecision();
        setIsShowDialogAddRepresentative(false);
    }

    useEffect(() => {
        if (!formik.values?.id && peopleDecisions.length > 0) {
          formik.setFieldValue(VARIABLE_STRING.NGUOI_KY_QUYET_DINH, peopleDecisions.find(people => people.isDefault) || null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [peopleDecisions]);

    return (
        <>
            <Row className="position-relative spaces z-index-2">
                <Col xs={12}>
                    <Row className="pt-2 gap-col-modal">
                        <Col xs={2} md={4} lg={3} xxl={2}>
                            <Autocomplete
                                isRequired
                                name="doiTuongKhenThuong"
                                lable={lang("APPRECIATIVE.DOITUONGKHENTHUONGTEXT")}
                                isReadOnly={isView}
                                value={formik.values?.doiTuongKhenThuong || null}
                                errors={formik.errors?.doiTuongKhenThuong}
                                touched={formik.touched?.doiTuongKhenThuong}
                                onChange={(value) => handleChangeSelect(VARIABLE_STRING.DOT_KHEN_THUONG, value)}
                                options={isPersonal ? [bonusObject[0]] :bonusObject}
                            />
                        </Col>
                        <Col xs={2} md={4} lg={3} xxl={2}>
                            <TextValidator
                                isRequired
                                name="dotKhenThuong"
                                lable={lang("APPRECIATIVE.NAME")}
                                readOnly={isView}
                                type="text"
                                value={formik.values?.dotKhenThuong || ""}
                                errors={formik.errors?.dotKhenThuong}
                                touched={formik.touched?.dotKhenThuong}
                                onChange={formik.handleChange}
                            />
                        </Col>
                        <Col xs={2} md={4} lg={3} xxl={2}>
                            <Autocomplete
                                isRequired
                                name="loaiKhenThuong"
                                lable={lang("APPRECIATIVE.LOAIKHENTHUONGTEXT")}
                                isReadOnly={isView}
                                value={formik.values?.loaiKhenThuong || null}
                                errors={formik.errors?.loaiKhenThuong}
                                touched={formik.touched?.loaiKhenThuong}
                                onChange={(value) => handleChangeSelect(VARIABLE_STRING.LOAI_KHEN_THUONG, value)}
                                options={bonusType}
                            />
                        </Col>
                        <Col xs={2} md={4} lg={3} xxl={2}>
                            <TextValidator
                                isRequired
                                name="namKhenThuong"
                                lable={lang("APPRECIATIVE.YEAR")}
                                readOnly={isView}
                                type="number"
                                value={formik.values?.namKhenThuong || ""}
                                onChange={formik.handleChange}
                                errors={formik.errors?.namKhenThuong}
                                touched={formik.touched?.namKhenThuong}
                            />
                        </Col>
                        <Col xs={2} md={4} lg={3} xxl={2}>
                            <Autocomplete
                                isRequired
                                name="hinhThucKhenThuong"
                                lable={lang("APPRECIATIVE.HINHTHUCKHENTHUONGTEXT")}
                                isReadOnly={isView}
                                value={formik.values?.hinhThucKhenThuong || null}
                                errors={formik.errors?.hinhThucKhenThuong}
                                touched={formik.touched?.hinhThucKhenThuong}
                                onChange={(value) => handleChangeSelect(VARIABLE_STRING.HINH_THUC_KHEN_THUONG, value)}
                                options={[]}
                                searchFunction={searchAllSimpleValue}
                                searchObject={{ type: TYPE_CATEGORY.hinhThucKhenThuong }}
                                getOptionLabel={(options) => options?.value}
                                isAddNew={isAddNew}
                                onAddNew={() => setShouldOpenAddFormOfDialog(true)}
                            />
                        </Col>
                        <Col xs={2} md={4} lg={3} xxl={2}>
                            <TextValidator
                                name="noiDungKhenThuong"
                                lable={lang("APPRECIATIVE.CONTENT")}
                                readOnly={isView}
                                type="text"
                                value={formik.values?.noiDungKhenThuong || ""}
                                onChange={formik.handleChange}
                            />
                        </Col>
                        <Col xs={2} md={4} lg={3} xxl={2}>
                            <Autocomplete
                                lable={lang("BONUS.EMULATION")}
                                value={formik.values?.danhHieuThiDua}
                                name={VARIABLE_STRING.DANH_HIEU_THI_DUA}
                                onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.DANH_HIEU_THI_DUA, selectedOption)}
                                isReadOnly={isView}
                                options={[]}
                                searchFunction={searchAllSimpleValue}
                                searchObject={{ type: TYPE_CATEGORY.danhHieu }}
                                getOptionLabel={(options) => options?.value}
                            />
                        </Col>
                        <Col xs={2} md={4} lg={3} xxl={2}>
                            <Autocomplete
                                isRequired
                                name="capQuyetDinh"
                                lable={lang("APPRECIATIVE.DECISION_LEVEL")}
                                isReadOnly={isView}
                                value={formik.values?.capQuyetDinh || null}
                                onChange={(value) => handleChangeSelect(VARIABLE_STRING.CAP_QUYET_DINH, value)}
                                options={bonusDecisionLevel}
                                errors={formik.errors?.capQuyetDinh}
                                touched={formik.touched?.capQuyetDinh}
                            />
                        </Col>
                        <Col xs={2} md={4} lg={3} xxl={2}>
                            <TextValidator
                                name="ngayBanHanh"
                                lable={lang("APPRECIATIVE.NGAYQUYETDINH")}
                                readOnly={isView}
                                type="date"
                                value={formik.values?.ngayBanHanh || ""}
                                onChange={formik.handleChange}
                                onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.ngayBanHanh, "ngayBanHanh")}
                            />
                        </Col>
                        <Col xs={2} md={4} lg={3} xxl={2}>
                            <TextValidator
                                isRequired
                                name="ngayKhenThuong"
                                lable={lang("APPRECIATIVE.DATE")}
                                readOnly={isView}
                                type="date"
                                value={covertDateToString(formik.values?.ngayKhenThuong)}
                                onChange={formik.handleChange}
                                onBlur={() =>
                                    handleBlurDate(formik.setFieldValue, formik.values?.ngayKhenThuong, "ngayKhenThuong")
                                }
                                errors={formik.errors?.ngayKhenThuong}
                                touched={formik.touched?.ngayKhenThuong}
                            />
                        </Col>
                        <Col xs={2} md={4} lg={3} xxl={2}>
                            <TextValidator
                                name="soQuyetDinh"
                                lable={lang("APPRECIATIVE.SOQUYETDINH")}
                                readOnly={isView}
                                type="text"
                                value={formik.values?.soQuyetDinh || ""}
                                onChange={formik.handleChange}
                            />
                        </Col>
                        <Col xs={2} md={4} lg={3} xxl={2}>
                            <Autocomplete
                                name="donViBanHanh"
                                lable={lang("APPRECIATIVE.AGENCIES_UNIT_DECITION")}
                                isReadOnly={isView}
                                value={formik.values?.donViBanHanh || null}
                                onChange={(value) => handleChangeSelect(VARIABLE_STRING.DON_VI_BAN_HANH, value)}
                                options={[]}
                                searchFunction={searchListWorkUnit}
                            />
                        </Col>
                        <Col xs={6} lg={5} xxl={3}>
                            <Row>
                                <Col xs={6}>
                                    <Form.Check
                                        label={lang("APPRECIATIVE.SALARY_AUJUSTED")}
                                        name="daXetDieuChinhLuong"
                                        checked={formik.values?.daXetDieuChinhLuong}
                                        disabled={isView}
                                        onChange={handleCheckBoxChange}
                                        className="checkBox custom-form-check"
                                    />
                                </Col>
                                <Col xs={6}>
                                    <Form.Check
                                        label={lang("APPRECIATIVE.MAX")}
                                        name="khenThuongCaoNhat"
                                        checked={formik.values?.khenThuongCaoNhat}
                                        disabled={isView}
                                        onChange={handleCheckBoxChange}
                                        className="checkBox custom-form-check"
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={6} lg={7} xxl={5}>
                            <Row>
                                <Col xs={6} lg={6} xxl={6}>
                                    <Form.Check
                                        label={lang("APPRECIATIVE.SALARY_AUJUSTMENT_TIME")}
                                        name="ahtgDieuChinhLuong"
                                        checked={formik.values?.ahtgDieuChinhLuong}
                                        disabled={isView}
                                        onChange={handleCheckBoxChange}
                                        className="checkBox custom-form-check"
                                    />
                                </Col>
                                <Col xs={6} lg={6} xxl={6}>
                                    <TextValidator
                                        isRequired={formik.values?.ahtgDieuChinhLuong}
                                        name="thoiGianHuongLuongKeoDai"
                                        lable={lang("APPRECIATIVE.SALARY_AFFECT_TIME")}
                                        readOnly={isView || !formik.values?.ahtgDieuChinhLuong}
                                        type="number"
                                        value={formik.values?.thoiGianHuongLuongKeoDai || ""}
                                        onChange={handleChangePositiveNumber}
                                        onKeyDown={justAllowNumber}
                                        errors={formik.errors?.thoiGianHuongLuongKeoDai}
                                        touched={formik.touched?.thoiGianHuongLuongKeoDai}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={2} md={4} lg={4} xxl={2}>
                            <Autocomplete
                                name="trangThai"
                                lable={lang("APPRECIATIVE.TRANGTHAITEXT")}
                                isReadOnly={isViewOnly ? isViewOnly : isView}
                                value={formik.values?.trangThai || null}
                                onChange={(value) => handleChangeSelect(VARIABLE_STRING.TRANG_THAI, value)}
                                options={MISSION_STATUS}
                            />
                        </Col>
                        <Col xs={2} md={4} lg={4} xxl={2}>
                            <Autocomplete
                                name="nguoiKyQuyetDinh"
                                lable={lang("APPRECIATIVE.PERSON_SINGING")}
                                isReadOnly={isView}
                                isAddNew
                                onAddNew={() => setIsShowDialogAddRepresentative(true)}
                                value={formik.values?.nguoiKyQuyetDinh || null}
                                onChange={(value) => handleChangeSelect(VARIABLE_STRING.NGUOI_KY_QUYET_DINH, value)}
                                options={peopleDecisions}
                            />
                        </Col>
                        <Col xs={2} md={4} lg={4} xxl={2}>
                            <UploadFile
                                isReadOnly={isView}
                                label="CONTRACT.ATTACHMENTS"
                                setValue={handleSetFile}
                                fileValue={{
                                    id: formik.values?.fileId || "",
                                    name: formik.values?.fileName || ""
                                }}
                            />
                        </Col>
                        <Col xs={12} xxl={10}>
                            <TextValidator
                                rows="1"
                                name="ghiChu"
                                lable={lang("ALLOWANCE.NOTE")}
                                as="textarea"
                                readOnly={isView}
                                value={formik.values?.ghiChu || ""}
                                onChange={formik.handleChange}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            {isShowDialogAddRepresentative && (
                <AddNewRepresentative
                handleClose={() => setIsShowDialogAddRepresentative(false)}
                handleCloseAndSearch={handleCloseAndSearchRepresentative}
                />
            )}
        </>
    )
}

export default BonusForm
import { IShift } from "../models/shiftModels";

export const INIT_SHIFT: IShift = {
  maCa: "",
  tenCa: "",
  thoiGianBatDau: null,
  thoiGianKetThuc: null,
  soGioLam: null,
  soGioTinhCong: null,
  caNghiBu: null,
  caNghiBuId: "",
  caNghiBuText: "",
  loaiCa: null
}

export const TYPE_SHIFT = [
  {
    code: 1,
    name: "Ca sáng"
  },
  {
    code: 2,
    name: "Ca chiều"
  },
  {
    code: 3,
    name: "Cả ngày"
  },
  {
    code: 4,
    name: "Theo giờ"
  }
]

export const NAME_COMPARISON_OPERATOR = {
  NHO_HON: "<",
  NHO_HON_BANG: "<=",
  LON_HON: ">",
  LON_HON_BANG: ">=",
  BANG: "=",
}

export const LIST_COMPARISON_OPERATOR = [
  {
    code: 1,
    name: NAME_COMPARISON_OPERATOR.NHO_HON,
  },
  {
    code: 2,
    name: NAME_COMPARISON_OPERATOR.NHO_HON_BANG,
  },
  {
    code: 3,
    name: NAME_COMPARISON_OPERATOR.LON_HON,
  },
  {
    code: 4,
    name: NAME_COMPARISON_OPERATOR.LON_HON_BANG,
  },
  {
    code: 5,
    name: NAME_COMPARISON_OPERATOR.BANG,
  }
]
import { FC, useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import AppContext from "../../AppContext";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../constants";
import useMultiLanguage from "../../hook/useMultiLanguage";
import InputSearch from "../components/input/InputSearch";
import TableCustom, { columnNamesType } from "../components/table-custom/TableCustom";
import AdvancedSearch from "../profile/components/dialogs/AdvancedSearch";
import { exportPhucLoi } from "../services/exportFileServices";
import { checkStatus, convertSearch, exportToFile, hasAuthority } from "../utils/functionUtils";
import { WelfareForm } from "./components/WelfareForm";
import { ListSearch } from "./constants/welfareConsts";
import { IWelfare } from "./models/welfareModels";
import "./welfare.scss";
import { GroupButton } from "../components/GroupButton";
import { deleteWelfare, searchWelfare } from "./services/welfareServices";
import { EXPORT_FILE_NAME, INIT_INPUT_SEARCH, INIT_SEARCH_OBJECT, MISSION_STATUS, MISSION_STATUS_CODE, RESPONSE_STATUS_CODE, TYPE } from "../constants/moduleConsts";
import { IItemSearch } from "../models/models";

const WelfareList: FC = () => {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [isView, setIsView] = useState<boolean>(false);
  const [shouldOpenWelfareDialog, setShouldOpenWelfareDialog] = useState<boolean>(false);
  const [searchObject, setSearchObject] = useState<any>(INIT_SEARCH_OBJECT);
  const [isAdvancedSearch, setIsAdvancedSearch] = useState<boolean>(false);
  const [listInputSearch, setListInputSearch] = useState<any[]>([INIT_INPUT_SEARCH]);
  const [listWelfare, setListWelfare] = useState<IWelfare[]>([]);
  const [welfare, setWelfare] = useState<IWelfare>({} as IWelfare);
  const [dataChecked, setDataChecked] = useState<IWelfare[]>([]);

  const updatePageData = async (searchData?: any) => {
    try {
      setPageLoading(true);
      setSearchObject({ ...searchData })
      const { data } = await searchWelfare(searchData);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListWelfare(data?.data?.content || []);
        setTotalPage(data?.data?.pageable?.totalPages || 0);
        setTotalElements(data?.data?.pageable?.totalElements || 0);
        setNumberOfElements(data?.data?.pageable?.numberOfElements || 0);
        setIsAdvancedSearch(false);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleOpenAdvancedSearch = () => {
    setIsAdvancedSearch(true);
  };

  const handleOpenDialog = () => {
    setShouldOpenWelfareDialog(true);
  };

  const handleOpenInfoDialog = (row: any) => {
    setIsView(true);
    setShouldOpenWelfareDialog(true);
    setWelfare(row);
  };
  
  const handleCloseAndSearch = () => {
    handleSearch();
    handleCloseDialog();
  }

  const handleCloseDialog = () => {
    setShouldOpenWelfareDialog(false);
    setWelfare({} as IWelfare);
    setIsView(false);
  };

  const handleOpenUpdateDialog = () => {
    setIsView(false);
  };

  const handleCloseUpdateDialog = () => {
    setIsView(true);
  };

  const handleSearch = (data: any = {}) => {
    const dataSearch: any = {
      ...searchObject,
      ...data
    };
    dataSearch.keyword = dataSearch?.keyword?.trim() || ""
    updatePageData(dataSearch);
  };

  useEffect(() => {
    if(searchObject?.pageIndex && searchObject?.pageSize){
      handleSearch()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchObject?.pageIndex, searchObject?.pageSize])

  const handleAdvancedSearch = (data: IItemSearch[]) => {
    setListInputSearch(data);
    handleSearch({ ...convertSearch(data), pageIndex: 1 });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchObject({
      keyword: e.target?.value
    });
  };

  const handleDeleteWelfare = async (ids: string[]) => {
    try {
      const { data } = await deleteWelfare(ids);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("TOAST.DELETE.SUCCESS"));
        await updatePageData(searchObject);
        return true;
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const columns: columnNamesType[] = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("GENERAL.STATUS"),
      field: "trangThai",
      headerStyle: {
        minWidth: "150px"
      },
      render: (row: any) => 
        <span className={`${checkStatus(MISSION_STATUS, row?.trangThai?.code)} status`}>
          {row?.trangThai?.name}
        </span>
    },
    {
      name: lang("WELFARE.NAME"),
      field: "tenPhucLoi",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
      render: (row: any) => <span className="color-steel-blue fw-600">{row?.tenPhucLoi}</span>
    },
    {
      name: lang("WELFARE.GROUP"),
      field: "nhomPhucLoi",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        minWidth: "200px",
        textAlign: "left"
      },
      render: (row: any) => <span>{row?.nhomPhucLoi?.name}</span>
    },
    {
      name: lang("WELFARE.APPLY.UNIT"),
      field: "donViApDungText",
      headerStyle: {
        minWidth: "350px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("WELFARE.ORGANIZATION.PLACE"),
      field: "diaDiemToChuc",
      headerStyle: {
        minWidth: "400px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("WELFARE.ORGANIZATION.PURPOSE"),
      field: "mucDichToChuc",
      headerStyle: {
        minWidth: "300px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("WELFARE.IMPLEMENT.FORM"),
      field: "hinhThucThucHien",
      headerStyle: {
        minWidth: "250px"
      },
      cellStyle: {
        textAlign: "left"
      },
      render: (row: any) => <span>{row?.hinhThucThucHien?.name}</span>
    },
    {
      name: lang("WELFARE.EXPECTED.PEOPLE"),
      field: "soNguoiDuKien",
      headerStyle: {
        minWidth: "150px"
      },
      cellStyle: {
        textAlign: "left"
      }
    }
  ];

  return (
    <div className="spaces px-8 py-12 flex-1">
      {!shouldOpenWelfareDialog && (
        <>
          <Row className="spaces mb-12 flex-middle">
            <Col xs={5} xxl={7} className="spaces p-0">
              <h2 className="breadcrumb-title">{lang("GENERAL.WELFARE.LIST")}</h2>
            </Col>
            <Col
              xs={7} xxl={5}
              className="flex"
            >
              <div className="spaces mr-10 flex-1">
                <InputSearch
                  className="border-3px"
                  value={searchObject?.keyword}
                  placeholder={"Tìm kiếm theo tên phúc lợi"}
                  handleSearch={() => handleSearch({ pageIndex: 1 })}
                  handleChange={handleChange}
                  isEnter
                />
              </div>
              <GroupButton className="spaces mr-10" handleEvent={() => handleSearch({ pageIndex: 1 })}>
                {lang("BTN.SEARCH")}
              </GroupButton>
              <GroupButton className="spaces mr-10" outline handleEvent={handleOpenAdvancedSearch}>
                {lang("BTN.ADVANCE_SEARCH")}
              </GroupButton>
              {isAdvancedSearch && (
                <AdvancedSearch
                  open={isAdvancedSearch}
                  listInputSearch={listInputSearch}
                  handleSearch={handleAdvancedSearch}
                  listOption={ListSearch(lang)}
                  handleClose={() => setIsAdvancedSearch(false)}
                />
              )}
            </Col>
          </Row>
          <Row className="bg-white">
            <Col xs={12} className="table-scroll">
              <TableCustom
                id="welfare-list"
                data={listWelfare}
                columns={columns}
                searchObject={searchObject}
                setSearchObject={setSearchObject}
                buttonAdd={hasAuthority(PERMISSIONS.PHUC_LOI_MENU, PERMISSION_ABILITY.CREATE)}
                buttonExportExcel={true}
                notDelete={!hasAuthority(PERMISSIONS.PHUC_LOI_MENU, PERMISSION_ABILITY.DELETE)}
                handleExportExcel={(ids: string[]) =>
                  exportToFile({
                    exportAPI: () => exportPhucLoi({ ids, ...searchObject }),
                    fileName: EXPORT_FILE_NAME.PHUC_LOI,
                    setPageLoading
                  })
                }
                type={TYPE.MULTILINE}
                fixedColumnsCount={3}
                totalPages={totalPage}
                totalElements={totalElements}
                numberOfElements={numberOfElements}
                handleOpenDialog={handleOpenDialog}
                handleDoubleClick={handleOpenInfoDialog}
                handleDelete={handleDeleteWelfare}
                setDataChecked={setDataChecked}
                dataChecked={dataChecked}
                deleteConditional={[
                  {
                    keyPath: "trangThai.code",
                    value: MISSION_STATUS_CODE.PROCESSING
                  },
                  {
                    keyPath: "trangThai.code",
                    value: MISSION_STATUS_CODE.COMPLETED
                  }
                ]}
              />
            </Col>
          </Row>
        </>
      )}
      {shouldOpenWelfareDialog && (
        <WelfareForm
          isView={isView}
          setIsView={setIsView}
          welfare={welfare}
          handleCloseDialog={handleCloseDialog}
          handleCloseAndSearch={handleCloseAndSearch}
          handleCloseUpdateDialog={handleCloseUpdateDialog}
          handleOpenUpdateDialog={handleOpenUpdateDialog}
        />
      )}
    </div>
  );
};
export { WelfareList };

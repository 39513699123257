import { useContext, useEffect, useState } from "react";
import useMultiLanguage from "../../../../../hook/useMultiLanguage";
import TableCustom from "../../../../components/table-custom/TableCustom";
import { EXPORT_FILE_NAME, RESPONSE_STATUS_CODE, TYPE } from "../../../../constants/moduleConsts";
import { exportLichSuLuong } from "../../../../services/exportFileServices";
import { exportToFile, formatDateTable, hasAuthority } from "../../../../utils/functionUtils";
import { REF_TAB } from "../../../constants/profileConsts";
import { EmployeeProfile } from "../../../models/profileModels";
import { deleteLichSuLuong, getAllLichSuLuongById } from "../../../services/dialogServices";
import SalaryDevelopmentDialog from "../../dialogs/SalaryDevelopmentDialog";
import AppContext from "../../../../../AppContext";
import { toast } from "react-toastify";
import { PERMISSION_ABILITY, PERMISSIONS } from "../../../../../constants";

interface Props {
  isView: boolean;
  employeeProfiles: EmployeeProfile;
  identify: string;
  activeTab: string;
}

function SalaryDevelopment(props: Props) {
  const { lang } = useMultiLanguage();

  const { isView, employeeProfiles, identify, activeTab } = props;
  const [listData, setListData] = useState([]);
  const [shouldOpenCreateDialog, setShouldOpenCreateDialog] = useState<boolean>(false);
  const [salaryInfo, setSalaryInfo] = useState({});
  const { setPageLoading } = useContext(AppContext);

  useEffect(() => {
    if (identify && activeTab === REF_TAB.TT_LUONG) {
      updateDataSalaryDevelop();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identify, activeTab]);

  const updateDataSalaryDevelop = async () => {
    if(identify) {
      try {
        const { data } = await getAllLichSuLuongById(String(employeeProfiles.id));
        if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
          setListData(data?.data || []);
        } else {
          toast.warning(data?.message);
        }
      } catch (error) {
        toast.error(lang("GENERAL.ERROR"));
      }
    }
  };

  const handleCloseCreateDialog = () => {
    setShouldOpenCreateDialog(false);
    setSalaryInfo({});
  };

  const handleOpenCreateDialog = (data: any) => {
    setShouldOpenCreateDialog(true);
    setSalaryInfo(data);
  };

  const handleCloseAndSearch = () => {
    updateDataSalaryDevelop();
    handleCloseCreateDialog();
  }

  const handleDeleteSalary = async (ids: string[]) => {
    try {
      const { data } = await deleteLichSuLuong(ids);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("GENERAL.DELETE_SUCCESS"));
        await updateDataSalaryDevelop();
        return true;
      } else toast.warning(data?.message);
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }

  const columns = [
    {
      name: lang("TABLE.INDEX"),
      field: "stt",
      headerStyle: {
        minWidth: "100px"
      },
      render: (row: any, index: number, STT: number) => <span>{STT}</span>
    },
    {
      name: lang("INPUT.SALARY.DATE.ENNABLE"),
      field: "ngayHieuLuc",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row: any) => (
        <span>
          <span>{formatDateTable(row?.ngayHuongLuongTuNgay)}</span>
          {row?.ngayHuongLuongDenNgay ? <span> {" - " + formatDateTable(row?.ngayHuongLuongDenNgay)}</span> : ""}
        </span>
      )
    },
    {
      name: lang("INPUT.SALARY.KIND"),
      field: "loaiLuong",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row: any) => <span>{row?.loaiLuong?.name}</span>
    },
    {
      name: lang("GENERAL.EMPLOYEE.POSITION"),
      field: "chucVuText",
      headerStyle: {
        minWidth: "200px"
      }
    },
    {
      name: lang("GENERAL.EMPLOYEE.TITLE"),
      field: "chucDanhText",
      headerStyle: {
        minWidth: "270px"
      }
    },
    {
      name: lang("INPUT.SALARY.RANK"),
      field: "bacLuong",
      headerStyle: {
        minWidth: "100px"
      }
    },
    {
      name: lang("ALLOWANCE.COEFFICIENT"),
      field: "heSoBacLuong",
      headerStyle: {
        minWidth: "100px"
      }
    },
    {
      name: lang("INPUT.SALARY.AGREEMENTS"),
      field: "luongThoaThuan",
      headerStyle: {
        minWidth: "200px"
      }
    }
  ];

  return (
    <div className="block-content">
      <TableCustom
        title={lang("SALARY.DEVELOPMENT")}
        isActionTableTab
        data={listData}
        columns={columns}
        justFilter={true}
        type={TYPE.MULTILINE}
        buttonExportExcel={true}
        notDelete={!hasAuthority(PERMISSIONS.LICH_SU_LUONG, PERMISSION_ABILITY.DELETE) || isView}
        noPagination={true}
        buttonAdd={!isView}
        handleDelete={handleDeleteSalary}
        handleDoubleClick={handleOpenCreateDialog}
        handleOpenDialog={(row) => handleOpenCreateDialog({})}
        handleExportExcel={() => 
          exportToFile({
            exportAPI: () => exportLichSuLuong(String(employeeProfiles?.id)), 
            fileName: EXPORT_FILE_NAME.DIEN_BIEN_LUONG,
            setPageLoading
          })
        }
      />
      {shouldOpenCreateDialog && (
        <SalaryDevelopmentDialog
          isView={isView}
          salaryInfo={salaryInfo}
          handleClose={handleCloseCreateDialog}
          show={shouldOpenCreateDialog}
          employeeProfiles={employeeProfiles}
          handleCloseAndSearch={handleCloseAndSearch}
        />
      )}
    </div>
  );
}

export default SalaryDevelopment;

import { formatDateTable } from "../../../utils/functionUtils";
import CustomTooltip from "../../../components/custom-tooltip";

export const canhBaoHetHanCuDaoTaoColumn = (handleAddCertificate: (row: any) => void) => {
  return [
    {
      name: "Trạng thái",
      field: "trangThaiHetHanCuDaoTao",
      headerCellProps: {
        minWidth: 120
      },
      render: (row: any) => (
        <span className={`${row?.trangThaiHetHanCuDaoTao?.bgColor} warning-status`}>{row?.trangThaiHetHanCuDaoTao?.name}</span>
      )
    },
    {
      name: "Thao tác",
      field: "custom",
      headerCellProps: {
        minWidth: 80
      },
      render: (row: any) => (
        <div className="flex flex-middle flex-center">
          <CustomTooltip
            title={"Bổ sung chứng chỉ"}
            placement="right"
          >
            <span
              onClick={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                e.stopPropagation();
                handleAddCertificate && handleAddCertificate(row);
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-sign-intersection text-primary" viewBox="0 0 16 16">
                <path d="M7.25 4v3.25H4v1.5h3.25V12h1.5V8.75H12v-1.5H8.75V4z"/>
                <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098zm-1.4.7a.495.495 0 0 1 .7 0l6.516 6.515a.495.495 0 0 1 0 .7L8.35 14.866a.495.495 0 0 1-.7 0L1.134 8.35a.495.495 0 0 1 0-.7L7.65 1.134Z"/>
              </svg>
            </span>
          </CustomTooltip>
        </div>
      )
    },
    {
      name: "Họ và tên",
      field: "tenNhanVien",
      headerCellProps: {
        minWidth: 200
      },
      render: (row: any) => (
        <span>{`${row?.maNhanVien || ""} ${row?.maNhanVien && row?.tenNhanVien ? "-" : ""} ${
          row?.tenNhanVien || ""
        }`}</span>
      )
    },
    {
      name: "Đơn vị công tác",
      field: "donViCongTacText",
      headerCellProps: {
        minWidth: 250
      }
    },
    {
      name: "Khoa/Phòng",
      field: "phongBanText",
      headerCellProps: {
        minWidth: 300
      }
    },
    {
      name: "Mã ngạch/Chức danh",
      field: "chucDanhText",
      headerCellProps: {
        minWidth: 250
      }
    },
    {
      name: "Tên khoá đào tạo",
      field: "tenKhoaDaoTao",
      headerCellProps: {
        minWidth: 200
      }
    },
    {
      name: "Nơi đào tạo",
      field: "noiDaoTao",
      headerCellProps: {
        minWidth: 150
      }
    },
    {
      name: "Từ ngày",
      field: "hieuLucTuNgay",
      headerCellProps: {
        minWidth: 100
      },
      bodyCellProps: {
        textAlign: "center"
      },
      render: (row: any) => <span>{formatDateTable(row?.hieuLucTuNgay)}</span>
    },
    {
      name: "Đến ngày",
      field: "hieuLucDenNgay",
      headerCellProps: {
        minWidth: 100
      },
      bodyCellProps: {
        textAlign: "center"
      },
      render: (row: any) => <span>{formatDateTable(row?.hieuLucDenNgay)}</span>
    },
    {
      name: "Số ngày còn lại",
      field: "soNgayConLai",
      headerCellProps: {
        minWidth: 120
      },
      bodyCellProps: {
        textAlign: "center"
      }
    }
  ];
};

export const TYPE_HANDLE_ROW = {
  ADD: "ADD",
  DELETE: "DELETE"
}

export const LIST_ICON_HANDLE_ROW = [
  {
    type: TYPE_HANDLE_ROW.ADD,
    icon: "/media/icons/add.svg"
  },
  {
    type: TYPE_HANDLE_ROW.DELETE,
    icon: "/media/icons/minus-solid.svg"
  }
]
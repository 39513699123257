import { useContext, useEffect, useState } from "react";
import TableCustom, { columnNamesType } from "../../table-custom/TableCustom";
import { toast } from "react-toastify";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import { RESPONSE_STATUS_CODE, TYPE } from "../../../constants/moduleConsts";
import { convertEmployeeId, removeChecked } from "../utils/common";
import AppContext from "../../../../AppContext";
import { EmployeeProfile } from "../../../profile/models/profileModels";
import { searchAllEmployee } from "../../../services/services";

interface IProps {
  departmentInfo: any;
  setDataChecked: any;
  selectedData: Array<any>;
  isEnableFilterData: boolean;
  resetSelectedRows: boolean;
  funcConvert?: (data: EmployeeProfile[]) => any;
  prefixNameChildren?: string;
  columnsCustom?: columnNamesType[];
  searchObjectsCustom?: any;
}

function EmployeeForDepartmentTable(props: IProps) {
  const {
    departmentInfo,
    setDataChecked,
    selectedData,
    isEnableFilterData,
    funcConvert,
    prefixNameChildren = "listEmployee",
    columnsCustom,
    searchObjectsCustom
  } = props;
  const { lang } = useMultiLanguage();
  const [employeeList, setEmployeeList] = useState<any[]>([]);
  const [prevEmployeeList, setPrevEmployeeList] = useState<any[]>([]);
  const { setPageLoading } = useContext(AppContext);

  useEffect(() => {
    filterDataExist(prevEmployeeList);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnableFilterData]);

  useEffect(() => {
    searchAllEmployeeByDepartment();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentInfo]);

  const searchAllEmployeeByDepartment = async () => {
    if (!departmentInfo?.id) return;

    try {
      setPageLoading(true);
      const searchObj = {
        ...searchObjectsCustom,
        phongBanIds: departmentInfo?.id || "",
      };
      const { data } = await searchAllEmployee(searchObj);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        let employeeConvert = funcConvert
          ? funcConvert(data?.data?.content || [])
          : convertEmployeeId(data?.data?.content || []);
        filterDataExist(employeeConvert);
        setPrevEmployeeList(employeeConvert);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const filterDataExist = (data: any[]) => {
    const currentDepartmentData = selectedData?.find((item) => item?.phongBanId === departmentInfo?.id);
    const filteredData = currentDepartmentData
      ? data?.filter(
          (item: any) =>
            !currentDepartmentData?.[prefixNameChildren]?.some(
              (departmentItem: any) => departmentItem?.employeeId === item?.employeeId
            )
        )
      : data;

    setEmployeeList(removeChecked(filteredData));
  };

  const columns: columnNamesType[] = columnsCustom
    ? [...columnsCustom]
    : [
        {
          name: lang("INPUT.CODE"),
          field: "custom",
          headerStyle: {
            minWidth: "200px"
          },
          cellStyle: {
            textAlign: "left"
          },
          render: (row: any) => (
            <span>
              <p className="color-steel-blue fw-600 spaces p-0 m-0">{row?.maNhanVien}</p>
              <p className="spaces p-0 m-0">{row?.name || row?.tenNhanVien}</p>
            </span>
          )
        },
        {
          name: lang("INPUT.SALARY.POSITION"),
          field: "chucVuText",
          headerStyle: {
            minWidth: "200px"
          },
          cellStyle: {
            textAlign: "left"
          }
        },
        {
          name: lang("GENERAL.EMPLOYEE.TITLE"),
          field: "viTriCongViecText",
          headerStyle: {
            minWidth: "200px"
          },
          cellStyle: {
            textAlign: "left"
          }
        }
      ];

  return (
    <div className="table-modal-h">
      <TableCustom
        data={employeeList}
        columns={columns}
        type={TYPE.MULTILINE}
        noPagination
        noToolbar
        notDelete
        setDataChecked={setDataChecked}
        uniquePrefix="employeeId"
        unSelectedAll={false}
      />
    </div>
  );
}

export default EmployeeForDepartmentTable;

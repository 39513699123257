import { IEmployeeJoin, IExpenses } from "../models/welfareModels";

export const convertEmployeeJoin = (data: any[], moneyCost: number, moneySupport: number, welfareId: string) => {
  return data?.map((firstItem) => {
    let listEmployeeConverted = firstItem?.data?.map((item: any) => {
      return {
        ...item,
        phucLoiId: welfareId,
        soTienNhanVienDong: moneyCost,
        soTienDonViHoTro: moneySupport
      };
    });

    return { ...firstItem, data: listEmployeeConverted };
  });
};

export const convertDataSubmit = (data: any, employeeJoin: IEmployeeJoin[], expense: IExpenses[]) => {
  const _expense = [...expense];
  _expense.forEach(item => delete item?.id);
  const _employeeJoin = employeeJoin?.map(item => item?.data)?.flat();
  _employeeJoin?.forEach(item => item?.phucLoiThamGiaRequestDtoList?.forEach(item => delete item?.id));
  return {
    ...data,
    phucLoiChiTietRequestDtoList: _employeeJoin,
    phucLoiKhoanChiRequestDtoList: _expense
  };
};

export const convertDataEmployeeSelect = (data: any[]) => {
  return data?.map((item) => {
    return {
      ten: item?.name || "",
      donViApDungId: item?.donViCongTacId || "",
      donViApDungText: item?.donViCongTacText || "",
      employeeId: item?.id || "",
      maNhanVien: item?.maNhanVien || "",
      phongBanId: item?.phongBanId || "",
      phongBanText: item?.phongBanText || "",
      chucVuId: item?.chucVuId || "",
      chucVuText: item?.chucVuText || ""
    };
  });
};

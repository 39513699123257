import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import * as Yup from "yup";
import AppContext from "../../../../AppContext";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import { IWorkingHistory } from "../../models/dialogModels";
import { INIT_WORKING_HISTORY, WORKING_HISTORY_OLD_MODE_TYPE } from "../../constants/profileDialogConsts";
import { RangeDatePicker } from "../../../components/input/RangeDatePicker";
import { RESPONSE_STATUS_CODE, TYPE } from "../../../constants/moduleConsts";
import { addLichSuLamViec, updateLichSuLamViec } from "../../services/dialogServices";
import TextArea from "../../../components/input/TextArea";
import { GroupButton } from "../../../components/GroupButton";
import { hasAuthority } from "../../../utils/functionUtils";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../../../constants";

interface Props {
  open: boolean;
  handleClose: () => void;
  handleCloseAndSearch: () => void;
  isView: boolean;
  dataEdit: IWorkingHistory;
  employeeId: string;
}
export const WorkInOldModeDialog = (props: Props) => {
  const { open, handleClose, handleCloseAndSearch, isView, dataEdit, employeeId } = props;
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [isEdit, setIsEdit] = useState<boolean>(!isView);

  useEffect(() => {
    if (dataEdit?.id) {
      formik.setValues(dataEdit);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataEdit]);

  const validationSchema: any = Yup.object().shape({
    thongTin: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    tuNgay: Yup.date().required(lang("VALIDATION.REQUIRE")).nullable(),
    denNgay: Yup.date()
      .required(lang("VALIDATION.REQUIRE"))
      .min(Yup.ref("tuNgay"), lang("VALIDATON.NOT_SMALL_FORMDATE"))
      .nullable()
  });

  const handleSubmit = async (values: IWorkingHistory) => {
    try {
      setPageLoading(true);
      let dataSubmit = { ...values, employeeId, type: WORKING_HISTORY_OLD_MODE_TYPE };
      let { data } = await (dataEdit?.id
        ? updateLichSuLamViec(dataEdit?.id, dataSubmit)
        : addLichSuLamViec(dataSubmit));

      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        let message = dataEdit?.id ? "TOAST.EDIT.SUCCESS" : "TOAST.ADD.SUCCESS";
        toast.success(lang(message));
        handleCloseAndSearch();
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: INIT_WORKING_HISTORY,
    validationSchema,
    onSubmit: handleSubmit
  });

  const handleChange = (value: any, name: string) => {
    formik.setFieldValue(name, value);
  };

  return (
    <>
      <Modal show={open} size="lg" centered aria-labelledby="example-custom-modal-styling-title" onHide={handleClose} backdrop="static" >
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title" className="heading-5">
              {lang(dataEdit?.id ? "PROFILE.OTHER.WORKING_MODE_OLD.EDIT" : "PROFILE.OTHER.WORKING_MODE_OLD.ADD")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-8 py-2 contract-dialog-content">
            <Row>
              <Col xl={12} className="spaces mt-8">
                <RangeDatePicker
                  label={lang("GENERAL.TIME")}
                  startDateName="tuNgay"
                  endDateName="denNgay"
                  isView={!isEdit}
                  isRequired
                  handleChange={formik.handleChange}
                  value={formik.values}
                  setFieldValue={formik.setFieldValue}
                  touch={formik.touched}
                  errors={formik.errors}
                />
              </Col>
              <Col xl={12} className="spaces mt-8">
                <TextArea
                  isRequired
                  rows={5}
                  name="thongTin"
                  lable={lang("PROFILE.OTHER.MORE_INFOMATION")}
                  as={TYPE.TEXTAREA}
                  readOnly={!isEdit}
                  value={formik.values?.thongTin}
                  onChange={handleChange}
                  errors={formik?.errors?.thongTin}
                  touched={formik?.touched?.thongTin}
                  bulletPoints="+"
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="flex-center">
            <GroupButton type="btn-cancel" handleCloseUpdateDialog={handleClose} />
            {isEdit ? (
              <GroupButton type="btn-save" />
            ) : (hasAuthority(PERMISSIONS.EMPLOYEE, PERMISSION_ABILITY.UPDATE) &&
              <GroupButton type="btn-edit" handleSaveEdit={() => setIsEdit(true)} />
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

import { useContext, useEffect, useState } from "react";
import TableCustom, { columnNamesType } from "../../../components/table-custom/TableCustom";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import InputSearch from "../../../components/input/InputSearch";
import { REMIND_TYPE_CODES } from "../../constants/remindMenuConsts";
import { IObject } from "../../../models/models";
import AppContext from "../../../../AppContext";
import { INIT_SEARCH_OBJECT, RESPONSE_STATUS_CODE } from "../../../constants/moduleConsts";
import { searchRemindApi } from "../../services/remindServices";
import { toast } from "react-toastify";
import { IRemindData } from "../../models/remindModels";
import EmployeeCell from "../partials/EmployeeCell";

interface Iprops {
  isDialogMode?: boolean;
  unitId?: string;
  resfeshPage?: boolean;
}

export function RemindEmployeeNoContractTable(props: Iprops) {
  const { lang } = useMultiLanguage();
  const { isDialogMode, unitId, resfeshPage } = props;
  const { setPageLoading } = useContext(AppContext);

  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [listData, setListData] = useState<IRemindData[]>([]);
  const [searchObject, setSearchObject] = useState<IObject>({
    ...INIT_SEARCH_OBJECT,
    pageSize: 5,
    type: REMIND_TYPE_CODES.NOT_YET_CONTRACT
  });

  useEffect(() => {
    updatePageData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchObject?.pageIndex, searchObject?.pageSize, resfeshPage]);

  const updatePageData = async () => {
    try {
      setPageLoading(true);
      const { data } = await searchRemindApi({ ...searchObject, orgId: unitId || null });
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListData(data?.data?.content || []);
        setTotalPage(data?.data?.pageable?.totalPages);
        setTotalElements(data?.data?.pageable?.totalElements);
        setNumberOfElements(data?.data?.pageable?.numberOfElements);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const columns: columnNamesType[] = [
    {
      name: "STT",
      field: "",
      render: (_, index, stt) => <span>{stt}</span>
    },
    {
      name: lang("PROFILE.NAME"),
      field: "custom",
      render: (row: IRemindData) => <EmployeeCell name={row?.tenNhanVien} avatar={row?.avatar} code={row?.maNhanVien} />
    },
    {
      name: lang("INPUT.DEPARTMENTS"),
      field: "phongBan",
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("GENERAL.EMPLOYEE.JOB_POSITION"),
      field: "viTriCongViec",
      cellStyle: {
        textAlign: "left"
      }
    }
  ];

  return (
    <div className={isDialogMode ? "" : "remind-item"}>
      {!isDialogMode && <h3 className="spaces fs-18 fw-600 mb-16">{lang("REMIND.EMPLOYEE_NO_CONTARCT")}</h3>}
      <div className="spaces mb-10 flex flex-between">
        <InputSearch
          className="border-3px remind-item__search"
          name="keyword"
          value={searchObject?.keyword || ""}
          placeholder={lang("GENERAL.ENTER_HERE")}
          isEnter={true}
          handleSearch={() => updatePageData()}
          handleChange={(event) => setSearchObject({ ...searchObject, keyword: event.target.value })}
        />
        {/* Tạm ẩn do chưa có mẫu excel */}
        {/* <GroupButton outline>
          <KTSVG path="/media/icons/export-excel.svg" /> {lang("BTN.EXPORT")}
        </GroupButton> */}
      </div>
      <div className="table-custom remind-table">
        <TableCustom
          id="no-contract-list"
          data={listData}
          columns={columns}
          searchObject={searchObject}
          setSearchObject={setSearchObject}
          buttonAdd={false}
          notDelete={true}
          totalPages={totalPage}
          totalElements={totalElements}
          numberOfElements={numberOfElements}
          noToolbar
          clearToolbar
        />
      </div>
    </div>
  );
}

import { paramsConfig } from "../../utils/paramsUtils";
import axios from "axios";
import { IOfficeWorkSchedule } from "../models/officeWorkScheduleModels";

const API_URL_LICH_HANH_CHINH = process.env.REACT_APP_API_URL_CHAM_CONG + "/work-schedule";

export const searchOfficeWorkSchedule = (searchData: any) => {
  let url = `${API_URL_LICH_HANH_CHINH}/page`;
  return axios.get(url, paramsConfig(searchData))
};

export const getOfficeWorkScheduleById = (id: string) => {
  return axios.get(`${API_URL_LICH_HANH_CHINH}/${id}`);
};

export const addOfficeWorkSchedule = (officeWorkInfo: IOfficeWorkSchedule) => {
  let url = `${API_URL_LICH_HANH_CHINH}`;
  return axios.post(url, officeWorkInfo);
};

export const updateOfficeWorkSchedule = (id: string, officeWorkInfo: IOfficeWorkSchedule) => {
  let url = `${API_URL_LICH_HANH_CHINH}/${id}`;
  return axios.put(url, officeWorkInfo);
};

export const deleteOfficeWorkSchedule = (ids: string[]) => {
  let url = `${API_URL_LICH_HANH_CHINH}/ids?ids=${ids.toString()}`;
  return axios.delete(url);
};
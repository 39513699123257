import { forwardRef } from 'react';
import { WithChildren } from '../../../../_metronic/helpers';
import "./resizable.scss";

interface IProps extends WithChildren {
  isHorizontal?: boolean;
  initialStyle?: React.CSSProperties;
  className?: string
}

function ResizablePanel({ children, isHorizontal, initialStyle, className = "" }: IProps, ref: React.ForwardedRef<HTMLDivElement>) {
  return (
    <div className={`${className}`} ref={ref} style={{
      ...initialStyle,
      minWidth: isHorizontal ? initialStyle?.flexBasis: 0,
      minHeight: !isHorizontal ? initialStyle?.flexBasis: 0
    }}>
      {children}
    </div>
  );
}

export default forwardRef<HTMLDivElement, IProps>(ResizablePanel);

import { useFormik } from "formik";
import { FC, useEffect, useMemo } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import * as Yup from "yup";
import { IAuth } from "../../models/managerModels";
import { toast } from "react-toastify";
import { addNewAuth, updateAuth } from "../../services/managerServices";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import TextValidator from "../../../components/input/text-validator";
import { RESPONSE_STATUS_CODE } from "../../../constants/moduleConsts";
import { hasAuthority } from "../../../utils/functionUtils";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../../../constants";
import { GroupButton } from "../../../components/GroupButton";
import { INIT_AUTH } from "../../constants/managerConsts";

interface IProps {
  handleCloseDialog: () => void;
  authInfo: IAuth;
  handleCloseAndSearchDialogAuth: () => void;
}

const AuthDialog: FC<IProps> = (props) => {
  const { handleCloseDialog, authInfo, handleCloseAndSearchDialogAuth } = props;
  const { lang } = useMultiLanguage();

  const isUpdate = useMemo(() => hasAuthority(PERMISSIONS.AUTHORITY, PERMISSION_ABILITY.UPDATE), []);

  useEffect(() => {
    if(authInfo?.id) {
      formik.setValues(authInfo);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authInfo])

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    code: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable()
  });

  const handleSubmit = async (values: IAuth) => {
    try {
      const res = authInfo.id ? await updateAuth(authInfo.id, values) : await addNewAuth(values);
      if (res?.data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        let message = authInfo.id ? "TOAST.EDIT.SUCCESS" : "TOAST.CREATE.SUCCESS";
        toast.success(lang(message));
        handleCloseAndSearchDialogAuth();
      } else {
        toast.warning(`${res?.data?.message}`);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const formik = useFormik({
    initialValues: INIT_AUTH,
    onSubmit: handleSubmit,
    validationSchema
  });

  return (
    <Modal
      show={true}
      onHide={handleCloseDialog}
      backdrop="static"
      size="lg"
      centered
    >
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>{lang(!authInfo?.id ? "AUTH.ADD" : "AUTH.EDIT")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="px-8">
            <Col xs={6}>
              <TextValidator
                isRequired
                name="name"
                lable={lang("AUTH.NAME")}
                type="text"
                isReadOnly={!isUpdate}
                value={formik.values?.name || ""}
                onChange={formik.handleChange}
                errors={formik.errors?.name}
                touched={formik.touched?.name}
              />
            </Col>
            <Col xs={6}>
              <TextValidator
                isRequired
                name="code"
                lable={lang("AUTH.CODE")}
                type="text"
                isReadOnly={!isUpdate}
                value={formik.values?.code || ""}
                onChange={formik.handleChange}
                errors={formik.errors?.code}
                touched={formik.touched?.code}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <GroupButton
            type="btn-cancel"
            handleCloseUpdateDialog={handleCloseDialog}
          />
          {isUpdate && <GroupButton type="btn-save" />}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
export { AuthDialog };

import { useEffect, useState } from "react";
import useMultiLanguage from "../../../../../hook/useMultiLanguage";
import TableCustom, { columnNamesType } from "../../../../components/table-custom/TableCustom";
import { RESPONSE_STATUS_CODE } from "../../../../constants/moduleConsts";
import { toast } from "react-toastify";
import { disciplineStatus } from "../../../../discipline/constants/disciplineConsts";
import { checkStatus, formatDateTable } from "../../../../utils/functionUtils";
import DisciplineViewDialog from "./DisciplineViewDialog";
import { getDiscipline } from "../../../../discipline/services/disciplineServices";

interface Iprops {
  isCallApi: boolean;
  employeeProfiles: any;
}

function DisciplineTable(props: Iprops) {
  const { lang } = useMultiLanguage();
  const { isCallApi, employeeProfiles } = props;

  const [disciplineList, setDisciplineList] = useState<any[]>([]);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [disciplineItem, setDisciplineItem] = useState({});
  const [shouldOpenViewDialog, setShouldBonusViewDialog] = useState<boolean>(false);

  useEffect(() => {
    updatePageData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCallApi])

  const updatePageData = async (searchObj?: any) => {
    if (!isCallApi) return;
    try {
      const searchData = {
        ...searchObj,
        employeeId: employeeProfiles?.id
      };
      const { data } = await getDiscipline(searchData);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setDisciplineList(data?.data?.content || []);
        setTotalPages(data?.data?.pageable?.totalPages || 0);
        setTotalElements(data?.data?.pageable?.totalElements || 0);
        setNumberOfElements(data?.data?.pageable?.numberOfElements || 0);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const handleExportExcel = () => {};

  const handleOpenDialog = (row: any) => {
    setShouldBonusViewDialog(true);
    setDisciplineItem(row);
  };

  const handleCloseDialog = () => {
    setShouldBonusViewDialog(false);
  };

  const columns: columnNamesType[] = [
    {
      name: lang("TABLE.INDEX"),
      field: "stt",
      headerStyle: {
        minWidth: "100px"
      },
      render: (row: any, index: number, STT: number) => <span>{STT}</span>
    },
    {
      name: lang("GENERAL.STATUS"),
      field: "trangThai",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row: any) => (
        <span className={`${checkStatus(disciplineStatus, row?.trangThai?.code)} status`}>
          {row?.trangThai?.name}
        </span>
      )
    },
    {
      name: lang("GENERAL.TIME"),
      field: "tuNgay-denNgay",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row: any) => (
        <span>{row?.tuNgay ? formatDateTable(row?.tuNgay) + "-" + formatDateTable(row?.denNgay) : ""}</span>
      )
    },
    {
      name: lang("DISCIPLINE.TYPE"),
      field: "loaiKyLuat",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row: any) => <span>{row?.loaiKyLuat?.name}</span>
    },
    {
      name: lang("APPRECIATIVE.DECISION_LEVEL"),
      field: "capQuyetDinh",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row: any) => <span>{row?.capQuyetDinh?.name}</span>
    },
    {
      name: lang("DISCIPLINE.VIOLATION_BEHAVIOR"),
      field: "hanhVi",
      headerStyle: {
        minWidth: "200px"
      }
    },
    {
      name: lang("DISCIPLINE.FORM_OF"),
      field: "hinhThucKyLuat",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row: any) => <span>{row?.hinhThucKyLuat?.name}</span>
    },
    {
      name: lang("APPRECIATIVE.PERSON_SINGING"),
      field: "nguoiKyQuyetDinhText",
      headerStyle: {
        minWidth: "200px"
      }
    },
    {
      name: lang("DISCIPLINE.ISSUING_AGENCY_UNIT"),
      field: "coQuanQuyetDinhText",
      headerStyle: {
        minWidth: "280px"
      }
    }
  ];

  return (
    <div className="spaces mt-16">
      <TableCustom
        title={lang("DISCIPLINE.TITLE.LIST")}
        isActionTableTab
        data={disciplineList}
        columns={columns}
        handleOpenDialog={handleOpenDialog}
        handleExportExcel={handleExportExcel}
        fixedColumnsCount={4}
        handleDoubleClick={handleOpenDialog}
        totalPages={totalPages}
        totalElements={totalElements}
        numberOfElements={numberOfElements}
        notDelete
        noPagination
      />
      {shouldOpenViewDialog && (
        <DisciplineViewDialog
          open={shouldOpenViewDialog}
          handleClose={handleCloseDialog}
          disciplineInfo={disciplineItem}
        />
      )}
    </div>
  );
}

export default DisciplineTable;

import axios, { AxiosResponse } from "axios";
import { APIResponse } from "../../models/models";
import { paramsConfig } from "../../utils/paramsUtils";
import { ISecondment } from "../models/secondmentModels";

const API_URL = process.env.REACT_APP_API_URL + "/biet-phai";

export const getListSecondment = (searchData?: any) => {
  const url = API_URL + "/page";
  return axios.get(url, paramsConfig(searchData));
};

export const addSecondment = (secondment: ISecondment): Promise<AxiosResponse<APIResponse>> => {
  return axios.post(API_URL, secondment);
};

export const updateSecondment = (
  id: string,
  secondment: ISecondment
): Promise<AxiosResponse<APIResponse>> => {
  const url = `${API_URL}/${id}`;
  return axios.put(url, secondment);
};

export const deleteSecondment = (ids: string[]): Promise<AxiosResponse<APIResponse>> => {
  const url = `${API_URL}/ids?ids=${ids.toString()}`;
  return axios.delete(url);
};

import { TransitionInFo } from "../models/transitionModels";

export const converDataUI = (data: TransitionInFo) => {
  let dataConvert = {
    ...data,
    donViCongTacMoi: data?.donViCongTacMoiId
      ? {
        id: data?.donViCongTacMoiId,
        name: data?.donViCongTacMoiText
      }
      : null,
    chucVuMoi: data?.chucVuMoiId
      ? {
        id: data?.chucVuMoiId,
        value: data?.chucVuMoiText
      }
      : null,
    viTriCongTacMoi: data?.viTriCongTacMoiId
      ? {
        id: data?.viTriCongTacMoiId,
        value: data?.viTriCongTacMoiText
      }
      : null,
    khoaPhongMoi: data?.khoaPhongMoiId
      ? {
        id: data?.khoaPhongMoiId,
        name: data?.khoaPhongMoiText
      }
      : null,
    employee: data?.employeeId
      ? {
        id: data?.employeeId,
        name: data?.fullName,
        maNhanVien: data?.code
      }
      : null,
    nguoiKy: data?.nguoiKyId
      ? {
        id: data?.nguoiKyId,
        name: data?.nguoiKyText
      }
      : null,
    donViQuyetDinh: data?.donViQuyetDinhId
      ? {
        id: data?.donViQuyetDinhId,
        name: data?.donViQuyetDinhText
      }
      : null
  };

  return dataConvert;
};

export const convertDataSubmit = (data: any) => {
  let dataConvert = {
    ...data,
    donViCongTacMoiId: data?.donViCongTacMoi?.id || "",
    donViCongTacMoiText: data?.donViCongTacMoi?.name || "",
    chucVuMoiId: data?.chucVuMoi?.id || "",
    chucVuMoiText: data?.chucVuMoi?.value || "",
    viTriCongTacMoiId: data?.viTriCongTacMoi?.id || "",
    viTriCongTacMoiText: data?.viTriCongTacMoi?.value || "",
    khoaPhongMoiId: data?.khoaPhongMoi?.id || "",
    khoaPhongMoiText: data?.khoaPhongMoi?.name || "",
    nguoiKyId: data?.nguoiKy?.id || "",
    nguoiKyText: data?.nguoiKy?.name || "",
    donViQuyetDinhId: data?.donViQuyetDinh?.id || "",
    donViQuyetDinhText: data?.donViQuyetDinh?.name || "",
    loaiDieuDongId: data?.loaiDieuDong?.id || "",
    loaiDieuDongText: data?.loaiDieuDong?.value || "",
  };

  return dataConvert;
};
import { useFormik } from 'formik';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import * as Yup from "yup";
import useMultiLanguage from '../../../../hook/useMultiLanguage';
import AppContext from '../../../../AppContext';
import { toast } from 'react-toastify';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import TextValidator from '../../../components/input/text-validator';
import { MAPPING_NAME_TYPE_DUTY } from '../../constants/shiftDivisionConsts';
import { ValueOrDots } from '../../../components/exportWord/partials';
import { GroupButton } from '../../../components/GroupButton';
import "../../shift.scss";
import { compareConditions } from '../../utils/UpdateWorkShiftsUtils';
import { IConditionShift, IWorkDay } from '../../models/shiftDivisionModels';
import { INIT_WORK_DAY } from '../../constants/updateWorkShiftsConsts';
import { useDebounce } from '../../../../../_metronic/helpers';
import { updateWorkShifts } from '../../services/updateWorkShiftsServices';
import { RESPONSE_STATUS_CODE } from '../../../constants/moduleConsts';

function UpdateWorkShiftsForm(props: any) {
	const {
		handleCloseAndSearch,
		handleClose,
		item,
		open
	} = props
	const { lang } = useMultiLanguage();
	const { setPageLoading } = useContext(AppContext);
	const [condition, setCondition] = useState<IConditionShift>({} as IConditionShift);

	const validationSchema = Yup.object().shape({
		soLuongBenhNhan: Yup.number()
			.max(100, "Không được nhập quá 100")
			.required(lang("VALIDATION.REQUIRE")).nullable()
	});

	const handleSubmit = async (values: IWorkDay) => {
		try {
			setPageLoading(true);
			const _values = { 
				...values,
				employeeInfoList: values?.employeeInfoList?.flat()
			};
			const { data } = await updateWorkShifts(_values?.id ?? "", _values);
			if(data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
				toast.success(lang("TOAST.EDIT.SUCCESS"));
				handleCloseAndSearch();
			} else toast.warning(data?.message);
		} catch (err) {
			toast.error(lang("GENERAL.ERROR"));
		} finally {
			setPageLoading(false)
		}
	};

	useEffect(() => {
		item?.bangPhanCaId && formik.setValues(convertDataUI(item));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [item]);

	const convertDataUI = (data: any) => {
		let _data = { ...data };
		let employeeInfoList = _data?.employeeInfoList?.reduce((acc: any, item: any) => {
			if (!acc[item?.loaiTrucId]) {
				acc[item?.loaiTrucId] = [];
			}
			acc[item.loaiTrucId].push(item);
			return acc;
		}, {});

		_data.employeeInfoList = Object.values(employeeInfoList) || [];
		return _data;
	}

	const formik = useFormik({
		initialValues: INIT_WORK_DAY,
		validationSchema,
		onSubmit: handleSubmit
	});
	
	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		let { name, value } = event.target;
		formik.setFieldValue(name, value);
	};

  const soBN = useDebounce(formik.values?.soLuongBenhNhan ?? "", 200);

	useEffect(() => {
		if (formik.values?.soLuongBenhNhan) {
			const phanCaList: IConditionShift = formik.values.configPhanCaList?.find(element => {
        const isDieuKienDuoi = element?.dieuKienDuoi?.name && element?.soLuongBenhNhanDuoi
            ? compareConditions[element.dieuKienDuoi.name](element.soLuongBenhNhanDuoi, formik.values.soLuongBenhNhan)
            : true;

        const isDieuKienTren = element?.dieuKienTren?.name && element?.soLuongBenhNhanTren
            ? compareConditions[element.dieuKienTren.name](formik.values.soLuongBenhNhan, element.soLuongBenhNhanTren)
            : true;

        return isDieuKienDuoi && isDieuKienTren;
			}) || ({} as IConditionShift);

			setCondition(phanCaList);
		} else setCondition({} as IConditionShift);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [soBN]);

	const handleCheckbox = (event: ChangeEvent<HTMLInputElement>, index: number, indexNV: number) => {
		const employeeInfoList: any = [...formik.values.employeeInfoList];
		const countEmployeeOnCall = employeeInfoList[index].filter((item: any) => item.duocTinhCong).length;
		const quantityOnCondition = condition?.soLuongTrucDtos?.find((element: any) => 
				element.loaiTrucId === employeeInfoList[index][indexNV].loaiTrucId
			)?.soLuong;
			
		if(event.target.checked && quantityOnCondition && countEmployeeOnCall === quantityOnCondition) {
			toast.warning("Số lượng nhân viên trực đã đạt tối đa");
			return;
		}
		employeeInfoList[index][indexNV].duocTinhCong = event.target.checked;
		formik.setFieldValue("employeeInfoList", employeeInfoList);
	}

	return (
		<Modal
			show={open}
			onHide={handleClose}
			backdrop="static"
			centered
			animation
			size='lg'
			aria-labelledby="example-custom-modal-styling-title"
			className="custom-modal confirm-dialog"
		>
			<Form onSubmit={formik.handleSubmit} className='spaces fs-14'>
				<Modal.Header className="bg-primary p-4" closeButton>
					<Modal.Title className="text-white text-uppercase">Cập nhật số lượng nhân viên đi làm theo bệnh nhân</Modal.Title>
				</Modal.Header>
				<Modal.Body className="px-4 py-3 update-shift-form">
					<Row>
						<Col xs={12} className='pt-4'>
							<TextValidator
								isRequired
								name="soLuongBenhNhan"
								lable={lang("TIMEKEEPING.NUMBER.PATIENT")}
								type="number"
								value={formik.values?.soLuongBenhNhan ?? null}
								onChange={handleChange}
								errors={formik.errors?.soLuongBenhNhan}
								touched={formik.touched?.soLuongBenhNhan}
							/>
						</Col>
						<Col xs={12} className='pt-4'>
							<div>
								<span className='fw-bold'>Điều kiện: </span>
								{
									condition?.soLuongBenhNhanDuoi && condition?.dieuKienDuoi
										? <>
											<span>{condition?.soLuongBenhNhanDuoi} </span>
											<span>{condition?.dieuKienDuoi?.name} </span>
										</>
										: <ValueOrDots size={20} />
								}
								<span> Bệnh nhân </span>
								{
									condition?.soLuongBenhNhanTren && condition?.dieuKienTren
										? <>
											<span>{condition?.soLuongBenhNhanTren} </span>
											<span>{condition?.dieuKienTren?.name} </span>
										</>
										: <ValueOrDots size={20} />
								}
								<span> chấm <ValueOrDots value={condition.soLuongTrucDtos?.find((item: any) => item?.mappingName === MAPPING_NAME_TYPE_DUTY.TRUC_CHINH)?.soLuong ?? ""} size={15} /></span>
								<span> trực chính </span>
								<span><ValueOrDots value={condition.soLuongTrucDtos?.find((item: any) => item?.mappingName === MAPPING_NAME_TYPE_DUTY.THUONG_TRU)?.soLuong ?? ""} size={15} /></span>
								<span> thường trú </span>
							</div>
						</Col>

						{
							formik.values?.employeeInfoList?.length > 0 && formik.values?.employeeInfoList?.map((item: any, index: number) => (
								<Col xs={4} className='pt-4'>
									<p className='pt-2 m-0 fw-bold'>{item[0]?.loaiTrucText}</p>
									{item?.length > 0 && item?.map((itemNV: any, indexNV: number) => {
										return (
											<div className='d-flex flex-middle py-2' key={indexNV}>
												<Form.Check
													className="checkBox check-box-autoComplete"
													id={itemNV?.employeeText}
													name={itemNV?.employeeText}
													label={itemNV?.employeeText}
													checked={itemNV?.duocTinhCong || false}
													onChange={(event: ChangeEvent<HTMLInputElement>) => handleCheckbox(event, index, indexNV)}
												/>
											</div>
										)
									})}
								</Col>
							))
						}
					</Row>
				</Modal.Body>
				<Modal.Footer className="flex-center py-4">
					<GroupButton type="btn-save" />
					<GroupButton type="btn-cancel" handleCloseUpdateDialog={handleClose} />
				</Modal.Footer>
			</Form>
		</Modal>
	)
}

export default UpdateWorkShiftsForm
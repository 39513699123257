import { Navigate, Route, Routes } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import { PERMISSIONS, PERMISSION_ABILITY } from "../constants";
import { AppointmentList } from "../modules/appointment-list/AppointmentList";
import Bonus from "../modules/bonus/Bonus";
import { Contract } from "../modules/contract/Contract";
import Discipline from "../modules/discipline/Discipline";
import { DismissedList } from "../modules/dismissed-list/DismissedList";
import { Manager } from "../modules/manager/Manager";
import { Profile } from "../modules/profile/Profile";
import { Resign } from "../modules/resign/Resign";
import Statistical from "../modules/statistical/Statistical";
import Transition from "../modules/transition/Transition";
import { hasAuthority } from "../modules/utils/functionUtils";
import Warning from "../modules/warning/Warning";
import { WelfareList } from "../modules/welfare-list/WelfareList";
import { HomePage } from "../pages/homepage/HomePage";
import Report from "../modules/report/Report";
import { Concurrently } from "../modules/concurrently/Concurrently";
import Secondment from "../modules/secondment/Secondment";
import DeclareAssets from "../modules/declare-assets/DeclareAssets";
import ReceivingCitizens from "../modules/receiving-citizens/ReceivingCitizens";
import Remind from "../modules/remind/Remind";
import SendPeopleTraining from "../modules/send-people-training/SendPeopleTraining";
import TimekeepingOverview from "../modules/timekeeping-overview/TimekeepingOverview";
import Timesheets from "../modules/timesheet/Timesheet";
import { AttendanceWatch, ChangeCompensated, RegisterOvertime } from "../modules/manager-request";
import { ApprovalConfig, LeaveRegulations, SymbolTimekeeping } from "../modules/category";
import { ComprehensiveShiftScheduling, OfficeWorkSchedule, Shift, ShiftDivision, UpdateWorkShifts } from "../modules/shift";
import { LIST_OBJECT_SCHEDULE, TYPE_OBJECT_SCHEDULE } from "../modules/shift/constants/shiftComprehensionConsts";

interface PrivateRouteProps {
  auth: string;
  ability: string;
  component: React.ComponentType<any>;
  redirect: string;
}

const PrivateRoutes = () => {

  const PrivateRoute: React.FC<PrivateRouteProps> = ({ auth, ability, component: Component, redirect, }) => {
    return hasAuthority(auth, ability) ? (<Component />) : (<Navigate to={redirect} />);
  };

  return (
    <Routes>
      <Route index element={<Navigate to="/home" />} />
      <Route path="/*" element={<HomePage />} />
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to="/home" />} />
        {/* Pages */}
        <Route path="/personal-information/statistical" element={<PrivateRoute auth={PERMISSIONS.THONG_KE} ability={PERMISSION_ABILITY.VIEW} component={Statistical} redirect="/home" />} />
        <Route path="/personal-information/remind" element={<PrivateRoute auth={PERMISSIONS.REMIND} ability={PERMISSION_ABILITY.VIEW} component={Remind} redirect="/home" />} />
        <Route path="/personal-information/warning" element={<PrivateRoute auth={PERMISSIONS.CANH_BAO} ability={PERMISSION_ABILITY.VIEW} component={Warning} redirect="/home" />} />
        <Route path="/personal-information/profile" element={<PrivateRoute auth={PERMISSIONS.EMPLOYEE} ability={PERMISSION_ABILITY.VIEW} component={Profile} redirect="/home" />} />
        <Route path="/personal-information/contract" element={<PrivateRoute auth={PERMISSIONS.HOP_DONG} ability={PERMISSION_ABILITY.VIEW} component={Contract} redirect="/home" />} />
        <Route path="/personal-information/declare-assets" element={<PrivateRoute auth={PERMISSIONS.KE_KHAI_TAI_SAN_MENU} ability={PERMISSION_ABILITY.VIEW} component={DeclareAssets} redirect="/home" />} />
        <Route path="/personal-information/resign" element={<PrivateRoute auth={PERMISSIONS.NGHI_VIEC} ability={PERMISSION_ABILITY.VIEW} component={Resign} redirect="/home" />} />
        <Route path="/personal-information/transition" element={<PrivateRoute auth={PERMISSIONS.THUYEN_CHUYEN} ability={PERMISSION_ABILITY.VIEW} component={Transition} redirect="/home" />} />
        <Route path="/personal-information/appreciative" element={<PrivateRoute auth={PERMISSIONS.KHEN_THUONG_MENU} ability={PERMISSION_ABILITY.VIEW} component={Bonus} redirect="/home" />} />
        <Route path="/personal-information/discipline" element={<PrivateRoute auth={PERMISSIONS.KY_LUAT_MENU} ability={PERMISSION_ABILITY.VIEW} component={Discipline} redirect="/home" />} />
        <Route path="/personal-information/appointment" element={<PrivateRoute auth={PERMISSIONS.BO_NHIEM} ability={PERMISSION_ABILITY.VIEW} component={AppointmentList} redirect="/home" />} />
        <Route path="/personal-information/dismissed" element={<PrivateRoute auth={PERMISSIONS.MIEN_NHIEM} ability={PERMISSION_ABILITY.VIEW} component={DismissedList} redirect="/home" />} />
        <Route path="/personal-information/welfare" element={<PrivateRoute auth={PERMISSIONS.PHUC_LOI_MENU} ability={PERMISSION_ABILITY.VIEW} component={WelfareList} redirect="/home" />} />
        <Route path="/personal-information/concurrently" element={<PrivateRoute auth={PERMISSIONS.KIEM_NHIEM} ability={PERMISSION_ABILITY.VIEW} component={Concurrently} redirect="/home" />} />
        <Route path="/personal-information/secondment" element={<PrivateRoute auth={PERMISSIONS.BIET_PHAI} ability={PERMISSION_ABILITY.VIEW} component={Secondment} redirect="/home" />} />
        <Route path="/personal-information/manager" element={<PrivateRoute auth={PERMISSIONS.MANAGEMENT} ability={PERMISSION_ABILITY.VIEW} component={Manager} redirect="/home" />} />
        <Route path="/personal-information/report" element={<PrivateRoute auth={PERMISSIONS.BAO_CAO} ability={PERMISSION_ABILITY.VIEW} component={Report} redirect="/home" />} />
        <Route path="/personal-information/receiving-citizens" element={<PrivateRoute auth={PERMISSIONS.TIEP_CONG_DAN} ability={PERMISSION_ABILITY.VIEW} component={ReceivingCitizens} redirect="/home" />} />
        <Route path="/personal-information/send-people-training" element={<PrivateRoute auth={PERMISSIONS.CU_DAO_TAO} ability={PERMISSION_ABILITY.VIEW} component={SendPeopleTraining} redirect="/home" />} />
        {/* Chấm công */}
        <Route path="/timekeeping/overview" element={<PrivateRoute auth={PERMISSIONS.CHAM_CONG_TONG_QUAN} ability={PERMISSION_ABILITY.VIEW} component={TimekeepingOverview} redirect="/home" />} />
        <Route path="/timekeeping/shift" element={<PrivateRoute auth={PERMISSIONS.CA_LAM_VIEC} ability={PERMISSION_ABILITY.VIEW} component={Shift} redirect="/home" />} />
        <Route path="/timekeeping/shift-division-doctor" element={<PrivateRoute auth={PERMISSIONS.PHAN_CA_BAC_SI} ability={PERMISSION_ABILITY.VIEW} component={() => <ShiftDivision typeObject={LIST_OBJECT_SCHEDULE.find(obj => obj.code === TYPE_OBJECT_SCHEDULE.BAC_SI)} />} redirect="/home" />} />
        <Route path="/timekeeping/shift-division-nursing" element={<PrivateRoute auth={PERMISSIONS.PHAN_CA_DIEU_DUONG} ability={PERMISSION_ABILITY.VIEW} component={() => <ShiftDivision typeObject={LIST_OBJECT_SCHEDULE.find(obj => obj.code === TYPE_OBJECT_SCHEDULE.DIEU_DUONG)} />} redirect="/home" />} />
        <Route path="/timekeeping/work-shifts" element={<PrivateRoute auth={PERMISSIONS.GIAO_CA} ability={PERMISSION_ABILITY.VIEW} component={UpdateWorkShifts} redirect="/home" />} />
        <Route path="/timekeeping/comprehensive-shift" element={<PrivateRoute auth={PERMISSIONS.PHAN_CA_TONG_HOP} ability={PERMISSION_ABILITY.VIEW} component={ComprehensiveShiftScheduling} redirect="/home" />} />
        <Route path="/timekeeping/office-work-schedule" element={<PrivateRoute auth={PERMISSIONS.LICH_HANH_CHINH} ability={PERMISSION_ABILITY.VIEW} component={OfficeWorkSchedule} redirect="/home" />} />
        <Route path="/timekeeping/management-request/attendance-watch" element={<PrivateRoute auth={PERMISSIONS.DON_XIN_NGHI} ability={PERMISSION_ABILITY.VIEW} component={AttendanceWatch} redirect="/home" />} />
        <Route path="/timekeeping/management-request/register-overtime" element={<PrivateRoute auth={PERMISSIONS.DON_DANG_KI_LAM_THEM} ability={PERMISSION_ABILITY.VIEW} component={RegisterOvertime} redirect="/home" />} />
        <Route path="/timekeeping/management-request/change-compensated" element={<PrivateRoute auth={PERMISSIONS.DON_DOI_CA_NGHI_BU} ability={PERMISSION_ABILITY.VIEW} component={ChangeCompensated} redirect="/home" />} />
        <Route path="/timekeeping/timesheet" element={<PrivateRoute auth={PERMISSIONS.BANG_CHAM_CONG} ability={PERMISSION_ABILITY.VIEW} component={Timesheets} redirect="/home" />} />
        <Route path="/timekeeping/category/symbol" element={<PrivateRoute auth={PERMISSIONS["DANH_MUC.CHAM_CONG.KY_HIEU"]} ability={PERMISSION_ABILITY.VIEW} component={SymbolTimekeeping} redirect="/home" />} />
        <Route path="/timekeeping/category/leave-regulations" element={<PrivateRoute auth={PERMISSIONS["DANH_MUC.CHAM_CONG.QUY_DINH_NGHI"]} ability={PERMISSION_ABILITY.VIEW} component={LeaveRegulations} redirect="/home" />} />
        <Route path="/timekeeping/category/approval-config" element={<PrivateRoute auth={PERMISSIONS["DANH_MUC.CHAM_CONG.CAU_HINH_DUYET"]} ability={PERMISSION_ABILITY.VIEW} component={ApprovalConfig} redirect="/home" />} />
       
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

export { PrivateRoutes };


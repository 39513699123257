import TextUnderline from "../../../components/TextUnderline";
import { IPropsExportWord } from "../../../components/exportWord";
import { ValueOrDots } from "../../../components/exportWord/partials";

export function DecisionEndContractWord({ data }: IPropsExportWord) {
  return (
    <div className="word spaces px-70 colors-word">
      <div className="spaces text-center flex flex-between align-start">
        <div className="fs-lg">
          <p>{data?.soYTeHeader ?? ""}</p>
          <p className="fw-600 text-uppercase">
            <span className="header-underline fs-lg">
              <ValueOrDots className="fs-lg" value={data.donViCongTacText} size={70} />
            </span>
          </p>
          <p className="spaces mt-16 fs-md">
            Số: <ValueOrDots value={data.soQuyetDinh} />
            /QĐ-BV
          </p>
        </div>
        <div className="fw-600 fs-lg">
          <p>CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM</p>
          <p>
            <span className="header-underline fs-lg">Độc lập - Tự do - Hạnh phúc</span>
          </p>
          <p className="spaces mt-16 fs-md text-italic fw-500">
            <ValueOrDots value="" size={20} />, {data.ngayQuyetDinhText}</p>
        </div>
      </div>
      <div className="fw-600 text-center fs-lg spaces py-16">
        <p>QUYẾT ĐỊNH</p>
        <p>
          <span className="header-underline fs-lg">Về việc chấm dứt hợp đồng lao động</span>
        </p>
      </div>
      <div className="fw-600 text-center fs-lg spaces my-16">
        <p className="text-uppercase">
          GIÁM ĐỐC <ValueOrDots className="fs-lg" value={data.donViCongTacText} size={70} />
        </p>
      </div>
      <div className="text-italic text-indent-40 text-justify">
        <p>
          Căn cứ Bộ luật lao động số 45/2019/QH14 ngày 20/11/2019; Nghị định số 145/2020/NĐ-CP ngày 14/12/2020 của Chính
          phủ quy định chi tiết và hướng dẫn thi hành một số điều của Bộ Luật lao động về điều kiện lao động và quan hệ
          lao động;
        </p>
        <p>{data?.quyetDinhHopDong ?? ""}</p>
        <p>
          Căn cứ Trích biên bản của Hội đồng tuyển dụng họp ngày <ValueOrDots value="" />
          về việc xem xét Đơn xin chấm dứt hợp đồng lao động;
        </p>
        <p>
          Theo đề nghị của Trưởng phòng TCHC <ValueOrDots value={data.donViCongTacText} size={70} /> tại Tở trình ngày{" "}
          <ValueOrDots value={data.ngayKyToTrinh} /> về việc xem xét giải quyết chấm dứt Hợp đồng lao động.
        </p>
      </div>
      <div className="fw-600 text-center fs-lg">
        <p>QUYẾT ĐỊNH:</p>
      </div>
      <div className="text-indent-40">
        <p>
          <span className="fw-600">Điều 1.</span> Chấm dứt hợp đồng lao động đối với ông/bà{" "}
          <ValueOrDots value={data.fullName} />, sinh ngày
          <ValueOrDots value={data.birthdate} />, chức danh: <ValueOrDots value={data.chucDanhText} size={70} />{" "}
          công tác tại <ValueOrDots value={data.phongBanText} size={70} />,{" "}
          <ValueOrDots value={data.donViCongTacText} size={80} /> kể từ ngày
          <ValueOrDots value={data.ngayChinhThuc} />.
        </p>
        <p className="spaces pl-40">
          <TextUnderline readOnly label="Lý do" value={data.lyDoThoiViec} />
        </p>
        <p>
          <span className="fw-600">Điều 2.</span> Ông/Bà <ValueOrDots value={data.fullName} /> có trách nhiệm bàn giao
          công việc, tài sản của đơn vị giao cho với lãnh đạo <ValueOrDots value={data.phongBanText} size={70} />,{" "}
          <ValueOrDots value={data.donViCongTacText} size={80} /> trước khi chấm dứt hợp đồng lao động; được hưởng tiền
          công và các khoản phụ cấp (nếu có) theo quy định hiện hành và quy định của đơn vị.
        </p>
        <p>
          <span className="fw-600">Điều 3.</span> Các ông, bà:{" "}
          <ValueOrDots value={data.khoaPhongNhanQuyetDinh} size={160} />; các khoa, phòng có liên quan và{" "}
          <span className="fw-600">
            ông/bà <ValueOrDots value={data.fullName} />
          </span>{" "}
          chịu trách nhiệm thi hành Quyết định này kể từ ngày ký.
        </p>
      </div>
      <div className="spaces pt-16 pb-70 flex flex-between align-start">
        <div>
          <p className="text-italic fw-600">Nơi nhận:</p>
          <p>- Như Điều 3;</p>
          <p>- Lưu: TCHC, VT</p>
        </div>
        <div className="text-center fw-600 spaces pr-20">
          <p>GIÁM ĐỐC</p>
          <p className="spaces mt-70">
            <ValueOrDots value={data.nguoiDaiDienCtyKyText} size={60} />
          </p>
        </div>
      </div>
    </div>
  );
}

import { FC, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { IAuth } from "../models/managerModels";
import { searchAllAuthority, deleteAuth } from "../services/managerServices";
import { toast } from "react-toastify";
import { AuthDialog } from "./dialogs/AuthDialog";
import { hasAuthority } from "../../utils/functionUtils";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../../constants";
import TableCustom from "../../components/table-custom/TableCustom";
import { RESPONSE_STATUS_CODE, TYPE } from "../../constants/moduleConsts";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { INIT_SEARCH_OBJECT } from "../../constants/moduleConsts";
import { GroupButton } from "../../components/GroupButton";
import InputSearch from "../../components/input/InputSearch";

const AuthorityList: FC = () => {
  const { lang } = useMultiLanguage();

  const [authList, setAuthList] = useState<IAuth[]>([]);
  const [authInfo, setAuthInfo] = useState<IAuth>({} as IAuth);
  const [openAuthDialog, setOpenAuthDialog] = useState<boolean>(false);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalElements, setTotalElementsAuth] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [searchObject, setSearchObject] = useState<any>({ ...INIT_SEARCH_OBJECT, pageSize: 20 });

  const handleOpenAuthDialog = (data: IAuth) => {
    setOpenAuthDialog(true);
    setAuthInfo(data);
  };

  const handleCloseAuthDialog = () => {
    setOpenAuthDialog(false);
    setAuthInfo({} as IAuth);
  };

  const handleCloseAndSearchDialogAuth = () => {
    handleCloseAuthDialog();
    handleSearch(searchObject);
  }

  useEffect(() => {
    if(searchObject?.pageIndex && searchObject?.pageSize){
      handleSearch(searchObject);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchObject?.pageIndex, searchObject?.pageSize]);

  const loadListAuth = async (searchObject?: any) => {
    try {
      setSearchObject({ ...searchObject });
      const { data } = await searchAllAuthority(searchObject);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setAuthList(data?.data?.content);
        setTotalPage(data?.data?.pageable?.totalPages);
        setTotalElementsAuth(data?.data?.pageable?.totalElements);
        setNumberOfElements(data?.data?.pageable?.numberOfElements);
      } else {
        toast.warning(data?.message);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const handleDeleteAuth = async (ids: string[]) => {
    try {
      const { data } = await deleteAuth(ids);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("GENERAL.DELETE_SUCCESS"));
        await loadListAuth(searchObject);
        return true;
      } else toast.warning(data?.message);
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }

  const handleSearch = (data: any = {}) => {
    data.keyword = data?.keyword?.trim() || "";
    loadListAuth(data);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchObject((prev: any) => {
      return {
        ...prev,
        keyword: e.target?.value
      }
    });
  };

  const authColumn = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("AUTH.CODE"),
      field: "code",
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("AUTH.NAME"),
      field: "name",
      cellStyle: {
        textAlign: "left"
      }
    }
  ];

  return (
    <>
      <div className="spaces pt-10 role-auth">
        <span className="spaces fs-18 fw-600">{lang("AUTH.LIST")}</span>
        <Row className="flex-space-between">
          <Col xs={3} md={3} lg={2} className="mt-3">
            {hasAuthority(PERMISSIONS.AUTHORITY, PERMISSION_ABILITY.CREATE) &&
              <GroupButton handleEvent={() => setOpenAuthDialog(true)}>
                <i className="spaces bi bi-plus fs-20 white pr-0"></i>
                <p className="spaces fs-14 m-0 ">{lang("BTN.ADD")}</p>
              </GroupButton>
            }
          </Col>
          <Col xs={8} md={7} xl={5} xxl={4} className="flex mt-3">
            <div className="spaces mr-10 flex-1">
              <InputSearch
                className="border-3px"
                value={searchObject?.keyword}
                placeholder="Tìm kiếm theo mã phân quyền, tên phân quyền"
                isEnter={true}
                handleSearch={() => handleSearch({ ...searchObject, pageIndex: 1 })}
                handleChange={handleChange}
              />
            </div>
            <GroupButton handleEvent={() => handleSearch({ ...searchObject, pageIndex: 1 })}>
              {lang("BTN.SEARCH")}
            </GroupButton>
          </Col>
        </Row>
        <div className="table-custom">
          <TableCustom
            id="authList"
            data={authList}
            columns={authColumn}
            searchObject={searchObject}
            setSearchObject={setSearchObject}
            type={TYPE.MULTILINE}
            buttonAdd={false}
            notDelete={!hasAuthority(PERMISSIONS.AUTHORITY, PERMISSION_ABILITY.DELETE)}
            totalPages={totalPage}
            totalElements={totalElements}
            numberOfElements={numberOfElements}
            handleDoubleClick={handleOpenAuthDialog}
            handleDelete={(ids: any) => handleDeleteAuth(ids)}
            justFilter={false}
            noToolbar
          />
        </div>
      </div>
      {openAuthDialog && (
        <AuthDialog
          handleCloseDialog={handleCloseAuthDialog}
          authInfo={authInfo}
          handleCloseAndSearchDialogAuth={handleCloseAndSearchDialogAuth}
        />
      )}
    </>
  );
};
export { AuthorityList };

import { FC, useContext, useEffect, useState } from "react";
import { AddNewContract } from "./components/AddNewContract";
import "./contract.scss";
import { IContractInfoDto, IContractInfo } from "./models/contractModels";
import { deleteContract, getListContracts } from "./services/contractServices";
import { toast } from "react-toastify";
import { checkStatus, convertSearch, exportToFile, formatDateTable, hasAuthority, searchDataRoleUser } from "../utils/functionUtils";
import { exportHopDong } from "../services/exportFileServices";
import TableCustom from "../components/table-custom/TableCustom";
import { RESPONSE_STATUS_CODE, TYPE, INIT_SEARCH_OBJECT, EXPORT_FILE_NAME, INIT_INPUT_SEARCH } from "../constants/moduleConsts";
import { INIT_CONTRACT, ListSearch, contractStatus } from "./constants/contractConsts";
import { convertDataUI } from "./utils/contractUtils";
import { Col, Row } from "react-bootstrap";
import InputSearch from "../components/input/InputSearch";
import AdvancedSearch from "../profile/components/dialogs/AdvancedSearch";
import { getAllDepartmentByUserId } from "../profile/services/profileServices";
import AppContext from "../../AppContext";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../constants";
import { convertOrganizationToTreeView } from "../profile/utils/profileUtils";
import useMultiLanguage from "../../hook/useMultiLanguage";
import ResizableGroup from "../components/resize-component/ResizableGroup";
import ResizablePanel from "../components/resize-component/ResizablePanel";
import TreeView from "../components/tree-view/TreeView";
import { TSelectTree } from "../components/tree-view/treeViewModels";
import { GroupButton } from "../components/GroupButton";
import { localStorageItem } from "../utils/localstorage";
import { KEY_LOCALSTORAGE } from "../auth/core/_consts";
import { useAuth } from "../auth";
import { searchListWorkUnitTree } from "../services/services";
import { IItemSearch } from "../models/models";

const Contract: FC = () => {
  const { lang } = useMultiLanguage();
  const { currentUser } = useAuth();
  const { setPageLoading } = useContext(AppContext);
  document.title = `${lang("GENERAL.APP.NAME")} | ${lang("GENERAL.CONTRACT")}`;
  const [shouldOpenAddNewContract, setShouldOpenAddNewContract] = useState<boolean>(false);
  const [viewInfoContract, setViewInfoContract] = useState<boolean>(false);
  const [contractInfo, setContractInfo] = useState<IContractInfo>(INIT_CONTRACT);
  const [contracts, setContracts] = useState<IContractInfoDto[]>([]);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);

  const [isAdvancedSearch, setIsAdvancedSearch] = useState<boolean>(false);
  const [searchObject, setSearchObject] = useState<any>(INIT_SEARCH_OBJECT);
  const [listInputSearch, setListInputSearch] = useState<any[]>([INIT_INPUT_SEARCH]);
  const [idSelected, setIdSelected] = useState<string>("");
  const [listTreeSelect, setListTreeSelect] = useState<TSelectTree[]>([])
  const [dataChecked, setDataChecked] = useState<IContractInfo[]>([]);

  const updatePageData = async (searchData?: any) => {
    try {
      setPageLoading(true);
      setSearchObject({ ...searchData });
      const { data } = await getListContracts(hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW) ? searchDataRoleUser(searchData) : { ...searchData });
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setContracts(data?.data?.content || []);
        setTotalPage(data?.data?.pageable?.totalPages);
        setTotalElements(data?.data?.pageable?.totalElements);
        setNumberOfElements(data?.data?.pageable?.numberOfElements);
        setIsAdvancedSearch(false);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleCloseDialog = () => {
    setShouldOpenAddNewContract(false);
    setViewInfoContract(false);
    setContractInfo(INIT_CONTRACT);
  };

  const handleOpenInfoDialog = (row: any) => {
    setViewInfoContract(true);
    setShouldOpenAddNewContract(true);
    setContractInfo(convertDataUI({ ...row, employee: currentUser }));
  };

  const handleOpenAddNewContract = () => {
    if (hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW)) {
      setContractInfo({ ...INIT_CONTRACT, employee: currentUser })
    }
    setShouldOpenAddNewContract(true);
  };

  const handleOpenUpdateDialog = () => {
    setViewInfoContract(false);
  };

  const handleCloseUpdateDialog = () => {
    setViewInfoContract(true);
  };

  const handleSearch = async (data: any = {}) => {
    let donViKyHopDongId = searchObject?.donViKyHopDongId;
    let phongBanIds = searchObject?.phongBanIds;
    if (listTreeSelect.length === 0 && !hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW)) {
      setPageLoading(true);
      const treeSelect = await getDonVi();
      if (treeSelect && treeSelect.length > 0) {
        if (treeSelect[0]?.orgId) {
          phongBanIds = treeSelect[0]?.id;
        } else donViKyHopDongId = treeSelect[0]?.id;
        setIdSelected(treeSelect[0]?.id ?? "");
      }
    }
    const dataSearch: any = {
      ...searchObject,
      donViKyHopDongId,
      phongBanIds,
      ...data
    }
    dataSearch.keyword = dataSearch?.keyword?.trim() || ""
    updatePageData(dataSearch)
  }

  useEffect(() => {
    if (searchObject?.pageIndex && searchObject?.pageSize) {
      handleSearch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchObject?.pageIndex, searchObject?.pageSize])

  const handleOpenAdvancedSearch = () => {
    setIsAdvancedSearch(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchObject({
      ...searchObject,
      keyword: e.target?.value
    });
  };

  const handleAdvancedSearch = (data: IItemSearch[]) => {
    setListInputSearch(data);
    handleSearch({ ...convertSearch(data), pageIndex: 1 });
  };

  const handleTreeSelect = async (item: any) => {
    if (!item?.expanded) {
      handleSearch({
        pageIndex: 1,
        phongBanIds: item?.orgId ? item?.id : null,
        donViKyHopDongId: !item?.orgId ? item?.id : null
      })
    }
  }

  const getDonVi = async () => {
    let treeSelect: TSelectTree[] = []
    try {
      if (hasAuthority(PERMISSIONS.DEPARTMENT, PERMISSION_ABILITY.VIEW)) {
        const { data } = await getAllDepartmentByUserId(localStorageItem.get(KEY_LOCALSTORAGE.CURRENT)?.id ?? "");
        if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
          treeSelect = [data?.data]?.map(convertOrganizationToTreeView);
        } else {
          toast.warning(data?.message);
        }
      } else {
        const { data } = await searchListWorkUnitTree();
        if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
          treeSelect = data?.data?.content?.map(convertOrganizationToTreeView);
        } else {
          toast.warning(data?.message);
        }
      }
      setListTreeSelect(treeSelect);
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
    return treeSelect;
  };

  const handleCloseAndSearch = () => {
    handleSearch();
    handleCloseDialog();
  }

  const handleDeleteContract = async (ids: string[]) => {
    try {
      const { data } = await deleteContract(ids);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("GENERAL.DELETE_SUCCESS"));
        await handleSearch();
        return true;
      } else toast.warning(data?.message);
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }

  const columns = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("CONTRACT.STATUS"),
      field: "trangThaiHopDong",
      headerStyle: {
        minWidth: "150px"
      },
      cellStyle: {
        textAlign: "center"
      },
      render: (row: any) => <span className={`${checkStatus(contractStatus, row?.trangThaiHopDong?.code)} status`}>{row?.trangThaiHopDong?.name}</span>
    },
    {
      name: lang("INPUT.CODE_NEW"),
      field: "employeeCode",
      headerStyle: {
        minWidth: "150px"
      },
      render: (row: any) => <span className="color-steel-blue fw-600">{row?.employeeCode}</span>
    },
    {
      name: lang("CONTRACT.WORKERFULLNAME"),
      field: "tenNguoiLaoDong",
      headerStyle: {
        minWidth: "150px"
      },
      cellStyle: {
        minWidth: "150px",
        textAlign: "left"
      }
    },
    {
      name: lang("CONTRACT.NUMBER"),
      field: "soHopDong",
      headerStyle: {
        minWidth: "180px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("PROFILE.ROLE"),
      field: "chucVuText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        minWidth: "200px",
        textAlign: "left"
      }
    },
    {
      name: lang("INPUT.SALARY.ROLE"),
      field: "chucDanhText",
      headerStyle: {
        minWidth: "270px"
      },
      cellStyle: {
        minWidth: "270px",
        textAlign: "left"
      }
    },
    {
      name: lang("INPUT.DEPARTMENTS"),
      field: "phongBanText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        minWidth: "200px",
        textAlign: "left"
      }
    },
    {
      name: lang("CONTRACT.TYPE"),
      field: "loaiHopDong",
      headerStyle: {
        minWidth: "150px"
      },
      cellStyle: {
        minWidth: "150px",
        textAlign: "left"
      },
      render: (row: any) => <span>{row?.loaiHopDong?.name || ""}</span>
    },
    {
      name: lang("CONTRACT.SIGNINGSTATUS"),
      field: "trangThaiKy",
      headerStyle: {
        minWidth: "120px"
      },
      render: (row: any) => <span>{row?.trangThaiKy?.name || ""}</span>
    },
    {
      name: lang("CONTRACT.SIGNINGDATE"),
      field: "ngayKyHopDong",
      headerStyle: {
        minWidth: "100px"
      },
      render: (row: any) => <span>{formatDateTable(row?.ngayKyHopDong)}</span>
    }
  ];

  return (
    <div className="contract spaces px-8 py-12">
      {!shouldOpenAddNewContract && (
        <>
          <Row className="spaces mb-12 flex-middle">
            <Col xs={5} xxl={7} className="spaces p-0">
              <h2 className="breadcrumb-title">{lang("CONTRACT.ALL")}</h2>
            </Col>
            <Col xs={7} xxl={5} className="flex">
              <div className="spaces mr-10 flex-1">
                <InputSearch
                  className="border-3px"
                  value={searchObject?.keyword}
                  placeholder="Tìm kiếm theo mã NV, họ và tên, số HĐ và phòng ban"
                  handleSearch={() => handleSearch({ pageIndex: 1 })}
                  handleChange={handleChange}
                  isEnter
                />
              </div>
              <GroupButton className="spaces mr-10" handleEvent={() => handleSearch({ pageIndex: 1 })}>
                {lang("BTN.SEARCH")}
              </GroupButton>
              <GroupButton className="spaces mr-10" outline handleEvent={handleOpenAdvancedSearch}>
                {lang("BTN.ADVANCE_SEARCH")}
              </GroupButton>
              {isAdvancedSearch && (
                <AdvancedSearch
                  open={isAdvancedSearch}
                  listInputSearch={listInputSearch}
                  handleSearch={handleAdvancedSearch}
                  listOption={ListSearch()}
                  handleClose={() => setIsAdvancedSearch(false)}
                />
              )}
            </Col>
          </Row>

          <Row className="bg-white">
            <ResizableGroup direction="horizontal">
              {!hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW) &&
                <ResizablePanel initialStyle={{ flexBasis: "200px", maxWidth: "600px" }}>
                  <h2 className="spaces fw-600 py-12 m-0 mb-6 box-shadow-bottom">{lang("GENERAL.UNIT_DEPARTMENT")}</h2>
                  <div className="spaces pl-4 select-tree">
                    <TreeView
                      listOption={listTreeSelect}
                      handleSelect={handleTreeSelect}
                      idSelected={idSelected}
                      handleChangeSelectId={setIdSelected}
                    />
                  </div>
                </ResizablePanel>
              }
              <div className="spaces pl-0 table-scroll">
                <TableCustom
                  id="contracts"
                  data={contracts}
                  columns={columns}
                  searchObject={searchObject}
                  setSearchObject={setSearchObject}
                  buttonAdd={hasAuthority(PERMISSIONS.HOP_DONG, PERMISSION_ABILITY.CREATE)}
                  buttonExportExcel={!hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW)}
                  notDelete={!hasAuthority(PERMISSIONS.HOP_DONG, PERMISSION_ABILITY.DELETE)}
                  handleDelete={handleDeleteContract}
                  handleExportExcel={(ids: string[]) =>
                    exportToFile({
                      exportAPI: () => exportHopDong({ ids, ...searchObject }),
                      fileName: EXPORT_FILE_NAME.HOP_DONG,
                      setPageLoading
                    })
                  }
                  type={TYPE.MULTILINE}
                  fixedColumnsCount={4}
                  totalPages={totalPage}
                  totalElements={totalElements}
                  numberOfElements={numberOfElements}
                  handleOpenDialog={handleOpenAddNewContract}
                  handleDoubleClick={handleOpenInfoDialog}
                  setDataChecked={setDataChecked}
                  dataChecked={dataChecked}
                />
              </div>
            </ResizableGroup>
          </Row>
        </>
      )}
      {shouldOpenAddNewContract && (
        <AddNewContract
          view={viewInfoContract}
          contractInfo={contractInfo}
          handleClose={handleCloseDialog}
          handleCloseAndSearch={handleCloseAndSearch}
          handleOpenUpdateDialog={handleOpenUpdateDialog}
          handleCloseUpdateDialog={handleCloseUpdateDialog}
        />
      )}
    </div>
  );
};
export { Contract };

import { SEARCH_OBJECT_MAX_SIZE } from "../../constants/moduleConsts"
import { IWorkDay } from "../models/shiftDivisionModels"
import { TYPE_OBJECT_SCHEDULE } from "./shiftComprehensionConsts"

export const INIT_UPDATE_SHIFT_SEARCH: any = {
	donViCongTac: null,
	phongBan: null,
	nam: null,
	thang: null,
	doiTuong: TYPE_OBJECT_SCHEDULE.DIEU_DUONG,
	...SEARCH_OBJECT_MAX_SIZE
}

export const INIT_WORK_DAY: IWorkDay = {
	ngayLamViec: "",
	soLuongBenhNhan: null,
	employeeInfoList: []
}
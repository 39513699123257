import { columnNamesCollapseType } from "../../../components/table-collapse-custom/TableCollapseCustom";
import { formatDateTable } from "../../../utils/functionUtils";

export const canhBaoDenHanVanBangChungChi = (): columnNamesCollapseType[] => {
  return (
    [
      {
        name: "Trạng thái",
        field: "",
        headerCellProps: {
          minWidth: 120
        },
        render: (row: any) => <span className={`${row?.trangThaiHetHan?.bgColor} warning-status`}>{row?.trangThaiHetHan?.name}</span>
      },
      {
        name: "Mã nhân viên",
        field: "maNhanVien",
        headerCellProps: {
          minWidth: 120
        }
      },
      {
        name: "Họ và tên",
        field: "tenNhanVien",
        headerCellProps: {
          minWidth: 250
        }
      },
      {
        name: "Khoa/Phòng",
        field: "phongBanText",
        headerCellProps: {
          minWidth: 300
        }
      },
      {
        name: "Tên văn bằng/Chứng chỉ",
        field: "tenChungChi",
        headerCellProps: {
          minWidth: 300
        },
        render: (row) => <span>{row?.tenChungChi || row?.vanBang}</span>
      },
      {
        name: "Ngày cấp",
        field: "thoiGianHieuLucTuNgay",
        headerCellProps: {
          minWidth: 100
        },
        bodyCellProps: {
          textAlign: "center"
        },
        render: (row: any) => <span>{formatDateTable(row?.thoiGianHieuLucTuNgay)}</span>
      },
      {
        name: "Ngày hết hạn",
        field: "thoiGianHieuLucDenNgay",
        headerCellProps: {
          minWidth: 100
        },
        bodyCellProps: {
          textAlign: "center"
        },
        render: (row: any) => <span>{formatDateTable(row?.thoiGianHieuLucDenNgay)}</span>
      }
    ]
  )
}
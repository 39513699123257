import { useContext, useEffect, useState } from "react";
import { IAllowance } from "../../../../contract/models/allowanceModels";
import { KTSVG } from "../../../../../../_metronic/helpers";
import { exportToFile, formatDateTable } from "../../../../utils/functionUtils";
import { exportPhuCap } from "../../../../services/exportFileServices";
import TableCustom from "../../../../components/table-custom/TableCustom";
import { getAllowancesByEmployee } from "../../../services/dialogServices";
import { EXPORT_FILE_NAME, RESPONSE_STATUS_CODE, TYPE } from "../../../../constants/moduleConsts";
import { EmployeeAllowanceDialog } from "../../dialogs/EmployeeAllowanceDialog";
import { deleteAllowance } from "../../../../contract/services/allowanceServices";
import { toast } from "react-toastify";
import { INIT_ALLOWANCE } from "../../../../contract/constants/contractConsts";
import useMultiLanguage from "../../../../../hook/useMultiLanguage";
import AppContext from "../../../../../AppContext";

interface Props {
  isView: boolean;
  identify: any;
  isCallApi: boolean;
  isDisplayBtnAdd?: boolean;
}

function Allowance(props: Props) {
  const { lang } = useMultiLanguage();
  const { isView, identify, isCallApi, isDisplayBtnAdd = true } = props;

  const [openAllowanceDialog, setOpenAllowanceDialog] = useState<boolean>(false);
  const [allowanceInfo, setAllowanceInfo] = useState<IAllowance>({});
  const [listAllowance, setListAllowance] = useState<IAllowance[]>([]);
  const { setPageLoading } = useContext(AppContext);

  useEffect(() => {
    if (!(identify && isCallApi)) return;
    updateDataAllowance();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identify, isCallApi]);

  const handleOpenAllowanceDialog = (data: any = {}) => {
    setOpenAllowanceDialog(true);
    setAllowanceInfo(data);
  };

  const handleCloseAllowanceDialog = () => {
    setAllowanceInfo({});
    setOpenAllowanceDialog(false);
  };

  const updateDataAllowance = async () => {
    if (identify) {
      try {
        let { data } = await getAllowancesByEmployee(identify || "");
        if(data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
          setListAllowance(data?.data || []);
        } else {
          toast.warning(data?.message);
        }
      } catch (error) {
        toast.error(lang("GENERAL.ERROR"));
      }
    }
  };

  const handleDeleteItems = async (ids: string) => {
    try {
      const { data } = await deleteAllowance(ids);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("GENERAL.DELETE_SUCCESS"));
        updateDataAllowance();
        return true;
      } else toast.warning(data?.message);
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const allowanceColumns = [
    {
      name: lang("TABLE.INDEX"),
      field: "stt",
      headerStyle: {
        minWidth: "100px"
      },
      render: (row: any, index: number, STT: number) => <span>{STT}</span>
    },
    {
      name: lang("ALLOWANCE.TYPE"),
      field: "tenKhoanPhuCap",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row: any) => <div>{row?.tenKhoanPhuCap?.name}</div>
    },
    {
      name: lang("INPUT.WORKEXPERIENCE.DATE.START"),
      field: "thoiGianHieuLucBatDau",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row: any) => <div>{formatDateTable(row?.thoiGianHieuLucBatDau)}</div>
    },
    {
      name: lang("INPUT.WORKEXPERIENCE.DATE.END"),
      field: "thoiGianHieuLucKetThuc",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row: any) => <div>{formatDateTable(row?.thoiGianHieuLucKetThuc)}</div>
    },
    {
      name: lang("ALOWNCE.BONUS_FORM"),
      field: "hinhThucHuong",
      headerStyle: {
        minWidth: "200px"
      }
    },
    {
      name: lang("ALLOWANCE.COEFFICIENT_PERCENT_VALUE"),
      field: "heSoPhanTramHuong",
      headerStyle: {
        minWidth: "250px"
      }
    },
    {
      name: lang("ALLOWANCE.BHXH"),
      field: "phuCapBHXH",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row: any) => {
        if (row?.phuCapBHXH) {
          return (
            <KTSVG
              path="/media/icons/check.svg"
              className="svg-icon-1"
            />
          );
        } else {
          return <span></span>
        }
      }
    },
    {
      name: lang("ALLOWANCE.IS_EXTENSION"),
      field: "coGiaHan",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row: any) => {
        if (row?.coGiaHan) {
          return (
            <KTSVG
              path="/media/icons/check.svg"
              className="svg-icon-1"
            />
          );
        } else {
          return <span></span>
        }
      }
    }
  ];

  return (
    <div className="block-content">
      <TableCustom
        id="allowance"
        title={lang("GENERAL.ALLOWANCE")}
        isActionTableTab
        buttonAdd={!isView && isDisplayBtnAdd}
        buttonExportExcel={true}
        notDelete={isView}
        noToolbar={true}
        data={listAllowance}
        columns={allowanceColumns}
        handleDelete={handleDeleteItems}
        justFilter={true}
        type={TYPE.MULTILINE}
        noPagination={true}
        handleOpenDialog={() => handleOpenAllowanceDialog(INIT_ALLOWANCE)}
        handleExportExcel={() => 
          exportToFile({
            exportAPI: () => exportPhuCap(String(identify)), 
            fileName: EXPORT_FILE_NAME.PHU_CAP,
            setPageLoading
          })
        }
        handleDoubleClick={handleOpenAllowanceDialog}
      />

      {openAllowanceDialog && (
        <EmployeeAllowanceDialog
          employeeId={identify}
          isView={isView}
          getListAllowance={updateDataAllowance}
          handleCloseAllowanceDialog={handleCloseAllowanceDialog}
          allowanceInfo={allowanceInfo}
        />
      )}
    </div>
  );
}

export default Allowance;

/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import Autocomplete from "../../components/input/autocomplete";
import TextValidator from "../../components/input/text-validator";
import { RESPONSE_STATUS_CODE, VARIABLE_STRING } from "../../constants/moduleConsts";
import { listBloodType } from "../constants/profileDialogConsts";
import { INIT_MEDICAL, REF_TAB } from "../constants/profileConsts";
import { addMedicalInfo } from "../services/profileServices";
import { convertDataInfoDto } from "../utils/profileUtils";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { GroupButton } from "../../components/GroupButton";

const MedicalInformation = (props: any) => {
  const { lang } = useMultiLanguage();
  const { identify, employeeProfiles, isView, handleGetEmployee, activeTab } = props;
  useEffect(() => {
    if (identify && activeTab === REF_TAB.TT_KHAC) {
      formik.setValues(employeeProfiles);
    }
  }, [employeeProfiles, activeTab]);

  const handleFormSubmit = async (values: any) => {
    try {
      const dataSubmit = convertDataInfoDto(values);
      const { data } = await addMedicalInfo(identify, dataSubmit);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("TOAST.EDIT.SUCCESS"));
        handleGetEmployee(employeeProfiles?.id);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const formik = useFormik({
    initialValues: INIT_MEDICAL,
    onSubmit: handleFormSubmit
  });

  const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target;
    formik.setFieldValue(name, checked);
  };

  const handleChangeSelect = (name: string, value: any) => {
    formik.setFieldValue(name, value);
  };

  return (
    <Form
      className="form-info pb-0"
      onSubmit={formik.handleSubmit}
    >
      <div className="block-content">
        <div className="spaces mb-8 flex flex-between mt-10">
          <span className="spaces fs-18 fw-600">{lang("GENERAL.INFO.MEDICAL")}</span>
          {!isView && <GroupButton type="btn-save" />}
        </div>
        <Row className="spaces pl-20">
          <Col xs={4} xl={4} className="spaces pt-0">
            <Autocomplete
              className="spaces z-index-10"
              lable={lang("INPUT.MEDICAL.BLOODTYPE")}
              name="nhomMau"
              value={formik?.values?.nhomMau || ""}
              options={listBloodType}
              isReadOnly={isView}
              onChange={(value) => handleChangeSelect(VARIABLE_STRING.NHOM_MAU, value)}
            />
          </Col>
          <Col xs={4} xl={4} className="spaces pt-0">
            <TextValidator
              lable={lang("INPUT.MEDICAL.HEIGHT")}
              name="chieuCao"
              value={formik?.values?.chieuCao || ""}
              type="number"
              readOnly={isView}
              onChange={formik.handleChange}
            />
          </Col>
          <Col xs={4} xl={4} className="spaces pt-0">
            <TextValidator
              lable={lang("INPUT.MEDICAL.WEIGHT")}
              name="canNang"
              value={formik?.values?.canNang || ""}
              type="number"
              readOnly={isView}
              onChange={formik.handleChange}
            />
          </Col>
          <Col xs={4} xl={4} className="spaces pt-4">
            <TextValidator
              lable={lang("INPUT.MEDICAL.HEALTHCONDITION")}
              name="tinhTrangSucKhoe"
              value={formik?.values?.tinhTrangSucKhoe || ""}
              type="text"
              readOnly={isView}
              onChange={formik.handleChange}
            />
          </Col>
          <Col xs={4} xl={4} className="spaces pt-4">
            <TextValidator
              lable={lang("INPUT.MEDICAL.DISEASES")}
              name="benhTat"
              value={formik?.values?.benhTat || ""}
              type="text"
              readOnly={isView}
              onChange={formik.handleChange}
            />
          </Col>
          <Col xs={4} xl={4} className="spaces pt-4">
            <Form.Check
              className="custom-form-check"
              label={lang("INPUT.MEDICAL.DISABILITIES")}
              name="nguoiKhuyetTat"
              disabled={isView}
              checked={formik?.values?.nguoiKhuyetTat}
              onChange={handleChangeCheckBox}
            />
          </Col>
          <Col xs={12} className="spaces pt-4">
            <TextValidator
              lable={lang("INPUT.MEDICAL.NOTE")}
              name="luuY"
              value={formik?.values?.luuY || ""}
              type="text"
              as="textarea"
              rows="3"
              readOnly={isView}
              onChange={formik.handleChange}
            />
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default MedicalInformation;

import { paramsConfig } from "../../utils/paramsUtils";
import axios from "axios";
import { IShift } from "../models/shiftModels";

const API_URL_CA_LAM_VIEC = process.env.REACT_APP_API_URL_CHAM_CONG;

export const searchShift = (searchData: any) => {
  let url = `${API_URL_CA_LAM_VIEC}/ca-lam-viec/page`;
  return axios.get(url, paramsConfig(searchData))
};

export const addShift = (shiftInfo: IShift) => {
  let url = `${API_URL_CA_LAM_VIEC}/ca-lam-viec`;
  return axios.post(url, shiftInfo);
};

export const updateShift = (id: string, shiftInfo: IShift) => {
  let url = `${API_URL_CA_LAM_VIEC}/ca-lam-viec/${id}`;
  return axios.put(url, shiftInfo);
};

export const deleteShift = (ids: string[]) => {
  let url = `${API_URL_CA_LAM_VIEC}/ca-lam-viec/ids?ids=${ids.toString()}`;
  return axios.delete(url);
};

import moment from "moment";
import { IPropsExportWord } from "../../../components/exportWord";
import { ValueOrDots } from "../../../components/exportWord/partials";
import { FORMAT_DATE } from "../../../constants/moduleConsts";
import { formatDateTable } from "../../../utils/functionUtils";

const OnLeaveWord = ({ data }: IPropsExportWord) => {
  return (
    <div className="word spaces px-70">
      <div className="spaces text-center flex flex-between align-start">
        <div className="fs-lg">
          <p>{data?.soYTeHeader ?? ""}</p>
          <p className="fw-600 text-uppercase">
            <span className="header-underline fs-lg">{data?.donViCongTacText ?? ""}</span>
          </p>
        </div>
        <div className="fw-600">
          <p className="fs-lg">CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM</p>
          <p>
            <span className="header-underline fs-lg">Độc lập - Tự do - Hạnh phúc</span>
          </p>
        </div>
      </div>
      <p className="text-center fw-600 fs-lg pt-6">ĐƠN XIN NGHỈ PHÉP</p>
      <p className="spaces ml-40 header-underline"><b>Kính gửi:</b></p>
      <div className="text-italic spaces ml-140 fw-bold">
        <p>- Ban giám đốc {data?.donViCongTacText},</p>
        <p>- Trưởng phòng Tổ chức cán bộ</p>
        <p>- Trưởng khoa (phòng): <span className="fw-normal">{data?.phongBanText}</span></p>
      </div>
      <div className="text-indent-40 mt-3">
        <p>Tên tôi là: <ValueOrDots value={data?.employeeText} size={100} /></p>
        <p>Chức vụ (Chức danh): <ValueOrDots value={data?.chucDanhText} size={100} /></p>
        <p>Khoa, phòng công tác: <ValueOrDots value={data?.phongBanText} size={100} /></p>
        <p>Điện thoại liên hệ: <ValueOrDots value={data?.dienThoai} size={30} /></p>
        <p>Xin được nghỉ phép năm: <ValueOrDots value="" size={30} /> trong thời gian <ValueOrDots value="" size={30} /></p>
        <p>Kể từ ngày: {data?.tuNgay ? formatDateTable(data?.tuNgay) : "............/............./20..........."} đến 
          hết ngày {data?.denNgay ? formatDateTable(data?.denNgay) : "............/............./20..........."}</p>
        <p>Nơi nghỉ phép: <ValueOrDots value={data?.noiXinNghi} size={120} /></p>
        <p>Lí do nghỉ phép: <ValueOrDots value={data?.lyDoXinNghi} size={120} /></p>
        <p>Kính trình Ban giám đốc <ValueOrDots value={data?.donViCongTacText} size={60} />, 
          Phòng Tổ chức cán bộ, Trưởng khoa xem xét và giải quyết cho tôi được nghỉ phép theo nguyện vọng trên.
        </p>
        <p>Tôi xin đảm bảo sẽ nghỉ theo đúng số ngày đã ghi trong đơn, đồng thời bàn giao công việc cho Cán bộ 
          quản lý phòng, khoa và tiếp nhận lại công việc đầy đủ trước và sau khi nghỉ phép.
        </p>
        <p>Tôi xin chân thành cảm ơn!</p>
      </div>
      <p className="text-italic text-align-right spaces mt-16">
        <ValueOrDots value="" size={20} />, ngày <ValueOrDots value={data.ngayNop ? moment(data.ngayNop).format(FORMAT_DATE.DD) : ""} size={15} /> tháng{" "}
        <ValueOrDots value={data.ngayNop ? moment(data.ngayNop).format(FORMAT_DATE.MM) : ""} size={15} /> năm{" "}
        <ValueOrDots value={data.ngayNop ? moment(data.ngayNop).format(FORMAT_DATE.YYYY) : ""} size={15} />
      </p>
      <div className="flex flex-between align-start spaces pb-70 mt-16 text-center">
        <div>
          <h3>THỦ TRƯỞNG ĐƠN VỊ</h3>
          <h4 className="spaces mt-60">{data?.thuTruongText}</h4>
        </div>
        <div>
          <h3>PHÒNG TCCB</h3>
          <h4 className="spaces mt-60">{data?.phongTccbText}</h4>
        </div>
        <div>
          <h3>TRƯỞNG KHOA/PHÒNG</h3>
          <h4 className="spaces mt-60">{data?.truongKhoaText}</h4>
        </div>
        <div>
          <h3>NGƯỜI LÀM ĐƠN</h3>
          <h4 className="spaces mt-60">{data?.employeeText}</h4>
        </div>
      </div>
    </div>
  );
};

export default OnLeaveWord;
const KEY_LOCALSTORAGE = {
  AUTH: "auth",
  ACCESS_TOKEN_DECODE: "access-token-decode",
  TOKEN_EXPIRATION: "token-expiration",
  DEPARTMENT: "department",
  LIST_SUB_MENU: 'listSubMenu',
  AUTH_LOCAL_STORAGE_KEY: 'kt-auth-react-v',
  AUTHORITIES: "authorities",
  LIST_MENU: "listMenu",
  ACCOUNT_ROLE: "acccount-role",
  DEFAULT_MODULE: 'home',
  CURRENT: "current",
  URL_IMAGE_AVATAR: "urlImageAvatar",
  ACCOUNT_EMPLOYEE_ID: "account_employee_id",
  EMPLOYEE: "employee"
}


const AUTHORIZE_REQUEST = `${process.env.REACT_APP_SSO_API_URL}/oauth2/authorize?response_type=${process.env.REACT_APP_SSO_RESPONSE_TYPE}&scope=${process.env.REACT_APP_SSO_SCOPE}&client_id=${process.env.REACT_APP_SSO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_SSO_REDIRECT_URI}`;

export { KEY_LOCALSTORAGE, AUTHORIZE_REQUEST }

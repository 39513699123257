import { IColumnsTotal } from "../../../components/table-grouping/TableGrouping";
import { TYPE } from "../../../constants/moduleConsts";

export const columnSalaryFundTotal: IColumnsTotal[] = [
  {
    colSpan: 7,
    isTitle: true,
    field: "",
    cellStyle: {
      fontSize: "15px",
      fontWeight: "bold",
      textAlign: "left",
      paddingLeft: "16px"
    }
  },
  {
    field: "heSoBacLuong",
    type: TYPE.NUMBER_FLOAT
  },
  {
    field: "phuCapTnvk",
    type: TYPE.NUMBER_FLOAT
  },
  {
    field: "phuCapChucVu",
    type: TYPE.NUMBER_FLOAT
  },
  {
    field: "phuCapTrachNhiem",
    type: TYPE.NUMBER_FLOAT
  },
  {
    field: "phuCapDocHai",
    type: TYPE.NUMBER_FLOAT
  },
  {
    field: "phuCapUuDai",
    type: TYPE.NUMBER_FLOAT
  },
  {
    field: "phuCapKhac",
    type: TYPE.NUMBER_FLOAT
  },
  {
    field: "tongSo",
    type: TYPE.MONEY
  },
  {
    field: "luongTheoNgachBac",
    type: TYPE.MONEY
  },
  {
    field: "tienPhuCapTnvk",
    type: TYPE.MONEY
  },
  {
    field: "tongSoPhuCap",
    type: TYPE.MONEY
  },
  {
    field: "tienPhuCapChucVu",
    type: TYPE.MONEY
  },
  {
    field: "tienPhuCapTrachNhiem",
    type: TYPE.MONEY
  },
  {
    field: "tienPhuCapDocHai",
    type: TYPE.MONEY
  },
  {
    field: "tienPhuCapUuDai",
    type: TYPE.MONEY
  },
  {
    field: "tienPhuCapKhac",
    type: TYPE.MONEY
  },
  {
    field: "ghiChu"
  }
];

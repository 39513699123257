import axios from "axios";
import { paramsConfig } from "../../utils/paramsUtils";
import { IObject } from "../../models/models";

const API_URL = process.env.REACT_APP_API_URL;

export const getRemindAmountApi = () => {
  const url = API_URL + "/nhac-nho";
  return axios.get(url);
};

export const searchRemindApi = (params: IObject) => {
  const url = API_URL + "/nhac-nho/page";
  return axios.get(url, paramsConfig(params));
};

import { BonusInfo } from "../models/bonusModels";

export const convertDataSubmit = (data: BonusInfo, bonusEmployeeList: any) => {
  const bonusListConvert = bonusEmployeeList ? bonusEmployeeList?.map((item: any) => item?.listEmployee)?.flat() : null; 

  const dataConvert: BonusInfo = {
    ...data,
    hinhThucKhenThuongId: data?.hinhThucKhenThuong?.id || "",
    hinhThucKhenThuongText: String(data?.hinhThucKhenThuong?.value || ""),
    danhHieuThiDuaId: data?.danhHieuThiDua?.id || "",
    danhHieuThiDuaText: String(data?.danhHieuThiDua?.value || ""),
    donViBanHanhId: data?.donViBanHanh?.id || "",
    donViBanHanhText: data?.donViBanHanh?.name || "",
    nguoiKyQuyetDinhId: data?.nguoiKyQuyetDinh?.id || "",
    nguoiKyQuyetDinhText: data?.nguoiKyQuyetDinh?.name || "",
    khenThuongList: data?.isProfile ? data?.khenThuongList : bonusListConvert
  };

  return dataConvert;
};

export const convertDataUI = (data: BonusInfo) => {
  const dataConvert = {
    ...data,
    hinhThucKhenThuong: data?.hinhThucKhenThuongId
      ? {
          id: data?.hinhThucKhenThuongId || "",
          value: data?.hinhThucKhenThuongText || ""
        }
      : null,
    danhHieuThiDua: data?.danhHieuThiDuaId
      ? {
          id: data?.danhHieuThiDuaId || "",
          value: data?.danhHieuThiDuaText || ""
        }
      : null,
    donViBanHanh: data?.donViBanHanhId
      ? {
          id: data?.donViBanHanhId || "",
          name: data?.donViBanHanhText || ""
        }
      : null,
    nguoiKyQuyetDinh: data?.nguoiKyQuyetDinhId
      ? {
          id: data?.nguoiKyQuyetDinhId || "",
          name: data?.nguoiKyQuyetDinhText || ""
        }
      : null
  };

  return dataConvert;
};


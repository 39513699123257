import { paramsConfig} from "../../utils/paramsUtils";
import axios from "axios";
import { IApprovalConfig } from "../models/categoryModels";

const API_URL_CHAM_CONG = process.env.REACT_APP_API_URL_CHAM_CONG;

export const searchApprovalConfig = (searchData: any = {}) => {
  let url = `${API_URL_CHAM_CONG}/config-approval/page`;
  return axios.get(url, paramsConfig(searchData));
};

export const addApprovalConfig = (symbolInfo: IApprovalConfig) => {
  let url = `${API_URL_CHAM_CONG}/config-approval`;
  return axios.post(url, symbolInfo);
};

export const updateApprovalConfig = (id: string, symbolInfo: IApprovalConfig) => {
  let url = `${API_URL_CHAM_CONG}/config-approval/${id}`;
  return axios.put(url, symbolInfo);
};

export const deleteApprovalConfig = (ids: string[]) => {
  let url = `${API_URL_CHAM_CONG}/config-approval/ids?ids=${ids.toString()}`;
  return axios.delete(url);
};

import { Col, Row } from "react-bootstrap";
import TextUnderline from "../../../components/TextUnderline";
import { ValueOrDots } from "../../../components/exportWord/partials";
import { TYPE } from "../../../constants/moduleConsts";
import { IPropsExportWord } from "../../../components/exportWord";

export function DecisionRetireWord({ data }: IPropsExportWord) {
  return (
    <div className="word spaces px-70 colors-word">
      <div className="spaces text-center flex flex-between align-start">
        <div className="fs-lg">
          <p>{data?.soYTeHeader ?? ""}</p>
          <p className="fw-600 text-uppercase">
            <span className="header-underline fs-lg">
              <ValueOrDots className="fs-lg" value={data.donViCongTacText} size={70} />
            </span>
          </p>
          <p className="spaces mt-16 fs-md">
            Số: <ValueOrDots value={data.soQuyetDinh} />
            /QĐ-BV
          </p>
        </div>
        <div className="fw-600 fs-lg">
          <p>CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM</p>
          <p>
            <span className="header-underline fs-lg">Độc lập - Tự do - Hạnh phúc</span>
          </p>
          <p className="spaces mt-16 fs-md text-italic fw-500">
            <ValueOrDots value="" size={20} />, {data.ngayQuyetDinhText}
          </p>
        </div>
      </div>
      <div className="fw-600 text-center fs-lg spaces py-16">
        <p>QUYẾT ĐỊNH</p>
        <p>
          <span className="header-underline fs-lg">Về việc nghỉ việc hưởng chế độ hưu trí</span>
        </p>
      </div>
      <div className="fw-600 text-center fs-lg spaces my-16">
        <p className="text-uppercase">
          GIÁM ĐỐC <ValueOrDots className="fs-lg" value={data.donViCongTacText} size={70} />
        </p>
      </div>
      <div className="text-italic text-indent-40 text-justify">
        <p>{data?.qdUBNDHuuTri ?? ""}</p>
        <p>
          Căn cứ Luật Bảo hiểm xã hội số 58/2014/QH13; Nghị định số 115/2015/NĐ-CP ngày 11/11/2015 của Chính phủ quy
          định chi tiết một số điều của Luật Bảo hiểm xã hội về bảo hiểm xã hội bắt buộc;
        </p>
        <p>{data?.quyetDinhHopDong ?? ""}</p>
        <p>{data?.qdhdHuuTri ?? ""}</p>
        <p>Theo đề nghị của Trưởng phòng Tổ chức hành chính Bệnh viện.</p>
      </div>
      <div className="fw-600 text-center fs-lg">
        <p>QUYẾT ĐỊNH:</p>
      </div>
      <div className="text-indent-40">
        <div className="spaces pl-40">
          <Row>
            <Col xs={8} className="fw-600">
              <TextUnderline readOnly label="Điều 1. Ông/Bà" value={data.fullName} noColon />
            </Col>
            <Col xs={4}>
              <TextUnderline readOnly label="Mã số BHXH" value={data.soSoBHXH} />
            </Col>
          </Row>
          <TextUnderline readOnly label="Sinh ngày" value={data.birthdate} type={TYPE.DATE} />
          <TextUnderline readOnly label="Nơi sinh" value={data.noiSinh} />
          <TextUnderline readOnly label="Chức danh" value={data.chucDanhText} />
          <TextUnderline readOnly label="Đơn vị công tác" value={data.donViCongTacText} />
          <TextUnderline
            readOnly
            label="Được nghỉ việc để hưởng chế độ hưu trí từ ngày"
            value={data.ngayHieuLuc}
            type={TYPE.DATE}
          />
          <TextUnderline readOnly label="Nơi cư trú khi nghỉ hưu" value={data.hoKhauThuongTru} />
          <TextUnderline readOnly label="Điện thoại" value={data.phone} />
        </div>
        <p>
          Hình thức nhận lương hưu: Qua tài khoản thẻ ATM, Chủ tài khoản: <ValueOrDots value={data.fullName} />, số tài
          khoản: 
          <ValueOrDots value={data.tkNganHang} size={37} /> tại <ValueOrDots value={data.nganHangText} size={70} /> chi
          nhánh <ValueOrDots value={data.chiNhanh} size={70} />.
        </p>
        <p>
          Nơi đăng ký khám chữa bệnh BHYT ban đầu: <ValueOrDots value={data.donViCongTacText} size={70} />.
        </p>
        <p>
          <span className="fw-600">Điều 2.</span> Chế độ hưu trí đối với ông/bà <ValueOrDots value={data.fullName} /> do
          Bảo hiểm xã hội thành phố Hà Nội giải quyết theo quy định của pháp luật Bảo hiểm xã hội.{" "}
        </p>
        <p>
          <span className="fw-600">Điều 3.</span> Các ông, bà: Trưởng phòng Tổ chức hành chính; Trưởng phòng Tài chính
          kế toán; Trưởng, phó các khoa, phòng có liên quan và ông/bà{" "}
          <span className="fw-600">
            <ValueOrDots value={data.fullName} />
          </span>{" "}
          chịu trách nhiệm thi hành Quyết định này.
        </p>
      </div>
      <div className="spaces pt-16 pb-70 flex flex-between align-start">
        <div>
          <p className="text-italic fw-600">Nơi nhận:</p>
          <p>- Sở Y tế Hà Nội </p>
          <p>- BHXH TP Hà Nội </p>
          <p>- Như Điều 3;</p>
          <p>- Lưu: TCHC, VT.</p>
        </div>
        <div className="text-center fw-600 spaces pr-20">
          <p>GIÁM ĐỐC</p>
          <p className="spaces mt-90">
            <ValueOrDots value={data.nguoiDaiDienCtyKyText} size={60} />
          </p>
        </div>
      </div>
    </div>
  );
}

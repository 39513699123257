/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import AppContext from "../../../AppContext";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import UploadFile from "../../components/file-upload/UploadFile";
import { GroupButton } from "../../components/GroupButton";
import Autocomplete from "../../components/input/autocomplete";
import { RangeDatePicker } from "../../components/input/RangeDatePicker";
import TextValidator from "../../components/input/text-validator";
import { IFile } from "../../profile/models/dialogModels";
import { getEmployeeProfileById } from "../../profile/services/profileServices";
import { searchAllEmployee, searchAllSimpleValue, searchNguoiDaiDienKy, searchPhongBanByDonVi } from "../../services/services";
import {
  COMPLETED_STATUS,
  RESPONSE_STATUS_CODE,
  TYPE,
  VARIABLE_STRING,
  TYPE_CATEGORY
} from "../../constants/moduleConsts";
import { checkObject, handleBlurDate, hasAuthority } from "../../utils/functionUtils";
import { DISMISS, INIT_DISMISSED, LIST_DISMISSED_TYPE } from "../constants/dismissedConsts";
import { IDismissed } from "../models/dismissedModels";
import { addNewDismissed, editDismissed, getTypeAssignmentConcurrently } from "../services/dismissedServices";
import { IPeopleDecision, OptionReactSelect } from "../../models/models";
import { STATUS_NV } from "../../profile/constants/dialogChildConsts";
import DialogPositionConcurrently from "./DialogPositionConcurrently";
import { IConcurrently } from "../../concurrently/models/concurrentlyModels";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../../constants";
import { AddNewRepresentative } from "../../components/dialogs";

interface IProps {
  dismissedInfo: IDismissed;
  isView: boolean;
  setIsView: (value: boolean) => void;
  handleCloseDialog: () => void;
  handleOpenUpdateDialog: () => void;
  handleCloseUpdateDialog: () => void;
  handleCloseAndSearch: () => void;
}
const DismissedForm: FC<IProps> = ({
  dismissedInfo,
  handleCloseDialog,
  isView,
  setIsView,
  handleCloseUpdateDialog,
  handleCloseAndSearch
}) => {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [isShowAcceptDismissBtn, setIsShowAcceptDismissBtn] = useState<boolean>(false);
  const [isAcceptDismiss, setIsAcceptDismiss] = useState<boolean>(dismissedInfo.xacNhanMienNhiem || false);
  const [isShowBtnSave, setIsShowBtnSave] = useState<boolean>(true);
  const [peopleDecisions, setPeopleDecisions] = useState<IPeopleDecision[]>([]);
  const [listTypeAssignmentConcurrently, setListTypeAssignmentConcurrently] = useState<OptionReactSelect[]>([]);
  const [showDialogPosition, setShowDialogPosition] = useState(false);
  const [isShowDialogAddRepresentative, setIsShowDialogAddRepresentative] = useState<boolean>(false);

  const getPeopleDecision = async () => {
    try {
      const { data } = await searchNguoiDaiDienKy();
      if(data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setPeopleDecisions(data?.data?.content || [])
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }

  useEffect(() => {
    getPeopleDecision();
  }, [])

  const validationSchema = Yup.object().shape({
    employee: Yup.object()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    chucVuText: Yup.string().nullable(),
    donViCongTacText: Yup.string().nullable(),
    phongBanText: Yup.string().nullable(),
    thoiGianHieuLucTuNgay: Yup.date()
      .test('checkThoiGianHieuLucTuNgay', "", function (value) {
        const { thoiGianKiemNhiemTuNgay, thoiGianKiemNhiemDenNgay } = this.parent;
        if (value && thoiGianKiemNhiemTuNgay && value < new Date(thoiGianKiemNhiemTuNgay)) {
          return this.createError({
            path: 'thoiGianHieuLucTuNgay',
            message: lang('VALIDATE.DISMISSED.TIME_START_AFTER_TIME_CONCURRENTLY')
          });
        }
        if (value && thoiGianKiemNhiemDenNgay && value > new Date(thoiGianKiemNhiemDenNgay)) {
          return this.createError({
            path: 'thoiGianHieuLucTuNgay',
            message: lang('VALIDATE.DISMISSED.TIME_START_BEFORE_TIME_CONCURRENTLY')
          });
        }
        return true;
      })
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    thoiGianHieuLucDenNgay: Yup.date()
      .when('thoiGianHieuLucTuNgay', {
        is: (thoiGianHieuLucTuNgay: Date | null) => thoiGianHieuLucTuNgay,
        then: Yup.date()
          .min(Yup.ref("thoiGianHieuLucTuNgay"), lang("VALIDATION.MINDATE") + lang("GENERAL_EFFECTIVE_DATE_START")).nullable()
      })
      .test('checkThoiGianHieuLucDenNgay', "", function (value) {
        const { thoiGianKiemNhiemDenNgay } = this.parent;
        if (value && thoiGianKiemNhiemDenNgay && value > new Date(thoiGianKiemNhiemDenNgay)) {
          return this.createError({
            path: 'thoiGianHieuLucDenNgay',
            message: lang('VALIDATE.DISMISSED.TIME_END_BEFORE_TIME_CONCURRENTLY')
          });
        }
        return true;
      })
      .nullable(),
    soQuyetDinh: Yup.string().nullable().max(255, lang("VALIDATION.MAX255")),
    // Tạm ẩn đơn vị công tác miễn nhiệm
    // donViCongTacMoi: Yup.object()
    //   .required(lang("VALIDATION.REQUIRE"))
    //   .nullable(),
    phongBanMoi: Yup.object()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    chucVuMoi: Yup.object()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable()
  });

  useEffect(() => {
    if(dismissedInfo?.id) {
      let values = {
        ...dismissedInfo,
        // Tạm ẩn đơn vị công tác miễn nhiệm
        // donViCongTacMoi: {
        //   id: dismissedInfo?.donViCongTacMoiId,
        //   name: dismissedInfo?.donViCongTacMoiText
        // },
        phongBanMoi: {
          id: dismissedInfo?.phongBanMoiId,
          name: dismissedInfo?.phongBanMoiText
        },
        chucVuMoi: {
          id: dismissedInfo?.chucVuMoiId,
          value: dismissedInfo?.chucVuMoiText
        },
        nguoiKy: dismissedInfo?.nguoiKyId ? {
          id: dismissedInfo?.nguoiKyId,
          name: dismissedInfo?.nguoiKyText
        } : null
      }
      formik.setValues(values);
    }
    if(hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW)) {
      handleEmployeeChange(dismissedInfo?.employee);
    } else if(dismissedInfo?.employeeId) {
      getEmployeeInfo(dismissedInfo.employeeId);
    }
    setIsShowAcceptDismissBtn(!dismissedInfo?.xacNhanMienNhiem)
  }, [dismissedInfo]);

  const getEmployeeInfo = async (id: string) => {
    try {
      setPageLoading(true);
      const { data } = await getEmployeeProfileById(id);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        formik.setFieldValue(VARIABLE_STRING.EMPLOYEE, data?.data);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      console.error(error);
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const convertDataSubmit = (data: IDismissed) => {
    return {
      ...data,
      chucVuMoiId: data?.chucVuMoi?.id || "",
      chucVuMoiText: String(data?.chucVuMoi?.value) || "",
      chucVuId: data?.employee?.chucVuId,
      chucVuText: data?.employee?.chucVuText,
      donViCongTacText: data?.employee?.donViCongTacText,
      donViCongTacId: data?.employee?.donViCongTacId,
      // Tạm ẩn đơn vị công tác miễn nhiệm
      // donViCongTacMoiId: data?.donViCongTacMoi?.id || "",
      // donViCongTacMoiText: data?.donViCongTacMoi?.name,
      maNhanVien: data?.employee?.maNhanVien,
      phongBanMoiId: data?.phongBanMoi?.id || "",
      phongBanMoiText: data?.phongBanMoi?.name || "",
      phongBanId: data?.employee?.phongBanId,
      phongBanText: data?.employee?.phongBanText,
      ten: data?.employee?.name,
      xacNhanMienNhiem: isAcceptDismiss,
      nguoiKyId: data?.nguoiKy?.id || "",
      nguoiKyText: data?.nguoiKy?.name || ""
    }
  }

  const handleSubmit = async (values: IDismissed) => {
    const dataSubmit = convertDataSubmit(values);
    try {
      setPageLoading(true)
      const res = dismissedInfo?.id
        ? await editDismissed(dismissedInfo.id, dataSubmit)
        : await addNewDismissed(dataSubmit);
      if (res?.data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(
          dismissedInfo.id
            ? lang("TOAST.EDIT.SUCCESS")
            : lang("TOAST.CREATE.SUCCESS")
        );
        handleCloseAndSearch();
      } else {
        toast.warning(`${res?.data?.message}`);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false)
    }
  };

  const formik = useFormik({
    initialValues: INIT_DISMISSED,
    validationSchema,
    onSubmit: handleSubmit
  })

  const handleChangeSelect = (name: string, value: any) => {
    switch (name) {
      // Tạm ẩn đơn vị công tác miễn nhiệm
      // case VARIABLE_STRING.DON_VI_CONG_TAC_MOI:
      //   formik.setValues({
      //     ...formik.values,
      //     [name]: value,
      //     [VARIABLE_STRING.PHONG_BAN_MOI]: null
      //   })
      //   break;
      case VARIABLE_STRING.PHONG_BAN_MOI:
        formik.setValues({
          ...formik.values,
          [name]: value,
          [VARIABLE_STRING.CHUC_VU_MOI]: null,
          [VARIABLE_STRING.PHONG_BAN_MOI_KHAC]: value
        })
        break;
      case VARIABLE_STRING.CHUC_VU_MOI:
        formik.setValues({
          ...formik.values,
          [name]: value,
          [VARIABLE_STRING.CHUC_VU_MOI_KHAC]: null
        })
        break;
      case VARIABLE_STRING.THOI_GIAO_NHIEM_VU_TYPE:
        formik.setValues({
          ...formik.values,
          [name]: value,
          [VARIABLE_STRING.PHONG_BAN_MOI]: null,
          [VARIABLE_STRING.CHUC_VU_MOI]: null
        })
        break;
      default:
        formik.setFieldValue(name, value);
        break;
    }
  };
  
  const handleEmployeeChange = (employeeInfo: any) => {
    // Tạm ẩn đơn vị miễn nhiệm
    // let addInfoHasUserRole = hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW)
    //   ? {
    //       donViCongTacMoi: {
    //         id: employeeInfo.donViCongTacId || "",
    //         name: employeeInfo.donViCongTacText || ""
    //       },
    //       donViCongTacMoiId: employeeInfo.donViCongTacId || "",
    //       donViCongTacMoiText: employeeInfo.donViCongTacText || ""
    //     }
    //   : {};
    formik.setFieldValue("employee", employeeInfo);
    formik.setFieldValue("employeeId", employeeInfo?.id);
    formik.setFieldValue("thoiGiaoNhiemVuType", null);
  }

  const handleUploadFile = (data: IFile) => {
    formik.setFieldValue("fileId", data.id);
    formik.setFieldValue("fileName", data.name);
  }

  const handleAcceptDismiss = async () => {
    if(checkObject(await formik.validateForm())) {
      setIsAcceptDismiss(true);
    }
  }

  const handleChangeDate = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value);
    if (name === VARIABLE_STRING.THOI_GIAN_HIEU_LUC_TU_NGAY) {
      const effectiveDate = value ? new Date(value) : null;
      const currentDate = new Date();
      if (effectiveDate && effectiveDate <= currentDate) {
        setIsShowBtnSave(false);
      } else setIsShowBtnSave(true);
    }
  }

  const getTypeAssignmentByEmployee = async (employeeId: string) => {
    try {
      const { data } = await getTypeAssignmentConcurrently(employeeId);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        const listTypeAssignment = LIST_DISMISSED_TYPE.filter(type => data.data?.includes(type.code));
        if(listTypeAssignment.length === 1) {
          formik.setFieldValue(VARIABLE_STRING.THOI_GIAO_NHIEM_VU_TYPE, listTypeAssignment[0]);
        }
        setListTypeAssignmentConcurrently(listTypeAssignment);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }

  const handleConfirm = (position: IConcurrently) => {
    if(!position) {
      toast.warning(lang("GENERAL.PLEASE_SELECT_POSITION"));
      return;
    }
    setShowDialogPosition(false);
    formik.setValues({
      ...formik.values,
      kiemNhiemId: position?.id ?? "",
      thoiGianKiemNhiemTuNgay: position?.tuNgay,
      thoiGianKiemNhiemDenNgay: position?.denNgay,
      phongBanMoi: {
        id: position?.phongBanMoiId,
        name: position?.phongBanMoiText,
      },
      chucVuMoi: {
        id: position?.chucVuMoiId,
        value: position?.chucVuMoiText || position?.chucVuMoiKhac,
      },
    })
  }

  const handleOpenDialog = () => {
    if(!Boolean(formik.values?.thoiGiaoNhiemVuType)) {
      toast.warning(lang("GENERAL.PLEASE_SELECT_TYPE_ASSIGNMENT"));
      return;
    }
    setShowDialogPosition(true);
  }

  useEffect(() => {
    if(formik.values.employee?.id) {
      getTypeAssignmentByEmployee(formik.values.employee?.id);
    }
  }, [formik.values?.employee?.id]);

  const handleCloseAndSearchRepresentative = () => {
    getPeopleDecision();
    setIsShowDialogAddRepresentative(false);
  }

  return (
    <>
      <Form onSubmit={formik.handleSubmit} className="h-full">
        <div className="header-form-action">
          <GroupButton type="btn-back" handleClose={handleCloseDialog} />
          <div className="flex gap-4">
            {(!isView && dismissedInfo?.trangThai?.code === DISMISS.STATUS.UNFINISH && dismissedInfo?.id) && (
              <GroupButton
                type="btn-cancel"
                handleCloseUpdateDialog={handleCloseUpdateDialog}
              />
            )}
            {isView && dismissedInfo?.id && dismissedInfo?.trangThai?.code !== DISMISS.STATUS.FINISHED 
              && !dismissedInfo.xacNhanMienNhiem && hasAuthority(PERMISSIONS.MIEN_NHIEM, PERMISSION_ABILITY.UPDATE) && (
              <GroupButton
                type="btn-edit"
                handleSaveEdit={() => setIsView(false)}
              />
            )}
            {(!isView && isShowBtnSave && (dismissedInfo?.trangThai?.code === DISMISS.STATUS.UNFINISH || !dismissedInfo?.id || dismissedInfo?.trangThai?.code === DISMISS.STATUS.PROCESSING)) && (
              <GroupButton type="btn-save" handleSubmit={() => setIsAcceptDismiss(false)} />
            )}
            {(isShowAcceptDismissBtn) && (
              <GroupButton typeButton="submit" handleEvent={handleAcceptDismiss}>{lang("DISMISS.ACCEPT")}</GroupButton>
            )}
          </div>
        </div>
        <div className="form-content-scroll">
          <div className="sub-title-form">{dismissedInfo?.id ? lang("DISMISSED.EDIT") : lang("DISMISSED.ADD")}</div>
          <div className="sub-title pb-2 pt-0">{lang("DISMISSED_INFO_EMPLOYEE")}</div>
          <Row className="g-4">
            <Col sm={4} md={4} lg={4} xl={4} xxl={3}>
              <Autocomplete
                lable={lang("PROFILE.NAME")}
                isReadOnly={isView || hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW)}
                searchFunction={searchAllEmployee}
                searchObject={{
                  trangThaiLamViec: Object.values(STATUS_NV).filter(status => status !== STATUS_NV.THOI_VIEC).join(',')
                }}
                options={[]}
                isRequired={true}
                value={formik.values?.employee || ""}
                name="employee"
                onChange={(selectedOption) =>
                  handleEmployeeChange(selectedOption)
                }
                getOptionLabel={(option) => `${option?.maNhanVien} - ${option?.name}`}
                errors={formik.errors?.employee}
                touched={formik.touched?.employee}
              />
            </Col>
            <Col sm={3} md={3} lg={3} xl={2} xxl={3}>
              <TextValidator
                name="maNhanVien"
                lable={lang("PROFILE.CODE")}
                type="text"
                value={formik.values?.employee?.maNhanVien}
                readOnly={true}
              />
            </Col>
            <Col sm={5} md={5} lg={5} xl={6} xxl={3}>
              <TextValidator
                name="donViCongTacText"
                lable={lang("INPUT_DISMISSED_WORK_UNIT")}
                type="text"
                value={formik.values?.employee?.donViCongTacText}
                readOnly={true}
              />
            </Col>
            <Col sm={6} md={5} lg={5} xl={4} xxl={3}>
              <TextValidator
                name="phongBanText"
                lable={lang("INPUT_DISMISSED_UNIT_DEPARTMENT")}
                type="text"
                value={formik.values?.employee?.phongBanText}
                readOnly={true}
              />
            </Col>
            <Col sm={6} md={5} lg={5} xl={4} xxl={4}>
              <TextValidator
                name="chucVuText"
                lable={lang("INPUT_DISMISSED_JOB_POSITION")}
                type="text"
                value={formik.values?.employee?.chucVuText}
                readOnly={true}
              />
            </Col>
          </Row>
          <div className="sub-title pb-2">{lang("DISMISSED_INFO")}</div>
          <Row className="g-4">
            {listTypeAssignmentConcurrently.length > 0 && (
              <>
                <Col sm={6} md={listTypeAssignmentConcurrently.length > 0 ? 6 : 5} lg={4} xl={4} xxl={3} className="flex gap-3">
                  <div className="flex-1">
                    <Autocomplete
                      name="thoiGiaoNhiemVuType"
                      lable={lang("DISMISSED.TYPE")}
                      options={listTypeAssignmentConcurrently}
                      onChange={(value) => handleChangeSelect(VARIABLE_STRING.THOI_GIAO_NHIEM_VU_TYPE, value)}
                      value={formik.values?.thoiGiaoNhiemVuType || null}
                      errors={formik?.errors?.thoiGiaoNhiemVuType}
                      touched={formik?.touched?.thoiGiaoNhiemVuType}
                      isReadOnly={isView}
                    />
                  </div>
                  <GroupButton handleEvent={handleOpenDialog} disabled={isView} className="spaces mt-20">
                    {lang("BTN.CHOOSE_POSITION")}
                  </GroupButton>
                </Col>
              </>
            )}
            {/* Tạm ẩn đơn vị công tác miễn nhiệm */}
            {/* <Col sm={6} md={4} lg={4} xl={4} xxl={3}>
              <Autocomplete
                name="donViCongTacMoi"
                lable={lang("INPUT_DISMISSED_WORK_UNIT_NEW")}
                searchFunction={getAllDonVi}
                searchObject={SEARCH_OBJECT_MAX_SIZE}
                onChange={(value) =>
                  handleChangeSelect(
                    VARIABLE_STRING.DON_VI_CONG_TAC_MOI,
                    value
                  )
                }
                value={formik.values.donViCongTacMoi || null}
                isRequired
                options={[]}
                errors={formik?.errors?.donViCongTacMoi}
                touched={formik?.touched?.donViCongTacMoi}
                isReadOnly={hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW)}
              />
            </Col> */}
            {formik.values?.thoiGiaoNhiemVuType ? (
              <>
                <Col sm={6} md={6} lg={4} xl={4} xxl={3}>
                  <TextValidator
                    name={VARIABLE_STRING.PHONG_BAN_MOI}
                    lable={lang("INPUT_DISMISSED_UNIT_DEPARTMENT_NEW")}
                    type="text"
                    value={formik.values?.phongBanMoi?.name}
                    readOnly={true}
                    isRequired
                    errors={formik?.errors?.phongBanMoi}
                    touched={formik?.touched?.phongBanMoi}
                  />
                </Col>
                <Col sm={6} md={6} lg={4} xl={4} xxl={3}>
                  <TextValidator
                    name={VARIABLE_STRING.CHUC_VU_MOI}
                    lable={lang("INPUT_DISMISSED_JOB_POSITION_NEW")}
                    type="text"
                    value={formik.values?.chucVuMoi?.value}
                    readOnly={true}
                    isRequired
                    errors={formik?.errors?.chucVuMoi}
                    touched={formik?.touched?.chucVuMoi}
                  />
                </Col>
              </>) : (
              <>
                <Col sm={6} md={6} lg={4} xl={4} xxl={3}>
                  <Autocomplete
                    lable={lang("INPUT_DISMISSED_UNIT_DEPARTMENT_NEW")}
                    isReadOnly={isView}
                    searchFunction={searchPhongBanByDonVi}
                    searchObject={{ id: formik.values?.employee?.donViCongTacId }}
                    options={[]}
                    isRequired
                    value={formik.values?.phongBanMoi}
                    name={VARIABLE_STRING.PHONG_BAN_MOI}
                    onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.PHONG_BAN_MOI, selectedOption)}
                    urlData="data.data"
                    errors={formik.errors?.phongBanMoi}
                    touched={formik.touched?.phongBanMoi}
                    dependencies={[formik.values?.employee?.donViCongTacId]}
                  />
                </Col>
                <Col sm={6} md={6} lg={4} xl={4} xxl={3}>
                  <Autocomplete
                    lable={lang("INPUT_DISMISSED_JOB_POSITION_NEW")}
                    isReadOnly={isView}
                    searchFunction={searchAllSimpleValue}
                    searchObject={{ type: TYPE_CATEGORY.chucDanh }}
                    options={[]}
                    isRequired
                    value={formik.values?.chucVuMoi}
                    name={VARIABLE_STRING.CHUC_VU_MOI}
                    onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.CHUC_VU_MOI, selectedOption)}
                    errors={formik.errors?.chucVuMoi}
                    touched={formik.touched?.chucVuMoi}
                    getOptionLabel={(option) => option?.value}
                  />
                </Col>
              </>
            )}
            <Col 
              sm={8} 
              md={listTypeAssignmentConcurrently.length > 0 ? 6 : 5} 
              lg={4} 
              xl={listTypeAssignmentConcurrently.length > 0 ? 3 : 4} 
              xxl={listTypeAssignmentConcurrently.length > 0 ? 3 : 4}
            >
              <RangeDatePicker
                isRequired
                label={lang("INPUT_DISMISSED_TIME_EFFECT")}
                startDateName="thoiGianHieuLucTuNgay"
                endDateName="thoiGianHieuLucDenNgay"
                isView={isView}
                handleChange={handleChangeDate}
                value={formik.values}
                setFieldValue={formik.setFieldValue}
                touch={formik.touched}
                errors={formik.errors}
              />
            </Col>
            <Col sm={6} md={3} lg={2} xl={2} xxl={2}>
              <TextValidator
                name="ngayQuyetDinh"
                lable={lang("INPUT_APPOINTMENT_CONFIRM_DATE")}
                type="date"
                value={formik.values?.ngayQuyetDinh}
                readOnly={isView}
                onChange={formik.handleChange}
                onBlur={() =>
                  handleBlurDate(
                    formik.setFieldValue,
                    formik.values?.ngayQuyetDinh,
                    "ngayQuyetDinh"
                  )
                }
                errors={formik?.errors?.ngayQuyetDinh}
                touched={formik?.touched?.ngayQuyetDinh}
              />
            </Col>
            <Col sm={4} md={listTypeAssignmentConcurrently.length > 0 ? 3 : 4} lg={3} xl={2} xxl={listTypeAssignmentConcurrently.length > 0 ? 2 : 3}>
              <TextValidator
                name="soQuyetDinh"
                lable={lang("INPUT_APPOINTMENT_NUMBER")}
                type="text"
                value={formik.values?.soQuyetDinh}
                readOnly={isView}
                onChange={formik.handleChange}
                errors={formik?.errors?.soQuyetDinh}
                touched={formik?.touched?.soQuyetDinh}
              />
            </Col>
            <Col sm={6} md={3} lg={listTypeAssignmentConcurrently.length > 0 ? 3 : 4} xl={3} xxl={3}>
              <UploadFile
                isReadOnly={isView}
                label="CONTRACT.ATTACHMENTS"
                setValue={handleUploadFile}
                fileValue={{
                  id: formik.values.fileId || "",
                  name: formik.values.fileName || ""
                }}
              />
            </Col>
            <Col sm={6} md={3} lg={3} xl={2} xxl={listTypeAssignmentConcurrently.length > 0 ? 2 : 3}>
              <Autocomplete
                lable={lang("GENERAL.STATUS")}
                isReadOnly={isView}
                options={COMPLETED_STATUS}
                value={formik.values?.trangThai || null}
                name="trangThai"
                onChange={(selectedOption) =>
                  handleChangeSelect("trangThai", selectedOption)
                }
                errors={formik.errors?.trangThai}
                touched={formik.touched?.trangThai}
              />
            </Col>
            <Col sm={4} md={3} lg={3} xl={3} xxl={3}>
              <Autocomplete
                lable={lang("APPRECIATIVE.NGUOIQUYETDINHTEXT")}
                isReadOnly={isView}
                options={peopleDecisions}
                value={formik.values?.nguoiKy || null}
                name="nguoiKy"
                isAddNew
                onAddNew={() => setIsShowDialogAddRepresentative(true)}
                onChange={(selectedOption) => handleChangeSelect("nguoiKy", selectedOption)}
                errors={formik.errors?.nguoiKy}
                touched={formik.touched?.nguoiKy}
              />
            </Col>
            <Col xs={12}>
              <TextValidator
                className="h-auto-input spaces"
                lable={lang("ALLOWANCE.NOTE")}
                name="ghiChu"
                value={formik.values.ghiChu}
                type="text"
                readOnly={isView}
                rows={2}
                onChange={formik.handleChange}
                as={TYPE.TEXTAREA}
              />
            </Col>
          </Row>
        </div>
      </Form>
      {showDialogPosition && (
        <DialogPositionConcurrently
          show={showDialogPosition}
          handleClose={() => setShowDialogPosition(false)} 
          handleConfirm={handleConfirm}
          employeeId={formik.values.employeeId}
          phanCongType={Number(formik.values.thoiGiaoNhiemVuType?.code) || null}
        />
      )}
      {isShowDialogAddRepresentative && (
        <AddNewRepresentative
          handleClose={() => setIsShowDialogAddRepresentative(false)}
          handleCloseAndSearch={handleCloseAndSearchRepresentative}
        />
      )}
    </>
  );
};
export { DismissedForm };


import { FC, useContext, useEffect, useRef, useState } from 'react'
import useMultiLanguage from '../../../../hook/useMultiLanguage';
import * as Yup from "yup";
import AppContext from '../../../../AppContext';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';
import { GroupButton } from '../../../components/GroupButton';
import TextValidator from '../../../components/input/text-validator';
import { INIT_REGISTER_OVERTIME } from '../../constants/registerOvertimeConsts';
import Autocomplete from '../../../components/input/autocomplete';
import { RESPONSE_STATUS_CODE, VARIABLE_STRING } from '../../../constants/moduleConsts';
import { searchAllEmployee, searchListWorkUnit, searchNguoiDaiDienKy, searchPhongBanByDonVi } from '../../../services/services';
import DanhSachNhanVien from './DanhSachNhanVien';
import { CODE_STATUS, LIST_STATUS } from '../../constants/managerRequestConsts';
import { convertDataRegisterOvertimeDto, convertDataRegisterOvertimeUI } from '../../utils/functionUtils';
import { ConfirmDialog } from '../../../components/dialogs';
import { RefuseDialog } from '../../../components/dialogs/RefuseDialog';
import { findList, hasAuthority } from '../../../utils/functionUtils';
import { PERMISSION_ABILITY, PERMISSIONS } from '../../../../constants';
import { addManagerRequest, approveManagerRequest, updateManagerRequest } from '../../services/managerRequestServices';
import { IRegisterOvertime } from '../../models/registerOvertimeModes';

const RegisterOvertimeForm: FC<any> = (props) => {
    const {
        handleClose,
        item,
        handleCloseUpdateDialog,
        isView,
        setIsView,
        handleCloseAndSearch,
        isApproveOrReject
    } = props;
    const { lang } = useMultiLanguage()
    const { setPageLoading } = useContext(AppContext);

    const [shouldOpenSendDialog, setShouldOpenSendDialog] = useState<boolean>(false);
    const [shouldOpenRejectDialog, setShouldOpenRejectDialog] = useState<boolean>(false);
    const [shouldOpenApproveDialog, setShouldOpenApproveDialog] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");

    const IRefDanhSachNhanVien = useRef<any>(null);

    const validationSchema = Yup.object().shape({
        employee: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
        donViCongTac: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
        phongBan: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
        ngayNop: Yup.date().required(lang("VALIDATION.REQUIRE")).nullable(),
        trangThai: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    });

    useEffect(() => {
        if (item?.id) {
            formik.setValues(convertDataRegisterOvertimeUI(item))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item]);

    const handleSubmit = async (values: IRegisterOvertime) => {
        try {
            let employeeInfos = IRefDanhSachNhanVien.current?.getDataNhanVien();
            if(!employeeInfos.length) {
                toast.warning("Vui lòng chọn nhân viên");
                return;
            }
            employeeInfos.forEach((item: any) => {
                item.thoiGianBatDau = item?.thoiGianBatDau ?? null
                item.thoiGianKetThuc = item?.thoiGianKetThuc ?? null
            });
            let _values = {
                ...convertDataRegisterOvertimeDto(values),
                employeeInfos: employeeInfos
            }
            const { data } = _values?.id
                ? await updateManagerRequest(values?.id, _values)
                : await addManagerRequest(_values);

            if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
                toast.success(
                    values?.id
                        ? lang("TOAST.EDIT.SUCCESS")
                        : lang("TOAST.CREATE.SUCCESS")
                );
                handleCloseAndSearch();
                return;
            }
            toast.warning(data?.message);
        } catch (err) {
            toast.error(lang("GENERAL.ERROR"));
        } finally {
            setPageLoading(false)
        }
    };

    const formik = useFormik({
        initialValues: INIT_REGISTER_OVERTIME,
        validationSchema,
        onSubmit: handleSubmit
    });

    const handleChangeSelect = (name: string, value: any) => {
        if(name === VARIABLE_STRING.EMPLOYEE) {
            let _values = {
                [name]: value,
                donViCongTac: value?.donViCongTacId ? {
                    id: value?.donViCongTacId,
                    name: value?.donViCongTacText
                } : null,
                phongBan: value?.phongBanId ? {
                    id: value?.phongBanId,
                    name: value?.phongBanText
                } : null
            }

            formik.setValues({ ...formik.values, ..._values });
            return;
        }
        formik.setFieldValue(name, value);
    };

    const handleConfirm = (lyDo: string = "") => {
        shouldOpenApproveDialog && handleUpdateStatus(CODE_STATUS.DA_DUYET, "Duyệt đơn thành công")
        shouldOpenSendDialog && handleUpdateStatus(CODE_STATUS.CHO_DUYET, "Gửi đơn Thành công")
        shouldOpenRejectDialog && handleUpdateStatus(CODE_STATUS.TU_CHOI, "Đã từ chối đơn", lyDo)
    }

    const handleCloseForm = () => {
        setShouldOpenSendDialog(false)
        setShouldOpenApproveDialog(false)
        setShouldOpenRejectDialog(false)
    }

    const handleOpenSendDialog = () => {
        setMessage("Bạn có chắc chắn muốn gửi không?")
        setShouldOpenSendDialog(true)
    }

    const handleOpenApproveDialog = () => {
        setMessage("Bạn có chắc chắn muốn duyệt đơn này?")
        setShouldOpenApproveDialog(true)
    }

    const handleOpenRejectDialog = () => {
        setShouldOpenRejectDialog(true)
    }

    const handleUpdateStatus = async (status: string | number, messageSuccess: string = "", lyDo: string = "") => {
        try {
            setPageLoading(true);
            let _values = {
                trangThai: findList(LIST_STATUS, status),
                ...status === CODE_STATUS.TU_CHOI && { lyDo }
            }

            const { data } = await approveManagerRequest(formik.values?.id ?? "", _values, {
                loaiDon: formik.values?.loaiDon?.type
            });

            if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
                toast.success(messageSuccess);
                handleCloseAndSearch();
                handleCloseForm();
                return;
            }
            toast.warn(data?.message);
        } catch {
            toast.error(lang("TOAST.GET.ERROR"));
        } finally {
            setPageLoading(false);
        }
    }

    return (
        <div className="managerRequest h-full">
            <Form onSubmit={formik.handleSubmit}>
                <div className="header-form-action">
                    <GroupButton type="btn-back" handleClose={handleClose} />
                    <div className="flex gap-4">
                        {
                            formik.values?.trangThai?.code === CODE_STATUS.TAO_MOI && !isApproveOrReject && item?.id &&
                            (isView
                                ?
                                    <>
                                        {hasAuthority(PERMISSIONS.DON_DANG_KI_LAM_THEM, PERMISSION_ABILITY.UPDATE)
                                            && <GroupButton type="btn-edit" handleSaveEdit={() => setIsView(false)} />
                                        }
                                        <GroupButton handleEvent={handleOpenSendDialog}>{lang("AUTH.GENERAL.SUBMIT_BUTTON")}</GroupButton>
                                    </>
                                : <GroupButton
                                    type="btn-cancel"
                                    handleCloseUpdateDialog={handleCloseUpdateDialog}
                                />
                            )
                        }

                        {
                            (!item?.id || (item?.id && !isView)) &&
                            < GroupButton type="btn-save" />
                        }

                        {item?.id && isView && isApproveOrReject && hasAuthority(PERMISSIONS.DON_DANG_KI_LAM_THEM, PERMISSION_ABILITY.APPROVE) && (
                            <>
                                <GroupButton handleEvent={handleOpenApproveDialog}>{lang("BTN.APPROVE")}</GroupButton>
                                <GroupButton handleEvent={handleOpenRejectDialog}>{lang("DECLARE_ASSETS.STATUS.REJECT")}</GroupButton>
                            </>
                        )}
                    </div>
                </div>
                <div className="form-content-scroll">
                    <div className="sub-title-form">{item?.id ? lang("MANAGENT_REQUEST.REGISTER_OVERTIME.EDIT") : lang("MANAGENT_REQUEST.REGISTER_OVERTIME.ADD")}</div>
                    <Row className="relative spaces z-index-4">
                        <Col sm={4} md={4} lg={4} xl={4} className="spaces pt-6">
                            <Autocomplete
                                isRequired
                                isReadOnly={isView || isApproveOrReject}
                                lable={lang("APPLICANT")}
                                options={[]}
                                onChange={(value) => handleChangeSelect(VARIABLE_STRING.EMPLOYEE, value)}
                                name="employee"
                                value={formik.values?.employee || null}
                                searchFunction={searchAllEmployee}
                                errors={formik.errors.employee}
                                touched={formik.touched.employee}
                                getOptionLabel={(option) => `${option?.maNhanVien} - ${option?.name}`}
                            />
                        </Col>
                        <Col sm={4} md={4} lg={4} xl={4} className="spaces pt-6">
                            <Autocomplete
                                isRequired
                                lable={lang("GENERAL.CURRENT_ORGANIZATION")}
                                isReadOnly={isView || isApproveOrReject}
                                options={[]}
                                value={formik.values?.donViCongTac || null}
                                name="donViCongTac"
                                onChange={(selectedOption) => handleChangeSelect("donViCongTac", selectedOption)}
                                searchFunction={searchListWorkUnit}
                                touched={formik.touched?.donViCongTac}
                                errors={formik.errors?.donViCongTac}
                            />
                        </Col>
                        <Col sm={4} md={4} lg={4} xl={4} className="spaces pt-6">
                            <Autocomplete
                                isRequired
                                lable={lang("INPUT.DEPARTMENTS")}
                                isReadOnly={isView || isApproveOrReject}
                                options={[]}
                                value={formik.values?.phongBan || null}
                                name="phongBan"
                                onChange={(selectedOption) => handleChangeSelect("phongBan", selectedOption)}
                                urlData="data.data"
                                searchFunction={searchPhongBanByDonVi}
                                searchObject={{ id: formik.values?.donViCongTac?.id }}
                                touched={formik.touched?.phongBan}
                                errors={formik.errors?.phongBan}
                                dependencies={[formik.values?.donViCongTac]}
                            />
                        </Col>
                        <Col sm={3} md={3} lg={3} xl={3} className="spaces pt-6">
                            <TextValidator
                                isRequired
                                name="ngayNop"
                                lable={"Ngày nộp đơn"}
                                type="date"
                                value={formik.values?.ngayNop}
                                onChange={formik.handleChange}
                                readOnly={isView}
                                errors={formik.errors?.ngayNop}
                                touched={formik.touched?.ngayNop}
                            />
                        </Col>
                        <Col sm={3} md={3} lg={3} xl={3} className="spaces pt-6">
                            <Autocomplete
                                isReadOnly={true}
                                lable={lang("MANAGENT_REQUEST.ATTENDANCE_WATCH.DEAN")}
                                options={[]}
                                value={formik.values?.truongKhoa || null}
                                name="truongKhoa"
                                searchFunction={searchNguoiDaiDienKy}
                                onChange={(value) => handleChangeSelect("truongKhoa", value)}
                                errors={formik.errors?.truongKhoa}
                                touched={formik.touched?.truongKhoa}
                            />
                        </Col>
                        <Col sm={3} md={3} lg={3} xl={3} className="spaces pt-6">
                            <TextValidator
                                name="ngayDuyet"
                                lable={lang("DATE.APPROVE")}
                                type="date"
                                value={formik.values?.ngayDuyet}
                                readOnly={true}
                                onChange={formik.handleChange}
                                errors={formik.errors?.ngayDuyet}
                                touched={formik.touched?.ngayDuyet}
                            />
                        </Col>
                        <Col sm={3} md={3} lg={3} xl={3} className="spaces pt-6">
                            <Autocomplete
                                isRequired
                                lable={"Trạng thái"}
                                isReadOnly={formik.values?.id || isView || isApproveOrReject}
                                options={LIST_STATUS}
                                value={formik.values?.trangThai || null}
                                name="trangThai"
                                onChange={(value) => handleChangeSelect("trangThai", value)}
                                errors={formik.errors?.trangThai}
                                touched={formik.touched?.trangThai}
                            />
                        </Col>
                        <Col xs={12} className="spaces pt-6">
                            <TextValidator
                                name="ghiChu"
                                lable={"Ghi chú"}
                                type="text"
                                value={formik.values?.ghiChu}
                                readOnly={isView || isApproveOrReject}
                                errors={formik.errors?.ghiChu}
                                touched={formik.touched?.ghiChu}
                                as="textarea"
                                rows="3"
                                onChange={formik.handleChange}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <DanhSachNhanVien
                            employeeInfos={formik.values?.employeeInfos}
                            values={formik.values}
                            ref={IRefDanhSachNhanVien}
                        />
                    </Row>

                    {
                        (shouldOpenSendDialog || shouldOpenApproveDialog) &&
                        <ConfirmDialog
                            show={true}
                            title={lang("DIALOG.CONFIRM")}
                            message={message}
                            cancel={lang("BTN.CLOSE")}
                            onCancelClick={handleCloseForm}
                            yes={lang("BTN.CONFIRM")}
                            onYesClick={handleConfirm}
                        />
                    }

                    {
                        shouldOpenRejectDialog &&
                        <RefuseDialog
                            show={shouldOpenRejectDialog}
                            field='lyDo'
                            onSubmit={handleConfirm}
                            onCloseClick={handleCloseForm}
                        />
                    }
                </div>
            </Form>
        </div>
    );
};

export default RegisterOvertimeForm;

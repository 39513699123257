import { FC, useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { ISoftwareUsage } from "../models/managerModels";
import { EXPORT_FILE_NAME, RESPONSE_STATUS_CODE } from "../../constants/moduleConsts";
import { GroupButton } from "../../components/GroupButton";
import TableGrouping, { IColumnsGrouping } from "../../components/table-grouping/TableGrouping";
import { KTSVG } from "../../../../_metronic/helpers";
import { getSoftwareUsage } from "../services/managerServices";
import { exportToFile, hasAuthority } from "../../utils/functionUtils";
import { exportSoftwareUsage } from "../../services/exportFileServices";
import AppContext from "../../../AppContext";
import { TYPE_SOFTWARE_USAGE } from "../constants/managerConsts";
import { checkWarningSoftwareUsage } from "../utils/managerUtils";
import { PERMISSION_ABILITY, PERMISSIONS } from "../../../constants";
import { localStorageItem } from "../../utils/localstorage";
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";

const SoftwareUsage: FC = () => {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);
  const [data, setData] = useState<ISoftwareUsage[]>([] as ISoftwareUsage[]);
  const [searchObject] = useState({
    departmentId: hasAuthority(PERMISSIONS.DEPARTMENT, PERMISSION_ABILITY.VIEW) 
      ? localStorageItem.get(KEY_LOCALSTORAGE.EMPLOYEE)?.phongBanId 
      : null
  })

  const updatePageData = useCallback(async (searchObject: any) => {
    try {
      const { data } = await getSoftwareUsage(searchObject);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setData(data?.data || []);
      } else {
        toast.warning(data?.message);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }, [lang]);

  useEffect(() => {
    updatePageData(searchObject);
  }, [searchObject, updatePageData]);


  const handleExportExcel = () => {
    exportToFile({
      exportAPI: () => exportSoftwareUsage({ type: TYPE_SOFTWARE_USAGE, ...searchObject }),
      fileName: EXPORT_FILE_NAME.SOFTWARE_USAGE,
      setPageLoading
    })
  }

  const columns: IColumnsGrouping[] = [
    {
      name: "STT",
      field: "",
      render: (_row, _index, stt) => <span>{stt}</span>,
      isSticky: true,
    },
    {
      name: "Khoa/Phòng",
      field: "employeeName",
      headerStyle: {
        width: "200px",
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
      isSticky: true
    },
    {
      name: "Mã ngạch/Chức danh",
      field: "jobTitleText",
      headerStyle: {
        width: "250px",
        minWidth: "250px"
      },
      cellStyle: {
        textAlign: "left"
      },
      isSticky: true
    },
    {
      name: "Tiến độ",
      field: "",
      child: [
        {
          name: "Thông tin cơ bản",
          field: "personalInfoProcess",
          headerStyle: {
            minWidth: "120px"
          },
          render: (row) => 
            <span className={`${checkWarningSoftwareUsage(row?.personalInfoProcessValue)} status`}>
              {row?.personalInfoProcess}
            </span>
        },
        {
          name: "Thông tin liên hệ",
          field: "contactInfoProcess",
          headerStyle: {
            minWidth: "120px"
          },
          render: (row) => 
            <span className={`${checkWarningSoftwareUsage(row?.contactInfoProcessValue)} status`}>
              {row?.contactInfoProcess}
            </span>
        },
        {
          name: "Thông tin công việc",
          field: "workInfoProcess",
          headerStyle: {
            minWidth: "140px"
          },
          render: (row) => 
            <span className={`${checkWarningSoftwareUsage(row?.workInfoProcessValue)} status`}>
              {row?.workInfoProcess}
            </span>
        },
        {
          name: "Trình độ đào tạo",
          field: "trainingInfoProcess",
          headerStyle: {
            minWidth: "120px"
          },
          render: (row) => 
            <span className={`${checkWarningSoftwareUsage(row?.trainingInfoProcessValue)} status`}>
              {row?.trainingInfoProcess}
            </span>
        },
        {
          name: "Thông tin lương",
          field: "salaryInfoProcess",
          headerStyle: {
            minWidth: "120px"
          },
          render: (row) => 
            <span className={`${checkWarningSoftwareUsage(row?.salaryInfoProcessValue)} status`}>
              {row?.salaryInfoProcess}
            </span>
        },
        {
          name: "Thông tin hợp đồng",
          field: "contractInfoProcess",
          headerStyle: {
            minWidth: "140px"
          },
          render: (row) => 
            <span className={`${checkWarningSoftwareUsage(row?.contractInfoProcessValue)} status`}>
              {row?.contractInfoProcess}
            </span>
        },
      ]
    }
  ];

  return (
    <div className="software-usage-tab">
      <div className="flex flex-space-between spaces my-10">
        <span className="spaces fs-18 fw-600">{lang("SOFTWARE_USAGE")}</span>
        <GroupButton outline handleEvent={handleExportExcel}>
          <KTSVG path="/media/icons/export-excel.svg" />{" "}
          {lang("BTN.EXPORT")}
        </GroupButton>
      </div>
      <div className="user-list table-custom">
        <TableGrouping
          id="software-usage"
          columns={columns || []}
          data={data || []}
          className="table-custom"
          prefixDataField="data"
          prefixTitleField="phongBanText"
        />
      </div>
    </div>
  );
};
export { SoftwareUsage };

import TextValidator from "../../../components/input/text-validator";
import { Col, Form, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { INIT_ABROAD_ACCOUNT } from "../../constants/initValues";
import { IFormProps, IAbroadAccount } from "../../models/declareAssetsModels";
import { useEffect } from "react";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import { REGEX } from "../../../constants/moduleConsts";

export function AbroadAccountForm(props: IFormProps<IAbroadAccount>) {
  const { handleSubmit, isView, btnSubmitRef, dataEdit } = props;
  const { lang } = useMultiLanguage();

  useEffect(() => {
    if (dataEdit?.id) {
      formik.setValues(dataEdit);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataEdit]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    soTaiKhoan: Yup.string()
      .required(lang("VALIDATION.REQUIRE"))
      .matches(REGEX.NUMBER, lang("VALIDATION.NUMBER_ONLY"))
      .nullable(),
    tenNganHang: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable()
  });

  const formik = useFormik({
    initialValues: INIT_ABROAD_ACCOUNT,
    onSubmit: handleSubmit,
    validationSchema
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row>
        <Col xs={6} className="spaces mt-10">
          <TextValidator
            isRequired
            lable={lang("DECLARE_ASSETS.BANK_USER")}
            name="name"
            readOnly={isView}
            type="text"
            value={formik.values?.name}
            onChange={formik.handleChange}
            errors={formik.errors?.name}
            touched={formik.touched?.name}
          />
        </Col>
        <Col xs={6} className="spaces mt-10">
          <TextValidator
            isRequired
            lable={lang("DECLARE_ASSETS.BANK_NUMBER")}
            name="soTaiKhoan"
            readOnly={isView}
            type="text"
            value={formik.values?.soTaiKhoan}
            onChange={formik.handleChange}
            errors={formik.errors?.soTaiKhoan}
            touched={formik.touched?.soTaiKhoan}
          />
        </Col>
        <Col xs={12} className="spaces mt-10">
          <TextValidator
            isRequired
            lable={lang("DECLARE_ASSETS.BANK_NAME")}
            name="tenNganHang"
            readOnly={isView}
            type="text"
            value={formik.values?.tenNganHang}
            onChange={formik.handleChange}
            errors={formik.errors?.tenNganHang}
            touched={formik.touched?.tenNganHang}
          />
        </Col>
      </Row>
      <button ref={btnSubmitRef} type="submit" className="hidden"></button>
    </Form>
  );
}


import { useContext, useEffect, useState } from "react";
import { REF_TAB } from "../constants/profileConsts";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import TableCustom, { columnNamesType } from "../../components/table-custom/TableCustom";
import { IWelfare } from "../../welfare-list/models/welfareModels";
import AppContext from "../../../AppContext";
import { RESPONSE_STATUS_CODE } from "../../constants/moduleConsts";
import { toast } from "react-toastify";
import { searchWelfare } from "../../welfare-list/services/welfareServices";
import WelfareDialog from "./dialogs/WelfareDialog";

interface IProps {
  isView: boolean;
  activeTab: string;
  employeeProfiles: any;
}

function WelfareInformation(props: IProps) {
  const { activeTab, employeeProfiles } = props;
  const { lang } = useMultiLanguage();

  const [listWelfare, setListWelfare] = useState<IWelfare[]>([]);
  const [welfareItem, setWelfareItem] = useState<IWelfare>({} as IWelfare);
  const [shoulOpenDialog, setShouldOpenDialog] = useState<boolean>(false);
  const { setPageLoading } = useContext(AppContext);

  useEffect(() => {
    if (employeeProfiles?.id && activeTab === REF_TAB.PHUC_LOI) {
      updatePageData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeProfiles, activeTab]);

  const updatePageData = async () => {
    if (!employeeProfiles?.id) return;
    try {
      setPageLoading(true);
      const { data } = await searchWelfare({ employeeId: employeeProfiles?.id || "" });
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListWelfare(data?.data?.content || []);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleOpenInfoDialog = (row: any) => {
    setWelfareItem(row);
    setShouldOpenDialog(true);
  };

  const columns: columnNamesType[] = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("GENERAL.STATUS"),
      field: "trangThai",
      headerStyle: {
        minWidth: "120px"
      },
      render: (row: any) => <span>{row?.trangThai?.name}</span>
    },
    {
      name: lang("WELFARE.NAME"),
      field: "tenPhucLoi",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
      render: (row: any) => <span className="color-steel-blue fw-600">{row?.tenPhucLoi}</span>
    },
    {
      name: lang("WELFARE.GROUP"),
      field: "nhomPhucLoi",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        minWidth: "200px",
        textAlign: "left"
      },
      render: (row: any) => <span>{row?.nhomPhucLoi?.name}</span>
    },
    {
      name: lang("WELFARE.APPLY.UNIT"),
      field: "donViApDungText",
      headerStyle: {
        minWidth: "350px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("WELFARE.ORGANIZATION.PLACE"),
      field: "diaDiemToChuc",
      headerStyle: {
        minWidth: "400px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("WELFARE.ORGANIZATION.PURPOSE"),
      field: "mucDichToChuc",
      headerStyle: {
        minWidth: "300px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("WELFARE.IMPLEMENT.FORM"),
      field: "hinhThucThucHien",
      headerStyle: {
        minWidth: "250px"
      },
      cellStyle: {
        textAlign: "left"
      },
      render: (row: any) => <span>{row?.hinhThucThucHien?.name}</span>
    },
    {
      name: lang("WELFARE.EXPECTED.PEOPLE"),
      field: "soNguoiDuKien",
      headerStyle: {
        minWidth: "150px"
      },
      cellStyle: {
        textAlign: "left"
      }
    }
  ];

  return (
    <div className="form-info">
      <div className="block-content">
        <TableCustom
          title={lang("GENERAL.WELFARE.LIST")}
          isActionTableTab
          data={listWelfare}
          columns={columns}
          buttonAdd={false}
          buttonExportExcel={false}
          notDelete={true}
          fixedColumnsCount={2}
          handleDoubleClick={handleOpenInfoDialog}
          noPagination
          noToolbar
        />
      </div>
      {shoulOpenDialog && (
        <WelfareDialog
          handleClose={() => setShouldOpenDialog(false)}
          open={shoulOpenDialog}
          dataWelfare={welfareItem}
        />
      )}
    </div>
  );
}

export default WelfareInformation;

import axios from "axios";
import { paramsConfig } from "../../utils/paramsUtils";
import { IObject } from "../../models/models";
import { IDeclareAssets } from "../models/declareAssetsModels";

const API_URL = process.env.REACT_APP_API_URL;

export const searchDeclareAssets = (searchObj: IObject) => {
  let url = API_URL + "/ke-khai-tai-san/page";
  return axios.get(url, paramsConfig(searchObj));
};

export const deleteDeclareAssets = (ids: string) => {
  let url = API_URL + `/ke-khai-tai-san/ids?ids=${ids}`;
  return axios.delete(url);
};

export const getDeclareAssetById = (id: string) => {
  let url = API_URL + `/ke-khai-tai-san/${id}`;
  return axios.get(url);
};

export const addDeclareAssets = (data: IDeclareAssets) => {
  let url = API_URL + `/ke-khai-tai-san`;
  return axios.post(url, data);
};

export const updateDeclareAssets = (id: string, data: IDeclareAssets) => {
  let url = API_URL + `/ke-khai-tai-san/${id}`;
  return axios.put(url, data);
};

export const getDeclareAssetsByEmployeeId = (employeeId: string) => {
  let url = API_URL + `/ke-khai-tai-san/employee/${employeeId}`;
  return axios.get(url);
};

import { Formik } from "formik";
import { FC } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import * as Yup from "yup";
import { IUnit } from "../../models/managerModels";
import TextField from "../../../components/input/TextField";
import { toast } from "react-toastify";
import { addNewUnit, updateUnit } from "../../services/managerServices";
import { RESPONSE_STATUS_CODE } from "../../../constants/moduleConsts";
import { GroupButton } from "../../../components/GroupButton";

interface IProps {
  handleCloseDialog: () => void;
  unitInfo: IUnit;
  handleCloseAndSearch: () => void;
}
const UnitDialog: FC<IProps> = (props) => {
  const { handleCloseDialog, unitInfo, handleCloseAndSearch } = props;
  const intl = useIntl();

  const issueValidationSchema = Yup.object().shape({
    name: Yup.string()
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRE" }))
      .nullable(),
  });
    const handleSubmit = async (values: IUnit) => {
    try {
      const res = unitInfo.id ? await updateUnit(unitInfo.id, values) : await addNewUnit(values);
      if (res?.data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(
          unitInfo.id
            ? intl.formatMessage({ id: "TOAST.EDIT.SUCCESS" })
            : intl.formatMessage({ id: "TOAST.CREATE.SUCCESS" })
        );
        handleCloseAndSearch();
      } else {
        toast.warning(`${res?.data?.message}`);
      }
    } catch (err) {
      toast.error(intl.formatMessage({ id: "GENERAL.ERROR" }));
    }
  }
  return (
    <>
      <Formik
        initialValues={unitInfo}
        validationSchema={issueValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
          <Modal show={true} onHide={handleCloseDialog} backdrop="static" size="lg" centered>
            <Form onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>
                  {intl.formatMessage({
                    id: !unitInfo?.id ? "UNIT.ADD" : "UNIT.EDIT",
                  })}
                  <i
                    className={`bi ${
                      unitInfo?.id ? "bi-pencil-square" : " bi-plus-circle"
                    } text-primary ps-2 fs-5`}
                  ></i>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row className="px-6">
                  <Col xs={6}>
                    <TextField
                      name="name"
                      label="UNIT.NAME"
                      type={"text"}
                      handleChange={handleChange}
                      value={values.name}
                      required
                      errors={errors.name}
                    />
                  </Col>
                  <Col xs={6}>
                    <TextField
                      name="code"
                      label="UNIT.CODE"
                      type={"text"}
                      handleChange={handleChange}
                      value={values.code}
                    />
                  </Col>
                </Row>
                <Row className="px-6 pt-6">
                  <Col xs={6}>
                    <TextField
                      name="orgType"
                      label="UNIT.TYPE"
                      type={"text"}
                      handleChange={handleChange}
                      value={values.orgType}
                    />
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-center">
                <GroupButton type="btn-cancel" handleCloseUpdateDialog={handleCloseDialog} />
                <GroupButton type="btn-save" />
              </Modal.Footer>
            </Form>
          </Modal>
        )}
      </Formik>
    </>
  );
};
export { UnitDialog };

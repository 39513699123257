import React from 'react'
import TextValidator from './text-validator'
import { KEY, TYPE } from '../../constants/moduleConsts';
import { TextValidatorProps } from './text-validator/models';

interface ITextAreaProps {
    onChange: (value: string, name: string) => void;
    bulletPoints?: string;
}

type TextAreaProps = Omit<TextValidatorProps, 'onChange'> & ITextAreaProps;

function TextArea(props: TextAreaProps) {
    const { onChange, bulletPoints = "-", ...newProps } = props;
    const handleKeyDown = (e: any) => {
        if (e.key === KEY.ENTER) {
            e.preventDefault();
            const cursorPosition = e.target.selectionStart;
            const newText =
                cursorPosition === 0
                    ? `${bulletPoints} ${props?.value?.substring(cursorPosition)}`
                    : `${props?.value?.substring(0, cursorPosition)}\n${bulletPoints} ${props?.value?.substring(cursorPosition)}`;
            onChange(newText, e.target.name)
        }
    };

    const handleChange = (event: any) => {
        const { value: newValue, name } = event.target;
        if (newValue) {
            if (!props?.value?.startsWith(bulletPoints)) {
                onChange(`${bulletPoints} ` + newValue, name)
            } else {
                onChange(newValue, name)
            }
        } else {
            onChange(newValue, name)
        }
    };

    return (
        <TextValidator
            {...newProps}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            as={TYPE.TEXTAREA}
        />
    )
}

export default TextArea
import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { IParamsSearchShiftComprehension, IPropsShiftComprehensionTab, IRowDataSchedule } from "../../models/shiftDivisionModels";
import { Col, Form, Row } from "react-bootstrap";
import { toast } from 'react-toastify';
import { useFormik } from "formik";
import * as Yup from "yup";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import AppContext from "../../../../AppContext";
import { searchShiftDivision } from "../../services/shiftDivisionServices";
import { FORMAT_DATE, RESPONSE_STATUS_CODE } from "../../../constants/moduleConsts";
import { INIT_PARAMS_SEARCH_SHIFT_COMPREHENSION, SUMMARY_SHIFT_TYPE_LIST } from "../../constants/shiftComprehensionConsts";
import { GroupButton } from "../../../components/GroupButton";
import { convertDataShiftComprehension } from "../../utils/shiftUtils";
import TableCustomSpan, { IColumnsSpan } from "../../../components/table-custom/TableCustomSpan";
import { buildWeekDaysList } from "../../../utils/dateUtils";
import moment from "moment";
import TextValidator from "../../../components/input/text-validator";
import { handleBlurDate } from "../../../utils/functionUtils";
import Autocomplete from "../../../components/input/autocomplete";

const ShiftComprehensionTab: FC<IPropsShiftComprehensionTab> = (props) => {
  const { title, doiTuong } = props;
  const { setPageLoading } = useContext(AppContext);
  const { lang } = useMultiLanguage();

  const [columnsFull, setColumnsFull] = useState<IColumnsSpan[]>([]);
  const [listData, setListData] = useState<IRowDataSchedule[]>([]);
  const [timeColumns, setTimeColumns] = useState({
    ngayBatDau: moment().startOf('isoWeek').format(FORMAT_DATE.YYYY_MM_DD_INPUT),
    ngayKetThuc: moment().endOf('isoWeek').format(FORMAT_DATE.YYYY_MM_DD_INPUT)
  })

  const validationSchema = Yup.object().shape({
    ngayBatDau: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable()
  });

  const handleSearch = useCallback(async (values: IParamsSearchShiftComprehension) => {
    try {
      setPageLoading(true);
      setTimeColumns({
        ngayBatDau: values?.ngayBatDau ? moment(values?.ngayBatDau).startOf('isoWeek').format(FORMAT_DATE.YYYY_MM_DD_INPUT) : "",
        ngayKetThuc: values?.ngayBatDau ? moment(values?.ngayBatDau).endOf('isoWeek').format(FORMAT_DATE.YYYY_MM_DD_INPUT) : ""
      });
      const { data } = await searchShiftDivision({ ...values, summaryShiftType: values?.summaryShiftType?.code, doiTuong });
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListData(convertDataShiftComprehension(data?.data?.content));
      } else toast.warning(data?.message);
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  }, [doiTuong, lang, setPageLoading]);

  const formik = useFormik({
    initialValues: INIT_PARAMS_SEARCH_SHIFT_COMPREHENSION,
    validationSchema: validationSchema,
    onSubmit: handleSearch
  });

  useEffect(() => {
    handleSearch(formik.values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSearch]);

  const handleFillColumns = useCallback((columns: IColumnsSpan[]) => {
    const listWeekDay = buildWeekDaysList(timeColumns.ngayBatDau, timeColumns.ngayKetThuc);
    const newColumns = [...columns];
    listWeekDay.forEach(item => {
      newColumns.push({
        name: `${lang(item.weekday)}<br />${moment(item.day).format(FORMAT_DATE.DD)}`,
        field: item.day,
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          textAlign: "left"
        },
        render: row => <div className="text-center spaces h-20">{row[item.day]?.name}</div>
      });
    });

    setColumnsFull(newColumns);
  }, [timeColumns, lang]);

  const columns: IColumnsSpan[] = useMemo(() => [
    {
      name: "STT",
      field: "stt",
      headerStyle: {
        minWidth: "50px"
      },
      cellStyle: {
        textAlign: "center"
      }
    },
    {
      name: lang("PROFILE.DEPARTMENT"),
      field: "phongBanText",
      headerStyle: {
        minWidth: "200px"
      },

    },
    {
      name: lang("TIMEKEEPING.SHIFT.SCHEDULE.TYPE"),
      field: "loaiTruc",
      headerStyle: {
        minWidth: "150px"
      },
      cellStyle: {
        textAlign: "left"
      },
      render: row => <span>{row?.loaiTruc?.value}</span>
    },
    {
      name: lang("TIMEKEEPING.SHIFT.SCHEDULE.SHIFT"),
      field: "caLamViec",
      headerStyle: {
        minWidth: "150px"
      },
      cellStyle: {
        textAlign: "left"
      },
      render: row => <span>{row?.caLamViec?.tenCa}</span>
    }
  ], [lang]);

  useEffect(() => {
    handleFillColumns(columns);
  }, [columns, handleFillColumns]);

  const handleChangeSelect = (name: string, value: any) => {
    formik.setFieldValue(name, value);
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row className="flex-bottom pt-10 px-10 relative spaces z-index-3">
        <Col xs={4} md={4} lg={3} xl={2}>
          <Autocomplete
            isRequired
            lable={lang("TYPE.SCHEDULE")}
            options={SUMMARY_SHIFT_TYPE_LIST}
            value={formik.values?.summaryShiftType || null}
            name="summaryShiftType"
            onChange={(selectedOption) => handleChangeSelect("summaryShiftType", selectedOption)}
            touched={formik.touched?.summaryShiftType}
            errors={formik.errors?.summaryShiftType}
          />
        </Col>
        <Col xs={4} md={4} lg={3} xl={2}>
          <TextValidator
            name="ngayBatDau"
            lable={lang("GENERAL.TIME")}
            type="date"
            value={formik.values?.ngayBatDau}
            onChange={formik.handleChange}
            onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.ngayBatDau, "ngayBatDau")}
            errors={formik?.errors?.ngayBatDau}
            touched={formik?.touched?.ngayBatDau}
          />
        </Col>
        <Col xs={4} md={4} lg={3} xl={2}>
          <GroupButton typeButton="submit">
            <i className="bi bi-funnel spaces flex fs-18 text-white mr-6"></i>
            {lang("GENERAL.FILTER")}
          </GroupButton>
        </Col>
        {/* Tạm ẩn do chưa làm chức năng export pdf */}
        {/* <GroupButton outline handleEvent={handleExportPDF}>
          <KTSVG path="/media/icons/export-excel.svg" />{" "}
          {lang("BTN.EXPORT.PDF")}
        </GroupButton> */}
      </Row>
      <div className="mt-5 px-3">
        <h3 className="text-center spaces fs-20" dangerouslySetInnerHTML={{ __html: title(timeColumns.ngayBatDau, timeColumns.ngayKetThuc) }}></h3>
        <div className="schedule table-custom mt-4">
          <TableCustomSpan
            className="table-custom"
            id={`shift-comprehension-${doiTuong}`}
            data={listData}
            columns={columnsFull}
            fixedColumnsCount={4}
            isRowSpan
            numberSpan={3}
            noPagination
          />
        </div>
      </div>
    </Form>
  )
}

export default ShiftComprehensionTab;
import React, { useRef, useState } from "react";
import TableCustom, { columnNamesType } from "../../components/table-custom/TableCustom";
import { Modal } from "react-bootstrap";
import { IFormProps } from "../models/declareAssetsModels";
import { TYPE } from "../../constants/moduleConsts";
import { generateRandomId } from "../../utils/functionUtils";
import { OptionReactSelect } from "../../models/models";
import { ASSETS_TYPE_DOMESTIC, NAME_TITLE } from "../constants/declareAssetsConsts";
import { toast } from "react-toastify";
import { GroupButton } from "../../components/GroupButton";

interface IProps {
  id: string;
  isView?: boolean;
  columns: columnNamesType[];
  data: any[];
  setData: (newData: any) => void;
  partForm: (props: IFormProps<any>) => React.ReactNode;
  tableTitle: string;
  dialogSize?: "sm" | "lg" | "xl";
  assetsType?: OptionReactSelect;
  nameTitle?: string;
}

function TablePartInfomation(props: IProps) {
  const {
    id,
    isView = false,
    columns,
    data,
    setData,
    partForm,
    dialogSize = "xl",
    assetsType = ASSETS_TYPE_DOMESTIC,
    tableTitle,
    nameTitle
  } = props;

  const btnSubmitRef = useRef<HTMLButtonElement>(null);

  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [dataEdit, setDataEdit] = useState<any>({});

  const handleCloseDialog = () => {
    setIsOpenDialog(false);
  };

  const handleOpenUpdateDialog = (row: any) => {
    setIsOpenDialog(true);
    setDataEdit(row);
  }

  const handleOpenDialog = () => {
    setIsOpenDialog(true);
  };

  const handleSubmit = (values: any) => {
    if (values?.taiSanId) {
      setData(data.map((item) => (item?.taiSanId === values?.taiSanId ? values : item)));
      toast.success("Cập nhật thành công")
    } else {
      setData([...data, { ...values, taiSanId: generateRandomId(), loaiTaiSan: assetsType }]);
      toast.success("Thêm mới thành công")
    }
    setIsOpenDialog(false);
  };

  const handleDelete = (ids: string) => {
    let _ids = ids.split(",");
    let _data = data.filter((item) => !_ids.includes(item?.taiSanId));
    setData(_data);
    return true;
  };

  const handleBtnSubmitClick = () => {
    btnSubmitRef?.current?.click();
  };

  const filterData = (dataFilter: any[]) => {
    if(nameTitle === NAME_TITLE.CON_CAI ||  nameTitle === NAME_TITLE.TK_NGAN_HANG){
      return dataFilter;
    }

    return dataFilter.filter((item) => item?.loaiTaiSan?.code === assetsType?.code);
  };

  return (
    <>
      <TableCustom
        title={tableTitle}
        id={id}
        columns={columns}
        data={filterData(data)}
        buttonAdd={!isView}
        isActionTableTab={true}
        noPagination={true}
        type={TYPE.MULTILINE}
        notDelete={isView}
        handleDoubleClick={handleOpenUpdateDialog}
        handleOpenDialog={handleOpenDialog}
        handleDelete={handleDelete}
        uniquePrefix="taiSanId"
        className="table-title-assets"
      />
      {isOpenDialog && (
        <Modal
          show={isOpenDialog}
          size={dialogSize}
          centered
          onHide={handleCloseDialog}
          backdrop="static"
          className="custom-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="heading-5">Thêm mới thông tin</Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-8 py-2 contract-dialog-content">
            {partForm({ handleSubmit, isView, btnSubmitRef, dataEdit })}
          </Modal.Body>
          <Modal.Footer className="flex-center">
            <GroupButton type="btn-cancel" handleCloseUpdateDialog={handleCloseDialog} />
            {!isView && (
              <GroupButton type="btn-save" handleSubmit={handleBtnSubmitClick} />
            )}
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export default TablePartInfomation;

export const levelTraining = [
  { code: "0", name: "Chưa xác định" },
  { code: "1", name: "Đại học" },
  { code: "2", name: "Cao đẳng" },
  { code: "3", name: "Trung cấp" },
  { code: "4", name: "Thạc sĩ" },
  { code: "5", name: "Tiến sĩ" },
  { code: "6", name: "Trung học phổ thông" },
  { code: "7", name: "Trung học cơ sở" },
  { code: "8", name: "Tiểu học" },
  { code: "9", name: "Cao đẳng nghề" },
  { code: "10", name: "Trung cấp nghề" },
  { code: "11", name: "Sơ cấp nghề" },
  { code: "12", name: "Dạy nghề thường xuyên" },
  { code: "13", name: "Giáo dục thường xuyên" },
  { code: "14", name: "Cử nhân (Cao đẳng)" },
  { code: "15", name: "Cử nhân" },
  { code: "16", name: "Bác sĩ chuyên khoa I" },
  { code: "17", name: "Bác sĩ chuyên khoa II" },
  { code: "18", name: "Trung học chuyên nghiệp" },
];

export const rankOfEducation = [
  { code: 1, name: "Xuất sắc" },
  { code: 2, name: "Giỏi" },
  { code: 3, name: "Khá" },
  { code: 4, name: "Khá giỏi" },
  { code: 5, name: "Đạt" },
  { code: 6, name: "Trung bình khá" },
  { code: 7, name: "Trung bình" },
  { code: 8, name: "Yếu" }
];

export const groupOfCertificate = [
  { code: 1, name: "Chứng chỉ hành nghề" },
  { code: 2, name: "Chứng chỉ tin học" },
  { code: 3, name: "Chứng chỉ ngoại ngữ" },
  { code: 4, name: "Chứng chỉ chuyên môn khác" },
];

export const politicalTheoryTrainingForms = [
  { code: 1, name: "Bồi dưỡng" },
  { code: 2, name: "Bổ túc văn hóa" },
  { code: 3, name: "Chính quy" },
  { code: 4, name: "Chuyển đổi" },
  { code: 5, name: "Đào tạo từ xa" },
  { code: 6, name: "Chuyên tu" },
  { code: 7, name: "Liên kết" },
  { code: 8, name: "Liên thông" },
  { code: 9, name: "Mở rộng" },
  { code: 10, name: "Không tập trung" },
  { code: 11, name: "Khác" },
  { code: 12, name: "Chưa xác định" },
  { code: 13, name: "Vừa học vừa làm" },
  { code: 14, name: "Tại chức" },
];

export const Relatives = [
  { code: 1, name: "Ông" },
  { code: 2, name: "Bà" },
  { code: 3, name: "Cha đẻ" },
  { code: 4, name: "Mẹ đẻ" },
  { code: 5, name: "Chồng" },
  { code: 6, name: "Vợ" },
  { code: 7, name: "Anh trai" },
  { code: 8, name: "Chị gái" },
  { code: 9, name: "Em trai" },
  { code: 10, name: "Con trai" },
  { code: 11, name: "Con gái" },
  { code: 12, name: "Cha nuôi" },
  { code: 13, name: "Mẹ nuôi" },
  { code: 14, name: "Cha trước" },
  { code: 15, name: "Mẹ trước" },
  { code: 16, name: "Cha dượng" },
  { code: 17, name: "Mẹ kế" },
  { code: 18, name: "Cha chồng" },
  { code: 19, name: "Mẹ chồng" },
  { code: 20, name: "Em gái" },
  { code: 21, name: "Anh trai chồng" },
  { code: 22, name: "Chị gái chồng" },
  { code: 23, name: "Chị gái chồng" },
  { code: 24, name: "Em gái chồng" },
  { code: 25, name: "Anh trai vợ" },
  { code: 26, name: "Chị gái vợ" },
  { code: 27, name: "Em gái vợ" },
  { code: 28, name: "Em trai vợ" },
];

export const diseasesRoom = [
  { code: 1, name: 'COVcode-19' },
  { code: 2, name: 'Lao' },
  { code: 3, name: 'Viêm gan A' },
  { code: 4, name: 'Viêm gan B' },
  { code: 5, name: 'Viêm gan A+B' },
  { code: 6, name: 'Bạch hầu - Ho gà - Uốn ván' },
  { code: 7, name: 'Bại liệt' },
  { code: 8, name: 'Viêm họng, viêm phổi, viêm màng não do HIB' },
  { code: 9, name: 'Tiêu chảy do virus Rota' },
  { code: 10, name: 'Viêm màng não, viêm phổi, nhiễm khuẩn huyết, viêm tai giữa do phế cầu' },
  { code: 11, name: 'Viêm màng não do não mô cầu BC' },
  { code: 12, name: 'Viêm màng não do não mô cầu A,C,Y và W135' },
  { code: 13, name: 'Cúm' },
  { code: 14, name: 'Sởi - Quai bị - Rubella' },
  { code: 15, name: 'Thủy đậu' },
  { code: 16, name: 'Uốn ván' },
  { code: 17, name: 'Ung thư cổ tử cung' },
  { code: 18, name: 'Thương hàn' },
  { code: 19, name: 'Tả' },
  { code: 20, name: 'Dại' },
];
export const vaccinationCount = [
  {code:1,name: "Mũi 1"},
  {code:2,name: "Mũi 2"},
  {code:3,name: "Nhắc lại"},
];
export const roleStaff = [
  { code: 1, name: "Nhân viên" },
  { code: 2, name: "Quản lý" },
];
export const typeProcedure = [
  { code: 1, name: "Luân chuyển" },
  { code: 2, name: "Điều động" },
  { code: 3, name: "Bổ nhiệm" },
  { code: 4, name: "Kiêm nhiệm" },
  { code: 5, name: "Miễn nhiệm" },
  { code: 6, name: "Biệt phái" },
  { code: 7, name: "Tiếp nhận" },
  { code: 8, name: "Tuyển dụng" },
  { code: 9, name: "Khác" }
];
export const positionInJob = [
  { code: 1, name: "DLM PODVP - Phó Giám đốc văn phòng" },
  { code: 2, name: "DLM_GDVP - Giám đốc văn phòng" },
  { code: 3, name: "DLM_PTGD - Phó Tổng giám đốc" },
  { code: 4, name: "DLM_TGD - Tổng giám đốc" },
  { code: 5, name: "DLM_NVQLKD - Nhân viên quản lý kinh doanh" },
  { code: 6, name: "DLMNVOM - Nhân viên Digital Marketing" },
  { code: 7, name: "DLM_NVCM - Nhân viên Content Marketing" },
  { code: 8, name: "DUM_TPHCTH - Trưởng phòng hành chính nhân sự" },
  { code: 9, name: "DLM_NVQTNNL - Nhân viên quản trị nguồn nhân lực" },
  { code: 10, name: "DLM.LT - Lễ Tân" },
  { code: 11, name: "DLM_NVHCTH - Nhân viên hành chính tổng hợp" },
  { code: 12, name: "DLM_KTT - Kế toán trưởng" },
  { code: 13, name: "DLMKTTH - Kế toán tổng hợp" },
  { code: 14, name: "DLM_KTBH - Kế toán bán hàng" },
  { code: 15, name: "DLM_TPKD - Trưởng phòng kinh doanh" },
  { code: 16, name: "DLM_NVKD - Nhân viên kinh doanh" }
];
export const laborNature = [
  { code: 1, name: "Chính thức" },
  { code: 2, name: "Thực tập sinh" },
  { code: 3, name: "Học việc" },
  { code: 4, name: "Thời gian thử việc" },
  { code: 5, name: "Chính thức" },
  { code: 6, name: "Thất nghiệp" },
  { code: 7, name: "Tạm dừng công việc" },
  { code: 8, name: "Nghỉ thai sản" },
  { code: 9, name: "Khác" }
];

export const salaryTypeOptions = [
  { code: 1, name: "GROSS" },
  { code: 2, name: "NET" }
];
export const employmentStatusOptions = [
  { code: 1, name: "Thực tập sinh" },
  { code: 2, name: "Người học việc" },
  { code: 3, name: "Thời gian thử việc" },
  { code: 4, name: "Chính thức" },
  { code: 5, name: "Thất nghiệp" },
  { code: 6, name: "Tạm ngừng làm việc tạm thời" },
  { code: 7, name: "Nghỉ thai sản" },
  { code: 8, name: "Sa thải" },
  { code: 9, name: "Nghỉ hưu" },
  { code: 10, name: "Chấm dứt hợp đồng một phía" },
  { code: 11, name: "Thỏa thuận chấm dứt hợp đồng" },
  { code: 12, name: "Khác" }
];
export const tempPapers = [
  { code: 1, name: "Chứng minh nhân dân" },
  { code: 2, name: "Căn cước công dân" },
  { code: 3, name: "Hộ chiếu" },
  { code: 4, name: "Giấy khám sức khỏe" },
]

export const INFO_TECHNOLOGY_CERTIFICATE = [
  { code: 1, name: "Đại học/ Sau ĐH" },
  { code: 2, name: "Cao đẳng" },
  { code: 3, name: "CNTT cơ bản" },
]

export const LANGUAGE_CERTIFICATE = [
  { code: 1, name: "A1 (Bậc 1)" },
  { code: 2, name: "A2 (Bậc 2)" },
  { code: 3, name: "B1 (Bậc 3)" },
  { code: 4, name: "B2 (Bậc 4)" },
  { code: 5, name: "C1 (Bậc 5)" },
  { code: 6, name: "C2 (Bậc 6)" }
]

export const PRACTICING_CERTIFICATE = [
  { code: 1, name: "Đại học/ Sau ĐH" },
  { code: 2, name: "Cao đẳng" },
]

export const STATUS_NV = {
  DANG_LAM_VIEC : 1,
  THOI_VIEC: 2,
  DINH_CHI_CONG_TAC: 3,
  NGHI_CHE_DO: 4,
  NGHI_KHONG_LUONG: 5
}

export const LIST_STATUS_NV = [
  { code: STATUS_NV.DANG_LAM_VIEC, name: "Đang làm việc", styleClass: "bg-green" },
  { code: STATUS_NV.THOI_VIEC, name: "Thôi việc", styleClass: "bg-slate-gray" },
  { code: STATUS_NV.DINH_CHI_CONG_TAC, name: "Đình chỉ công tác", styleClass: "bg-coral" },
  { code: STATUS_NV.NGHI_CHE_DO, name: "Nghỉ chế độ", styleClass: "bg-amber" },
  { code: STATUS_NV.NGHI_KHONG_LUONG, name: "Nghỉ không lương", styleClass: "bg-purple" },
]

export const TYPE_OF_OFFICER = [
  {code: 1, name: "Công chức"},
  {code: 2, name: "Viên chức"},
  {code: 3, name: "Chưa xác định"},
  {code: 4, name: "Cán bộ cấp xã"},
  {code: 5, name: "Công chức cấp xã"},
  {code: 6, name: "Hợp đồng lao động"},
  {code: 7, name: "Hợp đồng theo Nghị định 68"},
  {code: 8, name: "Cán bộ"},
  {code: 9, name: "Lãnh đạo chuyên trách tại các hội đặc thù"},
  {code: 10, name: "Hợp đồng theo Nghị định 111"},
  {code: 11, name: "Hợp đồng theo Nghị định 161"},
  {code: 12, name: "Cộng tác viên"},
]

export const OFFICER_CODE = {
  CONG_CHUC: 1,
  VIEN_CHUC: 2,
  CHUA_XAC_DINH: 3,
  CAN_BO_XA: 4,
  CONG_CHUC_XA: 5,
  HOP_DONG_LAO_DONG: 6,
  HOP_DONG_LAO_DONG_68: 7,
  CAN_BO: 8,
  LANH_DAO_CHUYEN_TRACH: 9,
  HOP_DONG_LAO_DONG_111: 10,
  HOP_DONG_LAO_DONG_161: 11,
  CONG_TAC_VIEN: 12
}

export const LIST_NOT_OFFICER_CODE = [
  OFFICER_CODE.CHUA_XAC_DINH,
  OFFICER_CODE.HOP_DONG_LAO_DONG,
  OFFICER_CODE.CONG_TAC_VIEN,
  OFFICER_CODE.HOP_DONG_LAO_DONG_68,
  OFFICER_CODE.HOP_DONG_LAO_DONG_161,
  OFFICER_CODE.HOP_DONG_LAO_DONG_111
]

export const foreignLanguages = [
  { code: 1, name: "Tiếng Anh" },
  { code: 2, name: "Tiếng Nga" },
  { code: 3, name: "Tiếng Pháp" },
  { code: 4, name: "Tiếng Đức" },
  { code: 5, name: "Tiếng Trung Quốc" },
  { code: 6, name: "Tiếng Nhật Bản" },
  { code: 7, name: "Ngôn ngữ khác" },
  { code: 8, name: "Chưa xác định" }
]

export const specializeTrainingFields = [
  { code: 1, name: "Chuyên môn" },
  { code: 2, name: "Chức danh nghề nghiệp" },
  { code: 3, name: "Kiến thức kỹ năng chuyên ngành, vị trí việc làm" },
  { code: 4, name: "Kỹ năng lãnh đạo quản lý" },
  { code: 5, name: "Quản lý, điều hành chương trình KT - XH" },
  { code: 6, name: "Quản lý hành chính công" },
  { code: 7, name: "Xây dựng và phát triển nguồn nhân lực" },
  { code: 8, name: "Chính sách công, dịch vụ công" },
  { code: 9, name: "Kiến thức hội nhập" },
  { code: 10, name: "Phương pháp giảng dạy" },
  { code: 11, name: "Nội dung khác" }
]

export const TRAINING_TYPE = {
  BOI_DUONG_CHUC_DANH: {
    code: 1,
    name: "Bồi dưỡng chức danh"
  },
  NGOAI_NGU: {
    code: 2,
    name: "Trình độ ngoại ngữ"
  },
  TIN_HOC: {
    code: 3,
    name: "Trình độ tin học"
  },
  QUAN_LY_NHA_NUOC: {
    code: 4,
    name: "Trình độ quản lý nhà nước"
  },
  CHUNG_CHI_HANH_NGHE: {
    code: 5,
    name: "Chứng chỉ hành nghề"
  },
  QUOC_PHONG: {
    code: 6,
    name: "Bồi dưỡng quốc phòng"
  },
}
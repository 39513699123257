import { FC, useContext, useState } from "react";
import { INIT_RESIGN_INFO, REF_TAB_RESIGN } from "./constants/resignConsts";
import "./resign.scss";
import ResignInformation from "./components/resign/ResignInformation";
import { ResignInfo } from "./models/resignModels";
import InterruptInformation from "./components/interrupt/InterruptInformation";
import { InterruptDialog } from "./components/interrupt/InterruptDialog";
import { InterruptInfo } from "./models/interruptModels";
import { INIT_INTERRUPT_INFO } from "./constants/interruptConsts";
import { Tab, Tabs,  } from "react-bootstrap";
import { addNewResign, editResign } from "./services/resignServices";
import { RESPONSE_STATUS_CODE, INIT_SEARCH_OBJECT } from "../constants/moduleConsts";
import { toast } from "react-toastify";
import useMultiLanguage from "../../hook/useMultiLanguage";
import AppContext from "../../AppContext";
import ResignForm from "./components/resign/ResignForm";
import { hasAuthority } from "../utils/functionUtils";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../constants";
import { useAuth } from "../auth";

const Resign: FC = () => {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);
  const { currentUser } = useAuth();

  const [activeTab, setActiveTab] = useState<string>(REF_TAB_RESIGN.THOI_VIEC);
  const [shouldOpenDialogResign, setShouldOpenDialogResign] = useState<boolean>(false);
  const [shouldOpenDialogInterrupt, setShouldOpenDialogInterrupt] = useState<boolean>(false);
  const [viewInfoResign, setViewInfoResign] = useState<boolean>(false);
  const [viewInfoInterrupt, setViewInfoInterrupt] = useState<boolean>(false);
  const [resignInfo, setResignInfo] = useState<ResignInfo>(INIT_RESIGN_INFO as ResignInfo);
  const [interruptInfo, setInterruptInfo] = useState<InterruptInfo>({} as InterruptInfo);
  const [listResign, setListResign] = useState<ResignInfo[]>([]);
  const [listInterrupt, setListInterrupt] = useState<InterruptInfo[]>([]);
  const [searchObject, setSearchObject] = useState<any>(INIT_SEARCH_OBJECT);
  const [dataCheckedResign, setDataCheckedResign] = useState<ResignInfo[]>([]);
  const [dataCheckedInterrupt, setDataCheckedInterrupt] = useState<InterruptInfo[]>([]);

  const handleTabChange = (key: string | null) => {
    setSearchObject({});
    key && setActiveTab(key);
  };
  
  const handleCloseDialog = () => {
    setShouldOpenDialogResign(false);
    setShouldOpenDialogInterrupt(false);
    setViewInfoResign(false);
    setViewInfoInterrupt(false);
    setResignInfo(INIT_RESIGN_INFO);
    setInterruptInfo(INIT_INTERRUPT_INFO);
  };
  
  const handleOpenDialogResign = (row: ResignInfo) => {
    setViewInfoResign(true);
    setShouldOpenDialogResign(true);
    setResignInfo({ 
      ...row, 
      employee: hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW) ? currentUser : row.employee } as ResignInfo
    );
  };

  const handleOpenAddNewResign = () => {
    if(hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW)) {
      setResignInfo({ employee: currentUser } as ResignInfo);
    }
    setShouldOpenDialogResign(true);
  };

  const handleOpenUpdateDialogResign = () => {
    setViewInfoResign(false);
  };

  const handleCloseUpdateDialogResign = () => {
    setViewInfoResign(true);
  };
  
  const handleOpenDialogInterrupt = (row: InterruptInfo) => {
    setViewInfoInterrupt(true);
    setShouldOpenDialogInterrupt(true);
    setInterruptInfo({ 
      ...row, 
      employee: hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW) ? currentUser : row.employee } as InterruptInfo
    );
  };

  const handleOpenAddNewInterrupt = () => {
    if(hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW)) {
      setInterruptInfo({ employee: currentUser } as InterruptInfo);
    }
    setShouldOpenDialogInterrupt(true);
  };

  const handleOpenUpdateDialogInterrupt = () => {
    setViewInfoInterrupt(false);
  };

  const handleCloseUpdateDialogInterrupt = () => {
    setViewInfoInterrupt(true);
  };

  const handleSubmitFormResign = async (values: ResignInfo) => {
    try {
      setPageLoading(true);
      const _values = { ...values };
      _values.listKhoanThu.forEach(item => delete item.id);
      const { data } = resignInfo?.id
        ? await editResign(resignInfo?.id, _values)
        : await addNewResign(_values);
      if (data.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(
          resignInfo.id
            ? lang("TOAST.EDIT.SUCCESS")
            : lang("TOAST.CREATE.SUCCESS")
        );
        handleCloseDialog();
      } else {
        toast.warning(data.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  }

  return (
    <div className="resign spaces mt-16">
      {!shouldOpenDialogResign && !shouldOpenDialogInterrupt && (
        <Tabs
          activeKey={activeTab}
          defaultActiveKey={REF_TAB_RESIGN.THOI_VIEC}
          id="justify-tab-example"
          className="tabs-user-info"
          onSelect={handleTabChange}
        >
          <Tab eventKey={REF_TAB_RESIGN.THOI_VIEC} title="Thôi việc" className="tab-content" id={REF_TAB_RESIGN.THOI_VIEC} mountOnEnter>
            <ResignInformation 
              listResign={listResign}
              setListResign={setListResign}
              handleOpenDialog={handleOpenDialogResign}
              handleOpenAddNewDialog={handleOpenAddNewResign}
              initSearchObject={searchObject}
              setInitSearchObject={setSearchObject}
              dataChecked={dataCheckedResign} 
              setDataChecked={setDataCheckedResign}
            />
          </Tab>
          <Tab eventKey={REF_TAB_RESIGN.GIAN_DOAN} title="Gián đoạn" className="tab-content" id={REF_TAB_RESIGN.GIAN_DOAN} mountOnEnter>
            <InterruptInformation 
              listInterrupt={listInterrupt}
              activeTab={activeTab}
              setListInterrupt={setListInterrupt}
              handleOpenDialog={handleOpenDialogInterrupt}
              handleOpenAddNewDialog={handleOpenAddNewInterrupt}
              initSearchObject={searchObject}
              setInitSearchObject={setSearchObject}
              dataChecked={dataCheckedInterrupt} 
              setDataChecked={setDataCheckedInterrupt}
            />
          </Tab>
        </Tabs>
      )}
      {shouldOpenDialogResign && (
        <ResignForm 
          isView={viewInfoResign}
          resignInfo={resignInfo}
          handleClose={handleCloseDialog}
          handleOpenUpdateDialog={handleOpenUpdateDialogResign}
          handleCloseUpdateDialog={handleCloseUpdateDialogResign}
          handleSubmitForm={handleSubmitFormResign} 
        />
      )}
      {shouldOpenDialogInterrupt && (
        <InterruptDialog
          isView={viewInfoInterrupt}
          interruptInfo={interruptInfo}
          handleClose={handleCloseDialog}
          handleOpenUpdateDialog={handleOpenUpdateDialogInterrupt}
          handleCloseUpdateDialog={handleCloseUpdateDialogInterrupt}
        />
      )}
    </div>
  );
};
export { Resign };

import { IReceivingCitizens } from "../models/receivingCitizensModels";
import axios from "axios";
import { paramsConfig } from "../../utils/paramsUtils";
const API_URL = process.env.REACT_APP_API_URL;

export const getListReceivingCitizens = (searchData: any) => {
  let url = `${API_URL}/tiep-cong-dan/page`;
  return axios.get(url, paramsConfig(searchData));
};

export const addNewReceivingCitizens = (data: IReceivingCitizens) => {
  let url = `${API_URL}/tiep-cong-dan`;
  return axios.post(url, data);
};

export const getByIdReceivingCitizens = (id: string) => {
  let url = `${API_URL}/tiep-cong-dan/${id}`;
  return axios.get(url);
};

export const updateReceivingCitizens = (id: string, data: IReceivingCitizens) => {
  let url = `${API_URL}/tiep-cong-dan/${id}`;
  return axios.put(url, data);
};

export const deleteReceivingCitizens = (ids: string[]) => {
  let url = `${API_URL}/tiep-cong-dan/ids?ids=${ids.toString()}`;
  return axios.delete(url);
};

import { memo, useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import { TablePagination } from "./TablePagination";
import {
  handlePagesChange,
  handleRowsPerPageChange,
} from "../../utils/pageUtils";
import { ROWS_FOR_PAGE, VARIABLE_STRING } from "../../constants/moduleConsts";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { TableSpanRow } from "./TableSpanRow";
import "./table-custom.scss";
import { eventResizeWindow } from "../../utils/functionUtils";

export interface IRowSpanInfo {
  [key: string]: {
    rowSpan: number;
    indexOfRowSpan: number;
  };
}

export interface IColumnsSpan {
  name: string;
  field: string;
  headerStyle?: React.CSSProperties | object;
  cellStyle?: React.CSSProperties | object;
  colSpan?: number;
  render?: (
    data: any,
    index: number,
    numericalOrder: number,
    itemList: any[]
  ) => any;
};

export interface IPositionHandleRow {
  position: number;
  type: string;
  handleRow: (rowData: any, rowIndex: number, itemList: any[]) => void;
}

interface TableProps {
  bodyClasses?: string;
  className?: string;
  columns: IColumnsSpan[];
  data: any[];
  dependencies?: any[];
  fixedColumnsCount?: number;
  handleDoubleClick?: (row: any, index: number) => void;
  headerClasses?: string;
  height?: number;
  id: string;
  isHandleNewRow?: boolean;
  isRowSpan?: boolean;
  noPagination?: boolean;
  notEdit?: boolean;
  numberSpan?: number;
  listPositionHandleRow?: IPositionHandleRow[];
  numberOfElements?: number;
  scrollable?: boolean;
  searchObject?: any;
  setSearchObject?: (row: any) => void;
  title?: string;
  totalElements?: number;
  totalPages?: number;
};

const TableCustomSpan = memo((props: TableProps) => {
  const {
    id,
    data,
    columns,
    headerClasses,
    bodyClasses,
    height,
    scrollable,
    totalPages,
    totalElements,
    numberOfElements,
    noPagination,
    fixedColumnsCount,
    handleDoubleClick,
    title,
    notEdit,
    dependencies,
    className,
    searchObject,
    setSearchObject = () => { },
    isHandleNewRow = false,
    listPositionHandleRow,
    isRowSpan = false,
    numberSpan = 0
  } = props;

  const { lang } = useMultiLanguage();

  const [stickyColumnCount, setStickyColumnCount] = useState<number>(fixedColumnsCount || 0);
  const [rowSpanInfo, setRowSpanInfo] = useState<IRowSpanInfo>({});

  const handleChangeSearchObject = (name: string, value: any) => {
    switch (name) {
      case VARIABLE_STRING.PAGE_SIZE: {
        setSearchObject({
          ...searchObject,
          [name]: value,
          [VARIABLE_STRING.PAGE_INDEX]: 1
        })
        break;
      }
      default:
        setSearchObject({
          ...searchObject,
          [name]: value
        })
    }
  }

  const styles: object = {
    height: height || "auto",
    overflowY: scrollable && "auto"
  };

  const handleRenderStickyHeader = useCallback(() => {
    let stickyColumns = document.querySelectorAll(`.sticky-column-${id}`);
    let leftOffset = 0;

    stickyColumns.forEach(column => {
      (column as HTMLElement).style.left = leftOffset + "px";
      leftOffset += (column as HTMLElement).offsetWidth;
    });
  }, [id]);

  const handleRenderStickyRowData = useCallback((index: number) => {
    const stickyColumns = Array.from(document.querySelectorAll(`.sticky-column-row-${id}-${index}`));

    let leftOffset = 0;
    stickyColumns.forEach((column, colIndex) => {
      if (isRowSpan) {
        const colIdx = column.getAttribute("data-index-span-prev");
        if (colIdx) {
          const stickyColumnsPrev = Array.from(document.querySelectorAll(`.sticky-column-row-${id}-${colIdx}`));
          const numberColumnsHasRowSpan = stickyColumnsPrev.length - stickyColumns.length;

          if (numberColumnsHasRowSpan > 0) {
            const columnHasRowSpan = stickyColumnsPrev[numberColumnsHasRowSpan - 1] as HTMLElement;
            const computedStyle = window.getComputedStyle(columnHasRowSpan);
            leftOffset = (parseFloat(computedStyle.left) || 0) + columnHasRowSpan.offsetWidth;
          }
        }
      }
      const columnElement = column as HTMLElement;
      columnElement.style.left = `${leftOffset}px`;
      leftOffset += columnElement.offsetWidth;
    });
  }, [id, isRowSpan]);

  useEffect(() => {
    const handleReRenderStickyColumns = (listData: any[]) => {
      return () => {
        handleRenderStickyHeader();
        listData?.forEach((_row, index) => {
          handleRenderStickyRowData(index);
        });
      }
    }

    const removeResizeListener = eventResizeWindow(handleReRenderStickyColumns(data));

    return () => removeResizeListener();
  }, [data, handleRenderStickyHeader, handleRenderStickyRowData]);

  useEffect(() => {
    if (fixedColumnsCount) {
      setStickyColumnCount(fixedColumnsCount);
    } else {
      setStickyColumnCount(0);
    }
    if (stickyColumnCount) {
      handleRenderStickyHeader();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependencies, stickyColumnCount, rowSpanInfo, columns]);

  const isEqualData = useCallback((firstData: any, secondDate: any): Boolean => {
    return (firstData !== null && firstData !== undefined
      && secondDate !== null && secondDate !== undefined
      && (firstData === secondDate || isEqualData(firstData?.id, secondDate?.id)));
  }, []);

  // Hàm tính toán rowspan
  const calculateRowSpanInfo = useCallback((listData: any[], listColumn: IColumnsSpan[]) => {
    const newRowSpanInfo: IRowSpanInfo = {};
    const countLoop = numberSpan ? Math.min(numberSpan, listColumn.length) : listColumn.length;

    for (let columnIndex = 0; columnIndex < countLoop; columnIndex++) {
      const prevField = listColumn[columnIndex - 1]?.field;
      const currentField = listColumn[columnIndex].field;
      let spanCount = 1;

      for (let i = 1; i < listData.length; i++) {
        const fieldDataCurrent = listData[i][currentField];
        const fieldDataPrev = listData[i - 1][currentField];

        if (
          isEqualData(fieldDataCurrent, fieldDataPrev) && 
          (columnIndex === 0 || newRowSpanInfo[prevField + '-' + i]?.rowSpan === 0)
        ) {
          newRowSpanInfo[currentField + '-' + i] = { rowSpan: 0, indexOfRowSpan: i - spanCount };
          spanCount++;
        } else if (spanCount > 1) {
          newRowSpanInfo[currentField + '-' + (i - spanCount)] = { rowSpan: spanCount, indexOfRowSpan: i - spanCount };
          spanCount = 1;
        }
      }

      if (spanCount > 1) {
        newRowSpanInfo[currentField + '-' + (listData.length - spanCount)] = {
          rowSpan: spanCount,
          indexOfRowSpan: listData.length - spanCount
        };
      }
    }

    return newRowSpanInfo;
  }, [isEqualData, numberSpan]);

  useEffect(() => {
    if (isRowSpan) {
      setRowSpanInfo(calculateRowSpanInfo(data, columns));
    }
  }, [data, isRowSpan, calculateRowSpanInfo, columns]);

  return (
    <div id={id} className={`table-custom-span ${className || ""}`}>
      {title && <div className="spaces fs-18 text-header-table fw-600 title-action-tab pb-10">{title}</div>}
      <div className="table-responsive customs-collapse-row m-0" style={styles}>
        <table
          className="table-row-dashed dataTable table w-100 border-bottom border-b-2"
          id="kt_table_users"
        >
          <thead
            className={clsx(headerClasses, "position-sticky top-0 z-index-2")}
          >
            <tr className="text-header-table fw-600 fw-bolder text-capitalize-first gs-0 border">
              {columns?.map((column, index) => {
                return (
                  <th
                    key={column?.field + index}
                    className={clsx(
                      `p-table text-center bg-header-table ${index < stickyColumnCount
                        ? `sticky-column sticky-column-${id}`
                        : ""
                      }`,
                      !column?.headerStyle
                    )}
                    style={column?.headerStyle}
                    colSpan={column?.colSpan}
                  >
                    <div dangerouslySetInnerHTML={{ __html: column.name }} />
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className={clsx(bodyClasses)}>
            {data?.length ? (
              data?.map((rowData: any, index: number) => (
                <TableSpanRow
                  id={id}
                  data={data}
                  rowData={rowData}
                  columns={columns}
                  rowIndex={index}
                  notEdit={notEdit}
                  handleDoubleClick={handleDoubleClick}
                  searchObject={searchObject}
                  dependencies={dependencies}
                  stickyColumnCount={stickyColumnCount}
                  rowSpanInfo={rowSpanInfo}
                  isRowSpan={isRowSpan}
                  isHandleNewRow={isHandleNewRow}
                  listPositionHandleRow={listPositionHandleRow}
                  handleRenderStickyRowData={handleRenderStickyRowData}
                />
              ))
            ) : (
              <tr>
                <td colSpan={columns.length} className="text-center border">
                  {lang("TABLE.DATA.EMPTY")}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {!noPagination && (
        <TablePagination
          page={searchObject?.pageIndex || 1}
          setPage={(pageIndex) => handleChangeSearchObject(VARIABLE_STRING.PAGE_INDEX, pageIndex)}
          handlePagesChange={handlePagesChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
          rowsForPage={ROWS_FOR_PAGE}
          rowsPerPage={searchObject?.pageSize}
          setRowsPerPage={(pageSize) => handleChangeSearchObject(VARIABLE_STRING.PAGE_SIZE, pageSize)}
          totalPages={totalPages || 0}
          totalElements={totalElements || 0}
          numberOfElements={numberOfElements || 0}
        />
      )}
    </div>
  );
});

export default TableCustomSpan;

import { formatDateTable } from "../../../utils/functionUtils";

export const EthnicLanguageColumn = () => {
  return [
    {
      name: "STT",
      field: "stt",
      headerStyle: {
        minWidth: "40px"
      },
      render: (row: any, index: number, numericalOrder: number) => (
        <span>{numericalOrder}</span>
      )
    },
    {
      name: "Từ ngày",
      field: "daoTaoTuNgay",
      headerStyle: {
        minWidth: "90px"
      },
      render: (row: any) => <span>{formatDateTable(row?.daoTaoTuNgay)}</span>
    },
    {
      name: "Đến ngày",
      field: "daoTaoDenNgay",
      headerStyle: {
        minWidth: "90px"
      },
      render: (row: any) => <span>{formatDateTable(row?.daoTaoDenNgay)}</span>
    },
    {
      name: "Tiếng dân tộc",
      field: "tiengDanToc",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
    },
    {
      name: "Cơ sở đào tạo",
      field: "coSoDaoTaoText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
      render: (row: any) => <span>{row?.coSoDaoTaoKhac ? row?.coSoDaoTaoKhac : row?.coSoDaoTaoText}</span>
    },
    {
      name: "Văn bằng/Chứng chỉ/Chứng nhận được cấp",
      field: "vanBang",
      headerStyle: {
        minWidth: "300px"
      },
      cellStyle: {
        textAlign: "left"
      },
    }
  ];
};
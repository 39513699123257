import { useContext, useEffect, useState } from "react";
import useMultiLanguage from "../../hook/useMultiLanguage";
import AppContext from "../../AppContext";
import { IKHChamCong, ITableTimesheet } from "../timesheet/models/timesheetModels";
import moment from "moment";
import { EXPORT_FILE_NAME, FORMAT_DATE, LIST_MONTH, RESPONSE_STATUS_CODE, SEARCH_OBJECT_MAX_SIZE, VARIABLE_STRING } from "../constants/moduleConsts";
import { searchSymbol } from "../category/services/symbolServices";
import * as Yup from "yup";
import { toast } from 'react-toastify';
import { searchTimeSheet } from "../timesheet/services/timesheetServices";
import { useFormik } from "formik";
import { INIT_TIMEKEEPING_OVERVIEW } from "./constants/timekeepingOverviewConsts";
import { exportToFile, getFullYear } from "../utils/functionUtils";
import { exportTimeSheet } from "../services/exportFileServices";
import { Col, Form, Row } from "react-bootstrap";
import Autocomplete from "../components/input/autocomplete";
import { GroupButton } from "../components/GroupButton";
import { KTSVG } from "../../../_metronic/helpers";
import TableTimesheets from "../timesheet/components/TableTimesheet";

const TimekeepingOverview = () => {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [initDataTable, setInitDataTable] = useState<ITableTimesheet[]>([]);
  const [dataKHChamCong, setDataKHChamCong] = useState<IKHChamCong[]>([]);
  const [dateSearch, setDateSearch] = useState<{ nam: number, thang: number }>({
    nam: Number(moment().format(FORMAT_DATE.YYYY)),
    thang: Number(moment().format(FORMAT_DATE.MM)),
  });

  const getDataKHChamCong = async () => {
    try {
      const { data } = await searchSymbol(SEARCH_OBJECT_MAX_SIZE);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        const listData = data.data.content || [];
        listData.forEach((item: IKHChamCong) => item.isEdited = true);
        setDataKHChamCong(listData);
        return;
      }
      toast.warning(data?.message);
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }

  useEffect(() => {
    getDataKHChamCong();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const validationSchema = Yup.object().shape({
    nam: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    thang: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
  })

  const handleSearch = async () => {
    try {
      setPageLoading(true);
      let { nam, thang, pageSize, pageIndex } = formik.values;
      let searchObject = {
        pageSize,
        pageIndex,
        nam,
        thang
      };
      nam && thang && setDateSearch({ nam, thang });
      const { data } = await searchTimeSheet(searchObject);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setInitDataTable(data?.data?.content || [])
      } else toast.warning(data?.message);
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: INIT_TIMEKEEPING_OVERVIEW,
    validationSchema: validationSchema,
    onSubmit: handleSearch
  });
  
  const handleExportExcel = () => {
    if (!initDataTable.length) {
      toast.warning(lang("GENERAL.NO_DATA_TO_EXPORT"));
      return;
    }
    let { nam, thang, pageSize, pageIndex } = formik.values;
    let searchObject = {
      pageSize,
      pageIndex,
      nam,
      thang
    };
    exportToFile({
      exportAPI: () => exportTimeSheet(searchObject),
      fileName: `${EXPORT_FILE_NAME.BANG_CHAM_CONG}${thang ? " tháng " + thang
        : ""}${nam ? " năm " + nam : ""}`,
      setPageLoading
    });
  }

  const handleChangeSelect = (name: string, value: any) => {
    formik.setFieldValue(name, value);
  };

  return (
    <div className='timekeeping-overview spaces px-8 mt-12 py-12'>
      <Form onSubmit={formik.handleSubmit}>
        <Row className="spaces relative z-index-4">
          <Col xs={4} md={3} lg={3} xl={2} xxl={2}>
            <Autocomplete
              isRequired
              lable={lang("GENERAL.YEAR")}
              options={getFullYear(0)}
              value={String(formik.values?.nam) || null}
              name={VARIABLE_STRING.NAM}
              valueSearch="name"
              onChange={(value) => handleChangeSelect(VARIABLE_STRING.NAM, value?.name)}
              touched={formik.touched?.nam}
              errors={formik.errors?.nam}
            />
          </Col>
          <Col xs={4} md={3} lg={3} xl={2} xxl={2}>
            <Autocomplete
              isRequired
              lable={lang("GENERAL.MONTH")}
              options={LIST_MONTH}
              value={formik.values?.thang || null}
              name="thang"
              valueSearch="code"
              onChange={(value) => handleChangeSelect("thang", value?.code)}
              touched={formik.touched?.thang}
              errors={formik.errors?.thang}
            />
          </Col>

          <Col xs={3} className="spaces flex flex-start mt-21">
            <GroupButton typeButton="submit" className='spaces pr-10'>
              <i className="bi bi-funnel spaces flex fs-18 text-white mr-6"></i>
              {lang("GENERAL.FILTER")}
            </GroupButton>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className='relative pt-5 mb-3'>
              <p className='fw-bold text-uppercase fs-1 text-center m-0'>bảng chấm công</p>
              <p className='fw-bold fs-4 text-center m-0'>{`Tháng ${formik.values.thang ?? "....."} năm ${formik.values.nam ?? "....."}`}</p>
              <GroupButton className="position-absolute bottom-0 end-0" outline handleEvent={handleExportExcel}>
                <KTSVG path="/media/icons/export-excel.svg" />{" "}
                {lang("BTN.EXPORT")}
              </GroupButton>
            </div>
            <TableTimesheets
              data={[...initDataTable]}
              dateSearch={dateSearch}
              dataKHChamCong={dataKHChamCong}
            />
          </Col>
        </Row>
        <Col xs={12} className='fw-bold pt-2'>Kí hiệu chấm công:</Col>
        <Row className='ps-6'>
          {dataKHChamCong.map((item: any) => {
            return <Col xs={3} className='spaces flex fs-12'>
              <p className='m-0'>{item?.name}</p>:
              <p className='m-0 fw-bold ps-1 color-crimson-red'>{item?.code}</p>
            </Col>
          })}
        </Row>
      </Form >
    </div>
  )
}

export default TimekeepingOverview;

import { Col, Row } from "react-bootstrap";
import TextUnderline from "../../../components/TextUnderline";
import { IPropsExportWord } from "../../../components/exportWord";
import { ValueOrDots } from "../../../components/exportWord/partials";
import { TYPE } from "../../../constants/moduleConsts";

export function ContractIndefiniteWord({ data }: IPropsExportWord) {
  return (
    <div className="word spaces px-70 colors-word">
      <div className="spaces text-center flex flex-between align-start">
        <div className="fs-lg">
          <p>{data.soYTeHeader}</p>
          <p className="fw-600 text-uppercase">
            <span className="header-underline fs-lg">
              <ValueOrDots className="fs-lg" value={data.donViKyHopDongText} size={70} />
            </span>
          </p>
          <p className="spaces mt-16 fs-md">
            Số: <ValueOrDots value={data.soHopDong} />
            /HĐLVXĐTH
          </p>
        </div>
        <div className="fw-600 fs-lg">
          <p>CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM</p>
          <p>
            <span className="header-underline fs-lg">Độc lập - Tự do - Hạnh phúc</span>
          </p>
        </div>
      </div>
      <div className="text-center spaces py-20">
        <p className="fw-600 fs-lg">HỢP ĐỒNG LÀM VIỆC KHÔNG XÁC ĐỊNH THỜI HẠN</p>
      </div>
      <div>
        <div className="text-indent-40 text-italic text-justify">
          <p>Căn cứ Luật Khám bệnh, chữa bệnh;</p>
          <p>Căn cứ Nghị định 115/2020/NĐ-CP của Chính phủ ngày 25/9/2020 quy định về tuyển dụng, sử dụng, quản lý viên chức;</p>
          <p>{data?.quyetDinhHopDong ?? ""}</p>
          <p>{data?.qdhdLamViecDaiHan ?? ""}</p>
        </div>
        <div>
          <p className="text-indent-40">
            Hôm nay, ngày <ValueOrDots value={data.ngayKyHopDong} /> tại{" "}
            <ValueOrDots value={data.donViKyHopDongText} size={85} />, chúng tôi gồm các bên dưới đây:
          </p>
          <p className="fw-600 flex">
            <TextUnderline readOnly label="BÊN A" value={data.nguoiDaiDienCtyKyText} />
          </p>
          <div className="text-indent-40">
            <p className="spaces pl-40">
              <TextUnderline readOnly label="Chức vụ" value={data.nguoiDaiDienCtyChucDanhText} />
            </p>
            <p className="spaces pl-40">
              <TextUnderline readOnly label="Đại diện cho đơn vị" value={data.donViKyHopDongText} />
            </p>
            <p>Địa chỉ: {data?.unitAddress ?? ""}</p>
            <p>Điện thoại: {data?.unitPhone ?? ""}</p>
            <p>Mã số thuế: {data?.unitFax ?? ""}</p>
          </div>
        </div>
        <div>
          <p className="fw-600">
            <TextUnderline readOnly label="BÊN B: Ông/Bà " value={data.fullName} noColon />
          </p>
          <div className="spaces pl-40">
            <Row className="flex">
              <Col xs={4}>
                <TextUnderline readOnly label="Sinh ngày" value={data.birthdate} type={TYPE.DATE} />
              </Col>
              <Col xs={8}>
                <TextUnderline readOnly label="Tại" value={data.noiSinh} />
              </Col>
            </Row>
            <TextUnderline readOnly label="Giới tính" value={data.gender?.name} />
            <TextUnderline readOnly label="Hộ khẩu thường trú" value={data.hoKhauThuongTru} />
            <TextUnderline readOnly label="Điện thoại" value={data.phone} />
            <TextUnderline readOnly label="Tài khoản ngân hàng" value={data.tkNganHang} />
            <TextUnderline readOnly label="Nơi mở tài khoản" value={data.chiNhanh} />
            <TextUnderline readOnly label="Email (nếu có)" value={data.email} />
            <p className="flex">
              <TextUnderline readOnly label="Số CCCD/Hộ chiếu" value={data.soCMNDOrCCCD} />
              <TextUnderline readOnly label="Cấp ngày" value={data.ngayCapCMNDOrCCCD} type={TYPE.DATE} />
            </p>
            <TextUnderline readOnly label="Tại" value={data.noiCapCMNDOrCCCD} />
            <TextUnderline readOnly label="Trình độ đào tạo" value={data.trinhDoChuyenMon} />
            <TextUnderline readOnly label="Chuyên ngành đào tạo" value={data.chuyenNganhDaoTao} />
            <TextUnderline readOnly label="Năm tốt nghiệp" value={data.namDaoTao} />
            <TextUnderline readOnly label="Nghề nghiệp trước khi được tuyển dụng" value={data.noiCapCMNDOrCCCD} />
            <TextUnderline
              readOnly
              label="Chứng chỉ hành nghề (nếu có)"
              value={data.tenChungChi + data.chuyenNganhDaoTao}
            />
            <TextUnderline
              readOnly
              label="Các thông tin khác theo yêu cầu của vị trí việc làm ký kết hợp đồng làm việc"
              rows={2}
            />
          </div>
        </div>
      </div>
      <div>
        <p>Thỏa thuận ký kết Hợp đồng làm việc và cam kết làm đúng những điều khoản sau đây:</p>
        <p className="fw-600">Điều 1. Nhiệm vụ hợp đồng</p>
        <p className="fw-600">
          <TextUnderline
            readOnly
            label="1.1. Địa điểm làm việc"
            value={`${data.phongBanText || ""} , ${data.donViKyHopDongText || ""}`}
          />
          <TextUnderline readOnly label="1.2. Vị trí việc làm" value={data.chucDanhText} />
        <TextUnderline readOnly label="1.3. Chức danh nghề nghiệp" value={data.chucDanhText} />
        </p>   
        <p>
          <span className="fw-600">1.4.</span> Nhiệm vụ:
        </p>
        <div className="spaces pl-35">
          <p>
            - Thực hiện công việc của chức danh nghề nghiệp <ValueOrDots value={data.chucDanhText} size={80} /> theo bản mô tả công việc của bệnh viện;
          </p>
          <p>- Thực hiện công việc theo phân công của khoa, phòng;</p>
          <p>- Thực hiện luân chuyển, điều động theo quy định pháp luật và quy chế Bệnh viện. </p>
        </div>
      </div>
      <div>
        <p className="fw-600">Điều 2. Chế độ làm việc</p>
        <p>2.1. Thời giờ làm việc: không quá 08 giờ/ngày và không quá 48 giờ/tuần.</p>
        <p>
          2.2. Chế độ nghỉ ngơi (nghỉ hàng tuần, nghỉ phép năm, nghỉ lễ, tết,...): Theo quy định hiện hành của Nhà nước
          và Quy chế, nội quy, quy định của Bệnh viện.
        </p>
        <p>
          2.3. Khoa, phòng, bệnh viện sắp xếp thời gian biểu trong tuần cho Bên B và có thể thay đổi thời gian biểu,
          thời giờ làm việc của Bên B nhằm hoàn thành khối lượng công việc được giao, bảo đảm số lượng, chất lượng, hiệu
          quả, đáp ứng nhu cầu hoạt động của Bệnh viện và phù hợp với quy định của pháp luật hiện hành.
        </p>
        <p>
          2.4. Thời giờ, chế độ làm thêm, làm ban đêm áp dụng theo Quy chế, nội quy, quy định của Bệnh viện phù hợp với
          quy định của Bộ luật lao động.
        </p>
        <p>
          2.5. Được trang bị những phương tiện làm việc: Bên B được cung cấp trang thiết bị bảo hộ lao động, phương tiện
          làm việc phù hợp với công việc và được Bên A bảo đảm an toàn lao động, vệ sinh lao động trong thời gian làm
          việc theo hợp đồng. Bên B có trách nhiệm sử dụng, bảo quản các trang thiết bị bảo hộ lao động, phương tiện làm
          việc và tuân thủ các quy định về an toàn lao động, vệ sinh lao động.
        </p>
      </div>
      <div>
        <p className="fw-600">Điều 3. Nghĩa vụ và quyền lợi của người được tuyển dụng:</p>
        <p className="spaces pl-35">
          Ngoài thực hiện các quyền, nghĩa vụ theo quy định của pháp luật về viên chức và quy định của pháp luật khác có
          liên quan, Bên B còn thực hiện các quyền, nghĩa vụ sau:
        </p>
        <p>3.1. Nghĩa vụ:</p>
        <div className="spaces pl-35">
          <p>
            a. Hoàn thành nhiệm vụ đã cam kết trong hợp đồng làm việc và và thực hiện nghiêm túc chế độ báo cáo kết quả
            công việc theo quy định của Bệnh viện hoặc theo yêu cầu của cấp quản lý.
          </p>
          <p>
            b. Cung cấp văn bản, giấy tờ xác minh đủ tiêu chuẩn, điều kiện thực hiện công việc thỏa thuận theo yêu cầu
            của Bên A.
          </p>
          <p>
            c. Cam kết khi ký Hợp đồng này đã đọc, hiểu và chấp hành nghiêm chỉnh quy định, nội quy, quy chế, kỷ luật
            làm việc của Bệnh viện, các quy định tại Điều 16, Điều 17, Điều 18 và Điều 19 của Luật Viên chức và các quy
            định của pháp luật khác liên quan trong thời gian thực hiện Hợp đồng này. Nếu vi phạm, Bên B phải hoàn toàn
            chịu trách nhiệm theo quy định của Bệnh viện và của pháp luật về hành vi mình gây ra.{" "}
          </p>
          <p>
            d. Chấp hành việc xử lý kỷ luật và trách nhiệm bồi thường, hoàn trả theo quy định tại Hợp đồng này, quy chế,
            nội quy, quy định của Bệnh viện và theo quy định của pháp luật.
          </p>
          <p>
            e. Tuyệt đối đảm bảo tính bảo mật thông tin tình trạng bệnh của người bệnh, những thông tin mà người bệnh đã
            cung cấp và hồ sơ bệnh án.
          </p>
          <p>f. Chấp hành sự quản lý, điều hành, giám sát của người sử dụng lao động.</p>
          <p>
            g. Báo cáo kịp thời với người có trách nhiệm khi phát hiện nguy cơ gây tai nạn lao động bệnh nghề nghiệp,
            gây độc hại hoặc sự cố nguy hiểm, tham gia cấp cứu và khắc phục hậu quả tai nạn lao động khi có lệnh của
            Người sử dụng lao động.
          </p>
          <p>
            h. Thuế thu nhập cá nhân (nếu có) do Bên B đóng. Cơ quan, đơn vị sẽ tạm khấu trừ trước khi chi trả cho Bên B
            theo quy định.
          </p>
          <p>
            i. Trung thành với lợi ích của Bệnh viện, không sử dụng thông tin, tài sản, mối quan hệ với khách hàng, bệnh
            nhân, đối tác,… của Bệnh viện để trục lợi hoặc vì mục đích cá nhân khác.
          </p>
          <p>
            j. Chấp hành lệnh điều hành, nội quy, quy chế, an toàn lao động, hướng dẫn về ứng xử trong công việc và
            chính sách của Bệnh viện.
          </p>
          <p>
            k. Có trách nhiệm thực hiện công việc theo đúng quy định chuyên môn kỹ thuật, đạo đức hành nghề và chịu
            trách nhiệm cá nhân trước Trưởng Bộ phận, Trưởng phòng, Giám đốc Bệnh viện và trước pháp luật về việc thực
            hiện các nhiệm vụ, quyền hạn được giao theo quy định Bệnh viện và quy định pháp luật có liên quan.
          </p>
          <p>
            l. Kịp thời sơ cứu, cấp cứu, khám bệnh, chữa bệnh cho người bệnh trừ trường hợp tiên lượng bệnh vượt quá khả
            năng hoặc trái với phạm vi hoạt động chuyên môn của mình.
          </p>
          <p>
            m. Tôn trọng các quyền của người bệnh, có thái độ ân cần, hòa nhã với người bệnh và đối xử bình đẳng với
            người bệnh, không để lợi ích cá nhân hay sự phân biệt đối xử ảnh hưởng đến quyết định chuyên môn của mình.
          </p>
          <p>
            n. Không được kê đơn, chỉ định sử dụng các dịch vụ khám bệnh, chữa bệnh, gợi ý chuyển người bệnh tới cơ sử
            khám bệnh, chữa bệnh khác vì vụ lợi.
          </p>
          <p>
            o. Chấp hành quyết định điều động của cơ quan quản lý trực tiếp, hỗ trợ các công việc liên quan theo chuyên
            môn.
          </p>
          <p>
            p. Trong thời gian làm việc tại Bệnh viện theo hợp đồng làm việc này, Bên B cam kết không làm thêm giờ
            và/hoặc cộng tác viên cho bất kỳ tổ chức, cá nhân nào khác mà không được sự đồng ý trước bằng văn bản của
            Bệnh viện.{" "}
          </p>
          <p>
            q. Trong thời gian làm việc tại Bệnh viện theo hợp đồng làm việc này, Bên B cam kết đảm bảo thực hiện đúng
            tiêu chuẩn đạo đức của người làm công tác y tế theo quy chế, nội quy Bệnh viện và quy định của pháp luật.
          </p>
          <p>
            r. Trong suốt thời hạn của Hợp đồng làm việc này và toàn bộ thời gian sau đó, Bên B không được phép sử dụng
            hoặc để lộ cho bất kỳ thông tin mật, bí mật kinh doanh và tài sản trí tuệ nào liên quan đến hoạt động kinh
            doanh của Bệnh viện hoặc liên quan đến bất kỳ đơn vị liên kết nào của Bệnh viện, trừ khi pháp luật yêu cầu.
          </p>
          <p>
            s. Bên B tại đây nhất trí rằng nếu như quan hệ lao động của mình với Bên A chấm dứt vì bất kỳ lý do gì, Bên
            B sẽ ngay lập tức và vô điều kiện bàn giao lại toàn bộ tài sản vật chất được Bên A giao và/hoặc đang cho Bên
            B nắm giữ và toàn bộ các tài liệu, giấy tờ và vật liệu chứa đựng các thông tin mật hoặc thông tin về nhân
            sự, bệnh án của Bệnh viện.
          </p>
          <p>
            t. Nếu Bên B tham gia đào tạo, bồi dưỡng, Bên B có nghĩa vụ bồi thường chi phí đào tạo theo quy chế, nội
            quy, quy định của Bệnh viện và quy định pháp luật nếu vi phạm một trong các trường hợp sau:
          </p>
          <p>- Tự ý bỏ học, bỏ việc hoặc đơn phương chấm dứt hợp đồng làm việc trong thời gian đào tạo.</p>
          <p>- Không được cơ sở đào tạo cấp văn bằng tốt nghiệp.</p>
          <p>
            - Đã hoàn thành và được cấp văn bằng tốt nghiệp khóa học nhưng bỏ việc hoặc đơn phương chấm dứt hợp đồng làm
            việc hoặc không tiếp tục ký kết hợp đồng làm việc mới theo Khoản 7.2 Điều 7 Hợp đồng này khi chưa phục vụ đủ
            thời gian cam kết làm việc sau đào tạo theo quy chế, nội quy, quy định của Bệnh viện.
          </p>
          <p>u. Bồi thường thiệt hại và trách nhiệm vật chất: </p>
          <p>
            - Bên B làm hư hỏng dụng cụ, thiết bị hoặc có/không có hành vi gây thiệt hại cho Bệnh viện thì Bên B có
            trách nhiệm bồi thường toàn bộ thiệt hại theo quy định của Bệnh viện và pháp luật.
          </p>
          <p>
            - Khi đơn phương chấm dứt hợp đồng làm việc, Bên B có trách nhiệm đền bù chi phí đào tạo (nếu có) theo quy
            định của Bệnh viện và pháp luật.
          </p>
          <p>
            - Trách nhiệm khi để xảy ra tai biến trong khám chữa bệnh và mức tiền bồi thường khi có thiệt hại xảy ra:
            thực hiện theo quy định của pháp luật và quy định của bệnh viện.
          </p>
          <p>
            v. Bên B phải hành động để tránh gây tổn thất, thiệt hại, giảm sút uy tín, thương hiệu của Bệnh viện cũng
            như của bệnh nhân. Nếu vi phạm, Bên B phải hoàn toàn chịu trách nhiệm theo quy định của Bệnh viện và của
            pháp luật về những thiệt hại do hành vi của mình gây ra.
          </p>
          <p>w. Nghĩa vụ khác theo quy định pháp luật.</p>
        </div>
        <p className="fw-600">3.2. Quyền lợi:</p>
        <div className="spaces pl-35">
          <p>a. Được hưởng các quyền lợi quy định tại Điều 11, Điều 12, Điều 13, Điều 14 và Điều 15 Luật Viên chức.</p>
          <p>b. Phương tiện đi lại làm việc: cá nhân tự túc.</p>
          <p className="fw-600 text-italic">c. Hệ số lương và phụ cấp, các khoản trả ngoài lương</p>
          <p>
            - Được hưởng lương ở chức danh nghề nghiệp (mã số): <ValueOrDots value={data.chucDanhText} size={80} />, (Mã
            số: <ValueOrDots value={data.maNgach} size={60} />
            );
          </p>
          <p>
            - Bậc: <ValueOrDots value={data.currentBacLuong} /> Hệ số lương: <ValueOrDots value={data.heSoLuong} />;
          </p>
          <p>- Thời gian nâng lương lần sau: <ValueOrDots value={data.currentNgayHuongLuongDenNgay} /></p>
          <p>
            - Các khoản phụ cấp, bổ sung (nếu có): Theo quy định của Nhà nước và quy định của Bệnh viện, được trả một
            lần hàng tháng;
          </p>
          <p>
            - Thời gian tính nâng bậc lương: Thực hiện theo quy định hiện hành của Nhà nước và Quy chế Nâng bậc lương
            thường xuyên và nâng bậc lương trước thời hạn cho viên chức trong Bệnh viện;
          </p>
          <p>- Khoản trả ngoài lương: Theo nội quy, quy chế của Bệnh viện;</p>
          <p>- Hình thức trả lương: chuyển khoản;</p>
          <p>- Hàng tháng trả lương một lần theo Quy chế chi tiêu nội bộ, quy chế dân chủ của Bệnh viện;</p>
          <p>- Được hưởng các phúc lợi: Theo nội quy, quy chế của Bệnh viện;</p>
          <p>
            - Được hưởng các khoản thưởng, nâng bậc lương, thi hoặc xét thăng hạng chức danh nghề nghiệp, đào tạo, bồi
            dưỡng chuyên môn nghiệp vụ, thực hiện nhiệm vụ hợp tác khoa học, công nghệ với các đơn vị trong hoặc ngoài
            nước theo quy định của pháp luật: Thực hiện theo quy định hiện hành của Nhà nước và Quy chế Nâng bậc lương
            thường xuyên và nâng bậc lương trước thời hạn cho viên chức trong Bệnh viện;
          </p>
          <p className="fw-600 text-italic">d. Chế độ đào tạo, bồi dưỡng</p>
          <p>
            - Bên B sẽ được đào tạo, bồi dưỡng theo kế hoạch đào tạo của Bệnh viện hoặc Bên B tự nguyện tham gia đào
            tạo, bồi dưỡng nâng cao trình độ trong thời gian thực hiện Hợp đồng này.
          </p>
          <p>- Địa điểm đào tạo: Tùy theo kế hoạch, nội dung đào tạo từng thời kỳ.</p>
          <p>- Thời gian đào tạo: Theo kế hoạch đào tạo của Bệnh viện.</p>
          <p>
            - Bên B được cử đi đào tạo, bồi dưỡng được hưởng các quyền lợi liên quan theo Quy chế đào tạo của Bệnh viện
            và theo quy định pháp luật.
          </p>
          <p>
            - Có cam kết thời gian công tác tại Bệnh viện sau khi kết thúc khóa học tương ứng với từng khóa học và bồi
            hoàn chi phí đào tạo theo quy định tại Quy chế đào tạo của Bệnh viện, thực hiện đúng những nội dung trong
            cam kết đào tạo.
          </p>
          <p>
            - Trong thời gian đào tạo, sẵn sàng về tham gia công tác trong trường hợp Bệnh viện có yêu cầu (kể cả trường
            hợp phải tạm dừng đào tạo) theo Quyết định của Giám đốc/Người phụ trách Bệnh viện.
          </p>
          <p>
            e. Chế độ bảo hiểm: Bên B được tham gia đóng và hưởng các chế độ bảo hiểm xã hội, bảo hiểm thất nghiệp, bảo
            hiểm y tế theo quy định của pháp luật tại thời điểm tham gia bảo hiểm.
          </p>
          <p>
            f. Được hưởng các chế độ thôi việc, trợ cấp thôi việc, bồi thường theo quy định của pháp luật về viên chức.
          </p>
          <p>
            g. Được tiếp tục ký kết hợp đồng làm việc trước khi hết hạn hợp đồng làm việc 60 ngày khi đơn vị sự nghiệp
            công lập còn nhu cầu.
          </p>
          <p>
            h. Có quyền đề xuất, khiếu nại, thay đổi, đề nghị chấm dứt hợp đồng làm việc theo quy định của pháp luật.
          </p>
          <p>
            i. Được quyết định và chịu trách nhiệm về chẩn đoán, phương pháp điều trị bệnh trong phạm vi hoạt động
            chuyên môn. Cung cấp cho Bên A phác đồ điều trị và hồ sơ bệnh án khi có yêu cầu.
          </p>
          <p>
            j. Được từ chối khám bệnh, chữa bệnh nếu trong quá trình khám bệnh, chữa bệnh mà tiên lượng bệnh vượt quá
            khả năng hoặc trái với phạm vi hoạt động chuyên môn của mình, nhưng phải báo cáo với người có thẩm quyền để
            giải quyết.
          </p>
          <p>
            k. Được từ chối khám bệnh, chữa bệnh nếu việc khám bệnh, chữa bệnh đó trái với quy định pháp luật hoặc đạo
            đức nghề nghiệp.
          </p>
          <p>
            l. Ngoài quyền lợi được hưởng theo Hợp đồng này, Bên B được hưởng các quyền lợi khác theo thỏa thuận, quy
            định của Bệnh viện và được các bên ghi nhận tại Phụ lục hợp đồng kèm theo Hợp đồng này.
          </p>
        </div>
      </div>
      <div>
        <p className="fw-600">Điều 4. Nghĩa vụ và quyền hạn của người đứng đầu đơn vị sự nghiệp</p>
        <p className="fw-600">4.1. Nghĩa vụ</p>
        <div className="spaces pl-35">
          <p>
            a. Cung cấp thông tin, tài liệu và các phương tiện, điều kiện làm việc cần thiết để Bên B thực hiện công
            việc.
          </p>
          <p>b. Bảo đảm việc làm và thực hiện đầy đủ những điều đã cam kết trong hợp đồng làm việc này.</p>
          <p>c. Thanh toán đầy đủ, đúng thời hạn các chế độ của viên chức đã cam kết trong hợp đồng làm việc.</p>
        </div>
        <p className="fw-600">4.2. Quyền hạn</p>
        <div className="spaces pl-35">
          <p>a. Sử dụng viên chức để hoàn thành công việc theo đúng thỏa thuận tại hợp đồng này. </p>
          <p>
            b. Điều hành Bên B thực hiện công việc và tuân thủ các nghĩa vụ theo Hợp đồng (bố trí, điều chuyển công việc
            cho cán bộ, công chức, viên chức theo đúng chức năng chuyên môn).
          </p>
          <p>
            c. Xếp loại chất lượng hoàn thành nhiệm vụ của Bên B. Trong trường hợp Bên B có 02 năm liên tiếp bị xếp loại
            chất lượng ở mức độ không hoàn thành nhiệm vụ hoặc Bên B không đạt yêu cầu sau thời gian tập sự, Bên A có
            quyền đơn phương chấm dứt hợp đồng với Bên B theo quy định của pháp luật hiện hành và quy chế, nội quy, quy
            định của Bệnh viện.
          </p>
          <p>
            d. Có quyền chuyển tạm thời lao động, ngừng việc, thay đổi, tạm thời chấm dứt hợp đồng làm việc và áp dụng
            các biện pháp kỷ luật theo quy định của pháp luật hiện hành và theo nội quy, quy định của Bệnh viện trong
            thời gian hợp đồng còn giá trị.
          </p>
          <p>
            e. Tạm hoãn, chấm dứt Hợp đồng làm việc, kỷ luật viên chức theo quy định của pháp luật hiện hành và theo nội
            quy, quy định của Bệnh viện.
          </p>
          <p>f. Các quyền khác theo quy định của pháp luật.</p>
        </div>
      </div>
      <div>
        <p className="fw-600">Điều 5. Thay đổi nội dung, ký kết tiếp, tạm hoãn, chấm dứt hợp đồng làm việc </p>
        <div className="spaces pl-35">
          <p>
            5.1. Trong quá trình thực hiện hợp đồng làm việc, nếu một bên có yêu cầu thay đổi nội dung hợp đồng làm việc
            thì phải báo cho bên kia biết trước ít nhất 03 ngày làm việc. Khi đã chấp thuận thì các bên tiến hành sửa
            đổi, bổ sung nội dung liên quan của hợp đồng làm việc. Trong thời gian tiến hành thoả thuận, các bên vẫn
            phải tuân theo hợp đồng làm việc đã ký kết. Trường hợp không thoả thuận được thì các bên tiếp tục thực hiện
            hợp đồng làm việc này hoặc thoả thuận chấm dứt hợp đồng làm việc.
          </p>
          <p>
            5.2. Việc tạm hoãn thực hiện hợp đồng làm việc, chấm dứt hợp đồng làm việc được thực hiện theo quy định của
            pháp luật về lao động.
          </p>
          <p>
            5.3. Khi Bên B chuyển công tác đến cơ quan, tổ chức, đơn vị khác thì chấm dứt hợp đồng làm việc và được giải
            quyết các chế độ, chính sách theo quy định của pháp luật.
          </p>
        </div>
      </div>
      <div>
        <p className="fw-600">Điều 6. Phương thức giải quyết tranh chấp</p>
        <div className="spaces pl-35">
          <p>
            6.1. Bất kỳ tranh chấp nào giữa các bên phát sinh từ hoặc liên quan đến Hợp đồng này sẽ được giải quyết
            trước hết bằng thương lượng đàm phán giữa các bên trong thời hạn 30 ngày kể từ ngày phát sinh tranh chấp.
            Hết thời hạn này, tranh chấp không được giải quyết thì có quyền đưa tranh chấp ra Toà án có thẩm quyền để
            giải quyết theo pháp luật Việt Nam.
          </p>
          <p>
            6.2. Trong thời gian có mâu thuẫn hoặc có tranh chấp đang được giải quyết thì các bên phải tiếp tục thực
            hiện nghĩa vụ của mình theo Hợp đồng này, ngoại trừ vấn đề đang bị tranh chấp.
          </p>
        </div>
      </div>
      <div>
        <p className="fw-600">Điều 7. Điều khoản thi hành</p>
        <div className="spaces pl-35">
          <p>
            7.1. Hợp đồng có hiệu lực từ ngày <ValueOrDots value={data.ngayCoHieuLuc} /> Hợp đồng này có
            hiệu lực thay thế cho các thỏa thuận liên quan đến lao động mà hai bên đã ký kết trước đó (nếu có).
          </p>
          <p>
            7.2. Những vấn đề về lao động khác không ghi trong hợp đồng này thì áp dụng quy định của nội quy, quy chế
            của Bệnh viện, trường hợp nội quy, quy chế của Bệnh viện không có quy định thì được thực hiện theo quy định
            tại Bộ luật Lao động và các văn bản quy phạm pháp khác có liên quan.
          </p>
          <p>
            7.3. Hợp đồng được làm thành 03 bản có giá trị pháp lý như nhau, mỗi bên giữ 03 bản, 01 bản lưu trong hồ sơ
            của Bên B./.
          </p>
        </div>
        <p>
          Các bên ký xác nhận đã hiểu nội dung tất cả điều khoản, đồng ý ký kết và nghiêm túc thực hiện các quy định tại
          Hợp đồng này.
        </p>
      </div>
      <div className="flex align-start flex-between fs-lg fw-600 spaces mt-16 text-center pb-70 px-70">
        <div>
          <p>ĐẠI DIỆN BÊN A</p>
          <p className="spaces mt-70">
            <ValueOrDots value={data.nguoiDaiDienCtyKyText} size={60} />
          </p>
        </div>
        <div>
          <p>BÊN B</p>
          <p className="spaces mt-70">
            <ValueOrDots value={data.fullName} size={60} />
          </p>
        </div>
      </div>
    </div>
  );
}

import axios, { AxiosResponse } from "axios";
import { APIResponse } from "../../models/models";
import { IAuth, IRole, IUnit, IuserByUnit } from "../models/managerModels";
import { paramsConfig, paramsMaxSizeSearch } from "../../utils/paramsUtils";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../../constants";
import { hasAuthority } from "../../utils/functionUtils";
import { localStorageItem } from "../../utils/localstorage";
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";
import { TYPE_SOFTWARE_USAGE } from "../constants/managerConsts";
const API_URL = process.env.REACT_APP_API_URL;
const API_V1_URL = process.env.REACT_APP_API_URL + "";

export const searchAllAuthority = (searchData?: any): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/authorities/page`;
  return axios.get(url, paramsMaxSizeSearch(searchData));
};

export const searchAllRole = (searchData?: any): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/roles/page`;
  return axios.get(url, paramsMaxSizeSearch(searchData));
};

export const searchAllUser = (searchObject?: any): Promise<AxiosResponse<APIResponse>> => {
  if(hasAuthority(PERMISSIONS.DEPARTMENT, PERMISSION_ABILITY.VIEW)) {
    searchObject.departmentId = localStorageItem.get(KEY_LOCALSTORAGE.EMPLOYEE)?.phongBanId;
  }
  
  let url = `${API_URL}/users/page`;
  return axios.get(url, paramsMaxSizeSearch(searchObject));
};

export const updateUser = (id: string, user: IuserByUnit): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/users/${id}`;
  return axios.put(url, user);
};

export const addNewUser = (user: IuserByUnit): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/users/save/by/employee`;
  return axios.post(url, user);
};

export const deleteUser = (ids: string[]): Promise<AxiosResponse<APIResponse>> => {
  let config = {
    params :{
      ids:ids?.toString()
    }
  }
  let url = `${API_URL}/users/list-id`;
  return axios.delete(url,config);
};

export const updateAuth = (id: string, auth: IAuth): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/authorities/${id}`;
  return axios.put(url, auth);
};

export const addNewAuth = (auth: IAuth): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/authorities`;
  return axios.post(url, auth);
};

export const deleteAuth = (ids: string[]): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/authorities/${ids}`;
  return axios.delete(url);
};

export const updateRole = (id: string, role: IRole): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/roles/${id}`;
  return axios.put(url, role);
};

export const addNewRole = (role: IRole): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/roles`;
  return axios.post(url, role);
};

export const deleteRole = (ids: string[]): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/roles/${ids}`;
  return axios.delete(url);
};

export const getAllRole = (ids: string[]): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_URL}/roles`;
  return axios.get(url);
};

export const updateUnit = (id: string, unit: IUnit): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_V1_URL}/organizations/${id}`;
  return axios.put(url, unit);
};

export const addNewUnit = (unit: IUnit): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_V1_URL}/organizations`;
  return axios.post(url, unit);
};

export const deleteUnit = (ids: string[]): Promise<AxiosResponse<APIResponse>> => {
  let url = `${API_V1_URL}/organizations/list-id`;
  return axios.delete(url, paramsConfig({ ids: ids.toString() }));
};

export const getUserById = (id?: any) => {
  let url = `${API_URL}/users/${id}`;
  return axios.get(url);
};

export const getSoftwareUsage = (searchObject?: any) => {
  return axios.get(`${API_URL}/bao-cao?type=${TYPE_SOFTWARE_USAGE}`, paramsConfig(searchObject));
};
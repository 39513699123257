import { KTSVG } from "../../../../../_metronic/helpers";
import { PERMISSION_ABILITY, PERMISSIONS } from "../../../../constants";
import { ILang } from "../../../../hook/useMultiLanguage";
import CustomTooltip from "../../../components/custom-tooltip";
import { columnNamesType } from "../../../components/table-custom/TableCustom";
import { checkStatus, formatDateTable, hasAuthority } from "../../../utils/functionUtils";
import { CODE_STATUS, LIST_STATUS } from "../../constants/managerRequestConsts";
import { IAttendanceWatch } from "../../models/attendanceWatchModes";

type IProps = {
    handleOpenAttendanceWatchForm: (data: IAttendanceWatch) => void,
    handleApproveOrReject: (data: IAttendanceWatch) => void,
    lang: ILang
}

export const columns = (props: IProps): columnNamesType[] => {
    let {
        handleOpenAttendanceWatchForm,
        handleApproveOrReject,
        lang
    } = props;
    
    return [
        {
            name: "STT",
            field: "",
            render: (row: any, index: number, stt: number) => <span>{stt}</span>
        },
        {
            name: lang("GENERAL.ACTION"),
            field: "thaoTac",
            headerStyle: {
                minWidth: "100px"
            },
            render: (row: any) => {
                return (
                    <div className="flex flex-center column-gap-4">
                        {
                            row?.trangThai?.code === CODE_STATUS.TAO_MOI &&
                            <CustomTooltip title={lang("GENERAL.EDIT")} placement="top">
                                <div className='color-primary' onClick={() => handleOpenAttendanceWatchForm(row)}>
                                    <KTSVG path='/media/icons/pencil-fill.svg' />
                                </div>
                            </CustomTooltip>
                        }
                        {
                            row?.trangThai?.code === CODE_STATUS.CHO_DUYET &&
                            hasAuthority(PERMISSIONS.DON_XIN_NGHI, PERMISSION_ABILITY.APPROVE) &&
                            <CustomTooltip title={lang('GENERAL.APPROVE_OR_REJECT')} placement="top">
                                <div className='color-green' onClick={() => handleApproveOrReject(row)}>
                                    <KTSVG path={'/media/icons/circle-check.svg'} />
                                </div>
                            </CustomTooltip>
                        }
                    </div>
                )
            }
        },
        {
            name: lang("STATUS"),
            field: "trangThai",
            headerStyle: {
                minWidth: "100px"
            },
            render: (row: any) => (
                <span className={`${checkStatus(LIST_STATUS, Number(row?.trangThai?.code))} status`} >
                    {row?.trangThai?.name}
                </span>
            )
        },
        {
            name: lang("MANAGENT_REQUEST.ATTENDANCE_WATCH.PERSON_APPLY_LEAVE"),
            field: "employeeText",
            headerStyle: {
                minWidth: "200px"
            },
            cellStyle: {
                textAlign: "left"
            },
        },
        {
            name: lang("GENERAL.EMPLOYEE.JOB_POSITION"),
            field: "chucDanhText",
            headerStyle: {
                minWidth: "200px"
            },
            cellStyle: {
                textAlign: "left"
            },
        },
        {
            name: lang("MANAGENT_REQUEST.ATTENDANCE_WATCH.DATE_APPLICATION"),
            field: "ngayNop",
            headerStyle: {
                minWidth: "150px"
            },
            render: row => <span>{formatDateTable(row?.ngayNop)}</span>
        },
        {
            name: lang("MANAGENT_REQUEST.ATTENDANCE_WATCH.DATE_APPROVE"),
            field: "ngayDuyet",
            headerStyle: {
                minWidth: "100px"
            },
            render: row => <span>{formatDateTable(row?.ngayDuyet)}</span>
        },
        {
            name: lang("INPUT.DATE_START"),
            field: "tuNgay",
            headerStyle: {
                minWidth: "100px"
            },
            render: row => <span>{formatDateTable(row?.tuNgay)}</span>
        },
        {
            name: lang("INPUT.DATE_END"),
            field: "denNgay",
            headerStyle: {
                minWidth: "100px"
            },
            render: row => <span>{formatDateTable(row?.denNgay)}</span>
        },
        {
            name: lang("MANAGENT_REQUEST.ATTENDANCE_WATCH.DEAN"),
            field: "truongKhoaText",
            headerStyle: {
                minWidth: "200px"
            },
            cellStyle: {
                textAlign: "left"
            },
        }
    ];
}
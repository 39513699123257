import { ILang } from "../../../hook/useMultiLanguage";
import { ITemplateExportWord } from "../../components/exportWord";
import { OptionReactSelect } from "../../models/models";
import { MISSION_STATUS, TYPE, TYPE_CATEGORY } from "../../constants/moduleConsts";
import { JobDecisionWord, MissionDecisionWord } from "../components/word-templates";
import { IConcurrently } from "../models/concurrentlyModels";
import { searchAllSimpleValue, searchPhongBan } from "../../services/services";

export const INIT_CONCURRENTLY: IConcurrently = {
  employee: null,
  chucVuId: "",
  chucVuMoi: null,
  chucVuMoiId: "",
  chucVuMoiText: "",
  chucVuMoiKhac: "",
  chucVuText: "",
  createDate: "",
  createdBy: "",
  denNgay: null,
  employeeId: "",
  fileId: "",
  fileName: "",
  ghiChu: "",
  id: "",
  maNhanVien: "",
  modifiedBy: "",
  modifyDate: "",
  name: "",
  ngayQuyetDinh: "",
  phongBanId: "",
  phongBanMoi: null,
  phongBanMoiId: "",
  phongBanMoiText: "",
  phongBanText: "",
  soQuyetDinh: "",
  trangThai: null,
  tuNgay: null,
  xacNhanKiemNhiem: false,
  phanCongType: null,
  khoaPhongNhanQuyetDinh: "",
  noiDungNhiemVu: "",
  nguoiKy: null,
  nguoiKyId: "",
  nguoiKyText: ""
}

export const ListSearch = (lang: ILang) => {
  return [
    {
      name: lang("PROFILE.CODE"),
      field: "maNhanVien",
      type: TYPE.TEXT,
    },
    {
      name: lang("PROFILE.NAME"),
      field: "name",
      type: TYPE.TEXT,
    },
    {
      name: lang("INPUT.WORKPROCESS.DEPARTMENT.OLD"),
      field: "phongBanIds",
      searchFunction: searchPhongBan,
      type: TYPE.SELECT,
      isMulti: true,
    },
    {
      name: lang("RESIGN.OLDPOSITION"),
      field: "chucVuId",
      searchFunction: searchAllSimpleValue,
      searchObject: { type: TYPE_CATEGORY.chucDanh },
      type: TYPE.SELECT,
      optionLabel: "value"
    },
    {
      name: lang("INPUT.WORKPROCESS.DEPARTMENT.NEW"),
      field: "phongBanMoiIds",
      searchFunction: searchPhongBan,
      type: TYPE.SELECT,
      isMulti: true,
    },
    {
      name: lang("RESIGN.NEWPOSITION"),
      field: "chucVuMoiId",
      searchFunction: searchAllSimpleValue,
      searchObject: { type: TYPE_CATEGORY.chucDanh },
      type: TYPE.SELECT,
      optionLabel: "value"
    },
    {
      name: lang("INPUT.WORKPROCESS.DECISION.NUMBER"),
      field: "soQuyetDinh",
      type: TYPE.TEXT,
    },
    {
      name: lang("GENERAL.STATUS"),
      field: "trangThai",
      type: TYPE.SELECT,
      listOption: MISSION_STATUS,
      isMulti: true,
    },
  ]
}

export const LIST_ASSIGNMENT_CODE = {
  GIAO_PHU_TRACH: 1,
  GIAO_CONG_VIEC: 2
}

export const LIST_ASSIGNMENT: OptionReactSelect[] = [
  {
    code: LIST_ASSIGNMENT_CODE.GIAO_PHU_TRACH,
    name: "Giao phụ trách"
  },
  {
    code: LIST_ASSIGNMENT_CODE.GIAO_CONG_VIEC,
    name: "Giao công việc"
  }
];

export const concurrentlyWordExportList: ITemplateExportWord[] = [
  {
    type: LIST_ASSIGNMENT_CODE.GIAO_PHU_TRACH,
    name: "QĐ giao nhiệm vụ (giao phụ trách)",
    componentPreview: (dataPreview) => MissionDecisionWord({ data: dataPreview })
  },
  {
    type: LIST_ASSIGNMENT_CODE.GIAO_CONG_VIEC,
    name: "QĐ giao nv ( giao công việc)",
    componentPreview: (dataPreview) => JobDecisionWord({ data: dataPreview })
  }
]
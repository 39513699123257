/* eslint-disable react-hooks/exhaustive-deps */
import { AxiosResponse } from "axios";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import * as Yup from "yup";
import AppContext from "../../../../AppContext";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import Autocomplete from "../../../components/input/autocomplete";
import TextValidator from "../../../components/input/text-validator";
import TableCustom from "../../../components/table-custom/TableCustom";
import { AddNewContractAnnex } from "../../../contract/components/AddNewContractAnnex";
import {
  CODE_CONTRACT_LONG_TERM,
  CODE_HOP_DONG,
  INIT_CONTRACT_ANNEX_INFO,
  codeStatusContract,
  contractStatus,
  contractTypeBienChe,
  contractTypeHopDong,
  contractWordExportList,
  signinStatus,
  workShifts
} from "../../../contract/constants/contractConsts";
import { deleteMultipleContractAnnex, getListAnnexByContract } from "../../../contract/services/annexServices";
import { addNewContract, updateContract } from "../../../contract/services/contractServices";
import { IContractAnnexInfo, IContractInfo } from "../../../contract/models/contractModels";
import { convertDataContactDto, switchContractStatus } from "../../../contract/utils/contractUtils";
import { APIResponse, IPeopleDecision } from "../../../models/models";
import { searchAllSimpleValue, searchListWorkUnit, searchNguoiDaiDienKy, searchPhongBanByDonVi } from "../../../services/services";
import "../../../styles/index.scss";
import { 
  RESPONSE_STATUS_CODE,
  TYPE,
  VARIABLE_STRING,
  DATA_WORD_TYPES,
  TYPE_CATEGORY,
  EXPORT_FILE_NAME
} from "../../../constants/moduleConsts";
import { 
  addMoreDay,
  addMoreYear,
  convertNumberPrice,
  convertTextPrice,
  exportToFile,
  handleBlurDate,
  matchTemplateWord
} from "../../../utils/functionUtils";
import { checkInvalidDate } from "../../../utils/validationSchema";
import { getBacLuongByChucDanh } from "../../services/dialogServices";
import { filterObject } from "../../utils/profileUtils";
import { ContractAnnexColumn } from "../columns/ContractAnnexColumn";
import Allowance from "../partials/allowance/Allowance";
import { useProfileContext } from "../../ProfileContext";
import UploadFile from "../../../components/file-upload/UploadFile";
import { IFile } from "../../models/dialogModels";
import { OFFICER_CODE } from "../../constants/dialogChildConsts";
import { TYPE_POSITION } from "../../constants/profileConsts";
import { createHopDongTaiKy } from "../../../warning/services/warningServices";
import { exportHopDongPhuLuc } from "../../../services/exportFileServices";
import { ExportWord, ITemplateExportWord } from "../../../components/exportWord";
import { GroupButton } from "../../../components/GroupButton";
import { AddNewRepresentative } from "../../../components/dialogs";

interface Props {
  shouldOpenContractDialog: boolean;
  handleCloseContractDialog: () => void;
  isView: boolean;
  contractEdit: IContractInfo;
  handleCloseAndSearch: () => void;
  employeeInfo: any;
  isReSignContract?: boolean;
  warningInfo?: any;
}
export const ContractDialog = (props: Props) => {
  const { lang, intl } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const {
    shouldOpenContractDialog,
    handleCloseContractDialog,
    contractEdit,
    isView,
    handleCloseAndSearch,
    employeeInfo,
    isReSignContract,
    warningInfo
  } = props;
  const { isResign } = useProfileContext();

  const [isHopDongDaiHan, setIsHopDongDaiHan] = useState(false);
  const [shouldOpenAddContractAnnex, setShouldOpenAddContractAnnex] = useState<boolean>(false);
  const [annexInfo, setAnnexInfo] = useState<IContractAnnexInfo>(INIT_CONTRACT_ANNEX_INFO);
  const [contractAnnexList, setContractAnnexList] = useState<IContractAnnexInfo[]>([]);
  const [isEdit, setIsEdit] = useState<boolean>(!isView);
  const [isCollaborators, setIsCollaborators] = useState<boolean>(false);
  const [isLumpSum, setIsLumpSum] = useState<boolean>(false);
  const [isShowDialogWord, setIsShowDialogWord] = useState<boolean>(false);
  const [templateWord, setTemplateWord] = useState<ITemplateExportWord[] | null>(null);
  const [isShowDialogAddRepresentative, setIsShowDialogAddRepresentative] = useState<boolean>(false);
  const [peopleDecisions, setPeopleDecisions] = useState<IPeopleDecision[]>([]);

  const validationSchema: any = Yup.object().shape({
    donViKyHopDong: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    soHopDong: Yup.string().when(VARIABLE_STRING.LOAI_HOP_DONG, {
      is: (loaiHopDong: any) => loaiHopDong?.code === CODE_HOP_DONG.THU_VIEC,
      then: Yup.string().nullable(),
      otherwise: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable()
    }),
    phongBan: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    chucVu: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    chucDanh: isCollaborators ? Yup.object().nullable() : Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    luongCoBan: (!isCollaborators && !isLumpSum) ? Yup.string().nullable() : Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    bacLuongOption: (isCollaborators || isLumpSum) ? Yup.object().nullable() : Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    heSoLuong: (isCollaborators || isLumpSum) ? Yup.string().nullable() : Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    loaiHopDong: Yup.object()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    ngayCoHieuLuc: Yup.date()
      .concat(checkInvalidDate(intl))
      .required(lang("VALIDATION.REQUIRE"))
      .when("ngayHetHan", {
        is: (ngayHetHan: Date | null) => ngayHetHan,
        then: Yup.date()
          .max(
            Yup.ref("ngayHetHan"),
            lang("VALIDATION.MAXDATE") +
            lang("CONTRACT.EXPIRATIONDATE")
          )
          .nullable()
      })
      .nullable(),
    ngayHetHan: Yup.date()
      .concat(checkInvalidDate(intl))
      .when(VARIABLE_STRING.LOAI_HOP_DONG, {
        is: (loaiHopDong: any) =>
          loaiHopDong?.code === CODE_HOP_DONG.VIEN_CHUC_DAI_HAN || loaiHopDong?.code === CODE_HOP_DONG.HOP_DONG_DAI_HAN,
        then: Yup.date().nullable(),
        otherwise: Yup.date()
          .required(lang("VALIDATION.REQUIRE"))
          .concat(checkInvalidDate(intl))
          .min(Yup.ref(VARIABLE_STRING.NGAY_CO_HIEU_LUC), lang("VALIDATE.EXPIRATION_DATE_AFTER_EFFECTIVE_DATE"))
          .nullable()
      }),
    ngayKyHopDong: Yup.date()
      .concat(checkInvalidDate(intl))
      .when("ngayHetHan", {
        is: (ngayHetHan: Date | null) => ngayHetHan && ngayHetHan < (new Date()),
        then: Yup.date()
          .max(
            Yup.ref("ngayHetHan"),
            lang("VALIDATION.SIGN_DAY.EQUAL_OF_LESS")
          )
          .nullable()
      })
      .max(new Date(), lang("VALIDATION.INVALID_SIGN_DATE"))
      .nullable(),
    trangThaiHopDong: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    nguoiDaiDienCtyKy: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
  });

  useEffect(() => {
    updateDataContractAnnex();
  },[])

  const handleSubmit = async (values: IContractInfo) => {
    try {
      setPageLoading(true);
      const dataContract = filterObject(convertDataContactDto(values));
      let res;
      if(isReSignContract) {
        res = await createHopDongTaiKy(dataContract);
      } else {
        res = contractEdit.id
        ? await updateContract(contractEdit.id, dataContract)
        : await addNewContract(dataContract);
      }

      if(res?.data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        if(isReSignContract) {
          toast.success(lang("TOAST.ADD.RESIGN_CONTRACT"));
        } else {
          toast.success(contractEdit.id 
            ? lang("TOAST.EDIT.CONTRACT.SUCCESS") 
            : lang("TOAST.ADD.CONTRACT.SUCCESS"));
          }
        handleCloseAndSearch();
      } else {
        toast.warning(res?.data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      ...contractEdit,
      employee: employeeInfo || null,
      ...(!contractEdit?.id && {
        donViKyHopDong: null,
        soHopDong: "",
        loaiHopDong: null,
        ngayCoHieuLuc: isReSignContract ? addMoreDay(warningInfo?.ngayHetHan, 1) : "",
        ngayHetHan: "",
        bacLuongOption: null,
        heSoLuong: "",
        trangThaiKy: null,
        trangThaiHopDong: null,
        nguoiDaiDienCtyKy: null,
        luongCoBan: ""
      })
    },
    validationSchema,
    onSubmit: handleSubmit
  });

  useEffect(() => {
    const lumpSumContract = formik.values?.loaiHopDong?.code === CODE_HOP_DONG.HOP_DONG_KHOAN;
    setIsLumpSum(lumpSumContract);
    lumpSumContract && formik.setValues({ ...formik.values, bacLuongOption: null, heSoLuong: "" });
  }, [formik.values?.loaiHopDong?.code]);

  useEffect(() => {
    if (!contractEdit?.id) return;
    formik.setFieldValue(VARIABLE_STRING.LOAI_HOP_DONG, contractEdit?.loaiHopDong);
    const checkHopDongDaiHan = CODE_CONTRACT_LONG_TERM.some(value => value === Number(contractEdit?.loaiHopDong?.code))
    setIsHopDongDaiHan(checkHopDongDaiHan)
    setTemplateWord(matchTemplateWord(Number(contractEdit?.loaiHopDong?.code), contractWordExportList));
  }, [contractEdit]);

  useEffect(() => {
    let officerType = employeeInfo?.loaiCanBo;
    const conditionalCodes = [OFFICER_CODE.CONG_TAC_VIEN];
    if (officerType?.code) {
      let isCooperator = conditionalCodes.includes(Number(officerType?.code));
      setIsCollaborators(isCooperator);
      if(isCooperator) {
        if(!employeeInfo?.chucVu) {
          const getPosition = async () => {
            try {
              const { data } = await searchAllSimpleValue({ type: TYPE_CATEGORY.chucDanh });
              if(data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
                const positionCollaborator = data.data?.content?.find((position: any) => position?.mappingName === TYPE_POSITION.CONG_TAC_VIEN);
                formik.setFieldValue("chucVu", positionCollaborator);
              } else {
                toast.warning(data?.message);
              }
            } catch (error) {
              toast.error(lang("GENERAL.ERROR"));
            }
          }
          getPosition();
        }
      }
    }
  }, [employeeInfo?.loaiCanBo?.code])

  const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    switch (name) {
      case VARIABLE_STRING.VIEN_CHUC:
        formik.setFieldValue(name, checked);
        formik.setFieldValue(VARIABLE_STRING.HOP_DONG_LAO_DONG, false);
        break;
      case VARIABLE_STRING.HOP_DONG_LAO_DONG:
        formik.setFieldValue(name, checked);
        formik.setFieldValue(VARIABLE_STRING.VIEN_CHUC, false);
        break;
      default:
        formik.setFieldValue(name, checked);
        break;
    }
  };

  const handleChangeSelect = (name: string, value: any) => {
    switch (name) {
      case VARIABLE_STRING.LOAI_HOP_DONG:
        const { VIEN_CHUC_DAI_HAN, HOP_DONG_DAI_HAN, ONE_YEAR } = CODE_HOP_DONG;
        switch (value?.code) {
          case VIEN_CHUC_DAI_HAN:
          case HOP_DONG_DAI_HAN:
            formik.setValues({
              ...formik.values,
              [name]: value,
              ngayHetHan: ""
            });
            setIsHopDongDaiHan(true);
            break;
          case ONE_YEAR:
            setIsHopDongDaiHan(true);
            formik.setValues({
              ...formik.values,
              [name]: value,
              ngayHetHan: addMoreYear(formik.values?.ngayCoHieuLuc, 1)
            });
            break;
          default:
            formik.setFieldValue(VARIABLE_STRING.LOAI_HOP_DONG, value);
            setIsHopDongDaiHan(false);
            break;
        }
        break;
      case VARIABLE_STRING.NGUOI_DAI_DIEN_KY:
        formik.setValues({
          ...formik.values,
          nguoiDaiDienCtyChucDanhId: value?.chucVuId,
          nguoiDaiDienCtyChucDanhText: value?.chucVuText,
          nguoiDaiDienCtyKyId: value?.id,
          nguoiDaiDienCtyKyText: value?.name
        });
        break;
      case VARIABLE_STRING.CHUC_DANH:
        formik.setFieldValue(name, value);
        formik.setFieldValue(VARIABLE_STRING.BAC_LUONG_OPTION, null);
        formik.setFieldValue(VARIABLE_STRING.HE_SO_LUONG, "");
        break;
      case VARIABLE_STRING.BAC_LUONG_OPTION:
        formik.setFieldValue(name, value);
        formik.setFieldTouched(name, false, false);
        formik.setFieldValue(VARIABLE_STRING.HE_SO_LUONG, value?.heSoLuong || "");
        break;
      default:
        formik.setFieldValue(name, value);
        break;
    }
    formik.setFieldValue(name, value);
  };

  const handleChangeNgayCoHieuLuc = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    formik.setValues({
      ...formik.values,
      [name]: value,
      ngayHetHan:
        formik.values?.loaiHopDong?.code === CODE_HOP_DONG.ONE_YEAR ? addMoreYear(value, 1) : formik.values?.ngayHetHan
    });
  };

  const updateDataContractAnnex = async () => {
    let contractId = contractEdit?.id;
    if (!contractId) return;
    try {
      let { data } = await getListAnnexByContract(contractId);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setContractAnnexList(data?.data);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      console.error(error);
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const handleOpenDialog = (
    row: any,
    setShouldOpenDialog: (value: React.SetStateAction<boolean>) => void,
    setData: (value: any) => void
  ): void => {
    setShouldOpenDialog(true);
    setData(row);
  };

  const handleCloseDialog = (
    initData: any,
    setShouldOpenDialog: (value: React.SetStateAction<boolean>) => void,
    setData: (value: any) => void
  ): void => {
    setShouldOpenDialog(false);
    setData(initData);
  };

  const handleDeleteItems = async (
    handleDelete: (...params: any) => Promise<AxiosResponse<APIResponse>>,
    handleUpdate: () => Promise<void>,
    ids: string
  ) => {
    try {
      const res = await handleDelete?.(ids);
      if (res?.data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("GENERAL.DELETE_SUCCESS"));
        updateDataContractAnnex();
        return true;
      } else toast.warning(`${res?.data?.message}`);
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    }
    await handleUpdate?.();
  };

  useEffect(() => {
    switchContractStatus(employeeInfo?.loaiCanBo, formik);
  }, [employeeInfo]);

  const handleChangeMoney = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, Number(convertTextPrice(value)));
  };

  const handleFile = (data: IFile) => {
    formik.setFieldValue("fileId", data.id);
    formik.setFieldValue("fileName", data.name);
  }

  const getPeopleDecision = async () => {
    try {
      const { data } = await searchNguoiDaiDienKy();
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setPeopleDecisions(data?.data?.content || [])
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }

  useEffect(() => {
    getPeopleDecision();
  }, []);

  useEffect(() => {
    if(!contractEdit?.id && peopleDecisions.length > 0) {
      const representativeDefault = peopleDecisions.find(people => people.isDefault) || null;
      formik.setValues({
        ...formik.values,
        nguoiDaiDienCtyKy: representativeDefault,
        nguoiDaiDienCtyKyId: representativeDefault?.id || "",
        nguoiDaiDienCtyKyText: representativeDefault?.name || "",
        nguoiDaiDienCtyChucDanhId: representativeDefault?.chucVuId || "",
        nguoiDaiDienCtyChucDanhText: representativeDefault?.chucVuText || ""
      });
    }
  }, [peopleDecisions]);

  const handleCloseAndSearchRepresentative = () => {
    getPeopleDecision();
    setIsShowDialogAddRepresentative(false);
  }

  return (
    <>
      <Modal
        show={shouldOpenContractDialog}
        size="xl"
        centered
        aria-labelledby="example-custom-modal-styling-title"
        onHide={handleCloseContractDialog}
        backdrop="static"
      >
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title
              id="example-custom-modal-styling-title"
              className="heading-5"
            >
              {lang("INFO.CONTRACT")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-8 py-2 contract-dialog-content">
            <div className="relative z-index-2">
              <div className="text-header py-2">{lang("CONTRACT.INFO")}</div>
              <div className="grid-cols-5">
                <div className="col-span-1">
                  <Autocomplete
                    isRequired
                    lable={lang("PROFILE.NAME")}
                    options={[]}
                    name="employee"
                    value={formik.values?.employee || null}
                    isReadOnly={true}
                    touched={formik.touched?.employee}
                    errors={formik.errors?.employee}
                  />
                </div>
                <div className="col-span-1">
                  <TextValidator
                    lable={lang("INPUT.CODE_NEW")}
                    isRequired
                    name="maNhanVien"
                    value={formik.values?.employee?.maNhanVien || ""}
                    type="text"
                    readOnly={true}
                    onChange={formik.handleChange}
                    touched={formik.touched?.maNhanVien}
                    errors={formik.errors?.maNhanVien}
                  />
                </div>
                <div className="col-span-1">
                  <Autocomplete
                    isRequired
                    lable={lang("INPUT.DEPARTMENTS")}
                    isReadOnly={!isEdit}
                    options={[]}
                    value={formik.values?.phongBan || null}
                    name="phongBan"
                    onChange={(selectedOption) =>
                      handleChangeSelect("phongBan", selectedOption)
                    }
                    urlData="data.data"
                    searchFunction={searchPhongBanByDonVi}
                    searchObject={{ id: formik.values?.employee?.donViCongTacId }}
                    touched={formik.touched?.phongBan}
                    errors={formik.errors?.phongBan}
                    dependencies={[formik.values?.employee?.donViCongTacId]}
                  />
                </div>
                <div className="col-span-1">
                  <Autocomplete
                    isRequired
                    lable={lang("GENERAL.EMPLOYEE.POSITION")}
                    name="chucVu"
                    value={formik.values?.chucVu || null}
                    searchFunction={searchAllSimpleValue}
                    searchObject={{ type: TYPE_CATEGORY.chucDanh }}
                    isReadOnly={!isEdit || isCollaborators}
                    onChange={(selectedOption) =>
                      handleChangeSelect(VARIABLE_STRING.CHUC_VU, selectedOption)
                    }
                    errors={formik.errors?.chucVu}
                    touched={formik.touched?.chucVu}
                    options={[]}
                    getOptionLabel={(option) => option?.value}
                  />
                </div>
                <div className="">
                  <Autocomplete
                    lable={lang("GENERAL.EMPLOYEE.TITLE")}
                    name="chucDanh"
                    isRequired={!isCollaborators}
                    value={formik.values?.chucDanh || null}
                    searchFunction={searchAllSimpleValue}
                    searchObject={{ type: TYPE_CATEGORY.viTriCongViec }}
                    isReadOnly={!isEdit || isCollaborators}
                    onChange={(selectedOption) =>
                      handleChangeSelect("chucDanh", selectedOption)
                    }
                    options={[]}
                    getOptionLabel={(option) => option?.value}
                    errors={formik.errors?.chucDanh}
                    touched={formik.touched?.chucDanh}
                  />
                </div>
                <div className="col-span-1 py-2">
                  <Form.Check
                    label={lang("CONTRACT.IS_JOBHOLDER")}
                    checked={formik?.values?.vienChuc}
                    onChange={handleChangeCheckBox}
                    name="vienChuc"
                    readOnly={true}
                    disabled={true}
                    type="radio"
                  />
                </div>
                <div className="col-span-1 py-2">
                  <Form.Check
                    label={lang("CONTARCT.IS_LABOR_CONTRACT")}
                    checked={formik?.values?.hopDongLaoDong}
                    onChange={handleChangeCheckBox}
                    name="hopDongLaoDong"
                    readOnly={true}
                    disabled={true}
                    type="radio"
                  />
                </div>
              </div>
              <div className="text-header py-2">
                {lang("CONTRACT.INFO_DETAIL")}
              </div>
              <div className="grid-cols-5">
                <div className="col-span-1">
                  <Autocomplete
                    isRequired
                    lable={lang("CONTRACT.SIGNINGUNIT")}
                    options={[]}
                    value={formik.values?.donViKyHopDong || null}
                    name="donViKyHopDong"
                    onChange={(value) =>
                      handleChangeSelect(
                        VARIABLE_STRING.DON_VI_KY_HOP_DONG,
                        value
                      )
                    }
                    searchFunction={searchListWorkUnit}
                    touched={formik.touched?.donViKyHopDong}
                    errors={formik.errors?.donViKyHopDong}
                    isReadOnly={!isEdit}
                  />
                </div>
                <div className="col-span-1">
                  <Autocomplete
                    isRequired
                    lable={lang("GENERAL.EMPLOYEE.CONTRACT_TYPE")}
                    options={
                      formik.values?.vienChuc
                        ? contractTypeBienChe
                        : contractTypeHopDong
                    }
                    onChange={(value) =>
                      handleChangeSelect(VARIABLE_STRING.LOAI_HOP_DONG, value)
                    }
                    name="loaiHopDong"
                    value={formik.values?.loaiHopDong || null}
                    isReadOnly={!isEdit}
                    touched={formik.touched?.loaiHopDong}
                    errors={formik.errors?.loaiHopDong}
                    dependencies={[
                      formik.values?.vienChuc,
                      formik.values?.hopDongLaoDong
                    ]}
                  />
                </div>
                <div className="col-span-1">
                  <TextValidator
                    isRequired={formik.values.loaiHopDong?.code !== CODE_HOP_DONG.THU_VIEC}
                    lable={lang("CONTRACT.NUMBER")}
                    name="soHopDong"
                    value={formik.values?.soHopDong || ""}
                    type="text"
                    readOnly={!isEdit}
                    onChange={formik.handleChange}
                    touched={formik.touched?.soHopDong}
                    errors={formik.errors?.soHopDong}
                  />
                </div>
                <div className="col-span-1">
                  <TextValidator
                    lable={lang("CONTRACT.NAME")}
                    name="tenHopDong"
                    value={formik.values?.tenHopDong || ""}
                    type="text"
                    readOnly={!isEdit}
                    onChange={formik.handleChange}
                  />
                </div>
                <div className="col-span-1">
                  <Autocomplete
                    lable={lang("CONTRACT.WORK_SHIFT")}
                    options={workShifts}
                    onChange={(value) =>
                      handleChangeSelect(VARIABLE_STRING.CA_LAM_VIEC, value)
                    }
                    name="caLamViec"
                    value={formik.values?.caLamViec || null}
                    isReadOnly={!isEdit}
                  />
                </div>
                <div className="col-span-1">
                  <TextValidator
                    lable={lang("CONTRACT.EFFECTIVEDATE")}
                    name="ngayCoHieuLuc"
                    value={formik.values?.ngayCoHieuLuc || ""}
                    type="date"
                    isRequired
                    readOnly={!isEdit}
                    onChange={handleChangeNgayCoHieuLuc}
                    onBlur={() =>
                      handleBlurDate(
                        formik.setFieldValue,
                        formik.values?.ngayCoHieuLuc,
                        "ngayCoHieuLuc"
                      )
                    }
                    errors={formik.errors?.ngayCoHieuLuc}
                    touched={formik.touched?.ngayCoHieuLuc}
                  />
                </div>
                <div className="col-span-1">
                  <TextValidator
                    lable={lang("CONTRACT.EXPIRATIONDATE")}
                    name="ngayHetHan"
                    value={formik.values?.ngayHetHan || ""}
                    type="date"
                    readOnly={!isEdit || isHopDongDaiHan}
                    isRequired={!isHopDongDaiHan}
                    onChange={formik.handleChange}
                    onBlur={() =>
                      handleBlurDate(
                        formik.setFieldValue,
                        formik.values?.ngayHetHan,
                        "ngayHetHan"
                      )
                    }
                    errors={formik.errors?.ngayHetHan}
                    touched={formik.touched?.ngayHetHan}
                  />
                </div>
                <div className="col-span-1">
                  <Autocomplete
                    lable={lang("CONTRACT.SALARY.LEVEL")}
                    name="bacLuongOption"
                    isRequired={!isCollaborators && !isLumpSum}
                    isReadOnly={isView || isCollaborators || isLumpSum}
                    value={formik.values?.bacLuongOption || ""}
                    searchFunction={getBacLuongByChucDanh}
                    searchObject={formik.values?.chucDanh?.id}
                    sort={(data: any) =>
                      data.sort((a: any, b: any) => a.bacLuong - b.bacLuong)
                    }
                    urlData="data.data"
                    onChange={(selectedOption) =>
                      handleChangeSelect("bacLuongOption", selectedOption)
                    }
                    options={[]}
                    getOptionLabel={(option) => option?.bacLuong}
                    dependencies={[formik.values?.chucDanh?.id]}
                    errors={formik.errors?.bacLuongOption}
                    touched={formik.touched?.bacLuongOption}
                  />
                </div>
                <div className="col-span-1">
                  <TextValidator
                    lable={lang("CONTRACT.SALARY.COEFFICIENT")}
                    name="heSoLuong"
                    isRequired={!isCollaborators && !isLumpSum}
                    value={formik.values?.heSoLuong || ""}
                    type="number"
                    readOnly={true}
                    onChange={formik.handleChange}
                    errors={formik.errors?.heSoLuong}
                    touched={formik.touched?.heSoLuong}
                  />
                </div>
                <div className="col-span-1">
                  <TextValidator
                    lable={lang("CONTRACT.BASESALARY")}
                    name="luongCoBan"
                    isRequired={isCollaborators || isLumpSum}
                    value={convertNumberPrice(formik.values?.luongCoBan)}
                    type="text"
                    readOnly={!isEdit}
                    onChange={handleChangeMoney}
                    errors={formik.errors?.luongCoBan}
                    touched={formik.touched?.luongCoBan}
                  />
                </div>
                <div className="col-span-1">
                  <TextValidator
                    lable={lang("CONTRACT.SALARY_PERCENT")}
                    name="tyLeHuongLuong"
                    value={formik.values?.tyLeHuongLuong || ""}
                    type="number"
                    readOnly={!isEdit}
                    onChange={formik.handleChange}
                  />
                </div>
                <div className="col-span-1">
                  <TextValidator
                    lable={lang("CONTRACT.INSURANCECONTRIBUTION")}
                    name="luongDongBaoHiem"
                    value={convertNumberPrice(formik.values?.luongDongBaoHiem)}
                    type="text"
                    readOnly={!isEdit}
                    onChange={handleChangeMoney}
                  />
                </div>
                <div className="col-span-1">
                  <Autocomplete
                    isRequired
                    lable={lang("CONTRACT.STATUS")}
                    options={contractStatus}
                    onChange={(value) =>
                      handleChangeSelect(
                        VARIABLE_STRING.TRANG_THAI_HOP_DONG,
                        value
                      )
                    }
                    name="trangThaiHopDong"
                    value={formik.values?.trangThaiHopDong || null}
                    isReadOnly={!isEdit}
                    errors={formik.errors?.trangThaiHopDong}
                    touched={formik.touched?.trangThaiHopDong}
                  />
                </div>
                <div className="col-span-1">
                  <UploadFile 
                    label="CONTRACT.ATTACHMENTS"
                    setValue={handleFile}
                    isReadOnly={!isEdit}
                    fileValue={{
                      id: formik.values.fileId || "",
                      name: formik.values.fileName || ""
                    }}
                  />
                </div>
              </div>
              <div className="mt-4">
                <TextValidator
                  lable={lang("CONTRACT.NOTE")}
                  name="ghiChu"
                  value={formik.values?.ghiChu}
                  type="text"
                  readOnly={isView}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="text-header py-2">
                {lang("CONTRACT.SALARY_SIGN")}
              </div>
              <div className="grid-cols-5 pb-4">
                <div className="col-span-1">
                  <Form.Check
                    label={lang("CONTRACT.PAPER_AUTHORITY")}
                    checked={formik.values?.giayUyQuyen}
                    onChange={handleChangeCheckBox}
                    className="custom-form-check"
                    readOnly={!isEdit}
                    name="giayUyQuyen"
                  />
                </div>
                <div className="col-span-1">
                  <Autocomplete
                    isRequired
                    lable={lang("CONTRACT.SIGNEDCOMPANYREPRESENTATIVE")}
                    options={peopleDecisions}
                    value={formik.values?.nguoiDaiDienCtyKy || null}
                    name="nguoiDaiDienCtyKy"
                    onChange={(value) =>
                      handleChangeSelect(VARIABLE_STRING.NGUOI_DAI_DIEN_KY, value)
                    }
                    isAddNew
                    onAddNew={() => setIsShowDialogAddRepresentative(true)}
                    isReadOnly={!isEdit}
                    errors={formik.errors?.nguoiDaiDienCtyKy}
                    touched={formik.touched?.nguoiDaiDienCtyKy}
                  />
                </div>
                <div className="col-span-1">
                  <TextValidator
                    lable={lang("CONTRACT.REPRESENTATIVETITLE")}
                    name="nguoiDaiDienCtyChucDanh"
                    value={formik.values?.nguoiDaiDienCtyChucDanhText || ""}
                    type="text"
                    readOnly={true}
                  />
                </div>
                <div className="col-span-1">
                  <TextValidator
                    lable={lang("ANNEX.SIGNINGDATE")}
                    name="ngayKyHopDong"
                    value={formik.values?.ngayKyHopDong || ""}
                    type="date"
                    // isRequired
                    readOnly={!isEdit}
                    onBlur={() =>
                      handleBlurDate(
                        formik.setFieldValue,
                        formik.values?.ngayKyHopDong,
                        "ngayKyHopDong"
                      )
                    }
                    onChange={formik.handleChange}
                    errors={formik.errors?.ngayKyHopDong}
                    touched={formik.touched?.ngayKyHopDong}
                  />
                </div>
                <div className="col-span-1">
                  <Autocomplete
                    lable={lang("CONTRACT.SIGNINGSTATUS")}
                    options={signinStatus}
                    value={formik.values?.trangThaiKy || null}
                    name="trangThaiKy"
                    onChange={(value) =>
                      handleChangeSelect(VARIABLE_STRING.TRANG_THAI_KY, value)
                    }
                    menuPlacement="top"
                    // isRequired
                    isReadOnly={!isEdit}
                    errors={formik.errors?.trangThaiKy}
                    touched={formik.touched?.trangThaiKy}
                  />
                </div>
              </div>
            </div>
            {contractEdit?.id && (
              <>
                <TableCustom
                  id="contractAnnex"
                  title={lang("CONTRACT.ANNEX")}
                  className="pt-4"
                  data={contractAnnexList}
                  columns={ContractAnnexColumn()}
                  handleDelete={(ids: string) =>
                    handleDeleteItems(
                      deleteMultipleContractAnnex,
                      updateDataContractAnnex,
                      ids
                    )
                  }
                  isActionTableTab
                  buttonAdd={
                    isEdit &&
                    formik.values?.trangThaiHopDong?.code !==
                    codeStatusContract.EXPIRE
                  }
                  buttonExportExcel={true}
                  type={TYPE.MULTILINE}
                  fixedColumnsCount={3}
                  noToolbar={true}
                  noPagination={true}
                  notDelete={true}
                  handleExportExcel={() => 
                    exportToFile({
                      exportAPI: () => exportHopDongPhuLuc(contractEdit.id), 
                      fileName: EXPORT_FILE_NAME.PHU_LUC_HD,
                      setPageLoading
                    })
                  }
                  handleOpenDialog={(row) =>
                    handleOpenDialog(
                      annexInfo,
                      setShouldOpenAddContractAnnex,
                      setAnnexInfo
                    )
                  }
                  handleDoubleClick={(row: any) =>
                    handleOpenDialog(
                      row,
                      setShouldOpenAddContractAnnex,
                      setAnnexInfo
                    )
                  }
                />
                <div className="spaces mt-16">
                  <Allowance
                    isView={!isEdit}
                    isDisplayBtnAdd={
                      formik.values?.trangThaiHopDong?.code !==
                      codeStatusContract.EXPIRE
                    }
                    identify={contractEdit?.employeeId}
                    isCallApi={true}
                  />
                </div>
              </>
            )}
          </Modal.Body>

          <Modal.Footer className="flex-center">
            <GroupButton type="btn-cancel" handleCloseUpdateDialog={handleCloseContractDialog} />
            {!isResign &&
              (isEdit ? (
                <GroupButton type="btn-save" />
              ) : 
                <GroupButton type="btn-edit" handleSaveEdit={() => setIsEdit(true)} />
              )}

              {(contractEdit?.id && templateWord) && (
                <GroupButton handleEvent={() => setIsShowDialogWord(true)}>
                  {lang("GENERAL.EXPORT_WORD")}
                </GroupButton>
              )}
          </Modal.Footer>
        </Form>
      </Modal>
      {shouldOpenAddContractAnnex && (
        <AddNewContractAnnex
          isView={!isEdit}
          annexInfo={annexInfo}
          contractInfo={contractEdit}
          getListAnnex={updateDataContractAnnex}
          handleCloseAddContractAnnex={() =>
            handleCloseDialog(
              INIT_CONTRACT_ANNEX_INFO,
              setShouldOpenAddContractAnnex,
              setAnnexInfo
            )
          }
          isCollaborators={isCollaborators}
        />
      )}

      {isShowDialogWord && (
        <ExportWord
          open={isShowDialogWord}
          handleClose={() => setIsShowDialogWord(false)}
          templateList={templateWord || []}
          getObj={{
            employeeId: contractEdit?.employeeId,
            hopDongId: contractEdit?.id,
            hopDongType: contractEdit?.loaiHopDong?.code,
            type: DATA_WORD_TYPES.CONSTRACT
          }}
          customFileName={(data, nameBase) => `${data.fullName} (${nameBase})`}
        />
      )}\
      {isShowDialogAddRepresentative && (
        <AddNewRepresentative
          handleClose={() => setIsShowDialogAddRepresentative(false)}
          handleCloseAndSearch={handleCloseAndSearchRepresentative}
        />
      )}
    </>
  );
};

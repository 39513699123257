import moment from "moment";
import { FORMAT_DATE } from "../../constants/moduleConsts";
import { IParamsSearchShiftComprehension } from "../models/shiftDivisionModels";
import { formatDateTable } from "../../utils/functionUtils";

export const SUMMARY_SHIFT_TYPE = {
  SCHEDULE: 1,
  ACTUAL: 2
}

export const SUMMARY_SHIFT_TYPE_LIST = [
  {
    code: SUMMARY_SHIFT_TYPE.SCHEDULE,
    name: "Lịch đăng ký"
  },
  {
    code: SUMMARY_SHIFT_TYPE.ACTUAL,
    name: "Lịch thực tế"
  }
];

export const INIT_PARAMS_SEARCH_SHIFT_COMPREHENSION: IParamsSearchShiftComprehension = {
  ngayBatDau: moment().startOf('isoWeek').format(FORMAT_DATE.YYYY_MM_DD_INPUT),
  summaryShiftType: SUMMARY_SHIFT_TYPE_LIST.find(t => t.code === SUMMARY_SHIFT_TYPE.ACTUAL) ?? null
}

export const TYPE_OBJECT_SCHEDULE = {
  DIEU_DUONG: 1,
  BAC_SI: 2
}

export const LIST_OBJECT_SCHEDULE = [
  {
    code: TYPE_OBJECT_SCHEDULE.DIEU_DUONG,
    title: "TIMEKEEPING.SHIFT.SCHEDULE.NURSING",
    name: "Điều dưỡng"
  },
  {
    code: TYPE_OBJECT_SCHEDULE.BAC_SI,
    title: "TIMEKEEPING.SHIFT.SCHEDULE.DOCTOR",
    name: "Bác sĩ"
  }
]

export const REF_TAB_SHIFT_DIVISION = {
  DIEU_DUONG: "DIEU_DUONG",
  BAC_SI: "BAC_SI",
}

export const LIST_TAB_SHIFT_COMPREHENSION = [
  {
    id: REF_TAB_SHIFT_DIVISION.DIEU_DUONG,
    nameTab: "Điều dưỡng",
    title: (timeStart: string | null, timeEnd: string | null) => 
      `Lịch phân trực điều dưỡng năm ${moment(timeStart).format(FORMAT_DATE.YYYY)}<br />
        (Từ ngày ${timeStart ? formatDateTable(timeStart) : ".........."} đến ngày ${timeEnd ? formatDateTable(timeEnd) : ".........."})`,
    doiTuong: TYPE_OBJECT_SCHEDULE.DIEU_DUONG
  },
  {
    id: REF_TAB_SHIFT_DIVISION.BAC_SI,
    nameTab: "Bác sĩ",
    title: (timeStart: string | null, timeEnd: string | null) => 
      `Lịch phân trực bác sĩ năm ${moment(timeStart).format(FORMAT_DATE.YYYY)}<br />
        (Từ ngày ${timeStart ? formatDateTable(timeStart) : ".........."} đến ngày ${timeEnd ? formatDateTable(timeEnd) : ".........."})`,
    doiTuong: TYPE_OBJECT_SCHEDULE.BAC_SI
  }
]
import { useFormik } from "formik";
import { FC } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import * as Yup from "yup";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import TextValidator from "../../../components/input/text-validator";
import { ReceivableInfo } from "../../models/resignModels";
import { convertNumberPrice, convertTextPrice, generateRandomId } from "../../../utils/functionUtils";
import { GroupButton } from "../../../components/GroupButton";
interface IProps {
  handleClose: () => void;
  receivableInfo: ReceivableInfo;
  isView?: boolean;
  handleDialogSubmit: (value: ReceivableInfo) => void;
}
const ReceivableDialog: FC<IProps> = (props) => {
  const { lang } = useMultiLanguage();
  const { handleClose, isView = false, receivableInfo, handleDialogSubmit } = props;

  const validationSchema = Yup.object().shape({
    khoanThu: Yup.string()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    giaTri: Yup.number()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
  });

  const handleSubmit = async (values: ReceivableInfo) => {
    let _values = {
      ...values,
      ...((!values?.id) && {
        id: generateRandomId()
      })
    }
    handleDialogSubmit(_values);
    handleClose();
  };

  const formik = useFormik<ReceivableInfo>({
    initialValues: receivableInfo,
    validationSchema,
    onSubmit: handleSubmit
  })

  const handleChangeMoney = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, Number(convertTextPrice(value)));
  };

  return (
    <>
      <Modal
        show={true}
        size="xl"
        centered
        aria-labelledby="example-custom-modal-styling-title"
        onHide={handleClose}
        backdrop="static"
        className="custom-modal"
      >
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title
              id="example-custom-modal-styling-title"
              className="heading-5"
            >
              {lang(!receivableInfo?.id ? "RECEIVABLE.ADD" : "RECEIVABLE.EDIT")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col xs={4}>
                <Row>
                  <Col xs={12}>
                    <TextValidator
                      isRequired
                      lable={lang("GENERAL.RECEIVABLES")}
                      name="khoanThu"
                      type="text"
                      readOnly={isView}
                      onChange={formik.handleChange}
                      value={formik?.values?.khoanThu || ""}
                      errors={formik?.errors?.khoanThu}
                      touched={formik.touched?.khoanThu}
                    />
                  </Col>
                  <Col xs={12}>
                    <TextValidator
                      isRequired
                      className="spaces mt-16"
                      name="giaTri"
                      lable={lang("GENERAL.ALLOWANCE.VALUE")}
                      type="text"
                      readOnly={isView}
                      onChange={handleChangeMoney}
                      value={convertNumberPrice(formik.values?.giaTri || null)}
                      errors={formik?.errors?.giaTri}
                      touched={formik?.touched?.giaTri}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={8}>
                <TextValidator
                  lable={lang("INPUT.QUALIFICATION.NOTE")}
                  name="ghiChu"
                  type="text"
                  as="textarea"
                  rows="4"
                  value={formik.values?.ghiChu}
                  readOnly={isView}
                  onChange={formik.handleChange}
                />
              </Col>
            </Row>
          </Modal.Body>
          {!isView && (
            <Modal.Footer className="flex-center">
              <GroupButton type="btn-cancel" handleCloseUpdateDialog={handleClose} />
              <GroupButton type="btn-save" />
            </Modal.Footer>
          )}
        </Form>
      </Modal>
    </>
  );
};
export { ReceivableDialog };


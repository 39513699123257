import { OptionReactSelect } from "../../models/models";
import { InterruptInfo } from "../models/interruptModels";
import { MISSION_STATUS, TYPE, TYPE_CATEGORY } from "../../constants/moduleConsts";
import { searchAllSimpleValue, searchPhongBan } from "../../services/services";

export const INIT_INTERRUPT_INFO: InterruptInfo = {
	employee: null,
	employeeCode: "",
	employeeId: "",
	chucVu: null,
	chucVuId: "",
	chucVuText: "",
	phongBan: null,
	phongBanId: "",
	phongBanText: "",
	viTriCongViec: null,
	viTriCongViecId: "",
	viTriCongViecText: "",
	createDateTime: null,
	createdBy: "",
	loaiNghi: null,
	ngayBatDau: "",
	ngayQuayLaiLV: "",
	lyDoNghi: "",
	soQuyetDinh: "",
	nguoiKy: null,
	nguoiKyId: "",
	nguoiKyText: "",
	fileId: "",
	fileName: "",
	trangThaiBaoHiem: null,
	thoiGianDungDongBH: "",
	hinhThucDieuChinh: null,
	thoiGianThucHien: "",
	modifiedBy: "",
	modifyDate: null
}

export const listInterruptType: OptionReactSelect[] = [
	{
		code: 1,
		name: "Nghỉ thai sản"
	},
	{
		code: 2,
		name: "Nghỉ ốm"
	},
	{
		code: 3,
		name: "Nghỉ không lương"
	},
	{
		code: 4,
		name: "Đình chỉ công tác"
	},
	{
		code: 5,
		name: "Lý do khác"
	},
]

export const FIELD_SEARCH_INTERRUPT = [
	{
		name: "Mã nhân viên",
		field: "code",
		type: TYPE.TEXT
	},
	{
		name: "Họ và tên",
		field: "name",
		type: TYPE.TEXT
	},
	{
		name: "Khoa/Phòng",
		field: "phongBanIds",
		type: TYPE.SELECT,
		searchFunction: searchPhongBan,
		isMulti: true,
	},
	{
		name: "Chức vụ",
		field: "chucVuId",
		searchFunction: searchAllSimpleValue,
		searchObject: { type: TYPE_CATEGORY.chucDanh },
		type: TYPE.SELECT,
		optionLabel: "value"
	},
	{
		name: "Chức danh",
		field: "viTriCongViecIds",
		searchFunction: searchAllSimpleValue,
		searchObject: { type: TYPE_CATEGORY.viTriCongViec },
		type: TYPE.SELECT,
		optionLabel: "value",
		isMulti: true,
	},
	{
		name: "Loại nghỉ",
		field: "loaiNghi",
		type: TYPE.SELECT,
		listOption: listInterruptType
	},
	{
		name: "Ngày bắt đầu",
		field: "ngayBatDau",
		type: TYPE.DATE
	},
	{
		name: "Ngày trở lại làm việc",
		field: "ngayQuayLaiLV",
		type: TYPE.DATE
	},
	{
		name: "Số quyết định",
		field: "soQuyetDinh",
		type: TYPE.TEXT
	},
	{
		name: "Trạng thái",
		field: "trangThai",
		type: TYPE.SELECT,
		listOption: MISSION_STATUS,
		isMulti: true,
	},
];

import { IColumnsGrouping } from "../../../components/table-grouping/TableGrouping";

export const columnStatistical: IColumnsGrouping[] = [
  {
    name: "STT",
    field: "",
    render: (row, index, stt) => <span>{stt}</span>,
    cellStyle: {
      fontSize: "16px"
    },
    isSticky: true
  },
  {
    name: "Đơn vị",
    field: "donVi",
    headerStyle: {
      minWidth: "300px"
    },
    cellStyle: {
      fontSize: "16px"
    },
    isSticky: true
  },
  {
    name: "Tổng số nhân lực trong biên chế được giao (theo TB của SNV)",
    field: "tongSoNhanLucDuocGiao",
    headerStyle: {
      minWidth: "200px"
    },
    cellStyle: {
      fontSize: "16px"
    }
  },
  {
    name: "Tổng số nhân lực trong biên chế hiện có",
    field: "tongSoNhanLucHienCo",
    headerStyle: {
      minWidth: "200px"
    },
    cellStyle: {
      fontSize: "16px"
    }
  },
  {
    name: "Cột kiểm tra độ tuổi",
    field: "kiemTraDoTuoi",
    headerStyle: {
      minWidth: "100px"
    },
    cellStyle: {
      fontSize: "16px"
    }
  },
  {
    name: "Cột kiểm tra trình độ chuyên môn",
    field: "kiemTraTrinhDoChuyenMon",
    headerStyle: {
      minWidth: "120px"
    },
    cellStyle: {
      fontSize: "16px"
    }
  },
  {
    name: "Trong đó",
    field: "",
    child: [
      {
        name: "Công chức, viên chức",
        field: "congChucVienChuc",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Hợp đồng 111",
        field: "hopDong111",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Nữ",
        field: "gioiTinhNu",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Dân  tộc thiểu  số",
        field: "danTocThieuSo",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Đảng viên",
        field: "dangVien",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Có tôn giáo",
        field: "coTonGiao",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Tuổi <30",
        field: "doTuoiDuoi30",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Tuổi 30 - 50",
        field: "doTuoi3150",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Tuổi ≥ 51 ",
        field: "doTuoiTren50",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Thầy thuốc nhân dân",
        field: "thayThuocNhanDan",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Thầy thuốc ưu tú",
        field: "thayThuocUuTu",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Giáo sư",
        field: "giaoSu",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Phó giáo sư",
        field: "phoGiaoSu",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Thương binh",
        field: "thuongBinh",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Con liệt sỹ",
        field: "conLietSy",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Con bà mẹ VNAH",
        field: "conBaMeVNAH",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      }
    ]
  },
  {
    name: "Tổng số Bác sỹ",
    field: "tongSoBacSi",
    headerStyle: {
      minWidth: "120px"
    },
    cellStyle: {
      fontSize: "16px"
    }
  },
  {
    name: "Trong đó",
    field: "",
    child: [
      {
        name: "Nữ",
        field: "bacSiNu",
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Tiến sỹ, CK II",
        field: "bacSiTienSi",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Thạc sỹ, CKI",
        field: "bacSiThacSi",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Đại học",
        field: "bacSiDaiHoc",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      }
    ]
  },
  {
    name: "Tổng số Y sỹ",
    field: "tongSoYSi",
    headerStyle: {
      minWidth: "120px"
    },
    cellStyle: {
      fontSize: "16px"
    }
  },
  {
    name: "Trong đó",
    field: "",
    child: [
      {
        name: "Nữ",
        field: "ysiNu",
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Y sỹ đa khoa",
        field: "ysiDaKhoa",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Y sỹ sản nhi",
        field: "ysiSanNhi",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Y sỹ YHCT",
        field: "ysiYhct",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      }
    ]
  },
  {
    name: "Tổng số Dược sỹ",
    field: "tongSoDuocSi",
    headerStyle: {
      minWidth: "120px"
    },
    cellStyle: {
      fontSize: "16px"
    }
  },
  {
    name: "Trong đó",
    field: "",
    child: [
      {
        name: "Nữ",
        field: "duocSiNu",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Tiến sỹ, CK II",
        field: "duocSiTienSi",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Thạc sỹ, CKI",
        field: "duocSiThacSi",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Đại học",
        field: "duocSiDaiHoc",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Cao đẳng",
        field: "duocSiCaoDang",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Trung cấp",
        field: "duocSiTrungCap",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Sơ cấp, Dược tá",
        field: "duocSiSoCap",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      }
    ]
  },
  {
    name: "Tổng số YT CC",
    field: "tongSoYtcc",
    headerStyle: {
      minWidth: "120px"
    },
    cellStyle: {
      fontSize: "16px"
    }
  },
  {
    name: "Trong đó",
    field: "",
    child: [
      {
        name: "Nữ",
        field: "ytccNu",
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Tiến sỹ, CK II",
        field: "ytccTienSi",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Thạc sỹ, CKI",
        field: "ytccThacSi",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Đại học",
        field: "ytccDaiHoc",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      }
    ]
  },
  {
    name: "Tổng số Điều dưỡng",
    field: "tongSoDieuDuong",
    headerStyle: {
      minWidth: "120px"
    },
    cellStyle: {
      fontSize: "16px"
    }
  },
  {
    name: "Trong đó",
    field: "",
    child: [
      {
        name: "Nữ",
        field: "dieuDuongNu",
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Thạc sỹ",
        field: "dieuDuongThacSi",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Đại học",
        field: "dieuDuongDaiHoc",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Cao đẳng",
        field: "dieuDuongCaoDang",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Trung cấp",
        field: "dieuDuongTrungCap",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Sơ cấp",
        field: "dieuDuongSoCap",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      }
    ]
  },
  {
    name: "Tổng số Hộ sinh",
    field: "tongSoHoSinh",
    headerStyle: {
      minWidth: "120px"
    },
    cellStyle: {
      fontSize: "16px"
    }
  },
  {
    name: "Trong đó",
    field: "",
    child: [
      {
        name: "Nữ",
        field: "hoSinhNu",
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Thạc sỹ",
        field: "hoSinhThacSi",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Đại học",
        field: "hoSinhDaiHoc",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Cao đẳng",
        field: "hoSinhCaoDang",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Trung cấp",
        field: "hoSinhTrungCap",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Sơ cấp",
        field: "hoSinhSoCap",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      }
    ]
  },

  {
    name: "Tổng số Kỹ thuật Y",
    field: "tongSoKyThuatY",
    headerStyle: {
      minWidth: "120px"
    },
    cellStyle: {
      fontSize: "16px"
    }
  },
  {
    name: "Trong đó",
    field: "",
    child: [
      {
        name: "Nữ",
        field: "kyThuatYNu",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Thạc sỹ",
        field: "kyThuatYThacSi",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Đại học",
        field: "kyThuatYDaiHoc",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Cao đẳng",
        field: "kyThuatYCaoDang",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Trung cấp",
        field: "kyThuatYTrungCap",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Sơ cấp",
        field: "kyThuatYSoCap",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      }
    ]
  },
  {
    name: "Cán bộ dân số",
    field: "tongSoCanBoDanSo",
    headerStyle: {
      minWidth: "120px"
    },
    cellStyle: {
      fontSize: "16px"
    }
  },
  {
    name: "Trong đó",
    field: "",
    child: [
      {
        name: "Nữ",
        field: "canBoDanSoNu",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Thạc sỹ",
        field: "canBoDanSoThacSi",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Đại học",
        field: "canBoDanSoDaiHoc",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Cao đẳng",
        field: "canBoDanSoCaoDang",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Trung cấp",
        field: "canBoDanSoTrungCap",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Sơ cấp",
        field: "canBoDanSoSoCap",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      }
    ]
  },
  {
    name: "Chức danh khác",
    field: "tongSoChucDanhKhac",
    headerStyle: {
      minWidth: "120px"
    },
    cellStyle: {
      fontSize: "16px"
    }
  },
  {
    name: "Trong đó",
    field: "",
    child: [
      {
        name: "Nữ",
        field: "chucDanhKhacNu",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Thạc sỹ",
        field: "chucDanhKhacThacSi",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Đại học",
        field: "chucDanhKhacDaiHoc",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Cao đẳng",
        field: "chucDanhKhacCaoDang",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Trung cấp",
        field: "chucDanhKhacTrungCap",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      },
      {
        name: "Sơ cấp, khác",
        field: "chucDanhKhacSoCap",
        headerStyle: {
          minWidth: "120px"
        },
        cellStyle: {
          fontSize: "16px"
        }
      }
    ]
  }
];

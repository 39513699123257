import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { INIT_EMPLOYEE_JOIN } from "../constants/welfareConsts";
import TextValidator from "../../components/input/text-validator";
import TableCustom, { columnNamesType } from "../../components/table-custom/TableCustom";
import { IAttachedPeople, IEmployeeJoin } from "../models/welfareModels";
import AttachDialog from "./AttachDialog";
import { convertNumberPrice, convertTextPrice, formatDateTable } from "../../utils/functionUtils";
import { TYPE } from "../../constants/moduleConsts";
import { GroupButton } from "../../components/GroupButton";

interface Props {
  handleCloseDialog: () => void;
  isView: boolean;
  employeeJoin: IEmployeeJoin;
  setEmployeeJoinData: (data: IEmployeeJoin[]) => void;
  employeeJoinData: IEmployeeJoin[];
}
export const EmployeeJoinDialog = (props: Props) => {
  const { lang } = useMultiLanguage();

  const { handleCloseDialog, employeeJoin, isView, setEmployeeJoinData, employeeJoinData } = props;

  const [shouldOpenDialog, setShouldOpenDialog] = useState<boolean>(false);
  const [attachInfo, setAttachInfo] = useState<IAttachedPeople>({} as IAttachedPeople);
  const [attachList, setAttachList] = useState<IAttachedPeople[]>([]);

  useEffect(() => {
    if (employeeJoin) {
      formik.setValues(employeeJoin);
      setAttachList(employeeJoin.phucLoiThamGiaRequestDtoList || []);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeJoin]);

  const handleFormSubmit = (dataForm: IEmployeeJoin) => {
    let { isChecked, ...dataFormFilter } = dataForm;
    let dataAfterEdit = employeeJoinData?.map((item) => {
      let employeeEdited = item?.data?.map((itemEmployee: any) => {
        return itemEmployee.employeeId === dataForm.employeeId
          ? { ...dataFormFilter, phucLoiThamGiaRequestDtoList: attachList }
          : itemEmployee;
      });
      return { ...item, data: employeeEdited };
    });

    setEmployeeJoinData(dataAfterEdit);
    handleCloseDialog();
  };

  const formik = useFormik({
    initialValues: INIT_EMPLOYEE_JOIN,
    onSubmit: handleFormSubmit
  });

  const handleDoubleClick = (row: any, index: number) => {
    setShouldOpenDialog(true);
    setAttachInfo(row);
  };

  const handleOpenDialog = () => {
    setShouldOpenDialog(true);
    setAttachInfo({} as IAttachedPeople);
  };

  const handleChangeMoney = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, Number(convertTextPrice(value)));
  };

  //Danh sách đính kèm

  const handleDeleteAttach = (ids: string) => {
    const listIdRemove = ids.split(',');
    let attachListDeleted = attachList.filter(item => !listIdRemove.includes(item?.id ?? ""));
    setAttachList(attachListDeleted);
    return true;
  };

  const handleAddAttach = (data: IAttachedPeople) => {
    setAttachList([...attachList, data]);
  };

  const handleEditAttach = (data: IAttachedPeople) => {
    let attachListEdited = attachList.map(item => {
      return item.id === data.id ? data : item;
    });
    setAttachList(attachListEdited);
  };

  const attachedPeopleColumns: columnNamesType[] = [
    {
      name: lang("TABLE.INDEX"),
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("WELFARE.EMPLOYEE.FULLNAME"),
      field: "ten",
      headerStyle: {
        minWidth: "250px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("INPUT.FAMILY.RELATION"),
      field: "quanHeText",
      headerStyle: {
        minWidth: "200px"
      }
    },
    {
      name: lang("PROFILE.BIRTHDAY"),
      field: "ngaySinh",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row) => <span>{formatDateTable(row?.ngaySinh || "")}</span>
    },
    {
      name: lang("PROFILE.GENDER"),
      field: "gender",
      headerStyle: {
        minWidth: "150px"
      },
      render: (row: any) => <span>{row?.gender?.name}</span>
    },
    {
      name: lang("WELFARE.MONEY.MUST_TURN_IN"),
      field: "soTienDong",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row) => <span>{convertNumberPrice(row?.soTienDong)}</span>
    },
    {
      name: lang("WELFARE.MONEY.UNIT_SUPORT"),
      field: "soTienDonViHoTro",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row) => <span>{convertNumberPrice(row?.soTienDonViHoTro)}</span>
    },
    {
      name: lang("GENERAL.NOTE"),
      field: "note",
      headerStyle: {
        minWidth: "350px"
      },
      cellStyle: {
        textAlign: "left"
      }
    }
  ];

  return (
    <Modal
      show={true}
      size="xl"
      centered
      aria-labelledby="example-custom-modal-styling-title"
      onHide={handleCloseDialog}
      backdrop="static"
    >
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title
            id="example-custom-modal-styling-title"
            className="heading-5"
          >
            {lang("WELFARE.EMPLOYEE.JOIN.INFO")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12}>
              <div className="text-header py-2">{lang("GENERAL.INFO")}</div>
            </Col>
            <Col xs={12}>
              <Row className="g-4 px-4 relative z-index-2">
                <Col xs={4}>
                  <TextValidator
                    name="ten"
                    isRequired
                    lable={lang("WELFARE.EMPLOYEE.FULLNAME")}
                    type="text"
                    readOnly={true}
                    value={formik.values?.ten || ""}
                    touched={formik.touched?.ten}
                    errors={formik.errors?.ten}
                  />
                </Col>
                <Col xs={4}>
                  <TextValidator
                    name="maNhanVien"
                    isRequired
                    lable={lang("WELFARE.EMPLOYEE.CODE")}
                    type="text"
                    readOnly={true}
                    value={formik.values?.maNhanVien || ""}
                    touched={formik.touched?.maNhanVien}
                    errors={formik.errors?.maNhanVien}
                  />
                </Col>
                <Col xs={4}>
                  <TextValidator
                    name="donViApDungText"
                    isRequired
                    lable={lang("WELFARE.APPLY.UNIT")}
                    type="text"
                    value={formik.values?.donViApDungText || ""}
                    readOnly={true}
                    touched={formik.touched?.donViApDungText}
                    errors={formik.errors?.donViApDungText}
                  />
                </Col>
                <Col xs={4}>
                  <TextValidator
                    name="phongBanText"
                    isRequired
                    lable={lang("WELFARE.EMPLOYEE.DEPARTMENT")}
                    type="text"
                    value={formik.values?.phongBanText || ""}
                    readOnly={true}
                    touched={formik.touched?.phongBanText}
                    errors={formik.errors?.phongBanText}
                  />
                </Col>
                <Col xs={4}>
                  <TextValidator
                    name="chucVuText"
                    isRequired
                    lable={lang("JOB.POSITION")}
                    type="text"
                    value={formik.values?.chucVuText || ""}
                    readOnly={true}
                    touched={formik.touched?.chucVuText}
                    errors={formik.errors?.chucVuText}
                  />
                </Col>
                <Col xs={4}>
                  <TextValidator
                    name="soTienNhanVienDong"
                    lable={lang("WELFARE.AMOUNT")}
                    type="text"
                    onChange={handleChangeMoney}
                    value={convertNumberPrice(formik.values?.soTienNhanVienDong || null)}
                    readOnly={isView}
                    touched={formik.touched?.soTienNhanVienDong}
                    errors={formik.errors?.soTienNhanVienDong}
                  />
                </Col>
                <Col xs={4}>
                  <TextValidator
                    name="soTienDonViHoTro"
                    lable={lang("WELFARE.AMOUNT.SUPPORT")}
                    type="text"
                    onChange={handleChangeMoney}
                    value={convertNumberPrice(formik.values?.soTienDonViHoTro || null)}
                    readOnly={isView}
                    touched={formik.touched?.soTienDonViHoTro}
                    errors={formik.errors?.soTienDonViHoTro}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={12}>
              <TableCustom
                id="attachedList"
                title={lang("WELFARE.ATTACHED.LIST")}
                isActionTableTab
                buttonAdd={!isView}
                buttonExportExcel={false}
                notDelete={isView}
                noToolbar={true}
                data={attachList}
                columns={attachedPeopleColumns}
                justFilter={true}
                noPagination={true}
                handleOpenDialog={handleOpenDialog}
                handleDoubleClick={handleDoubleClick}
                unSelectedAll={true}
                type={TYPE.MULTILINE}
                handleDelete={handleDeleteAttach}
                checkedInit={false}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="flex-center">
          <GroupButton type="btn-cancel" handleCloseUpdateDialog={handleCloseDialog} />
          {!isView && (
            <GroupButton type="btn-save" />
          )}
        </Modal.Footer>
      </Form>
      {shouldOpenDialog && (
        <AttachDialog
          open={shouldOpenDialog}
          handleClose={() => setShouldOpenDialog(false)}
          isView={isView}
          attachInfo={attachInfo}
          handleAdd={handleAddAttach}
          handleEdit={handleEditAttach}
        />
      )}
    </Modal>
  );
};

import { FC, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import useMultiLanguage from "../../hook/useMultiLanguage";
import { LIST_TAB_SHIFT_COMPREHENSION, REF_TAB_SHIFT_DIVISION } from "./constants/shiftComprehensionConsts";
import ShiftComprehensionTab from "./components/shift-comprehension/ShiftComprehensionTab";

const ComprehensiveShiftScheduling: FC = () => {
  const { lang } = useMultiLanguage();
  const [activeTab, setActiveTab] = useState<string>(REF_TAB_SHIFT_DIVISION.DIEU_DUONG);

  const handleTabChange = (key: string | null) => {
    key && setActiveTab(key);
  };

  return (
    <div className='shift-comprehension spaces px-8 py-12'>
      <Tabs
        activeKey={activeTab}
        defaultActiveKey={REF_TAB_SHIFT_DIVISION.DIEU_DUONG}
        id="justify-tab-example"
        className="list-tabs"
        onSelect={handleTabChange}
      >
        {LIST_TAB_SHIFT_COMPREHENSION.map(tab => (
          <Tab
            eventKey={tab.id}
            title={lang(tab.nameTab)}
            className="tab-content bg-white"
            id={tab.id}
            mountOnEnter
          >
            <ShiftComprehensionTab
              title={tab.title}
              doiTuong={tab.doiTuong}
            />
          </Tab>
        ))}
      </Tabs>
    </div>
  );
}

export { ComprehensiveShiftScheduling };
import { useState, useContext, useEffect, useCallback } from "react";
import SendPeopleTrainingDialog from "./components/SendPeopleTrainingDialog";
import { toast } from "react-toastify";
import { checkStatus, convertSearch, exportToFile, formatDateTable, hasAuthority, searchDataRoleUser } from "../utils/functionUtils";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../constants";
import { Col, Row } from "react-bootstrap";
import InputSearch from "../components/input/InputSearch";
import TableCustom, { columnNamesType } from "../components/table-custom/TableCustom";
import useMultiLanguage from "../../hook/useMultiLanguage";
import {
  MISSION_STATUS,
  MISSION_STATUS_CODE,
  RESPONSE_STATUS_CODE,
  TYPE, DATA_WORD_TYPES,
  INIT_SEARCH_OBJECT,
  EXPORT_FILE_NAME,
  INIT_INPUT_SEARCH
} from "../constants/moduleConsts";
import AppContext from "../../AppContext";
import { ISendPeopleTraining } from "./models/sendPeopleTrainingModels";
import { deleteSendPeopleTraining, getListSendPeopleTraining } from "./services/sendPeopleTrainingServices";
import { ExportWord, IDataPreview } from "../components/exportWord";
import SendPeopleTrainingWord from "./components/SendPeopleTrainingWord";
import Autocomplete from "../components/input/autocomplete";
import { IItemSearch, IObject } from "../models/models";
import { GroupButton } from "../components/GroupButton";
import { getDataPreviewWord } from "../services/services";
import {exportSendPeopleTraining} from "../services/exportFileServices";
import AdvancedSearch from "../profile/components/dialogs/AdvancedSearch";
import { ListSearch } from "./constants/sendPeopleTrainingConsts";

function SendPeopleTraining() {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  document.title = `${lang("GENERAL.APP.NAME")} | ${lang("GENERAL.DISMISSED")}`;
  const [dataEdit, setDataEdit] = useState<ISendPeopleTraining>({} as ISendPeopleTraining);
  const [receivingCitizensList, setReceivingCitizensList] = useState<ISendPeopleTraining[]>([]);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [searchObject, setSearchObject] = useState<any>(INIT_SEARCH_OBJECT);
  const [isView, setIsView] = useState<boolean>(false)
  const [showSendPeopleTrainingDialog, setShowSendPeopleTrainingDialog] = useState<boolean>(false);
  const [shouldOpenExportWord, setShouldOpenExportWord] = useState<boolean>(false);
  const [dataChecked, setDataChecked] = useState<ISendPeopleTraining[]>([]);
  const [listDataPreview, setListDataPreview] = useState<IDataPreview[]>([]);
  const [dataWord, setDataWord] = useState<IDataPreview>({} as IDataPreview);
  const [isAdvancedSearch, setIsAdvancedSearch] = useState<boolean>(false);
  const [listInputSearch, setListInputSearch] = useState<IItemSearch[]>([INIT_INPUT_SEARCH]);

  const handleOpenUpdateDialog = () => {
    setIsView(false);
  };

  const handleCloseUpdateDialog = () => {
    setIsView(true);
  };

  const handleOpenInfoDialog = (row: any) => {
    setIsView(true);
    setDataEdit(row)
    setShowSendPeopleTrainingDialog(true);
  };

  const handleCloseDialog = () => {
    setShowSendPeopleTrainingDialog(false);
    setDataEdit({} as ISendPeopleTraining);
    setIsView(false);
  };

  const handleOpenDialog = () => {
    setShowSendPeopleTrainingDialog(true);
  };

  const handleCloseAndSearch = () => {
    handleSearch();
    handleCloseDialog();
  }

  const updatePageData = useCallback(async (searchData?: any) => {
    try {
      setPageLoading(true);
      setSearchObject({ ...searchData });
      const { data } = await getListSendPeopleTraining(hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW) ? searchDataRoleUser(searchData) : { ...searchData });
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setReceivingCitizensList(data?.data?.content || []);
        setTotalPage(data?.data?.pageable?.totalPages);
        setTotalElements(data?.data?.pageable?.totalElements);
        setNumberOfElements(data?.data?.pageable?.numberOfElements);
        setIsAdvancedSearch(false);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  }, [lang, setPageLoading]);

  const handleSearch = (data: any = {}) => {
    const dataSearch: any = {
      ...searchObject,
      ...data
    };
    dataSearch.keyword = dataSearch?.keyword?.trim() || "";
    updatePageData(dataSearch);
  };

  useEffect(() => {
    if (searchObject?.pageIndex && searchObject?.pageSize) {
      handleSearch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchObject?.pageIndex, searchObject?.pageSize])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchObject({
      ...searchObject,
      keyword: e.target?.value
    });
  };

  const handleAdvancedSearch = (data: IItemSearch[]) => {
    setListInputSearch(data);
    handleSearch({ ...convertSearch(data), pageIndex: 1 });
  };

  const handleDelete = async (ids: string[]) => {
    try {
      const { data } = await deleteSendPeopleTraining(ids)
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("GENERAL.DELETE_SUCCESS"))
        updatePageData(searchObject);
        return true;
      } else {
        toast.warning(data?.message)
      }
    }
    catch (err: any) {
      toast.error(err)
    }
  }

  const handleShowExportWord = () => {
    if (dataChecked.length === 0) {
      toast.warning(lang("TOAST.SELECT_EMPLOYEE"));
      return;
    }
    setShouldOpenExportWord(true)
  }

  const searchFunction = async (searchObject: IObject) => {
    try {
      setPageLoading(true);
      const { data } = await getDataPreviewWord(searchObject);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListDataPreview(data?.data || [])
        setDataWord(data?.data?.[0]);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleOpenAdvancedSearch = () => {
    setIsAdvancedSearch(true);
  };

  const columns: columnNamesType[] = [
    {
      name: "STT",
      field: "",
      render: (_row: any, _index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("GENERAL.STATUS"),
      field: "trangThai",
      headerStyle: {
        minWidth: "150px"
      },
      render: (row: any) =>
        <span className={`${checkStatus(MISSION_STATUS, row?.trangThai?.code)} status`}>
          {row?.trangThai?.name}
        </span>
    },
    {
      name: lang("PROFILE.CODE"),
      field: "maNhanVien",
      headerStyle: {
        minWidth: "150px"
      },
      cellStyle: {
        textAlign: "center"
      },
      render: (row: any) => <span className="color-steel-blue fw-600">{row?.maNhanVien}</span>
    },
    {
      name: lang("PROFILE.NAME"),
      field: "tenNhanVien",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
    },
    {
      name: lang("INPUT.DEPARTMENTS"),
      field: "phongBanText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        minWidth: "200px",
        textAlign: "left",
      },
    },
    {
      name: lang("INPUT.WORKPROCESS.JOBTITLE"),
      field: "chucDanhText",
      headerStyle: {
        minWidth: "250px"
      },
      cellStyle: {
        minWidth: "250px",
        textAlign: "left",
      },
    },
    {
      name: lang("GENERAL.CURRENT_ORGANIZATION"),
      field: "donViCongTacText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
    },
    {
      name: lang("SEND_PEOPLE_TRAINING.NAME"),
      field: "tenKhoaDaoTao",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
    },
    {
      name: lang("SEND_PEOPLE_TRAINING.LOCATION"),
      field: "noiDaoTao",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
    },
    {
      name: lang("INPUT_APPOINTMENT_NUMBER"),
      field: "soQuyetDinh",
      headerStyle: {
        minWidth: "120px"
      },
      cellStyle: {
        textAlign: "left"
      },
    },
    {
      name: lang("INPUT_APPOINTMENT_CONFIRM_DATE"),
      field: "ngayQuyetDinh",
      headerStyle: {
        minWidth: "120px"
      },
      render: (row) => <span>{formatDateTable(row?.ngayQuyetDinh)}</span>
    },
    {
      name: lang("INPUT_APPOINTMENT_TIME_EFFECT"),
      field: "thoiGianHieuLuc",
      headerStyle: {
        minWidth: "180px"
      },
      render: (row: any) => <span>{formatDateTable(row?.hieuLucTuNgay)} {row?.hieuLucDenNgay && " - "}{formatDateTable(row?.hieuLucDenNgay)}</span>
    },
    {
      name: lang("GENERAL.DEPARTMENT_RECEIVE"),
      field: "khoaPhongNhanQuyetDinh",
      headerStyle: {
        minWidth: "250px"
      },
      cellStyle: {
        textAlign: "left"
      },
      render: (row: any) => <span>{row?.khoaPhongNhanQuyetDinh}</span>
    },
    {
      name: lang("TRANSFER.NGUOIKI"),
      field: "nguoiKyText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      },
    }
  ]

  return (
    <div className="spaces px-8 py-12 flex-1">
      {!showSendPeopleTrainingDialog ?
        <>
          <Row className="spaces mb-12 flex-middle">
            <Col xs={12} lg={4} xl={5} xxl={6} className="spaces p-0">
              <h2 className="breadcrumb-title">{lang("SEND_PEOPLE_TRAINING.LIST")}</h2>
            </Col>
            <Col xs={12} lg={8} xl={7} xxl={6} className="flex">
              <div className="spaces mr-10 flex-1">
                <InputSearch
                  className="border-3px"
                  value={searchObject?.keyword}
                  placeholder="Tìm kiếm theo mã nhân viên, tên nhân viên, Khoa/Phòng"
                  handleSearch={() => handleSearch({ pageIndex: 1 })}
                  handleChange={handleChange}
                  isEnter
                />
              </div>
              <GroupButton className="spaces mr-10" handleEvent={() => handleSearch({ pageIndex: 1 })}>
                {lang("BTN.SEARCH")}
              </GroupButton>
              <GroupButton className="spaces mr-10" outline handleEvent={handleOpenAdvancedSearch}>
                {lang("BTN.ADVANCE_SEARCH")}
              </GroupButton>
              {isAdvancedSearch && (
                <AdvancedSearch
                  open={isAdvancedSearch}
                  listInputSearch={listInputSearch}
                  handleSearch={handleAdvancedSearch}
                  listOption={ListSearch(lang)}
                  handleClose={() => setIsAdvancedSearch(false)}
                />
              )}
            </Col>
          </Row>
          <Row className="bg-white">
            <Col xs={12} className="table-scroll">
              <TableCustom
                id="send-people-training"
                data={receivingCitizensList}
                columns={columns}
                searchObject={searchObject}
                setSearchObject={setSearchObject}
                buttonAdd={hasAuthority(PERMISSIONS.CU_DAO_TAO, PERMISSION_ABILITY.CREATE)}
                buttonExportWord={true}
                handleExportWord={handleShowExportWord}
                buttonExportExcel={false}
                handleExportExcel={(ids: string[]) =>
                  exportToFile({
                    exportAPI: () => exportSendPeopleTraining({ ids, ...searchObject }),
                    fileName: EXPORT_FILE_NAME.SEND_PEOPLE_TRAINING,
                    setPageLoading
                  })
                }
                notDelete={!hasAuthority(PERMISSIONS.CU_DAO_TAO, PERMISSION_ABILITY.DELETE)}
                type={TYPE.MULTILINE}
                dataChecked={dataChecked}
                setDataChecked={setDataChecked}
                fixedColumnsCount={4}
                totalPages={totalPage}
                totalElements={totalElements}
                numberOfElements={numberOfElements}
                handleOpenDialog={handleOpenDialog}
                handleDoubleClick={handleOpenInfoDialog}
                handleDelete={(ids: string[]) => handleDelete(ids)}
                deleteConditional={[
                  {
                    keyPath: "trangThai.code",
                    value: MISSION_STATUS_CODE.PROCESSING,
                  },
                  {
                    keyPath: "trangThai.code",
                    value: MISSION_STATUS_CODE.COMPLETED,
                  },
                ]}
              />
            </Col>
          </Row>
        </> : (
          <SendPeopleTrainingDialog
            dataEdit={dataEdit}
            isView={isView}
            handleCloseDialog={handleCloseDialog}
            handleCloseAndSearch={handleCloseAndSearch}
            handleCloseUpdateDialog={handleCloseUpdateDialog}
            handleOpenUpdateDialog={handleOpenUpdateDialog}
          />
        )
      }
      {shouldOpenExportWord && (
        <ExportWord
          open={shouldOpenExportWord}
          dataWord={dataWord}
          handleClose={() => setShouldOpenExportWord(false)}
          templateList={[{
            name: "QĐ cử đi học",
            componentPreview: (dataPreview) => <SendPeopleTrainingWord data={dataPreview} />
          }]}
          ComponentSearch={() =>
            <Row>
              <Col xl={6}>
                <Autocomplete
                  horizontal={true}
                  lable='Chọn nhân viên để xem mẫu word'
                  options={listDataPreview}
                  getOptionLabel={(option) => option.fullName}
                  value={dataWord}
                  onChange={(value) => setDataWord(value)}
                />
              </Col>
            </Row>
          }
          getObj={{
            list: dataChecked.map((item: any) => item?.id).join(','),
            type: DATA_WORD_TYPES.SEND_PEOPLE_TRAINING
          }}
          searchFunction={searchFunction}
        />
      )}
    </div>
  );
}

export default SendPeopleTraining

import { FC, useContext, useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import * as Yup from "yup";
import TextValidator from "../../components/input/text-validator";
import { ISymbolTimekeeping } from "../models/categoryModels";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import AppContext from "../../../AppContext";
import { addSymbol, getListWorkDay, updateSymbol } from "../services/symbolServices";
import { RESPONSE_STATUS_CODE } from "../../constants/moduleConsts";
import { INIT_SYMBOL_TIMEKEEPING } from "../constants/symbolTimekeepingConsts";
import { GroupButton } from "../../components/GroupButton";
import { hasAuthority } from "../../utils/functionUtils";
import { PERMISSION_ABILITY, PERMISSIONS } from "../../../constants";
import Autocomplete from "../../components/input/autocomplete";

interface IProps {
  open: boolean;
  handleClose: () => void;
  handleCloseAndSearch: () => void;
  symbolInfo: ISymbolTimekeeping;
  isView: boolean;
}

const SymbolTimekeepingForm: FC<IProps> = (props) => {
  const {
    open,
    handleClose,
    handleCloseAndSearch,
    symbolInfo,
    isView
  } = props;

  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);
  const [isEdit, setIsEdit] = useState<boolean>(!isView);

  useEffect(() => {
    symbolInfo?.id && formik.setValues({ ...formik.values, ...symbolInfo });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [symbolInfo]);

  const validationSchema = Yup.object().shape({
    code: Yup.string().required(lang("VALIDATION.REQUIRE")),
    name: Yup.string().required(lang("VALIDATION.REQUIRE")),
    loaiTinhCongs: Yup.array().min(1, lang("VALIDATION.REQUIRE")),
  });

  const handleSubmit = async (values: ISymbolTimekeeping) => {
    try {
      setPageLoading(true);
      const { data } = symbolInfo?.id
        ? await updateSymbol(symbolInfo.id, values)
        : await addSymbol(values);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(
          symbolInfo.id
            ? lang("TOAST.EDIT.SUCCESS")
            : lang("TOAST.CREATE.SUCCESS")
        );
        handleCloseAndSearch();
      } else {
        toast.warning(data?.message);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false)
    }
  };

  const formik = useFormik({
    initialValues: INIT_SYMBOL_TIMEKEEPING,
    validationSchema,
    onSubmit: handleSubmit
  });

  const handleChangeSelect = (name: string, value: any) => {
    formik.setFieldValue(name, value);
  };

  return (
    <Modal
      show={open}
      onHide={handleClose}
      backdrop="static"
      size="lg"
      centered
    >
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>
            {symbolInfo?.id ? lang("SYMBOL.EDIT") : lang("SYMBOL.ADD")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="g-4 relative spaces z-index-4">
            <Col sm={4} md={4} lg={6} xl={6}>
              <TextValidator
                isRequired
                name="code"
                lable={lang("SYMBOL")}
                type="text"
                onChange={formik.handleChange}
                value={formik.values?.code}
                readOnly={!isEdit}
                errors={formik.errors?.code}
                touched={formik.touched?.code}
              />
            </Col>
            <Col sm={8} md={8} lg={6} xl={6}>
              <TextValidator
                isRequired
                name="name"
                lable={lang("CATEGORY.NAME")}
                type="text"
                onChange={formik.handleChange}
                value={formik.values?.name}
                readOnly={!isEdit}
                errors={formik.errors?.name}
                touched={formik.touched?.name}
              />
            </Col>
            <Col>
              <Autocomplete
                isRequired
                lable={lang("ATTRIBUTION")}
                closeMenuOnSelect={false}
                name="loaiTinhCongs"
                value={formik.values?.loaiTinhCongs || []}
                options={[]}
                isMulti={true}
                searchFunction={getListWorkDay}
                onChange={(selectedOption: any) => handleChangeSelect("loaiTinhCongs", selectedOption)}
                isReadOnly={!isEdit}
                errors={formik.errors?.loaiTinhCongs}
                touched={formik.touched?.loaiTinhCongs}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          {(isEdit ? (
              <GroupButton type="btn-save" />
            ) : (hasAuthority(PERMISSIONS["DANH_MUC.CHAM_CONG.KY_HIEU"], PERMISSION_ABILITY.UPDATE) &&
              <GroupButton type="btn-edit" handleSaveEdit={() => setIsEdit(true)} />
          ))}
          <GroupButton
            type="btn-cancel"
            handleCloseUpdateDialog={handleClose}
          />
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default SymbolTimekeepingForm;

export const TYPE_DON = {
    DON_XIN_NGHI: 1,
    DIEU_DONG_THUONG_TRUC: 2,
    DANG_KY_LAM_THEM: 3,
    DON_DOI_CA_NGHI_BU: 4,
};

export const LIST_LOAI_DON = [
    { type: TYPE_DON.DON_XIN_NGHI, name: "Đơn xin nghỉ", code: "DXN" },
    { type: TYPE_DON.DIEU_DONG_THUONG_TRUC, name: "Đơn điều động thường trực", code: "DDTT" },
    { type: TYPE_DON.DANG_KY_LAM_THEM, name: "Đơn đăng ký làm thêm", code: "DDTT" },
    { type: TYPE_DON.DON_DOI_CA_NGHI_BU, name: "Đơn xin đổi ca", code: "DXDC" },
];

export const CODE_STATUS = {
    TAO_MOI: 1,
    CHO_DUYET: 2,
    DA_DUYET: 3,
    TU_CHOI: 4,
};

export const LIST_STATUS = [
    { code: CODE_STATUS.TAO_MOI, name: "Tạo mới", styleClass: "bg-gray-700" },
    { code: CODE_STATUS.CHO_DUYET, name: "Chờ duyệt", styleClass: "bg-orange-dark" },
    { code: CODE_STATUS.DA_DUYET, name: "Đã duyệt", styleClass: "bg-green" },
    { code: CODE_STATUS.TU_CHOI, name: "Từ chối", styleClass: "bg-coral" },
];
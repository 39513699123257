import { ILang } from '../../../hook/useMultiLanguage';
import { TYPE } from '../../constants/moduleConsts';
import { IReceivingCitizens } from '../models/receivingCitizensModels';

export const INIT_RECEIVING_CITIZENS: IReceivingCitizens = {
  id: "",
  ngayTiep: "",
  name: "",
  address: "",
  cccd: "",
  noiDungCongViec: "",
  noiDungHuongGiaiQuyet: "",
  phuongPhapGiaiQuyet: null,
  note: "",
  baoCao: "",
  phanLoaiDon: null,
  status: null
}

export const LIST_STATUS_CODE = {
  UNFINISH: 1,
  PROCESSING: 2,
  FINISHED: 3,
}

export const LIST_STATUS = [
  { code: LIST_STATUS_CODE.UNFINISH, name: "Chưa giải quyết", styleClass: "bg-gray-700" },
  { code: LIST_STATUS_CODE.PROCESSING, name: "Đang giải quyết", styleClass: "bg-orange-dark" },
  { code: LIST_STATUS_CODE.FINISHED, name: "Đã giải quyết", styleClass: "bg-green" },
]

export const PHAN_LOAI_DON = [
  { code: 1, name: "Khiếu nại" },
  { code: 2, name: "Tố cáo" },
  { code: 3, name: "Kiến nghị" },
  { code: 4, name: "Thuộc thẩm quyền" },
  { code: 5, name: "Không thuộc thẩm quyền" },
  { code: 6, name: "Phản ánh" },
]

export const PHUONG_PHAP_GIAI_QUYET = [
  { code: 1, name: "Cơ quan tự giải quyết" },
  { code: 2, name: "Thẩm quyền giải quyết" },
]

export const ListSearch = (lang: ILang) => {
  return [
    {
      name: lang("PROFILE.NAME"),
      field: "name",
      type: TYPE.TEXT,
    },
    {
      name: lang("RECEIVING.DAY_RECEPTION"),
      field: "ngayTiep",
      type: TYPE.DATE,
    },
    {
      name: lang("INPUT.IDENTIFICATION"),
      field: "cccd",
      type: TYPE.TEXT,
    },
    {
      name: lang("RECEIVING.SINGLE_CLASSIFICATION"),
      field: "phanLoaiDon",
      type: TYPE.SELECT,
      listOption: PHAN_LOAI_DON,
      isMulti: true
    },
    {
      name: lang("RECEIVING.SOLUTION"),
      field: "phuongPhapGiaiQuyets",
      type: TYPE.SELECT,
      listOption: PHUONG_PHAP_GIAI_QUYET,
      isMulti: true
    },
    {
      name: lang("GENERAL.STATUS"),
      field: "trangThai",
      type: TYPE.SELECT,
      listOption: LIST_STATUS,
      isMulti: true,
    },
  ]
}

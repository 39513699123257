import { FC, useEffect, useState } from "react";
import { IUnit } from "../models/managerModels";
import { toast } from "react-toastify";
import { deleteUnit } from "../services/managerServices";
import { UnitDialog } from "./dialogs/UnitDialog";
import TableCustom, { columnNamesType } from "../../components/table-custom/TableCustom";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { RESPONSE_STATUS_CODE, TYPE } from "../../constants/moduleConsts";
import { IObject } from "../../models/models";
import { INIT_SEARCH_OBJECT } from "../../constants/moduleConsts";
import { searchListWorkUnit } from "../../services/services";
import { Col, Row } from "react-bootstrap";
import { hasAuthority } from "../../utils/functionUtils";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../../constants";
import { GroupButton } from "../../components/GroupButton";
import InputSearch from "../../components/input/InputSearch";

const UnitList: FC = () => {
  const { lang } = useMultiLanguage();

  const [unitList, setUnitList] = useState<IUnit[]>([]);
  const [unitInfo, setUnitInfo] = useState<IUnit>({} as IUnit);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [openUnitDialog, setOpenUnitDialog] = useState<boolean>(false);
  const [searchObject, setSearchObject] = useState<IObject>(INIT_SEARCH_OBJECT);

  useEffect(() => {
    if(searchObject?.pageIndex && searchObject?.pageSize) {
      handleSearch();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchObject?.pageIndex, searchObject?.pageSize])

  const handleCloseUnitDialog = () => {
    setOpenUnitDialog(false);
    setUnitInfo({} as IUnit);
  };

  const loadListUnit = async (searchObject: any) => {
    try {
      setSearchObject({ ...searchObject });
      const { data } = await searchListWorkUnit(searchObject);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setUnitList(data?.data?.content);
        setTotalPage(data?.data?.pageable?.totalPages || 0);
        setTotalElements(data?.data?.pageable?.totalElements || 0);
        setNumberOfElements(data?.data?.pageable?.numberOfElements || 0);
      } else {
        toast.warning(data?.message);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const handleSearch = (data: any = {}) => {
    const dataSearch: any = {
      ...searchObject,
      ...data
    };
    dataSearch.keyword = dataSearch?.keyword?.trim() || ""
    loadListUnit(dataSearch);
  };

  const handleDeleteUnit= async (ids: string[]) => {
    try {
      const res = await deleteUnit(ids);
      if (res?.data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("GENERAL.DELETE_SUCCESS"));
        return true;
      } else toast.warning(`${res?.data?.message}`);
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }

  const handleCloseAndSearch = () => {
    handleCloseUnitDialog();
    handleSearch();
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchObject({
      ...searchObject,
      keyword: e.target?.value
    });
  };

  const columns: columnNamesType[] = [
    {
      name: lang("TABLE.INDEX"),
      field: "stt",
      render: (row, index, stt) => <span>{stt}</span>
    },
    {
      name: lang("UNIT.NAME"),
      field: "name",
      headerStyle: {
        minWidth: "150px"
      }
    },
    {
      name: lang("UNIT.CODE"),
      field: "code",
      headerStyle: {
        minWidth: "250px"
      }
    },
    {
      name: lang("UNIT.TYPE"),
      field: "orgType",
      headerStyle: {
        minWidth: "150px"
      }
    }
  ];

  return (
    <div>
      <Row className="flex-space-between">
        <Col xs={12} md={4} lg={4} xl={2} className="mt-3">
          <span className="spaces fs-18 fw-600">{lang("UNIT.LIST")}</span>
          {hasAuthority(PERMISSIONS.ORGANIZATION_MENU, PERMISSION_ABILITY.CREATE) &&
            <GroupButton handleEvent={() => setOpenUnitDialog(true)}>
              <i className="spaces bi bi-plus fs-20 white pr-0"></i>
              <p className="spaces fs-14 m-0 ">{lang("BTN.ADD")}</p>
            </GroupButton>
          }
        </Col>
        <Col xs={12} md={8} lg={7} xl={6} className="flex mt-3">
          <div className="spaces mr-10 flex-1">
            <InputSearch
              className="border-3px"
              value={searchObject?.keyword}
              placeholder="Tìm kiếm theo tên đơn vị"
              isEnter={true}
              handleSearch={() => handleSearch({ pageIndex: 1 })}
              handleChange={handleChange}
            />
          </div>
          <GroupButton handleEvent={() => handleSearch({ pageIndex: 1 })}>
            {lang("BTN.SEARCH")}
          </GroupButton>
        </Col>
      </Row>
      <div className="table-scroll">
        <TableCustom
          data={unitList}
          columns={columns}
          handleDelete={(ids: string[]) => handleDeleteUnit(ids)}
          noToolbar={true}
          buttonAdd={false}
          justFilter={false}
          notDelete={true}
          totalPages={totalPage}
          totalElements={totalElements}
          numberOfElements={numberOfElements}
          unSelectedAll={true}
          type={TYPE.MULTILINE}
          searchObject={searchObject}
          setSearchObject={setSearchObject}
        />
        {openUnitDialog && (
          <UnitDialog
            handleCloseDialog={handleCloseUnitDialog}
            unitInfo={unitInfo}
            handleCloseAndSearch={handleCloseAndSearch}
          />
        )}
      </div>
    </div>
  );
};
export { UnitList };

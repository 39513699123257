import { FC, useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../constants";
import useMultiLanguage from "../../hook/useMultiLanguage";
import InputSearch from "../components/input/InputSearch";
import TableCustom, { columnNamesType } from "../components/table-custom/TableCustom";
import AdvancedSearch from "../profile/components/dialogs/AdvancedSearch";
import { MISSION_STATUS, RESPONSE_STATUS_CODE, TYPE, INIT_SEARCH_OBJECT, EXPORT_FILE_NAME, INIT_INPUT_SEARCH } from "../constants/moduleConsts";
import { exportBietPhai } from "../services/exportFileServices";
import { convertSearch, hasAuthority, exportToFile, searchDataRoleUser, checkStatus } from "../utils/functionUtils";
import AppContext from "../../AppContext";
import { ISecondment } from "./models/secondmentModels";
import { ListSearch } from "./constants/secondmentConsts";
import SecondmentForm from "./components/SecondmentForm";
import { deleteSecondment, getListSecondment } from "./services/secondmentServices";
import { GroupButton } from "../components/GroupButton";
import { IItemSearch } from "../models/models";

const Secondment: FC = () => {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [listSecondment, setListSecondment] = useState<ISecondment[]>([]);
  const [dataChecked, setDataChecked] = useState<ISecondment[]>([]);
  const [openSecondmentForm, setOpenSecondmentForm] = useState<boolean>();
  const [secondmentInfo, setSecondmentInfo] = useState<ISecondment>({} as ISecondment);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [isView, setIsView] = useState<boolean>(false);
  const [searchObject, setSearchObject] = useState<any>(INIT_SEARCH_OBJECT);
  const [isAdvancedSearch, setIsAdvancedSearch] = useState<boolean>(false);
  const [listInputSearch, setListInputSearch] = useState<any[]>([INIT_INPUT_SEARCH]);

  const handleOpenSecondmentForm = (info: any) => {
    setIsView(true);
    setOpenSecondmentForm(true);
    setSecondmentInfo(info);
  };

  const handleCloseAndSearch = () => {
    handleSearch();
    handleClose();
  }

  const handleClose = () => {
    setOpenSecondmentForm(false);
    setIsView(false);
    setSecondmentInfo({} as ISecondment);
  };

  const updatePageData = async (searchData?: any) => {
    try {
      setPageLoading(true);
      setSearchObject({ ...searchData });
      const { data } = await getListSecondment(hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW) ? searchDataRoleUser(searchData) : { ...searchData });
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListSecondment(data?.data?.content || []);
        setTotalPage(data?.data?.pageable?.totalPages);
        setTotalElements(data?.data?.pageable?.totalElements);
        setNumberOfElements(data?.data?.pageable?.numberOfElements);
        setIsAdvancedSearch(false);
      } else {
        toast.warning(data?.message);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleOpenUpdateDialog = () => {
    setOpenSecondmentForm(true);
  };

  const handleCloseUpdateDialog = () => {
    setIsView(true);
  };

  const handleSearch = (data: any = {}) => {
    const dataSearch: any = {
      ...searchObject,
      ...data
    };
    dataSearch.keyword = dataSearch?.keyword?.trim() || "";
    updatePageData(dataSearch);
  };

  useEffect(() => {
    if (searchObject?.pageIndex && searchObject?.pageSize) {
      handleSearch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchObject?.pageIndex, searchObject?.pageSize])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchObject({
      ...searchObject,
      keyword: e.target?.value
    });
  };

  const handleOpenAdvancedSearch = () => {
    setIsAdvancedSearch(true);
  };

  const handleAdvancedSearch = (data: IItemSearch[]) => {
    setListInputSearch(data);
    handleSearch({ ...convertSearch(data), pageIndex: 1 });
  };

  const handleDeleteSecondment = async (ids: string[]) => {
    try {
      const { data } = await deleteSecondment(ids)
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("TOAST.DELETE.SECONDMENT.SUCCESS"))
        updatePageData(searchObject);
        return true;
      } else {
        toast.warning(data?.message)
      }
    }
    catch (err: any) {
      toast.error(err)
    }
  };

  const columns: columnNamesType[] = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("GENERAL.STATUS"),
      field: "trangThai",
      headerStyle: {
        minWidth: "150px"
      },
      render: (row: any) => (
        <span className={`${checkStatus(MISSION_STATUS, row?.trangThai?.code)} status`}>
          {row?.trangThai?.name}
        </span>
      )
    },
    {
      name: lang("INPUT_APPOINTMENT_CODE"),
      field: "maNhanVien",
      headerStyle: {
        minWidth: "150px"
      },
      render: (row: any) => <span className="color-steel-blue fw-600">{row?.maNhanVien}</span>
    },
    {
      name: lang("INPUT_APPOINTMENT_NAME"),
      field: "name",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("SECONDMENT.WORK_UNIT_OLD"),
      field: "donViCongTacText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("INPUT_APPOINTMENT_UNIT_DEPARTMENT"),
      field: "phongBanText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("INPUT_APPOINTMENT_JOB_POSITION"),
      field: "chucVuText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("SECONDMENT.WORK_UNIT_NEW"),
      field: "donViCongTacText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("INPUT_APPOINTMENT_UNIT_DEPARTMENT_NEW"),
      field: "phongBanMoiText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("INPUT_APPOINTMENT_NEW_JOB_POSITION_NEW"),
      field: "chucVuMoiText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("INPUT_APPOINTMENT_NUMBER"),
      field: "soQuyetDinh",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left"
      }
    }
  ];

  return (
    <div className="spaces px-8 py-12 flex-1">
      {!openSecondmentForm ? (
        <>
          <Row className="spaces mb-12 flex-middle">
            <Col xs={5} xl={6} xxl={7} className="p-0">
              <h2 className="breadcrumb-title">{lang("SECONDMENT.LIST")}</h2>
            </Col>
            <Col xs={7} xl={6} xxl={5} className="flex">
              <div className="spaces mr-10 flex-1">
                <InputSearch
                  className="border-3px"
                  value={searchObject?.keyword}
                  placeholder="Tìm kiếm theo mã NV, họ và tên"
                  handleSearch={() => handleSearch({ pageIndex: 1 })}
                  handleChange={handleChange}
                  isEnter
                />
              </div>
              <GroupButton className="spaces mr-10" handleEvent={() => handleSearch({ pageIndex: 1 })}>
                {lang("BTN.SEARCH")}
              </GroupButton>
              <GroupButton className="spaces mr-10" outline handleEvent={handleOpenAdvancedSearch}>
                {lang("BTN.ADVANCE_SEARCH")}
              </GroupButton>
              {isAdvancedSearch && (
                <AdvancedSearch
                  open={isAdvancedSearch}
                  listInputSearch={listInputSearch}
                  handleSearch={handleAdvancedSearch}
                  listOption={ListSearch(lang)}
                  handleClose={() => setIsAdvancedSearch(false)}
                />
              )}
            </Col>
          </Row>
          <Row className="bg-white">
            <Col className="table-scroll">
              <TableCustom
                id="secondment-list"
                data={listSecondment || []}
                columns={columns}
                searchObject={searchObject}
                setSearchObject={setSearchObject}
                buttonAdd={hasAuthority(PERMISSIONS.BIET_PHAI, PERMISSION_ABILITY.CREATE)}
                buttonExportExcel={!hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW)}
                notDelete={!hasAuthority(PERMISSIONS.BIET_PHAI, PERMISSION_ABILITY.DELETE)}
                handleExportExcel={(ids: string[]) =>
                  exportToFile({
                    exportAPI: () => exportBietPhai({ ids, ...searchObject }),
                    fileName: EXPORT_FILE_NAME.BO_NHIEM,
                    setPageLoading
                  })
                }
                type={TYPE.MULTILINE}
                fixedColumnsCount={4}
                totalPages={totalPage}
                totalElements={totalElements}
                numberOfElements={numberOfElements}
                handleOpenDialog={handleOpenUpdateDialog}
                handleDoubleClick={(row: any) => handleOpenSecondmentForm(row)}
                handleDelete={(ids: string[]) => handleDeleteSecondment(ids)}
                setDataChecked={setDataChecked}
                dataChecked={dataChecked}
                deleteConditional={[
                  {
                    keyPath: 'xacNhanBietPhai',
                    value: true
                  }
                ]}
              />
            </Col>
          </Row>
        </>
      ) : (
        <SecondmentForm
          handleClose={handleClose}
          secondmentInfo={secondmentInfo}
          handleCloseAndSearch={handleCloseAndSearch}
          handleCloseUpdateDialog={handleCloseUpdateDialog}
          isView={isView}
          setIsView={setIsView}
        />
      )}
    </div>
  );
};

export default Secondment;

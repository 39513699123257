import { useFormik } from "formik";
import { IConcurrently } from "../models/concurrentlyModels";
import * as Yup from "yup";
import { Col, Row, Form } from "react-bootstrap";
import { GroupButton } from "../../components/GroupButton";
import Autocomplete from "../../components/input/autocomplete";
import TextValidator from "../../components/input/text-validator";
import { RangeDatePicker } from "../../components/input/RangeDatePicker";
import { INIT_CONCURRENTLY, LIST_ASSIGNMENT, LIST_ASSIGNMENT_CODE, concurrentlyWordExportList } from "../constants/concurrentlyConsts";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { 
  MISSION_STATUS,
  MISSION_STATUS_CODE,
  RESPONSE_STATUS_CODE,
  TYPE,
  VARIABLE_STRING,
  DATA_WORD_TYPES,
  TYPE_CATEGORY
} from "../../constants/moduleConsts";
import { checkObject, handleBlurDate, hasAuthority, matchTemplateWord } from "../../utils/functionUtils";
import UploadFile from "../../components/file-upload/UploadFile";
import { IFile } from "../../profile/models/dialogModels";
import { EmployeeProfile } from "../../profile/models/profileModels";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { getEmployeeProfileById } from "../../profile/services/profileServices";
import { checkInvalidDate } from "../../utils/validationSchema";
import AppContext from "../../../AppContext";
import { addConcurrently, updateConcurrently } from "../services/concurrentlyServices";
import { STATUS_NV } from "../../profile/constants/dialogChildConsts";
import { ExportWord } from "../../components/exportWord";
import { IPeopleDecision } from "../../models/models";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../../constants";
import { searchAllEmployee, searchAllSimpleValue, searchNguoiDaiDienKy, searchPhongBanByDonVi } from "../../services/services";
import { AddNewRepresentative } from "../../components/dialogs";

interface IProps {
  concurrentlyInfo: IConcurrently;
  handleClose: () => void;
  handleCloseUpdateDialog: () => void;
  handleCloseAndSearch: () => void;
  isView: boolean;
  setIsView: (value: boolean) => void;
}

function ConcurrentlyForm(props: IProps) {
  const {
    concurrentlyInfo,
    handleClose,
    handleCloseUpdateDialog,
    isView,
    setIsView,
    handleCloseAndSearch
  } = props;
  const { setPageLoading } = useContext(AppContext);
  const { lang, intl } = useMultiLanguage();
  const [isShowBtnSave, setIsShowBtnSave] = useState<boolean>(true);
  const [isAcceptConcurrently, setIsAcceptConcurrently] = useState<boolean>(Boolean(concurrentlyInfo?.xacNhanKiemNhiem));
  const isShowAcceptConcurrentlyBtn = useMemo(() => !concurrentlyInfo?.id || !concurrentlyInfo?.xacNhanKiemNhiem, [concurrentlyInfo?.id, concurrentlyInfo?.xacNhanKiemNhiem]);
  const [isCheckChucVuMoi, setIsCheckChucVuMoi] = useState<boolean>(false);
  const [isShowDialogWord, setIsShowDialogWord] = useState<boolean>(false);
  const [peopleDecisions, setPeopleDecisions] = useState<IPeopleDecision[]>([]);
  const [isShowDialogAddRepresentative, setIsShowDialogAddRepresentative] = useState<boolean>(false);

  const templateWord = useMemo(() => 
    matchTemplateWord(Number(concurrentlyInfo?.phanCongType?.code), concurrentlyWordExportList), [concurrentlyInfo?.phanCongType]);
  
  const validationSchema = Yup.object().shape({
    employee: Yup.object()
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    maNhanVien: Yup.string()
      .required(lang("VALIDATION.REQUIRE")),
    phongBanText: Yup.string().required(lang("VALIDATION.REQUIRE")),
    chucVuText: Yup.string().required(lang("VALIDATION.REQUIRE")),
    phongBanMoi: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    chucVuMoi: isCheckChucVuMoi ? Yup.object().nullable() 
      : Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    chucVuMoiKhac: isCheckChucVuMoi ? Yup.string().required(lang("VALIDATION.REQUIRE")).nullable()
      : Yup.string().nullable(),
    soQuyetDinh: Yup.string(),
    tuNgay: Yup.date()
      .concat(checkInvalidDate(intl))
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
    denNgay: Yup.date()
      .concat(checkInvalidDate(intl))
      .when("tuNgay", {
        is: (tuNgay: Date | null) => tuNgay,
        then: Yup.date()
          .min(
            Yup.ref("tuNgay"),
            lang("VALIDATION.MINDATE") +
            lang("GENERAL_EFFECTIVE_DATE_START")
          )
          .nullable()
      })
      .nullable(),
    trangThai: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    phanCongType: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    nguoiKy: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
  });

  const convertDataSubmit = (data: IConcurrently) => {
    const chucVuMoi = isCheckChucVuMoi ? {
      [VARIABLE_STRING.CHUC_VU_MOI_KHAC]: data?.chucVuMoiKhac
    } : {
      chucVuMoiId: data?.chucVuMoi?.id || "",
      chucVuMoiText: String(data?.chucVuMoi?.value),
    };
    return {
      ...data,
      ...chucVuMoi,
      phongBanMoiId: data?.phongBanMoi?.id || "",
      phongBanMoiText: data.phongBanMoi?.name || "",
      nguoiKyId: data?.nguoiKy?.id || "",
      nguoiKyText: data?.nguoiKy?.name || "",
      xacNhanKiemNhiem: isAcceptConcurrently
    }
  }

  const handleSubmit = async (values: IConcurrently) => {
    try {
      setPageLoading(true);
      const res = concurrentlyInfo.id
        ? await updateConcurrently(concurrentlyInfo.id, convertDataSubmit(values))
        : await addConcurrently(convertDataSubmit(values));
      if (res?.data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(
          concurrentlyInfo.id
            ? lang("TOAST.EDIT.SUCCESS")
            : lang("TOAST.CREATE.SUCCESS")
        );
        handleCloseAndSearch();
      } else {
        toast.warning(`${res?.data?.message}`);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false)
    }
  };

  const formik = useFormik({
    initialValues: INIT_CONCURRENTLY,
    onSubmit: handleSubmit,
    validationSchema
  });

  const getEmployeeInfo = async (id: string) => {
    try {
      const { data } = await getEmployeeProfileById(id);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        formik.setFieldValue(VARIABLE_STRING.EMPLOYEE, data?.data);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      console.error(error);
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const getPeopleDecision = useCallback(async () => {
    try {
      const { data } = await searchNguoiDaiDienKy();
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setPeopleDecisions(data?.data?.content || []);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }, [lang]);

  useEffect(() => {
    getPeopleDecision();
  }, [getPeopleDecision])

  useEffect(() => {
    if (concurrentlyInfo?.id) {
      let values = {
        ...concurrentlyInfo,
        phongBanMoi: concurrentlyInfo?.phongBanMoiId ? {
          id: concurrentlyInfo?.phongBanMoiId,
          name: concurrentlyInfo?.phongBanMoiText
        } : null,
        chucVuMoi: concurrentlyInfo?.chucVuMoiId ? {
          id: concurrentlyInfo?.chucVuMoiId,
          value: concurrentlyInfo?.chucVuMoiText
        } : null,
        nguoiKy: concurrentlyInfo?.nguoiKyId ? {
          id: concurrentlyInfo?.nguoiKyId,
          name: concurrentlyInfo?.nguoiKyText
        } : null
      }
      formik.setValues(values);
      setIsCheckChucVuMoi(Boolean(concurrentlyInfo?.chucVuMoiKhac));
    }
    if (concurrentlyInfo?.employeeId) {
      getEmployeeInfo(concurrentlyInfo.employeeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [concurrentlyInfo]);

  useEffect(() => {
    if (!concurrentlyInfo?.id && peopleDecisions.length > 0) {
      formik.setFieldValue("nguoiKy", peopleDecisions.find(people => people.isDefault) || null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [peopleDecisions]);

  const handleChangeSelect = (name: string, value: any) => {
    switch(name) {
      case VARIABLE_STRING.CHUC_VU_MOI:
        formik.setFieldValue(name, value);
        formik.setFieldValue(VARIABLE_STRING.CHUC_VU_MOI_KHAC, "");
        break;
      default:
        formik.setFieldValue(name, value);
    }
  };

  const handleEmployeeChange = (employee: EmployeeProfile) => {
    let values: IConcurrently = {
      ...formik.values,
      employee,
      name: employee?.name || "",
      employeeId: employee?.id || "",
      maNhanVien: employee?.maNhanVien || "",
      phongBanId: employee?.phongBanId || "",
      phongBanText: employee?.phongBanText || "",
      chucVuId: employee?.chucVuId || "",
      chucVuText: employee?.chucVuText || "",
    };
    formik.setValues(values);
  };

  const handleFile = (data: IFile) => {
    formik.setFieldValue("fileId", data.id);
    formik.setFieldValue("fileName", data.name);
  };

  const handleAcceptConcurrently = async () => {
    if (checkObject(await formik.validateForm())) {
      setIsAcceptConcurrently(true);
    }
  }

  const handleChangeDate = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value);
    if (name === VARIABLE_STRING.TU_NGAY) {
      const effectiveDate = value ? new Date(value) : null;
      const currentDate = new Date();
      if (effectiveDate && effectiveDate <= currentDate) {
        setIsShowBtnSave(false);
      } else setIsShowBtnSave(true);
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, nameObj: string) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value);
    formik.setFieldValue(nameObj, null);
  };

  const handleCloseAndSearchRepresentative = () => {
    getPeopleDecision();
    setIsShowDialogAddRepresentative(false);
  }

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <div className="header-form-action">
          <GroupButton type="btn-back" handleClose={handleClose} />
          <div className="flex gap-4">
            {(!isView && concurrentlyInfo?.trangThai?.code === MISSION_STATUS_CODE.UNFULFILLED && concurrentlyInfo?.id) && (
              <GroupButton type="btn-cancel" handleCloseUpdateDialog={handleCloseUpdateDialog} />
            )}
            {isView && concurrentlyInfo?.id && concurrentlyInfo?.trangThai?.code !== MISSION_STATUS_CODE.COMPLETED 
              && !concurrentlyInfo.xacNhanKiemNhiem && hasAuthority(PERMISSIONS.KIEM_NHIEM, PERMISSION_ABILITY.UPDATE) && (
              <GroupButton type="btn-edit" handleSaveEdit={() => setIsView(false)} />
            )}
            {(!isView && isShowBtnSave && (concurrentlyInfo?.trangThai?.code === MISSION_STATUS_CODE.UNFULFILLED || !concurrentlyInfo?.id)) && (
              <GroupButton type="btn-save" handleSubmit={() => setIsAcceptConcurrently(false)} />
            )}
            {isShowAcceptConcurrentlyBtn && (
              <GroupButton typeButton="submit" handleEvent={handleAcceptConcurrently}>{lang("CONCURRENTLY.ACCEPT")}</GroupButton>
            )}
            {concurrentlyInfo?.id && (
              <GroupButton handleEvent={() => setIsShowDialogWord(true)}>{lang("GENERAL.EXPORT_WORD")}</GroupButton>
            )}
          </div>
        </div>
        <div className="form-content-scroll">
          <div className="sub-title-form">{concurrentlyInfo?.id ? lang("CONCURRENTLY.EDIT") : lang("CONCURRENTLY.ADD")}</div>
          <div className="sub-title pb-2 pt-0">{lang("APPOINTMENT_INFO_EMPLOYEE")}</div>
          <Row className="g-4">
            <Col sm={4} md={4} lg={4} xl={3} xxl={3}>
              <Autocomplete
                isRequired
                lable={lang("GENERAL.FULLNAME")}
                options={[]}
                className="spaces"
                onChange={(value) => handleEmployeeChange(value)}
                name="employee"
                value={formik.values?.employee || null}
                searchFunction={searchAllEmployee}
                searchObject={{
                  trangThaiLamViec: Object.values(STATUS_NV).filter(status => status !== STATUS_NV.THOI_VIEC).join(',')
                }}
                isReadOnly={isView}
                errors={formik.errors.employee}
                touched={formik.touched.employee}
                getOptionLabel={(option) => `${option?.maNhanVien} - ${option?.name}`}
              />
            </Col>
            <Col sm={3} md={3} lg={3} xl={2} xxl={2}>
              <TextValidator
                name="maNhanVien"
                lable={lang("INPUT_APPOINTMENT_CODE")}
                type="text"
                value={formik.values?.maNhanVien}
                readOnly={true}
                errors={formik.errors?.maNhanVien}
                touched={formik.touched?.maNhanVien}
                isRequired
              />
            </Col>
            <Col sm={6} md={5} lg={5} xl={4} xxl={3}>
              <TextValidator
                name="phongBanText"
                lable={lang("INPUT_APPOINTMENT_UNIT_DEPARTMENT")}
                type="text"
                value={formik.values?.phongBanText}
                readOnly={true}
                isRequired
                errors={formik.errors?.phongBanText}
                touched={formik.touched?.phongBanText}
              />
            </Col>
            <Col sm={6} md={5} lg={5} xl={3} xxl={4}>
              <TextValidator
                name="chucVuText"
                lable={lang("INPUT_APPOINTMENT_JOB_POSITION")}
                type="text"
                value={formik.values?.chucVuText}
                readOnly={true}
                isRequired
                errors={formik?.errors?.chucVuText}
                touched={formik?.touched?.chucVuText}
              />
            </Col>
          </Row>
          <div className="sub-title pb-2">{lang("CONCURRENTLY.INFOMATION")}</div>
          <Row className="g-4">
            <Col sm={6} md={3} lg={3} xl={4} xxl={2}>
              <Autocomplete
                lable={lang("CONCURRENTLY.TYPE_ASSIGNMENT")}
                isReadOnly={isView}
                options={LIST_ASSIGNMENT}
                isRequired
                value={formik.values?.phanCongType}
                name={VARIABLE_STRING.PHAN_CONG_TYPE}
                onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.PHAN_CONG_TYPE, selectedOption)}
                errors={formik.errors?.phanCongType}
                touched={formik.touched?.phanCongType}
                dependencies={[formik.values?.employee?.donViCongTacId]}
              />
            </Col>
            <Col sm={6} md={5} lg={5} xl={4} xxl={3}>
              <Autocomplete
                lable={lang("INPUT_APPOINTMENT_UNIT_DEPARTMENT_NEW")}
                isReadOnly={isView}
                searchFunction={searchPhongBanByDonVi}
                searchObject={{ id: formik.values?.employee?.donViCongTacId }}
                options={[]}
                isRequired
                value={formik.values?.phongBanMoi}
                name={VARIABLE_STRING.PHONG_BAN_MOI}
                onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.PHONG_BAN_MOI, selectedOption)}
                urlData="data.data"
                errors={formik.errors?.phongBanMoi}
                touched={formik.touched?.phongBanMoi}
                dependencies={[formik.values?.employee?.donViCongTacId]}
              />
            </Col>
            <Col sm={6} md={4} lg={4} xl={4} xxl={3}>
              <Autocomplete
                lable={lang("INPUT_APPOINTMENT_NEW_JOB_POSITION_NEW")}
                isReadOnly={isView}
                formCheckBox={formik.values?.phanCongType?.code === LIST_ASSIGNMENT_CODE.GIAO_CONG_VIEC}
                setIsCheckBox={setIsCheckChucVuMoi}
                isCheckBox={isCheckChucVuMoi}
                searchFunction={searchAllSimpleValue}
                searchObject={{ type: TYPE_CATEGORY.chucDanh, phanCongType: formik.values?.phanCongType?.code }}
                options={[]}
                isRequired
                value={isCheckChucVuMoi ? formik.values?.chucVuMoiKhac : formik.values?.chucVuMoi}
                name={isCheckChucVuMoi ? VARIABLE_STRING.CHUC_VU_MOI_KHAC : VARIABLE_STRING.CHUC_VU_MOI}
                onChange={(selectedOption) => 
                  isCheckChucVuMoi 
                    ? handleChange(selectedOption, VARIABLE_STRING.CHUC_VU_MOI) 
                    : handleChangeSelect(VARIABLE_STRING.CHUC_VU_MOI, selectedOption)
                }
                errors={isCheckChucVuMoi ? formik.errors?.chucVuMoiKhac : formik.errors?.chucVuMoi}
                touched={isCheckChucVuMoi ? formik.errors?.chucVuMoiKhac : formik.touched?.chucVuMoi}
                getOptionLabel={(option) => option?.value}
                dependencies={[formik.values?.phanCongType]}
              />
            </Col>
            <Col sm={6} md={4} lg={3} xl={3} xxl={2}>
              <Autocomplete
                lable={lang("GENERAL.STATUS")}
                isRequired
                isReadOnly={isView}
                options={MISSION_STATUS}
                value={formik.values?.trangThai || null}
                name="trangThai"
                onChange={(selectedOption) => handleChangeSelect(VARIABLE_STRING.TRANG_THAI, selectedOption)}
                errors={formik.errors?.trangThai}
                touched={formik.touched?.trangThai}
              />
            </Col>
            <Col sm={6} md={4} lg={3} xl={2} xxl={2}>
              <TextValidator
                name="soQuyetDinh"
                lable={lang("INPUT_APPOINTMENT_NUMBER")}
                type="text"
                value={formik.values?.soQuyetDinh}
                readOnly={isView}
                onChange={formik.handleChange}
                errors={formik?.errors?.soQuyetDinh}
                touched={formik?.touched?.soQuyetDinh}
              />
            </Col>
            <Col sm={6} md={4} lg={2} xl={3} xxl={2}>
              <TextValidator
                name="ngayQuyetDinh"
                lable={lang("INPUT_APPOINTMENT_CONFIRM_DATE")}
                type="date"
                value={formik.values?.ngayQuyetDinh}
                readOnly={isView}
                onChange={formik.handleChange}
                onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.ngayQuyetDinh, "ngayQuyetDinh")}
                errors={formik?.errors?.ngayQuyetDinh}
                touched={formik?.touched?.ngayQuyetDinh}
              />
            </Col>
            <Col sm={6} md={5} lg={4} xl={4} xxl={4}>
              <RangeDatePicker
                label={lang("CONCURRENTLY.EFFECTIVE.TIME")}
                startDateName="tuNgay"
                endDateName="denNgay"
                isView={isView}
                handleChange={handleChangeDate}
                value={formik.values}
                setFieldValue={formik.setFieldValue}
                touch={formik.touched}
                errors={formik.errors}
                isRequired
              />
            </Col>
            <Col sm={6} md={7} lg={3} xl={3} xxl={3}>
              <UploadFile
                label="INPUT_APPOINTMENT_ATTACHMENTS"
                setValue={handleFile}
                isReadOnly={isView}
                fileValue={{
                  id: formik.values?.fileId || "",
                  name: formik.values?.fileName || ""
                }}
              />
            </Col>
            <Col sm={6} md={5} lg={3} xl={3} xxl={3}>
              <Autocomplete
                lable={lang("APPRECIATIVE.NGUOIQUYETDINHTEXT")}
                isReadOnly={isView}
                options={peopleDecisions}
                isRequired
                value={formik.values?.nguoiKy || null}
                name="nguoiKy"
                isAddNew
                onAddNew={() => setIsShowDialogAddRepresentative(true)}
                onChange={(selectedOption) => handleChangeSelect("nguoiKy", selectedOption)}
                errors={formik.errors?.nguoiKy}
                touched={formik.touched?.nguoiKy}
              />
            </Col>
          </Row>
          <Row className="g-4">
            <Col xs={12} lg={6} className="spaces pt-13">
              <Row>
                {formik.values?.phanCongType?.code === LIST_ASSIGNMENT_CODE.GIAO_CONG_VIEC && 
                  <Col xs={12} className="spaces pb-6">
                    <TextValidator
                      lable={lang("CONCURRENTLY.CONTENT_JOB")}
                      name="noiDungNhiemVu"
                      value={formik.values.noiDungNhiemVu || ""}
                      type="text"
                      readOnly={isView}
                      rows={2}
                      onChange={formik.handleChange}
                      as={TYPE.TEXTAREA}
                    />
                  </Col>
                }
                <Col xs={12}>
                  <TextValidator
                    lable={lang("GENERAL.DEPARTMENT_RECEIVE")}
                    name="khoaPhongNhanQuyetDinh"
                    value={formik.values.khoaPhongNhanQuyetDinh || ""}
                    type="text"
                    readOnly={isView}
                    rows={formik.values?.phanCongType?.code === LIST_ASSIGNMENT_CODE.GIAO_CONG_VIEC ? 2: 4}
                    onChange={formik.handleChange}
                    as={TYPE.TEXTAREA}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={12} lg={6} className="spaces pt-13">
              <TextValidator
                className="h-auto-input spaces"
                lable={lang("ALLOWANCE.NOTE")}
                name="ghiChu"
                value={formik.values.ghiChu}
                type="text"
                readOnly={isView}
                rows={formik.values?.phanCongType?.code === LIST_ASSIGNMENT_CODE.GIAO_CONG_VIEC ? 6 : 4}
                onChange={formik.handleChange}
                as={TYPE.TEXTAREA}
              />
            </Col>
          </Row>
        </div>
      </Form>
      {isShowDialogWord && (
        <ExportWord
          open={isShowDialogWord}
          handleClose={() => setIsShowDialogWord(false)}
          templateList={templateWord || []}
          getObj={{
            id: concurrentlyInfo?.id,
            type: DATA_WORD_TYPES.CONCURRENTLY,
            kiemNhiemType: concurrentlyInfo?.phanCongType?.code
          }}
        />
      )}
      {isShowDialogAddRepresentative && (
        <AddNewRepresentative
          handleClose={() => setIsShowDialogAddRepresentative(false)}
          handleCloseAndSearch={handleCloseAndSearchRepresentative}
        />
      )}
    </>
  );
}

export default ConcurrentlyForm;

/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { exportTiemChung } from "../../services/exportFileServices";
import { exportToFile, formatDateTable } from "../../utils/functionUtils";
import { REF_TAB } from "../constants/profileConsts";
import { InitVaccine, VaccineInfo } from "../models/dialogModels";
import { deleteTiemChung, getAllTiemChungById } from "../services/dialogServices";
import { VaccinationDialog } from "./dialogs/VaccinationDialog";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import TableCustom from "../../components/table-custom/TableCustom";
import { EXPORT_FILE_NAME, RESPONSE_STATUS_CODE, TYPE } from "../../constants/moduleConsts";
import AppContext from "../../../AppContext";

const VaccinationInformation = (props: any) => {
  const { lang } = useMultiLanguage();
  const { identify, isView, employeeProfiles, activeTab } = props;
  const [shouldOpenVaccinationDialog, setShouldOpenVaccinationDialog] = useState<boolean>(false);
  const [listVaccine, setListVaccine] = useState<VaccineInfo[]>([]);
  const [dataSelect, setDataSelect] = useState<VaccineInfo>(InitVaccine);
  const [lastDate, setLastDate] = useState<string | null>(null);
  const { setPageLoading } = useContext(AppContext);
  const [dataChecked, setDataChecked] = useState<any[]>([]);

  useEffect(() => {
    if (!listVaccine || !listVaccine.length) return;
    setLastDate(listVaccine[0]?.ngayTiem);
  }, [listVaccine])

  useEffect(() => {
    if (identify && activeTab === REF_TAB.TT_TIEM_CHUNG) {
      updateDataVaccine();
    }
  }, [employeeProfiles, activeTab]);

  const handleOpenVaccinationDialog = (row: any) => {
    setShouldOpenVaccinationDialog(true);
    setDataSelect(row);
  };
  const handleCloseVaccinationDialog = (): void => {
    setShouldOpenVaccinationDialog(false);
    setDataSelect(InitVaccine);
  };
  
  const handleCloseAndSearch = () => {
    updateDataVaccine();
    handleCloseVaccinationDialog();
  }

  const updateDataVaccine = async () => {
    if (identify) {
      try {
        setPageLoading(true)
        const { data } = await getAllTiemChungById(identify);
        if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
          setListVaccine(data?.data);
          setPageLoading(false);
        } else {
          toast.warning(data?.message);
        }
      } catch (error) {
        toast.error(lang("GENERAL.ERROR"));
      }
    }
  };

  const handleDelete = async (ids: string) => {
    try {
      const { data } = await deleteTiemChung(ids);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("TOAST.DELETE.SUCCESS"));
        updateDataVaccine();
        return true;
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const columns = [
    {
      name: lang("TABLE.INDEX"),
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("INPUT.VACCINATION.DATE"),
      field: "ngayTiem",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row: any) => <span>{formatDateTable(row?.ngayTiem)}</span>
    },
    {
      name: lang("INPUT.VACCINATION.DISEASES"),
      field: "phongBenhText",
      headerStyle: {
        minWidth: "200px"
      }
    },
    {
      name: lang("INPUT.VACCINATION.KIND"),
      field: "loaiVacxin",
      headerStyle: {
        minWidth: "200px"
      }
    },
    {
      name: lang("INPUT.VACCINATION.COUNT"),
      field: "muiTiem",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row: any) => <span>{row?.muiTiem?.name}</span>
    },
    {
      name: lang("INPUT.VACCINATION.STATUSAFTER"),
      field: "tinhTrangSkSauTiem",
      headerStyle: {
        minWidth: "200px"
      }
    },
    {
      name: lang("INPUT.VACCINATION.NEXTTIME"),
      field: "lanTiemTiepTheo",
      headerStyle: {
        minWidth: "200px"
      },
      render: (row: any) => <span>{formatDateTable(row?.lanTiemTiepTheo)}</span>
    }
  ];

  return (
    <div className="form-info">
      <div className="block-content">
        <TableCustom
          id="listVaccine"
          data={listVaccine}
          columns={columns}
          isActionTableTab
          type={TYPE.MULTILINE}
          title={lang("INPUT.VACCINATION")}
          fixedColumnsCount={3}
          noToolbar={true}
          noPagination={true}
          buttonAdd={!isView}
          notDelete={isView}
          buttonExportExcel={!dataChecked.length}
          dataChecked={dataChecked}
          setDataChecked={setDataChecked}
          handleDoubleClick={handleOpenVaccinationDialog}
          handleOpenDialog={handleOpenVaccinationDialog}
          handleDelete={handleDelete}
          handleExportExcel={() => 
            exportToFile({
              exportAPI: () => exportTiemChung(employeeProfiles.id), 
              fileName: EXPORT_FILE_NAME.TIEM_CHUNG, 
              setPageLoading
            })
          }
          dependencies={activeTab}
        />
      </div>
      {shouldOpenVaccinationDialog && (
        <VaccinationDialog
          open={shouldOpenVaccinationDialog}
          handleClose={handleCloseVaccinationDialog}
          identify={identify}
          isView={isView}
          handleCloseAndSearch={handleCloseAndSearch}
          dataSelect={dataSelect}
          lastDate={lastDate}
        />
      )}
    </div>
  );
};

export default VaccinationInformation;

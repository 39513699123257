import { FC, useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../constants";
import useMultiLanguage from "../../hook/useMultiLanguage";
import InputSearch from "../components/input/InputSearch";
import TableCustom, { columnNamesType } from "../components/table-custom/TableCustom";
import { checkStatus, formatDateTable, hasAuthority } from "../utils/functionUtils";
import AppContext from "../../AppContext";
import { GroupButton } from "../components/GroupButton";
import { INIT_SEARCH_OBJECT, RESPONSE_STATUS_CODE, TYPE } from "../constants/moduleConsts";
import { IOfficeWorkSchedule } from "./models/officeWorkScheduleModels";
import OfficeWorkScheduleForm from "./components/office-work-schedule/OfficeWorkScheduleForm";
import { deleteOfficeWorkSchedule, searchOfficeWorkSchedule } from "./services/officeWorkScheduleServices";
import "./shift.scss";
import { LIST_STATUS } from "../manager-request/constants/managerRequestConsts";

const OfficeWorkSchedule: FC = () => {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [listOfficeWork, setListOfficeWork] = useState<IOfficeWorkSchedule[]>([]);
  const [openOfficeWorkForm, setOpenOfficeWorkForm] = useState<boolean>();
  const [officeWorkInfo, setOfficeWorkInfo] = useState<IOfficeWorkSchedule>({} as IOfficeWorkSchedule);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [isView, setIsView] = useState<boolean>(false);
  const [searchObject, setSearchObject] = useState<any>(INIT_SEARCH_OBJECT);

  const handleOpenForm = (info: any) => {
    setIsView(true);
    setOpenOfficeWorkForm(true);
    setOfficeWorkInfo(info);
  };

  const handleCloseAndSearch = () => {
    handleSearch();
    handleClose();
  }

  const handleClose = () => {
    setOpenOfficeWorkForm(false);
    setIsView(false);
    setOfficeWorkInfo({} as IOfficeWorkSchedule);
  };

  const getListOfficeWorkSchedule = async (searchData?: any) => {
    try {
      setPageLoading(true);
      setSearchObject({ ...searchData })
      const { data } = await searchOfficeWorkSchedule(searchData);
      if(data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListOfficeWork(data?.data?.content || []);
        setTotalPage(data?.data?.pageable?.totalPages);
        setTotalElements(data?.data?.pageable?.totalElements);
        setNumberOfElements(data?.data?.pageable?.numberOfElements);
      } else {
        toast.warning(data?.message);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleOpenUpdateDialog = () => {
    setOpenOfficeWorkForm(true)
  }

  const handleCloseUpdateDialog = () => {
    setIsView(true);
  }

  const handleSearch = (data: any = {}) => {
    const dataSearch: any = {
      ...searchObject,
      ...data
    };
    dataSearch.keyword = dataSearch?.keyword?.trim() || "";
    getListOfficeWorkSchedule(dataSearch);
  };

  useEffect(() => {
    if(searchObject?.pageIndex && searchObject?.pageSize) {
      handleSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchObject?.pageIndex, searchObject?.pageSize])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchObject({
      ...searchObject,
      keyword: e.target?.value
    });
  };

  const handleDelete = async (ids: string[]) => {
    try {
      const { data } = await deleteOfficeWorkSchedule(ids)
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("TIMEKEEPING.SCHEDULE.OFFICE_WORK.DELETE"))
        await handleSearch();
        return true;
      } else {
        toast.warning(data?.message)
      }
    }
    catch (err: any) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }

  const columns: columnNamesType[] = [
    {
      name: "STT",
      field: "",
      render: (_row, _index, stt) => <span>{stt}</span>
    },
    {
      name: lang("STATUS"),
      field: "status",
      headerStyle: {
        minWidth: "100px"
      },
      render: (row: any) => (
        <span className={`${checkStatus(LIST_STATUS, Number(row?.status?.code))} status`}>
          {row?.status?.name}
        </span>
      )
    },
    {
      name: lang("INPUT.DEPARTMENTS"),
      field: "phongBanText",
      headerStyle: {
        minWidth: "200px",
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("GENERAL.TIME"),
      field: "thoiGian",
      headerStyle: {
        minWidth: "120px"
      },
      render: (row: any) => <span>{row?.month + "/" + row?.year}</span>
    },
    {
      name: lang("DATE.APPROVE"),
      field: "approvalDate",
      headerStyle: {
        minWidth: "120px"
      },
      render: (row: any) => <span>{formatDateTable(row?.approvalDate)}</span>
    },
    {
      name: lang("WELFARE.ACTUAL_COST.APPROVED_PEOPLE"),
      field: "approverText",
      headerStyle: {
        minWidth: "120px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
  ];

  return (
    <div className="spaces px-8 py-12 flex-1">
      {!openOfficeWorkForm ? (
        <>
          <Row className="spaces mb-12 flex-middle flex-space-between">
            <Col xs={5} xl={6} xxl={7} className="p-0">
              <h2 className="breadcrumb-title">{lang("TIMEKEEPING.SCHEDULE.OFFICE_WORK.LIST")}</h2>
            </Col>
            <Col xs={5} xl={3} xxl={3} className="flex">
              <div className="spaces mr-10 flex-1">
                <InputSearch
                  className="border-3px"
                  value={searchObject?.keyword}
                  placeholder="Tìm kiếm"
                  handleSearch={() => handleSearch({ pageIndex: 1 })}
                  handleChange={handleChange}
                  isEnter
                />
              </div>
              <GroupButton handleEvent={() => handleSearch({ pageIndex: 1 })}>
                {lang("BTN.SEARCH")}
              </GroupButton>
            </Col>
          </Row>
          <Row className="bg-white">
            <Col className="table-scroll">
              <TableCustom
                id="office-work-schedule-list"
                data={listOfficeWork}
                columns={columns}
                searchObject={searchObject}
                setSearchObject={setSearchObject}
                buttonAdd={hasAuthority(PERMISSIONS.LICH_HANH_CHINH, PERMISSION_ABILITY.CREATE)}
                notDelete={!hasAuthority(PERMISSIONS.LICH_HANH_CHINH, PERMISSION_ABILITY.DELETE)}
                type={TYPE.MULTILINE}
                totalPages={totalPage}
                totalElements={totalElements}
                numberOfElements={numberOfElements}
                handleOpenDialog={handleOpenUpdateDialog}
                handleDoubleClick={(row: any) => handleOpenForm(row)}
                handleDelete={(ids: string[]) => handleDelete(ids)}
              />
            </Col>
          </Row>
        </>
      ) : (
        <OfficeWorkScheduleForm
          handleClose={handleClose}
          handleCloseAndSearch={handleCloseAndSearch}
          officeWorkInfo={officeWorkInfo}
          handleCloseUpdateDialog={handleCloseUpdateDialog}
          isView={isView}
          setIsView={setIsView}
        />
      )}
    </div>
  )
}

export { OfficeWorkSchedule };
import { Col, Form } from "react-bootstrap";
import { findChildrenNamesByValue } from "../../utils/managerUtils";
import { INodeTree } from "../../models/managerModels";

interface IProps {
  data: INodeTree[];
  isView: boolean;
  handleShowListPermission: (keyPermission: string) => void;
  handleCheck: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SelectedPermissions: React.FC<IProps> = ({ isView, data = [], handleShowListPermission, handleCheck }) => {
  const mapPermission = (listPermissions: INodeTree[], name: string) => {
    return listPermissions.map((item, index) => {
      return (
        <div key={index} className='relative'>
          <span 
            className={`vertical-lines per-item`}
            data-count={countChildren(item)}
            style={{height: `calc(100% - ${countChildren(item) > 0 ? countChildren(item) * 33 + 4 : 42}px)`}}
          ></span>
          <Form.Group className="mb-3 d-flex">
            <Form.Check
              type="checkbox"
              label={<span className='horizontal-line'>{item.nameNode}</span>}
              checked={!!item.checked}
              disabled={isView}
              value={item.value}
              onChange={handleCheck}
            />
          </Form.Group>
          {item.children ? (
            <div className='list-permissions-item'>{mapPermission(item.children, item.name)}</div>
          ) : null}
        </div>
      );
    });
  };

  const countChildren = (item: INodeTree): number => {
    let value = item.value;
    let lastChildValue = item.children?.[item.children.length - 1]?.value;
    let lastChildChildren = item.children?.[item.children.length - 1]?.children;
    if(!lastChildValue || !lastChildChildren?.length) return 0;
    let moreChild: string[] = [];
    let children: string[] = findChildrenNamesByValue(data, lastChildValue);
    for (let index: number = 0; index < children.length; index++) {
      let splitStr: string[] = children[index].split('.');
      let minElement = value.split('.').length;
      let maxElement: number = splitStr.length;
      for (let index: number = 0; index < maxElement - minElement - 1; index++) {
        splitStr.pop();
        let string: string = splitStr.join('.');
        if(!moreChild.includes(string)){
          moreChild.push(string);
        }
      }
    }
    return children.length + moreChild.length;
  }

  return (
    <>
      {data.map((item, index) => {
        return (
          <Col xs={12} md={6} lg={4} key={index}>
            <div className='select-permission relative' key={index}>
              {item.notShow &&
              <span
                className={`vertical-lines`}
                data-count={countChildren(item)}
                style={{height: `calc(100% - ${countChildren(item) > 0 ? countChildren(item) * 33 + 4 : 42}px)`}}
              ></span>}
              {!item.children ? (
                <i className="bi bi-circle"></i>
              ) : item.notShow ? (
                <i className="bi bi-dash-circle" onClick={() => handleShowListPermission(item.value)}></i>
              ) : (
                <i className="bi bi-plus-circle" onClick={() => handleShowListPermission(item.value)}></i>
              )}
              <Form.Group className="mb-3 d-flex">
                <Form.Check
                  type="checkbox"
                  label={
                    <span className={index === data.length - 1 ? 'horizontal-line' : 'horizontal-line'}>{item.nameNode}</span>
                  }
                  disabled={isView}
                  value={item.value}
                  checked={!!item.checked}
                  onChange={(e: any) => handleCheck(e)}
                />
              </Form.Group>
              {item.children
                ? item.notShow && <div className='list-permissions'>{mapPermission(item.children, item.name)}</div>
                : null}
            </div>
          </Col>
        );
      })}
    </>
  );
};

export default SelectedPermissions;
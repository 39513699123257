import { ReceivableInfo, ResignInfo, ResignObjectSearch } from '../models/resignModels';
import { APIResponse } from "../../models/models";
import axios, { AxiosResponse } from "axios";
import { paramsConfig } from '../../utils/paramsUtils';

const API_URL = process.env.REACT_APP_API_URL;

export const getListResign = (objectSearch: ResignObjectSearch): Promise<AxiosResponse<APIResponse>> => {
    let url = `${API_URL}/employee-nghi-viec/page`;
    return axios.get(url, paramsConfig(objectSearch));
};

export const deleteResign = (ids: string[]
): Promise<AxiosResponse<APIResponse>> => {
    let url = `${API_URL}/employee-nghi-viec/ids?ids=${ids.toString()}`;
    return axios.delete(url);
};

export const addNewResign = (
    resign: ResignInfo
): Promise<AxiosResponse<any>> => {
    let url = `${API_URL}/employee-nghi-viec`;
    return axios.post(url, resign);
};
export const editResign = (id: string, resign: ResignInfo): Promise<AxiosResponse<any>> => {
    let url = `${API_URL}/employee-nghi-viec/${id}`;
    return axios.put(url, resign);
};

export const getListReceivable = (id: string): Promise<AxiosResponse<APIResponse>> => {
    let url = `${API_URL}/nghi-viec-khoan-thu/page?nghiViecId=${id}`;
    return axios.get(url);
};

export const addReceivable = (receivable: ReceivableInfo): Promise<AxiosResponse<any>> => {
    let url = `${API_URL}/nghi-viec-khoan-thu`;
    return axios.post(url, receivable);
}

export const updateReceivable = (id: string, receivable: ReceivableInfo): Promise<AxiosResponse<any>> => {
    let url = `${API_URL}/nghi-viec-khoan-thu/${id}`;
    return axios.put(url, receivable);
}

export const deleteListReceivable = (ids: string[]): Promise<AxiosResponse<APIResponse>> => {
    let url = `${API_URL}/nghi-viec-khoan-thu/ids?ids=${ids.toString()}`;
    return axios.delete(url);
};
import { paramsConfig } from "../../utils/paramsUtils";
import axios from "axios";
import { IShiftDivision } from "../models/shiftDivisionModels";

const API_URL_CHAM_CONG_PHAN_CA = process.env.REACT_APP_API_URL_CHAM_CONG + "/bang-phan-ca";

export const searchShiftDivision = (searchData: any) => {
  let url = `${API_URL_CHAM_CONG_PHAN_CA}/page`;
  return axios.get(url, paramsConfig(searchData))
};

export const getShiftDivisionById = (id: string) => {
  return axios.get(`${API_URL_CHAM_CONG_PHAN_CA}/${id}`);
};

export const addShiftDivision = (shiftDivisionInfo: IShiftDivision) => {
  let url = `${API_URL_CHAM_CONG_PHAN_CA}`;
  return axios.post(url, shiftDivisionInfo);
};

export const updateShiftDivision = (id: string, shiftDivisionInfo: IShiftDivision) => {
  let url = `${API_URL_CHAM_CONG_PHAN_CA}/${id}`;
  return axios.put(url, shiftDivisionInfo);
};

export const deleteShiftDivision = (ids: string[]) => {
  let url = `${API_URL_CHAM_CONG_PHAN_CA}/ids?ids=${ids.toString()}`;
  return axios.delete(url);
};

export const approveShiftDivision = (id: string, status: number) => {
  let url = `${API_URL_CHAM_CONG_PHAN_CA}/approve/${id}`;
  return axios.put(url, null, paramsConfig({ status: status }));
};
import { useMemo } from "react";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { TablePagination } from "../../components/table-custom/TablePagination";
import { handlePagesChange, handleRowsPerPageChange } from "../../utils/pageUtils";
import { IColumns } from "../models/reportModels";
import { KTSVG } from "../../../../_metronic/helpers";
import { DEFAULT_PAGE_INDEX, ROWS_FOR_PAGE } from "../../constants/moduleConsts";
import { GroupButton } from "../../components/GroupButton";

interface IProps {
  columns: IColumns[];
  data: any[];
  totalPages?: number;
  totalElements?: number;
  numberOfElements?: number;
  page?: number;
  setPage?: (page: any) => void;
  rowsPerPage?: number;
  setRowsPerPage?: (page: any) => void;
  buttonAdd?: boolean;
  buttonExportExcel?: boolean;
  handleExportExcel?: (row: any) => void;
  handleOpenDialog?: (row: any) => void;
  title?: string;
  noPagination?: boolean;
  notEdit?: boolean;
  handleDoubleClick?: (row: any) => void;
}

function TableWithColumnGroup(props: IProps) {
  const {
    columns,
    data,
    totalElements,
    totalPages,
    numberOfElements,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    buttonAdd,
    buttonExportExcel,
    handleExportExcel,
    handleOpenDialog,
    title,
    noPagination,
    notEdit,
    handleDoubleClick
  } = props;
  const { lang } = useMultiLanguage();

  const columnsConvert = useMemo(() => {
    return columns.map((col) => (col.child ? col.child : col)).flat();
  }, [columns]);

  return (
    <>
      <div className="d-flex align-items-center flex-space-between spaces mb-10">
        {title && <span className="spaces fs-18 text-header-table fw-600">{title}</span>}
        <div className="spaces p-0 d-flex align-items-center">
          {buttonAdd && (
            <GroupButton className="spaces mx-16" handleEvent={handleOpenDialog}>
              <i className="spaces bi bi-plus fs-20 white"></i>
              <p className="spaces fs-14 m-0 ">Thêm mới</p>
            </GroupButton>
          )}

          {buttonExportExcel && (
            <GroupButton className="spaces mr-16" outline handleEvent={handleExportExcel}>
              <KTSVG path="/media/icons/export-excel.svg" /> {lang("BTN.EXPORT")}
            </GroupButton>
          )}
        </div>
      </div>
      <div className="table-responsive customs-collapse-row m-0">
        <table className="table-row-dashed dataTable table w-100">
          <thead className="top-0 z-index-1">
            <tr className="text-header-table fw-600 fw-bolder text-uppercase gs-0 border">
              {columns.map((col, index) => {
                return (
                  <th
                    key={col?.field + index}
                    className="p-table text-center bg-header-table"
                    colSpan={col?.colSpan || 1}
                    rowSpan={col?.rowSpan || 1}
                    style={col?.headerStyle}
                  >
                    {col?.name}
                  </th>
                );
              })}
            </tr>
            <tr className="text-header-table fw-600 fw-bolder text-uppercase gs-0 border">
              {columns.map((col) =>
                col?.child ? (
                  col.child.map((colChild, index) => (
                    <th
                      key={colChild?.field + index}
                      className="p-table text-center bg-header-table"
                      rowSpan={1}
                      style={colChild?.headerStyle}
                    >
                      {colChild?.name}
                    </th>
                  ))
                ) : (
                  <></>
                )
              )}
            </tr>
          </thead>
          <tbody>
            {data?.length > 0 ? (
              data?.map((row: any, index: number) => (
                <tr
                  key={index}
                  className="border-bottom border"
                >
                  {columnsConvert.map((item, idx) => {
                    return (
                      <td
                        key={idx}
                        className="td-vertical-center bg-white"
                        style={item?.cellStyle}
                        onDoubleClick={() => {
                          !notEdit && handleDoubleClick && handleDoubleClick(row);
                        }}
                      >
                        {item?.render
                          ? page && rowsPerPage
                            ? item?.render(row, index, (page - 1) * rowsPerPage + index + 1, data)
                            : item?.render(row, index, index + 1, data)
                          : row[item?.field]}
                      </td>
                    );
                  })}
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={columnsConvert.length}
                  className="text-center border"
                >
                  {lang("TABLE.DATA.EMPTY")}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {!noPagination && (
        <TablePagination
          page={page || DEFAULT_PAGE_INDEX}
          setPage={setPage ? setPage : () => {}}
          handlePagesChange={handlePagesChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
          rowsForPage={ROWS_FOR_PAGE}
          rowsPerPage={rowsPerPage || 10}
          setRowsPerPage={setRowsPerPage ? setRowsPerPage : () => {}}
          totalPages={totalPages || 0}
          totalElements={totalElements || 0}
          numberOfElements={numberOfElements || 0}
        />
      )}
    </>
  );
}

export default TableWithColumnGroup;

import moment from "moment";
import { IDayWithWeekDay, IWeekDay } from "../models/models";
import { FORMAT_DATE, LIST_WEEKDAY } from "../constants/moduleConsts";

const createWeekdayPredicate = (currentDate: moment.Moment) => {
  return (weekday: IWeekDay) => {
    return weekday.dayOfWeek === currentDate.isoWeekday();
  };
};

export const buildWeekDaysList = (startDateOfWeek: string | null, endDateOfWeek: string | null) => {
  if(!startDateOfWeek || !endDateOfWeek) return [];
  let currentDate = moment(startDateOfWeek);
  const daysList: IDayWithWeekDay[] = [];

  while (currentDate.isSameOrBefore(moment(endDateOfWeek))) {
    const predicate = createWeekdayPredicate(currentDate);
    daysList.push({
      day: currentDate.format(FORMAT_DATE.YYYY_MM_DD_INPUT),
      weekday: LIST_WEEKDAY.find(predicate)?.name || "" // Chú ý: isoWeekday trả về 1 cho Thứ Hai, 7 cho Chủ Nhật
    });
    currentDate = currentDate.add(1, 'days');
  }

  return daysList;
};
import { IAttendanceWatch } from "../models/attendanceWatchModes";
import { IChangeCompensated } from "../models/changeCompensatedModes";
import { IRegisterOvertime } from "../models/registerOvertimeModes";

export const convertDataAttendanceWatchDto = (data: any) => {
	let { employee, thuTruong, truongKhoa, phongTccb, ..._data } = data;

	let values = {
		..._data,
		employeeId: employee?.id,
		employeeText: employee?.name,
		thuTruongId: thuTruong?.id ?? null,
		thuTruongText: thuTruong?.name ?? null,
		truongKhoaId: truongKhoa?.id ?? null,
		truongKhoaText: truongKhoa?.name ?? null,
		phongTccbId: phongTccb?.id ?? null,
		phongTccbText: phongTccb?.name ?? null,
	}
	return values;
}

export const convertDataAttendanceWatchUI = (data: any) : IAttendanceWatch => {
    let {
        employeeId,
        employeeText,
        truongKhoaId,
        truongKhoaText,
        thuTruongId,
        thuTruongText,
        phongTccbId,
        phongTccbText,
        ..._data
    } = data;

    let values = {
        ..._data,
        employee: employeeId ? {
            id: employeeId,
            name: employeeText,
						maNhanVien: data?.maNhanVien,
        } : null,
        truongKhoa: truongKhoaId ? {
            id: truongKhoaId,
            name: truongKhoaText,
        } : null,
        thuTruong: thuTruongId ? {
            id: thuTruongId,
            name: thuTruongText,
        } : null,
        phongTccb: phongTccbId ? {
            id: phongTccbId,
            name: phongTccbText,
        } : null,
    }
    return values;
}

export const convertDataRegisterOvertimeDto = (data: any) => {
	let { employee, truongKhoa, donViCongTac, phongBan, ..._data } = data;

	let values = {
		..._data,
		employeeId: employee?.id,
		employeeText: employee?.name,
		maNhanVien: employee?.maNhanVien,
		phongBanId: phongBan?.id ?? null,
		phongBanText: phongBan?.name ?? null,
		truongKhoaId: truongKhoa?.id ?? null,
		truongKhoaText: truongKhoa?.name ?? null,
		donViCongTacId: donViCongTac?.id ?? null,
		donViCongTacText: donViCongTac?.name ?? null,
	}
	return values;
}

export const convertDataRegisterOvertimeUI = (data: any): IRegisterOvertime => {
	let {
		maNhanVien,
		employeeId,
		employeeText,
		truongKhoaId,
		truongKhoaText,
		donViCongTacId,
		donViCongTacText,
		phongBanId,
		phongBanText,
		..._data
	} = data;

    let values = {
        ..._data,
        employee: employeeId ? {
            id: employeeId,
            name: employeeText,
            maNhanVien: maNhanVien,
        } : null,
        truongKhoa: truongKhoaId ? {
            id: truongKhoaId,
            name: truongKhoaText,
        } : null,
        donViCongTac: donViCongTacId ? {
            id: donViCongTacId,
            name: donViCongTacText,
        } : null,
        phongBan: phongBanId ? {
            id: phongBanId,
            name: phongBanText,
        } : null,
    }
    return values;
}

export const convertDataChangeCompensatedDto = (data: any) => {
	let { employee, employeeDangKy, thuTruong, truongKhoa, phongTccb, caHienTai, caDangKy, ..._data } = data;

	let values = {
		..._data,
		employeeId: employee?.id,
		employeeText: employee?.name,
		thuTruongId: thuTruong?.id ?? null,
		thuTruongText: thuTruong?.name ?? null,
		truongKhoaId: truongKhoa?.id ?? null,
		truongKhoaText: truongKhoa?.name ?? null,
		phongTccbId: phongTccb?.id ?? null,
		phongTccbText: phongTccb?.name ?? null,
		caHienTaiId: caHienTai?.id,
		caHienTaiText: caHienTai?.tenCa ?? "",
		caDangKyId: caDangKy?.id ?? "",
		caDangKyText: caDangKy?.tenCa ?? "",
		employeeDangKyId: employeeDangKy?.id ?? "",
		employeeDangKyText: employeeDangKy?.name ?? "",
		maNhanVienDangKy: employeeDangKy?.maNhanVien ?? "",
		ngayDangKy: data?.ngayDangKy,
	}
	return values;
}

export const convertDataChangeCompensatedUI = (data: any): IChangeCompensated => {
	let {
		maNhanVien,
		employeeId,
		employeeText,
		maNhanVienDangKy,
		employeeDangKyId,
		employeeDangKyText,
		truongKhoaId,
		truongKhoaText,
		thuTruongId,
		thuTruongText,
		phongTccbId,
		phongTccbText,
		caHienTaiId,
		caHienTaiText,
		caDangKyId,
		caDangKyText,
		..._data
	} = data;

	let values = {
		..._data,
		maNhanVien,
		employee: employeeId ? {
			id: employeeId,
			name: employeeText,
			maNhanVien: maNhanVien,
		} : null,
		employeeDangKy: employeeDangKyId ? {
			id: employeeDangKyId,
			name: employeeDangKyText,
			maNhanVien: maNhanVienDangKy,
		} : null,
		truongKhoa: truongKhoaId ? {
			id: truongKhoaId,
			name: truongKhoaText,
		} : null,
		thuTruong: thuTruongId ? {
			id: thuTruongId,
			name: thuTruongText,
		} : null,
		phongTccb: phongTccbId ? {
			id: phongTccbId,
			name: phongTccbText,
		} : null,
		caHienTai: caHienTaiId ? {
			id: caHienTaiId,
			tenCa: caHienTaiText
		} : null,
		caDangKy: caDangKyId ? {
			id: caDangKyId,
			tenCa: caDangKyText
		} : null,
	}
	return values;
}

import { FC, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../../../constants";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import InputSearch from "../../../components/input/InputSearch";
import TableCustom from "../../../components/table-custom/TableCustom";
import AdvancedSearch from "../../../profile/components/dialogs/AdvancedSearch";
import { MISSION_STATUS, MISSION_STATUS_CODE, RESPONSE_STATUS_CODE, TYPE, INIT_SEARCH_OBJECT, EXPORT_FILE_NAME, INIT_INPUT_SEARCH } from "../../../constants/moduleConsts";
import { exportGianDoan } from "../../../services/exportFileServices";
import { checkStatus, convertSearch, exportToFile, formatDateTable, hasAuthority, searchDataRoleUser } from "../../../utils/functionUtils";
import { FIELD_SEARCH_INTERRUPT } from "../../constants/interruptConsts";
import { InterruptInfo } from "../../models/interruptModels";
import { deleteInterrupt, getListInterrupt } from "../../services/interruptServices";
import { Col, Row } from "react-bootstrap";
import AppContext from "../../../../AppContext";
import { GroupButton } from "../../../components/GroupButton";
import { IItemSearch } from "../../../models/models";

interface IProps {
  handleOpenDialog: (row: InterruptInfo) => void;
  handleOpenAddNewDialog: () => void;
  listInterrupt: InterruptInfo[];
  activeTab: string;
  setListInterrupt: React.Dispatch<React.SetStateAction<InterruptInfo[]>>;
  initSearchObject?: Object;
  setInitSearchObject?: React.Dispatch<React.SetStateAction<any>>;
  dataChecked: InterruptInfo[];
  setDataChecked: React.Dispatch<React.SetStateAction<InterruptInfo[]>>;
}

const InterruptInformation: FC<IProps> = (props) => {
  const {
    handleOpenDialog,
    handleOpenAddNewDialog,
    listInterrupt,
    activeTab,
    setListInterrupt,
    initSearchObject,
    setInitSearchObject,
    dataChecked,
    setDataChecked,
  } = props
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  document.title = `${lang("GENERAL.APP.NAME")} | ${lang("GENERAL.INTERRUPT")}`;
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [searchObject, setSearchObject] = useState<any>(initSearchObject || INIT_SEARCH_OBJECT);
  const [isAdvancedSearch, setIsAdvancedSearch] = useState<boolean>(false);
  const [listInputSearch, setListInputSearch] = useState<any[]>([INIT_INPUT_SEARCH]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchObject({
      ...searchObject,
      keyword: e.target?.value
    })
  }

  const handleSearch = (data: any = {}) => {
    const dataSearch: any = {
      ...searchObject,
      ...data
    }

    dataSearch.keyword = dataSearch?.keyword?.trim() || ""
    setInitSearchObject && setInitSearchObject(dataSearch)
    updatePageData(dataSearch);
  }

  useEffect(() => {
    handleSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchObject?.pageIndex, searchObject?.pageSize])

  const handleAdvancedSearch = (data: IItemSearch[]) => {
    setListInputSearch(data)
    handleSearch({ ...convertSearch(data), pageIndex: 1 })
  }

  const handleOpenAdvancedSearch = () => {
    setIsAdvancedSearch(true)
  }

  const updatePageData = async (searchObject?: any) => {
    try {
      setSearchObject({ ...searchObject });
      setPageLoading(true);
      const { data } = await getListInterrupt(hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW) ? searchDataRoleUser(searchObject) : { ...searchObject });
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setIsAdvancedSearch(false);
        setListInterrupt(data?.data?.content || []);
        setTotalPage(data?.data?.pageable?.totalPages || 0);
        setTotalElements(data?.data?.pageable?.totalElements);
        setNumberOfElements(data?.data?.pageable?.numberOfElements);
      } else {
        toast.warning(data?.message);
      }
    } catch (err: any) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleDelete = async (ids: string[]) => {
    try {
      const { data } = await deleteInterrupt(ids);
      if (data.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("TOAST.DELETE.SUCCESS"));
        await updatePageData(searchObject);
        return true;
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }

  const columns = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang('RESIGN.STATUS'),
      field: "trangThai",
      headerStyle: {
        minWidth: "150px"
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any) => (
        <span className={`${checkStatus(MISSION_STATUS, row?.trangThai?.code)} status`}>
          {row?.trangThai?.name}
        </span>
      )
    },
    {
      name: lang('RESIGN.EMPLOYEECODE'),
      field: "maNhanVien",
      headerStyle: {
        minWidth: "120px"
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any) => <span className="color-steel-blue fw-600">{row?.maNhanVien}</span>
    },
    {
      name: lang('GENERAL.FULLNAME'),
      field: "name",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang('INPUT.SALARY.DEPARTMENT'),
      field: "phongBanText",
      headerStyle: {
        minWidth: "250px"
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang('GENERAL.EMPLOYEE.POSITION'),
      field: "chucVuText",
      headerStyle: {
        minWidth: "250px"
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang('GENERAL.EMPLOYEE.TITLE'),
      field: "viTriCongViecText",
      headerStyle: {
        minWidth: "270px"
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang('RESIGN.TYPE_BREAK'),
      field: "loaiNghi",
      headerStyle: {
        minWidth: "150px"
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any) => row?.loaiNghi?.name
    },
    {
      name: lang('START.DATE'),
      field: "ngayBatDau",
      headerStyle: {
        minWidth: "120px"
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any) => <span>{formatDateTable(row?.ngayBatDau)}</span>
    },
    {
      name: lang('TIME_KEEPING.RETURN_DATE_SHORTHAND'),
      field: "ngayQuayLaiLV",
      headerStyle: {
        minWidth: "120px"
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any) => <span>{formatDateTable(row?.ngayQuayLaiLV)}</span>
    },
    {
      name: lang('GENERAL.EMPLOYEE.RESIGN_REASON'),
      field: "lyDoNghi",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        textAlign: "enter",
      },
    },
    {
      name: lang('INPUT.WORKPROCESS.DECISION.NUMBER'),
      field: "soQuyetDinh",
      headerStyle: {
        minWidth: "150px"
      },
      cellStyle: {
        textAlign: "enter",
      },
    }
  ]

  return (
    <div className="resign spaces px-10 py-16">
      <Row className="spaces mb-8 flex-middle ml-10">
        <Col xs={4} xxl={6} className="spaces p-0">
          <h2 className="breadcrumb-title"> Danh sách nghỉ gián đoạn</h2>
        </Col>
        <Col xs={8} xxl={6} className="flex">
          <div className="spaces mr-10 flex-1">
            <InputSearch
              className='border-3px'
              value={searchObject?.keyword}
              placeholder="Tìm kiếm theo mã NV, họ và tên, phòng ban, chức danh và số quyết định"
              isEnter={true}
              handleSearch={() => handleSearch({ pageIndex: 1 })}
              handleChange={handleChange} />
          </div>
          <GroupButton className="spaces mr-10" handleEvent={() => handleSearch({ pageIndex: 1 })}>
            {lang("BTN.SEARCH")}
          </GroupButton>
          <GroupButton className="spaces mr-10" outline handleEvent={handleOpenAdvancedSearch}>
            {lang("BTN.ADVANCE_SEARCH")}
          </GroupButton>
          {isAdvancedSearch &&
            <AdvancedSearch
              open={isAdvancedSearch}
              listInputSearch={listInputSearch}
              handleSearch={handleAdvancedSearch}
              listOption={FIELD_SEARCH_INTERRUPT}
              handleClose={() => setIsAdvancedSearch(false)}
            />
          }
        </Col>
      </Row>
      <div className="spaces flex-1 pb-18 table-custom">
        <TableCustom
          id="listInterrupt"
          data={listInterrupt}
          columns={columns}
          dependencies={activeTab}
          searchObject={searchObject}
          setSearchObject={setSearchObject}
          buttonAdd={hasAuthority(PERMISSIONS.NGHI_VIEC, PERMISSION_ABILITY.CREATE)}
          buttonExportExcel={!hasAuthority(PERMISSIONS.USER, PERMISSION_ABILITY.VIEW)}
          notDelete={!hasAuthority(PERMISSIONS.NGHI_VIEC, PERMISSION_ABILITY.DELETE)}
          handleExportExcel={(ids: string[]) =>
            exportToFile({
              exportAPI: () => exportGianDoan({ ids, ...searchObject }),
              fileName: EXPORT_FILE_NAME.GIAN_DOAN,
              setPageLoading
            })
          }
          type={TYPE.MULTILINE}
          fixedColumnsCount={4}
          totalPages={totalPage}
          totalElements={totalElements}
          numberOfElements={numberOfElements}
          handleOpenDialog={handleOpenAddNewDialog}
          handleDoubleClick={(row: any) => handleOpenDialog(row)}
          handleDelete={(ids: string[]) => handleDelete(ids)}
          setDataChecked={setDataChecked}
          dataChecked={dataChecked}
          deleteConditional={[{
            keyPath: "trangThai.code",
            value: MISSION_STATUS_CODE.PROCESSING
          }, {
            keyPath: "trangThai.code",
            value: MISSION_STATUS_CODE.COMPLETED
          }]}
        />
      </div>
    </div>
  );
}

export default InterruptInformation;
import { MODULE, MODULE_NAME, PERMISSIONS, PERMISSION_ABILITY } from "../../constants"

export type TMenu = {
  title: string,
  to: string,
  defaultTo?: string,
  name: string,
  permission: string,
  ability: string,
  subMenu: TSubMenu[],
  icon: string,
  classNameIcon?: string,
}

export type TSubMenu = {
  title: string,
  to: string,
  hasBullet?: boolean,
  icon: string
  permission: string,
  ability: string,
  classNameIcon?: string,
  subMenu?: TSubMenu[],
  state?: Object;
}

export const allMenu: TMenu[] = [
  {
    title: "GENERAL.APP.NAME",
    to: "/personal-information",
    name: MODULE_NAME.HO_SO,
    permission: PERMISSIONS.MODULE,
    ability: MODULE.HO_SO,
    icon: "/media/icons/homepage/profile.svg",
    subMenu: [
      {
        title: "GENERAL.OVERVIEW",
        to: "/personal-information/statistical",
        hasBullet: false,
        icon: "/media/icons/overview-side.svg",
        permission: PERMISSIONS.THONG_KE,
        ability: PERMISSION_ABILITY.VIEW,
        subMenu: [
          {
            title: "GENERAL.STATISTICAL",
            to: "/personal-information/statistical",
            hasBullet: false,
            icon: "/media/icons/statistical.svg",
            permission: PERMISSIONS.THONG_KE,
            ability: PERMISSION_ABILITY.VIEW
          },
          {
            title: "REMIND",
            to: "/personal-information/remind",
            hasBullet: false,
            icon: "/media/icons/remind.svg",
            permission: PERMISSIONS.REMIND,
            ability: PERMISSION_ABILITY.VIEW
          },
        ]
      },
      {
        title: "GENERAL.WARNING",
        to: "/personal-information/warning",
        hasBullet: false,
        icon: "/media/icons/warning.svg",
        permission: PERMISSIONS.CANH_BAO,
        ability: PERMISSION_ABILITY.VIEW,
      },
      {
        title: "GENERAL.PROFILE",
        to: "/personal-information/profile",
        hasBullet: false,
        icon: "/media/icons/profile.svg",
        permission: PERMISSIONS.EMPLOYEE,
        ability: PERMISSION_ABILITY.VIEW,
      },
      {
        title: "GENERAL.CONTRACT",
        to: "/personal-information/contract",
        hasBullet: false,
        icon: "/media/icons/contract.svg",
        permission: PERMISSIONS.HOP_DONG,
        ability: PERMISSION_ABILITY.VIEW,
      },
      {
        title: "DECLARE_ASSETS",
        to: "/personal-information/declare-assets",
        hasBullet: false,
        icon: "/media/icons/demand-registration.svg",
        permission: PERMISSIONS.KE_KHAI_TAI_SAN_MENU,
        ability: PERMISSION_ABILITY.VIEW,
      },
      {
        title: "GENERAL.TRANSFER",
        to: "/personal-information/transition",
        hasBullet: false,
        icon: "/media/icons/transition.svg",
        permission: PERMISSIONS.THUYEN_CHUYEN,
        ability: PERMISSION_ABILITY.VIEW,
      },
      {
        title: "GENERAL.RESIGN",
        to: "/personal-information/resign",
        hasBullet: false,
        icon: "/media/icons/resign.svg",
        permission: PERMISSIONS.NGHI_VIEC,
        ability: PERMISSION_ABILITY.VIEW,
      },
      {
        title: "APPOINTMENT",
        to: "/personal-information/appointment",
        hasBullet: false,
        icon: "/media/icons/appointment.svg",
        permission: PERMISSIONS.BO_NHIEM,
        ability: PERMISSION_ABILITY.VIEW,
      },
      {
        title: "DISMISSED",
        to: "/personal-information/dismissed",
        hasBullet: false,
        icon: "/media/icons/dismissed.svg",
        permission: PERMISSIONS.MIEN_NHIEM,
        ability: PERMISSION_ABILITY.VIEW,
      },
      {
        title: "WELFARE",
        to: "/personal-information/welfare",
        hasBullet: false,
        icon: "/media/icons/welfare.svg",
        permission: PERMISSIONS.PHUC_LOI_MENU,
        ability: PERMISSION_ABILITY.VIEW,
      },
      {
        title: "CONCURRENTLY",
        to: "/personal-information/concurrently",
        hasBullet: false,
        icon: "/media/icons/concurrently.svg",
        permission: PERMISSIONS.KIEM_NHIEM,
        ability: PERMISSION_ABILITY.VIEW,
      },
      {
        title: "SECONDMENT",
        to: "/personal-information/secondment",
        hasBullet: false,
        icon: "/media/icons/secondment.svg",
        permission: PERMISSIONS.BIET_PHAI,
        ability: PERMISSION_ABILITY.VIEW,
      },
      {
        title: "GENERAL.APPRECIATIVE",
        to: "/personal-information/appreciative",
        hasBullet: false,
        icon: "/media/icons/appreciative.svg",
        permission: PERMISSIONS.KHEN_THUONG_MENU,
        ability: PERMISSION_ABILITY.VIEW,
      },
      {
        title: "GENERAL.DISCIPLINE",
        to: "/personal-information/discipline",
        hasBullet: false,
        icon: "/media/icons/issue.svg",
        permission: PERMISSIONS.KY_LUAT_MENU,
        ability: PERMISSION_ABILITY.VIEW,
      },
      {
        title: "GENERAL.RECEIVING_CITIZENS",
        to: "/personal-information/receiving-citizens",
        hasBullet: false,
        classNameIcon:"svg-color",
        icon: "/media/icons/sitting-svgrepo-com.svg",
        permission: PERMISSIONS.TIEP_CONG_DAN,
        ability: PERMISSION_ABILITY.VIEW,
      },
      {
        title: "GENERAL.SEND_PEOPLE_TRAINING",
        to: "/personal-information/send-people-training",
        hasBullet: false,
        icon: "/media/icons/graduation-cap.svg",
        permission: PERMISSIONS.CU_DAO_TAO,
        ability: PERMISSION_ABILITY.VIEW,
      },
      {
        title: "GENERAL.REPORT",
        to: "/personal-information/report",
        hasBullet: false,
        icon: "/media/icons/report.svg",
        permission: PERMISSIONS.BAO_CAO,
        ability: PERMISSION_ABILITY.VIEW,
      },
      {
        title: "MANAGER",
        to: "/personal-information/manager",
        hasBullet: false,
        icon: "/media/icons/manager.svg",
        permission: PERMISSIONS.MANAGEMENT,
        ability: PERMISSION_ABILITY.VIEW,
      }
    ]
  },
  {
    title: "GENERAL.TIME_KEEPING",
    to: "/timekeeping",
    name: "timekeeping",
    permission: PERMISSIONS.MODULE,
    ability: MODULE.CHAM_CONG,
    icon: "/media/icons/homepage/time.svg",
    subMenu: [
      {
        title: "TIMEKEEPING.OVERVIEW",
        to: "/timekeeping/overview",
        hasBullet: false,
        icon: "/media/icons/overview-side.svg",
        permission: PERMISSIONS.CHAM_CONG_TONG_QUAN,
        ability: PERMISSION_ABILITY.VIEW,
      },
      {
        title: "GENERAL.TIME_KEEPING",
        to: "/timekeeping/timesheet",
        hasBullet: false,
        icon: "/media/icons/timeSheet.svg",
        permission: PERMISSIONS.BANG_CHAM_CONG,
        ability: PERMISSION_ABILITY.VIEW,
      },
      {
        title: "TIMEKEEPING.SHIFT",
        to: "/timekeeping/shift-division",
        hasBullet: false,
        icon: "/media/icons/calendar-check.svg",
        permission: PERMISSIONS.CA_LAM_VIEC,
        ability: PERMISSION_ABILITY.VIEW,
        subMenu: [
          {
            title: "TIMEKEEPING.UPDATE_WORK_SHIFT",
            to: "/timekeeping/work-shifts",
            hasBullet: false,
            icon: "/media/icons/calendar-lines-pen.svg",
            permission: PERMISSIONS.GIAO_CA,
            ability: PERMISSION_ABILITY.VIEW
          },
          {
            title: "TIMEKEEPING.COMPREHENSIVE_SHIFT",
            to: "/timekeeping/comprehensive-shift",
            hasBullet: false,
            icon: "/media/icons/calendar-star.svg",
            permission: PERMISSIONS.PHAN_CA_TONG_HOP,
            ability: PERMISSION_ABILITY.VIEW
          },
          {
            title: "TIMEKEEPING.SHIFT_DIVISION.DOCTOR",
            to: "/timekeeping/shift-division-doctor",
            hasBullet: false,
            icon: "/media/icons/calendar-circle-plus.svg",
            permission: PERMISSIONS.PHAN_CA_BAC_SI,
            ability: PERMISSION_ABILITY.VIEW
          },
          {
            title: "TIMEKEEPING.SHIFT_DIVISION.NURSING",
            to: "/timekeeping/shift-division-nursing",
            hasBullet: false,
            icon: "/media/icons/calendar-circle-plus.svg",
            permission: PERMISSIONS.PHAN_CA_DIEU_DUONG,
            ability: PERMISSION_ABILITY.VIEW
          },
          {
            title: "TIMEKEEPING.SCHEDULE.OFFICE_WORK",
            to: "/timekeeping/office-work-schedule",
            hasBullet: false,
            icon: "/media/icons/calendar-pen.svg",
            permission: PERMISSIONS.LICH_HANH_CHINH,
            ability: PERMISSION_ABILITY.VIEW,
          },
          {
            title: "TIMEKEEPING.SHIFT",
            to: "/timekeeping/shift",
            hasBullet: false,
            icon: "/media/icons/clipboard-list-check.svg",
            permission: PERMISSIONS.CA_LAM_VIEC,
            ability: PERMISSION_ABILITY.VIEW
          }
        ]
      },
      {
        title: "TIMEKEEPING.MANAGENT_REQUEST",
        to: "/timekeeping/management-request/attendance-watch",
        hasBullet: false,
        icon: "/media/icons/demand-registration.svg",
        permission: PERMISSIONS.DON_TU,
        ability: PERMISSION_ABILITY.VIEW,
        subMenu: [
          {
            title: "TIMEKEEPING.MANAGENT_REQUEST.ATTENDANCE_WATCH",
            to: "/timekeeping/management-request/attendance-watch",
            hasBullet: false,
            icon: "/media/icons/contract.svg",
            permission: PERMISSIONS.DON_XIN_NGHI,
            ability: PERMISSION_ABILITY.VIEW
          },
          {
            title: "TIMEKEEPING.MANAGENT_REQUEST.REGISTER_OVERTIME",
            to: "/timekeeping/management-request/register-overtime",
            hasBullet: false,
            icon: "/media/icons/contract.svg",
            permission: PERMISSIONS.DON_DANG_KI_LAM_THEM,
            ability: PERMISSION_ABILITY.VIEW
          },
          {
            title: "MANAGENT_REQUEST.CHANGE_COMPENSATED",
            to: "/timekeeping/management-request/change-compensated",
            hasBullet: false,
            icon: "/media/icons/contract.svg",
            permission: PERMISSIONS.DON_DOI_CA_NGHI_BU,
            ability: PERMISSION_ABILITY.VIEW
          }
        ]
      },
      {
        title: "CATEGORY",
        to: "/timekeeping/category",
        hasBullet: false,
        icon: "/media/icons/category.svg",
        permission: PERMISSIONS["DANH_MUC.CHAM_CONG"],
        ability: PERMISSION_ABILITY.VIEW,
        subMenu: [
          {
            title: "SYMBOL",
            to: "/timekeeping/category/symbol",
            hasBullet: false,
            icon: "/media/icons/book.svg",
            permission: PERMISSIONS["DANH_MUC.CHAM_CONG.KY_HIEU"],
            ability: PERMISSION_ABILITY.VIEW,
          },
          {
            title: "LEAVE_REGULATIONS",
            to: "/timekeeping/category/leave-regulations",
            hasBullet: false,
            icon: "/media/icons/calendar-days.svg",
            permission: PERMISSIONS["DANH_MUC.CHAM_CONG.QUY_DINH_NGHI"],
            ability: PERMISSION_ABILITY.VIEW,
          },
          {
            title: "APPROVAL_CONFIG",
            to: "/timekeeping/category/approval-config",
            hasBullet: false,
            icon: "/media/icons/wrench.svg",
            permission: PERMISSIONS["DANH_MUC.CHAM_CONG.CAU_HINH_DUYET"],
            ability: PERMISSION_ABILITY.VIEW,
          }
        ]
      },
    ]
  },
  {
    title: "GENERAL.RECRUITMENT",
    to: "/recruitment",
    defaultTo: "/recruitment",
    name: "recruitment",
    permission: PERMISSIONS.MODULE,
    ability: MODULE.TUYEN_DUNG,
    icon: "/media/icons/homepage/hr.svg",
    subMenu: []
  },
  {
    title: "GENERAL.DOCUMENT",
    to: "/document",
    defaultTo: "/document",
    name: "document",
    permission: PERMISSIONS.MODULE,
    ability: MODULE.TAI_LIEU,
    icon: "/media/icons/homepage/document.svg",
    subMenu: []
  },
  {
    title: "GENERAL.TRAINING",
    to: "/training",
    defaultTo: "/training",
    name: "training",
    permission: PERMISSIONS.MODULE,
    ability: MODULE.DAO_TAO,
    icon: "/media/icons/homepage/training.svg",
    subMenu: []
  },
  {
    title: "GENERAL.MEETING_ROOM",
    to: "/meeting-room",
    defaultTo: "/meeting-room",
    name: "meeting-room",
    permission: PERMISSIONS.MODULE,
    ability: MODULE.PHONG_HOP,
    icon: "/media/icons/homepage/meet.svg",
    subMenu: []
  },
  {
    title: "GENERAL.JOB",
    to: "/job",
    defaultTo: "/job",
    name: "job",
    permission: PERMISSIONS.MODULE,
    ability: MODULE.CONG_VIEC,
    icon: "/media/icons/homepage/work.svg",
    subMenu: []
  },
  {
    title: "GENERAL.SYSTEM",
    to: "/system",
    defaultTo: "/system",
    name: "system",
    permission: PERMISSIONS.MODULE,
    ability: MODULE.HE_THONG,
    icon: "/media/icons/homepage/system.svg",
    subMenu: []
  }
]

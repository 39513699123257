/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import * as Yup from "yup";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import Autocomplete from "../../../components/input/autocomplete";
import TextValidator from "../../../components/input/text-validator";
import TableCustom from "../../../components/table-custom/TableCustom";
import { IPeopleDecision, OptionReactSelect } from "../../../models/models";
import "../../../styles/index.scss";
import { LIST_MONTH, RESPONSE_STATUS_CODE, TYPE, VARIABLE_STRING } from "../../../constants/moduleConsts";
import { TYPE_OF_RESIGN_CODE, insuranceStatusOptions, retireOptions } from "../../constants/warningConsts";
import { IRetireInfo } from "../../models/warningModels";
import { CacKhoanPhaiThuColumn } from "../columns/cacKhoanPhaiThuColumn";
import { addRetire, getRetirementByEmployeeId } from "../../services/warningServices";
import { toast } from "react-toastify";
import AppContext from "../../../../AppContext";
import { checkInvalidDate } from "../../../utils/validationSchema";
import { INIT_RETIRE } from "../../constants/retireConsts";
import { ReceivableInfo } from "../../../resign/models/resignModels";
import { INIT_RECEIVABLE_INFO, INSURANCE_TYPE_CODE, listFormOfAdjustment } from "../../../resign/constants/resignConsts";
import { ReceivableDialog } from "../../../resign/components/resign/ReceivableDialog";
import { checkObject, convertNumberPrice, convertTextPrice, getFullYear, handleBlurDate } from "../../../utils/functionUtils";
import { GroupButton } from "../../../components/GroupButton";
import { searchNguoiDaiDienKy } from "../../../services/services";
import { AddNewRepresentative } from "../../../components/dialogs";

interface IProps {
  handleClose: () => void;
  retireInfo: IRetireInfo;
  handleUpdateParent: () => void;
}
export const RetireDecisionDialog: React.FC<IProps> = ({ handleClose, retireInfo, handleUpdateParent }) => {
  const { lang, intl } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [listReceivables, setListReceivables] = useState<ReceivableInfo[]>([]);
  const [shouldOpenDialog, setShouldOpenDialog] = useState<boolean>(false);
  const [receivableInfo, setReceivableInfo] = useState<ReceivableInfo>({} as ReceivableInfo);
  const [isMakeDecision, setIsMakeDecision] = useState<boolean>(false);
  const [isShowBtnSave, setIsShowBtnSave] = useState<boolean>(true);
  const [isShowDialogAddRepresentative, setIsShowDialogAddRepresentative] = useState<boolean>(false);
  const [peopleDecisions, setPeopleDecisions] = useState<IPeopleDecision[]>([]);

  const getPeopleDecision = async () => {
    try {
      const { data } = await searchNguoiDaiDienKy();
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setPeopleDecisions(data?.data?.content || []);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }

  useEffect(() => {
    getPeopleDecision();
  }, []);

  const handleCloseAndSearchRepresentative = () => {
    getPeopleDecision();
    setIsShowDialogAddRepresentative(false);
  }

  const validationSchema = Yup.object().shape({
    loaiThoiViec: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    ngayQuyetDinh: Yup.date()
      .concat(checkInvalidDate(intl))
      .concat(isMakeDecision ? Yup.date().required(lang("VALIDATION.REQUIRE")).nullable() : Yup.date().nullable())
      .when("ngayHieuLuc", {
        is: (ngayHieuLuc: Date | null) => ngayHieuLuc,
        then: Yup.date()
          .max(Yup.ref("ngayHieuLuc"), lang("VALIDATION.DECISION_DATE_BEFORE_EFFECTIVE_DATE"))
          .nullable()
      })
      .nullable(),
    ngayHieuLuc: Yup.date().concat(checkInvalidDate(intl)).required(lang("VALIDATION.REQUIRE")).nullable(),
    ngayThongBao: Yup.date().concat(checkInvalidDate(intl)).nullable(),
    soQuyetDinh: Yup.string().nullable(),
    nguoiKy: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    trangThaiBaoHiem: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    thoiGianDungDongBH: Yup.date()
      .concat(checkInvalidDate(intl))  
      .when("trangThaiBaoHiem", {
        is: (trangThaiBaoHiem: OptionReactSelect | null) => trangThaiBaoHiem && trangThaiBaoHiem?.code === INSURANCE_TYPE_CODE.DUNG_DONG,
        then: Yup.date()
          .required(lang("VALIDATION.REQUIRE"))
          .nullable()
      })
      .nullable(),
    thoiGianThucHien: Yup.date()
      .concat(checkInvalidDate(intl))
      .required(lang("VALIDATION.REQUIRE"))
      .nullable(),
  });

  const handleFormSubmit = async (values: IRetireInfo) => {
    try {
      setPageLoading(true);
      for (let index = 0; index < listReceivables.length; index++) {
        delete listReceivables[index].id;
      }
      const dataRetire = {
        ...values,
        fullName: values.name,
        employeeId: values.id,
        nguoiKyId: values?.nguoiKy?.id,
        nguoiKyText: values?.nguoiKy?.name,
        listKhoanThu: listReceivables,
        raQuyetDinh: isMakeDecision,
        thangKhauTru: Number(values.thangKhauTruOption?.code),
        namKhauTru: Number(values.namKhauTruOption?.name),
      }
      const { data } = await addRetire(dataRetire);
      if(data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(isMakeDecision
          ? lang("TOAST.DECISION.RETIRE.SUCCESS")
          : lang("TOAST.EDIT.RETIRE.SUCCESS")
        );
        handleUpdateParent();
        handleClose();
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const convertDataRetireInfo = (retireInfo: IRetireInfo) => {
    return {
      ...INIT_RETIRE,
      ...retireInfo,
      ngayHieuLuc: retireInfo.ngayNghiHuuDuKien,
      phongBan: {
        id: retireInfo?.phongBanId,
        name: retireInfo?.phongBanText
      },
      donViCongTac: {
        id: retireInfo?.donViCongTacId,
        name: retireInfo?.donViCongTacText
      },
      chucDanh: {
        id: retireInfo?.viTriCongViecId,
        value: retireInfo?.viTriCongViecText
      }
    }
  }

  const formik = useFormik({
    initialValues: convertDataRetireInfo(retireInfo),
    validationSchema,
    onSubmit: handleFormSubmit
  })

  const handleChangeSelect = (name: string, value: OptionReactSelect) => {
    formik.setFieldValue(name, value);
  }

  const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target;
    formik.setFieldValue(name, checked);
  };

  const handleOpenInfoDialog = (row: ReceivableInfo) => {
    setShouldOpenDialog(true);
    setReceivableInfo(row);
  };

  const updateData = async (values: any) => {
    try {
      const { data } = await getRetirementByEmployeeId(retireInfo.id || "");
      const namKhauTruOption = getFullYear().find(item => Number(item.name) === data?.data?.namKhauTru) || null;
      formik.setValues({
        ...formik.values,
        ...values,
        ...data?.data,
        nguoiKy: data?.data?.nguoiKyId ? {
          id: data?.data?.nguoiKyId,
          name: data?.data?.nguoiKyText
        } : null,
        thangKhauTruOption: LIST_MONTH.find(item => item.code === data?.data?.thangKhauTru) || null,
        namKhauTruOption
      });
      setIsMakeDecision(Boolean(data?.data?.raQuyetDinh));
      setListReceivables(data?.data?.listKhoanThu || []);
    } catch (error: any) {
      toast.error(error);
    }
  }

  const handleAddReceivable = (data: ReceivableInfo) => {
    let id = data?.id;
    let index = listReceivables.findIndex((item: ReceivableInfo) => (item?.id) === id);
    if(index > -1){
      let _listReceivables = [...listReceivables];
      _listReceivables[index] = data;
      setListReceivables([..._listReceivables]);
    }else{
      setListReceivables([...listReceivables, data])
    }
    handleCloseDialog();
  }

  const handleDeleteReceivables = (ids: string[]) => {
    let _listReceivables = listReceivables.filter((item: ReceivableInfo) => !ids.includes(item?.id || ""));
    setListReceivables([..._listReceivables]);
    return true;
  }

  const handleCloseDialog = () => {
    setShouldOpenDialog(false);
    setReceivableInfo(INIT_RECEIVABLE_INFO);
  };

  const handleMakeDecision = async () => {
    if(checkObject(await formik.validateForm())) {
      setIsMakeDecision(true);
    }
  }

  const handleChangeMoney = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, convertTextPrice(value));
  }

  useEffect(() => {
    const defaultTypeOfResignation = retireOptions.find(item => item.code === TYPE_OF_RESIGN_CODE.NGHI_HUU);
    retireInfo?.id && updateData({ 
      loaiThoiViec: defaultTypeOfResignation,
    });
  }, [retireInfo]);

  useEffect(() => {
    const effectiveDate = formik.values?.ngayHieuLuc ? new Date(formik.values?.ngayHieuLuc) : null;
    const currentDate = new Date();
    if(effectiveDate && effectiveDate <= currentDate) {
      setIsShowBtnSave(false);
    } else setIsShowBtnSave(true);
  }, [formik.values?.ngayHieuLuc]);

  return (
    <Modal
      show={true}
      size="xl"
      centered
      aria-labelledby="example-custom-modal-styling-title"
      onHide={handleClose}
      backdrop="static"
    >
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title
            id="example-custom-modal-styling-title"
            className="heading-5"
          >
            {lang("INPUT.RETIRE_INFO.DECIDE_RETIRE")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="contract-dialog-content">
          <Row>
            <Col xs={12}>
              <span className="fs-3 fw-bold">
                {lang("INPUT.RETIRE_INFO.OFFICER_INFO")}
              </span>
            </Col>
            <Col xs={12}>
              <Row className="ms-2 mt-4">
                <Col xs={3}>
                  <TextValidator
                    lable={lang("INPUT.FULLNAME")}
                    name="name"
                    isRequired
                    isReadOnly={true}
                    value={formik.values?.name?.toUpperCase() || ""}
                    type="text"
                    readOnly={true}
                    onChange={formik.handleChange}
                  />
                </Col>
                <Col xs={3}>
                  <TextValidator
                    lable={lang("INPUT.CODE_NEW")}
                    name="maNhanVien"
                    isRequired
                    isReadOnly={true}
                    value={formik.values?.maNhanVien || ""}
                    type="text"
                    readOnly={true}
                    onChange={formik.handleChange}
                  />
                </Col>
                <Col xs={3}>
                  <Autocomplete
                    isRequired
                    lable={lang("GENERAL.CURRENT_ORGANIZATION")}
                    options={[]}
                    isReadOnly={true}
                    onChange={() => {}}
                    name="donViCongTac"
                    value={formik.values?.donViCongTac || null}
                    errors={formik.errors.donViCongTac}
                    touched={formik.touched.donViCongTac}
                  />
                </Col>
                <Col xs={3}>
                  <Autocomplete
                    isReadOnly={true}
                    lable={lang("INPUT.DEPARTMENTS")}
                    options={[]}
                    value={formik.values?.phongBan || null}
                    isRequired
                    name="phongBan"
                    onChange={(selectedOption) =>
                      handleChangeSelect("phongBan", selectedOption)
                    }
                    touched={formik.touched?.phongBan}
                    errors={formik.errors?.phongBan}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={12} className="pt-4">
              <span className="fs-3 fw-bold">
                {lang("INPUT.RETIRE_INFO.QUIT_JOB_INFO")}
              </span>
            </Col>
            <Col xs={12}>
              <Row className="ms-2 mt-4">
                <Col xs={3}>
                  <Autocomplete
                    className="spaces z-index-2"
                    lable={lang("INPUT.RETIRE_INFO.OFF_WORK_TYPE")}
                    name="loaiThoiViec"
                    isRequired
                    value={formik.values?.loaiThoiViec || null}
                    onChange={(selectedOption) =>
                      handleChangeSelect("loaiThoiViec", selectedOption)
                    }
                    options={retireOptions}
                    errors={formik.errors.loaiThoiViec}
                    touched={formik.errors.loaiThoiViec}
                  />
                </Col>
                <Col xs={3}>
                  <TextValidator
                    lable={lang("INPUT.RETIRE_INFO.SUBSIDIZE")}
                    name="troCapThoiViec"
                    value={convertNumberPrice(formik.values?.troCapThoiViec || null)}
                    type="text"
                    onChange={handleChangeMoney}
                  />
                </Col>
                <Col xs={3}>
                  <TextValidator
                    lable={lang("INPUT.RETIRE_INFO.LOST_WORK")}
                    name="troCapMatViec"
                    value={convertNumberPrice(formik.values?.troCapMatViec || null)}
                    type="text"
                    onChange={handleChangeMoney}
                  />
                </Col>
                <Col xs={3}>
                  <TextValidator
                    lable={lang("INPUT.RETIRE_INFO.OTHER_PAYABLES")}
                    name="cacKhoanTraKhac"
                    value={convertNumberPrice(formik.values?.cacKhoanTraKhac || null)}
                    type="text"
                    onChange={handleChangeMoney}
                  />
                </Col>
                <Col xs={3} className="pt-4">
                  <TextValidator
                    lable={lang("INPUT.RETIRE_INFO.OTHER_RECEIVABLES")}
                    name="cacKhoanThuKhac"
                    value={listReceivables.length}
                    type="text"
                    onChange={formik.handleChange}
                    readOnly={true}
                  />
                </Col>
                <Col xs={3} className="pt-3">
                  <Autocomplete
                    className="spaces z-index-2"
                    lable={lang("RESIGN.MONTH_DEDUCTION")}
                    options={LIST_MONTH}
                    onChange={(value) =>
                      handleChangeSelect(VARIABLE_STRING.THANG_KHAU_TRU_OPTION, value)
                    }
                    name="thangKhauTruOption"
                    value={formik.values?.thangKhauTruOption || null}
                  />
                </Col>
                <Col xs={3} className="pt-3 z-index-2">
                  <Autocomplete
                    lable={lang("RESIGN.YEAR_DEDUCTION")}
                    options={getFullYear()}
                    onChange={(value) =>
                      handleChangeSelect(VARIABLE_STRING.NAM_KHAU_TRU_OPTION, value)
                    }
                    name="namKhauTruOption"
                    value={formik.values?.namKhauTruOption || null}
                  />
                </Col>
                <Col xs={3} className="pt-4">
                  <TextValidator
                    isRequired={isMakeDecision}
                    lable={lang("INPUT.RETIRE_INFO.DECISION_DATE")}
                    name="ngayQuyetDinh"
                    value={formik.values?.ngayQuyetDinh || ""}
                    type="date"
                    onChange={formik.handleChange}
                    errors={formik.errors.ngayQuyetDinh}
                    touched={formik.touched.ngayQuyetDinh}
                  />
                </Col>
                <Col xs={3} className="pt-4">
                  <TextValidator
                    lable={lang("INPUT.RETIRE_INFO.EFFECTIVE_DATE")}
                    name="ngayHieuLuc"
                    isRequired
                    value={formik.values?.ngayHieuLuc || ""}
                    type="date"
                    onChange={formik.handleChange}
                    errors={formik.errors.ngayHieuLuc}
                    touched={formik.touched.ngayHieuLuc}
                  />
                </Col>
                <Col xs={3} className="pt-4">
                  <TextValidator
                    lable={lang("INPUT.RETIRE_INFO.ANNOUNCEMENT_DATE")}
                    name="ngayThongBao"
                    value={formik.values?.ngayThongBao || ""}
                    type="date"
                    onChange={formik.handleChange}
                    errors={formik.errors.ngayThongBao}
                    touched={formik.touched.ngayThongBao}
                  />
                </Col>
                <Col xs={3} className="pt-4">
                  <TextValidator
                    lable={lang("INPUT.RETIRE_INFO.DECISION_NO")}
                    name="soQuyetDinh"
                    value={formik.values?.soQuyetDinh || ""}
                    type="text"
                    onChange={formik.handleChange}
                    errors={formik.errors.soQuyetDinh}
                    touched={formik.touched.soQuyetDinh}
                  />
                </Col>
                <Col xs={3} className="pt-4">
                  <Autocomplete
                    className="z-index-2"
                    isRequired
                    lable={lang("INPUT.RETIRE_INFO.SIGNER")}
                    options={peopleDecisions}
                    onChange={(selectedOption) =>
                      handleChangeSelect("nguoiKy", selectedOption)
                    }
                    name="nguoiKy"
                    value={formik.values?.nguoiKy || null}
                    isAddNew
                    onAddNew={() => setIsShowDialogAddRepresentative(true)}
                    errors={formik.errors.nguoiKy}
                    touched={formik.touched.nguoiKy}
                  />
                </Col>
                <Col xs={2} className="pt-4">
                  <Form.Check
                    label={lang("INPUT.RETIRE_INFO.IS_ADDED_PROFILE")}
                    name="daBoSungHoSo"
                    className="checkBox custom-form-check"
                    checked={formik.values?.daBoSungHoSo}
                    onChange={handleChangeCheckBox}
                  />
                </Col>
                <Col xs={2} className="pt-4">
                  <Form.Check
                    label={lang("INPUT.RETIRE_INFO.IS_PROCEDURE_COMPLETED")}
                    name="daHoanTatThuTuc"
                    className="checkBox custom-form-check"
                    checked={formik.values?.daHoanTatThuTuc}
                    onChange={handleChangeCheckBox}
                  />
                </Col>
                <Col xs={8} className="pt-4">
                  <TextValidator
                    lable={lang("INPUT.RETIRE_INFO.REASON")}
                    name="lyDoThoiViec"
                    value={formik.values?.lyDoThoiViec || ""}
                    type="text"
                    onChange={formik.handleChange}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={12} className="pt-4">
              <TableCustom
                id="receivables"
                title={lang("INPUT.RETIRE_INFO.RECEIVABLES")}
                data={listReceivables}
                columns={CacKhoanPhaiThuColumn()}
                isActionTableTab
                buttonAdd={true}
                type={TYPE.MULTILINE}
                noToolbar={true}
                noPagination={true}
                handleOpenDialog={() => handleOpenInfoDialog(INIT_RECEIVABLE_INFO)}
                handleDoubleClick={(row: any) => handleOpenInfoDialog(row)}
                handleDelete={(ids: string[]) => handleDeleteReceivables(ids)}
                
              />
            </Col>
            <Col xs={12} className="pt-4">
              <span className="fs-3 fw-bold">
                {lang("INPUT.RETIRE_INFO.INSURANCE_INFO")}
              </span>
            </Col>
            <Col xs={12}>
              <Row className="ms-2">
                <Col xs={3} className="pt-3">
                  <Autocomplete
                    isRequired
                    lable={lang("INPUT.RETIRE_INFO.INSURANCE_STATUS")}
                    name="trangThaiBaoHiem"
                    value={formik.values.trangThaiBaoHiem || null}
                    onChange={(selectedOption) =>
                      handleChangeSelect("trangThaiBaoHiem", selectedOption)
                    }
                    options={insuranceStatusOptions}
                    errors={formik.errors?.trangThaiBaoHiem}
                    touched={formik.touched?.trangThaiBaoHiem}
                  />
                </Col>
                <Col xs={3} className="pt-3">
                  <TextValidator
                    isRequired={formik.values?.trangThaiBaoHiem?.code === INSURANCE_TYPE_CODE.DUNG_DONG}
                    lable={lang("INPUT.INSURANCE.STOP_TIME")}
                    name="thoiGianDungDongBH"
                    value={formik.values?.thoiGianDungDongBH || ""}
                    type="date"
                    onChange={formik.handleChange}
                    onBlur={() =>
                      handleBlurDate(
                        formik.setFieldValue,
                        formik.values?.thoiGianDungDongBH,
                        "thoiGianDungDongBH"
                      )
                    }
                    errors={formik.errors?.thoiGianDungDongBH}
                    touched={formik.touched?.thoiGianDungDongBH}
                  />
                </Col>
                <Col xs={3} className="pt-3">
                  <Autocomplete
                    lable={lang("INPUT.INSURANCE.ADJUST")}
                    options={listFormOfAdjustment}
                    onChange={(value) =>
                      handleChangeSelect(VARIABLE_STRING.HINH_THUC_DIEU_CHINH, value)
                    }
                    menuPlacement="top"
                    name="hinhThucDieuChinh"
                    value={formik.values?.hinhThucDieuChinh || null}
                  />
                </Col>
                <Col xs={3} className="pt-3">
                  <TextValidator
                    isRequired
                    lable={lang("IMPLEMENTATION.TIME")}
                    name="thoiGianThucHien"
                    value={formik.values?.thoiGianThucHien || ""}
                    type="date"
                    onChange={formik.handleChange}
                    onBlur={() =>
                      handleBlurDate(
                        formik.setFieldValue,
                        formik.values?.thoiGianThucHien,
                        "thoiGianThucHien"
                      )
                    }
                    errors={formik.errors?.thoiGianThucHien}
                    touched={formik.touched?.thoiGianThucHien}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="flex-center">
          <GroupButton type="btn-cancel" handleCloseUpdateDialog={handleClose} />
          <GroupButton handleEvent={handleMakeDecision}>{lang("BTN.DECISION")}</GroupButton>
          {isShowBtnSave && <GroupButton type="btn-save" handleSubmit={() => setIsMakeDecision(false)} />}
        </Modal.Footer>
      </Form>
      {shouldOpenDialog && (
        <ReceivableDialog
          receivableInfo={receivableInfo}
          handleClose={handleCloseDialog}
          handleDialogSubmit={handleAddReceivable}
        />
      )}
      {isShowDialogAddRepresentative && (
        <AddNewRepresentative
          handleClose={() => setIsShowDialogAddRepresentative(false)}
          handleCloseAndSearch={handleCloseAndSearchRepresentative}
        />
      )}
    </Modal>
  );
};

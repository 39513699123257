import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import { AuthModel } from './_models'
import * as authHelper from './AuthHelpers'
import { getCurrentUser, sendTokenRequest } from './_requests'
import { WithChildren } from '../../../../_metronic/helpers'
import { AUTHORIZE_REQUEST, KEY_LOCALSTORAGE } from './_consts'
import { localStorageItem } from '../../utils/localstorage'
import jwt_decode from "jwt-decode";
import { getEmployeeIdByUsername } from '../../profile/services/dialogServices'
import { EmployeeProfile } from '../../profile/models/profileModels'
import { toast } from "react-toastify";
import useMultiLanguage from '../../../hook/useMultiLanguage'
import { RESPONSE_STATUS_CODE } from '../../constants/moduleConsts'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: EmployeeProfile | undefined
  setCurrentUser: Dispatch<SetStateAction<EmployeeProfile | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => { },
  currentUser: undefined,
  setCurrentUser: () => { },
  logout: () => { },
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<EmployeeProfile | undefined>()

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.logoutAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  return (
    <AuthContext.Provider value={{ auth, saveAuth, currentUser, setCurrentUser, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, saveAuth, setCurrentUser } = useAuth()
  const { lang } = useMultiLanguage();
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const code = new URL(window.location.href).searchParams.get("code");

  useEffect(() => {
    const requestUser = async (id_token: string) => {
      try {
        if (!didRequest.current) {
          const tokenDecode = jwt_decode(id_token) as {
            scope: string[]
          }

          if (tokenDecode) {
            localStorageItem.set(KEY_LOCALSTORAGE.ACCESS_TOKEN_DECODE, tokenDecode)
            if (tokenDecode?.scope) {
              const permissionObj: { [key: string]: boolean; } = {};
              for (const permission of tokenDecode.scope) {
                permissionObj[permission] = true;
              }
              localStorageItem.set(KEY_LOCALSTORAGE.AUTHORITIES, permissionObj)
            };
          }
          try {
            const { data } = await getCurrentUser();
            if(data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
              setCurrentUser(data?.data as EmployeeProfile | undefined);
              localStorageItem.set(KEY_LOCALSTORAGE.CURRENT, data?.data);

              const { data: dataEmployee } = await getEmployeeIdByUsername((data?.data as any)?.username);
              if(dataEmployee?.code === RESPONSE_STATUS_CODE.SUCCESS) {
                setCurrentUser(dataEmployee?.data);
                localStorageItem.set(KEY_LOCALSTORAGE.EMPLOYEE, dataEmployee?.data);
              } else {
                toast.warning(dataEmployee?.message);
              }
            } else {
              toast.warning(data?.message);
            }
          } catch (error) {
            console.error(error);
            toast.error(lang("GENERAL.ERROR"));
          }
        }
      } catch (error) {
        console.error(error);
        toast.error(lang("GENERAL.ERROR"));
        if (!didRequest.current) {
          window.location.href = AUTHORIZE_REQUEST
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (process.env.REACT_APP_SSO_ENABLE) {
      if (code && !auth) {
        sendTokenRequest(code).then((resAuth: AuthModel) => {
          saveAuth(resAuth);
          requestUser(resAuth.access_token);
        })
          .catch(() => {
            window.location.href = AUTHORIZE_REQUEST
          })
      } else if (!auth) {
        window.location.href = AUTHORIZE_REQUEST
      } else {
        requestUser(auth.access_token);
      }
    } else {
      if (auth && auth.access_token) {
        requestUser(auth.access_token)
      } else {
        window.location.href = AUTHORIZE_REQUEST
      }
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }

import TextValue from "../../../components/TextValue"

export const columnsLevel = () => {
  return (
    [
      {
        name: "STT",
        field: "",
        render: (row: any, index: number, stt: number) => <span>{stt}</span>
      },
      {
        name: "Tên trường",
        field: "name",
        headerStyle: {
          minWidth: "130px",
          textAlign: "center",
        },
        cellStyle: {
          textAlign: "left",
        },
        render: (row: any) => row?.name ? <span>{row?.name}</span> : <TextValue className="spaces h-25" value="" />
      },
      {
        name: "Ngành học hoặc tên lớp học",
        field: "chuyenNganh",
        headerStyle: {
          minWidth: "130px",
          textAlign: "center",
        },
        cellStyle: {
          textAlign: "left",
        },
        render: (row: any) => row?.chuyenNganh ? <span>{row?.chuyenNganh}</span> : <TextValue className="spaces h-25" value="" />
      },
      {
        name: "Thời gian học",
        field: "thoiGian",
        headerStyle: {
          minWidth: "150px",
          textAlign: "center",
        },
        cellStyle: {
          textAlign: "center",
        },
        render: (row: any) => row?.thoiGian ? <span>{row?.thoiGian}</span> : <TextValue className="spaces h-25" value="" />
      },
      {
        name: "Hình thức học",
        field: "hinhThuc",
        headerStyle: {
          minWidth: "130px",
          textAlign: "center",
        },
        cellStyle: {
          textAlign: "left",
        },
        render: (row: any) => row?.hinhThuc?.name ? <span>{row?.hinhThuc?.name}</span> : <TextValue className="spaces h-25" value="" />
      },
      {
        name: "Văn bằng, chứng chỉ, trình độ gì",
        field: "vanBang",
        headerStyle: {
          minWidth: "130px",
          textAlign: "center",
        },
        cellStyle: {
          textAlign: "left",
        },
        render: (row: any) => row?.vanBang ? <span>{row?.vanBang}</span> : <TextValue className="spaces h-25" value="" />
      },
    ]
  )
}
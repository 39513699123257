import { toAbsoluteUrl } from "../../../../../_metronic/helpers";

interface Iprops {
  avatar: string;
  name: string;
  code: string;
}

function EmployeeCell(props: Iprops) {
  const { avatar, name, code } = props;
  return (
    <div>
      <div className="flex flex-middle">
        <img
          src={avatar || toAbsoluteUrl("/media/avatars/blank.png")}
          alt="avatar"
          width={35}
          height={35}
          className="avatar rounded-circle"
        />
        <div className="spaces ml-16 text-left">
          <span>
            {code ? code + " - " : ""}
            {name}
          </span>
        </div>
      </div>
    </div>
  );
}

export default EmployeeCell;

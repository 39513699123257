import { PERMISSIONS, PERMISSION_ABILITY } from "../../../constants";
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";
import { ITemplateExportWord } from "../../components/exportWord";
import { OptionReactSelect } from "../../models/models";
import { TYPE, TYPE_CATEGORY } from "../../constants/moduleConsts";
import { hasAuthority } from "../../utils/functionUtils";
import { localStorageItem } from "../../utils/localstorage";
import { ContractCommonWord, ContractDeadlineWord, ContractIndefiniteWord, ContractProbationWord } from "../components/word-templates";
import { IAllowance } from "../models/allowanceModels";
import { IContractAnnexInfo, IContractInfo } from "../models/contractModels";
import { searchAllSimpleValue, searchPhongBan, searchPhongBanByDepartment } from "../../services/services";

export const contractTypes: OptionReactSelect[] = [
  {
    code: "1",
    name: "Thử việc",
  },
  {
    code: "2",
    name: "Hợp đồng xác định thời hạn",
  },
  {
    code: "3",
    name: "Hợp đồng không xác định thời hạn",
  },
  {
    code: "4",
    name: "Học việc",
  },
  {
    code: "5",
    name: "Hợp đồng mùa vụ",
  },
  {
    code: "6",
    name: "Hợp đồng dịch vụ",
  },
];

export const signinStatus = [
  {
    code: 1,
    name: "Chưa ký",
    styleClass: "bg-green"
  },
  {
    code: 2,
    name: "Đã ký",
    styleClass: "bg-gray-700"
  },
];

export const INIT_ALLOWANCE: IAllowance = {
  id: "",
  employeeId: "",
  hopDongLaoDongId: "",
  tenKhoanPhuCap: null,
  tinhCongHuongLuong: false,
  giaTri: "",
  ghiChu: "",
}

export const INIT_CONTRACT: IContractInfo = {
  id: "",
  employeeId: "",
  donViKyHopDong: null,
  viTriCongViec: null,
  soHopDong: "",
  tenHopDong: "",
  nguoiDaiDienCtyKy: null,
  nguoiDaiDienCtyChucDanh: null,
  loaiHopDong: null,
  thoiHanHopDong: null,
  hinhThucLamViec: null,
  ngayCoHieuLuc: "",
  ngayHetHan: "",
  ngayKyHopDong: "",
  luongCoBan: "",
  luongDongBaoHiem: "",
  tyLeHuongLuong: "",
  ghiChu: "",
  fileId: "",
  tenNguoiLaoDong: "",
  employeeCode: "",
  trangThaiKy: null,
  trangThaiHopDong: null,
  employee: undefined,
  phongBan: null,
  chucVu: null,
  chucDanh: null,
  bacLuongOption: null,
  heSoLuong: "",
  vienChuc: false,
  hopDongLaoDong: false,
  caLamViec: null,
  giayUyQuyen: false,
  nguoiDaiDienCtyChucDanhText: "",
  nguoiDaiDienCtyChucDanhId: "",
  nguoiDaiDienCtyKyId: "",
  nguoiDaiDienCtyKyText: ""
}

export const INIT_CONTRACT_ANNEX_INFO: IContractAnnexInfo = {
  employeeId: "",
  fileId: "",
  fileName: "",
  ghiChu: "",
  hopDongGoc: "",
  hopDongLaoDongId: "",
  id: "",
  luongCoBan: "",
  luongDongBaoHiem: "",
  ngayCoHieuLuc: "",
  ngayCoHieuLucPhuLuc: "",
  ngayHetHan: "",
  ngayKy: "",
  soPhuLuc: "",
  tenPhuLuc: "",
  tyLeHuongLuong: "",
  donViKyHopDong: null,
  viTriCongViec: null,
  nguoiDaiDienCtyKy: null,
  nguoiDaiDienCtyChucDanh: null,
  hinhThucLamViec: null,
  loaiHopDong: null,
  thoiHanHopDong: null,
  trangThaiKy: null,
  bacLuongOption: null,
  heSoLuong: "",
  chucDanh: null,
  chucDanhId: "",
  chucDanhText: "",
  phongBan: null,
  phongBanId: "",
  phongBanText: "",
}

export const ListSearch = () => {
  return [
    {
      name: "Số hợp đồng",
      field: "soHopDong",
      type: TYPE.TEXT,
    },
    {
      name: "Mã nhân viên",
      field: "maNhanVien",
      type: TYPE.TEXT
    },
    {
      name: "Họ và tên",
      field: "tenNhanVien",
      type: TYPE.TEXT,
    },
    {
      name: "Ngày ký HĐ",
      field: "ngayKyHopDong",
      type: TYPE.DATE,
    },
    {
      name: "Khoa/Phòng",
      field: "phongBanIds",
      searchFunction: hasAuthority(PERMISSIONS.DEPARTMENT, PERMISSION_ABILITY.VIEW) ? searchPhongBanByDepartment : searchPhongBan,
      searchObject: {
        departmentId: hasAuthority(PERMISSIONS.DEPARTMENT, PERMISSION_ABILITY.VIEW) 
          ? localStorageItem.get(KEY_LOCALSTORAGE.EMPLOYEE)?.phongBanId 
          : ""
      },
      type: TYPE.SELECT,
      isMulti: true,
    },
    {
      name: "Chức vụ",
      field: "chucVuId",
      searchFunction: searchAllSimpleValue,
      searchObject: { type: TYPE_CATEGORY.chucDanh },
      type: TYPE.SELECT,
      optionLabel: "value"
    },
    {
      name: "Chức danh",
      field: "viTriCongViecIds",
      searchFunction: searchAllSimpleValue,
      searchObject: { type: TYPE_CATEGORY.viTriCongViec },
      type: TYPE.SELECT,
      optionLabel: "value",
      isMulti: true,
    },
    {
      name: "Loại HĐ",
      field: "loaiHopDong",
      type: TYPE.SELECT,
      listOption: contractTypeBienChe.concat(contractTypeHopDong),
    },
    {
      name: "Trạng thái ký",
      field: "trangThaiKy",
      type: TYPE.SELECT,
      listOption: signinStatus,
    },
    {
      name: "Trạng thái HĐ",
      field: "trangThaiHopDong",
      type: TYPE.SELECT,
      listOption: contractStatus,
      isMulti: true,
    },
  ]
} 

export const CODE_HOP_DONG = {
  //Viên chức
  VIEN_CHUC_DAI_HAN: 1,
  VIEN_CHUC_CO_HAN: 2,
  TAP_SU: 3,
  HOP_DONG_111: 4,
  //Hợp đòng lao động
  HOP_DONG_DAI_HAN: 5,
  HOP_DONG_CO_HAN: 6,
  ONE_YEAR: 7,
  CTV_THUONG_XUYEN: 8,
  CTV: 9,
  THU_VIEC: 10,
  HOC_VIEC: 11,
  DAO_TAO_THUC_HANH: 12,
  HOP_DONG_KHOAN: 13
};

export const CODE_CONTRACT_LONG_TERM = [
  CODE_HOP_DONG.VIEN_CHUC_DAI_HAN,
  CODE_HOP_DONG.HOP_DONG_DAI_HAN,
  CODE_HOP_DONG.ONE_YEAR
]

export const contractTypeBienChe: OptionReactSelect[] = [
  {
    code: 1,
    name: "Hợp đồng không xác định thời hạn"
  },
  {
    code: 2,
    name: "Hợp đồng có thời hạn"
  },
  {
    code: 3,
    name: "Hợp đồng tập sự"
  },
  {
    code: 4,
    name: "Hợp đồng nghị định 111 (HĐ 68 cũ)"
  },
  {
    code: 13,
    name: "Hợp đồng khoán"
  }
]

export const contractTypeHopDong: OptionReactSelect[] = [
  {
    code: 5,
    name: "Hợp đồng lao động không xác định thời hạn"
  },
  {
    code: 6,
    name: "Hợp đồng lao động có thời hạn"
  },
  {
    code: 7,
    name: "Thời hạn 1 năm"
  },
  {
    code: 8,
    name: "Cộng tác viên thường xuyên"
  },
  {
    code: 9,
    name: "Cộng tác viên"
  },
  {
    code: 10,
    name: "Hợp đồng thử việc"
  },
  {
    code: 11,
    name: "Hợp đồng học việc"
  },
  {
    code: 12,
    name: "Hợp đồng đào tạo thực hành"
  },
  {
    code: 13,
    name: "Hợp đồng khoán"
  }
]

export const workShifts:  OptionReactSelect[] = [
  {
    code: 1,
    name: "Không giới hạn số giờ"
  },
  {
    code: 2,
    name: "40h/tuần"
  },
  {
    code: 3,
    name: "44h/tuần"
  },
  {
    code: 4,
    name: "48h/tuần"
  },
  {
    code: 5,
    name: "Theo thỏa thuận"
  }
]

export const codeStatusContract = {
  IN_EFFECT: 1,
  EXPIRE: 2,
  NOT_YET_APPLIED: 3
}

export const contractStatus = [
  {
    code: codeStatusContract.IN_EFFECT,
    name: "Còn hiệu lực",
    styleClass: "bg-green"
  },
  {
    code: codeStatusContract.EXPIRE,
    name: "Hết hiệu lực",
    styleClass: "bg-gray-700"
  },
  {
    code: codeStatusContract.NOT_YET_APPLIED,
    name: "Chưa áp dụng",
    styleClass: "bg-purple",
  }
]

export const contractWordExportList: ITemplateExportWord[] = [
  {
    type: CODE_HOP_DONG.VIEN_CHUC_CO_HAN,
    name: "Hợp đồng làm việc xác định thời hạn",
    componentPreview: (dataPreview) => ContractDeadlineWord({ data: dataPreview })
  },
  {
    type: CODE_HOP_DONG.VIEN_CHUC_DAI_HAN,
    name: "Hợp đồng làm việc không xác định thời hạn",
    componentPreview: (dataPreview) => ContractIndefiniteWord({ data: dataPreview })
  },
  {
    type: CODE_HOP_DONG.HOP_DONG_CO_HAN,
    name: "Hợp đồng lao động có thời hạn",
    componentPreview: (dataPreview) => ContractCommonWord({ data: dataPreview })
  },
  {
    type: CODE_HOP_DONG.THU_VIEC,
    name: "Hợp đồng thử việc",
    componentPreview: (dataPreview) => ContractProbationWord({ data: dataPreview })
  },
] 
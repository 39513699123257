import TextValidator from "../../../components/input/text-validator";
import { Col, Form, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { INIT_GEM_STONES } from "../../constants/initValues";
import { IFormProps, IGemStones } from "../../models/declareAssetsModels";
import { useEffect } from "react";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import { convertNumberPrice, convertTextPrice } from "../../../utils/functionUtils";

export function GemStonesForm(props: IFormProps<IGemStones>) {
  const { handleSubmit, isView, btnSubmitRef, dataEdit } = props;
  const { lang } = useMultiLanguage();

  useEffect(() => {
    if (dataEdit?.taiSanId) {
      formik.setValues(dataEdit);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataEdit]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable()
  });

  const formik = useFormik({
    initialValues: INIT_GEM_STONES,
    onSubmit: handleSubmit,
    validationSchema
  });

  const handleChangeMoney = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, convertTextPrice(value));
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row>
        <Col xs={6} className="spaces mt-10">
          <TextValidator
            isRequired
            lable={lang("GENERAL.NAME")}
            name="name"
            readOnly={isView}
            type="text"
            value={formik.values?.name}
            onChange={formik.handleChange}
            errors={formik.errors?.name}
            touched={formik.touched?.name}
          />
        </Col>
        <Col xs={6} className="spaces mt-10">
          <TextValidator
            lable={lang("ALLOWANCE.VALUE")}
            name="giaTri"
            readOnly={isView}
            type="text"
            value={convertNumberPrice(formik.values?.giaTri)}
            onChange={handleChangeMoney}
            errors={formik.errors?.giaTri}
            touched={formik.touched?.giaTri}
          />
        </Col>
      </Row>
      <button ref={btnSubmitRef} type="submit" className="hidden"></button>
    </Form>
  );
}

import { Col, Form, Row } from "react-bootstrap";
import Autocomplete from "../../components/input/autocomplete";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { REGEX, RESPONSE_STATUS_CODE, TYPE_CATEGORY, VARIABLE_STRING } from "../../constants/moduleConsts";
import TextValidator from "../../components/input/text-validator";
import { useContext, useEffect, useState } from "react";
import { FormikProps, useFormik } from "formik";
import TablePartInfomation from "./TablePartInfomation";
import { AbroadAccountColumns, MinorChildrenColumns } from "../constants/columns";
import { MinorChildrenForm, AbroadAccountForm } from "./part-form";
import TableCustom, { columnNamesType } from "../../components/table-custom/TableCustom";
import {
  ASSETS_TYPE_DOMESTIC,
  DECLARE_ASSETS_TYPES,
  DECLARE_ASSETS_TYPES_CODES,
  NAME_TITLE,
  UP_DOWNS
} from "../constants/declareAssetsConsts";
import { GroupButton } from "../../components/GroupButton";
import { INIT_DECLARE_ASSETS } from "../constants/initValues";
import BodyInfomation from "./BodyInfomation";
import DeclareAbroadDialog from "./DeclareAbroadDialog";
import { toast } from "react-toastify";
import { addDeclareAssets, getDeclareAssetById, updateDeclareAssets } from "../services/declareAssetsServices";
import AppContext from "../../../AppContext";
import { IDeclareAssets, IFluctuation } from "../models/declareAssetsModels";
import { convertDataFluctuation, convertDataSubmit, convertDataUI } from "../utils/declareAssetsUtils";
import * as Yup from "yup";
import { convertNumberPrice, convertTextPrice, handleBlurDate } from "../../utils/functionUtils";
import { searchAllEmployee, searchAllSimpleValue } from "../../services/services";

interface IProps {
  handleClose: () => void;
  handleCloseAndSearch?: () => void;
  editId: string;
  employeeInfo?: any;
  formikRef?: React.MutableRefObject<FormikProps<any> | null>
}

function DeclareAssetsForm(props: IProps) {
  const { handleClose, editId, employeeInfo, handleCloseAndSearch, formikRef } = props;
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [isView, setIsView] = useState<boolean>(false);
  const [isOpenAbroadDialog, setIsOpenAbroadDialog] = useState<boolean>(false);
  const [fluctuationData, setFluctuationData] = useState<IFluctuation[]>([]);

  useEffect(() => {
    if (editId) {
      getEditData();
    } else {
      getFluctuationData();
      employeeInfo && formik.setValues(convertEmployee(employeeInfo, formik.values));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId]);

  const getFluctuationData = async () => {
    try {
      const { data } = await searchAllSimpleValue({ type: TYPE_CATEGORY.bienDongTaiSan });
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setFluctuationData(convertDataFluctuation(data?.data?.content || []));
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const getEditData = async () => {
    try {
      setPageLoading(true)
      const { data } = await getDeclareAssetById(editId);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        formik.setValues(convertDataUI(data?.data || []));
        setFluctuationData(data?.data?.bienDong || []);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
       setPageLoading(false)
    }
  };

  const handleSubmit = async (values: IDeclareAssets) => {
    try {
      setPageLoading(true);
      let dataSubmit = convertDataSubmit(values, fluctuationData);
      const { data } = await (!values?.id ? addDeclareAssets(dataSubmit) : updateDeclareAssets(values?.id, dataSubmit));
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        let message = !values?.id ? "TOAST.ADD.SUCCESS" : "TOAST.EDIT.SUCCESS";
        toast.success(lang(message));
        handleCloseAndSearch?.();
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const validationSchema = Yup.object().shape({
    employee: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    loaiKeKhai: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    ngayKeKhai: Yup.date()
      .required(lang("VALIDATION.REQUIRE"))
      .max(new Date(), lang("VALIDATON.DATE_CURRENT"))
      .nullable(),
    vcBirthdate: Yup.date().max(new Date(), lang("VALIDATON.DATE_CURRENT")).nullable(),
    vcSoCmndCccdNgayCap: Yup.date().max(new Date(), lang("VALIDATON.DATE_CURRENT")).nullable(),
    vcSoCmndCccd: Yup.string()
      .matches(REGEX.CHARACTER9or12, lang("VALIDATION.CHARACTER9OR12"))
      .matches(REGEX.NOT_ZERO, lang("VALIDATON.INVALID_CMND_CCCD"))
      .nullable(),
    soCmndCccd: Yup.string()
      .matches(REGEX.CHARACTER9or12, lang("VALIDATION.CHARACTER9OR12"))
      .matches(REGEX.NOT_ZERO, lang("VALIDATON.INVALID_CMND_CCCD"))
      .nullable(),
    soCmndCccdNgayCap: Yup.date().max(new Date(), lang("VALIDATON.DATE_CURRENT")).nullable()
  });

  const formik = useFormik({
    initialValues: INIT_DECLARE_ASSETS,
    onSubmit: handleSubmit,
    validationSchema
  });

  useEffect(() => {
    if (formikRef && formik) {
      formikRef.current = formik;
    }
  }, [formik, formikRef]);

  const handleAddAndEditData = (newChildData: any[], field: string) => {
    formik.setValues({ ...formik.values, [field]: newChildData });
  };

  const convertEmployee = (employee: any, data: IDeclareAssets) => {
    return {
      ...data,
      tenNhanVien: employee?.name || "",
      donViCongTacText: employee?.donViCongTacText || "",
      donViCongTacId: employee?.donViCongTacId || "",
      viTriCongViecId: employee?.viTriCongViecId || "",
      viTriCongViecText: employee?.viTriCongViecText || "",
      chucVuId: employee?.chucVuId || "",
      chucVuText: employee?.chucVuText || "",
      phongBanId: employee?.phongBanId || "",
      phongBanText: employee?.phongBanText || "",
      birthdate: employee?.birthDate || "",
      soCmndCccd: employee?.soCMNDOrCCCD || "",
      diaChiThuongChu: employee?.hkDiachi || "",
      soCmndCccdNgayCap: employee?.ngayCapCMNDOrCCCD || "",
      soCmndCccdNoiCap: employee?.noiCapCMNDOrCCCD || "",
      maNhanVien: employee?.maNhanVien || "",
      employee: employee,
      employeeId: employee?.id
    };
  };

  const handleChangeSelect = (name: string, value: any) => {
    switch (name) {
      case VARIABLE_STRING.EMPLOYEE:
        formik.setValues(convertEmployee(value, formik.values));
        break;
      case VARIABLE_STRING.LOAI_KE_KHAI:
        formik.setFieldValue(name, value);
        value?.code !== DECLARE_ASSETS_TYPES_CODES.LAN_DAU && getFluctuationData();
        break;
      default:
        formik.setFieldValue(name, value);
        break;
    }
  };

  const handleChangeFluctuation = (name: keyof IFluctuation, value: any, row: IFluctuation, itemList: IFluctuation[]) => {
    let _fluctuationData = itemList.map((item) => {
      let valueConvert = name === "giaTri" || name === "soLuong" ? convertTextPrice(value) : value;
      return item.taiSanId === row.taiSanId ? { ...item, [name]: valueConvert } : item;
    });
    setFluctuationData(_fluctuationData);
  };

  const handleChangeMoney = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, convertTextPrice(value));
  };

  const columnsFluctuation: columnNamesType[] = [
    {
      name: "STT",
      field: "",
      headerStyle: {
        width: "50px"
      },
      render: (_row, _index, stt) => <span>{stt}</span>
    },
    {
      name: lang("DECLARE_ASSETS.GROUP"),
      field: "nhomTaiSan",
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("DECLARE_ASSETS.NAME"),
      field: "name",
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("DECLARE_ASSETS.UP_DOWN"),
      field: "tangGiam",
      headerStyle: {
        minWidth: 150
      },
      cellStyle: {
        textAlign: "left"
      },
      render: (row, _index, _stt, itemList) => (
        <Autocomplete
          placeholder={lang("DECLARE_ASSETS.UP_DOWN")}
          isReadOnly={isView}
          options={UP_DOWNS}
          value={row?.tangGiam || null}
          name="tangGiam"
          onChange={(value) => handleChangeFluctuation("tangGiam", value, row, itemList)}
        />
      )
    },
    {
      name: lang("DECLARE_ASSETS.AMOUNT_ASSETS"),
      field: "soLuong",
      cellStyle: {
        textAlign: "left"
      },
      headerStyle: {
        minWidth: 200
      },
      render: (row, _index, _stt, itemList) => (
        <TextValidator
          name="soLuong"
          placeholder={lang("DECLARE_ASSETS.AMOUNT_ASSETS")}
          readOnly={isView}
          type="text"
          value={convertNumberPrice(row?.soLuong)}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleChangeFluctuation("soLuong", event.target.value, row, itemList)
          }
        />
      )
    },
    {
      name: lang("DECLARE_ASSETS.VALUES"),
      field: "giaTri",
      cellStyle: {
        textAlign: "left"
      },
      headerStyle: {
        minWidth: 200
      },
      render: (row, _index, _stt, itemList) => (
        <TextValidator
          name="giaTri"
          placeholder={lang("DECLARE_ASSETS.VALUES")}
          readOnly={isView}
          type="text"
          value={convertNumberPrice(row?.giaTri)}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleChangeFluctuation("giaTri", event.target.value, row, itemList)
          }
        />
      )
    },
    {
      name: lang("DECLARE_ASSETS.CONTENT"),
      field: "noiDung",
      cellStyle: {
        textAlign: "left"
      },
      headerStyle: {
        minWidth: 400
      },
      render: (row, _index, _stt, itemList) => (
        <TextValidator
          name="noiDung"
          placeholder={lang("DECLARE_ASSETS.CONTENT")}
          readOnly={isView}
          type="text"
          value={row?.noiDung}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleChangeFluctuation("noiDung", event.target.value, row, itemList)
          }
        />
      )
    }
  ];

  return (
    <Form onSubmit={formik.handleSubmit}>
      {!employeeInfo && (
        <div className="header-form-action">
          <GroupButton type="btn-back" handleClose={handleClose} />
          <div className="flex flex-center">
            {isView && formik.values?.id && (
              <GroupButton type="btn-edit" handleSaveEdit={() => setIsView(false)} />
            )}
            {!isView && <GroupButton type="btn-save" />}
          </div>
        </div>
      )}
      <div className="bg-white spaces p-16">
        <Row className="relative spaces mb-12 z-index-2">
          {!employeeInfo && (
            <Col xs={7} md={6} xl={5} xxl={4}>
              <Autocomplete
                horizontal={true}
                lable={lang("INPUT.EMPLOYEE")}
                isReadOnly={isView}
                searchFunction={searchAllEmployee}
                searchObject={{ isDangLamViec: true }}
                options={[]}
                isRequired
                value={formik.values?.employee || null}
                name="employee"
                maxMenuHeight={270}
                onChange={(value) => handleChangeSelect("employee", value)}
                getOptionLabel={(option) => `${option?.maNhanVien} - ${option?.name}`}
                errors={formik.errors?.employee}
                touched={formik.touched?.employee}
              />
            </Col>
          )}
          <Col xs={6} lg={5} xl={4}>
            <Autocomplete
              horizontal={true}
              lable={lang("DECLARE_ASSETS.TYPE")}
              isReadOnly={isView}
              options={DECLARE_ASSETS_TYPES}
              isRequired
              value={formik.values?.loaiKeKhai}
              name="loaiKeKhai"
              onChange={(value) => handleChangeSelect("loaiKeKhai", value)}
              errors={formik.errors?.loaiKeKhai}
              touched={formik.touched?.loaiKeKhai}
            />
          </Col>
        </Row>
        <div className="spaces p-16 declare-form">
          <Row className="spaces mb-16">
            <Col xs={6} className="flex-center flex-column">
              <span className="spaces fs-20">TÊN CƠ QUAN ĐƠN VỊ:</span>
              <div className="pseudo-input disabled spaces fs-16">{formik.values?.donViCongTacText}</div>
            </Col>
            <Col xs={6} className="text-center spaces fs-20 fw-600">
              <p>CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM</p>
              <p>Độc lập - Tự do - Hạnh phúc</p>
              <p className="spaces fs-14 fw-400">{formik.values?.createDate ? `Ngày ${new Date(formik.values?.createDate).getDate()}
                    tháng ${new Date(formik.values?.createDate).getMonth() + 1} năm ${new Date(formik.values?.createDate).getFullYear()}`
                  : `Ngày ${new Date().getDate()} tháng ${new Date().getMonth() + 1} năm ${new Date().getFullYear()}`
              }</p>
            </Col>
          </Row>
          <div className="flex-center flex-column">
            <h3 className="spaces fs-24 fw-600">KÊ KHAI TÀI SẢN</h3>
            <TextValidator
              isRequired
              className="flex gap-3 flex-top label-declare"
              name="ngayKeKhai"
              lable={lang("DECLARE_ASSETS.DECLARE_DATE")}
              readOnly={isView}
              type="date"
              onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.ngayKeKhai, "ngayKeKhai")}
              value={formik.values?.ngayKeKhai}
              onChange={formik.handleChange}
              errors={formik.errors?.ngayKeKhai}
              touched={formik.touched?.ngayKeKhai}
            />
          </div>
          <div className="spaces mt-16">
            <h6 className="fw-600 spaces fs-16">I.THÔNG TIN CHUNG</h6>
            <p className="order-title">1.Người kê khai tài sản, thu nhập</p>
            <Row className="declare-form-table">
              <Col xs={6} className="item-col">
                <span className="w-185">- Họ và tên:</span>
                <TextValidator
                  className="w-calc-185 input-form"
                  placeholder="Họ và tên"
                  name="tenNhanVien"
                  readOnly={true}
                  type="text"
                  value={formik.values?.tenNhanVien}
                  onChange={formik.handleChange}
                  errors={formik.errors?.tenNhanVien}
                  touched={formik.touched?.tenNhanVien}
                />
              </Col>
              <Col xs={3} className="spaces pl-0">
                <div className="spaces w-220 flex-middle">
                  <span className="spaces w-80">Ngày sinh</span>
                  <TextValidator
                    className="spaces flex-1 pl-10"
                    name="birthdate"
                    readOnly={true}
                    type="date"
                    onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.birthdate, "birthdate")}
                    value={formik.values?.birthdate}
                    onChange={formik.handleChange}
                    errors={formik.errors?.birthdate}
                    touched={formik.touched?.birthdate}
                  />
                </div>
              </Col>
              <Col xs={12} className="item-col">
                <span className="w-185">- Chức vụ/chức danh công tác:</span>
                <TextValidator
                  className="w-calc-185 input-form"
                  placeholder="Chức vụ/chức danh công tác"
                  name="viTriCongViecText"
                  readOnly={true}
                  type="text"
                  value={formik.values?.viTriCongViecText}
                  onChange={formik.handleChange}
                  errors={formik.errors?.viTriCongViecText}
                  touched={formik.touched?.viTriCongViecText}
                />
              </Col>
              <Col xs={6} className="item-col">
                <span className="w-185">- Cơ quan/đơn vị công tác:</span>
                <TextValidator
                  className="w-calc-185 input-form"
                  placeholder="Cơ quan/đơn vị công tác"
                  name="donViCongTacText"
                  readOnly={true}
                  type="text"
                  value={formik.values?.donViCongTacText}
                  onChange={formik.handleChange}
                  errors={formik.errors?.donViCongTacText}
                  touched={formik.touched?.donViCongTacText}
                />
              </Col>
              <Col xs={6} className="item-col">
                <span className="w-185">- Nơi thường trú:</span>
                <TextValidator
                  className="w-calc-185 input-form"
                  placeholder="Nơi thường trú"
                  name="diaChiThuongChu"
                  readOnly={isView}
                  type="text"
                  value={formik.values?.diaChiThuongChu}
                  onChange={formik.handleChange}
                  errors={formik.errors?.diaChiThuongChu}
                  touched={formik.touched?.diaChiThuongChu}
                />
              </Col>
              <Col xs={6} className="spaces flex-middle pr-0">
                <span className="w-185">- Số chứng minh nhân dân:</span>
                <TextValidator
                  className="w-calc-185 input-form"
                  placeholder="Số chứng minh nhân dân"
                  name="soCmndCccd"
                  readOnly={isView}
                  type="text"
                  value={formik.values?.soCmndCccd}
                  onChange={formik.handleChange}
                  errors={formik.errors?.soCmndCccd}
                  touched={formik.touched?.soCmndCccd}
                />
              </Col>
              <Col xs={6} className="spaces flex pl-10">
                <div className="spaces w-220 flex-middle">
                  <span className="spaces w-80">Ngày cấp</span>
                  <TextValidator
                    className="spaces flex-1 pl-10"
                    name="soCmndCccdNgayCap"
                    readOnly={isView}
                    type="date"
                    onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.soCmndCccdNgayCap, "soCmndCccdNgayCap")}
                    value={formik.values?.soCmndCccdNgayCap}
                    onChange={formik.handleChange}
                    errors={formik.errors?.soCmndCccdNgayCap}
                    touched={formik.touched?.soCmndCccdNgayCap}
                  />
                </div>
                <div className="spaces flex-1 flex-middle pl-10">
                  <span className="spaces w-48">Nơi cấp</span>
                  <TextValidator
                    placeholder="Nơi cấp"
                    className="spaces flex-1 pl-10"
                    name="soCmndCccdNoiCap"
                    readOnly={isView}
                    type="text"
                    value={formik.values?.soCmndCccdNoiCap}
                    onChange={formik.handleChange}
                    errors={formik.errors?.soCmndCccdNoiCap}
                    touched={formik.touched?.soCmndCccdNoiCap}
                  />
                </div>
              </Col>
            </Row>
            <p className="spaces order-title pt-10">2.Vợ hoặc chồng của người kê khai tài sản, thu nhập</p>
            <Row className="declare-form-table">
              <Col xs={6} className="item-col">
                <span className="w-185">- Họ và tên:</span>
                <TextValidator
                  className="w-calc-185 input-form"
                  placeholder="Họ và tên"
                  name="vcHoTen"
                  readOnly={isView}
                  type="text"
                  value={formik.values?.vcHoTen}
                  onChange={formik.handleChange}
                  errors={formik.errors?.vcHoTen}
                  touched={formik.touched?.vcHoTen}
                />
              </Col>

              <Col xs={3} className="spaces pl-0">
                <div className="spaces w-220 flex-middle">
                  <span className="spaces w-80">Ngày sinh </span>
                  <TextValidator
                    className="spaces flex-1 pl-10"
                    name="vcBirthdate"
                    readOnly={isView}
                    type="date"
                    onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.vcBirthdate, "vcBirthdate")}
                    value={formik.values?.vcBirthdate}
                    onChange={formik.handleChange}
                    errors={formik.errors?.vcBirthdate}
                    touched={formik.touched?.vcBirthdate}
                  />
                </div>
              </Col>
              <Col xs={6} className="item-col">
                <span className="w-185">- Chức vụ/chức danh công tác:</span>
                <TextValidator
                  className="w-calc-185 input-form"
                  placeholder="Chức vụ/chức danh công tác"
                  name="vcViTriCongViecText"
                  readOnly={isView}
                  type="text"
                  value={formik.values?.vcViTriCongViecText}
                  onChange={formik.handleChange}
                  errors={formik.errors?.vcViTriCongViecText}
                  touched={formik.touched?.vcViTriCongViecText}
                />
              </Col>
              <Col xs={6} className="item-col">
                <span className="w-185">- Cơ quan/đơn vị công tác:</span>
                <TextValidator
                  className="w-calc-185 input-form"
                  placeholder="Cơ quan/đơn vị công tác"
                  name="vcDonViCongTacText"
                  readOnly={isView}
                  type="text"
                  value={formik.values?.vcDonViCongTacText}
                  onChange={formik.handleChange}
                  errors={formik.errors?.vcDonViCongTacText}
                  touched={formik.touched?.vcDonViCongTacText}
                />
              </Col>
              <Col xs={6} className="item-col">
                <span className="w-185">- Nơi thường trú:</span>
                <TextValidator
                  className="w-calc-185 input-form"
                  placeholder="Nơi thường trú"
                  name="vcDiaChiThuongChu"
                  readOnly={isView}
                  type="text"
                  value={formik.values?.vcDiaChiThuongChu}
                  onChange={formik.handleChange}
                  errors={formik.errors?.vcDiaChiThuongChu}
                  touched={formik.touched?.vcDiaChiThuongChu}
                />
              </Col>
              <Col xs={6} className="spaces flex-middle pr-0">
                <span className="w-185">- Số chứng minh nhân dân:</span>
                <TextValidator
                  className="w-calc-185 input-form"
                  placeholder="Số chứng minh nhân dân"
                  name="vcSoCmndCccd"
                  readOnly={isView}
                  type="text"
                  value={formik.values?.vcSoCmndCccd}
                  onChange={formik.handleChange}
                  errors={formik.errors?.vcSoCmndCccd}
                  touched={formik.touched?.vcSoCmndCccd}
                />
              </Col>
              <Col xs={6} className="spaces flex pl-10">
                <div className="spaces w-220 flex-middle">
                  <span className="spaces w-80">Ngày cấp</span>
                  <TextValidator
                    className="spaces flex-1 pl-10"
                    name="vcSoCmndCccdNgayCap"
                    readOnly={isView}
                    type="date"
                    onBlur={() => handleBlurDate(formik.setFieldValue, formik.values?.vcSoCmndCccdNgayCap, "vcSoCmndCccdNgayCap")}
                    value={formik.values?.vcSoCmndCccdNgayCap}
                    onChange={formik.handleChange}
                    errors={formik.errors?.vcSoCmndCccdNgayCap}
                    touched={formik.touched?.vcSoCmndCccdNgayCap}
                  />
                </div>
                <div className="spaces flex-1 flex-middle pl-10">
                  <span className="spaces w-48">Nơi cấp</span>
                  <TextValidator
                    placeholder="Nơi cấp"
                    className="spaces flex-1 pl-10"
                    name="vcSoCmndCccdNoiCap"
                    readOnly={isView}
                    type="text"
                    value={formik.values?.vcSoCmndCccdNoiCap}
                    onChange={formik.handleChange}
                    errors={formik.errors?.vcSoCmndCccdNoiCap}
                    touched={formik.touched?.vcSoCmndCccdNoiCap}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div>
            <TablePartInfomation
              id="minorChildren"
              columns={MinorChildrenColumns(lang)}
              data={formik.values?.conCai || []}
              setData={(newData) => handleAddAndEditData(newData, "conCai")}
              partForm={(props) => <MinorChildrenForm {...props} />}
              nameTitle={NAME_TITLE.CON_CAI}
              tableTitle="3.Con chưa thành niên (con đẻ, con nuôi theo quy định của pháp luật)"
            />
          </div>
          {formik.values?.loaiKeKhai?.code !== DECLARE_ASSETS_TYPES_CODES.BO_SUNG && (
            <div className="spaces mt-36">
              <h6 className="fw-600 spaces fs-16">II. THÔNG TIN MÔ TẢ TÀI SẢN</h6>
              <BodyInfomation
                handleAddAndEditData={handleAddAndEditData}
                data={formik.values}
                assetsType={ASSETS_TYPE_DOMESTIC}
              />
              <div className="spaces mt-20">
                <p className="order-title">8. Tài sản ở nước ngoài:</p>
                <GroupButton handleEvent={() => setIsOpenAbroadDialog(true)}>Kê khai</GroupButton>
                {isOpenAbroadDialog && (
                  <DeclareAbroadDialog
                    open={isOpenAbroadDialog}
                    handleClose={() => setIsOpenAbroadDialog(false)}
                    data={formik.values}
                    handleAddAndEditData={handleAddAndEditData}
                  />
                )}
              </div>
              <div className="spaces mt-20">
                <TablePartInfomation
                  id="abroadAccount"
                  columns={AbroadAccountColumns(lang)}
                  data={formik.values?.taiKhoanNganHang || []}
                  setData={(newData) => handleAddAndEditData(newData, "taiKhoanNganHang")}
                  partForm={(props) => <AbroadAccountForm {...props} />}
                  dialogSize="lg"
                  nameTitle={NAME_TITLE.TK_NGAN_HANG}
                  tableTitle="9. Tài khoản ở nước ngoài:"
                />
              </div>
              <div className="spaces mt-20 declare-form-table">
                <p className="order-title">10. Tổng thu nhập giữa hai lần kê khai:</p>
                <Row>
                  <Col xs={3} className="flex flex-middle">
                    <span className="w-185">- Người kê khai:</span>
                    <TextValidator
                      placeholder="Người kê khai"
                      name="thuNhap"
                      readOnly={isView}
                      type="text"
                      value={convertNumberPrice(formik.values?.thuNhap)}
                      onChange={handleChangeMoney}
                      errors={formik.errors?.thuNhap}
                      touched={formik.touched?.thuNhap}
                    />
                  </Col>
                  <Col xs={3} className="flex flex-middle">
                    <span className="w-185">- Vợ (hoặc chồng):</span>
                    <TextValidator
                      placeholder="Vợ (hoặc chồng)"
                      name="vcThuNhap"
                      readOnly={isView}
                      type="text"
                      value={convertNumberPrice(formik.values?.vcThuNhap)}
                      onChange={handleChangeMoney}
                      errors={formik.errors?.vcThuNhap}
                      touched={formik.touched?.vcThuNhap}
                    />
                  </Col>
                  <Col xs={3} className="flex flex-middle">
                    <span className="w-185">- Con chưa thành niên:</span>
                    <TextValidator
                      placeholder="Con chưa thành niên"
                      name="conCaiChuaThanhNien"
                      readOnly={isView}
                      type="text"
                      value={convertNumberPrice(formik.values?.conCaiChuaThanhNien)}
                      onChange={handleChangeMoney}
                      errors={formik.errors?.conCaiChuaThanhNien}
                      touched={formik.touched?.conCaiChuaThanhNien}
                    />
                  </Col>
                  <Col xs={3} className="flex flex-middle">
                    <span className="w-185">- Thu nhập chung:</span>
                    <TextValidator
                      placeholder="Thu nhập chung"
                      name="tongThuNhap"
                      readOnly={isView}
                      type="text"
                      value={convertNumberPrice(formik.values?.tongThuNhap)}
                      onChange={handleChangeMoney}
                      errors={formik.errors?.tongThuNhap}
                      touched={formik.touched?.tongThuNhap}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          )}
          {formik.values?.loaiKeKhai?.code !== DECLARE_ASSETS_TYPES_CODES.LAN_DAU && (
            <div className="spaces mt-36">
              <h6 className="fw-600 spaces fs-16">
                III. BIẾN ĐỘNG TÀI SẢN, THU NHẬP; GIẢI TRÌNH NGUỒN GỐC CỦA TÀI SẢN, THU NHẬP TĂNG THÊM
              </h6>
              <p className="note-text">(nếu là kê khai tài sản, thu nhập lần đầu thì không phải kê khai Mục này)</p>
              <TableCustom
                id="fluctuation"
                columns={columnsFluctuation}
                data={fluctuationData}
                buttonAdd={false}
                clearToolbar
                noToolbar
                noPagination={true}
                notDelete={true}
              />
            </div>
          )}
        </div>
      </div>
    </Form>
  );
}

export default DeclareAssetsForm;

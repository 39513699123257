import { Row, Tab, Tabs } from "react-bootstrap";
import { useMemo, useState } from "react";
import "./category.scss";
import useMultiLanguage from "../../hook/useMultiLanguage";
import { findMenuAccessAllowed, hasAuthority } from "../utils/functionUtils";
import { LIST_TAB_LEAVE_REGULATIONS } from "./constants/leaveRegulationsConsts";
import { PERMISSION_ABILITY } from "../../constants";

const LeaveRegulations = () => {
  const { lang } = useMultiLanguage();
  const defaultKey = useMemo(() => findMenuAccessAllowed(LIST_TAB_LEAVE_REGULATIONS)?.key, []);
  const [activeTab, setActiveTab] = useState<string>(defaultKey);

  const handleTabChange = (key: string | null) => {
    key && setActiveTab(key);
  };
  
  return (
    <Row className="leave-regulations spaces mt-16 px-10">
      <Tabs
        className="spaces fs-15"
        activeKey={activeTab}
        defaultActiveKey={defaultKey}
        onSelect={handleTabChange}
      >
        {LIST_TAB_LEAVE_REGULATIONS.map((tab, index) => {
          const TabComponent = tab.component;
          return (hasAuthority(tab.permission, PERMISSION_ABILITY.VIEW) &&
            <Tab
              id={tab.key}
              eventKey={tab.key}
              key={index}
              title={lang(tab.title)}
              mountOnEnter
            >
              <TabComponent />
            </Tab>
          )
        })}
      </Tabs>
    </Row>
  );
};

export { LeaveRegulations };
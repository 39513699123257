import useMultiLanguage from "../../../hook/useMultiLanguage";
import { Modal } from "react-bootstrap";
import TableCustom from "../../components/table-custom/TableCustom";
import { RESPONSE_STATUS_CODE, TYPE } from "../../constants/moduleConsts";
import { useContext, useEffect, useState } from "react";
import { INIT_SEARCH_OBJECT } from "../../constants/moduleConsts";
import AppContext from "../../../AppContext";
import { getPositionConcurrently } from "../services/dismissedServices";
import { toast } from "react-toastify";
import { GroupButton } from "../../components/GroupButton";

interface IProps {
  handleClose: () => void;
  show: boolean;
  handleConfirm: (position: any) => void;
  employeeId: string;
  phanCongType: number | null;
}

function DialogPositionConcurrently(props: IProps) {
  const { handleClose, show, handleConfirm, employeeId, phanCongType } = props;
  const { setPageLoading } = useContext(AppContext);
  const { lang } = useMultiLanguage();

  const [dataChecked, setDatChecked] = useState();
  const [searchObject, setSearchObject] = useState<any>(INIT_SEARCH_OBJECT);
  const [listPosition, setListPosition] = useState<any>([]);

  const updatePageData = async (searchData?: any) => {
    try {
      setPageLoading(true);
      setSearchObject({ ...searchData });
      const { data } = await getPositionConcurrently({ employeeId, phanCongType });
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListPosition(data?.data || []);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleSearch = (data: any = {}) => {
    const dataSearch: any = {
      ...searchObject,
      ...data
    };
    dataSearch.keyword = dataSearch?.keyword?.trim() || ""
    updatePageData(dataSearch);
  };

  useEffect(() => {
    if(searchObject?.pageIndex && searchObject?.pageSize) {
      handleSearch()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchObject?.pageIndex, searchObject?.pageSize]);

  const columns = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: "Khoa/Phòng",
      field: "phongBanMoiText",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        minWidth: "200px",
        textAlign: "left",
      },
    },
    {
      name: "Chức vụ",
      field: "chucVuMoiText",
      headerStyle: {
        minWidth: "150px"
      },
      cellStyle: {
        minWidth: "150px",
        textAlign: "left",
      },
      render: (row: any) => <span>{row?.chucVuMoiText || row?.chucVuMoiKhac}</span>
    }
  ]

  return (
    <Modal
      show={show}
      size="xl"
      centered
      aria-labelledby="example-custom-modal-styling-title"
      onHide={() => handleClose()}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="example-custom-modal-styling-title"
          className="heading-5"
        >
          {lang("CONCURRENTLY.LIST_POSITION")}
        </Modal.Title>
      </Modal.Header>
    <Modal.Body>
      <TableCustom
        id="position_concurrently-list"
        data={listPosition}
        columns={columns}
        dataChecked={dataChecked}
        setDataChecked={setDatChecked}
        searchObject={searchObject}
        setSearchObject={setSearchObject}
        notDelete={true}
        noToolbar={true}
        type={TYPE.SINGLE}
        noPagination={true}
      />
    </Modal.Body>
    <Modal.Footer className="flex-center">
      <GroupButton type="btn-cancel" handleCloseUpdateDialog={handleClose} />
      <GroupButton type="btn-save" typeButton="button" handleSubmit={() => handleConfirm(dataChecked?.[0])} />
    </Modal.Footer>
    </Modal>
  );
}

export default DialogPositionConcurrently;

/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import React, { useContext, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import Autocomplete from "../../components/input/autocomplete";
import TextValidator from "../../components/input/text-validator";
import { RESPONSE_STATUS_CODE, VARIABLE_STRING, TYPE_CATEGORY } from "../../constants/moduleConsts";
import { EmployeeProfile } from "../models/profileModels";
import { updateBank } from "../services/dialogServices";
import Allowance from "./partials/allowance/Allowance";
import Deduct from "./partials/deduct/Deduct";
import SalaryDevelopment from "./partials/salary-development/SalaryDevelopment";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { IBank } from "../models/dialogModels";
import { INIT_BANK } from "../constants/profileDialogConsts";
import { REF_TAB } from "../constants/profileConsts";
import AppContext from "../../../AppContext";
import { searchAllSimpleValue } from "../../services/services";

type IProps = {
  identify: string;
  activeTab: string;
  isView: boolean;
  employeeProfiles: EmployeeProfile;
  handleGetEmployee: (id: string) => Promise<void>;
  formikRef: any;
};

export const SalaryInformation: React.FC<IProps> = (props) => {
  const { formikRef, identify, isView, employeeProfiles, handleGetEmployee, activeTab } = props;
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const handleFormSubmit = async (values: IBank) => {
    try {
      setPageLoading(true);
      const dataSubmit: IBank = {
        ...values,
        nganHangId: values?.nganHang?.id || "",
        nganHangText: String(values?.nganHang?.value || "")
      };
      if (!employeeProfiles?.id) return;
      const { data } = await updateBank(dataSubmit, employeeProfiles?.id);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("TOAST.EDIT.SUCCESS"));
        employeeProfiles?.id && handleGetEmployee(employeeProfiles.id);
      } else {
        toast.warning(data?.message);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: INIT_BANK,
    onSubmit: handleFormSubmit
  });

  const handleChangeSelect = (name: string, value: any) => {
    formik.setFieldValue(name, value);
  };

  useEffect(() => {
    if (employeeProfiles?.id) {
      formik.setValues({
        ...formik.values,
        chiNhanh: employeeProfiles?.chiNhanh || "",
        nganHang: employeeProfiles?.nganHang || null,
        tkNganHang: employeeProfiles?.tkNganHang || ""
      });
    }
  }, [employeeProfiles]);

  useEffect(() => {
    if (formikRef) {
      formikRef.current = formik;
    }
  }, [formik, formikRef]);

  return (
    <>
      <Form
        className="form-info"
        onSubmit={formik.handleSubmit}
      >
        <div className="block-content">
          <span className="text-header">{lang("SALARY.BANK.INFOMATION")}</span>
          <Row className="g-4">
            <Col lg={6} xl={4} xxl={3}>
              <TextValidator
                lable={lang("GENERAL.EMPLOYEE.BANK_NUMBER")}
                name="tkNganHang"
                value={formik.values?.tkNganHang || ""}
                type="text"
                readOnly={isView}
                onChange={formik.handleChange}
              />
            </Col>
            <Col lg={6} xl={4} xxl={3} className="z-index-2">
              <Autocomplete
                lable={lang("INPUT.SALARY.BANK")}
                name="nganHang"
                value={formik.values?.nganHang || null}
                searchFunction={searchAllSimpleValue}
                searchObject={{ type: TYPE_CATEGORY.nganHang }}
                isReadOnly={isView}
                onChange={(value) => handleChangeSelect(VARIABLE_STRING.NGAN_HANG, value)}
                options={[]}
                getOptionLabel={(option) => option?.value}
              />
            </Col>
            <Col lg={6} xl={4} xxl={3}>
              <TextValidator
                lable={lang("GENERAL.EMPLOYEE.RANK_BRANCH")}
                name="chiNhanh"
                value={formik.values?.chiNhanh || ""}
                type="text"
                readOnly={isView}
                onChange={formik.handleChange}
              />
            </Col>
          </Row>
        </div>
      </Form>
      <div className="form-info">
        <SalaryDevelopment
          isView={isView}
          identify={identify}
          employeeProfiles={employeeProfiles}
          activeTab={activeTab}
        />
        <Allowance
          isView={isView}
          identify={identify}
          isCallApi={activeTab === REF_TAB.TT_LUONG}
        />
        <Deduct
          isView={isView}
          identify={identify}
          employeeProfiles={employeeProfiles}
          isCallApi={activeTab === REF_TAB.TT_LUONG}
        />
      </div>
    </>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import { AxiosResponse } from "axios";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import AppContext from "../../../AppContext";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import Autocomplete from "../../components/input/autocomplete";
import { heightSelectMutil } from "../../components/input/autocomplete/styleJS";
import TextValidator from "../../components/input/text-validator";
import TableCustom from "../../components/table-custom/TableCustom";
import { APIResponse } from "../../models/models";
import { RESPONSE_STATUS_CODE, TYPE, VARIABLE_STRING, TYPE_CATEGORY } from "../../constants/moduleConsts";
import { ACADEMIC_RANK, INIT_TRAINING_LEVEL, REF_TAB, generalEducationLevel } from "../constants/profileConsts";
import { CertificateInfo } from "../models/dialogModels";
import { EmployeeProfile, ITrainingLevel } from "../models/profileModels";
import "../profile.scss";
import {
  deleteChungChi,
  deleteMultipleEthnicLanguage,
  deleteMultipleForeignLanguage,
  deleteMultipleITQualification,
  deleteMultipleNationalDefense,
  deleteMultiplePoliticalTheory,
  deleteMultipleQualification,
  deleteMultipleSpecializeTraining,
  deleteMultipleStateManagement,
  getAllChungChiById,
  getAllPoliticalTheoryByEmployeeId,
  getEthnicLanguageByEmployee,
  getForeignLanguageLevelByEmployee,
  getITQualificationByEmployee,
  getNationalDefenseByEmployee,
  getQualificationByEmployee,
  getSpecializeTrainingByEmployee,
  getStateManagementByEmployee
} from "../services/dialogServices";
import { getTrainingInfoByEmployee, updateTrainingInfo } from "../services/profileServices";
import { CertificateColumn } from "./columns/CertificateColumn";
import { EthnicLanguageColumn } from "./columns/EthnicLanguageColumn";
import { ForeignLanguageLevelColumn } from "./columns/ForeignLanguageLevelColumn";
import { ITQualificationColumn } from "./columns/ITQualificationColumn";
import { NationalDefenseColumn } from "./columns/NationalDefenseColumn";
import { PoliticalTheoryColumn } from "./columns/PoliticalTheoryColumn";
import { QualificationColumn } from "./columns/QualificationColumn";
import { SpecializeTrainingColumn } from "./columns/SpecializeTrainingColumn";
import { StateManagementColumn } from "./columns/StateManagementColumn";
import { CertificateDialog } from "./dialogs/CertificateDialog";
import { EthnicLanguageDialog } from "./dialogs/EthnicLanguageDialog";
import { ForeignLanguageLevelDialog } from "./dialogs/ForeignLanguageLevelDialog";
import { ITQualificationDialog } from "./dialogs/ITQualificationDialog";
import { NationalDefenseDialog } from "./dialogs/NationalDefenseDialog";
import { PoliticalTheoryDialog } from "./dialogs/PoliticalTheoryDialog";
import { QualificationDialog } from "./dialogs/QualificationDialog";
import { SpecializeTrainingDialog } from "./dialogs/SpecializeTrainingDialog";
import { StateManagementDialog } from "./dialogs/StateManagementDialog";
import { searchAllSimpleValue } from "../../services/services";

type IProps = {
  identify: string;
  isView: boolean;
  employeeProfiles: EmployeeProfile;
  activeTab: string;
  formikRef: any;
};

export const TrainingInformation: React.FC<IProps> = ({ identify, isView, employeeProfiles, activeTab, formikRef }) => {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [shouldOpenCertificateDialog, setShouldOpenCertificateDialog] = useState<boolean>(false);
  const [shouldOpenPoliticalTheoryDialog, setShouldOpenPoliticalTheoryDialog] = useState<boolean>(false);
  const [shouldOpenQualificationDialog, setShouldOpenQualificationDialog] = useState<boolean>(false);
  const [shouldOpenStateManagementDialog, setShouldOpenStateManagementDialog] = useState<boolean>(false);
  const [shouldOpenITQualificationDialog, setShouldOpenITQualificationDialog] = useState<boolean>(false);
  const [shouldOpenForeignLanguageLevelDialog, setShouldOpenForeignLanguageLevelDialog] = useState<boolean>(false);
  const [shouldOpenNationalDefenseDialog, setShouldOpenNationalDefenseDialog] = useState<boolean>(false);
  const [shouldOpenSpecializeTrainingDialog, setShouldOpenSpecializeTrainingDialog] = useState<boolean>(false);
  const [shouldOpenEthnicLanguageDialog, setShouldOpenEthnicLanguageDialog] = useState<boolean>(false);

  const [trainingInfo, setTrainingInfo] = useState<ITrainingLevel>();
  const [certificate, setCertificate] = useState<CertificateInfo>({} as CertificateInfo);
  const [listCertificate, setListCertificate] = useState<CertificateInfo[]>([]);
  const [politicalTheory, setPoliticalTheory] = useState<any>({});
  const [listPoliticalTheory, setListPoliticalTheory] = useState<any[]>([]);
  const [qualification, setQualification] = useState<any>({});
  const [listQualification, setListQualification] = useState<any[]>([]);
  const [stateManagement, setStateManagement] = useState<any>({});
  const [listStateManagement, setListStateManagement] = useState<any[]>([]);
  const [ITQualification, setITQualification] = useState<any>({});
  const [listITQualification, setListITQualification] = useState<any[]>([]);
  const [foreignLanguageLevel, setForeignLanguageLevel] = useState<any>({});
  const [listForeignLanguageLevel, setListForeignLanguageLevel] = useState<any[]>([]);
  const [nationalDefense, setNationalDefense] = useState<any>({});
  const [listNationalDefense, setListNationalDefense] = useState<any[]>([]);
  const [specializeTraining, setSpecializeTraining] = useState<any>({});
  const [listSpecializeTraining, setListSpecializeTraining] = useState<any[]>([]);
  const [ethnicLanguage, setEthnicLanguage] = useState<any>({});
  const [listEthnicLanguage, setListEthnicLanguage] = useState<any[]>([]);
  const [isCheckChuyenNganh, setIsCheckChuyenNganh] = useState<boolean>(false);

  useEffect(() => {
    if (!identify || activeTab !== REF_TAB.DAO_TAO_BOI_DUONG) return;
    updateDataTrainingInfo();
    updateDataQualification();
    updateDataCertificate();
    updateDataPoliticalTheory();
    updateDataStateManagement();
    updateDataITQualification();
    updateDataForeignLanguageLevel();
    updateDataNationalDefense();
    updateDataSpecializeTraining();
    updateDataEthnicLanguage();
  }, [identify, activeTab]);

  const updateDataTrainingInfo = async () => {
    try {
      setPageLoading(true);
      let { data } = await getTrainingInfoByEmployee(identify);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setTrainingInfo(data?.data);
        setIsCheckChuyenNganh(data?.data?.chuyenNganhHocHamKhac ? true : false)
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const updateDataQualification = async () => {
    try {
      let { data } = await getQualificationByEmployee(identify);
      if(data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListQualification(data?.data);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const updateDataCertificate = async () => {
    if (identify) {
      try {
        let { data } = await getAllChungChiById(identify);
        if(data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
          setListCertificate(data?.data);
        } else {
          toast.warning(data?.message);
        }
      } catch (error) {
        toast.error(lang("GENERAL.ERROR"));
      }
    }
  };

  const updateDataPoliticalTheory = async () => {
    if (identify) {
      try {
        let { data } = await getAllPoliticalTheoryByEmployeeId(identify);
        if(data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
          setListPoliticalTheory(data?.data?.content);
        } else {
          toast.warning(data?.message);
        }
      } catch (error) {
        toast.error(lang("GENERAL.ERROR"));
      }
    }
  };

  const updateDataStateManagement = async () => {
    try {
      let { data } = await getStateManagementByEmployee(identify);
      if(data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListStateManagement(data?.data);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const updateDataITQualification = async () => {
    try {
      let { data } = await getITQualificationByEmployee(identify);
      if(data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListITQualification(data?.data);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const updateDataForeignLanguageLevel = async () => {
    try {
      let { data } = await getForeignLanguageLevelByEmployee(identify);
      if(data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListForeignLanguageLevel(data?.data);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const updateDataNationalDefense = async () => {
    try {
      let { data } = await getNationalDefenseByEmployee(identify);
      if(data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListNationalDefense(data?.data);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const updateDataSpecializeTraining = async () => {
    try {
      let { data } = await getSpecializeTrainingByEmployee(identify);
      if(data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListSpecializeTraining(data?.data);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const updateDataEthnicLanguage = async () => {
    try {
      let { data } = await getEthnicLanguageByEmployee(identify);
      if(data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListEthnicLanguage(data?.data);
      }  else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const handleOpenDialog = (
    row: any,
    setShouldOpenDialog: (value: React.SetStateAction<boolean>) => void,
    setData: (value: any) => void
  ): void => {
    if (employeeProfiles?.id) {
      setShouldOpenDialog(true);
      setData(row);
    } else {
      toast.warning(lang("MESSAGE.BASIC.FIRST"));
    }
  };

  const handleCloseDialog = (
    setShouldOpenDialog: (value: React.SetStateAction<boolean>) => void,
    setData: (value: any) => void
  ): void => {
    setShouldOpenDialog(false);
    setData({});
  };

  const handleCloseAndSearch = (
    updateData: () => Promise<void>,
    setShouldOpenDialog: (value: React.SetStateAction<boolean>) => void,
    setData: (value: any) => void
  ): void => {
    updateData();
    updateDataTrainingInfo();
    handleCloseDialog(setShouldOpenDialog, setData);
  };

  const handleDeleteItems = async (
    handleDelete: (...params: any) => Promise<AxiosResponse<APIResponse>>,
    handleUpdate: () => Promise<void>,
    ids: string
  ) => {
    try {
      const res = await handleDelete?.(ids);
      if (res?.data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("GENERAL.DELETE_SUCCESS"));
        await handleUpdate?.();
        await updateDataTrainingInfo();
        return true;
      } else toast.warning(`${res?.data?.message}`);
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };
  
  const validationSchema = Yup.object().shape({
    trinhDoGiaoDucPhoThong: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    namPhongTang: Yup.number()
      .min(1900, lang("VALIDATION.MIN_YEAR"))
      .max(new Date().getFullYear(), lang("VALIDATON.YEAR_CURRENT"))
      .nullable()
  });

  const handleFormSubmit = async (values: any) => {
    try {
      setPageLoading(true);
      let dataSubmit = {
        ...values,
        employeeId: identify,
        trinhDoGiaoDucPhoThongId: values.trinhDoGiaoDucPhoThong?.code || "",
        trinhDoGiaoDucPhoThongText: values.trinhDoGiaoDucPhoThong?.name || "",
        chuyenNganhHocHamId: values.chuyenNganhHocHam?.id || "",
        chuyenNganhHocHamText: values.chuyenNganhHocHam?.value || ""
      };
      const { data } = await updateTrainingInfo(identify, dataSubmit);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("TOAST.EDIT.SUCCESS"));
        updateDataTrainingInfo();
      } else toast.warning(`${data?.message}`);
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(true);
    }
  };

  const formik = useFormik({
    initialValues: INIT_TRAINING_LEVEL,
    validationSchema,
    onSubmit: handleFormSubmit
  });

  useEffect(() => {
    if (identify) {
      formik.setValues({
        ...trainingInfo,
        trinhDoGiaoDucPhoThong: trainingInfo?.trinhDoGiaoDucPhoThongId
          ? {
            code: trainingInfo?.trinhDoGiaoDucPhoThongId || "",
            name: trainingInfo?.trinhDoGiaoDucPhoThongText || ""
          }
          : null,
        chuyenNganhHocHam: trainingInfo?.chuyenNganhHocHamId
          ? {
            id: trainingInfo?.chuyenNganhHocHamId || "",
            value: trainingInfo?.chuyenNganhHocHamText || ""
          }
          : null
      });
    }
  }, [identify, trainingInfo]);

  const handleChangeSelect = (name: string, value: any) => {
    formik.setFieldValue(name, value);
  };

  useEffect(() => {
    if (formikRef) {
      formikRef.current = formik;
    }
  }, [formik, formikRef]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, nameObj: string) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value);
    formik.setFieldValue(nameObj, null);
  };

  return (
    <>
      <Form
        className="form-info training-level-info flex-column z-index-2 relative"
        onSubmit={formik.handleSubmit}
      >
        <div className="block-content">
          <span className="text-header">{lang("GENERAL.TRAINING_LEVEL_INFO")}</span>
          <Row className="g-4">
            <Col md={6} lg={4} xl={3} xxl={3}>
              <Autocomplete
                isRequired={true}
                lable={lang("INPUT.TRAINING_LEVEL_INFO.EDUCATION_LEVEL")}
                name="trinhDoGiaoDucPhoThong"
                value={formik.values?.trinhDoGiaoDucPhoThong}
                options={generalEducationLevel}
                isReadOnly={isView}
                onChange={(selectedOption: any) => handleChangeSelect("trinhDoGiaoDucPhoThong", selectedOption)}
                touched={formik.touched?.trinhDoGiaoDucPhoThong}
                errors={formik.errors?.trinhDoGiaoDucPhoThong}
              />
            </Col>
            <Col md={6} lg={4} xl={3} xxl={3}>
              <TextValidator
                lable={lang("INPUT.TRAINING_LEVEL_INFO.HIGHEST_LEVEL")}
                name="trinhDoChuyenMonCaoNhat"
                type="text"
                value={formik.values?.trinhDoChuyenMonCaoNhat || ""}
                readOnly={true}
              />
            </Col>
            <Col md={6} lg={4} xl={3} xxl={2}>
              <TextValidator
                lable={lang("INPUT.TRAINING_LEVEL_INFO.TRAINING_FORM")}
                name="hinhThucDaoTao"
                type="text"
                value={formik.values?.hinhThucDaoTao || ""}
                readOnly={true}
              />
            </Col>
            <Col md={6} lg={4} xl={3} xxl={2}>
              <TextValidator
                lable={lang("INPUT.TRAINING_LEVEL_INFO.SPECIALIZED_TRAINING")}
                name="chuyenNganhDaoTao"
                type="text"
                value={formik.values?.chuyenNganhDaoTao || ""}
                readOnly={true}
              />
            </Col>
            <Col md={6} lg={4} xl={3} xxl={2}>
              <TextValidator
                lable={lang("INPUT.QUALIFICATION.GRADUATE_YEAR")}
                name="namDaoTao"
                type="text"
                value={formik.values?.namDaoTao || ""}
                readOnly={true}
              />
            </Col>
            <Col md={6} lg={4} xl={3} xxl={3}>
              <TextValidator
                lable={lang("INPUT.TRAINING_LEVEL_INFO.TRANNING_FACILITY")}
                name="coSoDaoTao"
                type="text"
                value={formik.values?.coSoDaoTao || ""}
                readOnly={true}
              />
            </Col>
            <Col md={6} lg={4} xl={3} xxl={3}>
              <TextValidator
                lable={lang("INPUT.TRAINING_LEVEL_INFO.HIGHEST_LANGUAGE_LEVEL")}
                name="trinhDoNgoaiNguCaoNhat"
                type="text"
                value={formik.values?.trinhDoNgoaiNguCaoNhat || ""}
                readOnly={true}
              />
            </Col>
            <Col md={6} lg={4} xl={3} xxl={2}>
              <TextValidator
                lable={lang("INPUT.TRAINING_LEVEL_INFO.POLITICAL_THEORY_LEVEL")}
                name="trinhDoLyLuanChinhTri"
                type="text"
                value={formik.values?.trinhDoLyLuanChinhTri || ""}
                readOnly={true}
              />
            </Col>
            <Col md={6} lg={4} xl={3} xxl={2}>
              <TextValidator
                lable={lang("INPUT.TRAINING_LEVEL_INFO.HIGHEST_IT_LEVEL")}
                name="trinhDoTinHocCaoNhat"
                type="text"
                value={formik.values?.trinhDoTinHocCaoNhat || ""}
                readOnly={true}
              />
            </Col>
            <Col md={6} lg={4} xl={3} xxl={2}>
              <TextValidator
                lable={lang("INPUT.TRAINING_LEVEL_INFO.STATE_MANAGEMENT_LEVEL")}
                name="trinhDoQuanLyNhaNuoc"
                type="text"
                value={formik.values?.trinhDoQuanLyNhaNuoc || ""}
                readOnly={true}
              />
            </Col>
            <Col md={12}>
              <Autocomplete
                lable={lang("INPUT.TRAINING_LEVEL_INFO.SOFT_SKILL")}
                closeMenuOnSelect={false}
                name="kyNangMemList"
                value={formik.values?.kyNangMemList || []}
                options={[]}
                isMulti={true}
                searchFunction={searchAllSimpleValue}
                onChange={(selectedOption: any) => handleChangeSelect("kyNangMemList", selectedOption)}
                searchObject={{ type: TYPE_CATEGORY.kyNangMem }}
                getOptionLabel={(option) => option?.value}
                getOptionValue={(option) => option?.value}
                styles={heightSelectMutil("auto")}
                isReadOnly={isView ? true : undefined}
              />
            </Col>
          </Row>
        </div>
        <div className="block-content">
          <span className="text-header">{lang("INPUT.ACADEMIC_TITLE_CONFERRED")}</span>
          <Row className="g-4">
            <Col md={6} lg={3} xl={3} xxl={2}>
              <Autocomplete
                lable={lang("INPUT.ACADEMIC_RANK")}
                name="hocHam"
                value={formik.values?.hocHam || null}
                options={ACADEMIC_RANK}
                isReadOnly={isView}
                onChange={(selectedOption: any) => handleChangeSelect(VARIABLE_STRING.HOC_HAM, selectedOption)}
              />
            </Col>
            <Col md={6} lg={3} xl={3} xxl={2}>
              <TextValidator
                lable={lang("INPUT.YEAR_OF_CONFERMENT")}
                name="namPhongTang"
                type="number"
                value={formik.values?.namPhongTang}
                readOnly={isView}
                onChange={formik.handleChange}
                touched={formik.touched?.namPhongTang}
                errors={formik.errors?.namPhongTang}
              />
            </Col>
            <Col md={6} lg={6} xl={4} xxl={3}>
              <Autocomplete
                lable={lang("INPUT.SPECIALIZED")}
                formCheckBox={true}
                value={isCheckChuyenNganh ? formik.values?.chuyenNganhHocHamKhac : formik.values?.chuyenNganhHocHam}
                name={
                  isCheckChuyenNganh ? VARIABLE_STRING.CHUYEN_NGANH_HOC_HAM_KHAC : VARIABLE_STRING.CHUYEN_NGANH_HOC_HAM
                }
                setIsCheckBox={setIsCheckChuyenNganh}
                isCheckBox={isCheckChuyenNganh}
                onChange={(selectedOption) =>
                  isCheckChuyenNganh
                    ? handleChange(selectedOption, VARIABLE_STRING.CHUYEN_NGANH_HOC_HAM)
                    : handleChangeSelect(VARIABLE_STRING.CHUYEN_NGANH_HOC_HAM, selectedOption)
                }
                isReadOnly={isView}
                options={[]}
                searchFunction={searchAllSimpleValue}
                searchObject={{ type: TYPE_CATEGORY.chuyenNganh }}
                getOptionLabel={(option) => option?.value}
              />
            </Col>
          </Row>
        </div>
      </Form>
      <div className="form-info">
        <div className="block-content">
          <TableCustom
            id="qualification"
            title={lang("INFO.QUALIFICATION.FROM_INTERMEDIATE_LEVEL")}
            isActionTableTab
            buttonAdd={!isView}
            setSearchObject={() => {}}
            notDelete={isView}
            noToolbar={true}
            data={listQualification}
            columns={QualificationColumn()}
            handleDelete={(ids: string) => handleDeleteItems(deleteMultipleQualification, updateDataQualification, ids)}
            justFilter={true}
            type={TYPE.MULTILINE}
            noPagination={true}
            fixedColumnsCount={3}
            dependencies={activeTab}
            handleOpenDialog={(row) => handleOpenDialog(row, setShouldOpenQualificationDialog, setQualification)}
            handleDoubleClick={(row) => handleOpenDialog(row, setShouldOpenQualificationDialog, setQualification)}
          />
        </div>
        <div className="block-content">
          <TableCustom
            id="certificate"
            title={lang("INFO.CERTIFICATE")}
            isActionTableTab
            buttonAdd={!isView}
            buttonExportExcel={false}
            notDelete={isView}
            noToolbar={true}
            data={listCertificate}
            fixedColumnsCount={3}
            columns={CertificateColumn()}
            handleDelete={(ids: string) => handleDeleteItems(deleteChungChi, updateDataCertificate, ids)}
            justFilter={true}
            type={TYPE.MULTILINE}
            noPagination={true}
            handleOpenDialog={(row) => handleOpenDialog(row, setShouldOpenCertificateDialog, setCertificate)}
            handleDoubleClick={(row) => handleOpenDialog(row, setShouldOpenCertificateDialog, setCertificate)}
          />
        </div>
        <div className="block-content">
          <TableCustom
            id="politicalTheory"
            title={lang("INFO.POLITICAL_THEORY")}
            isActionTableTab
            buttonAdd={!isView}
            notDelete={isView}
            noToolbar={true}
            data={listPoliticalTheory}
            fixedColumnsCount={3}
            columns={PoliticalTheoryColumn()}
            handleDelete={(ids: string) =>
              handleDeleteItems(deleteMultiplePoliticalTheory, updateDataPoliticalTheory, ids)
            }
            justFilter={true}
            type={TYPE.MULTILINE}
            noPagination={true}
            handleOpenDialog={(row) => handleOpenDialog(row, setShouldOpenPoliticalTheoryDialog, setPoliticalTheory)}
            handleDoubleClick={(row) => handleOpenDialog(row, setShouldOpenPoliticalTheoryDialog, setPoliticalTheory)}
          />
        </div>
        <div className="block-content">
          <TableCustom
            id="stateManagement"
            title={lang("INFO.STATE_MANAGEMENT")}
            isActionTableTab
            buttonAdd={!isView}
            notDelete={isView}
            noToolbar={true}
            data={listStateManagement}
            fixedColumnsCount={3}
            columns={StateManagementColumn()}
            handleDelete={(ids: string) =>
              handleDeleteItems(deleteMultipleStateManagement, updateDataStateManagement, ids)
            }
            justFilter={true}
            type={TYPE.MULTILINE}
            noPagination={true}
            handleOpenDialog={(row) => handleOpenDialog(row, setShouldOpenStateManagementDialog, setStateManagement)}
            handleDoubleClick={(row) => handleOpenDialog(row, setShouldOpenStateManagementDialog, setStateManagement)}
          />
        </div>
        <div className="block-content">
          <TableCustom
            id="ITQualification"
            title={lang("INFO.IT_QUALIFICATION")}
            isActionTableTab
            buttonAdd={!isView}
            notDelete={isView}
            noToolbar={true}
            data={listITQualification}
            fixedColumnsCount={3}
            columns={ITQualificationColumn()}
            handleDelete={(ids: string) =>
              handleDeleteItems(deleteMultipleITQualification, updateDataITQualification, ids)
            }
            justFilter={true}
            type={TYPE.MULTILINE}
            noPagination={true}
            handleOpenDialog={(row) => handleOpenDialog(row, setShouldOpenITQualificationDialog, setITQualification)}
            handleDoubleClick={(row) => handleOpenDialog(row, setShouldOpenITQualificationDialog, setITQualification)}
          />
        </div>
        <div className="block-content">
          <TableCustom
            id="foreignLanguageLevel"
            title={lang("INFO.FOREIGN_LANG_LEVEL")}
            isActionTableTab
            buttonAdd={!isView}
            notDelete={isView}
            noToolbar={true}
            data={listForeignLanguageLevel}
            fixedColumnsCount={3}
            columns={ForeignLanguageLevelColumn()}
            handleDelete={(ids: string) =>
              handleDeleteItems(deleteMultipleForeignLanguage, updateDataForeignLanguageLevel, ids)
            }
            justFilter={true}
            type={TYPE.MULTILINE}
            noPagination={true}
            handleOpenDialog={(row) =>
              handleOpenDialog(row, setShouldOpenForeignLanguageLevelDialog, setForeignLanguageLevel)
            }
            handleDoubleClick={(row) => handleOpenDialog(row, setShouldOpenForeignLanguageLevelDialog, setForeignLanguageLevel)}
          />
        </div>
        <div className="block-content">
          <TableCustom
            id="nationalDefense"
            title={lang("INFO.TRAINING.NATIONAL_DEFENSE")}
            isActionTableTab
            buttonAdd={!isView}
            notDelete={isView}
            noToolbar={true}
            data={listNationalDefense}
            fixedColumnsCount={3}
            columns={NationalDefenseColumn()}
            handleDelete={(ids: string) =>
              handleDeleteItems(deleteMultipleNationalDefense, updateDataNationalDefense, ids)
            }
            justFilter={true}
            type={TYPE.MULTILINE}
            noPagination={true}
            handleOpenDialog={(row) => handleOpenDialog(row, setShouldOpenNationalDefenseDialog, setNationalDefense)}
            handleDoubleClick={(row) => handleOpenDialog(row, setShouldOpenNationalDefenseDialog, setNationalDefense)}
          />
        </div>
        <div className="block-content">
          <TableCustom
            id="specializeTraining"
            title={lang("INFO.TRAINING.SPECIALIZE")}
            isActionTableTab
            buttonAdd={!isView}
            notDelete={isView}
            noToolbar={true}
            data={listSpecializeTraining}
            fixedColumnsCount={3}
            columns={SpecializeTrainingColumn()}
            handleDelete={(ids: string) =>
              handleDeleteItems(deleteMultipleSpecializeTraining, updateDataSpecializeTraining, ids)
            }
            justFilter={true}
            type={TYPE.MULTILINE}
            noPagination={true}
            handleOpenDialog={(row) =>
              handleOpenDialog(row, setShouldOpenSpecializeTrainingDialog, setSpecializeTraining)
            }
            handleDoubleClick={(row) => handleOpenDialog(row, setShouldOpenSpecializeTrainingDialog, setSpecializeTraining)}
          />
        </div>
        <div className="block-content">
          <TableCustom
            id="ethnicLanguage"
            title={lang("INFO.ETHNIC_LANGUAGE")}
            isActionTableTab
            buttonAdd={!isView}
            notDelete={isView}
            noToolbar={true}
            data={listEthnicLanguage}
            fixedColumnsCount={3}
            columns={EthnicLanguageColumn()}
            handleDelete={(ids: string) =>
              handleDeleteItems(deleteMultipleEthnicLanguage, updateDataEthnicLanguage, ids)
            }
            justFilter={true}
            type={TYPE.MULTILINE}
            noPagination={true}
            handleOpenDialog={(row) => handleOpenDialog(row, setShouldOpenEthnicLanguageDialog, setEthnicLanguage)}
            handleDoubleClick={(row) => handleOpenDialog(row, setShouldOpenEthnicLanguageDialog, setEthnicLanguage)}
          />
        </div>
        {shouldOpenQualificationDialog && (
          <QualificationDialog
            handleCloseQualificationDialog={() => handleCloseDialog(setShouldOpenQualificationDialog, setQualification)}
            handleCloseAndSearch={() => 
              handleCloseAndSearch(updateDataQualification, setShouldOpenQualificationDialog, setQualification)
            }
            identify={identify}
            isView={isView}
            qualificationEdit={qualification}
          />
        )}
        {shouldOpenCertificateDialog && (
          <CertificateDialog
            handleCloseCertificateDialog={() => handleCloseDialog(setShouldOpenCertificateDialog, setCertificate)}
            handleCloseAndSearch={() =>
              handleCloseAndSearch(updateDataCertificate, setShouldOpenCertificateDialog, setCertificate)
            }
            identify={identify}
            isView={isView}
            certificateEdit={certificate}
          />
        )}
        {shouldOpenPoliticalTheoryDialog && (
          <PoliticalTheoryDialog
            handleClosePoliticalTheoryDialog={() => handleCloseDialog(setShouldOpenPoliticalTheoryDialog, setPoliticalTheory)}
            handleCloseAndSearch={() =>
              handleCloseAndSearch(updateDataPoliticalTheory, setShouldOpenPoliticalTheoryDialog, setPoliticalTheory)
            }
            identify={identify}
            isView={isView}
            politicalTheoryEdit={politicalTheory}
          />
        )}
        {shouldOpenStateManagementDialog && (
          <StateManagementDialog
            handleCloseStateManagementDialog={() => handleCloseDialog(setShouldOpenStateManagementDialog, setStateManagement)}
            handleCloseAndSearch={() =>
              handleCloseAndSearch(updateDataStateManagement, setShouldOpenStateManagementDialog, setStateManagement)
            }
            identify={identify}
            isView={isView}
            stateManagementEdit={stateManagement}
          />
        )}
        {shouldOpenITQualificationDialog && (
          <ITQualificationDialog
            handleCloseITQualificationDialog={() => handleCloseDialog(setShouldOpenITQualificationDialog, setITQualification)}
            handleCloseAndSearch={() =>
              handleCloseAndSearch(updateDataITQualification, setShouldOpenITQualificationDialog, setITQualification)
            }
            identify={identify}
            isView={isView}
            ITQualificationEdit={ITQualification}
          />
        )}
        {shouldOpenForeignLanguageLevelDialog && (
          <ForeignLanguageLevelDialog
            handleCloseForeignLanguageLevelDialog={() =>
              handleCloseDialog(
                setShouldOpenForeignLanguageLevelDialog,
                setForeignLanguageLevel
              )
            }
            handleCloseAndSearch={() =>
              handleCloseAndSearch(
                updateDataForeignLanguageLevel,
                setShouldOpenForeignLanguageLevelDialog,
                setForeignLanguageLevel
              )
            }
            identify={identify}
            isView={isView}
            foreignLanguageLevelEdit={foreignLanguageLevel}
          />
        )}
        {shouldOpenNationalDefenseDialog && (
          <NationalDefenseDialog
            handleCloseNationalDefenseDialog={() => handleCloseDialog(setShouldOpenNationalDefenseDialog, setNationalDefense)}
            handleCloseAndSearch={() =>
              handleCloseAndSearch(updateDataNationalDefense, setShouldOpenNationalDefenseDialog, setNationalDefense)
            }
            identify={identify}
            isView={isView}
            nationalDefenseEdit={nationalDefense}
          />
        )}
        {shouldOpenSpecializeTrainingDialog && (
          <SpecializeTrainingDialog
            handleCloseSpecializeTrainingDialog={() =>
              handleCloseDialog(
                setShouldOpenSpecializeTrainingDialog,
                setSpecializeTraining
              )
            }
            handleCloseAndSearch={() =>
              handleCloseAndSearch(
                updateDataSpecializeTraining,
                setShouldOpenSpecializeTrainingDialog,
                setSpecializeTraining
              )
            }
            identify={identify}
            isView={isView}
            specializeTrainingEdit={specializeTraining}
          />
        )}
        {shouldOpenEthnicLanguageDialog && (
          <EthnicLanguageDialog
            handleCloseEthnicLanguageDialog={() => handleCloseDialog(setShouldOpenEthnicLanguageDialog, setEthnicLanguage)}
            handleCloseAndSearch={() =>
              handleCloseAndSearch(updateDataEthnicLanguage, setShouldOpenEthnicLanguageDialog, setEthnicLanguage)
            }
            identify={identify}
            isView={isView}
            ethnicLanguageEdit={ethnicLanguage}
          />
        )}
      </div>
    </>
  );
};

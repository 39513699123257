import moment from "moment";
import { IDetailSchedule, IOfficeWorkDay, IOfficeWorkSchedule } from "../models/officeWorkScheduleModels";
import { FORMAT_DATE, LIST_OFFICE_WEEKDAY } from "../../constants/moduleConsts";

export const initListWorkday = (year: number | null, month: number | null) => {
  if(!year || !month) return [];
  const firstDayOfMonth = moment([year, month - 1]);
  const lastDayOfMonth = moment(firstDayOfMonth).endOf('month');
  const listWorkday = Array.from({ length: lastDayOfMonth.date() }, (_, i) => {
    const date = moment(firstDayOfMonth).date(i + 1);
    return { dayOfWeek: date.isoWeekday(), date: date.format(FORMAT_DATE.YYYY_MM_DD_INPUT) };
  }).reduce((weeks: IOfficeWorkDay[], { dayOfWeek, date }) => {
    if (!weeks[weeks.length - 1] || dayOfWeek === 1) {
      weeks.push({});
    }
    weeks[weeks.length - 1][dayOfWeek] = {
      date,
      isWorked: LIST_OFFICE_WEEKDAY.includes(dayOfWeek),
      isRequire: LIST_OFFICE_WEEKDAY.includes(dayOfWeek)
    };
    return weeks;
  }, []);
  
  return listWorkday;
}

export const convertDataSubmit = (values: IOfficeWorkSchedule, listWorkday: IOfficeWorkDay[]) => {
  const { donViCongTac, phongBan, ...rest } = values;
  return {
    ...rest,
    donViCongTacId: donViCongTac?.id,
    donViCongTacText: donViCongTac?.name,
    phongBanId: phongBan?.id,
    phongBanText: phongBan?.name,
    detailSchedule: listWorkday.length ? listWorkday.reduce((acc: IDetailSchedule[], week) => {
      acc.push(...Object.values(week));
      return acc;
    }, []) : rest.detailSchedule
  }
}

export const convertDetailSchedule = (detailSchedule: IDetailSchedule[]) => {
  const listWorkday = detailSchedule.map(detail => {
    const date = moment(detail.date, 'YYYY-MM-DD');
    return { dayOfWeek: date.isoWeekday(), date: date.format('YYYY-MM-DD'), isWorked: detail.isWorked, note: detail.note };
  }).reduce((weeks: IOfficeWorkDay[], { dayOfWeek, date, isWorked, note }) => {
    if (!weeks[weeks.length - 1] || dayOfWeek === 1) {
      weeks.push({});
    }
    weeks[weeks.length - 1][dayOfWeek] = { date, isWorked, isRequire: LIST_OFFICE_WEEKDAY.includes(dayOfWeek), note };
    return weeks;
  }, []);

  return listWorkday;
};

export const convertDataUI = (officeWorkInfo: IOfficeWorkSchedule) => {
  return {
    ...officeWorkInfo,
    donViCongTac: officeWorkInfo?.donViCongTacId ? {
      id: officeWorkInfo?.donViCongTacId,
      name: officeWorkInfo?.donViCongTacText
    } : null,
    phongBan: officeWorkInfo?.phongBanId ? {
      id: officeWorkInfo?.phongBanId,
      name: officeWorkInfo?.phongBanText
    } : null,
    approver: officeWorkInfo?.approverId ? {
      id: officeWorkInfo?.approverId,
      name: officeWorkInfo?.approverText
    } : null
  }
}
/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import AppContext from "../../../AppContext";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { GroupButton } from "../../components/GroupButton";
import TableCollapseCustom, { columnNamesCollapseType } from "../../components/table-collapse-custom/TableCollapseCustom";
import AddAttributeValueDialog from "../../profile/components/dialogs/AddAttributeValueDialog";
import { IFile } from "../../profile/models/dialogModels";
import { MISSION_STATUS, MISSION_STATUS_CODE, RESPONSE_STATUS_CODE, VARIABLE_STRING, TYPE_CATEGORY, EXPORT_FILE_NAME } from "../../constants/moduleConsts";
import { exportToFile, hasAuthority } from "../../utils/functionUtils";
import {
  INIT_BONUS_INFO,
  OBJECT_BONUS_CODE,
} from "../constants/bonusConsts";
import { BonusInfo } from "../models/bonusModels";
import { addNewBonus, getKhenThuongList, updateBonus } from "../services/bonusServices";
import { convertDataSubmit, convertDataUI } from "../utils/bonusUtils";
import { checkInvalidDate } from "../../utils/validationSchema";
import { useIntl } from "react-intl";
import { exportKhenThuongChiTiet } from "../../services/exportFileServices";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../../constants";
import { KTSVG } from "../../../../_metronic/helpers";
import { STATUS_NV } from "../../profile/constants/dialogChildConsts";
import BonusForm from "./BonusForm";
import { SelectEmployeeByDepartment, SelectEmployeeDialog } from "../../components/dialogs";

interface IProps {
  bonusInfo: BonusInfo;
  listBonus?: BonusInfo[];
  handleClose: () => void;
  handleCloseAndSearch: () => void;
  isView: boolean;
  setIsView: (data: any) => void;
}

const BonusDialog: FC<IProps> = (props) => {
  const { lang } = useMultiLanguage();
  const intl = useIntl();
  const {
    bonusInfo,
    handleClose,
    isView,
    setIsView,
    handleCloseAndSearch
  } = props;
  const [shouldOpenBonusListDialog, setShouldOpenBonusListDialog] = useState(false);
  const [shouldOpenBonusListSigleDialog, setShouldOpenBonusListSigleDialog] = useState(false);
  const [employeeSelected, setEmployeeSelected] = useState<any[]>([]);
  const [shouldOpenAddFormOfDialog, setShouldOpenAddFormOfDialog] = useState(false);
  const [dataChecked, setDataChecked] = useState<any[]>([]);
  const [isViewOnly, setIsViewOnly] = useState<boolean>(false);
  const { setPageLoading } = useContext(AppContext);

  useEffect(() => {
    if (bonusInfo?.id) {
      formik.setValues(convertDataUI(bonusInfo));
      setIsViewOnly(bonusInfo?.trangThai?.code === MISSION_STATUS_CODE.COMPLETED);
      getEmployeeSelected();
    } else {
      formik.setFieldValue(VARIABLE_STRING.TRANG_THAI, MISSION_STATUS[0]);
    }
  }, [bonusInfo]);

  const getEmployeeSelected = async () => {
    try {
      setPageLoading(true);
      const { data } = await getKhenThuongList(String(bonusInfo?.id));
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setEmployeeSelected(data?.data);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleCloseBonusListDialog = () => {
    setShouldOpenBonusListDialog(false);
  };

  const handleOpenBonusListDialog = () => {
    if (!formik.values?.doiTuongKhenThuong?.code) return toast.warning(lang("TOAST.SELECT_OBJECT_BONUS"));
    formik.values?.doiTuongKhenThuong?.code === OBJECT_BONUS_CODE.GROUP
      ? setShouldOpenBonusListDialog(true)
      : setShouldOpenBonusListSigleDialog(true);
  };

  const validationSchema = Yup.object().shape({
    doiTuongKhenThuong: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    dotKhenThuong: Yup.string().required(lang("VALIDATION.REQUIRE")).max(255, lang("VALIDATION.MAX255")).nullable(),
    loaiKhenThuong: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    hinhThucKhenThuong: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    capQuyetDinh: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable(),
    namKhenThuong: Yup.number()
      .required(lang("VALIDATION.REQUIRE"))
      .min(1900, lang("VALIDATION.MIN_YEAR"))
      .max(9999, lang("VALIDATION.MAX_YEAR"))
      .nullable(),
    ngayKhenThuong: Yup.date().concat(checkInvalidDate(intl)).required(lang("VALIDATION.REQUIRE")),
    thoiGianHuongLuongKeoDai: Yup.number()
      .max(12, lang("APPRECIATIVE.MAX_12_MONTHS"))
      .when("ahtgDieuChinhLuong", {
        is: (ahtgDieuChinhLuong: boolean) => ahtgDieuChinhLuong,
        then: Yup.number().required(lang("VALIDATION.REQUIRE")).nullable(),
      })
      .nullable(),
  });

  const handleSubmit = async (values: any) => {
    if (!(employeeSelected?.length > 0)) return toast.warning(lang("TOAST.SELECT_LIST_BONUS"));
    if (
      !(employeeSelected?.length > 1) &&
      !(employeeSelected[0]?.listEmployee?.length > 1) &&
      formik.values?.doiTuongKhenThuong?.code === OBJECT_BONUS_CODE.GROUP
    )
      return toast.warning(lang("TOAST.GROUP_BIGEST_TWO"));
    try {
      setPageLoading(true);
      const dataSubmit = convertDataSubmit(values, employeeSelected);
      const { data } = bonusInfo?.id ? await updateBonus(bonusInfo.id, dataSubmit) : await addNewBonus(dataSubmit);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        let message = bonusInfo?.id ? "TOAST.EDIT.SUCCESS" : "TOAST.CREATE.SUCCESS";
        toast.success(lang(message));
        handleCloseAndSearch();
      } else {
        toast.warning(`${data?.message}`);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: INIT_BONUS_INFO,
    onSubmit: handleSubmit,
    validationSchema
  });

  const handleChangeSelect = (name: string, value: any) => {
    switch (name) {
      case VARIABLE_STRING.DOI_TUONG_KHEN_THUONG:
        setEmployeeSelected([]);
        formik.setFieldValue(name, value);
        break;
      case VARIABLE_STRING.DANH_HIEU_THI_DUA:
        formik.setValues({
          ...formik.values,
          [name]: value,
          ahtgDieuChinhLuong: Boolean(value?.mappingName),
          thoiGianHuongLuongKeoDai: null
        })
        break;
      default:
        formik.setFieldValue(name, value);
    }
  };

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    if (name === VARIABLE_STRING.ANH_HUONG_THOI_GIAN_DIEU_CHINH_LUONG) {
      formik.setValues({
        ...formik.values,
        [name]: checked,
        thoiGianHuongLuongKeoDai: null
      });
    } else {
      formik.setFieldValue(name, checked);
    }
  };

  const handleCloseAttributeDialog = () => {
    setShouldOpenAddFormOfDialog(false);
  };

  const handleSaveFormOfItem = (newItem: any) => {
    handleChangeSelect(VARIABLE_STRING.HINH_THUC_KHEN_THUONG, newItem);
    handleCloseAttributeDialog();
  };

  const handleSaveEmployeeSingle = (employee: any) => {
    const convertDataTable = [
      {
        phongBanId: employee[0]?.phongBanId || "",
        phongBanText: employee[0]?.phongBanText || "",
        listEmployee: [{ ...employee[0], isChecked: false }]
      }
    ];
    setEmployeeSelected(convertDataTable);
  };

  const handleSetFile = (data: IFile) => {
    formik.setFieldValue("fileId", data.id);
    formik.setFieldValue("fileName", data.name);
  };

  const handleDeleteEmployeeSelect = () => {
    const newSelectedData = employeeSelected.map((row: any) => {
      if (row?.isParentChecked) return null;
      return {
        ...row,
        listEmployee: (row?.listEmployee || []).filter(
          (oldItem: any) => !dataChecked?.some((removeItem: any) => oldItem?.employeeId === removeItem?.employeeId)
        )
      };
    });

    setEmployeeSelected(newSelectedData.filter((item: any) => item !== null));
    setDataChecked([]);
  };

  const columns: columnNamesCollapseType[] = [
    {
      name: lang("TABLE.INDEX"),
      field: "index",
      headerCellProps: {
        minWidth: 50
      },
      bodyCellProps: {
        textAlign: "center"
      },
      render: (row: any, index: number) => <span>{index + 1}</span>
    },
    {
      name: lang("INPUT.CODE"),
      field: "maNhanVien",
      headerCellProps: {
        minWidth: 80
      },
      bodyCellProps: {
        textAlign: "center"
      },
      render: (row: any) => <span className="color-steel-blue fw-600">{row?.maNhanVien}</span>
    },
    {
      name: lang("INPUT.FULLNAME"),
      field: "name",
      headerCellProps: {
        minWidth: 200
      }
    },
    {
      name: lang("PROFILE.ROLE"),
      field: "chucVuText",
      headerCellProps: {
        minWidth: 250
      }
    },
    {
      name: lang("GENERAL.EMPLOYEE.TITLE"),
      field: "viTriCongViecText",
      headerCellProps: {
        minWidth: 150
      }
    }
  ];

  const handleChangePositiveNumber = (event: React.ChangeEvent<any>) => {
    if (!event.target?.value?.startsWith("0")) {
      formik.handleChange(event);
    }
  };

  const handleExport = () => {
    exportToFile({
      exportAPI: () => exportKhenThuongChiTiet(bonusInfo.id || ""),
      fileName: EXPORT_FILE_NAME.CHI_TIET_KHEN_THUONG,
      setPageLoading
    })
  }

  return (
    <Form
      onSubmit={formik.handleSubmit}
      className="h-full"
    >
      <div className="header-form-action">
        <GroupButton
          type="btn-back"
          handleClose={handleClose}
        />
        {/* {!isViewOnly && ( */}
        <div className="flex">
          {bonusInfo?.id && (
            <GroupButton className="spaces mr-16" outline handleEvent={handleExport}>
              <KTSVG path="/media/icons/export-excel.svg" /> {lang("BTN.EXPORT")}
            </GroupButton>
          )}
          {isView ? (hasAuthority(PERMISSIONS.KHEN_THUONG_MENU, PERMISSION_ABILITY.UPDATE) &&
            <GroupButton type="btn-edit" handleSaveEdit={() => setIsView(false)} />
          ) : (
            <GroupButton type="btn-save" />
          )}
        </div>
        {/* )} */}
      </div>
      <div className="form-content-scroll">
        <div className="sub-title-form">{bonusInfo?.id ? lang("APPRECIATIVE.LIST.EDIT") : lang("APPRECIATIVE.LIST.ADD")}</div>
        <div><span className="fs-3 fw-bold">{lang("GENERAL.APPRECIATIVE")}</span></div>
        <BonusForm
          handleClose={handleClose}
          bonusInfo={bonusInfo}
          handleExport={handleExport}
          isView={isView}
          setIsView={setIsView}
          formik={formik}
          handleChangeSelect={handleChangeSelect}
          setShouldOpenAddFormOfDialog={setShouldOpenAddFormOfDialog}
          handleCheckBoxChange={handleCheckBoxChange}
          isViewOnly={isViewOnly}
          handleChangePositiveNumber={handleChangePositiveNumber}
          handleSetFile={handleSetFile}
          isAddNew={true}
        />
        <Row>
          <Col xs={12} className="spaces mt-24">
            <TableCollapseCustom
              title={lang("GENERAL.APPRECIATIVE.EMPLOYEE_LIST")}
              columnNameList={columns}
              data={employeeSelected}
              nameParent="phongBanText"
              nameChildren="listEmployee"
              selectData={setDataChecked}
              setData={setEmployeeSelected}
              selectionMode="multiple"
              isSelect={true}
              buttonAdd={!isView}
              handleOpenDialog={handleOpenBonusListDialog}
              handleDelete={handleDeleteEmployeeSelect}
              notDelete={isView}
              uniquePrefix="employeeId"
              disabledSelect={isView}
            />
          </Col>

          {shouldOpenBonusListDialog && (
            <SelectEmployeeByDepartment
              title={lang("GENERAL.LIST_EMPLOYEE_BY_DEPARTMENT")}
              open={shouldOpenBonusListDialog}
              handleClose={handleCloseBonusListDialog}
              setParentSelectedData={setEmployeeSelected}
              parentSelectedData={employeeSelected}
              searchEmployeeObject={{
                trangThaiLamViec: Object.values(STATUS_NV)
                  .filter((status) => status !== STATUS_NV.THOI_VIEC)
                  .join(",")
              }}
            />
          )}

          {shouldOpenBonusListSigleDialog && (
            <SelectEmployeeDialog
              open={shouldOpenBonusListSigleDialog}
              handleClose={() => setShouldOpenBonusListSigleDialog(false)}
              handleSaveData={handleSaveEmployeeSingle}
              dataParent={employeeSelected[0]?.listEmployee || []}
            />
          )}

          {shouldOpenAddFormOfDialog && (
            <AddAttributeValueDialog
              onClose={handleCloseAttributeDialog}
              handleSaveItem={handleSaveFormOfItem}
              title={"GENERAL.ADD_FORM_OF_BONUS"}
              typeContent={TYPE_CATEGORY.hinhThucKhenThuong}
            />
          )}
        </Row>
      </div>
    </Form>
  );
};
export default BonusDialog;

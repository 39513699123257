import TextValidator from "../../../components/input/text-validator";
import { Col, Form, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { INIT_HOUSING } from "../../constants/initValues";
import { IFormProps, IHousing } from "../../models/declareAssetsModels";
import { useEffect } from "react";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import Autocomplete from "../../../components/input/autocomplete";
import { TYPE, TYPE_CATEGORY } from "../../../constants/moduleConsts";
import { convertNumberPrice, convertTextPrice } from "../../../utils/functionUtils";
import { searchAllSimpleValue } from "../../../services/services";
import UploadFile from "../../../components/file-upload/UploadFile";
import { IFile } from "../../../profile/models/dialogModels";

export function HousingForm(props: IFormProps<IHousing>) {
  const { handleSubmit, isView, btnSubmitRef, dataEdit } = props;
  const { lang } = useMultiLanguage();

  useEffect(() => {
    if (dataEdit?.taiSanId) {
      formik.setValues({
        ...dataEdit,
        loaiNhaObj: { value: dataEdit?.loaiNha || "" },
        loaiObj: { value: dataEdit?.loai }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataEdit]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
    loaiObj: Yup.object().required(lang("VALIDATION.REQUIRE")).nullable()
  });

  const formik = useFormik({
    initialValues: INIT_HOUSING,
    onSubmit: handleSubmit,
    validationSchema
  });

  const handleChangeSelect = (name: string, value: any) => {
    switch (name) {
      case "loaiObj":
        formik.setValues({ ...formik.values, [name]: value, loai: value?.value || "" });
        break;
      case "loaiNhaObj":
        formik.setValues({ ...formik.values, [name]: value, loaiNha: value?.value || "" });
        break;
      default:
        formik.setFieldValue(name, value);
        break;
    }
  };

  const handleChangeMoney = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, convertTextPrice(value));
  };

  const handleFile = (data: IFile) => {
    formik.setFieldValue("fileId", data.id);
    formik.setFieldValue("fileName", data.name);
  }

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row>
        <Col xs={6} className="spaces mt-10">
          <Autocomplete
            isRequired
            lable={lang("VACCINATION.TYPE")}
            name="loaiObj"
            value={formik.values?.loaiObj}
            options={[]}
            searchObject={{ type: TYPE_CATEGORY.loaiCongTrinh }}
            searchFunction={searchAllSimpleValue}
            menuPlacement="bottom"
            getOptionLabel={(option) => option?.value}
            isReadOnly={isView}
            onChange={(value) => handleChangeSelect("loaiObj", value)}
            errors={formik.errors?.loaiObj}
            touched={formik.touched?.loaiObj}
          />
        </Col>
        <Col xs={6} className="spaces mt-10">
          <TextValidator
            isRequired
            lable={lang("GENERAL.NAME")}
            name="name"
            readOnly={isView}
            type="text"
            value={formik.values?.name}
            onChange={formik.handleChange}
            errors={formik.errors?.name}
            touched={formik.touched?.name}
          />
        </Col>
        <Col xs={6} className="spaces mt-10">
          <Autocomplete
            lable={lang("DECLARE_ASSETS.HOUSE_TYPE")}
            name="loaiNhaObj"
            value={formik.values?.loaiNhaObj}
            options={[]}
            searchObject={{ type: TYPE_CATEGORY.loaiNha }}
            searchFunction={searchAllSimpleValue}
            menuPlacement="bottom"
            getOptionLabel={(option) => option?.value}
            isReadOnly={isView}
            onChange={(value) => handleChangeSelect("loaiNhaObj", value)}
            errors={formik.errors?.loaiNhaObj}
            touched={formik.touched?.loaiNhaObj}
          />
        </Col>
        <Col xs={6} className="spaces mt-10">
          <TextValidator
            lable={lang("DECLARE_ASSETS.ACREAGE")}
            name="dienTich"
            readOnly={isView}
            type="text"
            value={convertNumberPrice(formik.values?.dienTich)}
            onChange={handleChangeMoney}
            errors={formik.errors?.dienTich}
            touched={formik.touched?.dienTich}
          />
        </Col>
        <Col xs={6} className="spaces mt-10">
          <TextValidator
            lable={lang("ALLOWANCE.VALUE")}
            name="giaTri"
            readOnly={isView}
            type="text"
            value={convertNumberPrice(formik.values?.giaTri)}
            onChange={handleChangeMoney}
            errors={formik.errors?.giaTri}
            touched={formik.touched?.giaTri}
          />
        </Col>
        <Col xs={6} className="spaces mt-10">
          <UploadFile
            label="DECLARE_ASSETS.CERTIFATE_LAND_USE"
            setValue={handleFile}
            isReadOnly={isView}
            fileValue={{
              id: formik.values.fileId || "",
              name: formik.values.fileName || ""
            }}
          />
        </Col>
        <Col xs={12} className="spaces mt-10">
          <TextValidator
            lable={lang("GENERAL.ADDRESS")}
            name="diaChi"
            readOnly={isView}
            type="text"
            value={formik.values?.diaChi}
            onChange={formik.handleChange}
            errors={formik.errors?.diaChi}
            touched={formik.touched?.diaChi}
          />
        </Col>
        <Col xs={12} className="spaces mt-10">
          <TextValidator
            lable={lang("INFORMATION.OTHER")}
            name="thongTinKhac"
            readOnly={isView}
            type="text"
            as={TYPE.TEXTAREA}
            rows={2}
            value={formik.values?.thongTinKhac}
            onChange={formik.handleChange}
            errors={formik.errors?.thongTinKhac}
            touched={formik.touched?.thongTinKhac}
          />
        </Col>
      </Row>
      <button ref={btnSubmitRef} type="submit" className="hidden"></button>
    </Form>
  );
}

import { IItemSearch, IWeekDay, OptionReactSelect } from "../models/models"

export const RESPONSE_STATUS_CODE = {
  SUCCESS: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  CONFLICT: 409,
  INTERNAL_SERVER_ERROR: 500,
  BAD_GATEWAY: 502,
}

export const inputTypeList = {
  text: 'text',
  select: 'select',
  date: 'date',
  number: 'number',
  file: 'file',
  checkbox: 'checkbox'
};

export const TYPE_CATEGORY = {
  cap: 1,
  bac: 2,
  chucVuDang: 3,
  chucVuDoan: 4,
  chuyenNganh: 5,
  phuCap: 6,
  danToc: 7,
  chucDanh: 8,
  phongBan: 9,
  nganHang: 10,
  binhChung: 11,
  chungChi: 12,
  tonGiao: 13,
  capBacQuanSu: 14,
  chucVuQuanSu: 15,
  hangThuongBenhBinh: 16,
  phongBenh: 17,
  viTriCongViec: 28,
  noiDaoTao: 31,
  donVi: 100,
  nhomChungChi: 34,
  trinhDoDaoTao: 35,
  quanHeGiaDinh: 36,
  kyNangMem: 37,
  trinhDoNgoaiNgu: 38,
  trinhDoTinHoc: 39,
  trinhDoQuanLyNhaNuoc: 40,
  trinhDoLyLuan: 41,
  hinhThucKhenThuong: 42,
  danhHieu: 43,
  loaiThuTuc: 44,
  loaiDieuDong: 45,
  phanLoaiBaoCao: 46,
  kieuDat: 53,
  loaiCongTrinh: 54,
  loaiNha: 55,
  taiSanGanLienVoiDat: 56,
  loaiGiayToCoGiaTri: 57,
  loaiTaiSanKhac: 58,
  bienDongTaiSan: 59,
  kiHieu: 60,
  loaiTruc: 62
};

export const CODE_GROUP_CERTIFICATE = {
  HANH_NGHE: "HN",
  NGOAI_NGU: "NN",
  TIN_HOC: "TH",
  KHAC: "OTHER"
}

export enum DATA_WORD_TYPES {
  SALARY_USULLY = 1,
  CONSTRACT = 2,
  TRANSFER_STAFF = 3,
  APPOINTMENT = 4,
  CONCURRENTLY = 6,
  CONSTRACT_ANNEX = 9,
  RESIGN = 10,
  RECEIVING_CITIZEN = 13,
  SEND_PEOPLE_TRAINING = 14,
  ATTENDANCE_WATCH = 1,
}

export enum TYPE {
  OBJECT = "object",
  STATUS = "status",
  STRING = "string",
  TEXT = "text",
  NUMBER = "number",
  INPUT = "input",
  DATE = "date",
  TEXTAREA = "textarea",
  SELECT = "select",
  SINGLE = "single",
  MULTILINE = "multiline",
  PASSWORD = "password",
  EXCEL = "EXCEL",
  WORD = "WORD",
  MONEY = "MONEY",
  NUMBER_FLOAT = "NUMBER_FLOAT"
}

export enum EXTENSIONS {
  EXCEL = "xlsx",
  WORD = "docx",
}

export const REGEX = {
  TEN: /^[^~`!@#$%^&*()+=\-[\]\\';,/{}|\\":._<>?\d]+$/,
  AZ_09: /^[a-zA-Z0-9]*$/,
  CHARACTER20: /^.{6,20}$/,
  CHARACTER9or12: /^\d{9}(\d{3})?$/,
  CHARACTER10or13: /^\w{10}(\w{3})?$/,
  CHARACTER50: /^.{1,50}$/,
  CHARACTER255: /^.{1,255}$/,
  CHECK_PHONE: /^(0|\+84)\d{9,10}$/,
  YEAR: /^.{4,5}$/,
  PERCENTAGE: /^(-?\d{1,4})(\.\d{1,2})?$/,
  MA_SO_THUE: /^[0-9]{10}$/,
  NUMBER: /^[0-9]+$/,
  NOT_ZERO: /^[0-9]*[1-9][0-9]*$/
}
export const DATE = {
  MAX_DATE: new Date(9999, 12, 31),
  MIN_DATE: new Date(1900, 0, 1),
}

export enum FORMAT_DATE {
  HH_MM = "HH:mm",
  DD_MM_YYYY = "DD/MM/YYYY",
  YYYY_MM_DD_INPUT = "YYYY-MM-DD",
  HH_MM_DD_MM_YYYY = "HH:mm DD/MM/YYYY",
  HH_MM_SS_DD_MM_YYYY = "HH:mm:ss DD/MM/YYYY",
  YYYY_MM_DD_T_HH_MM_SS = "YYYY-MM-DDTHH:mm:ss",
  DD = "DD",
  MM = "MM",
  YYYY = "YYYY"
}

export const LIST_WEEKDAY: IWeekDay[] = [
  {
    dayOfWeek: 1,
    name: "WEEKDAY.MONDAY"
  },
  {
    dayOfWeek: 2,
    name: "WEEKDAY.TUESDAY"
  },
  {
    dayOfWeek: 3,
    name: "WEEKDAY.WEDNESDAY"
  },
  {
    dayOfWeek: 4,
    name: "WEEKDAY.THURSDAY"
  },
  {
    dayOfWeek: 5,
    name: "WEEKDAY.FRIDAY"
  },
  {
    dayOfWeek: 6,
    name: "WEEKDAY.SATURDAY"
  },
  {
    dayOfWeek: 7,
    name: "WEEKDAY.SUNDAY"
  }
];

export const NUMBER_EXCEPT_THIS_SYMBOLS = ["e", "E", "+", "-", "."]
export const JUST_ALLOW_NUMBER = ["e", "E", "+", "-", ".", ","]
export const ROWS_FOR_PAGE = [1, 2, 3, 5, 10, 15, 20, 50, 100]
export const DEFAULT_PAGE_INDEX = 1
export const DEFAULT_PAGE_SIZE = 10
export const DEFAULT_TOTAL_PAGES = 0
export const DEFAULT_TOTAL_ELEMENTS = 0
export const MAX_PAGE_SIZE = 99999

export const INIT_SEARCH_OBJECT = {
  pageIndex: 1,
  pageSize: 10
}

export const SEARCH_OBJECT_MAX_SIZE = {
  pageIndex: DEFAULT_PAGE_INDEX,
  pageSize: MAX_PAGE_SIZE,
}

export const KEY = {
  ENTER: 'Enter',
  SPACE: 'Space',
}

export const VARIABLE_STRING = {
  PAGE_SIZE: "pageSize",
  PAGE_INDEX: "pageIndex",
  CHUYEN_NGANH: "chuyenNganh",
  CHUYEN_NGANH_KHAC: "chuyenNganhKhac",
  NOI_DAO_TAO: "noiDaoTao",
  CHUYEN_NGANH_ID: "chuyenNganhId",
  NOI_DAO_TAO_ID: "noiDaoTaoId",
  QUOC_TICH_ID: "quocTichId",
  DAN_TOC_ID: "danTocId",
  TON_GIAO_ID: "tonGiaoId",
  HK_QUOC_GIA: "hkQuocGia",
  HK_TINH: "hkTinh",
  HK_HUYEN: "hkHuyen",
  HK_XA: "hkXa",
  HK_SO_NHA: "hkSoNha",
  HN_QUOC_GIA: "hnQuocGia",
  HN_TINH: "hnTinh",
  HN_HUYEN: "hnHuyen",
  HN_XA: "hnXa",
  HN_SO_NHA: "hnSoNha",
  TT_TINH: "ttTinh",
  TT_HUYEN: "ttHuyen",
  TT_XA: "ttXa",
  TRINH_DO_DAO_TAO: "trinhDoDaoTao",
  NHOM_CHUNG_CHI: "nhomChungChi",
  DON_VI_CONG_TAC: "donViCongTac",
  DON_VI_CONG_TAC_ID: "donViCongTacId",
  DON_VI_CONG_TAC_TEXT: "donViCongTacText",
  PHONG_BAN: "phongBan",
  PHONG_BAN_TEXT: "phongBanText",
  PHONG_BAN_KHAC: "phongBanKhac",
  VI_TRI_CONG_VIEC: "viTriCongViec",
  VI_TRI_CONG_VIEC_ID: "viTriCongViecId",
  CHUC_DANH: "chucDanh",
  CAP: "cap",
  BAC: "bac",
  TRANG_THAI_LAO_DONG: "trangThaiLaoDong",
  LOAI_HOP_DONG: "loaiHopDong",
  NGUOI_QUAN_LY: "nguoiQuanLy",
  NGAN_HANG: "nganHang",
  TINH_DANG_KY_KCB: "tinhDangKyKhamChuaBenh",
  NOI_Dang_KY_KCB: "noiDangKyKcb",
  MA_NOI_Dang_KY_KCB: "maSoNoiDangKyKcb",
  DOAN_VIEN: "doanVien",
  DANG_VIEN: "dangVien",
  NGOAI_CHI_BO_QUAN_LY: "ngoaiChiBoQuanLy",
  HIEN_NAY_DA_ROI_DANG: "hienNayDaRoiDang",
  CHUC_VU_DOAN: "chucVuDoan",
  CHUC_VU: "chucVu",
  CHUC_VU_ID: "chucVuId",
  CHUC_VU_DANG: "chucVuDang",
  CHUC_VU_MOI: "chucVuMoi",
  CHUC_VU_MOI_KHAC: "chucVuMoiKhac",
  CHUC_VU_MOI_ID: "chucVuMoiId",
  CHUC_VU_MOI_TEXT: "chucVuMoiText",
  HN_GIONG_HO_KHAU: "hnGiongHoKhau",
  HK_LA_CHU_HO: "hkLaChuHo",
  TINH_CHAT_LAO_DONG: "tinhChatLaoDong",
  BINH_CHUNG: "binhChung",
  CAP_BAC_QUAN_SU: "capBacQuanSu",
  CHUC_VU_QUAN_SU: "chucVuQuanSu",
  HANG_THUONG_BINH: "hangThuongBenhBinh",
  NHOM_MAU: "nhomMau",
  QUOC_TICH: "quocTich",
  HK_DIA_CHI: "hkDiachi",
  HN_DIA_CHI: "hnDiachi",
  TT_DIA_CHI: "ttDiachi",
  NGAY_NGHI_VIEC: "ngayNghiViec",
  LY_DO_NGHI: "lyDoNghi",
  THANG_KHAU_TRU_OPTION: "thangKhauTruOption",
  NAM_KHAU_TRU_OPTION: "namKhauTruOption",
  QQ_TINH: "queQuanTinh",
  QQ_HUYEN: "queQuanHuyen",
  QQ_XA: "queQuanXa",
  NS_TINH: "noiSinhTinh",
  NS_HUYEN: "noiSinhHuyen",
  NS_XA: "noiSinhXa",
  QQ_DCCT: "queQuanDiaChiChiTiet",
  NS_DCCT: "noiSinhDiaChiChiTiet",
  NGAY_HET_HAN: "ngayHetHan",
  NGAY_CO_HIEU_LUC: "ngayCoHieuLuc",
  NGUOI_DAI_DIEN_KY: "nguoiDaiDienCtyKy",
  EMPLOYEE: "employee",
  EMPLOYEE_DANG_KY: "employeeDangKy",
  EMPLOYEE_CODE: "employeeCode",
  VIEN_CHUC: "vienChuc",
  HOP_DONG_LAO_DONG: "hopDongLaoDong",
  DON_VI_KY_HOP_DONG: "donViKyHopDong",
  DON_VI: "donVi",
  CA_LAM_VIEC: "caLamViec",
  TRANG_THAI: "trangThai",
  TRANG_THAI_KY: "trangThaiKy",
  NOI_DAO_TAO_KHAC: "noiDaoTaoKhac",
  CO_SO_DAO_TAO: "coSoDaoTao",
  CO_SO_DAO_TAO_KHAC: "coSoDaoTaoKhac",
  DON_VI_CONG_TAC_KHAC: "donViCongTacKhac",
  DON_VI_CONG_TAC_MOI: "donViCongTacMoi",
  DON_VI_CONG_TAC_MOI_ID: "donViCongTacMoiId",
  VI_TRI_CONG_TAC_MOI: "viTriCongTacMoi",
  VI_TRI_CONG_TAC_MOI_ID: "viTriCongTacMoiId",
  LOAI_CAN_BO: "loaiCanBo",
  LOAI_LUONG: "loaiLuong",
  VIET_NAM: "Việt Nam",
  HE_SO_LUONG: "heSoLuong",
  HE_SO_BAC_LUONG: "heSoBacLuong",
  LUONG_CO_BAN: "luongCoBan",
  BAC_LUONG: "bacLuong",
  BAC_LUONG_OPTION: "bacLuongOption",
  TRANG_THAI_HOP_DONG: "trangThaiHopDong",
  UNEXPORED: "unexpired",
  DUE: "due",
  OUT_OF_DATE: "outOfDate",
  FEMALE: "female",
  MALE: "male",
  NO_INFO: "noInfo",
  QUOC_GIA: "nationality",
  QUAN_HE_NV: "quanHeNV",
  QUAN_HE_NV_ID: "quanHeNVId",
  QUAN_HE_NV_TEXT: "quanHeNVText",
  HOP_DONG_GOC: "hopDongGoc",
  NGAY_HUONG_LUONG_TU: "ngayHuongLuongTuNgay",
  NGAY_HUONG_LUONG_DEN: "ngayHuongLuongDenNgay",
  GENDER: "gender",
  CANH_BAO_HET_HAN_HOP_DONG: "canhBaoHetHanHopDong",
  CANH_BAO_DEN_HAN_NANG_LUONG: "canhBaoHetHanNangLuong",
  CANH_BAO_NANG_LUONG_TRUOC_HAN: "canhBaoNangLuongTruocHan",
  CANH_BAO_NHAN_SU_DEN_TUOI_NGHI_HUU: "canhBaoNhanSuDenTuoiNghiHuu",
  CANH_BAO_DEN_HAN_VAN_BANG_CHUNG_CHI: "canhBaoDenHanVanBangChungChi",
  CANH_BAO_HET_HAN_LUAN_CHUYEN: "canhBaoHetHanLuanChuyen",
  NGANH_DAO_TAO: "nganhDaoTao",
  NGANH_DAO_TAO_KHAC: "nganhDaoTaoKhac",
  CHUYEN_NGANH_DAO_TAO: "chuyenNganhDaoTao",
  CHUYEN_NGANH_DAO_TAO_KHAC: "chuyenNganhDaoTaoKhac",
  NUOC_DAO_TAO: "nuocDaoTao",
  NUOC_DAO_TAO_KHAC: "nuocDaoTaoKhac",
  CAP_CHUNG_CHI: "capChungChi",
  VAN_BANG: "vanBang",
  NGAY_CAP: "ngayCap",
  DON_VI_CAP: "donViCap",
  HIEU_LUC_BAT_DAU: "hieuLucBatDau",
  HIEU_LUC_KET_THUC: "hieuLucKetThuc",
  CHUNG_CHI_QUOC_TE: "chungChiQuocTe",
  KHOA_PHONG: "khoaPhong",
  KHOA_PHONG_ID: "khoaPhongId",
  PHONG_BAN_MOI: "phongBanMoi",
  PHONG_BAN_MOI_KHAC: "phongBanMoiKhac",
  KHOA_PHONG_MOI_ID: "phongBanMoiId",
  PHONG_BAN_MOI_TEXT: "phongBanMoiText",
  HIEU_LUC_TU_NGAY: "hieuLucTuNgay",
  HIEU_LUC_DEN_NGAY: "hieuLucDenNgay",
  PHIEU_LUONG_XAC_NHAN: "phieuLuongXacNhan",
  BO_SUNG_HO_SO: "boSungHoSo",
  LOAI_THOI_VIEC: "loaiThoiViec",
  LOAI_THOI_VIEC_BAO_HIEM: "loaiThoiViecBH",
  TRANG_THAI_BAO_HIEM: "trangThaiBaoHiem",
  LOAI_NGHI: "loaiNghi",
  LOAI_GIAN_DOAN: "loaiGianDoan",
  HINH_THUC_DIEU_CHINH: "hinhThucDieuChinh",
  LOAI_BO_NHIEM: "loaiBoNhiem",
  DOT_KHEN_THUONG: "doiTuongKhenThuong",
  LOAI_KHEN_THUONG: "loaiKhenThuong",
  HINH_THUC_KHEN_THUONG: "hinhThucKhenThuong",
  DANH_HIEU_THI_DUA: "danhHieuThiDua",
  DANH_HIEU_THI_DUA_KHAC: "danhHieuThiDuaKhac",
  CAP_QUYET_DINH: "capQuyetDinh",
  DON_VI_BAN_HANH: "donViBanHanh",
  LOAI_KY_LUAT: "loaiKyLuat",
  HINH_THUC_KY_LUAT: "hinhThucKyLuat",
  NGUOI_KY_QUYET_DINH: "nguoiKyQuyetDinh",
  CO_QUAN_QUYET_DINH: "coQuanQuyetDinh",
  CHUYEN_NGANH_HOC_HAM_KHAC: "chuyenNganhHocHamKhac",
  CHUYEN_NGANH_HOC_HAM: "chuyenNganhHocHam",
  HOC_HAM: "hocHam",
  DANH_HIEU: "danhHieu",
  CHINH_SACH: "chinhSach",
  HANG_THUONG_BINH2: "hangThuongBinh",
  HINH_THUC_KHEN_THUONG_ID: "hinhThucKhenThuongId",
  DOI_TUONG_KHEN_THUONG: "doiTuongKhenThuong",
  NHAN_VIEN_ID: "employeeId",
  CO_SO_SO_BHXH: "coSoBhxhHayChua",
  SO_SO_BHXH: "soSoBhxh",
  TRANG_THAI_THAM_GIA_BAO_HIEM: "trangThaiThamGiaBaoHiem",
  LUONG_THEO_HE_SO: "luongTheoHeSo",
  PHU_CAP_THAM_NIEN_NGHE: "phuCapThamNienNghe",
  PHU_CAP_THAM_NIEN_VUOT_KHUNG: "phuCapThamNienVuotKhung",
  MUC_LUONG: "mucLuong",
  PHU_CAP_LUONG: "phuCapLuong",
  CAC_KHOAN_BO_SUNG: "cacKhoanBoSung",
  DON_VI_THAM_GIA_BHXH: "donViThamGiaBhxh",
  CONG_TAC_VIEN_NAME: "Cộng tác viên",
  YEAR: "year",
  NAM: "nam",
  MONTH: "month",
  THANG: "thang",
  QUY: "quy",
  HUONG_LUONG_TAP_SU: "Hưởng lương tập sự",
  LOAI_DIEU_CHINH_LUONG: "loaiDieuChinhLuong",
  ANH_HUONG_THOI_GIAN_DIEU_CHINH_LUONG: "ahtgDieuChinhLuong",
  NHOM_PHUC_LOI: "nhomPhucLoi",
  DON_VI_AP_DUNG: "donViApDung",
  HINH_THUC_THUC_HIEN: "hinhThucThucHien",
  TEN_NGUOI_DUYET: "tenNguoiDuyet",
  NGUOI_DUYET: "nguoiDuyet",
  QUAN_HE: "quanHe",
  NGAY_HIEU_LUC: "ngayHieuLuc",
  THOI_GIAN_HIEU_LUC_TU_NGAY: "thoiGianHieuLucTuNgay",
  USER_ROLES: "user.roles",
  DEN_NGAY: "denNgay",
  TU_NGAY: "tuNgay",
  DOI_TUONG: "doiTuong",
  PHAN_CONG_TYPE: "phanCongType",
  THOI_GIAO_NHIEM_VU_TYPE: "thoiGiaoNhiemVuType",
  KHOA_PHONG_NHAN_QD: "khoaPhongNhanQuyetDinh",
  NOI_DUNG_NHIEM_VU: "noiDungNhiemVu",
  TRUC_CHINH: "trucChinh",
  THUONG_TRU: "thuongTru",
  LOAI_TRUC: "loaiTruc",
  LOAI_DOI: "loaiDoi",
  LOAI_KE_KHAI: "loaiKeKhai",
  CANH_BAO_HET_HAN_GIAN_DOAN: "canhBaoHetHanGianDoan",
  CANH_BAO_HET_HAN_CU_DAO_TAO: "canhBaoHetHanCuDaoTao",
}

export const SELECTION_MODE = {
  SINGLE: "single",
  MULTIPLE: "multiple"
}

export const COUNTRY = {
  CODE: {
    VIET_NAM: "VN"
  }
}

export const LIST_MONTH: OptionReactSelect[] = [
  {
    code: 1,
    name: "Tháng 1",
  },
  {
    code: 2,
    name: "Tháng 2"
  },
  {
    code: 3,
    name: "Tháng 3"
  },
  {
    code: 4,
    name: "Tháng 4"
  },
  {
    code: 5,
    name: "Tháng 5"
  },
  {
    code: 6,
    name: "Tháng 6"
  },
  {
    code: 7,
    name: "Tháng 7"
  },
  {
    code: 8,
    name: "Tháng 8"
  },
  {
    code: 9,
    name: "Tháng 9"
  },
  {
    code: 10,
    name: "Tháng 10"
  },
  {
    code: 11,
    name: "Tháng 11"
  },
  {
    code: 12,
    name: "Tháng 12"
  },
]

//khi có 3 trạng thái là chưa thực hiện, đang thực hiện, đã thực hiện
export enum MISSION_STATUS_CODE {
  UNFULFILLED = 1,
  PROCESSING = 2,
  COMPLETED = 3
};

export const MISSION_STATUS = [
  {
    name: "Chưa thực hiện",
    code: MISSION_STATUS_CODE.UNFULFILLED,
    styleClass: "bg-gray-700"
  },
  {
    name: "Đang thực hiện",
    code: MISSION_STATUS_CODE.PROCESSING,
    styleClass: "bg-orange-dark"
  },
  {
    name: "Đã hoàn thành",
    code: MISSION_STATUS_CODE.COMPLETED,
    styleClass: "bg-green"
  }
];

//khi có 2 trạng thái là chưa thực hiện , đã thực hiện
export enum COMPLETED_STATUS_CODE {
  UNFULFILLED = 1,
  COMPLETED = 2,
}

export const COMPLETED_STATUS = [
  {
    name: "Chưa thực hiện",
    code: COMPLETED_STATUS_CODE.UNFULFILLED,
    styleClass: "bg-gray-700"
  },
  {
    name: "Đã hoàn thành",
    code: COMPLETED_STATUS_CODE.COMPLETED,
    styleClass: "bg-green"
  },
];

export const NAME_EVENT_LISTENER = {
  REMINDER_NOTIFY: "reminder_notify" //nhắc nhở
}

export const EXPORT_FILE_NAME = {
  KHEN_THUONG: 'Danh sách khen thưởng',
  CHI_TIET_KHEN_THUONG: 'Chi tiết khen thưởng',
  HOP_DONG: 'Danh sách hợp đồng',
  PHU_CAP: 'Danh sách các khoản phụ cấp',
  PHU_LUC_HD: 'Danh sách phụ lục hợp đồng',
  MIEN_NHIEM: 'Danh sách miễn nhiệm',
  BO_NHIEM: 'Danh sách bổ nhiệm',
  LUAN_CHUYEN: 'Danh sách luân chuyển',
  SU_CO: 'Danh sách sự cố',
  KY_LUAT: 'Danh sách kỷ luật',
  HO_SO: 'Hồ sơ nhân viên',
  TEP_DINH_KEM: 'Danh sách các tệp đính kèm',
  KIEM_NHIEM: 'Danh sách kiêm nhiệm',
  QUAN_HE_GIA_DINH: 'Danh sách quan hệ gia đình',
  LICH_SU_LUONG: 'Danh sách lịch sử lương',
  GIAY_TO: 'Danh sách giấy tờ liên quan',
  TIEM_CHUNG: 'Danh sách lịch sử tiêm',
  KINH_NGHIEM_LAM_VIEC: 'Danh sách kinh nghiệm làm việc',
  QUA_TRINH_CONG_TAC: 'Danh sách quá trình công tác',
  BANG_CAP: 'Danh sách bằng cấp',
  KHAU_TRU: 'Danh sách các khoản khấu trừ',
  DIEN_BIEN_LUONG: 'Danh sách diễn biến lương',
  DE_XUAT: 'Danh sách đề xuất',
  TIEP_NHAN: 'Danh sách tiếp nhận',
  BAO_CAO: 'Danh sách báo cáo',
  GIAN_DOAN: 'Danh sách gián đoạn',
  NGHI_VIEC: 'Danh sách nghỉ việc',
  PHUC_LOI: 'Danh sách phúc lợi',
  CHI_TIET_PHUC_LOI: 'Chi tiết phúc lợi',
  CHUNG_CHI: 'Danh sách chứng chỉ',
  LY_LUAN_CHINH_TRI: 'Danh sách trình độ lý luận chính trị',
  QUA_TRINH_BOI_DUONG: 'Danh sách quá trình bồi dưỡng',
  CA_LAM_VIEC: 'Danh sách ca làm việc',
  BANG_CHAM_CONG: 'Bảng chấm công',
  SEND_PEOPLE_TRAINING: 'Danh sách cử người đi đào tạo',
  SOFTWARE_USAGE: 'Thống kê nhập liệu',
}

export const INIT_INPUT_SEARCH: IItemSearch = {
  name: "",
  type: TYPE.TEXT,
  field: "",
  value: null
};

export const TYPE_REF = {
  INPUT_REF: "INPUT_REF",
  AUTOCOMPLETE_REF: "AUTOCOMPLETE_REF",
  DATE_PICKER_REF: "DATE_PICKER_REF",
}

export const WEEKDAY = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 7
}

export const LIST_OFFICE_WEEKDAY = [WEEKDAY.MONDAY, WEEKDAY.TUESDAY, WEEKDAY.WEDNESDAY, WEEKDAY.THURSDAY, WEEKDAY.FRIDAY]

import { CODE_STATUS, LIST_STATUS } from "../../manager-request/constants/managerRequestConsts";
import { IOfficeWorkSchedule } from "../models/officeWorkScheduleModels";

export const INIT_OFFICE_WORK_SCHEDULE: IOfficeWorkSchedule = {
  year: new Date().getFullYear(),
  month: new Date().getMonth() + 2,
  approvalDate: null,
  approver: null,
  approverId: "",
  approverText: "",
  detailSchedule: [],
  donViCongTac: null,
  donViCongTacId: "",
  donViCongTacText: "",
  phongBan: null,
  phongBanId: "",
  phongBanText: "",
  status: LIST_STATUS.find((status) => status.code === CODE_STATUS.TAO_MOI) || LIST_STATUS[0]
}
import { Col, Row } from "react-bootstrap";
import TextUnderline from "../../../components/TextUnderline";
import { ValueOrDots } from "../../../components/exportWord/partials";
import { TYPE } from "../../../constants/moduleConsts";
import { IPropsExportWord } from "../../../components/exportWord";

export function ContractCommonWord({ data }: IPropsExportWord) {
  return (
    <div className="word spaces px-70 colors-word">
      <div className="spaces text-center flex flex-between align-start">
        <div className="fs-lg">
          <p>{data.soYTeHeader}</p>
          <p className="fw-600 text-uppercase">
            <span className="header-underline fs-lg">
              <ValueOrDots className="fs-lg" value={data.donViKyHopDongText} size={70} />
            </span>
          </p>
          <p className="spaces mt-16 fs-md">
            Số: <ValueOrDots value={data.soHopDong} />
            /HĐLĐ
          </p>
        </div>
        <div className="fw-600 fs-lg">
          <p>CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM</p>
          <p>
            <span className="header-underline fs-lg">Độc lập - Tự do - Hạnh phúc</span>
          </p>
        </div>
      </div>
      <div className="text-center spaces py-20 fw-600">
        <p className="fs-xl">HỢP ĐỒNG LAO ĐỘNG</p>
        <p className="fs-lg">THỰC HIỆN CÔNG VIỆC CHUYÊN MÔN, NGHIỆP VỤ</p>
      </div>
      <div className="text-indent-40 text-italic text-justify">
        <p>Căn cứ Luật Khám bệnh, chữa bệnh;</p>
        <p>Căn cứ Bộ luật lao động số 45/2019/QH14 ngày 20/11/2019; Nghị định 145/2020/NĐ-CP ngày 14/12/2020 của Chính phủ;</p>
        <p>{data?.quyetDinhHopDong ?? ""}</p>
        <p>Căn cứ nhu cầu và khả năng thực tế của các bên trong hợp đồng.</p>
      </div>
      <div>
        <p className="text-indent-40">
          Hôm nay, ngày <ValueOrDots value={data.ngayKyHopDong} /> tại{" "}
          <ValueOrDots value={data.donViKyHopDongText} size={85} />, chúng tôi gồm các bên dưới đây:
        </p>
        <p className="fw-600">BÊN A: NGƯỜI SỬ DỤNG LAO ĐỘNG</p>
        <div className="spaces pl-40">
          <p>
            <TextUnderline readOnly label="Đơn vị" value={data.donViKyHopDongText} />
          </p>
          <p>Địa chỉ: {data?.unitAddress ?? ""}</p>
          <p>Điện thoại: {data?.unitPhone ?? ""}</p>
          <p>Mã số thuế: {data?.unitFax ?? ""}</p>
          <p>
            <TextUnderline
              readOnly
              label="Đại diện theo pháp luật (hoặc người được ủy quyền): Ông/bà"
              value={data.nguoiDaiDienCtyKyText}
              noColon
            />
          </p>
          <p>
            <TextUnderline readOnly label="Chức vụ" value={data.nguoiDaiDienCtyChucDanhText} />
          </p>
          <p className="fw-600 text-italic">(Sau đây gọi tắt là “Bên A” hoặc “Bệnh viện”)</p>
        </div>
        <p className="fw-600">BÊN B: NGƯỜI LAO ĐỘNG</p>
        <div className="spaces pl-40">
          <TextUnderline readOnly label="Ông/bà" value={data.fullName} />
          <Row className="flex">
            <Col xs={4}>
              <TextUnderline readOnly label="Sinh ngày" value={data.birthdate} type={TYPE.DATE} />
            </Col>
            <Col xs={8}>
              <TextUnderline readOnly label="Tại" value={data.noiSinh} />
            </Col>
          </Row>
          <TextUnderline readOnly label="Giới tính" value={data.gender?.name} />
          <TextUnderline readOnly label="Hộ khẩu thường trú" value={data.hoKhauThuongTru} />
          <TextUnderline readOnly label="Điện thoại" value={data.phone} />
          <TextUnderline readOnly label="Tài khoản ngân hàng" value={data.tkNganHang} />
          <TextUnderline readOnly label="Nơi mở tài khoản" value={data.chiNhanh} />
          <TextUnderline readOnly label="Email (nếu có)" value={data.email} />
          <p className="flex">
            <TextUnderline readOnly label="Số CCCD/Hộ chiếu" value={data.soCMNDOrCCCD} />
            <TextUnderline readOnly label="Cấp ngày" value={data.ngayCapCMNDOrCCCD} type={TYPE.DATE} />
          </p>
          <TextUnderline readOnly label="Tại" value={data.noiCapCMNDOrCCCD} />
          <TextUnderline readOnly label="Trình độ đào tạo" value={data.trinhDoChuyenMon} />
          <TextUnderline readOnly label="Chuyên ngành đào tạo" value={data.chuyenNganhDaoTao} />
          <TextUnderline readOnly label="Trình độ tin học" value={data.trinhDoTinHocCaoNhat} />
          <TextUnderline readOnly label="Trình độ ngoại ngữ" value={data.trinhDoNgoaiNguCaoNhat} />
          <TextUnderline
            readOnly
            label="Chứng chỉ hành nghề (nếu có)"
            value={(data.tenChungChi ?? "") + (data.chuyenNganhDaoTao ?? "")}
          />
          <p>
            <TextUnderline
              className="spaces px-0"
              readOnly
              label="Các thông tin khác theo yêu cầu của vị trí việc làm ký kết hợp đồng lao động"
              rows={2}
            />
          </p>
        </div>
        <p>Hai bên thỏa thuận ký kết hợp đồng lao động và cam kết thực hiện những nội dung sau đây:</p>
      </div>
      <div>
        <p className="fw-600">Điều 1. Công việc, vị trí việc làm và thời hạn hợp đồng</p>
        <p className="fw-600">1.1. Thời hạn hợp đồng</p>
        <div className="spaces pl-35">
          <p>
            a. Bên A và Bên B thỏa thuận ký kết hợp đồng xác định thời hạn, cụ thể như sau: Thời hạn của hợp đồng lao
            động: <ValueOrDots value={data.thoiHanHopDong} /> tháng, kể từ ngày{" "}
            <ValueOrDots value={data.ngayCoHieuLuc} /> đến <ValueOrDots value={data.ngayHetHan} /> trong
            đó không bao gồm thời gian thử việc.
          </p>
          <p>b. Khi hợp đồng lao động này hết hạn mà Bên B vẫn tiếp tục làm việc thì thực hiện như sau:</p>
          <p>
            - Trong thời hạn 30 ngày kể từ ngày hợp đồng lao động này hết hạn, Bên A và Bên B phải thỏa thuận để ký kết
            hợp đồng lao động mới; trong thời gian chưa ký kết hợp đồng lao động mới thì quyền, nghĩa vụ và lợi ích của
            hai bên được thực hiện theo hợp đồng này.
          </p>
          <p>
            - Trường hợp Bên A và Bên B tiếp tục thỏa thuận ký kết hợp đồng lao động mới là hợp đồng xác định thời hạn
            thì chỉ được ký thêm 01 lần. Hết thời hạn ký kết hợp đồng lần thứ 2, nếu Bên B vẫn tiếp tục làm việc thì
            phải ký kết hợp đồng lao động không xác định thời hạn.
          </p>
          <p>
            - Trường hợp hết thời hạn 30 ngày kể từ ngày hợp đồng lao động này hết hạn mà Bên A và Bên B không ký kết
            hợp đồng lao động mới và không có thỏa thuận khác thì hợp đồng này đương nhiên trở thành hợp đồng lao động
            không xác định thời hạn.
          </p>
        </div>
        <p className="fw-600">1.2. Công việc và vị trí việc làm</p>
        <div className="spaces pl-35">
          <TextUnderline
            readOnly
            label="a. Địa điểm làm việc"
            value={`${data.phongBanText || ""} , ${data.donViKyHopDongText || ""}`}
          />
          <TextUnderline readOnly label="b. Bộ phận/Đơn vị quản lý" value={data.phongBanText} />
          <TextUnderline readOnly label="c. Vị trí việc làm" value={data.chucDanhText} />
          <TextUnderline readOnly label="d. Chức danh nghề nghiệp" value={data.chucDanhText} />
          <p>e. Nhiệm vụ:</p>
          <p>
            - Thực hiện công việc của chức danh nghề nghiệp <ValueOrDots value={data.chucDanhText} size={80} /> theo bản
            mô tả công việc của bệnh viện;
          </p>
          <p>- Thực hiện theo sự phân công, nhiệm vụ của Khoa, Phòng;</p>
          <p>
            - Thực hiện luân chuyển, điều động theo quy định pháp luật và quy chế Bệnh viện nhằm tạo điều kiện cho Bên B
            được bồi dưỡng toàn diện, rèn luyện trong thực tiễn, khắc phục tình trạng khép kín, cục bộ trong từng khoa,
            phòng thuộc Bệnh viện;
          </p>
        </div>
        <p className="fw-600">Điều 2. Quyền và nghĩa vụ của Bên B:</p>
        <p className="spaces pl-35">
          Ngoài thực hiện các quyền, nghĩa vụ theo quy định của pháp luật về lao động và quy định của pháp luật khác có
          liên quan, Bên B còn thực hiện các quyền, nghĩa vụ sau:
        </p>
        <p className="fw-600">2.1. Quyền của Bên B:</p>
        <div className="spaces pl-35">
          <p className="text-italic fw-600">a. Tiền lương, thưởng và các khoản phụ cấp, bổ sung khác</p>
          <p>
            - Mức lương cơ bản:{" "}
            <span className="fw-600">
              Được hưởng 100% mức lương cơ sở của bậc <ValueOrDots value={data.currentBacLuong} /> , chức danh{" "}
              <ValueOrDots value={data.chucDanhText} size={80} />, mã số <ValueOrDots value={data.maNgach} />, hệ số
              lương <ValueOrDots value={data.heSoLuong} />.
            </span>
          </p>
          <p>- Các khoản phụ cấp, bổ sung (nếu có): </p>
          <div className="spaces pl-35">
            <p>
              + Được hưởng phụ cấp khác (phụ cấp về nhà ở, công tác phí...) với số tiền{" "}
              <span className="fw-600">................. đồng/tháng</span> (Bằng chữ:
              .........................................................................).
            </p>
            <p>
              + Được hưởng các khoản lương tăng thêm, thu nhập hàng tháng khác theo Quy chế chi tiêu nội bộ của bệnh
              viện.
            </p>
          </div>
          <p>- Hình thức trả lương (tiền mặt/chuyển khoản): chuyển khoản.</p>
          <p>- Hàng tháng trả lương một lần theo Quy chế chi tiêu nội bộ, quy chế dân chủ của Bệnh viện.</p>
          <p>
            - Chế độ nâng bậc, nâng lương: Thực hiện theo quy định hiện hành của Nhà nước và Quy chế Nâng bậc lương
            thường xuyên và nâng bậc lương trước thời hạn cho người lao động trong Bệnh viện.
          </p>
          <p>- Thưởng: Thực hiện theo quy định hiện hành của Nhà nước và Quy chế chi tiêu nội bộ của Bệnh viện.</p>
          <p className="text-italic fw-600">b. Thời giờ làm việc, thời giờ nghỉ ngơi</p>
          <p>- Thời giờ làm việc: không quá 08 giờ/ngày và không quá 48 giờ/tuần.</p>
          <p>
            - Chế độ nghỉ ngơi (nghỉ hàng tuần, nghỉ phép năm, nghỉ lễ, tết,...): Theo quy định hiện hành của Nhà nước
            và Quy chế, nội quy, quy định của Bệnh viện.
          </p>
          <p>
            - Khoa, phòng, bệnh viện sắp xếp thời giờ làm việc cho Bên B và có thể thay đổi thời gian biểu, thời giờ làm
            việc của Bên B nhằm hoàn thành khối lượng công việc được giao, bảo đảm số lượng, chất lượng, hiệu quả, đáp
            ứng nhu cầu hoạt động của Bệnh viện và phù hợp với quy định của pháp luật hiện hành.
          </p>
          <p>
            - Thời giờ, chế độ làm thêm, làm ban đêm áp dụng theo Quy chế, nội quy, quy định của Bệnh viện phù hợp với
            quy định của Bộ luật lao động.
          </p>
          <p className="text-italic fw-600">c. Chế độ đào tạo, bồi dưỡng</p>
          <p>
            - Bên B sẽ được đào tạo, bồi dưỡng theo kế hoạch đào tạo của Bệnh viện hoặc Bên B tự nguyện tham gia đào
            tạo, bồi dưỡng nâng cao trình độ trong thời gian thực hiện Hợp đồng này.
          </p>
          <p>- Địa điểm đào tạo: Tùy theo kế hoạch, nội dung đào tạo từng thời kỳ.</p>
          <p>- Thời gian đào tạo: Theo kế hoạch đào tạo của Bệnh viện.</p>
          <p>
            - Bên B được cử đi đào tạo, bồi dưỡng được hưởng các quyền lợi liên quan theo Quy chế đào tạo của Bệnh viện
            và theo quy định pháp luật.
          </p>
          <p>
            - Có cam kết thời gian công tác tại Bệnh viện sau khi kết thúc khóa học tương ứng với từng khóa học và bồi
            hoàn chi phí đào tạo theo quy định tại Quy chế đào tạo của Bệnh viện, thực hiện đúng những nội dung trong
            cam kết đào tạo.
          </p>
          <p>
            - Trong thời gian đào tạo, sẵn sàng về tham gia công tác trong trường hợp Bệnh viện có yêu cầu (kể cả trường
            hợp phải tạm dừng đào tạo) theo Quyết định của Giám đốc Bệnh viện.
          </p>
          <p>
            <span className="fw-600 text-italic">d. Điều kiện lao động:</span> Bên B được cung cấp trang thiết bị bảo hộ
            lao động phù hợp với công việc và được Bên A bảo đảm an toàn lao động, vệ sinh lao động để phòng ngừa, giảm
            thiểu nguy cơ lây nhiễm, tai nạn liên quan đến nghề nghiệp trong thời gian làm việc theo hợp đồng. Bên B có
            trách nhiệm sử dụng, bảo quản các trang thiết bị bảo hộ lao động và tuân thủ các quy định về an toàn lao
            động, vệ sinh lao động.
          </p>
          <p className="fw-600 text-italic">e. Bảo hiểm xã hội, bảo hiểm y tế, bảo hiểm thất nghiệp:</p>
          <p>
            - Bên B được tham gia đóng và hưởng các chế độ bảo hiểm xã hội, bảo hiểm thất nghiệp, bảo hiểm y tế theo quy
            định của pháp luật tại thời điểm tham gia bảo hiểm.
          </p>
          <p>
            - Trường hợp Bên B không tham gia bảo hiểm xã hội (với lý do đã đóng bảo hiểm tại nơi khác), tiền lương đã
            bao gồm khoản Bệnh viện trích đóng bảo hiểm.
          </p>
          <p>
            f. Ngoài quyền lợi được hưởng theo Hợp đồng này, Bên B được hưởng các quyền lợi khác theo thỏa thuận, quy
            định của Bệnh viện và được các bên ghi nhận tại Phụ lục hợp đồng kèm theo Hợp đồng này.
          </p>
        </div>
        <p className="fw-600">2.2. Nghĩa vụ của Bên B</p>
        <div className="spaces pl-35">
          <p>
            a. Thực hiện các nhiệm vụ theo thỏa thuận trong hợp đồng lao động và và báo cáo kết quả công việc theo quy
            định của Bệnh viện hoặc theo yêu cầu của cấp quản lý.
          </p>
          <p>
            b. Cung cấp văn bản, giấy tờ xác minh đủ tiêu chuẩn, điều kiện thực hiện công việc thỏa thuận theo yêu cầu
            của Bên A.
          </p>
          <p>
            c. Cam kết khi ký Hợp đồng này đã đọc, hiểu và chấp hành nghiêm chỉnh quy định, nội quy, quy chế, kỷ luật
            làm việc của Bệnh viện và các quy định của pháp luật trong thời gian thực hiện Hợp đồng này. Nếu vi phạm,
            Bên B phải hoàn toàn chịu trách nhiệm theo quy định của Bệnh viện và của pháp luật về hành vi mình gây ra.{" "}
          </p>
          <p>
            d. Chấp hành việc xử lý vi phạm kỷ luật lao động và trách nhiệm bồi thường, hoàn trả theo quy định tại Hợp
            đồng này, quy chế, nội quy, quy định của Bệnh viện và theo quy định của pháp luật.
          </p>
          <p>
            e. Tuyệt đối đảm bảo tính bảo mật thông tin tình trạng bệnh của người bệnh, những thông tin mà người bệnh đã
            cung cấp và hồ sơ bệnh án.
          </p>
          <p>f. Chấp hành sự quản lý, điều hành, giám sát của người sử dụng lao động.</p>
          <p>
            g. Báo cáo kịp thời với người có trách nhiệm khi phát hiện nguy cơ gây tai nạn lao động bệnh nghề nghiệp,
            gây độc hại hoặc sự cố nguy hiểm, tham gia cấp cứu và khắc phục hậu quả tai nạn lao động khi có lệnh của
            Người sử dụng lao động.
          </p>
          <p>
            h. Thuế thu nhập cá nhân (nếu có) do Bên B đóng. Cơ quan, đơn vị sẽ tạm khấu trừ trước khi chi trả cho Bên B
            theo quy định.
          </p>
          <p>
            i. Trung thành với lợi ích của Bệnh viện, không sử dụng thông tin, tài sản, mối quan hệ với khách hàng, bệnh
            nhân, đối tác,… của Bệnh viện để trục lợi hoặc vì mục đích cá nhân khác.
          </p>
          <p>
            j. Chấp hành lệnh điều hành, nội quy lao động, an toàn lao động, hướng dẫn về ứng xử trong công việc và
            chính sách của Bệnh viện.
          </p>
          <p>
            k. Có trách nhiệm thực hiện công việc theo đúng quy định chuyên môn kỹ thuật, đạo đức hành nghề và chịu
            trách nhiệm cá nhân trước Trưởng Bộ phận, Trưởng phòng, Giám đốc Bệnh viện và trước pháp luật về việc thực
            hiện các nhiệm vụ, quyền hạn được giao theo quy định Bệnh viện và quy định pháp luật có liên quan.
          </p>
          <p>
            l. Tôn trọng các quyền của người bệnh, có thái độ ân cần, hòa nhã với người bệnh và đối xử bình đẳng với
            người bệnh, không để lợi ích cá nhân hay sự phân biệt đối xử ảnh hưởng đến quyết định chuyên môn của mình.
          </p>
          <p>
            m. Chấp hành quyết định điều động của cơ quan quản lý trực tiếp, hỗ trợ các công việc liên quan theo chuyên
            môn.
          </p>
          <p>
            n. Trong thời gian làm việc tại Bệnh viện theo Hợp đồng lao động này, Bên B cam kết không làm thêm giờ
            và/hoặc cộng tác viên cho bất kỳ tổ chức, cá nhân nào khác mà không được sự đồng ý trước bằng văn bản của
            Bệnh viện. Trường hợp Bên B vi phạm, Bệnh viện có quyền xử lý kỷ luật lao động và bồi thường thiệt hại ở mức
            cao nhất theo Nội quy lao động Bệnh viện.
          </p>
          <p>
            o. Trong thời gian làm việc tại Bệnh viện theo hợp đồngnày, Bên B cam kết đảm bảo thực hiện đúng tiêu chuẩn
            đạo đức theo quy chế, nội quy Bệnh viện và quy định của pháp luật.
          </p>
          <p>
            p. Trong suốt thời hạn của Hợp đồng này và toàn bộ thời gian sau đó, Bên B không được phép sử dụng hoặc để
            lộ cho bất kỳ thông tin mật,bí mật kinh doanh và tài sản trí tuệ nào liên quan đến hoạt động kinh doanh của
            Bệnh viện hoặc liên quan đến bất kỳ đơn vị liên kết nào của Bệnh viện, trừ khi pháp luật yêu cầu.
          </p>
          <p>
            q. Bên B tại đây nhất trí rằng nếu như quan hệ lao động của mình với Bên A chấm dứt vì bất kỳ lý do gì, Bên
            B sẽ ngay lập tức và vô điều kiện bàn giao lại toàn bộ tài sản vật chất được Bên A giao và/hoặc đang cho Bên
            B nắm giữ và toàn bộ các tài liệu, giấy tờ và vật liệu chứa đựng các thông tin mật hoặc thông tin về nhân
            sự, bệnh án của Bệnh viện.
          </p>
          <p>
            r. Nếu Bên B tham gia đào tạo, bồi dưỡng, Bên B có nghĩa vụ bồi thường chi phí đào tạo theo quy chế, nội
            quy, quy định của Bệnh viện và quy định pháp luật nếu vi phạm một trong các trường hợp sau:
          </p>
          <p>- Tự ý bỏ học, bỏ việc hoặc đơn phương chấm dứt hợp đồng làm việc trong thời gian đào tạo.</p>
          <p>- Không được cơ sở đào tạo cấp văn bằng tốt nghiệp.</p>
          <p>
            - Đã hoàn thành và được cấp văn bằng tốt nghiệp khóa học nhưng bỏ việc hoặc đơn phương chấm dứt hợp đồng lao
            động hoặc không tiếp tục ký kết hợp đồng lao động mới theo Khoản 1.1 Điều 1 Hợp đồng này khi chưa phục vụ đủ
            thời gian cam kết làm việc sau đào tạo theo quy chế, nội quy, quy định của Bệnh viện.
          </p>
          <p>s. Bồi thường thiệt hại và trách nhiệm vật chất: </p>
          <p>
            - Bên B làm hư hỏng dụng cụ, thiết bị hoặc có/không có hành vi gây thiệt hại cho Bệnh viện thì Bên B có
            trách nhiệm bồi thường toàn bộ thiệt hại theo quy định của Bệnh viện và pháp luật.
          </p>
          <p>
            - Khi đơn phương chấm dứt Hợp đồng lao động, Bên B có trách nhiệm đền bù chi phí đào tạo (nếu có) theo quy
            định của Bệnh viện và pháp luật.{" "}
          </p>
          <p>
            - Trách nhiệm khi xảy ra tai biến trong khám chữa bệnh và mức tiền bồi thường khi có thiệt hại xảy ra: thực
            hiện theo quy định của bệnh viện.
          </p>
          <p>
            t. Bên B phải hành động để tránh gây tổn thất, thiệt hại, giảm sút uy tín, thương hiệu của Bệnh viện cũng
            như của bệnh nhân. Nếu vi phạm, Bên B phải hoàn toàn chịu trách nhiệm theo quy định của Bệnh viện và của
            pháp luật về những thiệt hại do hành vi của mình gây ra.
          </p>
          <p>u. Nghĩa vụ khác theo quy định pháp luật.</p>
        </div>
        <p className="fw-600">Điều 3. Quyền và nghĩa vụ của Bên A</p>
        <p className="fw-600">3.1. Quyền của Bên A</p>
        <div className="spaces pl-35">
          <p>a. Yêu cầu Bên B thực hiện công việc và tuân thủ các nghĩa vụ theo đúng thỏa thuận tại hợp đồng này.</p>
          <p>
            b. Điều hành Bên B hoàn thành công việc theo Hợp đồng (bố trí, điều chuyển công việc cho người lao động theo
            đúng chức năng chuyên môn).
          </p>
          <p>
            c. Đánh giá mức độ hoàn thành chỉ tiêu công việc của Bên B. Trong trường hợp Bên B thường xuyên không hoàn
            thành chỉ tiêu công việc, Bên A có quyền đơn phương chấm dứt hợp đồng với Bên B theo quy định của pháp luật
            hiện hành và quy chế, nội quy, quy định của Bệnh viện.
          </p>
          <p>
            d. Có quyền chuyển tạm thời lao động, ngừng việc, thay đổi, tạm thời chấm dứt Hợp đồng lao động và áp dụng
            các biện pháp kỷ luật theo quy định của pháp luật hiện hành và theo nội quy, quy định của Bệnh viện trong
            thời gian hợp đồng còn giá trị.
          </p>
          <p>
            e. Tạm hoãn, chấm dứt Hợp đồng, kỷ luật người lao động theo quy định của pháp luật hiện hành và theo nội
            quy, quy định của Bệnh viện.
          </p>
          <p>
            f. Trường hợp Bên B vi phạm nghĩa vụ thì bên A có quyền đơn phương chấm dứt thực hiện hợp đồng và yêu cầu
            bồi thường thiệt hại.
          </p>
          <p>g. Các quyền khác theo quy định của pháp luật.</p>
        </div>
        <p className="fw-600">3.2. Nghĩa vụ của Bên A</p>
        <div className="spaces pl-35">
          <p>
            a. Cung cấp thông tin, tài liệu và các phương tiện, điều kiện làm việc cần thiết để Bên B thực hiện công
            việc.
          </p>
          <p>b. Bảo đảm việc làm và thực hiện đầy đủ những điều đã cam kết trong Hợp đồng lao động này. </p>
          <p>
            c. Chi trả lương, thực hiện chế độ, chính sách khác cho người lao động theo thỏa thuận bảo đảm phù hợp với
            quy định của pháp luật lao động và quy định của pháp luật khác có liên quan.
          </p>
          <p>
            d. Bảo đảm quyền, lợi ích hợp pháp của người lao động theo thỏa thuận tại hợp đồng và quy định của pháp luật
            về lao động.
          </p>
        </div>
        <p className="fw-600">Điều 4. Tạm hoãn, chấm dứt hợp đồng lao động</p>
        <p>
          4.1. Việc tạm hoãn, chấm dứt hợp đồng giữa các bên được thực hiện theo quy định của pháp luật về lao động.
        </p>
        <p>4.2. Bên B bị coi là vi phạm hợp đồng khi thuộc một trong các trường hợp sau đây:</p>
        <p>
          4.3. Bên B không thực hiện hoặc thực hiện không đúng, không đầy đủ và chậm thực hiện bất kỳ nghĩa vụ nào quy
          định trong hợp đồng này.
        </p>
        <p>4.4. Bên B vi phạm kỷ luật lao động.</p>
        <p>4.5. Đơn phương chấm dứt hợp đồng trái quy định.</p>
        <p>
          4.6. Trường hợp Bên A vi phạm nghiêm trọng nghĩa vụ thì Bên B có quyền đơn phương chấm dứt thực hiện hợp đồng
          và yêu cầu bồi thường thiệt hại.
        </p>
        <p className="fw-600">Điều 5. Phương thức giải quyết tranh chấp</p>
        <p>
          5.1. Bất kỳ tranh chấp nào giữa các bên phát sinh từ hoặc liên quan đến Hợp đồng này sẽ được giải quyết trước
          hết bằng thương lượng đàm phán giữa các bên trong thời hạn 30 ngày kể từ ngày phát sinh tranh chấp. Hết thời
          hạn này, tranh chấp không được giải quyết thì có quyền đưa tranh chấp ra Toà án có thẩm quyền để giải quyết
          theo pháp luật Việt Nam.
        </p>
        <p>
          5.2. Trong thời gian có mâu thuẫn hoặc có tranh chấp đang được giải quyết thì các bên phải tiếp tục thực hiện
          nghĩa vụ của mình theo Hợp đồng này, ngoại trừ vấn đề đang bị tranh chấp.
        </p>
        <p className="fw-600">Điều 6. Điều khoản thi hành</p>
        <p>
          6.1. Hợp đồng có hiệu lực từ ngày <ValueOrDots value={data.ngayKyHopDong} />. Hợp đồng này có hiệu lực
          thay thế cho các hợp đồng lao động liên quan mà hai bên đã ký kết trước đó (nếu có).
        </p>
        <p>
          6.2. Trong quá trình thực hiện hợp đồng lao động, nếu bên nào có yêu cầu sửa đổi, bổ sung nội dung hợp đồng
          thì phải báo cho bên kia biết trước ít nhất 03 ngày làm việc về nội dung cần sửa đổi, bổ sung.
        </p>
        <p>
          - Trường hợp hai bên thỏa thuận được thì việc sửa đổi, bổ sung nội dung hợp đồng lao động được tiến hành bằng
          việc ký kết phụ lục hợp đồng lao động hoặc ký kết hợp đồng lao động mới.
        </p>
        <p>
          - Trường hợp hai bên không thỏa thuận được việc sửa đổi, bổ sung nội dung hợp đồng lao động thì tiếp tục thực
          hiện hợp đồng lao động đã ký kết.
        </p>
        <p>
          6.3. Những vấn đề về lao động khác không ghi trong hợp đồng này thì áp dụng quy định của nội quy, quy chế của
          Bệnh viện, trường hợp nội quy, quy chế của Bệnh viện không có quy định thì được thực hiện theo quy định tại Bộ
          luật Lao động và các văn bản quy phạm pháp khác có liên quan.
        </p>
        <p>
          6.4. Hợp đồng được làm thành 03 bản có giá trị pháp lý như nhau, mỗi bên giữ 01 bản, 01 bản lưu trong hồ sơ
          của Bên B./.
        </p>
        <p>
          Các bên ký xác nhận đã hiểu nội dung tất cả điều khoản, đồng ý ký kết và nghiêm túc thực hiện các quy định tại
          Hợp đồng này.
        </p>
      </div>
      <div className="flex align-start flex-between fs-lg fw-600 spaces mt-16 text-center pb-70 px-70">
        <div>
          <p>ĐẠI DIỆN BÊN A</p>
          <p className="spaces mt-70">
            <ValueOrDots value={data.nguoiDaiDienCtyKyText} size={60} />
          </p>
        </div>
        <div>
          <p>BÊN B</p>
          <p className="spaces mt-70">
            <ValueOrDots value={data.fullName} size={60} />
          </p>
        </div>
      </div>
    </div>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { KTSVG } from "../../../../_metronic/helpers";
import TableCustom from "../../components/table-custom/TableCustom";
import { EXPORT_FILE_NAME, RESPONSE_STATUS_CODE, TYPE } from "../../constants/moduleConsts";
import { exportGiaDinh } from "../../services/exportFileServices";
import { exportToFile, useCustomIntl } from "../../utils/functionUtils";
import { INIT_RELATIVES_INFO, REF_TAB } from "../constants/profileConsts";
import { IRelativesInfo, IRelativesInfoDto } from "../models/dialogModels";
import "../profile.scss";
import { deleteQuanHeGiaDinh, getAllQuanHeGiaDinhById } from "../services/dialogServices";
import { convertDataFamilyUI } from "../utils/profileUtils";
import { RelativesDialog } from "./dialogs/RelativesDialog";
import AppContext from "../../../AppContext";
import useMultiLanguage from "../../../hook/useMultiLanguage";

const FamilyInformation = (props: any) => {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [shouldOpenRelativesDialog, setShouldOpenRelativesDialog] = useState<boolean>(false);
  const { identify, isView, employeeProfiles, activeTab } = props;
  const [relative, setRelative] = useState<IRelativesInfo>(INIT_RELATIVES_INFO);
  const [listRelative, setListRelative] = useState<IRelativesInfoDto[]>([]);
  const [dataChecked, setDataChecked] = useState<any[]>([]);
  
  useEffect(() => {
    if (identify && activeTab === REF_TAB.TT_GIA_DINH) {
      updateDataRelatives()
    }
  }, [employeeProfiles, activeTab]);


  const handleCloseRelativesDialog = (): void => {
    setRelative(INIT_RELATIVES_INFO);
    setShouldOpenRelativesDialog(false);
  };

  const updateDataRelatives = async () => {
    if (identify) {
      try {
        setPageLoading(true);
        let { data } = await getAllQuanHeGiaDinhById(identify);
        if(data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
          setListRelative(data?.data);
        } else {
          toast.warning(data?.message);
        }
      } catch (error) {
        toast.error(lang("GENERAL.ERROR"));
      } finally {
        setPageLoading(false);
      }
    }
  };

  const handleOpenDialog = (row: any) => {
    setShouldOpenRelativesDialog(true);
    setRelative(convertDataFamilyUI(row));
  };

  const handleDelete = async (ids: string[]) => {
    try {
      const { data } = await deleteQuanHeGiaDinh(ids)
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("GENERAL.DELETE_SUCCESS"))
        updateDataRelatives();
        return true;
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }

  const handleCloseAndSearch = () => {
    updateDataRelatives();
    handleCloseRelativesDialog();
  }

  const columns = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: useCustomIntl("INPUT.FAMILY.RELATION"),
      field: "quanHeNV",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        minWidth: "200px",
        maxWidth: "200px",
        textAlign: "left",
      },
      render: (row: any) => <span className="color-steel-blue fw-600">{row?.quanHeNVText}</span>
    },
    {
      name: useCustomIntl("INPUT.FAMILY.NAME"),
      field: "ten",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        minWidth: "200px",
        textAlign: "left",
      },
    },
    {
      name: useCustomIntl("INPUT.FAMILY.BIRTH"),
      field: "namSinh",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        minWidth: "200px",
      },
    },
    {
      name: useCustomIntl("INPUT.FAMILY.MORE_INFO"),
      field: "moreDetails",
      headerStyle: {
        minWidth: "500px"
      },
      cellStyle: {
        minWidth: "500px",
        textAlign: "left"
      },
      render: (row: any) => {
        const lines = row?.moreDetails ? row?.moreDetails?.split('\n') : [];
        return (
          lines.map((line: string, index: number) => (
            <p className="spaces m-0" key={index}>{line}</p>
          ))
        )
      }
    },
    {
      name: useCustomIntl("INPUT.FAMILY.IS_DEATH"),
      field: "isDeadth",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        minWidth: "200px",
      },
      render: (row: any) => row?.isDeadth ? <KTSVG path='/media/icons/check.svg' className=' svg-icon-1' /> : ""
    },
    {
      name: useCustomIntl("INPUT.FAMILY.IS_SAME_ORGANIZATION"),
      field: "isSameOrganization",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        minWidth: "200px",
      },
      render: (row: any) => row?.isSameOrganization ? <KTSVG path='/media/icons/check.svg' className=' svg-icon-1' /> : ""
    },
    {
      name: useCustomIntl("INPUT.FAMILY.IS_DEPENDENT_PERSON"),
      field: "isDependentPerson",
      headerStyle: {
        minWidth: "200px"
      },
      cellStyle: {
        minWidth: "200px",
      },
      render: (row: any) => row?.isDependentPerson ? <KTSVG path='/media/icons/check.svg' className=' svg-icon-1' /> : ""
    }
  ]

  return (
    <>
      <div className="form-info">
        <div className="block-content">
          <TableCustom
            id="listRelative"
            title={useCustomIntl("GENERAL.INFO.FAMILY")}
            isActionTableTab
            data={listRelative}
            columns={columns}
            type={TYPE.MULTILINE}
            fixedColumnsCount={3}
            noToolbar={true}
            noPagination={true}
            buttonAdd={!isView}
            notDelete={isView}
            buttonExportExcel={!dataChecked.length}
            dataChecked={dataChecked}
            setDataChecked={setDataChecked}
            handleDoubleClick={handleOpenDialog}
            handleOpenDialog={() => setShouldOpenRelativesDialog(true)}
            handleDelete={handleDelete}
            handleExportExcel={() => exportToFile({
              exportAPI: () => exportGiaDinh(employeeProfiles.id), 
              fileName: EXPORT_FILE_NAME.QUAN_HE_GIA_DINH, 
              setPageLoading
            })}
            dependencies={activeTab}
          />
        </div>
      </div>
      {shouldOpenRelativesDialog && (
        <RelativesDialog
          handleCloseRelativesDialog={handleCloseRelativesDialog}
          handleCloseAndSearch={handleCloseAndSearch}
          identify={identify}
          isView={isView}
          dataRelativeEdit={relative}
        />
      )}
    </>
  );
};

export default FamilyInformation;

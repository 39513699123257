import { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { RESPONSE_STATUS_CODE, TYPE } from "../../constants/moduleConsts";
import { toast } from "react-toastify";
import { updateThongTinDacDiemBanThan } from "../services/profileServices";
import { useFormik } from "formik";
import { EmployeeProfile, IPersonalHistorical } from "../models/profileModels";
import TextArea from "../../components/input/TextArea";
import { WorkInOldModeInformation } from "./WorkInOldModeInformation";
import { AbroadInformation } from "./AbroadInformation";
import { GroupButton } from "../../components/GroupButton";

interface IProps {
  identify: string;
  isView: boolean;
  employeeProfiles: EmployeeProfile;
  activeTab: string;
  handleGetEmployee: (id: string) => void;
}

function PersonalHistoricalInformation(props: IProps) {
  const { identify, isView, employeeProfiles, activeTab, handleGetEmployee } = props;
  const { lang } = useMultiLanguage();

  useEffect(() => {
    if (employeeProfiles?.id) {
      const dataFilter: IPersonalHistorical = {
        diemManh: employeeProfiles.diemManh || "",
        diemYeu: employeeProfiles.diemYeu || "",
        mtstVanDeKhac: employeeProfiles.mtstVanDeKhac || "",
        mucTieuCaNhan: employeeProfiles.mucTieuCaNhan || "",
        lichSuBanThan: employeeProfiles.lichSuBanThan || "",
        soThich: employeeProfiles.soThich || ""
      };
      formik.setValues(dataFilter);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeProfiles]);

  const handleFormSubmit = async (values: any) => {
    try {
      const { data } = await updateThongTinDacDiemBanThan(identify, values);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("TOAST.EDIT.SUCCESS"));
        handleGetEmployee(employeeProfiles?.id || "");
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const formik = useFormik({
    initialValues: {} as IPersonalHistorical,
    onSubmit: handleFormSubmit
  });

  const handleChange = (value: any, name: string) => {
    formik.setFieldValue(name, value);
  };

  return (
    <Form className="personal-historical form-info" onSubmit={formik.handleSubmit}>
      <div className="block-content">
        <div className="spaces mb-8 flex flex-between">
          <span className="spaces fs-18 fw-600">{lang("PROFILE.OTHER.PERSONAL_HISTORICAL")}</span>
          {!isView && <GroupButton type="btn-save" />}
        </div>
        <h5 className="spaces m-0">{"* " + lang("PROFILE.OTHER.PERSONAL_HISTORICAL.PLACEHOLDER")}</h5>
        <Row className="spaces pl-20">
          <Col xs={12} className="spaces pt-4">
            <TextArea
              type="text"
              as={TYPE.TEXTAREA}
              placeholder={lang("PROFILE.OTHER.PERSONAL_HISTORICAL.PLACEHOLDER")}
              name="lichSuBanThan"
              value={formik.values?.lichSuBanThan}
              rows={5}
              readOnly={isView}
              onChange={handleChange}
              bulletPoints="+"
            />
          </Col>
        </Row>
        <h5 className="spaces pt-12 m-0">{"* Thông tin mục tiêu - sở thích"}</h5>
        <Row className="spaces pl-20">
          <Col xs={6} className="spaces pt-4">
            <TextArea
              type="text"
              rows={3}
              lable={lang("PROFILE.OTHER.TARGET_PERSONAL")}
              as={TYPE.TEXTAREA}
              readOnly={isView}
              name="mucTieuCaNhan"
              value={formik.values?.mucTieuCaNhan}
              onChange={handleChange}
              bulletPoints="+"
            />
          </Col>
          <Col xs={6} className="spaces pt-4">
            <TextArea
              type="text"
              rows={3}
              lable={lang("PROFILE.OTHER.INTEREST")}
              as={TYPE.TEXTAREA}
              readOnly={isView}
              name="soThich"
              value={formik.values?.soThich}
              onChange={handleChange}
              bulletPoints="+"
            />
          </Col>
          <Col xs={6} className="spaces pt-4">
            <TextArea
              type="text"
              rows={3}
              lable={lang("PROFILE.OTHER.FORTE")}
              as={TYPE.TEXTAREA}
              readOnly={isView}
              name="diemManh"
              value={formik.values?.diemManh}
              onChange={handleChange}
              bulletPoints="+"
            />
          </Col>
          <Col xs={6} className="spaces pt-4">
            <TextArea
              type="text"
              rows={3}
              lable={lang("PROFILE.OTHER.WEAKNESS")}
              as={TYPE.TEXTAREA}
              readOnly={isView}
              name="diemYeu"
              value={formik.values?.diemYeu}
              onChange={handleChange}
              bulletPoints="+"
            />
          </Col>
          <Col xs={12} className="spaces pt-4">
            <TextArea
              type="text"
              rows={3}
              lable={lang("PROFILE.OTHER.PROPLEM_AMEND")}
              as={TYPE.TEXTAREA}
              readOnly={isView}
              name="mtstVanDeKhac"
              value={formik.values?.mtstVanDeKhac}
              onChange={handleChange}
              bulletPoints="+"
            />
          </Col>
        </Row>
        {/* Làm việc trong chế độ cũ */}
        <WorkInOldModeInformation
          identify={identify}
          isView={isView}
          activeTab={activeTab}
        />
        {/* Nước ngoài */}
        <AbroadInformation
          identify={identify}
          isView={isView}
          activeTab={activeTab}
        />
      </div>
    </Form>
  );
}

export default PersonalHistoricalInformation;

import { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { IuserByUnit } from "../models/managerModels";
import { INIT_SEARCH_OBJECT } from "../../constants/moduleConsts";
import { deleteUser, searchAllUser, getUserById } from "../services/managerServices";
import { RESPONSE_STATUS_CODE, TYPE } from "../../constants/moduleConsts";
import TableCustom, { columnNamesType } from "../../components/table-custom/TableCustom";
import { hasAuthority } from "../../utils/functionUtils";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../../constants";
import { UserDialog } from "./dialogs/UserDialog";
import { Col, Row } from "react-bootstrap";
import { GroupButton } from "../../components/GroupButton";
import InputSearch from "../../components/input/InputSearch";

const UserList: FC = () => {
  const { lang } = useMultiLanguage();
  const [userList, setUserList] = useState<IuserByUnit[]>([]);
  const [userInfo, setUserInfo] = useState<IuserByUnit>({} as IuserByUnit);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [openUserDialog, setOpenUserDialog] = useState<boolean>(false);
  const [searchObject, setSearchObject] = useState<any>(INIT_SEARCH_OBJECT);

  useEffect(() => {
    if(searchObject?.pageIndex && searchObject?.pageSize) {
      handleSearch();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchObject?.pageIndex, searchObject?.pageSize])

  const handleOpenUserDialog = async (row: any) => {
    try {
      const { data } = await getUserById(row?.id);
      if(data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        const result = data?.data;
        result.user.roles = [{ name: result?.user?.roles?.[0] }];
        setUserInfo(result);
        setOpenUserDialog(true);
      } else {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const handleCloseUserDialog = () => {
    setOpenUserDialog(false);
    setUserInfo({} as IuserByUnit);
  };

  const loadListUser = async (searchObject: any) => {
    try {
      setSearchObject({ ...searchObject })
      const { data } = await searchAllUser(searchObject);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setUserList(data?.data?.content);
        setTotalPage(data?.data?.pageable?.totalPages || 0);
        setTotalElements(data?.data?.pageable?.totalElements || 0);
        setNumberOfElements(data?.data?.pageable?.numberOfElements || 0);
      } else {
        toast.warning(data?.message);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const handleDeleteUser = async (ids: string[]) => {
    try {
      const { data } = await deleteUser(ids);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("GENERAL.DELETE_SUCCESS"));
        loadListUser(searchObject);
        return true;
      } else toast.warning(data?.message);
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }

  const handleSearch = (data: any = {}) => {
    const dataSearch: any = {
      ...searchObject,
      ...data
    };
    dataSearch.keyword = dataSearch?.keyword?.trim() || ""
    loadListUser(dataSearch);
  };

  const handleCloseAndSearch = () => {
    handleCloseUserDialog();
    handleSearch();
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchObject({
      ...searchObject,
      keyword: e.target?.value
    });
  };

  const columns: columnNamesType[] = [
    {
      name: lang("TABLE.INDEX"),
      field: "stt",
      render: (row, index, stt) => <span>{stt}</span>
    },
    {
      name: lang("UNIT"),
      field: "organization?.name",
      headerStyle: {
        minWidth: "150px"
      },
      cellStyle: {
        textAlign: "left"
      },
      render: (row) => <span>{row?.organization?.name}</span>
    },
    {
      name: lang("INPUT.DEPARTMENTS"),
      field: "phongBanText",
      headerStyle: {
        minWidth: "200px",
      },
      cellStyle: {
        textAlign: "left"
      },
      render: (row) => <span>{row?.employeeResponseDto?.phongBanText || ""}</span>
    },
    {
      name: lang("PROFILE.NAME"),
      field: "name",
      headerStyle: {
        minWidth: "200px",
      },
      cellStyle: {
        textAlign: "left"
      },
      render: (row) => <span>{row?.employeeResponseDto?.name || ""}</span>
    },
    {
      name: lang("USER.NAME"),
      field: "username",
      headerStyle: {
        minWidth: "150px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: lang("USER.ROLE"),
      field: "roles",
      headerStyle: {
        minWidth: "150px"
      },
      cellStyle: {
        textAlign: "left"
      },
      render: (row) => <span>{row?.roles?.toString()}</span>
    },
    {
      name: lang("USER.EMAIL"),
      field: "email",
      headerStyle: {
        minWidth: "150px"
      },
      cellStyle: {
        textAlign: "left"
      },
    },
    {
      name: lang("USER.LAST_LOGIN"),
      field: "lastLoginTime",
      headerStyle: {
        minWidth: "150px"
      }
    }
  ];

  return (
    <div className="user-tab">
      <Row className="flex-space-between spaces mt-10">
        <span className="spaces fs-18 fw-600">{lang("USER.LIST")}</span>
        <Col xs={4} md={4} lg={4} xl={2} className="mt-3">
          {hasAuthority(PERMISSIONS.MANAGEMENT_UNIT, PERMISSION_ABILITY.CREATE) &&
            <GroupButton handleEvent={() => setOpenUserDialog(true)}>
              <i className="spaces bi bi-plus fs-20 white pr-0"></i>
              <p className="spaces fs-14 m-0 ">{lang("BTN.ADD")}</p>
            </GroupButton>
          }
        </Col>
        <Col xs={8} md={6} lg={5} xl={4} className="flex mt-3">
          <div className="spaces mr-10 flex-1">
            <InputSearch
              className="border-3px"
              value={searchObject?.keyword}
              placeholder="Tìm kiếm theo tên đăng nhập"
              isEnter={true}
              handleSearch={() => handleSearch({ pageIndex: 1 })}
              handleChange={handleChange}
            />
          </div>
          <GroupButton handleEvent={() => handleSearch({ pageIndex: 1 })}>
            {lang("BTN.SEARCH")}
          </GroupButton>
        </Col>
      </Row>
      <div className="user-list table-custom">
        <TableCustom
          data={userList}
          columns={columns}
          handleDelete={(ids: string[]) => handleDeleteUser(ids)}
          handleDoubleClick={handleOpenUserDialog}
          searchObject={searchObject}
          setSearchObject={setSearchObject}
          noToolbar={true}
          buttonAdd={false}
          notDelete={!hasAuthority(PERMISSIONS.MANAGEMENT_UNIT, PERMISSION_ABILITY.DELETE)}
          justFilter={false}
          totalPages={totalPage}
          totalElements={totalElements}
          numberOfElements={numberOfElements}
          unSelectedAll={true}
          type={TYPE.MULTILINE}
        />
        {openUserDialog && (
          <UserDialog
            handleCloseDialog={handleCloseUserDialog}
            userInfo={userInfo}
            handleCloseAndSearch={handleCloseAndSearch}
          />
        )}
      </div>
    </div>
  );
};
export { UserList };

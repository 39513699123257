import { ResignObjectSearch } from '../models/resignModels';
import { APIResponse } from "../../models/models";
import axios, { AxiosResponse } from "axios";
import { InterruptInfo } from '../models/interruptModels';
import { paramsConfig } from '../../utils/paramsUtils';

const API_URL = process.env.REACT_APP_API_URL;

export const getListInterrupt = (objectSearch: ResignObjectSearch): Promise<AxiosResponse<APIResponse>> => {
    let url = `${API_URL}/nghi-gian-doan/page`;
    return axios.get(url, paramsConfig(objectSearch));
};

export const deleteInterrupt = (ids: string[]
): Promise<AxiosResponse<APIResponse>> => {
    let url = `${API_URL}/nghi-gian-doan/ids?ids=${ids.toString()}`;
    return axios.delete(url);
};

export const addNewInterrupt = (
    interrupt: InterruptInfo
): Promise<AxiosResponse<APIResponse>> => {
    let url = `${API_URL}/nghi-gian-doan`;
    return axios.post(url, interrupt);
};
export const editInterrupt = (id: string, interrupt: InterruptInfo): Promise<AxiosResponse<APIResponse>> => {
    let url = `${API_URL}/nghi-gian-doan/${id}`;
    return axios.put(url, interrupt);
};
import { IPropsExportWord } from "../../../components/exportWord";
import { ValueOrDots } from "../../../components/exportWord/partials";

export function MissionDecisionWord({ data }: IPropsExportWord) {
  return (
    <div className="word spaces px-70">
      <div className="spaces text-center flex flex-between align-start">
        <div className="fs-lg">
          <p>{data?.soYTeHeader ?? ""}</p>
          <p className="fw-600 text-uppercase">
            <span className="header-underline fs-lg">{data?.donViCongTacText ?? ""}</span>
          </p>
          <p className="spaces mt-16 fs-md">Số: {data?.soQuyetDinh}</p>
        </div>
        <div className="fw-600">
          <p className="fs-lg">CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM</p>
          <p>
            <span className="header-underline fs-lg">Độc lập - Tự do - Hạnh phúc</span>
          </p>
          <p className="text-italic fw-500 spaces mt-16">
            <ValueOrDots value="" size={20} />, ngày <ValueOrDots value={data.ngayQuyetDinhNgay} size={15}/> tháng{" "}
            <ValueOrDots value={data.ngayQuyetDinhThang} size={15}/> năm <ValueOrDots value={data.ngayQuyetDinhNam} size={15} />
          </p>
        </div>
      </div>
      <div className="text-center fw-600">
        <div className="fs-lg">
          <p>QUYẾT ĐỊNH</p>
          <p>
            <span className="header-underline fs-lg">Về việc giao nhiệm vụ Phụ trách {data?.chucVuMoiText}</span>
          </p>
        </div>
        <div className="spaces py-16">
          <p className="fs-lg text-uppercase">GIÁM ĐỐC {data?.donViCongTacText ?? ""}</p>
        </div>
      </div>
      <div className="text-italic text-indent-40 text-justify">
        <p>{data?.qdUBNDKiemNhiem ?? ""}</p>
        <p>{data?.quyetDinhHopDong ?? ""}; {data?.qdhdBoNhiem ?? ""}</p>
        <p>Căn cứ Biên bản họp Ban Giám đốc ngày <ValueOrDots value="" /> về việc giao nhiệm vụ phụ trách{" "}
          <ValueOrDots value={data?.chucVuMoiText} /> <ValueOrDots value={data?.phongBanMoiText} />
        </p>
        <p>Xét đề nghị của Trưởng phòng Tổ chức Hành chính Bệnh viện</p>
      </div>
      <p className="fw-600 text-center fs-lg spaces my-16">QUYẾT ĐỊNH:</p>
      <div className="text-indent-40">
        <p>
          <span className="fw-600">Điều 1. </span> 
          <span>Giao nhiệm vụ Phụ trách </span> 
          <ValueOrDots value={data?.chucVuMoiText} size={40}/>{" "}
          <ValueOrDots value={data?.phongBanMoiText} size={40}/>
          <span> đối với (Ông/Bà) </span>
          <ValueOrDots value={data?.fullName} size={40}/>
          <span>, sinh ngày: {data?.birthdate ? data?.birthdate : "....../..../........."}, </span> 
          <span>trình độ chuyên môn: </span>
          <ValueOrDots value={data?.trinhDoChuyenMon} size={40}/>{" "}
          <ValueOrDots value={data?.chuyenNganhDaoTao} size={40}/>
          <span> kể từ ngày </span>
          <ValueOrDots value={data?.hieuLucTuNgay} size={40}/>
          <span> cho đến khi {data?.donViCongTacText} có Quyết định về công tác cán bộ đối với </span>
          <ValueOrDots value={data?.phongBanMoiText} size={40}/>.
        </p>
        <div>
          <span className="fw-600">Điều 2. </span>
          <span>Tiền lương và các khoản phụ cấp theo lương của (Ông/Bà) </span>
          <ValueOrDots value={data?.fullName} size={40}/>
          <span> hưởng theo quy định hiện hành.</span>
        </div>
        <p>
          <span className="fw-600">Điều 3. </span>
          <span>Các Ông, bà <ValueOrDots value={data?.khoaPhongNhanQuyetDinh} size={160} /></span>
          <span>, các khoa, phòng có liên quan và (Ông/Bà) </span>
          <ValueOrDots value={data?.fullName} size={40}/>
          <span> chịu trách nhiệm thi hành quyết định này kể từ ngày ký.</span>
        </p>
      </div>
      <div className="flex flex-between align-start spaces pb-70 mt-16">
        <div>
          <p className="fw-600 text-italic">Nơi nhận: </p>
          <p>- Như Điều 3 (để t/h);</p>
          <p>- Lưu: VT, TCHC.</p>
        </div>
        <div className="text-center fs-md">
          <p className="fw-600">GIÁM ĐỐC</p>
          <p className="spaces mt-70 fw-600 text-capitalize-first">{data?.nguoiQuyetDinhText}</p>
        </div>
      </div>
    </div>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import * as Yup from "yup";
import UploadFile from "../../../components/file-upload/UploadFile";
import Autocomplete from "../../../components/input/autocomplete";
import { RangeDatePicker } from "../../../components/input/RangeDatePicker";
import TextValidator from "../../../components/input/text-validator";
import { searchAllSimpleValue, searchListWorkUnit, searchPhongBanByDonVi } from "../../../services/services";
import "../../../styles/index.scss";
import { RESPONSE_STATUS_CODE, VARIABLE_STRING, TYPE_CATEGORY } from "../../../constants/moduleConsts";
import { handleBlurDate, hasAuthority, useCustomIntl } from "../../../utils/functionUtils";
import { LIST_STATUS_NV } from "../../constants/dialogChildConsts";
import { TYPE_OF, natureOfWork } from "../../constants/profileDialogConsts";
import { INIT_WORKING_PROCESS } from "../../constants/profileConsts";
import { IFile } from "../../models/dialogModels";
import { IWorkingProcessInfoOutput } from "../../models/profileModels";
import { addQuaTrinhCongTac, updateQuaTrinhCongTac, } from "../../services/dialogServices";
import { useProfileContext } from "../../ProfileContext";
import { checkInvalidDate } from "../../../utils/validationSchema";
import AppContext from "../../../../AppContext";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import { GroupButton } from "../../../components/GroupButton";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../../../constants";
interface Props {
  handleCloseWorkProcessDialog: () => void;
  handleCloseAndSearch: () => void;
  identify: string;
  isView: boolean;
  workProcessEdit: IWorkingProcessInfoOutput;
}
export const WorkProcessDialog = (props: Props) => {
  const { handleCloseWorkProcessDialog, handleCloseAndSearch, identify, workProcessEdit, isView } = props;

  const { lang, intl } = useMultiLanguage();
  const { isResign } = useProfileContext();

  const [isCheckDonViCongTac, setIsCheckDonViCongTac] = useState<boolean>(workProcessEdit?.donViCongTacKhac ? true : false);
  const [isCheckPhongBan, setIsCheckPhongBan] = useState<boolean>(workProcessEdit?.phongBanKhac ? true : false);
  const [isEdit, setIsEdit] = useState<boolean>(!isView);
  const { setPageLoading } = useContext(AppContext);

  useEffect(() => {
    formik.setValues(workProcessEdit)
  }, [workProcessEdit])

  const handleSubmit = async (values: IWorkingProcessInfoOutput) => {
    const dataSubmit = {
      ...values,
      employeeId: identify,
      donViCongTacId: values.donViCongTac?.id,
      donViCongTacText: values.donViCongTac?.name || values.donViCongTac?.value,
      phongBanId: values.phongBan?.id,
      phongBanText: values.phongBan?.name || values.phongBan?.value,
      chucVuId: values.chucVu?.id,
      chucVuText: values.chucVu?.name || values.chucVu?.value,
      chucDanhId: values.chucDanh?.id,
      chucDanhText: values.chucDanh?.name || values.chucDanh?.value
    };

    if (typeof identify === TYPE_OF.STRING && identify !== "") {
      try {
        setPageLoading(true)
        const { data } = workProcessEdit?.id
          ? await updateQuaTrinhCongTac(workProcessEdit?.id, { ...dataSubmit, id: workProcessEdit?.id })
          : await addQuaTrinhCongTac(dataSubmit);
        if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
          const message = workProcessEdit?.id ? "TOAST.EDIT.SUCCESS" : "TOAST.ADD.SUCCESS";
          toast.success(intl.formatMessage({ id: message }));
          handleCloseAndSearch();
        } else {
          toast.warning(data?.message);
        }
      } catch (error) {
        toast.error(intl.formatMessage({ id: "GENERAL.ERROR" }));
      } finally {
        setPageLoading(false)
      }
    } else {
      toast.warning(intl.formatMessage({ id: "MESSAGE.BASIC.FIRST" }));
    }
  };

  const validationSchema = Yup.object().shape({
    tuNgay: Yup.date()
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRE" }))
      .concat(checkInvalidDate(intl))
      .nullable(),
    denNgay: Yup.date()
      .concat(checkInvalidDate(intl))
      .nullable(),
    ngayQuyetDinh: Yup.date()
      .concat(checkInvalidDate(intl))
      .nullable(),
    donViCongTac: !isCheckDonViCongTac
      ? Yup.object().required(lang("VALIDATION.REQUIRE")).nullable()
      : Yup.object().nullable(),
    donViCongTacKhac: isCheckDonViCongTac
      ? Yup.string().required(lang("VALIDATION.REQUIRE")).nullable()
      : Yup.string().nullable(),
    phongBan: !isCheckPhongBan
      ? Yup.object().required(lang("VALIDATION.REQUIRE")).nullable()
      : Yup.object().nullable(),
    phongBanKhac: isCheckPhongBan
      ? Yup.string().required(lang("VALIDATION.REQUIRE")).nullable()
      : Yup.string().nullable(),
    tinhChatLaoDong: Yup.object()
      .shape({})
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRE" }))
      .nullable(),
    chucVu: Yup.object()
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRE" }))
      .nullable(),
    loaiThuTuc: Yup.object()
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRE" }))
      .nullable(),
  });

  const formik = useFormik({
    initialValues: INIT_WORKING_PROCESS,
    validationSchema,
    onSubmit: handleSubmit
  })

  const handleChangeSelect = (name: string, value: any) => {
    if (name === VARIABLE_STRING.DON_VI_CONG_TAC) {
      formik.setFieldValue(VARIABLE_STRING.DON_VI_CONG_TAC_KHAC, "")
      formik.setFieldValue(VARIABLE_STRING.PHONG_BAN_KHAC, "")
    }

    if (name === VARIABLE_STRING.PHONG_BAN) {
      formik.setFieldValue(VARIABLE_STRING.PHONG_BAN_KHAC, "")
    }

    formik.setFieldValue(name, value)
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>, nameObj: string) => {
    const { name, value } = event.target;

    if (name === VARIABLE_STRING.DON_VI_CONG_TAC_KHAC) {
      formik.setFieldValue(VARIABLE_STRING.DON_VI_CONG_TAC, null)
      formik.setFieldValue(VARIABLE_STRING.PHONG_BAN, null)
      formik.setFieldValue(name, value)
      return;
    }

    formik.setFieldValue(nameObj, null)
    formik.setFieldValue(name, value)
  }

  const handleUploadFile = (data: IFile) => {
    formik.setFieldValue("fileId", data.id);
    formik.setFieldValue("fileName", data.name);
  }

  return (
    <Modal
      show={true}
      size="xl"
      centered
      aria-labelledby="example-custom-modal-styling-title"
      onHide={handleCloseWorkProcessDialog}
      backdrop="static"
    >
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title
            id="example-custom-modal-styling-title"
            className="heading-5"
          >
            {useCustomIntl("INPUT.WORKPROCESS.TITLE")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={4} className="pt-5">
              <Autocomplete
                lable={useCustomIntl("INPUT.WORKPROCESS.KIND")}
                isReadOnly={!isEdit}
                isRequired
                options={[]}
                value={formik.values?.loaiThuTuc || null}
                name="loaiThuTuc"
                onChange={(selectedOption) =>
                  handleChangeSelect("loaiThuTuc", selectedOption)
                }
                searchFunction={searchAllSimpleValue}
                searchObject={{ type: TYPE_CATEGORY.loaiThuTuc }}
                errors={formik.errors?.loaiThuTuc}
                touched={formik.touched?.loaiThuTuc}
                getOptionLabel={(option) => option?.value}
              />
            </Col>
            <Col xs={4} className="pt-5">
              <RangeDatePicker
                label={intl.formatMessage({
                  id: "INPUT.WORKPROCESS.WORKING_TIME"
                })}
                isRequired
                startDateName="tuNgay"
                endDateName="denNgay"
                isView={!isEdit}
                handleChange={formik.handleChange}
                value={formik.values}
                setFieldValue={formik.setFieldValue}
                touch={formik.touched}
                errors={formik.errors}
              />
            </Col>
            <Col xs={4} className="pt-5">
              <Autocomplete
                isRequired
                lable={useCustomIntl("GENERAL.CURRENT_ORGANIZATION")}
                formCheckBox={true}
                isCheckBox={isCheckDonViCongTac}
                isReadOnly={!isEdit}
                setIsCheckBox={setIsCheckDonViCongTac}
                name={
                  isCheckDonViCongTac
                    ? VARIABLE_STRING.DON_VI_CONG_TAC_KHAC
                    : VARIABLE_STRING.DON_VI_CONG_TAC
                }
                value={
                  isCheckDonViCongTac
                    ? formik.values?.donViCongTacKhac
                    : formik.values?.donViCongTac
                }
                onChange={(selectedOption) =>
                  isCheckDonViCongTac
                    ? handleChange(
                      selectedOption,
                      VARIABLE_STRING.DON_VI_CONG_TAC
                    )
                    : handleChangeSelect(
                      VARIABLE_STRING.DON_VI_CONG_TAC,
                      selectedOption
                    )
                }
                options={[]}
                searchFunction={searchListWorkUnit}
                errors={isCheckDonViCongTac ? formik.errors?.donViCongTacKhac : formik.errors?.donViCongTac}
                touched={isCheckDonViCongTac ? formik.touched?.donViCongTacKhac : formik.touched?.donViCongTac}
              />
            </Col>
            <Col xs={4} className="pt-5">
              <Autocomplete
                isRequired
                lable={intl.formatMessage({ id: "INPUT.DEPARTMENTS" })}
                isReadOnly={!isEdit}
                options={[]}
                formCheckBox={true}
                isCheckBox={isCheckPhongBan}
                setIsCheckBox={setIsCheckPhongBan}
                name={
                  isCheckPhongBan
                    ? VARIABLE_STRING.PHONG_BAN_KHAC
                    : VARIABLE_STRING.PHONG_BAN
                }
                value={
                  isCheckPhongBan
                    ? formik.values?.phongBanKhac
                    : formik.values?.phongBan
                }
                onChange={(selectedOption) =>
                  isCheckPhongBan
                    ? handleChange(selectedOption, VARIABLE_STRING.PHONG_BAN)
                    : handleChangeSelect(
                      VARIABLE_STRING.PHONG_BAN,
                      selectedOption
                    )
                }
                urlData="data.data"
                searchFunction={searchPhongBanByDonVi}
                searchObject={{ id: formik.values?.donViCongTac?.id }}
                touched={isCheckPhongBan ? formik.touched?.phongBanKhac : formik.touched?.phongBan}
                errors={isCheckPhongBan ? formik.errors?.phongBanKhac : formik.errors?.phongBan}
                dependencies={[formik.values?.donViCongTac]}
              />
            </Col>
            <Col xs={4} className="pt-5">
              <Autocomplete
                isRequired
                lable={useCustomIntl("CONTRACT.JOBPOSITION")}
                options={[]}
                isReadOnly={!isEdit}
                value={formik.values?.chucVu || null}
                name="chucVu"
                onChange={(selectedOption) =>
                  handleChangeSelect("chucVu", selectedOption)
                }
                searchFunction={searchAllSimpleValue}
                searchObject={{ type: TYPE_CATEGORY.chucDanh }}
                errors={formik.errors?.chucVu}
                touched={formik.touched?.chucVu}
                getOptionLabel={(option) => option?.value || option?.name}
              />
            </Col>
            <Col xs={4} className="pt-5">
              <Autocomplete
                lable={intl.formatMessage({
                  id: "INPUT.WORKPROCESS.JOB_TITLE"
                })}
                name="chucDanh"
                value={formik.values?.chucDanh || ""}
                searchFunction={searchAllSimpleValue}
                searchObject={{ type: TYPE_CATEGORY.viTriCongViec }}
                isReadOnly={!isEdit}
                onChange={(selectedOption) =>
                  handleChangeSelect(VARIABLE_STRING.CHUC_DANH, selectedOption)
                }
                options={[]}
                getOptionLabel={(option) => option?.value}
              />
            </Col>
            <Col xs={4} className="pt-5">
              <TextValidator
                lable={useCustomIntl("GENERAL.INFO.CONCURRENTLY")}
                name="kiemNhiem"
                value={formik.values?.kiemNhiem || ""}
                type="text"
                readOnly={!isEdit}
                onChange={formik.handleChange}
              />
            </Col>
            <Col xs={4} className="pt-5">
              <Autocomplete
                isRequired
                lable={useCustomIntl("INPUT.WORKPROCESS.NATURE")}
                isReadOnly={!isEdit}
                options={natureOfWork}
                value={formik.values?.tinhChatLaoDong || null}
                name="tinhChatLaoDong"
                onChange={(selectedOption) =>
                  handleChangeSelect("tinhChatLaoDong", selectedOption)
                }
                errors={formik.errors?.tinhChatLaoDong}
                touched={formik.touched?.tinhChatLaoDong}
              />
            </Col>
            <Col xs={4} className="pt-5">
              <Autocomplete
                lable={useCustomIntl("INPUT.WORKPROCESS.STATUS")}
                isReadOnly={!isEdit}
                options={LIST_STATUS_NV}
                value={formik.values?.trangThaiLaoDong || null}
                name="trangThaiLaoDong"
                onChange={(selectedOption) =>
                  handleChangeSelect("trangThaiLaoDong", selectedOption)
                }
              />
            </Col>
          </Row>
          <Row>
            <Col xs={4} className="pt-5">
              <TextValidator
                lable={useCustomIntl("INPUT.WORKPROCESS.DECISION.DATE")}
                name="ngayQuyetDinh"
                value={formik.values?.ngayQuyetDinh || ""}
                type="date"
                readOnly={!isEdit}
                onChange={formik.handleChange}
                onBlur={() =>
                  handleBlurDate(
                    formik.setFieldValue,
                    formik.values?.ngayQuyetDinh,
                    "ngayQuyetDinh"
                  )
                }
                errors={formik.errors?.ngayQuyetDinh}
                touched={formik.touched?.ngayQuyetDinh}
              />
            </Col>
            <Col xs={4} className="pt-5">
              <TextValidator
                lable={useCustomIntl("INPUT.WORKPROCESS.DECISION.NUMBER")}
                name="soQuyetDinh"
                value={formik.values?.soQuyetDinh || ""}
                type="text"
                readOnly={!isEdit}
                onChange={formik.handleChange}
              />
            </Col>
            <Col xs={4} className="pt-5">
              <UploadFile
                isReadOnly={!isEdit}
                label="CONTRACT.ATTACHMENTS"
                setValue={handleUploadFile}
                fileValue={{
                  id: formik.values.fileId || "",
                  name: formik.values.fileName || ""
                }}
                errors={formik?.errors?.fileName}
              />
            </Col>
            <Col xs={12} className="pt-5">
              <TextValidator
                lable={useCustomIntl("INPUT.FAMILY.NOTE")}
                name="note"
                value={formik.values?.note || ""}
                type="text"
                as="textarea"
                rows="3"
                readOnly={!isEdit}
                onChange={formik.handleChange}
                touched={formik.touched?.note}
                errors={formik.errors?.note}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="flex-center">
          <GroupButton type="btn-cancel" handleCloseUpdateDialog={handleCloseWorkProcessDialog} />
          {!isResign &&
            (isEdit ? (
              <GroupButton type="btn-save" />
            ) : (hasAuthority(PERMISSIONS.EMPLOYEE, PERMISSION_ABILITY.UPDATE) &&
              <GroupButton type="btn-edit" handleSaveEdit={() => setIsEdit(true)} />
            ))}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
